import React, { useEffect, useRef, useState } from 'react';
import { editQuestionnaire } from '../../../services/QuestionnaireService';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import { addDebitNote, deleteChargeBill, findAll } from '../../../services/PayCostManagementService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import AuthorizedView from '../../../util/AuthorizedView';
import { findFee } from '../../../services/orderService';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
import { findBuilds, findLiveRoom, findWuyeRoomList } from '../../../services/ZhuhushenheService';
import {
	addMeterReading,
	deleteMeterReading,
	findLastMeterReading,
	getList,
	updateMeterReading,
} from '../../../services/meterReading';

const Option = Select.Option;
interface Params {
	state?: string;
	billType?: string;
}
const MeterReadingList: React.FC<Params> = (props: any) => {
	useEffect(() => {
		findRooms().then();
	}, []);
	const TreeNode = TreeSelect.TreeNode;
	const confirm = Modal.confirm;
	const Search = Input.Search;
	let { RangePicker } = DatePicker;
	let msg = require('../../../util/SuspensionMsg');
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [searchInput, setSearchInput] = useState('');
	const [visible, setVisible] = useState(false);
	const [rooms, setrooms] = useState([]);
	const [type, settype] = useState('');
	const [roomUuid, setroomUuid] = useState('');
	const [billType, setbillType] = useState(props.billType || '');
	const billTypes = getBillTypes().list || [];
	const [add, setadd] = useState(false);

	const columns = [
		{
			title: '房间号',
			dataIndex: 'roomNumber',
		},
		{
			title: '房间地址',
			dataIndex: 'roomPosition',
		},
		{
			title: '客户',
			dataIndex: 'userName',
		},
		{
			title: '上次抄表',
			dataIndex: 'lastMeterReading',
		},
		{
			title: '上次抄表时间',
			dataIndex: 'lastMeterReadingDate',
			valueType: 'date',
		},
		{
			title: '本次抄表',
			dataIndex: 'thisMeterReading',
		},
		{
			title: '本次抄表时间',
			dataIndex: 'thisMeterReadingDate',
			valueType: 'date',
		},
		{
			title: '操作人',
			dataIndex: 'opUserName',
		},
		{
			title: '操作',
			key: 'action',
			width: 100,
			fixed: 'right',
			render: (_, item) => {
				return (
					<>
						<Space>
							<a
								onClick={() => {
									setadd(false);
									const formData = {
										...item,
										lastMeterReadingDate: moment(
											moment(item.lastMeterReadingDate).format('YYYY-MM-DD'),
											'YYYY-MM-DD'
										),
										thisMeterReadingDate: moment(
											moment(item.thisMeterReadingDate).format('YYYY-MM-DD'),
											'YYYY-MM-DD'
										),
									};
									form.setFieldsValue({ ...formData });
									setVisible(true);
								}}
							>
								编辑
							</a>
							<a onClick={() => deleteItem(item)}>删除</a>
						</Space>
					</>
				);
			},
		},
	];
	const tableSearchBarRender = () => [
		<div hidden={DAL.dal.isNotNull(props.state)}>
			<Select
				placeholder={'缴费状态'}
				allowClear
				onChange={(value: any) => {
					setState(value);
					// actionRef.current.reloadAndRest();
				}}
			>
				<Option value={'true'}>已缴费</Option>
				<Option value={'false'}>未缴费</Option>
			</Select>
		</div>,
		<div>
			<Select value={billType} onChange={(value) => setbillType(value)}>
				<Select.Option value={''}>全部</Select.Option>
				{billTypes.map((item: any) => (
					<Option value={item.value}>{item.name}</Option>
				))}
			</Select>
		</div>,
		<Input
			placeholder="姓名,账号"
			onChange={(event) => {
				setSearchInput(event.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];

	const deleteItem = (value: any) => {
		confirm({
			title: '您确定要删除此抄表记录吗?',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteMeterReading({ uuid: value.uuid });
				console.log('.....', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};

	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data::::', data);
			const formData = {
				...data,
				lastMeterReadingDate: moment(data.lastMeterReadingDate).format('YYYY-MM-DD'),
				thisMeterReadingDate:moment(data.thisMeterReadingDate).format('YYYY-MM-DD')
			};
			const res = await addMeterReading(formData);
			if (res.err == 0) {
				message.success('添加成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				...data,
				lastMeterReadingDate: moment(data.lastMeterReadingDate).format('YYYY-MM-DD'),
				thisMeterReadingDate:moment(data.thisMeterReadingDate).format('YYYY-MM-DD')
			};
			const res = await updateMeterReading(formData);
			if (res.err == 0) {
				message.success('修改成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	/*获得房间列表*/
	const findRooms = async () => {
		const res = await findWuyeRoomList({});
		if (res.err === 0) {
			setrooms(res.data || []);
		}
	};
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setadd(true);
				form.resetFields();
				setVisible(true);
			}}
		>
			新增
		</Button>,
	];

	const findLast = async (type, roomUuid) => {
		if (DAL.dal.isNotNull(type) && DAL.dal.isNotNull(roomUuid)) {
			const res = await findLastMeterReading({ type, roomUuid });
			console.log('last::', res);
			if (res.err == 0) {
				if (res.data) {
					const formData = {
						lastMeterReading: res.data.lastMeterReading,
						lastMeterReadingDate: moment(
							moment( res.data.thisMeterReadingDate).format('YYYY-MM-DD'),
							'YYYY-MM-DD'
						),
					};

					form.setFieldsValue({ ...formData });
				}
			}
		}
	};
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {
						page: params.current,
						size: params.pageSize,
						searchInput: searchInput,
					};
					const res = await getList(data);
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>

			<Modal
				title={add ? '添加抄表' : '修改抄表'}
				visible={visible}
				onOk={saveItem}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem name={'uuid'} hidden={true}>
						<Input></Input>
					</FormItem>
					<FormItem rules={[{ required: true }]} label="表类型" name={'type'}>
						<Select
							disabled={!add}
							value={type}
							onChange={(value) => {
								settype(value);
								findLast(value, roomUuid);
							}}
						>
							{getBillTypes().meterReadingTypes.map((item) => {
								return (
									<>
										<Select.Option value={item.value}>{item.name}</Select.Option>
									</>
								);
							})}
						</Select>
					</FormItem>
					<FormItem label={'房间'} name={'roomUuid'} rules={[{ required: true }]}>
						<Select
							disabled={!add}
							value={roomUuid}
							onChange={(value) => {
								setroomUuid(value);
								findLast(type, value);
							}}
							optionFilterProp="children"
							showSearch
						>
							{rooms.map((item) => {
								return (
									<>
										<Select.Option value={item.uuid}>
											{item.roomNumber}-{item.position}
										</Select.Option>
									</>
								);
							})}
						</Select>
					</FormItem>
					<FormItem label={'上次抄表'} name={'lastMeterReading'} rules={[{ required: true }]}>
						<InputNumber step={'0.01'} min={0} disabled={!add} />
					</FormItem>
					<FormItem label={'上次抄表时间'} rules={[{ required: true }]} name={'lastMeterReadingDate'}>
						<DatePicker disabled={!add} format="YYYY-MM-DD" style={{ width: '100%' }}></DatePicker>
					</FormItem>
					<FormItem
						label={'本次抄表'}
						required
						name={'thisMeterReading'}
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(new Error('请输入本次抄表'));
									}
									if (value < getFieldValue('lastMeterReading')) {
										return Promise.reject(new Error('抄表必须大于上次抄表'));
									}
									return Promise.resolve();
								},
							}),
						]}
					>
						<InputNumber step={'0.01'} min={0} />
					</FormItem>
					<FormItem
						label={'本次抄表时间'}
						required
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(new Error('请输入本次抄表时间'));
									}
									if (value < getFieldValue('lastMeterReadingDate')) {
										return Promise.reject(new Error('本次抄表时间须大于上次抄表时间'));
									}
									return Promise.resolve();
								},
							}),
						]}
						name={'thisMeterReadingDate'}
					>
						<DatePicker format="YYYY-MM-DD" style={{ width: '100%' }}></DatePicker>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default MeterReadingList;
