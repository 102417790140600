import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker, Select, Space } from 'antd';
interface CustomRangePickerProps {
	selectType?: number;
	startTime?: moment;
	endTime?: moment;
	onChange: (startTime: moment, endTime: moment, selectType?: number) => void;
}
const RangePicker = DatePicker.RangePicker;

const CustomRangePicker: React.FC<CustomRangePickerProps> = (props: CustomRangePickerProps) => {
	const [selectType, setSelectType] = useState(props.selectType || 3);
	const [startTime, setStartTime] = useState(
		props.startTime || moment().subtract(7, 'd').hours(0).minute(0).second(0)
	);
	const [endTime, setEndTime] = useState(props.endTime || moment().hours(23).minute(59).second(59));
	const changeSelectType = (e: number) => {
		setSelectType(e);
		switch (e) {
			case 1:
				setStartTime(moment().hours(0).minute(0).second(0));
				setEndTime(moment().hours(23).minute(59).second(59));
				props.onChange(
					moment().hours(0).minute(0).second(0),
					moment().hours(23).minute(59).second(59),
					1
				);
				break;
			case 2:
				setStartTime(moment().subtract(1, 'd').hours(0).minute(0).second(0));
				setEndTime(moment().subtract(1, 'd').hours(23).minute(59).second(59));
				props.onChange(
					moment().subtract(1, 'd').hours(0).minute(0).second(0),
					moment().subtract(1, 'd').hours(23).minute(59).second(59),
					2
				);
				break;
			case 3:
				setStartTime(moment().subtract(7, 'd').hours(0).minute(0).second(0));
				setEndTime(moment().hours(23).minute(59).second(59));
				props.onChange(
					moment().subtract(7, 'd').hours(0).minute(0).second(0),
					moment().hours(23).minute(59).second(59),
					3
				);
				break;
			case 4:
				setStartTime(moment().subtract(15, 'd').hours(0).minute(0).second(0));
				setEndTime(moment().hours(23).minute(59).second(59));
				props.onChange(
					moment().subtract(15, 'd').hours(0).minute(0).second(0),
					moment().hours(23).minute(59).second(59),
					4
				);
				break;
			case 5:
				setStartTime(
					moment()
						.week(moment().week() - 1)
						.startOf('week')
						.hours(0)
						.minute(0)
						.second(0)
				);
				setEndTime(
					moment()
						.week(moment().week() - 1)
						.endOf('week')
						.hours(23)
						.minute(59)
						.second(59)
				);
				props.onChange(
					moment()
						.week(moment().week() - 1)
						.startOf('week')
						.hours(0)
						.minute(0)
						.second(0),
					moment()
						.week(moment().week() - 1)
						.endOf('week')
						.hours(23)
						.minute(59)
						.second(59),5
				);
				break;
			case 6:
				setStartTime(
					moment()
						.month(moment().month() - 1)
						.startOf('month')
						.hours(0)
						.minute(0)
						.second(0)
				);
				setEndTime(
					moment()
						.month(moment().month() - 1)
						.endOf('month')
						.hours(23)
						.minute(59)
						.second(59)
				);
				props.onChange(
					moment()
						.month(moment().month() - 1)
						.startOf('month')
						.hours(0)
						.minute(0)
						.second(0),
					moment()
						.month(moment().month() - 1)
						.endOf('month')
						.hours(23)
						.minute(59)
						.second(59),
					6
				);
				break;
		}
	};
	return (
		<Space>
			<Select style={{width: 150}} defaultValue={selectType} onChange={changeSelectType}>
				<Select.Option value={1}>今天</Select.Option>
				<Select.Option value={2}>昨天</Select.Option>
				<Select.Option value={3}>最近7天</Select.Option>
				<Select.Option value={4}>最近15天</Select.Option>
				<Select.Option value={5}>上一周</Select.Option>
				<Select.Option value={6}>上个月</Select.Option>
				<Select.Option value={7}>自定义</Select.Option>
			</Select>
			<>
				{selectType === 7 && (
					<RangePicker
						key={[startTime, endTime].toString()}
						showTime={true}
						allowClear={false}
						defaultValue={[startTime, endTime]}
						onChange={(dates: any) => {
							console.log('dates:::::', dates);
							setStartTime(
								dates
									? dates[0]
									: props.startTime || moment().subtract(7, 'd').hours(0).minute(0).second(0)
							);
							setEndTime(dates ? dates[1] : props.endTime || moment().hours(23).minute(59).second(59));
							props.onChange(
								dates
									? dates[0]
									: props.startTime || moment().subtract(7, 'd').hours(0).minute(0).second(0),
								dates ? dates[1] : props.endTime || moment().hours(23).minute(59).second(59),7
							);
						}}
						format="YYYY-MM-DD HH:mm"
						placeholder={['请选择开始时间', '请选择结束时间']}
					/>
				)}
			</>
		</Space>
	);
};
export default CustomRangePicker;
