import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import BeijingOfficeList from './page/BeijingOfficeList';
import ZjbOrderList from './page/ZjbOrderList';
import DemandList from "./page/DemandList";

const BeijingOfficeMiniApp: React.FC = () => {
	const rootUrl = '/beijingOffice';
	const menus: MiniAppMenu[] = [
		{
			authority: 'beijingOfficeManager:list',
			name: '城市名片列表',
			path: 'list',
			component: BeijingOfficeList,
		},{
			authority: 'beijingOfficeManager:demand:list',
			name: '需求列表',
			path: 'demandList',
			component: DemandList ,
		},{
			authority: 'zjb_order_manage',
			name: '订单列表',
			path: 'orderManage',
			component: ZjbOrderList,
		},
	];
	return (
		<MiniApp miniAppName={"驻京办"} rootUrl={rootUrl} menus={menus} />
	);
}
export default BeijingOfficeMiniApp
