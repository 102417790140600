import React, { useEffect, useRef, useState } from 'react';

import {
	Button,
	Checkbox,
	Col,
	List,
	Input,
	message,
	Modal,
	Row,
	Select,
	Space,
	Steps,
	Switch,
	Tag,
	DatePicker,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import DAL from '../../../data/DAL';
import { findBuilds, findLiveRoom } from '../../../services/ZhuhushenheService';
import VirtualList from 'rc-virtual-list';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CloseOutlined } from '@ant-design/icons';
import { ActionType } from '@ant-design/pro-table';
import ZNengCheckedCharge from '../components/ZNengCheckedCharge';
import { creatChargeBillByCharge } from '../../../services/roomCharge';
var msg = require('../../../util/SuspensionMsg');
const confirm = Modal.confirm;
const { Step } = Steps;
const ZNengJiFei: React.FC = () => {
	useEffect(() => {
		getBuilds().then();
	}, []);
	const actionRef = useRef<ActionType>();
	const formRef = useRef();
	const [loading, setloading] = useState(false);
	const [chargeSubject, setchargeSubject] = useState('');
	const [search, setsearch] = useState('');
	const [builds, setbuilds] = useState([]);
	const [seleectedRowKeys, setseleectedRowKeys] = useState([]);
	const [current, setcurrent] = useState(0);
	const wHeight = document.documentElement.clientHeight + 25;
	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAll, setCheckAll] = useState(false);
	const [checkList, setcheckList] = useState([]);
	const [buildTotal, setbuildTotal] = useState(0);
	const [checkedChargeList, setcheckedChargeList] = useState([]);
	let { RangePicker } = DatePicker;
	const [startTime, setstartTime] = useState('');
	const [endTime, setendTime] = useState('');
	const onChangeSubject = (value) => {
		setCheckAll(false);
		setseleectedRowKeys([]);
		setcheckList([]);
		setsearch('');
		setIndeterminate(false);

		if (value.target.checked) {
			setchargeSubject(value.target.value);
		} else {
			setchargeSubject('');
		}
	};
	const rowSelection = {
		columnWidth: 50,
		preserveSelectedRowKeys: true,
		selectedRowKeys: seleectedRowKeys,
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			console.log('selec', selectedRowKeys, selectedRows);
			setseleectedRowKeys(selectedRowKeys);
			setcheckList(selectedRows);
		},
	};
	const columns = [
		{
			title: '房间号',
			dataIndex: 'roomNumber',
			key: 'roomNumber',
		},
		{
			title: '资产类型',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '装修风格',
			dataIndex: 'houseStyle',
			key: 'houseStyle',
		},
		{
			title: '业主',
			key: 'householdName',
			dataIndex: 'householdName',
		},
		{
			title: '备注',
			key: 'note',
			dataIndex: 'note',
		},
	];
	const getBuilds = async () => {
		const res = await findBuilds({ search: search });
		if (res.err == 0) {
			setbuilds(res.data || []);
			setbuildTotal(res.total);
		}
	};
	const onChangeBuilds = (value) => {
		if (value.length == 0) {
			setIndeterminate(false);
			setCheckAll(false);
		} else if (value.length == buildTotal) {
			setIndeterminate(false);
			setCheckAll(true);
		} else {
			setIndeterminate(true);
			setCheckAll(false);
		}
		setcheckList(value);
		console.log('values:::', value);
	};
	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		if (e.target.checked) {
			setcheckList([...builds]);
		} else {
			setcheckList([]);
		}
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};
	const verifyAll = (checkList) => {
		if (checkList.length == 1) {
			setIndeterminate(false);
			setCheckAll(false);
		} else if (checkList.length == buildTotal) {
			setIndeterminate(true);
			setCheckAll(false);
		}
	};
	const dateTime = (date: any, dateToString: any) => {
		setstartTime(dateToString[0]);
		setendTime(dateToString[1]);
	};

	const createdItem = async () => {
		// if (!DAL.dal.isNotNull(startTime)) {
		// 	message.warning('请选择开始时间');
		// 	return;
		// }
		// if (!DAL.dal.isNotNull(endTime)) {
		// 	message.warning('请选择结束时间');
		// 	return;
		// }
		let subJectList = [];
		checkList.map((item) => {
			subJectList.push(item.uuid);
		});
		let chargeUuids = [];
		checkedChargeList.map((item) => {
			chargeUuids.push(item.uuid);
		});
		const formData = {
			chargeSubjectType: chargeSubject,
			chargeSubjectUuids: subJectList.toString(),
			feeTypeUuids: chargeUuids.toString(),
			startTime: startTime,
			endTime: endTime,
		};
		console.log('formData', formData);
		setloading(true);
		const res = await creatChargeBillByCharge(formData);
		setloading(false);

		if (res.err === 0) {
			message.success('生成成功');
		} else {
			message.error(res.msg);
		}
	};

	return (
		<>
			<style>{`
               
				.content-big
				{
					padding:0px
				}
				.ant-pro-table-list-toolbar-container{
					padding:0px
				}
				.ant-pro-table .ant-table-content tr:first-child > th:first-child, .ant-pro-table .ant-table-row .ant-table-cell:first-child {
					padding-left:9px
				}
			
            `}</style>

			{/* 头 */}
			<div
				style={{
					background: '#fff',
					padding: '10px 20px ',
					height: 100,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Steps current={current}>
					<Step title="选择计费主体" />
					<Step title="选择收费项目" />
					{/* <Step title="设置范围及执行方式" /> */}
				</Steps>
			</div>
			<div style={{ height: 10 }}></div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: wHeight - 190,
					background: '#fff',
					padding: 16,
				}}
			>
				{current == 0 && (
					<>
						{/* <div style={{ display: 'flex', width: '100%', height: 80, justifyContent: 'space-between' }}> */}
						<Row style={{ marginBottom: 10 }}>
							<Col span={8}></Col>
							<Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
								<Row>
									<Checkbox
										checked={chargeSubject == '楼栋'}
										disabled={DAL.dal.isNotNull(chargeSubject) && '楼栋' != chargeSubject}
										onChange={(value) => onChangeSubject(value)}
										value="楼栋"
									>
										楼栋
									</Checkbox>
									<Checkbox
										checked={chargeSubject == '房间'}
										disabled={DAL.dal.isNotNull(chargeSubject) && '房间' != chargeSubject}
										onChange={(value) => onChangeSubject(value)}
										value="房间"
									>
										房间
									</Checkbox>
								</Row>
							</Col>
							<Col span={8} style={{ textAlign: 'end' }}>
								{chargeSubject == '房间' && (
									<Space>
										<Input
											placeholder="房间号"
											onChange={(value: any) => setsearch(value.target.value)}
										></Input>
										<Button
											type="primary"
											onClick={() => {
												if (actionRef.current) {
													actionRef.current.reloadAndRest();
												}
											}}
										>
											查询{' '}
										</Button>
									</Space>
								)}
							</Col>
						</Row>
						{/* <div style={{ display: 'flex' }}> </div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								
							</div>
							<div style={{ display: 'flex' }}>
								
							</div> */}
						{/* </div> */}
						{chargeSubject == '楼栋' && (
							<div style={{ display: 'flex' }}>
								<div style={{ minWidth: 813, display: 'flex', width: '100%', flexDirection: 'column' }}>
									<div
										style={{
											height: 47,
											background: '#FAFAFA',
											display: 'flex',
											// flex: 1,
											alignItems: 'center',
										}}
									>
										<div>共 {buildTotal} 个</div>
										<div style={{ marginLeft: 10 }}>
											<Checkbox
												indeterminate={indeterminate}
												onChange={onCheckAllChange}
												checked={checkAll}
											>
												全选
											</Checkbox>
										</div>
									</div>

									<div>
										<Checkbox.Group
											style={{ width: '100%' }}
											value={checkList}
											onChange={(value) => onChangeBuilds(value)}
										>
											<Row>
												{builds.map((item) => {
													return (
														<>
															<Col span={6}>
																<Checkbox value={item}>{item.buildName}</Checkbox>
															</Col>
														</>
													);
												})}
											</Row>
										</Checkbox.Group>
									</div>
								</div>
								<div
									style={{
										width: 10,
										height: 460,
										background: '#FAFAFA',
									}}
								></div>
								<div style={{ width: 295 }}>
									<div
										style={{
											height: 39,
											background: '#FAFAFA',
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<div>已选择{checkList.length}</div>
										<a
											onClick={() => {
												setCheckAll(false);
												setIndeterminate(false);
												setcheckList([]);
											}}
											style={{ marginRight: 10 }}
										>
											{' '}
											全部清空
										</a>
									</div>
									<List>
										<VirtualList data={checkList} height={380} itemHeight={47} itemKey="uuid">
											{(item: any, index: number) => (
												<List.Item
													actions={[
														<CloseOutlined
															style={{ width: 10, height: 10 }}
															onClick={() => {
																console.log('index::', index);
																verifyAll(checkList);
																checkList.splice(index, 1);

																setcheckList([...checkList]);
															}}
														/>,
													]}
													key={item.uuid}
												>
													<div>{item.buildName}</div>
												</List.Item>
											)}
										</VirtualList>
									</List>
								</div>
							</div>
						)}
						{chargeSubject == '房间' && (
							<>
								<div style={{ display: 'flex' }}>
									<div style={{ minWidth: 813, width: '100%' }}>
										<GSTable
											width={'100%'}
											columns={columns}
											actionRef={actionRef}
											scroll={{ y: 363 }}
											rowSelection={rowSelection}
											rowKey={'uuid'}
											options={false}
											tableAlertRender={false}
											request={async (params: any = {}, sort: any, filter: any) => {
												const res = await findLiveRoom({
													page: params.current,
													size: params.pageSize,
													Search: search,
													state: 1,
												});

												if (res.err !== 0) {
													return {
														data: [],
														total: 0,
													};
												}
												return {
													data: res.data,
													total: res.count,
													success: true,
												};
											}}
										/>
									</div>
									<div
										style={{
											width: 10,
											height: 460,
											background: '#FAFAFA',
										}}
									></div>
									<div style={{ width: 295 }}>
										<div
											style={{
												height: 47,
												background: '#FAFAFA',
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
											}}
										>
											<div>已选择{checkList.length}</div>
											<a
												onClick={() => {
													setcheckList([]);
													setseleectedRowKeys([]);
												}}
												style={{ marginRight: 10 }}
											>
												全部清空
											</a>
										</div>

										<List>
											<VirtualList data={checkList} height={380} itemHeight={47} itemKey="uuid">
												{(item: any, index: number) => (
													<List.Item
														actions={[
															<CloseOutlined
																style={{ width: 10, height: 10 }}
																onClick={() => {
																	const findIndex = seleectedRowKeys.findIndex(
																		(item1) => item1 == item.uuid
																	);
																	if (findIndex != undefined) {
																		seleectedRowKeys.splice(findIndex, 1);
																		setseleectedRowKeys([...seleectedRowKeys]);
																	}
																	checkList.splice(index, 1);
																	setcheckList([...checkList]);
																}}
															/>,
														]}
														key={item.uuid}
													>
														<div>
															{item.roomNumber}（{item.householdName}）
														</div>
													</List.Item>
												)}
											</VirtualList>
										</List>
									</div>
								</div>
							</>
						)}
					</>
				)}
				<div hidden={current !== 1}>
					<ZNengCheckedCharge hidden={true} updateCheckCharge={(value) => setcheckedChargeList(value)}></ZNengCheckedCharge>
				</div>
				<div hidden={current !== 2}>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Row align="middle">
							<Col>应收范围：</Col>
							<Col>
								<RangePicker
									format="YYYY-MM-DD"
									placeholder={['请选择开始时间', '请选择结束时间']}
									onChange={dateTime}
									style={{ width: '100%' }}
								/>
							</Col>
						</Row>
					</div>
				</div>
				<div style={{ position: 'fixed', bottom: 10, right: 10 }}>
					<Space>
						{current !== 0 && (
							<Button
								onClick={() => {
									setcurrent(current - 1);
								}}
							>
								上一步
							</Button>
						)}
						{current === 0 && (
							<Button
								type="primary"
								onClick={() => {
									if (current == 0) {
										if (!DAL.dal.isNotNull(chargeSubject)) {
											message.warning('请选择计费主体');
											return;
										}
										if (checkList == undefined || checkList.length == 0) {
											if (chargeSubject == '楼栋') {
												message.warning('请选择楼栋');
												return;
											} else {
												message.warning('请选择房间');
												return;
											}
										}
									}
									setcurrent(current + 1);
								}}
							>
								下一步
							</Button>
						)}
						{current === 1 && (
							<Button
								type="primary"
								loading={loading}
								onClick={() => {
									if (checkedChargeList.length == 0) {
										message.warning('请选择收费项目');
										return;
									}
									createdItem();
								}}
							>
								立即生成
							</Button>
						)}
					</Space>
				</div>
			</div>
		</>
	);
};
export default ZNengJiFei;
