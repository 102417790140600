import Api from '../data/API';

export async function info(params: any) {
	return Api.postWithAuth('/api/company/info', params);
}

export async function updateInfo(params: any) {
	return Api.postWithAuth('/api/company/updateInfo', params);
}
export async function getCompanyListByName(params: any) {
	return Api.postWithAuth('/api/company/getCompanyListByName', params)
}
export async function getCompanyAccount(params: any) {
	return Api.postWithAuth('/admin/api/company/getCompanyAccount', params)
}