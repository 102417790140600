import React from 'react';
interface PhoneProp {
	url: string;
}
const Phone: React.FC<PhoneProp> = ({ url }) => {
	return (
		<>
			<div className="phone">
				<iframe src={url} frameborder="0" />
			</div>
		</>
	);
};
export default Phone;
