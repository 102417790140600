import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import {
	Button,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Select,
	Image,
	Col,
	Card,
	Row,
	Space,
	Switch,
	TreeSelect,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import Api from '../../../data/API';
import ConnectState from '../../../models/connect';
import { connect } from 'dva';
import {
	addMarketChance,
	deleteMarketChance,
	getMarketChanceList,
	updateMarketChance,
} from '../../../services/marketChance';

import WangEditorPro from '../../../components/WangEditorPro';
import UploadImgFormItem from '../../../components/UploadImgFormItem';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import GsTreeSelect from '../../../components/GsTreeSelect';
import { getTreeSchemeTechList, getTreeSchemeTypeList } from '../../../services/scheme';
import TrimInput from '../../../components/TrimInput';
import DAL from '../../../data/DAL';
const MarketChanceList: React.FC = (props: any) => {
	useEffect(() => {
		getLiuchengDanList().then();
		getTypeList().then();
		getTechList().then();
	}, []);
	const [scenarioTypeList, setscenarioTypeList] = useState([]);
	const [schemeTechList, setschemeTechList] = useState([]);
	const [searchType, setsearchType] = useState("")
	const getTypeList = async () => {
		const rsp = await getTreeSchemeTypeList({});
		if (rsp.err === 0) {
			const data = rsp.data || [];
			renChildren(data);
			setscenarioTypeList([...data]);
		}
	};
	const renChildren = (list) => {
		list.forEach((element) => {
			element.title = element.name;
			element.value = element.uuid;
			if (element.children != undefined && element.children.length > 0) {
				renChildren(element.children);
			}
		});
	};
	const getTechList = async () => {
		const rsp = await getTreeSchemeTechList({});
		if (rsp.err === 0) {
			const data = rsp.data || [];
			renChildren(data);
			setschemeTechList([...data]);
		}
	};
	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const actionRef = useRef<ActionType>();
	const [add, setAdd] = useState(false);
	const [name, setname] = useState('');
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [sortByType, setsortByType] = useState('数字排序');
	const [status, setstatus] = useState('');
	const [info, setinfo] = useState('');
	const [dateKey, setDateKey] = useState(new Date().getTime().toString());
	const [liuchengList, setLiuchengList] = useState([]);
	const [type, settype] = useState('资讯');
	const FormItem = Form.Item;
	const statusEnum = {
		true: { text: '上架', status: 'Success' },
		false: { text: '下架', status: 'Error' },
	};
	const getLiuchengDanList = async () => {
		const data = {
			state: true,
			selectedTags: '',
			searchContent: '',
		};
		const res = await getAllLiucheng(data);
		console.log('res:::', res);
		if (res.err === 0) {
			setLiuchengList(res.data || []);
		}
	};
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
		},

		{
			title: '列表图',
			dataIndex: 'imageUrl',
			key: 'imageUrl',
			render: (text: string, record: any) => (
				<>
					<Image src={Api.cdnRootPath + record.imageUrl} height={80} width={80} />
				</>
			),
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			valueEnum: statusEnum,
		},
		// {
		// 	title: '状态',
		// 	dataIndex: 'status',
		// 	key: 'status',
		// 	valueEnum: statusEnum,
		// },
		{
			title: '排序值',
			dataIndex: 'sortBy',
			key: 'sortBy',
		},
		{
			title: '收藏量',
			dataIndex: 'collectionNumber',
			key: 'collectionNumber',
		},
		{
			title: '点击量',
			dataIndex: 'clickNumber',
			key: 'clickNumber',
		},
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							openUpdateModal(record);
						}}
					>
						编辑
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];

	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '你确定要删除' + value.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteMarketChance({ uuid: value.uuid });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error(res.msg);
				}
			},
		});
	};
	const openUpdateModal = (value: any) => {
		const formData: any = {
			...value,
			status: value.status !== undefined ? value.status : false,
			typeUuid: DAL.dal.isNotNull(value.typeUuid) ? value.typeUuid.split(',') : undefined,
			techUuid: DAL.dal.isNotNull(value.techUuid) ? value.techUuid.split(',') : undefined,
		};
		setinfo(value.info);
		form.setFieldsValue(formData);
		setDateKey(new Date().getTime().toString());
		setAdd(false);
		setVisible(true);
	};
	const openAddModal = () => {
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				...data,
				status: data.status !== undefined ? data.status : false,
				info: info,
			};
			if (data.typeUuid != undefined && data.typeUuid.length > 0) {
				let values = []
				data.typeUuid.map(item => {
					values.push(item.value)
				})
				formData.typeUuid = values.toString()
			} else { 
				formData.typeUuid = ""
			}
			if (data.techUuid != undefined && data.techUuid.length > 0) {
				let values = []
				data.techUuid.map(item => {
					values.push(item.value)
				})
				formData.techUuid = values.toString()
			} else { 
				formData.techUuid = ""
			}
			const res = await addMarketChance(formData);
			if (res.err === 0) {
				message.success('添加成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				...data,
				status: data.status !== undefined ? data.status : false,
				info: info,
			};
			if (data.typeUuid != undefined && data.typeUuid.length > 0) {
				let values = []
				const flag = Object.keys(data.typeUuid[data.typeUuid.length - 1]).indexOf('value')
				if (flag < 0) {
					formData.typeUuid = data.typeUuid.toString()
				} else { 
					data.typeUuid.map(item => {
						values.push(item.value)
					})
					formData.typeUuid = values.toString()
				}
				
			} else { 
				formData.typeUuid = ""
			}
			if (data.techUuid != undefined && data.techUuid.length > 0) {
				let values = []
				const flag = Object.keys(data.techUuid[data.techUuid.length - 1]).indexOf('value')
				if (flag < 0) {
					formData.techUuid = data.techUuid.toString()
				} else { 
					data.techUuid.map(item => {
						values.push(item.value)
					})
					formData.techUuid = values.toString()
				}
			} else { 
				formData.techUuid = ""
			}
			const res = await updateMarketChance(formData);
			if (res.err === 0) {
				message.success('修改成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={openAddModal}>
			新增
		</Button>,
	];
	const tableSearchBarRender = () => [
		<Space>
			<Select
				style={{ width: 150 }}
				value={sortByType}
				onChange={(value) => {
					setsortByType(value);
				}}
			>
				<Select.Option value={'更新时间'}>更新时间降序</Select.Option>
				<Select.Option value={'时间'}>时间升序排序</Select.Option>
				<Select.Option value={'收藏量'}>收藏量降序</Select.Option>
				<Select.Option key={'点击量'} value={'点击量'}>
					点击量降序
				</Select.Option>
			</Select>
			<Select
				style={{ width: 120 }}
				value={status}
				placeholder={'状态'}
				onChange={(value: any) => {
					setstatus(value);
				}}
			>
				<Select.Option value="">全部</Select.Option>
				<Select.Option value={1}>上架</Select.Option>
				<Select.Option value={0}>下架</Select.Option>
			</Select>
			<Select
				style={{ width: 120 }}
				value={searchType}
				placeholder={'类型'}
				onChange={(value: any) => {
					setsearchType(value);
				}}
			>
				<Select.Option value="">全部</Select.Option>
				<Select.Option value={'资讯'}>资讯</Select.Option>
				<Select.Option value={'需求'}>需求</Select.Option>
			</Select>
			<Input
				placeholder="名称"
				onChange={(e) => {
					setname(e.target.value);
				}}
			/>
			<Button
				type="primary"
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>
		</Space>,
	];
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				// pagination={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getMarketChanceList({
						name: name,
						sortByType: sortByType,
						status: status,
						type:searchType
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				visible={visible}
				title={add ? '添加' : '修改'}
				width={1250}
				onOk={saveItem}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} name={'uuid'}>
						<Input />
					</FormItem>
					<FormItem name={'name'} label={'名称/需求场景'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem name={'note'} label={'描述/需求描述'}>
						<Input.TextArea />
					</FormItem>
					<FormItem label='类型' name={'type'}>
						<Select
							onChange={(value: any) => {
								settype(value);
							}}
						>
							<Select.Option value={'资讯'}>资讯</Select.Option>
							<Select.Option value={'需求'}>需求</Select.Option>
						</Select>
					</FormItem>
					<FormItem hidden={add} name={'sortBy'} label={'排序值'} tooltip="数越大越靠前">
						<InputNumber style={{ width: '100%' }} />
					</FormItem>
					<Form.Item
						hidden={'资讯' != type}
						label={'列表图'}
						name={'imageUrl'}
						rules={[{ required: '资讯' == type, message: '请上传图片' }]}
					>
						<UploadImgFormItem
							hiddenImgCorp={false}
							multiple={false}
							listType={'picture-card'}
							widthRatio={1}
							heightRatio={1}
						/>
					</Form.Item>
					<FormItem hidden={'资讯' == type} name={'userName'} label={'用户名称'}>
						<TrimInput />
					</FormItem>
					<FormItem hidden={'资讯' == type} name={'userPhone'} label={'用户手机号'}>
						<TrimInput />
					</FormItem>
					<FormItem hidden={'资讯' == type} name={'companyName'} label={'公司名称'}>
						<TrimInput />
					</FormItem>
					<FormItem hidden={'资讯' == type} name={'companyNote'} label={'公司描述'}>
						<Input.TextArea />
					</FormItem>
					<FormItem hidden={'资讯' == type} name={'implReq'} label={'实现要求'}>
						<Input />
					</FormItem>
					<FormItem hidden={'资讯' == type} name={'price'} label={'预算范围'}>
						<Input />
					</FormItem>
					<FormItem hidden={'资讯' == type} label="应用场景" name={'typeUuid'}>
						<GsTreeSelect
							maxTagCount={99}
							style={{ width: '100%' }}
							treeCheckStrictly={true}
							showCheckedStrategy={TreeSelect.SHOW_ALL}
							treeData={scenarioTypeList}
							treeNodeFilterProp={'title'}
							placeholder={'应用场景'}
							multiple={false}
							defaultExpandAll={false}
						/>
					</FormItem>
					<FormItem hidden={'资讯' == type} label="通用技术" name={'techUuid'}>
						<GsTreeSelect
							maxTagCount={99}
							style={{ width: '100%' }}
							treeCheckStrictly={true}
							showCheckedStrategy={TreeSelect.SHOW_ALL}
							treeData={schemeTechList}
							treeNodeFilterProp={'title'}
							placeholder={'通用技术'}
							multiple={false}
							defaultExpandAll={false}
						/>
					</FormItem>
					<FormItem hidden={'资讯' != type} label="详情" name="info">
						<WangEditorPro
							editorId={'editorElem'}
							key={dateKey}
							value1={info}
							changeCallback={(res: any) => setinfo(res)}
						/>
					</FormItem>
					<FormItem name={'status'} label={'上架/下架'} valuePropName={'checked'}>
						<Switch />
					</FormItem>
					{/* <FormItem name={'phone'} label={'联系电话'}>
						<Input />
					</FormItem> */}
				</ProxyForm>
			</Modal>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(MarketChanceList);
