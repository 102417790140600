import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import KuangshiJob from './pages/KuangshiJob';
import AppParameterSet from './pages/AppParameterSet';
import RongQiSet from './pages/RongQiSet';

export default function FoundationSteupMiniApp() {
	const rootUrl = '/foundation';
	const menus: MiniAppMenu[] = [
		{
			name: '旷世同步设置',
			path: 'kuangshi-job',
			component: KuangshiJob,
			authority: 'kuangshi_job_set',
		},
		{
			name: '页面配置',
			authority: 'foundation_setup:parameter',
			path: 'foundation-parameter',
			component: AppParameterSet,
		},
		{
			name: '容器配置',
			authority: 'foundation-rongQiSet:parameter',
			path: 'foundation-container-set',
			component: RongQiSet,
		},
		// {
		// 	name: '数据传输',
		// 	authority: 'foundation-data-transmission:parameter',
		// 	path: 'data-transmission',
		// 	component: DataTransmission,
		// },
	];

	return <MiniApp miniAppName={'参数设置'} rootUrl={rootUrl} menus={menus} />;
}
