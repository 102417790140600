import Api from '../data/API';

//获取所有权限项
export async function getAllPermItem(data: any) {
	return Api.postWithAuth('/api/v2/wuye/get_all_perm', data);
}
//获取所有权限项
export async function getAllMenuPerm(data: any) {
	return Api.postWithAuth('/api/v2/wuye/getAllMenuPerm', data);
}

//获取所有员工
export async function getDepartment(data: any) {
	return Api.postWithAuth('/api/wuye/department_and_person', data);
}

export async function addPermGroupPerms(data: any) {
	return Api.postWithAuth('/api/v2/wuye/add_perm_group_perms', data);
}
