import React, { useEffect, useRef, useState } from 'react';

import { Checkbox, Col, List, Row } from 'antd';
import { findBuilds, findLiveRoom } from '../../../services/ZhuhushenheService';
import VirtualList from 'rc-virtual-list';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CloseOutlined } from '@ant-design/icons';
import { ActionType } from '@ant-design/pro-table';
import { findFeeSubject } from '../../../services/PayCostManagementService';
import DAL from '../../../data/DAL';

interface Params {
	updateCheckCharge: any;
	hidden: boolean;
}
const ZNengCheckedCharge: React.FC<Params> = (props: any) => {
	useEffect(() => {
		getCharges().then();
	}, []);
	const [search, setsearch] = useState('');
	const [charges, setcharges] = useState([]);
	const [current, setcurrent] = useState(1);
	const wHeight = document.documentElement.clientHeight + 25;
	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAll, setCheckAll] = useState(false);
	const [checkList, setcheckList] = useState([]);
	const [chargeTotal, setchargeTotal] = useState(0);
	const [chargeData, setchargeData] = useState([]);
	const getCharges = async () => {
		const res = await findFeeSubject({ state: 'true', search: search });
		if (res.err == 0) {
			const data = res.data || [];
			setcharges([...data]);
			setchargeTotal(res.total);
			setData(data);
		}
	};
	const setData = (data: any) => {
		let list = [];
		const zhouqiList = data.filter((item) => '周期类' == item.type);
		if (zhouqiList.length > 0) {
			list.push({ type: '周期类', children: zhouqiList });
		}
		const yajinLei = data.filter((item) => '押金类' == item.type);
		if (yajinLei.length > 0) {
			list.push({ type: '押金类', children: yajinLei });
		}
		if (!props.hidden) {
			const yibiap = data.filter((item) => '仪表类' == item.type);
			if (yibiap.length > 0) {
				list.push({ type: '仪表类', children: yibiap });
			}
			const linshilei = data.filter((item) => '临时类' == item.type);
			if (linshilei.length > 0) {
				list.push({ type: '临时类', children: linshilei });
			}
			const dingjinlei = data.filter((item) => '定金类' == item.type);
			if (dingjinlei.length > 0) {
				list.push({ type: '定金类', children: dingjinlei });
			}
			const wufeilei = data.filter((item) => !DAL.dal.isNotNull(item.type));
			if (wufeilei.length > 0) {
				list.push({ type: '无类型', children: wufeilei });
			}
		}

		setchargeData(list);
	};
	const onChangeBuilds = (value) => {
		if (value.length == 0) {
			setIndeterminate(false);
			setCheckAll(false);
		} else if (value.length == chargeTotal) {
			setIndeterminate(false);
			setCheckAll(true);
		} else {
			setIndeterminate(true);
			setCheckAll(false);
		}
		setcheckList(value);
		propsUpdate(value);
		console.log('values:::', value);
	};
	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		if (e.target.checked) {
			setcheckList([...charges]);
			propsUpdate([...charges]);
		} else {
			setcheckList([]);
			propsUpdate([]);
		}
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};
	const verifyAll = (checkList) => {
		if (checkList.length == 1) {
			setIndeterminate(false);
			setCheckAll(false);
		} else if (checkList.length == chargeTotal) {
			setIndeterminate(true);
			setCheckAll(false);
		}
	};
	const propsUpdate = (value) => {
		props.updateCheckCharge(value);
	};
	return (
		<>
			<div style={{ display: 'flex' }}>
				<div style={{ minWidth: 813, display: 'flex', width: '100%', flexDirection: 'column' }}>
					<div
						style={{
							height: 39,
							background: '#FAFAFA',
							display: 'flex',
							// flex: 1,
							alignItems: 'center',
						}}
					>
						<div>共 {chargeTotal} 个</div>
						<div style={{ marginLeft: 10 }}>
							<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
								全选
							</Checkbox>
						</div>
					</div>
					<div>
						<Checkbox.Group
							style={{ width: '100%', height: 460, overflowY: 'auto' }}
							value={checkList}
							onChange={(value) => onChangeBuilds(value)}
						>
							{chargeData.map((item: any) => {
								return (
									<>
										<Row style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: 4,
													height: 16,
													background: '#1890FF',
													borderRadius: '10px 10px 10px 10px',
													marginRight: 10,
												}}
											></div>
											<div>{item.type}</div>
										</Row>
										<Row>
											{item.children.map((item1) => {
												return (
													<>
														<Col span={6}>
															<Checkbox value={item1}>{item1.feeName}</Checkbox>
														</Col>
													</>
												);
											})}
										</Row>
									</>
								);
							})}
						</Checkbox.Group>
					</div>
				</div>
				<div
					style={{
						width: 10,
						height: 460,
						background: '#FAFAFA',
					}}
				></div>
				<div style={{ width: 295 }}>
					<div
						style={{
							height: 39,
							background: '#FAFAFA',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div>已选择{checkList.length}</div>
						<a
							onClick={() => {
								setCheckAll(false);
								setIndeterminate(false);
								setcheckList([]);
							}}
							style={{ marginRight: 10 }}
						>
							{' '}
							全部清空
						</a>
					</div>
					<List>
						<VirtualList data={checkList} height={380} itemHeight={47} itemKey="uuid">
							{(item: any, index: number) => (
								<List.Item
									actions={[
										<CloseOutlined
											style={{ width: 10, height: 10 }}
											onClick={() => {
												console.log('index::', index);
												verifyAll(checkList);
												checkList.splice(index, 1);
												propsUpdate([...checkList]);
												setcheckList([...checkList]);
											}}
										/>,
									]}
									key={item.uuid}
								>
									<div>{item.feeName}</div>
								</List.Item>
							)}
						</VirtualList>
					</List>
				</div>
			</div>
		</>
	);
};
export default ZNengCheckedCharge;
