import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import {
	addHouseStyle,
	deleteHouseStyle,
	getHouseStyleList,
	syncRoomItem,
	updateHouseStyle,
} from '../../../services/ZhuhushenheService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Button, Form, Input, message, Modal, Select, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import ListOfItems from './ListOfItems';
const HouseStyleList: React.FC<any> = (props: any) => {
	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const actionRef = useRef<ActionType>();
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);
	const [infoVisible, setinfoVisible] = useState(false)
	const [styleId, setstyleId] = useState<any>(0)
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [dateKey, setdateKey] = useState(new Date().getTime().toString())
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '备注',
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<Space>
					{
						props.type !== 'ItemSetting' && <a
							onClick={() => {
								openUpdateModal(record);
							}}
						>
							编辑
						</a>
					}

					<a
						onClick={() => {
							openInfoModal(record);
						}}
					>
						物品设置
					</a>
					<a
						onClick={() => {
							syncItem(record);
						}}
					>
						更新房间物品
					</a>
					{
						props.type !== 'ItemSetting' && <a
							onClick={() => {
								deleteItem(record);
							}}
						>
							删除
						</a>
					}

				</Space>
			),
		},
	];
	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '提示',
			content: '确定要删除该物品吗？该操作不可恢复！',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteHouseStyle({ id: value.id });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error('删除失败');
				}
			},
		});
	};
	const syncItem = (value) => {
		Modal.confirm({
			title: '提示',
			okType:'danger',
			content: '将“'+value.name+'”风格下物品同步到该风格下的所有房间，如果物品不存在则新增物品，如果物品已经存在则忽略。请谨慎操作！',
			onOk: async () => {
				const res = await syncRoomItem({ houseStyleId: value.id });
				if (res.err === 0) {
					message.success('同步成功');

				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error('同步失败');
				}
			},
		});
	}
	const openInfoModal = (item: any) => {
		setstyleId(item.id)
		setdateKey(new Date().getTime().toString())
		setinfoVisible(true)
	}
	const openUpdateModal = (value: any) => {
		const formData: any = {
			id: value.id,
			name: value.name,
			note: value.note,
		};
		form.setFieldsValue(formData);
		setAdd(false);
		setVisible(true);
	};
	const tableSearchBarRender = () => [
		// <Search
		// 	placeholder="名称"
		// 	onSearch={(value) => {
		// 		setSearch(value);
		// 		if (actionRef.current) {
		// 			actionRef.current.reloadAndRest();
		// 		}
		// 	}}
		// 	enterButton={true}
		// />,
	];
	const openAddModal = () => {
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				name: data.name,
				note: data.note,
			};
			const res = await addHouseStyle(formData);
			if (res.err === 0) {
				message.success('添加成功');
			} else {
				message.success('添加失败');
			}
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				id: data.id,
				name: data.name,
				note: data.note,
			};
			const res = await updateHouseStyle(formData);
			if (res.err === 0) {
				message.success('修改成功');
			} else {
				message.success('修改失败');
			}
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		});
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const tableOperationsBarRender = () => [
		<>
			{
				props.type !== 'ItemSetting' && <Button type="primary" onClick={openAddModal}>
					新增
				</Button>
			}
		</>,
	];
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getHouseStyleList({
						page: params.current,
						size: params.pageSize,
						name: search,
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				visible={visible}
				title={add ? '添加' : '修改'}
				onOk={saveItem}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} name={'id'}>
						<Input />
					</FormItem>
					<FormItem name={'name'} label={'名称'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem name={'note'} label={'备注'}>
						<Input />
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal width={1300} title={"物品设置"} footer={null} visible={infoVisible} onCancel={()=>setinfoVisible(false)}>
				<ListOfItems key={dateKey} houseStyleId={styleId}></ListOfItems>
			</Modal>
		</>
	);
};
export default HouseStyleList;
