import * as React from 'react';

/**
 * 单选控件
 */

interface RadioButtonProps {
	title: string;
	descr: string;
	uuid: string;
	initialValue: string;
	required: string;
	aliasName: string;
	isHide: boolean;
}

class RadioButton extends React.Component<RadioButtonProps> {
	constructor(props: any) {
		super(props);
		this.state = { selectValue: '', radio: {} };
		this.select = this.select.bind(this);
	}

	select(res: any) {
		(this.state as any).radio[res.target.value] = res.target.checked;
		this.setState({
			selectValue: res.target.value,
		});
	}

	render() {
		return (
			<div style={{ paddingBottom: 10 }} hidden={this.props.isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i
							style={{ fontSize: 10 }}
							className={this.props.required === 'required' ? 'icon icon-asterisk' : ''}
						/>
					</span>
					{this.props.title === '' ? '未命名' : this.props.title}
				</div>
				{/*<div style={{color: 'red'}}><i className={this.props.required ? 'icon icon-asterisk' : ''}/>{this.props.required ? '必填' : ''}</div>
				 */}
				<div dangerouslySetInnerHTML={{ __html: this.props.descr }} />
				<input
					className={this.props.required}
					required={true}
					type="text"
					hidden={true}
					value={(this.state as any).selectValue}
					id={this.props.uuid}
					name={this.props.uuid}
				/>
				<div>
					{this.props.initialValue.split(',').map(
						(item: any, index: any) =>
							item !== null && item !== undefined && item !== '' ? (
								item.toString().includes('###') ? (
									<label>
										<input
											type="radio"
											id={index}
											name={this.props.uuid + 'danxuan'}
											onClick={this.select}
											value={item}
										/>{' '}
										{item.toString().split('###')[0]}
									</label>
								) : (
									<label>
										<input
											type="radio"
											id={index}
											name={this.props.uuid + 'danxuan'}
											onClick={this.select}
											value={item}
										/>{' '}
										{item}
									</label>
								)
							) : (
								<label>
									<input
										type="radio"
										id={index}
										name={this.props.uuid + 'danxuan'}
										onClick={this.select}
										value={item}
									/>{' '}
									{item}
								</label>
							)
						// <label>
						//     <input type="radio" id={index} name={this.props.uuid + 'danxuan'}   onInput={this.select} value={item}/> {item}
						// </label>
					)}
				</div>
				{this.props.aliasName === '' ||
				this.props.aliasName === null ||
				this.props.aliasName === 'null' ||
				this.props.aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {this.props.aliasName}
					</text>
				)}
			</div>
		);
	}
}

export default RadioButton;
