import { Form, FormInstance, Input, Select } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormContext } from '../../../components/FormContext';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
interface Params {
	required: Boolean;
}
const ChargeAmountComponents: React.FC<Params> = (props: any) => {
	const FormItem = Form.Item;
	return (
		<>
			<FormItem
				name={'amountBit'}
				label={'金额保留位'}
				rules={[{ required: props.required, message: '请选择金额保留位' }]}
			>
				<Select>
					<Select.Option value={'元'}>元</Select.Option>
					<Select.Option value={'角'}>角</Select.Option>
					<Select.Option value={'分'}>分</Select.Option>
				</Select>
			</FormItem>
			<FormItem
				tooltip="四舍五入（0.125就精确到0.13）、舍位（0.125就精确到0.12）、进位（0.121就精确到0.13）"
				name={'roundingMethod'}
				label={'取整方式'}
				rules={[{ required: props.required, message: '请选择取整方式' }]}
			>
				<Select>
					<Select.Option value={'四舍五入'}>四舍五入</Select.Option>
					<Select.Option value={'舍位'}>舍位</Select.Option>
					<Select.Option value={'进位'}>进位</Select.Option>
				</Select>
			</FormItem>

			<FormItem label="单位名称" name={'unitName'}>
				<Select>
					{getBillTypes().unitNames.map((item) => {
						return (
							<>
								<Select.Option value={item.value}> {item.name} </Select.Option>
							</>
						);
					})}
				</Select>
			</FormItem>
		</>
	);
};
export default ChargeAmountComponents;
