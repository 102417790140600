import Api from '../data/API';

export async function getBlackList(params: any) {
	return Api.postWithAuth('/adminapi/black/getBlackList', params);
}
export async function addBlackList(params: any) {
	return Api.postWithAuth('/adminapi/black/addBlackList', params);
}
export async function deleteBlackList(params: any) {
	return Api.postWithAuth('/adminapi/black/deleteBlackList', params);
}
export async function findList(params: any) {
	return Api.postWithAuth('/adminapi/black/findList', params);
}
export async function updateBlackUser(params: any) {
	return Api.postWithAuth('/adminapi/black/updateBlackUser', params);
}


