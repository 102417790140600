import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Dropdown, Form, Input, Menu, message, Modal, notification, Select, Space, Tag} from "antd";
import {NavLink} from "react-router-dom";
import AuthorizedView from "../../../util/AuthorizedView";
import GSTable from "../../../components/GSTable";
import {ActionType} from "@ant-design/pro-table";
import GsTreeSelect from "../../../components/GsTreeSelect";
import {findDoorList, findDoorRecordList, updateDoorInfo} from "../../../services/DoorService";
import SocketContext from "../../../context/SocketContext";
import {FormInstance} from "antd/es/form";
import CustomRangePicker from "../../../components/CustomRangePicker";
import moment from "moment/moment";

const DoorRecordList: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [search, setSearch] = useState('');
  const socket: any = useContext(SocketContext);
  const[selectType, setSelectType] = useState( 1);
  const [updateForm] = Form.useForm<FormInstance>();
  const [Stime, setStime] = useState(moment().hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:00')
  );
  const [Etime, setEtime] = useState( moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:59'));
  const dateTime = (startTime: any, endTime: any) => {
    setStime(startTime.format('YYYY-MM-DD HH:mm:00'));
    setEtime(endTime.format('YYYY-MM-DD HH:mm:59'));
  };
  const proColumns = [
    {
      title: '名称',
      width: 200,
      dataIndex: 'doorName',
    },
    {
      title: '设备SN',
      width: 100,
      dataIndex: 'doorSn'
    },
    // {
    //   title: '门号',
    //   width: 100,
    //   dataIndex: 'doorNo'
    // },
    {
      title: '类型',
      width: 100,
      dataIndex: 'type'
    },
    {
      title: '卡号',
      width: 100,
      dataIndex: 'cardNo'
    },
    {
      title: '状态',
      width: 150,
      dataIndex: 'status'
    },
    {
      title: '描述',
      width: 100,
      dataIndex: 'msg',
      ellipsis:true,
    },
    {
      title: '创建时间',
      width: 200,
      dataIndex: 'createdTime',
      valueType: 'dateTime'
    }
  ];
  const tableSearchBarRender = () => [
    <CustomRangePicker selectType={selectType} onChange={dateTime} />,
    <Input
      placeholder="名称"
      onChange={(value: any) => {
        setSearch(value.target.value);
      }}
    />,
    <Button type={'primary'} onClick={() => {
      if (actionRef.current) {
        actionRef.current.reloadAndRest();
      }
    }}>查询</Button>
  ];
  const loadList = async (params: any) => {
    let req = {
      page: params.current,
      size: params.pageSize,
      search: search,
      startTime: Stime,
      endTime: Etime
    }
    let rsp = await findDoorRecordList(req);
    if (rsp.err === 0) {
      return {
        data: rsp.data.data,
        total: rsp.data.total
      }
    } else {
      return {
        data: [],
        total: 0
      }
    }
  }
  const updateItem = async () => {
    let data = await updateForm.validateFields();
    console.log('data', data);
    let rsp = await updateDoorInfo(data);
    if (rsp.err === 0) {
      message.success('修改成功');
      actionRef.current.reload()
    } else {
      message.error(rsp.message)
    }
  }
  return <>
    <GSTable
      actionRef={actionRef}
      searchBarRender={tableSearchBarRender()}
      columns={proColumns}
      request={async (params: any, sort: any, filter: any) => {
        return loadList(params);
      }}
      rowKey="id"
    />
  </>
}
export default DoorRecordList;
