import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Image, Input, Modal } from "antd";
import Api from '../../../data/API';
import { Link } from 'react-router-dom';
import GSTable from '../../../components/GSTable';
import {
	qunzuDelTiezi,
	qunzuFindListDongtai,
	tiebaDelTiezi,
	tiebaFindTieziList,
} from '../../../services/PostBarService';
import DAL from '../../../data/DAL';
const LookTieba: React.FC = (props: any) => {
	const msg = require('../../../util/SuspensionMsg');
	const actionRef = useRef<ActionType>();
	const type = DAL.dal.getQueryString('state');
	const uuid = DAL.dal.getQueryString('uuid');
	const [search, setSearch] = useState('');
	const Search = Input.Search;
	const columns = [
		{
			title: '发布日期',
			dataIndex: 'fabuShijian',
			key: 'fabuShijian',
			ellipsis: true,
		},
		{
			title: '发布人',
			key: 'fabuRenyuan',
			dataIndex: 'fabuRenyuan',
		},
		{
			title: '主题',
			key: 'zhuti',
			dataIndex: 'zhuti',
			ellipsis: true,
		},
		{
			title: '图片',
			key: 'tupianUrl',
			dataIndex: 'tupianUrl',
			render: (text: string, record: any) => (
				<span>
					{record.tupianUrlPc === null || record.tupianUrlPc === '' || record.tupianUrlPc === undefined ? (
						''
					) : (
						<Image
							className="homePage"
							src={Api.cdnRootPath + record.tupianUrlPc}
							width={100}
							height={70}
						/>
					)}
				</span>
			),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<Link
								to={`${type === 'tieba' ? '/postbar/wenzhang_info' : '/group/wenzhang_info'}?uuid=${
									record.uuid
								}&state=${type}&wenZhangType=${record.typeUuid}`}
							>
								<a>详情</a>
							</Link>
						</li>
						<li>
							<a
								onClick={() => {
									deleteItem(record);
								}}
							>
								删除
							</a>
						</li>
					</ul>
				</span>
			),
		},
	];

	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '您确认要删除该帖子吗？',
			okType: 'danger',
			onOk: async () => {
				let res;
				if (type === 'tieba') {
					res = await tiebaDelTiezi({ uuid: value.uuid });
				} else {
					res = await qunzuDelTiezi({ uuid: value.uuid });
				}
				if (res) {
					msg.suspensionMsg({ content: res.msg, type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const getData = async (params: any) => {
		let res;
		if (type === 'tieba') {
			res = await tiebaFindTieziList({
				typeUuid: uuid,
				page: params.current,
				Search: search,
				size: params.pageSize,
			});
		} else {
			res = await qunzuFindListDongtai({
				group_uuid: uuid,
				page: params.current,
				Search: search,
				size: params.pageSize,
			});
		}
		if (res.err !== 0) {
			console.log(res.msg);
			return;
		}
		if (type === 'tieba') {
			return {
				data: res.data,
				total: res.count,
				success: true,
			};
		} else {
			return {
				data: res.data,
				total: res.count,
				success: true,
			};
		}
	};

	const tablSearchBarRender = () => [
		<Input
			placeholder="搜索主题"
			onChange={(event) => {
				setSearch(event.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reload();
			}
		}}>查询</Button>
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tablSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					return getData(params);
				}}
			/>
		</div>
	);
};
export default LookTieba;
