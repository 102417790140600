import * as React from 'react';

//import * as Api from '../data/API';
import DAL from '../../data/DAL';
import { Route, Switch, Redirect } from 'react-router-dom';
import CompanyIframe from './CompanyIframe';

class Company extends React.Component {
	deviceId: string = '';
	dal = DAL.dal;

	constructor(props: any) {
		super(props);
		this.state = { ds: [], url: window.location.pathname };
	}

	componentWillMount() {
		console.log('liuCheng -> componentWillMount');
	}
 
	render() {
		return (
			<div>
				<Switch>
					<Route path="/company" component={CompanyIframe} />
					{/*<Redirect to="/company/iframe" />*/}
					<Route render={() => <CompanyIframe />} />
				</Switch>
			</div>
		);
	}
}

export default Company;
