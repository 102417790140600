import React, { useEffect, useRef, useState } from "react";
import {
    getHouseStyle,
    addListOfItems,
    getListOfItemsList,
    updateListOfItems,
    deleteListOfItems,
    getAllItemInfo,
    batchListOfItems,
	batchItemInfo,
	batchRoomItem
} from "../../../services/ZhuhushenheService";
import GSTable from "../../../components/GSTable";
import { ActionType } from "@ant-design/pro-table/lib/typing";
import { Button, Form, Input, InputNumber, message, Modal, Select, Space, Upload } from "antd";
import { FormInstance } from "antd/es/form";
import ProxyForm from "../../../components/ProxyForm";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from 'xlsx';
import moment from "moment";
let myXlsx = require('../../../util/myXlsx');

const ItemsBatch: React.FC = (props:any) => {
    const batchActionRef = useRef<ActionType>();
    const [size, setsize] = useState(0)
    const [errCount, seterrCount] = useState(0)
    const [success, setsuccess] = useState(false)
    const [errVisible, seterrVisible] = useState(false)
    const [errData, seterrData] = useState([])
    const [batchData, setbatchData] = useState([])
    const [content,setContent] = useState("")
    const [confirmLoading, setconfirmLoading] = useState(false)
    const batchColumns = [
		{
			title: '物品编号',
			key: '物品编号',
			dataIndex: '物品编号',
		},
		{
			title: '房间号',
			key: '房间号',
			dataIndex: '房间号',
		},
        {
			title: '数量',
			key: '数量',
			dataIndex: '数量',
		},
		{
			title: '状态',
			key: '状态',
			dataIndex: '状态',
		},
		{
			title: '损坏数量',
			key: '损坏数量',
			dataIndex: '损坏数量',
		},
		{
			title: '缺失数量',
			key: '缺失数量',
			dataIndex: '缺失数量',
		},
    ];
    const errorColumns = [
		{
			title: '物品编号',
			key: '物品编号',
			dataIndex: '物品编号',
		},
		{
			title: '房间号',
			key: '房间号',
			dataIndex: '房间号',
		},
        {
			title: '数量',
			key: '数量',
			dataIndex: '数量',
		},
		{
			title: '状态',
			key: '状态',
			dataIndex: '状态',
		},
		{
			title: '损坏数量',
			key: '损坏数量',
			dataIndex: '损坏数量',
		},
		{
			title: '缺失数量',
			key: '缺失数量',
			dataIndex: '缺失数量',
		},
        {
			title: "错误原因",
			key: "errorMsg",
			dataIndex: "errorMsg",
			render: (text: string) => {
				return <div style={{ color: "red" }}>{text}</div>;
			}
		}

    ]
    const isNull = (string:any) =>{
		if(string===null||string===undefined||string===''){
			return true
		}else{
			return false
		}
	}
     /*导入信息显示*/
     const	analyze = (evv: any) => {
			console.log('上传返回', evv);
			myXlsx.readData(evv, function (res: any) {
				let index = 0
				const size = res.length
				let errData:any = []
				let errCount = 0
				let departData:any = []
				res.map((item:any)=>{
					if(isNull(item.物品编号)){
						item["errorMsg"] = "请填写物品编号";
						errCount++;
						errData.push(item)
						index++
						return
					}
					if(isNull(item.房间号)){
						item["errorMsg"] = "请填写房间号";
						errCount++;
						errData.push(item)
						index++
						return
					}
					if(isNull(item.数量)){
						item["errorMsg"] = "请填写数量";
						errCount++;
						errData.push(item)
						index++
						return
					}
					if(isNull(item.状态)){
						item.状态 = "正常"
					}
					if(!isNull(item.物品编号)){
						item.物品编号 = item.物品编号.toString().replace(/\s*/g,"")
                    }
                    if(!isNull(item.房间号)){
						item.房间号 = item.房间号.toString().replace(/\s*/g,"")
					}
					if(!isNull(item.数量)){
						item.数量 = item.数量.toString().replace(/\s*/g,"")
					}
					if(!isNull(item.状态)){
						item.状态 = item.状态.toString().replace(/\s*/g,"")
					}
					
					if(!isNull(item.损坏数量)){
						item.损坏数量 = item.损坏数量.toString().replace(/\s*/g,"")
					}
					if(!isNull(item.缺失数量)){
						item.缺失数量 = item.缺失数量.toString().replace(/\s*/g,"")
					}
					index++
					return
				})
				if(index === size){
					setsize(size);
					seterrCount(errCount)
					if (errCount > 0) {
						setsuccess(false);
						seterrVisible(true);
						seterrData(errData);
					} else {
						setsuccess(true);
						setbatchData(res);
						batchActionRef.current.reload();
					}
				}

			});
    };
    
    /** 模板下载 */
	const exportBmry = () => {
		window.open(
			'https://pcdn.zp.guosim.com/%E6%88%BF%E9%97%B4%E7%89%A9%E5%93%81%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%8C%89%E6%88%BF%E9%97%B4%E5%8F%B7.xlsx',
			'_blank'
		);
    };
    /*导入数据*/
	const batchImport = async () => {
		if(batchData.length==0){
			message.warning("请先导入数据")
			return
		}
		const data = {
            data: JSON.stringify(batchData),
		}
		if(success){
			setconfirmLoading(true)
			const res = await batchRoomItem(data)
			setconfirmLoading(false)
			if(res.err === 0){
				const size = batchData.length;
				setsize(size);
				const errCount = res.errCount;
				seterrCount(errCount);
				if (errCount > 0) {
					seterrVisible(true);
					seterrData(res.errData);
					setbatchData([]);
					batchActionRef.current.reload();
					message.success("导入" + size + "条" + "失败" + errCount + "条");
				} else {
					setbatchData([]);
					batchActionRef.current.reload();
					message.success("导入" + size + "条，成功" + size + "条");
				}
			}
		}else{
			// message.error(content)
			return
		}
	};
    const getData = () =>{
		return{
			data:batchData
		}
	}
    const tableOperationsBarRender = () =>[
		<Space >
			<Upload showUploadList={false} onChange={analyze} accept={'.xlsx'}>
				<Button>
					<UploadOutlined /> 选择文件
				</Button>
			</Upload>
			<Button type="primary" onClick={exportBmry}>
				模版下载
			</Button>
			<Button type="primary" loading={confirmLoading} onClick={batchImport}>
				开始导入
			</Button>
		</Space>
    ]
    const tableSearchBarRender = () =>[
		<span>{content}</span>

    ]
    const exportData = () => {
		let sheetData = new Array(errData.length + 1);
		let titles: [string?] = [];
		errorColumns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < errData.length; i++) {
			let data: [string?] = [];
			errorColumns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[errData[i][item.dataIndex]]) {
						data.push(item.valueEnum[errData[i][item.dataIndex]].props.children);
					} else {
						data.push("");
					}
				} else if (item.valueType === "dateTime") {
					data.push(moment(errData[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
				} else {
					data.push(errData[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "导入失败数据");
		/* save to file */
		XLSX.writeFile(wb, "导入失败数据.xlsx");
	};
    const errorTableOperationBarRender = () => {
		return [<Space>
			<Button type="primary" onClick={exportData}>
				导出
			</Button>
		</Space>];
	};

    return <>
        <GSTable
				columns={batchColumns}
				actionRef={batchActionRef}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					return getData()
				}}
				options={false}
        />
        <Modal maskClosable={false} destroyOnClose={true} title={"结果"} width={600} footer={null} visible={errVisible}
				   onOk={() => seterrVisible(false)}
				   onCancel={() => {
                    seterrVisible(false);
				   }}>
				<GSTable size={"small"} searchBarRender={tableSearchBarRender()}
						 operationsBarRender={errorTableOperationBarRender()}
						 options={false} request={() => {
					return {
						data: errData
					};
				}} columns={errorColumns}/>
			</Modal>
    </>
}
export default ItemsBatch;
