import React, { useRef, useState, useEffect } from 'react';
import { ActionType } from '@ant-design/pro-table';
import {
	archive,
	batchArchive,
	findContractList,
	getContractPdfUrl,
	shangshangqianContract,
	undoSign,
	getContractTemplateList,
	createContractByTid,
	download,
	deleteContract,
	getContractNo,
	getHasPermContractTemplateList, toBeSignedContractList
} from '../../../services/Contract';
import { getCompanyAccount, getCompanyListByName } from '../../../services/CompanyService';
import { getUserListByName } from '../../../services/UserService';
import GSTable from '../../../components/GSTable';
import { Button, Input, message, Modal, Select, Steps, Row, Col, Form, DatePicker, Space, Tag, Radio, Tooltip } from 'antd';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import AuthorizedView from '../../../util/AuthorizedView';
import moment from 'moment';
import { findProductSms } from '../../../services/notificationService';
import DAL from '../../../data/DAL';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsZip from 'jszip';
const ToBeSignedContractList: React.FC = () => {
	const Search = Input.Search;
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const actionRef = useRef<ActionType>();
	const [status, setStatus] = useState('待签署');
	const [isEvidence, setIsEvidence] = useState('false');
	const [isRevoke, setIsRevoke] = useState('false');
	const [search, setSearch] = useState('');
	const [signatory, setSignatory] = useState('');
	const [signatory1, setSignatory1] = useState({});
	const [loading11, setLoading11] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [current, setCurrent] = useState(0);
	const [templateList, setTemplateList] = useState([]);
	const [currentTemplate, setCurrentTemplate] = useState<any>({});
	const [templateValues, setTemplateValues] = useState<any>({});
	const [contractTitle, setContractTitle] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [contractNo, setContractNo] = useState('');
	const [type, settype] = useState('');
	const [signatoryType, setsignatoryType] = useState('企业');
	const [isPay, setIsPay] = useState('');
	const [signatoryItem, setsignatoryItem] = useState<any>({});
	const [companyList, setCompanyList] = useState([]);
	const [contractType, setcontractType] = useState('');
	const [contractTid, setcontractTid] = useState('');
	const [total, setTotal] = useState(0);
	const [quanXuan, setQuanXuan] = useState(false);
	let timeout: any = null;
	const [form] = Form.useForm();
	useEffect(() => {
		getTemplateList().then();
	}, []);

	const columns = [
		{
			title: '合同编号',
			dataIndex: 'contractNo',
			key: 'contractNo',
		},
		{
			title: '合同名称',
			dataIndex: 'contractName',
			key: 'contractName',
			render: (text: string, record: any) => (
				<span>
				{record.isRevoke ? (
					record.qiniuContract ? (
						<a
							onClick={() => {
								getContractUrl(record);
							}}
						>
							{text}
						</a>
					) : (
						text
					)
				) : (
					<a
						onClick={() => {
							getContractUrl(record);
						}}
					>
						{text}
					</a>
				)}
			</span>
			),
		},

		{
			title: '开始时间',
			dataIndex: 'contractStartTime',
			key: 'contractStartTime',
		},
		{
			title: '结束时间',
			dataIndex: 'contractEndTime',
			key: 'contractEndTime',
		},
		{
			title: '签署人',
			dataIndex: 'contractSignatory',
			key: 'contractSignatory',
			hideInTable: true,
			render: (text: string, record: any) => (
				<span>
					{record.contractSignatory.map((item: string, index: number) => {
						return (
							<div >{item}</div>
						)
					})}
				</span>
			),
		},
		{
			title: '签署状态',
			dataIndex: 'contractSignatoryAndStatus',
			key: 'contractSignatoryAndStatus',
			render: (text: string, record: any) => (
				<span>
					{record.contractSignatoryAndStatus.map((item: string, index: number) => {
						if (item.includes("待签署")) {
							return <div style={{color: 'red'}}>{item}</div>
						}
						return (
							<div style={{color: 'green'}}>{item}</div>
						)
					})}
				</span>
			),
		},
		{
			title: '合同类型',
			dataIndex: 'contractCount',
			key: 'contractCount',
			align: 'center',
		},
		// {
		// 	title: '合同签署人',
		// 	dataIndex: 'contractSignatory',
		// 	key: 'contractSignatory',
		// 	render: (text: string, record: any) => (
		// 		<span>{record.contractSignatory !== undefined ? record.contractSignatory.toString() : ''}</span>
		// 	),
		// },
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<Space>
					{ record.isRevoke ? (
						'已撤销'
					) : (
						<>
							<a
								onClick={() => {
									revokeShowConfirm(record);
								}}
							>
								撤销
							</a>
						</>
					)}

					{
						record.isRevoke ? <a
							onClick={() => {
								openDelete(record);
							}}
						>
							删除
						</a> : <Tooltip overlay={'未撤销无法删除'}><span>删除</span></Tooltip>
					}
					<AuthorizedView needAuthority={'contract_management:download'}>
						<a
							onClick={() => {
								Modal.confirm({
									title: '下载合同',
									content: '您确定要下载该合同吗？',
									onOk: async () => {
										return downloadContact(record.contractNo);
									},
								});
							}}
						>
							下载
						</a>
					</AuthorizedView>
				</Space>
			),
		},
	];
	const cundang = (value: any) => {
		Modal.confirm({
			title: '您确定要将此合同存档吗？',
			onOk() {
				cundangOk(value);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const openDelete = (item: any) => {
		if (item.isRevoke) {
			Modal.confirm({
				title: '您确定要删除此合同吗？',
				onOk() {
					deleteItem(item);
				},
				onCancel() {
					console.log('Cancel');
				},
			});
		} else {
			message.warning("请先撤销合同")
		}

	};
	const deleteItem = async (item) => {
		const res = await deleteContract({ contractNo: item.contractNo });
		console.log('res.err', res);
		if (res.err === 0) {
			message.success('删除成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error('删除失败');
		}
	};

	//存档
	const cundangOk = async (value: any) => {
		const res = await archive({
			contractNo: value.contractNo,
		});
		console.log(' 获得合同存证结果', res);
		if (res.err === 0) {
			message.success(' 存档成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
	};

	const evidenceShowConfirm = (value: any) => {
		Modal.confirm({
			title: ' 您确定要将此合同锁定吗？',
			onOk() {
				evidence(value);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const evidence = async (value: any) => {
		const res = await shangshangqianContract({
			contractNo: value.contractNo,
		});
		console.log(' 获得合同存证结果', res);
		if (res.err === 0) {
			message.success('锁定成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
	};
	const revokeShowConfirm = (value: any) => {
		Modal.confirm({
			title: ' 您确定要撤销此合同吗？',
			onOk() {
				revoke(value);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const revoke = async (value: any) => {
		const res = await undoSign({
			contractNo: value.contractNo,
		});
		console.log(' 获得合同撤销结果', res.err);
		if (res.err === 0 || res.err === '0') {
			message.success('撤销成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
	};
	const getContractUrl = async (record: any) => {
		console.log(' 合同内容', record);
		if (record.contractStatus === '待签署') {
			message.warning(' 该合同暂未签署完成');
			return;
		}
		const res = await getContractPdfUrl({ contractNo: record.contractNo });
		console.log(' 获得合同路径', res.err);
		if (res.err === 0) {
			message.success(res.msg);
			let url = res.data.url;
			window.open(url, '_blank');
		} else {
			message.error(res.msg);
		}
		return false;
	};

	const rowSelection = {
		fixed: true,
		preserveSelectedRowKeys: true,
		selectedRowKeys: selectedRowKeys,
		selections: [{
			key: 'currentPage',
			text: '全选当前页面',
			onSelect: (changableRowKeys: any) => {
				setQuanXuan(false);
				setSelectedRowKeys(changableRowKeys);
			}
		}, {
			key: '"SELECT_ALL"',
			text: '全选全部页面',
			onSelect: (changableRowKeys: any) => {
				setQuanXuan(true);
				setSelectedRowKeys(changableRowKeys);
			}
		}],
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			console.log('变化：：：：', selectedRowKeys);
			setQuanXuan(false);
			setSelectedRowKeys(selectedRowKeys);
		}
	};
	const tableSearchBarRender = () => [
		<Select
			style={{ width: 150 }}
			maxTagCount={1}
			showSearch={true}
			placeholder={'请选择合同模板'}
			mode={'multiple'}
			allowClear={true}
			optionFilterProp={'children'}
			onChange={(e: any) => {
				setcontractTid(e.toString());
			}}
		>
			{templateList.map((item) => {
				return (
					<>
						<Select.Option value={item.templateId}>{item.templateName}</Select.Option>
					</>
				);
			})}
		</Select>,

		<Select
			style={{ width: 100 }}
			defaultValue={''}
			onChange={(e: any) => {
				setcontractType(e);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			<Select.Option value={''}>合同类型</Select.Option>
			<Select.Option value={'个人'}>个人</Select.Option>
			<Select.Option value={'公司'}>公司</Select.Option>
		</Select>,

		<Input
			placeholder={' 合同名称或编号'}
			onInput={(value: any) => {
				setSearch(value.target.value);
			}}
		/>,
		<Input
			placeholder=" 公司名称或签署人姓名"
			onChange={(event) => {
				setSignatory(event.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];
	const cundang1 = (selectedRowKeys: any) => {
		if (selectedRowKeys.length > 0) {
			Modal.confirm({
				title: ' 您确定要将此合同存档吗？',
				onOk:async() => {
					if (quanXuan) {
						setLoading11(true);
						const res = await findContractList({
							search: search,
							status: status,
							isEvidence: isEvidence,
							isRevoke: isRevoke,
							signatory: signatory,
							isPay: isPay,
							contractType: contractType,
							tid: contractTid,
							archive: '上上签',
						});
						if (res.err == 0) {
							const data = res.data || []
							let contractNos:any = []
							data.forEach(element => {
								contractNos.push(element.contractNo)
							});
							cundangOk1(contractNos);
						} else {
							setLoading11(false);
							message.error(res.msg);
						}
					} else {
						setLoading11(true);

						cundangOk1(selectedRowKeys);
					}
				},
				onCancel() {
					console.log('Cancel');
				},
			});
		} else {
			message.warning(' 请先勾选');
		}
	};
	const tableOperationsBarRender = () => [
		<Button type={'primary'} loading={loading11} onClick={createdContract}>
			发起合同
		</Button>,
	];
	const createdContract = () => {
		setModalVisible(true);
	};
	const cundangOk1 = async (selectedRowKeys) => {
		const res = await batchArchive({
			ids: selectedRowKeys.join(','),
		});
		console.log('获得合同存证结果', res);
		if (res.err === 0) {
			setLoading11(false);
			message.success(' 存档成功');
			setSelectedRowKeys([]);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			setLoading11(false);
			message.error(res.msg);
		}
	};

	const renderSelectTemplate = () => {
		return (
			<>
				<Form.Item label={' 选择模板'} rules={[{ required: true, message: ' 请选择模板' }]} name={'templateId'}>
					<Select
						onChange={async (value) => {
							const template = templateList.find((item) => item.templateId == value);
							const res = await getContractNo({ tid: template.templateId });
							const data: any = {
								title: template.templateName,
								type: template.contractCount,
							};
							if (res.err === 0) {
								data.contractNo = res.data;
							}
							form.setFieldsValue(data);
						}}
					>
						{templateList.map((item: any, index: number) => {
							return (
								<Select.Option key={index} value={item.templateId}>
									{item.templateName}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={' 合同名称'} rules={[{ required: true, message: ' 请选择合同名称' }]} name={'title'}>
					<Input />
				</Form.Item>
				<Form.Item label={' 合同类型'} rules={[{ required: true, message: ' 请选择合同类型' }]} name={'type'}>
					<Select>
						<Select.Option key={' 二方协议'} value={' 二方协议'}>
							二方协议
						</Select.Option>
						<Select.Option key={' 三方协议'} value={' 三方协议'}>
							三方协议
						</Select.Option>
						<Select.Option key={' 四方协议'} value={' 四方协议'}>
							四方协议
						</Select.Option>
						<Select.Option key={' 承诺书'} value={' 承诺书'}>
							承诺书
						</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item  label={' 合同号'} rules={[{ required: true, message: ' 请选择合同号' }]} name={'contractNo'}>
					<Input disabled />
				</Form.Item>
				<Form.Item
					label={' 合同开始时间'}
					rules={[{ required: true, message: ' 请选择合同开始时间' }]}
					name={'startTime'}
				>
					<DatePicker />
				</Form.Item>
				<Form.Item
					label={' 合同结束时间'}
					rules={[{ required: true, message: ' 请选择合同结束时间' }]}
					name={'endTime'}
				>
					<DatePicker />
				</Form.Item>
			</>
		);
	};

	const renderTemplateValue = () => {
		return JSON.parse(currentTemplate.templateValues || []).map((item: any) => {
			return (
				<Form.Item
					label={item.label}
					name={item.label}
					rules={[{ required: true, message: `请填写${item.label}` }]}
				>
					<Input />
				</Form.Item>
			);
		});
	};

	const renderTemplateSignatory = () => {
		console.log("currentTemplate",currentTemplate);

		if ('二方协议' == currentTemplate.contractCount) {
			if (currentTemplate.templateType == '公司和个人') {
				return (
					<>
						<Form.Item label={'类型'} initialValue={'企业'} name={'signatoryType'}>
							<Radio.Group
								defaultValue={'企业'}
								onChange={(e) => {
									setsignatoryType(e.target.value);
									JSON.parse(currentTemplate.signatory || []).map((sign) => {
										const data = {};
										data[sign.param] = '';
										form.setFieldsValue(data);
									});
								}}
							>
								<Radio value={'企业'}>企业</Radio>
								<Radio value={'个人'}>个人</Radio>
							</Radio.Group>
						</Form.Item>
						{JSON.parse(currentTemplate.signatory || []).map((item: any) => {
							if (item.type === '本公司') {
								return <></>;
							}
							return (
								<Form.Item
									hidden={signatoryType != item.type}
									label={item.name}
									name={item.param}
									rules={[{ required: signatoryType == item.type, message: `请填写${item.name}` }]}
								>
									<Select
										showSearch={true}
										onSearch={(e: string) => handleSearch(item.type, e)}
										defaultActiveFirstOption={false}
										showArrow={false}
										onChange={(value) => {
											JSON.parse(currentTemplate.signatory || []).map((sign) => {
												if (sign.param != item.param) {
													console.log('param', sign.param, item.param);
													const data = {};
													data[sign.param] = '';
													form.setFieldsValue(data);
												}
											});
											if (item.type == '企业') {
												setsignatoryItem(companyList.find((item) => item.uuid === value));
											} else {
												setsignatoryItem(companyList.find((item) => item.userUuid === value));
											}
										}}
										filterOption={false}
									>
										{companyList.map((data: any) => {
											if (item.type === '企业') {
												return (
													<Select.Option value={data.uuid} key={data.uuid}>
														{data.name}
													</Select.Option>
												);
											} else {
												return (
													<Select.Option value={data.userUuid} key={data.userUuid}>
														{data.realName}-{data.loginId}
													</Select.Option>
												);
											}
										})}
									</Select>
								</Form.Item>
							);
						})}
					</>
				);
			} else {
				return JSON.parse(currentTemplate.signatory || []).map((item: any) => {
					if (item.type === '本公司') {
						return <></>;
					}
					return (
						<Form.Item
							label={item.name}
							name={item.param}
							rules={[{ required: true, message: `请填写${item.name}` }]}
						>
							<Select
								showSearch={true}
								onSearch={(e: string) => handleSearch(item.type, e)}
								defaultActiveFirstOption={false}
								showArrow={false}
								filterOption={false}
								onChange={(value) => {
									if (item.type == '企业') {
										setsignatoryItem(companyList.find((item) => item.uuid === value));
									} else {
										setsignatoryItem(companyList.find((item) => item.userUuid === value));
									}
								}}
							>
								{companyList.map((data: any) => {
									if (item.type === '企业') {
										return (
											<Select.Option value={data.uuid} key={data.uuid}>
												{data.name}
											</Select.Option>
										);
									} else {
										return (
											<Select.Option value={data.userUuid} key={data.userUuid}>
												{data.realName}-{data.loginId}
											</Select.Option>
										);
									}
								})}
							</Select>
						</Form.Item>
					);
				});
			}
		} else {
			return JSON.parse(currentTemplate.signatory || []).map((item: any) => {
				if (item.type === '本公司') {
					return <></>;
				}
				return (
					<Form.Item
						label={item.name}
						name={item.param}
						rules={[{ required: true, message: `请填写${item.name}` }]}
					>
						<Select
							showSearch={true}
							onSearch={(e: string) => handleSearch(item.type, e)}
							defaultActiveFirstOption={false}
							showArrow={false}
							filterOption={false}
							onChange={(value) => {
								if (item.type == '企业') {
									setsignatoryItem(companyList.find((item) => item.uuid === value));
								} else {
									setsignatoryItem(companyList.find((item) => item.userUuid === value));
								}
							}}
						>
							{companyList.map((data: any) => {
								if (item.type === '企业') {
									return (
										<Select.Option value={data.uuid} key={data.uuid}>
											{data.name}
										</Select.Option>
									);
								} else {
									return (
										<Select.Option value={data.userUuid} key={data.userUuid}>
											{data.realName}-{data.loginId}
										</Select.Option>
									);
								}
							})}
						</Select>
					</Form.Item>
				);
			});
		}
	};

	const steps = [
		{
			title: '选择合同模板',
			content: renderSelectTemplate,
		},
		{
			title: '签署人',
			content: renderTemplateSignatory,
		},
		{
			title: '合同参数',
			content: renderTemplateValue,
		},
	];
	//获取模板列表
	const getTemplateList = async () => {
		let rsp = await getHasPermContractTemplateList({});
		if (rsp.err === 0) {
			setTemplateList(rsp.data);
		} else {
			message.error(rsp.msg);
		}
	};

	//创建合同
	const createContract = async () => {
		let param = await form.validateFields();
		console.log(' param::::', param);
		setLoading11(true);
		let data: any = {
			templateId: currentTemplate.templateId,
			templateValues: JSON.stringify(param),
			signatory: JSON.stringify(signatory1),
			title: contractTitle,
			startTime: startTime,
			endTime: endTime,
			contractNo: contractNo,
			type: type,
		};
		if (currentTemplate.templateType == '公司和个人') {
			if (signatoryType == '个人') {
				data.contractType = '个人';
			} else {
				data.contractType = '公司';
			}
		} else {
			data.contractType = currentTemplate.templateType;
		}

		let rsp = await createContractByTid(data);
		setLoading11(false);
		if (rsp.err === 0) {
			setCurrent(0);
			setSignatory1({});
			form.resetFields();
			setStartTime('');
			setEndTime('');
			setsignatoryType('企业');
			settype('');
			setsignatoryItem({});
			setCompanyList([]);
			setCurrentTemplate({});

			message.success('成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
			setModalVisible(false);
		} else {
			message.error(rsp.msg);
		}
	};

	const loadCompayList = async (name: string) => {
		let rsp = await getCompanyListByName({ name: name });

		if (rsp.err === 0) {
			console.log('companyList::', rsp.data);

			setCompanyList(rsp.data);
		} else {
			setCompanyList([]);
		}
	};
	const loadUserList = async (name: string) => {
		let rsp = await getUserListByName({ search: name });
		if (rsp.err === 0) {
			console.log('companyList::', rsp.data);

			setCompanyList(rsp.data);
		} else {
			setCompanyList([]);
		}
	};

	const handleSearch = async (type: string, value: any) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		timeout = setTimeout(async () => {
			if (value) {
				if (type === '企业') {
					return loadCompayList(value);
				} else {
					return loadUserList(value);
				}
			} else {
				setCompanyList([]);
			}
		}, 1000);
	};

	const downloadContact = async (contractNo: string) => {
		let data = {
			contractNo: contractNo,
		};
		let res = await download(data);
		if (res.size === 0) {
			message.error('下载失败');
			return;
		}
		const blob = new Blob([res], {
			type: 'application/pdf;chartset=UTF-8',
		}); // 通过返回的流数据 手动构建blob 流
		const reader = new FileReader();
		reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签的href （转换base64还可用 window.atob ，未实验过）
		reader.onload = (e: any) => {
			// 转换完成，创建一个a标签用于下载
			const a = document.createElement('a');
			a.download = decodeURIComponent(contractNo) + '.pdf'; // 构建 下载的文件名称以及下载的文件格式（可通过传值输入）
			if (typeof e.target.result === 'string') {
				a.href = e.target.result;
			}
			a.click();
		};
	};
	const batchDownloadHt = async (selectedRowKeys: [any?]) => {
		if (quanXuan) {
			setLoading11(true);
			const res = await findContractList({
				search: search,
				status: status,
				isEvidence: isEvidence,
				isRevoke: isRevoke,
				signatory: signatory,
				isPay: isPay,
				contractType: contractType,
				tid: contractTid,
				archive: '上上签',
			});
			if (res.err == 0) {
				const data = res.data || []
				let contractNos = []
				data.forEach(element => {
					contractNos.push(element.contractNo)
				});
				batchDownload(contractNos)
			} else {
				setLoading11(false);
				message.error(res.msg);
			}
		} else {
			setLoading11(true);
			batchDownload(selectedRowKeys)
		}

	}
	const batchDownload = (selectedRowKeys: any[]) => {
		let allTotal = selectedRowKeys.length
		let count = 0;
		const zip = new jsZip();
		selectedRowKeys.map(async (item) => {
			let data = {
				contractNo: item,
			};
			let res = await download(data);
			if (res.size !== 0) {
				const blob = new Blob([res], {
					type: 'application/pdf;chartset=UTF-8',
				}); // 通过返回的流数据 手动构建blob 流
				zip.file(item + '.pdf', blob);
			}
			count = count + 1
			console.log("count:::", count);
			if (count == allTotal) {
				setLoading11(false)
				zip.generateAsync({
					type: 'blob',
				}).then((res) => {
					message.success('下载成功');
					saveAs(res, '合同文件.zip');
				});
			}
		});

	};

	const exprotData = async (selectedRows: [any?]) => {
		if (quanXuan) {
			setLoading11(true)
			const res = await toBeSignedContractList({
				search: search,
				status: status,
				isEvidence: isEvidence,
				isRevoke: isRevoke,
				signatory: signatory,
				isPay: isPay,
				contractType: contractType,
				tid: contractTid,
				archive: '上上签'
			});
			setLoading11(false)
			if (res.err == 0) {
				setExprotData(res.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
	};
	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: any = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else if (item.dataIndex === 'contractSignatoryAndStatus') {
					data.push(selectedRows[i][item.dataIndex].toString());
				}else if (item.dataIndex === 'contractSignatory'){
					data.push(selectedRows[i][item.dataIndex].toString());
				}else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '待签署合同列表');
		/* save to file */
		XLSX.writeFile(wb, '待签署合同列表.xlsx');
	};

	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				options={{ density: false }}
				rowKey="contractNo"
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanXuan ? total : selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
										if (quanXuan) {
											setSelectedRowKeys([]);
										}
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={16}>
							<AuthorizedView needAuthority={'contract_management:download'}>
								<a onClick={() => batchDownloadHt(selectedRowKeys)}>下载</a>
							</AuthorizedView>
							{/* <a onClick={() => cundang1(selectedRowKeys)}>存档</a> */}
							<a  onClick={() => exprotData(selectedRows)}>导出</a>
						</Space>
					);
				}}
				rowSelection={rowSelection}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await toBeSignedContractList({
						search: search,
						status: status,
						isEvidence: isEvidence,
						isRevoke: isRevoke,
						page: params.current,
						size: params.pageSize,
						signatory: signatory,
						isPay: isPay,
						contractType: contractType,
						tid: contractTid,
						archive: '上上签',
					});
					if (quanXuan) {
						let uuids: any = [];
						res.data.map((item: any) => {
							uuids.push(item.contractNo);
						});
						setSelectedRowKeys(uuids);
					}
					setTotal(res.count);
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title={'发起合同'}
				width={1100}
				visible={modalVisible}
				onCancel={() => setModalVisible(false)}
				onOk={() => setModalVisible(false)}
				footer={null}
			>
				<Steps current={current}>
					{steps.map((item) => (
						<Steps.Step key={item.title} title={item.title} />
					))}
				</Steps>
				<Form labelCol={{ span: 8 }} form={form} preserve={true}>
					<div className="steps-content">{steps[current].content()}</div>
				</Form>
				<div className="steps-action">
					{current > 0 && <Button onClick={() => setCurrent(current - 1)}>上一步</Button>}
					{current < steps.length - 1 && (
						<Button
							style={{ marginLeft: 8 }}
							type="primary"
							onClick={() => {
								form.validateFields().then((data: any) => {
									if (current === 0) {
										const template = templateList.find((item: any) => {
											return item.templateId === data.templateId;
										});
										console.log('template::', template);

										setCurrentTemplate(template);
										setContractTitle(data.title);
										settype(data.type);
										setContractNo(data.contractNo);
										setStartTime(moment(data.startTime).format('YYYY-MM-DD'));
										setEndTime(moment(data.endTime).format('YYYY-MM-DD'));
									}
									if (current === 1) {
										console.log('signItem', signatoryItem);
										console.log('template', currentTemplate);
										const templatValues = JSON.parse(currentTemplate.templateValues || []);
										console.log('templatValues', templatValues);

										setSignatory1(data);
										const formData = {};
										templatValues.map(async (item) => {
											if ('contractStartTime' == item.name) {
												formData[item.label] = startTime;
											} else if ('contractEndTime' == item.name) {
												formData[item.label] = endTime;
											} else if ('contractNo' == item.name) {
												formData[item.label] = contractNo;
											} else if ('creditCode' == item.name) {
												formData[item.label] = signatoryItem.creditCode;
											} else if ('userCompanyName' == item.name) {
												formData[item.label] = signatoryItem.name;
											} else if ('userCompanyRegisterAddress' == item.name) {
												formData[item.label] = signatoryItem.registerAddress;
											} else if ('userCompanyRegisterAddress' == item.name) {
												formData[item.label] = signatoryItem.registerAddress;
											} else if ('companyMethodPerson' == item.name) {
												formData[item.label] = signatoryItem.methodPerson;
											} else if ('signName' == item.name) {
												if (DAL.dal.isNotNull(signatoryItem.name)) {
													formData[item.label] = signatoryItem.name;
												} else {
													formData[item.label] = signatoryItem.realName;
												}
											} else if ('userName' == item.name) {
												if (DAL.dal.isNotNull(signatoryItem.methodPerson)) {
													formData[item.label] = signatoryItem.methodPerson;
												} else {
													formData[item.label] = signatoryItem.realName;
												}
											} else if ('userPhone' == item.name) {
												if (DAL.dal.isNotNull(signatoryItem.methodPerson)) {
													const res = await getCompanyAccount({ companyUuid: signatoryItem.uuid })
													if (res.err == 0) {
														console.log("companyAccount:::", res,res.data.loginId);
														const data = res.data || {}
														formData[item.label] = data.loginId;
														form.setFieldsValue(formData);
													}
												} else {
													formData[item.label] = signatoryItem.loginId;
												}
											}
										});
										console.log("formData::",formData);

										form.setFieldsValue(formData);
									}
									if (current === 2) {
										// setTemplateValues(data);
									}
									setCurrent(current + 1);
								});
							}}
						>
							下一步
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button style={{ marginLeft: 8 }} loading={loading11} type="primary" onClick={createContract}>
							创建
						</Button>
					)}
				</div>
			</Modal>
		</>
	);
};
export default ToBeSignedContractList;
