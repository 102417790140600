import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import {
	getZhuHuReportFormList,
	spotCheck,
	unfreezeMapping,
	freezeMapping,
	getReqortFormsBatch,
	findBuilds,
	addHouseholdVerification,
	findhouseholdVerification,
} from '../../../services/ZhuhushenheService';
import { Button, Col, DatePicker, Input, InputNumber, message, Modal, Row, Select, Table } from 'antd';
import { ActionType } from '@ant-design/pro-table';

let moment = require('moment');
var msg = require('../../../util/SuspensionMsg');
const Option = Select.Option;
const Search = Input.Search;
const { RangePicker } = DatePicker;
const confirm = Modal.confirm;
const ReportForms: React.FC = () => {
	useEffect(() => {
		getBuilds();
		findReportFormsBatchList();
	}, []);
	const sheheColumns = [
		{
			title: '日期',
			key: 'checkDate',
			dataIndex: 'checkDate',
		},
		{
			title: '记录',
			key: 'content',
			dataIndex: 'content',
		},
	];
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [buildUuid, setBuildUuid] = useState('');
	const [builds, setBuilds] = useState([]);
	const [batchList, setBatchList] = useState([]);
	const [batch, setBatch] = useState('');
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [visible, setVisible] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [startTime, setStartTime] = useState('');
	const [deadline, setDeadline] = useState('');
	const [limited, setLimited] = useState('');
	const [Verification, setVerification] = useState('');
	const [addModalvisible, setAddModalvisible] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [Info, setInfo] = useState([]);
	const [Verificationcontent, setVerificationcontent] = useState('');
	const columns = [
		{
			title: '房间位置',
			dataIndex: 'roomnumber',
			key: 'roomnumber',
		},
		{
			title: '手机号',
			dataIndex: 'phoneno',
			key: 'phoneno',
		},
		{
			title: '姓名',
			dataIndex: 'householdname',
			key: 'householdname',
		},
		{
			title: '入住日期',
			key: 'checkintime',
			dataIndex: 'checkintime',
			render: (text: any, record: any) => moment(text).format('YYYY-MM-DD'),
		},
		{
			title: '总天数',
			key: 'checkinnum',
			dataIndex: 'checkinnum',
		},
		{
			title: '90天居住率',
			key: 'occupancyrate',
			dataIndex: 'occupancyrate',
		},
		{
			title: '整体居住率',
			key: 'overalloccupancyrate',
			dataIndex: 'overalloccupancyrate',
		},
		{
			title: '人脸核查异常',
			key: 'faceshehefail',
			dataIndex: 'faceshehefail',
		},
		{
			title: '操作',
			key: 'action',
			dataIndex: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							{record.isavailable ? (
								<a
									onClick={() => {
										FreezeMappingItem(record.key, record.userid);
									}}
								>
									冻结
								</a>
							) : (
								<a
									onClick={() => {
										unFreezeMappingItem(record.key, record.userid);
									}}
								>
									解除冻结
								</a>
							)}
						</li>
						<li>
							<a
								onClick={() => {
									VerificationItem(record);
								}}
							>
								核查
							</a>
						</li>
					</ul>
				</span>
			),
		},
	];
	const VerificationItem = async (ev: any) => {
		console.log('ev', ev);
		setVerification(ev.householduuid);
		setAddModalvisible(true);
		getVerificationList(ev.householduuid);
	};
	const getVerificationList = async (uuid: string) => {
		console.log('uuid', uuid);
		setTableLoading(true);
		const res = await findhouseholdVerification({
			uuid: uuid,
		});
		console.log('', res);
		setTableLoading(false);
		if (res.err === 0) {
			setInfo(res.data);
		} else {
			return;
		}
	};
	//冻结钥匙
	const FreezeMappingItem = (deviceId: string, email: string) => {
		confirm({
			title: '您确定要冻结该钥匙吗？',
			onOk: async () => {
				const res = await freezeMapping({
					deviceId: deviceId,
					userId: email,
				});

				if (res.err === 0) {
					if (actionRef.current) {
						actionRef.current.reload();
					}
					message.success('钥匙冻结成功');
				} else {
					message.error('钥匙冻结失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const unFreezeMappingItem = (deviceId: string, loginId: string) => {
		confirm({
			title: '您确定要解除冻结该钥匙吗？',
			onOk: async () => {
				const res = await unfreezeMapping({
					deviceId: deviceId,
					userId: loginId,
				});
				if (res.err === 0) {
					if (actionRef.current) {
						actionRef.current.reload();
					}
					msg.suspensionMsg({ content: '钥匙解除冻结成功', type: 'success' });
				} else {
					msg.suspensionMsg({ content: '钥匙解除冻结失败', type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	/*获得楼栋列表*/
	const getBuilds = async () => {
		const res = await findBuilds({});
		if (res.err === 0) {
			setBuilds(res.data);
		}
	};
	//获取异常批次列表
	const findReportFormsBatchList = async () => {
		const res = await getReqortFormsBatch({});
		if (res.err === 0) {
			console.log('获得异常批次列表', res);
			setBatchList(res.data);
		}
	};

	const tableSearchBarRender = () => [
		<Select
			showSearch={true}
			style={{ width: 200 }}
			onChange={(value: any) => {
				setBatch(value);
			}}
			optionFilterProp="children"
			placeholder="选择批次"
		>
			{batchList.map((batch: any) => (
				<Option key={batch} value={batch}>
					{batch}
				</Option>
			))}
		</Select>,
		<Select
			showSearch={true}
			style={{ width: 200 }}
			onChange={(text: any) => {
				setBuildUuid(text);
			}}
			optionFilterProp="children"
			placeholder="请选择楼栋"
			defaultValue={'全部'}
		>
			<Option value={''} key={''}>
				全部
			</Option>
			{builds.map((build: any) => (
				<Option key={build.uuid} value={build.uuid}>
					{build.buildName}
				</Option>
			))}
		</Select>,
		<Search
			placeholder="搜索"
			onSearch={(value) => {
				setSearch(value);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
			enterButton={true}
		/>,
	];
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={showModal}>
			生成抽查
		</Button>,
	];
	const showModal = () => {
		if (selectedRowKeys.length < 1) {
			// message.error('请选择');
			msg.suspensionMsg({ content: '请选择', type: 'danger' });
			return;
		}
		setVisible(true);
	};
	const onSelectChange = (selectedRowKeys: any) => {
		console.log('selectedRowKeys changed: ', selectedRowKeys);
		setSelectedRowKeys(selectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys: selectedRowKeys,
		preserveSelectedRowKeys: true,
		onChange: onSelectChange,
	};
	const getDeadline = (date: any, dateString: any) => {
		console.log('deadline', dateString);
		setStartTime(dateString[0]);
		setDeadline(dateString[1]);
	};
	const saveSpotCheck = async () => {
		if (selectedRowKeys.length < 1) {
			msg.suspensionMsg({ content: '请选择', type: 'danger' });
			return;
		}
		if (startTime === null || startTime === undefined || startTime === '') {
			msg.suspensionMsg({ content: '请输入开始时间', type: 'danger' });
			return;
		}
		if (deadline === null || deadline === undefined || deadline === '') {
			msg.suspensionMsg({ content: '请选择截止时间', type: 'danger' });
			return;
		}
		if (limited === null || limited === undefined || limited === '') {
			msg.suspensionMsg({ content: '请输入限时分钟', type: 'danger' });
			return;
		}
		const res = await spotCheck({
			selectedRowKeys: selectedRowKeys.toString(),
			startTime: startTime,
			deadline: deadline,
			limited: limited,
		});
		console.log('抽查', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '抽查成功', type: 'success' });
			setVisible(false);
			setSelectedRowKeys([]);
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'error' });
		}
		// });
	};
	const VerificationClick = async () => {
		const res = await addHouseholdVerification({
			content: Verificationcontent,
			uuid: Verification,
		});
		console.log('', res);
		if (res.err === 0) {
			message.success('检查成功');
			getVerificationList(Verification);
		} else {
			return;
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				selectedRowKeys={selectedRowKeys}
				rowSelection={rowSelection}
				tableAlertRender={false}
				rowKey="key"
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await getZhuHuReportFormList({
						page: params.current,
						size: params.pageSize,
						search: search,
						batch: batch,
						buildUuid: buildUuid,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			{/*生成抽查*/}
			<Modal
				width={600}
				visible={visible}
				title="生成抽查"
				confirmLoading={confirmLoading}
				onOk={saveSpotCheck}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<div className="antRowCol">
					<Row className="RowCol" style={{ textAlign: 'center', lineHeight: '27px' }}>
						<Col span={5} offset={2}>
							抽查时间：
						</Col>
						<Col span={14} style={{ textAlign: 'left' }}>
							<RangePicker
								showTime={{ format: 'HH:mm:ss' }}
								format="YYYY-MM-DD HH:mm:ss"
								placeholder={['生效时间', '截止时间']}
								onChange={getDeadline}
							/>
						</Col>
					</Row>
					<Row className="RowCol" style={{ textAlign: 'center', lineHeight: '27px' }}>
						<Col span={5} offset={2}>
							限时提交时间：
						</Col>
						<Col span={14} style={{ textAlign: 'left' }}>
							<InputNumber
								min={1}
								placeholder={'分钟'}
								onChange={(value: any) => {
									setLimited(value);
								}}
							/>
						</Col>
					</Row>
				</div>
			</Modal>
			{/*核查*/}
			<Modal
				title="核查"
				visible={addModalvisible}
				onCancel={() => {
					setAddModalvisible(false);
				}}
				onOk={VerificationClick}
				okText={'添加'}
			>
				<Row gutter={8} justify="center" align="middle">
					<Col span={4}>核查内容：</Col>
					<Col span={10}>
						<Input
							onInput={(ev: any) => {
								setVerificationcontent(ev.target.value);
							}}
							placeholder="请输入核查内容"
						/>
					</Col>
				</Row>
				<br />
				<Row gutter={8} justify="center">
					<Col span={4}>历史记录：</Col>
					<Col span={10}>
						<Table size={'small'} loading={tableLoading} dataSource={Info} columns={sheheColumns} />
					</Col>
				</Row>
			</Modal>
		</>
	);
};
export default ReportForms;
