import * as React from 'react';
import DAL from '../../data/DAL';

import { Route, Switch } from 'react-router-dom';
import QuestionnaireData from './QuestionnaireData';

class QuestionnaireManger extends React.Component {
	deviceId: string = '';
	dal = DAL.dal;

	constructor(props: any) {
		super(props);
		this.getUuid = this.getUuid.bind(this);
	}

	getName() {
		var page = window.location.search;
		var c = page.split('&');
		console.log('name' + decodeURIComponent(c[1]));
		return decodeURIComponent(c[1].split('=')[1]);
	}
	getUuid() {
		var page = window.location.search;
		var c = page.split('&');
		return c[0].split('=')[1];
	}
	render() {
		return (
			<div>
				<div>
					<Switch>
						<Route path="/questionnaire/questionnaire_data" component={QuestionnaireData} />
						<Route render={() => <QuestionnaireData />} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default QuestionnaireManger;
