import * as React from 'react';
import { Column } from '@ant-design/charts';
import { useContext } from 'react';
import { ColumnChartContext } from '../pages/controlPlatform/CurrentPhoneBrandChart';

interface Params { 
	theme?:string
}
const ColumnChart: React.FC<Params> = (props: any) => {
	const data = useContext(ColumnChartContext);

	const config = {
		data: data,
		xField: 'x',
		yField: 'y',
		theme: 'dark' == props.theme ? 'dark':'default',
		label: {
			position: 'middle',
			style: {
				fill: '#FFFFFF',
				opacity: 0.6,
			},
		},
		meta: {
			x: { alias: '手机品牌' },
			y: { alias: '开门次数' },
		},
	};

	return <Column {...config} {...props} />;
};
export default ColumnChart;
