import Api from '../data/API';

export async function synchrWuye(params: any) {
	return Api.postWithAuth('/api/order/synchr_wuye', params);
}

export async function refundSynchrWuye(params: any) {
	return Api.postWithAuth('/api/order/refund_synchr_wuye', params);
}
export async function paySynchrWuye(params: any) {
	return Api.postWithAuth('/api/order/pay_synchr_wuye', params);
}

export async function findAllKinds(params: any) {
	return Api.postWithAuth('/api/order/find_all_kinds', params);
}

export async function wxpayRefundLaunchLiucheng(params: any) {
	return Api.postWithAuth('/api/wxpay_refund_launch_liucheng', params);
}

export async function findFee(params: any) {
	return Api.postWithAuth('/debit_node/find_fee', params);
}

export async function orderReport(params: any) {
	return Api.postWithAuth('/api/order/order_report', params);
}
export async function confirmQyOrder(params: any) {
	return Api.postWithAuth('/api/order/confirmQyOrder', params);
}
export async function fndOrderList(params: any) {
	return Api.postWithAuth('/adminapi/order/fndOrderList', params);
}
export async function getWxBusinessByGroup(params: any) {
	return Api.postWithAuth('/adminapi/permOrder/getWxBusinessByGroup', params);
}
export async function savePermGroupByOrder(params: any) {
	return Api.postWithAuth('/adminapi/permOrder/savePermGroupByOrder', params);
}
export async function findPermGroupByOrder(params: any) {
	return Api.postWithAuth('/adminapi/permOrder/findPermGroupByOrder', params);
}
export async function getPermContractGroupByWxBusinessUuid(params: any) {
	return Api.postWithAuth('/adminapi/permOrder/getPermContractGroupByWxBusinessUuid', params);
}
export async function findByPermGId(params: any) {
	return Api.postWithAuth('/adminapi/permOrder/findByPermGId', params);
}
export async function printBill(params: any) {
	return Api.postWithAuth('/adminapi/order/printBill', params);
}
export async function updateOrderPrintStatus(params: any) {
	return Api.postWithAuth('/adminapi/order/updateOrderPrintStatus', params);
}
export async function orderKindList(params: any) {
	return Api.postWithAuth("/admin/api/order-kind/list", params)
}
export async function orderKindSetPerm(params: any) {
	return Api.postWithAuth("/admin/api/order-kind/setPerm", params)
}
export async function orderKindSetLiucheng(params: any) {
	return Api.postWithAuth("/admin/api/order-kind/setLiucheng", params)
}
