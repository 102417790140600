import React from "react";
import WuYePersonGroupList from "./pages/WuYePersonGroupList";
import ProjectList from "./pages/ProjectList";
import MiniApp, { MiniAppMenu, MiniAppRoute } from "../../util/MiniApp";
import DepartList from "./pages/DepartList";
import EmployeeList from "./pages/DepartmentList";
import BatchEmp from "./pages/BatchEmp";
import BatchUpdateUserImg from "./pages/BatchUpdateUserImg";
import EmpData from "./pages/EmpData";
import UnlockRecordData from "./pages/UnlockRecordData";
import UserCertificationBatch from "./pages/UserCertificationBatch";
import RequestList from '../ZhiNengMenJin/pages/RequestList';
import AbnormalPersonnel from './pages/AbnormalPersonnel';

export default function CompanyOrgMiniApp() {
    const rootUrl = "/company-organization";

    const menus: MiniAppMenu[] = [
        {
            authority: "business:employees_departments",
            name: "员工",
            path: "employee",
            component: EmployeeList
        },
        {
            authority: "business:departments",
            name: "部门",
            path: "departmentList",
            component: DepartList
        },
        {
            authority: "business:roles",
            name: "权限",
            path: "roles",
            component: WuYePersonGroupList
        },
        {
            authority: "black-list:select",
            name: "黑名单管理",
            path: "black-list",
            component: WuYePersonGroupList
        },

        {
            authority: "business:projects",
            name: "项目授权",
            path: "projects",
            component: ProjectList
        }, {
            name: "人员统计",
            path: "empData",
            authority: "personnel_statistics",
            component: EmpData
        },
        {
            name: "异常人员",
            path: "AbnormalPersonnel",
            authority: "company-organization:AbnormalPersonnel",
            component: AbnormalPersonnel
        },
        {
            name: "待授权列表",
            path: "request-list",
            authority: "entrance_guard:request-list:select",
            component: RequestList
        },
        // {
        // 	name: '人员类型设置',
        // 	path: 'employeeType',
        // 	component: EmployeeTypeManager,
        // },

    ];
    const routes: MiniAppRoute[] = [
        {
            // authority: 'batch:emp',
            path: "batchEmp",
            component: BatchEmp
        },
        // {
        //     // authority: 'batch:emp',
        //     path: "batchEmp",
        //     component: BatchEmp
        // },
        {
            path: "updateImgs",
            component: BatchUpdateUserImg
        },
        {
            path: "batchUserCertificate",
            component: UserCertificationBatch
        }
    ];

    return <MiniApp miniAppName={"组织架构"} rootUrl={rootUrl} routes={routes} menus={menus}/>;
}
