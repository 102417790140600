import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import BatchList from './pages/BatchList';
import ReportForms from './pages/ReportForms';
import ShenheList from './components/ShenheList';
import EntranceGuardReport from './components/EntranceGuardReport';
import HouseholdCheckOut from './components/HouseholdCheckOut';
import EntranceGuardReportInfo from './components/EntranceGuardReportInfo';
import KaimenData from '../assets/components/KaimenData';
import ControlPlatform from './pages/ControlPlatform';
import VacantRoomList from '../earlyWarning/pages/VacantRoomList';
import SubleaseRoomList from '../earlyWarning/pages/SubleaseRoomList';
import UnCheckRoomList from '../earlyWarning/pages/UnCheckRoomList';

export default function FaceAuditMiniApp() {
	const rootUrl = '/face_audit';
	const menus: MiniAppMenu[] = [
		{
			name: '控制台',
			path: 'plat-form',
			authority: 'household:console:select',
			component: ControlPlatform,
		},
		{
			name: '住户人脸审核',
			path: 'batch',
			authority: 'household:facebatch:select',
			component: BatchList,
		},
		{
			name: '异常人员',
			path: 'reportForms',
			authority: 'household:report_forms:select',
			component: ReportForms,
		},
		{
            name: '空置列表',
            path: 'vacantRoomList',
            authority: 'household:vacant_room_list:select',
            component: VacantRoomList,
        },
        {
            name: '转租转借列表',
            path: 'subleaseRoomList',
            authority: 'household:sublease_room_list:select',
            component: SubleaseRoomList,
        },
        {
            name: '空房列表',
            path: 'unCheckRoomList',
            authority: 'household:uncheck_room_list:select',
            component: UnCheckRoomList,
        },
	];

	const routes: MiniAppRoute[] = [
		{
			path: 'renlian-shenhei',
			component: ShenheList,
		},
		{
			path: 'entrance_guard_report',
			component: EntranceGuardReport,
		},
		{
			path: 'household-check-out',
			component: HouseholdCheckOut,
		},
		{
			path: 'entrance-guard-report-info',
			component: EntranceGuardReportInfo,
		},
		{
			path: 'statistics',
			component: KaimenData,
		},
	];

	return <MiniApp miniAppName={'住户'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
