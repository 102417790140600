import Api from '../data/API';
export async function getHeatlhRecordList(params: any) {
	return Api.postWithAuth('/admin/api/heatlh_record/getList', params);
}
export async function getAllHeatlhRecordList(params: any) {
	return Api.postWithAuth('/admin/api/heatlh_record/getAllList', params);
}
export async function getHealthCheckList(params: any) {
	return Api.postWithAuth('/admin/api/get_health_check_list', params);
}
