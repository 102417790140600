import React from "react"
import DebitNote from "./DebitNote"

const BillReceivable: React.FC = () => { 
    
    
    
    return <>
        <DebitNote state="false"></DebitNote>
    </>

}
export default BillReceivable