import { message } from 'antd';

// let j = jQuery as any;
var isclick = true;
var btns = '';

interface Message {
	type?: string; // 消息类型 默认(info) | success | error | warning
	config?: {};
	content: string; // 消息内容
	duration?: number; // 自动关闭的延时
}

export const suspensionMsg = (msg: Message) => {
	if (msg.config !== undefined) {
		message.config(msg.config);
	}
	if (msg.duration === undefined) {
		msg.duration = 5;
	}
	switch (msg.type) {
		case 'success':
			message.success(msg.content, msg.duration);
			break;
		case 'error':
			message.error(msg.content, msg.duration);
			break;
		case 'danger':
			message.warning(msg.content, msg.duration);
			break;
		case 'warning':
			message.warning(msg.content, msg.duration);
			break;
		default:
			message.info(msg.content, msg.duration);
	}
};

// /**
//  *
//  * @param content 提示内容
//  * @param type 消息类型
//  * 	'default'(默认)|'primary'|'success'|'info'|'warning'|'danger'|'important'|'special'
//  */
//
//
// export  function isDisabled(btnId: any) {
//     utilDisabled(true , btnId);
//     btns = btnId;
//     setTimeout(() => { utilDisabled(false , btns); }, 2000);
// }
// function utilDisabled(flag: boolean , btnIds: any) {
//     j(`#${btnIds}`).attr('disabled', flag);
//
// }
// export  function suspensionMsg(content: any , type: any, hideDivId: any) {
//     if (hideDivId !== undefined && hideDivId !== '') {
//         j(`#${hideDivId}`).modal('hide');
//     }
//     if (isclick) {
//         isclick = false;
//         let myMessager = new j.zui.Messager(content, {
//             type: type === undefined || '' ? 'danger' : type,
//             close: false,
//             time: 0, // 不进行自动隐藏
//         });
//         // // 5 秒之后隐藏消息
//         setTimeout(function () { myMessager.hide(); isclick = true; }, 2000);
//         myMessager.show(content);
//     }
// }
