import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Space, message, Button, Input, Modal, Row, Col, Form, Select, Switch } from 'antd';
import GSTable from '../../../components/GSTable';
import {
	getContractTemplateList,
	addContractTemplate,
	deleteContractTemplate,
	updateContractTemplate,
	findContractPermGroup,
	savePermContract,
	getPermContractGroupByContractId,
} from '../../../services/Contract';
import { ActionType } from '@ant-design/pro-table';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import update from 'immutability-helper';
import InputDragable from '../../../components/InputDragable';
import { DndProvider } from 'react-dnd';
import HTMLBackend from 'react-dnd-html5-backend';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import { findFeeSubject } from '../../../services/PayCostManagementService';
import { format } from 'path';
const ContractTemplate: React.FC = () => {
	const [feeSubjectList, setfeeSubjectList] = useState([]);
	useEffect(() => {
		getFeeTypeList().then();
	}, []);

	const getFeeTypeList = async () => {
		const res = await findFeeSubject({ state: 'true' });
		if (res.err == 0) {
			console.log('dataList：：', res);
			setfeeSubjectList(res.data || []);
		}
	};
	const contractCountList = [
		{
			name: '单方协议',
			value: '单方协议',
		},
		{
			name: '二方协议',
			value: '二方协议',
		},
		{
			name: '三方协议',
			value: '三方协议',
		},
		{
			name: '四方协议',
			value: '四方协议',
		},
	];
	const contractParamSelect = [
		{
			name: '本公司名称',
			value: 'ownCompanyName',
		},
		{
			name: '签署方公司名称',
			value: 'userCompanyName',
		},
		{
			name: '签署方公司信用代码',
			value: 'creditCode',
		},
		{
			name: '签署方公司注册地址',
			value: 'userCompanyRegisterAddress',
		},
		{
			name: '签署方公司法人',
			value: 'companyMethodPerson',
		},
		{
			name: '签署方名称',
			value: 'signName',
		},
		{
			name: '签署方联系人',
			value: 'userName',
		},
		{
			name: '签署联系人电话',
			value: 'userPhone',
		},
		{
			name: '产品名称',
			value: 'productName',
		},
		{
			name: '价格',
			value: 'price',
		},
		{
			name: '押金',
			value: 'deposit',
		},
		{
			name: '大写价格',
			value: 'capitalPrice',
		},
		{
			name: '大写押金',
			value: 'capitalDeposit',
		},
		{
			name: '合同编号',
			value: 'contractNo',
		},
		{
			name: '合同开始时间',
			value: 'contractStartTime',
		},
		{
			name: '合同结束时间',
			value: 'contractEndTime',
		},
		{
			name: '收费周期',
			value: 'chargingCycle',
		},
		{
			name: '楼号',
			value: 'buildingNumber',
		},
		{
			name: '单元',
			value: 'unit',
		},
		{
			name: '房间号',
			value:'roomNumber'
		},
		{
			name: '计费面积',
			value:'chargeArea'
		},
		{
			name: '智能计费',
			value: '智能计费',
		},
	];
	useEffect(() => {
		getLiuchengDanList().then();
		getPermContractList().then();
	}, []);
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [addVisible, setAddVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	// const [form] = Form.useForm();
	const [updateForm] = Form.useForm();
	const [liuchengList, setLiuchengList] = useState([]);
	const [permContractList, setpermContractList] = useState([]);
	const [permForm] = Form.useForm();
	const [permVisible, setpermVisible] = useState(false);
	const [add, setadd] = useState(false);
	const getPermContractList = async () => {
		const res = await findContractPermGroup({});
		if (res.err == 0) {
			setpermContractList([...res.data]);
		}
	};
	const columns = [
		{
			title: '模板名称',
			dataIndex: 'templateName',
			key: 'templateName',
		},
		{
			title: '合同类型',
			dataIndex: 'templateType',
			key: 'templateType',
		},
		{
			title: '模板ID',
			dataIndex: 'templateId',
			key: 'templateId',
		},
		{
			title: '模板参数',
			dataIndex: 'templateValues',
			key: 'templateValues',
			ellipsis: true,
		},
		{
			title: '签章参数',
			dataIndex: 'signatory',
			key: 'signatory',
			ellipsis: true,
		},
		{
			title: '备注',
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: '操作',
			fixed: 'right',
			width: 150,
			key: 'action',
			render: (text: string, record: any) => (
				<Space>
					<a
						onClick={() => {
							updateItem(record);
						}}
					>
						编辑
					</a>
					<a
						onClick={() => {
							openPermModal(record);
						}}
					>
						设置分组
					</a>
					<a
						onClick={() => {
							deleteItem(record.id);
						}}
					>
						删除
					</a>
				</Space>
			),
		},
	];
	const getLiuchengDanList = async () => {
		const data = {
			state: true,
			selectedTags: '',
			searchContent: '',
		};
		const res = await getAllLiucheng(data);
		console.log('res:::', res);
		if (res.err === 0) {
			setLiuchengList(res.data);
		}
	};
	const deleteItem = (id: string) => {
		Modal.confirm({
			title: '删除确认',
			content: '您确认要删除该模板吗？',
			okText: '删除',
			okType: 'danger',
			onOk: async () => {
				return deleteTepmlate(id);
			},
		});
	};
	const updateItem = (record: any) => {
		setadd(false);
		setUpdateVisible(true);
		let data = {
			...record,
			id: record.id,
			name: record.templateName,
			tid: record.templateId,
			templateValues: JSON.parse(record.templateValues) || [],
			signatory: JSON.parse(record.signatory) || [],
		};
		updateForm.setFieldsValue(data);
	};
	const deleteTepmlate = async (id: string) => {
		let data = {
			id: id,
		};
		let rsp = await deleteContractTemplate(data);
		if (rsp.err === 0) {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		} else {
			message.error(rsp.msg);
		}
	};
	const loadList = async (data: any) => {
		let rsp = await getContractTemplateList(data);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
			return;
		}
		return {
			data: rsp.data,
			total: rsp.total,
		};
	};
	const tableOperationsBarRender = () => [
		<Button
			type={'primary'}
			onClick={() => {
				updateForm.resetFields();
				setadd(true);
				setUpdateVisible(true);
			}}
		>
			添加
		</Button>,
	];
	const tableSearchBarRender = () => [
		<Input
			placeholder="请输入"
			onChange={(value: any) => {
				setSearch(value.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();

				}
			}}
		>
			查询
		</Button>,
	];
	const saveContractTemplate = () => {
		updateForm.validateFields().then(async (data: any) => {
			console.log('data', data);
			let params = {
				...data,
				id: undefined,
				templateValues: JSON.stringify(data.templateValues),
				signatory: JSON.stringify(data.signatory),
			};
			let rsp = await addContractTemplate(params);
			if (rsp.err !== 0) {
				message.error(rsp.msg);
				return;
			}
			setUpdateVisible(false);
			message.success('添加成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		});
	};
	const updateContractTemplateRequest = async () => {
		updateForm.validateFields().then(async (data: any) => {
			console.log(data);
			let params = {
				...data,
				templateValues: JSON.stringify(data.templateValues),
				signatory: JSON.stringify(data.signatory),
			};
			let rsp = await updateContractTemplate(params);
			if (rsp.err === 0) {
				message.success('修改成功');
				setUpdateVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(rsp.msg);
			}
		});
		// let data = await updateForm.validateFields();
	};

	const moveCard = (dragIndex: number, hoverIndex: number) => {
		let dragCard: any;
		let data = updateForm.getFieldValue('templateValues');
		dragCard = data[dragIndex];
		let arr = update(data, {
			$splice: [
				[dragIndex, 1],
				[hoverIndex, 0, dragCard],
			],
		});
		console.log('ziDuanList::::', arr);
		data = arr;
		updateForm.setFieldsValue({ templateValues: data });
	};
	const updateMoveCard = (dragIndex: number, hoverIndex: number) => {
		let dragCard: any;
		let data = updateForm.getFieldValue('templateValues');
		dragCard = data[dragIndex];
		let arr = update(data, {
			$splice: [
				[dragIndex, 1],
				[hoverIndex, 0, dragCard],
			],
		});
		console.log('ziDuanList::::', arr);
		data = arr;
		updateForm.setFieldsValue({ templateValues: data });
	};

	const openPermModal = async (record: any) => {
		const res = await getPermContractGroupByContractId({ contractTemplateId: record.id });
		if (res.err == 0) {
			let permId = [];
			const pcData = res.data || [];
			res.data.map((item) => {
				permId.push(item.permGroupId);
			});
			permForm.setFieldsValue({ contractTemplateId: record.id, permGroupId: permId });
			setpermVisible(true);
		} else {
			message.error(res.msg);
		}
	};
	const savePerm = () => {
		permForm.validateFields().then(async (data: any) => {
			const res = await savePermContract({ ...data });
			if (res.err == 0) {
				message.success('设置成功');
				setpermVisible(false);
				permForm.resetFields();
				actionRef.current.reload();
			} else {
				message.error(res.msg);
			}
		});
	};
	const saveItem = () => {
		if (add) {
			saveContractTemplate();
		} else {
			updateContractTemplateRequest();
		}
	};
	return (
		<>
			<GSTable
				actionRef={actionRef}
				columns={columns}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any) => {
					let data = {
						page: params.current,
						size: params.pageSize,
						search: search,
					};
					return loadList(data);
				}}
			/>
			<Modal
				title={'设置权限分组'}
				visible={permVisible}
				width={800}
				onOk={() => {
					savePerm();
				}}
				onCancel={() => {
					setpermVisible(false);
				}}
			>
				<Form form={permForm} labelCol={{ span: 4 }}>
					<Form.Item name={'contractTemplateId'} hidden={true}>
						<Input></Input>
					</Form.Item>
					<Form.Item name={'permGroupId'} label={'分组'}>
						<Select mode="multiple" showSearch optionFilterProp="children">
							{permContractList.map((item) => {
								return (
									<>
										<Select.Option value={item.id}>{item.name}</Select.Option>
									</>
								);
							})}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				width={1300}
				title={add ? '添加' : '修改'}
				visible={updateVisible}
				onOk={
					() => saveItem()
					// return updateContractTemplateRequest();
				}
				onCancel={() => {
					setUpdateVisible(false);
				}}
			>
				<DndProvider backend={HTMLBackend}>
					<Form form={updateForm} labelCol={{ span: 4 }}>
						<Form.Item name={'id'} hidden={true}>
							<Input />
						</Form.Item>
						<Form.Item
							name={'name'}
							rules={[{ required: true, message: '请填写模板名称' }]}
							label={'模板名称'}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'tid'}
							rules={[{ required: true, message: '请填写模板ID' }]}
							required={true}
							label={'模板ID'}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'contractNoTemplate'}
							rules={[{ required: true, message: '请填写合同编号模板' }]}
							required={true}
							tooltip={'编号变量为：{NO}'}
							label={'合同编号'}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'templateType'}
							rules={[{ required: true, message: '请选择合同类型' }]}
							required={true}
							label={'合同类型'}
						>
							<Select>
								<Select.Option value="个人">个人</Select.Option>
								<Select.Option value="公司">公司</Select.Option>
								<Select.Option value="公司和个人">公司和个人</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item
							name={'contractCount'}
							rules={[{ required: true, message: '请选择协议' }]}
							required={true}
							label={'协议内容'}
						>
							<Select>
								{contractCountList.map((item: any) => {
									return (
										<Select.Option value={item.value} key={item.value}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						<Row gutter={4} align={'middle'}>
							<Col span={4} style={{ textAlign: 'right' }}>
								参数信息：
							</Col>
							<Col span={20}>
								<Form.Item style={{ width: '100%' }} shouldUpdate noStyle>
									{() => (
										<Form.List name={'templateValues'}>
											{(fields, { add, remove, move }) => (
												<>
													{fields.map((field, index) => (
														<InputDragable
															type={'select'}
															key={index}
															accept={'selectItem'}
															index={index}
															moveCard={updateMoveCard}
														>
															<Space key={field.key} align="baseline">
																<Form.Item
																	{...field}
																	name={[field.name, 'label']}
																	fieldKey={[field.fieldKey, 'label']}
																	rules={[
																		{ required: true, message: '请输入参数名称' },
																	]}
																>
																	<Input placeholder="参数名称" />
																</Form.Item>
																<Form.Item
																	{...field}
																	name={[field.name, 'labelNote']}
																	fieldKey={[field.fieldKey, 'labelNote']}
																>
																	<Input placeholder="备注" />
																</Form.Item>
																<Form.Item
																	{...field}
																	name={[field.name, 'componentType']}
																	fieldKey={[field.fieldKey, 'componentType']}
																>
																	<Select
																		placeholder="组件类型"
																		style={{ width: 100 }}
																	>
																		<Select.Option value={'input'}>
																			文本框
																		</Select.Option>
																		<Select.Option value={'numberInput'}>
																			数字文本框
																		</Select.Option>
																		<Select.Option value={'textAreaInput'}>
																			多行文本框
																		</Select.Option>
																		{/* <Select.Option value={'date'}>日期</Select.Option> */}
																	</Select>
																</Form.Item>
																<Form.Item
																	{...field}
																	name={[field.name, 'type']}
																	fieldKey={[field.fieldKey, 'type']}
																	rules={[
																		{ required: true, message: '请选择参数类型' },
																	]}
																>
																	<Select
																		placeholder="参数类型"
																		style={{ width: 100 }}
																	>
																		<Select.Option value={'系统'}>
																			系统
																		</Select.Option>
																		<Select.Option value={'自定义'}>
																			自定义
																		</Select.Option>
																	</Select>
																</Form.Item>
																<Form.Item
																	{...field}
																	name={[field.name, 'name']}
																	fieldKey={[field.fieldKey, 'name']}
																>
																	<Select
																		onChange={() => {
																			console.log(
																				updateForm.getFieldValue([
																					'templateValues',
																					field.name,
																					'name',
																				])
																			);
																		}}
																		style={{ width: 150 }}
																		allowClear
																		placeholder="模板参数"
																	>
																		{contractParamSelect.map((item) => (
																			<Select.Option value={item.value}>
																				{item.name}
																			</Select.Option>
																		))}
																	</Select>
																</Form.Item>
																<Form.Item
																	{...field}
																	hidden={
																		updateForm.getFieldValue([
																			'templateValues',
																			field.name,
																			'name',
																		]) != '智能计费'
																	}
																	name={[field.name, 'feeTypeUuid']}
																	fieldKey={[field.fieldKey, 'feeTypeUuid']}
																>
																	<Select
																		style={{ width: 150 }}
																		allowClear
																		placeholder="费用科目"
																		showSearch
																		optionFilterProp="children"
																	>
																		{feeSubjectList.map((item) => {
																			return (
																				<>
																					<Select.Option value={item.uuid}>
																						{item.feeName}
																					</Select.Option>
																				</>
																			);
																		})}
																	</Select>
																</Form.Item>
																<Form.Item
																	{...field}
																	hidden={
																		updateForm.getFieldValue([
																			'templateValues',
																			field.name,
																			'name',
																		]) != '智能计费'
																	}
																	name={[field.name, 'feeExpression']}
																	fieldKey={[field.fieldKey, 'feeExpression']}
																>
																	<Select
																		style={{ width: 150 }}
																		allowClear
																		placeholder="费用方式"
																	>
																		<Select.Option value={"应收小写"}>应收小写</Select.Option>
																		<Select.Option value={"应收大写"}>应收大写</Select.Option>
																		<Select.Option value={"总费用大写"}>总费用大写（供暖）</Select.Option>
																		<Select.Option value={"总费用小写"}>总费用小写（供暖）</Select.Option>
																	</Select>
																</Form.Item>
																<Form.Item
																	{...field}
																	name={[field.name, 'morenzhi']}
																	fieldKey={[field.fieldKey, 'morenzhi']}
																>
																	<Input placeholder="自定义默认值"></Input>
																</Form.Item>
																<Form.Item
																	{...field}
																	valuePropName={'checked'}
																	name={[field.name, 'isShow']}
																	fieldKey={[field.fieldKey, 'isShow']}
																>
																	<Switch
																		checkedChildren="显示"
																		unCheckedChildren="隐藏"
																	></Switch>
																</Form.Item>
																<MinusCircleOutlined
																	onClick={() => remove(field.name)}
																/>
															</Space>
														</InputDragable>
													))}
													<Button
														type="dashed"
														onClick={() => add()}
														block
														icon={<PlusOutlined />}
													>
														添加参数
													</Button>
												</>
											)}
										</Form.List>
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={4} align={'middle'}>
							<Col span={4} style={{ textAlign: 'right' }}>
								签署人信息：
							</Col>
							<Col span={20}>
								<Form.List name={'signatory'}>
									{(fields, { add, remove }) => (
										<>
											{fields.map((field) => (
												<Space key={field.key} align="baseline">
													<Form.Item
														{...field}
														name={[field.name, 'name']}
														fieldKey={[field.fieldKey, 'name']}
														rules={[{ required: true, message: '请输入名称' }]}
													>
														<Input placeholder="名称" />
													</Form.Item>
													<Form.Item
														{...field}
														// label={'签署人类型'}
														name={[field.name, 'type']}
														fieldKey={[field.fieldKey, 'type']}
														rules={[{ required: true, message: '请选择签署人类型' }]}
													>
														<Select placeholder="签署人类型" style={{ width: 100 }}>
															<Select.Option value={'本公司'}>本公司</Select.Option>
															<Select.Option value={'企业'}>企业</Select.Option>
															<Select.Option value={'个人'}>个人</Select.Option>
															<Select.Option value={'房管局'}>房管局</Select.Option>
															<Select.Option value={'户主'}>户主</Select.Option>
														</Select>
													</Form.Item>
													<Form.Item
														{...field}
														name={[field.name, 'param']}
														fieldKey={[field.fieldKey, 'param']}
														rules={[{ required: true, message: '请填写模板参数' }]}
													>
														<Input placeholder="模板参数" />
													</Form.Item>
													<MinusCircleOutlined onClick={() => remove(field.name)} />
												</Space>
											))}
											{/* <Form.Item> */}
											<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
												添加签署人
											</Button>
											{/* </Form.Item> */}
										</>
									)}
								</Form.List>
							</Col>
						</Row>
						<Form.Item
							name={'hasSignDate'}
							tooltip={'签署时间_+签署人参数，例如：签署时间_甲方盖章'}
							label="签署日期参数"
							valuePropName="checked"
						>
							<Switch />
						</Form.Item>
						<Form.Item name={'liuChengUuid'} label={'流程单'}>
							<Select
								placeholder={'流程单'}
								style={{ width: '100%' }}
								showSearch
								optionFilterProp="children"
								allowClear
							>
								{liuchengList.map((item: any) => {
									return (
										<Select.Option value={item.uuid} key={item.uuid}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						<Form.Item name={'note'} label={'备注'}>
							<Input.TextArea />
						</Form.Item>
					</Form>
				</DndProvider>
			</Modal>
		</>
	);
};
export default ContractTemplate;
