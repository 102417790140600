import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { batchEmp, findAllPersonManage } from '../../../services/WuyeService';
import GSTable from '../../../components/GSTable';
import { Button, message, Space, Tag, Upload, Modal, Table, Tooltip, Steps, Image, Input, Result } from 'antd';
import { UploadOutlined } from '@ant-design/icons/lib';
import { batchImportHousehold } from '../../../services/ZhuhushenheService';
import API from '../../../data/API';
import * as XLSX from 'xlsx';
import BatchEmp1 from './BatchEmp1';
import appConfig from '../../../appConfig';
import BatchUpdateUserImg from './BatchUpdateUserImg';
import { sendKeyByEmp } from '../../../services/ZhiNengMenJinService';
import { getWuyeProjectInfo } from '../../../services/FoundationSetupService';
import DAL from '../../../data/DAL';

let myXlsx = require('../../../util/myXlsx');
let moment = require('moment');
const BatchEmp: React.FC = () => {
	useEffect(() => {
		projectInfo().then();
	}, []);

	const [current, setcurrent] = useState(0);
	const Step = Steps.Step;
	const empList = useRef(new Map());
	const [search, setsearch] = useState('');
	const actionRef = useRef<ActionType>();
	const errActionRef = useRef<ActionType>();
	const [loading, setloading] = useState(false);
	const [showPage, setshowPage] = useState(false);
	const [errVisible, seterrVisible] = useState(false);
	const [device, setdevice] = useState('');
	const [errData, seterrData] = useState([]);
	const [addEmpAduit, setaddEmpAduit] = useState(false);
	const projectInfo = async () => {
		const res = await getWuyeProjectInfo({ wuyeUuid: localStorage.getItem('wyUuid') });
		console.log('信息', res);
		if (res.err === 0) {
			const data = res.data || {};
			setdevice(data.batchAddEmpDevice || '');
			setaddEmpAduit(data.addEmpAudit);
		}
	};
	const saveEmpList = (value) => {
		value.map((value) => {
			empList.current.set(value.联系电话, value);
		});
		console.log('saveEmpListValue::', empList.current);
	};
	const proColumns = [
		{
			title: '姓名',
			width: 100,
			dataIndex: 'employeeName',
			key: 'employeeName',
		},
		{
			title: '登录账号',
			width: 150,
			dataIndex: 'account',
			key: 'account',
		},
		{
			title: '人脸照片',
			width: 100,
			dataIndex: 'facePhoto',
			key: 'facePhoto',
			render: (text: string) => {
				if (text === '-') {
					return <span>未上传</span>;
				}
				return (
					<Image
						width={100}
						height={100}
						src={API.apiRootPath + '/api/file/get_file_qiniu/' + text + '/' + appConfig.wuYeUuid}
					/>
				);
			},
		},
		{
			title: '身份证号',
			dataIndex: 'cardIdNum',
			key: 'cardIdNum',
			width: 150,
		},
		{
			title: '部门',
			dataIndex: 'departmentName',
			key: 'departmentName',
			width: 150,
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			width: 100,
			render: (text: string, record: any) => <Tag color={record.status === '正常' ? 'green' : 'red'}>{text}</Tag>,
		},
		{
			title: '人员类型',
			dataIndex: 'employeeType',
			key: 'employeeType',
			width: 100,
		},
		{
			title: '截止日期',
			valueType: 'date',
			dataIndex: 'expireTime',
			key: 'expireTime',
			width: 100,
		},
		{
			title: '所属项目',
			dataIndex: 'wuyeProj',
			key: 'wuyeProj',
			width: 150,
		},
		{
			title: '录入人员',
			dataIndex: 'createor',
			key: 'createor',
			width: 100,
		},
	];
	const tableSearchBarRender = () => [
		<Input
			placeholder="姓名，登录账号，身份证号"
			onChange={(value: any) => {
				setsearch(value.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];

	const sendDevice = async () => {
		if (!DAL.dal.isNotNull(device)) {
			message.warning('暂无默认设备，请设置项目默认发送设备');
			return;
		}
		let phones = [];
		empList.current.forEach((value, key, map) => {
			phones.push(key);
		});
		let params = {
			device_id: device.toString(),
			key_type: 'anytime',
			phones: phones.toString(),
			companyUuid: localStorage.getItem('companyUuid'),
		};
		setloading(true);
		let res = await sendKeyByEmp(params);
		setloading(false);
		if (res.err === 0) {
			setshowPage(true);
			if (res.errCount > 0) {
				seterrVisible(true);
				const errData = res.errList || [];
				let errPhone = [];
				errData.map((item) => {
					errPhone.push(item.loginId);
				});
				seterrData(errPhone);
				return;
			}
			// message.success('发送成功');
			setcurrent(0);
			empList.current = new Map();
		} else {
			message.error(res.msg);
		}
	};
	return (
		<>
			<style>{`
				.content-big
				{
					padding:0px
				}
				// .ant-pro-table-list-toolbar-container{
				// 	padding:0px
				// }
				// .ant-pro-table .ant-table-content tr:first-child > th:first-child, .ant-pro-table .ant-table-row .ant-table-cell:first-child {
				// 	padding-left:9px
				// }
			
            `}</style>

			{!showPage && (
				<>
					<div
						style={{
							background: '#fff',
							padding: '10px 20px ',
							height: 100,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Steps current={current}>
							<Step title="上传人员" />
							<Step title="上传人员照片" />
							<Step title={ addEmpAduit ? '导入的人员':'发送钥匙'} />

						</Steps>
					</div>
					<div style={{ height: 10 }}></div>
					{current == 0 && (
						<>
							<BatchEmp1 saveEmpList={(value) => saveEmpList(value)}></BatchEmp1>
						</>
					)}
					{current == 1 && (
						<div style={{ padding: 16 }}>
							<BatchUpdateUserImg></BatchUpdateUserImg>
						</div>
					)}
					{current == 2 && (
						<div style={{ padding: 16 }}>
							<GSTable
								columns={proColumns}
								rowKey="account"
								rowSelection={{
									preserveSelectedRowKeys: true,
									selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
								}}
								tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
									return (
										<Space size={24}>
											<span>
												已选 {selectedRowKeys.length} 项
												<a
													style={{ marginLeft: 8 }}
													onClick={() => {
														onCleanSelected();
													}}
												>
													取消选择
												</a>
											</span>
										</Space>
									);
								}}
								actionRef={actionRef}
								searchBarRender={tableSearchBarRender()}
								request={async (params: any = {}, sort: any, filter: any) => {
									let phones = [];
									empList.current.forEach((value, key, map) => {
										phones.push(key);
									});
									const res = await findAllPersonManage({
										state: true,
										phones: phones.toString(),
										page: params.current,
										Search: search,
										size: params.pageSize,
										departmentUuid: '',
										companyUuid: localStorage.getItem('companyUuid'),
									});
									return {
										data: res.data,
										total: res.count,
										success: true,
									};
								}}
							/>
						</div>
					)}
					<div style={{ position: 'fixed', bottom: 10, right: 10 }}>
						<Space>
							{current !== 0 && (
								<Button
									onClick={() => {
										setcurrent(current - 1);
									}}
								>
									上一步
								</Button>
							)}
							{(current === 0 || current === 1) && (
								<Button
									type="primary"
									onClick={() => {
										if (current == 0) {
											console.log(' empList.current.size', empList.current.size);
											if (empList.current.size === 0) {
												message.warning('请导入人员');
												return;
											}
										}
										setcurrent(current + 1);
									}}
								>
									下一步
								</Button>
							)}
							{/* {(current === 1 && addEmpAduit) && (
								<Button
									type="primary"
									onClick={() => {
										setcurrent(current + 1);
									}}
								>
									下一步
								</Button>
							)} */}
							{current === 2 && !addEmpAduit && (
								<Button loading={loading} onClick={sendDevice} type="primary">
									发送钥匙
								</Button>
							)}
						</Space>
					</div>
				</>
			)}
			{showPage && (
				<>
					<div style={{ backgroundColor: '#fff' }}>
						<Result status="success" title="发送成功" />
					</div>
					<div style={{ marginTop: 10 }}></div>
					{errVisible && (
						<>
							<div>未上传人脸照片，发送失败人员</div>
							<div style={{ marginTop: 10 }}></div>
							<GSTable
								// title="未上传人脸照片，发送失败人员"
								size={'small'}
								//  operationsBarRender={errorTableOperationBarRender()}
								options={false}
								actionRef={errActionRef}
								request={async (params) => {
									const res = await findAllPersonManage({
										state: true,
										phones: errData.toString(),
										page: params.current,
										Search: search,
										size: params.pageSize,
										departmentUuid: '',
										companyUuid: localStorage.getItem('companyUuid'),
									});
									return {
										data: res.data,
										total: res.count,
										success: true,
									};
								}}
								columns={proColumns}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
export default BatchEmp;
