import React from 'react';
import { Form } from 'antd';
const formLayout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 13 },
};
export default (props: any) => {
	const { ...rest } = props;

	return (
		<Form key={props.key} {...rest} {...formLayout}>
			{props.children}
		</Form>
	);
};
