import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ActionType} from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import {
    Button,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Select,
    Image,
    Col,
    Row,
    Card,
    DatePicker,
    Space,
    Switch,
    TreeSelect,
    Spin,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import Api from '../../../data/API';
import ConnectState from '../../../models/connect';
import {connect} from 'dva';
import {addTalent, deleteTalent, getTalentList, updateTalent} from '../../../services/talent';
import UploadImgFormItem from '../../../components/UploadImgFormItem';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import {getAllLiucheng} from '../../../services/FoundationSetupService';
import GsTreeSelect from '../../../components/GsTreeSelect';
import DAL from '../../../data/DAL';
import * as XLSX from 'xlsx';
import {
    getSchemeTechList,
    getSchemeTypeList,
    getTreeSchemeTechList,
    getTreeSchemeTypeList,
} from '../../../services/scheme';

const TalentList: React.FC = (props: any) => {
    useEffect(() => {
        getLiuchengDanList().then();
        getAllTech().then();
        getAlltype().then();
        getTypeList().then();
        getTechList().then();
    }, []);
    const getTypeList = async () => {
        const rsp = await getTreeSchemeTypeList({});
        if (rsp.err === 0) {
            const data = rsp.data || [];
            renChildren(data);
            setscenarioTypeList([...data]);
        }
    };
    const renChildren = (list) => {
        list.forEach((element) => {
            element.title = element.name;
            element.value = element.uuid;
            if (element.children != undefined && element.children.length > 0) {
                renChildren(element.children);
            }
        });
    };
    const getTechList = async () => {
        const rsp = await getTreeSchemeTechList({});
        if (rsp.err === 0) {
            const data = rsp.data || [];
            renChildren(data);
            setschemeTechList([...data]);
        }
    };
    const getAlltype = async () => {
        const res = await getSchemeTypeList({});
        if (res.err == 0) {
            setallType(res.data);
        }
    };
    const getAllTech = async () => {
        const res = await getSchemeTechList({});
        if (res.err == 0) {
            setallTech(res.data);
        }
    };
    const [allType, setallType] = useState([]);
    const [allTech, setallTech] = useState([]);
    const Search = Input.Search;
    const [search, setSearch] = useState('');
    const actionRef = useRef<ActionType>();
    const [quanXuan, setquanXuan] = useState(false);
    const [loading, setloading] = useState(false);
    const [dataTotal, setdataTotal] = useState(0);
    const [scenarioTypeList, setscenarioTypeList] = useState([]);
    const [schemeTechList, setschemeTechList] = useState([]);
    const [add, setAdd] = useState(false);
    const [visible, setVisible] = useState(false);
    const [sortByType, setsortByType] = useState('数字排序');
    const [form] = Form.useForm<FormInstance>();
    const [dateTime, setdateTime] = useState('');
    const [dateKey, setDateKey] = useState(new Date().getTime().toString());
    const [type, settype] = useState('');
    const [status, setstatus] = useState('');
    const [name, setname] = useState('');
    const [techUuid, settechUuid] = useState('');
    const [infoSearch, setinfoSearch] = useState('');
    const [typeId, settypeId] = useState('');
    const [talentTop, setTalentTop] = useState('');
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [liuchengList, setLiuchengList] = useState([]);
    const [dataSource, setDataSource] = useState("");
    const FormItem = Form.Item;
    const statusEnum = {
        true: '上架',
        false: '下架',
    };
    const topEnum = {
        true: '置顶',
        false: '未置顶',
    };
    const getLiuchengDanList = async () => {
        const data = {
            state: true,
            selectedTags: '',
            searchContent: '',
        };
        const res = await getAllLiucheng(data);
        console.log('res:::', res);
        if (res.err === 0) {
            setLiuchengList(res.data || []);
        }
    };
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '列表图',
            dataIndex: 'listImg',
            key: 'listImg',
            render: (text: string, record: any) => (
                <>
                    {
                        record.listImg.includes("http") ? <Image src={record.listImg} height={80} width={80}/> :
                            <Image src={Api.cdnRootPath + record.listImg} height={80} width={80}/>
                    }

                </>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            valueEnum: statusEnum,
        },
        {
            title: '应用场景',
            hideInTable: true,
            valueType: 'types',
            dataIndex: 'typeUuid',
        },
        {
            title: '一级应用场景',
            hideInTable: true,
            valueType: 'oneTypes',
            dataIndex: 'typeUuid',
        },
        {
            title: '二级应用场景',
            hideInTable: true,
            valueType: 'twoTypes',
            dataIndex: 'typeUuid',
        },
        {
            title: '三级级应用场景',
            hideInTable: true,
            valueType: 'threeTypes',
            dataIndex: 'typeUuid',
        },
        {
            title: '通用技术',
            hideInTable: true,
            valueType: 'techs',
            dataIndex: 'techUuid',
        },
        {
            title: '一级通用技术',
            hideInTable: true,
            valueType: 'oneTechs',
            dataIndex: 'techUuid',
        },
        {
            title: '二级通用技术',
            hideInTable: true,
            valueType: 'twoTechs',
            dataIndex: 'techUuid',
        },
        {
            title: '三级级通用技术',
            hideInTable: true,
            valueType: 'threeTechs',
            dataIndex: 'techUuid',
        },
        {
            title: '个人简介',
            hideInTable: true,
            dataIndex: 'note',
        },
        {
            title: '教育经历',
            hideInTable: true,
            dataIndex: 'educateExperience',
        },
        {
            title: '服务案例',
            hideInTable: true,
            dataIndex: 'example',
        },
        {
            title: '专业主张',
            hideInTable: true,
            dataIndex: 'proAdvocate',
        },
        {
            title: '获得奖励',
            hideInTable: true,
            dataIndex: 'reward',
        },
        {
            title: '工作经历',
            hideInTable: true,
            dataIndex: 'workExperience',
        },
        {
            title: '手机号码',
            hideInTable: true,
            dataIndex: 'phone',
        },
        {
            title: '置顶',
            dataIndex: 'talentTop',
            key: 'talentTop',
            valueEnum: topEnum,
        },
        {
            title: '公司名称',
            ellipsis: true,
            dataIndex: 'companyName',
            key: 'companyName',
        },
        // {
        // 	title: '领域',
        // 	dataIndex: 'field',
        // 	key: 'field',
        // },
        {
            title: '排序值',
            dataIndex: 'sortBy',
            key: 'sortBy',
        },
        {
            title: '收藏量',
            dataIndex: 'collectionNumber',
            key: 'collectionNumber',
        },
        {
            title: '点击量',
            dataIndex: 'clickNumber',
            key: 'clickNumber',
        },
        // {
        // 	title: '状态',
        // 	dataIndex: 'status',
        // 	key: 'status',
        // 	valueEnum: statusEnum,
        // },
        {
            title: '操作',
            key: 'action',
            align: 'left',
            render: (record: any) => (
                <span>
					<a
                        onClick={() => {
                            openUpdateModal(record);
                        }}
                    >
						编辑
					</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                        onClick={() => {
                            deleteItem(record);
                        }}
                    >
						删除
					</a>
				</span>
            ),
        },
    ];

    const deleteItem = (value: any) => {
        Modal.confirm({
            title: '你确定要删除' + value.name + '吗？',
            okType: 'danger',
            onOk: async () => {
                const res = await deleteTalent({uuid: value.uuid});
                if (res.err === 0) {
                    message.success('删除成功');
                    if (actionRef.current) {
                        actionRef.current.reload();
                    }
                } else if (res.err === -2) {
                    message.warning(res.msg);
                } else {
                    message.error(res.msg);
                }
            },
        });
    };
    const openUpdateModal = (value: any) => {
        console.log('value', value);
        const formData: any = {
            ...value,
            uuid: value.uuid,
            name: value.name,
            note: value.note,
            listImg: value.listImg,
            officeImg: value.officeImg,
            field: value.field,
            companyName: value.companyName,
            tag: value.tag != undefined ? value.tag.split(',') : '',
            example: value.example,
            proAdvocate: value.proAdvocate,
            workExperience: value.workExperience,
            gradSchool: value.gradSchool,
            degree: value.degree,
            major: value.major,
            type: value.type,
            phone: value.phone,
            reward: value.reward,
            educateExperience: value.educateExperience,
            button: JSON.parse(value.button || '[]'),
            newEducateExperience: JSON.parse(value.newEducateExperience || '[]'),
            newWorkExperience: JSON.parse(value.newWorkExperience || '[]'),
            typeUuid: DAL.dal.isNotNull(value.typeUuid) ? value.typeUuid.split(',') : undefined,
            techUuid: DAL.dal.isNotNull(value.techUuid) ? value.techUuid.split(',') : undefined,
            talentTop: value.talentTop !== undefined ? value.talentTop : false,
            status: value.status !== undefined ? value.status : false,
            sortBy: value.sortBy,
        };
        setDataSource(value.dataSources)
        setdateTime(value.gradTime);
        form.setFieldsValue(formData);
        setDateKey(new Date().getTime().toString());
        setAdd(false);
        setVisible(true);
    };
    const openAddModal = () => {
        form.resetFields();
        setAdd(true);
        setVisible(true);
    };
    const addItem = () => {
        form.validateFields().then(async (data: any) => {
            const formData: any = {
                name: data.name,
                note: data.note,
                listImg: data.listImg,
                officeImg: data.officeImg,
                field: data.field,
                companyName: data.companyName,
                tag: data.tag != undefined ? data.tag.toString() : '',
                example: data.example,
                proAdvocate: data.proAdvocate,
                workExperience: data.workExperience,
                gradTime: dateTime,
                gradSchool: data.gradSchool,
                degree: data.degree,
                major: data.major,
                type: data.type,
                phone: data.phone,
                educateExperience: data.educateExperience,
                button: JSON.stringify(data.button),
                newEducateExperience: data.newEducateExperience || [],
                newWorkExperience: data.newWorkExperience || [],
                reward: data.reward,
                talentTop: data.talentTop !== undefined ? data.talentTop : false,
                status: data.status !== undefined ? data.status : false,
                // typeUuid: DAL.dal.isNotNull(data.typeUuid) ? data.typeUuid.toString() : '',
                // techUuid: DAL.dal.isNotNull(data.techUuid) ? data.techUuid.toString() : '',
            };
            if (data.typeUuid != undefined && data.typeUuid.length > 0) {
                let values = [];
                data.typeUuid.map((item) => {
                    values.push(item.value);
                });
                formData.typeUuid = values.toString();
            } else {
                formData.typeUuid = '';
            }
            if (data.techUuid != undefined && data.techUuid.length > 0) {
                let values = [];
                data.techUuid.map((item) => {
                    values.push(item.value);
                });
                formData.techUuid = values.toString();
            } else {
                formData.techUuid = '';
            }
            const res = await addTalent(formData);
            if (res.err === 0) {
                message.success('添加成功');
                setVisible(false);
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            } else {
                message.error(res.msg);
            }
        });
    };
    const updateItem = () => {
        form.validateFields().then(async (data: any) => {
            const formData: any = {
                ...data,
                name: data.name,
                note: data.note,
                listImg: data.listImg,
                officeImg: data.officeImg,
                field: data.field,
                companyName: data.companyName,
                tag: data.tag != undefined ? data.tag.toString() : '',
                example: data.example,
                proAdvocate: data.proAdvocate,
                workExperience: data.workExperience,
                gradTime: dateTime,
                gradSchool: data.gradSchool,
                degree: data.degree,
                major: data.major,
                uuid: data.uuid,
                type: data.type,
                phone: data.phone,
                reward: data.reward,
                newEducateExperience: data.newEducateExperience || [],
                newWorkExperience: data.newWorkExperience || [],
                educateExperience: data.educateExperience,
                button: JSON.stringify(data.button),
                talentTop: data.talentTop !== undefined ? data.talentTop : false,
                sortBy: data.sortBy,
                status: data.status !== undefined ? data.status : false,
            };
            if (data.typeUuid != undefined && data.typeUuid.length > 0) {
                let values = [];
                const flag = Object.keys(data.typeUuid[data.typeUuid.length - 1]).indexOf('value');
                if (flag < 0) {
                    formData.typeUuid = data.typeUuid.toString();
                } else {
                    data.typeUuid.map((item) => {
                        values.push(item.value);
                    });
                    formData.typeUuid = values.toString();
                }
            } else {
                formData.typeUuid = '';
            }
            if (data.techUuid != undefined && data.techUuid.length > 0) {
                let values = [];
                const flag = Object.keys(data.techUuid[data.techUuid.length - 1]).indexOf('value');
                if (flag < 0) {
                    formData.techUuid = data.techUuid.toString();
                } else {
                    data.techUuid.map((item) => {
                        values.push(item.value);
                    });
                    formData.techUuid = values.toString();
                }
            } else {
                formData.techUuid = '';
            }
            const res = await updateTalent(formData);
            if (res.err === 0) {
                message.success('修改成功');
                setVisible(false);
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            } else {
                message.error(res.msg);
            }
        });
    };
    const saveItem = () => {
        if (add) {
            addItem();
        } else {
            updateItem();
        }
    };
    const tableOperationsBarRender = () => [
        <Button type="primary" onClick={openAddModal}>
            新增
        </Button>,
    ];
    const tableSearchBarRender = () => [
        <Space>
            <GsTreeSelect
                style={{width: 150}}
                checkable={false}
                treeCheckable={false}
                treeData={scenarioTypeList}
                treeNodeFilterProp={'title'}
                placeholder={'应用场景'}
                defaultValue={typeId == '' ? undefined : typeId}
                multiple={false}
                defaultExpandAll={false}
                onChange={(ev: any) => {
                    settypeId(ev);
                }}
            />
            <GsTreeSelect
                style={{width: 150}}
                checkable={false}
                treeCheckable={false}
                treeData={schemeTechList}
                treeNodeFilterProp={'title'}
                placeholder={'通用技术'}
                defaultValue={techUuid == '' ? undefined : techUuid}
                multiple={false}
                defaultExpandAll={false}
                onChange={(ev: any) => {
                    settechUuid(ev);
                }}
            />
            <Select
                style={{width: 80}}
                value={type}
                placeholder={'类型'}
                onChange={(value: any) => {
                    settype(value);
                }}
            >
                <Select.Option value="">全部</Select.Option>
                <Select.Option value={'专家顾问'}>专家顾问</Select.Option>
                <Select.Option value={'企业家'}>企业家</Select.Option>
                <Select.Option value={'工程师'}>工程师</Select.Option>
                <Select.Option value={'投资人'}>投资人</Select.Option>
            </Select>
            <Select
                style={{width: 80}}
                value={talentTop}
                placeholder={'是否置顶'}
                onChange={(value: any) => {
                    setTalentTop(value);
                }}
            >
                <Select.Option value="">全部</Select.Option>
                <Select.Option value={'1'}>置顶</Select.Option>
                <Select.Option value={'0'}>不置顶</Select.Option>
            </Select>
            <Select
                style={{width: 80}}
                value={sortByType}
                onChange={(value) => {
                    setsortByType(value);
                }}
            >
                <Select.Option value={'数字排序'}>数字排序降序</Select.Option>
                <Select.Option value={'更新时间'}>更新时间降序</Select.Option>
                <Select.Option value={'时间'}>时间升序排序</Select.Option>
                <Select.Option value={'收藏量'}>收藏量降序</Select.Option>
                <Select.Option key={'点击量'} value={'点击量'}>
                    点击量降序
                </Select.Option>
            </Select>
            <Select
                style={{width: 80}}
                value={status}
                placeholder={'状态'}
                onChange={(value: any) => {
                    setstatus(value);
                }}
            >
                <Select.Option value="">全部</Select.Option>
                <Select.Option value={1}>上架</Select.Option>
                <Select.Option value={0}>下架</Select.Option>
            </Select>

            <Input
                style={{minWidth: 150}}
                placeholder="名称"
                onChange={(e) => {
                    setname(e.target.value);
                }}
            />
            <Input
                style={{minWidth: 150}}
                placeholder="详情"
                onChange={(e) => {
                    setinfoSearch(e.target.value);
                }}
            />
            <Button
                type="primary"
                onClick={() => {
                    if (actionRef.current) {
                        actionRef.current.reloadAndRest();
                    }
                }}
            >
                查询
            </Button>
        </Space>,
    ];

    const exprotData = async (selectedRows: [any?]) => {
        console.log('selectedRows:::::', selectedRows);
        setloading(true);
        if (quanXuan) {
            const res = await getTalentList({
                name: name,
                type: type,
                talentTop: talentTop,
                sortByType: sortByType,
                typeUuid: typeId,
                techUuid: techUuid,
                status: status,
                infoSearch: infoSearch,
            });
            setloading(false);
            if (res.err == 0) {
                setExprotData(res.data);
            } else {
                message.error(res.msg);
            }
        } else {
            setloading(false);
            setExprotData(selectedRows);
        }
    };

    const setExprotData = (selectedRows: [any?]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        console.log('columns:::', columns);
        columns.map((item) => {
            titles.push(item.title);
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data = [];
            columns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
                        } else {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
                        }
                    } else {
                        data.push('');
                    }
                } else if (item.valueType == 'types') {
                    data.push(renderSchemeType(selectedRows[i][item.dataIndex], 'all'));
                } else if (item.valueType == 'oneTypes') {
                    data.push(renderSchemeType(selectedRows[i][item.dataIndex], '1'));
                } else if (item.valueType == 'twoTypes') {
                    data.push(renderSchemeType(selectedRows[i][item.dataIndex], '2'));
                } else if (item.valueType == 'threeTypes') {
                    data.push(renderSchemeType(selectedRows[i][item.dataIndex], '3'));
                } else if (item.valueType == 'techs') {
                    data.push(renderSchemeTech(selectedRows[i][item.dataIndex], 'all'));
                } else if (item.valueType == 'oneTechs') {
                    data.push(renderSchemeTech(selectedRows[i][item.dataIndex], '1'));
                } else if (item.valueType == 'twoTechs') {
                    data.push(renderSchemeTech(selectedRows[i][item.dataIndex], '2'));
                } else if (item.valueType == 'threeTechs') {
                    data.push(renderSchemeTech(selectedRows[i][item.dataIndex], '3'));
                } else if (item.valueType === 'dateTime') {
                    data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '人才');
        /* save to file */
        XLSX.writeFile(wb, '人才.xlsx');
    };

    const chilrenSchemeType = (typeTree: any, pid: any, allType: any) => {
        const item = allType.find((item) => item.id == pid);
        if (item) {
            const tItme = typeTree.get(item.id);
            if (tItme == undefined) {
                typeTree.set(item.id, item);
            }
            if (item.pid !== 0) {
                chilrenSchemeType(typeTree, item.pid, allType);
            }
        }
    };

    const generateOptions = (params: any) => {
        const result = [];
        params.map((param) => {
            if (param.pid == 0) {
                const item: any = {
                    id: param.id,
                    uuid: param.uuid,
                    name: param.name,
                };
                item.children = getChilds(param.id, params);
                result.push(item);
            }
        });
        return result;
    };

    const getChilds = (pid, params) => {
        let childrens = [];
        params.map((param) => {
            if (param.pid == pid) {
                childrens.push({id: param.id, uuid: param.uuid, name: param.name});
            }
        });
        childrens.map((item) => {
            const c1 = getChilds(item.id, params);
            if (c1.length > 0) {
                item.children = c1;
            }
        });
        return childrens;
    };

    const schemeTypeTree = (types) => {
        let typeTree: any = new Map();
        let items = [];
        if (DAL.dal.isNotNull(types)) {
            const uuids = types.split(',');
            uuids.map((uuid) => {
                const item = allType.find((item) => item.uuid == uuid);
                if (item) {
                    const tItme = typeTree.get(item.id);
                    if (tItme == undefined) {
                        typeTree.set(item.id, item);
                    }
                    if (item.pid !== 0) {
                        chilrenSchemeType(typeTree, item.pid, allType);
                    }
                }
            });
        }
        typeTree.forEach((value, key, map) => {
            items.push(value);
        });
        const item = generateOptions(items);
        console.log('item:::', item, items);

        return item;
    };

    const schemeTechTree = (types) => {
        let typeTree: any = new Map();
        let items = [];
        if (DAL.dal.isNotNull(types)) {
            const uuids = types.split(',');
            uuids.map((uuid) => {
                const item = allTech.find((item) => item.uuid == uuid);
                if (item) {
                    const tItme = typeTree.get(item.id);
                    if (tItme == undefined) {
                        typeTree.set(item.id, item);
                    }
                    if (item.pid !== 0) {
                        chilrenSchemeType(typeTree, item.pid, allTech);
                    }
                }
            });
        }
        typeTree.forEach((value, key, map) => {
            items.push(value);
        });
        const item = generateOptions(items);
        return item;
    };
    const renderSchemeType = (types: string, xianshi: any) => {
        let typeNames: any = schemeTypeTree(types);
        let string = '';
        if (xianshi == 'all') {
            typeNames.map((item) => {
                string = string + item.name;
                if (item.children && item.children.length > 0) {
                    string = string + ':';
                    item.children.map((c1) => {
                        string = string + c1.name + '、';
                        if (c1.children && c1.children.length > 0) {
                            c1.children.map((c2) => {
                                string = string + c2.name + '、';
                            });
                        } else {
                            string = string + `\t\n`;
                        }
                    });
                } else {
                    string = string + `\t\n`;
                }
            });
        } else if (xianshi == '1') {
            typeNames.map((item) => {
                string = string + item.name + `\t\n`;
            });
        } else if (xianshi == '2') {
            typeNames.map((item) => {
                if (item.children) {
                    item.children.map((c1) => {
                        string = string + c1.name + `\t\n`;
                    });
                }
            });
        } else if (xianshi == '3') {
            typeNames.map((item) => {
                if (item.children) {
                    item.children.map((c1) => {
                        if (c1.children) {
                            c1.children.map((c2) => {
                                string = string + c2.name + `\t\n`;
                            });
                        }
                    });
                }
            });
        }

        return string;
    };
    const renderSchemeTech = (types: string, xianshi: any) => {
        let typeNames: any = schemeTechTree(types);
        let string = '';
        if (xianshi == 'all') {
            typeNames.map((item) => {
                string = string + item.name;
                if (item.children && item.children.lenght > 0) {
                    string = string + ':';
                    item.children.map((c1) => {
                        string = string + c1.name + '、';
                        if (c1.children && item.c1.lenght > 0) {
                            c1.children.map((c2) => {
                                string = string + c1.name + '、';
                            });
                        } else {
                            string = string + `\t\n`;
                        }
                    });
                } else {
                    string = string + `\t\n`;
                }
            });
        } else if (xianshi == '1') {
            typeNames.map((item) => {
                string = string + item.name + `\t\n`;
            });
        } else if (xianshi == '2') {
            typeNames.map((item) => {
                if (item.children) {
                    item.children.map((c1) => {
                        string = string + c1.name + `\t\n`;
                    });
                }
            });
        } else if (xianshi == '3') {
            typeNames.map((item) => {
                if (item.children) {
                    item.children.map((c1) => {
                        if (c1.children) {
                            c1.children.map((c2) => {
                                string = string + c2.name + `\t\n`;
                            });
                        }
                    });
                }
            });
        }

        return string;
    };

    return (
        <>
            <Spin spinning={loading}>
                <GSTable
                    columns={columns}
                    actionRef={actionRef}
                    searchBarRender={tableSearchBarRender()}
                    operationsBarRender={tableOperationsBarRender()}
                    // pagination={false}
                    rowSelection={{
                        fixed: true,
                        columnWidth: 100,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedRowKeys: any, selectedRows: any) => {
                            setquanXuan(false);
                            setselectedRowKeys(selectedRowKeys);
                        },
                        selections: [
                            {
                                key: 'SELECT_ALL',
                                text: '全选全部页面',
                                onSelect: (changableRowKeys: any) => {
                                    setquanXuan(true);
                                    setselectedRowKeys(changableRowKeys);
                                },
                            },
                        ],
                    }}
                    tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}: any) => {
                        return (
                            <Space size={24}>
								<span>
									已选 {quanXuan ? dataTotal : selectedRowKeys.length} 项
									<a
                                        style={{marginLeft: 8}}
                                        onClick={() => {
                                            onCleanSelected();
                                        }}
                                    >
										取消选择
									</a>
								</span>
                            </Space>
                        );
                    }}
                    tableAlertOptionRender={({selectedRowKeys, selectedRows, onCleanSelected}: any) => {
                        console.log('tableAlertOptionRender::::', selectedRows);
                        return (
                            <Space size={16}>
                                <a onClick={() => exprotData(selectedRows)}>导出数据</a>
                            </Space>
                        );
                    }}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        const res = await getTalentList({
                            name: name,
                            type: type,
                            page: params.current,
                            size: params.pageSize,
                            talentTop: talentTop,
                            sortByType: sortByType,
                            typeUuid: typeId,
                            techUuid: techUuid,
                            status: status,
                            infoSearch: infoSearch,
                        });
                        if (quanXuan) {
                            let uuids: any = [];
                            res.data.map((item: any) => {
                                uuids.push(item.id);
                            });
                            setselectedRowKeys(uuids);
                            setdataTotal(res.total);
                        }
                        setdataTotal(res.total);
                        if (res.err !== 0) {
                            return {
                                data: [],
                                total: 0,
                            };
                        }
                        return {
                            data: res.data,
                            total: res.total,
                            success: true,
                        };
                    }}
                />
                <Modal
                    visible={visible}
                    title={add ? '添加' : '修改'}
                    width={1250}
                    onOk={saveItem}
                    onCancel={() => {
                        setVisible(false);
                    }}
                >
                    <ProxyForm form={form}>
                        <FormItem hidden={true} name={'uuid'}>
                            <Input/>
                        </FormItem>
                        <FormItem name={'name'} label={'名称'} rules={[{required: true}]}>
                            <Input/>
                        </FormItem>
                        <FormItem hidden={add} name={'sortBy'} label={'排序值'} tooltip="值越大越靠前">
                            <InputNumber style={{width: '100%'}}/>
                        </FormItem>
                        <FormItem name={'type'} label={'类型'} rules={[{required: true, message: '请选择类型'}]}>
                            <Select>
                                <Select.Option value={'专家顾问'}>专家顾问</Select.Option>
                                <Select.Option value={'企业家'}>企业家</Select.Option>
                                <Select.Option value={'工程师'}>工程师</Select.Option>
                                <Select.Option value={'投资人'}>投资人</Select.Option>
                            </Select>
                        </FormItem>
                        <FormItem name={'status'} label={'上架/下架'} valuePropName={'checked'}>
                            <Switch/>
                        </FormItem>
                        <FormItem name={'companyName'} label={dataSource === '抓取' ? '部门/实验室' :'现工作单位及职务'} rules={[{required:dataSource === '抓取'? false : true}]}>
                            <Input/>
                        </FormItem>
                        {
                            dataSource === '抓取' && <FormItem name={'enterprise'} label={'所在院所'}>
                                <Input/>
                            </FormItem>
                        }
                        {
                            dataSource === '抓取' && <FormItem name={'field'} label={'研究领域'}>
                                <Input/>
                            </FormItem>
                        }
                        <FormItem name={'tag'} hidden={true} label={'标签'}>
                            <Input/>
                        </FormItem>
                        <FormItem label="应用场景" name={'typeUuid'}>
                            <GsTreeSelect
                                maxTagCount={99}
                                style={{width: '100%'}}
                                treeCheckStrictly={true}
                                showCheckedStrategy={TreeSelect.SHOW_ALL}
                                treeData={scenarioTypeList}
                                treeNodeFilterProp={'title'}
                                placeholder={'应用场景'}
                                multiple={false}
                                defaultExpandAll={false}
                            />
                        </FormItem>
                        <FormItem label="通用技术" name={'techUuid'}>
                            <GsTreeSelect
                                maxTagCount={99}
                                style={{width: '100%'}}
                                treeCheckStrictly={true}
                                showCheckedStrategy={TreeSelect.SHOW_ALL}
                                treeData={schemeTechList}
                                treeNodeFilterProp={'title'}
                                placeholder={'通用技术'}
                                multiple={false}
                                defaultExpandAll={false}
                            />
                        </FormItem>
                        <Form.Item
                            label={'列表图'}
                            name={'listImg'}
                            rules={[{required: true, message: '请上传图片'}]}
                        >
                            <UploadImgFormItem
                                hiddenImgCorp={false}
                                multiple={false}
                                listType={'picture-card'}
                                widthRatio={1}
                                heightRatio={1}
                            />
                        </Form.Item>
                        {
                            dataSource === '抓取' && <FormItem name={'title'} label={'专业技术职务'}>
                                <Input.TextArea/>
                            </FormItem>
                        }
                        <FormItem name={'note'} label={'个人简介'}>
                            <Input.TextArea/>
                        </FormItem>
                        <FormItem name={'educateExperience'} label={'教育经历'}>
                            <Input.TextArea/>
                        </FormItem>
                        {
                            dataSource === '抓取' ?   <FormItem
                                name={'contact'}
                                rules={[{required: true, message: '联系方式'}]}
                                label={'联系方式'}
                            >
                                <Input/>
                            </FormItem> :  <FormItem
                                name={'phone'}
                                rules={[{required: true, message: '请填写手机号码'}]}
                                label={'手机号码'}
                            >
                                <Input/>
                            </FormItem>
                        }

                        <FormItem
                            name={'talentTop'}
                            valuePropName={'checked'}
                            tooltip="选中后会在首页智库人才中显示"
                            label={'人才置顶'}
                        >
                            <Switch/>
                        </FormItem>
                        {
                            dataSource !== '抓取' &&   <FormItem label="服务案例" name={'example'}>
                                <Input.TextArea></Input.TextArea>
                            </FormItem>
                        }


                        <Form.Item name={'proAdvocate'} label="专业主张">
                            <Input.TextArea></Input.TextArea>
                        </Form.Item>

                        <Form.Item label="获得奖励" name={'reward'}>
                            <Input.TextArea></Input.TextArea>
                        </Form.Item>
                        <Form.Item label="工作经历" name={'workExperience'}>
                            <Input.TextArea></Input.TextArea>
                        </Form.Item>
                        {
                            dataSource === '抓取' &&    <Form.Item label="兼职经历" name={'partTimeExperience'}>
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                        }
                        {
                            dataSource === '抓取' &&    <Form.Item label="发表论文" name={'publishApaper'}>
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                        }
                        {
                            dataSource === '抓取' &&    <Form.Item label="荣誉及奖励" name={'example'}>
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                        }
                        {
                            dataSource === '抓取' &&    <Form.Item label="专利成果" name={'patentAchievements'}>
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                        }
                        {
                            dataSource === '抓取' &&    <Form.Item label="科研项目" name={'researchProject'}>
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                        }
                        <Form.Item shouldUpdate noStyle>
                            {() => (
                                <Row>
                                    <Col span={4} style={{textAlign: 'right'}}>
                                        新教育经历：
                                    </Col>
                                    <Col span={16}>
                                        <Form.List name={'newEducateExperience'}>
                                            {(fields, {add, remove}) => (
                                                <>
                                                    {fields.map((field: any, index: number) => (
                                                        <Card
                                                            size="small"
                                                            bordered={true}
                                                            title={`第${index + 1}个`}
                                                            extra={
                                                                fields.length > 0 && (
                                                                    <MinusCircleOutlined
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                )
                                                            }
                                                        >
                                                            <Form.Item
                                                                {...field}
                                                                label={'毕业院校'}
                                                                name={[field.name, 'name']}
                                                                fieldKey={[field.fieldKey, 'name']}
                                                            >
                                                                <Input></Input>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                label={'学历及其他描述'}
                                                                name={[field.name, 'note']}
                                                                fieldKey={[field.fieldKey, 'note']}
                                                            >
                                                                <Input.TextArea></Input.TextArea>
                                                            </Form.Item>
                                                        </Card>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            style={{marginTop: 10}}
                                                            onClick={() => add()}
                                                            block
                                                            icon={<PlusOutlined/>}
                                                        >
                                                            添加
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            )}
                        </Form.Item>

                        <Form.Item shouldUpdate noStyle>
                            {() => (
                                <Row>
                                    <Col span={4} style={{textAlign: 'right'}}>
                                        新工作经历：
                                    </Col>
                                    <Col span={16}>
                                        <Form.List name={'newWorkExperience'}>
                                            {(fields, {add, remove}) => (
                                                <>
                                                    {fields.map((field: any, index: number) => (
                                                        <Card
                                                            size="small"
                                                            bordered={true}
                                                            title={`第${index + 1}个`}
                                                            extra={
                                                                fields.length > 0 && (
                                                                    <MinusCircleOutlined
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                )
                                                            }
                                                        >
                                                            <Form.Item
                                                                {...field}
                                                                label={'工作单位'}
                                                                name={[field.name, 'name']}
                                                                fieldKey={[field.fieldKey, 'name']}
                                                            >
                                                                <Input></Input>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                label={'工作描述'}
                                                                name={[field.name, 'note']}
                                                                fieldKey={[field.fieldKey, 'note']}
                                                            >
                                                                <Input.TextArea></Input.TextArea>
                                                            </Form.Item>
                                                        </Card>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            style={{marginTop: 10}}
                                                            onClick={() => add()}
                                                            block
                                                            icon={<PlusOutlined/>}
                                                        >
                                                            添加
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            )}
                        </Form.Item>
                    </ProxyForm>
                </Modal>
            </Spin>
        </>
    );
};
export default connect(({common}: ConnectState) => ({
    common: common,
}))(TalentList);
