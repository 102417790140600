import Api from '../data/API';

export async function findContractList(params: any) {
	return Api.postWithAuth('/api/contract/find_contract_list', params);
}
export async function toBeSignedContractList(params: any) {
	return Api.postWithAuth('/api/contract/toBeSignedContractList', params);
}
export async function signedContractList(params: any) {
	return Api.postWithAuth('/api/contract/signedContractList', params);
}
export async function completedContractList(params: any) {
	return Api.postWithAuth('/api/contract/completedContractList', params);
}
export async function rescindedContractList(params: any) {
	return Api.postWithAuth('/api/contract/rescindedContractList', params);
}
export async function expiredButNotSignedContractList(params: any) {
	return Api.postWithAuth('/api/contract/expiredButNotSignedContractList', params);
}
export async function getContractPdfUrl(params: any) {
	return Api.postWithAuth('/api/contract/get_contract_pdf_url', params);
}

export async function undoSign(params: any) {
	return Api.postWithAuth('/api/contract/undo_sign', params);
}

export async function completionContract(params: any) {
	return Api.postWithAuth('/api/contract/completion_contract', params);
}

export async function shangshangqianContract(params: any) {
	return Api.postWithAuth('/api/contract/shangshangqianContract', params);
}

export async function archive(params: any) {
	return Api.postWithAuth('/api/contract/archive', params);
}
export async function batchArchive(params: any) {
	return Api.postWithAuth('/api/contract/batchArchive', params);
}
export async function getContractTemplateList(params: any) {

	return Api.postWithAuth('/api/contract/contractTemplateList', params)
}
export async function getHasPermContractTemplateList(params: any) {
	return Api.postWithAuth('/api/contract/getContractTemplateList', params)
}
export async function addContractTemplate(params: any) {
	return Api.postWithAuth('/api/contract/addContractTemplate', params);
}
export async function updateContractTemplate(params: any) {
	return Api.postWithAuth('/api/contract/updateContractTemplate', params);
}
export async function deleteContractTemplate(params: any) {
	return Api.postWithAuth('/api/contract/deleteContractTemplate ', params);
}
export async function createContractByTid(param: any) {
	return Api.postWithAuth('/api/contract/createContractByTid', param);
}

export async function download(param: any) {
	return Api.postDownloadWithAuth("/admin/api/contract/downloadContract", param);
}

export async function deleteContract(param: any) {
	return Api.postWithAuth("/admin/api/contract/deleteContract", param);
}
export async function getContractNo(param: any) {
	return Api.postWithAuth("/admin/api/contract/getContractNo", param);
}

export async function getPermContractGroupByContractId(param: any) {
	return Api.postWithAuth("/admin/api/contract/getPermContractGroupByContractId", param);
}
export async function savePermContract(param: any) {
	return Api.postWithAuth("/admin/api/contract/savePermContract", param);
}
export async function findContractPermGroup(param: any) {
	return Api.postWithAuth("/admin/api/contract/findContractPermGroup", param);
}
