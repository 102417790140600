import Api from '../data/API';

//import {} from "../../services/UserService";
export async function getUserList(params: any) {
	return Api.postWithAuth('/api/user/get_user_list', params);
}

export async function getUserListByName(param: any) {
	return Api.postWithAuth("/api/user/get_user_list_by_name", param)
}

export async function updateUserInfoPc(params: any) {
	return Api.postWithAuth('/api/user/update_user_info_pc', params);
}