import $ from 'jquery';

let fromData:any
class InputTime {
	static inputTime = new InputTime()
	getData = (id:any, callbackData:any)=> {
		const o = {};
		const a = $('#' + id).serializeArray();
		console.log('获得对象', $('#' + id));
		$.each(a, function () {
			if (o[this.name] !== undefined) {
				if (!o[this.name].push) {
					o[this.name] = [o[this.name]];
				}
				o[this.name].push(this.value || '');
			} else {
				o[this.name] = this.value || '';
			}
		});

		if (callbackData != undefined) {
			callbackData(o);
		}
	}
	getData0(id:any, callbackData:any) {
		const o:any = [];
		const a = $('#' + id).serializeArray();
		$.each(a, function () {
			if (this.name !== undefined) {
				if (!this.name.push) {
					this.name = this.name;
				}
				o.push(this.value || '');
			} else {
				o.push(this.value || '');
			}
		});

		if (callbackData != undefined) {
			callbackData(o);
		}
	}
	setCallBack0(callData:any) {
		fromData = callData;
	}

	gethouseholdDate(key:any) {
		const o = { key: key };
		console.log('key', key);
		let a = $('#householdDate').serializeArray();
		$.each(a, function () {
			if (o[this.name] !== undefined) {
				if (!o[this.name].push) {
					o[this.name] = [o[this.name]];
				}
				o[this.name].push(this.value || '');
			} else {
				o[this.name] = this.value || '';
			}
		});
		if (fromData != undefined) {
			fromData(o);
		}
	}
}

export default InputTime