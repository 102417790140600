import React, {useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import moment from "moment/moment";
import {Button, Image, Input, message, Modal, Select, Space} from "antd";
import CustomRangePicker from "../../../components/CustomRangePicker";
import {deleteEvent, getList} from "../../../services/TrafficEventService";
import {ActionType} from "@ant-design/pro-table";
import ApiImp from "../../../data/API";

const Index = () => {
    const actionRef = useRef<ActionType>();
    const [startTime, setStartTime] = useState(moment().subtract(7, "d").hours(0).minute(0).second(0));
    const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
    const [search, setSearch] = useState("");
    const columns = [
        {
            title: '车牌号',
            key: 'plateNumber',
            width: 100,
            dataIndex: 'plateNumber'
        },
        {
            title: '事件名称',
            key: 'eventName',
            width: 100,
            dataIndex: 'eventName'
        },
        {
            title: '事件时间',
            key: 'eventDate',
            width: 200,
            valueType: 'dateTime',
            dataIndex: 'eventDate'
        },
        {
            title: '速度',
            key: 'speed',
            width: 100,
            dataIndex: 'speed',
            render: (_, item) =>{
                return <span>{item.speed} km/h</span>
            }
        }, {
            title: '车道号',
            key: 'cameraName',
            width: 100,
            dataIndex: 'cameraName'
        },
        {
            title: '照片',
            key: 'picture',
            dataIndex: 'picture',
            width: 100,
            render: (text: string, record: any) => {
                return <>{
                    text === '-' ? '-': <Image src={ApiImp.cdnRootPath + text} style={{ width: 100, height: 100 }}/>
                }</>
            }
        },
        {
            title: '所有人姓名',
            key: 'ownerName',
            width: 100,
            dataIndex: 'ownerName'
        }, {
            title: '所有人手机号',
            key: 'ownerPhone',
            width: 100,
            dataIndex: 'ownerPhone'
        },
         {
            title: '所有人部门',
            key: 'departmentName',
             width: 100,
             dataIndex: 'departmentName'
        },{
            title:'操作',
            width: 80,
            fixed: 'right',
            render : (text, record) => {
                return <Space>
                    <a onClick={() => {
                        Modal.confirm({
                            title: '您确定要删除该记录吗？',
                            okType: "danger",
                            onOk: async () => {
                                let rsp = await deleteEvent({id: record.id})
                                if (rsp.err === 0) {
                                    message.success('删除成功')
                                    actionRef.current.reload();
                                } else {
                                    message.error(rsp.msg)
                                }
                            }
                        })
                    }}>删除</a>
                </Space>
            }
        }
    ]
    const tableSearchBarRender = () => [
        <Space>
            <CustomRangePicker
                onChange={(startTime, endTime) => {
                    setStartTime(startTime);
                    setEndTime(endTime);
                }}
                selectType={3}
                startTime={startTime}
                endTime={endTime}
            />
            <Input
                placeholder={"车牌号"}
                onChange={(e: any) => {
                    setSearch(e.target.value);
                }}
            />
            <Button
                type={"primary"}
                onClick={() => {
                    actionRef.current.reloadAndRest();
                }}
            >
                查询
            </Button>
        </Space>
    ];
    const loadList = async (params: any) => {
        let rsp = await getList(params);
        if (rsp.err == 0) {
            return {
                data: rsp.data,
                total: rsp.total
            }
        }
        return {
            data: [],
            total: 0
        }
    }
    return (
        <GSTable
            actionRef={actionRef}
            searchBarRender={tableSearchBarRender()}
            columns={columns}
            request={async (params: any) => {
                let data = {
                    page: params.current,
                    size: params.pageSize,
                    search: search,
                    startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
                    endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
                };
                return loadList(data);
            }}
        >

        </GSTable>
    );
}
export default Index;
