import React from 'react';
import { Card, Col, Image, Row } from 'antd';
import Api from '../../data/API';
import appConfig from '../../appConfig';
const DataScreen: React.FC = () => {
	return (<div style={{paddingLeft: 10}}>
		<Row gutter={16} >
			<Col  span={4}>
				<Card
					bodyStyle={{ paddingLeft: 20 }}
					style={{ width: '100%', height: 221.7 }}
					cover={<Image height={128.7} src={"https://pcdn.guosim.com/yuanqu.png"} />}
					actions={[
						<a
							style={{ height: 25 }}
							href={"https://mobileapp.bjckxz.com/3d/controlPlatform/"}
							target="_blank"
						>
							查看
						</a>
					]}
				>
					<div className={"omit1"} style={{ display: 'flex', alignItems: 'center'  }}>园区运营大屏</div>
				</Card>
			</Col>
			<Col  span={4}>
				<Card
					bodyStyle={{ paddingLeft: 20 }}
					style={{ width: '100%', height: 221.7 }}
					cover={<Image height={128.7} src={"https://pcdn.guosim.com/changye.png"} />}
					actions={[
						<a
							style={{ height: 25 }}
							href={"https://mobileapp.bjckxz.com/3d/industry3d/"}
							target="_blank"
						>
							查看
						</a>
					]}
				>
					<div className={"omit1"} style={{ display: 'flex', alignItems: 'center'  }}>产业运营大屏</div>
				</Card>
			</Col>
			<Col  span={4}>
				<Card
					bodyStyle={{ paddingLeft: 20 }}
					style={{ width: '100%', height: 221.7 }}
					cover={<Image height={128.7} src={"https://pcdn.guosim.com/fangyi.png"} />}
					actions={[
						<a
							style={{ height: 25 }}
							href={"https://wy.guosim.com/epidemic-prevention-report#/"}
							target="_blank"
						>
							查看
						</a>
					]}
				>
					<div className={"omit1"} style={{ display: 'flex', alignItems: 'center'  }}>防疫管理大屏</div>
				</Card>
			</Col>
		</Row>
	</div>);
};
export default DataScreen;
