import * as React from "react";
import MiniApp, { MiniAppMenu } from "../../util/MiniApp";
import CarManager from "./pages/CarManager";
import CarPathManager from "./pages/CarPathManager";
import CarAsyncList from "./pages/CarAsyncList";
import BatchCar from "./pages/BatchCar";
import CarRecord from "./pages/CarRecord";

export default function CarMiniApp() {
    const rootUrl = "/car";
    const menus: MiniAppMenu[] = [
        { authority: "car-manager:select", name: "车辆管理", path: "car-manager", component: CarManager },
        { authority: "car-manager:record:select", name: "车辆通行记录", path: "car-record", component: CarRecord },
        { authority: "car-tao-mamager:select", name: "车场设置", path: "car-path-manager", component: CarPathManager },
        {
            authority: "car-sync-record-mamager:select",
            name: "车辆同步记录",
            path: "car-record-sync-manager",
            component: CarAsyncList
        },
        { authority: "car-manager:batch-import", name: "批量导入", path: "batch-car", component: BatchCar }
    ];
    return <MiniApp miniAppName={"车辆管理"} rootUrl={rootUrl} menus={menus}/>;
}
