import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { findSmsRecord, findSmsSendBatchList } from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Modal, Tag } from 'antd';
import * as moment from 'moment';
import { batchList } from '../../../services/PayCostManagementService';
import SmsDetail from '../../notification/components/SmsDetail';
import BillRecordDetailList from './BillRecordDetailList';
import LOGO from './ckxz_logo.png';
import DAL from '../../../data/DAL';
import { printBill, updateOrderPrintStatus } from '../../../services/orderService';
let orderUuid;
const ReceiptOfCharges: React.FC = () => {
	const [otherBillList, setotherBillList] = useState([]);
	const [yjBillList, setyjBillList] = useState([]);
	const payMent = useRef('');
	const departName = useRef('');
	const userName = useRef('');
	const item = useRef<any>({});
	const room = useRef<any>({})
	const [contractNo, setcontractNo] = useState("")
	useEffect(() => {
		orderUuid = DAL.dal.getQueryString('orderUuid');
		// orderUuid = '2b9c48cd-6dc9-4815-8f72-c8031ddd4989';
		getData().then();
	}, []);
	const getData = async () => {
		const res = await printBill({ orderUuid: orderUuid });
		if (res.err == 0) {
			const datas = res.data.chargeBills || [];
			room.current = res.data.room || {}
			let other = [];
			let yj = [];
			const otehtMap = new Map();
			setcontractNo(res.data.contractNo || "")
			if (datas.length > 0) {
				item.current = datas[0];
				datas.forEach((item) => {
					if ('押金类' == item.billType) {
						yj.push(item);
					} else {
						if (otehtMap.get(item.name) != undefined) {
							otehtMap.get(item.name).push(item);
						} else {
							const data = [];
							data.push(item);
							otehtMap.set(item.name, data);
						}
					}
				});
			}

			otehtMap.forEach((value, key) => {
				const item = { name: '', actualAmount: 0, startTime: '', endTime: '' };
				value.forEach((element, index) => {
					if (value.length == 1) {
						item.name = element.name;
						item.actualAmount = item.actualAmount + element.actualAmount;
						item.startTime = element.startTime;
						item.endTime = element.endTime;
					} else {
						if (index == 0) {
							item.name = element.name;
							item.actualAmount = item.actualAmount + element.actualAmount;
							item.startTime = element.startTime;
						} else {
							item.actualAmount = item.actualAmount + element.actualAmount;
							item.endTime = element.endTime;
						}
					}
				});
				other.push(item);
			});

			departName.current = res.data.departName || '';
			userName.current = res.data.userName || '';
			payMent.current = res.data.payment || '微信支付';
			setotherBillList(other);
			setyjBillList(yj);
			console.log('data;::', res);
		}
	};

	const changeNumMoneyToChinese = (money) => {
		var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'); //汉字的数字
		var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
		var cnIntUnits = new Array('', '万', '亿', '兆'); //对应整数部分扩展单位
		var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
		var cnInteger = '整'; //整数金额时后面跟的字符
		var cnIntLast = '元'; //整型完以后的单位
		var maxNum = 999999999999999.9999; //最大处理的数字
		var IntegerNum; //金额整数部分
		var DecimalNum; //金额小数部分
		var ChineseStr = ''; //输出的中文金额字符串
		var parts; //分离金额后用的数组，预定义
		var Symbol = ''; //正负值标记
		if (money == '') {
			return '';
		}
		money = parseFloat(money);
		if (money >= maxNum) {
			alert('超出最大处理数字');
			return '';
		}
		if (money == 0) {
			ChineseStr = cnNums[0] + cnIntLast + cnInteger;
			return ChineseStr;
		}
		if (money < 0) {
			money = -money;
			Symbol = '负 ';
		}
		money = money.toString(); //转换为字符串
		if (money.indexOf('.') == -1) {
			IntegerNum = money;
			DecimalNum = '';
		} else {
			parts = money.split('.');
			IntegerNum = parts[0];
			DecimalNum = parts[1].substr(0, 4);
		}
		if (parseInt(IntegerNum, 10) > 0) {
			//获取整型部分转换
			var zeroCount = 0;
			var IntLen = IntegerNum.length;
			for (var i = 0; i < IntLen; i++) {
				var n = IntegerNum.substr(i, 1);
				var p = IntLen - i - 1;
				var q = p / 4;
				var m = p % 4;
				if (n == '0') {
					zeroCount++;
				} else {
					if (zeroCount > 0) {
						ChineseStr += cnNums[0];
					}
					zeroCount = 0; //归零
					ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
				}
				if (m == 0 && zeroCount < 4) {
					ChineseStr += cnIntUnits[q];
				}
			}
			ChineseStr += cnIntLast;
			//整型部分处理完毕
		}
		if (DecimalNum != '') {
			//小数部分
			var decLen = DecimalNum.length;
			for (var i = 0; i < decLen; i++) {
				var n = DecimalNum.substr(i, 1);
				if (n != '0') {
					ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
				}
			}
		}
		if (ChineseStr == '') {
			ChineseStr += cnNums[0] + cnIntLast + cnInteger;
		} else if (DecimalNum == '') {
			ChineseStr += cnInteger;
		}
		ChineseStr = Symbol + ChineseStr;

		return ChineseStr;
	};
	const getString = (string) => {
		if (DAL.dal.isNotNull(string)) {
			return string;
		} else {
			return '';
		}
	};

	const dayin = (id) => {
		updateItem().then()
		var printStr = "<html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'></head><body >";
		var content = '';
		var print = '';
		print = document.getElementById(id).innerHTML;
		printStr = printStr + print + '</body></html>';
		window.document.write(printStr);
		window.document.close(); //这句很重要，没有就无法实现
		window.print();
		window.location.reload();
	};
	const updateItem =async () => {
		const res = await updateOrderPrintStatus({ contractNo: contractNo })
	}
	const getSmallPrice = () => {
		let price = 0;
		otherBillList.map((item) => {
			price = price + item.actualAmount;
		});
		return (price / 100).toFixed(2);
	};

	const renderEmptyTR = () => {
		let billLength = (otherBillList || []).length;
		let emptyLength = 3 - billLength;
		let emptyData = [];
		for (let i = 0 ; i< emptyLength ; i++) {
			emptyData.push(i);
		}
		return (otherBillList || []).length < 3 && emptyData.map(item => {
			return (
				<>
					<tr style={{height: 40}}>
						<td style={{ textAlign: 'center' }}>\</td>
						<td style={{ textAlign: 'center' }}>\</td>
						<td style={{ textAlign: 'center' }}>
\
						</td>
						<td style={{ textAlign: 'center' }}>\</td>
						<td style={{ textAlign: 'center' }}>\</td>
						<td style={{ textAlign: 'center' }}>\</td>
						<td style={{ textAlign: 'center' }}>\</td>
					</tr>
				</>
			);
		})
	}

	return (
		<>
			<div id="piaoju" className="piaoju">
				<div className="main">
					<div className="body">
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							{/* NO：<span style={{ color: '#9C5223' }}>2396827</span> */}
						</div>
						<div className="title" style={{ display: 'flex', justifyContent: 'center' }}>
							<div>温泉科技园一期收费专用收据</div>
						</div>
						<div style={{ display: 'flex' }}>
							<img src={LOGO} style={{ height: 40 }}></img>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>开票日期：{moment().format('YYYY-MM-DD')}</div>
							<div>单据号：{getString(item.current.contractNo)}</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>业主公司（人）：{getString(item.current.custName)}</div>
							<div>支付类型：微信支付</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>租用位置（地址）：{getString(item.current.roomId)}</div>
							<div>面积：{getString(room.current.chargeArea)}㎡</div>
							<div></div>
						</div>
						<table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
							<tr style={{height: 40}}>
								<th style={{ textAlign: 'center' }}>序号</th>
								<th style={{ textAlign: 'center' }}>项目名称</th>
								<th style={{ textAlign: 'center' }}>费用期间</th>
								<th style={{ textAlign: 'center' }}>上次抄表</th>
								<th style={{ textAlign: 'center' }}>本次抄表</th>
								<th style={{ textAlign: 'center' }}>用量</th>
								<th style={{ textAlign: 'center' }}>金额</th>
							</tr>
							{otherBillList.map((item, index) => {
								return (
									<>
										<tr style={{height: 40}}>
											<td style={{ textAlign: 'center' }}>{index + 1}</td>
											<td>{item.name}</td>
											<td style={{ textAlign: 'center' }}>
												{moment(item.startTime).format('YYYY-MM-DD')}~
												{moment(item.endTime).format(

													'YYYY-MM-DD')}
											</td>
											<td style={{ textAlign: 'end', paddingRight: 10 }}>0.00</td>
											<td style={{ textAlign: 'end', paddingRight: 10 }}>0.00</td>
											<td style={{ textAlign: 'end', paddingRight: 10 }}>0.00</td>
											<td style={{ textAlign: 'end' , paddingRight: 10}}>{(item.actualAmount / 100).toFixed(2)}</td>
										</tr>
									</>
								);
							})}
							{/*{*/}
							{/*	renderEmptyTR()*/}
							{/*}*/}

							<tr style={ {height: 40}}>
								<td colSpan={3} style={{ textAlign: 'center' }} rowSpan={2}>
									合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计
								</td>
								<td style={{ textAlign: 'end' ,paddingRight: 10}} colSpan={2}>
									人民币小写
								</td>
								<td style={{ textAlign: 'end', paddingRight: 10 }} colSpan={2}>
									￥ {getSmallPrice()}
								</td>
							</tr>
							<tr style={ {height: 40}}>
								<td style={{ textAlign: 'end' ,paddingRight: 10}} colSpan={2}>
									人民币大写
								</td>
								<td style={{ textAlign: 'end', paddingRight: 10 }} colSpan={2}>
									{changeNumMoneyToChinese(getSmallPrice())}
								</td>
							</tr>
						</table>
						<div style={{ display: 'flex', marginTop:10 }}>
							注：第一联（白联）财务联；第二联（粉联）客户联；第三联（黄联）存根联。
						</div>
						<div style={{ width: '100%', justifyContent: 'space-around', display: 'flex', height: '40px' }}>
							<div style={{ flex: 1, height: '40px', lineHeight: '40px' }}>
								收费员：{userName.current}
							</div>
							<div style={{flex: 1,  height: '40px', lineHeight: '40px' }}>
								收费部门：{departName.current}
							</div>
							<div style={{height: '40px', lineHeight: '40px', marginRight: 70 }}>收款单位（章）</div>
						</div>
					</div>
				</div>
			</div>
			<Button onClick={() => dayin('piaoju')}>打印</Button>

			{yjBillList.map((yjItem, index) => {
				return (
					<>
						<div id={'yajin' + index} className="piaoju">
							<div className="main">
								<div className="body">
									<div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
									<div className="title" style={{ display: 'flex', justifyContent: 'center' }}>
										<div>温泉科技园一期&nbsp;&nbsp;押金收取凭证</div>
									</div>

									<table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
										<tr>
											<td style={{ textAlign: 'center' }}>
												开票时间：{moment().format('YYYY-MM-DD')}
											</td>
											<td style={{ textAlign: 'center' }}>
												单据编号：{getString(yjItem.contractNo)}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'center' }}>
												缴费单位（人）：{getString(yjItem.custName)}
											</td>
											<td style={{ textAlign: 'center' }}>支付类型：微信支付</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'center' }}>押金类别</td>
											<td style={{ textAlign: 'center' }}>金额</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'center' }}>{getString(yjItem.name)}</td>
											<td style={{ textAlign: 'center' }}>
												{(yjItem.actualAmount / 100).toFixed(2)}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'center' }}>金额大写</td>
											<td style={{ textAlign: 'center' }}>
												{changeNumMoneyToChinese((yjItem.actualAmount / 100).toFixed(2))}
											</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'center' }}>收款单位（章）</td>
											<td style={{ textAlign: 'center' }}>收款人：{userName.current}</td>
										</tr>
									</table>

									<div style={{ display: 'flex', justifyContent: 'center',marginTop:10 }}>
										注：第一联（白联）财务联；第二联（粉联）客户联；第三联（黄联）存根联。
									</div>
								</div>
							</div>
						</div>
						<Button onClick={() => dayin('yajin' + index)}>打印</Button>
					</>
				);
			})}
		</>
	);
};

export default ReceiptOfCharges;
