import Api from '../data/API';

export async function findDemandCollection(params: any) {
	return Api.postWithAuth('/api/demand/find_demand_collection', params);
}

export async function findDemandType(params: any) {
	return Api.postWithAuth('/api/demand/find_demand_type', params);
}

export async function findLiuchengdanName(params: any) {
	return Api.postWithAuth('/api/liucheng/find_liuchengdan_name', params);
}

export async function addDemandType(params: any) {
	return Api.postWithAuth('/api/demand/add_demand_type', params);
}
export async function updateDemandType(params: any) {
	return Api.postWithAuth('/api/demand/update_demand_type', params);
}

export async function deleteDemandType(params: any) {
	return Api.postWithAuth('/api/demand/delete_demand_type', params);
}
export async function findDemandOne(params: any) {
	return Api.postWithAuth('/api/demand/find_demand_one', params);
}
export async function nextStep(params: any) {
	return Api.postWithAuth('/api/demand/next_step', params);
}
