import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import AddWenzhang from './components/AddWenzhang';
import WenZhangInfo from './components/WenZhangInfo';
import LookWenzhang from './components/LookWenzhang';
import LookWenzhangType from './components/LookWenzhangType';
import LookWenzhangTag from './pages/LookWenzhangTag';

export default function WenzhangMiniApp() {
	const rootUrl = '/wenzhang';
	const menus: MiniAppMenu[] = [
		{
			authority: 'community_service:wenzhang_type_list',
			name: '资讯列表',
			path: 'find_wenzhang_list',
			component: LookWenzhang,
		},
		{
			authority: 'community_service:wenzhang_type_list',
			name: '资讯类目',
			path: 'find_wenzhang_type',
			component: LookWenzhangType,
		},
		{
			authority: 'community_service:wenzhang_tag_list',
			name: '资讯标签',
			path: 'find_wenzhang_tag',
			component: LookWenzhangTag,
		},
	];
	const routes: MiniAppRoute[] = [
		{
			path: 'add_wenzhang',
			component: AddWenzhang,
		},
		// {
		//     path: 'update_wenzhang',
		//     components: UpdateWenzhang
		// },
		// {
		//     path: 'find_details',
		//     components: UpdateWenzhang
		// },
		{
			path: 'find_wenzhang_list',
			component: LookWenzhang,
		},
		{
			path: 'add_wenzhang_type',
			component: LookWenzhangType,
		},
		// {
		//     path: 'find_tiezi_details',
		//     components: TieziXiangQing
		// },
		{
			path: 'wenzhang_info',
			component: WenZhangInfo,
		},
	];

	return <MiniApp miniAppName={'资讯'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
