import {
	Button,
	Card,
	Col,
	Collapse,
	Form,
	Input,
	InputNumber,
	message,
	Row,
	Select,
	Space,
	TreeSelect,
	Switch,
} from 'antd';
import UploadImg from '../../../components/UploadImg';
import { CloseCircleOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import WangEditorPro from '../../../components/WangEditorPro';
import React, { useEffect, useState } from 'react';
import { addPageSet, getProductByUuid, getProductCateList, updatePageSet } from '../../../services/Product';
import { FormInstance } from 'antd/es/form';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import DAL from '../../../data/DAL';
import { Link } from 'react-router-dom';
import { getContractTemplateList } from '../../../services/Contract';
import { findFee } from '../../../services/orderService';
import appConfig from '../../../appConfig';
import getAdViceUrls from '../../../models/AdviceUrls';

class ButtonParamsData {
	condition: string = '';
	msg: string = '';
	type: string = 'liuChengDan';
	value: string = '';
}

class ContentData {
	content: string = '';
	tab: string = '';
	hasPadding: boolean = true;
}

class ProductParamData {
	guige: string = '';
	serviceFeeType: any = '金额';
	serviceFee: any = 0;
	guanFee: any = 0;
	yajin: any = 0;
}

const { Panel } = Collapse;
let add: any;
let productUuid: any;
let sortBy: any;
let cateUuid: any;
const AddORUpdateProduct: React.FC = (props: any) => {
	const { history } = props;
	const titleCol = 3;
	const contentCol = 16;
	const dal = new DAL();
	useEffect(() => {
		add = Boolean(dal.getQueryString('add'));
		console.log('add::', add);
		productUuid = dal.getQueryString('uuid');
		sortBy = dal.getQueryString('sortBy');
		cateUuid = dal.getQueryString('cateUuid');
		if (!add) {
			getData();
		}
		getLiuchengDanList();
		getProductCateData();
		getContract();
		getFees();
	}, []);

	const [item, setItem] = useState<any>({});
	const [productCateList, setProductCateList] = useState([]);
	const [liuchengList, setLiuchengList] = useState([]);
	const [productImg, setProductImg] = useState('');
	const [form] = Form.useForm<FormInstance>();
	const [name, setName] = useState('');
	const [uuid, setUuid] = useState('');
	const [phone, setPhone] = useState('');
	const [productPrice, setProductPrice] = useState<any>(0);
	const [contentParams, setContentParams] = useState<any>([]);
	const [productCates, setProductCates] = useState<any>([]);
	const [contractList, setContractList] = useState<any>([]);
	const [button, setButton] = useState([{ name: '', type: '', value: '', params: [] }]);
	const [productParams, setProductParams] = useState([new ProductParamData()]);
	const [personalContract, setPersonalContract] = useState('');
	const [companyContract, setCompanyContract] = useState('');
	const [contractType, setContractType] = useState('');
	const [feeTypeUuid, setFeeTypeUuid] = useState('');
	const [fees, setFees] = useState([]);
	const [type, setType] = useState('自有平台');
	const [adviceUrl, setadviceUrl] = useState('');
	const [contractApprove, setContractApprove] = useState(false);
	const getData = async () => {
		const res = await getProductByUuid({ uuid: productUuid });
		console.log('resgetData', res);
		if (res.err == 0) {
			const item = res.data;
			setItem(res.data);
			setItem(item);
			setUuid(item.uuid);
			setType(item.type);
			setFeeTypeUuid(item.feeTypeUuid);
			setContractType(item.contractType);
			setProductCates(
				item.productType !== undefined && item.productType !== null && item.productType !== ''
					? item.productType.split(',')
					: []
			);
			setProductPrice(item.productPrice);
			setProductImg(item.productImg);
			setButton(JSON.parse(item.button || '[]'));
			setContentParams(JSON.parse(item.content || '[]'));
			setName(item.name);
			setCompanyContract(item.companyContract);
			setPersonalContract(item.personalContract);
			setContractApprove(item.contractApprove);
			setadviceUrl(item.adviceUrl)
			setPhone(item.phone);
			setProductParams(JSON.parse(item.productParams || '[]'));
		}
	};
	const getFees = async () => {
		const res = await findFee({});
		console.log('科目列表', res);
		setFees(res.data);
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const getContract = async () => {
		const res = await getContractTemplateList({});
		if (res.err === 0) {
			console.log('contractList::', res.data);
			setContractList(res.data);
		}
	};
	const getLiuchengDanList = async () => {
		const data = {
			state: true,
			selectedTags: '',
			searchContent: '',
		};
		const res = await getAllLiucheng(data);
		console.log('res:::', res);
		if (res.err === 0) {
			setLiuchengList(res.data);
		}
	};
	const getProductCateData = async () => {
		let rsp = await getProductCateList({});
		if (rsp.err === 0) {
			setProductCateList(rsp.data);
		} else {
			setProductCateList([]);
		}
	};
	const addItem = async () => {
		let sortBy = 0;
		if (productCates.length == 0) {
			message.warning('请选择类目');
			return;
		}
		const formData: any = {
			name: name,
			button: JSON.stringify(button),
			content: JSON.stringify(contentParams),
			productParams: JSON.stringify(productParams),
			sortBy: 99,
			productImg: productImg,
			productPrice: productPrice,
			productType: productCates.toString(),
			personalContract: personalContract,
			companyContract: companyContract,
			contractType: contractType,
			feeTypeUuid: feeTypeUuid,
			type: type,
			contractApprove: contractApprove,
			phone: phone,
			adviceUrl:adviceUrl,
		};
		const res = await addPageSet(formData);
		if (res.err === 0) {
			message.success('添加成功');
			history.push('/product/product?cateUuid=' + cateUuid);
			// if(actionRef.current){
			// 	actionRef.current.reload()
			// }
		} else {
			message.error('添加失败');
		}
	};
	const getFileName = (value: any) => {
		setProductImg(value);
	};
	const updateItem = async () => {
		if (productCates.length == 0) {
			message.warning('请选择类目');
			return;
		}
		const formData: any = {
			id: item.id,
			uuid: item.uuid,
			name: name,
			button: JSON.stringify(button),
			content: JSON.stringify(contentParams),
			productParams: JSON.stringify(productParams),
			productImg: productImg,
			productPrice: productPrice,
			productType: productCates.toString(),
			personalContract: personalContract,
			companyContract: companyContract,
			contractType: contractType,
			feeTypeUuid: feeTypeUuid,
			type: type,
			contractApprove: contractApprove,
			phone: phone,
			adviceUrl:adviceUrl,
		};
		const res = await updatePageSet(formData);
		if (res.err === 0) {
			message.success('修改成功');
			history.push('/product/product?cateUuid=' + cateUuid);
			// window.location.href = '../product/product';
			// if(actionRef.current){
			// 	actionRef.current.reload()
			// }
		} else {
			message.error('修改失败');
		}
	};
	const changeChildrenValue = (data: any, index: any, value: any, type: any) => {
		console.log('data:::', data);
		if (data) {
			data[index][type] = value;
		}
		setButton([...button]);
	};
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	const addButtonParams = (data: any) => {
		console.log('data:::', data);
		if (data) {
			if (data.params == undefined) {
				data.params = [];
				data.params.push(new ButtonParamsData());
			} else {
				data.params.push(new ButtonParamsData());
			}
		}
		console.log('data:::', data);
		setButton([...button]);
	};
	const addProductParams = () => {
		productParams.push(new ProductParamData());
		setProductParams([...productParams]);
	};
	const deleteButton = (data: any, index: any) => {
		if (data) {
			data.splice(index, 1);
		}
		setButton([...button]);
	};
	const deleteProductParams = (index: any) => {
		productParams.splice(index, 1);
		setProductParams([...productParams]);
	};
	const deleteContentParams = (index: any) => {
		contentParams.splice(index, 1);
		setContentParams([...contentParams]);
	};
	const changeProductParam = (index: any, value: any, type: any) => {
		productParams[index][type] = value;
		setProductParams([...productParams]);
	};
	const changeContentParam = (index: any, value: any, type: any) => {
		contentParams[index][type] = value;
		setContentParams([...contentParams]);
	};
	const addButton = () => {
		button.push({ name: '', type: '', value: '', params: [] });
		setButton([...button]);
	};
	const addContentParams = () => {
		contentParams.push(new ContentData());
		setContentParams([...contentParams]);
	};

	const getIframe = () => {
		var win = window.frames;
		let ifra = win[0];
		ifra.location.href =
			appConfig.shequUrl +
			'/preview.html?previewUrl=' +
			appConfig.mobileAppUrl +
			'/service' +
			'/ckxz/WidgetPage?userId=' +
			localStorage.getItem('userId') +
			'&wuyeUuid=' +
			localStorage.getItem('wyUuid') +
			'&productUuid=' +
			productUuid +
			'&token=' +
			localStorage.getItem('token') +
			'&ss=ss';
	};
	const renderParams = (item: any, pIndex: number) => {
		return (
			<>
				{item.params !== undefined &&
					item.params.length > 0 &&
					item.params.map((data: any, index: any) => (
						<>
							<Row align={'middle'} gutter={15}>
								<Col span={titleCol} style={{ textAlign: 'right', marginTop: 10 }}>
									条件:
								</Col>
								<Col span={4} style={{ marginTop: 10 }}>
									<Select
										placeholder={'条件'}
										style={{ width: '100%' }}
										showSearch
										value={data.condition == '' ? undefined : data.condition}
										onChange={(value: any) => {
											changeChildrenValue(item.params, index, value, 'condition');
										}}
										optionFilterProp="children"
										allowClear
									>
										<Select.Option value={'SMRZ'} key={'SMRZ'}>
											实名认证
										</Select.Option>
										<Select.Option value={'XZTD'} key={'XZTD'}>
											选择团队
										</Select.Option>
										<Select.Option value={'CARZ'} key={'CARZ'}>
											CA认证
										</Select.Option>
										<Select.Option value={'GUANSIGUANLIREN'} key={'GUANSIGUANLIREN'}>
											公司管理人
										</Select.Option>
										<Select.Option value={'YUANSUO'} key={'YUANSUO'}>
											拥有院所
										</Select.Option>
									</Select>
								</Col>
								{/* <Col span={4} style={{ marginTop: 10 }}>
									<Input
										placeholder={'弹框提示信息'}
										value={data.msg}
										onChange={(e) => {
											changeChildrenValue(item.params, index, e.target.value, 'msg');
										}}
									/>
								</Col>
								<Col span={3} style={{ marginTop: 10 }}>
									<Select
										style={{ width: '100%' }}
										value={data.type}
										onChange={(value: any) => {
											changeChildrenValue(item.params, index, value, 'type');
										}}
									>
										<Select.Option value={'liuChengDan'}>流程单</Select.Option>
										<Select.Option value={'url'}>跳转链接</Select.Option>
									</Select>
								</Col>
								<Col span={3} style={{ marginTop: 10 }}>
									{data.type === 'liuChengDan' ? (
										<Select
											placeholder={'流程单'}
											style={{ width: '100%' }}
											showSearch
											value={data.value}
											onChange={(value: any) => {
												changeChildrenValue(item.params, index, value, 'value');
											}}
											optionFilterProp="children"
											allowClear
										>
											{liuchengList.map((item: any) => {
												return (
													<Select.Option value={item.uuid} key={item.uuid}>
														{item.name}
													</Select.Option>
												);
											})}
										</Select>
									) : (
										<Input
											placeholder={'链接'}
											value={data.value}
											onChange={(e) => {
												changeChildrenValue(item.params, index, e.target.value, 'value');
											}}
										/>
									)}
								</Col> */}
								<Col span={2} style={{ textAlign: 'right', marginTop: 10 }}>
									<CloseCircleOutlined
										style={{ cursor: 'pointer' }}
										onClick={() => {
											deleteButton(item.params, index);
										}}
									/>
								</Col>
							</Row>
						</>
					))}
				<Row align={'middle'} gutter={15}>
					<Col span={titleCol} style={{ textAlign: 'right' }}></Col>
					<Col span={contentCol}>
						<Button
							type={'primary'}
							onClick={() => {
								addButtonParams(item);
							}}
						>
							添加条件
						</Button>
					</Col>
				</Row>
			</>
		);
	};

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
				<Link style={{ color: '#000' }} to={'/product/product?cateUuid=' + cateUuid}>
					<LeftOutlined style={{ marginRight: 10, cursor: 'pointer' }} />
				</Link>
				<div style={{ fontSize: 20, fontWeight: 'bold' }}>{add ? '新建产品' : '修改产品'}</div>
			</div>
			<div>
				<Row>
					<Col span={15}>
						<Collapse
							defaultActiveKey={['baseInfo', 'priceInfo', 'contractInfo']}
							style={{ height: document.body.clientHeight - 250, overflowY: 'auto' }}
							ghost
						>
							<Panel style={{ backgroundColor: '#fff' }} header="基本信息" key={'baseInfo'}>
								<Row align={'middle'} gutter={15}>
									<Col span={titleCol} style={{ textAlign: 'right' }}>
										名称:
									</Col>
									<Col span={contentCol}>
										<Input
											value={name}
											onChange={(e) => {
												setName(e.target.value);
											}}
										/>
									</Col>
								</Row>
								<Row align={'middle'} gutter={15}>
									<Col span={titleCol} style={{ textAlign: 'right' }}>
										产品图:
									</Col>
									<Col span={contentCol}>
										<UploadImg
											uploadParams={{ wuye_uuid: localStorage.getItem('wyUuid') }}
											action={'public'}
											getFileName={(value: any) => {
												getFileName(value);
											}}
											hiddenImgCorp={false}
											photo={productImg}
											heightRatio={3}
											widthRatio={5}
										/>
										<div>请准备分辨率比例为5:3的图片</div>
									</Col>
								</Row>
								<Row align={'middle'} gutter={15}>
									<Col span={titleCol} style={{ textAlign: 'right' }}>
										产品类目<span style={{ color: 'red' }}>*</span>:
									</Col>
									<Col span={contentCol}>
										<TreeSelect
											value={productCates}
											style={{ width: '100%' }}
											dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
											treeData={productCateList}
											allowClear
											multiple
											treeDefaultExpandAll
											treeCheckable={true}
											onChange={(value: string) => {
												setProductCates(value);
											}}
										/>
									</Col>
								</Row>
								<Row align={'middle'} gutter={15}>
									<Col span={titleCol} style={{ textAlign: 'right' }}>
										联系电话:
									</Col>
									<Col span={contentCol}>
										<Input
											value={phone}
											onChange={(e) => {
												setPhone(e.target.value);
											}}
										/>
									</Col>
								</Row>
								<Row align={'middle'} gutter={15}>
									<Col span={titleCol} style={{ textAlign: 'right' }}>
										咨询:
									</Col>
									<Col span={contentCol}>
										<Select
											style={{width:'100%'}}
											value={adviceUrl}
											onChange={(value) => {
												setadviceUrl(value);
											}}
										>
											{getAdViceUrls().urls.map((item: any) => {
												return (
													<>
														<Select.Option value={item.value}>{item.name}</Select.Option>
													</>
												);
											})}
										</Select>
										{/* <Input
											value={phone}
											onChange={(e) => {
												setPhone(e.target.value);
											}}
										/> */}
									</Col>
								</Row>
								<Row align={'middle'} gutter={15}>
									<Col span={titleCol} style={{ textAlign: 'right' }}>
										产品来源:
									</Col>
									<Col span={contentCol}>
										<Select
											key={type}
											style={{ width: '100%' }}
											defaultValue={type}
											onChange={(e: any) => {
												if (contentParams.length === 0) {
													setContentParams([new ContentData()]);
												}
												setType(e);
											}}
										>
											<Select.Option value={'自有平台'}>自有平台</Select.Option>
											<Select.Option value={'三方网址'}>三方网址</Select.Option>
											<Select.Option value={'小程序'}>小程序</Select.Option>
										</Select>
									</Col>
								</Row>
								{type === '三方网址' && (
									<>
										<Row align={'middle'} gutter={15}>
											<Col span={titleCol} style={{ textAlign: 'right' }}>
												网址:
											</Col>
											<Col span={contentCol}>
												<Input.TextArea
													value={
														contentParams.length > 0 ? contentParams[0].content || '' : ''
													}
													style={{ width: '100%' }}
													onChange={(e: any) => {
														changeContentParam(0, e.target.value, 'content');
													}}
												/>
											</Col>
										</Row>
									</>
								)}
								{type === '小程序' && (
									<>
										<Row align={'middle'} gutter={15}>
											<Col span={titleCol} style={{ textAlign: 'right' }}>
												小程序原始ID:
											</Col>
											<Col span={contentCol}>
												<Input
													value={contentParams[0].userName || ''}
													style={{ width: '100%' }}
													onChange={(e: any) => {
														changeContentParam(0, e.target.value, 'userName');
													}}
												/>
											</Col>
										</Row>
										<Row align={'middle'} gutter={15}>
											<Col span={titleCol} style={{ textAlign: 'right' }}>
												小程序页面路径:
											</Col>
											<Col span={contentCol}>
												<Input
													value={contentParams[0].path || ''}
													style={{ width: '100%' }}
													onChange={(e: any) => {
														changeContentParam(0, e.target.value, 'path');
													}}
												/>
											</Col>
										</Row>
									</>
								)}
								{type === '自有平台' && (
									<>
										{contentParams !== undefined &&
											contentParams.length > 0 &&
											contentParams.map((item: ContentData, index: any) => (
												<Row gutter={15}>
													<Col span={titleCol} style={{ textAlign: 'right', marginTop: 10 }}>
														{index == 0 && '内容:'}
													</Col>
													<Col span={contentCol} style={{ marginTop: 10 }}>
														<Card
															title={'第' + (index + 1) + '个标签'}
															extra={
																<CloseCircleOutlined
																	style={{ cursor: 'pointer' }}
																	onClick={() => {
																		deleteContentParams(index);
																	}}
																/>
															}
														>
															<Input
																style={{ marginBottom: 10 }}
																placeholder={'标签名称'}
																value={item.tab}
																onChange={(e) => {
																	changeContentParam(index, e.target.value, 'tab');
																}}
															/>
															<Switch
																style={{ marginBottom: 10 }}
																checked={item.hasPadding}
																checkedChildren="有边距"
																unCheckedChildren="无边距"
																onChange={(value) => {
																	changeContentParam(index, value, 'hasPadding');
																}}
															/>
															<WangEditorPro
																editorId={'editorElem' + index}
																key={index + type}
																value1={item.content}
																changeCallback={(res: any) =>
																	changeContentParam(index, res, 'content')
																}
															/>
														</Card>
													</Col>
												</Row>
											))}
										<Row align={'middle'} gutter={15}>
											<Col span={titleCol} style={{ textAlign: 'right' }}></Col>
											<Col span={contentCol}>
												<Button type={'primary'} onClick={addContentParams}>
													添加标签
												</Button>
											</Col>
										</Row>
										<Row gutter={15}>
											<Col span={titleCol} style={{ textAlign: 'right', marginTop: 10 }}>
												底部按钮:
											</Col>
											<Col span={5} style={{ marginTop: 10 }}>
												名称
											</Col>
											<Col span={3} style={{ marginTop: 10 }}>
												类型
											</Col>
											<Col span={5} style={{ marginTop: 10 }}>
												值
											</Col>
										</Row>
										{button.length > 0 &&
											button.map((item: any, index: any) => {
												return (
													<>
														<Row align={'middle'} gutter={15}>
															<Col span={titleCol} style={{ marginTop: 10 }} />
															<Col span={5} style={{ marginTop: 10 }}>
																<Input
																	value={item.name}
																	onChange={(e) => {
																		changeChildrenValue(
																			button,
																			index,
																			e.target.value,
																			'name'
																		);
																	}}
																/>
															</Col>
															<Col span={3} style={{ marginTop: 10 }}>
																<Select
																	allowClear
																	style={{ width: '100%' }}
																	value={item.type}
																	onChange={(value: any) => {
																		changeChildrenValue(
																			button,
																			index,
																			value,
																			'type'
																		);
																	}}
																>
																	<Select.Option value={'url'}>
																		跳转链接
																	</Select.Option>
																	<Select.Option value={'liuChengDan'}>
																		流程单
																	</Select.Option>
																	<Select.Option value={'popUpForm'}>
																		弹出表单
																	</Select.Option>
																	<Select.Option value={'moneySub'}>
																		资金补贴
																	</Select.Option>
																	<Select.Option value={'toContract'}>
																		签署合同
																	</Select.Option>
																	<Select.Option value={'productNoContract'}>
																		产品支付
																	</Select.Option>
																</Select>
															</Col>
															<Col span={5} style={{ marginTop: 10 }}>
																{(item.type === 'liuChengDan' ||
																	item.type === 'popUpForm') && (
																	<Select
																		style={{ width: '100%' }}
																		showSearch
																		value={item.value}
																		onChange={(value: any) => {
																			changeChildrenValue(
																				button,
																				index,
																				value,
																				'value'
																			);
																		}}
																		optionFilterProp="children"
																		allowClear
																	>
																		{liuchengList.map((item: any) => {
																			return (
																				<Select.Option
																					value={item.uuid}
																					key={item.uuid}
																				>
																					{item.name}
																				</Select.Option>
																			);
																		})}
																	</Select>
																)}
																{(item.type === 'popUpProductParam' ||
																	item.type === 'productNoContract') && (
																	<Input
																		value={item.value}
																		onChange={(e) => {
																			changeChildrenValue(
																				button,
																				index,
																				e.target.value,
																				'value'
																			);
																		}}
																	/>
																)}
																{item.type === 'url' && (
																	<Select
																		style={{ width: '100%' }}
																		value={item.value}
																		onChange={(value: any) => {
																			changeChildrenValue(
																				button,
																				index,
																				value,
																				'value'
																			);
																		}}
																		allowClear
																	>
																		<Select value={'常规'}>常规</Select>
																		<Select value={'表单'}>表单</Select>
																	</Select>
																)}
															</Col>
															<Col span={2} style={{ textAlign: 'right', marginTop: 10 }}>
																<CloseCircleOutlined
																	style={{ cursor: 'pointer' }}
																	onClick={() => {
																		deleteButton(button, index);
																	}}
																/>
															</Col>
														</Row>
														{renderParams(item, index)}
													</>
												);
											})}
										<Row align={'middle'} gutter={15}>
											<Col span={titleCol} style={{ textAlign: 'right' }}></Col>
											<Col span={contentCol}>
												<Button type={'primary'} onClick={addButton}>
													添加按钮
												</Button>
											</Col>
										</Row>
									</>
								)}
							</Panel>
							{type === '自有平台' && (
								<Panel
									style={{ backgroundColor: '#fff', marginTop: 10 }}
									header="价格信息"
									key={'priceInfo'}
								>
									<Row align={'middle'} gutter={15}>
										<Col span={titleCol} style={{ textAlign: 'right' }}>
											产品价格:
										</Col>
										<Col span={contentCol}>
											<Input
												value={productPrice}
												onChange={(e) => {
													setProductPrice(e.target.value);
												}}
											/>
										</Col>
									</Row>
									<Row align={'middle'} gutter={15}>
										<Col span={titleCol} style={{ textAlign: 'right' }}>
											收费科目:
										</Col>
										<Col span={contentCol}>
											<Select
												showSearch
												optionFilterProp="children"
												value={feeTypeUuid}
												style={{ width: '100%' }}
												onChange={(value) => {
													setFeeTypeUuid(value);
												}}
												allowClear
											>
												{fees.length > 0 &&
													fees.map((item: any) => (
														<Select.Option value={item.uuid} key={item.uuid}>
															{item.feeName}
														</Select.Option>
													))}
											</Select>
										</Col>
									</Row>
									<Row gutter={15}>
										<Col span={titleCol} style={{ textAlign: 'right', marginTop: 10 }}>
											产品参数:
										</Col>
										<Col span={5} style={{ marginTop: 10 }}>
											规格
										</Col>
										<Col span={3} style={{ marginTop: 10 }}>
											服务费类型
										</Col>
										<Col span={3} style={{ marginTop: 10 }}>
											服务费
										</Col>
										<Col span={3} style={{ marginTop: 10 }}>
											官费
										</Col>
										<Col span={3} style={{ marginTop: 10 }}>
											押金
										</Col>
									</Row>
									{productParams.length > 0 &&
										productParams.map((item: any, index: any) => {
											return (
												<>
													<Row align={'middle'} gutter={15}>
														<Col span={titleCol} style={{ marginTop: 10 }} />
														<Col span={5} style={{ marginTop: 10 }}>
															<Input
																value={item.guige}
																onChange={(e) => {
																	changeProductParam(index, e.target.value, 'guige');
																}}
															/>
														</Col>
														<Col span={3} style={{ marginTop: 10 }}>
															<Select
																value={item.serviceFeeType}
																style={{ width: '100%' }}
																onChange={(e) => {
																	changeProductParam(index, e, 'serviceFeeType');
																}}
															>
																<Select.Option value={'金额'} key="金额">
																	金额
																</Select.Option>
																<Select.Option value="百分比" key="百分比">
																	百分比
																</Select.Option>
															</Select>
														</Col>
														<Col span={3} style={{ marginTop: 10 }}>
															{item.serviceFeeType == '百分比' ? (
																<Input
																	style={{ width: '100%' }}
																	value={item.serviceFee}
																	defaultValue={0}
																	min={0}
																	onChange={(e) => {
																		changeProductParam(
																			index,
																			e.target.value,
																			'serviceFee'
																		);
																	}}
																/>
															) : (
																<InputNumber
																	style={{ width: '100%' }}
																	value={item.serviceFee}
																	defaultValue={0}
																	min={0}
																	onChange={(e) => {
																		changeProductParam(index, e, 'serviceFee');
																	}}
																	step={0.01}
																/>
															)}
														</Col>
														<Col span={3} style={{ marginTop: 10 }}>
															<InputNumber
																style={{ width: '100%' }}
																value={item.guanFee}
																defaultValue={0}
																min={0}
																onChange={(e) => {
																	changeProductParam(index, e, 'guanFee');
																}}
																step={0.01}
															/>
														</Col>
														<Col span={3} style={{ marginTop: 10 }}>
															<InputNumber
																style={{ width: '100%' }}
																value={item.yajin}
																defaultValue={0}
																min={0}
																onChange={(e) => {
																	changeProductParam(index, e, 'yajin');
																}}
																step={0.01}
															/>
														</Col>
														<Col span={2} style={{ textAlign: 'right', marginTop: 10 }}>
															<CloseCircleOutlined
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	deleteProductParams(index);
																}}
															/>
														</Col>
													</Row>
												</>
											);
										})}

									<Row align={'middle'} gutter={15}>
										<Col span={titleCol} style={{ textAlign: 'right' }}></Col>
										<Col span={contentCol}>
											<Button type={'primary'} onClick={addProductParams}>
												添加产品参数
											</Button>
										</Col>
									</Row>
								</Panel>
							)}
							{type === '自有平台' && (
								<Panel
									style={{ backgroundColor: '#fff', marginTop: 10 }}
									header="合同信息"
									key={'contractInfo'}
								>
									<Row align={'middle'} gutter={15}>
										<Col span={titleCol} style={{ textAlign: 'right' }}>
											合同类型
										</Col>
										<Col span={contentCol}>
											<Select
												style={{ width: '100%' }}
												showSearch
												value={contractType}
												onChange={(value: any) => {
													setContractType(value);
												}}
												optionFilterProp="children"
												allowClear
											>
												<Select.Option value={'个人'}>个人</Select.Option>
												<Select.Option value={'企业'}>企业</Select.Option>
												<Select.Option value={'个人和企业'}>个人和企业</Select.Option>
											</Select>
										</Col>
									</Row>
									<Row
										hidden={contractType != '个人' && contractType != '个人和企业'}
										align={'middle'}
										gutter={15}
									>
										<Col span={titleCol} style={{ textAlign: 'right' }}>
											个人合同模板
										</Col>
										<Col span={contentCol}>
											<Select
												style={{ width: '100%' }}
												showSearch
												optionFilterProp="children"
												value={personalContract}
												onChange={(value: any) => {
													setPersonalContract(value);
												}}
												allowClear
											>
												{contractList.map((item: any) => {
													return (
														<Select.Option value={item.templateId} key={item.templateId}>
															{item.templateName}
														</Select.Option>
													);
												})}
											</Select>
										</Col>
									</Row>
									<Row
										hidden={contractType != '企业' && contractType != '个人和企业'}
										align={'middle'}
										gutter={15}
									>
										<Col span={titleCol} style={{ textAlign: 'right' }}>
											企业合同模板
										</Col>
										<Col span={contentCol}>
											<Select
												style={{ width: '100%' }}
												showSearch
												value={companyContract}
												onChange={(value: any) => {
													setCompanyContract(value);
												}}
												optionFilterProp="children"
												allowClear
											>
												{contractList.map((item: any) => {
													return (
														<Select.Option value={item.templateId} key={item.templateId}>
															{item.templateName}
														</Select.Option>
													);
												})}
											</Select>
										</Col>
									</Row>
									<Row align={'middle'} gutter={15}>
										<Col span={titleCol} style={{ textAlign: 'right' }}>
											合同是否需要审批
										</Col>
										<Col span={contentCol}>
											<Switch
												checkedChildren="需要"
												checked={contractApprove}
												onChange={(value) => {
													setContractApprove(value);
												}}
												unCheckedChildren="不需要"
											/>
										</Col>
									</Row>
								</Panel>
							)}
						</Collapse>
						<div style={{ backgroundColor: '#fff', marginTop: 10, padding: 10 }}>
							<Space>
								<Button onClick={saveItem} type={'primary'}>
									保存
								</Button>
							</Space>
						</div>
					</Col>
					<Col span={1}></Col>
					<Col span={6}>
						{/* <iframe
							id="main"
							// onLoad={this.onLoad}
							ref={getIframe}
							style={{ width: '100%', height: window.innerHeight - 70, margin: 0, border: 0 }}
						/> */}
					</Col>
				</Row>
			</div>
		</>
	);
};
export default AddORUpdateProduct;
