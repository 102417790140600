import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic, Space, message, List, Modal, TreeSelect, Button } from 'antd';
import LineChart from '../../components/LineChart';
import moment from 'moment';
import { getCurrentPlatformData, getDeviceOpenDoorData } from '../../services/controlPlatformService';
import ColumnChart from '../../components/ColumnChart';
import CurrentPhoneBrandChart from './CurrentPhoneBrandChart';
import AuthRecordLine from '../../components/AuthRecordLine';
import CustomRangePicker from '../../components/CustomRangePicker';
import { getDevices } from "../../services/ZhiNengMenJinService";
import GsTreeSelect from "../../components/GsTreeSelect";

export const DataContext = React.createContext([]);

const CurrentPlatform: React.FC = () => {
	const [selectType, setSelectType] = useState(1);

	let startTime = moment().subtract(6, 'd');
	let endTime = moment();
	let deviceId: string = "";
	const [data, setData] = useState<any>([]);
	const [totalData, setTotalData] = useState<any>({});
	const [tabKey, setTabKey] = useState('人脸识别开门');
	const [dataSource, setDataSource] = useState([]);
	const [loading, setLoading] = useState(false);
	const [modelVisable, setModelVisable] = useState(false);
	const [devices, setDevices] = useState([]);

	useEffect(() => {
		getData();
		getSelectDeviceList().then();
	}, []);
	const getSelectDeviceList = async () => {
		const res = await getDevices({});
		if (res.err !== 0) {
			return;
		}
		let data: any = [];
		data.push({key: '全部设备', title: '全部设备', value: ''});
		data = data.concat(res.data)
		setDevices(data);
	};
	const getData = async () => {
		setLoading(true);
		let params = {
			deviceIds:deviceId,
			startTime: startTime.format('YYYY-MM-DD'),
			endTime: endTime.format('YYYY-MM-DD'),
		};
		let rsp = await getDeviceOpenDoorData(params);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
			return
		}
		let chartData: any = [];
		const uNumbers = rsp.data.unlockNumbers || {}
		const uUsers = rsp.data.userNumbers || {}
		if ('{}' !== JSON.stringify(uNumbers)) { 
			let keys = Object.keys(uNumbers);
				keys.map((key: string) => {
					chartData.push({
						name: "次数",
						x: key,
						y: uNumbers[key],
					});
				});
		}
		if ('{}' !== JSON.stringify(uUsers)) { 
			let keys = Object.keys(uUsers);
				keys.map((key: string) => {
					chartData.push({
						name: "人数",
						x: key,
						y: uUsers[key],
					});
				});
		}
		console.log("rsp.data.deviceMap",rsp.data.deviceMap);
		
		setDataSource(rsp.data.deviceMap || [])
		setData(chartData);
	};
	const tabBarExtrContent = () => (
		<Space>
			<GsTreeSelect
				treeData={devices}
				defaultValue={deviceId}
				onChange={(ev: any) => {
					deviceId = ev.toString();	
				}}
			/>
		<CustomRangePicker
			onChange={(_startTime, _endTime) => {
				startTime = _startTime;
				endTime = _endTime;
			}}
			startTime={startTime}
			endTime={endTime}
		/>
		<Button type={"primary"} onClick={getData}> 查询</Button>
		</Space>
	);

	return (
		<div style={{ paddingLeft: 15, paddingRight: 15 }}>
			<Row gutter={16}>
				<Col span={16}>
					<Card
						loading={loading}
						title={'开门趋势'}
						extra={tabBarExtrContent()}
						bodyStyle={{ paddingLeft: 15 }}
						activeTabKey={tabKey}
					>
						<DataContext.Provider value={data}>
							<LineChart />
						</DataContext.Provider>
					</Card>
				</Col>
				<Col span={8}>
					<List
						loading={loading}
						style={{ height: '100%', background: '#fff' }}
						bordered={true}
						split={true}
						header={'通行排序'}
						itemLayout={'vertical'}
						dataSource={dataSource}
						pagination={
							dataSource.length > 10
								? {
										pageSize: 8,
										position: 'bottom',
								  }
								: false
						}
						renderItem={(item: any) => (
							<List.Item style={{ width: '100%' }} extra={item.number + '人次'}>
								{item.deviceName}
							</List.Item>
						)}
					/>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={16}>
					<AuthRecordLine />
				</Col>
				<Col span={8}>
					<CurrentPhoneBrandChart />
				</Col>
			</Row>
		</div>
	);
};
export default CurrentPlatform;
