import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import Product from './page/Product';
import ProductCate from './page/ProductCate';
import AddORUpdateProduct from './compontents/AddORUpdateProduct';
import ProductOrder from './page/ProductOrder';

export default function ProductMiniApp() {
	const rootUrl = '/product';
	const menus: MiniAppMenu[] = [
		{
			name: '产品',
			path: 'product',
			authority: 'product_list:select',
			component: Product,
		},
		{
			name: '产品类目',
			path: 'productCate',
			authority: 'product_cate_list:select',
			component: ProductCate,
		},
		{
			name: '产品订单',
			path: 'productOrder',
			authority: 'product_list:select',
			component: ProductOrder,
		},
	];
	const routes: MiniAppRoute[] = [
		{
			path: 'addOrUpdateProduct',
			component: AddORUpdateProduct,
		},
	]

	return <MiniApp miniAppName={'产品'} rootUrl={rootUrl} menus={menus} routes={routes}  />;
}
