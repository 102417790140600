import * as React from "react";
import DeviceGroup from "./pages/DeviceGroup";
import MappingList from "./pages/MappingList";
import SpecialAttention from "./pages/SpecialAttention";
import DevicesList from "./pages/DevicesList";
import UserNotesList from "./pages/UserNotesList";
import StaffList from "./pages/StaffList";
import MiniApp, { MiniAppMenu, MiniAppRoute } from "../../util/MiniApp";
import SingleLockUserList from "./components/SingleLockUserList";
import ShenheList from "./pages/FaceList";
import YaoshiLiebiao from "./components/YaoshiLiebiao";
import EquipmentRecord from "./pages/EquipmentRecord";
import OpenDoorRecord from "./pages/OpenDoorRecord";
import AuthRecord from "./pages/AuthRecord";
import { useEffect, useState } from "react";
import FaceAsyncList from "./pages/FaceAsyncList";
import KeySendRecord from "../notification/pages/KeySendRecord";
import SendKey from "./pages/SendKey";
import DetectRecord from "./pages/DetectRecord";
import KeyEventNote from './pages/KeyEventNote';
import RequestList from "./pages/RequestList";
import BlackListManager from "./pages/BlackListManager";
import ExitSlipList from './pages/ExitSlipList';

export default function ZhiNengMenJinMiniApp() {
    useEffect(() => {
        let curMenu = window.location.pathname.split("/")[2];
        console.log("curMenu::", curMenu);
    }, []);
    const [curMenu, setCurMent] = useState("");
    const rootUrl = "/zhineng-menjin";
    const menus: MiniAppMenu[] = [
        {
            name: "设备列表",
            path: "zhineng-menjin",
            authority: "entrance_guard:device_list:select",
            component: DevicesList
        },
        {
            name: "人员列表",
            path: "staff-list",
            authority: "entrance_guard:user_list:select",
            component: StaffList
        },
        {
            name: "钥匙列表",
            path: "mapping-list",
            authority: "entrance_guard:mapping_list:select",
            component: MappingList
        },
        {
            name: '钥匙处理记录',
            path: 'mapping-event-note',
            authority: 'key_event_note:select',
            component: KeyEventNote,
        },
        {
            name: "发送钥匙",
            path: "send-key",
            authority: "entrance_guard:mapping_list:sendKey",
            component: SendKey
        },
        {
            name: "钥匙申请",
            path: "shenqing-jilu",
            authority: "entrance_guard:ekey_shengqing:select",
            component: UserNotesList
        },
        {
            name: "人脸识别申请",
            path: "face-shenhei",
            authority: "entrance_guard:face_list:select",
            component: ShenheList
        },
        {
            name: "开门记录",
            path: "kaimen-jilu",
            authority: "entrance_guard:record_list:select",
            component: OpenDoorRecord
        },
        {
            name: "授权记录",
            path: "shouquan-jilu",
            authority: "entrance_guard:auth_record_list:select",
            component: AuthRecord
        },
        {
            name: "设备上下线记录",
            path: "shangxiaxian-jilu",
            authority: "entrance_guard:online_record_list:select",
            component: EquipmentRecord
        },
        {
            name: "设备分组",
            path: "device-group",
            authority: "entrance_guard:device_group:select",
            component: DeviceGroup
        },
        {
            name: "特别关注",
            path: "special-attention",
            authority: "entrance_guard:special_attention:select",
            component: SpecialAttention
        },
        {
            name: "命令日志",
            path: "face-async",
            authority: "entrance_guard:face-async:select",
            component: FaceAsyncList
        },
        {
            name: "授权日志",
            path: "key_send_record",
            authority: "entrance_guard:send_key_record:select",
            component: KeySendRecord
        }, {
            name: "识别日志",
            path: "detect-record",
            authority: "entrance_guard:detect-record:select",
            component: DetectRecord
        }, {
            authority: "black-list:select",
            name: "黑名单操作记录",
            path: "black-list",
            component: BlackListManager
        },
    ];

    const routes: MiniAppRoute[] = [
        {
            path: "single-lock-user-list",
            component: SingleLockUserList
        },
        {
            path: "yaoshi-liebiao",
            component: YaoshiLiebiao
        }
    ];
    return (
        <>
            {console.log("menus:::", menus)}
            <MiniApp
                key={window.location.href}
                miniAppName={"门禁通行"}
                rootUrl={rootUrl}
                menus={menus}
                routes={routes}
            />
        </>
    );
}
