import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row, Select, Image, Form, Input, Upload, message, Switch, Space, Button, Spin, Modal, Empty } from "antd";
import {
    addAppModel1,
    deleteAppModel1, getAllLiucheng,
    getAllLiuchengDanData,
    getAppModel1List,
    getRongQiList, getVersionController,
    sortAppModel1,
    tongbuAppModel1,
    updateAppModel1
} from "../../../services/FoundationSetupService";
import ProxyForm from "../../../components/ProxyForm";
import { FormInstance } from "antd/es/form";
import Api from "../../../data/API";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons/lib";
import { DndProvider } from "react-dnd";
import HTMLBackend from "react-dnd-html5-backend";
import CardItem from "../../assets/components/CardItem";
import { sortRoomImg } from "../../../services/ZhuhushenheService";
import { getAllMenuPerm } from "../../../services/PermItemService";
import GSTable from "../../../components/GSTable";
import DAL from "../../../data/DAL";
import { ActionType } from "@ant-design/pro-table";
import { getWuyeProj } from "../../../services/WuyeService";
import { findWenzhangListPc } from "../../../services/WenzhangService";
import { getPId0ProductCateList, getProductList } from '../../../services/Product';

const AppParameterSet: React.FC = (props: any) => {
    useEffect(() => {
        getAllProject().then(() => {
            AllMenuParm().then();
        });
        getLiuchengDanList().then();
        getAllProduct().then();
        getProductCate().then();
        getUserInfo();
        getAllWenZhang().then();
    }, []);
    let rongQiEnum: any = {};
    const rongQis: any = [
        {
            name: "容器一",
            value: 0
        },
        {
            name: "容器二",
            value: 1
        },
        {
            name: "容器三",
            value: 2
        },
        {
            name: "容器四",
            value: 3
        }
    ];
    let projects: any = [];
    const dal = DAL.dal;
    const Option = Select.Option;
    const actionRef = useRef<ActionType>();
    const [productList,setProductList] = useState([])
    const [productCateList,setProductCateList] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [rq, setRq] = useState(0);
    const [tableRq, setTableRq] = useState<any>(undefined);
    const [form] = Form.useForm<FormInstance>();
    const [paramData, setParamData] = useState([]);
    const [liuchengList, setLiuchengList] = useState([]);
    const [addForm] = Form.useForm<FormInstance>();
    const FormItem = Form.Item;
    const [widget, setWidget] = useState("");
    const [add, setAdd] = useState(false);
    const [icon, setIcon] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectIndex, setSelectIndex] = useState<any>("");
    const [sortVisible, setSortVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [sortData, setSortData] = useState([]);
    const [wuyeProjects, setWuyeProjects] = useState([]);
    const [syncVisible, setSyncVisible] = useState(false);
    const wyUuid = localStorage.getItem("wyUuid");
    const [selectWyUuid, setSelectWyuuid] = useState(localStorage.getItem("wyUuid"));
    const [sycnLoading, setSycnLoading] = useState(false);
    const [sortLoading, setSortLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [rongQiList, setRongQiList] = useState(rongQis);
    const [appXcxProjects, setAppXcxProjects] = useState<any>([]);
    const [item, setItem] = useState<any>({});
    const [wenzhangList, setWenzhangList] = useState([]);
    const columns = [
        {
            title: "名称",
            dataIndex: "modulName",
            key: "modulName"
        },
        {
            title: "图标",
            dataIndex: "icon",
            key: "icon",
            render: (text: any, item: any) => <img src={item.icon} style={{ width: 30, height: 30 }}/>
        },
        {
            title: "容器",
            dataIndex: "parentId",
            key: "parentId",
            render: (text: any, item: any) => {
                return filterRQ(text);
            }
        }
    ];

    const widgets = [
        {
            name: "程序页面",
            value: "XcxPage"
        },
        {
            name: "WebView页面",
            value: "WebViewPage"
        },
        {
            name: "流程单发起页面",
            value: "Liucheng"
        },
        {
            name: "流程单处理页面",
            value: "LiuchengChuLi"
        },
        {
            name: "资讯文章",
            value: "WenZhang"
        },
        {
            name: "产品",
            value: "product"
        }, {
            name: "产品列表",
            value: "productList"
        }
    ];
    const filterRQ = (pId: any) => {
        const items = rongQiList.filter((item: any) => item.value == pId);
        if (items[0] !== undefined) {
            return items[0].name;
        }
    };
    const getAllWenZhang = async () => {
        let res = await findWenzhangListPc({
            typeUuid: ""
        });
        if (res.err === 0) {
            setWenzhangList(res.data);
        }
    };
    const findRongQiList = async (appName: any) => {
        let params = {
            appName: appName
        };
        let rsp = await getRongQiList(params);
        let datas: any = [...rongQis];
        if (rsp.err === 0) {
            if (rsp.data.length > 0) {
                rsp.data.map((item: any) => {
                    const data = {
                        name: item.modulName,
                        value: item.id
                    };
                    datas.push(data);
                });
            }
            datas.map((item: any) => {
                rongQiEnum[item.value] = item.name;
            });
            console.log("rongQiEnum:::", rongQiEnum);
            setRongQiList(datas);
        } else {
            setRongQiList([...rongQis]);
            rongQis.map((item: any) => {
                rongQiEnum[item.value.toString()] = item.name;
            });
        }
    };
    const getUserInfo = () => {
        dal.getUserInfo().then((res) => {
            setWuyeProjects(res.project);
        });
    };
    const getAllProject = async () => {
        const res = await getVersionController({});
        if (res.err === 0) {
            projects = res.data;
            await getProjInfo(); 
        }
    };
    const getProjInfo = async () => {
        const res = await getWuyeProj({});
        if (res.err === 0) {
            let datas: any = new Set();
            const appXcxs = res.data.appXcx;
            if (appXcxs !== undefined && appXcxs !== null && appXcxs !== "") {
                const arr1 = appXcxs.split(",");
                arr1.map((item: any) => {
                    projects.map((item1: any) => {
                        if (item == '门禁卡小程序') { 
                            item = 'mjk_xcx'
                        }
                        if (item === item1.typeValue ) {
                            datas.add(item1);
                        }
                    });
                });
                setAppXcxProjects([...datas]);
                setSearch([...datas][0].typeValue);
                getData([...datas][0].typeValue, rq);
                findRongQiList([...datas][0].typeValue);
            }
        }
    };
    const getData = async (search: any, rq: any) => {
        setLoading(true);
        const data = {
            search: search,
            container: rq,
            wuyeUuid: wyUuid
        };
        const res = await getAppModel1List(data);
        setLoading(false);
        setData(res.data);
    };

    const AllMenuParm = async () => {
        const res = await getAllMenuPerm({});
        console.log("allMenuParm::;", res);
        if (res.err === 0) {
            setParamData(res.data);
        }
    };

    const getFileName = (list: any) => {
        console.log("filelist:", list);
        if (list.file.status === "done") {
            console.log(`${list.file.name}`);
            console.log("企业图标", list.file.response.url);
            setIcon(list.file.response.data);
        } else if (list.file.status === "error") {
            message.error(`${list.file.name} 上传文件失败`);
        }
    };
    const getLiuchengDanList = async () => {
        const data = {
            state: true,
            selectedTags: "",
            searchContent: ""
        };
        const res = await getAllLiucheng(data);
        console.log("res:::", res);
        if (res.err === 0) {
            setLiuchengList(res.data);
        }
    };
    const getAllProduct = async () => {
        const res = await getProductList({status:1});
        console.log("res:::", res);
        if (res.err === 0) {
            setProductList(res.data);
        }
    };
    const getProductCate = async () => {
        const res = await getPId0ProductCateList({});
        console.log("res:::", res);
        if (res.err === 0) {
            setProductCateList(res.data);
        }
    };
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );
    const setUpdateData = (record: any, index: any) => {
        console.log("record::;", record, index);
        setIcon(record.icon);
        setSelectIndex(index);
        setItem(record);
        setWidget(record.widget);
        form.setFieldsValue({
            ...record,
            comeCondition:
                (record.comeCondition || "").split(','),
            islogin: record.islogin == 1 ? true : false,
            isMenu: record.isMenu == 1 ? true : false,
            visible: record.visible == 1 ? false : true,
            isShowModal: record.showModal
        });
    };
    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            /**
             * 1、如果此时拖拽的组件是 Box 组件，则 dragIndex 为 undefined，则此时修改，则此时修改 cardList 中的占位元素的位置即可
             * 2、如果此时拖拽的组件是 Card 组件，则 dragIndex 不为 undefined，此时替换 dragIndex 和 hoverIndex 位置的元素即可
             */
            console.log("dragIndex", dragIndex);
            console.log("hoverIndex", hoverIndex);
            let sortList = [...sortData];
            const d1 = sortList[dragIndex];
            const ho = sortList[hoverIndex];
            console.log("data::", d1, ho);
            let tmp = sortList[dragIndex]; //临时储存文件
            sortList.splice(dragIndex, 1); //移除拖拽项
            sortList.splice(hoverIndex, 0, tmp); //插入放置项
            setSortData([...sortList]);
        },
        [sortData]
    );
    const addItem = () => {
        addForm.validateFields().then(async (data: any) => {
            console.log("data:::", data);
            const formData: any = {
                ...data,
                icon: icon.toString(),
                islogin: data.islogin == true ? 1 : 0,
                isMenu: data.isMenu == true ? 1 : 0,
                isThird: data.third == true ? true : false,
                third: data.third == true ? true : false,
                visible: data.visible == true ? false : true,
                widgetValue: data.widgetValue === undefined || data.widgetValue == null ? "" : data.widgetValue,
                appName: search,
                parentId: rq,
                showModal: data.isShowModal,
                comeCondition: (data.comeCondition || []).toString()
            };
            setSaveLoading(true);
            const res = await addAppModel1(formData);
            setSaveLoading(false);
            if (res.err === 0) {
                message.success("添加成功");
                setVisible(false);
                setIcon("");
                addForm.resetFields();
                setWidget("");
                getData(search, rq);
            } else {
                message.error("添加失败");
            }
        });
    };
    const saveItem = () => {
        if (selectIndex === "") {
            message.warning("请选择对象");
            return;
        }
        form.validateFields().then(async (data: any) => {
            console.log("data:::", data);
            const formData: any = {
                ...data,
                showModal: data.isShowModal,
                icon: icon.toString(),
                islogin: data.islogin == true ? 1 : 0,
                isMenu: data.isMenu == true ? 1 : 0,
                isThird: data.third == true ? true : false,
                third: data.third == true ? true : false,
                visible: data.visible == true ? false : true,
                widgetValue: data.widgetValue === undefined || data.widgetValue == null ? "" : data.widgetValue,
                comeCondition: (data.comeCondition || []).toString()

            };
            setSaveLoading(true);
            const res = await updateAppModel1(formData);
            setSaveLoading(false);
            if (res.err === 0) {
                message.success("修改成功");
                setIcon("");
                form.resetFields();
                getData(search, rq);
                setSelectIndex("");
            } else {
                message.error("修改失败");
            }
        });
    };
    const openAdd = () => {
        resetItem();
        const formData: any = {
            islogin: true,
            isMenu: true,
            third: true,
            visible: true
        };
        addForm.setFieldsValue(formData);
        setVisible(true);
        setAdd(true);
    };
    const resetItem = () => {
        setAdd(false);
        setIcon("");
        setSelectIndex("");
        setWidget("");
        form.resetFields();
        setItem({});
        addForm.resetFields();
    };

    const sortDataItem = async () => {
        let data: any = [];
        sortData.forEach((item: any) => {
            data.push(item.id);
        });
        setSortLoading(true);
        console.log("data:::", data.toString());
        const res = await sortAppModel1({
            ids: data.toString()
        });
        setSortLoading(false);
        if (res.err === 0) {
            getData(search, rq);
            setSortVisible(false);
            message.success("操作成功");
        } else {
            message.error("操作失败");
        }
    };

    const deleteItem = (item: any) => {
        Modal.confirm({
            title: "你是否要删除" + item.modulName + "吗？",
            okType: "danger",
            onOk: async () => {
                const res = await deleteAppModel1({ id: item.id, wuyeUuid: item.wuyeUuid });
                if (res.err === 0) {
                    message.success("删除成功");
                    getData(search, rq);
                    resetItem();
                } else {
                    message.error("删除失败");
                }
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    };

    const tongbu = async () => {
        setSelectedRowKeys([]);
        setSyncVisible(true);
    };
    const syncItem = async () => {
        const ids = selectedRowKeys;
        if (ids.length === 0 || ids.length < 0) {
            message.warning("请选择菜单");
            return;
        }
        setSycnLoading(true);
        const data: any = {
            ids: ids.toString(),
            sycnWyUuid: selectWyUuid,
            container: rq,
            appName: search
        };
        const res = await tongbuAppModel1(data);
        setSycnLoading(false);
        if (res.err === 0) {
            message.success("复制成功");
            setSyncVisible(false);
            getData(search, rq);
        } else {
            message.error("复制失败");
        }
    };
    const onSelectChange = (selectedRowKeys: any) => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: onSelectChange
    };

    const tableSearchBarRender = () => [
        <Space>
            <Select
                defaultValue={selectWyUuid}
                onChange={(value: any) => {
                    setSelectWyuuid(value);
                    actionRef.current.reload();
                }}
            >
                {wuyeProjects.map((item: any) => {
                    return <Option value={item.projectUuid}>{item.projectName}</Option>;
                })}
            </Select>
            <Select
                defaultValue={tableRq}
                allowClear
                style={{ width: 150 }}
                placeholder={"容器"}
                onChange={(value: any) => {
                    setTableRq(value);
                    actionRef.current.reload();
                }}
            >
                {rongQiList.length > 0
                    ? rongQiList.map((item: any) => {
                        return <Option value={item.value}>{item.name}</Option>;
                    })
                    : ""}
            </Select>
        </Space>
    ];

    return (
        <>
            <Spin spinning={loading}>
                {
                    appXcxProjects.length > 0 ? <>

                        <Row gutter={15} justify={"space-between"} align={"middle"}>
                            <Col>
                                <Space>
                                    <Select
                                        value={search}
                                        onChange={async (value: any) => {
                                            console.log('app:::::', value)
                                            setSearch(value);

                                            await getData(value, rq);
                                            await findRongQiList(value);
                                            resetItem();
                                        }}
                                    >
                                        {appXcxProjects.length > 0
                                            ? appXcxProjects.map((item: any) => {
                                                return <Option value={item.typeValue}>{item.name}</Option>;
                                            })
                                            : ""}
                                    </Select>
                                    <Select
                                        defaultValue={rq}
                                        style={{width: 200}}
                                        onChange={(value: any) => {
                                            setRq(value);
                                            getData(search, value);
                                            resetItem();
                                        }}
                                    >
                                        {
                                            search === 'ckxz' && <>
                                                <Option value={'-3'}>首页顶部BANNER图</Option>
                                                <Option value={'-1'}>生活板块</Option>
                                            </>
                                        }
                                        {rongQiList.length > 0
                                            ? rongQiList.map((item: any) => {
                                                return <Option value={item.value}>{item.name}</Option>;
                                            })
                                            : ""}
                                    </Select>

                                </Space>
                            </Col>
                            <Col>
                                <Space>
                                    <Button type={"primary"} onClick={openAdd}>
                                        添加
                                    </Button>
                                    <Button type={"primary"} loading={sycnLoading} onClick={tongbu}>
                                        复制菜单
                                    </Button>
                                    <Button
                                        type={"primary"}
                                        onClick={() => {
                                            setSortVisible(true);
                                            setSortData([...data]);
                                        }}
                                    >
                                        编辑排序
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={15} justify={"center"} align={"middle"}>
                            <Col span={7}>
                                <div
                                    style={{
                                        background: "#fff",
                                        marginTop: 10,
                                        height: document.body.clientHeight - 210,
                                        overflowY: "auto"
                                    }}
                                >
                                    <Row>
                                        {data.map((item: any, index: any) => {
                                            return (
                                                <Col span={6} style={{ marginTop: 20 }}>
                                                    <div
                                                        onClick={() => {
                                                            setAdd(false);
                                                            setUpdateData(item, index);
                                                            // setSelectIndex(index);
                                                        }}
                                                        style={{ cursor: "pointer", position: "relative" }}
                                                        className={
                                                            selectIndex === index
                                                                ? "d-flex justify-content-center align-items-center flex-column selectZiduan"
                                                                : "d-flex justify-content-center align-items-center flex-column "
                                                        }
                                                    >
                                                        <DeleteOutlined
                                                            onClick={() => {
                                                                deleteItem(item);
                                                            }}
                                                            hidden={selectIndex !== index}
                                                            style={{ position: "absolute", right: 10, top: 10 }}
                                                        />
                                                        <div className={"imgbox"}>
                                                            <img
                                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                                src={item.icon}
                                                            ></img>
                                                        </div>

                                                        <text>{item.modulName}</text>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                            </Col>
                            <Col span={17}>
                                {selectIndex !== ''  ? <>
                                    <div
                                        style={{
                                            background: "#fff",
                                            marginTop: 10,
                                            height: document.body.clientHeight - 280,
                                            overflowY: "auto"
                                        }}
                                    >
                                        <ProxyForm style={{ padding: "10px 0px" }} form={form}>
                                            <FormItem name={"id"} label={"id"} hidden={true}/>
                                            <FormItem label={"容器"} name={"parentId"} rules={[{ required: true }]}>
                                                <Select>
                                                    {rongQiList.length > 0
                                                        ? rongQiList.map((item: any) => {
                                                            return <Option value={item.value}>{item.name}</Option>;
                                                        })
                                                        : ""}
                                                </Select>
                                            </FormItem>
                                            <FormItem label={"对应使用客户端"} name={"appName"} hidden={true}>
                                                <Input/>
                                            </FormItem>
                                            <FormItem label={"排序"} name={"orderNum"} hidden={true}>
                                                <Input/>
                                            </FormItem>
                                            <FormItem
                                                name={"modulName"}
                                                label={"名称"}
                                                tooltip={"客户端菜单项的名字"}
                                                rules={[{ required: true }]}
                                            >
                                                <Input/>
                                            </FormItem>
                                            <FormItem name={"widget"} label={"组件类型"} rules={[{ required: true }]}>
                                                <Select
                                                    disabled={item.widget === "XcxPage"}
                                                    onChange={(value: any) => {
                                                        setWidget(value);
                                                    }}
                                                >
                                                    {widgets.map((item: any) => {
                                                        return <Option value={item.value}>{item.name}</Option>;
                                                    })}
                                                </Select>
                                            </FormItem>
                                            <FormItem name={"widgetValue"} label={"组件内容"}>
                                                {widget === "Liucheng" || widget === "LiuchengChuLi" ? (
                                                    <Select showSearch optionFilterProp="children" allowClear>
                                                        {liuchengList.map((item: any) => {
                                                            return (
                                                                <Select.Option value={item.uuid} key={item.uuid}>
                                                                    {item.name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                ) : (
                                                    widget === "WenZhang" ?
                                                        <Select showSearch optionFilterProp="children" allowClear>
                                                            {wenzhangList.map((item: any) => {
                                                                return (
                                                                    <Select.Option value={item.uuid}
                                                                                   key={item.uuid}>
                                                                        {item.wenzhangBiaoti}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>:
                                                        widget === "product" ?
                                                            <Select showSearch optionFilterProp="children" allowClear>
                                                                {productList.map((item: any) => {
                                                                    return (
                                                                        <Select.Option value={item.uuid}
                                                                                       key={item.uuid}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            :
                                                            widget === "productList" ?
                                                                <Select showSearch optionFilterProp="children" allowClear>
                                                                    {productCateList.map((item: any) => {
                                                                        return (
                                                                            <Select.Option value={item.uuid}
                                                                                           key={item.uuid}>
                                                                                {item.name}
                                                                            </Select.Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                        :
                                                        <Input disabled={item.widget === "XcxPage"}/>
                                                )}
                                            </FormItem>
                                            <FormItem label={"是否显示"} name={"visible"} valuePropName={"checked"}>
                                                <Switch checkedChildren="是" unCheckedChildren="否"/>
                                            </FormItem>
                                            <FormItem label={"图标"} name={"icon"}>
                                                <Upload
                                                    // disabled={item.widget === "XcxPage"}
                                                    name="image"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    action={Api.apiRootPath + "/api/picture/upload_qiniu_two"}
                                                    onChange={getFileName}
                                                >
                                                    {!add && icon !== null && icon !== undefined && icon !== "" ? (
                                                        <img alt="avatar" style={{ width: "100%" }} src={icon}/>
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </FormItem>
                                            <FormItem label={"web_view配置"} name={"webViewDeploy"}>
                                                <Input/>
                                            </FormItem>
                                            <FormItem label={"权限"} name={"perms"} tooltip={"对应的SaaS后台员工分组权限设置"}>
                                                <Select showSearch optionFilterProp="children">
                                                    {paramData.length > 0
                                                        ? paramData.map((item: any) => {
                                                            return (
                                                                <Select.Option value={item.permKey}
                                                                               key={item.permKey}>
                                                                    {item.name}
                                                                </Select.Option>
                                                            );
                                                        })
                                                        : ""}
                                                </Select>
                                            </FormItem>
                                            <FormItem label={"是否需要登录"} name={"islogin"} valuePropName={"checked"}>
                                                <Switch checkedChildren="是" unCheckedChildren="否"/>
                                            </FormItem>
                                            <FormItem
                                                label={"重复提交判断"}
                                                name={"comeLiuchengDay"}
                                                tooltip={"流程在这几天内是否重复提交判断"}
                                            >
                                                <Input/>
                                            </FormItem>
                                            <FormItem
                                                label={"进入项条件"}
                                                name={"comeCondition"}
                                                // tooltip={
                                                // 	'进入项的条件：实名认证（SMRZ），绑定公司（BDGS），银行卡（YHK)，个人资格（GRZG）,个人房源（GRFY）'
                                                // }
                                            >
                                                <Select mode="tags" allowClear tokenSeparators={[',']}>
                                                    <Option value={"SMRZ"}>实名认证</Option>
                                                    <Option value={"BDGS"}>绑定公司</Option>
                                                    <Option value={"GSGLR"}>公司管理员</Option>
                                                    <Option value={"YHK"}>个人绑定银行卡并同步</Option>
                                                    <Option value={"GRZG"}>房管局审核通过</Option>
                                                    <Option value={"GRFY"}>个人获得公司分配房源</Option>
                                                </Select>
                                            </FormItem>
                                            <FormItem label={"提示信息"} name={"msg"}>
                                                <Input/>
                                            </FormItem>
                                            <FormItem label={"菜单选项"} name={"isMenu"} valuePropName={"checked"}>
                                                <Switch checkedChildren="是" unCheckedChildren="否"/>
                                            </FormItem>
                                            <FormItem label={"第三方服务选项"} name={"third"} valuePropName={"checked"}>
                                                <Switch checkedChildren="是" unCheckedChildren="否"/>
                                            </FormItem>
                                            <FormItem label={"是否隐私协议弹框"} name={"isShowModal"} initialValue={false} valuePropName={"checked"}>
                                                <Switch checkedChildren="是" unCheckedChildren="否"/>
                                            </FormItem>
                                            <FormItem label={"备注"} name={"remark"}>
                                                <Input/>
                                            </FormItem>
                                            <FormItem label={"create_by"} name={"createBy"} hidden={true}>
                                                <Input/>
                                            </FormItem>
                                        </ProxyForm>
                                    </div>
                                    <div
                                        style={{
                                            background: "#fff",
                                            marginTop: 10,
                                            paddingTop: 4,
                                            paddingBottom: 12
                                            // justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Row>
                                            <Col span={7}/>
                                            <Col>
                                                <Button type={"primary"} loading={saveLoading} onClick={saveItem}>
                                                    保存
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </> : <div style={{ background: "#fff", height: document.body.clientHeight - 210, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Empty description={'请选择模块'}/></div>
                                }
                            </Col>
                        </Row>
                    </> : <Row justify={"center"} align={"middle"}
                               style={{ backgroundColor: "#fff", height: document.body.clientHeight - 180 }}><Col
                        span={24}><Empty description={"当前项目没有配置前端应用"}/> </Col></Row>
                }


                <Modal
                    title={"添加"}
                    visible={visible}
                    confirmLoading={saveLoading}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    onOk={addItem}
                >
                    <ProxyForm form={addForm}>
                        <FormItem name={"id"} label={"id"} hidden={true}/>
                        <FormItem
                            name={"modulName"}
                            label={"名称"}
                            tooltip={"客户端菜单项的名字"}
                            rules={[{ required: true }]}
                        >
                            <Input/>
                        </FormItem>
                        <FormItem name={"widget"} label={"组件类型"} rules={[{ required: true }]}>
                            <Select
                                onChange={(value: any) => {
                                    console.log("type:::",value)
                                    setWidget(value);
                                }}
                            >
                                {widgets.map((item: any) => {
                                    return <Option value={item.value}>{item.name}</Option>;
                                })}
                            </Select>
                        </FormItem>
                        <FormItem name={"widgetValue"} label={"组件内容"}>
                            {widget === "Liucheng" || widget === "LiuchengChuLi" ? (
                                <Select showSearch optionFilterProp="children" allowClear>
                                    {liuchengList.map((item: any) => {
                                        return (
                                            <Select.Option value={item.uuid} key={item.uuid}>
                                                {item.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            ) : (
                                widget === "WenZhang" ?
                                    <Select showSearch optionFilterProp="children" allowClear>
                                        {wenzhangList.map((item: any) => {
                                            return (
                                                <Select.Option value={item.uuid} key={item.uuid}>
                                                    {item.wenzhangBiaoti}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                    :
                                    widget === "product" ?
                                        <Select showSearch optionFilterProp="children" allowClear>
                                            {productList.map((item: any) => {
                                                return (
                                                    <Select.Option value={item.uuid}
                                                                   key={item.uuid}>
                                                        {item.name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                        :
                                        widget === "productList" ?
                                            <Select showSearch optionFilterProp="children" allowClear>
                                                {productCateList.map((item: any) => {
                                                    return (
                                                        <Select.Option value={item.uuid}
                                                                       key={item.uuid}>
                                                            {item.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>:
                                    <Input/>
                            )}
                        </FormItem>
                        <FormItem label={"是否显示"} name={"visible"} valuePropName={"checked"}>
                            <Switch checkedChildren="是" unCheckedChildren="否"/>
                        </FormItem>
                        <FormItem label={"图标"} name={"icon"}>
                            <Upload
                                name="image"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={Api.apiRootPath + "/api/picture/upload_qiniu_two"}
                                onChange={getFileName}
                            >
                                {icon !== null && icon !== undefined && icon !== "" ? (
                                    <img alt="avatar" style={{ width: "100%" }} src={icon}/>
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </FormItem>
                        <FormItem label={"web_view配置"} name={"webViewDeploy"}>
                            <Input/>
                        </FormItem>
                        <FormItem label={"权限"} name={"perms"} tooltip={"对应的SaaS后台员工分组权限设置"}>
                            <Select showSearch optionFilterProp="children">
                                {paramData.length > 0
                                    ? paramData.map((item: any) => {
                                        return (
                                            <Select.Option value={item.permKey} key={item.permKey}>
                                                {item.name}
                                            </Select.Option>
                                        );
                                    })
                                    : ""}
                            </Select>
                        </FormItem>
                        <FormItem label={"是否需要登录"} name={"islogin"} valuePropName={"checked"}>
                            <Switch checkedChildren="是" unCheckedChildren="否"/>
                        </FormItem>
                        <FormItem
                            label={"重复提交判断"}
                            name={"comeLiuchengDay"}
                            tooltip={"流程在这几天内是否重复提交判断"}
                        >
                            <Input/>
                        </FormItem>
                        <FormItem
                            label={"进入项条件"}
                            name={"comeCondition"}
                            // tooltip={
                            // 	'进入项的条件：实名认证（SMRZ），绑定公司（BDGS），银行卡（YHK)，个人资格（GRZG）,个人房源（GRFY）'
                            // }
                        >
                            <Select mode="tags" allowClear tokenSeparators={[',']}>
                                <Option value={"SMRZ"}>实名认证</Option>
                                <Option value={"BDGS"}>绑定公司</Option>
                                <Option value={"GSGLR"}>公司管理员</Option>
                                <Option value={"YHK"}>个人绑定银行卡并同步</Option>
                                <Option value={"GRZG"}>房管局审核通过</Option>
                                <Option value={"GRFY"}>个人获得公司分配房源</Option>
                            </Select>
                        </FormItem>
                        <FormItem label={"提示信息"} name={"msg"}>
                            <Input/>
                        </FormItem>
                        <FormItem label={"菜单选项"} name={"isMenu"} valuePropName={"checked"}>
                            <Switch checkedChildren="是" unCheckedChildren="否"/>
                        </FormItem>
                        <FormItem label={"第三方服务选项"} name={"third"} valuePropName={"checked"}>
                            <Switch checkedChildren="是" unCheckedChildren="否"/>
                        </FormItem>
                        <FormItem label={"是否隐私协议弹框"} name={"isShowModal"} initialValue={false} valuePropName={"checked"}>
                            <Switch checkedChildren="是" unCheckedChildren="否"/>
                        </FormItem>
                        <FormItem label={"备注"} name={"remark"}>
                            <Input/>
                        </FormItem>
                        <FormItem label={"create_by"} name={"createBy"} hidden={true}>
                            <Input/>
                        </FormItem>
                    </ProxyForm>
                </Modal>
            </Spin>
            <Modal
                visible={sortVisible}
                title={"排序"}
                confirmLoading={sortLoading}
                onCancel={() => {
                    setSortVisible(false);
                }}
                onOk={sortDataItem}
            >
                <DndProvider backend={HTMLBackend}>
                    <Row>
                        {sortData.map((item: any, index: any) => {
                            return (
                                <Col span={6} style={{ marginTop: 10 }}>
                                    <CardItem
                                        //@ts-ignore
                                        index={index}
                                        hoverable
                                        type={"AppModel"}
                                        key={index}
                                        item={item}
                                        moveCard={moveCard}
                                    ></CardItem>
                                </Col>
                            );
                        })}
                    </Row>
                </DndProvider>
            </Modal>

            <Modal
                title={"复制菜单"}
                visible={syncVisible}
                onCancel={() => {
                    setSyncVisible(false);
                }}
                onOk={syncItem}
            >
                <GSTable
                    columns={columns}
                    actionRef={actionRef}
                    rowKey="id"
                    selectedRowKeys={selectedRowKeys}
                    rowSelection={rowSelection}
                    tableAlertRender={false}
                    searchBarRender={tableSearchBarRender()}
                    pagination={false}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        const res = await getAppModel1List({
                            search: search,
                            wuyeUuid: selectWyUuid,
                            container: tableRq
                        });
                        return {
                            data: res.data,
                            success: true
                        };
                    }}
                />
            </Modal>
        </>
    );
};
export default AppParameterSet;
