import React, { useEffect, useState } from 'react';
import Dal from '../../data/DAL';
import Phone from '../../components/Phone';
import { Col, Row } from 'antd';
export default () => {
	const [url, setUrl] = useState('');
	useEffect(() => {
		let datas = window.location.href
		const urls = datas.split("previewUrl=")
		// let previewUrl = Dal.dal.getQueryString('previewUrl');
		console.log("urls::",urls)
		if(urls.length>1){
			setUrl(urls[1]);
		}
	}, []);
	return (
		<Row style={{ paddingTop: 80, marginTop: 0 }}>
			<Col span={24}>
				<Phone url={url} />
			</Col>
		</Row>
	);
};
