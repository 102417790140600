import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, message, Modal, Row, Space, TreeSelect } from 'antd';
import {
	addSchedul,
	batchSchedul,
	findPatrollEmployeeTree,
	findSchedul,
	updataSchedul
} from '../../../services/patrolService';
import API from '../../../data/API';
import DAL from '../../../data/DAL';

require('./patrolplane.css');
var msg = require('../../../util/SuspensionMsg');
var moment = require('moment');
const { RangePicker } = DatePicker;
const StaffRouteList: React.FC = (props: any) => {
	useEffect(() => {
		getfindSchedul();
		allSecurity();
	}, []);
	const routeUuid = DAL.dal.getQueryString('uuid');
	const [ds, setDs] = useState([]);
	const [shiftTree, setShiftTree] = useState([]);
	const [isShow, setIsShow] = useState(false);
	const [weekData, setWeekData] = useState([]);
	const [shiftSelect, setShiftSelect] = useState<any>(undefined);
	const [shijianDate, setShijianData] = useState('');
	const [employeeTree, setEmployeeTree] = useState<any>([]);
	const [employeeSelect, setEmployeeSelect] = useState<any>(undefined);
	const [employeeSel, setEmployeeSel] = useState<any>([]);
	const [uuid, setUuid] = useState('');
	const [addorupdate, setAddorupdate] = useState(false);
	const [visible, setVisible] = useState(false);
	const [shiftUuid, setShiftUuid] = useState('');
	const [week, setWeek] = useState('');
	const [disabled, setDisabled] = useState('');
	const [addLoading, setAddLoading] = useState(false);
	const [weekDate, setWeekDate] = useState('');

	const allSecurity = async () => {
		const res = await findPatrollEmployeeTree({});
		if (res.err === 0) {
			console.log('获得人员(树选择)', res.data);
			setEmployeeTree(res.data);
			setIsShow(false);
		}
	};

	/*查询排班*/
	const getfindSchedul = async () => {
		const res = await findSchedul({
			route_uuid: routeUuid,
			companyUuid: API.getCompanyUuid()
		});
		if (res.err === 0) {
			console.log('排班列表', res.data);
			setDs(res.data);
			setShiftTree(res.shiftData);
			setIsShow(false);
			setWeekData(res.weekData);
		}
	};
	/*/!*上一周*!/*/
	const prev = async (ev: any) => {
		let j = jQuery as any;
		let weeks = j('#weekDate').val();
		let weekss = weeks.split('/');
		var startweek = weekss[0];
		var dd = new Date(startweek);
		dd.setDate(dd.getDate() - 7);
		var y = dd.getFullYear();
		var m = dd.getMonth() + 1;
		var d = dd.getDate();
		var Sweek = y + '-' + m + '-' + d;
		var endweek = weekss[1];
		var ddd = new Date(endweek);
		ddd.setDate(ddd.getDate() - 7);
		var yy = ddd.getFullYear();
		var mm = ddd.getMonth() + 1;
		var d1 = ddd.getDate();
		var Eweek = yy + '-' + mm + '-' + d1;
		const res = await findSchedul({
			route_uuid: routeUuid,
			currentMonday: Sweek,
			previousSunday: Eweek,
			companyUuid: API.getCompanyUuid()
		});
		if (res.err === 0) {
			console.log('', res);
			setIsShow(false);
			setDs(res.data);
			setWeekData(res.weekData);
		}
	};
	const /*下一周*/
		next = async (ev: any) => {
			let j = jQuery as any;
			let weeks = j('#weekDate').val();
			let weekss = weeks.split('/');
			var startweek = weekss[0];
			var dd = new Date(startweek);
			dd.setDate(dd.getDate() + 7);
			var y = dd.getFullYear();
			var m = dd.getMonth() + 1;
			var d = dd.getDate();
			var Sweek = y + '-' + m + '-' + d;
			var endweek = weekss[1];
			var ddd = new Date(endweek);
			ddd.setDate(ddd.getDate() + 7);
			var yy = ddd.getFullYear();
			var mm = ddd.getMonth() + 1;
			var d1 = ddd.getDate();
			var Eweek = yy + '-' + mm + '-' + d1;
			const res = await findSchedul({
				route_uuid: routeUuid,
				currentMonday: Sweek,
				previousSunday: Eweek,
				companyUuid: API.getCompanyUuid()
			});
			if (res.err === 0) {
				console.log('', res);
				setDs(res.data);
				setWeekData(res.weekData);
				setIsShow(false);
			}
		};
	const disabledDate = (current: any) => {
		return current && current < moment().subtract(1, 'days');
	};
	const getKaishiDate = (date: any, dateToString: any) => {
		console.log('开始' + dateToString);
		setShijianData(dateToString);
	};
	//批量排班
	const batchSchedulItem = async () => {
		if (shijianDate === null || shijianDate === '' || shijianDate === undefined) {
			message.warning('请选择时间段');
			return;
		}
		if (shiftSelect === null || shiftSelect === '' || shiftSelect === undefined || shiftSelect.length < 1) {
			message.warning('请选择班次');
			return;
		}
		if (
			employeeSelect === null ||
			employeeSelect === '' ||
			employeeSelect === undefined ||
			employeeSelect.length < 1
		) {
			message.warning('请选择人员');
			return;
		}
		setIsShow(true);
		const res = await batchSchedul({
			shijianDate: shijianDate.toString(),
			shiftSelect: shiftSelect.toString(),
			employeeSelect: employeeSelect.toString()
		});

		if (res.err === 0) {
			getfindSchedul();
			message.success('批量排班成功');
		} else {
			message.error(res.msg);
		}
		setIsShow(false);
	};
	/*点击人员*/
	const paiBanPerson = (item: any, ev: any) => {
		console.log('123456789', item);
		setUuid(ev.target.id);
		let employeeUuids: any = [];
		item.peopleUuId.map((peopleUuid: any) => {
			for (let i = 0; i<employeeTree.length; i++) {
				if (employeeTree[i].children) {
					for (let j=0; j<employeeTree[i].children.length; j++) {
						if (props.deviceId !== '' && employeeTree[i].children[j].value.includes(peopleUuid)) {
							employeeUuids.push(employeeTree[i].children[j].value);
							console.log('找到了：：：', employeeTree[i].children[j].value);
							return
						}
					}
				}
			}
		})

		setEmployeeSel(employeeUuids);
		setAddorupdate(false);
		setVisible(true);
	};
	const addset = (ev: any) => {
		let j = jQuery as any;
		let weekD = j('#weekDate').val();
		let weekDe = weekD.split('/');
		let weekDate = weekDe[0];
		setShiftUuid(ev.target.title);
		setWeek(ev.target.id);
		setWeekDate(weekDate);
		setAddorupdate(true);
		setEmployeeSel([]);
		setVisible(true);
	};
	/*排班页面*/
	const schedul = (item: any, currentMonday: any) => {
		console.log(item);
		var res = [];
		for (let i = 0; i <= 7; i++) {
			let paiBan = item.fn;
			let b = 0;
			let s = '';
			for (; b < paiBan.length; b++) {
				if (i === 7 && new Date(paiBan[b].schedulDate).getDay() === 0) {
					if ( moment().diff(moment(paiBan[b].schedulDate, 'YYYY-MM-DD'),'day') > 0) {
						res.push(
							<td style={{ flex: 1 }}>
								<a
									id={paiBan[b].uuid}
									className="disable"

									onClick={(event) => {
										paiBanPerson(paiBan[b], event);
									}}
								>
									{paiBan[b].people.toString()}
								</a>
							</td>
						);
					} else {
						res.push(
							<td style={{ flex: 1 }}>
								<a
									id={paiBan[b].uuid}
									onClick={(event) => {
										paiBanPerson(paiBan[b], event);
									}}
								>
									{paiBan[b].people.toString()}
								</a>
							</td>
						);
					}

					break;
				}
				if (i !== 0 && new Date(paiBan[b].schedulDate).getDay() === i) {
					console.log('时间差：：', moment(paiBan[b].schedulDate, 'YYYY-MM-DD'), moment().diff(moment(paiBan[b].schedulDate, 'YYYY-MM-DD'),'day'))
					if ( moment().diff(moment(paiBan[b].schedulDate, 'YYYY-MM-DD'),'day') > 0) {
						res.push(
							<td style={{ flex: 1 }}>
								<a
									id={paiBan[b].uuid}
									onClick={(event) => {
										paiBanPerson(item.fn[b], event);
									}}
									className="disable"
								>
									{paiBan[b].people.toString()}
								</a>
							</td>
						);
					} else {
						res.push(
							<td style={{ flex: 1 }}>
								<a
									id={paiBan[b].uuid}
									onClick={(event) => {
										paiBanPerson(item.fn[b], event);
									}}
								>
									{paiBan[b].people.toString()}
								</a>
							</td>
						);
					}
					break;
				}
				if (new Date(paiBan[b].schedulDate).getDay() !== i) {
					s = paiBan[b].shiftUuid;
					//res.push(<td><a id={i.toString()} className={paiBan[b].shiftUuid} onClick={addset} type="button" data-toggle="modal" data-target="#addSchedules" >点击设置</a></td>);
				}
			}
			if (i !== 0 && b === paiBan.length) {
				let date = moment(currentMonday, 'YYYY-MM-DD').add(i-1,"day");
				console.log("时间：：：：", date , i)
				if ( moment().diff(date,'day') > 0) {
					res.push(
						<td style={{ flex: 1 }}>
							<a
								id={i.toString()}
								className={'disable'}
								title={s}
								onClick={(event) => {
									addset(event);
								}}
							>
								点击设置
							</a>
						</td>
					);
				} else {
					res.push(
						<td style={{ flex: 1 }}>
							<a
								id={i.toString()}
								title={s}
								onClick={(event) => {
									addset(event);
								}}
							>
								点击设置
							</a>
						</td>
					);
				}
			}


		}
		return res;
	};
	const /*添加排班人员*/
		addPerson = async () => {
			setIsShow(false);
			if (employeeSel === null || employeeSel === '' || employeeSel === undefined || employeeSel.length < 1) {
				message.warning('请选择人员');
				return;
			}
			setAddLoading(true);
			const res = await addSchedul({
				shiftUuid: shiftUuid,
				week: week,
				weekDate: weekDate,
				person: employeeSel.toString()
			});
			setAddLoading(false);
			console.log('...123', res);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				getfindSchedul();
			} else {
				msg.suspensionMsg({ content: '添加失败', type: 'error' });
			}
			setVisible(false);
		};
	const handleOk = () => {
		if (addorupdate) {
			addPerson();
		} else {
			UpdataSchdulPerson();
		}
	};
	/*修改排班人员*/
	const UpdataSchdulPerson = async () => {
		setIsShow(false);
		// if (employeeSel === null || employeeSel === '' || employeeSel === undefined || employeeSel.length < 1) {
		// 	message.warning('请选择人员');
		// 	return;
		// }
		let employeeUuids: any = [];
		employeeSel.map((peopleUuid: any) => {
			if (peopleUuid.includes(":::")) {
				employeeUuids.push(peopleUuid.split(":::")[1])
			} else {
				employeeUuids.push(peopleUuid)
			}
		})
		setAddLoading(true)
		const res = await updataSchedul({
			uuid: uuid,
			person: employeeUuids.toString()
		});
		setAddLoading(false)
		console.log('', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '修改成功', type: 'success' });
			getfindSchedul();
		} else {
			msg.suspensionMsg({ content: '修改失败', type: 'error' });
		}
		setVisible(false);
	};
	return (
		<div style={{ padding: '10px 0px' }}>
			{ds.map((item: any, index: any) => (
				// <li>
				<Space>
					<Input
						style={{ width: 180 }}
						type="text"
						id="weekDate"
						value={item.currentMonday + '/' + item.previousSunday}
						readOnly={true}
					/>
					<Button id={item.route_uuid} onClick={prev}>
						上一周
					</Button>
					<Button id={item.route_uuid} onClick={next}>
						下一周
					</Button>
				</Space>
				// </li>
			))}
			<div className="ant-pro-table ">
				<div className="container-fluid" style={{ padding: '16px 0px' }}>
					<Space>
						<RangePicker
							disabledDate={disabledDate}
							format="YYYY-MM-DD"
							placeholder={['开始日期', '结束日期']}
							onChange={getKaishiDate}
						/>
						<TreeSelect
							treeData={shiftTree}
							value={shiftSelect}
							onChange={(value: any) => {
								setShiftSelect(value);
							}}
							style={{ width: 300 }}
							treeCheckable={true}
							multiple={true}
							treeNodeFilterProp="title"
							placeholder="选择班次"
							dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
						/>
						<TreeSelect
							treeData={employeeTree}
							maxTagCount={1}
							style={{ width: 300 }}
							defaultValue={employeeSelect}
							onChange={(value: any) => {
								//去重
								let set = new Set();
								value.map((item: string) => {
									set.add(item.split(":::")[1])
								});
								setEmployeeSelect([...set]);
							}}
							treeCheckable={true}
							multiple={true}
							treeNodeFilterProp="title"
							placeholder="选择人员"
							dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
						/>
						<Button type="primary" onClick={batchSchedulItem}>
							批量排班
						</Button>
					</Space>
				</div>
				<div className="ant-table-wrapper">
					<table
						style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}
					>
						<thead className={'ant-table-thead'} style={{ display: 'flex', flex: 1 }}>
						<tr style={{ display: 'flex', flex: 1 }}>
							<th style={{ flex: 1 }}>班次</th>
							{console.log('weekData', weekData)}
							{weekData.map((item: any) => (
								<th style={{ flex: 1 }}>{item}</th>
							))}
						</tr>
						</thead>
						{ds.map((item: any, index: any) => (
							<tbody
								className={'ant-table-tbody'}
								style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
								key={index}
							>
							{item.paiBan.map((item1: any, index1: any) => (
								<tr style={{ flex: 1, display: 'flex' }} key={index1}>
									<td style={{ flex: 1, display: 'flex' }}>
										{item1.shiftName}({item1.startTime}-{item1.endTime})
									</td>
									{schedul(item1, item.currentMonday)}
								</tr>
							))}
							</tbody>
						))}
					</table>
				</div>
			</div>

			<Modal
				title="人员选择"
				visible={visible}
				destroyOnClose={true}
				onOk={handleOk}
				onCancel={() => {
					setVisible(false);
				}}
				confirmLoading={addLoading}
			>
				<div>
					<Row>
						<Col span={6}>人员选择:</Col>
						<Col span={12}>
							<TreeSelect
								treeData={employeeTree}
								defaultValue={employeeSel}
								onChange={(value: any) => {
									let set = new Set();
									value.map((item: string) => {
										set.add(item.split(":::")[1])
									});
									setEmployeeSel([...set]);
								}}
								treeCheckable={true}
								multiple={true}
								treeNodeFilterProp="title"
								searchPlaceholder="请选择人员"
								dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
								style={{ width: 300 }}
							/>
						</Col>
					</Row>
				</div>
			</Modal>

			{isShow ? (
				<div className="cover">
					<div>
						<i className="icon icon-spin icon-spinner-indicator"/>
					</div>
				</div>
			) : null}
		</div>
	);
};
export default StaffRouteList;
