import React, { useCallback, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import {
	addHouseType,
	deleteHouseType,
	getHouseTypeList,
	sortHouseType,
	updateHouseType,
} from '../../../services/ZhuhushenheService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Button, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import HouseTypeImg from './HouseTypeImg';
import WangEditor from '../../../components/WangEditor';
import { DndProvider } from 'react-dnd';
import HTMLBackend from 'react-dnd-html5-backend';
import DragableBodyRow from '../../../components/DragableBodyRow';
const HouseTypeList: React.FC = () => {
	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const actionRef = useRef<ActionType>();
	const actionRef1 = useRef<ActionType>();
	const [item, setItem] = useState({});
	const [imgVisible, setImgVisible] = useState(false);
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [description, setDescription] = useState('');
	const [dateKey, setDateKey] = useState(new Date().getTime().toString());
	const [sortVisible, setSortVisible] = useState(false);
	const [sortData, setSortData] = useState([]);
	const FormItem = Form.Item;
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '面积',
			dataIndex: 'area',
			key: 'area',
		},
		{
			title: '备注',
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							openUpdateModal(record);
						}}
					>
						编辑
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							toImg(record);
						}}
					>
						图片管理
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];

	const columns1 = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '面积',
			dataIndex: 'area',
			key: 'area',
		},
		{
			title: '备注',
			dataIndex: 'note',
			key: 'note',
		},
	];
	const toImg = (value: any) => {
		setItem(value);
		setImgVisible(true);
	};

	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '你确定要删除' + value.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteHouseType({ id: value.id });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error('删除失败');
				}
			},
		});
	};
	const openUpdateModal = (value: any) => {
		const formData: any = {
			id: value.id,
			name: value.name,
			note: value.note,
			area: value.area,
			type: value.type,
		};
		setDescription(value.description);
		form.setFieldsValue(formData);
		setDateKey(new Date().getTime().toString());
		setAdd(false);
		setVisible(true);
	};
	const tableSearchBarRender = () => [
		// <Search
		// 	placeholder="名称"
		// 	onSearch={(value) => {
		// 		setSearch(value);
		// 		if (actionRef.current) {
		// 			actionRef.current.reloadAndRest();
		// 		}
		// 	}}
		// 	enterButton={true}
		// />,
	];
	const openAddModal = () => {
		form.resetFields();
		setDateKey(new Date().getTime().toString());
		setDescription('');
		setAdd(true);
		setVisible(true);
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const data1: any = sortData[sortData.length - 1];
			let sort1 = 0;
			if (data1 !== null && data1 !== '' && data1 !== undefined) {
				sort1 = data1.sort + 1;
			}
			const formData = {
				name: data.name,
				note: data.note,
				description: data.description,
				area: data.area,
				sortKey: sort1,
				type: data.type,
			};

			const res = await addHouseType(formData);
			if (res.err === 0) {
				message.success('添加成功');
			} else {
				message.success('添加失败');
			}
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				id: data.id,
				name: data.name,
				note: data.note,
				description: description,
				area: data.area,
				type: data.type,
			};
			const res = await updateHouseType(formData);
			if (res.err === 0) {
				message.success('修改成功');
			} else {
				message.success('修改失败');
			}
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		});
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={openAddModal}>
			新增
		</Button>,
		<Button
			type="primary"
			onClick={() => {
				setSortVisible(true);
			}}
		>
			编辑排序
		</Button>,
	];
	const components = {
		body: {
			row: DragableBodyRow,
		},
	};
	const getData = () => {
		return {
			data: sortData,
		};
	};
	const moveRow = useCallback(
		async (dragIndex, hoverIndex, key) => {
			if (dragIndex === hoverIndex) {
				return;
			}
			console.log('dragIndex', dragIndex);
			console.log('hoverIndex', hoverIndex);
			let route = [...sortData];
			const d1 = route[dragIndex];
			const ho = route[hoverIndex];
			console.log('data::', d1, ho);
			let tmp = route[dragIndex]; //临时储存文件
			route.splice(dragIndex, 1); //移除拖拽项
			route.splice(hoverIndex, 0, tmp); //插入放置项
			setSortData([...route]);
			// findArray(routes, key, dragIndex, hoverIndex);
			actionRef1.current.reload();
		},
		[sortData]
	);

	const sortDataItem = async () => {
		console.log('排序：：：', '排序');
		const data = sortData;
		let ids: any = [];
		data.map((item) => {
			ids.push(item.id);
		});
		console.log('ids', ids);
		const res = await sortHouseType({ ids: ids.toString() });
		if (res.err === 0) {
			setSortVisible(false);
			message.success('排序成功');
			actionRef.current.reload();
		} else {
			message.error('排序失败');
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				pagination={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getHouseTypeList({
						name: search,
					});
					if (res.err !== 0) {
						setSortData([]);
						return {
							data: [],
							total: 0,
						};
					}
					setSortData(res.data);
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				visible={visible}
				width={1200}
				title={add ? '添加' : '修改'}
				onOk={saveItem}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} name={'id'}>
						<Input />
					</FormItem>
					<FormItem name={'name'} label={'名称'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem name={'type'} label={'类型'}>
						<Input />
					</FormItem>
					<FormItem name={'description'} label={'描述'}>
						<WangEditor
							key={dateKey}
							value1={description}
							changeCallback={(res: any) => setDescription(res)}
						/>
					</FormItem>
					<FormItem name={'area'} label={'面积（m²）'}>
						<InputNumber step="0.01" style={{ width: '100%' }} />
					</FormItem>
					<FormItem name={'note'} label={'备注'}>
						<Input />
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				width={1200}
				visible={imgVisible}
				title={'图片管理'}
				onCancel={() => {
					setImgVisible(false);
				}}
				footer={null}
				onOk={() => {
					setImgVisible(false);
				}}
			>
				<HouseTypeImg isHidenImgType={false}  imgType={"房间户型图片"} key={item.id} houseTypeId={item.id === undefined ? '' : item.id} />
			</Modal>
			<Modal
				visible={sortVisible}
				title={'排序'}
				onCancel={() => {
					setSortVisible(false);
				}}
				onOk={sortDataItem}
			>
				<DndProvider backend={HTMLBackend}>
					<GSTable
						options={false}
						actionRef={actionRef1}
						components={components}
						onRow={(record: any, index: any) => ({
							index,
							moveRow,
						})}
						columns={columns1}
						request={getData}
						pagination={false}
					/>
				</DndProvider>
			</Modal>
		</>
	);
};
export default HouseTypeList;
