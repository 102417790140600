import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import TemperatureRecords from './pages/TemperatureRecords';
import React from 'react';
import EpidemicPreventionReport from './pages/EpidemicPreventionReport';
import HealthCheckRecord from "./pages/HealthCheckRecord";

export default function EpidemicPreventionMiniApp() {
	const rootUrl = '/epidemic-prevention';
	const menus: MiniAppMenu[] = [
		{
			authority: 'temperature_records',
			name: '防疫通行记录',
			path: 'temperatureRecords',
			component: TemperatureRecords,
		},
		{
			authority: 'health_check_records',
			name: '防疫核验记录',
			path: 'healthCheckRecords',
			component: HealthCheckRecord,
		},
		{
			authority: 'temperature_platform',
			name: '防疫统计',
			path: 'epidemic_prevention_report',
			component: EpidemicPreventionReport,
		},
	];

	return <MiniApp miniAppName={'防疫管理'} rootUrl={rootUrl} menus={menus} />;
}
