import React, { useEffect, useRef, useState } from "react";
import {
    addProjectEmp,
    deleteProjectEmp,
    findProjectList,
    projectPersonAndAllPerson
} from "../../../services/WuyeService";
import API from "../../../data/API";
import { Card, Col, message, Modal, Row, Transfer, Button } from "antd";

interface ProjectListPro {
}

const ProjectList: React.FC<ProjectListPro> = () => {
    useEffect(() => {
        getProject();
    }, []);

    const [project, setProject] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [projectUuid, setProjectUuid] = useState("");
    const [mockData, setMockData] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [visible, setVisible] = useState(false);
    const [direction, setDirection] = useState("");
    const [moveKeys, setMoveKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const getProject = async () => {
        const res = await findProjectList({
            companyUuid: API.getCompanyUuid()
        });
        if (res.err === 0) {
            console.log("获得项目列表", res);
            setProject(res.data);
            setIsShow(false);
        }
    };

    const showModal = async (uuid: string) => {
        setProjectUuid(uuid);
        setLoading(true)
        const res = await projectPersonAndAllPerson({
            companyUuid: API.getCompanyUuid(),
            projectUuid: uuid
        });
        setLoading(false)
        if (res.err === 0) {
            setMockData(res.companyEmp);
            setTargetKeys(res.projectEmp);
        }
        setVisible(true);
    };

    const handleOk = () => {
        if (direction === null || direction === undefined || direction === "") {
            message.warning("请选择");
            return;
        }
        if (direction === "right") {
            insertProjectEmp();
        } else {
            deleteProjectEmpolyee();
        }
    };
    const insertProjectEmp = async () => {
        const res = await addProjectEmp({
            projectUuid: projectUuid,
            moveKeys: moveKeys.toString()
        });
        if (res.err === 0) {
            console.log("添加项目人员", res);
            message.success("操作成功");
        } else {
            message.error(res.msg);
        }
        setVisible(false);
    };
    const deleteProjectEmpolyee = async () => {
        const res = await deleteProjectEmp({
            projectUuid: projectUuid,
            moveKeys: moveKeys.toString()
        });
        if (res.err === 0) {
            console.log("删除项目人员", res);
            message.success("操作成功");
        } else {
            message.error(res.msg);
        }
        setVisible(false);
    };
    const filterOption = (inputValue: any, option: any) => {
        if (option.title !== null) {
            return option.title.indexOf(inputValue) > -1;
        } else {
            return false;
        }
    };

    const handleChange = (targetKeys: any, direction: any, moveKeys: any) => {
        console.log("穿梭框", targetKeys, direction, moveKeys);
        setTargetKeys(targetKeys);
        setDirection(direction);
        setMoveKeys(moveKeys);
    };
    const handleSearch = (dir: any, value: any) => {
        console.log("search:", dir, value);
    };
    return (
        <div>
            <Row gutter={16} style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                {project.map((item: any, index: any) => (
                    <Col span={6}>
                        <Card
                            hoverable
                            cover={
                                <img
                                    alth="example"
                                    style={{ height: 150 }}
                                    src={
                                        item.projImage == "" || item.projImage == undefined || item.projImage == null
                                            ? "http://pcdn.guosim.com/7a66a1c0-ad0d-4e75-87ed-d13a88bd57d01610092579912.jpeg"
                                            : item.projImage
                                    }
                                />
                            }
                            loading={isShow}
                            bordered={true}
                            actions={[
                                <Button type={"link"}
                                        loading={loading}
                                        key={index}
                                        onClick={() => {
                                            showModal(item.uuid);
                                        }}
                                >
                                    设置员工
                                </Button>
                            ]}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>名称：{item.name}</div>
                            <div style={{ display: "flex", alignItems: "center", paddingTop: 5 }}>
                                地址：{item.address}
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Modal
                title="分配人员"
                visible={visible}
                onOk={handleOk}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Row justify="center">
                    <Col>
                        <Transfer
                            listStyle={{ width: 300, height: 300 }}
                            dataSource={mockData}
                            showSearch={true}
                            filterOption={filterOption}
                            targetKeys={targetKeys}
                            onChange={handleChange}
                            render={(item) => item.title}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};
export default ProjectList;
