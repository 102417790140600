import * as React from 'react';
import DAl, { default as DAL } from '../../../data/DAL';
import API from '../../../data/API';
import { DatePicker, TreeSelect, Radio, Tooltip, Modal, Input, Row, Col, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getDevices } from '../../../services/ZhiNengMenJinService';
import { useEffect, useState } from "react";

let msg = require('../../../util/SuspensionMsg');
let moment = require('moment');
const Option = Select.Option;
const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;
const tooltipText = (
	<div>
		<div>普通用户：只能开锁；</div>
		<div>管理和查看：可以发送，删除钥匙，可以查看使用记录；</div>
		<div>管理：可以发送，删除钥匙；</div>
		<div>使用和查看：可以使用、查看使用记录；</div>
		<div>超级管理员：可以开锁，发送删除钥匙，查看使用记录</div>
	</div>
);
interface SendKeyModalInterFace {
	visible: boolean;
	onCancel: (...e: any) => void | any;
	callback?: (...e: any) => void | any;
	deviceId?: string;
	userId?: string;
}

const SendKeyModal :React.FC<SendKeyModalInterFace> = (props: any) => {
	const dal = DAL.dal;
	const [contact, setContact] = useState<Array<string>>([]);
	const [deviceIds, setDeviceIds] = useState<Array<string>>([]);
	const [keyType, setKeyType] = useState< string | undefined>('');
	const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
	const [expireTime, setExpireTime] = useState(moment().add(1, 'y').format('YYYY-MM-DD HH:mm:ss'));
	const [showRow,setShowRow] = useState(false);
	const [devices, setDevices] = useState<any>([]);
	const [deviceId, setDeviceId] = useState(props.deviceId);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [isShow, setIsShow] = useState(false);


	useEffect(() => {
		getDeviceList().then()
		console.log('deviceId::::', props, deviceId)
	},[])

	useEffect(() => {
		setDeviceId(props.deviceId);
		for (let i = 0; i<devices.length; i++) {
			if (devices[i].children) {
				for (let j=0; j<devices[i].children.length; j++) {
					if (props.deviceId !== '' && devices[i].children[j].value.includes(props.deviceId)) {
						setDeviceId(devices[i].children[j].value);
						console.log('找到了：：：', devices[i].children[j].value);
						return
					}
				}
			}
		}
	}, [props.deviceId])

	useEffect(() => {
		setContact(props.userId)
	}, [props.userId])

	const getDeviceList = async () => {
		const res = await getDevices({});
		if (res.err !== 0) {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		setDevices(res.data)
	};
	/** 发送钥匙 */
	const callData = () => {
		console.log('this.deviceIds', deviceIds);
		let sendDeviceId = props.deviceId === undefined ? deviceIds.toString() : props.deviceId;
		let sendContact = props.userId === undefined ? contact.toString() : props.userId;
		setIsShow(true)

		if (sendDeviceId === undefined || sendDeviceId === '') {
			msg.suspensionMsg({ content: '发送的设备不能为空', type: 'danger' });
			setIsShow(false)
			return;
		}

		if (sendContact === undefined || sendContact === '') {
			msg.suspensionMsg({ content: '接收人不能为空', type: 'danger' });
			setIsShow(false)
			return;
		}

		let url = '/send_keys';
		let datas = { contacts: '', device_id: '', key_type: '', start_time: '', expire_time: '' };
		datas.contacts = sendContact;
		datas.device_id = sendDeviceId;
		datas.key_type = keyType || 'anytime';
		datas.start_time = startTime;
		datas.expire_time = expireTime;
		setConfirmLoading(true)
		API.postWithAuth(url, datas).then((res) => {
			setConfirmLoading(false);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '发送成功', type: 'success' });
				props.onCancel();
				if (props.callback !== undefined) {
					props.callback();
				}
			}else {
				msg.suspensionMsg({ content: '发送结果' + (res as any).msg });
			}
		});
	};

	const getContact = (e: any) => {
		console.log('e', e);
		setContact(e)
	};

	const Jurlsdiction = (ev: any) => {
		setKeyType(ev)
	};

	const setDateByRadio = (e: any) => {
		let type = e.target.value;
		let startTime = moment().format('YYYY-MM-DD HH:mm:ss');
		let endTime = '';
		switch (type) {
			case 1:
				endTime = moment().add(1, 'y').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 2:
				endTime = moment().add(1, 'M').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 3:
				startTime = '1111-01-01 00:00:00';
				endTime = '9999-01-01 00:00:00';
				break;
			default:
				endTime = '';
				break;
		}
		console.log(startTime, endTime);
		setStartTime(startTime);
		setExpireTime(endTime)
		setShowRow(type === -1)
	};

	const setExpiretime = (date: any, dataToString: any) => {
		console.log(date, dataToString);
		setStartTime(dataToString[0])
		setExpireTime(dataToString[1])
	};

	const deviceIdsOnChange = (ev: any) => {
		console.log('ev', ev);
		setDeviceIds(ev)
	};

	return <div>
		{/*发送钥匙*/}
		<Modal
			width={650}
			visible={props.visible}
			title="发送钥匙"
			onOk={callData}
			confirmLoading={confirmLoading}
			onCancel={props.onCancel}
		>
			<Row justify="center" align={'middle'} className={'antdRow'}>
				<Col span={4}>设备名称：</Col>
				<Col span={14}>
					<TreeSelect
						key={deviceId}
						defaultValue={deviceId === undefined ? undefined : deviceId.split(',')}
						style={{ width: '100%' }}
						disabled={props.deviceId !== undefined}
						dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
						treeData={devices}
						allowClear={true}
						multiple={true}
						treeNodeFilterProp={'title'}
						treeCheckable={true}
						showSearch={true}
						onChange={deviceIdsOnChange}
					/>
				</Col>
			</Row>

			<Row  justify="center" align={'middle'} className={'antdRow'}>
				<Col span={4}>接收账号：</Col>
				<Col span={14}>
					<Select
						key={props.userId === undefined ? '' : props.userId}
						defaultValue={props.userId}
						disabled={props.userId !== undefined}
						mode="tags"
						style={{ width: '100%' }}
						onChange={getContact}
						tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
					/>
				</Col>
			</Row>
			<Row className={'antdRow'}  align={'middle'} justify="center">
				<Col span={4}>
					权限选择{' '}
					<Tooltip placement="topLeft" title={tooltipText}>
						<QuestionCircleOutlined />
					</Tooltip>{' '}
					:
				</Col>
				<Col span={14}>
					<Select
						showSearch={true}
						style={{ width: 200 }}
						placeholder="请选择"
						optionFilterProp="children"
						onChange={Jurlsdiction}
						defaultValue={'anytime'}
					>
						<Option value="anytime">普通用户</Option>
						<Option value="manage">管理</Option>
						<Option value="look">查看</Option>
						<Option value="use_look">使用和查看</Option>
						<Option value="manage_look">管理和查看</Option>
						<Option value="use_manage">使用和管理</Option>
						<Option value="admin">超级管理员</Option>
					</Select>
				</Col>
			</Row>

			<Row className={'antdRow'}  align={'middle'} justify="center">
				<Col span={4}>有效期：</Col>
				<Col span={12}>
					<RadioGroup onChange={setDateByRadio} defaultValue={1}>
						<Radio value={1}>一年</Radio>
						<Radio value={2}>一个月</Radio>
						<Radio value={3}>永久</Radio>
						<Radio value={-1}>自定义</Radio>
					</RadioGroup>
				</Col>
			</Row>
			{showRow ? (
				<Row className={'antdRow'}  justify="center" align={'middle'}>
					<Col span={14} offset={4}>
						<RangePicker
							showTime={{ format: 'HH:mm:ss' }}
							format="YYYY-MM-DD HH:mm:ss"
							placeholder={['生效时间', '截止时间']}
							onChange={setExpiretime}
							getCalendarContainer={(value: any) => {
								return value;
							}}
						/>
					</Col>
				</Row>
			) : null}
		</Modal>
	</div>
}
export default SendKeyModal;
