import Api from '../data/API';
export async function getList(params: any) {
	return Api.postWithAuth('/admin/api/beijingOffice/getList', params);
}
export async function addBeijingOffice(params: any) {
	return Api.postWithAuth('/admin/api/beijingOffice/add', params);
}
export async function updateBeijingOffice(params: any) {
	return Api.postWithAuth('/admin/api/beijingOffice/update', params);
}
export async function deleteBeijingOffice(params: any) {
	return Api.postWithAuth('/admin/api/beijingOffice/delete', params);
}
export async function getCharacteristicEnterprisesList(params: any) {
	return Api.postWithAuth('/admin/api/characteristicEnterprises/getList', params);
}
export async function addCharacteristicEnterprises(params: any) {
	return Api.postWithAuth('/admin/api/characteristicEnterprises/add', params);
}
export async function updateCharacteristicEnterprises(params: any) {
	return Api.postWithAuth('/admin/api/characteristicEnterprises/update', params);
}
export async function deleteCharacteristicEnterprises(params: any) {
	return Api.postWithAuth('/admin/api/characteristicEnterprises/delete', params);
}

export async function getBeijingOfficeUserList(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeUser/getList', params);
}
export async function addBeijingOfficeUser(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeUser/add', params);
}
export async function updateBeijingOfficeUser(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeUser/update', params);
}
export async function deleteBeijingOfficeUser(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeUser/delete', params);
}
export async function getZjbOrderList(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeUser/getZjbOrderList', params);
}

export async function getBeijingOfficeDemandList(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeDemand/getList', params);
}
export async function updateBeijingOfficeDemand(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeDemand/update', params);
}
export async function updateBeijingOfficeTechDemand(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeTechDemand/update', params);
}
export async function deleteBeijingOfficeDemand(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeDemand/delete', params);
}
export async function addBeijingOfficeDemand(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeDemand/add', params);
}
export async function addBeijingOfficeTechDemand(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeTechDemand/add', params);
}
export async function updateStatusBeijingOfficeDemand(params: any) {
	return Api.postWithAuth('/admin/api/beijingOfficeDemand/updateStatus', params);
}
export async function getAllBeijingOfficeOptionData(params: any) {
	return Api.postWithAuth('/admin/api/beijingOffice/getAllBeijingOfficeOptionData', params);
}
export async function getCityList(params: any) {
	return Api.postWithAuth("/api/huodong/find_city_list", params)
}

