import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Col,
	List,
	message,
	Modal,
	Row,
	Select,
	Radio,
	Tabs,
	Tag,
	Statistic,
	Result,
	Spin,
	Descriptions,
	Popover,
	Tooltip,
} from 'antd';
import { RoomStatusData } from '../pages/zhuhushenhe/models/RoomStatusData';
import LayerPlanComponent from './LayerPlanComponent';
import ApiImp from '../data/API';
import AuthorizedView from '../util/AuthorizedView';
import { WuyeRoom } from '../pages/zhuhushenhe/models/WuyeRoom';
import { EditOutlined, EllipsisOutlined, SettingOutlined, ToTopOutlined } from '@ant-design/icons/lib';
import AddLeaseInfoForm from '../pages/zhuhushenhe/models/AddLeaseInfoForm';

interface RoomStatusProps {
	showType: string;
}

const RoomStatusComponents: React.FC<RoomStatusProps> = (props: any) => {
	const [type, setType] = useState('look');
	const [showType, setShowType] = useState('card');
	const [assetsType, setAssetsType] = useState('');
	const [buildUuid, setBuildUuid] = useState('');
	const [data, setData] = useState(Array<RoomStatusData>());
	const [buildData, setBuildData] = useState([]);
	const [isShow, setIsShow] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [currentRoomStatusData, setCurrentRoomStatusData] = useState(new RoomStatusData());
	const [visible, setVisible] = useState(false);
	const [roomInfo, setRoomInfo] = useState(new WuyeRoom());
	const [assetsTypeList, setAssetsTypeList] = useState([]);
	useEffect(() => {
		getAssetsType();
		getBuildData(assetsType);
	}, []);

	const getBuildData = (assetsTypeParams: string) => {
		let wuyeUuid = ApiImp.wuyeUuid || props.match.params.wuyeUuid; // 'cb743f05-e07e-4159-8a34-2d55ba713cea';
		ApiImp.postWithAuth('/api/zhuhu/find_builds_data', { wuyeUuid: wuyeUuid, assetsType: assetsTypeParams }).then(
			(res) => {
				if (res.err === 0) {
					console.log('获得楼栋列表', res);
					setBuildData(res.data);
					if (res.data.length > 0) {
						setBuildUuid(res.data[0].uuid);
						getData(res.data[0].uuid, assetsTypeParams);
					}
				}
			}
		);
	};

	const getTab = (state: number) => {
		switch (state) {
			case 0:
				return <Tag color="#e2e2e2">空置</Tag>;
			case 1:
				return <Tag color="#f50">已出租</Tag>;
			default:
				return <Tag color="#e2e2e2">空置</Tag>;
		}
	};

	const onChange = (key: string) => {
		console.log('key:::', key);
		setType('look');
		setCurrentRoomStatusData(data[parseInt(key, 10)]);
	};

	const getAssetsType = () => {
		ApiImp.postWithAuth('/api/zhuhu/get_assets_type', {}).then((res: any) => {
			if (res.err === 0) {
				setAssetsTypeList(res.data);
			}
		});
	};

	const getData = (buildUuidParams: string, assetsTypeParams: string) => {
		setSpinning(true);
		let params = {
			buildUuid: buildUuidParams,
			assetsType: assetsTypeParams,
		};
		let uri = '/api/zhuhu/room_status';
		ApiImp.postWithAuth(uri, params).then((res: any) => {
			setIsShow(true);
			setSpinning(false);
			if (res.err === 0) {
				let roomStatusDatas = Array<RoomStatusData>();
				res.data.map((item: any) => {
					let roomStatusData: RoomStatusData = new RoomStatusData();
					roomStatusData.setProperty(item);
					roomStatusDatas.push(roomStatusData);
				});
				setData(roomStatusDatas);
				setCurrentRoomStatusData(roomStatusDatas[0] || new RoomStatusData());
			} else {
				message.error(res.msg);
			}
		});
	};

	const content = (roomInfo: WuyeRoom) => {
		return (
			<div>
				<Row align={'middle'} gutter={16}>
					<Col>房间号：</Col>
					<Col>{roomInfo.roomNumber}</Col>
				</Row>
				<Row align={'middle'} gutter={16}>
					<Col>承租方：</Col>
					<Col>{roomInfo.name}</Col>
				</Row>
				{roomInfo.companyName && (
					<Row align={'middle'} gutter={16}>
						<Col>承租方公司：</Col>
						<Col>{roomInfo.companyName}</Col>
					</Row>
				)}
				<Row align={'middle'} gutter={16}>
					<Col>合同号：</Col>
					<Col>{roomInfo.contractNo}</Col>
				</Row>
				<Row align={'middle'} gutter={16}>
					<Col>合同单价：</Col>
					<Col>{roomInfo.price} 元</Col>
				</Row>
				<Row align={'middle'} gutter={16}>
					<Col>合同开始时间：</Col>
					<Col>{roomInfo.contractStartTime}</Col>
				</Row>
				<Row align={'middle'} gutter={16}>
					<Col>合同结束时间：</Col>
					<Col>{roomInfo.contractEndTime}</Col>
				</Row>
			</div>
		);
	};

	const renderActions = (roomInfoParams: WuyeRoom) => {
		if (roomInfoParams.state === 1) {
			return [
				<AuthorizedView needAuthority={'assets_management:assets:update'}>
					<ToTopOutlined
						key="setting"
						onClick={() => {
							deleteLeaseInfo(roomInfoParams);
						}}
					/>
				</AuthorizedView>,
			];
		}
		return [
			<AuthorizedView needAuthority={'assets_management:assets:add'}>
				<EditOutlined
					key="editing"
					onClick={() => {
						setVisible(true);
						setRoomInfo(roomInfoParams);
					}}
				></EditOutlined>
			</AuthorizedView>,
		];
	};

	const deleteLeaseInfo = (roomInfoParams: WuyeRoom) => {
		Modal.confirm({
			title: '您确定要退租？',
			onOk: () => {
				ApiImp.postWithAuth('/api/zhuhu/delete_lease_info', { assetsUuid: roomInfoParams.uuid }).then(
					(res: any) => {
						if (res.err === 0) {
							message.success(res.msg);
							getData(buildUuid, assetsType);
						} else {
							message.error(res.msg);
						}
					}
				);
			},
		});
	};

	return (
		<div>
			<Spin spinning={spinning}>
				<Row gutter={16} style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
					<Col span={24}>
						<Descriptions title="楼层信息统计" style={{ paddingBottom: 0 }}>
							<Descriptions.Item>
								<Statistic
									title="可出租总面积"
									value={currentRoomStatusData.totalArea}
									precision={2}
									suffix="㎡"
								/>
							</Descriptions.Item>
							<Descriptions.Item>
								<Statistic
									title="已租总面积"
									value={currentRoomStatusData.rentedArea}
									precision={2}
									suffix="㎡"
								/>
							</Descriptions.Item>
							<Descriptions.Item>
								<Statistic
									title="待出租总面积"
									value={currentRoomStatusData.forRentArea}
									precision={2}
									suffix="㎡"
								/>
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row gutter={16} align={'middle'} justify={'space-between'} style={{ padding: 15, height: 50 }}>
					<Col>
						<Row align={'middle'} gutter={12}>
							<Col>
								<Select
									key={assetsType}
									defaultValue={assetsType}
									style={{ width: 100 }}
									onChange={(value: string) => {
										setAssetsType(value);
										getBuildData(value);
									}}
									placeholder="请选择楼栋"
								>
									<Select.Option value={''}>全部</Select.Option>
									{/*<Select.Option value={'工位'}>工位</Select.Option>*/}
									{/*<Select.Option value={'大面积'}>大面积</Select.Option>*/}
									{/*<Select.Option value={'公寓'}>公寓</Select.Option>*/}
									{/*<Select.Option value={'办公室'}>办公室</Select.Option>*/}
									{/*<Select.Option value={'商业'}>商业</Select.Option>*/}
									{/*<Select.Option value={'热桌'}>热桌</Select.Option>*/}
									{assetsTypeList.map((item: any) => {
										return (
											<Select.Option key={item} value={item}>
												{item}
											</Select.Option>
										);
									})}
								</Select>
							</Col>
							<Col>
								<Select
									key={buildUuid}
									style={{ width: 200 }}
									defaultValue={buildUuid}
									onChange={(value: string) => {
										setBuildUuid(value);
										getData(value, assetsType);
									}}
									placeholder="请选择楼栋"
								>
									{buildData.map((build: any) => (
										<Select.Option key={build.uuid} value={build.uuid}>
											{build.buildName}
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row align={'middle'} gutter={12}>
							<Col>
								状态： 空置: <Tag color="#e2e2e2">空置</Tag> 已出租: <Tag color="#f50">已出租</Tag>
							</Col>
							<Col>
								{props.showType === 'update' && showType === 'plan' && (
									<AuthorizedView needAuthority={'household:build_plan:update'}>
										<Button
											type={'primary'}
											onClick={() => {
												type === 'update' ? setType('look') : setType('update');
											}}
										>
											{type === 'update' ? '退出编辑' : '编辑平面图'}
										</Button>
									</AuthorizedView>
								)}
							</Col>
							<Col>
								<Radio.Group
									defaultValue={showType}
									onChange={(e: any) => {
										setShowType(e.target.value);
									}}
								>
									<Radio.Button value="card">卡片</Radio.Button>
									<Radio.Button value="plan">平面图</Radio.Button>
								</Radio.Group>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row align={'middle'} style={{ padding: 15 }}>
					<Col span={24}>
						{isShow && data.length === 0 && <Result status="404" title="暂无数据" />}

						<Tabs defaultActiveKey="1" tabPosition={'left'} onChange={onChange}>
							{data.map((item: RoomStatusData, index: number) => {
								return (
									<Tabs.TabPane tab={`${item.layer} F`} key={index + ''}>
										{showType === 'card' ? (
											<List
												grid={{ gutter: 16, column: 6 }}
												dataSource={item.rooms}
												renderItem={(rooms: any) => (
													<List.Item>
														{(rooms.state === 1 && (
															<Popover content={content(rooms)} trigger="hover">
																<Card
																	headStyle={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																	}}
																	bodyStyle={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		paddingLeft: 24,
																	}}
																	title={
																		<Tooltip title={rooms.roomNumber}>
																			{rooms.roomNumber}
																		</Tooltip>
																	}
																	actions={renderActions(rooms)}
																	extra={getTab(rooms.state)}
																>
																	<Statistic
																		title="面积"
																		value={rooms.acreage}
																		precision={2}
																		valueStyle={{ color: '#333333' }}
																		suffix="㎡"
																	/>
																</Card>
															</Popover>
														)) || (
															<Card
																headStyle={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
																bodyStyle={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	paddingLeft: 24,
																}}
																title={
																	<Tooltip title={rooms.roomNumber}>
																		{rooms.roomNumber}
																	</Tooltip>
																}
																actions={renderActions(rooms)}
																extra={getTab(rooms.state)}
															>
																<Statistic
																	title="面积"
																	value={rooms.acreage}
																	precision={2}
																	valueStyle={{ color: '#333333' }}
																	suffix="㎡"
																/>
															</Card>
														)}
													</List.Item>
												)}
											/>
										) : (
											<LayerPlanComponent
												key={JSON.stringify(item) + type}
												data={item}
												type={type}
											/>
										)}
									</Tabs.TabPane>
								);
							})}
						</Tabs>
					</Col>
				</Row>
				<Modal
					title="添加租赁信息"
					visible={visible}
					footer={null}
					onCancel={() => {
						setVisible(false);
					}}
				>
					<AddLeaseInfoForm
						key={roomInfo.uuid}
						roomInfo={roomInfo}
						callback={() => {
							setVisible(false);
							getData(buildUuid, assetsType);
						}}
					/>
				</Modal>
			</Spin>
		</div>
	);
};
export default RoomStatusComponents;
