import { message, Modal, Select, Space, TreeSelect } from 'antd';
import { connect } from 'dva';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import GSTable from '../../../components/GSTable';
import ConnectState from '../../../models/connect';
import { ActionType } from '@ant-design/pro-table';
import { getLiuChenDanByUserUuid, getShiLiShenHe, removeLiuChengDanShenHeRen, updateLiuChengDanShenHeRen, updateShiliShenHeRen } from '../../../services/WuyeService';
import appConfig from '../../../appConfig';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CheckableTag from 'antd/lib/tag/CheckableTag';
interface Params {
    userUuid:string
 }
const LiuChengShenHeCommponents: React.FC<Params> = (props: any) => {
    const { userUuid} = props
    const [liuChengShenheUpdateVisible, setliuChengShenheUpdateVisible] = useState(false);
    const [empUuid, setEmpUuid] = useState('');
    const [liuChengItem, setliuChengItem] = useState({});
    const { common } = props;
	const { empUserList } = common;
	const [loading, setloading] = useState(false)
	const liuchengActionRef = useRef<ActionType>();
	const [isLiuCheng, setisLiuCheng] = useState(true)
	const liuchengDanColumns = [
		{
			title: '流程单名称',
			width: 200,
			dataIndex: 'liu_cheng_dan_name',
			key: 'liu_cheng_dan_name',
			render: (text: any, record: any) => (
				<Link
					to={{
						pathname:
							appConfig.webCompanyUrl +
							`/page/liucheng/updata_liucheng-dan?liuchengUuid=${
								record.liu_cheng_dan_uuid
							}&look=${false}`,
					}}
					key={record.liu_cheng_dan_uuid}
					target={'_blank'}
				>
					<a>{record.liu_cheng_dan_name}</a>
				</Link>
			),
		},
		{
			title: '节点名称',
			dataIndex: 'jie_dian_name',
			key: 'jie_dian_name',
			width: 100,
		},
		{
			title: '操作',
			dataIndex: 'account',
			key: 'account',
			width: 80,
			fixed: 'right',
			render: (text: any, record: any) => {
				return (
					<Space>
						<a hidden={!isLiuCheng} onClick={() => deleteLiuChengShenHeItem(record)}>删除</a>
						<a
							onClick={() => {
								setliuChengShenheUpdateVisible(true);
								setliuChengItem(record);
								setEmpUuid('');
							}}
						>
							转移
						</a>
					</Space>
				);
			},
		},
    ];
    const deleteLiuChengShenHeItem = (record: any) => {
        Modal.confirm({
			title: '您确定要删除吗？',
			icon: <ExclamationCircleOutlined />,
			okType: 'danger',
			onOk: async () => {
				const res = await removeLiuChengDanShenHeRen({
					userUuid: userUuid,
					liuChengUuid: record.liu_cheng_dan_uuid,
					jieDianUuid: record.jie_dian_uuid,
				});
				if (res.err === 0) {
					message.success('删除成功');
					if (liuchengActionRef.current) {
						liuchengActionRef.current.reload();
					}
				} else {
					message.error(res.msg);
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
		console.log('record::', record);
	};
    const saveUpLiuChengShenHe =  async() => {
        if (empUuid == undefined || empUuid == "") {
            message.warning("请选择转移到的人员")
            return
		}
		if (isLiuCheng) {
			setloading(true)
			const res = await updateLiuChengDanShenHeRen({ empUuid: empUuid, userUuid: userUuid, liuChengUuid: liuChengItem.liu_cheng_dan_uuid, jieDianUuid: liuChengItem.jie_dian_uuid })
			setloading(false)
			if (res.err == 0) {
				message.success("转移成功")
				setliuChengShenheUpdateVisible(false)
				if (liuchengActionRef.current) {
					liuchengActionRef.current.reload()
				}
			} else {
				message.error(res.msg)
			}
		} else {
			setloading(true)
			const res = await updateShiliShenHeRen({ empUuid: empUuid, userUuid: userUuid, liuChengUuid: liuChengItem.liu_cheng_dan_uuid, jieDianUuid: liuChengItem.jie_dian_uuid })
			setloading(false)
			if (res.err == 0) {
				message.success("转移成功")
				setliuChengShenheUpdateVisible(false)
				if (liuchengActionRef.current) {
					liuchengActionRef.current.reload()
				}
			} else {
				message.error(res.msg)
			}
		}


	}
	const tableSearchBarRender = () => {
		return [
			<CheckableTag

				checked={isLiuCheng}
				onChange={() => {
					setisLiuCheng(true);
					if (liuchengActionRef.current) {
						liuchengActionRef.current.reloadAndRest();
					}
				}}
			>
				流程单
			</CheckableTag>,
			<CheckableTag
				checked={!isLiuCheng}
				onChange={() => {
					setisLiuCheng(false)
					if (liuchengActionRef.current) {
						liuchengActionRef.current.reloadAndRest();
					}
				}}
			>
				实例
			</CheckableTag>,


		];
	};
	return (
		<>


			<GSTable
				searchBarRender={tableSearchBarRender()}
				columns={liuchengDanColumns}
				pagination={false}
				rowKey={'jie_dian_uuid'}
				scroll={{
					x: 800
				}}
				actionRef={liuchengActionRef}
				request={async (params: any = {}) => {
					if (isLiuCheng) {
						const res = await getLiuChenDanByUserUuid({
							userUuid: props.userUuid,
						});
						return {
							data: res.data,
							success: true,
						};
					} else {
						const res = await getShiLiShenHe({
							userUuid: props.userUuid,
						});
						return {
							data: res.data,
							success: true,
						};
					}

                }}

            />
			<Modal
				confirmLoading={loading}
				title="流程转移"
				visible={liuChengShenheUpdateVisible}
				onCancel={() => setliuChengShenheUpdateVisible(false)}
				onOk={saveUpLiuChengShenHe}
			>
                <TreeSelect
                    showSearch
					key={empUserList}
					treeData={empUserList}
					value={empUuid == "" ?undefined:empUuid}
					onChange={(value)=>setEmpUuid(value)}
					// treeCheckable={true}
					// multiple={true}
					treeNodeFilterProp="title"
					searchPlaceholder="请选择"
					dropdownStyle={{ overflow: 'auto' }}
					style={{ width: 300 }}
				/>
			</Modal>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(LiuChengShenHeCommponents);
