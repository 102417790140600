import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Image, Input, message, Modal, Space, Spin, Table } from 'antd';
import DAl from '../../../data/DAL';
import API from '../../../data/API';
import * as XLSX from 'xlsx';
import appConfig from '../../../appConfig';
import { findXunGengRecordList, findXunJianReportForm, getXunGengInfo, likeFindReport } from '../../../services/patrolService';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import CustomRangePicker from '../../../components/CustomRangePicker';
import moment from 'moment';
let { RangePicker } = DatePicker;
var msg = require('../../../util/SuspensionMsg');
const { Column, ColumnGroup } = Table;
const XunGengReport: React.FC = () => {
	const [startTime, setStartTime] = useState(moment().subtract(7, 'd').hours(0).minute(0).second(0));
	const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
	const [search, setsearch] = useState('');
	const [selectType, setSelectType] = useState(3);
	const actionRef1 = useRef<ActionType>();
	const actionRef = useRef<ActionType>();
	const [loading, setloading] = useState(false);
	const [quanXuan, setquanXuan] = useState(false);
	const [dataTotal, setdataTotal] = useState(0)
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [infos, setInfos] = useState([]);
	const [infoVisible, setInfoVisible] = useState(false);
	const infoColumns = [
		{
			title: '巡更位置',
			dataIndex: 'position',
			key: 'position',
		},
		{
			title: '巡更照片',
			dataIndex: 'checkImg',
			key: 'checkImg',
			render: (text: any, record: any) => (
				<>
					{record.checkImg ? (
						<Image className="homePage" src={appConfig.cdnRootPath + text} width={100} height={70} />
					) : (
						'-'
					)}
				</>
			),
		},
		{
			title: '巡更时间',
			dataIndex: 'positionTime',
			key: 'positionTime',
		},
		{
			title: '巡更人员照片',
			dataIndex: 'employeeFase',
			key: 'employeeFase',
			render: (text: any, posi: any) => (
				<>
					{posi.employeeFase === '' || posi.employeeFase === null || posi.employeeFase === undefined ? (
						''
					) : (
						<div>
							{posi.employeeFase === '未上传' ? (
								<p>未上传</p>
							) : (
								<Image
									className="homePage"
									src={
										API.apiRootPath +
										'/api/file/get_file_qiniu/' +
										posi.employeeFase +
										'/' +
										appConfig.wuYeUuid
									}
									width={100}
									height={70}
								/>
							)}
							<p>{posi.faseTime}</p>
						</div>
					)}
				</>
			),
		},
	];
	const columns = [
		{
			title: '巡更路线',
			dataIndex: 'routeName',
			key: 'routeName',
		},
		{
			title: '巡更人员',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '日期',
			dataIndex: 'scheduleDate',
			valueType: 'date',
			key: 'scheduleDate',
		},
		{
			title: '班次',
			dataIndex: 'shiftName',
			key: 'shiftName',
		},
		{
			title: '开始时间',
			dataIndex: 'startTime',
			key: 'startTime',
			type:'time',
			render: (_, item: any) => {
				return <>{item.startTime != undefined && moment(item.startTime).format('HH:mm')}</>;
			},
		},
		{
			title: '结束时间',
			dataIndex: 'endTime',
			key: 'endTime',
			type:'time',
			render: (_, item: any) => {
				return <>{item.endTime != undefined && moment(item.endTime).format('HH:mm')}</>;
			},
		},
		{
			title: '巡更状态',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							openInfoModal(record);
						}}
					>
						详情
					</a>
				</span>
			),
		},
	];
	const openInfoModal = async (value: any) => {
		const res = await getXunGengInfo({id:value.id})
		if (res.err !== 0) { 
				message.error(res.msg)
			return
		}
		setInfos(res.data || []);
		setInfoVisible(true);
		if (actionRef1.current) {
			actionRef1.current.reload();
		}
	};
	const getInfo = () => {
		return {
			data: infos,
		};
	};
	const tableSearchBarRender = () => {
		return [
			<Space>
				<CustomRangePicker
					onChange={(startTime: any, endTime: any, type?: number) => {
						setStartTime(startTime);
						setEndTime(endTime);
						setSelectType(type || 1);
					}}
					selectType={selectType}
					startTime={startTime}
					endTime={endTime}
				/>
				<Input
					placeholder='路线名称'
					onChange={(e) => {
						setsearch(e.target.value);
					}}
				/>
				<Button
					type={'primary'}
					onClick={() => {
						actionRef.current.reloadAndRest();
					}}
				>
					查询
				</Button>
			</Space>,
		];
	};

	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setloading(true);
		if (quanXuan) {
			const res = await findXunGengRecordList({
				startTime: startTime,
				endTime: endTime,
				search: search,
			});
			setloading(false);
			if (res.err == 0) {
				setExprotData(res.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setloading(false);
			setExprotData(selectedRows);
		}
	};
	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log("selectedRows:::",selectedRows);
		
		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item:any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				}
				 else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				}
				else if (item.valueType === 'date') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD'));
				 }
				 else if (item.type === 'time') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('HH:mm'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '巡更报表');
		/* save to file */
		XLSX.writeFile(wb, '巡更报表.xlsx');
	};
	return (
		<>
			<Spin spinning={loading}>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				rowSelection={{
					fixed: true,
					columnWidth: 100,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setquanXuan(false);
						setselectedRowKeys(selectedRowKeys);
					},
					selections: [
						// {
						// 	key: 'SELECT_CURRENT_ALL',
						// 	text: '全选当前页面',
						// 	onSelect: (changableRowKeys: any) => {
						// 		setselectedRowKeys(selectedRowKeys.concat(changableRowKeys));
						// 	},
						// },
						{
							key: 'SELECT_ALL',
							text: '全选全部页面',
							onSelect: (changableRowKeys: any) => {
								setquanXuan(true);
								setselectedRowKeys(changableRowKeys);
							},
						},
					],
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanXuan ? dataTotal : selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::',selectedRowKeys,onCleanSelected, selectedRows);
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>
						</Space>
					);
				}}
				rowKey={'id'}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					

					const res = await findXunGengRecordList({
						page: params.current,
						size: params.pageSize,
						startTime: startTime,
						endTime: endTime,
						search: search,
					});
					if (res.err !== 0) { 
						message.error(res.msg)
						return
					}
					setdataTotal(res.total || 0)
					return {
						data: res.data || [],
						total: res.total || 0,
						success: true,
					};
				}}
			/>
			<Modal
				title={'详情'}
				visible={infoVisible}
				onCancel={() => {
					setInfoVisible(false);
				}}
				footer={null}
			>
				<GSTable
					columns={infoColumns}
					actionRef={actionRef1}
					request={async (params: any = {}, sort: any, filter: any) => {
						return getInfo();
					}}
				/>
				</Modal>
			</Spin>
				
		</>
	);
};
export default XunGengReport;
