import Api from '../data/API';

export async function getSystemLogs(params: any) {
    return Api.postWithAuth('/adminapi/syslog/get_syslog_list', params);
}
export async function getApplicationLogList(params: any) {
    return Api.postWithAuth('/adminapi/syslog/getApplicationLogList', params);
}
export async function getPermItemProjList(params: any) {
    return Api.postWithAuth('/adminapi/syslog/getPermItemProjList', params);
}