import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import DemandManage from './pages/DemandManage';
import LiuchengDemand from './components/LiuchengDemand';

export default function OperationManagementMiniApp() {
	const rootUrl = '/operationmanagement';
	const menus: MiniAppMenu[] = [
		{
			authority: 'operation_management',
			name: '需求服务',
			path: 'demand',
			component: DemandManage,
		},
	];
	const routes: MiniAppRoute[] = [
		{
			path: 'liucheng-demand',
			component: LiuchengDemand,
		},
	];

	return <MiniApp miniAppName={'产业赋能'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
