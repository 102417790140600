import Api from '../data/API';

export async function getFeeStandardList(params: any) {
	return Api.postWithAuth('/admin/api/fee/standard/list', params);
}
export async function updateFeeStandard(params: any) {
	return Api.postWithAuth('/admin/api/fee/standard/update', params);
}

export async function deleteFeeStandard(params: any) {
	return Api.postWithAuth('/admin/api/fee/standard/delete', params);
}
export async function addFeeStandard(params: any) {
	return Api.postWithAuth('/admin/api/fee/standard/add', params);
}

