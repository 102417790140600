import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import DAL from '../../data/DAL';
import './JianKong.css';
import { Card, message } from "antd";
import Slider from "react-slick";
import moment from "moment"
import {getAllFaceDevice} from "../../services/FaceService"

const leftBg = require('../../img/tongxing-jiankong/2.png');
const rightBg = require('../../img/tongxing-jiankong/3.png');
const JianKong: React.FC = (props: any) => {
	const [slider,setSlider] = useState("")
	const { Meta } = Card;
	const [client, setClient] = useState<any>(null);
	const projUuid = DAL.dal.getQueryString('projUuid') || '23ca0c74-e2a6-4341-833c-a8ae9f796a7f';
	const [datas,setDatas] = useState(new Set());
	const [tongxingData,setTongxingData] = useState([]);
	useEffect(() => {
		if (client) {
			client.on('connect', () => {
				console.log('连接上了');
				getAllDevice().then();

			});
			client.on('error', (err: any) => {
				console.error('Connection error: ', err);
				client.end();
			});
			client.on('reconnect', () => {
				console.log('重新连接');
			});
			client.on('message', (topic: any, message: any) => {
				try {

					let infos =new Set()
					infos = datas
					console.log("infos:::",infos)
					let item = JSON.parse(message.toString());
					const info:any ={
						// deviceName:'一号摆闸门',
						// departmentName:'测试'
						// ic:'11111',
						createTime:moment().format("YYYY-MM-DD HH:mm:ss"),
						detectFaceImg:item.detect_face_img,
						userName:item.user_name,
						status:item.status
					}
					const items: any = infos.add(info)
					console.log('datas::::', items);
					setDatas(items);
					setTongxingData(Array.from(items))
					console.log("tongxingData:::",tongxingData)
					if(items.size>4){
						console.log("slider:::",slider)
						if(slider){
							slider.slickNext();
						}
					}
				} catch (e) {}
			});
		} else {
			connect();
		}
	}, [client]);
	const connect = () => {
		const options: any = {
			keepalive: 30,
			protocolId: 'MQTT',
			protocolVersion: 4,
			clean: true,
			reconnectPeriod: 1000,
			connectTimeout: 30 * 1000,
			will: {
				topic: 'WillMsg',
				payload: 'Connection Closed abnormally..!',
				qos: 0,
				retain: false,
			},
			rejectUnauthorized: false,
			clientId: new Date().getTime(),
			username: 'admin',
			password: 'xA5iUF2N',
		};
		setClient(mqtt.connect('mqtt://api.guosim.com:15675/ws', options));
	};

	const mqttSub = (subscription: any) => {
		if (client) {
			const { topic, qos } = subscription;
			client.subscribe(topic, { qos }, (error: any) => {
				if (error) {
					console.log('Subscribe to topics error', error);
					return;
				}
				// setIsSub(true)
			});
		}
	};
	const getAllDevice = async () => {
		let data:any = {
			proj_uuid : projUuid
		}
		let rsp = await getAllFaceDevice(data);
		if (rsp.err === 0) {
			let data = rsp.data;
			console.log('data::::', data);
			data.map((item: any) => {
				mqttSub({ topic: `/${item.device_id}/user/record`, qos: 0 });
			})
		} else {
			message.error(rsp.msg)
		}
	}
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
	};
	return (
		<>
			{/*<div className={'img-bg bg'}>*/}
			{/*	<div className={'top-bg'} style={{ height: '15%' }}>*/}
			{/*		<div className={'top-title'}>LOGO身份识别系统</div>*/}
			{/*		<div className={'top-date'}>2020-11-17 星期二 08:12</div>*/}
			{/*	</div>*/}
			{/*	<div className={'d-flex justify-content-between'} style={{ height: '80%', padding: '0px 200px' }}>*/}
			{/*		<div style={{ position: 'relative' }}>*/}
			{/*			<img src={leftBg} style={{ height: '100%' }} alt="" />*/}
			{/*			<div className={'left-title'}>*/}
			{/*				<div className={'d-flex justify-content-center'}>欢迎您进入10000</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div style={{ position: 'relative' }}>*/}
			{/*			<img src={rightBg} style={{ height: '100%' }} alt="" />*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
<div style={{height:'100%',width:'100%',display: "tableCell",verticalAlign:"middle"}}>
			<Slider style={{height:800,width:"100%"}}  ref={c => (setSlider(c))} {...settings}>
				{datas.size>0?
					Array.from(datas).map((item:any)=>{
						return(
								<Card
									hoverable
									cover={<img alt="example" src={`data:image/jpeg;base64,${item.detectFaceImg}`} />}
								>
									<div style={{display:'flex'}}>
										<div>姓名：</div>
										<div>{item.userName}</div>
									</div>
									<div style={{display:'flex'}}>
										<div>通行时间：</div>
										<div>{item.createTime}</div>
									</div>
									<div style={{display:'flex'}}>
										<div>状态：</div>
										<div>{item.status}</div>
									</div>
								</Card>
							)
					})
				:''}
			</Slider>
</div>


			{/*<div*/}
			{/*	style={{*/}
			{/*		width: '100vw',*/}
			{/*		height: '100vh',*/}
			{/*		backgroundColor: '#fff',*/}
			{/*		display: 'flex',*/}
			{/*		justifyContent: 'center',*/}
			{/*		alignItems: 'center',*/}
			{/*	}}*/}
			{/*>*/}

			{/*	{data && (*/}
			{/*		<Card*/}
			{/*			hoverable*/}
			{/*			style={{ width: 240 }}*/}
			{/*			cover={<img alt="example" src={`data:image/jpeg;base64,${data.detect_face_img}`} />}*/}
			{/*		>*/}
			{/*			<Meta title="状态" description={data.status} />*/}
			{/*		</Card>*/}
			{/*	)}*/}
			{/*</div>*/}

			{/*<div>*/}
			{/*	<h2>Center Mode</h2>*/}
			{/*	<Slider {...settings}>*/}
			{/*		<div>*/}
			{/*			<h3>1</h3>*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h3>2</h3>*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h3>3</h3>*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h3>4</h3>*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h3>5</h3>*/}
			{/*		</div>*/}
			{/*		<div>*/}
			{/*			<h3>6</h3>*/}
			{/*		</div>*/}
			{/*	</Slider>*/}
			{/*</div>*/}

		</>
	);
};
export default JianKong;
