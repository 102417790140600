import React, { useRef, useState, useEffect } from 'react';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { getAllDetectRecord } from '../../../services/FaceService';
import { Col, Modal, Row, Image, Select, Input, Button, Tag, TreeSelect, message } from 'antd';
import CustomRangePicker from '../../../components/CustomRangePicker';
import moment from 'moment';
import { getDevices, getDevicesByAll, getAllDevicesGroup } from '../../../services/ZhiNengMenJinService';
import GsTreeSelect from '../../../components/GsTreeSelect';

const DetectRecord: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [visible, setVisible] = useState(false);
	const [detectImg, setDetectImg] = useState('');
	const [faceImg, setFaceImg] = useState('');
	const [deviceId, setDeviceId] = useState('');
	const [allDeviceId, setAllDeviceId] = useState('');
	const [faceDevice, setFaceDevice] = useState<any[]>([]);
	const [deviceEnum, setDeviceEnum] = useState<any>({});
	const [status, setStatus] = useState('SUCCESS');
	const [Stime, setStime] = useState(
		moment().subtract(7, 'd').hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:00')
	);
	const [Etime, setEtime] = useState(moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:59'));
	const [search, setSearch] = useState('');
	const [devices, setDevices] = useState([]);

	const statusEnum: any = {
		'SUCCESS': <Tag color={'green'}>开闸</Tag>,
		'ERROR': <Tag color={'red'}>不开闸</Tag>
	};
	const jianKangMaEnum: any = {
		'true': <Tag color={'green'}>通过</Tag>,
		'false': <Tag color={'red'}>不通过</Tag>
	};

	useEffect(() => {
		getSelectDeviceList().then();
	}, []);
	const columns = [{
		title: '设备ID',
		width: 150,
		dataIndex: 'deviceId',
		key: 'deviceId',
		valueEnum: deviceEnum
	}, {
		title: '分数',
		dataIndex: 'score',
		key: 'score',
		width: 100,
		valueType: 'digit',
		hideInSearch: true

	}, {
		title: '结果',
		dataIndex: 'status',
		key: 'status',
		width: 150,
		valueEnum: statusEnum
	}, {
		title: '描述',
		width: 150,
		dataIndex: 'msg',
		key: 'msg'
	}, {
		title: '健康码结果',
		width: 150,
		hideInTable: true,
		dataIndex: 'jianKangMaStatus',
		key: 'jianKangMaStatus',
		valueEnum: jianKangMaEnum
	},
		{
			title: '核酸',
			hideInTable: true,
			dataIndex: 'acidNum',
			key: 'acidNum'
		}, {
			title: '健康码描述',
			hideInTable: true,
			dataIndex: 'jianKangMaMsg',
			key: 'jianKangMaMsg'
		}, {
			title: '姓名',
			width: 100,
			dataIndex: 'userName',
			key: 'userName',
			hideInSearch: true
		}, {
			title: '抓拍照片',
			dataIndex: 'detectFaceImg',
			width: 150,
			key: 'detectFaceImg',
			hideInSearch: true,
			render: (text: string, record: any) => {
				return <img onClick={() => showModal(record)} src={text}
							style={{ width: 150, height: 150 }}></img>;
			}
		}, {
			title: '底库照片',
			width: 150,
			dataIndex: 'faceImg',
			key: 'faceImg',
			hideInSearch: true,
			render: (text: string, record: any) => {
				return <img onClick={() => showModal(record)} src={text}
							style={{ width: 150, height: 150 }}></img>;
			}
		}, {
			title: '用户信息',
			dataIndex: 'userInfo',
			key: 'userInfo',
			width: 200,
			hideInSearch: true
		}, {
			title: '识别时间',
			dataIndex: 'createdTime',
			key: 'createdTime',
			fixed: 'right',
			valueType: 'dateTime',
			width: 200,
			hideInSearch: true
		}];
	const loadData = async (param: any) => {
		console.log('param:', param);
		let data = {
			deviceId: deviceId.toString() || allDeviceId,
			status: status,
			startTime: Stime,
			endTime: Etime,
			page: param.current,
			size: param.pageSize,
			search: search,
			projUuid: localStorage.getItem('wyUuid')
		};
		const res = await getAllDetectRecord(data);
		if (res.err === 0) {
			return {
				data: res.data,
				total: res.count
			};
		} else {
			return {
				data: []
			};
		}
	};
	const showModal = (record: any) => {
		setDetectImg(record.detectFaceImg);
		setFaceImg(record.faceImg);
		setVisible(true);
	};
	const dateTime = (startTime: any, endTime: any) => {
		console.log('startTime:', startTime, 'endTime::', endTime);
		setStime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEtime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};
	const tableSearchBarRender = () => [
		<GsTreeSelect
			treeData={devices}
			multiple={true}
			defaultValue={deviceId}
			onChange={(ev: any) => {
				let data: any = [];
				ev.map((item: string) => {
					if (item.includes(':::')) {
						data.push(item.split(':::')[1]);
					} else {
						data.push(item);
					}
				});
				setDeviceId(data.toString());
			}}
		/>,
		<Select defaultValue={status} style={{ width: 200 }} onChange={(e: string) => {
			setStatus(e);
		}}>
			<Select.Option value={''}>全部</Select.Option>
			<Select.Option value={'SUCCESS'}>通过</Select.Option>
			<Select.Option value={'ERROR'}>不通过</Select.Option>
		</Select>,
		<CustomRangePicker onChange={dateTime}/>,
		<Input placeholder={'姓名'} onChange={(e) => {
			setSearch(e.target.value);
		}}/>,
		<Button type={'primary'} onClick={() => {
			actionRef.current.reloadAndRest();
		}}>查询</Button>
	];
	const getDeviceList = async () => {
		let params = {
			typecode: '0701',
			wuyeid: localStorage.getItem('wyUuid')
		};
		let rsp = await getDevicesByAll(params);
		console.log('rsp::::', rsp);
		if (rsp.err === 0) {
			let deviceId: string[] = [];
			let valueEnum: any = {};
			rsp.data.map((item: any) => {
				if (item.typecode === '0701') {
					deviceId.push(item.deviceid);
					valueEnum[item.deviceid] = item.description;
				}
			});
			setDeviceEnum(valueEnum);
			setAllDeviceId(deviceId.toString());
			setFaceDevice(rsp.data);
		}
	};

	const getSelectDeviceList = async () => {
		const res = await getDevices({ typeCode: '0701' });
		if (res.err !== 0) {
			message.error(res.msg);
			return;
		}
		let data: any = [];
		data.push({ key: '全部设备', title: '全部设备', value: '' });
		res.data.map((item: any) => {
			item['selectable'] = false;
		});
		data = data.concat(res.data);
		setDevices(data);
		let valueEnum: any = {};
		let deviceId: string[] = [];
		res.data.map((item: any) => {
			if (item.children) {
				item.children.map((clidrenItem: any) => {
					if (clidrenItem.value.includes(':::')) {
						valueEnum[clidrenItem.value.split(':::')[1]] = clidrenItem.title;
						deviceId.push(clidrenItem.value.split(':::')[1]);
					} else {
						valueEnum[clidrenItem.value] = clidrenItem.title;
						deviceId.push(clidrenItem.value);
					}


				});
			}
		});
		setDeviceEnum(valueEnum);
		setAllDeviceId(deviceId.toString());
	};
	return (
		<>
			<GSTable
				search={false}
				searchBarRender={tableSearchBarRender()}
				columns={columns}
				actionRef={actionRef}
				request={loadData}
			/>
			<Modal width={1000} title={'照片对比'} footer={null} visible={visible} onCancel={() => {
				setVisible(false);
			}} onOk={() => {
				setVisible(false);
			}}>
				<Row gutter={16}>
					<Col span={12}>
						<img src={detectImg} width={480}></img>
					</Col>
					<Col span={12}>
						<img src={faceImg} width={480}></img>

					</Col>
				</Row>
			</Modal>
		</>
	);
};
export default DetectRecord;
