import React, {useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import moment from "moment/moment";
import {Button, Image, Input, message, Modal, Select, Space} from "antd";
import {getList} from "../../../services/Camera";
import {ActionType} from "@ant-design/pro-table";
import Video from "../../../components/video";

const Index = () => {
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState("");
    const [item, setItem] = useState<any>({});
    const [visible, setVisible] = useState(false)
    const columns = [
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'IP',
            key: 'ip',
            dataIndex: 'ip'
        },
        {
            title: '操作',
            key: 'active',
            dataIndex: 'active',
            render: (_, record) => {
                return <Space>
                    <a onClick={() => {
                        setItem(record);
                        setVisible(true)
                    }}>查看</a>
                </Space>
            }
        }
    ]
    const tableSearchBarRender = () => [
        <Space>
            <Input
                placeholder={"名称"}
                onChange={(e: any) => {
                    setSearch(e.target.value);
                }}
            />
            <Button
                type={"primary"}
                onClick={() => {
                    actionRef.current.reloadAndRest();
                }}
            >
                查询
            </Button>
        </Space>
    ];
    const loadList = async (params: any) => {
        let rsp = await getList(params);
        if (rsp.err == 0) {
            return {
                data: rsp.data,
                total: rsp.total
            }
        }
        return {
            data: [],
            total: 0
        }
    }
    return (<>
            <GSTable
                actionRef={actionRef}
                searchBarRender={tableSearchBarRender()}
                columns={columns}
                request={async (params: any) => {
                    let data = {
                        page: params.current,
                        size: params.pageSize,
                        search: search
                    };
                    return loadList(data);
                }}
            >

            </GSTable>
            <Modal title={'视频'} width={800} footer={null} visible={visible} onCancel={() => {
                setVisible(false)
            }}>
               <Video ip={item.ip}/>
            </Modal>
        </>

    );
}
export default Index;
