import {useEffect, useState} from "react";
import MiniApp, {MiniAppMenu, MiniAppRoute} from "../../util/MiniApp";
import * as React from "react";
import DoorList from "./pages/DoorList";
import DoorRecordList from "./pages/DoorRecordList";

export default function DoorMiniApp() {
  useEffect(() => {
    let curMenu = window.location.pathname.split("/")[2];
    console.log("curMenu::", curMenu);
  }, []);
  const [curMenu, setCurMent] = useState("");
  const rootUrl = "/door-manager";
  const menus: MiniAppMenu[] = [
    {
      name: "门锁列表",
      path: "list",
      authority: "door_manager:door:list",
      component: DoorList
    },  {
      name: "门锁记录",
      path: "door-record",
      authority: "door_manager:door_record:list",
      component: DoorRecordList
    },
  ];

  const routes: MiniAppRoute[] = [
  ];
  return (
    <>
      <MiniApp
        key={window.location.href}
        miniAppName={"门锁状态"}
        rootUrl={rootUrl}
        menus={menus}
        routes={routes}
      />
    </>
  );
}
