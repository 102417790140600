import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import OrderDetail from './OrderDetail';
import { Tabs } from 'antd';
import { findMeetingPrice, orderDetail, queryMeetingTime } from '../../../services/meetingService';
const { TabPane } = Tabs;
let msg = require('../../../util/SuspensionMsg');

class OrderTime extends React.Component {
	dal = DAL.dal;

	constructor(props: any) {
		super(props);
		this.state = {
			url: window.location.pathname,
			uuid: '',
			times: [],
			imgs: ['loading'],
			day: {},
			order: {},
			days: [],
			hours: [],
			chosenDayIndex: 0,
			orderedHours: [],
		};
		this.getUuid = this.getUuid.bind(this);
		this.getDay = this.getDay.bind(this);
		this.getOrderUuid = this.getOrderUuid.bind(this);
	}

	componentDidMount() {
		this.initDays();
		this.queryMeetingPrice();
		//time.getDay(this.getDay);
		//         //time.getOrderUuid(this.getOrderUuid);
		//time.today();
	}

	initDays = () => {
		let date = new Date();
		let days = new Array();
		for (let i = 0; i < 11; i++) {
			let chosen = '';
			let m = date.getMonth() + 1;
			let M = m.toString();
			if (m < 10) {
				M = '0' + m.toString();
			}
			let d = date.getDate();
			let D = d.toString();
			if (d < 10) {
				D = '0' + d;
			}
			let mark = date.getFullYear() + '' + M + '' + D;
			let text = date.getMonth() + 1 + '月' + date.getDate() + '日';
			let nextDate = new Date(date.getTime() + 24 * 60 * 60 * 1000); //后一天
			let str = '周' + '日一二三四五六'.charAt(date.getDay());
			date = nextDate;
			let a = text.replace('月', '');
			a = a.replace('日', '');
			let day = {
				mark: mark,
				text: text,
				str: str,
			};
			days.push(day);
			/*j("#day-chose").append("<div class='scrollbar-hover scroll-days' id='" +
                mark+"'><p >" +
                text + "</p><p>" +
                str + "</p></div>");*/
		}
		console.log('生成日期' + days);
		this.setState({
			days: days,
		});
	};

	queryMeetingPrice = async () => {
		const res = await findMeetingPrice({
			uuid: this.getUuid(),
		});
		let url = '/api/meeting/find_meeting_price';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid(),
		// }).then(res => {
		console.log('会议室价格', res);
		if (res.err === 0) {
			this.setState({
				price: res.data,
			});
			this.init(Number.parseInt(res.startTime, 10), Number.parseInt(res.endTime, 10));
		} else {
			msg.suspensionMsg(res.msg);
		}
		// }).catch(err => {
		//     this.setState({
		//         isDisabled: false
		//     });
		// });
	};

	init(startTime: number, endTime: number) {
		let a = new Array();
		let b = new Array();
		for (let i = startTime; i < endTime; i++) {
			let str = '';
			if (i < 10) {
				str = '0' + i + ':00-0' + i + ':30&0' + i + ':30-0' + (i + 1) + ':00';
				if (i === 9) {
					str = '0' + i + ':00-0' + i + ':30&0' + i + ':30-' + (i + 1) + ':00';
				}
			} else {
				str = i + ':00-' + i + ':30&' + i + ':30-' + (i + 1) + ':00';
			}
			a.push(str);
		}

		for (let i = 0; i < a.length; i++) {
			let c = a[i].split('&');
			b.push(c[0]);
			b.push(c[1]);
		}

		if ((endTime - startTime) % 2 !== 0) {
			b.push('');
			b.push('');
		}
		this.setState({
			hours: b,
		});
	}

	getOrderHours = (times: any) => {
		let orderedHours = new Array();
		for (let i = 0; i < times.length; i++) {
			orderedHours.push(times[i].time);
		}
		return orderedHours;
	};

	getData = async (ev: any) => {
		console.log(ev.target.id);
		let c = ev.target.id.split(',');
		this.setState({
			chosenDayIndex: Number.parseInt(c[1], 10),
		});
		const res = await queryMeetingTime({
			uuid: this.getUuid(),
			day: c[0],
		});
		// let url = '/api/meeting/query_meeting_time';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid(),
		//     day: c[0]
		// }).then(res => {
		if (res.err === 0) {
			let orderedHours = this.getOrderHours(res.data);

			this.setState({
				times: res.data,
				orderedHours: orderedHours,
			});
		} else {
			msg.suspensionMsg(res.msg);
			return;
		}
		/*for (let i = 0 ; i < (this.state as any).times.length ; i++) {
                time.change((this.state as any).times[i]);
            }*/
		// }).catch(err => {
		//     this.setState({
		//         isDisabled: false
		//     });
		// });
	};

	getUuid() {
		let page = window.location.search;
		let c = page.split('=');
		let uuid = c[1];
		return uuid;
	}

	getOrderUuid = async (orderUuid: string) => {
		const res = await orderDetail({
			uuid: orderUuid,
		})
			.then((res) => {
				if (res.err === 0) {
					this.setState({
						order: res.data,
					});
				} else {
					msg.suspensionMsg(res.msg);
				}
			})
			.catch((err) => {
				this.setState({
					isDisabled: false,
				});
			});
		// let url = '/api/meeting/order_detail';
		//
		// API.postWithAuth(url , {
		//     uuid: orderUuid
		// }).then(res => {

		// }).catch(err => {
		//     this.setState({
		//         isDisabled: false
		//     });
		// });
	};

	//回调回来的day,传到getData去做网络请求
	getDay(day: any) {
		this.setState({
			day: day,
		});
		this.getData(day);
	}

	getOrderDetail = (ev: any) => {
		let c = (this.state as any).orderedHours.indexOf(ev.target.id);
		if (c > -1) {
			this.getOrderUuid((this.state as any).times[c].orderUuid);
		}
	};

	render() {
		return (
			<div className="time-big" style={{ marginTop: '15px' }}>
				<div className="day-chose " id="day-chose">
					<Tabs defaultActiveKey="0">
						{(this.state as any).days.map((d: any, index: number) => (
							// <div className={(this.state as any).chosenDayIndex === index ? 'scrollbar-hover scroll-days chosen-date' : 'scrollbar-hover scroll-days'} id={d.mark + ',' + index} onClick={this.getData} >
							//     <p id={d.mark + ',' + index}>{d.text}</p>
							//     <p id={d.mark + ',' + index}>{d.str}</p>
							// </div>
							<TabPane
								tab={
									<div
										className={
											(this.state as any).chosenDayIndex === index
												? 'scrollbar-hover scroll-days chosen-date'
												: 'scrollbar-hover scroll-days'
										}
										id={d.mark + ',' + index}
										onClick={this.getData}
									>
										<p id={d.mark + ',' + index}>{d.text}</p>
										<p id={d.mark + ',' + index}>{d.str}</p>
									</div>
								}
								key={index}
							></TabPane>
						))}
					</Tabs>
				</div>

				<div className="container">
					<div className="hours-big" style={{ margin: '35px auto' }}>
						<ul id="hours" className="hours">
							{(this.state as any).hours.map((h: string) => (
								<li id={h} onClick={this.getOrderDetail} className={'orderTime-date-li'}>
									<div
										id={h}
										className={
											(this.state as any).orderedHours.indexOf(h) > -1 ? 'chosen-day' : 'hours'
										}
										data-toggle={(this.state as any).orderedHours.indexOf(h) > -1 ? 'modal' : ''}
										title={(this.state as any).orderedHours.indexOf(h) > -1 ? '点击查看详情' : ''}
										data-target={
											(this.state as any).orderedHours.indexOf(h) > -1 ? '#orderDetail' : ''
										}
									>
										{h}
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>

				<div className="modal fade" id="orderDetail" hidden={true}>
					<div className="modal-dialog">
						<div className="modal-content">
							<OrderDetail order={(this.state as any).order} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderTime;
