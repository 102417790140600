import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, message, Modal, Select, Tooltip } from "antd";
import * as moment from 'moment';
import { ActionType } from '@ant-design/pro-table';
import API from '../../../data/API';
import GSTable from '../../../components/GSTable';
import { getBanGongDataList, getBanGongIntentionList } from '../../../services/BanGongService';
import HandleIntention from '../components/HandleIntention';
let BanGongEnum: any = {};
const LeadManager: React.FC = () => {
	useEffect(() => {
		getBanGongData();
	}, []);
	const [record, setRecord] = useState({});
	const actionRef = useRef<ActionType>();
	const [visible, setVisible] = useState(false);
	const [banGongData, setBanGongData] = useState([]);
	const [banGongUuid, setBanGongUuid] = useState('');
	const [search, setSearch] = useState('');
	const getBanGongData = async () => {
		const res = await getBanGongDataList({});
		console.log('data::111', res);
		if (res.err === 0) {
			res.data.map((item: any) => {
				BanGongEnum[item.uuid] = item.name;
			});
			console.log('BanGongEnum', BanGongEnum);
			setBanGongData(res.data);
			console.log('data:::', res.data);
		} else {
			message.error(res.msg);
		}
	};
	const columns = [
		{
			title: '意向办公名称',
			dataIndex: 'banGongUuid',
			key: 'banGongUuid',
			valueEnum: BanGongEnum,
		},
		{
			title: '客户名称',
			dataIndex: 'name',
			width: '150',
			key: 'name',
		},
		{
			title: '公司名称',
			dataIndex: 'companyName',
			width: '150',
			key: 'companyName',
		},
		{
			title: '手机号',
			width: '150',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: '提交日期',
			dataIndex: 'createdTime',
			key: 'createdTime',
			render: (text: string) => (
				<Tooltip title={moment(text).format('YYYY-MM-DD HH:mm')}>
					{moment(text).format('YYYY-MM-DD HH:mm')}
				</Tooltip>
			),
		},
		{
			title: '提交方式',
			width: '100',
			dataIndex: 'submitPlatform',
			key: 'phone',
		},
		{
			title: '处理结果',
			dataIndex: 'msg',
			key: 'phone',
			onCell: () => {
				return {
					style: {
						maxWidth: 150,
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						cursor: 'pointer',
					},
				};
			},
			render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
		},
		{
			title: '操作',
			width: '100',
			dataIndex: 'active',
			key: 'active',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						chuLiYiXiang(record);
					}}
				>
					处理
				</a>
			),
		},
	];

	const chuLiYiXiang = (record: any) => {
		setRecord(record);
		setVisible(true);
	};

	const tableSearchBarRender = () => [
		<Select
			defaultValue={'显示全部'}
			style={{ width: 200 }}
			onChange={(value: any) => {
				setBanGongUuid(value);
				actionRef.current.reloadAndRest();
			}}
		>
			<Select.Option value={'显示全部'} key={'显示全部'}>
				显示全部
			</Select.Option>
			{banGongData.map((item: any) => {
				return (
					<Select.Option value={item.uuid} key={item.uuid}>
						{item.name}
					</Select.Option>
				);
			})}
		</Select>,
		<Input
			placeholder="客户姓名，公司名称，手机号"
			allowClear
			style={{ width: 300 }}
			onChange={(event) => {
				setSearch(event.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={() => {
			actionRef.current.reloadAndRest();
		}}>查询</Button>
	];

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let url = '/api/bangGong/get_ban_gong_intention_list';
					const res = await getBanGongIntentionList({
						page: params.current,
						size: params.pageSize,
						uuid: banGongUuid === '显示全部' ? '' : banGongUuid,
						search: search,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title={'处理'}
				width={1000}
				style={{ top: 60 }}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={() => {
					setVisible(false);
					actionRef.current.reload();
				}}
				footer={null}
			>
				<HandleIntention key={record.uuid || ''} record={record} intentionUuid={record.uuid} />
			</Modal>
		</div>
	);
};
export default LeadManager;
