import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Image, Input, Modal, Row, Tag } from 'antd';
import API from '../../../data/API';
import appConfig from '../../../appConfig';
import GSTable from '../../../components/GSTable';
import { findFaceInfoByWyUuid, updFaceState } from '../../../services/FaceService';

let msg = require('../../../util/SuspensionMsg');
const Search = Input.Search;
const CheckableTag = Tag.CheckableTag;
const ShenheList: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [inputStr, setInputStr] = useState('');
	const [uuid, setUuid] = useState('');
	const [faceState, setFaceState] = useState(0);
	const [allFace, setAllFace] = useState(false);
	const [examFace, setExamFace] = useState(true); //待审核
	const [oKFace, setOKFace] = useState(false); //通过
	const [failFace, setFailFace] = useState(false); //不通过
	const [stateVisible, setStateVisible] = useState(false);
	const [refStateVisible, setRefStateVisible] = useState(false);
	const columns = [
		{
			title: '姓名',
			dataIndex: 'faceName',
		},
		{
			title: '手机',
			dataIndex: 'userEmail',
		},
		{
			title: '提交时间',
			dataIndex: 'updateTime',
		},
		{
			title: '当前照片',
			dataIndex: 'oldFacePhoto',
			render: (text: any, item: any) =>
				item.oldFacePhoto === null ? (
					''
				) : (
					<Image
						width={150}
						height={100}
						src={
							API.apiRootPath + '/api/file/get_file_qiniu/' + item.oldFacePhoto + '/' + appConfig.wuYeUuid
						}
					/>
				),
		},
		{
			title: '待审核照片',
			dataIndex: 'facePhoto',
			render: (text: any, item: any) => (
				<Image
					width={150}
					height={100}
					src={API.apiRootPath + '/api/file/get_file_qiniu/' + item.facePhoto + '/' + appConfig.wuYeUuid}
				/>
			),
		},
		{
			title: '审核状态',
			render: (text: any, item: any) =>
				item.state === 0 ? '待审核' : item.state === -1 ? '未通过' : item.state === 1 ? '通过' : '重新提交',
		},
		{
			title: '审核时间',
			dataIndex: 'auditTime',
		},
		{
			title: '操作',
			render: (text: any, item: any, index: any) => (
				<ul className="ant-list-item-action">
					<li>
						{item.state !== 0 ? (
							<a className={'disableda'}>通过</a>
						) : (
							<a
								id={index}
								onClick={() => {
									showStateModal('通过', item.uuid);
								}}
							>
								通过
							</a>
						)}
					</li>
					<li>
						{item.state !== 0 ? (
							<a className={'disableda'}>拒绝</a>
						) : (
							<a
								id={index}
								onClick={() => {
									showStateModal('拒绝', item.uuid);
								}}
							>
								拒绝
							</a>
						)}
					</li>
				</ul>
			),
		},
	];

	/** 显示通过/拒绝的Modal框 */
	const showStateModal = (status: any, uuid: any) => {
		if (status === '通过') {
			setStateVisible(true);
			setUuid(uuid);
		} else if (status === '拒绝') {
			setRefStateVisible(true);
			setUuid(uuid);
		}
	};

	/** 选择显示的数据 当前状态 */
	const findFaceByState = (state: number) => {
		if (state === faceState) {
			return;
		}
		if (state === 2) {
			setAllFace(true);
			setExamFace(false);
			setOKFace(false);
			setFailFace(false);
		} else if (state === 0) {
			setAllFace(false);
			setExamFace(true);
			setOKFace(false);
			setFailFace(false);
		} else if (state === 1) {
			setAllFace(false);
			setExamFace(false);
			setOKFace(true);
			setFailFace(false);
		} else if (state === -1) {
			setAllFace(false);
			setExamFace(false);
			setOKFace(false);
			setFailFace(true);
		}
		setFaceState(state);
		if (actionRef.current) {
			actionRef.current.reloadAndRest();
		}
	};
	const /** 获取搜索的手机号 */
		onChangeInput = (e: any) => {
			setInputStr(e.target.value);
		};
	const tableSearchBarRender = () => [
		<CheckableTag
			onChange={() => {
				findFaceByState(2);
			}}
			checked={allFace}
		>
			全部
		</CheckableTag>,
		<CheckableTag
			onChange={() => {
				findFaceByState(0);
			}}
			checked={examFace}
		>
			待审核
		</CheckableTag>,
		<CheckableTag
			onChange={() => {
				findFaceByState(1);
			}}
			checked={oKFace}
		>
			已通过
		</CheckableTag>,
		<CheckableTag
			onChange={() => {
				findFaceByState(-1);
			}}
			checked={failFace}
		>
			已拒绝
		</CheckableTag>,
		<Input
			placeholder="姓名,手机号"
			onChange={onChangeInput}
			value={inputStr}
		/>,
		<Button type={"primary"} onClick={() => {
			actionRef.current.reloadAndRest()
		}}>查询</Button>
	];
	/** 更新审核状态 */

	const handleCancel = () => {
		setRefStateVisible(false);
		setStateVisible(false);
	};
	const updFace = async (state: number) => {
		const res = await updFaceState({ state: state, uuid: uuid });
		if (res.err === 0) {
			msg.suspensionMsg({ content: res.msg, type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
			handleCancel();
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'danger' });
		}
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let phone = inputStr;
					let state = faceState;
					const res = await findFaceInfoByWyUuid({
						page: params.current,
						size: params.pageSize,
						loginId: API.userId,
						phone: phone,
						state: state,
					});
					if (res.err !== 0) {
						msg.suspensionMsg({ content: res.msg, type: 'danger' });
						return;
					}
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			{/*审核通过Modal*/}
			<Modal
				visible={stateVisible}
				title="提示"
				onCancel={handleCancel}
				onOk={() => {
					updFace(1);
				}}
				footer={[
					<Button key="back" onClick={handleCancel}>
						关闭
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => {
							updFace(1);
						}}
					>
						确认
					</Button>,
				]}
			>
				<Row justify={'center'} align={'middle'}>
					您确认审核通过？
				</Row>
			</Modal>

			{/*审核拒绝Modal*/}
			<Modal
				visible={refStateVisible}
				title="提示"
				onOk={() => {
					updFace(-1);
				}}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						关闭
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => {
							updFace(-1);
						}}
					>
						确认
					</Button>,
				]}
			>
				<Row justify={'center'} align={'middle'}>
					您确认要拒绝该用户吗？
				</Row>
			</Modal>
		</div>
	);
};
export default ShenheList;
