import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { findSmsRecord, findSmsSendDetailListByBatchId } from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Input, Modal, Select, Tag } from 'antd';
import * as moment from 'moment';
import { detailList } from '../../../services/PayCostManagementService';
interface SmsDetailPro {
	batchId: any;
}
const BillRecordDetailList: React.FC<SmsDetailPro> = (props: any) => {
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const [sendPhone, setSendPhone] = useState('');
	const [sendStatus, setSendStatus] = useState('');
	const columns = [
		{
			title: '用户',
			dataIndex: 'custName',
			key: 'custName',
		},
		{
			title: '房间号',
			dataIndex: 'roomNumber',
			key: 'roomNumber',
		},
		{
			title: '收费类目',
			dataIndex: 'feeTypeName',
			key: 'feeTypeName',
		},
		{
			title: '金额',
			dataIndex: 'amount',
			key: 'amount',
			valueType:'money'
		},
		{
			title: '费用期间',
			dataIndex: 'startTime',
			render: (text: string, record: any) => {
				return (
					<span>
						{record.startTime !== undefined && record.startTime !== null && record.startTime !== ''
							? moment(record.startTime).format('YYYY-MM-DD') + '~'
							: '-'}
						{record.endTime !== undefined && record.endTime !== null && record.endTime !== ''
							? moment(record.endTime).format('YYYY-MM-DD')
							: ''}
					</span>
				);
			},
		},
		{
			title: '状态',
			key: 'state',
			dataIndex: 'state',
			render: (text: string, record: any) => (
				<Tag color={record.state ? 'green' : 'red'}>
					{record.state ? '成功' :  '失败' }
				</Tag>
			),
		},
		{
			title: '描述',
			key: 'note',
			dataIndex: 'note',
		},
	];

	const tableSearchBarRender = () => [
		<Select
			defaultValue={sendStatus}
			onChange={(value) => {
				setSendStatus(value);
				actionRef.current.reload();
			}}
		>
			<Select.Option key={''} value={''}>
				全部
			</Select.Option>
			<Select.Option key={'1'} value={'1'}>
				成功
			</Select.Option>
			<Select.Option key={'0'} value={'0'}>
				失败
			</Select.Option>
		</Select>,
		<Search
			placeholder="客户,房间号"
			onSearch={(value) => {
				setSendPhone(value);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
			enterButton={true}
		/>,
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await detailList({
						page: params.current,
						size: params.pageSize,
						batchUuid: props.batchId,
						search: sendPhone,
						state: sendStatus,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};

export default BillRecordDetailList;
