import React, { useRef, useState } from "react";
import { ActionType } from "@ant-design/pro-table";
import GSTable from "../../../components/GSTable";
import { findContractList } from "../../../services/Contract";
import { Button, Input, message, Modal, Select, Steps, Row, Col, Form, DatePicker } from "antd";

const PendingApprovalContractList: React.FC = () => {
    const Search = Input.Search;
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState("");
    const [signatory, setSignatory] = useState("");

    const columns = [
        {
            title: "合同编号",
            dataIndex: "contractNo",
            key: "contractNo",
        },
        {
            title: "合同名称",
            dataIndex: "contractName",
            key: "contractName",
        },
        {
            title: "合同开始时间",
            dataIndex: "contractStartTime",
            key: "contractStartTime",
        },
        {
            title: "合同结束时间",
            dataIndex: "contractEndTime",
            key: "contractEndTime",
        },
        {
            title: "合同状态",
            dataIndex: "contractStatus",
            key: "contractStatus",
            render: (text: string, record: any) => (
                <span>
					{record.contractSignatoryAndStatus.map((item: any, index: number) => (
                        <p>{item}</p>
                    ))}
				</span>
            )
        },
        {
            title: "合同协议类型",
            dataIndex: "contractCount",
            key: "contractCount",
            align: "center",
        },
        {
            title: "合同签署人",
            dataIndex: "contractSignatory",
            key: "contractSignatory",
            render: (text: string, record: any) => (
                <span>{record.contractSignatory !== undefined ? record.contractSignatory.toString() : ""}</span>
            )
        },
        {
            title: "操作",
            key: "action",
            render: (text: string, record: any) => (
                <>
                </>
            )
        }
    ];
    const tableSearchBarRender = () => [
        <Input
            placeholder={"合同名称或编号"}
            onInput={(value: any) => {
                setSearch(value.target.value);
            }}
        />,
        <Input
            placeholder="公司名称或签署人姓名"
            onChange={(value) => {
                setSignatory(value.target.value);
            }}
        />,
        <Button type={"primary"} onClick={() => {
            if (actionRef.current) {
                actionRef.current.reloadAndRest();
            }
        }}>查询</Button>
    ];
    return <>
        <GSTable
            columns={columns}
            actionRef={actionRef}
            searchBarRender={tableSearchBarRender()}
            options={{ density: false }}
            tableAlertRender={false}
            request={async (params: any = {}, sort: any, filter: any) => {
                const res = await findContractList({
                    search: search,
                    status: "待审批",
                    page: params.current,
                    size: params.pageSize,
                    signatory: signatory
                });
                return {
                    data: res.data,
                    total: res.count,
                    success: true
                };
            }}
        />
    </>
}
export default PendingApprovalContractList;
