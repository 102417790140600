import React, { useEffect, useRef, useState } from "react";
import {
   getRoomItemRecordList,
} from '../../../services/ZhuhushenheService';
import GSTable from "../../../components/GSTable";
import { ActionType } from "@ant-design/pro-table/lib/typing";
import { Button, Form, Input, InputNumber, message, Modal, Select, Space, Image, Tag, Spin } from 'antd';

import DAL from "../../../data/DAL";
import ApiImp from "../../../data/API";
import moment from 'moment';
import * as XLSX from 'xlsx';
const statusEnum = {
    "正常": <Tag color={'green'}>正常</Tag>,
    "缺失":<Tag color={'blue'}>缺失</Tag>,
    "丢失":<Tag color={'blue'}>丢失</Tag>,
    "损坏":<Tag color={'red'}>损坏</Tag>,
    "异常":<Tag color={'red'}>异常</Tag>,
}
const RoomItemRecordList: React.FC = () => {
    const actionRef = useRef<ActionType>();
    const [houseStyle, setHouseStyle] = useState('');
    const [houseStyleList, setHouseStyleList] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [source, setsource] = useState("")
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [dataTotal, setdataTotal] = useState(0);
    const [loading, setLoading] = useState(false)
    const [quanXuan, setquanXuan] = useState(false)


    const columns = [
        {
            title: '状态',
            width: 50,
            dataIndex: 'state',
            key: 'state',
            valueEnum: statusEnum
        },
        {
            title: '房间号',
            width: 100,
            dataIndex: 'roomNumber',
            key: 'roomNumber',
        },
        {
            title: '物品类目',
            width: 100,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '物品名称',
            width: 100,
            ellipsis: true,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            key: 'brand',
            width: 100,
        },
        {
            title: '型号/规格',
            dataIndex: 'model',
            key: 'model',
            width: 100,
            ellipsis:true
        },

        {
            title: '单价',
            dataIndex: 'price',
            valueType: 'money',
            key: 'price',
            width: 100,
        },
        {
            title: '应有数量',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
        },
        {
            title: '损坏',
            dataIndex: 'damagedQuantity',
            key: 'damagedQuantity',
            width: 100,
        },{
            title: '缺失',
            dataIndex: 'missQuantity',
            key: 'missQuantity',
            width: 100,
        },
        {
            title: '描述',
            dataIndex: 'note',
            key: 'note',
            width: 100,
        },
        {
            title: '异常照片',
            dataIndex: 'exceptionPhoto',
            key: 'exceptionPhoto',
            width: 100,
            render: (_, item: any) => {
                if (DAL.dal.isNotNull(item.exceptionPhoto)) {
                    return item.exceptionPhoto.split(",").map(item => <>
                        <Image width={ 60} height={60} src={ApiImp.cdnRootPath + item }></Image>
                    </>)
                }
            }
        },
        {
            title: '来源',
            dataIndex: 'source',
            width: 80,
            key: 'source',
        },
        {
            title: '操作人',
            width: 100,
            dataIndex: 'opName',
            key: 'opName',
        },
        {

            title: '操作状态',
            width: 100,
            dataIndex: 'opState',
            key: 'opState',
        },
        {
            title: '创建时间',
            width: 150,
            fixed: 'right',
            dataIndex: 'createdTime',
            valueType: 'dateTime',
            key: 'createdTime',
        },
    ];



    const tableSearchBarRender = () => [
        <Select defaultValue={status} style={{width: 150}} onChange={(value: string) => {
            setStatus(value)
        }}>
            <Select.Option value={''}>状态</Select.Option>
            <Select.Option value={'正常'}>正常</Select.Option>
            {/* <Select.Option value={'维修中'}>维修中</Select.Option> */}
            <Select.Option value={'缺失'}>缺失</Select.Option>
            <Select.Option value={'损坏'}>损坏</Select.Option>
            {/* <Select.Option value={'异常'}>异常</Select.Option> */}
        </Select>,
         <Select defaultValue={source} style={{width: 150}} onChange={(value: string) => {
            setsource(value)
        }}>
            <Select.Option value={''}>来源</Select.Option>
            <Select.Option value={'流程单'}>流程单</Select.Option>
            <Select.Option value={'后台'}>后台</Select.Option>
        </Select>,
        <Input placeholder={'物品名称，房间号'} onChange={(event: any) => {
            setSearch(event.target.value)
        }}/>,
        <Button type="primary" onClick={() => {
            if (actionRef.current) {
                actionRef.current.reloadAndRest()
            }
        }}>查询</Button>
    ];


    const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setLoading(true);
		if (quanXuan) {
			const res = await getRoomItemRecordList({
                search: search,
                status: status,
                houseStyle: houseStyle,
                source:source
            });
			setLoading(false);
			if (res.err == 0) {
				setExprotData(res.data.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setLoading(false);
			setExprotData(selectedRows);
		}
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '记录');
		XLSX.writeFile(wb, '记录.xlsx');
	};

    return <>
        <Spin spinning={loading}>

        <GSTable
            columns={columns}
            actionRef={actionRef}
            rowKey="id"
            rowSelection={{
                fixed: true,
                columnWidth: 60,
                preserveSelectedRowKeys: true,
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys: any, selectedRows: any) => {
                    setquanXuan(false);
                    setselectedRowKeys(selectedRowKeys);
                },
                selections: [
                    {
                        key: 'SELECT_ALL',
                        text: '全选全部页面',
                        onSelect: (changableRowKeys: any) => {
                            setquanXuan(true);
                            setselectedRowKeys(changableRowKeys);
                        },
                    },
                ],
            }}
            tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                return (
                    <Space size={24}>
                        <span>
                            已选 {quanXuan ? dataTotal : selectedRowKeys.length} 项
                            <a
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    onCleanSelected();
                                }}
                            >
                                取消选择
                            </a>
                        </span>
                    </Space>
                );
            }}
            tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                console.log('tableAlertOptionRender::::', selectedRows);
                return (
                    <Space size={16}>
                        <a onClick={() => exprotData(selectedRows)}>导出数据</a>
                    </Space>
                );
            }}
            searchBarRender={tableSearchBarRender()}
            request={async (params: any = {}, sort: any, filter: any) => {
                const res = await getRoomItemRecordList({
                    page: params.current,
                    size: params.pageSize,
                    search: search,
                    status: status,
                    houseStyle: houseStyle,
                    source:source
                });
                if (res.err !== 0) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                setdataTotal(res.data.total || 0)
                return {
                    data: res.data.data,
                    total: res.data.total,
                    success: true,
                };
            }}
            />
        </Spin>
    </>
}
export default RoomItemRecordList;
