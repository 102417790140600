import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Input, message, Modal, Row, Switch, Tooltip, Upload} from 'antd';
import {getWuyeProj, updateWuyeProjByKeyAuto} from '../../../services/WuyeService';
import {ActionType} from "@ant-design/pro-table";
import {meetingOrderList} from "../../../services/meetingService";
import GSTable from "../../../components/GSTable";
import {findSmsTemplateList} from "../../../services/sms";
import PropertyNotice from "../components/PropertyNotice";
import {RcFile} from "antd/lib/upload";
import * as XLSX from "xlsx";
import {ExclamationCircleOutlined, InboxOutlined} from "@ant-design/icons/lib";
import {payNotice} from "../../../services/notificationService";
let myXlsx = require('../../../util/myXlsx');
interface Params {
	phones?:any
}
const PayNotice: React.FC<Params> = (props:any) => {
	const actionRef = useRef<ActionType>()
	const [visiable,setVisiable]= useState(false)
	const [smsInfo,setSmsInfo] = useState({})
	const [params,setParams] = useState([])
	const [autoSend,setAutoSend] = useState(false)
	const { TextArea } = Input;
	const { Dragger } = Upload;
	const { confirm } = Modal;
	const maxFileSizeLimit = 1;
	const sheetHeader = [
		'客户手机号',
		'',
		'',
	];
	const [isInput, setIsInput] = useState(false);
	const [userPhone, setUserPhone] = useState([]);
	const [inputPhone, setInputPhone] = useState('');
	const [info1, setInfo] = useState(null);
	const [phone, setPhones] = useState([]);
	const [fileList, setFileList] = useState([]);
	useEffect(() => {
		getProjInfo();

	}, []);
	const [projInfo, setProjInfo] = useState({});
	const columns =[
		{
			title: "ID",
			dataIndex: "templateCode",
			key: "templateCode",
		},
		// {
		// 	title: "模板类型",
		// 	dataIndex: "templateCate",
		// 	key: "templateCate",
		// },
		{
			title: "模板名称",
			dataIndex: "templateName",
			key: "templateName",
		},
		{
			title: "模板内容",
			dataIndex: "content",
			key: "content",
		},
		{
			title: "操作",
			fixed: 'right',
			width: 80,
			render:(text: string, record: any)=>{
				return<>
					<a onClick={()=>{openVisiable(record)}}>发送</a>
				</>
			}
		},
	]

	const openVisiable = (smsInfo:any) => {
		setSmsInfo(smsInfo)

		setVisiable(true)
		setIsInput(false);
		setFileList([])
		if (props.phones) {
			setIsInput(true)
			setPhones(props.phones)
			setInputPhone(props.phones.toString())
		} else {
			setInputPhone("")
		}

		setUserPhone([])
		console.log("smsInfo:::", smsInfo)
		const num = smsInfo.paramNum || 0
		let param =[]
		if (num > 0) {
			for (let i = 0; i < num; i++) {
				param.push("")
			}
			console.log("param::", param)
			setParams([...param])
		}else{
			setParams([])
		}
	}
	const getProjInfo = async () => {
		const res = await getWuyeProj({});
		if (res.err === 0) {
			setProjInfo(res.data);
			setAutoSend(res.data.keyAuto);
		}
	};
	const updataKeyAuto = async (value: boolean) => {
		setAutoSend(value);
		const res = await updateWuyeProjByKeyAuto({
			keyAuto: value,
		});
		if (res.err === 0) {
			message.success('修改成功');
		} else {
			message.error('修改失败');
			setAutoSend(!value);
		}
	};

	/**
	 * 检查文件尺寸
	 */
	const beforeUploadFile = (file: any, fileList: RcFile[]): boolean => {
		console.log('');
		console.log('info::::', file);
		console.log('fileList', fileList);
		const fileSize = file.size / 1024 / 1024;
		console.log('UploadFile.beforeUpload: ', maxFileSizeLimit, fileSize);
		let { status } = file;
		if (file.status == undefined) {
			file.status = 'done';
		}
		let isInLimit = fileSize < maxFileSizeLimit;
		if (!isInLimit) {
			message.warning('最大上传' + maxFileSizeLimit + 'M');
			file.status = 'error11';
			return isInLimit;
		}

		if (status === 'removed') {
			setInputPhone('');
			setIsInput(false);
			setUserPhone([]);
			setInfo(null);
			return true;
		}
		console.log('UploadFile.beforeUpload: 11', info1);
		if (info1 === null || info1 === undefined) {
			setInfo(file);
		} else {
			isInLimit = false;
			file.status = 'error12';
			message.warning('请先删除当前文件后，再次上传新的文件');
		}
		return isInLimit;
	};
	const props1 = {
		name: 'file',
		accept: '.xlsx',
		beforeUpload: beforeUploadFile,
		fileList: fileList,
		onChange(info: any) {
			console.log('info::::', info);
			const { status } = info.file;
			if (status === 'removed') {
				setInputPhone('');
				setIsInput(false);
				setUserPhone([]);
				setFileList([]);
				setInfo(null);
				return;
			}
			if (status === undefined || status === '') {
				// info.fileList = []
				return;
			}
			if (status === 'error12') {
				let list: any = [];
				list.push(info.fileList[0]);
				console.log('list::', info.fileList[0]);
				setFileList(list);
				return;
			}
			if (status === 'error11') {
				if (fileList.length > 0) {
					let list: any = [];
					list.push(info.fileList[0]);
					setFileList(list);
				} else {
					setFileList([]);
				}
				return;
			}
			setFileList(info.fileList);
			myXlsx.readData(info, function (res: any) {
				updata(res);
			});
		},
	};
	const updata = (res: any) => {
		console.log('获得的数22据', res);
		if (res.length <= 0) {
			setIsInput(false);
		}
		setIsInput(true);
		let phone: any = '';
		let Info: any = new Array();
		res.map((p: any) => {
			Info.push({ phone: p.客户手机号});
		});
		setUserPhone(Info);
		console.log('Info::::', Info);
		res.map((p: any) => {
			if (p.客户手机号 != '' && p.客户手机号 != undefined) {
				phone = p.客户手机号 + ',' + phone;
			}
		});
		const arr2 = res.filter(function (item: any) {
			return item.客户手机号;
		});
		setInputPhone(phone);
		setPhones(arr2);
	};
	/** 模板下载 */
	const exportBmry = () => {
		let sheetData = new Array(1);
		sheetData[0] = sheetHeader;
		sheetData[1] = [
			'1391xxxxxxx',
			'',
			'',
		];
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, '短信模板.xlsx');
	};

	const clickSend = () => {
		// let upload = inputPhone.split(",")
		const uploadLenth = phone.length > 0 ? phone.length : 0;
		if (uploadLenth > 5000) {
			const content = '当前一共' + uploadLenth + '条，最多支持5000条号码，请删除重新上传';
			message.warning(content);
			return;
		}
		confirm({
			title: '通知',
			icon: <ExclamationCircleOutlined />,
			content: '本次将发送' + uploadLenth + '条短信，请确认无误后发送',
			onOk: async () => {
				return sendMessage();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	/** 获取手机号 */
	const inputStr = (e: any) => {
		const content = e.target.value;
		let code = content.split(/[(\r\n)\r\n]+/); // 根据换行或者回车进行识别
		code.forEach((item: any, index: any) => {
			// 删除空项
			if (!item) {
				code.splice(index, 1);
			}
		});
		code = Array.from(new Set(code)); // 去重
		setPhones(code);
		console.log('code:::::', code);
		setInputPhone(e.target.value);
	};
	/** 获取要发送的内容 */
	const getInputInfo = (e: any, index: any) => {
		params[index] = e.target.value.replace(/,/gm,'，')
		setParams([...params]);
	}; /** 获取要发送的内容 */
	const sendMessage = async () => {
			let errData = 0
			let data = {
				wyUuid: localStorage.getItem('wyUuid'),
				inputInfo: params.toString(),
				inputPhone: phone.toString(),
				noticeType: smsInfo.templateType,
			};
			if (userPhone.length > 0 && userPhone !== null) {
				(data as any).userPhone = JSON.stringify(userPhone);
				(data as any).inputPhone = inputPhone;
			} else {
				if (inputPhone === null || inputPhone === '') {
					message.warning("手机号不能为空")
					return;
				}
				let regP = '^1(3|4|5|6|7|8|9)\\d{9}$';

				phone.map((item: any) => {
					if (!item.match(regP)) {
						errData++
						return;
					}
				});
				if(errData>0){
					message.warning("手机号格式不正确")
					return;
				}
				(data as any).userPhone = JSON.stringify(userPhone);
			}
			if(params.length>0){
				params.map((item)=>{
					if(item==null||item == undefined || item == ''){
						errData++
						return;
					}
				})
			}
			if(errData>0){
				message.warning("请填写参数")
				return;
			}
			console.log('formData:::,', data);
			const res = await payNotice(data);
			if ((res as any).err === 0) {
				message.success( (res as any).msg)
				setUserPhone([])
				setInputPhone("")
			} else {
				message.error( '发送结果' + (res as any).msg)
			}
	};
	return (
		<div>
			<Row gutter={16}>
				<Col span={24}>
					<span style={{ fontSize: 14 }}>
						如果您想设置自己公司的短信签名，请联系您的商服经理，并提供授权申请证书等
						{/* ，我们将联系运营商免费为您提供专属短信签名申请服务 */}
					</span>
				</Col>
			</Row>

			<GSTable
				columns={columns}
				actionRef={actionRef}
				// searchBarRender={tableSearchBarRender()}
				// operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findSmsTemplateList({
						page: params.current,
						size: params.pageSize,
					});
					return {
						data: res.data,
						// total: res.count,
						success: true,
					};
				}}
			/>
			<Modal key={smsInfo.toString()} width={900} title={'短信发送'} visible={visiable}  onCancel={()=>setVisiable(false)} footer={null}>
					<Row hidden={smsInfo.templateType != 'key_notice'} gutter={[16, 16]}>
						<Col span={4} push={1}>
							自动发送
						</Col>
						<Col span={2}>
							<Switch
								checked={autoSend}
								onChange={(value) => {
									updataKeyAuto(value)
								}}
							/>
						</Col>
						<Col span={16}>开启后，发送给用户钥匙时，将自动发送此短信通知</Col>
					</Row>

					<Row gutter={[16, 16]}>
						<Col span={4} push={1}>
							短信签名
						</Col>
						<Col span={20}>{projInfo.smsSign === null || projInfo.smsSign === undefined ? '' : projInfo.smsSign}</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={4} push={1}>
							模板内容
						</Col>
						<Col span={20}>{projInfo.smsSign === null || projInfo.smsSign === undefined ? '' : '【' + projInfo.smsSign + '】' + smsInfo.content}</Col>
					</Row>
					{params.length>0 ?
					params.map((item:any,index:any)=>{
						return(	<Row gutter={[16, 16]}>
							<Col span={4} push={1}>
								参数{index+1}的内容
							</Col>
							<Col span={20}>
								<Input value={item} placeholder={'前输入{}内的内容'} onChange={(e)=>{getInputInfo(e,index)}} />
							</Col>
						</Row>)
					}):''
					}
					<Row gutter={[16, 16]}>
						<Col span={4} push={1}>
							发送对象
						</Col>
						<Col span={20}>
							<TextArea
								disabled={isInput}
								onChange={inputStr}
								value={inputPhone}
								autoSize={{ minRows: 5, maxRows: 5 }}
								placeholder={'请输入手机号（使用回车键换行，一行视为一个号码）或者上传文件'}
							/>
						</Col>
						<Col span={4} push={1}></Col>
						<Col span={20}>
							<Dragger {...props1}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">点击或将文拖拽到这里上传</p>
								<p className="ant-upload-hint">支持扩展名：xlsx</p>
							</Dragger>
						</Col>
					</Row>
					<br />
					<Row gutter={[16, 16]}>
						<Col span={4} push={1}></Col>
						<Col span={20}>最多支持上传5000条号码，请上传xlsx格式文件，大小1MB以内</Col>
						<Col span={4}></Col>
						<Col span={20} style={{ marginTop: -14 }}>
							请根据要求填入客户手机号和变量内容<a onClick={exportBmry}>下载短信模板</a>
						</Col>
						<Col span={4}></Col>
						<Col span={20}>
							<Button onClick={clickSend} type={'primary'}>
								发送
							</Button>
						</Col>
					</Row>
			</Modal>
		</div>
	);
};
export default PayNotice;
