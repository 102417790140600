import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, DatePicker, Input, Select, TreeSelect } from 'antd';
import GSTable from '../../../components/GSTable';
import { getAllRecord, getDevices, getEquipmentRecords } from '../../../services/ZhiNengMenJinService';
import CustomRangePicker from '../../../components/CustomRangePicker';
import moment from 'moment';
import GsTreeSelect from "../../../components/GsTreeSelect";
let msg = require('../../../util/SuspensionMsg');
const Search = Input.Search;
const Option = Select.Option;
let { RangePicker } = DatePicker;

const EquipmentRecord: React.FC = (props: any) => {
	useEffect(() => {
		getUserDevice();
	}, []);

	const actionRef = useRef<ActionType>();
	const [userDevices, setUserDevices] = useState('');
	const [Stime, setStime] = useState(
		moment().subtract(7, 'd').hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:00')
	);
	const [Etime, setEtime] = useState(moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:59'));
	const [phone, setPhone] = useState('');
	const [event, setEvent] = useState('全部事件');
	const [events, setEvents] = useState('全部事件');
	const [selectionDates, setSelectionDates] = useState('最近7天');
	const [selectionDate, setSelectionDate] = useState('最近7天');
	const [userdevice, setUserdevice] = useState([]);
	const [selectState, setSelectState] = useState('2');

	const columns = [
		{
			title: '设备名称',
			dataIndex: 'deviceName',
		},
		{
			title: '事件',
			dataIndex: 'event',
		},
		{
			title: '时间',
			dataIndex: 'date',
		},
	];

	const getUserDevice = async () => {
		const res = await getDevices({});
		if (res.err !== 0) {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		res.data.map((item: any) => {
			item["selectable"] = false
		})
		setUserdevice(res.data);
	};

	/** 选择时间 */
	const selectionDates1 = (ev: any) => {
		if (ev === '自定义') {
			setSelectState('1');
		} else {
			setSelectState('2');
			setEtime('');
			setStime('');
		}
		setSelectionDate(ev);
		setSelectionDates(ev);
	};

	const dateTime = (startTime: any, endTime: any) => {
		setStime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEtime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};

	const getInputStr = (ev: any) => {
		setPhone(ev.target.value);
	};
	const tableSearchBarRender = () => [
		<GsTreeSelect
			// key={this.props.deviceId === undefined ? '' : this.props.deviceId}
			treeData={userdevice}
			multiple={true}
			onChange={(value: any) => {
				setUserDevices(value);
			}}
		/>,
		<CustomRangePicker onChange={dateTime} />,
		<Button
			type="primary"
			onClick={() => {
				actionRef.current.reloadAndRest();
			}}
		>
			查询
		</Button>,
		// <Search
		//     placeholder="账号搜索"
		//     onSearch={() => actionRef.current.reload()}
		//     value={phone}
		//     onInput={getInputStr}
		//     enterButton={true}
		// />
	];

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				// options={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {};
					if (userDevices !== null && userDevices !== undefined) {
						(data as any).device_id = userDevices.toString();
					}
					if (Stime !== null && Stime !== undefined) {
						(data as any).Stime = Stime;
					}
					if (Etime !== null && Etime !== undefined) {
						(data as any).Etime = Etime;
					}
					if (phone !== null && phone !== undefined) {
						(data as any).phone = phone;
					}
					(data as any).event = '设备上下线';
					(data as any).type = '设备上下线';
					(data as any).selectionDates = selectionDates;
					(data as any).page = params.current;
					(data as any).size = params.pageSize;
					let res = await getEquipmentRecords(data);
					if (res.err !== 0) {
						msg.suspensionMsg({ content: res.msg, type: 'error' });
						return;
					}
					return {
						data: res.records,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default EquipmentRecord;
