import React, { useState } from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons/lib";
interface StarProps {
    check: boolean
    onChange?: (check: boolean) => void
}
const Star: React.FC<StarProps> = (props: any) => {
    const [check, setCheck] = useState(props.check || false);
    return <>
        {
            check ?
                <StarFilled onClick={() => {
                    setCheck(false)
                    if (props.onChange) {
                        props.onChange(false)
                    }
                }} style={{ color: "#E98A11" }}/> :
                <StarOutlined onClick={() => {
                    setCheck(true)
                    if (props.onChange) {
                        props.onChange(true)
                    }
                }}/>
        }

    </>
}
export default Star;
