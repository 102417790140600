import * as React from 'react';
import DAL from '../../data/DAL';
import API from '../../data/API';
import { DatePicker } from 'antd';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import { getDeviceidData } from '../../services/StatisticsService';

var msg = require('../../util/SuspensionMsg');
var echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/line'); //图表类型
require('echarts/lib/component/title'); //标题插件
var moment = require('moment');

class KaimenList extends React.Component {
	dal = DAL.dal;
	lineData: any;
	sumData: any;
	successData: any;
	failData: any;
	xTime: any;
	deviceNmae: any;
	flag: boolean = window.location.search.trim().indexOf('debug') !== -1;
	constructor(props: any) {
		super(props);
		//获取时间
		var date = new Date();
		var year, month, day;
		date.setDate(date.getDate() - 7);
		year = date.getFullYear();
		month = date.getMonth() + 1;
		day = date.getDate();
		var startData = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
		var endData = moment(new Date()).format('YYYY-MM-DD');

		this.state = {
			value: '',
			users: [],
			isShow: false,
			viewType: '开锁次数',
			deviceNames: [],
			startData: startData,
			endData: endData,
			selectDevice: '全部设备',
		};

		this.setPieOption = this.setPieOption.bind(this);
		this.lines = this.lines.bind(this);
		this.getDate = this.getDate.bind(this);
		this.openLockNumber = this.openLockNumber.bind(this);
		this.openLockSuccessNumber = this.openLockSuccessNumber.bind(this);
		this.openLockFailNumber = this.openLockFailNumber.bind(this);
		this.callData = this.callData.bind(this);
		this.onCchangItem = this.onCchangItem.bind(this);
		this.allDeviceChange = this.allDeviceChange.bind(this);
		this.getStartData = this.getStartData.bind(this);
		this.getEndData = this.getEndData.bind(this);
	}

	callData() {
		console.log('获得的时间1:', (this.state as any).startData);
		this.getData((this.state as any).startData + ' 00:00:00', (this.state as any).endData + ' 00:00:00');
	}

	componentDidMount() {
		this.getData((this.state as any).startData + ' 00:00:00', (this.state as any).endData + ' 00:00:00');
	}
	getStartData(date: any, dateString: string) {
		console.log('开始时间改变' + dateString);
		this.setState({
			startData: dateString,
		});
	}
	getEndData(date: any, dateString: string) {
		console.log('结束时间改变' + dateString);
		this.setState({
			endData: dateString,
		});
	}
	getData = async (startTime: string, endTime: string) => {
		this.setState({
			isShow: true,
		});
		const res = await getDeviceidData({
			startTime: startTime,
			endTime: endTime,
		});
		// API.postWithAuth('/api/mongodb/get_deviceid_data', {
		//     startTime: startTime,
		//     endTime: endTime
		// }).then(res => {
		if (res.err === 0) {
			var datas = res.data;
			var showDataSum = [];
			var showDataSuccess = [];
			var showDataFail = [];
			var series = [];
			var devicename = [];
			var xAxisData = [];
			var num = 0;
			var nums = [];
			var allDevice = [];

			for (var j = 0; j < datas[0].sum.length; j++) {
				for (var i = 0; i < datas.length; i++) {
					var da = datas[i];
					var device_name = da.device_name;

					num += datas[i].sum[j];

					var sum = da.sum;
					var days = da.days;
					showDataSuccess[i] = {
						name: device_name,
						type: 'line',
						data: da.success,
						itemStyle: { normal: { label: { show: true } } },
					};
					showDataFail[i] = {
						name: device_name,
						type: 'line',
						data: da.fail,
						itemStyle: { normal: { label: { show: true } } },
					};
					//把天数转换成日期,只需转换一次
					if (i === 0) {
						for (var y = 0; y < days.length; y++) {
							xAxisData[y] = this.getDate(da.year, days[y]);
						}
					}
					series[i] = {
						name: device_name,
						type: 'line',
						data: sum,
						itemStyle: { normal: { label: { show: true } } },
					};
					devicename[i] = device_name;
					showDataSum[i] = { value: sum[0], name: device_name };
				}
				nums[j] = num;
				num = 0;
				allDevice[0] = {
					name: '总数据',
					type: 'line',
					data: nums,
					itemStyle: { normal: { label: { show: true } } },
				};
			}

			this.xTime = xAxisData;
			//防止选择设备的时候覆盖所以的设备，做出一下判断，当为空的时候才进行设备复制
			if (this.deviceNmae === undefined || this.deviceNmae.size === 0) {
				this.deviceNmae = devicename;
			}
			this.sumData = series;
			this.successData = showDataSuccess;
			this.failData = showDataFail;
			this.lineData.clear();
			console.log('清空数据...................');
			this.lineData.setOption(this.setPieOption(allDevice, xAxisData, devicename));
			this.setState({
				deviceNames: this.deviceNmae,
				isShow: false,
				selectDevice: '全部设备',
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};

	//通过天数获得日期
	getDate(year: number, day: number) {
		var newYear = year + '-01-01 00:00:00';
		newYear = newYear.replace(new RegExp('-', 'gm'), '/');
		var yearHaomiao = new Date(newYear).getTime(); //得到毫秒数
		var dayHaomiao = (day - 1) * 24 * 3600 * 1000;
		var sumHaomiao = yearHaomiao + dayHaomiao;
		var newTime = new Date(sumHaomiao);
		let dayy = moment(newTime).format('YYYY-MM-DD');
		return dayy;
	}

	lines(lin: any) {
		if (lin !== null) {
			this.lineData = echarts.init(lin);
		}
	}

	//一个基本的echarts图表配置函数
	setPieOption(data: any, xAxisData: any, devicename: any) {
		console.log('总数据', data);
		var max = data[0].data[0];
		var intervalNum = 0;
		for (var i = 1; i < data[0].data.length; i++) {
			if (data[0].data[i] > max) {
				max = data[0].data[i];
			}
		}

		intervalNum = (Math.round(max / 50) + 1) * 50;
		intervalNum = intervalNum / 5;

		return {
			title: {
				text: '开门数据统计',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
			},
			legend: {
				data: devicename,
				/*   data: ['蒸发量', '降水量', '平均温度']*/
			},
			xAxis: [
				{
					type: 'category',
					/* data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],*/
					data: xAxisData,
				},
			],
			yAxis: [
				{
					type: 'value',
					name: '开锁次数',
					interval: intervalNum,
					axisLabel: {
						formatter: '{value} 次',
					},
				},
			],
			series: data,
		};
	}

	//选择开锁次数
	openLockNumber() {
		//判断相同这不执行
		if ((this.state as any).viewType === '开锁次数') {
			return;
		}

		this.setState({
			viewType: '开锁次数',
		});
		//判断显示那些设备
		this.lineData.clear();
		if ((this.state as any).selectDevice === '全部设备') {
			this.lineData.setOption(this.setPieOption(this.allDeviceChange(this.sumData), this.xTime, this.deviceNmae));
		} else {
			var arry1 = this.sumData.filter(
				(item: any, index: any) => item.name.indexOf((this.state as any).selectDevice) > -1
			);
			this.lineData.setOption(this.setPieOption(arry1, this.xTime, this.deviceNmae));
		}
	}

	//选择开锁成功次数
	openLockSuccessNumber() {
		if ((this.state as any).viewType === '成功次数') {
			return;
		}
		this.setState({
			viewType: '成功次数',
		});
		//判断显示那些设备
		this.lineData.clear();
		if ((this.state as any).selectDevice === '全部设备') {
			this.lineData.setOption(
				this.setPieOption(this.allDeviceChange(this.successData), this.xTime, this.deviceNmae)
			);
		} else {
			var arry1 = this.successData.filter(
				(item: any, index: any) => item.name.indexOf((this.state as any).selectDevice) > -1
			);
			this.lineData.setOption(this.setPieOption(arry1, this.xTime, this.deviceNmae));
		}
	}

	//选择开锁失败次数
	openLockFailNumber() {
		if ((this.state as any).viewType === '失败次数') {
			return;
		}
		this.setState({
			viewType: '失败次数',
		});
		//判断显示那些设备
		this.lineData.clear();
		if ((this.state as any).selectDevice === '全部设备') {
			this.lineData.setOption(
				this.setPieOption(this.allDeviceChange(this.failData), this.xTime, this.deviceNmae)
			);
		} else {
			var arry1 = this.failData.filter(
				(item: any, index: any) => item.name.indexOf((this.state as any).selectDevice) > -1
			);
			this.lineData.setOption(this.setPieOption(arry1, this.xTime, this.deviceNmae));
		}
	}
	//选择设备
	onCchangItem(ev: any) {
		console.log('获得的设备id:', ev.target.id);

		//这里筛选出一个设备，但是无法更新界面，还待研究
		var viewType = (this.state as any).viewType;
		var arry = [];
		if (viewType === '开锁次数') {
			arry = this.sumData;
		} else if (viewType === '成功次数') {
			arry = this.successData;
		} else if (viewType === '失败次数') {
			arry = this.failData;
		}
		var arry1 = [];
		if (ev.target.id !== '全部设备') {
			arry1 = arry.filter((item: any, index: any) => item.name.indexOf(ev.target.id) > -1);
		} else {
			var num = 0;
			var nums = [];
			var allDevice = [];
			for (var i = 0; i < arry[0].data.length; i++) {
				for (var j = 0; j < arry.length; j++) {
					num += arry[j].data[i];
				}
				nums[i] = num;
			}
			allDevice[0] = {
				name: '总数据',
				type: 'line',
				data: nums,
				itemStyle: { normal: { label: { show: true } } },
			};
			arry1 = allDevice;
		}
		console.log('筛选的数据:', arry1.data);
		this.lineData.clear();
		this.lineData.setOption(this.setPieOption(arry1, this.xTime, this.deviceNmae));
		this.setState({
			selectDevice: ev.target.id,
		});
	}

	formChange() {
		console.log('筛选的数据:');
	}

	//全部设备 (开锁次数|成功次数|失败次数) 数据量
	allDeviceChange(data: any) {
		var num = 0;
		var nums = [];
		var allDevice = [];
		for (var i = 0; i < data[0].data.length; i++) {
			for (var j = 0; j < data.length; j++) {
				num += data[j].data[i];
			}
			nums[i] = num;
			num = 0;
		}
		allDevice[0] = { name: '总数据', type: 'line', data: nums, itemStyle: { normal: { label: { show: true } } } };

		return allDevice;
	}

	render() {
		return (
			<div>
				<nav className="filter-menu-bar navbar navbar-default" role="navigation">
					<div className="container-fluid">
						<ul className="nav navbar-nav">
							<li>
								<div className="dropdown query-conditions-first">
									<button className="btn" type="button" data-toggle="dropdown">
										{(this.state as any).selectDevice} <span className="caret" />
									</button>
									<div className="dropdown-menu dropdown-menu-table scroll-div">
										<div className="scroll-div-item" onClick={this.onCchangItem} id="全部设备">
											全部设备
										</div>
										{(this.state as any).deviceNames.map((item: any, index: any) => (
											<div
												className="scroll-div-item"
												onClick={this.onCchangItem}
												id={item}
												key={item}
											>
												{item}
											</div>
										))}
									</div>
								</div>
							</li>
							<li>
								<div className="dropdown query-conditions-one">
									<button className="btn" type="button" data-toggle="dropdown">
										{(this.state as any).viewType}
										<span className="caret" />
									</button>
									<div className="dropdown-menu dropdown-menu-table scroll-div">
										<div className="scroll-div-item" onClick={this.openLockNumber}>
											开锁次数
										</div>
										<div className="scroll-div-item" onClick={this.openLockSuccessNumber}>
											成功次数
										</div>
										{this.flag ? (
											<div className="scroll-div-item" onClick={this.openLockFailNumber}>
												失败次数
											</div>
										) : (
											''
										)}
									</div>
								</div>
							</li>
							<li>
								<form id="lineForm" onChange={this.formChange} className="form-display-flex">
									<div className="input-group-first">
										{/*<input className="form-control form-datetime-yy send-key-dialog-footer-time-input" value={(this.state as any).startData} name="start_time" placeholder="请选择开始时间"/>*/}
										<DatePicker placeholder="请选择开始时间" onChange={this.getStartData} />
									</div>
									<div className="input-group-one">
										{/*<input className="form-control form-datetime-yy send-key-dialog-footer-time-input" value={(this.state as any).endData} name="end_time" placeholder="请选择结束时间"/>*/}
										<DatePicker placeholder="请选择结束时间" onChange={this.getEndData} />
									</div>
									<div className="input-group-one">
										<button
											className="btn btn-primary"
											type="button"
											id="btnlines"
											onClick={this.callData.bind(this, (this.state as any).uuid)}
										>
											搜索
										</button>
									</div>
								</form>
							</li>
						</ul>
					</div>
				</nav>

				<div className="content">
					<div className="row ">
						<div className="col-xs-12 div-text-align-center">
							<canvas ref={this.lines} width="1200" height="700" />
						</div>
					</div>

					{(this.state as any).isShow ? (
						<div className="cover">
							<div>
								<i className="icon icon-spin icon-spinner-indicator" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default KaimenList;

// helpers

/*function getExclamationMarks(numChars: number) {
    return Array(numChars + 1).join('!');
}*/
