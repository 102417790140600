import React from 'react';

import RoomStatusComponents from '../../../components/RoomStatusComponents';
const RoomStatus: React.FC = (props: any) => {
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<RoomStatusComponents showType={'update'} />
		</div>
	);
};
export default RoomStatus;
