import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import LouDong from './pages/LouDong';
import RoomList from './pages/RoomList';
import RoomStatus from './pages/RoomStatus';

import RoomAndHousehold from './components/RoomAndHousehold';
import LayerPlan from './components/LayerPlan';
import KaimenData from './components/KaimenData';
import RoomSetting from './pages/RoomSetting';
import CleanItemList from './pages/CleanItemList';

export default function AssetsMiniApp() {
	const rootUrl = '/assets';
	const menus: MiniAppMenu[] = [
		{
			authority: 'assets_management:build_management',
			name: '楼栋管理',
			path: 'loudong',
			component: LouDong,
		},
		{
			authority: 'assets_management:room_management',
			name: '房间管理',
			path: 'room_list',
			component: RoomList,
		},
		{
			authority: 'assets_management:room_status',
			name: '房态图',
			path: 'room_status',
			component: RoomStatus,
		},
		{
			authority: 'assets_management:param_setting',
			name: '参数设置',
			path: 'room_settings',

			// component: RoomChargeList,
			component: RoomSetting,
		},
		// {
		// 	authority: 'assets_management:param_setting',
		// 	name: '收费参数设置',
		// 	path: 'room_settings',
		// 	// component: RoomChargeList,
		// 	component: RoomChargeList1,
		// },
	];

	const routes: MiniAppRoute[] = [
		{
			path: 'room-and-household',
			component: RoomAndHousehold,
		},
		{
			path: 'build-layer-plan',
			component: LayerPlan,
		},
		{
			path: 'statistics',
			component: KaimenData,
		},
	];

	return <MiniApp miniAppName={'资产管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
