import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Select,
	Space,
	Switch,
	TreeSelect,
	Tag,
	InputNumber,
	Row,
	Col
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { addCar, deleteCar, getCarList, getCarPathList, updateCar } from '../../../services/Car';
import { connect } from 'dva';

import ConnectState from '../../../models/connect';
import FangKePicker from '../../../components/FangKePicker';
import AuthorizedView from '../../../util/AuthorizedView';
import * as XLSX from "xlsx";
import { findDepartmentDirectory } from "../../../services/WuyeService";
import GsTreeSelect from "../../../components/GsTreeSelect";

let moment = require('moment');
const CarManager: React.FC = (props: any) => {
	useEffect(() => {
		// empUserList.map((item: any) => {
		// 	item['disabled'] = true
		// })
		console.log('empUserList:::', empUserList);
		findCarPathList().then();
		getDepartment().then()
	}, []);
	const actionRef = useRef<ActionType>();
	const { common } = props;
	const { empUserList } = common;
	const [visible, setVisble] = useState(false);
	const [add, setAdd] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [search, setSearch] = useState(undefined);
	const [status, setStatus] = useState(undefined);
	const [carStatus, setCarStatus] = useState(true);
	const [userType, setUserType] = useState(undefined);
	const [userType1, setUserType1] = useState('临时人员');
	const [startTime, setStartTime] = useState(moment());
	const [expireTime, setExpireTime] = useState(moment());
	const [carPathList, setCarPathList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [updateStartTime, setUpdateStartTime] = useState<any>('');
	const [updateEndTime, setUpdateEndTime] = useState<any>('');
	const [addTime, setAddTime] = useState(new Date().getTime().toString());
	const [quanXuan, setQuanXuan] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [total, setTotal] = useState(0);
	const [department, setDepartment] = useState([]);
	const [departmentUuid, setDepartmentUuid] = useState([]);
	const Option = Select.Option;
	const FormItem = Form.Item;
	const columns = [
		{
			title: '车牌号',
			dataIndex: 'carNumber',
			key: 'carNumber',
			width: 100,
		},
		{
			title: '通行有效期',
			valueType: 'custom',
			dataIndex: 'startTime',
			key: 'startTime',
			width: 200,
			render: (text: string, record: any) => (
				<>
					<span>
						{record.startTime !== undefined && record.startTime !== null
							? moment(record.startTime).format('YYYY-MM-DD')
							: ''} -
						{record.expireTime !== undefined && record.expireTime !== null
							? moment(record.expireTime).format('YYYY-MM-DD')
							: '-'}
					</span>
				</>
			),
		},
		{
			title: '人员类型',
			dataIndex: 'userType',
			key: 'userType',
			width: 100,
			render: (text: string, record: any) => (
				<span>{record.userType === '员工' ? '固定员工' : record.userType}</span>
			),
		},

		{
			title: '单位名称',
			width: 150,
			dataIndex: 'departmentName',
			key: 'departmentName',
		},
		{
			title: '车辆责任人',
			width: 100,
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '联系电话',
			width: 100,
			dataIndex: 'userPhone',
			key: 'userPhone',
		},
		{
			title: '责任人身份证号',
			width: 100,
			dataIndex: 'userIdNum',
			key: 'userIdNum',
		},
		{
			title: '驾驶证档案编号',
			width: 100,
			dataIndex: 'jiaShiZhengNum',
			key: 'jiaShiZhengNum',
		},
		{
			title: '车辆性质',
			width: 100,
			dataIndex: 'carNature',
			key: 'carNature',
		},
		{
			title: '车辆所有人',
			width: 100,
			dataIndex: 'carHolder',
			key: 'carHolder',
		},
		{
			title: '车辆品牌',
			width: 100,
			dataIndex: 'carType',
			key: 'carType',
		},
		{
			title: '备注',
			width: 100,
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: '状态',
			width: 100,
			dataIndex: 'status',
			key: 'status',
			render: (text: string, record: any) => (
				<>
					<Tag color={record.status ? 'green' : 'red'}>{record.status ? '正常' : '禁止'}</Tag>
				</>
			),
		},
		{
			title: '同步状态',
			width: 100,
			dataIndex: 'syncStatus',
			key: 'syncStatus',
			render: (text: string, record: any) => (
				<>
					<Tag color={record.syncStatus ? 'green' : 'red'}>{record.syncStatus ? '已同步' : '未同步'}</Tag>
				</>
			),
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 100,
			render: (text: string, record: any) => (
				<Space>
					<AuthorizedView needAuthority={'car-manager:update'}>
						<a
							onClick={() => {
								openUpdateModal(record);
							}}
						>
							编辑
						</a>
					</AuthorizedView>
					<AuthorizedView needAuthority={'car-manager:delete'}>
						<a
							onClick={() => {
								deleteItem(record);
							}}
						>
							删除
						</a>
					</AuthorizedView>
				</Space>
			),
		},
	];

	const getDepartment = async () => {
		const res = await findDepartmentDirectory({
			companyUuid: localStorage.getItem("companyUuid"),
			showAll: true
		});
		if (res.err == 0) {
			console.log('res:::', res.data);
			let dataList: any = [];
			res.data.map((item: any) => {
				let data: any = {};
				data["id"] = item.uuid;
				data["pId"] = item.parentUuid;
				data["value"] = item.uuid;
				data["title"] = item.departmentName;
				dataList.push(data);
			});
			setDepartment(dataList)
		}
	};

	const findCarPathList = async () => {
		const res = await getCarPathList({});
		if (res.err === 0) {
			setCarPathList(res.data);
		}
	};

	const openUpdateModal = (item: any) => {
		console.log('item::', item);
		setAdd(false);
		const data: any = {
			uuid: item.uuid,
			carNumber: item.carNumber,
			userType: item.userType,
			note: item.note,
			status: item.status,
			carPathUuid: (item.carPathUuid || '').split(','),
			userIdNum: item.userIdNum,
			carType: item.carType,
			carNature: item.carNature,
			carHolder: item.carHolder,
			userUuid: item.empUuid,
			carNo: item.carNo,
			jiaShiZhengNum: item.jiaShiZhengNum,
			userName: item.userName,
			userPhone: item.userPhone,
		};
		setCarStatus(item.status)
		const end = moment(item.expireTime).format('YYYY-MM-DD');
		const sta = moment(item.startTime).format('YYYY-MM-DD');
		if (item.userType == '访客' || item.userType == '临时人员') {
			data.startTime = moment(sta, 'YYYY-MM-DD');
			data.endTime = moment(end, 'YYYY-MM-DD');
		}
		if (item.suspendTime) {
			data.suspendTime = moment(item.suspendTime)
		}
		console.log('data:::::',data)
		setUpdateStartTime(moment(item.startTime).format('YYYY-MM-DD'));
		setUpdateEndTime(moment(item.expireTime).format('YYYY-MM-DD'));
		setUserType1(item.userType);
		form.setFieldsValue(data);
		setVisble(true);
	};

	const deleteItem = (item: any) => {
		Modal.confirm({
			title: '你确定要删除' + item.carNumber + '吗？',
			okType: 'danger',
			onOk: async () => {
				const data: any = {
					id: item.id,
					uuid: item.uuid,
				};
				const res = await deleteCar(data);
				if (res.err === 0) {
					message.success('删除成功');
					actionRef.current.reload();
				} else {
					message.error('删除失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const exprotData = async (selectedRows: [any?]) => {
		console.log("selectedRows:::::", selectedRows);
		setLoading(true);
		if (quanXuan) {
			const res = await getCarList({
				search: search,
				userType: userType,
				status: status,
				departmentUuid:(departmentUuid || []).toString()
			});
			if (res.err === 0) {
				setExprotData(res.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
		setLoading(false);
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children || '');
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}

					} else {
						data.push("");
					}
				} else if (item.valueType === "dateTime") {
					data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
				} else if (item.valueType === "custom") {
					console.log('item:::::::', item)
					data.push(moment(selectedRows[i].startTime).format("YYYY-MM-DD") + '-' + moment(selectedRows[i].expireTime).format("YYYY-MM-DD") )
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "车辆列表");

		/* save to file */
		XLSX.writeFile(wb, "车辆列表.xlsx");
	};

	const tableSearchBarRender = () => [
		<Space>
			<GsTreeSelect
				treeDefaultExpandAll
				treeData={department}
				multiple={true}
				treeCheckable={true}
				showCheckedStrategy={TreeSelect.SHOW_ALL}
				placeholder={'请选择部门'}
				treeDataSimpleMode={true}
				treeCheckStrictly={true}
				onChange={(value: any) => {
					let values: any = [];
					value.map((item: any) => {
						values.push(item.value);
					});
					setDepartmentUuid(values);
				}}
			/>
			<Select
				placeholder={'人员类型'}
				style={{ width: 100 }}
				allowClear
				onChange={(value: any) => {
					setUserType(value);
				}}
			>
				<Option value={'员工'}> 固定员工</Option>
				<Option value={'访客'}> 访客</Option>
				<Option value={'临时人员'}> 临时人员</Option>
			</Select>
			<Select
				placeholder={'状态'}
				allowClear
				style={{ width: 100 }}
				onChange={(value: any) => {
					setStatus(value);
				}}
			>
				<Option value={'true'}> 正常</Option>
				<Option value={'false'}> 禁止</Option>
			</Select>
			<Input
				placeholder={'姓名，手机号，车牌号'}
				onChange={(e: any) => {
					setSearch(e.target.value);
				}}
			/>
			<Button
				type={'primary'}
				onClick={() => {
					actionRef.current.reloadAndRest();
				}}
			>
				查询
			</Button>
		</Space>,
	];
	const tableOperationsBarRender = () => [
		<AuthorizedView needAuthority={'car-manager:add'}>
			<Button type={'primary'} onClick={openAddModal}>
				添加
			</Button>
		</AuthorizedView>,
	];
	const openAddModal = () => {
		setUserType1('临时人员');
		form.resetFields();
		//@ts-ignore
		form.setFieldsValue({ userType: '临时人员', status: true });
		setAddTime(new Date().getTime().toString());
		setStartTime(moment());
		setExpireTime(moment());
		setAdd(true);
		setVisble(true);
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const checkMobileNumber = (mobile: any) => {
		if (!mobile) {
			return false;
		}
		let pattern = /^1[3456789]\d{9}$/;
		return pattern.test(mobile);
	};

	const isLicenseNo = (str: any) => {
		var regExp = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[A-Z])|([A-Z]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;
		if (!regExp.test(str)) {
			return false;
		} else {
			console.log('车牌号正确');
			return true;
		}
	};

	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			if (!isLicenseNo(data.carNumber)) {
				message.warning('车牌号格式不对');
				return;
			}
			if (data.userPhone !== undefined && data.userPhone !== null && data.userPhone !== '') {
				if (!checkMobileNumber(data.userPhone)) {
					message.warning('手机号格式不对');
					return;
				}
			}
			if (data.userIdNum !== undefined && data.userIdNum !== null && data.userIdNum !== '') {
				data.userIdNum = data.userIdNum.toString().replace(/\s*/g, '').toUpperCase();
				if (data.userIdNum.length != 18) {
					message.warning('身份证号格式不对');
					return;
				}
			}

			const formData: any = {
				userType: data.userType,
				carNumber: data.carNumber,
				userName: data.userName,
				userPhone: data.userPhone,
				userUuid: data.userUuid,
				note: data.note,
				status: data.status,
				carPathUuid: data.carPathUuid.toString(),
				userIdNum: data.userIdNum,
				carType: data.carType,
				carNature: data.carNature,
				jiaShiZhengNum: data.jiaShiZhengNum,
				carHolder: data.carHolder,
				suspendTime: data.suspendTime
			};
			if (data.userType == '访客' || data.userType === '临时人员') {
				if (startTime != undefined && startTime != null && startTime != '') {
					formData.startTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
				}
				if (expireTime != undefined && expireTime != null && expireTime != '') {
					formData.expireTime = moment(expireTime).format('YYYY-MM-DD HH:mm:ss');
				}
			}
			if (!data.status) {
				formData.suspendTime = moment(formData.suspendTime).format('YYYY-MM-DD 23:59:59');
			}

			setLoading(true);
			const res = await addCar(formData);
			setLoading(false);
			if (res.err === 0) {
				message.success('添加成功');
				setVisble(false);
				actionRef.current.reload();
			} else if (res.err == -2) {
				message.warning(res.msg);
			} else {
				message.error('添加失败');
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			if (data.userIdNum !== undefined && data.userIdNum !== null && data.userIdNum !== '') {
				data.userIdNum = data.userIdNum.toString().replace(/\s*/g, '').toUpperCase();
				if (data.userIdNum.length != 18) {
					message.warning('身份证号格式不对');
					return;
				}
			}
			if (data.userType == '访客' || data.userType == '临时人员') {
				if (updateStartTime === undefined || updateStartTime === '' || updateStartTime == null) {
					message.warning('请选择开始时间');
					return;
				}
				if (updateEndTime === undefined || updateEndTime === '' || updateEndTime === null) {
					message.warning('请选择截止时间');
					return;
				}
			}
			if (!data.status) {
				data.suspendTime = moment(data.suspendTime).format('YYYY-MM-DD 23:59:59');
			}
			const formData = {
				userType: data.userType,
				carNumber: data.carNumber,
				userName: data.userName,
				userPhone: data.userPhone,
				userUuid: data.userUuid,
				uuid: data.uuid,
				startTime: moment(updateStartTime).format('YYYY-MM-DD HH:mm:ss'),
				expireTime: moment(updateEndTime).format('YYYY-MM-DD HH:mm:ss'),
				note: data.note,
				status: data.status,
				carPathUuid: data.carPathUuid.toString(),
				userIdNum: data.userIdNum,
				carType: data.carType,
				carNature: data.carNature,
				jiaShiZhengNum: data.jiaShiZhengNum,
				carHolder: data.carHolder,
				carNo: data.carNo,
				suspendTime: data.suspendTime

			};
			setLoading(true);
			const res = await updateCar(formData);
			setLoading(false);
			if (res.err === 0) {
				message.success('修改成功');
				setVisble(false);
				actionRef.current.reload();
			} else if (res.err == -2) {
				message.error(res.msg);
			} else {
				message.warning('修改失败');
			}
		});
	};
	const normalize = (value: string) => {
		console.log('value::::', value);
		let data: any = {
			carNumber: value.toUpperCase(),
		};
		form.setFieldsValue(data);
	};
	const formItemLayout = {
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 24 },
		},
	};
	return (
		<>
			<GSTable
				rowKey={'id'}
				columns={columns}
				rowSelection={{
					fixed: true,
					columnWidth: 60,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setSelectedRowKeys(selectedRowKeys);
						setQuanXuan(false);
					},
					selections: [
						{
							key: "SELECT_CURRENT_ALL",
							text: "全选当前页面",
							onSelect: (changableRowKeys: any) => {
								setQuanXuan(false);
								setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
							}
						},
						{
							key: "SELECT_ALL",
							text: "全选全部页面",
							onSelect: (changableRowKeys: any) => {
								setQuanXuan(true);
								setSelectedRowKeys(changableRowKeys);
							}
						}
					]
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
											setQuanXuan(false);
										}}
									>
										取消选择
									</a>
								</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log("tableAlertOptionRender::::", selectedRows);
					return (
						<AuthorizedView needAuthority={'exprot_car_list'}>
							<Space size={16}>
								<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							</Space>
						</AuthorizedView>

					);
				}}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				actionRef={actionRef}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getCarList({
						page: params.current,
						size: params.pageSize,
						search: search,
						userType: userType,
						status: status,
						departmentUuid:(departmentUuid || []).toString()
					});
					if (res.err === 0) {
						if (quanXuan) {
							let uuids: any = [];
							res.data.map((item: any) => {
								uuids.push(item.uuid);
							});
							setSelectedRowKeys(uuids);
							setTotal(res.total);
						}
						setTotal(res.total);
						return {
							data: res.data,
							total: res.total,
							success: true,
						};
					}
					return {
						data: [],
						total: 0
					};
				}}
			/>
			<Modal
				title={add ? '添加' : '修改'}
				onOk={saveItem}
				confirmLoading={loading}
				visible={visible}
				width={1100}
				onCancel={() => {
					setVisble(false);
				}}
			>
				<ProxyForm layout="vertical" form={form}>
					<FormItem hidden={true} name={'uuid'}>
						<Input />
					</FormItem>
					<FormItem hidden={true} name={'carNo'}>
						<Input />
					</FormItem>

					<h3>基本信息</h3>
					<Row className="margin-top--10" gutter={12} justify={'start'}>
						<Col span={8}>
							<FormItem name={'userType'} {...formItemLayout} label={'类型'} rules={[{ required: true }]}>
								<Select
									style={{ width: '100%' }}
									onChange={(value: any) => {
										setUserType1(value);
										// @ts-ignore
										form.setFieldsValue({ startTime: '', endTime: '' });
										setUpdateStartTime('');
										setUpdateEndTime('');
									}}
								>
									<Option value={'员工'}> 固定员工</Option>
									<Option value={'访客'}> 访客</Option>
									<Option value={'临时人员'}> 临时人员</Option>
								</Select>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'状态'} name={'status'} valuePropName={'checked'}>
								<Switch unCheckedChildren={'禁止'} checkedChildren={'正常'} onChange={(e) => {setCarStatus(e)}}/>
							</FormItem>
						</Col>
						<Col span={8}>
							{
								!carStatus && <FormItem {...formItemLayout} label={'暂停到：'} rules={[{required: true, message: '请填写禁止到什么时间'}]} name={'suspendTime'}>
									<DatePicker
										style={{ width: '100%' }}
										format={'YYYY-MM-DD'}
									></DatePicker>
								</FormItem>
							}
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={12} justify={'start'}>
						<Col span={8}>
							<FormItem
								{...formItemLayout}
								name={'carNumber'}
								label={'车牌号'}
								rules={[{ required: true }]}
							>
								<Input onBlur={(e: any) => normalize(e.target.value)} />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem
								{...formItemLayout}
								label={'车道'}
								name={'carPathUuid'}
								rules={[{ required: true }]}
							>
								<Select style={{ width: '100%' }} mode="tags" showSearch optionFilterProp="children">
									{carPathList.length > 0
										? carPathList.map((item: any) => {
												return (
													<Select.Option  value={item.uuid}>
														<Tag color={item.status === true ? 'green' : 'red'}>
															{item.status === true ? '在线' : '离线'}
														</Tag>{' '}
														{item.name}
													</Select.Option>
												);
										  })
										: ''}
								</Select>
							</FormItem>
						</Col>
						<Col span={8} hidden={userType1 === '员工' || add}>
							<FormItem {...formItemLayout} label={'开始时间'} name={'startTime'}>
								<DatePicker
									value={updateStartTime}
									onChange={(date: any, dateString: any) => {
										setUpdateStartTime(dateString);
									}}
									style={{ width: '100%' }}
									format={'YYYY-MM-DD'}
								></DatePicker>
							</FormItem>
						</Col>
						<Col span={8} hidden={userType1 === '员工' || add}>
							<FormItem {...formItemLayout} label={'截至时间'} name={'endTime'}>
								<DatePicker
									value={updateEndTime}
									onChange={(date: any, dateString: any) => {
										setUpdateEndTime(dateString);
									}}
									style={{ width: '100%' }}
									format={'YYYY-MM-DD'}
								></DatePicker>
							</FormItem>
						</Col>
						<Col span={8} hidden={userType1 === '员工' || !add}>
							<FormItem {...formItemLayout} label={'有效时间'} name={'time'}>
								<div style={{ width: '100%' }}>
									<FangKePicker
										key={addTime}
										onChange={(_startTime: any, _endTime: any) => {
											setStartTime(_startTime);
											setExpireTime(_endTime);
										}}
										startTime={startTime}
										endTime={expireTime}
										type={add}
									/>
								</div>
							</FormItem>
						</Col>
						<Col span={8} hidden={userType1 === '访客'}>
							<FormItem rules={[{required:userType1 != '访客'}]} {...formItemLayout} name={'userUuid'} label={'用户'}>
								<TreeSelect
									showSearch
									allowClear
									treeData={empUserList}
									treeNodeFilterProp="title"
									searchPlaceholder="请选择"
									dropdownStyle={{ overflow: 'auto' }}
								/>
							</FormItem>
						</Col>
						<Col span={8} hidden={userType1 === '员工' || userType1 === '临时人员'}>
							<FormItem {...formItemLayout} rules={[{required:userType1 == '访客'}]}  name={'userName'} label={'姓名'}>
								<Input />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} name={'carHolder'} label={'车辆所有人姓名'}>
								<Input />
							</FormItem>
						</Col>
						<Col span={8}   hidden={userType1 === '员工' || userType1 === '临时人员'}>
							<FormItem {...formItemLayout} rules={[{required:userType1 == '访客'}]} name={'userPhone'} label={'电话'}>
								<Input style={{width:'100%'}} />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} name={'userIdNum'} label={'身份证号'}>
								<Input />
							</FormItem>
						</Col>
					</Row>
					<h3>更多信息</h3>

					<Row className="margin-top--10" gutter={12} justify={'start'}>
						<Col span={8}>
							<FormItem {...formItemLayout} name={'carNature'} label={'车辆性质'}>
								<Select style={{ width: '100%' }}>
									<Option value={'私车'}> 私车</Option>
									<Option value={'公车'}> 公车</Option>
								</Select>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} name={'jiaShiZhengNum'} label={'驾驶证档案编号'}>
								<InputNumber style={{ width: '100%' }} />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} name={'carType'} label={'车辆品牌'}>
								<Input />
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" justify={'start'}>
						<Col span={16}>
							<FormItem {...formItemLayout} label={'备注'} name={'note'}>
								<Input.TextArea placeholder="请输入备注" />
							</FormItem>
						</Col>
					</Row>
				</ProxyForm>
			</Modal>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(CarManager);
