import React from 'react';
import ImgCrop from 'antd-img-crop';
import { message, Upload } from 'antd';
import Api from '../data/API';
import { PlusOutlined } from '@ant-design/icons';


interface UploadImgParams{
	hiddenImgCorp:any
	widthRatio?:number
	heightRatio?:number
	uploadParams:any
	getFileName:any
	photo:any
	action:any
}
const UploadImg:React.FC<UploadImgParams> = (props:UploadImgParams) =>{
	const {hiddenImgCorp,widthRatio,heightRatio,uploadParams,photo,action}:any = props

	const getFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			if(fileList.file.response.err !== 0){
				fileList.file.status = 'error';
				message.warning(fileList.file.response.msg);
			}else{
				props.getFileName(fileList.file.response.url)
				console.log(`${fileList.file.name}`);
			}
		} else if (fileList.file.status === 'error') {
			message.error('上传图片失败');
		}
	};
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	return<>
		{!hiddenImgCorp ?
			<ImgCrop quality={1} aspect={widthRatio / heightRatio} rotate>
				<Upload
					name="image"
					listType="picture-card"
					className="avatar-uploader"
					showUploadList={false}
					data={uploadParams}
					action={action== "public"?Api.apiRootPath + '/api/picture/upload_qiniu_two':''}
					onChange={getFileName}
					accept={'.jpg,.png'}
				>
					{photo !== null && photo !== undefined && photo !== '' ? (
						<img alt="avatar" style={{ width: '100%' }} src={Api.cdnRootPath + photo} />
					) : (
						uploadButton
					)}
				</Upload>
			</ImgCrop> :
			<Upload
				name="image"
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				data={uploadParams}
				action={action== "public"?Api.apiRootPath + '/api/picture/upload_qiniu_two':''}
				onChange={getFileName}
				accept={'.jpg,.png'}
			>
				{photo !== null && photo !== undefined && photo !== '' ? (
					<img alt="avatar" style={{ width: '100%' }} src={Api.cdnRootPath + photo} />
				) : (
					uploadButton
				)}
			</Upload>
		}
	</>
}
export default UploadImg