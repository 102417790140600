import * as React from 'react';

import DAL from '../../data/DAL';

import KaimenList from './KaimenList';
import BasicFacts from './BasicFacts';
import NumberAdded from './NumberAdded';
import { Menu } from 'antd';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';

class Statistics extends React.Component {
	deviceId: string = '';
	dal = DAL.dal;

	state: any;
	constructor(props: any) {
		super(props);
		this.state = { ds: [], url: window.location.pathname, current: 'basic-facts' };
		// const serch = props.location.search;
	}

	handleClick = (e: any) => {
		console.log('click ', e);
		this.setState({
			current: e.key,
		});
	};

	render() {
		return (
			<div>
				<Menu
					onClick={this.handleClick}
					selectedKeys={[this.state.current]}
					mode="horizontal"
					style={{ textAlign: 'left' }}
				>
					<Menu.Item key="basic-facts">
						<NavLink className={'TextDecora'} to={'/statistics/basic-facts'}>
							整体概况
						</NavLink>
					</Menu.Item>
					<Menu.Item key="shuju-tongji">
						<NavLink className={'TextDecora'} to={'/statistics/shuju-tongji'}>
							开门统计
						</NavLink>
					</Menu.Item>
					<Menu.Item key="number-added">
						<NavLink className={'TextDecora'} to={'/statistics/number-added'}>
							新用户统计
						</NavLink>
					</Menu.Item>
				</Menu>
				<div className="content-big">
					<Switch>
						<Route path="/statistics/basic-facts" component={BasicFacts} />
						<Route path="/statistics/shuju-tongji" component={KaimenList} />
						<Route path="/statistics/number-added" component={NumberAdded} />
						<Redirect to="/statistics/basic-facts" />
						<Route render={() => <BasicFacts />} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default Statistics;

// helpers
