import Api from '../data/API';

//import {} from "../../services/QuestionnaireService";
export async function findQuestionnaireDetails(params: any) {
	return Api.postWithAuth('/api/tuwen/find_questionnaire_details', params);
}

export async function editQuestionnaire(params: any) {
	return Api.postWithAuth('/api/tuwen/edit_questionnaire', params);
}

export async function questionnaireList(params: any) {
	return Api.postWithAuth('/api/tuwen/questionnaire_list', params);
}

export async function questionnaireState(params: any) {
	return Api.postWithAuth('/api/tuwen/questionnaire_state', params);
}

export async function questionnaireData(params: any) {
	return Api.postWithAuth('/api/tuwen/questionnaire_data', params);
}

export async function questionnaireDetail(params: any) {
	return Api.postWithAuth('/api/tuwen/questionnaire_detail', params);
}
