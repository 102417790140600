import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import Api from '../../../data/API';
import { Button, Dropdown, Image, Input, Menu, message, Modal, Select, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
	changeState,
	deleteWenzhang,
	findWenzhangListPc,
	findWenzhangTagList,
	getWenzhangType,
} from '../../../services/WenzhangService';
import GSTable from '../../../components/GSTable';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import appConfig from '../../../appConfig';
const LookWenzhang: React.FC = () => {
	useEffect(() => {
		let page = window.location.href;
		let c = page.split('=');
		let uuid: any = c[1];
		if (uuid !== undefined && uuid !== null && uuid !== '') {
			setType(uuid);
		}
		findWenzhangType();
	}, []);
	const [categoryEnum, setCategoryEnum] = useState([]);
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [wenzhangType, setWenzhangType] = useState([]);
	const [wenzhangTagList, setWenzhangTagList] = useState([]);
	const [type, setType] = useState(undefined);
	const columns = [
		{
			title: '标题',
			dataIndex: 'wenzhangBiaoti',
			key: 'wenzhangBiaoti',
			render: (text: string, record: any) => (
				<a href={appConfig.mobileAppUrl + '/pinglun/tieba?wenzhang_uuid=' + record.uuid} target={'_blank'}>
					{text}
				</a>
			),
		},
		{
			title: '类目',
			key: 'typeUuid',
			dataIndex: 'typeUuid',
			valueEnum: categoryEnum,
		},
		{
			title: '封面图',
			key: 'tupianUrl',
			dataIndex: 'tupianUrl',
			render: (text: string, record: any) => (
				<span>
					{record.tupianUrl === null || record.tupianUrl === '' || record.tupianUrl === undefined ? (
						''
					) : (
						<Image className="homePage" src={Api.cdnRootPath + record.tupianUrl} width={100} height={70} />
					)}
				</span>
			),
		},
		{
			title: '状态',
			key: 'status',
			dataIndex: 'status',
			render: (text: string, record: any) => (
				<Tag color={record.status === '发布' ? 'green' : 'yellow'}>{record.status}</Tag>
			),
		},
		{
			title: '开启评论',
			key: 'commentStatus',
			dataIndex: 'commentStatus',
		},
		{
			title: '全屏模式',
			key: 'isFullscreen',
			dataIndex: 'isFullscreen',
		},
		{
			title: '作者',
			dataIndex: 'fabuRenyuan',
			key: 'fabuRenyuan',
		},
		{
			title: '创建日期',
			key: 'fabuShijian',
			dataIndex: 'fabuShijian',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<Link
								to={`/wenzhang/add_wenzhang?uuid=${getUuids()}&wenzhangUuid=${
									record.uuid
								}&state=update`}
							>
								<a>编辑</a>
							</Link>
						</li>
						<li>
							<Dropdown
								overlay={
									<Menu>
										<Menu.Item>
											<a
												onClick={() => {
													ChangeState(record);
												}}
											>
												{record.status === '发布' ? '下线' : '发布'}
											</a>
										</Menu.Item>
										<Menu.Item>
											<Link
												to={`/wenzhang/wenzhang_info?uuid=${
													record.uuid
												}&state=wenzhang&wenZhangType=${getUuids()}`}
											>
												评论
											</Link>
										</Menu.Item>
										<Menu.Item>
											<a
												onClick={() => {
													deleteItem(record);
												}}
											>
												删除
											</a>
										</Menu.Item>
									</Menu>
								}
							>
								<a style={{ textDecoration: 'none' }} href="#">
									更多
								</a>
							</Dropdown>
						</li>
					</ul>
				</span>
			),
		},
	];

	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '删除资讯',
			icon: <ExclamationCircleOutlined />,
			content: '您确定删除吗',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteWenzhang({ uuid: value.uuid });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error('删除失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const findWenzhangType = async () => {
		const res = await getWenzhangType({});
		console.log('res:::', res);
		let cate: any = [];

		if (res.err === 0) {
			setWenzhangType(res.data);
			res.data.map((item: any) => {
				cate[item.uuid] = item.name;
			});
			setCategoryEnum(cate);
		}
	};

	const ChangeState = (value: any) => {
		Modal.confirm({
			title: '提示',
			content: `您确定要${value.status === '发布' ? '下线' : '发布'}该文章?`,
			onOk: async () => {
				const res = await changeState({
					uuid: value.uuid,
					status: value.status === '发布' ? '草稿' : '发布',
				});

				if (res) {
					message.success('操作成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error(res.msg || '操作失败');
				}
			},
		});
	};

	const tableSearchBarRender = () => [
		<Space>
			<Select
				placeholder="类目"
				style={{ width: 150 }}
				allowClear
				onChange={(value: any) => {
					setType(value);
					actionRef.current.reloadAndRest();
				}}
			>
				{wenzhangType.length > 0
					? wenzhangType.map((item: any) => {
							return <Select.Option value={item.uuid}>{item.name}</Select.Option>;
					  })
					: ''}
			</Select>
			<Input
				placeholder="搜索"
				onChange={(event) => {
					setSearch(event.target.value);

				}}
			/>
			<Button type={"primary"} onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}>查询</Button>
		</Space>,
	];
	const getUuids = () => {
		const page = window.location.search;
		const c = page.split('=');
		return c[1];
	};
	const tableOperationsBarRender = () => [
		<Link to={`/wenzhang/add_wenzhang?uuid=${getUuids()}&state=add`}>
			<Button type={'primary'}>创建</Button>
		</Link>,
	];

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await findWenzhangListPc({
						typeUuid: type,
						Search: search,
						page: params.current,
						size: params.pageSize,
					});
					// console.log("wenZhangType::",res.wenZhangType)
					// setWenzhangType(res.wenZhangType)
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default LookWenzhang;
