import React, { useEffect } from 'react';
import { TreeSelect } from "antd";
const GsTreeSelect = (props: any) => {


    return <>
        <TreeSelect
            {...props}
            maxTagCount={props.maxTagCount || 1}
            treeCheckable={props.treeCheckable === undefined ?  true : props.treeCheckable}
            style={props.style || { width: 200 }}
            placeholder={props.placeholder || '全部设备'}
            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            treeData={props.treeData}
            multiple={props.multiple}
            allowClear={props.allowClear || true}
            treeNodeFilterProp={props.treeNodeFilterProp || 'title'}
            showSearch={props.showSearch || true}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
        />
    </>
}
export default GsTreeSelect;
