import React from 'react';
import moment from "moment";
import {DatePicker} from "antd";

interface CustomDateFormItemProps {
    disabled?: boolean;
    allowClear?: boolean;
    style?: any;
    value?: string;
    inputReadOnly?: boolean;
    onChange?: (value: string) => void;
    showTime?: any;
    format?: string;
}

const CustomDateFormItem: React.FC<CustomDateFormItemProps> = (props: any) => {
    const triggerChange = (value?: string) => {
        props.onChange?.(value || '');
    };
    const onChange = (date: any, dateString: string) => {
        if (props.showTime) {
            if (dateString) {
                if (dateString.length === 19) {
                    triggerChange(dateString)
                } else {
                    triggerChange(dateString + ':00')
                }

            } else {
                triggerChange(dateString)
            }

        } else {
            triggerChange(dateString)

        }
    };
    const dateValue = () => {
        console.log('自定义表单组件value::::::', props.value)
        if (props.value) {
            return moment(props.value, 'YYYY-MM-DD HH:mm:ss')
        }
        return undefined
    };
    return (
        <DatePicker
            id={props.id}
            defaultValue={dateValue()}
            style={props.style}
            format={props.format}
            showTime={props.showTime}
            allowClear={props.allowClear || false}
            inputReadOnly={props.inputReadOnly}
            disabled={props.disabled || false}
            onChange={onChange}
        />
    );
};
export default CustomDateFormItem;
