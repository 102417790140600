import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import Group from './pages/Group';
import GroupUser from './compontents/GroupUser';
import GroupTieZi from './compontents/GroupTieZi';
import LookTieba from '../postBar/compontents/LookTieba';
import TieziXiangQing from '../wenzhang/pages/TieziXiangQing';
import GroupTieziXiangQing from './compontents/GroupTieziXiangQing';
import WenZhangInfo from '../wenzhang/components/WenZhangInfo';

export default function GroupMiniApp() {
	const rootUrl = '/group';
	const menus: MiniAppMenu[] = [
		{
			authority: 'group_management:qunzu',
			name: '群组管理',
			path: 'find-qunzu-name',
			component: Group,
		},
	];
	const routes: MiniAppRoute[] = [
		{
			path: 'find-qunzu-name',
			component: Group,
		},
		{
			path: 'group-list',
			component: Group,
		},
		{
			path: 'add-group',
			component: Group,
		},
		{
			path: 'change-role',
			component: GroupUser,
		},
		{
			path: 'group-user',
			component: GroupUser,
		},
		{
			path: 'group-tie-zi',
			component: GroupTieZi,
		},
		{
			path: 'find-user-eamil',
			component: GroupUser,
		},
		{
			path: 'find_tiezi_list',
			component: LookTieba,
		},
		{
			path: 'find_tiezi_details',
			component: TieziXiangQing,
		},
		{
			path: 'group-tiezi-xiangqing',
			component: GroupTieziXiangQing,
		},
		{
			path: 'wenzhang_info',
			component: WenZhangInfo,
		},
	];

	return <MiniApp miniAppName={'群组'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
