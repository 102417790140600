import React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import ItemInfoList from './page/ItemInfoList';

import RoomItemRecordList from './page/RoomItemRecordList';
import RoomItemList from './page/RoomItemList';
import ItemSetting from './page/ItemSetting';
import RoomItemReport from './page/RoomItemReport';
import CleanItemList from '../assets/pages/CleanItemList';
import ItemTypeList from './page/ItemTypeList';

const HomeInspectionMiniApp: React.FC =() => {
	const rootUrl = '/inspection';
	const menus: MiniAppMenu[] = [
		{
			authority: 'assets_management:room_item_list',
			name: '物品清单',
			path: 'room_item_list',
			component: RoomItemList,
		},
		{
			authority: 'assets_management:item_type_management',
			name: '物品类目',
			path: 'item_type_list',
			component: ItemTypeList,
		},
		{
			authority: 'assets_management:item_info_management',
			name: '物品设置',
			path: 'item_info_list',
			component: ItemInfoList,
		},
		{
			authority: 'assets_management:room_item_record_list',
			name: '物品验房记录',
			path: 'room_item_record_list',
			component: RoomItemRecordList,
		},
		{
			authority: 'fang_jian_wupin_shezhi',
			name: '房间物品设置',
			path: 'item-settings',
			component: ItemSetting,
		},
		{
			authority: 'fang_jian_tong_ji_bao_biao',
			name: '物品统计报表',
			path: 'room-item-report',
			component: RoomItemReport,
		},
		{
			authority: 'assets_management:clean_item',
			name: '保洁标准',
			path: 'clean_item',
			component: CleanItemList,
		},
	];

	const routes: MiniAppRoute[] = [
	];

	return <MiniApp miniAppName={'验房管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
export default HomeInspectionMiniApp;
