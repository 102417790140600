import React, {useEffect} from 'react';
import MiniApp, {MiniAppMenu} from "../../util/MiniApp";
import TrafficEventList from "./pages/TrafficEventList";
const TrafficEventMiniApp: React.FC = () => {
    const rootUrl = '/traffic_event';
    useEffect(() => {
    }, [])
    const menus: MiniAppMenu[] = [
        {
            authority: 'trafficEvent:list',
            name: '事件列表',
            path: 'list',
            component: TrafficEventList,
        },

    ];

    return <MiniApp miniAppName={'交通事件'} rootUrl={rootUrl} menus={menus} />;
}
export default TrafficEventMiniApp;
