import appConfig from '../appConfig';
import axios from 'axios';
import { message } from 'antd';
let apiRootPath = appConfig.apiRootPath;
let msg = require('../util/SuspensionMsg');

class Api {
	static api = new Api();
	userId: any;
	pd: any;
	token: any;
	companyUuid: any;
	wuyeUuid: any;
	menuPermission: any;
	buttonPermisssion: any;
	propertyApplicationName: any;
	bankApplicationName: any;
	keyApplicationName: any;
	source: any;
	public apiRootPath = appConfig.apiRootPath;
	public cdnRootPath = appConfig.cdnRootPath;
	public huodongPath = appConfig.huoDongPath;

	constructor() {
		console.log('运行环境：', process.env.NODE_ENV, appConfig);
		this.userId = localStorage.getItem('userId');
		this.pd = localStorage.getItem('password');
		this.token = localStorage.getItem('token');
		this.companyUuid = localStorage.getItem('companyUuid');
		this.wuyeUuid = localStorage.getItem('wyUuid');
		this.propertyApplicationName = 'property_notice';
		this.bankApplicationName = 'bank_notice';
		this.keyApplicationName = 'key_notice';
		this.source = '腾讯云';
	}

	public postPublicUrl(url: any, params: {}) {
		return new Promise<any>((resolve, reject) => {
			axios
				.post(url, params, {})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
					message.error('请求超时');
				});
		});
	}

	public getPublicUrl(url: any, params: {}) {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(url, params)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
					message.error('请求超时', err);
				});
		});
	}

	public post(uri: String, params: {}) {
        let state = this.getLoginState();
        if (
            !state &&
            !window.location.pathname.includes('/fangtai-embed') &&
            !window.location.pathname.includes('/preview.html') &&
            !window.location.pathname.includes('/wy/login')
        ) {
            if (window.location.pathname !== '/wy/login') {
                window.location.href = '/wy/login';
            }
            return new Promise<any>((resolve, reject) => {
                resolve({err: -1, msg: '登录过期'});
            });
        }
		let url = apiRootPath + uri;
		return new Promise<any>((resolve, reject) => {
			axios
				.post(url, params)
				.then((res) => {
					resolve(res.data);
					if (res.data.err === 401) {
						alert('未登录');
						localStorage.clear();
						window.location.href = '/wy/login';
					} else if (res.data.err === 403) {
						alert('没有权限');
					}
					console.log(res);
				})
				.catch((err) => {
					reject(err.data);
					message.error('请求超时');
					console.log(err);
				});
		});
	}

	public postWithAuth(uri: String, params: any) {
        let state = this.getLoginState();
        if (
            !state &&
            !window.location.pathname.includes('/fangtai-embed') &&
            !window.location.pathname.includes('/preview.html') &&
            !window.location.pathname.includes('/wy/login')	&&
			!window.location.pathname.includes('/tongxing-jiankong')
		) {
            if (window.location.pathname !== '/wy/login') {
                window.location.href = '/wy/login';
            }
            return new Promise<any>((resolve, reject) => {
                resolve({err: -1, msg: '登录过期'});
            });
        }
		let url = apiRootPath + uri;
		params.access_token = this.token;
		params.proj_id = localStorage.getItem('wyUuid');
		params.wuyeUuid = localStorage.getItem("wyUuid")
		return new Promise<any>((resolve, reject) => {
			axios
				.post(url, params)
				.then((res) => {
					resolve(res.data);
					if (res.data.err === 401) {
						localStorage.clear();
						window.location.href = '/wy/login';
					} else if (res.data.err === 403) {
						message.error('没有权限');
					}
					console.log(res);
					console.log(res.data);
				})
				.catch((err) => {
					resolve({
						err: -1,
						msg: '请求出错, 稍后再试',
					});
					reject(err.data);
					message.error('请求超时');
					console.log(err);
					return err;
				});
		});
	}

	public postDownloadWithAuth(uri: String, params: any) {
		let state = this.getLoginState();
		if (
			!state &&
			!window.location.pathname.includes('/fangtai-embed') &&
			!window.location.pathname.includes('/preview.html') &&
			!window.location.pathname.includes('/wy/login')	&&
			!window.location.pathname.includes('/tongxing-jiankong')
		) {
			if (window.location.pathname !== '/wy/login') {
				window.location.href = '/wy/login';
			}
			return new Promise<any>((resolve, reject) => {
				resolve({err: -1, msg: '登录过期'});
			});
		}
		let url = apiRootPath + uri;
		params.access_token = this.token;
		params.proj_id = localStorage.getItem('wyUuid');
		return new Promise<any>((resolve, reject) => {
			axios
				.post(url, params, {
					responseType: 'blob'
				})
				.then((res) => {
					resolve(res.data);
					if (res.data.err === 401) {
						localStorage.clear();
						window.location.href = '/wy/login';
					} else if (res.data.err === 403) {
						message.error('没有权限');
					}
					console.log(res);
					console.log(res.data);
				})
				.catch((err) => {
					resolve({
						err: -1,
						msg: '请求出错, 稍后再试',
					});
					reject(err.data);
					message.error('请求超时');
					console.log(err);
					return err;
				});
		});
	}

	public getWithAuth(uri: String, params: any) {
        let state = this.getLoginState();
        if (
            !state &&
            !window.location.pathname.includes('/fangtai-embed') &&
            !window.location.pathname.includes('/preview.html') &&
			!window.location.pathname.includes('/wy/login')	&&
			!window.location.pathname.includes('/tongxing-jiankong')
        ) {
            if (window.location.pathname !== '/wy/login') {
                window.location.href = '/wy/login';
            }
            return new Promise<any>((resolve, reject) => {
                resolve({err: -1, msg: '登录过期'});
            });
        }
		let url = this.cdnRootPath + uri;
		return new Promise<any>((resolve, reject) => {
			axios
				.get(url, params)
				.then((res) => {
					resolve(res.data);
					console.log(res);
					console.log(res.data);
				})
				.catch((err) => {
					reject(err.data);
					message.error('请求超时');
					console.log(err);
				});
		});
	}

	/**
	 * 设置用户名密码
	 */
	public setUserState(
		userId: string,
		password: string,
		token: string,
		wyYeShortName: string,
		wuyeSlogan: string,
		wyUuid: string,
		companyUuid: string
	) {
		localStorage.setItem('userId', userId);
		localStorage.setItem('password', password);
		localStorage.setItem('token', token);
		localStorage.setItem('wyYeShortName', wyYeShortName);
		localStorage.setItem('logintime', new Date().getTime().toString());
		localStorage.setItem('wuyeSlogan', wuyeSlogan);
		localStorage.setItem('wyUuid', wyUuid);
		localStorage.setItem('companyUuid', companyUuid);

		this.userId = userId;
		this.pd = password;
		this.token = token;
		this.companyUuid = companyUuid;
		this.wuyeUuid = wyUuid;
	}

	public setWyNameAndSlogan(wyYeShortName: string, wuyeSlogan: string) {
		localStorage.setItem('wyYeShortName', wyYeShortName);
		localStorage.setItem('wuyeSlogan', wuyeSlogan);
	}

	public changeAfter(wyUuid: string, companyUuid: string) {
		localStorage.setItem('wyUuid', wyUuid);
		localStorage.setItem('companyUuid', companyUuid);
		this.companyUuid = companyUuid;
		this.wuyeUuid = wyUuid;
	}

	/**
	 * 是否登陆
	 */
	public getLoginState(): boolean {
		let endTime1 = new Date().getTime();
		let loginTime = localStorage.getItem('logintime');
		let free = localStorage.getItem('free');
		console.log('是否免登' + free + !!free);
		console.log('登录时间' + loginTime);
		let expirationTime = 2592000000;
		if ('true' !== free) {
			console.log('没有免登');
			expirationTime = 7200000;
			localStorage.setItem('logintime', endTime1.toString());
		}
		console.log('时间' + expirationTime);
		if (endTime1 - Number(loginTime) > expirationTime) {
			console.log('登陆过期', endTime1 - Number(loginTime));
			localStorage.clear();
			localStorage.setItem("free", free || 'false')
			return false;
		}
		return this.userId != null && this.pd != null && this.token != null;
	}

	/**
	 * 保存审核人的缓存数据
	 */
	public getUserInfo() {
		let token = localStorage.getItem('token');

		const data = new FormData();

		data.append('user_id', this.userId);

		data.append('password', this.pd);

		data.append('access_token', token as string);

		return fetch(apiRootPath + '/api/wuye/get_user_info', {
			method: 'post',
			body: data,
		});
	}

	getAccessToken(): String {
		return this.token;
	}

	getUserId(): String {
		return this.userId;
	}

	getCompanyUuid(): string {
		return this.companyUuid;
	}
	getWuyeUuid(): string {
		return this.wuyeUuid;
	}
}

let ApiImp = new Api();
export default ApiImp;
