import React, { useEffect, useRef, useState } from "react";

import { Button, Input, Select, Space } from "antd";
import { ActionType } from "@ant-design/pro-table";
import GSTable from "../../../components/GSTable";
import { getAdNoticeList, getAdNoticeRecord, getNoNoticeUser } from "../../../services/notificationService";
import DAL from "../../../data/DAL";

let moment = require("moment");
let msg = require("../../../util/SuspensionMsg");

const Search = Input.Search;

interface AdNoticeRecordProps {
    uuid: string;
    flag: boolean;
}

const AdNoticeRecord: React.FC<AdNoticeRecordProps> = (props: any) => {
    useEffect(() => {
        if (isNotBlack(props.uuid)) {
            setcolumns(columns3);
        }
        getAdNotice();
    }, []);
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState("");
    const [type, setType] = useState("已接收");
    const [userData, setUserData] = useState([]);
    const [adList, setAdList] = useState([]);
    const [adNoticeUuid, setAdNoticeUuid] = useState("");
    const Option = Select.Option;
    const columns1 = [
        {
            title: "接收时间",
            key: "create_time",
            dataIndex: "create_time",
            hideInSearch: props.uuid !== undefined && props.uuid !== "" && props.uuid !== null,
            render: (text: any, item: any) => <span>{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</span>
        },
        {
            title: "接收账号",
            key: "login_id",
            dataIndex: "login_id"
        },
        {
            title: "接收内容",
            width: "40%",
            key: "text",
            hideInSearch: props.uuid !== undefined && props.uuid !== "" && props.uuid !== null,
            dataIndex: "text"
        }
    ];
    const columns3 = [
        {
            title: "账号",
            key: "login_id",
            dataIndex: "login_id"
        },
        {
            title: "接收时间",
            key: "create_time",
            dataIndex: "create_time",
            hideInSearch: props.uuid !== undefined && props.uuid !== "" && props.uuid !== null,
            render: (text: any, item: any) => <span>{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</span>
        },
        {
            title: "接收内容",
            width: "40%",
            key: "text",
            hideInSearch: props.uuid !== undefined && props.uuid !== "" && props.uuid !== null,
            dataIndex: "text"
        }
    ];
    const columns2 = [
        {
            title: "接收账号",
            key: "loginId",
            dataIndex: "loginId"
        }
    ];
    const [columns, setcolumns] = useState(columns1);
    const getAdNotice = async () => {
        const res = await getAdNoticeList({ text: "" });
        console.log("resAdNoticeList::", res);
        if (res.err === 0) {
            setAdList(res.data);
        }
    };
    const tableSearchBarRender = () => [
        <Space>
            {props.flag && (
                <Select
                    onChange={(value: any) => {
                        setType(value);
                        if (value === "已接收") {
                            setcolumns(columns3);
                        } else {
                            setcolumns(columns2);
                        }
                        actionRef.current.reloadAndRest();
                    }}
                    defaultValue={"已接收"}
                >
                    <Option value={"已接收"}>已接收</Option>
                    <Option value={"未接收"}>未接收</Option>
                </Select>
            )}
            <Input
                placeholder="账号"
                onChange={(event) => {
                    setSearch(event.target.value);

                }}
            />
            <Button type={"primary"} onClick={() => {
                if (actionRef.current) {
                    actionRef.current.reloadAndRest();
                }
            }}>查询</Button>
        </Space>
    ];
    const isNotBlack = (string: any) => {
        if (string !== undefined && string !== null && string !== "") {
            return true;
        } else {
            return false;
        }
    };
    const getData = async (params: any) => {
        if (isNotBlack(props.uuid) && props.flag === true) {
            if (type === "已接收") {
                const res = await getAdNoticeRecord({
                    page: params.current,
                    size: params.pageSize,
                    phone: search,
                    uuid: props.uuid
                });
                return {
                    data: res.data,
                    total: res.total,
                    success: true
                };
            } else {
                let data = {
                    uuid: props.uuid
                };
                const res = await getNoNoticeUser(data);
                if (res.err === 0) {
                    setUserData(res.data);
                } else {
                    msg.suspensionMsg({ content: res.msg });
                }
                let data1;
                if (isNotBlack(search)) {
                    data1 = res.data.filter(function(x: any) {
                        return x.loginId.includes(search);
                    });
                } else {
                    data1 = res.data;
                }

                return {
                    data: data1,
                    total: res.count,
                    success: true
                };
            }
        } else {
            const res = await getAdNoticeRecord({
                page: params.current,
                size: params.pageSize,
                phone: search,
                uuid: adNoticeUuid
            });
            return {
                data: res.data,
                total: res.total,
                success: true
            };
        }
    };
    return (
        <div>
            <GSTable
                rowKey={(record: any) => {
                    return JSON.stringify(record);
                }}
                columns={columns}
                actionRef={actionRef}
                searchBarRender={tableSearchBarRender()}
                request={async (params: any = {}, sort: any, filter: any) => {
                    return getData(params);
                }}
            />
        </div>
    );
};
export default AdNoticeRecord;
