import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { findSmsRecord, findSmsSendBatchList } from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Modal, Tag } from 'antd';
import SmsDetail from '../components/SmsDetail';
import * as moment from 'moment';

const SmsRecord: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [visible, setVisiable] = useState(false);
	const [batchId, setBatchId] = useState('');
	const [dateKey, setdateKey] = useState(new Date().getTime().toString())
	const columns = [
		{
			title: '发送时间',
			key: 'sendTime',
			width: 200,
			dataIndex: 'sendTime',
			render: (text: string, record: any) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
		},
		{
			title: '模板名称',
			width: 200,
			dataIndex: 'templateName',
			key: 'templateName',
		},
		{
			title: '模板内容',
			dataIndex: 'templateContent',
			key: 'templateContent',
			ellipsis: true,
			width: 300,
		},
		{
			title: '发送次数',
			dataIndex: 'sendCount',
			key: 'sendCount',
			width: 100,
		},
		{
			title: '状态',
			key: 'sendStatus',
			width: 100,
			dataIndex: 'sendStatus',
			tip: '该状态表示调用服务商发送短信是否成功，短信是否接收成功请查看详情',
			render: (text: string, record: any) => <Tag color={'green'}>{text}</Tag>,
		},
		{
			title: '操作',
			key: 'action',
			width: 100,
			fixed: 'right',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						showModal(record);
					}}
				>
					查看详情
				</a>
			),
		},
	];

	const showModal = (value: any) => {
		setdateKey(new Date().getTime().toString())
		setBatchId(value.uuid);
		setVisiable(true);
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findSmsSendBatchList({
						page: params.current,
						size: params.pageSize,
						wuyeUuid: localStorage.getItem('wyUuid'),
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="详情"
				visible={visible}
				onOk={() => {
					setVisiable(false);
				}}
				onCancel={() => {
					setVisiable(false);
				}}
				width={1200}
				footer={null}
			>
				<SmsDetail key={dateKey} batchId={batchId} />
			</Modal>
		</div>
	);
};

export default SmsRecord;
