import React, { useEffect, useRef, useState } from "react";
import { ActionType } from "@ant-design/pro-table";
import { Button, DatePicker, Input, Select, Tag, TreeSelect } from "antd";
import GSTable from "../../../components/GSTable";
import { getAllRecord, getDevices, getEquipmentRecords, getKeyEventNote } from "../../../services/ZhiNengMenJinService";
import CustomRangePicker from "../../../components/CustomRangePicker";
import moment from "moment";

let msg = require("../../../util/SuspensionMsg");
const Search = Input.Search;
const Option = Select.Option;
let { RangePicker } = DatePicker;

const KeyEventNote: React.FC<any> = (props: any) => {
    useEffect(() => {
        // getUserDevice();
    }, []);

    const actionRef = useRef<ActionType>();
    // const [userDevices, setUserDevices] = useState('');
    const [Stime, setStime] = useState(
        moment().subtract(7, "d").hours(0).minute(0).second(0).format("YYYY-MM-DD HH:mm:00")
    );
    const [Etime, setEtime] = useState(moment().hours(23).minute(59).second(59).format("YYYY-MM-DD HH:mm:59"));
    const [search, setSearch] = useState("");
    const [type, setType] = useState(undefined);
    const [userdevice, setUserdevice] = useState([]);

    const columns = [
        {
            title: "设备名称",
            dataIndex: "deviceName"
        },
        {
            title: "用户账号",
            dataIndex: "userAccount"
        },
        {
            title: "用户姓名",
            dataIndex: "userName"
        },
        {
            title: "操作类型",
            dataIndex: "opType"
        },
        {
            title: "事件类型",
            dataIndex: "type"
        },
        {
            title: "原因",
            dataIndex: "event",
            ellipsis: true,
        },
        {
            title: "操作人",
            dataIndex: "operateName"
        },
        {
            title: "状态",
            dataIndex: "read",
            render: (text: any, item: any) => {
                return <Tag color={item.read ? "green" : "blue"}>{item.read ? "已读" : "未读"}</Tag>;
            }
        },
        {
            title: "时间",
            dataIndex: "createdTime",
            valueType: "dateTime"
        }
    ];

    // const getUserDevice = async () => {
    // 	const res = await getDevices({});
    // 	if (res.err !== 0) {
    // 		msg.suspensionMsg({ content: res.msg });
    // 		return;
    // 	}
    // 	setUserdevice(res.data);
    // };

    const dateTime = (startTime: any, endTime: any) => {
        setStime(startTime.format("YYYY-MM-DD HH:mm:00"));
        setEtime(endTime.format("YYYY-MM-DD HH:mm:59"));
    };
    const tableSearchBarRender = () => [
        // <TreeSelect
        // 	style={{ width: 200 }}
        // 	placeholder={'全部设备'}
        // 	dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
        // 	treeData={userdevice}
        // 	allowClear={true}
        // 	multiple={false}
        // 	treeNodeFilterProp={'title'}
        // 	showSearch={true}
        // 	onChange={(value: any) => {
        // 		setUserDevices(value);
        // 	}}
        // />,
        <Select allowClear value={type} onChange={((value: any) => setType(value))} placeholder="事件类型"
                style={{ width: "100%" }}>
            <Select.Option value={"冻结"}>冻结</Select.Option>
            <Select.Option value={"解冻"}>解冻</Select.Option>
        </Select>,
        <CustomRangePicker onChange={dateTime}/>,
        <Input
            placeholder="账号"
            value={search}
            onChange={(e) => {
                setSearch(e.target.value);
            }}
        />,
        <Button
            type="primary"
            onClick={() => {
                actionRef.current.reloadAndRest();
            }}
        >
            查询
        </Button>
    ];

    return (
        <div>
            <GSTable
                columns={columns}
                rowKey={"id"}
                actionRef={actionRef}
                searchBarRender={tableSearchBarRender()}
                request={async (params: any = {}, sort: any, filter: any) => {
                    let data: any = {};
                    if (Stime !== null && Stime !== undefined) {
                        data.startTime = Stime;
                    }
                    if (Etime !== null && Etime !== undefined) {
                        data.endTime = Etime;
                    }
                    if (search !== null && search !== undefined) {
                        data.search = search;
                    }
                    if (type !== null && type !== undefined) {
                        data.type = type;
                    }
                    data.page = params.current;
                    data.size = params.pageSize;
                    data.deviceId = params.deviceId || props.deviceId;
                    let res = await getKeyEventNote(data);
                    if (res.err !== 0) {
                        msg.suspensionMsg({ content: res.msg, type: "error" });
                        return;
                    }
                    return {
                        data: res.data,
                        total: res.total,
                        success: true
                    };
                }}
            />
        </div>
    );
};
export default KeyEventNote;
