import React, {useEffect, useRef, useState} from 'react';
import {
  Input,
  Tag,
  TreeSelect,
  Form,
  Tooltip,
  Button,
  Modal,
  Row,
  Col,
  Image,
  message,
  Select,
  Radio,
  DatePicker,
  Popconfirm,
  Dropdown,
  Menu,
  Switch,
  Space,
  Checkbox,
  Spin,
} from 'antd';
import {connect} from 'dva';
import ConnectState from '../../models/connect';
import {FormInstance} from 'antd/es/form';
import {ActionType} from '@ant-design/pro-table';
import {ReaderFunction} from './js/ReaderFunction';
import {
  deleteEmployee,
  exportAllPersonManage,
  findAllPersonManage,
  findDepartment,
  findProjectList,
  getPermGByEmpUuid,
  quit,
  recoverUser,
  updataPersonManage,
  updateCompEmployee,
  wuyeAddEmployee,
} from '../../services/WuyeService';
import GSTable from '../../components/GSTable';
import API from '../../data/API';
import {ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons/lib';
import Api from '../../data/API';
import appConfig from '../../appConfig';
import {Link, NavLink} from 'react-router-dom';
import EmployeeTypeManager from './components/EmployeeTypeMamager';
import {getEmployeeTypeList} from '../../services/EmployeeTypeService';
import AuthorizedView from '../../util/AuthorizedView';
import CustomUploadImage from '../../components/CustomUploadImage';
import DAL from '../../data/DAL';
import * as XLSX from 'xlsx';
import InformationDesensitization from '../../components/InformationDesensitization';
import LiuChengShenHeCommponents from './components/LiuChengShenHeCommponents';
import UserCertificate from './components/UserCertificate';
import TrimInput from '../../components/TrimInput';
import {DownOutlined} from "@ant-design/icons";

const Search = Input.Search;
const TreeNode = TreeSelect.TreeNode;
const {CheckableTag} = Tag;
let reader: any;
let moment = require('moment');
require('./css/CompanyList.css');

interface EmpProps {
  departmentUuid: any;
  isDepartment: boolean;
  treeData: any;
  empType?: any;
  showModal?: any;
}

const CompanyList: React.FC<EmpProps> = (props: any) => {
  let dal = new DAL();

  useEffect(() => {
    getdepartment();
    getProject();
    employeeTypeList();
    console.log('isShowSon', dal.getCookie('isShowSon'));
    reader = ReaderFunction.createNew();
  }, []);

  const [isShowSon, setIsShowSon] = useState(
    dal.isNotNull(dal.getCookie('isShowSon')) ? ('true' == dal.getCookie('isShowSon') ? true : false) : true
  );
  const {confirm} = Modal;
  let [fileList, setFileList] = useState<any[]>([]);

  const uploadUrl = Api.apiRootPath + '/api/picture/upload_qiniu';
  const [form] = Form.useForm<FormInstance>();
  const FormItem = Form.Item;
  const actionRef = useRef<ActionType>();
  const permGActionRef = useRef<ActionType>();

  const [resignForm] = Form.useForm<FormInstance>();
  const [isLiZi, setIsLiZi] = useState(false);
  const [visible, setVisible] = useState(false);
  const [quitvisible, setQuitvisible] = useState(false);
  const [search, setSearch] = useState('');
  const [uuid, setUuid] = useState('');
  const [add, setAdd] = useState(false);
  const [departmentUuid, setDepartmentUuid] = useState('');
  const [department1, setDepartment1] = useState('');
  const [quitTime, setQuitTime] = useState('');
  const [project, setProject] = useState([]);
  const [item, setItem] = useState({});
  const [facePhoto, setFacePhoto] = useState('');
  const [isCertification, setIsCertification] = useState('');
  const [projectUuid, setProjectUuid] = useState('');
  const [department, setDepartment] = useState([]);
  const [joinDate, setJoinDate] = useState('');
  const [condition, setCondition] = useState(true);
  const [wceId, setwceId] = useState('');
  const [popVisible, setPopVisible] = useState(false);
  const [employeeTypeVisible, setEmployeeTypeVisible] = useState(false);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [defaultEmployeeType, setDefauleEmployeeType] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [employeeType, setEmployeeType] = useState('');
  const [previewVisable, setPreviewVisable] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState('');
  const [permGVisible, setPermGVisible] = useState(false);
  const [liuchengDanVisible, setLiuchengDanVisible] = useState(false);
  const [empUuid, setEmpUuid] = useState('');
  const [quanXuan, setQuanXuan] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [empStatus, setEmpStatus] = useState('');
  const [userCertificateVisible, setUserCertificateVisible] = useState(false);
  const [empType, setEmpType] = useState(props.empType || '');
  const [userUuid, setUserUuid] = useState('');
  const nations = [
    '汉',
    '蒙古',
    '回',
    '藏',
    '维吾尔',
    '苗',
    '彝',
    '壮',
    '布依',
    '朝鲜',
    '满',
    '侗',
    '瑶',
    '白',
    '土家',
    '哈尼',
    '哈萨克',
    '傣',
    '黎',
    '傈僳',
    '佤',
    '畲',
    '高山',
    '拉祜',
    '水',
    '东乡',
    '纳西',
    '景颇',
    '柯尔克孜',
    '土',
    '达斡尔',
    '仫佬',
    '羌',
    '布朗',
    '撒拉',
    '毛南',
    '仡佬',
    '锡伯',
    '阿昌',
    '普米',
    '塔吉克',
    '怒',
    '乌孜别克',
    '俄罗斯',
    '鄂温克',
    '德昂',
    '保安',
    '裕固',
    '京',
    '塔塔尔',
    '独龙',
    '鄂伦春',
    '赫哲',
    '门巴',
    '珞巴',
    '基诺',
  ];
  const permGColumns = [
    {
      title: '分组名称',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const proColumns = [
    {
      title: '姓名',
      dataIndex: 'employeeName',
      key: 'employeeName',
      width: 60,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <a
            onClick={() => {
              update(record);
            }}
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: '登录账号',
      dataIndex: 'account',
      key: 'account',
      width: 110,
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '身份证号',
      dataIndex: 'cardIdNum',
      key: 'cardIdNum',
      width: 170,
      // render: (text: string, record: any) => (
      //     <InformationDesensitization type={'cardIdNum'} value={text}/>
      // )
      render: (text: string, record: any) => (
        <AuthorizedView
          needAuthority={'plaintextDisplay'}
          mismatch={<InformationDesensitization type={'cardIdNum'} value={text}/>}
        >
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        </AuthorizedView>
      ),
    },
    {
      title: '部门',
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: 100,
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '人员类型',
      dataIndex: 'employeeType',
      key: 'employeeType',
      width: 80,
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '截止日期',
      dataIndex: 'expireTime',
      key: 'expireTime',
      valueType: 'date',
      width: 100,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      hideInTable: isLiZi,
      width: 50,
      render: (text: string, record: any) => <Tag color={record.status === '正常' ? 'green' : 'red'}>{text}</Tag>,
    },
    {
      title: '所属项目',
      dataIndex: 'wuyeProj',
      key: 'wuyeProj',
      width: 100,
      render: (text: string, record: any) => (
        <Tooltip title={record.wuyeProj.toString()}>
          <span>{text.toString()}</span>
        </Tooltip>
      ),
    },
    {
      title: '离职日期',
      dataIndex: 'quitDate',
      key: 'quitDate',
      width: 120,
      hideInTable: !isLiZi,
    },
    {
      title: '操作',
      key: 'action',
      width: isLiZi ? 100 : 60,
      fixed: 'right',
      render: (text: string, record: any) => (
        <>
          {isLiZi ? (
            ''
          ) : (
            <Dropdown
              overlay={
                <Menu>
                  {record.status === '正常' ? (
                    <Menu.Item>
                      <a
                        onClick={() => {
                          updateStatus(record, '暂停');
                        }}
                      >
                        暂停账号
                      </a>
                    </Menu.Item>
                  ) : (
                    <Menu.Item>
                      <a
                        onClick={() => {
                          updateStatus(record, '正常');
                        }}
                      >
                        恢复账号
                      </a>
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    <a
                      onClick={() => {
                        getuuid(record);
                      }}
                    >
                      操作离职
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      onClick={() => {
                        setEmpUuid(record.uuid);
                        setPermGVisible(true);
                      }}
                    >
                      权限分组
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      onClick={() => {
                        setUserUuid(record.userUuid);
                        setLiuchengDanVisible(true);
                      }}
                    >
                      流程单审核
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      onClick={() => {
                        setUserUuid(record.userUuid);
                        setUserCertificateVisible(true);
                      }}
                    >
                      查看证书
                    </a>
                  </Menu.Item>
                </Menu>
              }
            >
              <a style={{textDecoration: 'none'}} href="#">
                更多
              </a>
            </Dropdown>
          )}
          {!isLiZi ? (
            ''
          ) : (
            <>
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              <a
                style={{width: 50}}
                onClick={() => {
                  deleteItem(record);
                }}
              >
                删除
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                style={{width: 50}}
                onClick={() => {
                  huiFuUser(record);
                }}
              >
                恢复
              </a>
            </>
          )}
        </>
      ),
    },
  ];
  const getdepartment = async () => {
    const res = await findDepartment({
      companyUuid: API.getCompanyUuid(),
    });
    if (res.err === 0) {
      console.log('获得部门列表', res);
      setDepartment(res.data);
    }
  };
  const employeeTypeList = async () => {
    const res = await getEmployeeTypeList({state: 1});
    if (res.err === 0) {
      const defaultValue = res.defaultValue;
      if (defaultValue !== null && defaultValue !== undefined && defaultValue !== '') {
        if (defaultValue.state) {
          console.log('defauleValue.name::', defaultValue.name);
          setDefauleEmployeeType(defaultValue.name);
        } else {
          setDefauleEmployeeType('');
        }
      } else {
        setDefauleEmployeeType('');
      }
      setEmployeeTypes(res.data);
    } else {
      setEmployeeTypes([]);
    }
  };
  const updateStatus = async (record: any, status: any) => {
    let title = '您确定要将该账号的状态改为' + status + '吗？';
    if (status === '暂停') {
      title = '暂停账号后，该员工将无法查看公司的所有项目，是否确定要暂停该账号?';
    }
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined/>,
      okType: 'danger',
      onOk: async () => {
        const res = await updateCompEmployee({
          empUuid: record.uuid,
          compUuid: API.getCompanyUuid(),
          status: status,
          loginId: record.account,
        });
        if (res.err === 0) {
          message.success('修改成功');
          if (actionRef.current) {
            actionRef.current.reload();
          }
        } else {
          message.error('修改失败');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const deleteItem = (record: any) => {
    confirm({
      title: '您确定要删除吗？',
      icon: <ExclamationCircleOutlined/>,
      okType: 'danger',
      onOk: async () => {
        const res = await deleteEmployee({
          employeeUuid: record.uuid,
          companyUuid: API.getCompanyUuid(),
          wceId: record.wceId,
          name: record.employeeName,
        });
        if (res.err === 0) {
          message.success('删除成功');
          if (actionRef.current) {
            actionRef.current.reload();
          }
        } else {
          message.error('删除失败');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    console.log('record::', record);
  };

  const huiFuUser = (item: any) => {
    confirm({
      title: '您确定要恢复' + item.employeeName + '吗？恢复后需要重新发送钥匙和权限',
      onOk: async () => {
        const res = await recoverUser({
          uuid: item.uuid,
          companyUuid: API.getCompanyUuid(),
          wceId: item.wceId,
        });
        if (res.err === 0) {
          message.success('恢复成功');
          if (actionRef.current) {
            actionRef.current.reload();
          }
          props.dispatch({
            type: 'common/getEmpUserList',
            payload: {
              companyUuid: API.getCompanyUuid(),
            },
          });
        } else if (res.err === -2) {
          message.warning(res.msg);
        } else {
          message.error(res.msg);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const getuuid = (res: any) => {
    console.log('离职:::', res);
    setUuid(res.uuid);
    setQuitTime('');
    setUserUuid(res.userUuid);
    setItem(res);
    setQuitvisible(true);
  };
  const alertError = (result: any, errorMsg: any) => {
    if (result == null) {
      message.warning('接口调用失败，请检查服务器软件是否已开启！');
    } else if (result.code == 0) {
      message.warning(result.msg);
    } else if (result.retval != 0) {
      if (result.func == 'TyA_Request') {
        errorMsg = errorMsg + '请检查卡片是否存在，';
      } else if (result.func == 'Sys_Open') {
        errorMsg = errorMsg + '请检查设备是否连接，';
      }
      message.warning(errorMsg + '错误码：' + result.retval);
    } else {
      return false;
    }
    return true;
  };
  const rederKa = () => {
    reader.TyA_GetUID(parseInt('0'), function (result: any) {
      if (!alertError(result, '获取UID失败！')) {
        reader.Sys_SetBuzzer(reader.device_id, 20, null);
        let icUid: number = result.data.snr;
        icUid = hexadecimalToDecimal(icUid + '');
        let data: any = {icUid: icUid};
        form.setFieldsValue(data);
        console.log('result:::', result);
      }
    });
  };
  const hexadecimalToDecimal = (num: string) => {
    let arr: Array<string> = [];
    for (let i = 0; i < 4; i++) {
      arr.push(num.substr(i * 2, 2));
    }
    return parseInt(arr.reverse().join(''), 16);
  };
  const update = (value: any) => {
    console.log('value::', value);
    setAdd(false);
    setDepartment1(value.departmentName);
    setDepartmentUuid(value.departmentUuid);
    const item: any = {
      uuid: value.uuid,
      employeeName: value.employeeName,
      account: value.account,
      email: value.email,
      phone: value.phone,
      note: value.note,
      facePhoto: value.facePhoto,
      cardIdNum: value.cardIdNum,
      sex: value.sex,
      projectUuid: value.wuyeProjUuid,
      workId: value.workId,
      employeeType: value.employeeType,
      workStation: value.workStation,
      status: value.status,
      icUid: value.icUid,
      registeredAddr: value.registeredAddr,
      residenceAddr: value.residenceAddr,
      ethnic: value.ethnic,
      employeeCate: value.employeeCate,
      expireTime: value.expireTime ? moment(value.expireTime) : moment(),
      meetVisitor: value.meetVisitor == undefined || value.meetVisitor == null ? false : value.meetVisitor,
    };
    setProjectUuid(value.wuyeProjUuid);
    setFacePhoto(value.facePhoto);
    setIsCertification(value.isCertification);
    setJoinDate(value.joinDate === null ? '' : value.joinDate);
    form.setFieldsValue(item);
    setVisible(true);
    setEmployeeType(value.employeeType);
    if (!!value.facePhoto) {
      let list = [
        {
          uid: value.facePhoto,
          url: API.apiRootPath + '/api/file/get_image?projUuid=' + appConfig.wuYeUuid + '&fileName=' + value.facePhoto,
          name: 'logo.png',
          status: 'done',
        },
      ];
      setFileList(list);
    } else {
      setFileList([]);
    }
  };

  const tableSearchBarRender = () => {
    if (props.showModal) {
      return [];
    }
    return [
      <CheckableTag
        key={'1'}
        className="faceTag"
        checked={!isLiZi}
        onChange={() => {
          console.log('在职');
          setIsLiZi(false);
          if (actionRef.current) {
            actionRef.current.reloadAndRest();
          }
        }}
      >
        在职
      </CheckableTag>,
      <CheckableTag
        key={'3'}
        className="faceTag"
        checked={isLiZi}
        onChange={() => {
          console.log('离职');
          setIsLiZi(true);
          if (actionRef.current) {
            actionRef.current.reloadAndRest();
          }
        }}
      >
        离职
      </CheckableTag>,
      <Select
        style={{width: 80}}
        defaultValue={empStatus}
        onChange={(e: string) => {
          setEmpStatus(e);
        }}
      >
        <Select.Option value={''}>状态</Select.Option>
        <Select.Option value={'正常'}>正常</Select.Option>
        <Select.Option value={'暂停'}>暂停</Select.Option>
      </Select>,
      <Select
        style={{width: 100}}
        defaultValue={empType}
        onChange={(e: string) => {
          setEmpType(e);
        }}
      >
        <Select.Option value={''}>人员类型</Select.Option>
        {employeeTypes.map((item: any) => {
          return (
            <Select.Option value={item.name} key={item.name}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>,
      <Input
        key={'3'}
        placeholder="姓名，登录账号，身份证号"
        style={{minWidth: 200}}
        onChange={(value: any) => {
          setSearch(value.target.value);
        }}
      />,
      <Button
        type={'primary'}
        onClick={() => {
          if (actionRef.current) {
            actionRef.current.reloadAndRest();
          }
        }}
      >
        查询
      </Button>,
      <Checkbox
        key={isShowSon.toString()}
        checked={isShowSon}
        onChange={(e) => {
          onShowSonDepartment(e);
        }}
      >
        含子部门成员
      </Checkbox>,
    ];
  };

  const onShowSonDepartment = (e) => {
    dal.setCookie('isShowSon', e.target.checked.toString());
    setIsShowSon(e.target.checked);
    console.log('isShowSon', isShowSon);
    actionRef.current.reloadAndRest();
  };

  const tableOperationsBarRender = () => {
    if (props.showModal) {
      return [];
    }
    return [
      <Button
        type="primary"
        key={4}
        onClick={() => {
          addemp();
        }}
      >
        添加员工
      </Button>,
      <Dropdown overlay={
        <Menu>
          <Menu.Item>
            <Link to={'/company-organization/updateImgs'}>
              批量上传照片
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={'/company-organization/batchEmp'}>
              批量导入人员
            </Link>
          </Menu.Item> <Menu.Item>
          <Link to={'/company-organization/batchUserCertificate'}>
            批量导入工种
          </Link>
        </Menu.Item>
        </Menu>
      }><Button type={"primary"}>批量处理 <DownOutlined/></Button></Dropdown>
    ];
  };
  const getProject = async () => {
    const res = await findProjectList({
      companyUuid: API.getCompanyUuid(),
    });
    if (res.err === 0) {
      setProject(res.data);
    }
  };

  const resetForm = () => {
    setDepartment1('');
    setDepartmentUuid('');
    setIsCertification('');
    setJoinDate('');
    setFacePhoto('');
    setProjectUuid('');
    form.resetFields();
  };

  const addemp = () => {
    console.log('点击', moment().format('YYYY-MM-DD'));
    setAdd(true);
    setFacePhoto("")
    setFileList([])
    resetForm();
    setJoinDate(moment().format('YYYY-MM-DD'));
    // @ts-ignore
    form.setFieldsValue({status: '正常', employeeType: defaultEmployeeType});
    setVisible(true);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    if (add) {
      return addEmployee();
    } else {
      return updataUserInfo();
    }
  };

  const addEmployee = async () => {
    form.validateFields()
      .then(async (data: any) => {
        if (/[\u4E00-\u9FA5]/g.test(data.account)) {
          message.warning('登录帐号不能为汉字');
          setConfirmLoading(false);
          return;
        }
        if (data.phone !== null && data.phone !== undefined && data.phone !== '') {
          let regP = '^1(3|4|5|6|7|8|9)\\d{9}$';
          if (!data.phone.match(regP)) {
            message.warning('手机号格式不正确');
            setConfirmLoading(false);

            return;
          }
        }
        if (data.email !== null && data.email !== undefined && data.email !== '') {
          let regE = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}';
          if (!data.email.match(regE)) {
            message.warning('邮箱格式不正确');
            setConfirmLoading(false);
            return;
          }
        }
        if (data.cardIdNum !== null && data.cardIdNum !== undefined && data.cardIdNum !== '') {
          if (!(data.cardIdNum.length == 18)) {
            message.warning('身份证号格式不正确');
            setConfirmLoading(false);
            return;
          }
        }
        const formData = {
          companyUuid: localStorage.getItem('companyUuid'),
          employeeName: data.employeeName,
          account: data.account,
          phone: data.phone,
          email: data.email,
          departmentUuid: departmentUuid,
          note: data.note,
          facePhoto: facePhoto,
          cardIdNum: data.cardIdNum,
          projectUuid: projectUuid.toString(),
          workId: data.workId,
          sex: data.sex,
          employeeType: data.employeeType,
          workStation: data.workStation,
          joinDate: moment(joinDate).format('YYYY-MM-DD HH:mm:ss'),
          status: data.status,
          icUid: data.icUid,
          meetVisitor: data.meetVisitor,
          ethnic: data.ethnic,
          residenceAddr: data.residenceAddr,
          registeredAddr: data.registeredAddr,
          employeeCate: data.employeeCate,
        };
        if (data.expireTime) {
          formData['expireTime'] = data.expireTime.format('YYYY-MM-DD 23:59:59');
        }
        const res = await wuyeAddEmployee(formData);
        setConfirmLoading(false);
        if (res.err === 0) {
          message.success('添加成功');
          if (actionRef.current) {
            actionRef.current.reload();
          }
          resetForm();
          setVisible(false);
          props.dispatch({
            type: 'common/getEmpUserList',
            payload: {
              companyUuid: API.getCompanyUuid(),
            },
          });
        } else {
          message.error(res.msg);
        }

        console.log('添加的元素', formData);
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  };

  const updataUserInfo = async () => {
    form.validateFields()
      .then(async (data: any) => {
        if (/[\u4E00-\u9FA5]/g.test(data.account)) {
          message.warning('登录帐号不能为汉字');
          setConfirmLoading(false);

          return;
        }
        if (data.phone !== null && data.phone !== undefined && data.phone !== '') {
          let regP = '^1(3|4|5|6|7|8|9)\\d{9}$';
          if (!data.phone.match(regP)) {
            message.warning('手机号格式不正确');
            setConfirmLoading(false);

            return;
          }
        }
        if (data.email !== null && data.email !== undefined && data.email !== '') {
          let regE = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}';
          if (!data.email.match(regE)) {
            message.warning('邮箱格式不正确');
            setConfirmLoading(false);

            return;
          }
        }
        if (data.cardIdNum !== null && data.cardIdNum !== undefined && data.cardIdNum !== '') {
          if (!(data.cardIdNum.length == 18)) {
            message.warning('身份证号格式不正确');
            setConfirmLoading(false);

            return;
          }
        }
        const formData = {
          uuid: data.uuid,
          companyUuid: localStorage.getItem('companyUuid'),
          employeeName: data.employeeName,
          account: data.account,
          phone: data.phone,
          email: data.email,
          departmentUuid: departmentUuid,
          note: data.note,
          facePhoto: facePhoto,
          cardIdNum: data.cardIdNum,
          projectUuid: projectUuid.toString(),
          workId: data.workId,
          sex: data.sex,
          employeeType: data.employeeType,
          workStation: data.workStation,
          joinDate: moment(joinDate).format('YYYY-MM-DD HH:mm:ss'),
          status: data.status,
          icUid: data.icUid,
          meetVisitor: data.meetVisitor,
          ethnic: data.ethnic,
          residenceAddr: data.residenceAddr,
          registeredAddr: data.registeredAddr,
          employeeCate: data.employeeCate,
        };
        if (data.expireTime) {
          formData['expireTime'] = data.expireTime.format('YYYY-MM-DD 23:59:59');
        }
        const res = await updataPersonManage(formData);
        setConfirmLoading(false);
        if (res.err === 0) {
          message.success('修改成功');
          if (actionRef.current) {
            actionRef.current.reload();
          }
          setVisible(false);
          resetForm();
          props.dispatch({
            type: 'common/getEmpUserList',
            payload: {
              companyUuid: API.getCompanyUuid(),
            },
          });
        } else {
          message.error(res.msg);
        }
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  };
  const getFileName = (fileList: any) => {
    console.log('fileList', fileList);
    setFileList(fileList.fileList);
    if (fileList.file.status === 'done') {
      if (fileList.file.response.err === 0) {
        setFileList(fileList.fileList);
        setFacePhoto(fileList.file.response.url);
      } else {
        setFileList([]);
        setFacePhoto('');
        message.error(fileList.file.response.msg);
      }
    } else if (fileList.file.status === 'error') {
      message.error(`${fileList.file.name} 上传文件失败`);
    } else if (fileList.file.status === 'removed') {
      // fileList = fileList.filter((item: any) => item.status !== "removed");
      setFileList([]);
      setFacePhoto('');
    }
  };
  const setDepartment2 = (value: any) => {
    console.log('department::::::', value);
    if (value !== undefined) {
      value = JSON.parse(value);
      setDepartmentUuid(value.uuid);
      setDepartment1(value.department);
    }
    console.log('this.departmentUuid：：：：：', departmentUuid);
  };

  const getJoinDate = (date: any, dateString: any) => {
    if (date != null) {
      setJoinDate(dateString);
    }
  };
  const quitOnChange = (date: any, dateString: any) => {
    console.log(date, dateString);
    setQuitTime(dateString);
  };
  const getEmployeeType = (data: any, defaultValue: any) => {
    if (defaultValue !== null && defaultValue !== undefined && defaultValue !== '') {
      if (defaultValue.state) {
        setDefauleEmployeeType(defaultValue.name);
      } else {
        setDefauleEmployeeType('');
      }
    } else {
      setDefauleEmployeeType('');
    }
    if (data.length > 0) {
      const datas: any = data.filter((item: any) => {
        return item.state === true;
      });
      console.log('employeeTypeData:::', datas, defaultValue);
      setEmployeeTypes(datas);
    } else {
      setEmployeeTypes([]);
    }
  };

  const Quit = async () => {
    // resignForm.validateFields().then(async (data:any)=>{
    if (uuid === null) {
      message.warning('离职异常');
      return;
    }
    if (quitTime === null || quitTime === undefined || quitTime === '') {
      message.warning('请选择时间');
      return;
    }
    const res = await quit({
      uuid: uuid,
      quitTime: quitTime,
      companyUuid: API.getCompanyUuid(),
      userUuid: userUuid,
      name: item.employeeName,
    });
    if (res.err === 0) {
      message.success('离职成功');
      if (actionRef.current) {
        actionRef.current.reload();
      }
      props.dispatch({
        type: 'common/getEmpUserList',
        payload: {
          companyUuid: API.getCompanyUuid(),
        },
      });
    } else if (res.err === -2) {
      message.warning(res.msg);
    } else {
      message.error(res.msg);
    }
    setQuitTime('');
    setQuitvisible(false);
    // })
  };
  const formItemLayout = {
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 24},
    },
  };
  const formItemLayout1 = {
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 12},
    },
  };

  const handleVisibleChange = (visible: any) => {
    if (!visible) {
      setPopVisible(visible);
      return;
    }
    console.log(condition);
    if (condition) {
      if (quitTime === null || quitTime === undefined || quitTime === '') {
        message.warning('请选择时间');
        return;
      } else {
        setPopVisible(true);
      }
      console.log('aaaaa');
      // this.confirm(); // next step
    } else {
      console.log('22222');
      setPopVisible(visible);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div style={{marginTop: 8}}>上传</div>
    </div>
  );
  const render = () => (fileList.length >= 1 ? null : uploadButton);
  const onPreview = (file: any) => {
    setPreviewVisable(true);
    setPreviewFileUrl(file.url);
    return;
  };

  const exprotData = async (selectedRows: [any?]) => {
    console.log('selectedRows:::::', selectedRows);
    setLoading(true);
    if (quanXuan) {
      let isLiZi1 = !isLiZi;
      const res = await exportAllPersonManage({
        state: isLiZi1,
        Search: search,
        status: empStatus,
        empType: empType,
        departmentUuid:
          props.departmentUuid === localStorage.getItem('companyUuid') ? '' : props.departmentUuid,
        projSelect: props.departmentUuid === localStorage.getItem('companyUuid'),
        companyUuid: localStorage.getItem('companyUuid'),
        showSonDepartment: props.showModal ? false : isShowSon,
      });
      if (res.err === 0) {
        setExprotData(res.data);
      } else {
        message.error(res.msg);
      }
    } else {
      setExprotData(selectedRows);
    }
    setLoading(false);
  };

  const setExprotData = (selectedRows: [any?]) => {
    let sheetData = new Array(selectedRows.length + 1);
    let titles: [string?] = [];
    proColumns.map((item: any) => {
      titles.push(item.title);
    });
    sheetData[0] = titles;
    for (let i = 0; i < selectedRows.length; i++) {
      let data: [string?] = [];
      proColumns.map((item: any) => {
        if (item.valueEnum) {
          if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
            data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
          } else {
            data.push('');
          }
        } else if (item.valueType === 'date') {
          if (selectedRows[i][item.dataIndex]) {
            data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD'));
          } else {
            data.push('');
          }
        } else if (item.valueType === 'dateTime') {
          if (selectedRows[i][item.dataIndex]) {
            data.push(moment().format('YYYY-MM-DD HH:mm:ss'));
          } else {
            data.push('');
          }
        } else {
          data.push(selectedRows[i][item.dataIndex]);
        }
      });
      sheetData[i + 1] = data;
    }

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '员工列表');

    /* save to file */
    XLSX.writeFile(wb, '员工列表.xlsx');
  };

  return (
    <div style={{marginRight: 15}}>
      <Spin spinning={loading}>
        <GSTable
          rowSelection={{
            fixed: true,
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys: any, selectedRows: any) => {
              setSelectedRowKeys(selectedRowKeys);
              setQuanXuan(false);
            },
            selections: [
              {
                key: 'SELECT_CURRENT_ALL',
                text: '全选当前页面',
                onSelect: (changableRowKeys: any) => {
                  setQuanXuan(false);
                  setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
                },
              },
              {
                key: 'SELECT_ALL',
                text: '全选全部页面',
                onSelect: (changableRowKeys: any) => {
                  setQuanXuan(true);
                  setSelectedRowKeys(changableRowKeys);
                },
              },
            ],
          }}
          tableAlertRender={({selectedRowKeys, selectedRows, onCleanSelected}: any) => {
            return (
              <Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
                    style={{marginLeft: 8}}
                    onClick={() => {
                      onCleanSelected();
                      setQuanXuan(false);
                    }}
                  >
										取消选择
									</a>
								</span>
              </Space>
            );
          }}
          tableAlertOptionRender={({selectedRowKeys, selectedRows, onCleanSelected}: any) => {
            console.log('tableAlertOptionRender::::', selectedRows);
            return (
              <AuthorizedView needAuthority={'company_employee_export'}>
                <Space size={16}>
                  <a onClick={() => exprotData(selectedRows)}>导出数据</a>
                </Space>
              </AuthorizedView>
            );
          }}
          columns={proColumns}
          actionRef={actionRef}
          rowKey={'uuid'}
          options={false}
          scroll={{
            x: 940
          }}
          searchBarRender={tableSearchBarRender()}
          request={async (params: any = {}, sort: any, filter: any) => {
            let isLiZi1 = !isLiZi;
            const res = await findAllPersonManage({
              state: isLiZi1,
              page: params.current,
              Search: search,
              size: params.pageSize,
              status: empStatus,
              empType: empType,
              departmentUuid:
                props.departmentUuid === localStorage.getItem('companyUuid')
                  ? ''
                  : props.departmentUuid,
              projSelect: props.departmentUuid === localStorage.getItem('companyUuid'),
              companyUuid: localStorage.getItem('companyUuid'),
              showSonDepartment: props.showModal ? false : isShowSon,
            });
            if (res.err === 0) {
              if (quanXuan) {
                let uuids: any = [];
                res.data.map((item: any) => {
                  uuids.push(item.uuid);
                });
                setSelectedRowKeys(uuids);
                setTotal(res.count);
              }
              setTotal(res.count);
              return {
                data: res.data,
                total: res.count,
                success: true,
              };
            }
            return {
              data: [],
              total: 0,
              success: true,
            };
          }}
          operationsBarRender={tableOperationsBarRender()}
        />
      </Spin>
      <Modal
        title={add ? '添加员工' : '编辑员工'}
        visible={visible}
        onOk={handleOk}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        width={900}
      >
        <Form layout="vertical" {...formItemLayout} form={form}>
          <FormItem label={'uuid'} name={'uuid'} hidden={true}>
            <Input/>
          </FormItem>
          <h3>基本信息</h3>
          <Row className="margin-top--10" gutter={12} justify={'start'} align={'top'}>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'头像'}>
                <>
                  <CustomUploadImage
                    maxCount={1}
                    onPreview={onPreview}
                    cdn={'private'}
                    data={{isFace: true}}
                    multiple={false}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={getFileName}
                    render={render()}
                  />
                  <span>请上传大小25K~1M、像素不低于480x640的清晰免冠照片</span>
                </>
              </FormItem>
            </Col>
            <Col span={16}>
              <Row style={{marginTop: 0}} gutter={12} justify={'start'}>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    label={'姓名'}
                    name={'employeeName'}
                    rules={[{required: true}]}
                  >
                    <Input placeholder="请输入姓名" disabled={isCertification === '1'}/>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    label={'性别'}
                    name={'sex'}
                    rules={[{required: true}]}
                  >
                    <Radio.Group>
                      <Radio value={'男'}>男</Radio>
                      <Radio value={'女'}>女</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>
              <Row className="margin-top--10" gutter={12} justify={'start'}>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    label={'登录帐号'}
                    name={'account'}
                    rules={[{required: true}]}
                  >
                    <TrimInput placeholder="请输入账号"/>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label={'状态'} name={'status'}>
                    <Select placeholder={'请选择'}>
                      <Select.Option value={'正常'} key={'正常'}>
                        正常
                      </Select.Option>
                      <Select.Option value={'暂停'} key={'暂停'}>
                        暂停
                      </Select.Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="margin-top--10" gutter={12} justify={'start'}>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'身份证号'} name={'cardIdNum'}>
                <TrimInput placeholder="请输入身份证号"/>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'联系电话'} name={'phone'}>
                <TrimInput placeholder="请输入电话"/>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'邮箱'} name={'email'}>
                <Input placeholder="请输入邮箱"/>
              </FormItem>
            </Col>
          </Row>
          <Row className="margin-top--10" gutter={12} justify={'start'} align={'top'}>
            <Col span={8}>
              <FormItem {...formItemLayout} name={'registeredAddr'} label={'户籍地址'}>
                <Input.TextArea placeholder={'请输入户籍地址'}></Input.TextArea>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'现住地址'} name={'residenceAddr'}>
                <Input.TextArea placeholder={'请输入现住地址'}></Input.TextArea>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem {...formItemLayout} name={'ethnic'} label={'民族'}>
                <Select allowClear showSearch optionFilterProp="children">
                  {nations.map((item) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <h3>工作信息</h3>
          <Row className="margin-top--10" gutter={12} justify={'start'}>
            <Col span={8}>
              <FormItem {...formItemLayout} required={true} label={'部门'} name={'department'}>
                <TreeSelect
                  key={department1}
                  showSearch={true}
                  dropdownStyle={{maxHeight: 300, overflow: 'auto'}}
                  placeholder={'请选择'}
                  allowClear={true}
                  defaultValue={department1}
                  treeDefaultExpandAll={true}
                  treeData={props.treeData}
                  onChange={setDepartment2}
                >
                  {department.map((item: any, index: any) => (
                    <TreeNode value={item.key} title={item.departmentName} key={item.uuid}/>
                  ))}
                </TreeSelect>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'工号'} name={'workId'}>
                <Input placeholder="请输入工号"/>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'工位'} name={'workStation'}>
                <Input placeholder="请输入工位"/>
              </FormItem>
            </Col>
          </Row>
          <Row className="margin-top--10" gutter={12} justify={'start'}>
            <Col span={8}>
              <div className={'ant-row ant-form-item  '}>
                <div
                  className={'ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-24'}
                  style={{display: 'flex', justifyContent: 'space-between'}}
                >
                  <label>人员类型</label>
                  <AuthorizedView needAuthority={'empolyee-type-manager'}>
                    <a
                      onClick={() => {
                        setEmployeeTypeVisible(true);
                      }}
                    >
                      人员类型管理
                    </a>
                  </AuthorizedView>
                </div>
                <div className={'ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-24'}>
                  <FormItem
                    noStyle={true}
                    {...formItemLayout}
                    label={'人员类型'}
                    name={'employeeType'}
                  >
                    <Select
                      placeholder={'请选择'}
                      style={{width: '100%'}}
                      onChange={(e: any) => setEmployeeType(e)}
                    >
                      {employeeTypes.length > 0
                        ? employeeTypes.map((item: any) => {
                          return (
                            <Select.Option value={item.name} key={item.name}>
                              {item.name}
                            </Select.Option>
                          );
                        })
                        : ''}
                    </Select>
                  </FormItem>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <FormItem {...formItemLayout} name={'employeeCate'} label={'人员类别'}>
                <Select placeholder={'请选择'} style={{width: '100%'}}>
                  <Select.Option value={'操作'}>操作</Select.Option>
                  <Select.Option value={'管理'}>管理</Select.Option>
                  <Select.Option value={'管理及以上'}>管理及以上</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                {...formItemLayout}
                label={'所属项目'}
                name={'projectUuid'}
                rules={[{required: true}]}
              >
                <TreeSelect
                  key={'2'}
                  showSearch={true}
                  value={projectUuid}
                  dropdownStyle={{maxHeight: 300, overflow: 'auto'}}
                  placeholder={'请选择'}
                  allowClear={true}
                  multiple={true}
                  treeDefaultExpandAll={true}
                  onChange={(value) => {
                    setProjectUuid(value);
                  }}
                >
                  {project.map((item: any, index: any) => (
                    <TreeNode value={item.uuid} title={item.name} key={item.uuid}/>
                  ))}
                </TreeSelect>
              </FormItem>
            </Col>
          </Row>

          <Row className="margin-top--10" gutter={12} justify={'start'} align={'middle'}>
            <Col span={5}>
              <FormItem {...formItemLayout} label={'IC卡'} name={'icUid'}>
                <Input placeholder="请输入IC卡"/>
              </FormItem>
            </Col>
            <Col span={3} style={{marginTop: 15}}>
              <Button type={'primary'} onClick={rederKa}>
                读取IC卡
              </Button>
            </Col>
            <Col span={8}>
              <FormItem
                {...formItemLayout}
                valuePropName={'checked'}
                label={'是否可以接访'}
                name={'meetVisitor'}
              >
                <Switch/>
              </FormItem>
            </Col>
          </Row>
          <Row className="margin-top--10" gutter={12} justify={'start'}>
            <Col span={8}>
              <FormItem {...formItemLayout} label={'入职时间'}>
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{width: '100%'}}
                  onChange={getJoinDate}
                  value={joinDate === null || joinDate === '' ? null : moment(joinDate)}
                  placeholder="选择日期"
                />
              </FormItem>
            </Col>
            {employeeType === '临时人员' && (
              <Col span={8}>
                <FormItem
                  tooltip="该设置对临时人员有效，到此日期后，员工状态将变为离职" {...formItemLayout}
                  name={'expireTime'} label={'截止日期'}>
                  <DatePicker
                    disabledDate={(currentDate: any) => {
                      return currentDate.diff(moment(), 'months') >= 3;
                    }}
                    format="YYYY-MM-DD"
                    style={{width: '100%'}}
                    placeholder="选择日期"
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Row className="margin-top--10" gutter={12} justify={'start'}>
            <Col span={16}>
              <FormItem {...formItemLayout} label={'备注'} name={'note'}>
                <Input.TextArea placeholder="请输入备注"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="离职"
        visible={quitvisible}
        onOk={Quit}
        key={uuid}
        onCancel={() => {
          setQuitvisible(false);
        }}
        footer={
          <span>
						<Button
              onClick={() => {
                setQuitvisible(false);
              }}
            >
							取消
						</Button>
						<Popconfirm
              title="该账户将无法访问当前项目，并从所有权限分组中移除"
              okType={'danger'}
              visible={popVisible}
              onVisibleChange={handleVisibleChange}
              onConfirm={Quit}
              okText="确定"
              cancelText="取消"
            >
							<Button className={'ant-btn  ant-btn-dangerous'}>确定</Button>
						</Popconfirm>
					</span>
        }
      >
        <Row align={'middle'}>
          <Col span={4}>
            <span style={{color: 'red', textAlign: 'center'}}>*</span> 离职日期:
          </Col>
          <Col>
            <DatePicker
              key={uuid}
              format="YYYY-MM-DD"
              placeholder="选择或者输入日期"
              onChange={quitOnChange}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        width={1000}
        title={'人员类型管理'}
        visible={employeeTypeVisible}
        onCancel={() => {
          setEmployeeTypeVisible(false);
        }}
        footer={null}
      >
        <EmployeeTypeManager
          onSubmit={(data: any, defauleValue: any) => {
            getEmployeeType(data, defauleValue);
          }}
        ></EmployeeTypeManager>
      </Modal>
      <Modal
        title={'预览'}
        zIndex={1001}
        footer={null}
        visible={previewVisable}
        onCancel={() => {
          setPreviewVisable(false);
        }}
        onOk={() => {
          setPreviewVisable(false);
        }}
      >
        <Image src={previewFileUrl}/>
      </Modal>
      <Modal
        key={empUuid}
        title="拥有的权限分组"
        visible={permGVisible}
        footer={null}
        width={800}
        onCancel={() => {
          setPermGVisible(false);
        }}
      >
        <GSTable
          columns={permGColumns}
          pagination={false}
          scroll={{
            x: 800
          }}
          actionRef={permGActionRef}
          request={async (params: any = {}) => {
            const res = await getPermGByEmpUuid({
              empUuid: empUuid,
            });
            return {
              data: res.data,
              success: true,
            };
          }}
        />
      </Modal>
      <Modal
        key={userUuid}
        title="拥有的流程单"
        width={800}
        visible={liuchengDanVisible}
        footer={null}
        onCancel={() => {
          setLiuchengDanVisible(false);
        }}
      >
        <LiuChengShenHeCommponents key={userUuid} userUuid={userUuid}></LiuChengShenHeCommponents>
      </Modal>
      <Modal
        key={userUuid}
        width={800}
        title={'证书信息'}
        visible={userCertificateVisible}
        footer={null}
        onCancel={() => {
          setUserCertificateVisible(false);
        }}
      >
        <UserCertificate userUuid={userUuid}/>
      </Modal>
    </div>
  );
};
export default connect(({common}: ConnectState) => ({
  common: common,
}))(CompanyList);
