import React, { useEffect, useState } from 'react';
import LineChart from '../../../components/LineChart';
import  { DataContext } from '../../controlPlatform/CurrentPlatform';
import { Button, Card, message, Space } from 'antd';
import CustomRangePicker from '../../../components/CustomRangePicker';
import moment from 'moment';
import { getEquipmentStatus } from '../../../services/ZhiNengMenJinService';

interface EquipmentStatusChartPorps {
	deviceId: string
}

	const EquipmentStatusChart: React.FC<EquipmentStatusChartPorps> = (props: any) => {
	const [chartData, setChartData] = useState<any>([])
	const [loading, setLoading] = useState(false);
	let startTime = moment().subtract(6, 'd');
	let endTime = moment();
	useEffect(() => {
		getData().then()
	}, [props.deviceId])
	const getData = async () => {
		let data = {
			deviceId: props.deviceId,
			startTime: startTime.format('YYYY-MM-DD 00:00:00'),
			endTime: endTime.format('YYYY-MM-DD 23:59:59'),
		};
		setLoading(true)
		let res = await getEquipmentStatus(data);
		setLoading(false)
		if (res.err !== 0) {
			message.error(res.msg)
			return;
		}
		let chartData: any = [];
		let keys = Object.keys(res.data);
		keys.map((key: string) => {
			chartData.push({
				name: "状态",
				x: key,
				y: res.data[key],
			});
		});
		setChartData(chartData)
	}
	const tabBarExtrContent = () => (
		<Space>
			<CustomRangePicker
				onChange={(_startTime, _endTime) => {
					startTime = _startTime;
					endTime = _endTime;
				}}
				startTime={startTime}
				endTime={endTime}
			/>
			<Button type={"primary"} onClick={getData}> 查询</Button>
		</Space>
	);
	return (
		<>
			<Card
				loading={loading}
				title={'设备状态趋势'}
				extra={tabBarExtrContent()}
				bodyStyle={{ paddingLeft: 15 }}
			>
				<DataContext.Provider value={chartData}>
					<LineChart/>
				</DataContext.Provider>
			</Card>

		</>
	);
};
export default EquipmentStatusChart;
