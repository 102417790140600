import Api from '../data/API';
import { async } from 'q';

export async function getDeviceidData(params: any) {
	return Api.postWithAuth('/api/mongodb/get_deviceid_data', params);
}

export async function getDeviceUserInfo(params: any) {
	return Api.postWithAuth('/api/mongodb/get_device_user_info', params);
}

export async function getHealthData(params: any) {
	return Api.postWithAuth('/api/statistice/getHealthData', params);
}

export async function getHealthCheckData(params: any) {
	return Api.postWithAuth('/admin/api/get_health_check_statistice_data', params)
}

export async function getHealthRecordData(params: any) {
	return Api.postWithAuth('/api/statistice/getHealthRecordData', params);
}
export async function getHealthRecordData1(params: any) {
	return Api.postWithAuth('/api/statistice/getHealthRecordData1', params);
}

export async function getEmpDataByGroup(params: any) {
	return Api.postWithAuth('/api/statistice/getEmpDataByGroup', params);
}
export async function getEmpData(params: any) {
	return Api.postWithAuth('/api/statistice/getEmpData', params);
}

export async function getUnlockData(params: any) {
	return Api.postWithAuth('/api/statistice/getUnlockDataRecord', params);
}
export async function getUnlockDataTop(params: any) {
	return Api.postWithAuth('/api/statistice/getUnlockData', params);
}

export async function xunGengStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/xunGengStatistics', params);
}

export async function getRoomStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getRoomStatistics', params)
}
export async function getCompanyStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getCompanyStatistics', params)
}
export async function getTalentStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getTalentStatistics', params)
}
export async function getSchemeStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getSchemeStatistics', params)
}
export async function getMarketChanceStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getMarketChanceStatistics', params)
}
export async function getCompanyTypeStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getCompanyTypeStatistics', params)
}

export async function getTalentTypeStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getTalentTypeStatistics', params)
}
export async function getSchemeTypeStatistics(params: any) {
	return Api.postWithAuth('/api/statistice/getSchemeTypeStatistics', params)
}
export async function getHealthDataByDeviceId(params: any) {
	return Api.postWithAuth('/api/statistice/getHealthDataByDeviceId', params)
}
export async function getRoomItemStatisticsData(params: any) {
	return  Api.postWithAuth('/api/statistice/getRoomItemStatisticsData', params)
}
export async function getRoomItemStatisticsTotal(params: any) {
	return  Api.postWithAuth('/api/statistice/getRoomItemStatisticsTotal', params)
}
