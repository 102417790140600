import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Modal, Radio, Select, Space, Tag} from "antd";
import {ActionType} from "@ant-design/pro-table/es/typing";
import GSTable from "../../../components/GSTable";
import {getAchievementList, updateStatus} from "../../../services/AchievementService";
import {updateStatusBeijingOfficeDemand} from "../../../services/BeijingOfficeService";
import AuthorizedView from "../../../util/AuthorizedView";

const Index: React.FC = () => {
    const actionRef = useRef<ActionType>()
    const [addForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [status, setStatus] = useState('')
    const {CheckableTag} = Tag;

    const [search, setSearch] = useState("");
    // const [addVisible, setAddVisible] = useState(false);
    // const [typeList, setTypeList] = useState([])
    // const [techList, setTechList] = useState([]);
    // const [updateVisible, setUpdateVisible] = useState(false)
    // const [caseVisible, setCaseVisible] = useState(false)
    // const [achievementUuid, setAchievementUuid] = useState('')
    // const [name, setName] = useState('')

    useEffect(() => {

    }, [])
    const columns = [
        {
            title: '成果名称',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '成果简介',
            ellipsis: true,
            key: 'introduction',
            dataIndex: 'introduction',
        },
        {
            title: '成果类型',
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: '技术成熟度',
            key: 'technologyMaturity',
            dataIndex: 'technologyMaturity',
        },
        {
            title: '成果资质',
            key: 'achievementQualification',
            dataIndex: 'achievementQualification',
        },
        {
            title: '应用场景',
            ellipsis: true,
            key: 'typeName',
            dataIndex: 'typeName',
        },
        {
            title: '技术分类',
            ellipsis: true,
            key: 'techName',
            dataIndex: 'techName',
        },
        {
            title: '操作',
            key: 'active',
            render: (text: string, item: any) => {
                return <AuthorizedView needAuthority={'sz:achievement:updateStatus'}>
                        <Space>
                        {
                            item.status === '待审核' &&
                            <>
                                <a onClick={() => updateStatusItem(item.uuid, "通过")}>通过</a>
                                <a onClick={() => updateStatusItem(item.uuid, "未通过")}>不通过</a>
                            </>
                        }
                        {
                            item.status === '未通过' && <>
                                <a onClick={() => updateStatusItem(item.uuid, "通过")}>通过</a>
                            </>
                        }
                        {
                            item.status === '通过' && <>
                                <a onClick={() => updateStatusItem(item.uuid, "未通过")}>不通过</a>
                            </>
                        }
                        </Space>
                    </AuthorizedView>
            }
        }
    ]
    const updateStatusItem =  (uuid: string, status: string) => {
        Modal.confirm({
            title: '提示',
            content: '您确实要' + status + "吗？",
            // okType: "danger",
            okText: "确定",
            cancelText: "取消",
            onOk: async  () => {
                let rsp = await updateStatus({uuid, status})
                if (rsp.err !== 0) {
                    message.error(rsp.msg)
                }
                if (actionRef.current) {
                    actionRef.current.reload()
                }
            }
        })

    }
    const tableSearchBarRender = () => [
        <CheckableTag
            key={'1'}
            className="faceTag"
            checked={status === ''}
            onChange={() => {
                setStatus("")
            }}
        >
            全部
        </CheckableTag>, <CheckableTag
            key={'1'}
            className="faceTag"
            checked={status === '待审核'}
            onChange={() => {
                setStatus("待审核")
            }}
        >
            待审核
        </CheckableTag>,
        <CheckableTag
            key={'2'}
            className="faceTag"
            checked={status === '通过'}
            onChange={() => {
                setStatus("通过")
            }}
        >
            通过
        </CheckableTag>,
        <CheckableTag
            key={'3'}
            className="faceTag"
            checked={status === '未通过'}
            onChange={() => {
                setStatus("未通过")
            }}
        >
            未通过
        </CheckableTag>,
        <Space>
            <Input onChange={(e) => {
                setSearch(e.target.value)

            }} placeholder={'请输入名称'}></Input>
            <Button type={"primary"} onClick={() => {
                actionRef.current.reloadAndRest();
            }}>查询</Button>
        </Space>
    ]
    const tableOperationsBarRender = () => [
    ]
    // const addItem = async () => {
    //     let data = await addForm.validateFields();
    //     let rsp = await addAchievement({
    //         ...data,
    //         institutesUuid: localStorage.getItem("institutesUuid"),
    //         type: data.type.toString(),
    //         typeUuid: data.typeUuid3,
    //         techUuid: data.techUuid3,
    //         owner: (data.owner || '').toString(),
    //         ownerPosition: (data.ownerPosition || '').toString()
    //     })
    //     if (rsp.err === 0) {
    //         setAddVisible(false)
    //         actionRef.current.reload()
    //         message.success(rsp.msg)
    //     } else {
    //         message.error(rsp.msg)
    //     }
    // }
    // const updateItem = async () => {
    //     let data = await updateForm.validateFields();
    //     let rsp = await updateAchievement({
    //         ...data,
    //         institutesUuid: localStorage.getItem("institutesUuid"),
    //         type: data.type.toString(),
    //         typeUuid: data.typeUuid3,
    //         techUuid: data.techUuid3,
    //         owner: (data.owner || '').toString(),
    //         ownerPosition: (data.ownerPosition || '').toString()
    //     })
    //     if (rsp.err === 0) {
    //         setUpdateVisible(false)
    //         actionRef.current.reload()
    //         message.success(rsp.msg)
    //     } else {
    //         message.error(rsp.msg)
    //     }
    // }
    return <>
        <GSTable
            columns={columns}
            actionRef={actionRef}
            rowKey={'uuid'}
            searchBarRender={tableSearchBarRender()}
            operationsBarRender={tableOperationsBarRender()}
            request={async (params: any) => {
                let rsp = await getAchievementList({
                    search: search,
                    type: status,
                    page: params.current,
                    size: params.pageSize
                })
                if (rsp.err === 0) {
                    return {
                        data: rsp.data,
                        total: rsp.total
                    }
                }
                return {
                    data: []
                }
            }}
        >
        </GSTable>
        {/*<Modal title={'添加'} width={800} destroyOnClose={true} open={addVisible} onCancel={() => {*/}
        {/*    setAddVisible(false)*/}
        {/*}} onOk={addItem}>*/}
        {/*    <Form labelAlign={'left'} labelCol={{span: 6}} form={addForm}>*/}
        {/*        <Form.Item name={'name'} label={'名称'} rules={[{required: true, message: '请输入名称'}]}>*/}
        {/*            <Input></Input>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'introduction'} label={'成果简介'}*/}
        {/*                   rules={[{required: true, message: '请输入成果简介'}]}>*/}
        {/*            <Input.TextArea></Input.TextArea>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'type'} label={'成果类型'} rules={[{required: true, message: '请输入成果类型'}]}>*/}
        {/*            <Select mode={"tags"}>*/}
        {/*                <Select.Option key={'新技术'} value={'新技术'}>新技术</Select.Option>*/}
        {/*                <Select.Option key={'新产品'} value={'新产品'}>新产品</Select.Option>*/}
        {/*                <Select.Option key={'新工艺'} value={'新工艺'}>新工艺</Select.Option>*/}
        {/*                <Select.Option key={'新材料'} value={'新材料'}>新材料</Select.Option>*/}
        {/*                <Select.Option key={'新设备'} value={'新设备'}>新设备</Select.Option>*/}
        {/*            </Select>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'technologyMaturity'} label={'技术成熟度'}*/}
        {/*                   rules={[{required: true, message: '请输入技术成熟度'}]}>*/}
        {/*            <Select>*/}
        {/*                <Select.Option key={'正在研发'} value={'正在研发'}>正在研发</Select.Option>*/}
        {/*                <Select.Option key={'已有样品'} value={'已有样品'}>已有样品</Select.Option>*/}
        {/*                <Select.Option key={'通过小试'} value={'通过小试'}>通过小试</Select.Option>*/}
        {/*                <Select.Option key={'通过中试'} value={'通过中试'}>通过中试</Select.Option>*/}
        {/*                <Select.Option key={'可以量产'} value={'可以量产'}>可以量产</Select.Option>*/}
        {/*            </Select>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'achievementQualification'} label={'成果资质'}*/}
        {/*                   rules={[{required: true, message: '请输入成果资质'}]}>*/}
        {/*            <CustionRodioFormItem other={true}>*/}
        {/*                <Radio key={'发明专利'} value={'发明专利'}>发明专利</Radio>*/}
        {/*                <Radio key={'实用新型专利'}*/}
        {/*                       value={'实用新型专利'}>实用新型专利</Radio>*/}
        {/*                <Radio key={'软著'} value={'软著'}>软著</Radio>*/}
        {/*            </CustionRodioFormItem>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'typeUuid3'} label={'应用场景/领域'}*/}
        {/*                   rules={[{required: true, message: '请选择应用场景/领域'}]}>*/}
        {/*            <CheckedComponents*/}
        {/*                key={typeList.toString()}*/}
        {/*                dataList={typeList}*/}
        {/*            ></CheckedComponents>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'techUuid3'} label={'数智技术'} rules={[{required: true, message: '请选择数智技术'}]}>*/}
        {/*            <CheckedComponents*/}
        {/*                key={techList.toString()}*/}
        {/*                dataList={techList}*/}
        {/*            ></CheckedComponents>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'conversionMethod'} label={'预期转化方式'}*/}
        {/*                   rules={[{required: false, message: '请输入预期转化方式'}]}>*/}
        {/*            <CustionRodioFormItem other={true}>*/}
        {/*                <Radio key={'完成转让'} value={'完成转让'}>完成转让</Radio>*/}
        {/*                <Radio key={'许可转让'} value={'许可转让'}>许可转让</Radio>*/}
        {/*                <Radio key={'作价入股'} value={'作价入股'}>作价入股</Radio>*/}
        {/*                <Radio key={'合作转换'} value={'合作转换'}>合作转换</Radio>*/}
        {/*            </CustionRodioFormItem>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'price'} label={'交易价格（万元）'}*/}
        {/*                   rules={[{required: false, message: '请输入交易价格'}]}>*/}
        {/*            <InputNumber style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'services'} label={'交易后服务'}*/}
        {/*                   rules={[{required: false, message: '请输入交易后服务'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'characteristic'} label={'成果优势/特点'}*/}
        {/*                   rules={[{required: false, message: '成果优势/特点'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'innovate'} label={'创新内容'} rules={[{required: false, message: '请输入创新内容'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}

        {/*        <Form.Item name={'prospect'} label={'商业前景'} rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'listImg'} label={'图片展示'} rules={[{required: false, message: '请上传图片展示'}]}>*/}
        {/*            <UploadImgFormItem multiple={true} hiddenImgCorp={true}*/}
        {/*                               listType={'picture-card'}></UploadImgFormItem>*/}
        {/*        </Form.Item>*/}

        {/*        <Form.Item name={'contacts'} label={'联系人'} rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Input style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'contactInformation'} label={'联系方式'}*/}
        {/*                   rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Input style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'owner'} label={'所有者'} rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Select mode={"tags"}></Select>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'ownerPosition'} label={'所有者职务/职称'}*/}
        {/*                   rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Select mode={"tags"}></Select>*/}
        {/*        </Form.Item>*/}
        {/*    </Form>*/}
        {/*</Modal>*/}
        {/*<Modal title={'编辑'} width={800} destroyOnClose={true} open={updateVisible} onCancel={() => {*/}
        {/*    setUpdateVisible(false)*/}
        {/*}} onOk={updateItem}>*/}
        {/*    <Form labelAlign={'left'} labelCol={{span: 6}} form={updateForm}>*/}
        {/*        <Form.Item name={'uuid'} hidden={true} label={'名称'} rules={[{required: false, message: '请输入名称'}]}>*/}
        {/*            <Input></Input>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'name'} label={'名称'} rules={[{required: true, message: '请输入名称'}]}>*/}
        {/*            <Input></Input>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'introduction'} label={'成果简介'}*/}
        {/*                   rules={[{required: true, message: '请输入成果简介'}]}>*/}
        {/*            <Input.TextArea></Input.TextArea>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'type'} label={'成果类型'} rules={[{required: true, message: '请输入成果类型'}]}>*/}
        {/*            <Select mode={"tags"}>*/}
        {/*                <Select.Option key={'新技术'} value={'新技术'}>新技术</Select.Option>*/}
        {/*                <Select.Option key={'新产品'} value={'新产品'}>新产品</Select.Option>*/}
        {/*                <Select.Option key={'新工艺'} value={'新工艺'}>新工艺</Select.Option>*/}
        {/*                <Select.Option key={'新材料'} value={'新材料'}>新材料</Select.Option>*/}
        {/*                <Select.Option key={'新设备'} value={'新设备'}>新设备</Select.Option>*/}
        {/*            </Select>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'technologyMaturity'} label={'技术成熟度'}*/}
        {/*                   rules={[{required: true, message: '请输入技术成熟度'}]}>*/}
        {/*            <Select>*/}
        {/*                <Select.Option key={'正在研发'} value={'正在研发'}>正在研发</Select.Option>*/}
        {/*                <Select.Option key={'已有样品'} value={'已有样品'}>已有样品</Select.Option>*/}
        {/*                <Select.Option key={'通过小试'} value={'通过小试'}>通过小试</Select.Option>*/}
        {/*                <Select.Option key={'通过中试'} value={'通过中试'}>通过中试</Select.Option>*/}
        {/*                <Select.Option key={'可以量产'} value={'可以量产'}>可以量产</Select.Option>*/}
        {/*            </Select>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'achievementQualification'} label={'成果资质'}*/}
        {/*                   rules={[{required: true, message: '请输入成果资质'}]}>*/}
        {/*            <CustionRodioFormItem other={true}>*/}
        {/*                <Radio key={'发明专利'} value={'发明专利'}>发明专利</Radio>*/}
        {/*                <Radio key={'实用新型专利'}*/}
        {/*                       value={'实用新型专利'}>实用新型专利</Radio>*/}
        {/*                <Radio key={'软著'} value={'软著'}>软著</Radio>*/}
        {/*            </CustionRodioFormItem>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'typeUuid3'} label={'应用场景/领域'}*/}
        {/*                   rules={[{required: true, message: '请选择应用场景/领域'}]}>*/}
        {/*            <CheckedComponents*/}
        {/*                key={typeList.toString()}*/}
        {/*                dataList={typeList}*/}
        {/*            ></CheckedComponents>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'techUuid3'} label={'数智技术'} rules={[{required: true, message: '请选择数智技术'}]}>*/}
        {/*            <CheckedComponents*/}
        {/*                key={techList.toString()}*/}
        {/*                dataList={techList}*/}
        {/*            ></CheckedComponents>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'conversionMethod'} label={'预期转化方式'}*/}
        {/*                   rules={[{required: false, message: '请输入预期转化方式'}]}>*/}
        {/*            <CustionRodioFormItem other={true}>*/}
        {/*                <Radio key={'完成转让'} value={'完成转让'}>完成转让</Radio>*/}
        {/*                <Radio key={'许可转让'} value={'许可转让'}>许可转让</Radio>*/}
        {/*                <Radio key={'作价入股'} value={'作价入股'}>作价入股</Radio>*/}
        {/*                <Radio key={'合作转换'} value={'合作转换'}>合作转换</Radio>*/}
        {/*            </CustionRodioFormItem>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'price'} label={'交易价格（万元）'}*/}
        {/*                   rules={[{required: false, message: '请输入交易价格'}]}>*/}
        {/*            <InputNumber style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'services'} label={'交易后服务'}*/}
        {/*                   rules={[{required: false, message: '请输入交易后服务'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}

        {/*        <Form.Item name={'services'} label={'交易后服务'}*/}
        {/*                   rules={[{required: false, message: '请输入交易后服务'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'innovate'} label={'创新内容'} rules={[{required: false, message: '请输入创新内容'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}

        {/*        <Form.Item name={'prospect'} label={'商业前景'} rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Input.TextArea style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'listImg'} label={'图片展示'} rules={[{required: false, message: '请上传图片展示'}]}>*/}
        {/*            <UploadImgFormItem multiple={true} hiddenImgCorp={true}*/}
        {/*                               listType={'picture-card'}></UploadImgFormItem>*/}
        {/*        </Form.Item>*/}

        {/*        <Form.Item name={'contacts'} label={'联系人'} rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Input style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'contactInformation'} label={'联系方式'}*/}
        {/*                   rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Input style={{width: '100%'}}/>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'owner'} label={'所有者'} rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Select mode={"tags"}></Select>*/}
        {/*        </Form.Item>*/}
        {/*        <Form.Item name={'ownerPosition'} label={'所有者职务/职称'}*/}
        {/*                   rules={[{required: false, message: '请输入商业前景'}]}>*/}
        {/*            <Select mode={"tags"}></Select>*/}
        {/*        </Form.Item>*/}
        {/*    </Form>*/}
        {/*</Modal>*/}
        {/*<Modal title={'案例列表'} width={800} destroyOnClose={true} open={caseVisible} onCancel={() => {*/}
        {/*    setCaseVisible(false)*/}
        {/*}} onOk={() => {*/}
        {/*    setCaseVisible(false)*/}
        {/*}}>*/}
        {/*    <CaseList key={achievementUuid} name={name} achievementUuid={achievementUuid}></CaseList>*/}
        {/*</Modal>*/}
    </>
}
export default Index;
