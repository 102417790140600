import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import { findMeetingName, meetingOrderList, orderDetail } from '../../../services/meetingService';

var moment = require('moment');
let msg = require('../../../util/SuspensionMsg');
let { RangePicker } = DatePicker;
const MeetingOrderList: React.FC = () => {
	useEffect(() => {
		getMeetingName();
	}, []);

	const actionRef = useRef<ActionType>();
	const [fee, setFee] = useState('');
	const [orderUuid, setOrderUuid] = useState('');
	const [search, setSearch] = useState('');
	const [uuid, setUUid] = useState('');
	const [order, setOrder] = useState({});
	const [names, setNames] = useState([]);
	const [visible, setVisible] = useState(false);
	const [startDay, setStartDay] = useState('');
	const [endDay, setEndDay] = useState('');
	const [email, setEmail] = useState('');
	const columns = [
		{
			title: '会议室名称',
			dataIndex: 'name',
			key: 'name',
			width: 100,
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						getOneOrder(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '参会人数',
			dataIndex: 'attendance',
			key: 'attendance',
			width: 80,
		},
		{
			title: '预订人',
			dataIndex: 'realName',
			key: 'realName',
			width: 100,
		},
		{
			title: '预定账号',
			dataIndex: 'email',
			key: 'email',
			width: 100,
		},
		{
			title: '开始时间',
			dataIndex: 'start_time',
			key: 'start_time',
			width: 100,
		},
		{
			title: '结束时间',
			dataIndex: 'end_time',
			key: 'end_time',
			width: 100,
		},
		{
			title: '总价/元',
			dataIndex: 'total_price',
			key: 'total_price',
			width: 80,
			render: (text: string, record: any) => (
				<span>
					{record.total_price}
					{record.is_pay === '未支付' ? (
						<a onClick={getFee} data-toggle="modal" data-target="#updateFee">
							<span id={record.orderUuid + ',' + record.total_price} className="icon icon-edit" />
						</a>
					) : (
						''
					)}
				</span>
			),
		},
		{
			title: '下单时间',
			dataIndex: 'order_time',
			key: 'order_time',
			width: 100,
		},
		{
			title: '订单状态',
			dataIndex: 'is_pay',
			key: 'is_pay',
			width: 80,
		},
		{
			title: '支付时间',
			dataIndex: 'pay_time',
			key: 'pay_time',
			width: 100,
		},
		{
			title: '支付方式',
			dataIndex: 'payment',
			key: 'payment',
			width: 80,
		},
		{
			title: '订单号',
			dataIndex: 'order_no',
			key: 'order_no',
			width: 100,
		},
	];

	const getFee = (ev: any) => {
		console.log('sdsad', ev.target.id);
		let c = ev.target.id.split(',');
		let fee = c[1];
		setFee(fee);
		setOrderUuid(c[0]);
	};

	const getOneOrder = async (value: any) => {
		let orderState = '';
		const res = await orderDetail({
			uuid: value.orderUuid,
		});
		if (res.err === 0) {
			if (res.data.is_refund !== '6') {
				if (res.data.is_pay) {
					switch (res.data.is_refund) {
						case 1:
							orderState = '退款处理中';
							break;
						case 2:
							orderState = '退款成功';
							break;
						case 3:
							orderState = '退款关闭';
							break;
						case 4:
							orderState = '退款异常';
							break;
						case 5:
							orderState = '申请退款失败';
							break;
						default:
							orderState = '支付成功';
					}
				} else {
					orderState = '未支付';
				}
			} else {
				orderState = '订单关闭';
			}
			res.data.is_pay = orderState;

			setOrder(res.data);
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'error' });
		}
		setVisible(true);
	};

	const getMeetingName = async () => {
		const res = await findMeetingName({});
		if (res.err === 0) {
			setNames(res.data);
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'error' });
		}
	};

	const getHand = (ev: any) => {
		setUUid(ev);
		if (actionRef.current) {
			actionRef.current.reloadAndRest();
		}
	};

	const getKaishiDate = (date: any, dateToString: any) => {
		console.log('开始' + dateToString);
		setStartDay(dateToString[0]);
		setEndDay(dateToString[1]);
	};

	const getEmail = (ev: any) => {
		setEmail(ev.target.value);
	};
	const tableSearchBarRender = () => [
		<Select defaultValue={uuid} onChange={getHand}>
			<Select.Option key={''} value={''}>
				全部订单
			</Select.Option>
			{names.map((n: any, index: any) => (
				<Select.Option key={n.uuid} value={n.uuid}>
					{n.name}
				</Select.Option>
			))}
		</Select>,
		<li>
			{startDay === null || startDay === undefined || startDay === '' ? (
				<RangePicker
					format="YYYY-MM-DD"
					placeholder={['请选择开始时间', '请选择结束时间']}
					onChange={getKaishiDate}
				/>
			) : (
				<RangePicker
					format="YYYY-MM-DD"
					placeholder={['请选择开始时间', '请选择结束时间']}
					onChange={getKaishiDate}
					value={[moment(startDay, 'YYYY-MM-DD'), moment(endDay, 'YYYY-MM-DD')]}
				/>
			)}
		</li>,
		<Input placeholder="请输入账号" onInput={getEmail} />,
		<Button
			type="primary"
			onClick={() => {
				actionRef.current.reload();
			}}
		>
			搜索
		</Button>,
	];

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				rowKey={'id'}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await meetingOrderList({
						page: params.current,
						size: params.pageSize,
						search: search,
						kind: 'meeting',
						meeting_uuid: uuid,
						email: email,
						startDay:
							startDay === null || startDay === undefined || startDay === '' ? '' : startDay.toString(),
						endDay: endDay === null || endDay === undefined || endDay === '' ? '' : endDay.toString(),
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="订单详情"
				visible={visible}
				onOk={() => {
					setVisible(false);
				}}
				onCancel={() => {
					setVisible(false);
				}}
				cancelText={'关闭'}
				okButtonProps={{ hidden: true }}
			>
				<div className="modal-content">
					<table className="table table-hover">
						<tr key={order.uuid}>
							<td>会议室名称</td>
							<td>{order.name}</td>
						</tr>
						<tr>
							<td>预订人账号</td>
							<td>{order.email}</td>
						</tr>
						<tr>
							<td>预定时间</td>
							<td>
								{order.start_time} - {order.end_time}
							</td>
						</tr>
						<tr>
							<td>时间段</td>
							<td>{order.hours}</td>
						</tr>
						<tr>
							<td>订单价格</td>
							<td>{order.total_price / 100}</td>
						</tr>
						<tr>
							<td>下单时间</td>
							<td>{order.order_time}</td>
						</tr>
						<tr>
							<td>订单状态</td>
							<td>{order.is_pay}</td>
						</tr>
						<tr>
							<td>支付时间</td>
							<td>{order.pay_time}</td>
						</tr>
						<tr>
							<td>支付方式</td>
							<td>{order.payment}</td>
						</tr>
						<tr>
							<td>订单号</td>
							<td>{order.order_no}</td>
						</tr>
						<tr>
							<td>参会人数</td>
							<td>{order.attendance}人</td>
						</tr>
						<tr>
							<td>茶水</td>
							<td>{order.tea}份</td>
						</tr>
						<tr>
							<td>矿泉水</td>
							<td>{order.water}瓶</td>
						</tr>
						<tr>
							<td>纪要纸笔</td>
							<td>{order.paper_pen}份</td>
						</tr>
						<tr>
							<td>是否需要投影仪</td>
							<td>{order.need_projection === false ? '不需要' : '需要'}</td>
						</tr>
						<tr>
							<td>是否需要电脑</td>
							<td>{order.need_computer === false ? '不需要' : '需要'}</td>
						</tr>
					</table>
				</div>
			</Modal>
		</div>
	);
};
export default MeetingOrderList;
