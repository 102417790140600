import Api from '../data/API';

export async function getToken(params: any) {
	return Api.postWithAuth('/api/user/get_token', params);
}

export async function getAdminToken(params: any) {
	return Api.postWithAuth('/api/user/admin_get_token', params);
}

export async function tokenSignIn(params: any) {
	return Api.post('/api/user/admin_token_sign_in', params);
}

export async function getLoginCode(params: any) {
	return Api.postWithAuth('/api/user/get_login_code', params);
}
export async function saasRegister(params: any) {
	return Api.postWithAuth('/api/user/saas_register', params);
}
export async function saasRetrieve(params: any) {
	return Api.postWithAuth('/api/user/saas_retrieve', params);
}
export async function saasUserRegister(params: any) {
	return Api.postWithAuth('/api/user/saas_user_register', params);
}
export async function judgeAccount(params: any) {
	return Api.postWithAuth('/api/user/judge_account', params);
}
export async function loginWithSms(params: any) {
	return Api.postWithAuth('/api/login/adminLogin_with_sms', params);
}
export async function change_AdminProject(params: any) {
	return Api.postWithAuth('/api/wuye/change_AdminProject', params);
}
