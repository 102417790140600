import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import {
	Button,
	Card,
	Col,
	Dropdown,
	Form,
	Input,
	InputNumber,
	Menu,
	message,
	Modal,
	Row,
	Select,
	Space,
	Statistic,
	Switch,
	TimePicker,
	Tooltip,
	TreeSelect
} from 'antd';
import { ActionType } from '@ant-design/pro-table';
import { NavLink, Route } from 'react-router-dom';
import AuthorizedView from '../../../util/AuthorizedView';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import {
	add2GDevice,
	asyncFaceDeviceImage,
	copyDevice,
	getDevices,
	getUserDevicesWithUseKeys0,
	setFaceParams,
	deleteDeviceList,
	getUserDeviceStateCount, cpEkey
} from '../../../services/ZhiNengMenJinService';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons/lib';
import API from '../../../data/API';
import { updateDeviceInfo } from '../../../services/WuyeService';
import SendKeyModal from '../components/SendKeyModal';
import CustomUploadImage from '../../../components/CustomUploadImage';
import GsTreeSelect from '../../../components/GsTreeSelect';
import EquipmentStatusChart from '../components/EquipmentStatusChart';
import SocketContext from '../../../context/SocketContext'
import { DataContext } from '../../controlPlatform/CurrentPlatform';
import { IgnorePlugin } from 'webpack';

const DevicesList: React.FC = () => {
	let msg = require('../../../util/SuspensionMsg');
	let moment = require('moment');
	const Search = Input.Search;
	const Option = Select.Option;
	const [addForm] = Form.useForm<FormInstance>();
	const [updateForm] = Form.useForm<FormInstance>();
	const [paramForm] = Form.useForm<FormInstance>();
	const [copyForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const actionRef = useRef<ActionType>();
	const [addVisible, setAddVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	const [paramsVisible, setParamsVisible] = useState(false);
	const [deviceSelect, setDeviceSelect] = useState('全部设备');
	const [deviceData, setDeviceData] = useState({});
	const [search, setSearch] = useState('');
	const [isShow, setIsShow] = useState(true);
	const [isUseNeedReceive, setIsUseNeedReceive] = useState(false);
	const [needBindImsi, setNeedBindImsi] = useState(false);
	const [needBindPhone, setNeedBindPhone] = useState(false);
	const [needUserCertified, setNeedUserCertified] = useState(false);
	const [xcxAvailable, setXcxAvailable] = useState(false);
	const [copyVisible, setCopyVisible] = useState(false);
	const [devices, setDevices] = useState([]);
	const [allDevices, setAllDevices] = useState([]);
	const [sendDeviceVisible, setSendDeviceVisible] = useState(false);
	const [deviceId, setDeviceId] = useState('');
	let [fileList, setFileList] = useState<any[]>([]);
	const [faceDeviceName, setFaceDeviceName] = useState('');
	const [visible, setVisiable] = useState(false);
	const [data, setData] = useState([]);
	const [menuKey, setMenuKey] = useState<string>('1');
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [iotInfoVisible, setIotInfoVisible] = useState(false);
	const [icid, setIcid] = useState('');
	const [jiHuoShiJian, setJiHuoShiJian] = useState('');
	const [simServiceTime, setSimServiceTime] = useState('');
	const [selectDeviceId, setSelectDeviceId] = useState('');
	const [cpModalVisible, setCpModalVisible] = useState(false);
	const [targetDeviceId, setTargetDeviceId] = useState('');
	const [targetDeviceName, setTargetDeviceName] = useState('');
	const [batch, setbatch] = useState(false)
	const [deviceIds, setdeviceIds] = useState("")
	const [equipmentStatusVisible, setEquipmentStatusVisible] = useState(false);
	const [errVisible, seterrVisible] = useState(false);
	const [errData, seterrData] = useState([]);
	const [cpForm] = Form.useForm<FormInstance>();
	const socket: any = useContext(SocketContext);

	useEffect(() => {
		getDeviceList().then();
		getDeviceCount().then();
		getDeviceAllList().then();
		if (socket != null) {

		}
		return () => {
			if (socket != null) {
				console.log('deviceList:::off::message', data)
			}
		}
	}, []);


	const errorColumns = [
		{
			title: '设备',
			key: 'deviceName',
			dataIndex: 'deviceName',
		},
		{
			title: 'deviceId',
			key: 'deviceId',
			dataIndex: 'deviceId',
		},
        {
			title: "错误原因",
			key: "errMsg",
			dataIndex: "errMsg",
			render: (text: string) => {
				return <div style={{ color: "red" }}>{text}</div>;
			}
		}

    ]

	const proColumns = [
		{
			title: '设备名称',
			width: 200,
			dataIndex: 'device_name',
			render: (text: any, item: any, index: any) => (
				<a
					onClick={() => {
						openModal(item, 'update',false);
					}}
					id={index}
				>
					{text}
				</a>
			),
			renderFormItem: (_: any, { type, defaultRender, ...rest }: any, form: any) => {
				if (type === 'form') {
					return null;
				}
				const status = form.getFieldValue('state');
				if (status !== 'open') {
					return <Input {...rest} placeholder="请输入设备名称"/>;
				}
				return defaultRender(_);
			}
		},
		{
			title: '设备ID',
			width: 100,
			dataIndex: 'device_id'
		},
		{
			title: '设备类型',
			width: 100,
			dataIndex: 'type_code',
			render: (typeCode: any) => {
				switch (typeCode) {
					case '0201':
						return '蓝牙门禁';
					case '0113':
					case '0601':
						return '智能门锁';
					case '0701':
						return '人脸识别门禁';
					case '0801':
						return '联网门禁';
					case '1001':
						return '智能垃圾桶';
					case '0901':
						return '玻璃门智能锁';
					default:
						return typeCode;
				}
			}
		},
		{
			title: '在线状态',
			width: 100,
			dataIndex: 'online',
			render: (online: any, item: any) =>
				item.type_code === '0701' ? (
					online === true ? (
						<span style={{ color: 'green' }}>在线</span>
					) : (
						<span style={{ color: 'red' }}>离线</span>
					)
				) : (
					'-'
				)
		},
		{
			title: '设备电量',
			width: 100,
			dataIndex: 'device_battery',
			tip: '仅电池供电类设备会显示电量',
			render: (batt: number, row: any, index: number) => {
				if (row.type_code === '0601') {
					if (batt >= 450) {
						return '充足';
					} else if (batt >= 385) {
						return '中等';
					} else {
						return <span style={{ color: 'red' }}>低电</span>;
					}
				}
				return '-';
			}
		},
		{
			title: '权限',
			width: 100,
			tip: '当前账号用户拥有的该设备权限',
			dataIndex: 'key_type',
			render: (keyType: any) =>
				keyType === '111'
					? '管理员'
					: keyType === '010'
					? '普通用户'
					: keyType === '001'
						? '查看'
						: keyType === '011'
							? '使用和查看'
							: keyType === '101'
								? '管理和使用'
								: '管理'
		},
		{
			title: '设备型号',
			width: 100,
			dataIndex: 'faceDeviceName'
		},
		{
			title: '固件版本',
			width: 150,
			dataIndex: 'faceDeviceVersion'
		},
		{
			title: '操作',
			width: 200,
			fixed: 'right',
			render: (text: any, item: any, index: any) => {
				if (item.key_type.charAt(0) === '1') {
					return (
						<Space>
							<a onClick={() => {
								openModal(item, 'sendDevice',false);
							}}>发送钥匙</a>
							<NavLink
								to={{
									pathname: '/zhineng-menjin/mapping-list',
									state: { deviceId: item.device_id }
								}}
							>
								钥匙列表
							</NavLink>
							<Dropdown
								overlay={
									<Menu>
										{
											item.type_code === '0701' && <Menu.Item>
												<a
													onClick={() => {
														setTargetDeviceId(item.device_id);
														setEquipmentStatusVisible(true);
														setTargetDeviceName(item.device_name);
													}}
												>
													设备上下线
												</a>
											</Menu.Item>
										}

										<Menu.Item>
											<a
												onClick={() => {
													copyShowModal(item);
												}}
											>
												转移权限
											</a>
										</Menu.Item>
										<Menu.Item>
											<a
												onClick={() => {
													setTargetDeviceId(item.device_id);
													setCpModalVisible(true);
													setTargetDeviceName(item.device_name);
												}}
											>
												复制权限
											</a>
										</Menu.Item>
										{
											item.icid && <Menu.Item> <a onClick={() => {
												openModal(item, 'openIotInfo',false);
											}}>物联卡</a></Menu.Item>
										}

									</Menu>
								}
							>
								<a style={{ textDecoration: 'none' }}>
									更多
								</a>
							</Dropdown>
						</Space>
					);
				}
				return (
					<Space>
						{
							item.icid && <a onClick={() => {
								openModal(item, 'openIotInfo',false);
							}}>物联卡</a>
						}
					</Space>
				);
			}
		}
	];
	const infoColumns = [
		{
			title: '设备名称',
			dataIndex: 'devicename'
		},
		{
			title: '设备ID',
			dataIndex: 'deviceid'
		},
		{
			title: '设备类型',
			dataIndex: 'typecode',
			render: (typeCode: any) => {
				switch (typeCode) {
					case '0201':
						return '蓝牙门禁';
					case '0113':
					case '0601':
						return '智能门锁';
					case '0701':
						return '人脸识别门禁';
					case '0801':
						return '联网门禁';
					case '1001':
						return '智能垃圾桶';
					case '0901':
						return '玻璃门智能锁';
					default:
						return typeCode;
				}
			}
		},
		{
			title: '在线状态',
			dataIndex: 'online',
			render: (online: any, item: any) =>
				item.type_code === '0701' ? (
					online === true ? (
						<span style={{ color: 'green' }}>在线</span>
					) : (
						<span style={{ color: 'red' }}>离线</span>
					)
				) : (
					'-'
				)
		},
		{
			title: '设备电量',
			dataIndex: 'devicebattery',
			tip: '仅电池供电类设备会显示电量',
			render: (batt: number, row: any, index: number) => {
				if (row.typecode === '0601') {
					if (batt >= 450) {
						return '充足';
					} else if (batt >= 385) {
						return '中等';
					} else {
						return <span style={{ color: 'red' }}>低电</span>;
					}
				}
				return '-';
			}

		},
		{
			title: '权限',
			tip: '当前对该设备拥有的权限',
			dataIndex: 'keytype',
			render: (keyType: any) =>
				keyType === '111'
					? '管理员'
					: keyType === '010'
					? '普通用户'
					: keyType === '001'
						? '查看'
						: keyType === '011'
							? '使用和查看'
							: keyType === '101'
								? '管理和使用'
								: '管理'
		},
		{
			title: '设备型号',
			dataIndex: 'faceDevicename'
		},
		{
			title: '固件版本',
			dataIndex: 'facedeviceversion'
		}
	];

	const getDeviceList = async () => {
		const res = await getDevices({});
		if (res.err !== 0) {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		setDevices(res.data);
	};
	const getDeviceAllList = async () => {
		const res = await getDevices({ isAdmin: false });
		if (res.err !== 0) {
			return;
		}
		setAllDevices(res.data);
	};
	const copyShowModal = (item: any) => {
		console.log('item', item);
		copyForm.setFieldsValue({
			deviceId: item.device_id
		});
		setCopyVisible(true);
	};

	const openModal = (record: any, status: any,batch) => {
		console.log('record::::', record);
		if (!batch) {
			setdeviceIds("")
		}
		setbatch(batch)
		setFaceDeviceName(record.faceDeviceName);
		setDeviceId(record.device_id);
		if (status === 'update') {
			setIsUseNeedReceive(record.is_use_need_receive);
			setNeedBindImsi(record.need_bind_imsi);
			setNeedBindPhone(record.need_bind_phone);
			setXcxAvailable(record.xcx_available);
			setNeedUserCertified(record.need_user_certified);
			if (record.type_code === '0701') {
				console.log('signalTime', record.signalTime, record.signalTime < 0)
				paramForm.setFieldsValue({
					...record,
					password: record.password,
					deviceId: record.device_id,
					deviceName: record.device_name,
					restartTime: moment(record.restartTime || '00:00', 'HH:mm'),
					normallyOpen: record.signalTime < 0
				});

				if (record.logo !== '') {
					let list = [
						{
							uid: '1',
							url: record.logo,
							name: 'logo.png',
							status: 'done'
						}
					];
					setFileList(list);
				} else {
					setFileList([]);
				}

				setParamsVisible(true);
			} else {
				const FormData: any = {
					description: record.device_name,
					isUseNeedReceive: record.is_use_need_receive,
					needBindImsi: record.need_bind_imsi,
					needBindPhone: record.need_bind_phone,
					bindNum: record.bind_num,
					deviceId: record.device_id,
					xcxAvailable: record.xcx_available,
					needUserCertified: record.need_user_certified,
					mappingName: record.mapping_name
				};
				updateForm.setFieldsValue(FormData);
				setUpdateVisible(true);
			}
		} else if (status === 'sendDevice') {
			setSendDeviceVisible(true);
		} else if (status === 'openIotInfo') {
			setIotInfoVisible(true);
			setIcid(record.icid);
			setSimServiceTime(record.SIMServiceTime);
			setJiHuoShiJian(record.jiHuoShiJian);
		}
	};

	const getDeviceCount = async () => {
		const res = await getUserDeviceStateCount({});
		console.log('deviceData::', res);
		if (res.err !== 0) {
			message.warning(res.msg);
			return;
		}
		setDeviceData(res.deviceData);
		setIsShow(false);
	};

	const loadList = async (params: any) => {
		console.log('>>>>>>> request', params);
		let deviceSelect1: any = deviceSelect;
		if (deviceSelect === '全部设备') {
			deviceSelect1 = null;
		}
		let param = {
			page: params.current,
			size: params.pageSize,
			deviceIds: selectDeviceId,
			device_name: search,
			deviceSelect: deviceSelect1
		};
		let res = await getUserDevicesWithUseKeys0(param);
		if (res.err !== 0) {
			msg.suspensionMsg({ content: res.msg });
			return;
		}

		return {
			data: res.devices,
			total: res.count,
			success: true
		};
	};
	const tableExtraRender = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							loading={isShow}
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>设备总数</span>,
								''
							)}
							value={(deviceData as any).allDevice || 0}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>台</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							loading={isShow}
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>在线设备</span>,
								''
							)}
							value={(deviceData as any).onlineDevice || 0}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>台</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card className={'clickItem'} style={{ backgroundColor: '#E82D2D' }}
						  bodyStyle={{ padding: '10px 15px' }} onClick={() => {
						setVisiable(true);
						setData((deviceData as any).offLineData);
					}}>
						<Statistic
							loading={isShow}
							title={renderStatisticTitle(
								<span style={{ color: '#fff', fontSize: 16 }}>离线设备</span>,
								'',
								{ color: '#fff' }
							)}
							valueStyle={{ color: '#fff', fontSize: 30 }}
							value={(deviceData as any).offlineDevice || 0}
							suffix={<div style={{ color: '#fff', fontSize: 16 }}>台</div>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card className={'clickItem'} style={{ backgroundColor: '#E82D2D' }}
						  bodyStyle={{ padding: '10px 15px' }} onClick={() => {
						setVisiable(true);
						setData((deviceData as any).lowPowerData);
					}}>
						<Statistic
							loading={isShow}
							title={renderStatisticTitle(
								<span style={{ color: '#fff', fontSize: 16 }}>低电设备</span>,
								'',
								{ color: '#fff' }
							)}
							valueStyle={{ color: '#fff', fontSize: 30 }}
							value={(deviceData as any).lowPower || 0}
							suffix={<div style={{ color: '#fff', fontSize: 16 }}>台</div>}
						/>
					</Card>
				</Col>
			</Row>
		);
	};

	const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>{element}</div>
				{
					msg && <div>
						<Tooltip title={msg}>
							<ExclamationCircleOutlined style={iconStyle}/>
						</Tooltip>
					</div>
				}

			</div>
		);
	};

	/**
	 * 同步人脸照片
	 **/
	const asyncFaceImage = (selectedRowKeys: any) => {
		if (selectedRowKeys.length === 0) {
			message.warn('请选择设备');
			return;
		}
		Modal.confirm({
			width: 600,
			title: '提示',
			content: (
				<>
					<p>同步权限操作说明：</p>
					<p>
						1.
						同步权限只对人脸识别设备（离线版）有效，将把该设备未同步的所有用户权限（权限有效期、人脸识别照片等）同步到设备上
					</p>
					<p>2. 如果已经同步过，则不会同步</p>
					<p>3. 如果设备当前离线，则不会同步权限</p>
					<p>
						4.
						同步权限操作成功，表示同步命令的发送成功，并不代表实际同步成功，查看实际是否同步成功，请到钥匙授权列表页面查看
					</p>
				</>
			),
			onOk: async () => {
				let data = {
					deviceIds: selectedRowKeys.toString()
				};
				let rsp = await asyncFaceDeviceImage(data);
				if (rsp.err === 0) {
					message.success(rsp.msg);
				} else {
					message.success(rsp.msg);
				}
			}
		});
	};

	const batchUpdate = (selectedRowKeys: any) => {
		if (selectedRowKeys.length == 0) {
			message.warn('请选择设备');
			return
		}
		const items = selectedRowKeys.filter(item => {
			return item.type_code == '0701'
		})
		if (items.length == 0) {
			message.warning("请选择人脸设备")
			return
		}
		const deviceIds = []
		items.forEach(element => {
			deviceIds.push(element.device_id)
		});
		console.log("selectedRowKeys", items)

		setdeviceIds(deviceIds.toString())
		openModal(items[0],'update',true)
	 }
	const tableSearchBarRender = () => [
		<Select
			showSearch={true}
			style={{ width: 200 }}
			placeholder="请选择"
			optionFilterProp="children"
			onChange={(value: any) => {
				// setIsShow(true);
				setDeviceSelect(value);
				actionRef.current.reloadAndRest();
			}}
			defaultValue={''}
		>
			<Option value="">全部类型</Option>
			<Option value="0201">蓝牙门禁</Option>
			<Option value="0601">智能门锁</Option>
			<Option value="0901">玻璃门智能锁</Option>
			<Option value="0701">人脸识别门禁</Option>
			<Option value="0801">联网门禁</Option>
			<Option value="0000">二维码门禁</Option>
		</Select>,
		<GsTreeSelect
			treeData={allDevices}
			treeNodeFilterProp={'title'}
			defaultValue={selectDeviceId === '' ? [] : selectDeviceId.split(',')}
			multiple={true}
			onChange={(ev: any) => {
				if (ev) {
					setSelectDeviceId(ev.toString());
				}

			}}
		/>,
		<Input
			placeholder="设备名称"
			onChange={(value: any) => {
				setSearch(value.target.value);

			}}
		/>,
		<Button type={'primary'} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];
	const openAddModal = () => {
		addForm.resetFields();
		setAddVisible(true);
	};
	const tableOperationsBarRender = () => [
		<Button type={'primary'} onClick={openAddModal}>
			添加设备
		</Button>
	];

	const addItem = () => {
		addForm.validateFields().then(async (data: any) => {
			const formData: any = {
				device_id: data.deviceId,
				device_name: data.deviceName,
				device_typeCode: data.typeCode,
				device_version: data.deviceVersion,
				userId: API.userId
			};
			const res = await add2GDevice(formData);
			if (res.status === 1) {
				msg.suspensionMsg({ content: '添加成功！', type: 'success' });
				setAddVisible(false);
				addForm.resetFields();
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '添加失败！', type: 'error' });
			}
			console.log('添加设备:::', formData);
		});
	};

	const updateItem = () => {
		updateForm.validateFields().then(async (data: any) => {
			console.log('updateData:::,', data);
			setIsShow(false);
			const formData: any = {
				device_id: data.deviceId,
				description: data.description,
				isUseNeedReceive: isUseNeedReceive,
				needBindImsi: needBindImsi,
				needBindPhone: needBindPhone,
				bindNum: data.bindNum === 0 ? 1 : data.bindNum,
				xcxAvailable: xcxAvailable,
				needUserCertified: needUserCertified,
				mappingName: data.mappingName
			};
			const res = await updateDeviceInfo(formData);
			if (res.err === 0) {
				setUpdateVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
				msg.suspensionMsg({ content: '更新成功', type: 'success' });
			} else {
				msg.suspensionMsg({ content: '更新失败', type: 'error' });
			}
		});
	};
	const setFaceParam = () => {
		paramForm.validateFields().then(async (data: any) => {
			console.log('setParams', data);
			// if (data.online === null || !data.online) {
			//     message.warn("该设备处于离线状态，无法设置参数");
			//     return;
			// }
			setConfirmLoading(true);
			let params = {
				...data,
				deviceIds:deviceIds
			};
			if (data.threshold != undefined) {
				params = {
					...params,
					livenessScore: data.threshold / 100.0
				};
			}
			if (data.isVerifyLive != undefined) {
				params = {
					...params,
					recognitionMode: data.isVerifyLive ? 1 : 0
				};
			}
			if (data.restartTime != undefined) {
				params = {
					...params,
					restartTime: data.restartTime.format('HH:mm')
				};
			}
			if (data.normallyOpen) {
				params = {
					...params,
					signalTime: -1
				}
			} else {
				params = {
					...params,
					signalTime: 2
				}
			}
			let rsp = await setFaceParams(params);
			if (rsp.err === 0) {
				setdeviceIds("")

				setTimeout(() => {
					message.success("发送成功");
					setConfirmLoading(false);
					if (actionRef.current) {
						actionRef.current.reload();
					}
					setParamsVisible(false);
					const errCount = rsp.data.errCount || 0
					if (errCount != 0) {
						seterrVisible(true)
						seterrData(rsp.data.errList || [])
					}
				}, 1500);

			} else {
				message.error(rsp.msg);
				setConfirmLoading(false);
				setParamsVisible(false);
			}

		});
	};

	const copyhandleOk = async () => {
		copyForm.validateFields().then(async (data: any) => {
			console.log('copyData::::', data);
			Modal.confirm({
				title: '提示',
				content: '转移后会删除该设备的所有权限,您确认转移吗？',
				onOk: async () => {
					const res = await copyDevice({
						newDeviceId: data.deviceValue,
						oldDeviceId: data.deviceId
					});
					if (res.err !== 0) {
						msg.suspensionMsg({ content: res.msg });
						return;
					}
					msg.suspensionMsg({ content: '转移成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
					setCopyVisible(false);
				}
			});

		});
	};

	const uploadButton = (
		<div>
			<PlusOutlined/>
			<div style={{ marjiaginTop: 8 }}>上传</div>
		</div>
	);

	const render = () => (fileList.length >= 1 ? null : uploadButton);

	const handleChange = (list: any) => {
		if (list.file.status === 'done') {
			setFileList(list.fileList);
			console.log(`${list.file.name}`);
			console.log('企业图标', list.file.response.url, list.file.response.data[0]);
			paramForm.setFieldsValue({
				logo: list.file.response.data[0]
			});
		} else if (list.file.status === 'error') {
			msg.suspensionMsg({ content: `${list.file.name} 上传文件失败`, type: 'danger' });
		} else if (list.file.status === 'removed') {
			fileList = fileList.filter((item: any) => item.status !== 'removed');
			setFileList([]);
		}
	};
	const rowSelection = {
		preserveSelectedRowKeys: true
	};
	const delectDevice = (selectedRowsKeys: any) => {
		Modal.confirm({
			title: '提示',
			content: '该操作将删除该设备以及所有已经授权的用户钥匙，是否删除？',
			okText: '删除',
			okType: 'danger',
			onOk: async () => {
				let data = {
					device_ids: selectedRowsKeys.toString()
				};
				let rsp = await deleteDeviceList(data);
				if (rsp.err === 0) {
					message.success(rsp.msg);
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error(rsp.msg);
				}
			}
		});
	};

	const renderFaceDeviceSetting = (e: string) => {
		switch (e) {
			case '1':
				return <>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col hidden={batch} span={10}>
							<FormItem label={'设备名称'} name="deviceName" rules={[{ required: true }]}>
								<Input style={{ width: '100%' }} placeholder="请输入"/>
							</FormItem>
						</Col>
						<Col span={10}>
							<FormItem
								label={'设备密码'}
								name="password"
								rules={[{ required: true }]}
							>
								<Input/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={10}>
							<FormItem
								valuePropName={'checked'}
								label={'定时重启'}
								name="isRestart"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={10}>
							<FormItem label={'重启时间'} name="restartTime" rules={[{ required: true }]}>
								<TimePicker format={'HH:mm'}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'middle'}>
						<Col span={10}>
							<FormItem label={'公司名称'} name="companyName" rules={[{ required: true }]}>
								<Input/>
							</FormItem>
						</Col>
						<Col span={10}>
							<FormItem valuePropName={'checked'} label={'logo'} name="logo">
								<CustomUploadImage
									key={new Date().getTime()}
									cdn={'public'}
									listType="picture-card"
									defaultFileList={fileList.length > 0 ? fileList : null}
									onChange={handleChange}
									render={render()}
								/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={10}>
							<FormItem tooltip={'当识别成功时，上传抓拍的人脸图像'} valuePropName={'checked'} label={'开启人脸识别日志'}
									  name="uploadDetectRecord">
								<Switch/>
							</FormItem>
						</Col>
						<Col span={10}>
							<FormItem tooltip={'当识别失败时，上传抓拍的人脸图像'} valuePropName={'checked'} label={'开启陌生人抓拍'}
									  name="strangerCapture">
								<Switch/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={10}>
							<FormItem label={'方向'} name="direction">
								<Select>
									<Select.Option value={'进'}>进</Select.Option>
									<Select.Option value={'出'}>出</Select.Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'middle'}>
						<Col span={10}>
							<Button type={'primary'} loading={confirmLoading} onClick={setFaceParam}>保存</Button>
						</Col>
					</Row>
				</>;
			case '2':
				return <>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						height: 30,
						alignItems: 'center',
						marginBottom: 15
					}}>
						<div style={{ width: 4, height: 12, backgroundColor: '#1890FF', borderRadius: 10 }}/>
						<div style={{ color: '#141414', fontSize: 14, marginLeft: 5 }}>
							识别设置
						</div>
					</div>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem label={'识别模式'} name="activeType" rules={[{ required: true }]}>
								<Select>
									<Select.Option value={0}
												   disabled={faceDeviceName !== 'GW-S8TC' && faceDeviceName !== 'GS-R9T'}>人证识别</Select.Option>
									<Select.Option value={1}>人脸识别</Select.Option>
									<Select.Option value={2}
												   disabled={faceDeviceName !== 'GW-S8TC' && faceDeviceName !== 'GS-R9T'}>人证或人脸</Select.Option>
									<Select.Option value={3}
												   disabled={faceDeviceName !== 'GW-S8TC' && faceDeviceName !== 'GS-R9T'}>人脸+人证</Select.Option>
								</Select>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'活体检测'}
								name="isVerifyLive"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem label={'识别阈值'} name="threshold" rules={[{ required: true }]}>
								<InputNumber min={50} max={100} step={5}/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'最小人脸'} name="minimumFace" rules={[{ required: true }]}>
								<Select>
									<Select.Option value={'50'}>2米</Select.Option>
									<Select.Option value={'70'}>1.5米</Select.Option>
									<Select.Option value={'90'}>1米</Select.Option>
									<Select.Option value={'120'}>0.5米</Select.Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<FormItem label={'识别间隔（秒）'} name="recogInterval" rules={[{ required: true }]}>
							<InputNumber min={1} max={10} step={1}/>
						</FormItem>
					</Row>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						height: 30,
						alignItems: 'center',
						marginBottom: 15
					}}>
						<div style={{ width: 4, height: 12, backgroundColor: '#1890FF', borderRadius: 10 }}/>
						<div style={{ color: '#141414', fontSize: 14, marginLeft: 5 }}>
							门禁设置
						</div>
					</div>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'关联开启门禁'}
								tooltip={'开启后，识别成功后将联动输出开门信号'}
								name="openLock"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'是否常开'}
								tooltip={'道闸常开'}
								valuePropName={'checked'}
								name="normallyOpen"
								rules={[{ required: true }]}
							>
								<Switch />
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<FormItem
								label={'信号时间(秒)'}
								tooltip={'开门信号输出时间'}
								name="signalTime"
								rules={[{ required: true }]}
							>
								<InputNumber  step={1} />
							</FormItem>
						</Col>
					</Row>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						height: 30,
						alignItems: 'center',
						marginBottom: 15
					}}>
						<div style={{ width: 4, height: 12, backgroundColor: '#1890FF', borderRadius: 10 }}/>
						<div style={{ color: '#141414', fontSize: 14, marginLeft: 5 }}>
							位置校对设置
						</div>
					</div>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'位置验证'}
								name="isVerifyPosition"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'验证失败是否开门'}
								name="positionYiChangShiFouTongGuo"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
					</Row>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						height: 30,
						alignItems: 'center',
						marginBottom: 15
					}}>
						<div style={{ width: 4, height: 12, backgroundColor: '#1890FF', borderRadius: 10 }}/>
						<div style={{ color: '#141414', fontSize: 14, marginLeft: 5 }}>
							测温设置
						</div>
					</div>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'测温'}
								tooltip={'仅对测温版设备有效，开启后识别同时将测温'}
								name="isTemperatureMeasurement"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'正常温度阈值'} name="temperatureScore" rules={[{ required: true }]}>
								<InputNumber min={35} max={38} step={0.1}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'体温异常可通过'}
								name="wenDuYiChangShiFouTongGuo"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'温度补偿值'}
								name="tiwenOffset"
								rules={[{ required: true }]}
							>
								<InputNumber/>
							</FormItem>
						</Col>
					</Row>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						height: 30,
						alignItems: 'center',
						marginBottom: 15
					}}>
						<div style={{ width: 4, height: 12, backgroundColor: '#1890FF', borderRadius: 10 }}/>
						<div style={{ color: '#141414', fontSize: 14, marginLeft: 5 }}>
							健康宝设置
						</div>
					</div>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'健康宝验证'}
								name="isHealth"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'无网络关闭健康码验证'}
								name="offlineAutoCloseHealth"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'健康码异常可通过'}
								name="jianKangMaYiChangShiFouTongGuo"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'健康码获取超时时间'}
								name="jianKangMaTimeOut"
								rules={[{ required: true }]}
							>
								<InputNumber max={99} min={1}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								valuePropName={'checked'}
								label={'是否查验核酸证明'}
								name="acidCheck"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'核酸有效天数'}
								name="acidNum"
								rules={[{ required: true }]}
							>
								<InputNumber max={99} min={1}/>
							</FormItem>
						</Col>
					</Row>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						height: 30,
						alignItems: 'center',
						marginBottom: 15
					}}>
						<div style={{ width: 4, height: 12, backgroundColor: '#1890FF', borderRadius: 10 }}/>
						<div style={{ color: '#141414', fontSize: 14, marginLeft: 5 }}>
							语音反馈
						</div>
					</div>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem label={'通过提示音'} name="successTips" rules={[{ required: true }]}>
								<Input/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'识别失败提示音'} name="errorTips" rules={[{ required: true }]}>
								<Input/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem label={'无身份证提示音'} name="noIdCardTips" rules={[{ required: true }]}>
								<Input/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'核酸校验过期提示音'} name="acidTip" rules={[{ required: true }]}>
								<Input/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem label={'成功描述语'} name="msg" rules={[{ required: true }]}>
								<Input/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'语音音量大小'} name="volume" rules={[{ required: true }]}>
								<InputNumber min={1} max={15}/>
							</FormItem>
						</Col>
					</Row>

					<Row className="margin-top--10" style={{ marginTop: 20 }} gutter={16} justify={'start'}
						 align={'top'}>
						<Col span={12}>
							<Button type={'primary'} loading={confirmLoading} onClick={setFaceParam}>保存</Button>
						</Col>
					</Row>
				</>;
			case '3':
				return <>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={24}>
							<FormItem
								valuePropName={'checked'}
								label={'质量检测'}
								tooltip={'开启质量检测后，对抓拍人脸质量要求较高，请谨慎开启'}
								name="qualityControl"
								rules={[{ required: true }]}
							>
								<Switch/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								label={'模糊度阈值'}
								name="blur"
								tooltip={'人脸图片的模糊度范围，取值范围[0~1]，0 是最清晰，1 是最模糊。可根据场景要求适当减小数值，减少模糊图片的采集情况'}
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'光照阈值'}
								tooltip={'人脸面部的光线要求，取值范围[0~255]，脸部光照的灰度值，0 表示光照不好。对应 SDK 中，YUV 的 Y 分量'}
								name="illumination"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={255} step={1}/>
							</FormItem>
						</Col>

					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								label={'三维旋转之俯仰角度'}
								name="pitch"
								rules={[{ required: true }]}
							>
								<InputNumber min={-90} max={90} step={1}/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'三维旋转之左右旋转角'}
								name="yaw"
								rules={[{ required: true }]}
							>
								<InputNumber min={-90} max={90} step={1}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={24}>
							<FormItem
								label={'平面内旋转角度'}
								name="roll"
								rules={[{ required: true }]}
							>
								<InputNumber min={-90} max={90} step={1}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								label={'左眼被遮挡阈值'}
								tooltip={'左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。'}
								name="leftEye"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								tooltip={'左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。'}
								label={'右眼被遮挡阈值'}
								name="rightEye"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
					</Row>

					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								label={'鼻子被遮挡阈值'}
								tooltip={'左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。'}
								name="nose"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'嘴巴被遮挡阈值'}
								tooltip={'左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。'}
								name="mouth"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<FormItem
								label={'左脸颊被遮挡阈值'}
								tooltip={'左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。'}
								name="leftCheek"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label={'右脸颊被遮挡阈值'}
								tooltip={'左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。'}
								name="rightCheek"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={24}>
							<FormItem
								label={'下巴被遮挡阈值'}
								tooltip={'左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。'}
								name="chinContour"
								rules={[{ required: true }]}
							>
								<InputNumber min={0} max={1} step={0.1}/>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={16} justify={'start'} align={'top'}>
						<Col span={12}>
							<Button type={'primary'} loading={confirmLoading} onClick={setFaceParam}>保存</Button>
						</Col>
					</Row>
				</>;
			default:
				return <></>;
		}
	};

	const cpEkeyOk = async () => {
		let data: any = await cpForm.getFieldsValue();
		data = {
			...data,
			srcDeviceId: data.srcDeviceId.includes(':::') ? data.srcDeviceId.split(':::')[1] : data.srcDeviceId
		};
		if (data.targetDeviceId === data.srcDeviceId) {
			message.error('两台设备为同一台设备');
			return;
		}
		Modal.confirm({
			title: '确认',
			content: `您确认要将该设备钥匙复制给：${targetDeviceName}吗`,
			onOk: async () => {
				let rsp = await cpEkey(data);
				if (rsp.err === 0) {
					setCpModalVisible(false);
					message.success(rsp.msg);
				} else {
					message.error(rsp.msg);
				}

			}
		});
	};
	return (
		<div>
			<AuthorizedView needAuthority={'entrance_guard:device_list:select'}>
				<GSTable
					actionRef={actionRef}
					searchBarRender={tableSearchBarRender()}
					// operationsBarRender={tableOperationsBarRender()}
					rowSelection={rowSelection}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {selectedRowKeys.length} 项
									<a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={16}>
								<AuthorizedView needAuthority={'entrance_guard:device_list:asyncFaceImage'}>
									<a onClick={() => asyncFaceImage(selectedRowKeys)}>同步权限</a>
								</AuthorizedView>
								<AuthorizedView needAuthority={'entrance_guard:device_list:delete'}>
									<a onClick={() => delectDevice(selectedRowKeys)}>删除</a>
								</AuthorizedView>
									<a onClick={() => batchUpdate(selectedRows)}>修改</a>
							</Space>
						);
					}}
					tableExtraRender={tableExtraRender}
					columns={proColumns}
					request={async (params: any, sort: any, filter: any) => {
						return loadList(params);
					}}
					rowKey="device_id"
				/>

				<Modal
					title={'添加设备'}
					onCancel={() => {
						setAddVisible(false);
					}}
					onOk={addItem}
					visible={addVisible}
				>
					<ProxyForm form={addForm}>
						<FormItem label={'设备ID'} name={'deviceId'} rules={[{ required: true }]}>
							<Input/>
						</FormItem>
						<FormItem label={'设备名称'} name={'deviceName'} rules={[{ required: true }]}>
							<Input/>
						</FormItem>
						<FormItem label={'设备版本'} name={'deviceVersion'} rules={[{ required: true }]}>
							<Input/>
						</FormItem>
						<FormItem label={'设备类型'} name={'typeCode'} rules={[{ required: true }]}>
							<Select>
								<Select.Option value={'0801'}>联网门禁</Select.Option>
								<Select.Option value={'0201'}>门禁</Select.Option>
								<Select.Option value={'0601'}>门锁</Select.Option>
								<Select.Option value={'0701'}>人脸门禁</Select.Option>
							</Select>
						</FormItem>
					</ProxyForm>
				</Modal>
				<Modal
					title="详情"
					visible={visible}
					onOk={() => {
						setVisiable(false);
					}}
					onCancel={() => {
						setVisiable(false);
					}}
					width={1200}
					footer={null}
				>
					<GSTable
						columns={infoColumns}
						options={false}
						dataSource={data}
					/>
				</Modal>
				<Modal
					visible={updateVisible}
					title="更新设备"
					onOk={updateItem}
					onCancel={() => {
						setUpdateVisible(false);
					}}
				>
					<ProxyForm form={updateForm}>
						<FormItem label={'deviceId'} name={'deviceId'} hidden={true}>
							<Input/>
						</FormItem>
						<FormItem label={'设备名称'} name={'description'} rules={[{ required: true }]}>
							<Input/>
						</FormItem>
						<FormItem
							label={'钥匙名称'}
							tooltip={{
								title: '钥匙名称是您自己对设备的命名，他人无法看到',
								icon: <ExclamationCircleOutlined/>
							}}
							name={'mappingName'}
						>
							<Input/>
						</FormItem>
						<FormItem label={'是否需要绑定'} name={'isUseNeedReceive'}>
							<Switch
								key={new Date().getTime().toString()}
								checked={isUseNeedReceive}
								onChange={(checked) => {
									setIsUseNeedReceive(checked);
								}}
							/>
						</FormItem>
						<FormItem label={'是否需要绑定IMSI卡'} name={'needBindImsi'}>
							<Switch
								key={new Date().getTime().toString()}
								checked={needBindImsi}
								onChange={(checked) => {
									setNeedBindImsi(checked);
									setIsUseNeedReceive(needBindPhone || checked);
								}}
							/>
						</FormItem>
						<FormItem label={'是否需要绑定手机'} name={'needBindPhone'}>
							<Switch
								key={new Date().getTime().toString()}
								checked={needBindPhone}
								onChange={(checked) => {
									setNeedBindPhone(checked);
									setIsUseNeedReceive(needBindImsi || checked);
								}}
							/>
						</FormItem>
						<FormItem label={'是否需要实名认证'} name={'needUserCertified'}>
							<Switch
								key={new Date().getTime().toString()}
								checked={needUserCertified}
								onChange={(checked) => {
									setNeedUserCertified(checked);
								}}
							/>
						</FormItem>
						<FormItem label={'小程序是否可用'} name={'xcxAvailable'}>
							<Switch
								key={new Date().getTime().toString()}
								checked={xcxAvailable}
								onChange={(checked) => {
									setXcxAvailable(checked);
								}}
							/>
						</FormItem>
						<FormItem label={'绑定次数'} name={'bindNum'}>
							<InputNumber min={1} max={10}/>
						</FormItem>
					</ProxyForm>
				</Modal>
			</AuthorizedView>

			<Modal
				visible={paramsVisible}
				title="设置参数"
				width={800}
				onOk={setFaceParam}
				confirmLoading={confirmLoading}
				onCancel={() => {
					setParamsVisible(false);
				}}
				footer={null}
			>
				<Form form={paramForm}>
					<FormItem hidden={true} name={'deviceId'}/>
					<FormItem hidden={true} name={'online'}/>
					<Row>
						<Col span={4} style={{ borderRight: '10px solid #f0f2f5' }}>
							{/*<div style={{padding: '20px 10px 20px 10px', fontSize: 15, color: 'rgba(0, 0, 0, 0.65)', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{data.projectName}</div>*/}
							<Menu
								onClick={(e: any) => {
									setMenuKey(e.key);
								}}
								style={{ width: '100%' }}
								key={menuKey}
								defaultSelectedKeys={[...menuKey]}
							>
								<Menu.Item key="1">
									基础设置
								</Menu.Item>

								<Menu.Item key="2">
									通行设置
								</Menu.Item>
								<Menu.Item key="3">
									质量检测
								</Menu.Item>
							</Menu>
						</Col>
						<Col span={20} style={{ padding: 20 }}>
							{renderFaceDeviceSetting(menuKey)}
						</Col>
					</Row>

				</Form>
			</Modal>
			{/*复制选择设备*/}
			<Modal
				visible={copyVisible}
				title="转移权限"
				onOk={copyhandleOk}
				onCancel={() => {
					setCopyVisible(false);
				}}
			>
				<ProxyForm form={copyForm}>
					<FormItem label={'deviceId'} name={'deviceId'} hidden={true}>
						<Input/>
					</FormItem>
					<FormItem label={'转移权限到'} name={'deviceValue'}>
						<TreeSelect
							showSearch={true}
							treeNodeFilterProp={'title'}
							style={{ width: 300 }}
							dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
							treeData={devices}
							placeholder="请选择"
							treeDefaultExpandAll={true}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
			{/*发钥匙*/}
			<SendKeyModal
				visible={sendDeviceVisible}
				onCancel={() => {
					setSendDeviceVisible(false);
				}}
				deviceId={deviceId}
			/>

			<Modal title={'物联卡信息'} visible={iotInfoVisible} onCancel={() => {
				setIotInfoVisible(false);
			}} onOk={() => {
				setIotInfoVisible(false);
			}} footer={null}>
				<Row gutter={16} align={'middle'}>
					<Col span={8}>
						ICID
					</Col>
					<Col>
						{icid}
					</Col>
				</Row>
				<Row gutter={16} align={'middle'}>
					<Col span={8}>
						激活时间
					</Col>
					<Col>
						{jiHuoShiJian ? moment(jiHuoShiJian).format('YYYY-MM-DD') : '-'}
					</Col>
				</Row>
				<Row>
					<Col span={8}>
						到期时间
					</Col>
					<Col>
						{simServiceTime ? moment(simServiceTime).format('YYYY-MM-DD') : '-'}
					</Col>
				</Row>
			</Modal>
			<Modal title={'选择要复制的设备'} destroyOnClose={true} visible={cpModalVisible} onCancel={() => {
				setCpModalVisible(false);
			}} onOk={cpEkeyOk}>
				<Form form={cpForm} preserve={false}>
					<Form.Item hidden={true} label={'ID'} name="targetDeviceId" rules={[{ required: false }]}
							   initialValue={targetDeviceId}>
						<Input placeholder={'ID'}/>
					</Form.Item>
					<Form.Item label={'选择设备：'} name="srcDeviceId" rules={[{ required: false }]}>
						<TreeSelect
							showSearch={true}
							treeNodeFilterProp={'title'}
							style={{ width: 300 }}
							dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
							treeData={devices}
							placeholder="请选择"
							treeDefaultExpandAll={true}
						/>
					</Form.Item>
				</Form>
			</Modal>
			<Modal title={'设备上下线'} destroyOnClose={true} visible={equipmentStatusVisible} onCancel={() => {
				setEquipmentStatusVisible(false);
			}} onOk={() => {
				setEquipmentStatusVisible(false);
			}}>
				<EquipmentStatusChart deviceId={targetDeviceId}/>
			</Modal>
			<Modal maskClosable={false} destroyOnClose={true} title={"结果"} width={800} footer={null} visible={errVisible}
				   onOk={() => seterrVisible(false)}
				   onCancel={() => {
                    seterrVisible(false);
				   }}>
				<GSTable size={"small"}
						 scroll={{
							 x: 800
						 }}
						 options={false} request={() => {
					return {
						data: errData
					};
				}} columns={errorColumns}/>
			</Modal>
		</div>

	);
};
export default DevicesList;
