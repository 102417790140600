import * as React from 'react';
import DAL from '../../data/DAL';
import Api from '../../data/API';
import appConfig from '../../appConfig';
import { Col, Row, Spin } from 'antd';

class CompanyIframe extends React.Component {
	dal = DAL.dal;
	ifra: any;

	state: any;
	constructor(props: any) {
		super(props);
		// iframHeight: window.innerHeight - 70
		// iframHeight: window.outerHeight - 180
		this.state = { isShow: true, iframHeight: window.innerHeight - 70 };
		this.onLoad = this.onLoad.bind(this);
		this.getIframe = this.getIframe.bind(this);
	}

	onLoad(infrem: any) {
		console.log('获得的对象111>>>>>>>>>>>>>>>>>', infrem);
		this.setState({
			isShow: false,
		});
	}

	getIframe() {
		var win = window.frames;
		this.ifra = win[0];
		this.ifra.location.href =
			appConfig.webCompanyUrl +
			'/page'+ window.location.pathname+ '?access_token=' +
			Api.getAccessToken() +
			'&user_id=' +
			Api.getUserId() +
			'&deviceInfo=pc' +
			'&wyUuid=' +
			localStorage.getItem('wyUuid');
	}

	render() { 
		return (
			<div style={{ marginLeft: 0, marginTop: 0, marginRight: 0, marginBottom: 0, overflow: 'hidden' }}>
				<Spin size="large" delay={1000} spinning={this.state.isShow}>
					<iframe
						key={window.location.pathname}
						id="main"
						onLoad={this.onLoad}
						ref={this.getIframe}
						style={{ width: '100%', height: (this.state as any).iframHeight, margin: 0, border: 0 }}
					/>
				</Spin>
			</div>
		);
	}
}

export default CompanyIframe;
