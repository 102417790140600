import * as React from 'react';
import DAl, { default as DAL } from '../../../data/DAL';
import Api from '../../../data/API';
import { Pagination, Table, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { add_tieba, deleteTieZi, findListDongtai } from '../../../services/GroupService';

var msg = require('../../../util/SuspensionMsg');

const confirm = Modal.confirm;
class GroupTieZi extends React.Component {
	dal = DAL.dal;

	search = '';
	columns = [
		{
			title: '发布日期',
			dataIndex: 'fabuShijian',
			key: 'fabuShijian',
		},
		{
			title: '发布人',
			key: 'fabuRenyuan',
			dataIndex: 'fabuRenyuan',
		},
		{
			title: '主题',
			key: 'zhuti',
			dataIndex: 'zhuti',
		},
		{
			title: '图片',
			key: 'tupianUrl',
			dataIndex: 'tupianUrl',
			render: (text: string, record: any) => (
				<span>
					{record.tupianUrl === null || record.tupianUrl === '' || record.tupianUrl === undefined ? (
						''
					) : (
						<img className="homePage" src={Api.cdnRootPath + record.tupianUrl} />
					)}
				</span>
			),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<Link to={'/group/group-tiezi-xiangqing?uuid=' + record.uuid}>
								<a>详情</a>
							</Link>
							<em className="ant-list-item-action-split" />
						</li>
						<li>
							<a onClick={this.getUuid.bind(this, record)}>删除</a>
						</li>
					</ul>
				</span>
			),
		},
	];

	state: any;

	constructor(props: any) {
		super(props);
		this.state = {
			findList: [],
			isShow: true,
			fabuRenyuan: '',
			fabuShijian: '',
			zhuti: '',
			wenzhang: [],
			tupianUrl: '',
			tieDianzan: '',
			isDelete: '',
			img: '',
			typeUuid: '',
			total: 0,
			pageSize: 15,
			pageNumber: 1,
		};
		this.delTiezi = this.delTiezi.bind(this);
		this.getZhuti = this.getZhuti.bind(this);
		this.callData = this.callData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getImg = this.getImg.bind(this);
		this.callbackFenye = this.callbackFenye.bind(this);
		this.getUuid = this.getUuid.bind(this);
	}

	componentDidMount() {
		this.getDataPage();
		this.uploadingImg('uploaderExample', this.getImg);
	}
	//上传照片
	uploadingImg(id: any, method: any) {
		let j = jQuery as any;
		let that = this;
		j(`#${id}`).uploader({
			autoUpload: true, // 当选择文件后立即自动进行上传操作
			url: Api.apiRootPath + '/api/picture/upload_qiniu_two', // 文件上传提交地址
			deleteActionOnDone: function (file: any, doRemoveFile: any) {
				return true;
			},
			responseHandler: function fName(responseObject: any, file: any) {
				if (JSON.parse(responseObject.response) !== null && JSON.parse(responseObject.response) !== undefined) {
					console.log(responseObject.response);
					method(JSON.parse(responseObject.response).data[0].key);
				}
			},
		});
	}

	getImg(ress: any) {
		// console.log();
		this.setState({
			tupianUrl: ress,
		});
	}

	getUuid(ev: any) {
		this.setState({
			uuid: ev.uuid,
		});
		this.showConfirm();
	}

	showConfirm = () => {
		let that = this;
		confirm({
			title: '您确认要删除该帖子吗？',
			onOk() {
				that.delTiezi();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	callData = async () => {
		let sta = this.state as any;
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		if (sta.zhuti.length === 0) {
			msg.suspensionMsg('主题不能为空');
			return;
		}

		const res = await add_tieba({
			zhuti: (this.state as any).zhuti,
			typeUuid: (this.state as any).typeUuid,
			tupianUrl: (this.state as any).tupianUrl,
		});

		// Api.postWithAuth('/api/wenzhang/add_tieba' , {
		//     zhuti: (this.state as any).zhuti,
		//     typeUuid: (this.state as any).typeUuid,
		//     tupianUrl: (this.state as any).tupianUrl
		// }).then(res => {
		if (res.err === 0) {
			this.callbackFenye(1, (this.state as any).pageSize);
			msg.suspensionMsg('发布成功!', 'success', 'myModal');
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};

	handleChange(ev: any) {
		this.setState({
			tieziType: ev.target.value,
		});
	}

	getZhuti(ev: any) {
		this.setState({
			zhuti: ev.target.value,
		});
	}

	//初始化分页
	getDataPage = () => {
		let page = parseInt(window.location.hash.slice(1), 0);
		//第一次进入hash为NAN,
		if (isNaN(page)) {
			this.onPageChange((this.state as any).pageNumber, (this.state as any).pageSize);
		} else {
			this.setState({
				pageNumber: page,
			});
			this.onPageChange(page, 5);
		}
	};

	//页数点击事件
	onPageChange = (page: number, pageSize: number) => {
		this.setState({
			pageNumber: page,
		});
		this.callbackFenye(page, pageSize);
	};

	//查询数据
	callbackFenye = async (e: any, size: number) => {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];

		let j = jQuery as any;
		var mySearchBox = j('#inputSearchExample3').val();

		const res = await findListDongtai({
			group_uuid: uuid,
			page: e,
			Search: mySearchBox,
			size: size,
		});
		// Api.postWithAuth('/api/qunzu/find_list_dongtai' , {
		//     group_uuid: uuid,
		//     page: e,
		//     Search: mySearchBox,
		//     size: size
		// }).then(res => {
		console.log(res);
		if (res.err !== 0) {
			console.log(res.msg);
			this.setState({
				isShow: false,
			});
			return;
		}
		this.setState({
			findList: res.data,
			isShow: false,
			total: res.count,
		});
		// });
	};

	delTiezi = async () => {
		const res = await deleteTieZi({
			uuid: (this.state as any).uuid,
		});
		// Api.postWithAuth('/api/qunzu/del_tiezi' , {
		//     uuid: (this.state as any).uuid
		// }).then(res => {
		if (res) {
			msg.suspensionMsg(res.msg, 'success', 'deleteTiezi');
			this.callbackFenye(1, (this.state as any).pageSize);
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};

	render() {
		return (
			<div>
				<nav className="filter-menu-bar navbar navbar-default" role="navigation">
					<div className="container-fluid">
						<ul className="nav navbar-nav">
							<li>
								<div className="input-group">
									<div
										className="input-control search-box has-icon-left has-icon-right search-example"
										id="searchboxExample"
									>
										<input
											id="inputSearchExample3"
											type="search"
											className="form-control search-input"
											placeholder="主题搜索"
										/>
										<label className="input-control-icon-left search-icon">
											<i className="icon icon-search" />
										</label>
									</div>
									<span className="input-group-btn">
										<button
											className="btn btn-primary"
											type="button"
											onClick={this.callbackFenye.bind(this, 1, 15)}
										>
											搜索
										</button>
									</span>
								</div>
							</li>
						</ul>
					</div>
				</nav>
				<div className="content">
					<Table
						columns={this.columns}
						size="middle"
						dataSource={(this.state as any).findList}
						loading={(this.state as any).loading}
						pagination={false}
					/>
					<br />

					{/*分页实现*/}
					<Pagination
						className="pagination-com"
						showQuickJumper={true}
						defaultCurrent={(this.state as any).pageNumber}
						current={(this.state as any).pageNumber}
						total={(this.state as any).total}
						pageSize={(this.state as any).pageSize}
						onChange={this.onPageChange}
						showTotal={(e) => {
							return '共' + e + '项';
						}}
						showSizeChanger={true}
						pageSizeOptions={DAl.pageSize}
						onShowSizeChange={(current, size) => {
							this.setState({
								pageSize: size,
							});
							this.callbackFenye(1, size);
						}}
					/>

					{/*发布*/}
					<div className="modal fade " id="myModal">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<button type="button" className="close" data-dismiss="modal">
										<span aria-hidden="true">×</span>
										<span className="sr-only">关闭</span>
									</button>
									<h4 className="modal-title">发布</h4>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-xs-1 qiyeguanli-name-num">类型</div>
										<div className="col-xs-3 qiyeguanli-name-num-input">
											<textarea rows={8} onInput={this.getZhuti} />
										</div>
									</div>

									<div className="row">
										<div className="container qiyeguanli-div1">
											<div id="uploaderExample" className="uploader addMeetingPictureDiv">
												<div className="addMeetingPictureBtn">
													<button
														type="button"
														className="btn btn-primary uploader-btn-browse"
													>
														<i className="icon icon-cloud-upload" /> 上传图片
													</button>
												</div>
												<div
													className="file-list addMeetingPictureMin"
													data-drag-placeholder="请拖拽文件到此处"
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="modal-footer">
									<div className="row qiyeguanli-div">
										<button type="button" className="btn btn-default" data-dismiss="modal">
											关闭
										</button>
										<button
											type="button"
											className="btn btn-primary"
											data-dismiss="modal"
											onClick={this.callData}
										>
											保存
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					{(this.state as any).isShow ? (
						<div className="cover">
							<div>
								<i className="icon icon-spin icon-spinner-indicator" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default GroupTieZi;
