import React from 'react'
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import VacantRoomList from "./pages/VacantRoomList";
import SubleaseRoomList from "./pages/SubleaseRoomList";
import UnCheckRoomList from "./pages/UnCheckRoomList";

export default function EarlyWarningMiniApp() {
    const rootUrl = '/early_warning_manage';
    const menus: MiniAppMenu[] = [
       
    ];

    const routes: MiniAppRoute[] = [

    ];

    return <MiniApp miniAppName={'预警管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
