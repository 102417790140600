import React, { useEffect, useRef, useState } from 'react';

import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import GsTreeSelect from '../../../components/GsTreeSelect';
import { creatChargeBillByCharge, findByRoomUuid, updateRoomCharge } from '../../../services/roomCharge';
import { getFeeStandardList } from '../../../services/FeeStandardService';
import { EditableProTable } from '@ant-design/pro-table';
import { findFee } from '../../../services/orderService';
import CustomDateFormItem from '../components/CustomDateFormItem';
import DAL from '../../../data/DAL';
interface Params {
	roomUuid: string;
}
const CreateCharge: React.FC<Params> = (props: any) => {
	let { RangePicker } = DatePicker;
	const actionRef = useRef<ActionType>();
	const [feeTypeList, setfeeTypeList] = useState([]);
	const [standardList, setstandardList] = useState([]);
	const [form] = Form.useForm<FormInstance>();
	const editorFormRef = useRef();
	const [startTime, setstartTime] = useState("")
	const [endTime, setendTime] = useState("")
	const [checkedValues, setcheckedValues] = useState([]);
	useEffect(() => {
		finFeeType().then();
		getFeeStandardData().then();
	}, []);
	
	const finFeeType = async () => {
		const res = await findFee({});
		if (res.err == 0) {
			setfeeTypeList(res.data || []);
		}
	};
	const getFeeStandardData = async () => {
		const res = await getFeeStandardList({
			state: 1,
		});
		if (res.err == 0) {
			setstandardList(res.data || []);
		}
	};
	const onChange = (checkedValues) => {
		console.log('checked = ', checkedValues);
		setcheckedValues(checkedValues);
	};

	const saveItem =  async() => {
		// form.validateFields().then(async (data) => {
		// 	console.log('data', data);
		// 	// const res = await updateRoomCharge({ ...data, roomUuid: props.roomUuid })
		// 	// if (res.err == 0) {
		// 	//     message.success('设置成功')
		// 	// }
		// });
		if (!DAL.dal.isNotNull(startTime) || !DAL.dal.isNotNull(endTime)) { 
			message.warning("请选择时间");
			return
		}
		if (checkedValues.length == 0) { 
			message.warning("请选择收费项目");
			return
		}
		const formData = {
			startTime: startTime,
			endTime: endTime,
			roomUuids: props.roomUuid,
			feeTypeUuids:checkedValues.toString()
		}
		console.log("formData", formData);
		const res = await creatChargeBillByCharge(formData)
		if (res.err === 0) {
			message.success("生成成功")
		} else { 
			message.error(res.msg);
		}
		
	};
	const dateTime = (date: any, dateToString: any) => {
		setstartTime(dateToString[0])
		setendTime(dateToString[1])
	};
	return (
		<>
			<Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={onChange}>
				<Row>
					{feeTypeList.map((item) => {
						return (
							<Col span={6}>
								<Checkbox value={item.uuid}>{item.feeName}</Checkbox>
							</Col>
						);
					})}
				</Row>
			</Checkbox.Group>
			{/* <Form form={form}>
				<Form.Item name={"stringTime"}> 
					<CustomDateFormItem
						format={"YYYY-MM-DD"}
                    key={new Date().getTime().toString()}
                        // style={}
                        // allowClear={props.required != "required"}
                        inputReadOnly={true}
                        // disabled={!props.allowEdit}
                    />
				</Form.Item>
				<Form.Item name={"endTime"}> 
					<CustomDateFormItem
						format={"YYYY-MM-DD"}
                    key={new Date().getTime().toString()}
                        // style={}
                        // allowClear={props.required != "required"}
                        inputReadOnly={true}
                        // disabled={!props.allowEdit}
                    />
				</Form.Item>
            </Form> */}
			<RangePicker
				format="YYYY-MM-DD"
				placeholder={['请选择开始时间', '请选择结束时间']}
				onChange={dateTime}
				style={{ width: '100%' }}
			/>
			<Button type="primary" onClick={saveItem}>
				保存
			</Button>
		</>
	);
};
export default CreateCharge;
