import { DividerProps } from 'antd';
 
export default function getBillTypes() {
	const list = [
		{
			name: '周期类',
			value: '周期类',
		},
		{
			name: '仪表类',
			value: '仪表类',
		},
		{
			name: '押金类',
			value: '押金类',
		},
		{
			name: '临时类',
			value: '临时类',
		},
		{
			name: '定金类',
			value: '定金类',
		},
	];

	const cycleType = [
		{
			name: '自然周期',
			value: '自然周期',
		},
		{
			name: '循环周期',
			value: '循环周期',
		},
	];
	const billCycles = [
		{
			name: '每月',
			value: '每月',
			zrNote: [
				'按自然周期生成应收，示例：住户2017年6月10日入住，生成2017.06.10-2017.06.30一笔应收款，再次生成则生成2017.07.01-2017.07.31一笔应收款',
			],
			xhNote: [
				'循环周期在收费设置-房间收费设置时必须设置生效日期',
				'按循环周期生成应收，示例：住户2017年6月10日入住，首次生成2017.06.10-2017.07.09一笔应收款，再次则生成2017.07.10-2017.08.09一笔应收款',
			],
		},
		{
			name: '双月',
			value: '双月',
			zrNote: [
				'按自然周期生成应收，示例：住户2017年6月10日入住，生成2017.06.10-2017.06.30一笔应收款，再次生成则生成2017.07.01-2017.08.31一笔应收款',
			],
			xhNote: [
				'循环周期在收费设置-房间收费设置时必须设置生效日期',
				'按循环周期生成应收，示例：住户2017年6月10日入住，首次生成2017.06.10-2017.08.09一笔应收款，再次则生成2017.08.10-2017.10.09一笔应收款',
			],
		},
		{
			name: '季度',
			value: '季度',
			zrNote: [
				'按自然周期生成应收，示例：住户2017年9月1日入住，生成三季度2017.09.01-2017.09.30一笔应收款，四季度则生成2017.10.1-2017.12.31一笔应收款',
				'温馨提示：计费周期为季度，计费方式月单价：每个月的单价，季度的收费项目金额=计费依据公式运算结果*3个月',
			],
			xhNote: [
				'循环周期在收费设置-房间收费设置时必须设置生效日期',
				'按循环周期生成应收，示例：住户2017年9月1日入住，首次生成2017.09.01-2017.11.30一笔应收款，再次则生成2017.12.01-2018.02.28一笔应收款',
				'温馨提示：计费周期为季度，计费方式月单价：每个月的单价，季度的收费项目金额=计费依据公式运算结果*3个月',
			],
		},
		{
			name: '半年',
			value: '半年',
			zrNote: [
				'按自然周期生成应收，示例：住户2017年5月1日入住，生成2017.05.01-2017.06.30一笔应收款，再次生成则生成2017.07.01-2017.12.31一笔应收款',
				'温馨提示：计费周期为半年，计费方式月单价：每个月的单价，半年的收费项目金额=计费依据公式运算结果*6个月',
			],
			xhNote: [
				'循环周期在收费设置-房间收费设置时必须设置生效日期',
				'按循环周期生成应收，示例：住户2017年5月1日入住，首次生成2017.05.01-2017.10.31一笔应收款，再次则生成2017.11.01-2018.04.30一笔应收款',
				'温馨提示：计费周期为半年，计费方式月单价：每个月的单价，半年的收费项目金额=计费依据公式运算结果*6个月',
			],
		},
		{
			name: '年',
			value: '年',
			zrNote: [
				'按自然周期生成应收，示例：住户2017年5月1日入住，首年生成2017.05.01-2017.12.31一笔应收款，次年则生成2018.1.1-2018.12.31一笔应收款',
				'温馨提示：计费周期为一年，计费方式月单价：每个月的单价，一年的收费项目金额=计费依据公式运算结果*12个月',
			],
			xhNote: [
				'循环周期在收费设置-房间收费设置时必须设置生效日期',
				'按循环周期生成应收，示例：住户2017年5月1日入住，首年生成2017.05.01-2018.04.30一笔应收款，次年则生成2018.05.01-2019.04.30一笔应收款',
				'温馨提示：计费周期为一年，计费方式月单价：每个月的单价，一年的收费项目金额=计费依据公式运算结果*12个月',
			],
		},
		{
			name: '供暖费',
			value: '供暖费',
			zrNote: [
			],
			xhNote: [
			],
		},
	];

	const receviableTypes = [
		{
			name: '费用开始日期向前*天',
			value: '费用开始日期向前*天',
			type: 'other',
		},
		{
			name: '费用开始日期向后*天',
			value: '费用开始日期向后*天',
			type: 'other',
		},
		{
			name: '费用结束日期向前*天',
			value: '费用结束日期向前*天',
			type: 'other',
		},
		{
			name: '费用结束日期向后*天',
			value: '费用结束日期向后*天',
			type: 'other',
		},
		// {
		// 	name: '月初向前*天',
		// 	value: '月初向前*天',
		// 	type: 'yue',
		// },
		// {
		// 	name: '月初向后*天',
		// 	value: '月初向后*天',
		// 	type: 'yue',
		// },
		// {
		// 	name: '月后向前*天',
		// 	value: '月后向前*天',
		// 	type: 'yue',
		// },
		// {
		// 	name: '月后向后*天',
		// 	value: '月后向后*天',
		// 	type: 'yue',
		// },
		// {
		// 	name: '固定日',
		// 	value: '固定日',
		// 	type: 'yue',
		// },
		// {
		// 	name: '自定义',
		// 	value: '自定义',
		// 	type: 'year',
		// },
	];

	const unitNames = [
		{ name: '空', value: '' },
		{ name: '元', value: '元' },
		{ name: '元/㎡', value: '元/㎡' },
		{ name: '元/㎡/天', value: '元/㎡/天' },
		{ name: '元/月', value: '元/月' },
		{ name: '元/度', value: '元/度' },
		{ name: '元/吨', value: '元/吨' },
		{ name: '元/个', value: '元/个' },
		{ name: '元/张', value: '元/张' },
		{ name: '元/月/㎡', value: '元/月/㎡' },
		{ name: '元/年', value: '元/年' },
		{ name: '元/次', value: '元/次' },
	];

	const billBasis = [
		{ name: '固定金额', value: '固定金额' },
		{ name: '计费面积*单价', value: '计费面积*单价' },
	];
	const priceTypes = [
		{name:'月单价',value:'月单价'},
		{name:'自然日单价',value:'自然日单价'},
		{name:'平均日单价',value:'平均日单价'},
		{name:'总单价',value:'总单价'},
	]
	const meterReadingTypes = [
		{name:'电表',value:'电表'},
		{name:'水表',value:'水表'},
		{name:'燃气表',value:'燃气表'},
	]
	return {
		list,
		billCycles,
		cycleType,
		receviableTypes,
		unitNames,
		billBasis,
		priceTypes,
		meterReadingTypes
	};
}
