import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import {findSmsRecord, findSmsSendBatchList, getSendKeyList} from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Modal, Tag } from 'antd';
import SmsDetail from '../components/SmsDetail';
import * as moment from 'moment';
import SendKeyDetail from "../components/SendKeyDetail";

const KeySendRecord: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [visible, setVisiable] = useState(false);
	const [batchId, setBatchId] = useState('');
	const columns = [
		{
			title: '发送时间',
			key: 'sendtime',
			width: 200,
			dataIndex: 'sendtime',
			render: (text: string, record: any) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
		},
		{
			title: '发送人姓名',
			key: 'username',
			width: 200,
			dataIndex: 'username',
		},
		{
			title: '发送人手机号',
			key: 'userphone',
			width: 200,
			dataIndex: 'userphone',
		},
		{
			title: '发送总条数',
			dataIndex: 'sendcount',
			key: 'sendcount',
		},
		{
			title: '成功条数',
			dataIndex: 'successcount',
			key: 'successcount',
		},
		{
			title: '失败条数',
			dataIndex: 'errcount',
			key: 'errcount',
		},
		{
			title: '状态',
			key: 'sendStatus',
			dataIndex: 'sendStatus',
			tip: '全部发送成功显示成功否则失败',
			render: (text: string, record: any) => <Tag color={record.sendcount==record.successcount?'green':'red'}>{record.sendcount==record.successcount?'发送成功':'发送失败'}</Tag>,
		},
		{
			title: '操作',
			key: 'action',
			width: 100,
			fixed: 'right',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						showModal(record);
					}}
				>
					查看详情
				</a>
			),
		},
	];

	const showModal = (value: any) => {
		setBatchId(value.uuid);
		setVisiable(true);
	};

	return (
		<div>
			<GSTable
				columns={columns}
				rowKey={"uuid"}
				actionRef={actionRef}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getSendKeyList({
						page: params.current,
						size: params.pageSize,
					});
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				title="详情"
				visible={visible}
				onOk={() => {
					setVisiable(false);
				}}
				onCancel={() => {
					setVisiable(false);
				}}
				width={1200}
				footer={null}
			>
				<SendKeyDetail key={batchId} batchUuid={batchId} />
			</Modal>
		</div>
	);
};

export default KeySendRecord;
