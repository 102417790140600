import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import { Row, Col, Card, message } from 'antd';
import {
	getRoomSubletStatistics,
	getRoomVacantStatistics,
	getFaceAuditStatistics,
	getRecordStatisticsApi,
	getHouseholdStatistics,
	getZhuhuStatistics,
} from '../../../services/ZhuhushenheService';

var msg = require('../../../util/SuspensionMsg');

let echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/pie'); //图表类型
require('echarts/lib/component/title'); //标题插件

class BasicFacts extends React.Component {
	deviceId = '';
	dal = DAL.dal;
	contact = '';
	users2 = [];
	sumChart: any;
	successChart: any;
	failChart: any;
	failChart1: any;
	incomeChart: any;
	openNumChart: any;
	activiteStatisticsChart: any;
	customerNumChart: any;
	companyNumChart: any;

	state: any;

	constructor(props: any) {
		super(props);
		this.state = {
			// 管理户数
			totalZhuHu: 0,
			totalKongZi: 0,
			totalRuZhu: 0,
			loadingGuanLiHuShuData: true,

			totalZaiZhuRenShu: 0,
			lastMonthRuZhu: 0,
			laseMonthQianChu: 0,
			loadingRuzhuQianruQianchuData: true,
			totalZaiZhuHuShu: 0,

			// 开门数据
			dateList: [],
			sumList: [],
			loadingUnlockData: true,

			// 人脸审核
			totalAuditNum: 0,
			auditFailNum: 0,
			auditSuccessNum: 0,
			loadingAuditData: true,

			// 空置统计
			totalRoomNum: 0,
			kongZhiNum: 0,
			buKongZhiNum: 0,
			loadingKongZhiData: true,

			// 转租统计
			totalRoomNum1: 0,
			zhuangZhuNum: 0,
			unZhuangZhuNum: 0,
			loadingZhuanZuData: true,
		};
		this.setPieOption = this.setPieOption.bind(this);
		// this.getData = this.getData.bind(this);
		this.sum = this.sum.bind(this);
		this.fial = this.fial.bind(this);
		this.fial1 = this.fial1.bind(this);
		this.success = this.success.bind(this);
	}

	componentDidMount() {
		// this.getData();
		this.getZhuHuStatistics();
		this.getHouseholdStatistics();
		this.getRecordStatisticsApi();
		this.getFaceAuditStatistics();
		this.getRoomVacantStatistics();
		this.getRoomSubletStatistics();
	}

	/**
	 * 获取房间入住统计数据
	 */
	getZhuHuStatistics = async () => {
		const res = await getZhuhuStatistics({});
		// API.postWithAuth('/api/statistics/getZhuhuStatistics', {}).then(res => {
		if (res.err === 0) {
			this.setState({
				totalZhuHu: res.sumRoom,
				totalKongZi: res.kongZiRoom,
				totalRuZhu: res.ruZhuRoom,
				loadingGuanLiHuShuData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		if (this.sumChart !== undefined) {
			this.sumChart.setOption(
				this.manageHouserNum(
					[
						{ value: (this.state as any).totalRuZhu, name: '已入住' },
						{ value: (this.state as any).totalKongZi, name: '空置' },
					] as any,
					'住户管理',
					(this.state as any).totalZhuHu + '户'
				)
			);
		}
		// });
	};
	/**
	 * 获取入住人数统计
	 */
	getHouseholdStatistics = async () => {
		const res = await getHouseholdStatistics({});
		// API.postWithAuth('/api/statistics/getHouseholdStatistics', {}).then(res => {
		if (res.err === 0) {
			console.log('获取入住人数统计', res);
			this.setState({
				totalZaiZhuRenShu: res.totalZaiZhuRenShu,
				lastMonthRuZhu: res.lastMonthRuZhu,
				laseMonthQianChu: res.laseMonthQianChu,
				totalZaiZhuHuShu: res.totalZaiZhuHuShu,
				loadingRuzhuQianruQianchuData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		if (this.customerNumChart !== undefined) {
			this.customerNumChart.setOption(
				this.ruzhuRenshu(this.state.totalZaiZhuRenShu, this.state.totalZaiZhuHuShu)
			);
		}
		if (this.incomeChart !== undefined) {
			this.incomeChart.setOption(
				this.qianruQianchu(
					[{ value: (this.state as any).lastMonthRuZhu, name: '' }] as any,
					'上月迁入数',
					(this.state as any).lastMonthRuZhu + '人'
				)
			);
		}
		if (this.companyNumChart != undefined) {
			this.companyNumChart.setOption(
				this.qianruQianchu(
					[{ value: (this.state as any).laseMonthQianChu, name: '' }] as any,
					'上月迁出人数',
					(this.state as any).laseMonthQianChu + '人'
				)
			);
		}
		// });
	};
	/**
	 * 获取开锁记录统计
	 */
	getRecordStatisticsApi = async () => {
		const res = await getRecordStatisticsApi({});
		// API.postWithAuth('/api/statistics/getRecordStatisticsApi', {}).then(res => {
		console.log('开门记录：', res);
		if (res.err === 0) {
			this.setState({
				dateList: res.dateList,
				sumList: res.sumList,
				loadingUnlockData: false,
			});
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
		if (this.openNumChart !== undefined) {
			this.openNumChart.setOption(
				this.openNumChartData((this.state as any).dateList, (this.state as any).sumList)
			);
		}
		// });
	};
	/**
	 * 获取人脸审核
	 */
	getFaceAuditStatistics = async () => {
		const res = await getFaceAuditStatistics({});
		// API.postWithAuth('/api/statistics/getFaceAuditStatistics', {}).then(res => {
		if (res.err === 0) {
			this.setState({
				totalAuditNum: res.totalAuditNum,
				auditFailNum: res.auditFailNum,
				auditSuccessNum: res.auditSuccessNum,
				loadingAuditData: false,
			});
			/*this.setState({
                    totalAuditNum: 685,
                    auditFailNum: 11,
                    auditSuccessNum: 674,
                    loadingAuditData: false
                });*/
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'danger' });
		}
		if (this.activiteStatisticsChart !== undefined) {
			this.activiteStatisticsChart.setOption(
				this.manageHouserNum(
					[
						{ value: (this.state as any).auditFailNum, name: '审核失败' },
						{ value: (this.state as any).auditSuccessNum, name: '审核通过' },
					] as any,
					'住户审核统计',
					'上月抽查人数\n' + (this.state as any).totalAuditNum + '人'
				)
			);
		}
		// });
	};

	/**
	 * 获取空置房间的统计
	 */
	getRoomVacantStatistics = async () => {
		const res = await getRoomVacantStatistics({});
		// API.postWithAuth('/api/statistics/getRoomVacantStatistics', {}).then(res => {
		if (res.err === 0) {
			this.setState({
				totalRoomNum: res.totalRoomNum,
				kongZhiNum: res.kongZhiNum,
				buKongZhiNum: res.buKongZhiNum,
				loadingKongZhiData: false,
			});
			/*this.setState({
                    totalRoomNum: 2159,
                    kongZhiNum: 49,
                    buKongZhiNum: 2110,
                    loadingKongZhiData: false,
                });*/
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
		if (this.failChart !== undefined) {
			this.failChart.setOption(
				this.manageHouserNum(
					[
						{ value: (this.state as any).kongZhiNum, name: '疑似空置' },
						{ value: (this.state as any).buKongZhiNum, name: '正常' },
					] as any,
					'疑似空置',
					'入住' + (this.state as any).totalRoomNum + '户'
				)
			);
		}
		// });
	};

	getRoomSubletStatistics = async () => {
		const res = await getRoomSubletStatistics({});
		// API.postWithAuth('/api/statistics/getRoomSubletStatistics', {}).then(res => {
		if (res.err === 0) {
			this.setState({
				totalRoomNum1: res.totalRoomNum1,
				zhuangZhuNum: res.zhuangZhuNum,
				unZhuangZhuNum: res.unZhuangZhuNum,
				loadingZhuanZuData: false,
			});
			/*this.setState({
                    totalRoomNum1: 2159,
                    zhuangZhuNum: 31,
                    unZhuangZhuNum: 2128,
                    loadingZhuanZuData: false
                });*/
		} else {
			message.error(res.msg);
			// msg.suspensionMsg(res.msg);
		}
		if (this.successChart !== undefined) {
			this.successChart.setOption(
				this.manageHouserNum(
					[
						{ value: (this.state as any).zhuangZhuNum, name: '疑似转租' },
						{ value: (this.state as any).unZhuangZhuNum, name: '正常' },
					] as any,
					'疑似转租',
					'入住' + (this.state as any).totalRoomNum1 + '户'
				)
			);
		}
		// });
	};

	manageHouserNum = (data: any, title: string, legendArray: string) => {
		return {
			title: {
				x: 'center', //标题位置
				y: 'center',
				text: legendArray, //传入标题名称‘参保情况’
				textStyle: {
					//标题字体颜色等设置
					fontSize: 28,
					//fontWeight: 'bold',
				},
			},
			tooltip: {
				trigger: 'item',
				show: true,
				formatter: '{a} <br/>{b}: {c} <br/>({d}%)',
			},
			series: [
				{
					name: title,
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					silent: true,
					data: data,
					label: {
						show: true,
						normal: {
							formatter: '{b}{c}户\n{d}%',
							fontSize: 16,
							//position: 'inside',
						},
						/*                        rich: {
                            b: {
                                fontSize: 20,
                            }
                        },*/
						//lineHeight: 100,
						//fontWeight: 'bolder',
						//align: 'center'
					},
				},
			],
		};
	};

	qianruQianchu = (data: any, title: string, legendArray: string) => {
		return {
			title: {
				x: 'center', //标题位置
				y: 'center',
				text: legendArray, //传入标题名称‘参保情况’
				textStyle: {
					//标题字体颜色等设置
					fontSize: 26,
					//fontWeight: 'bold',
				},
			},
			tooltip: {
				trigger: 'item',
				show: true,
				formatter: '{a} <br/>{b}: {c} <br/>({d}%)',
			},
			series: [
				{
					name: title,
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					silent: true,
					data: data,
					label: {
						show: false,
					},
				},
			],
		};
	};

	companyNum = (chart: any) => {
		if (chart !== null) {
			this.companyNumChart = echarts.init(chart);
		}
	};

	activiteStatistics = (chart: any) => {
		if (chart !== null) {
			this.activiteStatisticsChart = echarts.init(chart);
		}
	};

	income = (chart: any) => {
		if (chart !== null) {
			this.incomeChart = echarts.init(chart);
		}
	};

	openNum = (chart: any) => {
		if (chart !== null) {
			this.openNumChart = echarts.init(chart);
		}
	};

	customerNum = (chart: any) => {
		if (chart !== null) {
			this.customerNumChart = echarts.init(chart);
		}
	};

	sum(chart: any) {
		console.log('chart对象', chart);
		if (chart !== null) {
			this.sumChart = echarts.init(chart);
		}
	}

	fial(chart: any) {
		if (chart !== null) {
			this.failChart = echarts.init(chart);
		}
	}

	fial1(chart: any) {
		if (chart !== null) {
			this.failChart1 = echarts.init(chart);
		}
	}

	success(chart: any) {
		if (chart !== null) {
			console.log('图表对象', chart);
			this.successChart = echarts.init(chart);
		}
	}

	ruzhuRenshu = (totalZaiZhuRenShu: any, totalZhuHu: any) => {
		return {
			xAxis: {
				type: 'category',
				data: ['入住户数', '入住人数'],
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					data: [totalZhuHu, totalZaiZhuRenShu],
					type: 'bar',
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	openNumChartData = (dateList: any, sumList: any) => {
		return {
			xAxis: {
				type: 'category',
				data: dateList,
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
			},
			yAxis: {
				type: 'value',
				axisLabel: {
					formatter: '{value}',
				},
			},
			series: [
				{
					data: sumList,
					type: 'line',
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	//报事处理
	circularDiagram = () => {
		return {
			tooltip: {},
			legend: {
				orient: 'vertical',
				x: 'left',
				data: ['1小时内', '4小时内', '其他'],
			},
			series: [
				{
					name: '访问来源',
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					label: {
						normal: {
							show: true,
							position: 'outside',
							formatter: '{b}: {d}%',
						},
						emphasis: {
							show: true,
							textStyle: {
								fontSize: '14',
							},
						},
					},
					labelLine: {
						normal: {
							show: false,
						},
					},
					data: [
						{ value: 83, name: '1小时内' },
						{ value: 10, name: '4小时内' },
						{ value: 7, name: '其他' },
					],
				},
			],
		};
	};

	//一个基本的echarts图表配置函数
	setPieOption(data: any, title: string) {
		return {
			title: {
				x: 'center', //标题位置
				y: 'center',
				text: title, //传入标题名称‘参保情况’
				textStyle: {
					//标题字体颜色等设置
					fontSize: 16,
					fontWeight: 'bold',
				},
			},
			series: [
				{
					name: '比例',
					type: 'pie',
					radius: ['40%', '80%'],
					data: data, //传入外部的data数据
					label: {
						normal: {
							show: false,
							position: 'inner',
							textStyle: {
								fontSize: '12',
							},
							formatter: '{b}:{c}',
						},
						emphasis: {
							show: true,
							textStyle: {
								fontSize: '12',
							},
						},
					},
					labelLine: {
						normal: {
							show: false,
						},
					},
				},
			],
		};
	}

	componentWillUnmount() {
		console.log('...............................', this.refs.chart);
	}

	render() {
		return (
			<div className="">
				<Row gutter={16}>
					<Col span={6}>
						<Card title="管理户数" bordered={false} loading={this.state.loadingGuanLiHuShuData}>
							<canvas ref={this.sum} width="400" height="400" style={{ height: '100%', width: '100%' }}>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="总入住人数" bordered={false} loading={this.state.loadingRuzhuQianruQianchuData}>
							<canvas
								ref={this.customerNum}
								width="400"
								height="400"
								style={{ height: '100%', width: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="上月迁入人数" bordered={false} loading={this.state.loadingRuzhuQianruQianchuData}>
							<canvas
								ref={this.income}
								width="400"
								height="400"
								style={{ height: '100%', width: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="上月迁出人数" bordered={false} loading={this.state.loadingRuzhuQianruQianchuData}>
							<canvas
								ref={this.companyNum}
								width="400"
								height="400"
								style={{ height: '100%', width: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
				</Row>

				<br />

				<Row gutter={16}>
					<Col span={6}>
						<Card title="近一月开门次数" bordered={false} loading={this.state.loadingUnlockData}>
							<canvas
								ref={this.openNum}
								width="400"
								height="400"
								style={{ height: '100%', width: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="人脸识别审核" bordered={false} loading={this.state.loadingAuditData}>
							<canvas
								ref={this.activiteStatistics}
								width="400"
								height="400"
								style={{ height: '100%', width: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="疑似空置" bordered={false} loading={this.state.loadingKongZhiData}>
							<canvas ref={this.fial} width="400" height="400" style={{ height: '100%', width: '100%' }}>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="疑似转租" bordered={false} loading={this.state.loadingZhuanZuData}>
							<canvas
								ref={this.success}
								width="400"
								height="400"
								style={{ height: '100%', width: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default BasicFacts;
