import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import { findWuyeDevicePatrol } from '../../../services/patrolService';

class AllDeviceJ extends React.Component {
	dal = DAL.dal;

	constructor(props: any) {
		super(props);
		this.state = {
			ds: [],
			isShow: true,
			uuid: '',
			deviceId: '',
			deviceName: '',
			deviceBattery: '',
			deviceVersion: '',
			location: '',
			createdTime: '',
			address: '',
			describe: '',
		};
		//this.getuuid = this.getuuid.bind(this);
		//this.deleteStaffRoute = this.deleteStaffRoute.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
	}

	componentDidMount() {
		this.findWuyeDevicePatrol();
		// this.dal.getpatrolJ().then(res => {
		//
		// });
	}

	findWuyeDevicePatrol = async () => {
		const res = await findWuyeDevicePatrol({
			typecode: '2222',
		});
		// API.postWithAuth('/api/patrolinspection/find_wuye_device_patrol' , {
		//     typecode: '2222'
		// }).then(res => {
		if (res.err === 0) {
			console.log(res);
			this.setState({
				ds: res.data,
				isShow: false,
			});
		}
		// });
	};

	/*getuuid(res: any) {
        console.log(res.target.id);
        this.setState({
            uuid: res.target.id
        });
    }

        deleteStaffRoute() {
            if ((this.state as any).uuid === null) {
                alert('删除异常');
                return;
            }
            this.dal.deleteStaffRoute((this.state as any).uuid).then(ress => {
                //alert('删除' + ress);
                console.log('获得的删除结果', ress);
                if (ress) {
                    alert('删除成功');
                    this.componentDidMount();
                } else {
                    alert('删除失败');
                }

            });
        }*/

	render() {
		return (
			<div className="devices-list">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>设备ID</th>
							<th>设备名称</th>
							{/*
                            <th>设备电量</th>
                            <th>设备版本</th>*/}
							<th>定位</th>
							<th>创建时间</th>
							<th>地址</th>
							<th>事件</th>
							{/*<th>操作</th>*/}
						</tr>
					</thead>
					<tbody>
						{(this.state as any).ds.map((item: any, index: any) => (
							<tr key={item.uuid}>
								<td>{item.deviceId}</td>
								<td>{item.deviceName}</td>
								{/*<td>{item.deviceBattery}</td>
                                <td>{item.deviceVersion}</td>*/}
								<td>{item.location}</td>
								<td>{item.createdTime}</td>
								<td>{item.address}</td>
								<td>{item.describe}</td>
								{/*<td>
                                    <button className="btn" type="button">
                                        <a href={'/patrolinspection/route_points?id=' + item.deviceUuid + ',' + item.routeName}>详细</a>
                                    </button>
                                    <button className="btn" type="button">
                                        <a type="button" onClick={this.getuuid} id={item.uuid} data-toggle="modal" data-target="#deletemySmModal">删除</a>
                                    </button>
                                </td>*/}
							</tr>
						))}
					</tbody>
				</table>

				<div className="modal fade" id="deletemySmModal">
					<div className="modal-dialog modal-sm">
						<div className="modal-content">
							<div className="dialog-title-min">提示</div>

							<div className="dialog-title-min-count">您确认要删除该用户吗？</div>

							<div className="dialog-title-min-btn">
								<button data-dismiss="modal" className="btn">
									取消
								</button>

								<button data-dismiss="modal" className="btn">
									确认
								</button>
							</div>
						</div>
					</div>
				</div>

				{(this.state as any).isShow ? (
					<div className="cover">
						<div>
							<i className="icon icon-spin icon-spinner-indicator" />
						</div>
					</div>
				) : null}
			</div>
		);
	}
}
export default AllDeviceJ;
