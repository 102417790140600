import React, { useCallback, useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import {
	Button,
	Card,
	Col,
	Form,
	Image,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Select,
	Space,
	Tag,
	TreeSelect,
	Upload,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { ActionType } from '@ant-design/pro-table';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import {
	addPageSet,
	deletePageSet,
	getPageSetList,
	getProductCateList,
	getProductList,
	sortProduct,
	updatePageSet,
	updateProductStatus,
} from '../../../services/Product';
import HouseTypeImg from '../../assets/components/HouseTypeImg';
import appConfig from '../../../appConfig';
import Api from '../../../data/API';
import { DndProvider } from 'react-dnd';
import HTMLBackend from 'react-dnd-html5-backend';
import DragableBodyRow from '../../../components/DragableBodyRow';
import { Link } from 'react-router-dom';
import DAL from '../../../data/DAL';
import OrderList from '../../order/pages/OrderList';
const statusEnum = {
	true: { text: '上架', status: 'Success' },
	false: { text: '下架', status: 'Error' },
};
const Product: React.FC = () => {
	const dal = new DAL();
	useEffect(() => {
		if (
			dal.getQueryString('cateUuid') !== null &&
			dal.getQueryString('cateUuid') !== undefined &&
			dal.getQueryString('cateUuid') !== ''
		) {
			setCateUuid(dal.getQueryString('cateUuid'));
		}
		getProductCateData().then();
	}, []);
	const actionRef = useRef<ActionType>();
	const actionRef1 = useRef<ActionType>();
	const [cateUuid, setCateUuid] = useState('');
	const [orderVisible, setOrderVisible] = useState(false)
	const [item, setItem] = useState<any>({});
	const [productCateList, setProductCateList] = useState([]);
	const [sortVisible, setSortVisible] = useState(false);
	const [sortData, setSortData] = useState([]);
	const [status, setStatus] = useState('');
	const [productName,setProductName] = useState("")
	const [sortBy, setSortBy] = useState(0);
	const [commodityUuid, setCommodityUuid] = useState("")
	const [bannerVisible, setBannerVisible] = useState(false);
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '封面图',
			dataIndex: 'productImg',
			key: 'productImg',
			render: (text: string, record: any) => (
				<>
					<Image className="homePage" src={Api.cdnRootPath + record.productImg} height={80} width={80} />
				</>
			),
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			valueEnum: statusEnum,
			// render: (text: string, record: any) => (
			// 	<>
			// 		<Tag color={record.status?'green':'gold'}>{record.status?'上架':'下架'}</Tag>
			// 	</>
			// ),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<>
					<Link to={'/product/addOrUpdateProduct?uuid=' + record.uuid + '&cateUuid=' + cateUuid}>编辑</Link>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							openSetBanner(record);
						}}
					>
						图片
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							updateStatus(record);
						}}
					>
						{record.status ? '下架' : '上架'}
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						target="_blank"
						href={
							'/preview.html?previewUrl=' +
							appConfig.mobileAppUrl +
							'/service' +
							'/ckxz/WidgetPage?userId=' +
							localStorage.getItem('userId') +
							'&wuyeUuid=' +
							localStorage.getItem('wyUuid') +
							'&productUuid=' +
							record.uuid +
							'&token=' +
							localStorage.getItem('token') +
							'&ss=ss'
						}
					>
						预览
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a onClick={() => {
						setCommodityUuid(record.uuid)
						setOrderVisible(true)
					}}>
						订单管理
					</a>
				</>
			),
		},
	];

	const columns1 = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '产品图',
			dataIndex: 'productImg',
			key: 'productImg',
			render: (text: string, record: any) => (
				<>
					<Image className="homePage" src={Api.cdnRootPath + record.productImg} height={80} width={80} />
				</>
			),
		},
	];

	const updateStatus = async (item: any) => {
		Modal.confirm({
			title: '你确定要' + (item.status ? '下架' : '上架') + item.name + '吗？',
			onOk: async () => {
				const formData: any = {
					productUuid: item.uuid,
					status: item.status ? false : true,
				};
				const res = await updateProductStatus(formData);
				if (res.err === 0) {
					message.success('修改成功');
					actionRef.current.reload();
				} else {
					message.error('修改失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const openSetBanner = (record: any) => {
		setItem(record);
		setBannerVisible(true);
	};

	const getProductCateData = async () => {
		let rsp = await getProductCateList({});
		if (rsp.err === 0) {
			setProductCateList(rsp.data);
		} else {
			setProductCateList([]);
		}
	};

	const deleteItem = (item: any) => {
		Modal.confirm({
			title: '你确定要删除' + item.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const data: any = {
					id: item.id,
					uuid: item.uuid,
					wuyeUuid: item.wuyeUuid,
				};
				const res = await deletePageSet(data);
				if (res.err === 0) {
					message.success('删除成功');
					actionRef.current.reload();
				} else if (res.err == -2) {
					message.warning(res.msg);
				} else {
					message.error('删除失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const getList = async () => {
		let params = {
			cateUuid: cateUuid,
			status: status,
			productName:productName
		};
		let rsp = await getPageSetList(params);
		if (rsp.err === 0) {
			setCateUuid(rsp.cateUuid);
			setSortData(rsp.data);
			if (rsp.data.length > 0) {
				const item: any = rsp.data[rsp.data.length - 1];
				if (item.sortBy !== null && item.sortBy !== undefined && item.sortBy !== '') {
					setSortBy(item.sortBy);
				} else {
					setSortBy(0);
				}
			}
			return {
				data: rsp.data,
			};
		} else {
			return {
				data: [],
				total: 0,
			};
		}
	};

	const tableSearchBarRender = () => [
		<Space>
			<TreeSelect
				key={cateUuid}
				value={cateUuid}
				style={{ width: 200 }}
				dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
				treeData={productCateList}
				treeDefaultExpandAll
				onChange={(value: any) => {
					setCateUuid(value);
					actionRef.current.reloadAndRest();
				}}
			/>
			<Select
				style={{ width: 200 }}
				value={status}
				placeholder={'状态'}
				onChange={(value: any) => {
					setStatus(value);
					actionRef.current.reloadAndRest();
				}}
			>
				<Select.Option value="">全部</Select.Option>
				<Select.Option value={1}>上架</Select.Option>
				<Select.Option value={0}>下架</Select.Option>
			</Select>

			<Input placeholder="产品名称" onChange={(e) => { setProductName(e.target.value)}} />
			<Button type={"primary"} onClick={() => {
				actionRef.current.reloadAndRest()
			}}>查询</Button>
		</Space>,
	];
	const tableOperationsBarRender = () => [
		<Button type={'primary'}>
			<Link to={'/product/addOrUpdateProduct?add=1&sortBy=' + sortBy + '&cateUuid=' + cateUuid}>添加</Link>
		</Button>,
		<Button
			type="primary"
			onClick={() => {
				setSortVisible(true);
			}}
		>
			编辑排序
		</Button>,
	];
	const moveRow = useCallback(
		async (dragIndex, hoverIndex, key) => {
			if (dragIndex === hoverIndex) {
				return;
			}
			console.log('dragIndex', dragIndex);
			console.log('hoverIndex', hoverIndex);
			let route = [...sortData];
			const d1 = route[dragIndex];
			const ho = route[hoverIndex];
			console.log('data::', d1, ho);
			let tmp = route[dragIndex]; //临时储存文件
			route.splice(dragIndex, 1); //移除拖拽项
			route.splice(hoverIndex, 0, tmp); //插入放置项
			setSortData([...route]);
			// findArray(routes, key, dragIndex, hoverIndex);
			actionRef1.current.reload();
		},
		[sortData]
	);

	const components = {
		body: {
			row: DragableBodyRow,
		},
	};
	const getData = () => {
		return {
			data: sortData,
		};
	};
	const sortDataItem = async () => {
		console.log('排序：：：', '排序');
		const data = sortData;
		let ids: any = [];
		data.map((item) => {
			ids.push(item.uuid);
		});
		console.log('ids', ids);
		const res = await sortProduct({ ids: ids.toString() });
		if (res.err === 0) {
			setSortVisible(false);
			message.success('排序成功');
			actionRef.current.reload();
		} else {
			message.error('排序失败');
		}
	};
	return (
		<>
			<GSTable
				pagination={false}
				columns={columns}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				actionRef={actionRef}
				request={(params: any) => {
					return getList();
				}}
			/>
			{/*<Modal*/}
			{/*	title={add ? '添加' : '修改'}*/}
			{/*	visible={visible}*/}
			{/*	onOk={saveItem}*/}
			{/*	width={1000}*/}
			{/*	onCancel={() => {*/}
			{/*		setVisble(false);*/}
			{/*	}}*/}
			{/*>*/}
			{/*	*/}
			{/*</Modal>*/}
			<Modal
				width={1300}
				visible={bannerVisible}
				onCancel={() => {
					setBannerVisible(false);
				}}
				title={'图片'}
				footer={null}
			>
				<HouseTypeImg
					imgType={'单页配置图'}
					isHidenImgType={true}
					key={item.toString()}
					houseTypeId={item.uuid}
				/>
			</Modal>
			<Modal
				visible={sortVisible}
				title={'排序'}
				onCancel={() => {
					setSortVisible(false);
				}}
				onOk={sortDataItem}
			>
				<DndProvider backend={HTMLBackend}>
					<GSTable
						options={false}
						actionRef={actionRef1}
						components={components}
						onRow={(record: any, index: any) => ({
							index,
							moveRow,
						})}
						columns={columns1}
						request={getData}
						pagination={false}
					/>
				</DndProvider>
			</Modal>
			<Modal title="订单管理" width={1300} visible={orderVisible} onCancel={()=>{setOrderVisible(false)}} footer={null}>
				<OrderList commodityUuid={commodityUuid} key={commodityUuid}></OrderList>
			</Modal>
		</>
	);
};
export default Product;
