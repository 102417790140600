import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { FormInstance } from 'antd/es/form';
import { Button, Form, Input, message, Modal, Select, Switch } from 'antd';
import * as moment from 'moment';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';

import {
	addSpecialAttention,
	deleteSpecialAttention,
	getSpecialAttentionList,
	setNoticeCheck,
	updateSpecialAttention,
} from '../../../services/ZhiNengMenJinService';
const SpecialAttention: React.FC = () => {
	let msg = require('../../../util/SuspensionMsg');
	const confirm = Modal.confirm;
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [add, setAdd] = useState(true);
	const [visible, setVisible] = useState(false);
	const [search, setSearch] = useState('');
	const [isShow, setIsShow] = useState(false);
	const columns = [
		{
			title: '分组名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '分组人数',
			dataIndex: 'num',
			key: 'num',
			render: (text: string, record: any) => (
				<a>{record.attentionPhone.substring(0, record.attentionPhone.length - 1).split(',').length}</a>
			),
		},
		{
			title: '创建时间',
			dataIndex: 'createdTime',
			key: 'createdTime',
			render: (text: string, record: any) => <span>{moment(record.created).format('YYYY-MM-DD')}</span>,
		},
		{
			title: '短信通知',
			dataIndex: 'smsNotice',
			key: 'smsNotice',
			render: (text: string, record: any) => (
				<Switch
					key={new Date().getTime().toString()}
					defaultChecked={record.smsNotice}
					onChange={(checked: any) => {
						setNoticeStatus(record, record.appNotice, checked);
					}}
				/>
			),
		},
		{
			title: 'app通知',
			dataIndex: 'appNotice',
			key: 'appNotice',
			render: (text: string, record: any) => (
				<Switch
					key={new Date().getTime().toString()}
					defaultChecked={record.appNotice}
					onChange={(checked: any) => {
						setNoticeStatus(record, checked, record.smsNotice);
					}}
				/>
			),
		},
		{
			title: '操作',
			dataIndex: 'active',
			key: 'active',
			render: (text: string, record: any) => {
				return (
					<div>
						<ul className="ant-list-item-action">
							<li>
								<a
									onClick={() => {
										deleteSpecialAttentionItem(record);
									}}
								>
									删除
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										openUpdateModal(record);
									}}
								>
									编辑
								</a>
							</li>
						</ul>
					</div>
				);
			},
		},
	];
	const openUpdateModal = (record: any) => {
		form.setFieldsValue({
			...record,
			attentionPhone: record.attentionPhone.substring(0, record.attentionPhone.length - 1).split(','),
			noticePhone: record.noticePhone.split(','),
		});
		setAdd(false);
		setVisible(true);
	};
	const deleteSpecialAttentionItem = (record: any) => {
		confirm({
			title: '您确定要删除"' + record.name + '" 分组?',
			okType: 'danger',
			onOk: async () => {
				let data = {
					id: record.id,
				};
				const res = await deleteSpecialAttention(data);
				if (res.err === 0) {
					if (actionRef.current) {
						actionRef.current.reload();
					}
					msg.suspensionMsg({ content: res.msg, type: 'success' });
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const setNoticeStatus = async (record: any, appNotice: boolean, smsNotice: boolean) => {
		let data = {
			id: record.id,
			appNotice: appNotice,
			smsNotice: smsNotice,
		};
		const res = await setNoticeCheck(data);
		if (res.err === 0) {
			message.success(res.msg);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
	};

	const tableSearchBarRender = () => [
		<Input
			placeholder={'请输入分组名称'}
			value={search}
			onChange={(e: any) => {
				setSearch(e.target.value);
			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];
	const openAddModal = () => {
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const tableOperationsBarRender = () => [
		<Button type={'primary'} onClick={openAddModal}>
			添加分组
		</Button>,
	];

	const onClickOk = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};

	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			setIsShow(true);
			let formData = {
				name: data.name,
				attentionPhone: data.attentionPhone.toString(),
				noticePhone: data.noticePhone.toString(),
				smsNotice: data.smsNotice,
				appNotice: data.appNotice,
			};
			console.log('formData::', formData);
			const res = await addSpecialAttention(formData);
			setIsShow(false);
			if (res.err === 0) {
				setVisible(false);
				message.success('添加成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				setVisible(false);
				message.error(res.msg);
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			setIsShow(true);
			let formData = {
				id: data.id,
				name: data.name,
				attentionPhone: data.attentionPhone.toString(),
				noticePhone: data.noticePhone.toString(),
				smsNotice: data.smsNotice,
				appNotice: data.appNotice,
			};
			console.log('formData::', formData);
			const res = await updateSpecialAttention(formData);
			setIsShow(false);
			if (res.err === 0) {
				setVisible(false);
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				setVisible(false);
				message.error(res.msg);
			}
		});
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {
						search: search,
						page: params.current,
						size: params.pageSize,
					};
					let res = await getSpecialAttentionList(data);
					if (res.err !== 0) {
						msg.suspensionMsg({ content: res.msg, type: 'danger' });
						return;
					}
					return {
						data: res.data.list,
						total: res.data.count,
						success: true,
					};
				}}
			/>
			<Modal
				title={add ? '添加' : '修改'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				confirmLoading={isShow}
				onOk={onClickOk}
			>
				<ProxyForm form={form}>
					<FormItem label={'id'} name={'id'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'分组名称'} name={'name'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem label={'关注者'} name={'attentionPhone'} rules={[{ required: true }]}>
						<Select
							mode="tags"
							// defaultValue={this.state.updateAttentionPhone.substring(0, this.state.updateAttentionPhone.length - 1).split(',')}
							// onChange={(e: any) => {this.setState({updateAttentionPhone: e.toString()}); }}
							style={{ width: '100%' }}
							tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
						/>
					</FormItem>
					<FormItem label={'通知者'} name={'noticePhone'} rules={[{ required: true }]}>
						<Select
							mode="tags"
							// defaultValue={this.state.updateAttentionPhone.substring(0, this.state.updateAttentionPhone.length - 1).split(',')}
							// onChange={(e: any) => {this.setState({updateAttentionPhone: e.toString()}); }}
							style={{ width: '100%' }}
							tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
						/>
					</FormItem>
					<FormItem valuePropName={'checked'} label={'短信通知'} name={'smsNotice'}>
						<Switch />
					</FormItem>
					<FormItem valuePropName={'checked'} label={'App通知'} name={'appNotice'}>
						<Switch />
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default SpecialAttention;
