import * as React from 'react';
import API from '../../../data/API';
import { Table, Divider, Modal, Input } from 'antd';
import {
	addHouseholdVerification,
	findhouseholdVerification,
	findhouseholdReportFormInfo,
} from '../../../services/ZhuhushenheService';

let moment = require('moment');
var msg = require('../../../util/SuspensionMsg');

class EntranceGuardReportInfo extends React.Component {
	columns = [
		{
			title: '房间位置',
			dataIndex: 'roomNumber',
			key: 'roomNumber',
		},
		{
			title: '手机号',
			dataIndex: 'phoneNo',
			key: 'phoneNo',
		},
		{
			title: '姓名',
			dataIndex: 'householdName',
			key: 'householdName',
		},
		{
			title: '入住日期',
			key: 'checkInTime',
			dataIndex: 'checkInTime',
			render: (text: any, record: any) => moment(text).format('YYYY-MM-DD'),
		},
		{
			title: '总天数',
			key: 'checkInNum',
			dataIndex: 'checkInNum',
		},
		{
			title: '90天居住率',
			key: 'occupancyRate',
			dataIndex: 'occupancyRate',
		},
		{
			title: '整体居住率',
			key: 'overallOccupancyRate',
			dataIndex: 'overallOccupancyRate',
		},
		{
			title: '人脸核查异常',
			key: 'faceShenheFail',
			dataIndex: 'faceShenheFail',
		},
		{
			title: '操作',
			key: 'action',
			dataIndex: 'action',
			render: (text: string, record: any) => (
				<span>
					<a onClick={this.Verification.bind(this, record)}>核查</a>
					<Divider type="vertical" />
					<a onClick={this.VerificationInfos.bind(this, record)}>详情</a>
				</span>
			),
		},
	];

	constructor(props: any) {
		super(props);
		this.state = {
			ds: [],
			Info: [],
			isShow: true,
			Verification: '',
			Verificationcontent: '',
			uuid: '',
			addModalvisible: false,
			xiangQingModalvisible: false,
			devices: props.location.state.deviceIds,
			buildUuid: props.location.state.uuid,
		};
		this.Verification = this.Verification.bind(this);
		this.Verificationcontent = this.Verificationcontent.bind(this);
		this.VerificationClick = this.VerificationClick.bind(this);
		this.VerificationInfos = this.VerificationInfos.bind(this);
	}

	componentDidMount = async () => {
		const res = await findhouseholdReportFormInfo({
			data: (this.state as any).devices.toString(),
			uuid: (this.state as any).buildUuid,
		});
		// API.postWithAuth('/api/zhuhu/findhousehold_report_form_info', {
		//     data: (this.state as any).devices.toString(),
		//     uuid: (this.state as any).buildUuid
		// }).then(res => {
		console.log('', res);
		if (res.err === 0) {
			this.setState({
				ds: res.data,
				isShow: false,
			});
		}
		// });
	};

	Verification(ev: any) {
		this.setState({
			Verification: ev.uuid,
			addModalvisible: true,
		});
		console.log('ev', ev.uuid);
	}

	VerificationInfos = async (ev: any) => {
		this.setState({
			uuid: ev.uuid,
			Info: [],
			xiangQingModalvisible: true,
		});
		const res = await findhouseholdVerification({
			uuid: ev.uuid,
		});
		// API.postWithAuth('/api/zhuhu/findhousehold_verification', {
		//     uuid: ev.uuid
		// }).then(res => {
		console.log('', res);
		if (res.err === 0) {
			this.setState({
				Info: res.data,
			});
		} else {
			return;
		}
		// });
	};

	Verificationcontent(ev: any) {
		this.setState({
			Verificationcontent: ev.target.value,
		});
	}

	VerificationClick = async () => {
		let state = this.state as any;
		const res = await addHouseholdVerification({
			content: state.Verificationcontent,
			uuid: state.Verification,
		});
		// API.postWithAuth('/api/zhuhu/add_household_verification', {
		//     content: state.Verificationcontent,
		//     uuid: state.Verification
		// }).then(res => {
		console.log('', res);
		if (res.err === 0) {
			msg.suspensionMsg('检查成功', 'success', 'Verification');
			this.componentDidMount();
		} else {
			return;
		}
		// });
	};

	render() {
		return (
			<div className="devices-list">
				{/*<div className="row" style={{paddingBottom: 10}}>
                    <div className="col-md-4">
                        <div className="input-group">
                            <div className="input-control search-box has-icon-left has-icon-right search-example" id="searchboxExample">
                                <input id="inputSearchExample3" type="search" className="form-control search-input" placeholder="搜索"/>
                                <label className="input-control-icon-left search-icon"><i className="icon icon-search"/></label>
                            </div>
                            <span className="input-group-btn">
                                <button className="btn btn-primary" type="button">搜索</button>
                            </span>
                        </div>
                    </div>
                </div>*/}
				<div className="content">
					<Table
						columns={this.columns}
						size="middle"
						dataSource={(this.state as any).ds}
						loading={(this.state as any).isShow}
					/>
				</div>
				{/*<table className="table table-striped ">
                    <thead>
                    <tr>
                        <th>房间位置</th>
                        <th>手机号</th>
                        <th>姓名</th>
                        <th>入住日期</th>
                        <th>总天数</th>
                        <th>90天居住率</th>
                        <th>整体居住率</th>
                        <th>人脸核查异常</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(this.state as any).ds.map((item: any, index: any) => (
                        <tr key={item.uuid}>
                            <td>{item.roomNumber}</td>
                            <td>{item.phone}</td>
                            <td>{item.householdNmae}</td>
                            <td>{item.stayDate}</td>
                            <td>{item.totalDays}</td>
                            <td>{item.habitationRate}</td>
                            <td>{item.residenceRate}</td>
                            <td>{item.NotThrough}</td>
                            <td>
                                <button className="btn" type="button" id={item.uuid} onClick={this.Verification} data-toggle="modal" data-target="#Verification">核查</button>
                                <button className="btn" type="button" id={item.uuid} onClick={this.VerificationInfos} data-toggle="modal" data-target="#VerificationInfo">详情</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>*/}
				{/*核查*/}
				<Modal
					title="核查"
					visible={(this.state as any).addModalvisible}
					onCancel={() => {
						this.setState({ addModalvisible: false });
					}}
					onOk={this.VerificationClick}
					okText={'添加'}
				>
					<div className="form-group">
						<label htmlFor="exampleInputAccount4" className="col-md-2">
							核查内容
						</label>
						<div className="col-md-10">
							<Input onInput={this.Verificationcontent} placeholder="请输入核查内容" />
						</div>
					</div>
				</Modal>

				{/*详情*/}
				<Modal
					title="核查详情"
					visible={(this.state as any).xiangQingModalvisible}
					onCancel={() => {
						this.setState({ xiangQingModalvisible: false });
					}}
					onOk={() => {
						this.setState({ xiangQingModalvisible: false });
					}}
				>
					<div className="modal-body">
						<table className="table table-striped ">
							<thead>
								<tr>
									<th>核查时间-内容</th>
								</tr>
							</thead>
							<tbody>
								{(this.state as any).Info.map((item: any, index: any) => (
									<tr key={item.uuid}>
										<td>
											{item.checkDate}-{item.content}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal>
			</div>
		);
	}
}

export default EntranceGuardReportInfo;
