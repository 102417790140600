import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import {
	Button,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Select,
	Image,
	Col,
	Row,
	Card,
	DatePicker,
	Space,
	Switch,
	TreeSelect,
	Tag,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import Api from '../../../data/API';
import ConnectState from '../../../models/connect';
import { connect } from 'dva';
import { addTalent, deleteTalent, getTalentList, updateTalent } from '../../../services/talent';
import UploadImgFormItem from '../../../components/UploadImgFormItem';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import GsTreeSelect from '../../../components/GsTreeSelect';
import DAL from '../../../data/DAL';
import { addWxBusiness, deleteWxBusiness, getWxBusinessList, setDefaultWxBusiness, updateWxBusiness } from '../../../services/Wx';
import { findPermGroupByOrder, getPermContractGroupByWxBusinessUuid, savePermGroupByOrder } from '../../../services/orderService';
const WxBusinessList: React.FC = (props: any) => {
	useEffect(() => {
		getPermOrderList().then()
	}, [])
	

	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const actionRef = useRef<ActionType>();
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);
	const [sortByType, setsortByType] = useState('时间');
	const [form] = Form.useForm<FormInstance>();
	const [dateTime, setdateTime] = useState('');
	const [type, settype] = useState('');
	const [status, setstatus] = useState("")
	const [name, setname] = useState('');
	const [techUuid, settechUuid] = useState('');
	const [typeId, settypeId] = useState('');
	const [talentTop, setTalentTop] = useState('');
	const FormItem = Form.Item;
	const [permForm] = Form.useForm();
	const [permVisible, setpermVisible] = useState(false);
	const [permOrderList, setpermOrderList] = useState([])
	const statusEnum = {
		true: { text: '上架', status: 'Success' },
		false: { text: '下架', status: 'Error' },
	};
	const topEnum = {
		true: { text: '置顶', status: 'Success' },
		false: { text: '未置顶', status: 'Error' },
	};
	const getPermOrderList = async () => {
		const res = await findPermGroupByOrder({});
		if (res.err == 0) {
			setpermOrderList([...res.data]);
		}
	};
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},{
			title: '默认',
			dataIndex: 'default',
			key: 'default',
			render: (_, item) => {
				return <><Tag color={item.default ? 'green' : 'default'} > { item.default ?"默认":""} </Tag></>
			}
		},
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							openUpdateModal(record);
						}}
					>
						编辑
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							defaultItem(record);
						}}
					>
						设置默认
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							openPermModal(record);
						}}
					>
						设置分组
					</a>
				</span>
			),
		},
	];

	
	const openPermModal = async(record:any) => { 
		const res = await getPermContractGroupByWxBusinessUuid({ wxBusinessUuid: record.uuid })
		if (res.err == 0) {	
			let permId = []
			const pcData = res.data || []
			res.data.map(item => { 
				permId.push(item.permGroupId)
			})
			permForm.setFieldsValue({wxBusinessUuid:record.uuid,permGroupId:permId})
			setpermVisible(true)

		} else { 
			message.error(res.msg)
		}
	}
	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '您确定要删除' + value.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteWxBusiness({ uuid: value.uuid });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error(res.msg);
				}
			},
		});
	};
	const defaultItem = (value: any) => {
		Modal.confirm({
			title: '您确定要设为默认吗？',
			onOk: async () => {
				const res = await setDefaultWxBusiness({ uuid: value.uuid });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error(res.msg);
				}
			},
		});
	};
	const openUpdateModal = (value: any) => {
		const formData: any = {
			...value
		};
		form.setFieldsValue(formData);
		setAdd(false);
		setVisible(true);
	};
	const openAddModal = () => {
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
            const formData: any = {
            ...data
            }
			const res = await addWxBusiness(formData);
			if (res.err === 0) {
				message.success('添加成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
            const formData: any = {
            ...data
            }
			const res = await updateWxBusiness(formData);
			if (res.err === 0) {
				message.success('修改成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={openAddModal}>
			新增
		</Button>,
	];
	// const tableSearchBarRender = () => [
	// 	<Space>
	// 		<Select
	// 			style={{ width: 120 }}
	// 			value={status}
	// 			placeholder={'状态'}
	// 			onChange={(value: any) => {
	// 				setstatus(value);
	// 			}}
	// 		>
	// 			<Select.Option value="">全部</Select.Option>
	// 			<Select.Option value={1}>上架</Select.Option>
	// 			<Select.Option value={0}>下架</Select.Option>
	// 		</Select>
	// 		<Input
	// 			placeholder="名称"
	// 			onChange={(e) => {
	// 				setname(e.target.value);
	// 			}}
	// 		/>
	// 		<Button
	// 			type="primary"
	// 			onClick={() => {
	// 				if (actionRef.current) {
	// 					actionRef.current.reloadAndRest();
	// 				}
	// 			}}
	// 		>
	// 			查询
	// 		</Button>
	// 	</Space>,
	// ];
	const savePerm = () => { 
		permForm.validateFields().then(async (data: any) => { 
			const res = await savePermGroupByOrder({ ...data })
			if (res.err == 0) {
				message.success('设置成功')
				setpermVisible(false)
				permForm.resetFields()
				actionRef.current.reload()
			} else { 
				message.error(res.msg)
			}
			
		})
	}
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				// searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				pagination={false}
				request={async (params: any = {}, sort: any, filter: any) => {
                    const res: any = await getWxBusinessList({});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				visible={visible}
				title={add ? '添加' : '修改'}
				width={800}
				onOk={saveItem}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} name={'uuid'}>
						<Input />
					</FormItem>
					<FormItem name={'name'} label={'名称'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem name={'wechatMiniProgramAppid'} label={'wechatMiniProgramAppid'}>
                        <Input />
                    </FormItem>
                    <FormItem name={'wechatPayAppid'} label={'wechatPayAppid'}>
                        <Input />
                    </FormItem>
                    <FormItem name={'wechatPayMchid'} label={'wechatPayMchid'}>
                        <Input />
                    </FormItem>
                    <FormItem name={'wechatPayKey'} label={'wechatPayKey'}>
                        <Input />
					</FormItem>
					<FormItem name={'wechatPayCert'} label={'wechatPayCert'}>
                        <Input />
                    </FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title={'设置权限分组'}
				visible={permVisible}
				width={800}
				onOk={() => {
					savePerm();
				}}
				onCancel={() => {
					setpermVisible(false);
				}}
			>
				<Form form={permForm} labelCol={{ span: 4 }}>
					<Form.Item name={'wxBusinessUuid'} hidden={true}>
						<Input></Input>
					</Form.Item>
					<Form.Item name={'permGroupId'} label={'分组'}>
						<Select mode="multiple" showSearch optionFilterProp="children">
							{permOrderList.map((item) => {
								return (
									<>
										<Select.Option value={item.id}>{item.name}</Select.Option>
									</>
								);
							})}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
export default WxBusinessList
