import React, { useEffect, useRef, useState } from "react";
import GSTable from "../../../components/GSTable";
import { findDepartmentDirectory } from "../../../services/WuyeService";
import { Row, Col, Card, Statistic, Space, TreeSelect, Button, message, Input } from "antd";
import { getUnlockData, getUnlockDataTop } from '../../../services/StatisticsService';
import CustomRangePicker from "../../../components/CustomRangePicker";
import moment from "moment";
import { getDevices } from "../../../services/ZhiNengMenJinService";
import GsTreeSelect from "../../../components/GsTreeSelect";
const UnlockRecordData: React.FC = ()=> {

    const actionRef = useRef<any>();
    const [department, setDepartment] = useState([]);
    const [departmentUuid, setDepartmentUuid] = useState([]);
    const [empSumData, setEmpSumData] = useState({total: 0, usertotal: 0,});
    const [isShow, setIsShow] = useState(false);
    const [startTime, setStartTime] = useState(moment().subtract(0, "d").hours(0).minute(0).second(0));
    const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
    const [devices, setDevices] = useState([]);
    const [deviceId, setDeviceId] = useState( '');
    const [search, setSearch] = useState('');
    const [selectType, setSelectType] = useState(1);
    const columns= [{
        title: '部门',
        dataIndex: 'departmentName',
        key: 'departmentName',
    }, {
        title: '姓名',
        dataIndex: 'realName',
        key: 'realName'
    },{
        title: '手机号',
        dataIndex: 'loginId',
        key: 'loginId'
    }, {
        title: '身份证号',
        dataIndex: 'cardIdNum',
        key: 'cardIdNum'
    }, {
        title: '通行次数',
        dataIndex: 'count',
        key: 'count',
        render: (text: any, record: any) => {
            return <a href={`/zhineng-menjin/kaimen-jilu?deviceIds=${deviceId.toString()}&phone=${record.loginId}&startTime=${startTime.format('YYYY-MM-DD HH:mm:ss')}&endTime=${endTime.format('YYYY-MM-DD HH:mm:ss')}&selectType=${selectType}`} target={"_blank"}>{text}</a>
        }
    }];

    useEffect(() => {
        getDepartment().then()
        getSelectDeviceList().then()
        getTopData().then()
    }, []);

    const getSelectDeviceList = async () => {
        const res = await getDevices({});
        if (res.err !== 0) {
            return;
        }
        let data: any = [];
        data.push({key: '全部设备', title: '全部设备', value: ''});
        data = data.concat(res.data)
        setDevices(data);
    };

    const getDepartment = async () => {
        const res = await findDepartmentDirectory({
            companyUuid: localStorage.getItem("companyUuid")
        });
        if (res.err == 0) {
            console.log('res:::', res.data);
            let dataList: any = [];
            res.data.map((item: any) => {
                let data: any = {};
                data["id"] = item.uuid;
                data["pId"] = item.parentUuid;
                data["value"] = item.uuid;
                data["title"] = item.departmentName;
                dataList.push(data);
            });
            setDepartment(dataList)
        }
    };
    const loadData = async (params: any) => {
        let data = {
            page: params.current,
            size: params.pageSize,
            deviceIds: deviceId,
            departmentUuid: departmentUuid.toString(),
            startTime: startTime.format('YYYY-MM-DD 00:00:00'),
            endTime: endTime.format('YYYY-MM-DD 23:59:59'),
            search: search
        };
        let rsp = await getUnlockData(data);
        if (rsp.err === 0) {
            return {
                data: rsp.data.data,
                total: rsp.data.total,
                success: true
            }
        }
        message.error(rsp.msg);
        return {
            data: [],
            total: 0,
            success: true
        }
    };
    const getTopData = async () => {
        let data = {
            deviceIds: deviceId,
            departmentUuid: departmentUuid.toString(),
            startTime: startTime.format('YYYY-MM-DD 00:00:00'),
            endTime: endTime.format('YYYY-MM-DD 23:59:59'),
            search: search
        };
        let rsp = await getUnlockDataTop(data);
        if (rsp.err === 0) {
            setEmpSumData(rsp.data.totalData)
        }
    }

    const tableExtraRender = () => {
        return (
            <Row gutter={16}>
                <Col span={6}>
                    <Card  style={{ backgroundColor: "#fff" }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>通行次数</span>}
                            value={empSumData.total}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>次</span>}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card  style={{ backgroundColor: "#fff" }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>通行人数</span>}
                            value={empSumData.usertotal}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>人</span>}
                        />
                    </Card>
                </Col>
            </Row>
        );
    };
    const tableSearchBarRender = () => {
        return [
            <Space>
                <CustomRangePicker
                    onChange={(startTime: any, endTime: any, type?: number) => {
                        setStartTime(startTime);
                        setEndTime(endTime);
                        setSelectType(type|| 1)
                    }}
                    selectType={selectType}
                    startTime={startTime}
                    endTime={endTime}
                />
                <GsTreeSelect
                    treeData={devices}
                    treeNodeFilterProp={'title'}
                    defaultValue={deviceId}
                    multiple={true}
                    onChange={(ev: any) => {
                        if (ev) {
                            setDeviceId(ev.toString());
                        }

                    }}
                />
                <GsTreeSelect
                    treeDefaultExpandAll
                    treeData={department}
                    treeCheckStrictly={true}
                    showCheckedStrategy={TreeSelect.SHOW_ALL}
                    placeholder={'请选择部门'}
                    treeDataSimpleMode={true}
                    onChange={(value: any) => {
                        let values: any =[];
                        value.map((item: any) => {
                            values.push(item.value)
                        })
                        setDepartmentUuid(values)
                    }}
                />
                <Input onChange={(e) => {
                    setSearch(e.target.value)
                }}/>
                <Button type={"primary"} onClick={() => {
                    actionRef.current.reloadAndRest();
                    getTopData().then()
                }}>查询</Button>
            </Space>
        ]
    }

    return (
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <GSTable
                columns={columns}
                rowKey={"userId"}
                actionRef={actionRef}
                tableExtraRender={tableExtraRender}
                searchBarRender={tableSearchBarRender()}
                // operationsBarRender={tableOperationsBarRender()}
                request={loadData}
            />
        </div>
    );
};
export default UnlockRecordData;
