import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Input, Menu, message, Modal, Row, Tooltip, Tree, TreeSelect } from 'antd';
import CompanyList from '../CompanyList';
import { MoreOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons/lib';
import {
	addDepartment,
	deleteDepartment,
	findDepartmentDirectory,
	updataDepartment,
} from '../../../services/WuyeService';
import API from '../../../data/API';
import { FormInstance } from 'antd/es/form';
import ProxyForm from '../../../components/ProxyForm';
import { forEach } from 'wangeditor/dist/utils/util';
import AuthorizedView from '../../../util/AuthorizedView';
import DAL from '../../../data/DAL';

const EmployeeList: React.FC = () => {
	require('../css/DepartmentList.css');
	let msg = require('../../../util/SuspensionMsg');
	//拖动
	const x = 3;
	const y = 2;
	const z = 1;
	let batchIndex = 0;
	let newData: any = [];
	useEffect(() => {
		getdepartment();
	}, []);
	let departData = [];
	const [key, setKey] = useState('');
	const [selectTreeData, setSelectTreeData] = useState([]);
	let companyName = '';
	const [data1, setData1] = useState([]);
	const [treeData, setTreeData] = useState([]);
	const [index, setIndex] = useState({});
	const [visible, setVisible] = useState(false);
	const [add, setAdd] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [addChild, setAddChild] = useState(false);
	const FormItem = Form.Item;
	const menu = (item: any) => (
		<Menu>
			<Menu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						editUpdata(item);
					}}
				>
					编辑部门
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						addChildDepartmentModal(item);
					}}
				>
					添加子部门
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						move(item, 1);
					}}
				>
					上移
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						move(item, -1);
					}}
				>
					下移
				</a>
			</Menu.Item>
			<Menu.Item danger={true}>
				<a
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						deleteItem(item.uuid);
					}}
				>
					删除
				</a>
			</Menu.Item>
		</Menu>
	);
	const deleteItem = (uuid: any) => {
		Modal.confirm({
			title: '您确定要删除此部门吗?',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteDepartment({ uuid: uuid });
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					getdepartment();
				} else {
					msg.suspensionMsg({ content: res.msg });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const move = (item: any, key: number) => {
		let index = 0;
		let items = departData;
		for (let i = 0; i < items.length; i++) {
			if (items[i].uuid === item.uuid) {
				index = i;
			}
		}
		let start = index,
			end = index - key;
		dragDrop(start, end);
	};
	const dragDrop = (start: number, end: number) => {
		console.log('data1::', departData);
		let items: any = reorder(departData, start, end);
		console.log('items', items);
		for (let k = 0; k < items.length; k++) {
			items[k].departmentNumber = k;
		}
		let { treeData, map } = renderTree1([...items], companyName);
		let { selectTreeData } = renderTree([...items], companyName);
		setTreeData([...treeData]);
		setSelectTreeData([...selectTreeData]);
		setIndex(map);
		departData = items;

		API.postWithAuth('/api/wuye/drag_drop_department', {
			data: JSON.stringify(items),
		}).then((res) => {
			console.log('res:::sdasd', res);
			if (res.err === 0) {
				getdepartment();
			} else {
			}
		});
	};

	const reorder = (list: any, startIndex: number, endIndex: number) => {
		console.log('拖动成功', list, startIndex, endIndex);
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const addChildDepartmentModal = (item: any) => {
		setAdd(true);
		setAddChild(true);
		let department = {
			uuid: item.uuid,
			departmentName: item.departmentName,
		};
		const formData: any = {
			selectedDepartmentUuid: JSON.stringify(department),
			departmentName: '',
		};
		form.setFieldsValue(formData);
		setVisible(true);
	};
	const editUpdata = (item: any) => {
		console.log('item::', item, localStorage.getItem('companyName'));
		let root: any = departData.filter((elemt: any) => elemt.uuid === item.parentUuid);
		let department = {};
		if (root.length > 0) {
			department = {
				uuid: root[0].uuid,
				departmentName: root[0].departmentName,
			};
		} else {
			department = {
				uuid: localStorage.getItem('companyUuid'),
				departmentName: companyName,
			};
		}
		const formData: any = {
			departmentUuid: item.uuid,
			selectedDepartmentUuid: JSON.stringify(department),
			departmentName: item.departmentName,
		};
		console.log('formData', formData);
		form.setFieldsValue(formData);
		setAdd(false);
		setAddChild(false);
		setVisible(true);
	};
	const everyOne = () => {
		return (
			<>
				<CompanyList key={key} departmentUuid={key} isDepartment={true} treeData={selectTreeData} />
			</>
		);
	};

	const renderTree = (data: any[], departmentName: any) => {
		let temp: any[] = [];
		temp.push({
			parentUuid: null,
			departmentName: departmentName,
			uuid: localStorage.getItem('companyUuid'),
		});
		data.forEach((item) => {
			temp.push({
				parentUuid: item.parentUuid === null ? localStorage.getItem('companyUuid') : item.parentUuid,
				departmentName: item.departmentName,
				uuid: item.uuid,
			});
		});

		temp.forEach((item) => {
			delete item.children;
		});
		let map = {};
		temp.forEach((item) => {
			map[item.uuid] = item;
		});
		let val: any[] = [];
		console.log('temp:::', temp);
		temp.forEach((item) => {
			let parent = map[item.parentUuid];
			item.title = <div>{item.departmentName}</div>;
			item.key = item.uuid;
			item.value = JSON.stringify({ uuid: item.uuid, departmentName: item.departmentName });
			if (!parent && item.uuid !== (localStorage.getItem('companyUuid') || '')) {
				parent = map[localStorage.getItem('companyUuid') || ''];
			}
			if (parent) {
				(parent.children || (parent.children = [])).push(item);
			} else {
				val.push(item);
			}
		});
		const arr = arrayTreeSetLevel(val, 'batchIndex', 'children');
		return {
			selectTreeData: arr,
		};
	};
	const getItem = (list: any, iid: any) => {
		for (let o of list || []) {
			if (o.uuid == iid) return o;
			const o_: any = getItem(o.children, iid);
			if (o_) return o_;
		}
	};
	const setIndex1 = (data: any, index: any, item1: any) => {
		for (let i = 0; i < data.length; i++) {
			if (item1.uuid == data[i].uuid) {
				return index;
			}
			if (data[i].children !== undefined && data[i].children.length > 0 && data[i].children !== null) {
				setIndex1(data[i].children, index++, item1);
			}
		}
	};

	const renderTree1 = (data: any[], departmentName: any) => {
		let temp: any[] = [];
		temp.push({
			parentUuid: null,
			departmentName: departmentName,
			order: -1,
			uuid: localStorage.getItem('companyUuid'),
		});
		data.forEach((item) => {
			temp.push({
				parentUuid: item.parentUuid === null ? localStorage.getItem('companyUuid') : item.parentUuid,
				departmentName: item.departmentName,
				order: item.departmentNumber,
				uuid: item.uuid,
			});
		});
		temp.forEach((item) => {
			delete item.children;
		});
		let map = {};
		temp.forEach((item) => {
			map[item.uuid] = item;
		});
		let val: any[] = [];
		console.log('原始数据：：：', temp);

		temp.forEach((item) => {
			let parent = map[item.parentUuid];
			item.title = (
				<Row gutter={[0, 10]} align={'middle'} style={{ marginTop: '0px', marginBottom: '0px', height: 30 }}>
					<Col span={2}>
						<UserOutlined />
					</Col>
					<Tooltip title={item.departmentName}>
						<Col
							span={20}
							style={{
								marginLeft: 4,
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{' '}
							{item.departmentName}
						</Col>
					</Tooltip>

					<AuthorizedView needAuthority={'business:departments:update'}>
						<Col
							span={2}
							style={{ position: 'absolute', left: '90%' }}
							hidden={localStorage.getItem('companyUuid') === item.uuid}
						>
							<Dropdown overlay={menu(item)}>
								<MoreOutlined onClick={(e) => e.preventDefault()} />
							</Dropdown>
						</Col>
					</AuthorizedView>
				</Row>
			);
			item.key = item.uuid;
			if (!parent && item.uuid !== (localStorage.getItem('companyUuid') || '')) {
				parent = map[localStorage.getItem('companyUuid') || ''];
			}
			if (parent) {
				(parent.children || (parent.children = [])).push(item);
			} else {
				val.push(item);
			}
		});
		console.log('封装数据：：：：', val);
		if (val.length > 1) {
			val.sort((a: any, b: any) => {
				return a.order - b.order;
			});
			val.map((item) => {
				sortNumber(item);
			});
		}
		const arr = arrayTreeSetLevel(val, 'batchIndex', 'children');
		return {
			treeData: arr,
			map: map,
		};
	};
	const sortNumber = (data: any) => {
		console.log('sortNumber:::data::::', data);
		if (data.children) {
			sortNumber(data.children);
		} else {
			// (data || []).sort((a: any, b: any) => {
			//     return a.order - b.order;
			// });
		}
	};
	const arrayTreeSetLevel = (array: any, levelName = 'batchIndex', childrenName = 'children') => {
		if (!Array.isArray(array)) return [];
		const recursive = (array: any, level = -2) => {
			level++;
			return array.map((v: any) => {
				v[levelName] = level;
				const child = v[childrenName];
				if (child && child.length) recursive(child, level);
				return v;
			});
		};
		return recursive(array);
	};
	const getdepartment = async () => {
		const res = await findDepartmentDirectory({
			companyUuid: localStorage.getItem('companyUuid'),
		});
		if (res.err == 0) {
			localStorage.setItem('companyName', res);
			let { treeData, map } = renderTree1([...res.data], res.companyName);
			let { selectTreeData } = renderTree([...res.data], res.companyName);
			departData = res.data;
			setTreeData([...treeData]);
			setSelectTreeData([...selectTreeData]);
			companyName = res.companyName;
			setIndex(map);
		}
	};
	const loop = (data: any, key: any, callback: any) => {
		for (let i = 0; i < data.length; i++) {
			if (data[i].key === key) {
				return callback(data[i], i, data);
			}
			if (data[i].children) {
				loop(data[i].children, key, callback);
			}
		}
	};
	const onDrop = (info: any) => {
		console.log('info:::::', info);
		const dropKey = info.node.props.eventKey;
		const dragKey = info.dragNode.props.eventKey;
		const dropPos = info.node.props.pos.split('-');
		const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
		const data = [...treeData];
		let dragObj: any;
		loop(data, dragKey, (item: any, index: any, arr: any) => {
			arr.splice(index, 1);
			dragObj = item;
			dragObj['parentUuid'] = !info.dropToGap ? info.node.uuid : info.node.parentUuid;
		});

		if (!info.dropToGap) {
			// Drop on the content
			loop(data, dropKey, (item) => {
				item.children = item.children || [];
				// where to insert 示例添加到尾部，可以是随意位置
				item.children.unshift(dragObj);
			});
		} else if (
			(info.node.props.children || []).length > 0 && // Has children
			info.node.props.expanded && // Is expanded
			dropPosition === 1 // On the bottom gap
		) {
			loop(data, dropKey, (item) => {
				item.children = item.children || [];
				// where to insert 示例添加到头部，可以是随意位置
				item.children.unshift(dragObj);
			});
		} else {
			let ar: any;
			let i: any;
			loop(data, dropKey, (item: any, index: any, arr: any) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}
		console.log('拖拽后的数据：：：：', data);
		const arr = arrayTreeSetLevel(data, 'batchIndex', 'children');
		setTreeData(arr);
		let updateData: [any?] = [];
		openTreeData(arr[0].children, updateData, arr[0].key);
		console.log('张开后::::', updateData);
		API.postWithAuth('/api/wuye/drag_drop_department', {
			data: JSON.stringify(updateData),
		}).then((res) => {
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				getdepartment();
				setVisible(false);
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
		});
	};

	const openTreeData = (data: any, arrData: [any?], companyUuid: string) => {
		data.map((item: any, index: number) => {
			if (item.children) {
				arrData.push({
					uuid: item.key,
					departmentName: item.departmentName,
					departmentNumber: index,
					batchIndex: item.batchIndex,
					parentUuid: companyUuid == item.parentUuid ? companyUuid : item.parentUuid,
				});
				openTreeData(item.children, arrData, companyUuid);
			} else {
				arrData.push({
					uuid: item.key,
					departmentName: item.departmentName,
					departmentNumber: index,
					batchIndex: item.batchIndex,
					parentUuid: companyUuid == item.parentUuid ? companyUuid : item.parentUuid,
				});
			}
		});
	};
	const onClickDepartment = (_, extra: any) => {
		console.log('node::::', extra);
		setKey(extra.uuid);
	};
	const addDepartmentshowModal = () => {
		form.resetFields();
		setVisible(true);
		setAdd(true);
		setAddChild(false);
	};
	const onClickOk = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const selectDepart = (value: any, label: any, extra: any) => {
		console.log('value, label, extra', value, label, extra);
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			let batchIndex = -1;
			let selectedDepartmentUuid = '';
			if (data.selectedDepartmentUuid !== undefined && data.selectedDepartmentUuid !== null) {
				const { uuid } = JSON.parse(data.selectedDepartmentUuid);
				selectedDepartmentUuid = uuid;
				const item = recursion(selectTreeData, uuid);
				batchIndex = item.batchIndex;
				console.log('selectedDepartmentUuid', selectedDepartmentUuid);
			}
			const res = await addDepartment({
				department_name: data.departmentName,
				companyUuid: API.getCompanyUuid(),
				parentUuid: selectedDepartmentUuid,
				batchIndex: batchIndex + 1,
			});
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				getdepartment();
				setVisible(false);
			} else {
				msg.suspensionMsg({ description: '添加失败', type: 'error' });
			}
		});
	};
	const recursion = (data: any, current: any) => {
		console.log('data:::rec', data, current);
		let result = null;
		if (!data) {
			return;
		}
		for (let i in data) {
			if (result != null) {
				break;
			}
			const item = data[i];
			if (item.uuid == current) {
				result = item;
				break;
			} else if (item.children && item.children.length > 0) {
				result = recursion(item.children, current);
			}
		}
		return result;
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			let batchIndex = -1;
			let selectedDepartmentUuid = '';
			if (data.selectedDepartmentUuid !== undefined && data.selectedDepartmentUuid !== null) {
				const { uuid } = JSON.parse(data.selectedDepartmentUuid);
				selectedDepartmentUuid = uuid;
				const item = recursion(selectTreeData, uuid);
				console.log('selectedDepartmentUuid', item);
				batchIndex = item.batchIndex;
			}
			const res = await updataDepartment({
				uuid: data.departmentUuid,
				department_name: data.departmentName,
				parent_uuid: selectedDepartmentUuid,
				batchIndex: batchIndex + 1,
			});
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				getdepartment();
				setVisible(false);
			} else {
				msg.suspensionMsg({ description: '修改失败', type: 'error' });
			}
		});
	};

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div >
					<Tree
						key={treeData.toString()}
						defaultExpandAll={true}
						draggable={DAL.permissions.indexOf('business:departments:update') !== -1}
						onDrop={onDrop}
						height={DAL.permissions.indexOf('business:departments:update') !== -1 ? 600 : 755}
						blockNode={true}
						style={{ padding: 15, minWidth: 300 }}
						treeData={treeData}
						onClick={onClickDepartment}
					/>
					<AuthorizedView needAuthority={'business:departments:update'}>
						<div style={{ margin: 15 }}>
							<Button
								style={{ width: '100%' }}
								icon={<PlusCircleOutlined />}
								onClick={addDepartmentshowModal}
							>
								新增部门
							</Button>
						</div>
					</AuthorizedView>
				</div>
				<div style={{ flex: 1}}>{everyOne()}</div>
			</div>
			{/*<Row wrap={false}>*/}
			{/*	<Col span={5}  >*/}
			{/*		*/}
			{/*	</Col>*/}
			{/*	<Col span={19}></Col>*/}
			{/*</Row>*/}
			<Modal
				title={add ? '添加' : '修改'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={onClickOk}
			>
				<ProxyForm form={form}>
					<FormItem label={'部门id'} name={'departmentUuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'部门名称'} name={'departmentName'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem label={'隶属部门'} name={'selectedDepartmentUuid'}>
						<TreeSelect
							key={new Date().getTime()}
							disabled={addChild}
							showSearch={true}
							dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
							allowClear={true}
							onChange={selectDepart}
							treeDefaultExpandAll={true}
							treeData={selectTreeData}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default EmployeeList;
