import React, { useRef, useState } from 'react';
import { connect } from 'dva';
import ConnectState from '../../../models/connect';
import {
	Button,
	Col,
	Input,
	Modal,
	Row,
	Tooltip,
	Form,
	message,
	Checkbox,
	TreeSelect,
	Divider,
	Select,
	Tag,
	Space,
	Menu,
	Dropdown,
} from 'antd';
import {
	addAdminWuyeGroup,
	deleteAdminWuyeGroup,
	findWuyeGroup,
	getPermGLiuChengByGroupId,
	groupOfMember,
	updataAdminWuyeGroup,
} from '../../../services/WuyeService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { ActionType } from '@ant-design/pro-table';
import { addPermGroupPerms } from '../../../services/PermItemService';
import DAL from '../../../data/DAL';
import { findByPermGId } from '../../../services/orderService';

const WuYePersonGroupList: React.FC = (props: any) => {
	let uuid = '';
	const confirm = Modal.confirm;
	const FromItem = Form.Item;
	const [form] = Form.useForm();
	const actionRef = useRef<ActionType>();
	const liuChengActionRef = useRef<ActionType>();
	const wxBusinessActionRef = useRef<ActionType>();
	const contractActionRef = useRef<ActionType>();
	const { common } = props;
	const { permItemList, empUserList } = common;
	const [visible, setVisible] = useState(false);
	const [add, setAdd] = useState(false);
	const [permissionItem, setPermissionItem] = useState(new Set<any>());
	const [groupUUID, setGroupUUID] = useState('');
	const [jurisdictionVisible, setJurisdictionVisible] = useState(false);
	const [permissionButtonLoading, setPermissionButtonLoading] = useState(false);
	const [treeSelect, setTreeSelect] = useState([]);
	const [membervisible, setMembervisible] = useState(false);
	const [liuChengVisiable, setLiuChengVisiable] = useState(false);
	const [wxBusinessVisible, setwxBusinessVisible] = useState(false)
	const [groupId, setGroupId] = useState('');
	const [empName, setEmpName] = useState('');
	const [contractVisible, setcontractVisible] = useState(false);
	const [name, setName] = useState('');
	const [isAdmin, setAdmin] = useState(false);
	const [type, settype] = useState('');
	const liuChengColums = [
		{
			title: '流程单',
			dataIndex: 'liuChengName',
			key: 'liuChengName',
		},
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			render: (text: string, record: any) => (
				<Tag color={record.state === 1 ? 'green' : record.state === 0 ? 'red' : ''}>
					{record.state === 1 ? '开启' : record.state === 0 ? '未开启' : '-'}
				</Tag>
			),
		},
	];
	const shanghuColums = [
		{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
		},
	];
	const contractColums = [
		{
			title: '合同模板',
			dataIndex: 'templateName',
			key: 'templateName',
		},
	];
	const columns = [
		{
			title: '分组名称',
			dataIndex: 'name',
			key: 'name',
			width: '15%',
			render: (text: string, record: any) => (
				<Tooltip title={text}>
					{' '}
					<a
						className={record.type === '-1' ? 'a-disable' : ''}
						id={'ts' + record.uuid + '1'}
						onClick={() => {
							update(record);
						}}
					>
						{text}
					</a>
				</Tooltip>
			),
		},
		{
			title: '分组类型',
			dataIndex: 'type',
			key: 'name',
			width: '8%',
			render: (text: string, record: any) => (
				<>
					{record.type !== undefined && (
						<Space>
							{DAL.dal.isNotNull(record.type) &&
								record.type
									.split(',')
									.map((item) => (
										<Tag
											color={
												item === '-1'
													? 'magenta'
													: item === '审批'
													? 'gold'
													: item === '自定义'
													? 'blue'
													: item == '合同'
													? 'orange'
													: item == '订单'
													? 'green'
													: 'default'
											}
										>
											{item === '-1' ? '系统' : item}
										</Tag>
									))}
						</Space>
					)}
				</>
			),
		},
		{
			title: '分组描述',
			dataIndex: 'description',
			key: 'description',
			width: '17%',
			render: (text: string, record: any) => (
				<Tooltip title={text}>
					{' '}
					<span id={'ts' + record.uuid + '2'}>{text}</span>
				</Tooltip>
			),
		},
		{
			title: '组成员',
			dataIndex: 'userList',
			key: 'userList',
			width: '40%',
			render: (text: string, record: any) => (
				<Tooltip title={record.userList !== undefined ? record.userList.toString() : record.userList}>
					{' '}
					<span id={'ts' + record.uuid + '3'}>
						{record.userList !== undefined ? record.userList.toString() : record.userList}
					</span>
				</Tooltip>
			),
		},
		{
			title: '操作',
			key: 'action',
			width: '20%',
			fixed: 'right',
			align: 'center',
			render: (text: string, record: any) => (
				<Space>
					{isAdmin && (
						<a
							className={'-1' === record.type ? 'a-disable' : ''}
							onClick={() => {
								Jurisdiction(record);
							}}
						>
							权限
						</a>
					)}

					{isAdmin && (
						<a
							onClick={() => {
								member(record);
							}}
						>
							成员
						</a>
					)}
					{isAdmin && (
						<a
							className={'-1' === record.type ? 'a-disable' : ''}
							onClick={() => {
								getuuid(record);
							}}
						>
							删除
						</a>
					)}
					<Dropdown
						overlay={
							<Menu>
								<Menu.Item>
									<a
										onClick={() => {
											opHt(record);
										}}
									>
										关联的合同
									</a>
								</Menu.Item>
								<Menu.Item>
									<a
										onClick={() => {
											opLiuCheng(record);
										}}
									>
										关联的流程单
									</a>
								</Menu.Item>
								<Menu.Item>
									<a
										onClick={() => {
											opWxBusiness(record);
										}}
									>
										关联的商户
									</a>
								</Menu.Item>
							</Menu>
						}
					>
						<a style={{ textDecoration: 'none' }} href="#">
							更多
						</a>
					</Dropdown>
				</Space>
			),
		},
	];

	const opLiuCheng = (record) => {
		setGroupId(record.id);
		setLiuChengVisiable(true);
	};
	const opWxBusiness = (record) => {
		setGroupId(record.id);
		setwxBusinessVisible(true);
	};

	const opHt = (record) => {
		setGroupId(record.id);
		setcontractVisible(true);
	};

	const getuuid = (res: any) => {
		console.log('res:::', res);
		if ('-1' !== res.type) {
			uuid = res.id;
			showConfirm();
		}
	};
	const showConfirm = () => {
		confirm({
			title: '你确定要删除此分组吗？',
			okType: 'danger',
			onOk() {
				deletewuyegroup();
			},
			onCancel() {},
		});
	};
	const deletewuyegroup = async () => {
		if (uuid === '') {
			message.warning('该分组无法删除');
			return;
		}
		const res = await deleteAdminWuyeGroup({
			uuid: uuid,
		});

		if (res.err === 0) {
			message.success('删除成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
	};
	const member = (value: any) => {
		console.log('empList::', empUserList);
		setTreeSelect(value.loginIdList);
		setGroupUUID(value.id);
		setMembervisible(true);
	};
	const Jurisdiction = (value: any) => {
		if (value.type !== '-1' || value.type === '1') {
			setPermissionItem(new Set(value.permItemIdList));
			setGroupUUID(value.id);
			setJurisdictionVisible(true);
		}
	};
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setVisible(true);
				setAdd(true);
				form.setFieldsValue({ type: '自定义' });
			}}
		>
			新增分组
		</Button>,
	];

	const update = (record: any) => {
		console.log('record', record);
		form.setFieldsValue({ ...record, type: record.type != undefined ? record.type.split(',') : '自定义' });
		if (record.type !== '-1') {
			setVisible(true);
			setAdd(false);
		}
	};
	const handleOk = () => {
		if (add) {
			addgroup();
		} else {
			updatagroup();
		}
	};

	const addgroup = async () => {
		form.validateFields().then(async (data) => {
			const res = await addAdminWuyeGroup({ ...data, type: data.type.toString() });
			if (res.err === 0) {
				message.success('添加成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('添加失败');
			}
			form.resetFields();
			setVisible(false);
		});
	};

	const updatagroup = async () => {
		form.validateFields().then(async (data) => {
			const res = await updataAdminWuyeGroup({ ...data, type: data.type.toString() });
			if (res.err === 0) {
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('修改失败');
			}
			form.resetFields();
			setVisible(false);
		});
	};

	const getAllChild = (data: any, cb: any) => {
		(data || []).map((item: any) => {
			cb(item);
			if (item.children) {
				getAllChild(item.children, cb);
			}
		});
	};

	const onChangeMenu = (e: any, child: any) => {
		console.log(`checked = ${e.target.checked}`);
		console.log('child::', child);
		let permissions = new Set(permissionItem);
		if (e.target.checked) {
			permissions.add(e.target.value);
			// if (child.length > 0) {
			// 	getAllChild(child, (item: any) => {
			// 		permissions.add(item.id + '');
			// 	});
			// }
		} else {
			permissions.delete(e.target.value);
			// if (child.length > 0) {
			// 	getAllChild(child, (item: any) => {
			// 		permissions.delete(item.id + '');
			// 	});
			// }
		}
		console.log('permissions::::', permissions);
		setPermissionItem(permissions);
	};
	const onChangeButton = (e: any) => {
		let permissions = new Set(permissionItem);
		if (e.target.checked) {
			permissions.add(e.target.value);
		} else {
			permissions.delete(e.target.value);
		}
		setPermissionItem(permissions);
	};

	const renderPermItem = (list: any) => {
		let lenght = list.length;
		return list.map((item1: any, index: number) => {
			if ((item1.children || []).length > 0) {
				return (
					<>
						<Row gutter={8} style={{ marginTop: 0 }}>
							<Col span={6}>
								<Checkbox
									value={item1.id + ''}
									onChange={(e) => {
										onChangeMenu(e, item1.children || []);
									}}
								>
									{' '}
									{item1.name}
								</Checkbox>
							</Col>
							<Col span={18}>{renderPermItem(item1.children)}</Col>
						</Row>
						{index === lenght - 1 ? null : <Divider type={'horizontal'} />}
					</>
				);
			}
			return (
				<Checkbox value={item1.id + ''} onChange={onChangeButton}>
					{item1.name}
				</Checkbox>
			);
		});
	};
	const jurisdictionModalOK = async () => {
		setPermissionButtonLoading(true);

		// if ((Array.from(permissionItem) || []).length == 0) {
		// 	message.warning('请选择');
		// 	return;
		// }
		let data = {
			permGroupId: groupUUID,
			permItemId: (Array.from(permissionItem) || []).length == 0 ? '' : Array.from(permissionItem).toString(),
		};

		let resp = await addPermGroupPerms(data);
		if (resp.err === 0) {
			setJurisdictionVisible(false);
			message.success(resp.msg);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(resp.msg);
		}
		setPermissionButtonLoading(false);
	};

	const groupofmember = async () => {
		const res = await groupOfMember({
			permId: groupUUID,
			empUuid: treeSelect.toString(),
		});
		if (res.err === 0) {
			message.success('保存成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error('保存失败');
		}
		setMembervisible(false);
	};
	const onChange = (value: any) => {
		setTreeSelect(value);
	};
	const tableSearchBarRender = () => [
		<Space>
			<Input
				placeholder="分组名称"
				onChange={(e) => {
					setName(e.target.value);
				}}
			></Input>
			<Input
				placeholder="员工姓名"
				onChange={(e) => {
					setEmpName(e.target.value);
				}}
			></Input>
			<Select defaultValue={''} onChange={(value) => settype(value)}>
				<Select.Option value={''}>类型</Select.Option>
				<Select.Option value={'自定义'}>自定义</Select.Option>
				<Select.Option value={'审批'}>审批</Select.Option>
				<Select.Option value={'合同'}>合同</Select.Option>
				<Select.Option value={'订单'}>订单</Select.Option>
				<Select.Option value={'产品'}>产品</Select.Option>
				<Select.Option value={'流程'}>流程</Select.Option>
			</Select>
			<Button
				type="primary"
				onClick={() => {
					actionRef.current.reloadAndRest();
				}}
			>
				查询
			</Button>
		</Space>,
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}) => {
					let url = '/api/v2/wuye/find_wuye_group';
					const res = await findWuyeGroup({
						page: params.current,
						size: params.pageSize,
						empName: empName,
						name: name,
						type: type,
					});
					if (res.err === 0) {
						setAdmin(res.data.isAdmin);
					}
					return {
						data: res.data.data,
						total: res.data.total,
						success: true,
					};
				}}
			/>
			<Modal
				title={add ? '新增角色' : '编辑分组'}
				visible={visible}
				onOk={handleOk}
				key={new Date().getTime()}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FromItem name="id" label="id" hidden={true}>
						<Input />
					</FromItem>
					<FromItem name="name" label="名称" rules={[{ required: true }]}>
						<Input placeholder="请输入角色名称" />
					</FromItem>
					<FromItem name="type" label="分组类型" rules={[{ required: true }]}>
						<Select mode="multiple">
							<Select.Option value={'自定义'}>自定义</Select.Option>
							<Select.Option value={'审批'}>审批</Select.Option>
							<Select.Option value={'合同'}>合同</Select.Option>
							<Select.Option value={'订单'}>订单</Select.Option>
							<Select.Option value={'产品'}>产品</Select.Option>
							<Select.Option value={'流程'}>流程</Select.Option>
						</Select>
					</FromItem>
					<FromItem name="description" label="描述">
						<Input.TextArea placeholder="请输入角色描述" />
					</FromItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="分配权限"
				visible={jurisdictionVisible}
				onOk={jurisdictionModalOK}
				confirmLoading={permissionButtonLoading}
				onCancel={() => {
					setJurisdictionVisible(false);
					setPermissionButtonLoading(false);
				}}
				width={1200}
				key={groupUUID}
			>
				<>
					<Row gutter={8} style={{ paddingBottom: 20 }}>
						<Col span={6}>
							<div>模块</div>
						</Col>
						<Col span={18}>
							<Row gutter={8} style={{ paddingTop: 0 }}>
								<Col span={6}>
									<div>子模块</div>
								</Col>
								<Col span={18}>
									<div>功能模块</div>
								</Col>
							</Row>
						</Col>
					</Row>
					<Checkbox.Group
						key={permissionItem.toString()}
						style={{ width: '100%' }}
						value={Array.from(permissionItem)}
					>
						{permItemList.map((item: any, index: number) => {
							let permItemlenght = (permItemList || []).length;
							return (
								<>
									<Row gutter={8} align="middle">
										<Col span={6}>
											<Checkbox
												value={item.id + ''}
												onChange={(e) => {
													onChangeMenu(e, item.children || []);
												}}
											>
												{' '}
												{item.name}
											</Checkbox>
										</Col>
										<Col span={18}>
											{(item.children || []).map((item1: any, index1: number) => {
												let childrenLength = (item.children || []).length;
												return (
													<>
														<Row gutter={8} style={{ marginTop: 0 }} align="middle">
															<Col span={6}>
																<Checkbox
																	value={item1.id + ''}
																	onChange={(e) => {
																		onChangeMenu(e, item1.children || []);
																	}}
																>
																	{' '}
																	{item1.name}
																</Checkbox>
															</Col>
															<Col span={18}>
																<Row gutter={8} align="middle" style={{ marginTop: 0 }}>
																	{(item1.children || []).map((item2: any) => {
																		return (
																			<Col span={6}>
																				<Checkbox
																					value={item2.id + ''}
																					onChange={onChangeButton}
																				>
																					{item2.name}
																				</Checkbox>
																			</Col>
																		);
																	})}
																</Row>
															</Col>
														</Row>
														{index1 === childrenLength - 1 ? null : (
															<Divider type={'horizontal'} style={{marginTop: 12, marginBottom: 12}} />
														)}
													</>
												);
											})}
										</Col>
										{/*<Col span={18}>*/}
										{/*    {renderPermItem(item.children || [])}*/}
										{/*</Col>*/}
									</Row>
									{index === permItemlenght - 1 ? null : <Divider type={'horizontal'} style={{marginTop: 12, marginBottom: 12}}  />}
								</>
							);
						})}
					</Checkbox.Group>
				</>
			</Modal>
			<Modal
				title="设置分组成员"
				visible={membervisible}
				onOk={groupofmember}
				onCancel={() => {
					setMembervisible(false);
				}}
			>
				<Row align={'middle'}>
					<Col>选择成员：</Col>
					<Col>
						<TreeSelect
							key={empUserList}
							treeData={empUserList}
							value={treeSelect}
							onChange={onChange}
							treeCheckable={true}
							multiple={true}
							treeNodeFilterProp="title"
							searchPlaceholder="请选择"
							dropdownStyle={{ overflow: 'auto' }}
							style={{ width: 300 }}
						/>
					</Col>
				</Row>
			</Modal>




			<Modal
				key={groupId + '11'}
				title="关联的流程单"
				visible={liuChengVisiable}
				footer={null}
				onCancel={() => {
					setLiuChengVisiable(false);
				}}
			>
				<GSTable
					columns={liuChengColums}
					pagination={false}
					actionRef={liuChengActionRef}
					request={async (params: any = {}) => {
						const res = await getPermGLiuChengByGroupId({
							groupId: groupId,
						});
						return {
							data: res.data,
							success: true,
						};
					}}
				/>
			</Modal>
			<Modal
				key={groupId + 'sahnghu'}
				title="关联的商户"
				visible={wxBusinessVisible}
				footer={null}
				onCancel={() => {
					setwxBusinessVisible(false);
				}}
			>
				<GSTable
					columns={shanghuColums}
					pagination={false}
					actionRef={wxBusinessActionRef}
					request={async (params: any = {}) => {
						const res = await findByPermGId({
							groupId: groupId,
						});
						return {
							data: res.data,
							success: true,
						};
					}}
				/>
			</Modal>
			<Modal
				key={groupId + '123'}
				title="关联的合同"
				visible={contractVisible}
				footer={null}
				onCancel={() => {
					setcontractVisible(false);
				}}
			>
				<GSTable
					columns={contractColums}
					pagination={false}
					actionRef={contractActionRef}
					request={async (params: any = {}) => {
						const res = await findContractByPermId({
							permGId: groupId,
						});
						return {
							data: res.data,
							success: true,
						};
					}}
				/>
			</Modal>
		</div>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(WuYePersonGroupList);
