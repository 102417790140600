import  React, { useState } from 'react';
import DAL from '../../../data/DAL';
import { Col, Input, message, Row, Form, Card, Radio, Switch, Button, Modal } from 'antd';
import { findUserInfo, updateCompnayEmployeeInfo } from '../../../services/scheme';
import { FormInstance } from 'antd/es/form';
import UploadImgFormItem from '../../../components/UploadImgFormItem';

interface Param{
	companyUuid: string,
	userUuid: string
	visible: boolean,
	onCancel:any
}
const EmployeeDetail:React.FC<Param> = (props:any) => {
	const [form]: any = Form.useForm<FormInstance>();
	const [user, setUser] = useState<any>({})
	const FormItem = Form.Item;
	React.useEffect(() => {
		if (props.visible) {
			getData();
		}
	}, []);

	const getData = async () => {
		console.log("props",props);

		const data = new FormData();
		data.append('username', props.userUuid);
		data.append('companyUuid', props.companyUuid);
		const res = await findUserInfo(data);
		if (res.err === 0) {
			setUser(res.data || {})
			// user: res.data,
			const formData = {
				...res.data,
				companyUuid: props.companyUuid,
				isCertification: res.data.isCertification ? true : false,
				ePass: res.data.ePass ? true : false,
				isCompanyAccount:res.data.isCompanyAccount?true:false
			}
			form.setFieldsValue(formData);


		} else {
			message.error(res.msg);
		}
	};
	const formItemLayout = {
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 24 },
		},
	};


	const saveItem = () => {
		form.validateFields().then(async (data: any) => {
			const res = await updateCompnayEmployeeInfo(data)
			if (res.err == 0) {
				message.success("修改成功")
				props.onCancel()
			} else {
				message.error(res.msg)
			}

		})
	}
	return (
		<>
			<Modal width={1200} title={user.realName+"基本信息"} visible={props.visible} onCancel={()=>props.onCancel()} onOk={saveItem}>
			<Form layout="vertical" {...formItemLayout} form={form} >
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'companyUuid'} name={'companyUuid'} hidden={true} initialValue={props.companyUuid}>
						<Input />
					</FormItem>
					<h3>基本信息</h3>
					<Row className="margin-top--10" gutter={12} justify={'start'} align={'top'}>
						<Col span={8}>
							<FormItem {...formItemLayout} name="userImg" label={'头像'} rules={[{ required: false }]}>
								<UploadImgFormItem  desc="请上传大小25K~1M、像素不低于480x640的清晰免冠照片" key={new Date().getTime().toString()} multiple={false} publicOrPrivate={'private'}></UploadImgFormItem>
							</FormItem>
						</Col>

						<Col span={16}>
							<Row style={{ marginTop: 0 }} gutter={12} justify={'start'}>
								<Col span={12}>
									<FormItem
										{...formItemLayout}
										label={'姓名'}
										name={'realName'}
										rules={[{ required: true }]}
									>
										<Input />
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										{...formItemLayout}
										label={'性别'}
										name={'sex'}
										// rules={[{ required: true }]}
									>
										<Radio.Group>
											<Radio value={'男'}>男</Radio>
											<Radio value={'女'}>女</Radio>
										</Radio.Group>
									</FormItem>
								</Col>
							</Row>
							<Row className="margin-top--10" gutter={12} justify={'start'}>
								<Col span={12}>
									<FormItem

										{...formItemLayout}
										label={'手机号'}
										name={'phone'}
										rules={[{ required: true }]}
									>
										<Input disabled={ true} />
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										{...formItemLayout}
										label={'邮箱'}
										name={'email'}
										// rules={[{ required: true }]}
									>
										<Input />
									</FormItem>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row className="margin-top--10" gutter={12} justify={'start'}>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'身份证号'} name={'cardId'}>
								<Input />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'银行卡号'} name={'bankcardNo'}>
								<Input />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem
								{...formItemLayout}
								label={'是否实名认证'}
								valuePropName="checked"
								name={'isCertification'}
								initialValue={false}
							>
								<Switch></Switch>
							</FormItem>
						</Col>
					</Row>
					<Row className="margin-top--10" gutter={12} justify={'start'}>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'所在地'} name={'address'}>
								<Input />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'户口所在地'} name={'homeAddress'}>
								<Input />
							</FormItem>
						</Col>

					</Row>
					<h3>工作信息</h3>
					<Row className="margin-top--10" gutter={12} justify={'start'}>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'公司名称'} name={'company'}>
								<Input disabled={ true} />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'部门'} name={'department'}>
								<Input />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem {...formItemLayout} label={'职位'} name={'position'}>
								<Input />
							</FormItem>
						</Col>

					</Row>
					<Row className="margin-top--10" gutter={12} justify={'start'}>
						<Col span={8}>
							<FormItem
								{...formItemLayout}
								label={'是否负责人'}
								valuePropName="checked"
								name={'isCompanyAccount'}
								initialValue={false}

							>
								<Switch></Switch>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem
								{...formItemLayout}
								label={'开启通行证'}
								valuePropName="checked"
								name={'ePass'}
								initialValue={false}

							>
								<Switch></Switch>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* <div className="container-fluid">
				<div  >
					<h1>{user.realName}基本信息</h1>
				</div>
			</div> */}
			{/* <div style={{backgroundColor:"#fff",padding:10}}> */}

				{/* <Button type='primary' onClick={saveItem}> 保存</Button> */}
			{/* </div> */}

		</>
	);
};
export default EmployeeDetail;
