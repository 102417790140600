import * as React from 'react';
import { Button, Col, Dropdown, Icon, Input, message, Row, Steps } from 'antd';
import CheckTable from './CheckTable';
import API from '../../../data/API';

interface CheckTableInterFace {
	allDevice: Array<any>;
	selectDevice: Array<string>;
	disabled: boolean;
	onCancel: any;
	refreshData: any;
}

const Step = Steps.Step;

export default class AddDeviceGroup extends React.Component<CheckTableInterFace> {
	allDevice = this.props.allDevice;
	searchDevice = this.props.allDevice;

	state: any;
	constructor(props: any) {
		super(props);
		this.state = {
			current: 0,
			page: 1,
			size: 30,
			data: [],
			selectDevice: props.selectDevice,
			indeterminate: false,
			checkAll: false,
			groupName: '',
		};
	}
	componentWillMount(): void {
		this.getData();
	}

	getData = () => {
		console.log('刷新数据：', this.searchDevice);
		let data = this.searchDevice.slice((this.state.page - 1) * this.state.size, this.state.page * this.state.size);
		let indeterminate = 0;
		data.map((item: any) => {
			if (this.state.selectDevice.includes(item.deviceId)) {
				indeterminate++;
			}
		});
		this.setState({
			data,
			indeterminate: !!indeterminate && indeterminate < data.length,
			checkAll: indeterminate === data.length,
		});
	};

	onChange = (selectDevice: any) => {
		let options: any = [];
		this.state.data.map((item: any) => {
			options.push(item.deviceId);
		});
		let newSelectDevice = this.state.selectDevice.filter((item: string) => {
			return !options.includes(item);
		});
		console.log('新的选中数组：', newSelectDevice.concat(selectDevice));
		this.setState({
			selectDevice: newSelectDevice.concat(selectDevice),
			indeterminate: !!selectDevice.length && selectDevice.length < this.state.data.length,
			checkAll: selectDevice.length === this.state.data.length,
		});
	};

	onCheckAllChange = (e: any) => {
		let options: any = [];
		this.state.data.map((item: any) => {
			options.push(item.deviceId);
		});
		let newSelectDevice = this.state.selectDevice.filter((item: string) => {
			return !options.includes(item);
		});
		console.log('新的选中数组：', newSelectDevice.concat(e.target.checked ? options : []));

		this.setState({
			selectDevice: newSelectDevice.concat(e.target.checked ? options : []),
			indeterminate: false,
			checkAll: e.target.checked,
		});
	};

	onSearch = (search: string) => {
		console.log('搜索条件：', search);
		if (search === '') {
			this.searchDevice = this.allDevice;
		} else {
			this.searchDevice = this.allDevice.filter((item: any) => {
				return item.deviceName !== null && item.deviceName.startsWith(search);
			});
			console.log('搜索后的数组：', this.searchDevice);
		}
		// this.setState({page: 1}, this.getData);
		this.getData();
	};

	pageChange = (page: number, pageSize: number) => {
		console.log('点击页码：', page);
		this.setState({ page }, this.getData);
	};

	onShowSizeChange = (current: number, size: number) => {
		this.setState({ page: 1, size: size }, this.getData);
	};

	next = () => {
		const current = this.state.current + 1;
		if (current === 2) {
			this.searchDevice = this.allDevice.filter((item: any) => {
				return this.state.selectDevice.includes(item.deviceId);
			});
			if (this.state.page === 1) {
				this.getData();
			} else {
				this.setState({ page: 1 }, this.getData);
			}
		}
		this.setState({ current });
	};

	prev = () => {
		const current = this.state.current - 1;
		if (current === 1) {
			this.searchDevice = this.allDevice;
			this.getData();
		}
		this.setState({ current });
	};

	showGroupName = () => {
		return (
			<Row type="flex" justify="center" align="middle" gutter={8} style={{ height: 100 }}>
				<Col span={6}>分组名称：</Col>
				<Col span={18}>
					<Input onInput={this.inputGroupName} value={this.state.groupName} />
				</Col>
			</Row>
		);
	};

	selectDevice = () => {
		return (
			<CheckTable
				isSearch={true}
				isShowCheckAll={true}
				checkAll={this.state.checkAll}
				size={this.state.size}
				disabled={this.props.disabled}
				onChange={this.onChange}
				onSearch={this.onSearch}
				data={this.state.data}
				selectDevice={this.state.selectDevice}
				indeterminate={this.state.indeterminate}
				onCheckAllChange={this.onCheckAllChange}
				onShowSizeChange={this.onShowSizeChange}
				page={this.state.page}
				pageChange={this.pageChange}
				total={this.searchDevice.length}
			/>
		);
	};

	showSelectDevice = () => {
		return (
			<CheckTable
				isSearch={false}
				isShowCheckAll={false}
				checkAll={this.state.checkAll}
				size={this.state.size}
				disabled={this.props.disabled}
				onChange={this.onChange}
				onSearch={this.onSearch}
				data={this.state.data}
				selectDevice={this.state.selectDevice}
				indeterminate={this.state.indeterminate}
				onCheckAllChange={this.onCheckAllChange}
				onShowSizeChange={this.onShowSizeChange}
				page={this.state.page}
				pageChange={this.pageChange}
				total={this.searchDevice.length}
			/>
		);
	};

	inputGroupName = (e: any) => {
		this.setState({
			groupName: e.target.value,
		});
	};

	/** 新增设备分组 */
	addDeviceGroup = () => {
		let groupName = this.state.groupName;
		this.setState({
			buttonLoading: true,
		});
		if (groupName === null || groupName === '') {
			message.error('分组名称不能为空!');
			return;
		}
		if (this.state.selectDevice.length === 0) {
			message.error('请选择设备!');
			return;
		}
		API.postWithAuth('/api/device/addDeviceGroup', {
			groupName: groupName,
			groupType: '普通分组',
			deviceIds: this.state.selectDevice.toString(),
		}).then((res) => {
			this.setState({
				buttonLoading: false,
			});
			if (res.err === 0) {
				this.props.onCancel();
				this.props.refreshData();
				message.success('操作成功');
			} else {
				message.error(res.msg);
			}
		});
	};

	render() {
		const steps = [
			{
				title: '输入分组名称',
				content: this.showGroupName,
			},
			{
				title: '添加分组设备',
				content: this.selectDevice,
			},
			{
				title: '确定',
				content: this.showSelectDevice,
			},
		];
		const { current } = this.state;
		return (
			<div>
				<Steps current={current}>
					{steps.map((item) => (
						<Step key={item.title} title={item.title} />
					))}
				</Steps>
				<div className="steps-content">{steps[current].content()}</div>
				<div className="steps-action">
					{current < steps.length - 1 && (
						<Button type="primary" onClick={() => this.next()}>
							下一步
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button type="primary" onClick={this.addDeviceGroup}>
							创建分组
						</Button>
					)}
					{current > 0 && (
						<Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
							上一步
						</Button>
					)}
				</div>
			</div>
		);
	}
}
