import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';

import ControlPlatform from './pages/ControlPlatform';
import ShenheList from './components/ShenheList';
import EntranceGuardReport from './components/EntranceGuardReport';
import HouseholdCheckOut from './components/HouseholdCheckOut';
import EntranceGuardReportInfo from './components/EntranceGuardReportInfo';
import KaimenData from '../assets/components/KaimenData';

export default function BaoBiaoMiniApp() {
	const rootUrl = '/baobiao';
	const menus: MiniAppMenu[] = [
		{
			name: '控制台',
			path: 'plat-form',
			authority: 'household:console:select',
			component: ControlPlatform,
		},
	];

	const routes: MiniAppRoute[] = [
		{
			path: 'renlian-shenhei',
			component: ShenheList,
		},
		{
			path: 'entrance_guard_report',
			component: EntranceGuardReport,
		},
		{
			path: 'household-check-out',
			component: HouseholdCheckOut,
		},
		{
			path: 'entrance-guard-report-info',
			component: EntranceGuardReportInfo,
		},
		{
			path: 'statistics',
			component: KaimenData,
		},
	];

	return <MiniApp miniAppName={'住户'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
