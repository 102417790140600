import Api from '../data/API';
export async function getInstitutesList(params: any) {
    return Api.postWithAuth('/admin/api/achievement/researchInstitutes/list', params);
}
export async function addResearchInstitutes(params: any) {
    return Api.postWithAuth('/admin/api/achievement/researchInstitutes/add', params);
}
export async function updateResearchInstitutes(params: any) {
    return Api.postWithAuth('/admin/api/achievement/researchInstitutes/update', params);
}
export async function getInstitutesUserList(params: any) {
    return Api.postWithAuth('/admin/api/achievement/institutesUser/list', params);
}
export async function addInstitutesUser(params: any) {
    return Api.postWithAuth('/admin/api/achievement/institutesUser/add', params);
}
export async function updateInstitutesUser(params: any) {
    return Api.postWithAuth('/admin/api/achievement/institutesUser/update', params);
}
export async function deleteInstitutesUser(params: any) {
    return Api.postWithAuth('/admin/api/achievement/institutesUser/delete', params);
}
export async function getAchievementList(params: any) {
    return Api.postWithAuth('/admin/api/achievement/list', params);
}
export async function updateStatus(params: any) {
    return Api.postWithAuth('/admin/api/achievement/updateStatus', params);
}
