import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Image, Input, message, Modal, Select } from "antd";
import Api from '../../../data/API';
import GSTable from '../../../components/GSTable';
import { deleteXunException, findException } from '../../../services/patrolService';
import { Link } from 'react-router-dom';
import AuthorizedView from '../../../util/AuthorizedView';

const Exception: React.FC = () => {
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [routeType, setRouteType] = useState('');
	const columns = [
		{
			title: '巡更路线',
			dataIndex: 'routeName',
		},
		{
			title: '路线类型',
			dataIndex: 'routeType',
		},
		{
			title: '异常位置',
			dataIndex: 'deviceName',
		},
		{
			title: '异常说明',
			dataIndex: 'description',
		},
		{
			title: '照片',
			render: (text: any, item: any) => (
				<Image width={80} height={80} className="homePage" src={Api.cdnRootPath + item.img} />
			),
		},
		{
			title: '处理说明',
			dataIndex: 'process',
		},
		{
			title: '反馈人',
			dataIndex: 'feedbackName',
		},
		{
			title: '反馈时间',
			dataIndex: 'feedbackTime',
		},
		{
			title: '备注',
			dataIndex: 'remarks',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<>
					<AuthorizedView needAuthority={'intelligent_patrol:patrol-exception:delete'}>
						<a
							onClick={() => {
								deleteItem(record);
							}}
						>
							删除
						</a>
					</AuthorizedView>
				</>
			),
		},
	];

	const deleteItem = (item: any) => {
		console.log('item::;', item);
		Modal.confirm({
			title: '你确定要删除这条 ' + item.routeName + '路线的异常记录吗？',
			okType: 'danger',
			onOk: async () => {
				const data = {
					uuid: item.uuid,
					wuyeUuid: localStorage.getItem('wyUuid'),
				};
				const res = await deleteXunException(data);
				if (res.err === 0) {
					message.success('删除成功');
					actionRef.current.reload();
				} else {
					message.error('删除失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const tableSearchBarRender = () => [
		<Select
			defaultValue={'全部类型'}
			onChange={(value) => {
				setRouteType(value);
			}}
		>
			<Select.Option key="全部类型" value={''}>
				全部类型
			</Select.Option>
			<Select.Option key="巡更" value={'巡更'}>
				巡更
			</Select.Option>
			<Select.Option key="巡检" value={'巡检'}>
				巡检
			</Select.Option>
		</Select>,
		<Input
			placeholder="路线名称"
			onChange={(event) => {
				setSearch(event.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	return (
		<div>
			<GSTable
				rowKey={'uuid'}
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findException({
						page: params.current,
						size: params.pageSize,
						search: search,
						routeType: routeType,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default Exception;
