import * as React from 'react';
import RadioButton from './RadioButton';
import CheckboxButton from './CheckboxButton';
import InputTextarea from './InputTextarea';
import { Input, DatePicker } from 'antd';
import Ueditor from '../js/ueditor';
let ueditor = Ueditor.uditor;
class Widget extends React.Component {
	constructor(props: any) {
		super(props);
	}
	/**
	 * 控件描述
	 */

	describe(descr: string, img: any) {
		console.log('调用描述');
		return (
			<div>
				{/*  <div>{descr}</div>*/}
				<div dangerouslySetInnerHTML={{ __html: descr }} />
				<div>
					{img.size === 0 ? (
						''
					) : (
						<div>
							{img.map((item: any, index: any) => (
								<img src={img} />
							))}
						</div>
					)}
				</div>
			</div>
		);
	}

	/**
	 * 定义数字数字输入框
	 * @returns {any}
	 */
	inputNumber(
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: string,
		aliasName: string,
		isHide: boolean
	) {
		return (
			<div style={{ paddingBottom: 10 }} hidden={isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />
					</span>
					{title === '' ? '未命名' : title}
				</div>
				<div>{this.describe(descr, img)}</div>
				<Input
					style={{ width: width, height: height, borderRadius: 0, borderColor: '#ddd' }}
					name={uuid}
					id={uuid}
					type="number"
				/>
				{aliasName === '' ||
				aliasName === null ||
				aliasName === 'null' ||
				aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {aliasName}
					</text>
				)}
			</div>
		);
	}

	/**
	 * 文本输入框
	 * @returns {any}
	 */
	inputTxt(
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: string,
		aliasName: string,
		isHide: boolean
	) {
		return (
			<div style={{ paddingBottom: 10 }} hidden={isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />
					</span>
					{title === '' ? '未命名' : title}
				</div>
				{/*<div style={{color: 'red'}}><i className={required ? 'icon icon-asterisk' : ''}/>{required ? '必填' : ''}</div>*/}
				<div>{this.describe(descr, img)}</div>
				<Input
					style={{ width: width, height: height, borderRadius: 0, borderColor: '#ddd' }}
					name={uuid}
					id={uuid}
					type="text"
				/>
				{aliasName === '' ||
				aliasName === null ||
				aliasName === 'null' ||
				aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {aliasName}
					</text>
				)}
			</div>
		);
	}

	/**
	 *  多文本输入框
	 * @param {number} width
	 * @param {number} height
	 * @param {string} title
	 * @param {string} descr
	 * @param img
	 * @param uuid
	 * @param {string} required
	 * @returns {any}
	 */
	inputTextarea(width: any, height: any, title: string, descr: string, img: any, uuid: any, required: string) {
		return (
			<div style={{ paddingBottom: 10 }}>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />
					</span>
					{title === '' ? '未命名' : title}
				</div>
				{/* <div style={{color: 'red'}}><i className={required ? 'icon icon-asterisk' : ''}/>{required ? '必填' : ''}</div>*/}
				<div>{this.describe(descr, img)}</div>
				{/*<input className={required} style={{width: width, height: height}} name={uuid} id={uuid} type="text" />*/}
				<InputTextarea
					className={'form-control' + ' ' + required}
					required={true}
					rows={3}
					style={{ width: width, height: height, borderRadius: '#ddd', borderColor: '#ddd' }}
					name={uuid}
					id={uuid}
				/>
			</div>
		);
	}

	/**
	 * 关联的组件
	 * @param rel
	 *  关联自动填写的每一个字段，比如、姓名、手机号码等等
	 */
	relevance(
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: string,
		aliasName: string,
		isHide: boolean,
		allowEdit: boolean
	) {
		return (
			<div style={{ paddingBottom: 10 }} hidden={isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />
					</span>
					{title === '' ? '未命名' : title}
				</div>
				{/* <div style={{color: 'red'}}><i className={required ? 'icon icon-asterisk' : ''}/>{required ? '必填' : ''}</div>*/}
				<div>{this.describe(descr, img)}</div>
				{/*<input className={required} style={{width: width, height: height}} name={uuid} id={uuid} type="rel"/>*/}
				<Input
					className={'form-control' + ' ' + required}
					readOnly={!allowEdit}
					style={{ width: width, height: height, borderRadius: 0, borderColor: '#ddd' }}
					name={uuid}
					id={uuid}
					type="rel"
				/>
				{aliasName === '' ||
				aliasName === null ||
				aliasName === 'null' ||
				aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {aliasName}
					</text>
				)}
			</div>
		);
	}

	inputFile(
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: string,
		aliasName: string
	) {
		return (
			<div>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />
					</span>
					{title === '' ? '未命名' : title}
				</div>
				{/* <div style={{color: 'red'}}><i className={required ? 'icon icon-asterisk' : ''}/>{required ? '必填' : ''}</div>*/}
				<div>{this.describe(descr, img)}</div>
				{/*<input style={{width: width, height: height}} name={uuid} type="file"/>*/}
				<input
					className={required}
					hidden={true}
					style={{ width: width, height: height }}
					id={uuid}
					name={uuid}
					value="/file.jpg "
				/>
				{aliasName === '' ||
				aliasName === null ||
				aliasName === 'null' ||
				aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {aliasName}
					</text>
				)}
				<div id="uploaderExample" className="uploader">
					<div className="file-list" data-drag-placeholder="请拖拽文件到此处" />
					<button type="button" className="btn btn-primary uploader-btn-browse">
						<i className="icon icon-cloud-upload" /> 选择文件
					</button>
				</div>
			</div>
		);
	}

	inputDateTime(
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: any,
		aliasName: string,
		isHide: boolean,
		initialValue: string,
		allowEdit: boolean
	) {
		return (
			<div style={{ paddingBottom: 10 }} hidden={isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />{' '}
					</span>
					{title === '' ? '未命名' : title}
				</div>
				{/* <div style={{color: 'red'}}><i className={required ? 'icon icon-asterisk' : ''}/>{required ? '必填' : ''}</div>*/}
				<div>{this.describe(descr, img)}</div>
				{/* <input type="text" className="form-control form-datetime dateTime" onMouseDown={this.oninputTime} name={uuid} id={uuid} placeholder="日期时间：yyyy-MM-dd hh:mm" style={{width: width, height: height}}/>*/}
				<DatePicker
					disabled={required}
					showTime={true}
					className={
						'form-control form-datetime dateTime' +
						' ' +
						uuid +
						' ' +
						required +
						' ' +
						(allowEdit ? '' : 'events')
					}
					name={uuid}
					id={uuid}
					placeholder="日期时间：yyyy-MM-dd hh:mm"
					style={{ width: width, height: height, borderRadius: 0, borderColor: '#ddd' }}
				/>
				{aliasName === '' ||
				aliasName === null ||
				aliasName === 'null' ||
				aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {aliasName}
					</text>
				)}
				{/*{widgetTime.dataTime1(uuid)}*/}
			</div>
		);
	}

	inputDate(
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: any,
		aliasName: string,
		isHide: boolean,
		initialValue: string,
		allowEdit: boolean
	) {
		return (
			<div style={{ paddingBottom: 10 }} hidden={isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />
					</span>
					{title === '' ? '未命名' : title}
				</div>
				{/*<div style={{color: 'red'}}><i className={required ? 'icon icon-asterisk' : ''}/>{required ? '必填' : ''}</div>*/}
				<div>{this.describe(descr, img)}</div>
				<DatePicker
					disabled={required}
					className={
						'form-control form-date dateTime' +
						' ' +
						uuid +
						' ' +
						required +
						' ' +
						(allowEdit ? '' : 'events')
					}
					name={uuid}
					id={uuid}
					placeholder="日期：yyyy-MM-dd"
					style={{ width: width, height: height, borderRadius: 0, borderColor: '#ddd' }}
				/>
				{aliasName === '' ||
				aliasName === null ||
				aliasName === 'null' ||
				aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {aliasName}
					</text>
				)}
				{/*{widgetTime.date1(uuid)}*/}
			</div>
		);
	}

	/**
	 * 手机号码
	 *
	 */
	inputPhoneNumber(
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: string,
		aliasName: string,
		isHide: boolean
	) {
		return (
			<div style={{ paddingBottom: 10 }} hidden={isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i style={{ fontSize: 10 }} className={required === 'required' ? 'icon icon-asterisk' : ''} />
					</span>
					{title === '' ? '未命名' : title}
				</div>
				{/*<div style={{color: 'red'}}><i className={required ? 'icon icon-asterisk' : ''}/>{required ? '必填' : ''}</div>*/}
				<div>{this.describe(descr, img)}</div>
				<Input
					className={'form-control' + ' ' + required}
					style={{ width: width, height: height, borderRadius: 0, borderColor: '#ddd' }}
					name={uuid}
					id={uuid}
					type="number"
					max-length={11}
				/>
				{aliasName === '' ||
				aliasName === null ||
				aliasName === 'null' ||
				aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: {aliasName}
					</text>
				)}
			</div>
		);
	}

	/**
	 * 单选按钮
	 */
	radioButton(title: string, descr: string, img: any, uuid: any, required: string) {
		return (
			<div>
				<div>
					<i style={{ fontSize: 10 }} className="icon icon-asterisk " />
					{title === '' ? '未命名' : title}
				</div>
				<div style={{ color: 'red' }}>
					<i className={required ? 'icon icon-asterisk' : ''} />
					{required ? '必填' : ''}
				</div>
				<div>{this.describe(descr, img)}</div>
				<label>
					<input type="radio" id={uuid} name={uuid + 'radioOptionsExample'} /> {required}
				</label>
			</div>
		);
	}

	/**
	 * 付文本编辑器
	 */
	ueditor(uuid: string) {
		ueditor.init();
		return <div id="containerueditor" />;
	}

	/**
	 * 通过类型选择控件
	 * @param type
	 *   需要的组件类型
	 * @param width
	 *   控件的宽度
	 * @param height
	 *   控件的高度
	 *  @param title
	 *   控件的名字
	 *   @param descr
	 *   控件的描述，比如该控件是做什么的
	 *   @param img
	 *   描述的图片 是一个数组
	 *   @param uuid
	 *   只有在用户获得数据结构的时候使用，创建接口的时候不使用
	 *   @param required
	 *    是否必填
	 *    @param initialValue
	 *    默认值
	 *    @param state
	 *    设置当前的模式，false代表在添加流程，true代表使用该控件
	 *    @param shiliUuid
	 *    实例的UUID
	 *     @param aliasName
	 *     别名
	 */

	selectWidget(
		deviceInfo: string,
		type: string,
		width: any,
		height: any,
		title: string,
		descr: string,
		img: any,
		uuid: any,
		required: string,
		initialValue: string,
		state: boolean,
		shiliUuid: string,
		aliasName: string,
		wuyeUUID: string,
		isHide: boolean,
		allowEdit: boolean
	) {
		switch (type) {
			case 'number':
				return this.inputNumber(width, height, title, descr, img, uuid, required, aliasName, isHide);
			case 'text':
				return this.inputTxt(width, height, title, descr, img, uuid, required, aliasName, isHide);
			case 'rel':
				return this.relevance(width, height, title, descr, img, uuid, required, aliasName, isHide, allowEdit);
			case 'dateTime':
				return this.inputDateTime(
					width,
					height,
					title,
					descr,
					img,
					uuid,
					required,
					aliasName,
					isHide,
					initialValue,
					allowEdit
				);
			case 'phoneNumber':
				return this.inputPhoneNumber(width, height, title, descr, img, uuid, required, aliasName, isHide);
			case 'date':
				return this.inputDate(
					width,
					height,
					title,
					descr,
					img,
					uuid,
					required,
					aliasName,
					isHide,
					initialValue,
					allowEdit
				);
			case 'textarea':
				//return this.inputTextarea(width, height, title, descr, img, uuid, required);
				return (
					<InputTextarea
						title={title}
						descr={descr}
						uuid={uuid}
						initialValue={initialValue}
						required={required}
						width={width}
						height={height}
						aliasName={aliasName}
						isHide={isHide}
					/>
				);
			case 'ueditor':
				return this.ueditor(uuid);
			case 'radio':
				return (
					<RadioButton
						title={title}
						descr={descr}
						uuid={uuid}
						initialValue={initialValue}
						required={required}
						aliasName={aliasName}
						isHide={isHide}
					/>
				);
			case 'checkbox':
				return (
					<CheckboxButton
						title={title}
						descr={descr}
						uuid={uuid}
						initialValue={initialValue}
						required={required}
						aliasName={aliasName}
						isHide={isHide}
					/>
				);
			default:
				return '';
		}
	}
}

export default Widget;
