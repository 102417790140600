import * as React from 'react';

import DAL from '../../../data/DAL';
import LookHuodong from '../pages/LookHuodong';

import { NavLink, Route, Switch } from 'react-router-dom';
import LookBmry from './LookBmry';
import LookSign from './LookSign';
import Tongji from './Tongji';
import LookDingdan from './LookDingdan';
import { Affix, Breadcrumb, Col, Menu, Row } from 'antd';
import { HomeOutlined } from '@ant-design/icons/lib';
import { MiniAppMenu } from '../../../util/MiniApp';

class HuoDongManger extends React.Component {
	deviceId: string = '';
	dal = DAL.dal;
	state: any;
	constructor(props: any) {
		super(props);
		this.getUuid = this.getUuid.bind(this);
		this.state = {
			curMenuPath: this.getKey(),
		};
	}

	getName() {
		let page = window.location.search;
		let c = page.split('&');
		return decodeURIComponent(c[1].split('=')[1]);
	}
	getKey() {
		let page = window.location.search;
		let c = page.split('&');
		return c[2].split('=')[1];
	}
	getUuid() {
		let page = window.location.search;
		let c = page.split('&');
		console.log("c[0].split('=')[1]", c[0].split('=')[1]);
		return c[0].split('=')[1];
	}
	switchMenu = (e: any) => {
		this.setState({
			curMenuPath: e.key,
		});
	};
	render() {
		return (
			<div>
				<Row style={{ marginLeft: 5 }}>
					<Col>
						<Breadcrumb>
							<Breadcrumb.Item href="/huodong/find-huodong-list">活动列表</Breadcrumb.Item>
							<Breadcrumb.Item>{this.getName()}</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col span={24} style={{ paddingRight: 20 }}>
						<Menu
							onClick={this.switchMenu}
							selectedKeys={[this.state.curMenuPath]}
							mode="horizontal"
							style={{ textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0)', marginLeft: -14 }}
						>
							<Menu.Item key={'find-bmry-list'}>
								<NavLink
									to={
										'/huodong/find-bmry-list?uuid=' +
										this.getUuid() +
										'&name=' +
										this.getName() +
										'&key=' +
										'find-bmry-list'
									}
								>
									报名管理
								</NavLink>{' '}
							</Menu.Item>
							<Menu.Item key={'find-sign-list'}>
								<NavLink
									to={
										'/huodong/find-sign-list?uuid=' +
										this.getUuid() +
										'&name=' +
										this.getName() +
										'&key=' +
										'find-sign-list'
									}
								>
									签到管理
								</NavLink>{' '}
							</Menu.Item>
							<Menu.Item key={'ding-dan-list'}>
								<NavLink
									to={
										'/huodong/ding-dan-list?uuid=' +
										this.getUuid() +
										'&name=' +
										this.getName() +
										'&key=' +
										'ding-dan-list'
									}
								>
									订单管理
								</NavLink>{' '}
							</Menu.Item>
						</Menu>
					</Col>
				</Row>
				{/*<nav className="submenu navbar navbar-default" role="navigation" style={{paddingBottom: 0 , backgroundColor: '#f8fafe'}}>*/}
				{/*    <div className="container-fluid">*/}
				{/*        <nav className="filter-menu-bar navbar navbar-default" role="navigation">*/}
				{/*            <ul className="nav navbar-nav">*/}
				{/*                <li className={window.location.pathname === '/huodong/find-bmry-list' ? 'active' : ''} ><NavLink  to={'/huodong/find-bmry-list?uuid=' + this.getUuid() + '&name=' + this.getName()}>报名管理</NavLink></li>*/}
				{/*                <li className={window.location.pathname === '/huodong/find-sign-list' ? 'active' : ''} ><NavLink to={'/huodong/find-sign-list?uuid=' + this.getUuid() + '&name=' + this.getName()} >签到管理</NavLink></li>*/}
				{/*                <li className={window.location.pathname === '/huodong/ding-dan-list' ? 'active' : ''} ><NavLink   to={'/huodong/ding-dan-list?uuid=' + this.getUuid() + '&name=' + this.getName()}>订单管理</NavLink></li>*/}
				{/*            </ul>*/}
				{/*        </nav>*/}
				{/*    </div>*/}
				{/*</nav>*/}

				<div>
					<Switch>
						<Route path="/huodong/find-bmry-list" component={LookBmry} />
						<Route path="/huodong/find-sign-list" component={LookSign} />
						<Route path="/huodong/ding-dan-list" component={LookDingdan} />
						<Route path="/huodong/tongji" component={Tongji} />
						<Route
							render={() => (
								<LookHuodong startDay={''} endDay={''} classification={''} isHuodongReport={false} />
							)}
						/>
					</Switch>
				</div>
			</div>
		);
	}
}

export default HuoDongManger;
