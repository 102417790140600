import * as React from 'react';
import DAL from '../../../data/DAL';
import Api from '../../../data/API';
import MyUeditor from '../../../components/MyUeditor';
import { DatePicker, Button, Input, Dropdown, Menu } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { findCityAreaList, findHuodongEtails, updateHuodong } from '../../../services/huodongService';

let moment = require('moment');
let msg = require('../../../util/SuspensionMsg');
require('./addHuodong.css');
class UpdataHuodong extends React.Component {
	dal = DAL.dal;
	UE = (window as any).UE;
	provinces: any = [];
	allData: any = [];
	constructor(props: any) {
		super(props);
		this.state = {
			isShow: false,
			huodongInitiator: '',
			huodongInitiatorPhone: '',
			userId: '',
			huodongBiaoti: '',
			huodongXiangqing: '',
			tupianUrl: '',
			lbs: '',
			isDelete: '',
			huodongFaqiDate: '',
			huodongJiezhiDate: '',
			huodongJieshuDate: '',
			huodongKaishiDate: '',
			date: [''],
			huodongUuid: '',
			huodongFormUuid: '',
			type: '',
			money: '',
			people: '',
			zhuangTai: '',
			ticketDescribe: '',
			cities: [],
			counties: [],
			ticket: [],
			areaName: '',
			id: '',
			areaParentId: '',
			cityArea: '请选择省',
			cityArea1: '请选择市',
			cityArea2: '请选择区',
			isHidde: false,
			piaozhong: [],
			isHidden: true,
			date_kind: 'end_time',
			selectType: [], //获得的类型
			selectIsShenhe: [],
			moneys: [],
			peoples: [],
			ticketDescribes: [],
			isChecks: [],
			isDeletes: [], //判断删除的票票种
		};
		this.getHuodongInitiator = this.getHuodongInitiator.bind(this);
		this.getHuodongBiaoti = this.getHuodongBiaoti.bind(this);
		this.getHuodongInitiatorPhone = this.getHuodongInitiatorPhone.bind(this);
		this.getLbs = this.getLbs.bind(this);
		this.getImg = this.getImg.bind(this);
		this.addTicket = this.addTicket.bind(this);
		this.inputDateTime = this.inputDateTime.bind(this);
		this.getZhuangTai = this.getZhuangTai.bind(this);
		this.getHuodongJiezhiDate = this.getHuodongJiezhiDate.bind(this);
		this.getHuodongJieshuDate = this.getHuodongJieshuDate.bind(this);
		this.getHuodongKaishiDate = this.getHuodongKaishiDate.bind(this);
		this.huodongJieshuDate = this.huodongJieshuDate.bind(this);
		this.huodongKaishiDate = this.huodongKaishiDate.bind(this);
		this.delTicket = this.delTicket.bind(this);
		this.ticketDescribe = this.ticketDescribe.bind(this);
		this.getHtmls = this.getHtmls.bind(this);
		this.getOneId = this.getOneId.bind(this);
		this.getTwoId = this.getTwoId.bind(this);
		this.zidingTime = this.zidingTime.bind(this);
		this.end = this.end.bind(this);
		this.ProvincesCitiesCounties = this.ProvincesCitiesCounties.bind(this);
		this.getThreeId = this.getThreeId.bind(this);
		this.getData = this.getData.bind(this);
		this.addFabu = this.addFabu.bind(this);
		//   this.dianji = this.dianji.bind(this);
		this.getUuid = this.getUuid.bind(this);
		this.selectType = this.selectType.bind(this);
		this.getpostion = this.getpostion.bind(this);
		this.getType = this.getType.bind(this);
		this.dropDownShenhe = this.dropDownShenhe.bind(this);
		this.scelect = this.scelect.bind(this);
		this.inputText = this.inputText.bind(this);
	}

	componentWillMount() {
		//this.ProvincesCitiesCounties();
	}

	componentDidMount() {
		let page = window.location.search;
		let c = page.split('=');
		let uuid = c[1];
		console.log(uuid);
		// img.setCallBack(this.getImg);
		this.getData();
		this.ProvincesCitiesCounties();
		let j = jQuery as any;
		let that = this;
		// that.UE.getEditor('myDivContainer').setHeight(300);
		// this.UE.getEditor('editor').setHeight(300);
		j('#uploaderExample').uploader({
			filters: {
				max_file_size: '30mb',
				// 不允许上传重复文件
				prevent_duplicates: true,
			},
			resize: {
				width: 512,
				height: 512,
				crop: false,
				quuality: 50,
			},
			autoUpload: true, // 当选择文件后立即自动进行上传操作
			url: Api.apiRootPath + '/api/picture/upload_qiniu_two', // 文件上传提交地址
			multipart_params: {
				wuye_uuid: localStorage.getItem('wyUuid'),
			},
			chunk_size: 0,
			responseHandler: function fName(responseObject: any, file: any) {
				if (JSON.parse(responseObject.response) !== null && JSON.parse(responseObject.response) !== undefined) {
					console.log(responseObject.response);
					that.getImg(JSON.parse(responseObject.response).url);
				}
			},
		});
	}

	getData = async () => {
		let ser = window.location.search;
		let sp = ser.split('=');
		if (sp[1] === undefined) {
			msg.suspensionMsg('uuid异常');
			return;
		}

		const res = await findHuodongEtails({
			uuid: sp[1],
		});

		// Api.postWithAuth('/api/huodong/find_huodong_etails' , {
		//     uuid: sp[1]
		// }).then(res => {
		console.log('获得详细信息', res.data);
		let da = res.data;
		let jq = jQuery as any;
		jq('.huodongBiaoti').val(da.huodongBiaoti);
		jq('.huodongKaishiDate').val(da.huodongKaishiDate);
		jq('.huodongJieshuDate').val(da.huodongJieshuDate);
		jq('.lbs').val(da.lbs);
		this.setState({
			huodongKaishiDate: da.huodongKaishiDate,
			huodongJieshuDate: da.huodongJieshuDate,
			huodongBiaoti: da.huodongBiaoti,
			cityArea: da.cityArea,
			cityArea1: da.cityArea1,
			cityArea2: da.cityArea2,
		});
		jq('.huodongInitiator').val(da.huodongInitiator);
		jq('.huodongInitiatorPhone').val(da.huodongInitiatorPhone);
		jq('.huodongJiezhiDate').val(da.huodongJiezhiDate);
		// alert('获得的时间' + da.huodongJiezhiDate);
		this.setState({
			huodongInitiator: da.huodongInitiator,
			huodongInitiatorPhone: da.huodongInitiatorPhone,
			huodongJiezhiDate: da.huodongJiezhiDate,
			tupianUrl: da.tupianUrl,
			date: da.piaozhong,
			zhuangTai: da.zhuangTai,
		});
		let piaozhong = da.piaozhong;
		for (let j = 0; j < piaozhong.length; j++) {
			let a = piaozhong[j];
			(this.state as any).selectType[j] = a.type;
			(this.state as any).moneys[j] = a.money;
			(this.state as any).peoples[j] = a.people;
			(this.state as any).ticketDescribes[j] = a.ticketDescribe;
			(this.state as any).isChecks[j] = a.isAudit;
			(this.state as any).selectIsShenhe[j] = a.isAudit;
		}
		this.setState({
			selectType: (this.state as any).selectType,
			isApply: res.isApply,
		});
		//selectType
		if (da.huodongXiangqing !== null || da.huodongXiangqing !== undefined) {
			let that = this;
			setTimeout(function () {
				that.UE.getEditor('myDivContainer').setContent(da.huodongXiangqing);
			}, 500);
		}

		// });
	};

	dropDownShenhe(width: any, height: number, uuid: any) {
		return (
			<div>
				<input
					type="text"
					className={'add-company-count-div2 ' + uuid}
					hidden={true}
					value={(this.state as any).selectIsShenhe[uuid]}
					name="ticket"
					id={uuid}
				/>
				<div className="btn-group">
					<button
						type="button"
						id={uuid}
						onClick={this.getpostion}
						className="btn dropdown-toggle"
						data-toggle="dropdown"
					>
						{(this.state as any).selectIsShenhe[uuid] === undefined
							? '请选择'
							: (this.state as any).selectIsShenhe[uuid]}{' '}
						<span className="caret" />
					</button>
					<ul className="dropdown-menu" role="menu">
						<li onClick={this.scelect} id="是" style={{ padding: 5, textAlign: 'center' }}>
							是
						</li>
						<li onClick={this.scelect} id="否" style={{ padding: 5, textAlign: 'center' }}>
							否
						</li>
					</ul>
				</div>
			</div>
		);
	}

	/**
	 * 选择审核类型
	 */
	scelect(res: any) {
		console.log('选择的数据', res.target.id === '0');
		let st = this.state as any;
		let selectIsShenhe = st.selectIsShenhe;
		selectIsShenhe[st.buttomPostion] = res.target.id;
		this.setState({
			selectIsShenhe: selectIsShenhe,
		});
	}

	getImg(ress: any) {
		this.setState({
			tupianUrl: ress,
		});
	}

	ProvincesCitiesCounties = async () => {
		let that = this;
		const res = await findCityAreaList({});
		// Api.postWithAuth('/api/huodong/find_city_area_list' , {}).then(res => {
		console.log(1111111111, res.data);

		// this.provinces = res.data;
		this.allData = res.data;

		let ctr: any[] = [];
		let dangqiangcheshi = '';

		for (let i = 0; i < res.data.length; i++) {
			let item = res.data[i];
			if (item.area_parent_id === 0) {
				this.provinces.push(item);
			}
			if (dangqiangcheshi === '') {
				if (item.areaName === (this.state as any).cityArea) {
					dangqiangcheshi = item.id;
				}
			}
			if (dangqiangcheshi !== '') {
				if (item.area_parent_id === dangqiangcheshi) {
					//父ID等于当前ID
					ctr.push(item);
				}
			}
		}
		this.setState({
			cities: ctr,
		});
		let ctr1: any = [];
		let dangqiangcheshi1 = '';
		for (let h = 0; h < this.provinces.length; h++) {
			let item1 = this.provinces[h];
			if (dangqiangcheshi1 === '') {
				if (item1.areaName === (this.state as any).cityArea1) {
					dangqiangcheshi1 = item1.id;
				}
			}
			if (dangqiangcheshi1 !== '') {
				if (item1.area_parent_id === dangqiangcheshi1) {
					ctr1.push(item1);
				}
			}
		}

		console.log('赛选的二级菜单1', ctr1);
		let date1 = new Date();
		this.setState({
			counties: ctr1,
		});
		let date2 = new Date();
		console.log('运行时间', date2.getTime() - date1.getTime());
		// });
	};

	getUuid() {
		let page = window.location.search;
		let c = page.split('=');
		let uuid = c[1];
		return uuid;
	}
	addFabu = async (flag: any) => {
		let page = window.location.search;
		let c = page.split('=');
		let uuid = c[1];
		let sta = this.state as any;
		let regP = '(^(0[0-9]{2,3}\\-)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?$)|(^0?[1][358][0-9]{9}$)';
		if (!sta.huodongInitiatorPhone.match(regP)) {
			msg.suspensionMsg('手机号格式不正确');
			return;
		}
		if (
			sta.huodongJieshuDate <= sta.huodongKaishiDate &&
			sta.huodongJieshuDate === '' &&
			sta.huodongJieshuDate === null
		) {
			msg.suspensionMsg('结束日期不能为空且大于开始时间');
			return;
		}
		if (sta.huodongJiezhiDate > sta.huodongJieshuDate) {
			msg.suspensionMsg('截止时间不能大于活动结束时间');
			return;
		}

		if (sta.huodongJiezhiDate === null) {
			msg.suspensionMsg('截止时间不能为空');
			return;
		}
		if (sta.huodongBiaoti === null) {
			msg.suspensionMsg('标题不能为空');
			return;
		}
		if (sta.huodongInitiator === null) {
			msg.suspensionMsg('发起人不能为空');
			return;
		}
		if (sta.cityArea === null || sta.cityArea1 === null || sta.cityArea2 === null) {
			msg.suspensionMsg('地址不能为空');
			return;
		}

		const res = await updateHuodong({
			uuid: this.getUuid(),
			huodongInitiator: (this.state as any).huodongInitiator,
			huodongXiangqing: this.UE.getEditor('myDivContainer').getContent(),
			huodongInitiatorPhone: (this.state as any).huodongInitiatorPhone,
			huodongBiaoti: (this.state as any).huodongBiaoti,
			lbs: (this.state as any).lbs,
			huodongKaishiDate: (this.state as any).huodongKaishiDate,
			huodongJieshuDate: (this.state as any).huodongJieshuDate,
			huodongJiezhiDate: (this.state as any).huodongJiezhiDate,
			tupianUrl: (this.state as any).tupianUrl,
			//ticket: ev.ticket.toString(),
			selectType: (this.state as any).selectType.toString(), //获得的类型
			selectIsShenhe: (this.state as any).selectIsShenhe.toString(),
			moneys: (this.state as any).moneys.toString(),
			peoples: (this.state as any).peoples.toString(),
			ticketDescribes: (this.state as any).ticketDescribes.toString(),
			isDeletes: (this.state as any).isDeletes.toString(),
			zhuangTai: flag,
			cityArea: (this.state as any).cityArea,
			cityArea1: (this.state as any).cityArea1,
			cityArea2: (this.state as any).cityArea2,
			date_kind: (this.state as any).date_kind,
		});
		// Api.postWithAuth('/api/huodong/update_huodong' , {
		//     uuid: this.getUuid(),
		//     huodongInitiator: (this.state as any).huodongInitiator,
		//     huodongXiangqing: this.UE.getEditor('myDivContainer').getContent(),
		//     huodongInitiatorPhone: (this.state as any).huodongInitiatorPhone,
		//     huodongBiaoti: (this.state as any).huodongBiaoti,
		//     lbs: (this.state as any).lbs,
		//     huodongKaishiDate: (this.state as any).huodongKaishiDate,
		//     huodongJieshuDate: (this.state as any).huodongJieshuDate,
		//     huodongJiezhiDate: (this.state as any).huodongJiezhiDate,
		//     tupianUrl: (this.state as any).tupianUrl,
		//     //ticket: ev.ticket.toString(),
		//     selectType: (this.state as any).selectType.toString(), //获得的类型
		//     selectIsShenhe: (this.state as any).selectIsShenhe.toString(),
		//     moneys: (this.state as any).moneys.toString(),
		//     peoples: (this.state as any).peoples.toString(),
		//     ticketDescribes: (this.state as any).ticketDescribes.toString(),
		//     isDeletes: (this.state as any).isDeletes.toString(),
		//     zhuangTai: flag,
		//     cityArea: (this.state as any).cityArea,
		//     cityArea1: (this.state as any).cityArea1,
		//     cityArea2: (this.state as any).cityArea2,
		//     date_kind: (this.state as any).date_kind
		// }).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg('成功', 'success', '');
			window.location.href = '/huodong/find_huodong_list';
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};
	getHtmls(e: any) {
		this.setState({
			huodongXiangqing: e,
		});
	}

	/**
	 *
	 * 获得第一级的ID
	 *
	 */
	getOneId(res: any) {
		console.log('筛选的二级菜单', res.target.value);
		(this.state as any).cityArea = res.target.value;
		(this.state as any).cityArea1 = '';
		(this.state as any).cityArea2 = '';
		let ctr: any[] = [];
		let dangqiangcheshi = '';
		for (let i = 0; i < this.allData.length; i++) {
			let item = this.allData[i];
			if (dangqiangcheshi === '') {
				if (item.areaName === res.target.value) {
					dangqiangcheshi = item.id;
				}
			}
			if (dangqiangcheshi !== '') {
				if (item.area_parent_id === dangqiangcheshi) {
					//父ID等于当前ID
					ctr.push(item);
				}
			}
		}

		console.log('赛选的二级菜单', ctr);
		this.setState({
			cities: ctr,
			counties: [{ areaName: '请选择区域', area_parent_id: -1, id: -1 }],
		});
	}

	getTwoId(res: any) {
		console.log('赛选的二级菜单', res.target.value);
		(this.state as any).cityArea1 = res.target.value;
		(this.state as any).cityArea2 = '';
		let cities = this.allData;
		let ctr: any[] = [];
		let dangqiangcheshi = '';
		console.log('cities' + cities);
		for (let i = 0; i < cities.length; i++) {
			let item = cities[i];
			if (dangqiangcheshi === '') {
				if (item.areaName === res.target.value) {
					dangqiangcheshi = item.id;
				}
			}
			if (dangqiangcheshi !== '') {
				if (item.area_parent_id === dangqiangcheshi) {
					ctr.push(item);
				}
			}
		}
		console.log('赛选的二级菜单', ctr);
		this.setState({
			counties: ctr,
		});
	}

	getThreeId(res: any) {
		console.log(res.target.value);
		(this.state as any).cityArea2 = res.target.value;
		this.setState({
			cityArea2: (this.state as any).cityArea2,
		});
	}

	addTicket() {
		(this.state as any).date.push('');
		this.setState({
			date: (this.state as any).date,
		});
		console.log('date', (this.state as any).date);
	}
	delTicket(res: any, ref: any) {
		console.log('删除：：：', res);
		if ((this.state as any).date.length === 1) {
			msg.suspensionMsg('必须有一个票种');
			return;
		}
		(this.state as any).date.splice(res, 1);
		if ((this.state as any).selectType !== undefined && (this.state as any).selectType !== null) {
			(this.state as any).selectType.splice(res, 1);
		}
		if ((this.state as any).selectIsShenhe !== undefined && (this.state as any).selectIsShenhe !== null) {
			(this.state as any).selectIsShenhe.splice(res, 1);
		}
		if ((this.state as any).moneys !== undefined && (this.state as any).moneys !== null) {
			(this.state as any).moneys.splice(res, 1);
		}
		if ((this.state as any).peoples !== undefined && (this.state as any).peoples !== null) {
			(this.state as any).peoples.splice(res, 1);
		}
		if ((this.state as any).ticketDescribes !== undefined && (this.state as any).ticketDescribes !== null) {
			(this.state as any).ticketDescribes.splice(res, 1);
		}
		(this.state as any).isDeletes.push(ref.target.id);
		this.setState({
			date: (this.state as any).date,
			isDeletes: (this.state as any).isDeletes,
		});
		console.log('selectType', (this.state as any).isDeletes);
	}

	getHuodongInitiator(ev: any) {
		this.setState({
			huodongInitiator: ev.target.value,
		});
	}

	ticketDescribe(ev: any) {
		this.setState({
			ticketDescribe: ev.target.value,
		});
	}

	getHuodongJiezhiDate(date: any, dateToString: any) {
		console.log('截至' + dateToString);
		this.setState({
			huodongJiezhiDate: dateToString,
		});
	}
	getHuodongKaishiDate(date: any, dateToString: any) {
		console.log('开始' + dateToString);
		this.setState({
			huodongKaishiDate: dateToString,
		});
	}
	getHuodongJieshuDate(date: any, dateToString: any) {
		console.log('结束' + dateToString);
		this.setState({
			huodongJieshuDate: dateToString,
		});
	}

	huodongKaishiDate(ev: any) {
		this.setState({
			huodongKaishiDate: ev.target.value,
		});
	}

	huodongJieshuDate(ev: any) {
		this.setState({
			huodongJieshuDate: ev.target.value,
		});
	}

	getLbs(ev: any) {
		this.setState({
			lbs: ev.target.value,
		});
	}

	getHuodongBiaoti(ev: any) {
		this.setState({
			huodongBiaoti: ev.target.value,
		});
	}

	getHuodongInitiatorPhone(ev: any) {
		this.setState({
			huodongInitiatorPhone: ev.target.value,
		});
	}

	getZhuangTai(ev: any) {
		this.setState({
			zhuangTai: ev.target.value,
		});
	}

	zidingTime() {
		this.setState({
			isHidde: false,
			date_kind: 'user-defined',
		});
	}

	end(ev: any) {
		this.setState({
			isHidde: true,
			huodongJiezhiDate: (this.state as any).huodongJieshuDate,
			date_kind: 'end_time',
		});
		console.log(1, (this.state as any).huodongJieshuDate);
	}

	/**
	 * 选择票的类型
	 */

	selectType(width: any, height: number, uuid: any) {
		const moneyMenu = (
			<Menu onClick={this.getType.bind(this, uuid)}>
				<Menu.Item>免费</Menu.Item>
				<Menu.Item>收费</Menu.Item>
			</Menu>
		);
		return (
			<div>
				<Input
					type="text"
					className={'add-company-count-div2 ' + uuid}
					hidden={true}
					value={(this.state as any).selectType[uuid]}
					name="ticket"
					id={uuid}
				/>
				<div className="btn-group">
					<Dropdown overlay={moneyMenu}>
						<Button>
							{/*{(this.state as any).selectType[uuid] === undefined ? '请选择' : (this.state as any).selectType[uuid]} <Icon type="down"/>*/}
							{(this.state as any).selectType[uuid] === undefined
								? '请选择'
								: (this.state as any).selectType[uuid]}{' '}
							<DownloadOutlined />
						</Button>
					</Dropdown>
					{/*<ul className="dropdown-menu" role="menu">*/}
					{/*<li onClick={this.getType.bind(this, '免费' , uuid )}  style={{padding: 5, textAlign: 'center'}}>免费</li>*/}
					{/*<li onClick={this.getType.bind(this, '收费' , uuid )}  style={{padding: 5, textAlign: 'center'}}>收费</li>*/}
					{/*</ul>*/}
				</div>
			</div>
		);
	}

	getpostion(res: any) {
		this.setState({
			buttomPostion: res.target.id,
			typePosition: res.target.id,
		});
	}

	/**
	 * 选择好的类型
	 */

	getType(uuid: any, ev: any) {
		let st = this.state as any;

		if (ev.item.props.children === '免费') {
			if (st.selectType[uuid] !== '免费') {
				if (st.selectType.indexOf('免费') > -1) {
					msg.suspensionMsg('免费的只能添加一个');
					return;
				}
			}

			st.moneys[uuid] = 0;
		} else {
			st.moneys[uuid] = 1;
		}
		st.selectType[uuid] = ev.item.props.children;
		this.setState({
			selectType: st.selectType,
		});
		console.log(st.selectType);
	}

	inputText(value: any, index: any, ref: any) {
		let values = '';
		switch (value) {
			case 'moneys':
				(this.state as any).moneys[index] = ref.target.value;
				this.setState({
					moneys: (this.state as any).moneys,
				});
				break;
			case 'peoples':
				(this.state as any).peoples[index] = ref.target.value;
				this.setState({
					peoples: (this.state as any).peoples,
				});
				break;
			case 'ticketDescribes':
				(this.state as any).ticketDescribes[index] = ref.target.value;
				this.setState({
					ticketDescribes: (this.state as any).ticketDescribes,
				});
				break;
			case 'isChecks':
				(this.state as any).isChecks[index] = ref.target.value;
				this.setState({
					isChecks: (this.state as any).isChecks,
				});
				break;
			default:
				values = '';
				break;
		}
	}
	inputDateTime(width: any, height: number, uuid: any, type: string, value: string) {
		let values = '';
		let isDisabled = false;
		switch (value) {
			case 'moneys':
				values = (this.state as any).moneys[uuid];
				if ((this.state as any).selectType[uuid] === '免费') {
					isDisabled = true;
				}
				break;
			case 'peoples':
				values = (this.state as any).peoples[uuid];
				break;
			case 'ticketDescribes':
				values = (this.state as any).ticketDescribes[uuid];
				break;
			case 'isChecks':
				values = (this.state as any).isChecks[uuid];
				break;
			default:
				values = '';
				break;
		}
		return (
			<div>
				<input
					type={type}
					disabled={isDisabled}
					onChange={this.inputText.bind(this, value, uuid)}
					value={values}
					className={'add-company-count-div2 ' + uuid + '  form-control'}
					name="ticket"
					id={uuid}
				/>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div className="add-company-add-div">
					{/*<div className="add-company-count-div1 col-md-2">*/}
					<span className="addTitle">编辑活动</span>
					{/*</div>*/}
				</div>
				<div style={{ width: '80%' }}>
					<form id="RouteForm" accept-charset="UTF-8">
						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>标题：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<input
									name="huodongBiaoti"
									onInput={this.getHuodongBiaoti}
									className="form-control company-time-input huodongBiaoti"
								/>
							</div>
						</div>
						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>活动起止时间：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<div className="shijiangeshi">
									<DatePicker
										showTime={true}
										format="YYYY-MM-DD HH:mm:ss"
										onChange={this.getHuodongKaishiDate}
										value={moment((this.state as any).huodongKaishiDate, 'YYYY-MM-DD HH:mm:ss')}
									/>
									<div style={{ padding: 5 }}>到</div>
									<DatePicker
										showTime={true}
										format="YYYY-MM-DD HH:mm:ss"
										onChange={this.getHuodongJieshuDate}
										value={moment((this.state as any).huodongJieshuDate, 'YYYY-MM-DD HH:mm:ss')}
									/>
								</div>
							</div>
						</div>
						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>地址：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<div id="sanji">
									<select
										className="form-control2 form-control3"
										onChange={this.getOneId}
										name="province"
										id="province"
									>
										<option>{(this.state as any).cityArea}</option>
										{this.provinces.map((item: any, index: any) =>
											item.area_parent_id === 0 ? (
												<option id={item.id} value={item.areaName} key={index}>
													{item.areaName}
												</option>
											) : (
												''
											)
										)}
									</select>

									<select
										className="form-control2 form-control3"
										onChange={this.getTwoId}
										id="city"
										name="city"
									>
										<option>{(this.state as any).cityArea1}</option>

										{(this.state as any).cities.map((item: any, index: any) => (
											<option value={item.areaName} key={index}>
												{item.areaName}
											</option>
										))}
									</select>
									<select
										className="form-control2"
										onChange={this.getThreeId}
										id="county"
										name="county"
									>
										<option>{(this.state as any).cityArea2}</option>
										{(this.state as any).counties.map((item: any, index: any) => (
											<option value={item.areaName} key={index}>
												{item.areaName}
											</option>
										))}
									</select>
								</div>
								<div className="lbsDiv">
									<input
										placeholder="详细位置,选填"
										name="lbs"
										onInput={this.getLbs}
										className="form-control lbs"
									/>
								</div>
							</div>
						</div>

						{(this.state as any).tupianUrl === '' ? (
							''
						) : (
							<div className="row" style={{ marginTop: 20 }}>
								<div className="col-md-2 qiyeguanli-name-num-input add-company-count-div1" />
								<div className="col-md-10 qiyeguanli-name-num-input  ">
									<img
										style={{ marginLeft: 10 }}
										data-toggle="modal"
										className="homePage img-size tupianUrl"
										src={Api.cdnRootPath + (this.state as any).tupianUrl}
									/>
								</div>
							</div>
						)}

						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>上传文件：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<div id="uploaderExample" className="uploader">
									<div className="file-list" data-drag-placeholder="请拖拽文件到此处" />
									<button type="button" className="btn btn-primary uploader-btn-browse">
										<i className="icon icon-cloud-upload" /> 选择文件
									</button>
								</div>
							</div>
						</div>

						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>主办方：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<input
									name="huodongInitiator"
									onInput={this.getHuodongInitiator}
									className="form-control huodongInitiator"
								/>
							</div>
						</div>
						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>咨询电话：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<input
									name="huodongInitiatorPhone"
									onInput={this.getHuodongInitiatorPhone}
									className="form-control huodongInitiatorPhone"
								/>
							</div>
						</div>

						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>报名截止时间：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<label onClick={this.end} className="radio-inline huodongJiezhiDate">
									<input
										type="radio"
										name="radioOptionsExample"
										checked={(this.state as any).isHidde}
									/>
									活动结束前
								</label>
								<label onClick={this.zidingTime} className="radio-inline huodongJiezhiDate">
									<input
										type="radio"
										checked={!(this.state as any).isHidde}
										name="radioOptionsExample"
									/>
									自定义时间
								</label>
								{(this.state as any).isHidde ? (
									''
								) : (
									<label className="radio-inline">
										<DatePicker
											showTime={true}
											format="YYYY-MM-DD HH:mm"
											placeholder="请选择时间"
											onChange={this.getHuodongJiezhiDate}
											value={moment((this.state as any).huodongJiezhiDate, 'YYYY-MM-DD HH:mm:ss')}
										/>
										{/*<input type="text" name="huodongJiezhiDate" value={(this.state as any).huodongJiezhiDate} className="form-control form-datetime company-time-input2 huodongJiezhiDate" placeholder="请选择时间"/>*/}
									</label>
								)}
							</div>
						</div>
						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>票种类型：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<table className="table table-fixed">
									<tr>
										<th>类型</th>
										<th>金额</th>
										<th>名额</th>
										<th>描述</th>
										<th>审核(是? 否)</th>
										<th>操作</th>
									</tr>

									{(this.state as any).date.map((item: any, index: any) => (
										<tr>
											<td> {this.selectType('100%', 30, index)}</td>
											<td> {this.inputDateTime('100%', 30, index, 'number', 'moneys')}</td>
											<td> {this.inputDateTime('100%', 30, index, 'number', 'peoples')}</td>
											<td> {this.inputDateTime('100%', 30, index, 'text', 'ticketDescribes')}</td>
											<td> {this.dropDownShenhe('100%', 30, index)}</td>
											<td hidden={true}>
												{' '}
												<input
													name="ticket"
													value={item.uuid === undefined ? 'undefined' : item.uuid}
												/>{' '}
											</td>
											<td>
												<Button
													onClick={this.delTicket.bind(this, index)}
													id={item.uuid}
													disabled={(this.state as any).isApply > 0 ? true : false}
												>
													{(this.state as any).isApply > 0
														? '活动已有报名人数,不能删除'
														: '删除'}
												</Button>
											</td>
										</tr>
									))}
								</table>
								<div className="text-left">
									<button className="btn btn-primary" type="button" onClick={this.addTicket}>
										添加票种类型
									</button>
								</div>
							</div>
						</div>
						<div className="add-company-add-div">
							<div className="add-company-count-div1 col-md-2">
								<span style={{ color: 'red' }}>*</span>详情：
							</div>
							<div className="add-company-count-div3 col-md-10">
								<MyUeditor
									title={'活动'}
									descr={''}
									uuid={''}
									initialValue={''}
									required={''}
									width={''}
									height={document.body.scrollHeight / 3}
								/>
							</div>
						</div>
						<div className="add-company-add-div" style={{ marginBottom: 10 }}>
							<div className="add-company-count-div1 col-md-2" />
							<div className="add-company-count-div3 col-md-10" style={{ marginBottom: '20px' }}>
								<div className="col-md-6 text-center">
									<button
										id="submitData"
										type="button"
										onClick={this.addFabu.bind(this, '未开始')}
										className="btn btn-primary"
									>
										发布
									</button>
								</div>
								{(this.state as any).zhuangTai !== '已发布' ? (
									<div className="col-md-6 text-center">
										<button
											id="submitData"
											data-dismiss="modal"
											type="button"
											onClick={this.addFabu.bind(this, '未发布')}
											className="btn"
										>
											暂存
										</button>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export default UpdataHuodong;
