import * as React from 'react';
import DAL from '../../../data/DAL';
import { Button, Col, DatePicker, Row, Space } from 'antd';
import API from '../../../data/API';
import OrderList from './OrderList';
import { orderReport } from '../../../services/orderService';
var moment = require('moment');
let { RangePicker } = DatePicker;
let echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/pie'); //图表类型
require('echarts/lib/component/title'); //标题插件
class OrderReport extends React.Component {
	orderChart: any;
	refundOrderChart: any;
	isRefund: boolean;
	dal = DAL.dal;
	state: any;
	key: any;
	constructor(props: any) {
		super(props);
		this.state = {
			url: window.location.pathname,
			startDay: '',
			endDay: '',
			loadingAuditData: false,
			orderReport: [],
			refundOrderReport: [],
			feeTypeId: '0',
			isOrderList: true,
			key: '',
			totalFee: '',
			totalCount: '',
			refundtotalCount: '',
			refundtotalFee: '',
			isRefund: false,
		};
	}

	componentDidMount() {
		this.orderReport();
		this.refundOrderReport();
	}

	orderReport = async () => {
		const res = await orderReport({
			startDay: this.state.startDay,
			endDay: this.state.endDay,
			isRefund: false,
		});
		// let url = '/api/order/order_report';
		// API.postWithAuth(url , {
		//     startDay: this.state.startDay,
		//     endDay: this.state.endDay,
		//     isRefund: false
		// }).then(res => {
		console.log(res);
		if (res.err === 0) {
			this.setState({
				orderReport: res.data,
				startDay: res.startDay,
				endDay: res.endDay,
				totalCount: res.totalCount,
				totalFee: res.totalFee,
			});
		}
		this.orderChart.setOption(this.manageHouserNum(res.data, '', ['费用']));
		// });
	};

	refundOrderReport = async () => {
		const res = await orderReport({
			startDay: this.state.startDay,
			endDay: this.state.endDay,
			isRefund: true,
		});
		// let url = '/api/order/order_report';
		// API.postWithAuth(url , {
		//     startDay: this.state.startDay,
		//     endDay: this.state.endDay,
		//     isRefund: true
		// }).then(res => {
		console.log(res);
		if (res.err === 0) {
			this.setState({
				refundOrderReport: res.data,
				startDay: res.startDay,
				endDay: res.endDay,
				refundtotalCount: res.totalCount,
				refundtotalFee: res.totalFee,
			});
		}
		this.refundOrderChart.setOption(this.manageHouserNum(res.data, '', ['费用']));
		// });
	};

	dateTime = (date: any, dataToString: any) => {
		this.setState({
			startDay: dataToString[0],
			endDay: dataToString[1],
		});
	};

	search = () => {
		this.orderReport();
		this.refundOrderReport();
	};

	orderAudit = (chart: any) => {
		let that = this;
		if (chart !== null) {
			this.orderChart = echarts.init(chart);
			this.orderChart.on('click', function (params: any) {
				console.log('fsdfsdllllllllllllllllllllll', params, params.data.key);
				if (params.data.key !== 0 && params.data.key !== '0') {
					that.setState({
						feeTypeId: params.data.key,
						isOrderList: false,
						key: Math.random(),
						isRefund: false,
					});
					that.isRefund = false;
				}
			});
		}
	};

	refundOrderAudit = (chart: any) => {
		let that = this;
		if (chart !== null) {
			this.refundOrderChart = echarts.init(chart);
			this.refundOrderChart.on('click', function (params: any) {
				console.log('fsdfsdllllllllllllllllllllll', params, params.data.key);
				if (params.data.key !== 0 && params.data.key !== '0') {
					that.setState({
						feeTypeId: params.data.key,
						isOrderList: false,
						key: Math.random(),
						isRefund: true,
					});
					that.isRefund = true;
				}
			});
		}
	};

	manageHouserNum = (data: any, title: string, legendArray: any) => {
		return {
			tooltip: {},
			/*legend: {
                orient: 'vertical',
                x: 'right',
                data: legendArray,
                selected: legendArray
            },*/
			series: [
				{
					name: title,
					type: 'pie',
					radius: '55%',
					center: ['50%', '50%'],
					data: data,
					avoidLabelOverlap: true,
					label: {
						normal: {
							show: true,
							position: 'outside',
							formatter: '{b}\n{c}元',
						},
						emphasis: {
							show: true,
						},
					},
				},
			],
		};
	};

	render() {
		return (
			<div className="content">
				<Space>
					<>
						{this.state.startDay === null ||
						this.state.startDay === undefined ||
						this.state.startDay === '' ? (
							<RangePicker
								format="YYYY-MM-DD"
								placeholder={['请选择开始时间', '请选择结束时间']}
								onChange={this.dateTime}
								style={{ width: '100%', paddingLeft: 8 }}
							/>
						) : (
							<RangePicker
								format="YYYY-MM-DD"
								placeholder={['请选择开始时间', '请选择结束时间']}
								onChange={this.dateTime}
								value={[
									moment(this.state.startDay, 'YYYY-MM-DD'),
									moment(this.state.endDay, 'YYYY-MM-DD'),
								]}
								style={{ width: '100%', paddingLeft: 8 }}
							/>
						)}
					</>
					<Button type="primary" onClick={this.search}>
						搜索
					</Button>
				</Space>
				<br />
				<Row type="flex" justify="center">
					<Col>
						<Row style={{ whiteSpace: 'pre-wrap', wordBreak: 'normal', wordWrap: 'break-word' }}>
							<canvas
								style={{ whiteSpace: 'pre-wrap', wordBreak: 'normal', wordWrap: 'break-word' }}
								ref={this.orderAudit}
								width="500"
								height="400"
							>
								x
							</canvas>
						</Row>
						<Row type="flex" justify="center">
							支付
						</Row>
						<Row type="flex" justify="center">
							<b>
								总金额：{this.state.totalFee}，总数量：{this.state.totalCount}
							</b>
						</Row>
					</Col>
					<Col>
						<Row>
							<canvas ref={this.refundOrderAudit} width="500" height="400">
								x
							</canvas>
						</Row>
						<Row type="flex" justify="center">
							退款
						</Row>
						<Row type="flex" justify="center">
							<b>
								总金额：{this.state.refundtotalFee}，总数量：{this.state.refundtotalCount}
							</b>
						</Row>
					</Col>
				</Row>
				<div hidden={this.state.isOrderList}>
					<OrderList
						key={this.state.key}
						startDay={this.state.startDay}
						endDay={this.state.endDay}
						fee={this.state.feeTypeId}
						isOrderReport={true}
						isRefund={this.state.isRefund}
					/>
				</div>
			</div>
		);
	}
}

export default OrderReport;
