/**
 * filename: Card
 * overview: 根据放入 Box 生成的 Card 组件
 */

import React, { useRef, useMemo, useState } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { Card, Image } from 'antd';
import { DeleteOutlined } from '@ant-design/icons/lib';

const CardItem: React.FC = (props: any) => {
	const ref = useRef<HTMLDivElement>(null);
	const { index, moveCard, type, item } = props;
	const [{ isDragging }, drag, dragPreview] = useDrag({
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging(),
		}),
		// item 中包含 index 属性，则在 drop 组件 hover 和 drop 是可以根据第一个参数获取到 index 值
		item: { type: type, index },
	});

	const [, drop] = useDrop({
		accept: type,
		hover(item: { type: string; index: number; index1: number }, monitor: DropTargetMonitor) {
			if (!ref.current) {
				return;
			}
			console.log('item:::1', monitor.getItem());
			console.log('item:::1', item.index);
			const dragIndex = item.index;
			const hoverIndex = index;

			// 拖拽元素下标与鼠标悬浮元素下标一致时，不进行操作
			if (dragIndex === hoverIndex) {
				return;
			}
			// 确定屏幕上矩形范围
			const hoverBoundingRect = ref.current!.getBoundingClientRect();
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2; //获取X轴中点
			const clientOffset: any = monitor.getClientOffset(); //获取拖拽目标偏移量
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;
			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
				return null;
			}
			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
				return null;
			}
			moveCard(dragIndex, hoverIndex);

			/**
			 * 如果拖拽的组件为 Box，则 dragIndex 为 undefined，此时不对 item 的 index 进行修改
			 * 如果拖拽的组件为 Card，则将 hoverIndex 赋值给 item 的 index 属性
			 */
			if (item.index !== undefined) {
				item.index = hoverIndex;
			}
		},
	});
	/**
	 * 使用 drag 和 drop 对 ref 进行包裹，则组件既可以进行拖拽也可以接收拖拽组件
	 * 使用 dragPreview 包裹组件，可以实现拖动时预览该组件的效果
	 */
	dragPreview(drop(ref));
	drag(ref);
	return (
		<>
			{type === 'CARD' ? (
				<div ref={ref}>
					{drag(
						<div>
							<Card {...props}></Card>
						</div>
					)}
				</div>
			) : type === 'AppModel' ? (
				<div ref={ref}>
					{drag(
						<div className={'d-flex justify-content-center align-items-center flex-column '}>
							<div className={'imgbox'}>
								<img style={{ maxWidth: '100%', maxHeight: '100%' }} src={item.icon}></img>
							</div>
							<text>{item.modulName}</text>
						</div>
					)}
				</div>
			) : (
				''
			)}
		</>
	);
};

export default CardItem;
