let UE:any;
let ue:any;
class Ueditor {
	static uditor = new Ueditor()
	init = ()=> {
		ue = UE.getEditor('containerueditor');
	}
}
export function extend(des:any, src:any, override:any) {
	if (src instanceof Array) {
		for (let i = 0, len = src.length; i < len; i++) extend(des, src[i], override);
	}
	for (let i in src) {
		if (override || !(i in des)) {
			des[i] = src[i];
		}
	}
	return des;
}
export default Ueditor

