import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, InputNumber, Select, Space, Switch, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { FormContext } from '../../../components/FormContext';
import getBillTypes from '../models/BillTypes';

interface Params {
	feeType: string;
}

const FeeSubjectForm: React.FC<Params> = (props: Params) => {
	const form: FormInstance = useContext(FormContext);

	const deleteItem = (feeTypeUuid: any) => {
		const roomChargeList = form.getFieldValue('feeStandardData') || [];
		const item = roomChargeList.findIndex((item) => item.uuid == feeTypeUuid);
		roomChargeList.splice(item, 1);
		form.setFieldsValue({ feeStandardData: roomChargeList });
		// const values = [...checkedValues];
		// const findIndex = values.findIndex((item) => item == feeTypeUuid);
		// console.log('findIndex', findIndex);
		// values.splice(findIndex, 1);
		// setcheckedValues([...values]);
	};
	const addItem = () => {
		const roomChargeList = form.getFieldValue('feeStandardData') || [];
		roomChargeList.push({
			name: '',
			billBasis: '',
			priceType: '',
			price: 0,
			add: true,
			uuid: new Date().getTime().toString(),
		});
		form.setFieldsValue({ feeStandardData: roomChargeList });
	};
	return (
		<>
			<div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
				<Space>
					<Button type="primary" onClick={addItem}>
						添加
					</Button>
				</Space>
			</div>
			<div className="ant-pro-table ">
				<div className="ant-table-wrapper">
					<table
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							flex: 1,
						}}
					>
						<thead className={'ant-table-thead'} style={{ display: 'flex', flex: 1 }}>
							<tr style={{ display: 'flex', flex: 1 }}>
								<th style={{ flex: 1 }}>收费标准名称</th>
								<th hidden={'周期类' !== props.feeType} style={{ flex: 1 }}>
									计费依据
									<Tooltip
										style={{ marginLeft: 10 }}
										title={
											<div >
												<span>费用的计算规则</span> <br />
												<span>计费面积*单价： 房间的建筑面积*单价</span> <br />
												<span>
													固定金额： 每月固定费用。 如停车费、代收有线电视费、代收网络费
												</span>{' '}
												<br />
											</div>
										}
									>
										<InfoCircleOutlined />
									</Tooltip>
								</th>
								<th hidden={'周期类' !== props.feeType} style={{ flex: 1 }}>
									单价类型
									<Tooltip
										style={{ marginLeft: 10 }}
										title={
											<div >
											<span>月单价： 每月固定价格。大部分地区为此价格。80平米的房间计算1个月的费用=80*月单价。</span> <br />
											<span>平均日单价：每日固定价格，按365天/12平均计算。80平米的房间计算1个月的费用=80*日单价*365/12。</span> <br />
											<span>
											自然日单价：每日固定价格，按当月天数计算。80平米的房间计算1个月的费用=80*日单价*当月天数。 
											</span>{' '}
											<br />
										</div>
										}
									>
										<InfoCircleOutlined />
									</Tooltip>
								</th>
								<th style={{ flex: 1 }}>单价</th>
								<th hidden={'周期类' !== props.feeType} style={{ flex: 1 }}>
									不足月按天计算
								</th>
								<th style={{ flex: 1 }}> 是否启用</th>
								<th style={{ flex: 1 }}>操作</th>
							</tr>
						</thead>
						<tbody
							className={'ant-table-tbody'}
							style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
						>
							<Form.Item style={{ width: '100%' }} shouldUpdate noStyle>
								{() => (
									<Form.List name={'feeStandardData'}>
										{(fields, { add, remove }) => (
											<>
												{fields.map((field: any, index: number) => (
													<>
														<tr style={{ flex: 1, display: 'flex', width: '100%' }}>
															<td hidden={true} style={{ flex: 1, display: 'flex' }}>
																<Form.Item
																	{...field}
																	name={[field.name, 'uuid']}
																	fieldKey={[field.fieldKey, 'uuid']}
																>
																	<Input style={{ width: '100%' }}></Input>
																</Form.Item>
															</td>
															<td style={{ flex: 1, display: 'flex', width: '100%' }}>
																<Form.Item
																	{...field}
																	name={[field.name, 'name']}
																	fieldKey={[field.fieldKey, 'name']}
																	rules={[
																		{
																			required: true,
																			message: '请填写名称',
																		},
																	]}
																>
																	<Input style={{ width: 150 }}></Input>
																</Form.Item>
															</td>
															<td
																hidden={'周期类' !== props.feeType}
																style={{ flex: 1, display: 'flex', width: '100%' }}
															>
																<Form.Item
																	{...field}
																	// label={'收费类型'}

																	name={[field.name, 'billBasis']}
																	fieldKey={[field.fieldKey, 'billBasis']}
																	rules={[
																		{
																			required: '周期类' == props.feeType,
																			message: '请选择计费依据',
																		},
																	]}
																>
																	<Select style={{ width: 150 }}>
																		{getBillTypes().billBasis.map((item) => {
																			return (
																				<>
																					<Select.Option value={item.value}>
																						{item.name}
																					</Select.Option>
																				</>
																			);
																		})}
																	</Select>
																</Form.Item>
															</td>
															<td
																hidden={'周期类' !== props.feeType}
																style={{ flex: 1, display: 'flex' }}
															>
																<Form.Item
																	{...field}
																	// label={'收费类型'}
																	name={[field.name, 'priceType']}
																	fieldKey={[field.fieldKey, 'priceType']}
																	rules={[
																		{
																			required: '周期类' == props.feeType,
																			message: '请选择单价类型',
																		},
																	]}
																>
																	<Select style={{ width: 150 }}>
																		{getBillTypes().priceTypes.map((item) => {
																			return (
																				<>
																					<Select.Option value={item.value}>
																						{item.name}
																					</Select.Option>
																				</>
																			);
																		})}
																	</Select>
																</Form.Item>
															</td>
															<td style={{ flex: 1, display: 'flex' }}>
																{
																	<Form.Item
																		style={{ width: '100%' }}
																		{...field}
																		// label={'收费标准'}
																		name={[field.name, 'price']}
																		fieldKey={[field.fieldKey, 'price']}
																		rules={[
																			{
																				required: true,
																				message: '请输入价格',
																			},
																		]}
																	>
																		<InputNumber
																			style={{ width: 150 }}
																			step={'0.01'}
																			min={0}
																		></InputNumber>
																	</Form.Item>
																}
															</td>
															<td
																hidden={'周期类' !== props.feeType}
																style={{ flex: 1, display: 'flex' }}
															>
																<Form.Item
																	{...field}
																	// label={'收费类型'}
																	name={[field.name, 'byTheDay']}
																	fieldKey={[field.fieldKey, 'byTheDay']}
																	valuePropName="checked"
																>
																	<Switch></Switch>
																</Form.Item>
															</td>
															<td style={{ flex: 1, display: 'flex' }}>
																<Form.Item
																	{...field}
																	// label={'收费类型'}
																	name={[field.name, 'state']}
																	fieldKey={[field.fieldKey, 'state']}
																	valuePropName="checked"
																>
																	<Switch></Switch>
																</Form.Item>
															</td>
															<td hidden={true} style={{ flex: 1, display: 'flex' }}>
																<Form.Item
																	{...field}
																	// label={'收费类型'}
																	name={[field.name, 'add']}
																	fieldKey={[field.fieldKey, 'add']}
																	valuePropName="checked"
																>
																	<Switch></Switch>
																</Form.Item>
															</td>
															<td
																style={{
																	flex: 1,
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<a
																	onClick={() =>
																		deleteItem(
																			form.getFieldValue([
																				'feeStandardData',
																				field.name,
																				'uuid',
																			])
																		)
																	}
																>
																	删除
																</a>
															</td>
														</tr>
													</>
												))}
											</>
										)}
									</Form.List>
								)}
							</Form.Item>
						</tbody>
						{/* ))} */}
					</table>
				</div>
			</div>
		</>
	);
};
export default FeeSubjectForm;
