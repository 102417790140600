import React, { useEffect, useState } from 'react';
import { Avatar, Card, Col, message, Row, Statistic, Image } from 'antd';
import { getHealthData, getHealthRecordData1, getHealthDataByDeviceId } from '../../services/StatisticsService';
import moment from 'moment';
import PieChart from '../../components/PieChart';
import { ColumnChartContext } from '../controlPlatform/CurrentPhoneBrandChart';
import ColumnChart from '../../components/ColumnChart';
import { DataContext } from '../controlPlatform/CurrentPlatform';
import LineChart from '../../components/LineChart';
import GSTable from '../../components/GSTable';
import mqtt from 'mqtt';
import { getAllFaceDevice } from '../../services/FaceService';
const EpidemicPreventionReport: React.FC = (props: any) => {

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>({});
	const [recordData, setRecordData] = useState([]);
	const [deviceEnum, setDeviceEnum] = useState<any>({});
	const [client, setClient] = useState<any>(null);
	const [tableData, setTableData] = useState<any>([]);
	const [imageBase64, setImageBase64] = useState('');
	const [totalRenCi, setTotalRenCi] = useState<any>([{ type: '核验人次', value: 0 }]);
	const [deviceData, setDeviceData] = useState([]);
	const [valueEnum, setValueEnum] = useState({
		'-6': <span style={{ color: '#ff0000' }}>健康宝数据解析异常(-6)</span>,
		'-5': <span style={{ color: '#ff0000' }}>健康码获取异常(-5)</span>,
		'-4': <span style={{ color: '#ff0000' }}>无身份证信息(-4)</span>,
		'-3': <span style={{ color: '#ff0000' }}>获取超时(-3)</span>,
		'-2': <span style={{ color: '#ff0000' }}>网络异常(-2)</span>,
		'-1': <span>未开启健康码验证(-1)</span>,
	});

	const infoColumns = [
		{
			title: '姓名',
			dataIndex: 'name',
		},
		{
			title: '设备名称',
			dataIndex: 'deviceId',
			valueEnum: deviceEnum,
		},
		{
			title: '身份证号',
			dataIndex: 'idCard',
		},
		{
			title: '健康码',
			dataIndex: 'healthCheck',
		},
		{
			title: '核酸天数',
			dataIndex: 'acidNum',
			render: (text: string, record: any) => {
				if (text === '-') {
					return <span>-</span>;
				}
				return <span>{text}天</span>;
			},
		},
		{
			title: '体温',
			dataIndex: 'temperature',
		},

		// {
		// 	title: '人证核验',
		// 	valueEnum: idCardEnum,
		// 	dataIndex: 'idCheck'
		// },
		{
			title: '记录时间',
			valueType: 'dateTime',
			dataIndex: 'createdTime',
		},
	];
	useEffect(() => {
		getData(true);
		getRecordData(true);
	}, []);
	useEffect(() => {
		if (client) {
			client.on('connect', () => {
				console.log('连接上了');
				getAllDevice(true);
			});
			client.on('error', (err: any) => {
				console.error('Connection error: ', err);
				client.end();
			});
			client.on('reconnect', () => {
				console.log('重新连接');
				getAllDevice(true);
			});
			client.on('message', (topic: any, message: any) => {
				try {
					let item = JSON.parse(message.toString());
					let userInfo = JSON.parse(item.user_info);
					console.log('item', item, userInfo);
					if (!item.shiBieStatus) {
						return
					}
					let dataList = tableData;
					let info = {
						name: informationDesensitization(userInfo.userName, 'name'),
						deviceId: item.deviceId,
						idCard: informationDesensitization(userInfo.idCard, 'card_id'),
						acidNum: item.acidNum,
						temperature: item.tiwen,
						healthCheck: item.jianKangMaMsg,
						createdTime: new Date().getTime(),
					};
					dataList.push(info);
					setImageBase64('data:image/png;base64,' + item.detect_face_img);
					setTableData([...dataList.reverse().slice(0, 10)]);
					getData(false);
					getRecordData(false);
					getAllDevice(false);

				} catch (e) {}
			});
		} else {
			connect();
		}
	}, [client]);
	const informationDesensitization = (str: string, type: string) => {
		switch (type) {
			case 'card_id':
				if (str != null && str.length === 18) {
					return str?.replace(/(\d{2})\d{14}(\w{2})/, '$1************$2');
				} else if (str != null && str.length == 15) {
					return str?.replace(/(\d{2})\d{11}(\w{2})/, '$1*********$2');
				} else {
					return str;
				}
			case 'name':
				if (str.length == 2) {
					return str.substring(0, 1) + '*'; //截取name 字符串截取第一个字符，
				} else if (str.length == 3) {
					return str.substring(0, 1) + '**'; //截取第一个和第三个字符
				} else {
					return str.substring(0, 2) + '*' + '*' + str.substring(4, str.length); //截取第一个和大于第4个字符
				}
			default:
				return str;
		}
	};
	const connect = () => {
		const options: any = {
			keepalive: 30,
			protocolId: 'MQTT',
			protocolVersion: 4,
			clean: true,
			reconnectPeriod: 1000,
			connectTimeout: 30 * 1000,
			rejectUnauthorized: false,
			clientId: new Date().getTime(),
			username: 'admin',
			password: 'xA5iUF2N',
		};
		setClient(mqtt.connect('wss://api.guosim.com/ws', options));
	};
	const mqttSub = (subscription: any) => {
		if (client) {
			const { topic, qos } = subscription;
			client.subscribe(topic, { qos }, (error: any) => {
				if (error) {
					console.log('Subscribe to topics error', error);
					return;
				}
			});
		}
	};
	const getAllDevice = async (isSub?:boolean) => {
		let data: any = {
			proj_uuid: localStorage.getItem('wyUuid'),
		};
		let rsp = await getAllFaceDevice(data);
		if (rsp.err === 0) {
			let data = rsp.data;
			console.log('data::::', data);
			let valueEnum: any = {};

			data.map((item: any) => {
				valueEnum[item.device_id] = item.description;
				if (isSub) {
					mqttSub({ topic: `/${item.device_id}/user/record`, qos: 0 });
				}
			});
			setDeviceEnum(valueEnum);
			console.log('valueEnum', valueEnum);
			getHeadDataDevice(valueEnum);
		} else {
			message.error(rsp.msg);
		}
	};

	const getData = async (isLoading?: boolean) => {
		let params = {
			startTime: moment().format('YYYY-MM-DD 00:00:00'),
			endTime: moment().format('YYYY-MM-DD 23:59:59'),
		};
		if (isLoading) {
			setLoading(true);
		}
		let rsp = await getHealthData(params);
		if (rsp.err === 0) {
			setLoading(false);
			setData(rsp.data);
			let totalRenCi = [{ type: '核验人次', value: rsp.data.totalRenCi || 0 }];
			setTotalRenCi(totalRenCi);
		}
	};

	const getRecordData = async (isLoading?: boolean) => {
		let params = {
			startTime: moment().subtract(14, 'd').format('YYYY-MM-DD 00:00:00'),
			endTime: moment().format('YYYY-MM-DD 23:59:59'),
		};
		if (isLoading) {
			setLoading(true);
		}
		let rsp = await getHealthRecordData1(params);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
			return;
		}
		let data = rsp.data;
		createChartData(data);
	};

	const getHeadDataDevice = async (deviceList: any) => {
		console.log('deviceList:::', deviceEnum)
		let params = {
			startTime: moment().format('YYYY-MM-DD 00:00:00'),
			endTime: moment().format('YYYY-MM-DD 23:59:59'),
		};
		let rsp = await getHealthDataByDeviceId(params);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
			return;
		}
		let data = rsp.data;
		let list: any = [];
		data.map((item: any) => {
			list.push({
				x: deviceList[item.deviceId],
				y: parseInt(item.total),
				name: '核验次数',
			});
		});
		setDeviceData(list);
	};

	const createChartData = (data: any) => {
		let sourceData = data[0] || {};
		console.log('sourceData', sourceData);
		let chartData: any = [];
		(sourceData.datas || []).map((item: any) => {
			let keys = Object.keys(item.dayData);
			keys.map((key: string) => {
				chartData.push({
					name: item.unit,
					x: key,
					y: item.dayData[key],
				});
			});
		});
		console.log('chartData:::::', chartData);
		setRecordData(chartData);
	};
	const pieConfig = {
		height: 230,
		legend: false,
		label: false,
		theme: 'dark',
		statistic: {
			title: {
				style: {
					fontSize: 16,
					color: '#ffffff',
				},
				content: '核验人次',
			},
			content: {
				offsetY: 10,
				style: {
					fontSize: 18,
					color: '#fff',
				},
			},
		},
	};

	return (
		<>
			<style>{`
                body {
                    background: #141414 !important;
                }
			
            `}</style>
			<div className="dark-bg">
				<Row gutter={16}>
					<Col span={4}>
						<ColumnChartContext.Provider value={totalRenCi}>
							<PieChart unit={'人次'} {...pieConfig} />
						</ColumnChartContext.Provider>
					</Col>
					<Col span={20}>
						<Row gutter={16} align="middle">
							<Col span={4}>
								<Card
									style={{ backgroundColor: '#E82D2D' }}
									loading={loading}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>隔离或密接人员</span>}
										valueStyle={{ color: '#fff' }}
										value={data.hongFail || 0}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#FAAD14' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>社区隔离中</span>}
										valueStyle={{ color: '#fff' }}
										value={data.sheQuGeLi || 0}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#FAAD14' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>入境人员未完成隔离</span>}
										valueStyle={{ color: '#fff' }}
										value={data.otherFail || 0}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#FAAD14' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>经过中高风险地区</span>}
										valueStyle={{ color: '#fff' }}
										value={data.gaoWei || 0}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#FAAD14' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>健康码弹框</span>}
										valueStyle={{ color: '#fff' }}
										value={data.keNengGaoWei || 0}
									/>
								</Card>
							</Col>

							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#FAAD14' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>体温异常</span>}
										valueStyle={{ color: '#fff' }}
										value={data.tiWenFail || 0}
									/>
								</Card>
							</Col>
						</Row>
						<Row gutter={16} align="middle">
							<Col span={4}>
								<Card
									style={{ backgroundColor: '#8f60f6' }}
									loading={loading}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>健康宝核验人数</span>}
										valueStyle={{ color: '#fffff' }}
										value={data.total || 0}
									/>
								</Card>
							</Col>

							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#2d7efa' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>健康宝行程未申报</span>}
										valueStyle={{ color: '#fff' }}
										value={data.xinchengweishengbao || 0}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#2d7efa' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>无身份证信息</span>}
										valueStyle={{ color: '#fff' }}
										value={data.wuShenfengZheng || 0}
									/>
								</Card>
							</Col>
							{/*</Row>*/}
							{/*<Row gutter={16}  align="middle">*/}
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#52C41A' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>状态正常</span>}
										valueStyle={{ color: '#fff' }}
										value={data.success || 0}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#52C41A' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>入境完成隔离或行程风险低</span>}
										valueStyle={{ color: '#fff' }}
										value={data.ruJingGeLi || 0}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card
									loading={loading}
									style={{ backgroundColor: '#52C41A' }}
									type="inner"
									bordered={false}
								>
									<Statistic
										title={<span style={{ color: '#fff' }}>环京人员</span>}
										valueStyle={{ color: '#fff' }}
										value={data.huanJing || 0}
									/>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={4}>
						<Card title={'区域分布'}>
							<ColumnChartContext.Provider value={deviceData}>
								<ColumnChart theme="dark" height={300} />
							</ColumnChartContext.Provider>
						</Card>
					</Col>
					<Col span={20}>
						<Card title={'防疫记录'} loading={loading}>
							<DataContext.Provider value={recordData}>
								<LineChart theme="dark" height={300} />
							</DataContext.Provider>
						</Card>
					</Col>
				</Row>
				<Row gutter={16} style={{ height: 300 }}>
					<Col span={4}>
						<Image src={imageBase64}></Image>
					</Col>
					<Col span={20}>
						<GSTable
							options={false}
							pagination={false}
							rowSelection={false}
							columns={infoColumns}
							dataSource={tableData}
						/>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default EpidemicPreventionReport;
