import Api from '../data/API';

//import {} from "../../services/WenzhangService";
export async function findDetails(params: any) {
	return Api.postWithAuth('/api/tuwen/find_details', params);
}

export async function addWenzhang(params: any) {
	return Api.postWithAuth('/api/tuwen/add_wenzhang', params);
}

export async function updateWenzhang(params: any) {
	return Api.postWithAuth('/api/tuwen/update_wenzhang', params);
}

export async function delWenzhang(params: any) {
	return Api.postWithAuth('/api/tuwen/del_wenzhang', params);
}
export async function getWenzhangType(params: any) {
	return Api.postWithAuth('/api/tuwen/getWenzhangType', params);
}

export async function changeState(params: any) {
	return Api.postWithAuth('/api/tuwen/change_state', params);
}
export async function deleteWenzhangTag(params: any) {
	return Api.postWithAuth('/api/tuwen/deleteWenzhangTag', params);
}

export async function findWenzhangListPc(params: any) {
	return Api.postWithAuth('/api/tuwen/find_wenzhang_list_pc', params);
}

export async function updateWenzhangType(params: any) {
	return Api.postWithAuth('/api/tuwen/update_wenzhang_type', params);
}

export async function changeLeixingState(params: any) {
	return Api.postWithAuth('/api/tuwen/change_leixing_state', params);
}

export async function deleteWenzhangType(params: any) {
	return Api.postWithAuth('/api/tuwen/deleteWenzhangType', params);
}

export async function deleteWenzhang(params: any) {
	return Api.postWithAuth('/api/tuwen/deleteWenzhang', params);
}

export async function addWenzhangType(params: any) {
	return Api.postWithAuth('/api/tuwen/add_wenzhang_type', params);
}

export async function findWenzhangTypeList(params: any) {
	return Api.postWithAuth('/api/tuwen/find_wenzhang_type_list', params);
}

export async function findWenzhangTagList(params: any) {
	return Api.postWithAuth('/api/tuwen/getWenZhangTag', params);
}

export async function addWenzhangTag(params: any) {
	return Api.postWithAuth('/api/tuwen/addWenzhangTag', params);
}

export async function updateWenzhangTag(params: any) {
	return Api.postWithAuth('/api/tuwen/updateWenzhangTag', params);
}

export async function findPingluList(params: any) {
	return Api.postWithAuth('/api/pinglun/find_pinglun_list', params);
}

export async function findTieziDetails(params: any) {
	return Api.postWithAuth('/api/tieba/find_tiezi_details', params);
}
export async function findTieziList(params: any) {
	return Api.postWithAuth('/api/tieba/find_tiezi_list', params);
}

export async function findWenzhangList(params: any) {
	return Api.postWithAuth('/api/tuwen/find_wenzhang_list', params);
}

export async function findDongTaiDetailsAfter(params: any) {
	return Api.postWithAuth('/api/qunzu/find_dong_tai_details_after', params);
}

export async function findTiebaTypeDetails(params: any) {
	return Api.postWithAuth('/api/tieba/find_tieba_type_details', params);
}

export async function delPinglu(params: any) {
	return Api.postWithAuth('/api/pinglun/del_pinglun', params);
}

