import * as React from 'react';

/**
 * 多行文本
 */

interface MyUeditorProps {
	title: string;
	descr: string;
	uuid: string;
	initialValue: string;
	required: string;
	width: any;
	height: any;
}

class MyUeditor extends React.Component<MyUeditorProps> {
	constructor(props: any) {
		super(props);
		this.state = { selectValue: '', radio: {} };
		this.select = this.select.bind(this);
	}

	select(res: any) {
		(this.state as any).radio[res.target.value] = res.target.checked;
		this.setState({
			selectValue: res.target.value,
		});
	}
	componentDidMount() {
		let UE = (window as any).UE;
		UE.getEditor('myDivContainer', {
			//autoHeightEnabled: false,
			//autoFloatEnabled: true,
			//initialFrameHeight: 'auto',
			scaleEnabled: false,
		});
		// UE.getEditor('editor').setHeight(300)
		/* UE.Editor.prototype._bkGetActionUrl = UE.Editor.prototype.getActionUrl;
        UE.Editor.prototype.getActionUrl = function(action: string) {

            if (action === 'uploadimage' || action === 'uploadscrawl' || action === 'uploadimage') {
                return 'http://localhost:9000/ue?action=' + action;
            } else if (action === 'uploadvideo') {
                return 'http://a.b.com/video.php';
            } else {
                //return this._bkGetActionUrl.call(this, action);
                return 'http://localhost:9000/ue?action=config';
            }
        };*/
	}

	render() {
		return (
			<div>
				<div id="myDivContainer" style={{ zIndex: 100 }} />
			</div>
		);
	}
}

export default MyUeditor;
