import Api from '../data/API';

export async function tiebaAddTieba(params: any) {
	return Api.postWithAuth('/api/tieba/add_tieba', params);
}

export async function tiebaFindTieziList(params: any) {
	return Api.postWithAuth('/api/tieba/find_tiezi_list', params);
}

export async function qunzuFindListDongtai(params: any) {
	return Api.postWithAuth('/api/qunzu/find_list_dongtai', params);
}

export async function tiebaDelTiezi(params: any) {
	return Api.postWithAuth('/api/tieba/del_tiezi', params);
}

export async function qunzuDelTiezi(params: any) {
	return Api.postWithAuth('/api/qunzu/del_tiezi', params);
}

export async function changeTiebaTypeState(params: any) {
	return Api.postWithAuth('/api/tieba/change_tieba_type_state', params);
}

export async function tiebaAddTiebaType(params: any) {
	return Api.postWithAuth('/api/tieba/add_tieba_type', params);
}

export async function tiebaFindTiebaTypeDetails(params: any) {
	return Api.postWithAuth('/api/tieba/find_tieba_type_detail', params);
}

export async function tiebaUpdateTiebaType(params: any) {
	return Api.postWithAuth('/api/tieba/update_tieba_type', params);
}

export async function tiebaFindTiebaTypeList(params: any) {
	return Api.postWithAuth('/api/tieba/find_tieba_type_list', params);
}
