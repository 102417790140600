import React, { useRef, useState, useEffect } from "react";
import GSTable from "../../../components/GSTable";
import { getEmpDataByGroup, getEmpData } from "../../../services/StatisticsService";
import moment from "moment";
import { Row, Col, Card, Statistic, Space, TreeSelect, Button, Modal } from "antd";
import { findDepartmentDirectory } from "../../../services/WuyeService";
import CompanyList from "../CompanyList";
import CustomRangePicker from "../../../components/CustomRangePicker";
import { getFangKeList } from "../../../services/FangKe";
import GsTreeSelect from "../../../components/GsTreeSelect";

const EmpData: React.FC = () => {
    const actionRef = useRef<any>();
    const [isShow, setIsShow] = useState(false);
    const [empSumData, setEmpSumData] = useState({ total: 0, gudingnum: 0, linshinum: 0, fangkeynum: 0 });
    const [department, setDepartment] = useState([]);
    const [departmentUuid, setDepartmentUuid] = useState([]);
    const [visible, setVisible] = useState(false);
    const [checkDepartmentUuid, setCheckDepartmentUuid] = useState("");
    const [checkEmpType, setCheckEmpType] = useState("");
    const [startTime, setStartTime] = useState(moment().subtract(0, "d").hours(0).minute(0).second(0));
    const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
    const [fangKeVisible, setFangKeVisile] = useState(false);
    const columns = [{
        title: "部门",
        dataIndex: "departmentName",
        key: "departmentName"
    }, {
        title: "人员总数",
        dataIndex: "total",
        key: "total",
        render: (text: string, record: any) => {
            return <a onClick={() => {
                setVisible(true);
                setCheckDepartmentUuid(record.uuid);
                setCheckEmpType("");
            }
            }>{text}</a>;
        }
    }, {
        title: "固定人员总数",
        dataIndex: "gudingNum",
        key: "gudingNum",
        render: (text: string, record: any) => {
            return <a onClick={() => {
                setVisible(true);
                setCheckDepartmentUuid(record.uuid);
                setCheckEmpType("固定人员");
            }
            }>{text}</a>;
        }
    }, {
        title: "临时人员总数",
        dataIndex: "linshiNum",
        key: "linshiNum",
        render: (text: string, record: any) => {
            return <><a onClick={() => {
                setVisible(true);
                setCheckDepartmentUuid(record.uuid);
                setCheckEmpType("临时人员");
            }
            }>{text} {record['linShiNumCount'] != undefined ? "(" + record['linShiNumCount']+ ")" : null}</a></>;
        }
    }, {
        title: "访客数",
        dataIndex: "fangkeyNum",
        key: "fangkeyNum",
        render: (text: string, record: any) => {
            return <><a onClick={() => {
                setVisible(false);
                setFangKeVisile(true);
                setCheckDepartmentUuid(record.uuid);
            }
            }>{text}{record['fangkeyNumCount'] != undefined ? "(" + record['fangkeyNumCount']+ ")" : null}</a></>;
        }
    }];

    const fangKeColumns = [
        {
            title: "访客姓名",
            dataIndex: "applyForName",
            key: "applyForName"
        },
        {
            title: "访客手机号",
            dataIndex: "applyForPhone",
            key: "applyForPhone"
        },
        {
            title: "访客车牌号",
            dataIndex: "carNumber",
            key: "carNumber"
        },
        {
            title: "访问开始时间",
            dataIndex: "startTime",
            valueType: "dateTime",
            key: "startTime"
        },
        {
            title: "访问结束时间",
            dataIndex: "endTime",
            valueType: "dateTime",
            key: "endTime"
        }, {
            title: "来访事由",
            dataIndex: "info",
            key: "info"
        }, {
            title: "接访人部门",
            dataIndex: "department",
            key: "department"
        }, {
            title: "接访人姓名",
            dataIndex: "ownerName",
            key: "ownerName"
        }
    ];

    useEffect(() => {
        getDepartment().then();
    }, []);

    const getDepartment = async () => {
        const res = await findDepartmentDirectory({
            companyUuid: localStorage.getItem("companyUuid"),
            showAll: true
        });
        if (res.err == 0) {
            console.log("res:::", res.data);
            let dataList: any = [];
            res.data.map((item: any) => {
                let data: any = {};
                data["id"] = item.uuid;
                data["pId"] = item.parentUuid;
                data["value"] = item.uuid;
                data["title"] = item.departmentName;
                dataList.push(data);
            });
            setDepartment(dataList);
        }
    };

    const loadData = async (params: any) => {
        let data = {
            startTime: startTime.format("YYYY-MM-DD 00:00:00"),
            endTime: endTime.format("YYYY-MM-DD 23:59:59"),
            departmentUuid: departmentUuid.toString()
        };
        let rsp = await getEmpDataByGroup(data);
        if (rsp.err === 0) {
            setEmpSumData(rsp.data.totalData);
            return {
                data: rsp.data.data,
                success: true

            };
        } else {
            return {
                data: [],
                success: true
            };
        }
    };

    const loadFangKeData = async (params: any) => {
        let data = {
            startTime: startTime.format("YYYY-MM-DD 00:00:00"),
            endTime: endTime.format("YYYY-MM-DD 23:59:59"),
            departmentUuid: checkDepartmentUuid
        }
        let rsp = await getFangKeList(data);
        if (rsp.err === 0) {
            return {
                data: rsp.data,
                success: true

            };
        } else {
            return {
                data: [],
                success: true
            };
        }
    }

    const tableExtraRender = () => {
        return (
            <Row gutter={16}>
                <Col span={6}>
                    <Card style={{ backgroundColor: "#fff" }} className={'clickItem'} onClick={() => {
                        setCheckDepartmentUuid("")
                        setCheckEmpType("")
                        setVisible(true)
                    }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>人员总数</span>}
                            value={empSumData.total}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>人</span>}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className={'clickItem'} onClick={() => {
                        setCheckDepartmentUuid("")
                        setCheckEmpType("固定人员")
                        setVisible(true)
                    }} style={{ backgroundColor: "#fff" }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>固定人员</span>}
                            value={empSumData.gudingnum}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>人</span>}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className={'clickItem'} onClick={() => {
                        setCheckDepartmentUuid("")
                        setCheckEmpType("临时人员")
                        setVisible(true)
                    }}  style={{ backgroundColor: "#fff" }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>临时人员</span>}
                            value={empSumData.linshinum}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>人</span>}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className={'clickItem'} onClick={() => {
                        setCheckDepartmentUuid("")
                        setFangKeVisile(true)
                    }}  style={{ backgroundColor: "#fff" }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>访客</span>}
                            value={empSumData.fangkeynum}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>人</span>}
                        />
                    </Card>
                </Col>
            </Row>
        );
    };
    const tableSearchBarRender = () => {
        return [
            <Space>
                <CustomRangePicker
                    onChange={(startTime, endTime) => {
                        setStartTime(startTime);
                        setEndTime(endTime);
                    }}
                    selectType={1}
                    startTime={startTime}
                    endTime={endTime}
                />
                <GsTreeSelect
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeDefaultExpandAll
                    treeData={department}
                    multiple={true}
                    treeCheckable={true}
                    treeCheckStrictly={true}
                    showCheckedStrategy={TreeSelect.SHOW_ALL}
                    placeholder={"请选择部门"}
                    treeDataSimpleMode={true}
                    onChange={(value: any) => {
                        let values: any = [];
                        value.map((item: any) => {
                            values.push(item.value);
                        });
                        setDepartmentUuid(values);
                    }}
                />
                <Button type={"primary"} onClick={() => {
                    actionRef.current.reloadAndRest();
                }}>查询</Button>
            </Space>
        ];
    };
    return (
        <>
            <GSTable
                columns={columns}
                rowKey={"uuid"}
                expandable={{
                    defaultExpandAllRows: false
                }}
                actionRef={actionRef}
                tableExtraRender={tableExtraRender}
                searchBarRender={tableSearchBarRender()}
                // operationsBarRender={tableOperationsBarRender()}
                request={loadData}
            />
            <Modal destroyOnClose={true} width={1080} title={"详情"} visible={visible} onCancel={() => {
                setVisible(false);
            }} footer={null}>
                <CompanyList key={checkDepartmentUuid} departmentUuid={checkDepartmentUuid} empType={checkEmpType}
                             isDepartment={true} treeData={[]} showModal={true}/>
            </Modal>

            <Modal width={1080} title={"访客详情"} visible={fangKeVisible} onCancel={() => {
                setFangKeVisile(false);
            }} footer={null}>
                <GSTable
                    key={new Date().getTime()}
                    rowKey={'id'}
                    columns={fangKeColumns}
                    options={false}
                    request={loadFangKeData}
                />
            </Modal>
        </>
    );
};
export default EmpData;
