import React, {useEffect, useRef, useState} from "react";
import {ActionType} from "@ant-design/pro-table";
import {Button, Form, Input, message, Modal, Select, Space, Tag} from "antd";
import {FormInstance} from "antd/es/form";
import {
    addCarPath,
    carParkList,
    deleteCar,
    deleteCarPath,
    getCarList,
    getCarPathList,
    updateCarPath
} from "../../../services/Car";
import GSTable from "../../../components/GSTable";
import ProxyForm from "../../../components/ProxyForm";
let moment = require('moment');
const CarPathMaanager:React.FC = () =>{
    useEffect(()=>{
        getMachineNumber()
        getCarParkList().then()
    },[])
    const [form] = Form.useForm<FormInstance>();
    const actionRef = useRef<ActionType>();
    const [visible, setVisble] = useState(false);
    const [add, setAdd] = useState(false);
    const FormItem = Form.Item
    const [machineNumbers,setMachineNumbers] = useState([])
    const [loading,setLoading] = useState(false)
    const [carParkData, setCarParkData] = useState([]);
    const columns = [
        {
            title: '车道名称',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => (
                <>
                    <a
                        onClick={() => {
                            openUpdateModal(record);
                        }}
                    >
                        {text}
                    </a>
                </>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: any) => (
                <>
                    <Tag color={record.status ? 'green' : 'red'}>{record.status ? '在线' : '离线'}</Tag>
                </>
            ),
        },

        {
            title: '机号',
            dataIndex: 'machineNumber',
            key: 'machineNumber',
        }, {
            title: '车场Id',
            dataIndex: 'carFactoryId',
            key: 'carFactoryId',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text: string, record: any) => (
                <>
					<span>
						{record.createTime !== undefined && record.createTime !== null
                            ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')
                            : '-'}
					</span>
                </>
            ),
        },

        {
            title: '操作',
            width: 80,
            fixed: 'right',
            key: 'action',
            render: (text: string, record: any) => (
                <>
                    <a
                        onClick={() => {
                            deleteItem(record);
                        }}
                    >
                        删除
                    </a>
                </>
            ),
        },
    ];
    const getMachineNumber = () =>{
        let numbers:any = []
        for (let i = 1;i<=127;i++){
            numbers.push(i)
        }
        setMachineNumbers(numbers)
    }

    const getCarParkList = async () => {
        let rsp = await carParkList({});
        if (rsp.err === 0) {
            setCarParkData(rsp.data)
        }
    }
    const openUpdateModal = (item:any) =>{
        const formData:any ={
            uuid:item.uuid,
            name:item.name,
            machineNumber:item.machineNumber,
            carFactoryId:item.carFactoryId
        }
        form.setFieldsValue(formData)
        setAdd(false)
        setVisble(true)
    }
    const deleteItem =(item:any)=>{
        Modal.confirm({
            title: '你确定要删除' + item.name + '吗？',
            okType: 'danger',
            onOk: async () => {
                const data: any = {
                    uuid: item.uuid,
                };
                const res = await deleteCarPath(data);
                if (res.err === 0) {
                    message.success('删除成功');
                    actionRef.current.reload();
                } else {
                    message.error('删除失败');
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const tableOperationsBarRender = () =>[
        <Space>
            <Button type={"primary"} onClick={openAddModal}>添加</Button>
        </Space>
    ]
    const openAddModal = () =>{
        form.resetFields()
        setAdd(true)
        setVisble(true)
    }
    const saveItem = () =>{
        if(add){
            addItem()
        }else{
            updateItem()
        }

    }
    const addItem = () =>{
        form.validateFields().then(async (data:any)=>{
            const formData:any ={
                name:data.name,
                machineNumber:data.machineNumber,
                carFactoryId:data.carFactoryId,
            }
            setLoading(true)
            const res = await addCarPath(formData)
            setLoading(false)
            if(res.err === 0){
                message.success("添加成功")
                setAdd(false)
                setVisble(false)
                if(actionRef.current){
                    actionRef.current.reload()
                }
            }else{
                message.error("添加失败")
                setVisble(false)
                setAdd(false)
            }
        })
    }
    const updateItem = () =>{
        form.validateFields().then(async (data:any)=>{
            const formData:any ={
                uuid:data.uuid,
                name:data.name,
                machineNumber:data.machineNumber,
                carFactoryId:data.carFactoryId
            }
            setLoading(true)
            const res = await updateCarPath(formData)
            setLoading(false)
            if(res.err === 0){
                message.success("修改成功")
                setAdd(false)
                setVisble(false)
                if(actionRef.current){
                    actionRef.current.reload()
                }
            }else{
                message.error("修改失败")
                setVisble(false)
                setAdd(false)
            }
        })
    }
    return <>
        <GSTable
            columns={columns}
            // searchBarRender={tableSearchBarRender()}
            operationsBarRender={tableOperationsBarRender()}
            actionRef={actionRef}
            request={async (params: any = {}, sort: any, filter: any) => {
                const res = await getCarPathList({
                });
                return {
                    data: res.data,
                    success: true,
                };
            }}
        />
        <Modal title={add?'添加':'修改'} confirmLoading={loading} visible={visible} onCancel={()=>{setVisble(false)}}  onOk={saveItem}>
            <ProxyForm form={form}>
                <FormItem name={'uuid'} hidden={true}><Input/></FormItem>
                <FormItem label={'车道名称'} name={'name'} rules={[{required:true}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'机号'} name={'machineNumber'} rules={[{required:true}]} tooltip={'1-127'}>
                    <Select
                        style={{width:'100%'}}
                        showSearch
                        optionFilterProp="children"
                    >
                        {machineNumbers.length>0?
                            machineNumbers.map((item:any)=>{
                                return <Select.Option value={item}>{item}</Select.Option>
                            })
                            :''}
                    </Select>
                </FormItem><FormItem label={'车场ID'} name={'carFactoryId'} rules={[{required:true}]}>
                <Select>
                    {
                        carParkData.map((item: any) => {
                            return <Select.Option value={item.uuid}>{item.name}</Select.Option>
                        })
                    }
                </Select>
                </FormItem>
            </ProxyForm>
        </Modal>
    </>
}
export default CarPathMaanager
