import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import RoomList from '../assets/pages/RoomList';
import RoomStatus from '../assets/pages/RoomStatus';
import LeaseList from './components/LeaseList';


export default function LeaseMiniApp() {
	const rootUrl = '/lease';
	const menus: MiniAppMenu[] = [
		{
			authority: 'assets_management:room_management',
			name: '全部资产',
			path: 'room_list',
			component: RoomList,
		},
		{
			authority: 'assets_management:room_status',
			name: '房态图',
			path: 'room_status',
			component: RoomStatus,
		},
		{
			authority: 'lease_management:lease_list',
			name: '租赁信息',
			path: 'lease_list',
			component: LeaseList,
		},
		// {
		// 	authority: 'assets_management:param_setting',
		// 	name: '收费参数设置',
		// 	path: 'room_settings',
		// 	// component: RoomChargeList,
		// 	component: RoomChargeList1,
		// },
	];

	const routes: MiniAppRoute[] = [
		
	];

	return <MiniApp miniAppName={'租赁管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
