import Api from '../data/API';

export async function getSchemeTypeList(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getSchemeTypeList', params);
}

export async function getSchemeTechList(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getSchemeTechList', params);
}
export async function addSchemeType(params: any) {
	return Api.postWithAuth('/adminapi/scheme/addSchemeType', params);
}

export async function updateSchemeType(params: any) {
	return Api.postWithAuth('/adminapi/scheme/updateSchemeType', params);
}
export async function moveSchemeType(params: any) {
	return Api.postWithAuth('/adminapi/scheme/moveSchemeType', params);
}
export async function moveSchemeTech(params: any) {
	return Api.postWithAuth('/adminapi/scheme/moveSchemeTech', params);
}
export async function deleteSchemeType(params: any) {
	return Api.postWithAuth('/adminapi/scheme/deleteSchemeType', params);
}

export async function getTreeSchemeTypeList(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getTreeSchemeTypeList', params);
}

export async function getTreeSchemeTechList(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getTreeSchemeTechList', params);
}


export async function sortSchemeType(params: any) {
	return Api.postWithAuth('/adminapi/scheme/sortSchemeType', params);
}



export async function addSchemeTech(params: any) {
	return Api.postWithAuth('/adminapi/scheme/addSchemeTech', params);
}

export async function updateSchemeTech(params: any) {
	return Api.postWithAuth('/adminapi/scheme/updateSchemeTech', params);
}

export async function deleteSchemeTech(params: any) {
	return Api.postWithAuth('/adminapi/scheme/deleteSchemeTech', params);
}

export async function sortSchemeTech(params: any) {
	return Api.postWithAuth('/adminapi/scheme/sortSchemeTech', params);
}



export async function getSchemeList(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getSchemeList', params);
}

export async function addScheme(params: any) {
	return Api.postWithAuth('/adminapi/scheme/addScheme', params);
}

export async function updateScheme(params: any) {
	return Api.postWithAuth('/adminapi/scheme/updateScheme', params);
}

export async function deleteScheme(params: any) {
	return Api.postWithAuth('/adminapi/scheme/deleteScheme', params);
}

export async function findSchemeInfoByUuid(params: any) {
	return Api.postWithAuth('/adminapi/scheme/findSchemeInfoByUuid', params);
}
export async function updateSchemeStatus(params: any) {
	return Api.postWithAuth('/adminapi/scheme/updateSchemeStatus', params);
}
export async function getClickNumberData(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getClickNumberData', params);
}
export async function collectionStatic(params: any) {
	return Api.postWithAuth('/adminapi/collection/collectionStatic', params);
}

export async function tiaoZhengSchemeType(params: any) {
	return Api.postWithAuth('/adminapi/scheme/tiaoZhengSchemeType', params);
}
export async function tiaoZhengSchemeTech(params: any) {
	return Api.postWithAuth('/adminapi/scheme/tiaoZhengSchemeTech', params);
}
export async function getCompanyInfoByName(params: any) {
	return Api.postWithAuth("/admin/api/company/getCompanyInfoByName", params)
}

export async function getCompanyList(params: any) {
	return Api.postWithAuth("/adminapi/scheme/getSzgCompanyList", params)
}
export async function getSzgOrderList(params: any) {
	return Api.postWithAuth("/adminapi/scheme/getSzgOrderList", params)
}
export async function updateSzgCompany(params: any) {
	return Api.postWithAuth("/adminapi/scheme/updateSzgCompany", params)
}


export async function companyAddEmployee(params: any) {
	return Api.postWithAuth('/admin/api/company/company_add_employee', params);
}
export async function companyUserList(params: any) {
	return Api.postWithAuth('/admin/api/company_user_list', params);
}
export async function deleteCompanyEmp(params: any) {

	return Api.postWithAuth('/api/company/deleteCompnayEmployee', params);

}

export async function findUserInfo(params: any) {
	return Api.postWithAuth('/api/find_user_info', params);
}
export async function updateCompnayEmployeeInfo(params: any) {
	return Api.postWithAuth('/api/company/updateCompnayEmployeeInfo', params);
}
export async function getTotalData(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getTotalData', params);
}
export async function getRightsAndInterests(params: any) {
	return Api.postWithAuth('/adminapi/scheme/getRightsAndInterests', params);
}
