import React, {useEffect, useRef, useState} from 'react';
import {ActionType} from "@ant-design/pro-table/lib/typing";
import {Button, Form, Image, Input, InputNumber, message, Modal, Select, Space, Switch, Tag} from "antd";
import API from "../../../data/API";
import AuthorizedView from "../../../util/AuthorizedView";
import {getRightsAndInterests} from "../../../services/scheme";
import {
    addInstitutesUser,
    addResearchInstitutes, deleteInstitutesUser,
    getInstitutesList,
    getInstitutesUserList, updateInstitutesUser,
    updateResearchInstitutes
} from "../../../services/AchievementService";
import GSTable from "../../../components/GSTable";
import UploadImgFormItem from "../../../components/UploadImgFormItem";
import CustomDateFormItem from "../../assets/components/CustomDateFormItem";
const Index: React.FC = (props: {institutesUuid: string}) => {
    const actionRef = useRef<ActionType>()
    const [search, setSearch] = useState("");
    const [addVisible, setAddVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [addForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    useEffect(() => {
    }, [])
    const columns = [
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '手机号',
            key: 'phone',
            dataIndex: 'phone',
        },
        {
            title: '邮箱',
            key: 'email',
            dataIndex: 'email',
        },
        {
            title: '会员',
            key: 'vipUser',
            dataIndex: 'vipUser',
            render: (text: string , record: any) => {
                if (record.vipUser) {
                    return  <Tag color={'green'} >是</Tag>
                } else {
                    return  <Tag color={'red'}>否</Tag>
                }
            }
        }, {
            title: '操作',
            key: 'active',
            render: (text: string, record: any) => {
                return <Space>
                    <AuthorizedView needAuthority={'sz:institutes:updateUser'}>
                        <a onClick={() => {
                            setUpdateVisible(true)
                            updateForm.setFieldsValue({...record, isVipUser: record.vipUser})
                        }}>编辑</a>
                    </AuthorizedView>
                    <AuthorizedView needAuthority={'sz:institutes:deleteUser'}>
                        <a onClick={() => {
                           Modal.confirm({
                               title: '提示',
                               content: '您确定删除该成员吗？',
                               okType: "danger",
                               onOk: async () => {
                                   let rsp = await deleteInstitutesUser({uuid: record.uuid})
                                   if (rsp.err === 0) {
                                       actionRef.current.reload()
                                   } else {
                                       message.error(rsp.msg)
                                   }
                               }
                           })
                        }}>删除</a>
                    </AuthorizedView>
                </Space>
            }
        }
    ]
    const tableSearchBarRender = () => [
        <Space>
            <Input onChange={(e) => {
                setSearch(e.target.value)

            }} placeholder={'请输入名称'}></Input>
            <Button type={"primary"} onClick={() => {
                actionRef.current.reloadAndRest();
            }}>查询</Button>
        </Space>
    ]
    const tableOperationsBarRender =() => [
        <AuthorizedView needAuthority={'sz:institutes:addUser'}>
            <Button type={"primary"} onClick={() => {
                setAddVisible(true)
            }}>添加</Button>
        </AuthorizedView>

    ]
    const addItem = async () => {
        let data = await addForm.validateFields()
        let rsp = await addInstitutesUser({...data, });
        if (rsp.err === 0) {
            setAddVisible(false)
            if (actionRef.current) {
                actionRef.current.reload();
            }
            message.success(rsp.msg)
        } else {
            message.error(rsp.msg)
        }
    }
    const updateItem = async () => {
        let data = await updateForm.validateFields()
        let rsp = await updateInstitutesUser({...data});
        if (rsp.err === 0) {
            setUpdateVisible(false)
            if (actionRef.current) {
                actionRef.current.reload();
            }
            message.success(rsp.msg)
        } else {
            message.error(rsp.msg)
        }
    }
    return (
        <div>
            <GSTable
                columns={columns}
                actionRef={actionRef}
                rowKey={'uuid'}
                searchBarRender={tableSearchBarRender()}
                operationsBarRender={tableOperationsBarRender()}
                request={async (params: any) => {
                    let rsp = await getInstitutesUserList({search: search, institutesUuid: props.institutesUuid, page: params.current, size: params.pageSize})
                    if (rsp.err === 0) {
                        return {
                            data: rsp.data,
                            total: rsp.total
                        }
                    }
                    return {
                        data: []
                    }
                }}
            >
            </GSTable>
            <Modal destroyOnClose={true} title={'添加成员'} visible={addVisible} onCancel={() => setAddVisible(false)} onOk={addItem}>
                <Form form={addForm} labelCol={{span: 6}} labelAlign={'left'}>
                    <Form.Item name={'institutesUuid'} hidden={true} initialValue={props.institutesUuid} label={'院所'}
                               rules={[{required: true, message: '输入院所'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'name'} label={'姓名'}
                               rules={[{required: true, message: '输入姓名'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'phone'} label={'手机号'}
                               rules={[{required: true, message: '输入手机号'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item valuePropName={'checked'} name={'isVipUser'} label={'会员'} initialValue={false}
                               rules={[{required: false, message: '会员'}]}>
                       <Switch />
                    </Form.Item>

                </Form>
            </Modal>
            <Modal destroyOnClose={true} title={'编辑院所'} visible={updateVisible} onCancel={() => setUpdateVisible(false)} onOk={updateItem}>
                <Form form={updateForm} labelCol={{span: 6}} labelAlign={'left'}>
                    <Form.Item name={'institutesUuid'} hidden={true} initialValue={props.institutesUuid} label={'院所'}
                               rules={[{required: true, message: '输入院所'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'uuid'} hidden={true}  label={'院所'}
                               rules={[{required: true, message: '输入院所'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'name'} label={'姓名'}
                               rules={[{required: true, message: '输入姓名'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'phone'} label={'手机号'}
                               rules={[{required: true, message: '输入手机号'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item valuePropName={'checked'} name={'isVipUser'} label={'会员'}
                               rules={[{required: false, message: '会员'}]}>
                        <Switch />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
}
export default Index;
