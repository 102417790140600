import React, { useEffect, useState } from 'react';
import { Col, Form, Input, message, Modal, Radio, Select, Upload } from 'antd';
import Api from '../../../data/API';
import ProxyForm from '../../../components/ProxyForm';
import { FormInstance } from 'antd/es/form';
import CustomPriceInput from './CustomPriceInput';
import { PlusOutlined } from '@ant-design/icons/lib';
import { addBanGong, updateBanGong } from '../../../services/BanGongService';
interface BanGongFormProps {
	onCancel: () => void | any;
	url: string;
	callBack: () => void | any;
	data?: any;
	title: string;
	visible: boolean;
}
const BanGongForm: React.FC<BanGongFormProps> = (props: any) => {
	const { confirm } = Modal;
	const Option = Select.Option;
	const [form] = Form.useForm<FormInstance>();
	const [fileList, setFileList] = useState([]);
	const FormItem = Form.Item;

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		if (props.data !== undefined && props.data.uuid !== undefined) {
			let formData = { ...props.data };
			let pictureUris = formData.pictureUris;
			let pictureUriList = pictureUris.split(',');
			let fileList: any = [];
			pictureUriList.map((item: any) => {
				let file = {
					uid: item,
					name: item,
					status: 'done',
					response: { err: 200, url: item, data: [Api.cdnRootPath + item] },
					url: Api.cdnRootPath + item,
					thumbUrl: Api.cdnRootPath + item,
				};
				fileList.push(file);
			});
			if (formData.price !== undefined) {
				formData.price = JSON.parse(props.data.price);
			}
			formData.pictureUris = fileList;
			form.setFieldsValue(formData);
			setFileList(fileList);
		}
	};
	const priceValidator = (rule: any, value: any, callback: any) => {
		console.log('价格标准》》》》', value);
		if (value === '') {
			callback('请输入价格标准');
			return;
		}
		let flag = false;
		value.data.map((item: any, index: number) => {
			if (index === 0 && (item.name === undefined || item.value === undefined)) {
				callback('请输入价格标准');
				flag = false;
				return;
			} else if (
				(item.name !== undefined && item.value === undefined) ||
				(item.name === undefined && item.value !== undefined)
			) {
				callback('价格名称和值是成对存在的');
				flag = false;
				return;
			} else {
				flag = true;
			}
		});
		if (flag) {
			callback();
		}
	};
	const normFile = (e: any) => {
		console.log('上传照片', e);
		if (Array.isArray(e)) {
			return e;
		}
		e.fileList.map((item: any) => {
			console.log('item>>>>>>>>>>>>>>', item.status);
			if (item.status === undefined) {
				item.status = 'error';
				item.response = '';
			}
			if (item.status === 'done') {
				if (item.response.err !== 0) {
					item.status = 'error';
					message.error(item.response.msg);
					item.response = '';
					// item.thumbUrl = 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png';
				} else {
					item.url = item.response.data[0];
					// item.thumbUrl = 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png';
				}
			}
		});
		setFileList(e.fileList);
		return e && e.fileList;
	};
	const uploadButton = (
		<div>
			{/*<Icon type="plus" /> */}
			<PlusOutlined />
			<div className="ant-upload-text">上传</div>
		</div>
	);

	const beforeUpload = (file: any) => {
		console.log('上传前', file, file.size, file.type);

		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
		if (!isJpgOrPng) {
			message.error('请上传jpeg, jpg或者png得格式得图片!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('文件大小不能超过2M!');
		}
		return isJpgOrPng && isLt2M;
	};

	const handleSubmit = () => {
		form.validateFields().then(async (data: any) => {
			let pictureUri: any = [];
			let formData = data;
			formData.pictureUris.map((item: any) => {
				if (item.status === 'done') {
					pictureUri.push(item.response.url);
				}
			});
			formData.pictureUris = pictureUri.toString();
			let price = data.price.data;
			formData.price = JSON.stringify({ data: price });
			formData.uuid = props.data !== undefined ? props.data.uuid : null;
			console.log('新数据》》》》', formData);
			confirm({
				title: '您确定要提交吗？',
				onOk() {
					saveData(formData);
				},
				onCancel() {
					console.log('Cancel');
				},
			});
		});
	};

	const saveData = async (values: any) => {
		let res;
		if (props.url == '/api/banGong/update_ban_gong') {
			res = await updateBanGong(values);
		} else {
			res = await addBanGong(values);
		}
		if (res.err === 0) {
			message.success('成功');
			form.resetFields();
			setFileList([]);
			props.callBack();
		} else {
			console.log('11111', res.errMsg[0], res.errMsg.length);
			if (res.errMsg !== undefined && res.errMsg.length > 0) {
				console.log('222222');
				res.errMsg.map((item: any) => {
					console.log('3333', Object.keys(item));
					for (let key in Object.keys(item)) {
						console.log('item', key);
						message.error(item[Object.keys(item)[key]]);
					}
				});
			} else {
				message.error(res.msg);
			}
		}
	};
	return (
		<div>
			<Modal
				title={props.title}
				width={900}
				visible={props.visible}
				onOk={handleSubmit}
				onCancel={props.onCancel}
			>
				<ProxyForm form={form}>
					<FormItem name={'name'} label={'办公名称'} rules={[{ required: true }]}>
						<Input placeholder={'请输入'} />
					</FormItem>
					<FormItem name={'address'} label={'地址'} rules={[{ required: true }]}>
						<Input placeholder={'请输入地址'} />
					</FormItem>
					<FormItem name={'type'} label={'类型'} rules={[{ required: true }]}>
						<Select>
							<Option value={'办公'}>办公</Option>
						</Select>
					</FormItem>
					<FormItem label={'所属'} name={'third'} rules={[{ required: true }]}>
						<Radio.Group>
							<Radio value={false}>小镇</Radio>
							<Radio value={true}>第三方</Radio>
						</Radio.Group>
					</FormItem>
					<FormItem
						label={'价格标准'}
						name={'price'}
						rules={[{ required: true, message: '  ' }, { validator: priceValidator }]}
					>
						<CustomPriceInput />
					</FormItem>
					<FormItem label={'空间特色'} name={'characteristic'} rules={[{ required: true }]}>
						<Input.TextArea placeholder={'请输入'} />
					</FormItem>
					<FormItem
						label={'图片'}
						valuePropName={'fileList'}
						name={'pictureUris'}
						getValueFromEvent={normFile}
						extra="请上传1200x800像素的png/jpg/jpeg格式图片"
					>
						<Upload
							listType={'picture-card'}
							beforeUpload={beforeUpload}
							accept={'.jpg,.png,.jpeg'}
							name="file"
							data={{ wuye_uuid: Api.wuyeUuid, width: 1200, height: 800,prefix: 'bangong' }}
							action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
						>
							{fileList.length > 2 ? null : uploadButton}
						</Upload>
					</FormItem>
					<FormItem label={'简介'} name={'synopsis'} rules={[{ required: true }]}>
						<Input.TextArea rows={4} placeholder={'请输入'} />
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default BanGongForm;
