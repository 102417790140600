import React, {useEffect, useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import AuthorizedView from "../../../util/AuthorizedView";
import {Form, Input, message, Modal, Select, Space} from "antd";
import DAL from "../../../data/DAL";
import {orderKindList, orderKindSetLiucheng, orderKindSetPerm} from "../../../services/orderService";
import {findContractPermGroup, savePermContract} from "../../../services/Contract";
import {ActionType} from "@ant-design/pro-table";
import {getAllLiucheng} from "../../../services/FoundationSetupService";

const OrderKind: React.FC = () => {
    const actionRef = useRef<ActionType>();
    const [permForm] = Form.useForm();
    const [liuChengForm] = Form.useForm();
    const [permContractList, setPermContractList] = useState<any>([]);
    const [permVisible, setPermVisible] = useState(false);
    const [liuChengVisible, setLiuChengVisible] = useState(false);
    const [permEnum, setPermEnum] = useState({})
    const [liuChengEnum, setLiuChengEnum] = useState({})
    const [liuChengList, setLiuChengList] = useState([]);
    const [loading,setLoading] = useState(false)
    useEffect(() => {
        getPermContractList().then()
        getLiuchengDanList().then()
    }, [])
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '关联退款流程',
            dataIndex: 'liuchengUuid',
            key: 'liuchengUuid',
            valueEnum: liuChengEnum
        },
        {
            title: '权限',
            dataIndex: 'permId',
            ellipsis: true,
            key: 'permId',
            render: (text: string, record: any) => {
                if (!record.permId ) {
                    return <span>-</span>
                }
                return <Space>
                    {(record.permId || '').split(",").map((item: any) => {
                        return <span>{permEnum[item.toString()]}</span>
                    })}
                </Space>
            }
        },
        {
            title: '操作',
            key: 'action',
            width: 150,
            render: (text: string, record: any) => (
                <Space>
                    <AuthorizedView needAuthority={'charge_management:order_kind:setPerm'}>
                        <a onClick={() => {
                            permForm.setFieldsValue({uuid: record.uuid, permUuid: (record.permId || '').split(",")})
                            setPermVisible(true)
                        }}>设置权限分组</a>
                    </AuthorizedView>
                    <AuthorizedView needAuthority={'charge_management:order_kind:setRefued'}>
                        <a onClick={() => {
                            liuChengForm.setFieldsValue({uuid: record.uuid, liuchengUuid: (record.liuchengUuid || '')})
                            setLiuChengVisible(true)
                        }}>设置退款流程</a>
                    </AuthorizedView>
                </Space>
            ),
        },
    ];
    const getLiuchengDanList = async () => {
        const data = {
            state: true,
            selectedTags: "",
            searchContent: ""
        };
        const res = await getAllLiucheng(data);
        console.log("res:::", res);
        if (res.err === 0) {
            let tempEnum = {};
            setLiuChengList(res.data);
            res.data.map((item: any) => {
                tempEnum[item.uuid] = item.name;
            })
            setLiuChengEnum(tempEnum)
        }
    };
    const getPermContractList = async () => {
        const res = await findContractPermGroup({type: '订单'});
        if (res.err == 0) {
            setPermContractList([...res.data]);
            let tempEnum: any = {};
            res.data.map((item: any) => {
                tempEnum[item.id + ""] = item.name;
            })
           setPermEnum(tempEnum);
        }
    };
    const setOrderKindReq = async () => {
        permForm.validateFields().then(async (data: any) => {
            setLoading(true)
            const res = await orderKindSetPerm({ ...data, permUuid: data.permUuid });
            setLoading(false)
            if (res.err == 0) {
                message.success('设置成功');
                setPermVisible(false);
                permForm.resetFields();
                actionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    }
    const setOrderKindLiuchengReq = async () => {
        liuChengForm.validateFields().then(async (data: any) => {
            setLoading(true)
            const res = await orderKindSetLiucheng({ ...data});
            setLoading(false)
            if (res.err == 0) {
                message.success('设置成功');
                setLiuChengVisible(false);
                liuChengForm.resetFields();
                actionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    }
    return <>
        <GSTable
            actionRef={actionRef}
            options={false}
            columns={columns}
            request={async (params: any = {}, sort: any, filter: any) => {
                let param = {
                    page: params.current,
                    size: params.size
                }
                let rsp = await orderKindList(param);
                if (rsp.err === 0) {
                    return {
                        data: rsp.data,
                        total: rsp.total
                    }
                }
                return {
                    data: []
                }
            }}
        />
        <Modal
            title={'设置权限分组'}
            visible={permVisible}
            onOk={() => {
                setOrderKindReq()
            }}
            confirmLoading={loading}
            onCancel={() => {
                setPermVisible(false);
            }}
        >
            <Form form={permForm} labelCol={{ span: 4 }}>
                <Form.Item name={'uuid'} hidden={true}>
                    <Input></Input>
                </Form.Item>
                <Form.Item name={'permUuid'} label={'分组'}>
                    <Select mode="multiple" showSearch optionFilterProp="children">
                        {permContractList.map((item: any) => {
                            return (
                                <>
                                    <Select.Option value={item.id + ''}>{item.name}</Select.Option>
                                </>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
        <Modal
            title={'设置退款流程'}
            visible={liuChengVisible}
            confirmLoading={loading}
            onOk={() => {
                setOrderKindLiuchengReq()
            }}
            onCancel={() => {
                setLiuChengVisible(false);
            }}
        >
            <Form form={liuChengForm} labelCol={{ span: 4 }}>
                <Form.Item name={'uuid'} hidden={true}>
                    <Input></Input>
                </Form.Item>
                <Form.Item name={'liuchengUuid'} label={'流程'}>
                    <Select showSearch optionFilterProp="children">
                        {liuChengList.map((item: any) => {
                            return (
                                <>
                                    <Select.Option value={item.uuid}>{item.name}</Select.Option>
                                </>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    </>
}
export default OrderKind;
