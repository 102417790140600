import Api from '../data/API';

export async function deleteChargeBill(params: any) {
	return Api.postWithAuth('/debit_note/delete_charge_bill', params);
}

export async function findAll(params: any) {
	return Api.postWithAuth('/debit_note/find_all', params);
}
export async function billStatistical(params: any) {
	return Api.postWithAuth('/debit_note/billStatistical', params);
}

export async function createBillRefund(params: any) {
	return Api.postWithAuth('/debit_node/createBillRefund', params);
}export async function findRefundRecordList(params: any) {
	return Api.postWithAuth('/debit_note/findRefundRecordList', params);
}
export async function findCheckBillInfo(params: any) {
	return Api.postWithAuth('/debit_note/findCheckBillInfo', params);
}


export async function billArrears(params: any) {
	return Api.postWithAuth('/debit_note/billArrears', params);
}

export async function findFee(params: any) {
	return Api.postWithAuth('/debit_node/find_fee', params);
}

export async function updateDebitState(params: any) {
	return Api.postWithAuth('/debit_note/update_debit_state', params);
}

export async function addDebitNote(params: any) {
	return Api.postWithAuth('/debit_note/add_debit_note', params);
}
export async function updateBill(params: any) {
	return Api.postWithAuth('/debit_note/updateBill', params);
}
export async function refundCompletion(params: any) {
	return Api.postWithAuth('/debit_note/refundCompletion', params);
}
export async function refundCancel(params: any) {
	return Api.postWithAuth('/debit_note/refundCancel', params);
}

export async function findFeeSubject(params: any) {
	return Api.postWithAuth('/debit_node/find_fee_subject', params);
}

export async function synchronizationFeeSubject(params: any) {
	return Api.postWithAuth('/debit_node/synchronization_fee_subject', params);
}

export async function addFeeSubject(params: any) {
	return Api.postWithAuth('/debit_node/add_fee_subject', params);
}
export async function checkFeeTypeTypeCode(params: any) {
	return Api.postWithAuth('/debit_node/checkFeeTypeTypeCode', params);
}
export async function updataFeeSubject(params: any) {
	return Api.postWithAuth('/debit_node/updata_fee_subject', params);
}

export async function deleteFeeSubject(params: any) {
	return Api.postWithAuth('/debit_node/delete_fee_subject', params);
}

export async function batchList(params: any) {
	return Api.postWithAuth('/adminapi/billRecord/batchList', params);
}
export async function detailList(params: any) {
	return Api.postWithAuth('/adminapi/billRecord/detailList', params);
}
