import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { Button, Descriptions, Input, message, Modal, Select, Space, Tag } from 'antd';
import { getSystemLogs } from '../../../services/SystemLogSevice';
import { ActionType } from '@ant-design/pro-table';
import { getOrderInfo, getOrderInvoiceList, updateOrderInvoiceState } from '../../../services/Invoice';
import { stat } from 'fs';
import moment from 'moment';
import DAL from '../../../data/DAL';
const InvoiceManager: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [state, setState] = useState('');
	const [item, setItem] = useState<any>({});
	const [orderProduct, setOrderProduct] = useState<any>({})
	const [infoVisible, setInfoVisible] = useState(false);
	const [order, setOrder] = useState<any>({})
	const columns = [
		{
			title: '名称',
			dataIndex: 'feeName',
			key: 'feeName',
		},
		{
			title: '订单号',
			dataIndex: 'outTradeNo',
			key: 'outTradeNo',
		},
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			render: (_: any, item: any) => (
				<>
					<Tag color={item.state == 1 ? 'red' : item.state == 2 ? 'green' : 'gold'}>
						{item.state == 1 ? '申请中' : item.state == 2 ? '已开据' : '未申请/已退回'}{' '}
					</Tag>
				</>
			),
		},

		{
			title: '发票类型',
			dataIndex: 'invoiceType',
			key: 'invoiceType',
		},
		{
			title: '抬头类型',
			dataIndex: 'invoiceTitle',
			key: 'invoiceTitle',
		},
		{
			title: '发票抬头',
			dataIndex: 'vatCompanyName',
			key: 'vatCompanyName',
		},
		{
			title: '单位税号',
			dataIndex: 'vatCompanyRegnum',
			key: 'vatCompanyRegnum',
		},
		{
			title: '电话',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: '开票时间',
			dataIndex: 'createdTime',
			valueType: 'dateTime',
			key: 'createdTime',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<>
					<Space>
						{record.state == 1 && (
							<a
								onClick={() => {
									updateInvoice(record);
								}}
							>
								开票
							</a>
						)}
						<a
							onClick={() => {
								getInvoiceInfo(record);
							}}
						>
							详情
						</a>
					</Space>
				</>
			),
		},
	];


	const getInvoiceInfo = async (item:any) => {
		const formData: any = {
			orderUuid: item.orderUuid,
		};
		const res = await getOrderInfo(formData);
		if (res.err == 0) {
			console.log("res:::",res)
			setItem(item);
			if (isNotNull(res.orderProduct)) {
				setOrderProduct(res.orderProduct)
			} else {
				setOrderProduct({})
			}
			if (isNotNull(res.orderD)) {
				setOrder(res.orderD)
			} else {
				setOrder({})
			}
		setInfoVisible(true);
		}
	}

	const updateInvoice = async (item: any) => {
		Modal.confirm({
			content: '您是否要发票？',
			onOk: async () => {
				const res = await updateOrderInvoiceState({ uuid: item.uuid });
				if (res.err === 0) {
					message.success('修改成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error('修改失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const tableSearchBarRender = () => {
		return [
			<Input
				placeholder={'发票抬头，电话'}
				onChange={(e: any) => {
					setSearch(e.target.value);
				}}
			/>,
			<Select
				value={state}
				onChange={(value: any) => {
					setState(value);
				}}
				style={{ width: 150 }}
			>
				<Select.Option value={''}>全部</Select.Option>
				<Select.Option value={'1'}>申请中</Select.Option>
				<Select.Option value={'2'}>已开据</Select.Option>
			</Select>,
			<Button
				type={'primary'}
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>,
		];
	};
	const isNotNull = (string) => {
		if (string != '' && string != null && string != undefined) {
			return true;
		}
		return false;
	};

	const getList = async (page: number, size: number) => {
		let params = {
			search: search,
			page: page,
			size: size,
			state: state,
			wuyeUuid: localStorage.getItem('wyUuid'),
		};
		let rsp = await getOrderInvoiceList(params);
		if (rsp.err === 0) {
			return {
				data: rsp.data,
				total: rsp.total,
			};
		} else {
			return {
				data: [],
				total: 0,
			};
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				searchBarRender={tableSearchBarRender()}
				actionRef={actionRef}
				request={(params: any) => {
					return getList(params.current, params.pageSize);
				}}
			/>

			<Modal
				title="详情"
				visible={infoVisible}
				onCancel={() => {
					setInfoVisible(false);
				}}
				width={900}
				footer={null}
			>
				<Descriptions title="发票信息" bordered>
					<Descriptions.Item label="开票金额">￥{ (item.actualAmount / 100).toFixed(2)}</Descriptions.Item>
					<Descriptions.Item label="票种">{ item.invoiceType}</Descriptions.Item>
					<Descriptions.Item label="开票名称">{ item.vatCompanyName}</Descriptions.Item>
					<Descriptions.Item  style={{display:isNotNull(item.vatCompanyRegnum)?'':'none'}} label="纳税人识别号">{item.vatCompanyRegnum}</Descriptions.Item>
					<Descriptions.Item  style={{display:isNotNull(item.vatCompanyAddress)?'':'none'}} label="企业经营地址">{item.vatCompanyAddress}</Descriptions.Item>
					<Descriptions.Item style={{display:isNotNull(item.vatBankName)?'':'none'}} label="开户银行名称">{item.vatBankName}</Descriptions.Item>
					<Descriptions.Item style={{display:isNotNull(item.vatBankAccount)?'':'none'}} label="开户银行账号">{item.vatBankAccount}</Descriptions.Item>
					<Descriptions.Item style={{display:isNotNull(item.phone)?'':'none'}} label="联系电话">{item.phone}</Descriptions.Item>
					<Descriptions.Item style={{display:isNotNull(item.email)?'':'none'}} label="邮箱">{item.email}</Descriptions.Item>
				</Descriptions>
				<Descriptions style={{marginTop:20}} title="订单信息" bordered>
					<Descriptions.Item label="订单编号">{ order.orderNo}</Descriptions.Item>
					<Descriptions.Item label="商户订单号">{order.outTradeNo}</Descriptions.Item>
					<Descriptions.Item label="支付方式">{(order.payment =='qiYe')?'企业支付':'微信支付'}</Descriptions.Item>
					<Descriptions.Item style={{ display: isNotNull(order.payTime) ? '' : 'none' }} label="支付时间">
						{isNotNull(order.payTime)?moment(order.payTime).format("YYYY-MM-DD HH:mm:ss"):''}
					</Descriptions.Item>
					<Descriptions.Item label="类型">{ orderProduct.type}</Descriptions.Item>
					<Descriptions.Item label="名称">{ orderProduct.name}</Descriptions.Item>
					<Descriptions.Item label="规格">{orderProduct.specification}</Descriptions.Item>
					<div hidden={!DAL.dal.isNotNull(orderProduct.price)}>
						<Descriptions.Item label="价格">￥{(orderProduct.price /100).toFixed(2)}</Descriptions.Item>
					</div>
					<div hidden={!DAL.dal.isNotNull(orderProduct.deposit)}>
						<Descriptions.Item label="押金">￥{isNotNull(orderProduct.deposit)? (orderProduct.price /100).toFixed(2):'0'}</Descriptions.Item>
					</div>
					<Descriptions.Item label="购买数量">{orderProduct.productNumber}</Descriptions.Item>
					<Descriptions.Item style={{display:(orderProduct.type == '工位' || orderProduct.type == '办公室') ?'':'none'}} label="付款方式">年付</Descriptions.Item>
					<Descriptions.Item label="付款金额">￥{(order.totalFee /100).toFixed(2)}</Descriptions.Item>

				</Descriptions>

			
			

			
			</Modal>
		</>
	);
};
export default InvoiceManager;
