import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import OrderList from './pages/OrderList';
import OrderReport from './pages/OrderReport';
import InvoiceMamager from './pages/InvoiceMamager';
import OrderKind from "./pages/OrderKind";

export default function OrderMiniApp() {
	const rootUrl = '/order';
	const menus: MiniAppMenu[] = [
		{
			authority: 'charge_management:order_list',
			name: '订单管理',
			path: 'order-list',
			//@ts-ignore
			component: OrderList,
		},
		{
			authority: 'charge_management:order_kind',
			name: '订单类型设置',
			path: 'order-kind',
			component: OrderKind,
		},
		{
			name: '发票申请',
			path: 'invoice-list',
			component:InvoiceMamager,
		},
		{
			authority: 'charge_management:order_report',
			name: '订单报表',
			path: 'order-report',
			component: OrderReport,
		},
	];

	return <MiniApp miniAppName={'订单处理'} rootUrl={rootUrl} menus={menus} />;
}
