import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { Button, Col, Dropdown, Input, Menu, Modal, Row, Select, Space } from 'antd';
import { getApplicationLogList, getPermItemProjList, getSystemLogs } from '../../../services/SystemLogSevice';
import CustomRangePicker from '../../../components/CustomRangePicker';
import { ActionType } from '@ant-design/pro-table';
import ReactJson from 'react-json-view';
import moment from 'moment';
const ApplicationLogList: React.FC = () => {
	useEffect(() => {
		getAllPerItem();
	}, []);
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [record, setRecord] = useState({});
	const [startTime, setStartTime] = useState(
		moment().subtract(7, 'd').hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss')
	);
	const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:ss'));
	const [item, setItem] = useState<any>({});
	const [visible, setVisible] = useState(false);
	const [perAllItem, setPerAllItem] = useState([]);
	const [menuPerItems, setMenuPerItems] = useState([]);
	const [sysModule,setSysModule] = useState("")
	let permItems = {};
	const sysTypesEnum = {
		add: '添加表单',
		update: '修改表单',
		sort: '排序',
		delete: '删除表单',
		export: '导出',
	};

	const getAllPerItem = async () => {
		const res = await getPermItemProjList({});
		if (res.err === 0) {
			const perms = res.data;
			setPerAllItem(perms);
			if (perms.length > 0) {
				const menus = perms.filter((item: any) => {
					return item.type === 'menu';
				});
				setMenuPerItems(menus);
			}
			actionRef.current.reload();
		}
	};
	const columns = [
		{
			title: '操作人员',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '账号',
			dataIndex: 'userLoginId',
			key: 'userLoginId',
		},
		{
			title: '操作模块',
			dataIndex: 'sysModule',
			key: 'sysModule',
			render: (text: any, item: any) => {
				return <span>{getItem(item.sysModule)}</span>;
			},
		},
		{
			title: '二级操作模块',
			dataIndex: 'sysXiaoModule',
			key: 'sysXiaoModule',
			render: (text: any, item: any) => {
				return <span>{getItem(item.sysXiaoModule)}</span>;
			},
		},
		{
			title: '操作类型',
			dataIndex: 'sysType',
			key: 'sysType',
			valueEnum: sysTypesEnum,
		},
		{
			title: '操作描述',
			dataIndex: 'eventMsg',
			key: 'eventMsg',
			ellipsis: true,
		},
		{
			title: '操作时间',
			dataIndex: 'createdTime',
			valueType: 'dateTime',
			key: 'createdTime',
		},
		{
			title: 'IP地址',
			dataIndex: 'ip',
			key: 'ip',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<>
					<a
						onClick={() => {
							openInfo(record);
						}}
					>
						详情
					</a>
				</>
			),
		},
	];

	const getItem = (string: any) => {
		if (perAllItem.length > 0) {
			const items: any = perAllItem.filter((item: any) => {
				return item.permKey === string;
			});
			console.log('item::', items);
			if (items.length > 0) {
				return items[0].name || '';
			} else {
				return string;
			}
		} else {
			return string;
		}
	};

	const columns1 = [
		{
			title: '操作人员',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '账号',
			dataIndex: 'userLoginId',
			key: 'userLoginId',
		},
		{
			title: '操作模块',
			dataIndex: 'sysModule',
			key: 'sysModule',
			render: (text: any, item: any) => {
				return <span>{getItem(item.sysModule)}</span>;
			},
		},
		{
			title: '二级操作模块',
			dataIndex: 'sysXiaoModule',
			key: 'sysXiaoModule',
			render: (text: any, item: any) => {
				return <span>{getItem(item.sysXiaoModule)}</span>;
			},
		},
		{
			title: '操作描述',
			dataIndex: 'eventMsg',
			key: 'eventMsg',
		},
		{
			title: '操作时间',
			dataIndex: 'createdTime',
			valueType: 'dateTime',
			key: 'createdTime',
		},
		{
			title: 'IP地址',
			dataIndex: 'ip',
			key: 'ip',
		},
	];
	const openInfo = (value: any) => {
		if (value.request !== undefined && value.request !== null && value.request !== '') {
			setItem(JSON.parse(value.request));
			setRecord(value);
		} else {
			setRecord({});
			setItem({});
		}
		setVisible(true);
	};
	const rangePickerClick = (startTime: any, endTime: any) => {
		setStartTime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEndTime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};
	const tableSearchBarRender = () => {
		return [
			<Space>
				<CustomRangePicker onChange={rangePickerClick} />
				<Select placeholder="操作模块" allowClear style={{minWidth:100}} onChange={(value:any)=>{setSysModule(value)}} showSearch optionFilterProp="children">
					{menuPerItems.length>0?
						menuPerItems.map((item:any)=>{
							return <Select.Option value={item.permKey}>{item.name}</Select.Option>
						})
						:''
					}
				</Select>
				<Input
					placeholder={'姓名，账号，描述'}
					onChange={(e: any) => {
						setSearch(e.target.value);
					}}
				/>

				<Button
					type={'primary'}
					onClick={() => {
						if (actionRef.current) {
							actionRef.current.reloadAndRest();
						}
					}}
				>
					查询
				</Button>
			</Space>,
		];
	};

	const getList = async (page: number, size: number) => {
		if (perAllItem.length > 0) {
			let params = {
				search: search,
				startTime: startTime,
				endTime: endTime,
				page: page,
				size: size,
				sysModule: sysModule,
			};
			let rsp = await getApplicationLogList(params);
			if (rsp.err === 0) {
				return {
					data: rsp.data.data,
					total: rsp.data.total,
				};
			} else {
				return {
					data: [],
					total: 0,
				};
			}
		} else {
			return {
				data: [],
				total: 0,
			};
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				searchBarRender={tableSearchBarRender()}
				actionRef={actionRef}
				request={(params: any) => {
					return getList(params.current, params.pageSize);
				}}
			/>
			<Modal
				width={1000}
				title={'请求参数'}
				footer={null}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<div style={{ marginTop: -40, marginBottom: 10 }}>
					<GSTable
						columns={columns1}
						options={false}
						pagination={false}
						request={(params: any) => {
							return {
								data: [record],
								total: 0,
							};
						}}
					/>
				</div>
				<ReactJson src={item} name={'请求参数'} />
			</Modal>
		</>
	);
};
export default ApplicationLogList;
