import React, { useEffect, useState } from 'react';
import { Input, message, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import appConfig from '../appConfig';
import { RcFile } from 'antd/lib/upload';


interface UploadFileProps  {
	value?:string
	onChange?: (url: string) => void;

}

const TrimInput: React.FC<UploadFileProps> = (props: any) => {
	const triggerChange = (value?: string) => {
		const stringValue = (value || '').toString().replace(/\s*/g,"")
		props.onChange?.(stringValue);
	};

	return (
		<Input {...props} value={props.value} onChange={(e)=>triggerChange(e.target.value)}></Input>
	);
};
export default TrimInput;
