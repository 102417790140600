import React, { useContext, useEffect, useRef } from 'react';
import { Bar } from '@ant-design/charts';
import { DataContext } from '../pages/controlPlatform/CurrentPlatform';

const BarChart: React.FC = () => {
	const data = useContext(DataContext);
	const canvasRef = useRef(null);
	useEffect(() => {
		if (canvasRef.current) {
		}
		console.log('useEffect::::', data);
	}, []);

	const config = {
		data: data,
		isStack: true,
		xField: 'value',
		yField: 'year',
		seriesField: 'type',
		label: {
			// 可手动配置 label 数据标签位置
			position: 'middle',
			// 'left', 'middle', 'right'
			// 可配置附加的布局方法
			layout: [
				// 柱形图数据标签位置自动调整
				{
					type: 'interval-adjust-position',
				}, // 数据标签防遮挡
				{
					type: 'interval-hide-overlap',
				}, // 数据标签文颜色自动调整
				{
					type: 'adjust-color',
				},
			],
		},
	};
	return <Bar {...config} ref={canvasRef} />;
};
export default BarChart;
