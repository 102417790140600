import Api from '../data/API';
export async function getList(params: any) {
	return Api.postWithAuth('/admin/api/user/certificate/list', params);
}
export async function add(params: any) {
	return Api.postWithAuth('/admin/api/user/certificate/add', params);
}
export async function update(params: any) {
	return Api.postWithAuth('/admin/api/user/certificate/update', params);
}
export async function deleteInfo(params: any) {
	return Api.postWithAuth('/admin/api/user/certificate/delete', params);
}
export async function batchUserCertification(params: any) {
	return Api.postWithAuth('/admin/api/user/certificate/batchUserCertification', params);
}