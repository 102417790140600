import { Effect } from 'dva';
import { Reducer } from 'redux';
import { getAllBuild } from '../services/LouDongService';
import { getAllPermItem, getDepartment } from '../services/PermItemService';
import { getSchemeTechList, getSchemeTypeList, getTreeSchemeTechList, getTreeSchemeTypeList } from '../services/scheme';
import {getDeviceDList} from "../services/ZhuhushenheService";
export interface CommonState {
	builds: [];
	empUserList?: [];
	permItemList?: [];
	deviceList? :[];
	scenarioTypeList? :[];
	schemeTechList? :[];
}
export interface CommonType {
	namespace: 'common';
	state: CommonState;
	effects: {
		getBuildsList: Effect;
		getEmpUserList: Effect;
		getPermItemList: Effect;
		getDeviceList: Effect;
		getScenarioTypeList: Effect;
		getSchemeTechList:Effect
	};
	reducers: {
		saveBuildList: Reducer<CommonState>;
		saveEmpUserList: Reducer<CommonState>;
		saveUserList: Reducer<CommonState>;
		savePermItemList: Reducer<CommonState>;
		saveDeviceList:Reducer<CommonState>;
		saveScenarioTypeList: Reducer<CommonState>;
		saveSchemeTechList: Reducer<CommonState>;
		
	};
}
const CommonModal: CommonType = {
	namespace: 'common',
	state: {
		builds: [],
		// empUserList: JSON.parse(localStorage.getItem('empUserList') || '[]'),
		empUserList: [],
		permItemList: [],
		deviceList: [],
		scenarioTypeList:[],
		schemeTechList:[],
	},
	effects: {
		*getBuildsList({ payload }, { call, put }) {
			const resp = yield call(getAllBuild, payload);
			yield put({
				type: 'saveBuildList',
				payload: resp,
			});
		},
		*getEmpUserList({ payload }, { call, put }) {
			const resp = yield call(getDepartment, payload);
			yield put({
				type: 'saveEmpUserList',
				payload: resp,
			});
		},
		*getPermItemList({ payload }, { call, put }) {
			const resp = yield call(getAllPermItem, payload);
			yield put({
				type: 'savePermItemList',
				payload: resp,
			});
		},
		*getDeviceList({ payload }, { call, put }) {
			const resp = yield call(getDeviceDList, payload);
			yield put({
				type: 'saveDeviceList',
				payload: resp,
			});
		},
		*getScenarioTypeList({ payload }, { call, put }) {
			const resp = yield call(getTreeSchemeTypeList, payload);
			yield put({
				type: 'saveScenarioTypeList',
				payload: resp,
			});
		},
		*getSchemeTechList({ payload }, { call, put }) {
			const resp = yield call(getTreeSchemeTechList, payload);
			yield put({
				type: 'saveSchemeTechList',
				payload: resp,
			});
		},
	},

	reducers: {
		saveBuildList(state, { payload }) {
			return {
				...state,
				builds: payload.data,
			};
		},
		saveEmpUserList(state, { payload }) {
			return {
				...state,
				empUserList: payload.data,
			};
		},
		saveDeviceList(state, { payload }) {
			return {
				...state,
				deviceList: payload.data,
			};
		},
		savePermItemList(state, { payload }) {
			// localStorage.setItem('empUserList', payload.data);
			return {
				...state,
				permItemList: payload.data,
			};
		},
		saveScenarioTypeList(state, { payload }) {
			return {
				...state,
				scenarioTypeList: payload.data,
			};
		},
		saveSchemeTechList(state, { payload }) {
			return {
				...state,
				schemeTechList: payload.data,
			};
		},
	},
};
export default CommonModal;
