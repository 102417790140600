import React, {useMemo, useRef} from 'react'
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop, XYCoord} from "react-dnd";
import {Button, Col, Input, Row, Space, Tooltip} from "antd";
import {DeleteOutlined, DragOutlined} from "@ant-design/icons/lib";
interface IProps {
    accept: string,
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    type: string
}
const InputDragable: React.FC<IProps> = ({accept, index, moveCard, children, type}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag, dragPreview] = useDrag({
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
        // item 中包含 index 属性，则在 drop 组件 hover 和 drop 是可以根据第一个参数获取到 index 值
        item: { type: accept, index, activeType: 'sort'},
    });
    const [, drop] = useDrop({
        accept: accept,
        hover(item: { type: string; index: number; jieDianIndex: number, activeType: string}, monitor: DropTargetMonitor) {
            const dragIndex = item.index;
            const hoverIndex = index;
            if (!ref.current) {
                return;
            }

            // 拖拽元素下标与鼠标悬浮元素下标一致时，不进行操作,并且在一个节点里
            if (dragIndex === hoverIndex) {
                return;
            }


            // 确定屏幕上矩形范围
            const hoverBoundingRect = ref.current!.getBoundingClientRect();

            // 获取中点垂直坐标
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // 确定鼠标位置
            const clientOffset = monitor.getClientOffset();

            // 获取距顶部距离
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            /**
             * 只在鼠标越过一半物品高度时执行移动。
             *
             * 当向下拖动时，仅当光标低于50%时才移动。
             * 当向上拖动时，仅当光标在50%以上时才移动。
             *
             * 可以防止鼠标位于元素一半高度时元素抖动的状况
             */
            // 向下拖动
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // 向上拖动
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            /**
             * 如果拖拽的组件为 Box，则 dragIndex 为 undefined，此时不对 item 的 index 进行修改
             * 如果拖拽的组件为 Card，则将 hoverIndex 赋值给 item 的 index 属性
             */
            moveCard(dragIndex, hoverIndex);
            if (item.index !== undefined) {
                item.index = hoverIndex;
            }

        },
    });

    const style: React.CSSProperties = useMemo(() => ({
        position: 'relative',
        // Card 为占位元素是，透明度 0.4，拖拽状态时透明度 0.2，正常情况透明度为 1
        opacity:  isDragging ? 0.2 : 1,
        verticalAlign: 40,
        cursor: "pointer"
    }), [isDragging]);
    /**
     * 使用 drag 和 drop 对 ref 进行包裹，则组件既可以进行拖拽也可以接收拖拽组件
     * 使用 dragPreview 包裹组件，可以实现拖动时预览该组件的效果
     */
    dragPreview(drop(ref));
    return (
        <div style={style} ref={ref}>
            {
                type === 'radio' && <Space style={{marginBottom: 5}}>
                    {drag(<div><DragOutlined /></div>)}
                    {
                        children
                    }
                </Space>
            }
            {
                type === 'checkbox' && <Space style={{marginBottom: 5}}>
                    {drag(<div><DragOutlined /></div>)}
                    {
                        children
                    }
                </Space>
            }
            {
                type === 'select' && <Space style={{marginBottom: 5}}>
                    {drag(<div><DragOutlined /></div>)}
                    {
                        children
                    }
                </Space>
            }
            {
                type === 'expand'  &&    <Row gutter={8} align={'middle'}>
                    <Col span={6} style={{textAlign: 'right', marginTop: 10}}>
                        {drag(<div><DragOutlined /></div>)}
                    </Col>
                    {
                        children
                    }
                </Row>
            }
               {
                type === 'menu' && <Space style={{display:'flex',width:129,justifyContent:'space-between',alignItems:'center'}}>
                    {drag(<div><DragOutlined /></div>)}
                    {
                        children
                    }
                </Space>
            }
             

        </div>
    );
};
export default InputDragable;