import React, { useEffect, useRef, useState } from 'react';
import { editQuestionnaire } from '../../../services/QuestionnaireService';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import {
	addDebitNote,
	createBillRefund,
	deleteChargeBill,
	findAll,
	updateBill,
} from '../../../services/PayCostManagementService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import AuthorizedView from '../../../util/AuthorizedView';
import { findFee } from '../../../services/orderService';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
import * as XLSX from 'xlsx';
import { jsChargeBillPrice } from '../../../services/roomCharge';
const Option = Select.Option;
interface Params {
	state?: string;
	billType?: string;
}
const BillRefundList: React.FC<Params> = (props: any) => {
	useEffect(() => {
		getFee();
	}, []);

	const TreeNode = TreeSelect.TreeNode;
	const confirm = Modal.confirm;
	const Search = Input.Search;
	let { RangePicker } = DatePicker;
	let msg = require('../../../util/SuspensionMsg');
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [searchInput, setSearchInput] = useState('');
	const [fee, setFee] = useState([]);
	const [visible, setVisible] = useState(false);
	const [updateVisible, setupdateVisible] = useState(false);
	const [state, setState] = useState<any>(props.state || '');
	const [feeId, setFeeId] = useState('');
	const [Stime, setStime] = useState('');
	const [Etime, setEtime] = useState('');
	const [refundVisible, setrefundVisible] = useState(false);
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [billType, setbillType] = useState(props.billType || '');
	const billTypes = getBillTypes().list || [];
	const [updateForm] = Form.useForm<FormInstance>();
	const [refundData, setrefundData] = useState([]);
	const [receivableDate, setreceivableDate] = useState('');
	const [loading, setloading] = useState(false)
	const columns = [
		{
			title: '房间',
			width: 100,
			dataIndex: 'roomId',
		},
		{
			title: '客户',
			width: 100,
			dataIndex: 'custName',
		},
		{
			title: '缴费类型',
			width: 100,
			dataIndex: 'billType',
		},
		{
			title: '合同号',
			width: 150,
			dataIndex: 'contractNo',
		},
		{
			title: '手机号',
			width: 100,
			dataIndex: 'phone',
		},
		{
			title: '应收编号',
			width: 150,
			dataIndex: 'chargeBillId',
		},
		{
			title: '费用期间',
			width: 200,
			dataIndex: 'startTime',
			render: (text: string, record: any) => {
				return (
					<span>
						{record.startTime !== undefined && record.startTime !== null && record.startTime !== ''
							? moment(record.startTime).format('YYYY-MM-DD') + '~'
							: '-'}
						{record.endTime !== undefined && record.endTime !== null && record.endTime !== ''
							? moment(record.endTime).format('YYYY-MM-DD')
							: ''}
					</span>
				);
			},
		},
		{
			title: '收费科目',
			width: 100,
			dataIndex: 'name',
		},
		{
			title: '应收金额(元)',
			width: 100,
			dataIndex: 'actualAmount',
			render: (text: string) => <span>{(parseInt(text) / 100).toFixed(2)}</span>,
		},
		{
			title: '实收金额',
			dataIndex: 'state',
			width: 100,
			render: (text: string, record: any) => {
				return <span>{text == '1' ? (record.actualAmount / 100).toFixed(2) : 0}</span>;
			},
		},
		{
			title: '创建日期',
			dataIndex: 'createTime',
			width: 150,
			render: (text: string) => (
				<span>
					{text !== undefined && text !== null && text !== ''
						? moment(text).format('YYYY-MM-DD HH:mm:ss')
						: '-'}
				</span>
			),
		},
		{
			title: '实收日期',
			width: 100,
			dataIndex: 'payTime',
			render: (text: string, record: any) => {
				return (
					<span>
						{record.payTime === null || record.payTime === undefined
							? '-'
							: moment(record.payTime).format('YYYY-MM-DD HH:mm:ss')}
					</span>
				);
			},
		},
	];
	const openUpdateModel = (item) => {
		updateForm.setFieldsValue({ ...item, billRefund: 0 == item.billRefund ? undefined : item.billRefund });
		setupdateVisible(true);
	};
	const tableSearchBarRender = () => [
		// <div hidden={DAL.dal.isNotNull(props.state)}>
		// 	<Select
		// 		placeholder={'缴费状态'}
		// 		allowClear
		// 		onChange={(value: any) => {
		// 			setState(value);
		// 		}}
		// 	>
		// 		<Option value={'true'}>已缴费</Option>
		// 		<Option value={'false'}>未缴费</Option>
		// 	</Select>
		// </div>,
		// <div>
		// 	<Select value={billType} onChange={(value) => setbillType(value)}>
		// 		<Select.Option value={''}>全部</Select.Option>
		// 		{billTypes.map((item: any) => (
		// 			<Option value={item.value}>{item.name}</Option>
		// 		))}
		// 	</Select>
		// </div>,
		<Input
			placeholder="姓名,账号,房间号"
			onChange={(event) => {
				setSearchInput(event.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];

	const deleteItem = (value: any) => {
		confirm({
			title: '您确定要删除此缴费单吗?',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteChargeBill({ uuid: value.uuid });
				console.log('.....', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const getFee = async () => {
		const res = await findFee({});
		console.log('科目列表', res);
		if (res.err === 0) {
			setFee(res.data);
		}
	};

	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			let value = data.subjectName.split(',');
			let formData: any = {
				name: value[1],
				loginId: data.loginId,
				actualAmount: data.actualAmount,
				Stime: Stime,
				Etime: Etime,
				feeId: value[0],
				roomNumber: data.roomNumber,
				contractNo: data.contractNo,
				receivableDate,
			};
			console.log('data::;', formData);
			const res = await addDebitNote(formData);
			if (res.err === 0) {
				if (actionRef.current) {
					actionRef.current.reload();
				}
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				setVisible(false);
			} else {
				message.warning(res.msg);
			}
		});
	};
	const updateItem = () => {
		updateForm.validateFields().then(async (data: any) => {
			console.log('data::;', data);
			let formData: any = {
				...data,
			};
			const res = await updateBill(formData);
			if (res.err === 0) {
				if (actionRef.current) {
					actionRef.current.reload();
				}
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				setupdateVisible(false);
			} else {
				message.warning(res.msg);
			}
		});
	};

	const dateTime = (date: any, dataToString: any) => {
		setStime(dataToString[0]);
		setEtime(dataToString[1]);
	};
	const setYSData = (date: any, dataToString: any) => {
		setreceivableDate(dataToString);
	};
	const tableOperationsBarRender = () => [
		// <div hidden={DAL.dal.isNotNull(props.state)}>
		// <AuthorizedView needAuthority={'payment_management:debit_note:add'}>
		// 	<Button
		// 		type="primary"
		// 		onClick={() => {
		// 			setVisible(true);
		// 		}}
		// 	>
		// 		新增缴费单
		// 	</Button>
		// </AuthorizedView>,
		// </div>,
	];
	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setExprotData(selectedRows);
	};

	const createdRefund = async (selectedRows: [any?]) => {
		setrefundData(selectedRows);
		form.setFieldsValue({ refundData: selectedRows });
		setrefundVisible(true);
		console.log('selectedRows:::::', selectedRows);
	};

	const saveRefund = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			data.refundData.forEach((element) => {
				const refundP = parseFloat(element.refundPrice) * 100;
				console.log('refundP', refundP);

				if (element.actualAmount < refundP) {
					message.warning('退款金额不能超过原金额!');
					return;
				}
			});
			setloading(true)
			const res = await createBillRefund({ refundData: JSON.stringify(data.refundData) });
			setloading(false)
			if (res.err == 0) {
				message.success('创建成功');
				setrefundVisible(false);
				setrefundData([]);
				setselectedRowKeys([]);
				actionRef.current.reloadAndRest();
			} else {
				message.error(res.msg);
			}
		});
	};
	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '缴费单');
		/* save to file */
		XLSX.writeFile(wb, '缴费单.xlsx');
	};

	const renderRefundData = (uuid: string, name: string) => {
		const item = refundData.find((item) => item.uuid == uuid);
		console.log('billItem', uuid, item[`${name}`]);
		if (item !== undefined) {
			return item[`${name}`];
		}
	};

	const sumPrice =async () => {
		const endTime = form.getFieldValue('endTime')
		if (!DAL.dal.isNotNull(endTime)) {
			message.warning("请选择截止时间")
			return
		}
		const formData = {
			data: JSON.stringify(refundData),
			endTime:moment(endTime).format("YYYY-MM-DD")
		}
		setloading(true)
		const res = await jsChargeBillPrice(formData)
		setloading(false)
		if (res.err == 0) {
			const data = res.data || []
			form.setFieldsValue({ refundData: data });
		}
	}



	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				rowSelection={{
					fixed: true,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setselectedRowKeys(selectedRowKeys);
					},
					// getCheckboxProps: (record: any) => ({
					// 	disabled: record.third, // Column configuration not to be checked
					// }),
					selections: [
						{
							key: 'SELECT_ALL',
							text: '全选全部页面',
							onSelect: (changableRowKeys: any) => {
								setselectedRowKeys(changableRowKeys);
							},
						},
					],
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::', selectedRows);
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							<a onClick={() => createdRefund(selectedRows)}>生成退款</a>
						</Space>
					);
				}}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {
						page: params.current,
						size: params.pageSize,
						state: 'true',
						searchInput: searchInput,
						billType: billType,
						billRefund: '0',
					};
					const res = await findAll(data);
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>

			<Modal
				width={1200}
				visible={refundVisible}
				onCancel={() => setrefundVisible(false)}
				title="退款"
				confirmLoading={loading}
				onOk={saveRefund}
			>
				<Form form={form}>
					<div style={{ display: 'flex' }}>
					<Form.Item
							style={{ display:'flex',alignItems:'center'}}
							rules={[

							{
								required: true,
								message: '请选择房租截止日期',
							},
						]}
						name={'endTime'}
						label="房租截止日期"
					>
					<DatePicker />
					</Form.Item>
					<Button style={{marginLeft:10,marginTop:10}} loading={loading} onClick={sumPrice} type='primary'>计算金额</Button>

					</div>


					<div className="ant-pro-table ">
						<div className="ant-table-wrapper">
							<table
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									flex: 1,
								}}
							>
								<thead className={'ant-table-thead'} style={{ display: 'flex', flex: 1 }}>
									<tr style={{ display: 'flex', flex: 1 }}>
										<th style={{ flex: 1 }}>缴费类型</th>
										<th style={{ flex: 1 }}>费用期间</th>
										<th style={{ flex: 1 }}>收费科目</th>
										<th style={{ flex: 1 }}>实收金额</th>
										<th style={{ flex: 1 }}>退还金额</th>
									</tr>
								</thead>
								<tbody
									className={'ant-table-tbody'}
									style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
								>
									<Form.Item style={{ width: '100%' }} shouldUpdate noStyle>
										{() => (
											<Form.List name={'refundData'}>
												{(fields, { add, remove }) => (
													<>
														{fields.map((field: any, index: number) => (
															<>
																<tr style={{ flex: 1, display: 'flex' }}>
																	<td style={{ flex: 1, display: 'flex' }}>
																		<Form.Item
																			{...field}
																			// label={'收费类型'}
																			name={[field.name, 'billType']}
																			fieldKey={[field.fieldKey, 'billType']}
																		>
																			{renderRefundData(
																				form.getFieldValue([
																					'refundData',
																					field.name,
																					'uuid',
																				]),
																				'billType'
																			)}
																		</Form.Item>
																	</td>
																	<td style={{ flex: 1, display: 'flex' }}>
																		{
																			<Form.Item
																				style={{ width: '100%' }}
																				{...field}
																				// label={'收费标准'}
																				name={[field.name, 'billTime']}
																				fieldKey={[field.fieldKey, 'billTime']}
																			>
																				{DAL.dal.isNotNull(
																					renderRefundData(
																						form.getFieldValue([
																							'refundData',
																							field.name,
																							'uuid',
																						]),
																						'startTime'
																					)
																				)
																					? moment(
																							renderRefundData(
																								form.getFieldValue([
																									'refundData',
																									field.name,
																									'uuid',
																								]),
																								'startTime'
																							)
																					  ).format('YYYY-MM-DD')
																					: ''}
																				-
																				{DAL.dal.isNotNull(
																					renderRefundData(
																						form.getFieldValue([
																							'refundData',
																							field.name,
																							'uuid',
																						]),
																						'endTime'
																					)
																				)
																					? moment(
																							renderRefundData(
																								form.getFieldValue([
																									'refundData',
																									field.name,
																									'uuid',
																								]),
																								'endTime'
																							)
																					  ).format('YYYY-MM-DD')
																					: ''}
																			</Form.Item>
																		}
																	</td>
																	<td style={{ flex: 1, display: 'flex' }}>
																		<Form.Item
																			{...field}
																			// label={'收费类型'}
																			name={[field.name, 'name']}
																			fieldKey={[field.fieldKey, 'name']}
																		>
																			{renderRefundData(
																				form.getFieldValue([
																					'refundData',
																					field.name,
																					'uuid',
																				]),
																				'name'
																			)}
																		</Form.Item>
																	</td>
																	<td style={{ flex: 1, display: 'flex' }}>
																		<Form.Item
																			{...field}
																			// label={'收费类型'}
																			name={[field.name, 'price']}
																			fieldKey={[field.fieldKey, 'price']}
																		>
																			{(
																				parseInt(
																					renderRefundData(
																						form.getFieldValue([
																							'refundData',
																							field.name,
																							'uuid',
																						]),
																						'actualAmount'
																					)
																				) / 100
																			).toFixed(2)}
																		</Form.Item>
																	</td>
																	<td style={{ flex: 1, display: 'flex' }}>
																		<Form.Item
																			{...field}
																			// label={'收费类型'}
																			name={[field.name, 'refundPrice']}
																			fieldKey={[field.fieldKey, 'refundPrice']}
																			rules={[
																				{
																					required: true,
																					message: '请输入退款金额',
																				},
																			]}
																		>
																			<InputNumber
																				min={0}
																				step={'0.01'}
																			></InputNumber>
																		</Form.Item>
																		{/* <Button
																			type="primary"
																			onClick={() => {
																				const endTime = form.getFieldValue("endTime")
																				if (!DAL.dal.isNotNull(endTime)) {
																					message.warn("请选择截止时间")
																					return
																				}
																				const price = (
																					parseInt(
																						renderRefundData(
																							form.getFieldValue([
																								'refundData',
																								field.name,
																								'uuid',
																							]),
																							'actualAmount'
																						)
																					) / 100
																				).toFixed(2);
																				const refundDataList: any =
																					form.getFieldValue('refundData') ||
																					[];
																				refundDataList[
																					index
																				].refundPrice = price;

																				form.setFieldsValue({
																					refundData: refundDataList,
																				});
																			}}
																			style={{ marginLeft: 10 }}
																		>
																			退还计算
																		</Button> */}
																	</td>
																</tr>
															</>
														))}
													</>
												)}
											</Form.List>
										)}
									</Form.Item>
								</tbody>
								{/* ))} */}
							</table>
						</div>
					</div>
				</Form>
			</Modal>
		</div>
	);
};
export default BillRefundList;
