import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Card, Col, DatePicker, Input, Row, Select, Statistic, Tooltip, TreeSelect } from 'antd';
import GSTable from '../../../components/GSTable';
import { getAllRecord, getAuthRecord, getDevices, getAuthRecordTop } from '../../../services/ZhiNengMenJinService';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import moment from 'moment';
import CustomRangePicker from '../../../components/CustomRangePicker';
import { TreeNode } from 'antd/lib/tree-select';
import GsTreeSelect from '../../../components/GsTreeSelect';
import AuthorizedView from '../../../util/AuthorizedView';

let msg = require('../../../util/SuspensionMsg');
const Search = Input.Search;
const Option = Select.Option;
let { RangePicker } = DatePicker;

const AuthRecord: React.FC = (props: any) => {
	useEffect(() => {
		getUserDevice();
	}, []);

	const actionRef = useRef<ActionType>();
	const [userDevices, setUserDevices] = useState('');
	const [Stime, setStime] = useState(
		moment().subtract(7, 'd').hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:00')
	);
	const [Etime, setEtime] = useState(moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:59'));
	const [phone, setPhone] = useState('');
	const [receivePhone, setReceivePhone] = useState('');

	const [event, setEvent] = useState('全部事件');
	const [events, setEvents] = useState('全部事件');
	const [selectionDates, setSelectionDates] = useState('最近7天');
	const [selectionDate, setSelectionDate] = useState('最近7天');
	const [userdevice, setUserdevice] = useState([]);
	const [selectState, setSelectState] = useState('2');
	const [topData, setTopData] = useState({ totalNum: 0, authUserNum: 0 });

	const columns = [
		{
			title: '操作者',
			width:150,
			dataIndex: 'nickName',
			render: (value: string, record: any) => {
				if (record.realName) {
					return (
						<span>
							{record.nickName}(姓名：{record.realName})
						</span>
					);
				}
				return <span>{record.nickName}</span>;
			}
		},
		{
			title: '操作者账号',
			width:150,
			dataIndex: 'email'
		},
		{
			title: '接收者',
			width:150,
			dataIndex: 'receiver',
			render: (value: string, record: any) => {
				if (record.receiveRealName) {
					return (
						<span>
							{record.receiver}(姓名：{record.receiveRealName})
						</span>
					);
				}
				return <span>{record.receiver}</span>;
			}
		},
		{
			title: '接收者账号',
			width:150,
			dataIndex: 'receiverAccount'
		},
		{
			title: '设备名称',
			width:150,
			dataIndex: 'deviceName'
		},
		{
			title: '设备ID',
			width:150,
			dataIndex: 'deviceId'
		},

		{
			title: '事件',
			width:150,
            fixed: 'right',
            dataIndex: 'event'
		},
		{
			title: '时间',
			width:150,
			fixed: 'right',
			dataIndex: 'date'
		}
	];

	const getUserDevice = async () => {
		const res = await getDevices({});
		if (res.err !== 0) {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// res.data.map((item: any) => {
		// 	item["selectable"] = false
		// })
		setUserdevice(res.data);
	};

	/** 选择时间 */
	const selectionDates1 = (ev: any) => {
		if (ev === '自定义') {
			setSelectState('1');
		} else {
			setSelectState('2');
			setEtime('');
			setStime('');
		}
		setSelectionDate(ev);
		setSelectionDates(ev);
	};

	const dateTime = (startTime: any, endTime: any) => {
		setStime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEtime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};

	const getInputStr = (ev: any) => {
		setPhone(ev.target.value);
	};
	const tableSearchBarRender = () => [
		<GsTreeSelect
			// key={this.props.deviceId === undefined ? '' : this.props.deviceId}
			treeData={userdevice}
			multiple={true}
			onChange={(value: any) => {
				setUserDevices(value);
			}}
		>
		</GsTreeSelect>,
		<Select
			onChange={(value: any) => {
				setEvents(value);
			}}
			defaultValue={'全部事件'}
		>
			<Select.Option value={'全部事件'}>全部事件</Select.Option>
			<Select.Option value={'发送钥匙'}>发送钥匙</Select.Option>
			<Select.Option value={'删除钥匙'}>删除钥匙</Select.Option>
			<Select.Option value={'钥匙延期'}>延期钥匙</Select.Option>
			<Select.Option value={'钥匙冻结'}>冻结钥匙</Select.Option>
			<Select.Option value={'钥匙解除冻结'}>激活钥匙</Select.Option>
			<Select.Option value={'接收钥匙'}>接收钥匙</Select.Option>
		</Select>,
		<CustomRangePicker onChange={dateTime}/>,
		<Input placeholder={'操作者账号，姓名'} onInput={getInputStr} value={phone}/>,
		<Input
			placeholder={'接收者账号，姓名'}
			onChange={(e: any) => {
				setReceivePhone(e.target.value);
			}}
			value={receivePhone}
		/>,
		<Button onClick={() => actionRef.current.reloadAndRest()} type={'primary'}>
			查询
		</Button>
	];

	const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>{element}</div>
				{
					msg && <div>
						<Tooltip title={msg}>
							<ExclamationCircleOutlined style={iconStyle}/>
						</Tooltip>
					</div>
				}

			</div>
		);
	};
	const tableExtraRender = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>授权人数</span>,
								''
							)}
							value={topData.authUserNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>人</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>授权钥匙总数</span>,
								''
							)}
							value={topData.totalNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>个</span>}
						/>
					</Card>
				</Col>
			</Row>
		);
	};
	const tableOperationsBarRender = () => [
		<AuthorizedView needAuthority={'entrance_guard:send_key_record:select'}>
			<Button type={'primary'}>
				<a target={'_black'} href={'key_send_record'}>
					授权日志
				</a>
			</Button>
		</AuthorizedView>
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				tableExtraRender={tableExtraRender}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data: any = {};
					if (userDevices !== null && userDevices !== undefined) {
						(data as any).device_id = userDevices.toString();
					}
					if (Stime !== null && Stime !== undefined) {
						(data as any).Stime = Stime;
					}
					if (Etime !== null && Etime !== undefined) {
						(data as any).Etime = Etime;
					}
					if (phone !== null && phone !== undefined) {
						(data as any).phone = phone;
					}
					(data as any).event = events;
					(data as any).type = '授权';
					(data as any).selectionDates = selectionDates;
					(data as any).page = params.current;
					(data as any).size = params.pageSize;
					data.receivePhone = receivePhone;
					getAuthRecordTop(data).then((rsp: any) => {
						if (rsp.err === 0) {
							setTopData(rsp.data);
						}
					});
					let res = await getAuthRecord(data);

					if (res.err !== 0) {
						return {
							data: [],
							total: 0
						};
					}
					return {
						data: res.records,
						total: res.count,
						success: true
					};
				}}
			/>
		</div>
	);
};
export default AuthRecord;
