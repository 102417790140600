import Api from '../data/API';

export async function getOrderInvoiceList(params: any) {
	return Api.postWithAuth('/api/invoice/getOrderInvoiceList', params);
}
export async function updateOrderInvoiceState(params: any) {
	return Api.postWithAuth('/api/invoice/updateOrderInvoiceState', params);
}
export async function getOrderInfo(params: any) {
    return Api.postWithAuth('/api/order/getOrderInfo', params);
}
