import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import {
	findSmsRecord,
	findSmsSendDetailListByBatchId,
	getSendKeyRecordList
} from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Input, Modal, Select, Tag } from 'antd';
import * as moment from 'moment';
interface SmsDetailPro {
	batchUuid: any;
}
const SendKeyDetail: React.FC<SmsDetailPro> = (props: any) => {
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const [receivePhone, setReceivePhone] = useState('');
	const [status, setStatus] = useState('');
	const columns = [
		{
			title: '手机号',
			dataIndex: 'receivePhone',
			key: 'receivePhone',
		},
		{
			title: '姓名',
			dataIndex: 'receiveName',
			key: 'receiveName',
		},
		{
			title: '设备名称',
			dataIndex: 'deviceName',
			key: 'deviceName',
		},
		{
			title: '设备ID',
			dataIndex: 'deviceId',
			key: 'deviceId',
		},
		{
			title: '接收时间',
			dataIndex: 'receiveTime',
			key: 'receiveTime',
			width: 150,
			render: (text: string, record: any) => (
				<span>
					{record.receiveTime !== undefined && record.receiveTime !== '' && record.receiveTime !== null
						? moment(text).format('YYYY-MM-DD HH:mm:ss')
						: '-'}
				</span>
			),
		},
		{
			title: '状态',
			key: 'status',
			dataIndex: 'status',
			render: (text: string, record: any) => (
				<Tag color={text === '成功' ? 'green' : 'red'}>
					{text == '成功' ? '成功' : '失败' }
				</Tag>
			),
		},
		{
			title: '描述',
			key: 'note',
			dataIndex: 'note',
		},
	];

	const tableSearchBarRender = () => [
		<Select
			defaultValue={status}
			onChange={(value:any) => {
				setStatus(value)
				actionRef.current.reloadAndRest();
			}}
		>
			<Select.Option key={''} value={''}>
				全部
			</Select.Option>
			<Select.Option key={'成功'} value={'成功'}>
				成功
			</Select.Option>
			<Select.Option key={'失败'} value={'失败'}>
				失败
			</Select.Option>
		</Select>,
		<Search
			placeholder="手机号"
			onSearch={(value) => {
				setReceivePhone(value);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
			enterButton={true}
		/>,
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getSendKeyRecordList({
						page: params.current,
						size: params.pageSize,
						batchUuid: props.batchUuid,
						receivePhone:receivePhone,
						status:status
					});
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
		</div>
	);
};

export default SendKeyDetail;
