import Api from '../data/API';

export async function getMarketChanceList(params: any) {
	return Api.postWithAuth('/adminapi/marketChance/getMarketChanceList', params);
}
export async function addMarketChance(params: any) {
	return Api.postWithAuth('/adminapi/marketChance/addMarketChance', params);
}
export async function updateMarketChance(params: any) {
	return Api.postWithAuth('/adminapi/marketChance/updateMarketChance', params);
}
export async function deleteMarketChance(params: any) {
	return Api.postWithAuth('/adminapi/marketChance/deleteMarketChance', params);
}

