import React from "react";

interface InformationDesensitizationProps {
    type: string
    value: string
}

const InformationDesensitization: React.FC<InformationDesensitizationProps> = ({ type, value }) => {
    const onlySeeSome = (str: string) => {
        //str文本-type类型：name/姓名；phone/手机号
        //脱敏
        console.log('脱敏', type, str);
        if (type == "name") {
            return new Array(str?.length).join("*") + str?.substr(-1);
        } else if (type == "phone") {
            return str?.replace(/(\d{3})\d*(\d{4})/, "$1****$2");
        } else if (type == "cardIdNum") {
            if (str != null && str.length === 18) {
                return str?.replace(/(\d{2})\d{14}(\w{2})/,"$1************$2");
            } else if(str != null && str.length == 15){
                return str?.replace(/(\d{2})\d{11}(\w{2})/,"$1*********$2");
            }else{
                return str;
            }
        }
        return str;
    };

    return (
        <span>
            {
                onlySeeSome(value)
            }
        </span>
    );
};
export default InformationDesensitization;
