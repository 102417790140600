import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Form, Input, message, Modal, Switch, Tag, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';

import { Link } from 'react-router-dom';
import {
	findWenzhangTypeList,
	addWenzhangType,
	changeLeixingState,
	updateWenzhangType,
	deleteWenzhangType,
	findWenzhangTagList,
	updateWenzhangTag,
	addWenzhangTag,
	deleteWenzhangTag,
} from '../../../services/WenzhangService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons/lib';
let moment = require('moment');
var msg = require('../../../util/SuspensionMsg');
const LookWenzhangTag: React.FC = () => {
	const { confirm } = Modal;
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const Search = Input.Search;
	const [searchType, setSearchType] = useState('');
	const [update, setUpdate] = useState(false);
	const [visible, setVisible] = useState(false);

	const columns = [
		{
			title: '标签名称',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						updateModal(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '状态',
			tip: '标签停用后，前端展示的文章将不显示该标签',
			dataIndex: 'status',
			key: 'status',
			render: (text: string, record: any) => (
				<Tag color={record.status ? 'green' : 'red'}>{record.status ? '开启' : '停用'}</Tag>
			),
			// render: (text: string, record: any) => (
			// 	<Switch
			// 		defaultChecked={record.status}
			// 		onChange={() => {
			// 			ChangeState(record);
			// 		}}
			// 	/>
			// ),
		},
		// {
		// 	title: '创建时间',
		// 	dataIndex: 'createTime',
		// 	key: 'createTime',
		// 	render: (text: string, record: any) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
		// },
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<a
						onClick={() => {
							ChangeState(record);
						}}
					>
						{record.status ? '停用' : '开启'}
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];

	const deleteItem = (value: any) => {
		if (value.cateType !== '系统') {
			confirm({
				title: '删除类目',
				icon: <ExclamationCircleOutlined />,
				content: '您确定删除吗',
				okType: 'danger',
				onOk: async () => {
					const res = await deleteWenzhangTag({ id: value.id });
					if (res.err === 0) {
						message.success('删除成功');
						if (actionRef.current) {
							actionRef.current.reload();
						}
					} else {
						message.error('删除失败');
					}
				},
				onCancel() {
					console.log('Cancel');
				},
			});
		}
	};

	const updateModal = (value: any) => {
		setUpdate(true);
		const formData: any = {
			name: value.name,
			id: value.id,
			status: value.status,
		};
		form.setFieldsValue(formData);
		setVisible(true);
	};
	const ChangeState = async (value: any) => {
		if (value.status) {
			value.status = false;
		} else {
			value.status = true;
		}
		const res = await updateWenzhangTag({
			id: value.id,
			status: value.status,
		});
		if (res.err === 0) {
			message.success('修改成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '失败', type: 'error' });
		}
	};
	const tableSearchBarRender = () => [
		<Input
			placeholder="搜索名称"
			onChange={(event) => {
				setSearchType(event.target.value);
			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	const xinjian = () => {
		form.resetFields();
		setUpdate(false);
		setVisible(true);
	};

	const handleOk = () => {
		if (update) {
			updateItem();
		} else {
			callData();
		}
	};

	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				id: data.id,
				name: data.name,
				status: data.status,
			};
			const res = await updateWenzhangTag(formData);
			console.log('', res);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
				setVisible(false);
			} else if (res.err === -2) {
				message.warning(res.message);
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
		});
	};

	const callData = async () => {
		form.validateFields().then(async (data: any) => {
			const res = await addWenzhangTag({
				name: data.name,
				status: data.status,
			});
			if (res.err === 0) {
				message.success('操作成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else if (res.err === -2) {
				message.warning(res.message);
			} else {
				message.error('操作失败');
			}
		});
	};

	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={xinjian}>
			新建标签
		</Button>,
	];

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await findWenzhangTagList({
						page: params.current,
						size: params.pageSize,
						wyUuid: localStorage.getItem('wyUuid'),
						name: searchType,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>

			<Modal
				title={update ? '编辑' : '新增'}
				visible={visible}
				onOk={handleOk}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'id'} name={'id'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'名称'} name={'name'} rules={[{ required: true }]}>
						<Input placeholder={'请输入名称'} />
					</FormItem>
					<FormItem
						tooltip={'标签停用后，前端展示的文章将不显示该标签'}
						label={'状态'}
						name={'status'}
						valuePropName={'checked'}
					>
						<Switch />
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default LookWenzhangTag;
