import { Form, FormInstance, Input, InputNumber, Select } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormContext } from '../../../components/FormContext';
import DAL from '../../../data/DAL';
import { getWxBusinessList } from '../../../services/Wx';
import { checkFeeTypeTypeCode } from '../../../services/PayCostManagementService';
import getBillTypes from '../models/BillTypes';
interface Params {
	required: Boolean;
	billCycle: string;
	receviableTypes: any;
	feeType: any;
}
const ChargeTypeComponents: React.FC<Params> = (props: any) => {
	useEffect(() => {
		getWxList().then();
	}, []);
	const [wxBusinessList, setwxBusinessList] = useState([]);
	const getWxList = async () => {
		const res = await getWxBusinessList({});
		if (res.err == 0) {
			setwxBusinessList(res.data || []);
		}
	};

	const checkedTypeCode = async (value) => {};
	const FormItem = Form.Item;
	return (
		<>
			<FormItem
				required={props.required}
				validateTrigger={props.required ? 'onBlur' : 'onChange'}
				name={'typeCode'}
				label={'编码'}
				rules={[
					// {required: props.required},
					({ getFieldValue }) => ({
						validator: async (_, value) => {
							if (props.required) {
								if (!DAL.dal.isNotNull(value)) {
									return Promise.reject(new Error('请填写编码'));
								} else {
									const res = await checkFeeTypeTypeCode({ typeCode: value });
									if (res.err == 0) {
										const data = res.data || {};
										if (DAL.dal.isNotNull(data.uuid)) {
											return Promise.reject(new Error('编码重复'));
										} else {
											return Promise.resolve();
										}
									} else {
										return Promise.resolve();
									}
								}
							} else {
								return Promise.resolve();
							}
						},
					}),
				]}
			>
				<Input style={{ width: '100%' }}></Input>
			</FormItem>
			<FormItem
				name={'name'}
				label={'收费项目名称'}
				rules={[{ required: props.required, message: '请填写收费项目名称' }]}
			>
				{'仪表类' == props.feeType ? (
					<Select>
						{getBillTypes().meterReadingTypes.map((item) => {
							return (
								<>
									<Select.Option value={item.value}>{item.name}</Select.Option>
								</>
							);
						})}
					</Select>
				) : (
					<Input></Input>
				)}
			</FormItem>
			<Form.Item required={props.required} label="应收日期">
				<Input.Group compact>
					<Form.Item
						name={'receivableType'}
						noStyle
						rules={[{ required: props.required, message: '请选择应收日期类型' }]}
					>
						<Select style={{ width: '50%' }}>
							{props.receviableTypes.map((item) => {
								return (
									<>
										<Select.Option value={item.value}> {item.name}</Select.Option>
									</>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item
						name={'receivableDate'}
						noStyle
						rules={[{ required: props.required, message: '请输入天数' }]}
					>
						<InputNumber  min={0} style={{ width: '50%' }} />
					</Form.Item>
				</Input.Group>
			</Form.Item>
		</>
	);
};
export default ChargeTypeComponents;
