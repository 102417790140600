import React, {useCallback, useEffect, useRef, useState} from 'react';
import GSTable from '../../../components/GSTable';
import {ActionType} from '@ant-design/pro-table';
import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Space, Switch} from 'antd';
import {FormInstance} from 'antd/es/form';
import {
    addProductCate,
    deleteProductCate, getProductCategoryPerm,
    getProductCateList, setProductCategoryPerm,
    sortProductCate,
    updateProductCate,
} from '../../../services/Product';
import {DndProvider} from 'react-dnd';
import HTMLBackend from 'react-dnd-html5-backend';
import DragableBodyRow from '../../../components/DragablePIdBodyRow';
import update from 'immutability-helper';
import {findContractPermGroup} from "../../../services/Contract";
import {orderKindSetPerm} from "../../../services/orderService";
import AuthorizedView from "../../../util/AuthorizedView";

const ProductCate: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const FormItem = Form.Item;
    const [permForm] = Form.useForm();
    const [form] = Form.useForm<FormInstance>();
    const Option = Select.Option;
    const [sortVisible, setSortVisible] = useState(false);
    const [add, setAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const actionRef = useRef<ActionType>();
    const actionRef1 = useRef<ActionType>();
    const [isSon, setIsSon] = useState(false)
    const [sortData, setSortData] = useState<any>();
    const [permVisible, setPermVisible] = useState(false);
    const [permContractList, setPermContractList] = useState<any>([]);
    useEffect(() => {
        getPermContractList().then()
    }, [])
    const getPermContractList = async () => {
        const res = await findContractPermGroup({type: '产品'});
        if (res.err == 0) {
            setPermContractList([...res.data]);
            let tempEnum: any = {};
            res.data.map((item: any) => {
                tempEnum[item.id + ""] = item.name;
            })
        }
    };

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (_: any, item: any) => (
                <a
                    onClick={() => {
                        showModel(item);
                    }}
                >
                    {item.name}
                </a>
            ),
        },
        {
            title: '操作',
            hideInSearch: true,
            render: (text: any, item: any) => {
                return (
                    <Space>
                        {item.pId == 0 && (
                            <>
                                <AuthorizedView needAuthority={'product_cate_list:add'}>
                                    <a
                                        onClick={() => {
                                            openSonAddModal(item);
                                        }}
                                    >
                                        添加子类目
                                    </a>
                                </AuthorizedView>
                            </>
                        )}
                        <AuthorizedView needAuthority={'product_list:setperm'}>
                            <a onClick={async () => {
                                setPermVisible(true)
                                permForm.resetFields();
                                let rsp = await getProductCategoryPerm({uuid: item.uuid})
                                if (rsp.err === 0) {
                                    permForm.setFieldsValue({uuid: item.uuid, permId: rsp.data})
                                }

                            }}>设置权限分组</a>
                        </AuthorizedView>
                       <AuthorizedView needAuthority={'product_cate_list:delete'}>
                           <a
                               onClick={() => {
                                   deleteItem(item);
                               }}
                           >
                               删除
                           </a>
                       </AuthorizedView>

                    </Space>
                );
            },
        },
    ];
    const columns1 = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
    ];
    const openSonAddModal = (item: any) => {
        form.resetFields();
        const formData: any = {
            pId: item.id,
            level: 2,
        };
        form.setFieldsValue(formData);
        setIsSon(true)
        setAdd(true);
        setVisible(true);
    };
    const deleteItem = (item: any) => {
        Modal.confirm({
            content: '您确定要删除' + item.name + '吗？',
            okType: 'danger',
            onOk: async () => {
                const res = await deleteProductCate({uuid: item.uuid});
                if (res.err === 0) {
                    message.success('删除成功');
                    if (actionRef.current) {
                        actionRef.current.reload();
                    }
                } else {
                    message.error('删除失败');
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const showModel = (item: any) => {
        console.log('item:::', item);
        const formData: any = {
            uuid: item.uuid,
            name: item.name,
            layoutType: item.layoutType,
            search: item.search !== undefined && item.search !== null && item.search !== '' ? item.search : false
        };
        if (item.level == 2) {
            setIsSon(true)
        } else {
            setIsSon(false)
        }
        form.setFieldsValue(formData);
        setAdd(false);
        setVisible(true);
    };
    const getList = async (param: any) => {
        let rsp = await getProductCateList({});
        if (rsp.err === 0) {
            setSortData([...rsp.data]);
            return {
                data: rsp.data,
            };
        } else {
            return {
                data: [],
            };
        }
    };
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };
    const tableOperationsBarRender = () => [
        <Space>
            <AuthorizedView needAuthority={'product_cate_list:add'}>
                <Button type={'primary'} onClick={openAddModal}>
                    添加
                </Button>
            </AuthorizedView>
            <Button
                type={'primary'}
                onClick={() => {
                    setSortVisible(true);
                    if (actionRef1.current) {
                        actionRef1.current.reload();
                    }
                }}
            >
                编辑排序
            </Button>
        </Space>,
    ];
    const openAddModal = () => {
        form.resetFields();
        const formData: any = {
            level: 1,
            pId: 0,
        };
        form.setFieldsValue(formData);
        setIsSon(false)
        setAdd(true);
        setVisible(true);
    };
    const saveItem = () => {
        if (add) {
            addItem();
        } else {
            updateItem();
        }
    };
    const addItem = () => {
        form.validateFields().then(async (data: any) => {
            const formData: any = {
                name: data.name,
                sortBy: 99,
                level: data.level,
                pId: data.pId,
                layoutType: data.layoutType,
                search: data.search !== undefined && data.search !== null && data.search !== '' ? data.search : false
            };
            const res = await addProductCate(formData);
            if (res.err === 0) {
                message.success('添加成功');
                setVisible(false);
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            } else {
                message.error('添加失败');
            }
        });
    };
    const updateItem = () => {
        form.validateFields().then(async (data: any) => {
            const formData: any = {
                uuid: data.uuid,
                name: data.name,
                layoutType: data.layoutType,
                search: data.search !== undefined && data.search !== null && data.search !== '' ? data.search : false
            };
            const res = await updateProductCate(formData);
            if (res.err === 0) {
                message.success('修改成功');
                setVisible(false);
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            } else {
                message.error('修改失败');
            }
        });
    };
    const components = {
        body: {
            row: DragableBodyRow,
        },
    };
    const getData = () => {
        if (sortData.length > 1) {
            sortData.sort((a: any, b: any) => {
                return a.sortBy - b.sortBy;
            });
            sortData.map((item: any) => {
                sortNumber(item);
            });
        }
        return {
            data: sortData,
        };
    };
    const moveRow = useCallback(
        async (dragIndex, hoverIndex, key) => {
            if (dragIndex === hoverIndex) {
                return;
            }
            findArray(sortData, key, dragIndex, hoverIndex);
        },
        [sortData]
    );
    const sortNumber = (data: any) => {
        if (data.children) {
            data.children.sort((a: any, b: any) => {
                return a.sortBy - b.sortBy;
            });
            data.children.map((item: any) => {
                sortNumber(item);
            });
        } else {
        }
    };
    const saveSortData = async () => {
        setLoading(true);
        let res = await sortProductCate({data: JSON.stringify(sortData)});
        setLoading(false);
        if (res.err === 0) {
            message.success('排序成功');
            setSortVisible(false);
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error('排序失败');
        }
    };
    const findArray = (data: any, id: any, dragIndex: number, hoverIndex: any) => {
        console.log('data:::', data);
        data.map(async (item: any) => {
            if (item.id === id) {
                const dragRow = data[dragIndex];
                let newArr = update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                });
                newArr.map((item: any, index: number) => {
                    item.sortBy = index;
                });
                const sortArr = newArr.sort((a: any, b: any) => {
                    return a.sortBy - b.sortBy;
                });
                data = [...sortArr];
                console.log('newArr::', sortArr);
                setSortData([...sortData]);
                // let rsp = await updatePermItemOrder(params);
                // if (rsp.err === 0) {
                if (actionRef1.current) {
                    actionRef1.current.reload();
                }
                // }
                return;
            }
            if (item.children) {
                findArray(item.children, id, dragIndex, hoverIndex);
            }
        });
    };
    const setPermReq = () => {
        permForm.validateFields().then(async (data: any) => {
            setLoading(true)
            const res = await setProductCategoryPerm({ ...data});
            setLoading(false)
            if (res.err == 0) {
                message.success('设置成功');
                setPermVisible(false);
                permForm.resetFields();
                actionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    }
    return (
        <>
            <Row gutter={8} justify={'center'} align={'middle'}>
                <Col span={24}>
                    <GSTable
                        rowKey="uuid"
                        operationsBarRender={tableOperationsBarRender()}
                        actionRef={actionRef}
                        pagination={false}
                        columns={columns}
                        request={(params: any) => {
                            return getList(params);
                        }}
                    />
                </Col>
            </Row>
            <Modal
                title={add ? '添加' : '修改'}
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={saveItem}
            >
                <Form form={form} {...formItemLayout}>
                    <FormItem name={'uuid'} hidden={true}>
                        <Input/>
                    </FormItem>
                    <FormItem name={'level'} hidden={true}>
                        <Input/>
                    </FormItem>
                    <FormItem name={'pId'} hidden={true}>
                        <Input/>
                    </FormItem>
                    <FormItem name={'name'} label={'名称'} rules={[{required: true}]}>
                        <Input/>
                    </FormItem>
                    <FormItem hidden={isSon} label={"布局类型"} name="layoutType">
                        <Select>
                            <Select.Option value="1col">1col</Select.Option>
                            <Select.Option value="2col">2col</Select.Option>
                        </Select>
                    </FormItem>
                    <FormItem hidden={isSon} label={"支持搜索"} valuePropName='checked'
                              tooltip={"开启可在列表页面搜索产品"} name="search">
                        <Switch/>
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                confirmLoading={loading}
                onOk={saveSortData}
                title={'排序'}
                visible={sortVisible}
                onCancel={() => {
                    setSortVisible(false);
                }}
            >
                <DndProvider backend={HTMLBackend}>
                    <GSTable
                        pagination={false}
                        actionRef={actionRef1}
                        components={components}
                        onRow={(record: any, index: any) => ({
                            index,
                            moveRow,
                        })}
                        columns={columns1}
                        request={getData}
                        rowKey={'id'}
                    />
                </DndProvider>
            </Modal>
            <Modal
                title={'设置权限分组'}
                visible={permVisible}
                onOk={() => {
                    setPermReq()
                }}
                confirmLoading={loading}
                onCancel={() => {
                    setPermVisible(false);
                }}
            >
                <Form form={permForm} labelCol={{span: 4}}>
                    <Form.Item name={'uuid'} hidden={true}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item name={'permId'} label={'分组'}>
                        <Select mode="multiple" showSearch optionFilterProp="children">
                            {permContractList.map((item: any) => {
                                return (
                                    <>
                                        <Select.Option value={item.id + ''}>{item.name}</Select.Option>
                                    </>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default ProductCate;
