import * as React from 'react';
import MeetingOrderList from './pages/MeetingOrderList';
import Meeting from './pages/Meeting';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import MeetingDetail from './components/MeetingDetail';

export default function MeetingMiniApp() {
	const rootUrl = '/meeting';
	const menus: MiniAppMenu[] = [
		{
			authority: 'management_meetings:meeting_room_list',
			name: '会议室列表',
			path: 'meeting',
			component: Meeting,
		},
		{
			authority: 'management_meetings:order_list',
			name: '查看订单',
			path: 'order_list',
			component: MeetingOrderList,
		},
	];
	const routes: MiniAppRoute[] = [
		{
			path: 'meeting-detail',
			component: MeetingDetail,
		},
		{
			path: 'meeting_info',
			component: MeetingDetail,
		},
		{
			path: 'meeting_imgs',
			component: MeetingDetail,
		},
		{
			path: 'order_time',
			component: MeetingDetail,
		},
	];

	return <MiniApp miniAppName={'会议室'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
