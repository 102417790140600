import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import CallerList from './pages/CallerList';

export default function CallerMiniApp() {
	const rootUrl = '/wy';
	const menus: MiniAppMenu[] = [
		{
			authority: 'visitor_management:fangke_guanli',
			name: '访客人员信息',
			path: 'fangke-guanli',
			component: CallerList,
		},
	];

	return <MiniApp miniAppName={'访客管理'} rootUrl={rootUrl} menus={menus} />;
}
