import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import Questionnaire from './Questionnaire';
import AddQuestionnaire from './AddQuestionnaire';
import QuestionnaireManger from './QuestionnaireManger';
import QuestionnaireData from './QuestionnaireData';

export default function QuestionnaireMiniApp() {
	const rootUrl = '/questionnaire';
	const menus: MiniAppMenu[] = [
		{
			authority: 'questionnaire_investigation:questionnaire',
			name: '问卷调查',
			path: 'questionnaire',
			component: Questionnaire,
		},
	];

	const routes: MiniAppRoute[] = [
		{
			path: 'add_questionnaire',
			component: AddQuestionnaire,
		},
		{
			path: 'questionnaire_manger',
			component: QuestionnaireManger,
		},
		{
			path: 'questionnaire_data',
			component: QuestionnaireData,
		},
	];
	return <MiniApp miniAppName={'问卷调查'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
