import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import MarketChanceList from './pages/MarketChanceList';
import ScenarioList from './pages/ScenarioList';
import ScenarioTypeList from './pages/ScenarioTypeList';
import SchemeTechList from './pages/SchemeTechList';
import SchemeTypeList from './pages/SchemeTypeList';
import SzStatic from './pages/SzStatic';
import TalentList from './pages/TalentList';
import ChannelList from './pages/ChannelList';
import UserList from './pages/UserList';
import CompanyList from './pages/CompanyList';
import SzgOrderList from './pages/SzgOrderList';
import InstitutesList from "./pages/InstitutesList";
import achievementList from "./pages/AchievementList";
import WhitePaperList from "./pages/WhitePaperList";

export default function ScenarioMiniApp() {
	const rootUrl = '/scenario';
	const menus: MiniAppMenu[] = [
		{
            authority: 'sz_scheme_list',
			name: '产品方案',
			path: 'list',
			component: ScenarioList,
		},
		{
			authority: 'sz_talent',
			name: '智库人才',
			path: 'talent',
			component:TalentList,
		},
		{
			authority: 'sz_market_chance',
			name: '市场机会',
			path: 'marketChance',
			component:MarketChanceList,
		},
		{
			authority: 'sz_scheme_type',
			name: '应用场景',
			path: 'typeList',
			component: SchemeTypeList,
			// component: ScenarioTypeList,
		},
		{
			authority: 'sz_scheme_tech',
			name: '通用技术',
			path: 'tech',
			component: SchemeTechList,
		},
		{
			authority: 'szg_vip_manager',
			name: '会员管理',
			path: 'vipManage',
			component: CompanyList,
		},{
			authority: 'szj_order_list',
			name: '订单管理',
			path: 'orderManage',
			component: SzgOrderList,
		},
		{
			authority: 'channel:manager',
			name: '渠道管理',
			path: 'channel',
			component: ChannelList,
		},

		{
			authority: 'channel:manager:user',
			name: '用户列表',
			path: 'userList',
			component: UserList,
		},
		{
			authority: 'sz_static',
			name: '数据统计',
			path: 'sz_static',
			component:SzStatic,
		},
		{
			authority: 'sz:institutes:list',
			name: '科技研究所列表',
			path: 'institutesList',
			component: InstitutesList,
		},
		{
			authority: 'sz:achievement:list',
			name: '科技成果列表',
			path: 'achievementList',
			component: achievementList,
		},
		{
			authority: 'whitePaper:list',
			name: '行业研报列表',
			path: 'whitePaper',
			component: WhitePaperList,
		},
	];
	const routes: MiniAppRoute[] = [
		// {
		// 	path: 'room-and-household',
		// 	component: RoomAndHousehold,
		// },
		// {
		// 	path: 'build-layer-plan',
		// 	component: LayerPlan,
		// },
		// {
		// 	path: 'statistics',
		// 	component: KaimenData,
		// },
	];

	return <MiniApp miniAppName={'应用场景管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
