import * as React from "react";
import GSTable from "../../../components/GSTable";
import { Select, Input, Space, message, Spin, Button, TreeSelect } from "antd";
import { useRef, useState, useEffect } from "react";
import moment from "moment";
import { getAttendaceRecordList } from "../../../services/AttendanceService";
import { ActionType } from "@ant-design/pro-table";
import * as XLSX from "xlsx";
import CustomRangePicker from "../../../components/CustomRangePicker";
import { getAllDevicesGroup, getDevicesByAll } from "../../../services/ZhiNengMenJinService";
import GsTreeSelect from "../../../components/GsTreeSelect";

const AttendanceRecords: React.FC = () => {
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState("");

    const [startTime, setStartTime] = useState(moment().subtract(7, "d").hours(0).minute(0).second(0));
    const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
    const [deviceEnum, setDeviceEnum] = useState<any>({});
    const [deviceId, setDeviceId] = useState("");
    const [quanXuan, setQuanXuan] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        getSelectDeviceList().then();
    }, []);
    const column = [
        {
            title: "姓名",
            dataIndex: "name"
        },
        {
            title: "设备名称",
            dataIndex: "deviceId",
            valueEnum: deviceEnum
        },
        {
            title: "手机号",
            dataIndex: "account"
        },
        {
            title: "部门名称",
            dataIndex: "department"
        },
        {
            title: "身份证号",
            dataIndex: "idCard"
        },
        {
            title: "首次打卡时间",
            valueType: "dateTime",
            dataIndex: "firstTime"
        },
        {
            title: "最后打卡时间",
            valueType: "dateTime",
            dataIndex: "lastTime"
        },
        {
            title: "小时",
            dataIndex: "hours"
        },
        {
            title: "记录时间",
            valueType: "date",
            dataIndex: "createdTime"
        }
    ];

    const getSelectDeviceList = async () => {
        const res = await getAllDevicesGroup({typeCode: '0701'});
        if (res.err !== 0) {
            message.error(res.msg);
            return;
        }
        let data: any = [];
        data.push({key: '全部设备', title: '全部设备', value: ''});
        res.data.map((item: any) => {
            item["selectable"] = false
        })
        data = data.concat(res.data)
        setDevices(data);
        let valueEnum: any = {};
        res.data.map((item: any) => {
            if (item.children) {
                item.children.map((clidrenItem: any) => {
                    valueEnum[clidrenItem.value] = clidrenItem.title;
                })
            }
        })
        setDeviceEnum(valueEnum);
    };
    const tableSearchBarRender = () => [
        <GsTreeSelect
            treeCheckable={false}
            style={{ width: 200 }}
            placeholder={'全部设备'}
            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            treeData={devices}
            allowClear={false}
            treeNodeFilterProp={'title'}
            showSearch={true}
            defaultValue={deviceId}
            onChange={(ev: any) => {
                if (ev) {
                    setDeviceId(ev.toString());
                }
            }}
        />,
        <CustomRangePicker
            onChange={(startTime, endTime) => {
                setStartTime(startTime);
                setEndTime(endTime);
            }}
            startTime={startTime}
            endTime={endTime}
        />,
        <Input onChange={(e) => {
            setSearch(e.target.value);

        }}
               placeholder="姓名,账号,身份证号"
        />,
        <Button type={"primary"} onClick={() => {
            if (actionRef.current) {
                actionRef.current.reloadAndRest();
            }
        }}>查询</Button>
    ];

    const getData = async (param: any) => {
        console.log("state:::::", startTime.format("YYYY-MM-DD 00:00:00"), endTime.format("YYYY-MM-DD 23:59:59"));
        let data = {
            search: search,
            startTime: startTime.format("YYYY-MM-DD 00:00:00"),
            endTime: endTime.format("YYYY-MM-DD 23:59:59"),
            page: param.current,
            size: param.pageSize,
            deviceId: deviceId
        };
        let rsp = await getAttendaceRecordList(data);
        if (rsp.err === 0) {
            if (quanXuan) {
                let uuids: any = [];
                rsp.data.data.map((item: any) => {
                    uuids.push(item.uuid);
                });
                setSelectedRowKeys(uuids);
                setTotal(rsp.total);
            }
            setTotal(rsp.total);
            return {
                data: rsp.data,
                total: rsp.total
            };
        } else {
            return {
                data: [],
                total: 0
            };
        }
    };

    const exprotData = async (selectedRows: [any?]) => {
        console.log("selectedRows:::::", selectedRows);
        setLoading(true);
        if (quanXuan) {
            let data = {
                search: search,
                startTime: startTime.format("YYYY-MM-DD 00:00:00"),
                endTime: endTime.format("YYYY-MM-DD 23:59:59")
            };
            let rsp = await getAttendaceRecordList(data);
            if (rsp.err === 0) {
                setExprotData(rsp.data);
            } else {
                message.error(rsp.msg);
            }
        } else {
            setExprotData(selectedRows);
        }
        setLoading(false);
    };

    const setExprotData = (selectedRows: [any?]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        column.map((item) => {
            titles.push(item.title);
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data: [string?] = [];
            column.map((item) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
                        } else {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
                        }
                    } else {
                        data.push("");
                    }
                } else if (item.valueType === 'date') {
                    if (selectedRows[i][item.dataIndex]) {
                        data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD"));
                    } else {
                        data.push("")
                    }
                }  else if (item.valueType === "dateTime") {
                    if (selectedRows[i][item.dataIndex]) {
                        data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
                    } else {
                        data.push("")
                    }
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "考勤记录");

        /* save to file */
        XLSX.writeFile(wb, "考勤记录.xlsx");
    };


    return (
        <>
            <Spin spinning={loading}>
                <GSTable
                    actionRef={actionRef}
                    rowSelection={{
                        fixed: true,
                        columnWidth: 100,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedRowKeys: any, selectedRows: any) => {
                            setSelectedRowKeys(selectedRowKeys);
                            setQuanXuan(false);
                        },
                        selections: [
                            {
                                key: "SELECT_CURRENT_ALL",
                                text: "全选当前页面",
                                onSelect: (changableRowKeys: any) => {
                                    setQuanXuan(false);
                                    setSelectedRowKeys(changableRowKeys);
                                }
                            },
                            {
                                key: "SELECT_ALL",
                                text: "全选全部页面",
                                onSelect: (changableRowKeys: any) => {
                                    setQuanXuan(true);
                                    setSelectedRowKeys(changableRowKeys);
                                }
                            }
                        ]
                    }}
                    tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                        return (
                            <Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
                                        style={{ marginLeft: 8 }}
                                        onClick={() => {
                                            onCleanSelected();
                                            setQuanXuan(false);
                                        }}
                                    >
										取消选择
									</a>
								</span>
                            </Space>
                        );
                    }}
                    rowKey={'id'}
                    tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                        console.log("tableAlertOptionRender::::", selectedRows);
                        return (
                            <Space size={16}>
                                <a onClick={() => exprotData(selectedRows)}>导出数据</a>
                            </Space>
                        );
                    }}
                    searchBarRender={tableSearchBarRender()}
                    // tableExtraRender={tableExtraRender}
                    options={{ density: false }}
                    columns={column}
                    request={getData}
                />
            </Spin>
        </>
    );
};
export default AttendanceRecords;
