import * as React from 'react';

import DAL from '../../data/DAL';
import { Route, Switch, Redirect } from 'react-router-dom';

import LiuchengIframe from './LiuchengIframe';

class Liucheng extends React.Component {
	deviceId: string = '';
	dal = DAL.dal;

	constructor(props: any) {
		super(props);
		this.state = { ds: [], url: window.location.pathname };
	}

	componentWillMount() {
		console.log('liuCheng -> componentWillMount');
	}

	render() {
		return (
			<div>
				<Switch>
					<Route path="/liucheng" component={LiuchengIframe} />
					{/*<Redirect to="/liucheng/iframe" />*/}
					<Route render={() => <LiuchengIframe />} />
				</Switch>
			</div>
		);
	}
}

export default Liucheng;
