import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Input, message, Modal, Row, Select, Space, Statistic, Tooltip } from 'antd';
import { getRoomItemStatisticsData, getRoomItemStatisticsTotal} from '../../../services/StatisticsService';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { getItemTypeList, getRoomItemList } from '../../../services/ZhuhushenheService';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
const RoomItemReport:React.FC = () => {
	const actionRef = useRef<ActionType>();
	const infoActionRef = useRef<ActionType>();
	const [type, setType] = useState('');
	const [search, setSearch] = useState('');
	const [title, setTitle] = useState('');
	const [visible, setVisible] = useState(false);
	const [status, setStatus] = useState('');
	const [itemInfoUuid, setItemInfoUuid] = useState('');
	const [roomItemTotal, setRoomItemTotal] = useState({total: 0,damageTotal: 0, defectTotal: 0 })
	const [itemTypeList, setitemTypeList] = useState([])
	useEffect(() => {
		getTotal().then()
		getAllItemTypeList().then()
	}, [])
	const getAllItemTypeList =async () => {
		const res = await getItemTypeList({
		});
		if (res.err == 0) {
			setitemTypeList(res.data || [])
		}
	}
	const getTotal = async () => {
		let rsp = await getRoomItemStatisticsTotal({})
		if (rsp.err === 0) {
			let data = {
				total: rsp.total,
				damageTotal: rsp.damageTotal,
				defectTotal: rsp.defectTotal
			}
			setRoomItemTotal(data)
		}
	}
	const columns = [
		{
			title: '名称',
			width: 150,
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '品牌',
			dataIndex: 'brand',
			key: 'brand',
			width: 50,
		},
		{
			title: '型号/规格',
			dataIndex: 'model',
			key: 'model',
			width: 80
		},
		{
			title: '单价',
			dataIndex: 'price',
			valueType: 'money',
			key: 'price',
			width: 80,
		},
		{
			title: '物品总数',
			dataIndex: 'total',
			key: 'total',
			width: 100,
		},
		{
			title: '损坏物品',
			dataIndex: 'damage',
			key: 'damage',
			width: 100,

		}, {
			title: '缺失物品',
			dataIndex: 'defect',
			key: 'defect',
			width: 100,
		},
		{
			title: '损坏率',
			dataIndex: 'damage_rate',
			key: 'damage_rate',
			width: 80,
			render: (text: string, record: any) => {

				return <span>{(record.damage / record.total* 100 ).toFixed(2) }%</span>
			}
		},
		{
			title: '缺失率',
			dataIndex: 'defect_rate',
			key: 'defect_rate',
			width: 80,
			render: (text: string, record: any) => {

				return <span>{(record.defect / record.total* 100 ).toFixed(2) }%</span>
			}
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 150,
			render: (record: any) => (
				<Space>
					<a onClick={() => {
						setTitle('损坏列表')
						setItemInfoUuid(record.itemInfoUuid)
						setStatus('损坏')
						setVisible(true)
					}}>损坏列表</a>
					<a onClick={() => {
						setTitle('丢失列表')
						setItemInfoUuid(record.itemInfoUuid)
						setStatus('缺失')
						setVisible(true)
					}}>丢失列表</a>
				</Space>
			),
		},
	];
	const recordColumns = [
		{
			title: '资产名称',
			dataIndex: 'name',
			width: 100,
			key: 'name',
		},
		{
			title: '描述',
			dataIndex: 'note',
			width: 100,
			ellipsis: true,
			key: 'note',
		},
		{
			title: '位置',
			dataIndex: 'roomNumber',
			width: 100,
			key: 'roomNumber',
		},
		{
			title: '损坏数量',
			hideInTable: status === '缺失',
			dataIndex: 'damagedQuantity',
			width: 100,
			key: 'damagedQuantity',
		},
		{
			title: '缺失数量',
			hideInTable: status === '损坏',
			width: 100,

			dataIndex: 'missQuantity',
			key: 'missQuantity',
		},
		{
			title: '操作人',
			width: 100,
			dataIndex: 'opName',
			key: 'opName',
		},
		{
			title: '操作时间',
			width: 150,
			dataIndex: 'updatedTime',
			key: 'updatedTime',
			valueType: 'dateTime'
		}

	];
	const tableSearchBarRender = () => [
		<Select defaultValue={type} style={{width: 150}} onChange={(value: any) => {
			setType(value)
		}}>
			<Select.Option value={''}>类目</Select.Option>
			{itemTypeList.map(item => {
				return <>
					<Select.Option value={item.name}>{item.name}</Select.Option>
				</>
			})}
		</Select>,

		<Input placeholder={'物品名称'} onChange={(event: any) => {
			setSearch(event.target.value)
		}}/>,
		<Button type="primary" onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest()
			}
		}}>查询</Button>
	];
	const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>{element}</div>
				{
					msg && <div>
						<Tooltip title={msg}>
							<ExclamationCircleOutlined style={iconStyle}/>
						</Tooltip>
					</div>
				}

			</div>
		);
	};
	const tableExtraRender = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card className={'clickItem'} onClick={() => {
						setTitle('物品总数列表')
						setItemInfoUuid("")
						setStatus('')
						setVisible(true)
					}} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>物品总数</span>,
								''
							)}
							value={roomItemTotal.total || 0}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>个</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card className={'clickItem'} onClick={() => {
						setTitle('损坏物品列表')
						setItemInfoUuid("")
						setStatus('损坏')
						setVisible(true)
					}} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>损坏物品总数</span>,
								''
							)}
							value={roomItemTotal.damageTotal || 0}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>个</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card className={'clickItem'} onClick={() => {
						setTitle('丢失列表')
						setItemInfoUuid("")
						setStatus('缺失')
						setVisible(true)
					}} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic

							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>缺失物品总数</span>,
								''
							)}
							value={roomItemTotal.defectTotal || 0}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>个</span>}
						/>
					</Card>
				</Col>

			</Row>
		);
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				rowKey={'name'}
				tableExtraRender={tableExtraRender}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getRoomItemStatisticsData({
						page: params.current,
						size: params.pageSize,
						search: search,
						type: type
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal key={title + status + itemInfoUuid} width={800} title={title} footer={null} onCancel={() => {setVisible(false)}} visible={visible} >
				<GSTable
					columns={recordColumns}
					rowSelection={false}
					actionRef={infoActionRef}
					options={false}
					request={async (params: any = {}, sort: any, filter: any) => {
						const res = await getRoomItemList({
							page: params.current,
							size: params.pageSize,
							status: status,
							itemInfoUuid: itemInfoUuid
						});
						if (res.err !== 0) {
							return {
								data: [],
								total: 0,
							};
						}
						return {
							data: res.data,
							total: res.total,
							success: true,
						};
					}}
				/>
			</Modal>
		</>
	);
}
export default RoomItemReport;
