import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic } from 'antd';

import PieChart from '../../components/PieChart';
import { ColumnChartContext } from '../controlPlatform/CurrentPhoneBrandChart';
import {
	getCompanyStatistics,
	getTalentStatistics,
	getSchemeStatistics,
	getMarketChanceStatistics,
	getCompanyTypeStatistics,
	getTalentTypeStatistics,
	getSchemeTypeStatistics
} from '../../services/StatisticsService';

const IndustrialOperation: React.FC = () => {
	const [companyData, setCompanyData] = useState<any>({total: 0, newlyAdded: 0});
	const [schemeData, setSchemeData] = useState<any>({total: 0, newlyAdded: 0});
	const [talentData, setTalentData] = useState<any>({total: 0, newlyAdded: 0});
	const [marketChanceData, setMarketChanceData] = useState<any>({total: 0, newlyAdded: 0});
	const [companyPieData, setCompanyPieData] = useState<any>([{type: "", value: 0}]);
	const [talentPieData, setTalentPieData] = useState<any>([{type: "", value: 0}]);
	const [schemePieData, setSchemePieData] = useState<any>([{type: "", value: 0}]);
	useEffect(() => {
		getCompanyData()
		getTalentData()
		getSchemeData()
		getMarketChanceData()
		getCompanyPieData()
		getTalentPieData()
		getSchemePieData()
	}, [])

	const getCompanyData = async () => {
		let rsp = await getCompanyStatistics({})
		if (rsp.err ===0) {
			setCompanyData(rsp.data)
		}
	}

	const getSchemeData = async () => {
		let rsp = await getSchemeStatistics({})
		if (rsp.err ===0) {
			setSchemeData(rsp.data)
		}
	}
	const getTalentData = async () => {
		let rsp = await getTalentStatistics({})
		if (rsp.err ===0) {
			setTalentData(rsp.data)
		}
	}
	const getMarketChanceData = async () => {
		let rsp = await getMarketChanceStatistics({})
		if (rsp.err ===0) {
			setMarketChanceData(rsp.data)
		}
	}

	const getCompanyPieData = async () => {
		let rsp = await getCompanyTypeStatistics({})
		if(rsp.err === 0) {
			let data: any = []
			rsp.data.map((item: any) => {
				data.push({
					type: item.type,
					value: item.total
				})
			})
			if (data.length >0) {
				setCompanyPieData(data)
			}
		}
	}

	const getTalentPieData = async  () => {
		let rsp = await getTalentTypeStatistics({})
		if(rsp.err === 0) {
			let data: any = []
			rsp.data.map((item: any) => {
				data.push({
					type: item.type,
					value: item.total
				})
			})
			if (data.length > 0) {
				setTalentPieData(data)
			}
		}
	}



	const getSchemePieData = async  () => {
		let rsp = await getSchemeTypeStatistics({})
		if(rsp.err === 0) {
			let data: any = []
			rsp.data.map((item: any) => {
				data.push({
					type: item.type,
					value: item.total
				})
			})
			if (data.length > 0) {
				setSchemePieData(data)
			}
		}
	}


	const data: any = [
		{
			type: '',
			value: ''
		}
	];
	return <div className="content-big">
		<Row gutter={12}>
			<Col span={6}>
				<Card title={'企业总数'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
					<Row justify={'space-around'}>
						<Col>
							<Statistic title={'总数'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={companyData.total}
									   suffix={'个'}>

							</Statistic>
						</Col>
						<Col>
							<Statistic title={'7日新增'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={companyData.newlyAdded}
									   suffix={'个'}>
							</Statistic>
						</Col>
					</Row>
				</Card>
			</Col>
			<Col span={6}>
				<Card title={'智库人才'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
					<Row justify={'space-around'}>
						<Col>
							<Statistic title={'总数'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={talentData.total}
									   suffix={'个'}>

							</Statistic>
						</Col>
						<Col>
							<Statistic title={'7日新增'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={talentData.newlyAdded}
									   suffix={'个'}>
							</Statistic>
						</Col>
					</Row>
				</Card>
			</Col>
			<Col span={6}>
				<Card title={'产品方案'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
					<Row justify={'space-around'}>
						<Col>
							<Statistic title={'总数'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={schemeData.total}
									   suffix={'个'}>

							</Statistic>
						</Col>
						<Col>
							<Statistic title={'7日新增'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={schemeData.newlyAdded}
									   suffix={'个'}>
							</Statistic>
						</Col>
					</Row>
				</Card>
			</Col>
			<Col span={6}>
				<Card title={'需求对接'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
					<Row justify={'space-around'}>
						<Col>
							<Statistic title={'总数'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={marketChanceData.total}
									   suffix={'个'}>

							</Statistic>
						</Col>
						<Col>
							<Statistic title={'7日新增'} valueStyle={{ color: '#000000', fontSize: 20 }}
									   value={marketChanceData.newlyAdded}
									   suffix={'个'}>
							</Statistic>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
		<Row gutter={16}>
			<Col span={8}>
				<Card title={'企业分布'}>
					<ColumnChartContext.Provider value={companyPieData}>
						<PieChart unit={"家"}/>
					</ColumnChartContext.Provider>
				</Card>
			</Col>
			<Col span={8}>
				<Card title={'人才分布'}>
					<ColumnChartContext.Provider value={talentPieData}>
						<PieChart unit={"人"}/>
					</ColumnChartContext.Provider>
				</Card>
			</Col>
			<Col span={8}>
				<Card title={'产品方案分布'}>
					<ColumnChartContext.Provider value={schemePieData}>
						<PieChart unit={"个"}/>
					</ColumnChartContext.Provider>
				</Card>
			</Col>
		</Row>
		<Row gutter={16}>

		</Row>
	</div>;
};
export default IndustrialOperation;
