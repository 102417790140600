import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Select,
	Space,
	Statistic,
	Tag,
	Tooltip
} from 'antd';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { getRightsAndInterests, getTotalData } from '../../../services/scheme';
import  moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import CustomRangePicker from '../../../components/CustomRangePicker';
import { getZjbOrderList } from '../../../services/BeijingOfficeService';
const ZjbOrderList: React.FC  = () =>  {
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [totalFee, setTotalFee] = useState(0);
	const [count, setCount] = useState(0);
	const [rightsAndInterestList, setRightsAndInterestList] = useState([]);
	const [rightsAndInterestUuid, setRightsAndInterestUuid] = useState("");
	const [stime, setStime] = useState(		moment().subtract(7, 'd').hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:00'));
	const [etime, setEtime] = useState(moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:59'));
	useEffect(()=> {
		// loadTotalData().then()
		loadRightsAndInterests().then()
	}, [])
	const columns = [
		{
			title: '企业名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '支付金额',
			dataIndex: 'total_fee',
			key: 'total_fee',
			render: (text: string, record: any) => {
				if (!record.total_fee) {
					return <span>-</span>
				} else {
					return <span>{(record.total_fee / 100).toFixed(2)}</span>
				}
			}
		},
		{
			title: "订单号",
			dataIndex: "order_no",
		},
		{
			title: '支付者',
			dataIndex: 'real_name',
			key: 'real_name',
			render: (text: string, record: any) => {
				return <span>{record.real_name || record.nickname || "-"}</span>
			}
		},
		{
			title: '支付者账号',
			dataIndex: 'login_id',
			key: 'login_id'
		},
		{
			title: '支付时间',
			dataIndex: 'pay_time',
			valueType: 'dateTime',
			key: 'pay_time'
		}
	];
	const dateTime = (startTime: any, endTime: any) => {
		setStime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEtime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};
	const tableSearchBarRender = () => [
		<Space>
			<Select style={{width: 200}} defaultValue={""} onChange={(value: any) => {
				setRightsAndInterestUuid(value)
			}}>
				<Select.Option key={""} value={""}>全部</Select.Option>
				{
					rightsAndInterestList.map(item => {
						return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
					})
				}
			</Select>
			<CustomRangePicker onChange={dateTime} />
			<Input
				style={{ minWidth: 150 }}
				placeholder="名称"
				onChange={(e) => {
					setSearch(e.target.value);
				}}
			/>

			<Button
				type="primary"
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>
		</Space>
	];
	const tableOperationsBarRender = () => [
	];
	const loadTotalData = async () => {
		let rsp = await getTotalData({});
		if (rsp.err === 0) {
			setTotalFee(rsp.total)
			setCount(rsp.count)
		}
	}
	const loadRightsAndInterests = async () => {
		let rsp = await getRightsAndInterests({type: 'zjb'});
		if (rsp.err === 0) {
			setRightsAndInterestList(rsp.data)
		}
	}

	const tableExtraRender = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>会员数</span>,
								''
							)}
							value={count}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>人</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>成交额</span>,
								''
							)}
							value={(totalFee/100).toFixed(2)}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>元</span>}
						/>
					</Card>
				</Col>
			</Row>
		);
	};

	const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>{element}</div>
				{
					msg && <div>
						<Tooltip title={msg}>
							<ExclamationCircleOutlined style={iconStyle}/>
						</Tooltip>
					</div>
				}

			</div>
		);
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				// tableExtraRender={tableExtraRender}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				rowKey="uuid"
				request={async (params: any = {}, sort: any, filter: any) => {
					const formData: any = {
						page: params.current,
						size: params.pageSize,
						rightsAndInterestUuid: rightsAndInterestUuid,
						search: search,
						startTime: stime,
						endTime: etime
					};
					const res = await getZjbOrderList(formData);
					return {
						data: res.data,
						total: res.total,
						success: true
					};
				}}
			/>
		</>
	);
};
export  default ZjbOrderList;
