import * as React from 'react';

/**
 * 多选控件
 */

interface CheckboxButtonProps {
	title: string;
	descr: string;
	uuid: string;
	initialValue: string;
	required: string;
	aliasName: string;
	isHide: boolean;
}

class CheckboxButton extends React.Component<CheckboxButtonProps> {
	constructor(props: any) {
		super(props);
		this.state = { selectValue: '', saveAllValue: [] };
		this.select = this.select.bind(this);
	}

	select(res: any) {
		let st = this.state as any;
		if (res.target.checked) {
			st.saveAllValue.push(res.target.value);
		} else {
			let index = st.saveAllValue.indexOf(res.target.value);
			if (index > -1) {
				st.saveAllValue.splice(index, 1);
			}
		}
		console.log('当前选择的值：', st.saveAllValue);
		let value = '';
		for (var i = 0; i < st.saveAllValue.length; i++) {
			if (i === 0) {
				value = st.saveAllValue[i];
			} else {
				value = value + ',' + st.saveAllValue[i];
			}
		}
		this.setState({
			selectValue: value,
		});
	}

	render() {
		return (
			<div style={{ paddingBottom: 10 }} hidden={this.props.isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i
							style={{ fontSize: 10 }}
							className={this.props.required === 'required' ? 'icon icon-asterisk' : ''}
						/>
					</span>
					{this.props.title === '' ? '未命名' : this.props.title}
				</div>
				{/* <div>{this.props.descr}</div>*/}
				<div dangerouslySetInnerHTML={{ __html: this.props.descr }} />
				{/*<div style={{color: 'red'}}><i className={this.props.required ? 'icon icon-asterisk' : ''}/>{this.props.required ? '必填' : ''}</div>
				 */}
				<div>
					<input
						className={this.props.required}
						hidden={true}
						id={this.props.uuid}
						value={(this.state as any).selectValue}
						type="text"
						name={this.props.uuid}
					/>
					{this.props.aliasName === '' ||
					this.props.aliasName === null ||
					this.props.aliasName === 'null' ||
					this.props.aliasName === undefined ||
					window.location.href.includes('user-add-shilie') ||
					window.location.href.includes('liucheng-dan?shili_uuid') ? (
						''
					) : (
						<text
							style={{
								width: '100%',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							别名: {this.props.aliasName}
						</text>
					)}
				</div>

				{this.props.initialValue.split(',').map((item: any, index: any) => (
					<label>
						<input type="checkbox" name={this.props.uuid + 'duoxuan'} onClick={this.select} value={item} />{' '}
						{item}
					</label>
				))}
			</div>
		);
	}
}

export default CheckboxButton;
