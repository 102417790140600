import Api from '../data/API';

export async function findByRoomUuid(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/findByRoomUuid', params);
}
export async function updateRoomCharge(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/update', params);
}
export async function creatChargeBillByCharge(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/creatChargeBillByCharge', params);
}

export async function creatReceivable(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/creatReceivable', params);
}
export async function frontDeskBill(params: any) {
	return Api.postWithAuth('/debit_note/frontDeskBill', params);
}
export async function getGNHTParams(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/createdGnHt', params);
}
export async function gyHtParams(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/createdGyHt', params);
}
export async function xzGyParams(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/createdXzGyHt', params);
}



export async function findHzHouseholdByRoomUuid(params: any) {
	return Api.postWithAuth('/api/zhuhu/findHzHouseholdByRoomUuid', params);
}
export async function jsChargeBillPrice(params: any) {
	return Api.postWithAuth('/admin/api/room/roomCharge/jsChargeBillPrice', params);
}


