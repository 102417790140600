export class WuyeBuildPlan {
	id: number = 0;
	buildUuid: string = '';
	layerNumber: number = 0;
	imageUrl: string = '';
	wuyeUuid: string = '';
	buildName: string = '';

	public setProperty(data: any) {
		this.id = data.id || 0;
		this.buildUuid = data.buildUuid || '';
		this.buildName = data.buildName || '';
		this.layerNumber = data.layerNumber || 0;
		this.imageUrl = data.imageUrl || '';
		this.wuyeUuid = data.wuyeUuid || '';
	}
}
