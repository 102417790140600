import React, {useEffect, useState} from 'react';
import moment from 'moment';
import { DatePicker, Select, Space } from 'antd';
interface FangKePickerPickerProps {
	startTime?: moment;
	endTime?: moment;
	onChange: (startTime: moment, endTime: moment) => void;
	type:boolean
}
const RangePicker = DatePicker.RangePicker;

const FangKePicker: React.FC<FangKePickerPickerProps> = (props: FangKePickerPickerProps) => {
	useEffect(()=>{console.log("props:::",props.type)},[])
	const [selectType, setSelectType] = useState(props.type?1:5);
	const [startTime, setStartTime] = useState(
		props.startTime || moment()
	);
	const [endTime, setEndTime] = useState(props.endTime || moment());
	const changeSelectType = (e: number) => {
		setSelectType(e);
		switch (e) {
			case 1:
				setStartTime(moment());
				setEndTime(moment());
				props.onChange(
					moment(),
					moment()
				);
				break;
			case 2:
				setStartTime(moment());
				setEndTime(moment().add(1, 'd'));
				props.onChange(
					moment(),
					moment().add(1, 'd'),
				);
				break;
			case 3:
				setStartTime(moment());
				setEndTime(moment().add(2, 'd'));
				props.onChange(
					moment(),
					moment().add(2, 'd'),
				);
				break;
			case 4:
				setStartTime(moment());
				setEndTime(moment().add(6, 'd'));
				props.onChange(
					moment(),
					moment().add(6, 'd'),
				);
				break;
		}
	};
	return (
		<div style={{width:'100%'}}>
			<Space>
			<Select style={{width:90}} defaultValue={selectType} onChange={changeSelectType}>
				<Select.Option value={1}>1天</Select.Option>
				<Select.Option value={2}>2天</Select.Option>
				<Select.Option value={3}>3天</Select.Option>
				<Select.Option value={4}>7天</Select.Option>
				<Select.Option value={5}>自定义</Select.Option>
			</Select>
			<>
				{selectType === 5 && (
					<RangePicker
						style={{width:'100%'}}
						key={[startTime, endTime].toString()}
						allowClear={false}
						defaultValue={[startTime, endTime]}
						onChange={(dates: any) => {
							console.log('dates:::::', dates);
							setStartTime(
								dates
									? dates[0]
									: props.startTime || moment()
							);
							setEndTime(dates ? dates[1] : props.endTime || moment())
							props.onChange(
								dates
									? dates[0]
									: props.startTime || moment(),
								dates ? dates[1] : props.endTime || moment()
							);
						}}
						format="YYYY-MM-DD"
						placeholder={['请选择开始时间', '请选择结束时间']}
					/>
				)}
			</>
		</Space>
		</div>
	);
};
export default FangKePicker;
