import { DatePicker, Form, FormInstance, Input, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { FormContext } from '../../../components/FormContext';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
interface Params {
	required: Boolean;
}
const ChargeTypeComponents: React.FC<Params> = (props: any) => {
	const billTypes = getBillTypes().list;
	const billCycles = getBillTypes().billCycles;
	const cycleTypes = getBillTypes().cycleType;
	const FormItem = Form.Item;
	const form: FormInstance = useContext(FormContext);
	const [billCyCle, setbillCyCle] = useState('每月');
	const [type, settype] = useState('');
	const [cycleType, setcycleType] = useState('自然周期');
	const renderNote = () => {
		console.log('billCycle', billCyCle, cycleType);
		if (DAL.dal.isNotNull(billCyCle) && type == '周期类') {
			const item = billCycles.find((item) => item.value == billCyCle);
			if (item) {
				if (cycleType == '循环周期') {
					return (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								color: '#eb9e05',
							}}
						>
							{item.xhNote.map((note) => {
								return (
									<>
										<div style={{ display: 'flex' }}>{note}</div>
									</>
								);
							})}
						</div>
					);
				} else if (cycleType == '自然周期') {
					return (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								color: '#eb9e05',
							}}
						>
							{item.zrNote.map((note) => {
								return (
									<>
										<div style={{ display: 'flex' }}>{note}</div>
									</>
								);
							})}
						</div>
					);
				}
			}
		}
	};
	return (
		<>
			<FormItem
				name={'type'}
				label={'收费项目类型'}
				rules={[{ required: props.required, message: '请选择收费项目类型' }]}
			>
				<Select
					onChange={(value: any) => {
						if ('仪表类' == type) {
							form.setFieldsValue({ name: '' });
						}
						settype(value);
					}}
				>
					{billTypes.map((item) => {
						return (
							<>
								<Select.Option value={item.value}>{item.name}</Select.Option>
							</>
						);
					})}
				</Select>
			</FormItem>
			{/* <FormItem
				name={'name'}
				label={'收费项目名称'}
				rules={[{ required: props.required, message: '请填写收费项目名称' }]}
			>
				{'仪表类' == type ? (
						<Select>
							{getBillTypes().meterReadingTypes.map((item) => {
								return (
									<>
										<Select.Option value={item.value}>{item.name}</Select.Option>
									</>
								);
							})}
						</Select>
				) : (
					<Input></Input>
				)}
			</FormItem> */}
			<FormItem
				hidden={type != '周期类'}
				name={'billingCycle'}
				initialValue={'每月'}
				rules={[{ required: type == '周期类' && props.required, message: '请选择计费周期' }]}
				label="计费周期"
			>
				<Select onChange={(value: any) => setbillCyCle(value)}>
					{billCycles.map((item) => {
						return (
							<>
								<Select.Option value={item.value}>{item.name}</Select.Option>
							</>
						);
					})}
				</Select>
			</FormItem>
			<FormItem
				hidden={type != '周期类'}
				name={'cycleType'}
				initialValue={'自然周期'}
				rules={[{ required: type == '周期类' && props.required, message: '请选择生成方式' }]}
				label="生成方式"
			>
				<Select onChange={(value: any) => setcycleType(value)}>
					{cycleTypes.map((item) => {
						return (
							<>
								<Select.Option value={item.value}>{item.name}</Select.Option>
							</>
						);
					})}
				</Select>
			</FormItem>

			<Form.Item hidden={"供暖费" !=  billCyCle} label="计费期间">
				<Input.Group compact>
					<Form.Item name={'startTime'} noStyle rules={[{ required:"供暖费" ==  billCyCle,message:'请输入计费开始时间' }]}>
						{/* <Input placeholder='例:11-15'  style={{ width: '50%' }} ></Input> */}
						<DatePicker
							style={{ width: '50%' }}
							format={'MM-DD'}
						/>
					</Form.Item>
					<Form.Item name={'endTime'} noStyle rules={[{ required: "供暖费" ==  billCyCle,message:'请输入计费结束时间' }]}>
						{/* <Input  placeholder='例:03-15' style={{ width: '50%' }} ></Input> */}
						<DatePicker
							style={{ width: '50%' }}
							format={'MM-DD'}
						/>
					</Form.Item>
				</Input.Group>
			</Form.Item>
			{renderNote()}
		</>
	);
};
export default ChargeTypeComponents;
