import * as React from 'react';
import DAL from '../../../data/DAL';
import Api from '../../../data/API';
import { delPinglun, findDongtaiDetails, getPinglunList } from '../../../services/GroupService';
var msg = require('../../../util/SuspensionMsg');

class GroupTieziXiangQing extends React.Component {
	dal = DAL.dal;
	findList2 = [];

	constructor(props: any) {
		super(props);
		this.state = {
			value: '',
			uuid: '',
			xiangqing: [],
			fabuRenyuan: '',
			fabuShijian: '',
			zhuti: '',
			tupianUrl: '',
			tieDianzan: '',
			isDelete: '',
			isOpen: '',
			tieziType: '',
			img: '',
			overallUuid: '',
			userName: '',
			pinglunName: '',
			pinglunNeirong: '',
			parentPinglunId: '',
			findList: [],
			isShow: true,
		};
		this.getdatas = this.getdatas.bind(this);
		this.findPinglunList = this.findPinglunList.bind(this);
		this.getImg = this.getImg.bind(this);
		this.getuuid = this.getuuid.bind(this);
		this.deletePinglun = this.deletePinglun.bind(this);
	}

	componentDidMount() {
		let page = window.location.search;
		let c = page.split('=');
		let uuid = c[1];
		console.log(uuid);
		this.getdatas(uuid);
	}

	componentWillMount() {
		this.findPinglunList();
	}

	getImg(ress: any) {
		this.setState({
			tupianUrl: ress,
		});
	}

	findPinglunList = async () => {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		const res = await getPinglunList({
			overallUuid: uuid,
		});
		// Api.postWithAuth('/api/pinglun/find_pinglun_list' , {
		//     overallUuid: uuid
		// }).then(res => {
		console.log('评论', res);
		if (res.err === 0) {
			this.setState({
				findList: res.data,
				isShow: false,
			});
			this.findList2 = res.data;
		} else {
			msg.suspensionMsg(res.msg);
			this.setState({
				isShow: false,
			});
		}
		// });
	};

	getdatas = async (uuid: any) => {
		const res = await findDongtaiDetails({
			uuid: uuid,
		});
		// Api.postWithAuth('/api/qunzu/find_dongtai_details' , {
		//     uuid: uuid
		// }).then(res => {
		console.log('xiangqing', res);
		if (res.err === 0) {
			this.setState({
				xiangqing: res.data,
				imgs: res.imgs,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};

	getuuid(ev: any) {
		this.setState({
			uuid: ev.target.id,
		});
	}

	deletePinglun = async () => {
		if ((this.state as any).uuid === null) {
			msg.suspensionMsg('删除异常');
			return;
		}

		const res = await delPinglun({
			uuid: (this.state as any).uuid,
		});

		// Api.postWithAuth('/api/pinglun/del_pinglun' , {
		//     uuid: (this.state as any).uuid
		// }).then(res => {
		console.log('评论', res);
		if (res.err === 0) {
			msg.suspensionMsg('删除成功', 'success', 'deletepinglun');
			this.findPinglunList();
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};

	render() {
		return (
			<div className="devices-list">
				<div>
					{(this.state as any).xiangqing.length === 0 ? (
						''
					) : (
						<div>
							<p>发布人: {(this.state as any).xiangqing.fabuRenyuan}</p>
							<p>发布时间: {(this.state as any).xiangqing.fabuShijian}</p>
							<p>主题: {(this.state as any).xiangqing.zhuti}</p>
							{(this.state as any).xiangqing.tupianUrl === null ||
							(this.state as any).xiangqing.tupianUrl === undefined ||
							(this.state as any).xiangqing.tupianUrl === '' ? (
								''
							) : (
								<img
									className="homePage"
									src={Api.cdnRootPath + (this.state as any).xiangqing.tupianUrl}
								/>
							)}
						</div>
					)}
				</div>
				<table className="table table-striped company-table">
					<thead>
						<tr>
							<th>评论人</th>
							<th>被评论人</th>
							<th>内容</th>
							<th>图片</th>
							<th>操作</th>
						</tr>
					</thead>
					<tbody>
						{(this.state as any).findList.map((d: any, index: any) => (
							<tr key={d.uuid}>
								<td>{d.userName}</td>
								<td>{d.pinglunName}</td>
								<td>{d.pinglunNeirong}</td>
								<td>
									{d.pinglunImg === null || d.pinglunImg === undefined || d.pinglunImg === '' ? (
										''
									) : (
										<img className="homePage" src={Api.cdnRootPath + d.pinglunImg} />
									)}
								</td>
								<td>
									<a
										type="button"
										id={d.uuid}
										onClick={this.getuuid}
										className="btn"
										data-toggle="modal"
										data-target="#deletepinglun"
									>
										删除
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				{/*删除*/}
				<div className="modal fade" id="deletepinglun">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal">
									<span aria-hidden="true">×</span>
									<span className="sr-only">关闭</span>
								</button>
								<h4 className="modal-title">提示</h4>
							</div>
							<div className="modal-body">
								<div className="dialog-title-min-count">您确认要删除该评论吗？</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-default" data-dismiss="modal">
									取消
								</button>
								<button
									type="button"
									className="btn btn-primary"
									data-dismiss="modal"
									onClick={this.deletePinglun}
								>
									确认
								</button>
							</div>
						</div>
					</div>
				</div>

				{(this.state as any).isShow ? (
					<div className="cover">
						<div>
							<i className="icon icon-spin icon-spinner-indicator" />
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default GroupTieziXiangQing;
