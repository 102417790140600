import * as React from 'react';
import DAL from '../../../data/DAL';
import { Col, Row } from 'antd';

interface OrderDetailProps {
	order: any;
}

class OrderDetail extends React.Component<OrderDetailProps> {
	dal = DAL.dal;
	constructor(props: any) {
		super(props);
		this.state = {
			orderUuid: '',
			order: '',
			orderState: '',
		};
		console.log('realname', this.props.order);
	}

	render() {
		return (
			<div>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						订单名称：
					</Col>
					<Col span={12}>{this.props.order.name}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						订单描述：
					</Col>
					<Col span={12}>{this.props.order.commodityDetail}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						预订人账号：
					</Col>
					<Col span={12}>{this.props.order.email}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						预订人姓名：
					</Col>
					<Col span={12}>{this.props.order.realName}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						预定时间：
					</Col>
					<Col span={12}>
						{this.props.order.start_time} - {this.props.order.end_time}
					</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						时间段：
					</Col>
					<Col span={12}>{this.props.order.hours}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						订单价格：
					</Col>
					<Col span={12}>{this.props.order.total_price}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						下单时间：
					</Col>
					<Col span={12}>{this.props.order.order_time}</Col>
				</Row>
				{/*<Row style={{paddingBottom: 10}}>
                    <Col span={12} style={{textAlign: 'center'}}>
                        订单状态：
                    </Col>
                    <Col span={12}>
                        {this.props.order.is_pay}
                    </Col>
                </Row>*/}
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						支付时间：
					</Col>
					<Col span={12}>{this.props.order.pay_time}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						支付方式：
					</Col>
					<Col span={12}>{this.props.order.payment}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }}>
					<Col span={12} style={{ textAlign: 'center' }}>
						订单号：
					</Col>
					<Col span={12}>{this.props.order.order_no}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						参会人数：
					</Col>
					<Col span={12}>{this.props.order.attendance}人</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						茶水：
					</Col>
					<Col span={12}>{this.props.order.tea}份</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						矿泉水：
					</Col>
					<Col span={12}>{this.props.order.water}瓶</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						纪要纸笔：
					</Col>
					<Col span={12}>{this.props.order.paper_pen}份</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						是否需要投影仪：
					</Col>
					<Col span={12}>{this.props.order.need_projection === false ? '不需要' : '需要'}</Col>
				</Row>
				<Row style={{ paddingBottom: 10 }} hidden={this.props.order.kind !== '会议室预定'}>
					<Col span={12} style={{ textAlign: 'center' }}>
						是否需要电脑：
					</Col>
					<Col span={12}>{this.props.order.need_computer === false ? '不需要' : '需要'}</Col>
				</Row>
			</div>
		);
	}
}

export default OrderDetail;
