import Polygon from './Polygon';

export class WuyeRoom {
	id: number = 0;
	uuid: string = '';
	roomNumber: string = '';
	unit: string = '';
	layer: number = 0;
	position: string = '';
	state: number = 0;
	note: string = '';
	buildUuid: string = '';
	wuyeUuid: string = '';
	wuyeRoomId: string = '';
	acreage: number = 0;
	propertyFee: number = 0;
	builtInArea: number = 0;
	type: string = '';
	houseType: string = '';
	chargeArea: number = 0;
	gardenArea: string = '';
	loftArea: string = '';
	name: string = '';
	contractNo: string = '';
	contractStartTime: string = '';
	contractEndTime: string = '';
	price: number = 0;
	zhengJianHao: string = '';
	companyName: string = '';

	public setProperty(data: any) {
		this.id = data.id || 0;
		this.uuid = data.uuid || '';
		this.roomNumber = data.roomNumber || '';
		this.position = data.position || '';
		this.state = data.state || 0;
		this.note = data.note || '';
		this.buildUuid = data.buildUuid || '';
		this.wuyeUuid = data.wuyeUuid || '';
		this.unit = data.unit || 0;
		this.layer = data.layer || 0;
		this.wuyeRoomId = data.wuyeRoomId || '';
		this.acreage = data.acreage || 0;
		this.propertyFee = data.propertyFee || 0;
		this.builtInArea = data.builtInArea || 0;
		this.type = data.type || '';
		this.chargeArea = data.chargeArea || 0;
		this.gardenArea = data.gardenArea || 0;
		this.loftArea = data.loftArea || 0;
		this.houseType = data.houseType || '';
		this.name = data.name || '';
		this.contractNo = data.contractNo || '';
		this.contractStartTime = data.contractStartTime || '';
		this.contractEndTime = data.contractEndTime || '';
		this.price = data.price || 0;
		this.zhengJianHao = data.zhengJianHao;
		this.companyName = data.companyName;
	}

	public isCheck(a: Polygon[]) {
		console.log('allPolygon::::', a);
		let flag = false;
		a.map((item: Polygon) => {
			if (item.roomUuid === this.uuid) {
				flag = true;
				return;
			}
		});
		return flag;
	}
}
