import Api from '../data/API';

export async function getTalentList(params: any) {
	return Api.postWithAuth('/adminapi/talent/getTalentList', params);
}
export async function addTalent(params: any) {
	return Api.postWithAuth('/adminapi/talent/addTalent', params);
}
export async function updateTalent(params: any) {
	return Api.postWithAuth('/adminapi/talent/updateTalent', params);
}
export async function deleteTalent(params: any) {
	return Api.postWithAuth('/adminapi/talent/deleteTalent', params);
}

