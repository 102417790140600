import * as React from 'react';
import DAL from '../../data/DAL';
import API from '../../data/API';
import { DatePicker } from 'antd';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import { getDeviceUserInfo } from '../../services/StatisticsService';

var msg = require('../../util/SuspensionMsg');

var echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/line'); //图表类型
require('echarts/lib/component/title'); //标题插件
var moment = require('moment');

class NumberAdded extends React.Component {
	dal = DAL.dal;
	lineData: any;
	userData: any;
	xTime: any;
	deviceNmae: any;

	constructor(props: any) {
		super(props);
		//获取时间
		var date = new Date();
		var year, month, day;
		date.setDate(date.getDate() - 7);
		year = date.getFullYear();
		month = date.getMonth() + 1;
		day = date.getDate();
		var startData = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
		var endData = moment(new Date()).format('YYYY-MM-DD');

		this.state = {
			value: '',
			users: [],
			isShow: true,
			viewType: '开锁次数',
			deviceNames: [],
			startData: startData,
			endData: endData,
			selectDevice: '全部设备',
		};

		this.setPieOption = this.setPieOption.bind(this);
		this.lines = this.lines.bind(this);
		this.getDate = this.getDate.bind(this);
		this.callData = this.callData.bind(this);
		this.onChangItem = this.onChangItem.bind(this);
		this.getStartData = this.getStartData.bind(this);
		this.getEndData = this.getEndData.bind(this);
		// lines.setCallBack(this.callData);
	}

	callData() {
		this.getData((this.state as any).startData + ' 00:00:00', (this.state as any).endData + ' 00:00:00');
	}

	componentDidMount() {
		this.getData((this.state as any).startData + ' 00:00:00', (this.state as any).endData + ' 00:00:00');
	}
	getStartData(date: any, dateString: string) {
		console.log('开始时间改变' + dateString);
		this.setState({
			startData: dateString,
		});
	}
	getEndData(date: any, dateString: string) {
		console.log('结束时间改变' + dateString);
		this.setState({
			endData: dateString,
		});
	}
	getData = async (startTime: string, endTime: string) => {
		this.setState({
			isShow: true,
		});
		const res = await getDeviceUserInfo({
			startTime: startTime,
			endTime: endTime,
		});
		// API.postWithAuth('/api/mongodb/get_device_user_info' , {
		//     startTime: startTime,
		//     endTime: endTime
		// }).then(res => {
		if (res.err === 0) {
			var datas = res.data;
			var series = [];
			var devicename = [];
			var days = [];
			//全部设备
			var num = 0;
			var nums = [];
			var allDevice = [];

			for (var i = 0; i < datas[0].values.length; i++) {
				for (var j = 0; j < datas.length; j++) {
					num += datas[j].values[i];
					var da = datas[j];
					var device_name = da.device_name;
					devicename[j] = device_name;
					//获得数据段，只需要获得一次
					if (j === 0) {
						for (var y = 0; y < da.days.length; y++) {
							days[y] = [this.getDate(da.year, da.days[y])];
						}
					}
					series[j] = {
						name: device_name,
						type: 'line',
						data: da.values,
						itemStyle: { normal: { label: { show: true } } },
					};
				}
				//全部设备数据
				nums[i] = num;
				num = 0;
				allDevice[0] = {
					name: '总数据',
					type: 'line',
					data: nums,
					itemStyle: { normal: { label: { show: true } } },
				};
			}
			console.log('num的值', nums);
			this.lineData.clear();
			this.lineData.setOption(this.setPieOption(allDevice, days, devicename));
			this.userData = series;
			this.setState({
				deviceNames: devicename,
				selectDevice: '全部设备',
				isShow: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}

		// });
	};

	//通过天数获得日期
	getDate(year: number, day: number) {
		var newYear = year + '-01-01 00:00:00';
		newYear = newYear.replace(new RegExp('-', 'gm'), '/');
		var yearHaomiao = new Date(newYear).getTime(); //得到毫秒数
		var dayHaomiao = (day - 1) * 24 * 3600 * 1000;
		var sumHaomiao = yearHaomiao + dayHaomiao;
		var newTime = new Date(sumHaomiao);
		let dayy = moment(newTime).format('YYYY-MM-DD');
		return dayy;
	}

	lines(lin: any) {
		if (lin !== null) {
			this.lineData = echarts.init(lin);
		}
	}

	//一个基本的echarts图表配置函数
	setPieOption(data: any, xAxisData: any, devicename: any) {
		var max = data[0].data[0];
		var intervalNum = 0;
		for (var i = 0; i < data[0].data.length; i++) {
			if (data[0].data[i] > max) {
				max = data[0].data[i];
			}
		}
		intervalNum = (Math.round(max / 50) + 1) * 50;
		intervalNum = intervalNum / 5;

		return {
			title: {
				text: '每天增加人数',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
			},
			legend: {
				data: devicename,
				/*   data: ['蒸发量', '降水量', '平均温度']*/
			},
			xAxis: [
				{
					type: 'category',
					/* data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],*/
					data: xAxisData,
				},
			],
			yAxis: [
				{
					type: 'value',
					name: '新赠人数',
					interval: intervalNum,
					axisLabel: {
						formatter: '{value} /人',
					},
				},
			],
			series: data,
		};
	}

	// //点击提交时间
	// submitData(ev: any) {
	//     lines.untucp();
	// }

	//选择设备
	onChangItem(ev: any) {
		console.log('获得的设备id:', ev.target.id);

		if (ev.target.id === '全部设备') {
			this.getData((this.state as any).startData + ' 00:00:00', (this.state as any).endData + ' 00:00:00');
			return;
		}

		var arry = this.userData;
		var arry1 = arry.filter((item: any, index: any) => item.name.indexOf(ev.target.id) > -1);
		this.lineData.clear();
		this.lineData.setOption(this.setPieOption(arry1, this.xTime, this.deviceNmae));
		this.setState({
			selectDevice: ev.target.id,
		});
	}

	formChange() {
		console.log('筛选的数据:');
	}

	render() {
		return (
			<div>
				<nav className="filter-menu-bar navbar navbar-default" role="navigation">
					<div className="container-fluid">
						<ul className="nav navbar-nav">
							<li>
								<div className="dropdown query-conditions-first">
									<button className="btn" type="button" data-toggle="dropdown">
										{(this.state as any).selectDevice} <span className="caret" />
									</button>
									<div className="dropdown-menu dropdown-menu-table scroll-div">
										<div className="scroll-div-item" onClick={this.onChangItem} id="全部设备">
											全部设备
										</div>
										{(this.state as any).deviceNames.map((item: any, index: any) => (
											<div
												className="scroll-div-item"
												onClick={this.onChangItem}
												id={item}
												key={item}
											>
												{item}
											</div>
										))}
									</div>
								</div>
							</li>
							<li>
								<form id="lineForm" onChange={this.formChange} className="form-display-flex">
									<div className="input-group-first">
										{/*<input className="form-control form-datetime-yy send-key-dialog-footer-time-input" value={(this.state as any).startData} name="start_time" placeholder="请选择开始时间"/>*/}
										<DatePicker placeholder="请选择开始时间" onChange={this.getStartData} />
									</div>
									<div className="input-group-one">
										{/*<input className="form-control form-datetime-yy send-key-dialog-footer-time-input" value={(this.state as any).endData}  name="end_time" placeholder="请选择结束时间"/>*/}
										<DatePicker placeholder="请选择结束时间" onChange={this.getEndData} />
									</div>
									<div className="input-group-one">
										<button
											className="btn btn-primary"
											type="button"
											id="btnlines"
											onClick={this.callData.bind(this, (this.state as any).uuid)}
										>
											搜索
										</button>
									</div>
								</form>
							</li>
						</ul>
					</div>
				</nav>

				<div className="content">
					<div className="choice-device">
						{/*<div className="div-display-fles line-slecet_device">
                        <div className="dropdown">
                            <button className="btn" type="button" data-toggle="dropdown">{(this.state as any).selectDevice} <span className="caret"/></button>
                            <div className="dropdown-menu dropdown-menu-table scroll-div">
                                <div className="scroll-div-item" onClick={this.onChangItem}  id="全部设备" >全部设备</div>
                                {(this.state as any).deviceNames.map((item: any, index: any) => (
                                    <div className="scroll-div-item" onClick={this.onChangItem} id={item} key={item} >{item}</div>
                                ))}

                            </div>
                        </div>

                        <form id="lineForm" onChange={this.formChange} className="form-display-flex">
                        <input className="form-control form-datetime-yy send-key-dialog-footer-time-input" value={(this.state as any).startData} name="start_time" placeholder="请选择开始时间"/>
                        <input className="form-control form-datetime-yy send-key-dialog-footer-time-input" value={(this.state as any).endData}  name="end_time" placeholder="请选择结束时间"/>
                        <button className="btn submit-btn" type="button" id="btnlines"  onClick={this .submitData.bind(this, (this.state as any).uuid)}>提交</button>
                        </form>


                    </div>*/}
						{/*<div className="choice-device-right2">

                        <div className="input-control search-box search-box-circle has-icon-left has-icon-right" id="searchboxExample">
                            <input id="inputSearchExample1" type="search" className="form-control search-input" placeholder="账号搜索"/>
                            <label className="input-control-icon-left search-icon"><i className="icon icon-search"/></label>
                            <a href="#" className="input-control-icon-right search-clear-btn"><i className="icon icon-remove"/></a>
                        </div>

                    </div>*/}
					</div>

					<div className="row ">
						<div className="col-xs-12 div-text-align-center">
							<canvas ref={this.lines} width="1200" height="700" />
						</div>
					</div>

					{(this.state as any).isShow ? (
						<div className="cover">
							<div>
								<i className="icon icon-spin icon-spinner-indicator" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default NumberAdded;

// helpers

/*function getExclamationMarks(numChars: number) {
    return Array(numChars + 1).join('!');
}*/
