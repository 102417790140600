import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import * as React from 'react';

const type = 'DragableBodyRow';
const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }: any) => {
	const ref = React.useRef();
	const [{ isDragging }, drag, dragPreview] = useDrag({
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging(),
		}),
		// item 中包含 index 属性，则在 drop 组件 hover 和 drop 是可以根据第一个参数获取到 index 值
		item: { type: type, index },
	});

	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: (monitor) => {
			const item = monitor.getItem() || {};
			// if (item.parentId !== restProps['children'][0].props.record.parentId) {
			// 	return {};
			// }
			return {
				isOver: monitor.isOver(),
				dropClassName: item.index < index ? ' drop-over-downward' : ' drop-over-upward',
			};
		},
		drop: (item: any) => {
			moveRow(item.index, index, item.index);
		},
	});

	dragPreview(drop(ref));
	drag(ref);
	return (
		<tr
			ref={ref}
			className={`${className}${isOver ? dropClassName : ''}`}
			style={{ cursor: 'move', ...style }}
			{...restProps}
		/>
	);
};
export default DragableBodyRow;
