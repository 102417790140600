import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, message, Modal, Popover, Row, Tooltip } from 'antd';
import { deleteBanGong, getBanGongDataList } from '../../../services/BanGongService';
import Api from '../../../data/API';
import * as moment from 'moment';
import BanGongForm from '../components/BanGongForm';
import appConfig from '../../../appConfig';
const OfficeSpace: React.FC = () => {
	useEffect(() => {
		getData();
	}, []);
	const { confirm } = Modal;
	const [data, setData] = useState([]);
	const [addVisible, setAddVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	const [item, setItem] = useState({});
	const getData = async () => {
		const res = await getBanGongDataList({});
		console.log('data::111', res);
		if (res.err === 0) {
			setData(res.data);
			console.log('data:::', res.data);
		} else {
			message.error(res.msg);
		}
	};
	const deleteItem = (item: any) => {
		console.log('deleteItem::', item);
		confirm({
			title: '您确定要删除该办公空间吗？',
			content: '删除后将无法恢复',
			okType: 'danger',
			onOk: async () => {
				let data = {
					uuid: item.uuid,
				};
				const res = await deleteBanGong(data);
				if (res.err === 0) {
					getData();
					message.success('删除成功');
				} else {
					message.error(res.msg);
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const getBanGongMsg = (item: any) => {
		return (
			<div>
				<Tooltip title={`${moment(item.createdTime).format('YYYY-MM-DD')} ${item.third ? ' 三方服务' : ''}`}>
					{moment(item.createdTime).format('YYYY-MM-DD')}{' '}
					<span style={{ color: 'red', fontSize: 13, marginLeft: 5 }}>{item.third ? '三方服务' : ''}</span>
				</Tooltip>
			</div>
		);
	};
	const openUpdate = (item: any) => {
		console.log('updateItem:::', item);
		setUpdateVisible(true);
		setItem(item);
	};
	return (
		<div>
			<Row gutter={16}>
				{data.map((item: any) => {
					let picture = item.pictureUris;
					console.log("picture.split(',')::", item);
					let coverPrice = '';
					if (picture !== undefined && picture.includes(',') !== -1) {
						coverPrice = picture.split(',')[0];
					} else {
						coverPrice = picture;
					}
					return (
						<Col key={item.toString()} span={4}>
							<Card
								bodyStyle={{ paddingLeft: 20 }}
								style={{ width: '100%', height: 251.7 }}
								cover={<Image height={128.7} src={Api.cdnRootPath + coverPrice} />}
								actions={[
									<a
										style={{ height: 25 }}
										key={item.uuid + '1'}
										href={
											'/preview.html?previewUrl=' +
											appConfig.mobileAppUrl +
											'/bangongkongjian/ban-gong-details?uuid=' +
											item.uuid
										}
										target="_blank"
									>
										预览
									</a>,
									<a
										style={{ height: 25 }}
										onClick={() => {
											openUpdate(item);
										}}
									>
										编辑
									</a>,
									<a
										style={{ height: 25 }}
										onClick={() => {
											deleteItem(item);
										}}
									>
										删除
									</a>,
								]}
							>
								<div className={"omit1"} style={{ display: 'flex', alignItems: 'center'  }}>名称：{item.name}</div>
								<div className={"omit1"} style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }}>
									地址：{item.address}
								</div>
							</Card>
						</Col>
					);
				})}
				<Col key={'1111'} span={4}>
					<div
						key={'222222'}
						onClick={() => {
							setAddVisible(true);
						}}
						style={{
							width: '100%',
							height: 253.7,
							display: 'flex',
							backgroundColor: '#fff',
							justifyContent: 'center',
							fontSize: '1.2rem',
							alignItems: 'center',
							borderColor: '#999',
							borderStyle: 'dashed',
							cursor: 'pointer',
							borderWidth: 1,
						}}
					>
						<span>+ 新增办公位置</span>
					</div>
				</Col>
			</Row>
			<BanGongForm
				key={new Date().getTime().toString()}
				title={'新增办公位置'}
				visible={addVisible}
				onCancel={() => {
					setAddVisible(false);
				}}
				url={'/api/banGong/save_ban_gong'}
				callBack={() => {
					getData();
					setAddVisible(false);
				}}
			/>
			<BanGongForm
				key={item.uuid}
				title={'编辑办公室位置'}
				visible={updateVisible}
				data={item}
				onCancel={() => {
					setUpdateVisible(false);
				}}
				url={'/api/banGong/update_ban_gong'}
				callBack={() => {
					getData();
					setUpdateVisible(false);
				}}
			/>
		</div>
	);
};
export default OfficeSpace;
