import React, { useEffect, useRef, useState } from 'react';

import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Tag } from 'antd';
import { ActionType } from '@ant-design/pro-table';
import { FormInstance } from 'antd/es/form';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import {
	addFeeSubject,
	deleteFeeSubject,
	findFeeSubject,
	synchronizationFeeSubject,
	updataFeeSubject,
} from '../../../services/PayCostManagementService';
import AuthorizedView from '../../../util/AuthorizedView';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import FeeStandardList from './FeeStandardList';
import getBillTypes from '../models/BillTypes';
import AddFeeSubjectForm from '../components/AddFeeSubjectForm';
import { findFeeSubjectInfo } from '../../../services/FeeService';
import FeeSubjectForm from '../components/FeeSubjectForm';
import { FormContext } from '../../../components/FormContext';
import { getWxBusinessList } from '../../../services/Wx';
import moment from 'moment';
import { data } from 'jquery';
import DAL from '../../../data/DAL';
var msg = require('../../../util/SuspensionMsg');
const confirm = Modal.confirm;
const FeeSubject: React.FC = () => {
	useEffect(() => {
		getWxList().then();
	}, []);

	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);
	const [type, setType] = useState('');
	const [state, setState] = useState('');
	const [name, setName] = useState('');
	const [feeStandardVisible, setfeeStandardVisible] = useState(false);
	const [dateKey, setdateKey] = useState(new Date().getTime().toString());
	const [feeTypeId, setfeeTypeId] = useState();
	const [addVisible, setaddVivible] = useState(false);
	const Option = Select.Option;
	const [feetype, setFeetype] = useState('');
	const [wxBusinessList, setwxBusinessList] = useState([]);
	const [startTime, setstartTime] = useState(undefined);
	const [endTime, setendTime] = useState(undefined);
	const billCycles = getBillTypes().billCycles;
	const cycleTypes = getBillTypes().cycleType;
	const billTypes = getBillTypes().list || [];
	const getWxList = async () => {
		const res = await getWxBusinessList({});
		if (res.err == 0) {
			setwxBusinessList(res.data || []);
		}
	};
	const columns = [
		{
			title: '收费项目编码',
			dataIndex: 'feeTypeCode',
			key: 'feeTypeCode',
		},
		{
			title: '收费项目',
			dataIndex: 'feeName',
			key: 'feeName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						updataShow(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '收费类型',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '是否启用',
			dataIndex: 'state',
			key: 'state',
			render: (text: string, record: any) => (
				<Tag color={record.state === false ? 'red' : record.state === true ? 'green' : ''}>
					{record.state === true ? '启用' : record.state === false ? '禁用' : ''}
				</Tag>
			),
		},
		{
			title: '操作',
			key: 'action',
			width: 100,
			fixed: 'right',
			render: (text: string, record: any) => (
				<Space>
					<a
						onClick={() => {
							deletefee(record.uuid);
						}}
					>
						删除
					</a>
					{/* <a
						onClick={() => {
							setdateKey(new Date().getTime().toString());
							setfeeTypeId(record.uuid);
							setfeeStandardVisible(true);
						}}
					>
						收费标准
					</a> */}
				</Space>
			),
		},
	];
	const updataShow = (record: any) => {
		console.log('item：：：：', record);
		setAdd(false);
		// const formData: any = {
		// 	feeTypeCode: record.feeTypeCode,
		// 	uuid: record.uuid,
		// 	feeName: record.feeName,
		// 	type: record.type,
		// 	billingCycle: record.billingCycle,
		// 	cycleType: record.cycleType,
		// 	state: record.state,
		// };
		// form.setFieldsValue(formData);
		getInfo(record.uuid);
	};

	const deletefee = (uuid: string) => {
		confirm({
			title: '您确认要删除此科目吗?',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteFeeSubject({ uuid: uuid });
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setdateKey(new Date().getTime().toString());
				form.resetFields();
				// setAdd(true);
				// setVisible(true);
				setaddVivible(true);
			}}
		>
			新建
		</Button>,
		// <AuthorizedView needAuthority={'payment_management:subject:property_synchr'}>
		// 	<Button type="primary" onClick={synch}>
		// 		从物业同步
		// 	</Button>
		// </AuthorizedView>,
	];

	const synch = async () => {
		const res = await synchronizationFeeSubject({});
		console.log('同步', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '同步成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'error' });
		}
	};

	const handleOk = () => {
		if (add) {
			addItem();
		} else {
			updataItem();
		}
	};
	const formItemLayout = {
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 24 },
		},
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data::;', data);
			const formData: any = {
				feeTypeCode: data.feeTypeCode,
				name: data.feeName,
				type: data.type,
				billingCycle: data.billingCycle,
				cycleType: data.cycleType,
				state: data.state === null || data.state === undefined || data.state === '' ? false : data.state,
			};
			const res = await addFeeSubject(formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else if (res.err === -2) {
				message.warning(res.msg);
			} else {
				msg.suspensionMsg({ content: res.msg, type: 'error' });
				setVisible(false);
			}
		});
	};

	const updataItem = () => {
		form.validateFields().then(async (data: any) => {
			const type = feetype;
			console.log('data::', data, type);
			const formData: any = {
				...data,
				feeTypeUuid: data.uuid,
				startTime: startTime,
				endTime: endTime,
			};
			const res = await updataFeeSubject(formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '更新成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: res.msg, type: 'error' });
			}
			setVisible(false);
		});
	};

	const getInfo = async (feeTypeUuid) => {
		const res = await findFeeSubjectInfo({ feeTypeUuid });

		if (res.err === 0) {
			setFeetype(res.data.type || '');
			setstartTime(res.data.startTime);
			setendTime(res.data.endTime);
			form.setFieldsValue({
				...res.data,

				startTime: DAL.dal.isNotNull(res.data.startTime) ? moment(res.data.startTime, "MM-DD") : undefined,
				endTime: DAL.dal.isNotNull(res.data.endTime) ? moment(res.data.endTime, "MM-DD") : undefined,
			});
			setVisible(true);
		} else {
			message.error(res.msg);
		}
	};
	const tableSearchBarRender = () => [
		<Space>
			<Select
				allowClear
				placeholder={'收费类型'}
				onChange={(value: any) => {
					setType(value);
				}}
			>
				{billTypes.map((item: any) => (
					<Option value={item.value}>{item.name}</Option>
				))}
			</Select>
			<Select
				allowClear
				placeholder={'状态'}
				onChange={(value: any) => {
					setState(value);
				}}
			>
				<Option value={'true'}>启用</Option>
				<Option value={'false'}>禁用</Option>
			</Select>
			<Input placeholder={'收费项目名称'} onChange={(e) => setName(e.target.value)} />
			<Button
				type={'primary'}
				onClick={() => {
					actionRef.current.reloadAndRest();
				}}
			>
				查询
			</Button>
		</Space>,
	];
	const onStartTimeChange = (date, dateString) => {
		setstartTime(moment(dateString).format('MM-DD'));
	};
	const onEndTimeChange = (date, dateString) => {
		setendTime(moment(dateString).format('MM-DD'));
	};
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findFeeSubject({
						page: params.current,
						size: params.pageSize,
						name: name,
						type: type,
						state: state,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title={'修改'}
				visible={visible}
				width={document.body.clientWidth - 210}
				onOk={handleOk}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<div>
					<FormContext.Provider value={form}>
						<ProxyForm key={feetype} form={form}>
							<Row className="margin-top--10" gutter={12} justify={'start'}>
								<Col span={12}>
									<FormItem
										name={'type'}
										label={'收费项目类型'}
										rules={[{ required: true, message: '请选择收费项目类型' }]}
									>
										<Select
											onChange={(value: any) => {
												setFeetype(value);
											}}
										>
											{billTypes.map((item) => {
												return (
													<>
														<Select.Option value={item.value}>{item.name}</Select.Option>
													</>
												);
											})}
										</Select>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem name={'typeCode'} label={'编码'} rules={[{ required: true }]}>
										<Input disabled={true} style={{ width: '100%' }}></Input>
									</FormItem>
								</Col>
							</Row>

							<Row className="margin-top--10" gutter={12} justify={'start'}>
								<Col span={12}>
									<FormItem
										name={'name'}
										label={'收费项目名称'}
										rules={[{ required: true, message: '请填写收费项目名称' }]}
									>
										<Input disabled={true}></Input>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										name={'amountBit'}
										label={'金额保留位'}
										rules={[{ required: true, message: '请选择金额保留位' }]}
									>
										<Select>
											<Select.Option value={'元'}>元</Select.Option>
											<Select.Option value={'角'}>角</Select.Option>
											<Select.Option value={'分'}>分</Select.Option>
										</Select>
									</FormItem>
								</Col>
							</Row>
							<Row className="margin-top--10" gutter={12} justify={'start'}>
								<Col span={12}>
									<FormItem
										tooltip="四舍五入（0.125就精确到0.13）、舍位（0.125就精确到0.12）、进位（0.121就精确到0.13）"
										name={'roundingMethod'}
										label={'取整方式'}
										rules={[{ required: true, message: '请选择取整方式' }]}
									>
										<Select>
											<Select.Option value={'四舍五入'}>四舍五入</Select.Option>
											<Select.Option value={'舍位'}>舍位</Select.Option>
											<Select.Option value={'进位'}>进位</Select.Option>
										</Select>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem label="单位名称" name={'unitName'}>
										<Select>
											{getBillTypes().unitNames.map((item) => {
												return (
													<>
														<Select.Option value={item.value}> {item.name} </Select.Option>
													</>
												);
											})}
										</Select>
									</FormItem>
								</Col>
							</Row>
							<Row hidden={feetype !== '周期类'} className="margin-top--10" gutter={12} justify={'start'}>
								<Col span={12}>
									<FormItem
										name={'billingCycle'}
										initialValue={'每月'}
										rules={[
											{
												required: feetype === '周期类',
												message: '请选择计费周期',
											},
										]}
										label="计费周期"
									>
										<Select>
											{billCycles.map((item) => {
												return (
													<>
														<Select.Option value={item.value}>{item.name}</Select.Option>
													</>
												);
											})}
										</Select>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										hidden={feetype !== '周期类'}
										name={'cycleType'}
										initialValue={'自然周期'}
										rules={[
											{
												required: feetype === '周期类',
												message: '请选择生成方式',
											},
										]}
										label="生成方式"
									>
										<Select>
											{cycleTypes.map((item) => {
												return (
													<>
														<Select.Option value={item.value}>{item.name}</Select.Option>
													</>
												);
											})}
										</Select>
									</FormItem>
								</Col>
							</Row>

							<Row className="margin-top--10" gutter={12} justify={'start'}>
								<Col span={12}>
									<Form.Item label="应收日期">
										<Input.Group compact>
											<Form.Item name={'receivableType'} noStyle rules={[{ required: true }]}>
												<Select style={{ width: '50%' }}>
													{getBillTypes().receviableTypes.map((item) => {
														return (
															<>
																<Select.Option value={item.value}>
																	{' '}
																	{item.name}
																</Select.Option>
															</>
														);
													})}
												</Select>
											</Form.Item>
											<Form.Item name={'receivableDate'} noStyle rules={[{ required: true }]}>
												<InputNumber min={0} style={{ width: '50%' }} />
											</Form.Item>
										</Input.Group>
									</Form.Item>
								</Col>
								<Col span={12}>
									<FormItem label={'是否启用'} name={'state'} valuePropName={'checked'}>
										<Switch />
									</FormItem>
								</Col>
							</Row>

							<Row className="margin-top--10" gutter={12} justify={'start'}>
								<Col span={12}>
									<Form.Item label="计费期间">
										<Input.Group compact>
											<Form.Item name={'startTime'} noStyle >
												{/* <Input placeholder='例:11-15'  style={{ width: '50%' }} ></Input> */}
												<DatePicker
													 style={{ width: '50%' }}
													format={'MM-DD'}
													value={startTime}
													onChange={onStartTimeChange}
												/>
											</Form.Item>
											<Form.Item name={'endTime'} noStyle >
												{/* <Input  placeholder='例:03-15' style={{ width: '50%' }} ></Input> */}
												<DatePicker
													 style={{ width: '50%' }}
													format={'MM-DD'}
													value={endTime}
													onChange={onEndTimeChange}
												/>
											</Form.Item>
										</Input.Group>
									</Form.Item>
								</Col>
							</Row>

							{/* <Row>
								<Col span={12}>
									<FormItem name={'wxBusinessUuid'} label={'wx商户'}>
										<Select>
											{wxBusinessList.map((item) => {
												return (
													<>
														<Select.Option value={item.uuid}>{item.name}</Select.Option>
													</>
												);
											})}
										</Select>
									</FormItem>
								</Col>
							</Row> */}
							<FormItem label={'uuid'} name={'uuid'} hidden={true}>
								<Input />
							</FormItem>

							<FeeSubjectForm feeType={feetype}></FeeSubjectForm>
						</ProxyForm>
					</FormContext.Provider>
				</div>
			</Modal>

			<Modal
				width={document.body.clientWidth - 210}
				title="新增"
				visible={addVisible}
				onCancel={() => setaddVivible(false)}
				footer={null}
			>
				<AddFeeSubjectForm
					key={dateKey}
					onCancel={() => setaddVivible(false)}
					onOk={() => {
						setaddVivible(false);
						if (actionRef.current) {
							actionRef.current.reload();
						}
					}}
				></AddFeeSubjectForm>
			</Modal>
		</div>
	);
};
export default FeeSubject;
