import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import SignAgreement from '../contract/pages/SignAgreement';
import AddFeeSubjectForm from './components/AddFeeSubjectForm';
import BillArrears from './pages/BillArrears';
import BillDeposit from './pages/BillDeposit';
import BillFrontDesk from './pages/BillFrontDesk';
import BillPaid from './pages/BillPaid';
import BillReceivable from './pages/BillReceivable';
import BillRecordBatchList from './pages/BillRecordBatchList';
import BillRefundList from './pages/BillRefundList';
import BillRefundRecord from './pages/BillRefundRecord';
import BillStatistical from './pages/BillStatistical';
import BillStats from './pages/BillStatistical';
import DebitNote from './pages/DebitNote';
import FeeSubject from './pages/FeeSubject';
import GenReceivable from './pages/GenReceivable';
import MeterReadingList from './pages/MeterReadingList';
import ReceiptOfCharges from './pages/ReceiptOfCharges';
import RefundRoom from './pages/RefundRoom';
import ZNengJiFei from './pages/ZNengJiFei';

export default function PayCostManageMiniApp() {
	const rootUrl = '/pay_cost_management';
	const menus: MiniAppMenu[] = [
		{
			authority: 'payment_management:debit_note',
			name: '缴费单',
			path: 'debit_note',
			component: DebitNote,
		},
		{
			authority: 'payment_management:bill_refund_list',
			name: '费用退还',
			path: 'bill_refund_list',
			component: BillRefundList,
		},
		{
			authority: 'payment_management:bill_refund_list_record',
			name: '费用退还查询',
			path: 'bill_refund_list_record',
			component: BillRefundRecord,
		},
		{
			authority: 'payment_management:fee_subject',
			name: '收费设置',
			path: 'fee_subject',
			component: FeeSubject,
		},
		{
			authority: 'payment_management:bill_receviable',
			name: '应收查询',
			path: 'bill_receviable',
			component: BillReceivable,
		},
		{
			authority: 'payment_management:bill_paid',
			name: '实收查询',
			path: 'bill_paid',
			component: BillPaid,
		},
		{
			authority: 'payment_management:bill_deposit',
			name: '押金查询',
			path: 'bill_deposit',
			component: BillDeposit,
		},
		{
			authority: 'payment_management:intelligent_bill',
			name: '智能计费',
			path: 'intelligent_bill',
			component: ZNengJiFei,
		},
		{
			authority: 'payment_management:generate_receivable',
			name: '生成应收',
			path: 'generate_receivable',
			component: GenReceivable,
		},
		{
			authority: 'payment_management:bill_arrears',
			name: '欠费预警',
			path: 'bill_arrears',
			component: BillArrears,
		},
		{
			authority: 'payment_management:bill_frontDesk',
			name: '前台收费',
			path: 'bill_frontDesk',
			component: BillFrontDesk,
		},
		{
			authority: 'payment_management:meter_reading',
			name: '抄表',
			path: 'meter_reading',
			component: MeterReadingList,
		},
		{
			authority: 'payment_management:created_record',
			name: '生成记录',
			path: 'created_record',
			component: BillRecordBatchList,
		},
		{
			authority: 'payment_management:sign_agreement',
			name: '签订协议',
			path: 'sign_agreement',
			component: SignAgreement,
		},
		{
			authority: 'payment_management:billStatistical',
			name: '账单统计',
			path: 'billStatistical',
			component: BillStatistical,
		},
		{
			// authority: 'payment_management:created_record',
			name: '收据',
			path: 'print_bill',
			component: ReceiptOfCharges,
		},
		{
			// authority: 'payment_management:created_record',
			name: '退房腾退单',
			path: 'refund_bill',
			component: RefundRoom,
		},
	];

	return <MiniApp miniAppName={'缴费管理'} rootUrl={rootUrl} menus={menus} />;
}
