import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import PieHuodongReport from './PieHuodongReport';
import LineHuodongReport from './LineHuodongReport';
const HuodongReport: React.FC = () => {
	const [loading, setLoading] = useState(false);

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<PieHuodongReport />
				</Col>
				<Col span={12}>
					<LineHuodongReport />
				</Col>
			</Row>
		</>
	);
};
export default HuodongReport;
