import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import { DatePicker, Button, Row, Col, Divider } from 'antd';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import { getOpenUnLockDate } from '../../../services/ZhuhushenheService';

var msg = require('../../../util/SuspensionMsg');
var echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/line'); //图表类型
require('echarts/lib/component/title'); //标题插件
var moment = require('moment');

interface KaimenDataPros {
	deviceId: string;
	deviceName: string;
	userId: string;
}
class KaimenList extends React.Component<KaimenDataPros> {
	dal = DAL.dal;
	lineData: any;
	deviceId: string;
	deviceName: string;
	userId: string;
	seriesData: any = [];

	constructor(props: any) {
		super(props);
		//获取时间
		var date = new Date();
		var year, month, day;
		date.setDate(date.getDate() - 30);
		year = date.getFullYear();
		month = date.getMonth() + 1;
		day = date.getDate();
		var startData = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
		var endData = moment(new Date()).format('YYYY-MM-DD');
		// this.deviceId = props.location.state.deviceId;
		// this.deviceName = props.location.state.deviceName;
		// this.userId = props.location.state.userId;
		this.deviceId = this.props.deviceId;
		this.deviceName = this.props.deviceName;
		this.userId = this.props.userId;
		console.log('deviceId:', this.deviceId);
		console.log('deviceName:', this.deviceName);
		console.log('userId:', this.userId);
		this.state = {
			value: '',
			isShow: false,
			startData: startData,
			endData: endData,
		};
		this.setPieOption = this.setPieOption.bind(this);
		this.lines = this.lines.bind(this);
		this.callData = this.callData.bind(this);
		this.getStartData = this.getStartData.bind(this);
		this.getEndData = this.getEndData.bind(this);
	}

	callData() {
		this.getData(
			(this.state as any).startData + ' 00:00:00',
			(this.state as any).endData + ' 00:00:00',
			this.deviceId,
			this.userId
		);
	}

	componentDidMount() {
		this.getData(
			(this.state as any).startData + ' 00:00:00',
			(this.state as any).endData + ' 00:00:00',
			this.deviceId,
			this.userId
		);
	}

	getStartData(date: any, dateString: string) {
		this.setState({
			startData: dateString,
		});
	}

	getEndData(date: any, dateString: string) {
		this.setState({
			endData: dateString,
		});
	}

	getData = async (startTime: string, endTime: string, deviceId: string, userId: string) => {
		this.setState({
			isShow: true,
		});
		const res = await getOpenUnLockDate({
			deviceId: deviceId,
			startTime: startTime,
			endTime: endTime,
			userUuid: userId,
		});
		// API.postWithAuth('/api/record/getOpenUnLockDate', {
		//     deviceId: deviceId,
		//     startTime: startTime,
		//     endTime: endTime,
		//     userUuid: userId
		// }).then(res => {
		if (res.err === 0) {
			console.log(res);
			this.setSeries(res.data);
			this.lineData.setOption(this.setPieOption(this.seriesData, [], []));
			this.setState({
				isShow: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};

	lines(lin: any) {
		if (lin !== null) {
			this.lineData = echarts.init(lin);
		}
	}

	setSeries = (data: any) => {
		for (let key in data) {
			if (data[key] != null) {
				let temSeries = {
					name: data[key][0][2],
					data: data[key],
					type: 'scatter',
					label: {
						emphasis: {
							show: true,
							formatter: function (param: any) {
								return param.data[3];
							},
							position: 'top',
						},
					},
				};
				this.seriesData.push(temSeries);
			}
		}
		console.log('this.seriesData', this.seriesData);
	};

	//一个基本的echarts图表配置函数
	setPieOption(data: any, xAxisData: any, devicename: any) {
		console.log('总数据', data);
		return {
			title: {
				text: (this.deviceName === 'undefined' ? '' : this.deviceName) + '开门数据统计',
			},
			legend: {
				data: devicename,
			},
			xAxis: {
				splitLine: {
					lineStyle: {
						type: 'dashed',
					},
				},
				type: 'time',
				// splitNumber: 10,
				minInterval: 3600 * 24 * 1000 * 2,
				name: '日期',
			},
			yAxis: {
				splitLine: {
					lineStyle: {
						type: 'dashed',
					},
				},
				type: 'time',
				name: '时间',
				splitNumber: 12,
				minInterval: 3600 * 1000,
				min: function (param: any) {
					return new Date('2018-01-01 00:00:00').getTime();
				},
				max: function (param: any) {
					return new Date('2018-01-01 23:59:59').getTime();
				},
				axisLabel: {
					formatter: function (param: any) {
						let date = new Date(param);
						let h = date.getHours() + '';
						let m = date.getMinutes() + '';
						h = h.length === 1 ? '0' + h : h;
						m = m.length === 1 ? '0' + m : m;
						return h + ':' + m;
					},
				},
			},
			series: data,
		};
	}

	formChange() {
		console.log('筛选的数据:');
	}

	render() {
		return (
			<div>
				<Row gutter={8}>
					<Col className="gutter-row">
						<DatePicker placeholder="请选择开始时间" onChange={this.getStartData} />
					</Col>
					<Col className="gutter-row">
						<DatePicker placeholder="请选择结束时间" onChange={this.getEndData} />
					</Col>
					<Col className="gutter-row">
						<Button
							type="primary"
							id="btnlines"
							onClick={this.callData.bind(this, (this.state as any).uuid)}
						>
							搜索
						</Button>
					</Col>
				</Row>
				<br />
				<div className="content">
					<div className="row ">
						<div className="col-xs-12 div-text-align-center">
							<canvas ref={this.lines} width="1200" height="700" />
						</div>
					</div>

					{(this.state as any).isShow ? (
						<div className="cover">
							<div>
								<i className="icon icon-spin icon-spinner-indicator" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default KaimenList;
