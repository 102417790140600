import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { FormInstance } from 'antd/es/form';
import { Button, Col, DatePicker, Form,Table, InputNumber, Modal, Radio, TreeSelect, Select, Switch, Space } from 'antd';
import { Link } from 'react-router-dom';
import {
    findAllBuilds,
    findBatchList, getRoomAbnormalList,
    jieshuBatch,
    kaiShiBatch,
    randomGetAuditing
} from "../../../services/ZhuhushenheService";
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import moment from "moment";
import * as XLSX from "xlsx";

var msg = require('../../../util/SuspensionMsg');
const BatchList: React.FC = () => {
	useEffect(() => {
		findAllBuild();
	}, []);
	const confirm = Modal.confirm;
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [searchString, setSearchString] = useState('');
	const [visible, setVisible] = useState(false);
	const [treeData, setTreeData] = useState([]);
	const [deadline, setDeadline] = useState('');
	const [startTime, setStartTime] = useState('');
	const [fangshi, setFangshi] = useState('tips');
	const [tips, setTips] = useState('');
	const [limit_days, setLimit_days] = useState('');
	const SHOW_PARENT = TreeSelect.SHOW_PARENT;
	const { RangePicker } = DatePicker;
	const [confirmLoading, setConfirmLoading] = useState(false);
	let randomNum = '';
	let users2 = [];
	const columns = [
		{
			title: '批次',
			dataIndex: 'batchNum',
			key: 'batchNum',
			align: 'center',
		},
		{
			title: '抽查人数',
			dataIndex: 'PeopleNum',
			align: 'center',
			key: 'PeopleNum',
		},
		{
			title: '已通知(通知超时)',
			dataIndex: 'yitongzhi',
			align: 'center',
			key: 'yitongzhi',
			render: (text: any, record: any) => {
				return text + '(' + record.tongzhichaoshi + ')';
			},
		},
		{
			title: '已提交',
			key: 'tijiao',
			dataIndex: 'tijiao',
			align: 'center',
		},
		{
			title: '已通过',
			key: 'yitongguo',
			align: 'center',
			dataIndex: 'yitongguo',
		},
		{
			title: '未通过(上传超时)',
			key: 'weitongguo',
			align: 'center',
			dataIndex: 'weitongguo',
			render: (text: any, record: any) => {
				return record.weitongguo + record.tijiaochaoshi + '(' + record.tijiaochaoshi + ')';
			},
		},
		{
			title: '异常比例',
			key: 'yichaungbili',
			align: 'center',
			dataIndex: 'yichaungbili',
			render: (text: any, record: any) => {
				return record.yitongguo !== 0
					? ((record.weitongguo * 100.0) / record.yitongguo).toFixed(2) + '%'
					: '0%';
			},
		},
		{
			title: '提交限时',
			key: 'limitedTime',
			align: 'center',
			dataIndex: 'limitedTime',
		},
		{
			title: '抽查时间',
			width: '16%',
			align: 'center',
			key: 'deadline',
			dataIndex: 'deadline',
			render: (text: any, record: any) => {
				return (
					<span>
						<div>{'开始：' + record.startTime} </div>
						<div>{'结束：' + record.deadline} </div>
					</span>
				);
			},
		},
		{
			title: '创建时间',
			key: 'creatTime',
			align: 'center',
			dataIndex: 'creatTime',
		},
		{
			title: '操作',
			key: 'action',
			align: 'center',
			width: 230,
			render: (text: string, record: any) => {
				return (
					<div>
						<span>
							<ul className="ant-list-item-action">
								{record.isKaiShi ? (
									<li>已开始</li>
								) : (
									<li
										onClick={() => {
											startBatchConfirm(record.uuid);
										}}
									>
										<a>开始</a>
									</li>
								)}
								{record.isJieshu ? (
									<li>已结束</li>
								) : (
									<li
										onClick={() => {
											showConfirm(record.uuid);
										}}
									>
										<a>结束</a>
									</li>
								)}
								<li>
									<Link
										to={`/face_audit/renlian-shenhei?batchUuid=${record.batchUuid}`}
									>
										详情
									</Link>
								</li>
							</ul>
						</span>
					</div>
				);
			},
		},
	];

	const showConfirm = (uuid: any) => {
		confirm({
			title: '您确定要结束此批次吗？',
			onOk: () => {
				return new Promise(async (resolve, reject) => {
					const res = await jieshuBatch({
						batchUuid: uuid,
					});
					console.log('', res);
					if (res.err === 0) {
						resolve();
						msg.suspensionMsg({ content: '结束成功', type: 'success' });
						if (actionRef.current) {
							actionRef.current.reload();
						}
					} else {
						reject();
						msg.suspensionMsg({ content: res.msg, type: 'danger' });
					}
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const startBatchConfirm = (uuid: any) => {
		confirm({
			title: '您确定要开始此批次吗？',
			onOk() {
				return new Promise(async (resolve, reject) => {
					const res = await kaiShiBatch({
						batchUuid: uuid,
					});
					if (res.err === 0) {
						msg.suspensionMsg({ content: '开始成功', type: 'success' });
						if (actionRef.current) {
							actionRef.current.reload();
						}
						resolve();
					} else {
						msg.suspensionMsg({ content: res.msg, type: 'danger' });
						reject();
						if (actionRef.current) {
							actionRef.current.reload();
						}
					}
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const findAllBuild = async () => {
		const res = await findAllBuilds({});
		if (res.err === 0) {
			console.log('获得楼栋列表andt', res);
			setTreeData(res.data);
		}
	};
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setVisible(true);
			}}
		>
			生成抽查
		</Button>,
	];

	const OnOk = () => {
		form.validateFields().then(async (data: any) => {
			if (startTime === null || startTime === undefined || startTime === '') {
				msg.suspensionMsg({ content: '请输入开始时间', type: 'danger' });
				return;
			}
			if (deadline === null || deadline === undefined || deadline === '') {
				msg.suspensionMsg({ content: '请选择截至时间', type: 'danger' });
				return;
			}
			setConfirmLoading(true);
			const formData: any = {
				TreeSelect: data.TreeSelect.toString(),
				peopleNum: data.peopleNum,
				deadline: deadline,
				startTime: startTime,
				limited: data.limited,
				dayOrTips: data.fangshi,
				tips: data.tips || '',
				limitDays: data.limitDays || '',
				souce: data.souce || 80,
				autoFreeze: data.autoFreeze,
				autoAdd: data.autoAdd
			};
			console.log('data:::a.', formData);
			const res = await randomGetAuditing(formData);
			setConfirmLoading(false);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '抽查成功', type: 'success' });
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: res.msg, type: 'danger' });
			}
		});
	};
	const tProps = {
		treeData: treeData,
		treeCheckable: true,
		showCheckedStrategy: SHOW_PARENT,
		searchPlaceholder: '请选择',
	};

	const changeDate = (date: any, dateString: any) => {
		console.log('deadline', dateString);
		setStartTime(dateString[0]);
		setDeadline(dateString[1]);
	};
    const setExprotData = (selectedRows: any[]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        columns.map((item: any) => {
            if (item.title !== "操作") {
                titles.push(item.title);
            }
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data: [string?] = [];
            columns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
                    } else {
                        data.push("");
                    }
                } else if (item.valueType === "dateTime") {
                    data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "抽查列表");

        /* save to file */
        XLSX.writeFile(wb, "抽查列表.xlsx");
    };
    const rowSelection = {
        fixed: true,
        columnWidth: 100,
        preserveSelectedRowKeys: true,
        selections:[
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            ]
    };
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
                rowKey={"batchUuid"}
                rowSelection={rowSelection}
                operationsBarRender={tableOperationsBarRender()}
                tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                    return (
                        <Space size={24}>
								<span>
									已选 {selectedRowKeys.length} 项
									<a
                                        style={{ marginLeft: 8 }}
                                        onClick={() => {
                                            onCleanSelected();
                                        }}
                                    >
										取消选择
									</a>
								</span>
                        </Space>
                    );
                }}
                tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                    return (
                        <Space size={16}>
                            <Button type={"link"} onClick={() => {
                                setExprotData(selectedRows);
                            }}>导出</Button>
                        </Space>
                    );
                }}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await findBatchList({
						page: params.current,
						size: params.pageSize,
						search: searchString,
					});
					randomNum = res.randomNum;
					if (res.err === 0) {
						users2 = res.data;
					} else {
						msg.suspensionMsg({ content: res.msg });
					}
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				visible={visible}
				width={800}
				title={'生成抽查'}
				confirmLoading={confirmLoading}
				onOk={OnOk}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'抽查范围'} name={'TreeSelect'} rules={[{ required: true }]}>
						<TreeSelect {...tProps} />
					</FormItem>
					<FormItem label={'抽查人数'} name={'peopleNum'} rules={[{ required: true }]}>
						<InputNumber min={1} max={500} />
					</FormItem>
					<FormItem label={'抽查时间'} name={'dateTime'} rules={[{ required: true }]}>
						<RangePicker
							showTime={{ format: 'HH:mm:ss' }}
							format="YYYY-MM-DD HH:mm:ss"
							placeholder={['生效时间', '截止时间']}
							onChange={(data: any, dateString: any) => {
								changeDate(data, dateString);
							}}
						/>
					</FormItem>
					<FormItem label={'限时提交天数'} tooltip={'收到通知后多少天内能提交'} name={'limited'} rules={[{ required: true }]}>
						<InputNumber min={1} placeholder={'天'} />
					</FormItem>
					{/*<FormItem label={'限制方式'} name={'fangshi'} initialValue={fangshi} rules={[{ required: true }]} >*/}
					{/*	<Select onChange={(e: any) => {setFangshi(e)}}>*/}
					{/*		<Select.Option value={"tips"}>按次数</Select.Option>*/}
					{/*		/!*<Select.Option value={"limitDays"}>按天数</Select.Option>*!/*/}
					{/*	</Select>*/}
					{/*</FormItem>*/}
					<FormItem label={'限提交次数'} name={"tips"} hidden={fangshi !== 'tips'} rules={[{ required: fangshi === 'tips' }]} >
						<InputNumber/>
					</FormItem>
					<FormItem label={'限提交天数'} name={"limitDays"}  hidden={fangshi !== 'limitDays'} rules={[{ required: fangshi === 'limitDays' }]} >
						<InputNumber/>
					</FormItem>
					<FormItem label={'识别阈值'} name={"source"} initialValue={80} rules={[{ required: true }]} >
						<InputNumber/>
					</FormItem>
					<FormItem label={'自动冻结'} name={"autoFreeze"} initialValue={false} rules={[{ required: true }]} >
						<Switch/>
					</FormItem>
					<FormItem label={'添加上次异常人员'} name={"autoAdd"} initialValue={false} rules={[{ required: true }]} >
						<Switch/>
					</FormItem>
					{/*<FormItem label={'限制方式'} name={'fangshi'} rules={[{ required: true }]}>*/}
					{/*	<Radio.Group>*/}
					{/*		<Radio value="tips">*/}
					{/*			<InputNumber*/}
					{/*				min={1}*/}
					{/*				style={{ width: 120 }}*/}
					{/*				placeholder={'限提交次数'}*/}
					{/*				onChange={(value: any) => {*/}
					{/*					setTips(value);*/}
					{/*				}}*/}
					{/*			/>*/}
					{/*		</Radio>*/}
					{/*		<Radio value="limitDays">*/}
					{/*			<InputNumber*/}
					{/*				min={1}*/}
					{/*				style={{ width: 120 }}*/}
					{/*				placeholder={'限提交天数'}*/}
					{/*				onChange={(value: any) => {*/}
					{/*					setLimit_days(value);*/}
					{/*				}}*/}
					{/*			/>*/}
					{/*		</Radio>*/}
					{/*	</Radio.Group>*/}
					{/*</FormItem>*/}
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default BatchList;
