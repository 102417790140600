import Api from '../data/API';

export async function findDrawPrizeByUuid(params: any) {
	return Api.postWithAuth('/api/luck/findDrawPrizeByUuid', params);
}

export async function deleteDraw(params: any) {
	return Api.postWithAuth('/api/luck/deleteDraw', params);
}

export async function addDrawPrize(params: any) {
	return Api.postWithAuth('/api/luck/addDrawPrize', params);
}

export async function updDrawPrize(params: any) {
	return Api.postWithAuth('/api/luck/updDrawPrize', params);
}

export async function updDrawUser(params: any) {
	return Api.postWithAuth('/api/luck/updDrawUser', params);
}

export async function luckDraw(params: any) {
	return Api.postWithAuth('/api/luck/luckDraw', params);
}

export async function saveLuckDrawPrizeUser(params: any) {
	return Api.postWithAuth('/api/luck/saveLuckDrawPrizeUser', params);
}

export async function getLuckDraw(params: any) {
	return Api.postWithAuth('/api/luck/getLuckDraw', params);
}

export async function findHuoDongFormUser(params: any) {
	return Api.postWithAuth('/api/huodong/findHuoDongFormUser', params);
}

export async function findAllHuoDongByZhuangTai(params: any) {
	return Api.postWithAuth('/api/huodong/findAllHuoDongByZhuangTai', params);
}
