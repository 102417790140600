import React, { useEffect, useState } from 'react';
import RoomStatusComponents from '../components/RoomStatusComponents';
const FangtaiEmbed: React.FC = (props: any) => {
	return (
		<div style={{ backgroundColor: '#fff' }}>
			<RoomStatusComponents showType={'look'} />
		</div>
	);
};
export default FangtaiEmbed;
