import * as React from 'react';
import GSTable from '../../../components/GSTable';
import { Select, Input, DatePicker, Button, Tag, Table, Space, Card, Row, Col, Statistic, message, Spin, TreeSelect } from 'antd';
import { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { getHeatlhRecordList } from '../../../services/TemperatureRecordsService';
import {getCodeList} from '../../../services/HeadlthCodeService'
import { ActionType } from '@ant-design/pro-table';
import * as XLSX from 'xlsx';
import CustomRangePicker from '../../../components/CustomRangePicker';
import { getAllDevicesGroup, getDevicesByAll } from "../../../services/ZhiNengMenJinService";
import GsTreeSelect from "../../../components/GsTreeSelect";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
let search: string = '';

const idCardEnum = {
	通过: <span style={{ color: '#008000' }}>通过</span>,
	未通过: <span style={{ color: '#ff0000' }}>未通过</span>,
};
const TemperatureRecords: React.FC = () => {
	const actionRef = useRef<ActionType>();

	const [selectType, setSelectType] = useState('');
	const [startTime, setStartTime] = useState(moment().subtract(7, 'd').hours(0).minute(0).second(0));
	const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
	const [allDeviceId, setAllDeviceId] = useState("");

	const [deviceEnum, setDeviceEnum] = useState<any>({});
	const [deviceId, setDeviceId] = useState('');
	const [faceDevice, setFaceDevice] = useState([]);
	const [quanXuan, setQuanXuan] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [devices, setDevices] = useState([]);
	const [valueEnum, setValueEnum] = useState({
		"-6": <span style={{ color: "#ff0000" }}>健康宝数据解析异常(-6)</span>,
		"-5": <span style={{ color: "#ff0000" }}>健康码获取异常(-5)</span>,
		"-4": <span style={{ color: "#ff0000" }}>无身份证信息(-4)</span>,
		"-3": <span style={{ color: "#ff0000" }}>获取超时(-3)</span>,
		"-2": <span style={{ color: "#ff0000" }}>网络异常(-2)</span>,
		"-1": <span>未开启健康码验证(-1)</span>,
		// 0: <span style={{ color: "#008000" }}>绿码，状态正常(0)</span>,
		// 10: <span style={{ color: "#008000" }}>绿码，白名单(10)</span>,
		// 20: <span style={{ color: "#008000" }}>绿码，入境完成隔离或行程轨迹风险低(20)</span>,
		// 30: <span style={{ color: "#008000" }}>绿码，环京通勤人员(30)</span>,
		// 40: <span style={{ color: "#008000" }}>绿码，无14日内核酸结果为阴性结果(40)</span>,
		// 1: <span style={{ color: "#ff0000" }}>黄码，社区隔离中(1)</span>,
		// 2: <span style={{ color: "#ff0000" }}>红码，隔离或密切接触人员！！！(2)</span>,
		// 3: <span style={{ color: "#ff0000" }}>黄码，此人近期有可能去过中高风险地区</span>,
		// 4: <span style={{ color: "#ff0000" }}>黄码，限制进京</span>,
		// 11: <span style={{ color: "#ff0000" }}>“进京后72小时内未做核酸人员、出现发热等“十一类症状”去小诊所后72小时内未做核酸人员</span>,
		// 31: <span style={{ color: "#ff0000" }}>异常，行程轨迹中存在中高风险地区(31)</span>,
		// 41: <span style={{ color: "#ff0000" }}>异常，入境人员进京但未在社区登记解除隔离(41)</span>,
		// 51: <span style={{ color: "#ff0000" }}>异常，从含中高风险地区的地级市进京人员无进京时间前48小时之后的核酸(51)</span>,
		// 21: <span style={{ color: "#ff0000" }}>非健康宝注册用户(21)</span>,
		// 88: <span style={{ color: "#ff0000" }}>请到健康宝进行行程申报(88)</span>,
		// " ": <span style={{ color: "#ff0000" }}>请到健康宝进行行程申报</span>
	})

	useEffect(() => {
		getSelectDeviceList().then()
		loadCodeList().then()
	}, [])
	const column = [
		{
			title: '姓名',
			dataIndex: 'name',
		},
		{
			title: '设备名称',
			dataIndex: "deviceName",
			// valueEnum: deviceEnum
		},
		{
			title: '部门名称',
			dataIndex: 'departmentName',
		},
		{
			title: '身份证号',
			dataIndex: 'idCard',
		},
		{
			title: '手机号',
			dataIndex: 'phoneNo',
		},
		{
			title: '体温',
			dataIndex: 'temperature',
			render: (text: string) => {
				if (text === '-') {
					return <span>-</span>;
				}
				return <span style={{ color: parseFloat(text) > 37.3 ? '#ff0000' : '#333' }}>{text}℃</span>;
			},
		},
		{
			title: '健康码',
			valueEnum: valueEnum,
			dataIndex: 'healthCheck',
		},
		{
			title: '核酸天数',
			dataIndex: 'acidNum'
		},
		{
			title: '人证核验',
			valueEnum: idCardEnum,
			dataIndex: 'idCheck',
		},
		{
			title: '记录时间',
			valueType: 'dateTime',
			dataIndex: 'createdTime',
		},
	];

	const getDeviceList = async () => {
		let params = {
			typecode: "0701",
			wuyeid: localStorage.getItem("wyUuid")
		};
		let rsp = await getDevicesByAll(params);
		console.log("rsp::::", rsp);
		if (rsp.err === 0) {
			let deviceId: string[] = [];
			let valueEnum: any = {};
			rsp.data.map((item: any) => {
				if (item.typecode === "0701") {
					deviceId.push(item.deviceid);
					valueEnum[item.deviceid] = item.description;
				}
			});
			setDeviceEnum(valueEnum);
			setAllDeviceId(deviceId.toString());
			setFaceDevice(rsp.data)
		}
	};

	const loadCodeList = async() => {
		let rsp = await getCodeList({})
		if (rsp.err === 0) {
			(rsp.data || []).map((item: any) => {
				valueEnum[item.code] = <span style={{ color: item.status === 'SUCCESS' ? "#008000" : "#ff0000"}}>{item.msg}</span>
			})
			console.log('valueEnum::::', valueEnum)
			setValueEnum({...valueEnum})
		}
	}

	const getSelectDeviceList = async () => {
		const res = await getAllDevicesGroup({typeCode: '0701'});
		if (res.err !== 0) {
			message.error(res.msg);
			return;
		}
		let data: any = [];
		data.push({key: '全部设备', title: '全部设备', value: ''});
		res.data.map((item: any) => {
			item["selectable"] = false
		})
		data = data.concat(res.data)
		setDevices(data);
		let valueEnum: any = {};
		let deviceId: string[] = [];
		res.data.map((item: any) => {
			if (item.children) {
				item.children.map((clidrenItem: any) => {
					if (clidrenItem.value.includes(":::")) {
						valueEnum[clidrenItem.value.split(":::")[1]] = clidrenItem.title;
						deviceId.push(clidrenItem.value.split(":::")[1]);
					} else {
						valueEnum[clidrenItem.value] = clidrenItem.title;
						deviceId.push(clidrenItem.value);
					}


				})
			}
		})
		setDeviceEnum(valueEnum);
		setAllDeviceId(deviceId.toString());
	};

	const tableSearchBarRender = () => [
		<GsTreeSelect
			treeData={devices}
			defaultValue={deviceId}
			onChange={(ev: any) => {
				if (ev) {
					let data: any = [];
					ev.map((item: string) => {
						if (item.includes(":::")) {
							data.push(item.split(":::")[1])
						} else {
							data.push(item)
						}
					})
					setDeviceId(data.toString());
				} else {
					setDeviceId('');
				}

			}}
		/>,
		<Select style={{width: 150}} defaultValue={selectType} onChange={(e: string) => setSelectType(e) }>
			<Select.Option value={''}>全部</Select.Option>
			<Select.Option value={'2'}>隔离或密接人员</Select.Option>
			<Select.Option value={'31'}>经过中高风险地区</Select.Option>
			<Select.Option value={'3'}>可能经过中高风险地区</Select.Option>
			<Select.Option value={'1'}>社区隔离中</Select.Option>
			<Select.Option value={'41'}>入境人员未完成隔离</Select.Option>
			<Select.Option value={'21,88, '}>请进行行程申报</Select.Option>
			<Select.Option value={'11'}>进京后72小时内未做核酸人员、出现发热等“十一类症状”去小诊所后72小时内未做核酸人员</Select.Option>
			<Select.Option value={'-4'}>无身份证信息</Select.Option>
			<Select.Option value={'-99'}>体温异常</Select.Option>
		</Select>,
		<CustomRangePicker
			onChange={(startTime, endTime) => {
				setStartTime(startTime);
				setEndTime(endTime);
			}}
			selectType={3}
			startTime={startTime}
			endTime={endTime}
		/>,
		<Input
			onChange={(e) => {
				search = e.target.value;

			}}
			placeholder="姓名搜索"
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	const getData = async (param: any) => {
		console.log('state:::::', startTime.format('YYYY-MM-DD 00:00:00'), endTime.format('YYYY-MM-DD 23:59:59'));
		let data = {
			selectType: selectType,
			search: search,
			startTime: startTime.format('YYYY-MM-DD HH:mm:00'),
			endTime: endTime.format('YYYY-MM-DD HH:mm:59'),
			page: param.current,
			size: param.pageSize,
			deviceId: deviceId
		};
		let rsp = await getHeatlhRecordList(data);
		if (rsp.err === 0) {
			if (quanXuan) {
				let uuids: any = [];
				rsp.data.data.map((item: any) => {
					uuids.push(item.uuid);
				});
				setSelectedRowKeys(uuids);
				setTotal(rsp.data.total);
			}
			setTotal(rsp.data.total);
			return {
				data: rsp.data.data,
				total: rsp.data.total,
			};
		} else {
			return {
				data: [],
				total: 0,
			};
		}
	};

	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setLoading(true);
		if (quanXuan) {
			let data = {
				selectType: selectType,
				search: search,
				deviceId: deviceId,
				startTime: startTime.format('YYYY-MM-DD 00:00:00'),
				endTime: endTime.format('YYYY-MM-DD 23:59:59'),
			};
			let rsp = await getHeatlhRecordList(data);
			if (rsp.err === 0) {
				setExprotData(rsp.data.data);
			} else {
				message.error(rsp.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
		setLoading(false);
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		column.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			column.map((item) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}

					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '防疫记录');

		/* save to file */
		XLSX.writeFile(wb, '防疫记录.xlsx');
	};


	return (
		<>
			<Spin spinning={loading}>
				<GSTable
					actionRef={actionRef}
					rowSelection={{
						fixed: true,
						columnWidth: 100,
						preserveSelectedRowKeys: true,
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys: any, selectedRows: any) => {
							setSelectedRowKeys(selectedRowKeys);
							setQuanXuan(false);
						},
						selections: [
							{
								key: 'SELECT_CURRENT_ALL',
								text: '全选当前页面',
								onSelect: (changableRowKeys: any) => {
									setQuanXuan(false);
									setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
								},
							},
							{
								key: 'SELECT_ALL',
								text: '全选全部页面',
								onSelect: (changableRowKeys: any) => {
									setQuanXuan(true);
									setSelectedRowKeys(changableRowKeys);
								},
							},
						],
					}}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
											setQuanXuan(false);
										}}
									>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						console.log('tableAlertOptionRender::::', selectedRows);
						return (
							<Space size={16}>
								<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							</Space>
						);
					}}
					rowKey={'uuid'}
					searchBarRender={tableSearchBarRender()}
					// tableExtraRender={tableExtraRender}
					options={{ density: false }}
					columns={column}
					request={getData}
				/>
			</Spin>
		</>
	);
};
export default TemperatureRecords;
