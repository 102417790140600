import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { DataContext } from '../controlPlatform/CurrentPlatform';
import { ColumnChartContext } from '../controlPlatform/CurrentPhoneBrandChart';
import BarChart from '../../components/BarChart';
import { getRoomStatistics } from '../../services/StatisticsService';
import ColumnChart from '../../components/ColumnChart';
import yingShou from '../../img/yingshou.png';
import shishou from '../../img/shishou.png';
import weijiao from '../../img/weijiao.png';
import LineChart from '../../components/LineChart';
import moment from 'moment';
import { getXunGengXunJianLineChartData } from '../../services/patrolService';

const PropertyReport: React.FC = (props) => {
	const [roomData, setRoomData] = useState({ total: 0, totalAcreage: 0, totalVacant: 0 });
	const [barData, setBarData] = useState<[any?]>([{
		year: '2022-07-13',
		value: 8,
		type: '已处理'
	},
		{
			year: '2022-07-14',
			value: 5,
			type: '已处理'
		},
		{
			year: '2022-07-15',
			value: 6,
			type: '已处理'
		},
		{
			year: '2022-07-16',
			value: 8,
			type: '已处理'
		},
		{
			year: '2022-07-17',
			value: 10,
			type: '已处理'
		},
		{
			year: '2022-07-18',
			value: 9,
			type: '已处理'
		},
		{
			year: '2022-07-19',
			value: 11,
			type: '已处理'
		},
		{
			year: '2022-07-13',
			value: 1,
			type: '未处理'
		},
		{
			year: '2022-07-14',
			value: 2,
			type: '未处理'
		},
		{
			year: '2022-07-15',
			value: 3,
			type: '未处理'
		},
		{
			year: '2022-07-16',
			value: 1,
			type: '未处理'
		},
		{
			year: '2022-07-17',
			value: 0,
			type: '未处理'
		},
		{
			year: '2022-07-18',
			value: 1,
			type: '未处理'
		},
		{
			year: '2022-07-19',
			value: 2,
			type: '未处理'
		}
	]);
	const [deviceData, setDeviceData] = useState<[any?]>([{
			year: '2022-07-13',
			value: 1,
			type: '设备离线'
		},
		{
			year: '2022-07-14',
			value: 2,
			type: '设备离线'
		},
		{
			year: '2022-07-15',
			value: 1,
			type: '设备离线'
		},
		{
			year: '2022-07-16',
			value: 3,
			type: '设备离线'
		},
		{
			year: '2022-07-17',
			value: 1,
			type: '设备离线'
		},
		{
			year: '2022-07-18',
			value: 0,
			type: '设备离线'
		},
		{
			year: '2022-07-19',
			value: 0,
			type: '设备离线'
		},
		{
			year: '2022-07-13',
			value: 1,
			type: '数据同步异常'
		},
		{
			year: '2022-07-14',
			value: 2,
			type: '数据同步异常'
		},
		{
			year: '2022-07-15',
			value: 3,
			type: '数据同步异常'
		},
		{
			year: '2022-07-16',
			value: 1,
			type: '数据同步异常'
		},
		{
			year: '2022-07-17',
			value: 0,
			type: '数据同步异常'
		},
		{
			year: '2022-07-18',
			value: 1,
			type: '数据同步异常'
		},
		{
			year: '2022-07-19',
			value: 2,
			type: '数据同步异常'
		}
	]);
	const [carData, setCarData] = useState<[any]>([
		{ name: '交房验房', x: '2022-01', y: 76 },
		{ name: '交房验房', x: '2022-02', y: 98 },
		{ name: '交房验房', x: '2022-03', y: 86 },
		{ name: '交房验房', x: '2022-04', y: 82 },
		{ name: '交房验房', x: '2022-05', y: 84 },
		{ name: '交房验房', x: '2022-06', y: 75 },
		{ name: '退房验房', x: '2022-01', y: 77 },
		{ name: '退房验房', x: '2022-02', y: 73 },
		{ name: '退房验房', x: '2022-03', y: 88 },
		{ name: '退房验房', x: '2022-04', y: 85 },
		{ name: '退房验房', x: '2022-05', y: 81 },
		{ name: '退房验房', x: '2022-06', y: 93 }]);
	const [xunData, setXunData] = useState<[any]>([
		{ name: '设备巡检', x: '2022-07-13', y: 18 },
		{ name: '设备巡检', x: '2022-07-14', y: 19 },
		{ name: '设备巡检', x: '2022-07-15', y: 20 },
		{ name: '设备巡检', x: '2022-07-16', y: 22 },
		{ name: '设备巡检', x: '2022-07-17', y: 18 },
		{ name: '设备巡检', x: '2022-07-18', y: 19 },
		{ name: '设备巡检', x: '2022-07-19', y: 20 },
		{ name: '安保巡更', x: '2022-07-13', y: 90 },
		{ name: '安保巡更', x: '2022-07-14', y: 113 },
		{ name: '安保巡更', x: '2022-07-15', y: 105 },
		{ name: '安保巡更', x: '2022-07-16', y: 119 },
		{ name: '安保巡更', x: '2022-07-17', y: 120 },
		{ name: '安保巡更', x: '2022-07-18', y: 117 },
		{ name: '安保巡更', x: '2022-07-19', y: 121 },
		{ name: '异常上报', x: '2022-07-13', y: 1 },
		{ name: '异常上报', x: '2022-07-14', y: 0 },
		{ name: '异常上报', x: '2022-07-15', y: 0 },
		{ name: '异常上报', x: '2022-07-16', y: 0 },
		{ name: '异常上报', x: '2022-07-17', y: 1 },
		{ name: '异常上报', x: '2022-07-18', y: 0 },
		{ name: '异常上报', x: '2022-07-19', y: 0 }
		]);
	useEffect(() => {
		getRoomData().then();
	}, []);
	const getRoomData = async () => {
		let rsp = await getRoomStatistics({});
		if (rsp.err === 0) {
			setRoomData(rsp.data);
		}
	};

	const config = {
		xField: 'year',
		yField: 'value',
		isGroup: true,
		color: ['#ff4d4f', '#faad14'],
		seriesField: 'type',
		label: {
			// 可手动配置 label 数据标签位置
			position: 'middle',
			// 'top', 'bottom', 'middle'
			// 可配置附加的布局方法
			layout: [
				// 柱形图数据标签位置自动调整
				{
					type: 'interval-adjust-position'
				}, // 数据标签防遮挡
				{
					type: 'interval-hide-overlap'
				}, // 数据标签文颜色自动调整
				{
					type: 'adjust-color'
				}
			]
		}
	};
	useEffect(() => {
		getLineChartData().then()
	}, [])
	const getLineChartData = async () => {
		let params = {
			startTime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
			endTime: moment().subtract(1, 'd').format('YYYY-MM-DD')
		};
		let rsp = await getXunGengXunJianLineChartData(params);
		if (rsp.err === 0) {
			setXunData(rsp.data)
		} else {
			setXunData([])
		}
	};
	return (
		<div className="content-big">
			<Row gutter={12}>
				<Col span={6}>
					<Card title={'资产总数'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={roomData.total}
										   valueStyle={{ color: '#FFBF24', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'资产总面积'} valueStyle={{ color: '#000000', fontSize: 14 }}
										   value={211185}
										   suffix={'m²'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'闲置率'} valueStyle={{ color: '#000000', fontSize: 14 }}
										   value={(4.6).toFixed(2)}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'业户总数'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={Math.ceil(roomData.total * 93.4 / 100)}
										   valueStyle={{ color: '#FD5F26', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'租户人数'} valueStyle={{ color: '#000000', fontSize: 14 }}
										   value={Math.ceil(roomData.total * 93.4 / 100)}
										   suffix={'人'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'出租率'} valueStyle={{ color: '#000000', fontSize: 14 }} value={93.40}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'设备总数'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={3152}
										   valueStyle={{ color: '#41DCEA', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'运行中设备'} valueStyle={{ color: '#000000', fontSize: 14 }} value={3152}
										   suffix={'台'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'异常运行率'} valueStyle={{ color: '#000000', fontSize: 14 }} value={0}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'服务请求'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={13370}
										   valueStyle={{ color: '#5BE84E', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'未处理事件'} valueStyle={{ color: '#000000', fontSize: 14 }} value={9}
										   suffix={'件'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'事件处理率'} valueStyle={{ color: '#000000', fontSize: 14 }}
										   value={((13370 - 9) / 13370 * 100).toFixed(2)}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			{/*<Row gutter={16}>*/}
			{/*	<Col span={8}>*/}
			{/*		<div style={{display: 'flex', flex: 1, height: 104, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center'}}>*/}
			{/*			<div>*/}
			{/*				<img src={yingShou} style={{width: 64, height: 64}} alt={"应收"}/>*/}
			{/*			</div>*/}

			{/*			<div style={{marginLeft: 30, color: '#000', fontWeight: 'bold', fontSize: 18}}>*/}
			{/*				<div>5000元</div>*/}
			{/*				<div style={{fontSize: 14}}>应收金额</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</Col >*/}
			{/*	<Col span={8}>*/}
			{/*		<div style={{display: 'flex', flex: 1, height: 104, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center'}}>*/}
			{/*			<div>*/}
			{/*				<img src={shishou} style={{width: 64, height: 64}} alt={"实收"}/>*/}
			{/*			</div>*/}

			{/*			<div style={{marginLeft: 30, color: '#000', fontWeight: 'bold', fontSize: 18}}>*/}
			{/*				<div>5000元</div>*/}
			{/*				<div style={{fontSize: 14}}>实收金额</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</Col>*/}
			{/*	<Col span={8}>*/}
			{/*		<div style={{display: 'flex', flex: 1, height: 104, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center'}}>*/}
			{/*			<div>*/}
			{/*				<img src={weijiao} style={{width: 64, height: 64}} alt={"未缴"}/>*/}
			{/*			</div>*/}

			{/*			<div style={{marginLeft: 30, color: '#000', fontWeight: 'bold', fontSize: 18}}>*/}
			{/*				<div>0元</div>*/}
			{/*				<div style={{fontSize: 14}}>未缴金额</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</Col>*/}
			{/*</Row>*/}
			<Row>
				<Col span={12}>
					<Card title={'报修统计'}>
						<DataContext.Provider value={barData}>
							<BarChart/>
						</DataContext.Provider>
					</Card>
				</Col>
				<Col span={12}>
					<Card title={'交验房统计'}>
						<DataContext.Provider value={carData}>
							<LineChart/>
						</DataContext.Provider>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Card title={'巡更巡检统计'}>
						<DataContext.Provider value={xunData}>
							<LineChart/>
						</DataContext.Provider>
					</Card>
				</Col>
				<Col span={12}>
					<Card title={'设备异常统计'}>
						<ColumnChartContext.Provider value={deviceData}>
							<ColumnChart {...config}/>
						</ColumnChartContext.Provider>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default PropertyReport;
