import * as React from 'react';
import { Card, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getHealthData } from '../services/StatisticsService';
import PieChart from './PieChart';
import { ColumnChartContext } from '../pages/controlPlatform/CurrentPhoneBrandChart';
import ColumnChart from './ColumnChart';
import { Column } from '@ant-design/charts';
const typeEnum = {
	success: '状态正常',
	huanJing: '环京人员',
	keNengGaoWei: '可能经过中高风险地区',
	gaoWei: '经过高危地区',
	otherFail: '入境人员',
	huangFail: '社区隔离',
	hongFail: '隔离或密接人员',
	tiWenFail: '体温异常',
	xinchengweishengbao: '行程未申报',
	wuShenfengZheng: '无身份证信息'
};
const VerificationDataPieChart: React.FC = () => {
	const [selectType, setSelectType] = useState(1);
	let startTime = moment().subtract(7, 'd');
	let endTime = moment();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>([]);

	useEffect(() => {
		getData();
	}, []);

	const changeSelectType = (e: number) => {
		setSelectType(e);
		switch (e) {
			case 1:
				startTime = moment().subtract(6, 'd');
				endTime = moment();
				break;
			case 2:
				startTime = moment().subtract(14, 'd');
				endTime = moment();
				break;
			case 3:
				startTime = moment().subtract(29, 'd');
				endTime = moment();
				break;
		}
		getData();
	};

	const getData = async () => {
		setLoading(true);
		let params = {
			startTime: startTime.format('YYYY-MM-DD 00:00:00'),
			endTime: endTime.format('YYYY-MM-DD 23:59:59'),
		};
		setLoading(true);
		let rsp = await getHealthData(params);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
		}
		let data = rsp.data;
		createChartData(data);
	};

	const createChartData = (data: any) => {
		let keys = Object.keys(data);
		let chartData: any = [];
		keys.map((key: string) => {
			if (typeEnum[key]) {
				chartData.push({
					x: typeEnum[key],
					y: data[key],
				});
			}
		});
		console.log('data:::::', chartData);
		setData(chartData);
	};

	const tabBarExtrContent = () => (
		<Select defaultValue={selectType} onChange={changeSelectType}>
			<Select.Option value={1}>最近7天</Select.Option>
			<Select.Option value={2}>最近15天</Select.Option>
			<Select.Option value={3}>最近30天</Select.Option>
		</Select>
	);
	const config = {
		data: data,
		xField: 'x',
		yField: 'y',
		color: function color(_ref: any) {
			let type = _ref.x;
			console.log('type::::', type);
			switch (type) {
				case '绿码，状态正常':
				case '绿码，环京人员':
					return '#52C41A';
				case '体温异常':
				case '经过高危地区':
				case '可能经过中高风险地区':
				case '入境人员':
				case '社区隔离':
					return '#FAAD14';
				case '隔离或密接人员':
					return '#E82D2D';
				case '无身份证信息':
				case '行程未申报':
					return 'RGBA(0,0,0,0.4)'
			}
		},
		label: {
			position: 'middle',
			style: {
				fill: '#FFFFFF',
				opacity: 0.6,
			},
		},
		meta: {
			x: { alias: '类型' },
			y: { alias: '人次' },
		},
		yAxis: {
			grid: {
				line: {
					style: {
						lineWidth: 0,
					},
				},
			},
		},
	};
	return (
		<Card extra={tabBarExtrContent()} title={'累计数据统计'} loading={loading} bodyStyle={{ paddingLeft: 15 }}>
			<Column {...config} />
		</Card>
	);
};
export default VerificationDataPieChart;
