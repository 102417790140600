import React, { useEffect, useRef, useState } from 'react';
import { editQuestionnaire } from '../../../services/QuestionnaireService';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space, AutoComplete,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import {
	addDebitNote,
	createBillRefund,
	deleteChargeBill,
	findAll,
	findRefundRecordList,
	refundCancel,
	refundCompletion,
	updateBill,
} from '../../../services/PayCostManagementService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import AuthorizedView from '../../../util/AuthorizedView';
import { findFee } from '../../../services/orderService';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
import * as XLSX from 'xlsx';
import {findAllRoom, getHouseholdInfo} from "../../../services/room";
const Option = Select.Option;
interface Params {
	state?: string;
	billType?: string;
}
let timeout: any = null ;
const BillRefundRecord: React.FC<Params> = (props: any) => {
	useEffect(() => {
		getFee();
	}, []);

	const confirm = Modal.confirm;
	let msg = require('../../../util/SuspensionMsg');
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const [searchInput, setSearchInput] = useState('');
	const [visible, setVisible] = useState(false);
	const [Stime, setStime] = useState('');
	const [Etime, setEtime] = useState('');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [updateForm] = Form.useForm<FormInstance>();
	const [billRefund, setbillRefund] = useState('1');
	const [receivableDate, setreceivableDate] = useState('');
	const [option, setOption] = useState([])
	const getRooms = async (e: string) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		if (e) {
			timeout = setTimeout(async () => {
				const formData = {
					search: e,
				};
				const res = await getHouseholdInfo(formData);
				if (res.err == 0) {
					let options = [];
					res.data.map(item => {
						options.push({value: item.uuid, label: item.roomNumber + "-" + item.householdName})
					})
					setOption(options)
				}
			}, 2000)

		} else {
			// setRoomList([])
		}

	};
	const columns = [
		{
			title: '房间',
			dataIndex: 'room_id',
		},
		{
			title: '客户',
			dataIndex: 'cust_name',
		},
		{
			title: '合同号',
			dataIndex: 'contract_no',
		},
		{
			title: '手机号',
			dataIndex: 'phone',
		},
		{
			title: '退款创建日期',
			dataIndex: 'create_time',
			valueType: 'dateTime',
		},
		{
			title: '操作',
			width: 200,
			fixed: 'right',
			key: 'action',
			render: (text: string, record: any) => (
				<>
					<Space>
						<a
							hidden={record.bill_refund == '2'}
							onClick={() => {
								refundSuccess(record);
							}}
						>
							退款成功
						</a>
						<a
							hidden={record.bill_refund == '2'}
							onClick={() => {
								refundCancelItem(record);
							}}
						>
							取消退款
						</a>
						<a target={'_blank'} href={'/pay_cost_management/refund_bill?roomId=' + record.room_id
						+'&contractNo='+record.contract_no +'&phone='+record.phone+'&billRefund='+record.bill_refund+'&createTime='+moment(record.create_time).format('YYYY-MM-DD HH:mm:ss')}>
							打印
						</a>
					</Space>
				</>
			),
		},
	];
	const refundSuccess = (item: any) => {
		confirm({
			title: '您确定退款成功吗?',
			// okType: 'danger',
			onOk: async () => {
				const formData = {
					roomNumber: item.room_id,
					billRefund: item.bill_refund,
					contractNo: item.contract_no,
					phone: item.phone,
					createTime:moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')
				}
				const res = await refundCompletion(formData);
				console.log('.....', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '修改成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};


	const refundCancelItem = (item: any) => {
		confirm({
			title: '您确定取消退款吗?',
			// okType: 'danger',
			onOk: async () => {
				const formData = {
					roomNumber: item.room_id,
					billRefund: item.bill_refund,
					contractNo: item.contract_no,
					phone: item.phone,
					createTime:moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')
				}
				const res = await refundCancel(formData);
				console.log('.....', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '取消成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const tableSearchBarRender = () => [
		<div hidden={DAL.dal.isNotNull(props.state)}>
			<Select
				placeholder={'退款状态'}
				value={billRefund}
				onChange={(value: any) => {
					setbillRefund(value);
				}}
			>
				<Option value={'1'}>处理中</Option>
				<Option value={'2'}>退款成功</Option>
				{/* <Option value={'5'}>退款失败</Option> */}
			</Select>
		</div>,
		<Input
			placeholder="姓名,账号,房间号"
			onChange={(event) => {
				setSearchInput(event.target.value);
			}}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];

	const deleteItem = (value: any) => {
		confirm({
			title: '您确定要删除此缴费单吗?',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteChargeBill({ uuid: value.uuid });
				console.log('.....', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const getFee = async () => {
		const res = await findFee({});
		console.log('科目列表', res);
		if (res.err === 0) {
			// setFee(res.data);
		}
	};

	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			let value = data.subjectName.split(',');
			let formData: any = {
				name: value[1],
				loginId: data.loginId,
				actualAmount: data.actualAmount,
				Stime: Stime,
				Etime: Etime,
				feeId: value[0],
				roomNumber: data.roomNumber,
				contractNo: data.contractNo,
				receivableDate,
			};
			console.log('data::;', formData);
			const res = await addDebitNote(formData);
			if (res.err === 0) {
				if (actionRef.current) {
					actionRef.current.reload();
				}
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				setVisible(false);
			} else {
				message.warning(res.msg);
			}
		});
	};

	const tableOperationsBarRender = () => [
		<div hidden={DAL.dal.isNotNull(props.state)}>
		{/*<AuthorizedView needAuthority={'payment_management:debit_note:add'}>*/}
			{/*<Button*/}
			{/*	type="primary"*/}
			{/*	onClick={() => {*/}
			{/*		setVisible(true);*/}
			{/*	}}*/}
			{/*>*/}
			{/*	新增退款单*/}
			{/*</Button>*/}
		{/*</AuthorizedView>,*/}
		</div>,
	];
	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setExprotData(selectedRows);
	};

	const saveRefund = () => {
		form.validateFields().then(async (data: any) => {
			const res = await createBillRefund({ refundData: JSON.stringify(data.refundData) });
			if (res.err == 0) {
				message.success('创建成功');
				setselectedRowKeys([]);
				actionRef.current.reloadAndRest();
			} else {
				message.error(res.msg);
			}
		});
	};
	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '缴费单');
		/* save to file */
		XLSX.writeFile(wb, '缴费单.xlsx');
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				rowKey="contract_no"
				rowSelection={{
					fixed: true,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setselectedRowKeys(selectedRowKeys);
					},
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							{/* <a onClick={() => createdRefund(selectedRows)}>生成退款</a> */}
						</Space>
					);
				}}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {
						page: params.current,
						size: params.pageSize,
						state: 'true',
						search: searchInput,
						billType: '退款类',
						billRefund: billRefund,
					};
					const res = await findRefundRecordList(data);
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			{/*<Modal title={'新增退款单'} visible={visible} onCancel={() => {setVisible(false)}} onOk={() => {*/}
			{/*	setVisible(false)*/}
			{/*}}>*/}
			{/*	<Form>*/}
			{/*		<Form.Item label={'住户'} name={'household'} required={true}>*/}
			{/*			<AutoComplete  onSearch={(text) => {getRooms(text)}} options={option}  allowClear>*/}

			{/*			</AutoComplete>*/}
			{/*		</Form.Item>*/}
			{/*	</Form>*/}
			{/*</Modal>*/}

		</div>
	);
};
export default BillRefundRecord;
