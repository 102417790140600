import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { RoomStatusData } from '../../zhuhushenhe/models/RoomStatusData';
import LayerPlanComponent from '../../../components/LayerPlanComponent';

const LayerPlan: React.FC = (props: any) => {
	const [data, setData] = useState(new RoomStatusData());
	const [type, setType] = useState('look');
	useEffect(() => {
		const img = new Image();
		if (props.location.state !== undefined) {
			setData(props.location.state.data || new RoomStatusData());
			setType(props.location.state.type || 'look');
			console.log('数据：：：', data, data.buildPlan.imageUrl);
		}
	}, []);

	return (
		<div style={{ backgroundColor: '#fff' }}>
			<Row>
				<Col>
					<LayerPlanComponent key={data.buildPlan.imageUrl} data={data} type={type} />
				</Col>
			</Row>
		</div>
	);
};
export default LayerPlan;
