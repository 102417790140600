import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space,
} from 'antd';
import moment from 'moment';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table';
import { findAll } from '../../../services/PayCostManagementService';
import DAL from '../../../data/DAL';
import { findHouseholdList } from '../../../services/WuyeService';
import { frontDeskBill } from '../../../services/roomCharge';

const Option = Select.Option;
const BillFrontDesk: React.FC = (props: any) => {
	useEffect(() => {
		// getHousehold().then();
	}, []);

	const [userDList, setuserDList] = useState([]);
	const [houseList, sethouseList] = useState([]);
	const [search, setsearch] = useState('');
	const actionRef = useRef<ActionType>();
	const [serchType, setserchType] = useState('房间');
	const [searchList, setsearchList] = useState([]);
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [selectedRows, setselectedRows] = useState([]);
	const [payType, setpayType] = useState('');
	const [note, setnote] = useState('');
	let timeout: any = null;
	useEffect(() => {}, []);
	const columns = [
		{
			title: '房间',
			dataIndex: 'roomId',
		},
		{
			title: '客户',
			dataIndex: 'custName',
		},
		{
			title: '缴费类型',
			dataIndex: 'billType',
		},
		{
			title: '合同号',
			dataIndex: 'contractNo',
		},
		{
			title: '手机号',
			dataIndex: 'phone',
		},
		{
			title: '应收编号',
			dataIndex: 'chargeBillId',
		},
		{
			title: '费用期间',
			dataIndex: 'startTime',
			render: (text: string, record: any) => {
				return (
					<span>
						{record.startTime !== undefined && record.startTime !== null && record.startTime !== ''
							? moment(record.startTime).format('YYYY-MM-DD') + '~'
							: '-'}
						{record.endTime !== undefined && record.endTime !== null && record.endTime !== ''
							? moment(record.endTime).format('YYYY-MM-DD')
							: ''}
					</span>
				);
			},
		},
		{
			title: '收费科目',
			dataIndex: 'name',
		},
		{
			title: '应收金额(元)',
			dataIndex: 'actualAmount',
			render: (text: string) => <span>{(parseInt(text) / 100).toFixed(2)}</span>,
		},
	];
	const renderLowerCase = () => {
		let amout = 0;
		selectedRows.map((item) => {
			amout = amout + parseInt(item.actualAmount);
		});
		return (amout / 100).toFixed(2);
	};

	const tableSearchBarRender = () => [
		<Space>
			<Input
				placeholder="姓名,账号,房间号"
				onChange={(event) => {
					setsearch(event.target.value);
				}}
			/>

			<Button
				type={'primary'}
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>
			{/* <Select
				value={serchType}
				onChange={(vaule) => {
					if (serchType == vaule) {
						return;
					}
					setserchType(vaule);
					setsearch('');
				}}
			>
				<Select.Option value={'房间'}>房间</Select.Option>
				<Select.Option value={'客户'}>客户</Select.Option>
			</Select> */}
			{/* <Select
				showSearch={true}
				value={search}
				onSearch={(e: string) => handleSearch(serchType, e)}
				defaultActiveFirstOption={false}
				showArrow={false}
				style={{ width: 200 }}
				onChange={(value) => {
					console.log('searchValue', value);

					setsearch(value);
				}}
				filterOption={false}
			>
				{searchList.map((data: any) => {
					if (serchType === '房间') {
						return (
							<Select.Option value={data.user_uuid} key={data.user_uuid + data.room_number}>
								{data.room_number}-{data.householdname}-{data.householder}
							</Select.Option>
						);
					} else {
						return (
							<Select.Option value={data.userUuid} key={data.userUuid}>
								{data.realName}-{data.loginId}
							</Select.Option>
						);
					}
				})}
			</Select>
			<Button
				type="primary"
				onClick={() => {
					if (!DAL.dal.isNotNull(search)) {
						if (serchType == '房间') {
							message.warning('请输入房间号');
						} else {
							message.warning('请输入客户名称');
						}
					}
					actionRef.current.reloadAndRest();
				}}
			>
				查询
			</Button> */}
		</Space>
	];

	const findData = async (params) => {
		let data = {
			page: params.current,
			size: params.pageSize,
			state: 'false',
			searchInput: search,
		};
		if (DAL.dal.isNotNull(search)) {
			const res = await findAll(data);
			return {
				data: res.data,
				total: res.count,
				success: true,
			};
		} else {
			return {
				data: [],
				total: 0,
				success: true,
			};
		}
	};

	const billItem = async () => {
		if (selectedRowKeys.length == 0) {
			message.warning('请选择缴费单');
			return;
		}
		if (!DAL.dal.isNotNull(payType)) {
			message.warn('请选择结算方式');
			return;
		}
		const formData = {
			billUuids: selectedRowKeys.toString(),
			payType: payType,
			note: note,
		};
		const res = await frontDeskBill(formData);
		if (res.err == 0) {
			message.success('结算成功');
			setpayType('');
			setnote('');
			setselectedRowKeys([]);
			setselectedRows([]);
			actionRef.current.reloadAndRest();
		} else {
			message.warning(res.msg);
		}
	};

	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				rowKey="uuid"
				rowSelection={{
					fixed: true,
					columnWidth: 60,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setselectedRows(selectedRows);
						setselectedRowKeys(selectedRowKeys);
					},
				}}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					return findData(params);
				}}
			/>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div style={{ display: 'flex' }}>
					<div style={{width: 100, textAlign: "right"}}>收款合计：</div>
					<Space>
						<div >大写金额：</div>
						<div>{DAL.dal.changeNumMoneyToChinese(renderLowerCase())}</div>
						<div>小写金额：</div>
						<div>{renderLowerCase()}</div>
					</Space>

				</div>

			</div>
			<div style={{ display: 'flex' , marginTop: 10, alignItems: "center"}}>
				<div className="required-item" style={{width: 100, textAlign: "right"}}>缴费方式：</div>
				<div>
					<Select style={{ width: 200 }} value={payType} onChange={(value) => setpayType(value)}>
						<Select.Option value={'微信'}>微信</Select.Option>
						<Select.Option value={'支付宝'}>支付宝</Select.Option>
						<Select.Option value={'现金'}>现金</Select.Option>
					</Select>
				</div>
			</div>
			<div style={{ display: 'flex' ,marginTop: 10, marginBottom: 10}}>
				<div style={{width: 100, textAlign: "right"}}>备注：</div>
				<div>
					<Input.TextArea
						style={{width: 200}}
						value={note}
						onChange={(e) => {
							setnote(e.target.value);
						}}
					></Input.TextArea>
				</div>
			</div>
			<div style={{width: 300, display: "flex",}}>
				<div style={{width: 100}}></div>
				<Button type="primary" onClick={billItem}>
					确认收款
				</Button>
			</div>
		</>
	);
};
export default BillFrontDesk;
