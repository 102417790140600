import React, { useEffect, useRef, useState } from 'react';
import {

	getLeaseInfoList,
} from '../../../services/ZhuhushenheService';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { Tag } from 'antd';
interface Params {
	roomUuid: number;
}
const LeaseInfo: React.FC<Params> = (props: any) => {
	const actionRef = useRef<ActionType>();
	const columns = [
		
		{
			title: '承租方',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '合同号',
			dataIndex: 'contractNo',
			key: 'contractNo',
		},
		{
			title: '合同单价',
			dataIndex: 'price',
			valueType:'money',
			key: 'price',
		},
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			render: (_, item) => {
				return <><Tag color={item.state ? 'green' : 'red'}> { item.state ? '生效中':'未生效'}</Tag></>	
			}
		},
		{
			title: '合同开始时间',
			dataIndex: 'contractStartTime',
			valueType: 'dateTime',
			key: 'contractStartTime',
		},
		{
			title: '合同结束时间',
			dataIndex: 'contractEndTime',
			valueType: 'dateTime',
			key: 'contractEndTime',
		},
	];
	const tableSearchBarRender = () => [
	
	];
	
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				// pagination={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getLeaseInfoList({
						page: params.current,
						size: params.pageSize,
						assetsUuid: props.roomUuid,
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data.list,
						total: res.data.total,
						success: true,
					};
				}}
			/>
		
		</>
	);
};
export default LeaseInfo;
