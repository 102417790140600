import React, { useEffect, useRef, useState } from 'react';
import AuthorizedView from '../../../util/AuthorizedView';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { Button, Col, Input, message, Modal, Row, Select, Space } from 'antd';
import {
	getAll,
	getChannelUserList,
	getShuZhiUser,
	getUserBindChannelList,
	setUserChannel
} from '../../../services/ChannelService';

const UserList: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const channelListActionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [channelList, setChannelList] = useState([]);
	const [visible, setVisible] = useState(false);
	const [channelVisible, setChannelVisible] = useState(false);
	const [channelUuid, setChannelUuid] = useState('');
	const [userUuid, setUserUuid] = useState('');
	const [channelEnum, setChannelEnum] = useState({});
	useEffect(() => {
		getAllChannel();
	}, []);
	const columns = [
		{
			title: '昵称',
			width: 100,
			key: 'nickname',
			dataIndex: 'nickname'
		},
		{
			title: '真实姓名',
			width: 100,
			key: 'realName',
			dataIndex: 'realName'
		},
		{
			title: '账号',
			width: 100,
			key: 'loginId',
			dataIndex: 'loginId'
		},
		{
			title: '实名认证',
			width: 100,
			key: 'isCertification',
			dataIndex: 'isCertification',
			render: (text: string) => {
				return text === '1' ? '是' : '否';
			}
		},
		{
			title: '操作',
			width: 100,
			key: 'active',
			dataIndex: 'active',
			render: (text: string, record: any) => {
				return (
					<Space>
						<a onClick={() => {
							setUserUuid(record.userUuid);
							setChannelVisible(true);
						}}>渠道列表</a>
					</Space>
				);
			}
		}
	];
	const channelColumns = [
		{
			title: '推荐人帐号',
			width: 100,
			key: 'referrerPhone',
			dataIndex: 'referrerPhone'
		},
		{
			title: '推荐人渠道',
			width: 100,
			key: 'channelUuid',
			valueEnum: channelEnum,
			dataIndex: 'channelUuid'
		}, {
			title: '加入时间',
			width: 100,
			key: 'joinChannelTime',
			valueType: 'dateTime',
			dataIndex: 'joinChannelTime'
		},
		{
			title: '最后登录时间',
			width: 100,
			key: 'endLoginTime',
			dataIndex: 'endLoginTime',
			valueType: 'dateTime'

		}
	];
	const tableSearchBarRender = () => [
		<Space>
			<Input
				placeholder="请输入手机号或真实姓名"
				onChange={(e) => {
					setSearch(e.target.value);
				}}
			/>
			<Button type={'primary'} onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}>查询</Button>
		</Space>

	];

	const getAllChannel = async () => {
		let rsp = await getAll({});
		if (rsp.err === 0) {
			let channelEnum = {};
			rsp.data.map((item: any) => {
				channelEnum[item.uuid] = item.name;
			});
			setChannelEnum(channelEnum);
			setChannelList(rsp.data);
		}
	};
	const setChannel = async () => {
		let data = {
			userUuid: userUuid,
			channelUuid: channelUuid
		};
		let rsp = await setUserChannel(data);
		if (rsp.err === 0) {
			actionRef.current.reload();
			setVisible(false);
		} else {
			message.error(rsp.msg);
		}
	};
	return (<>
		<GSTable
			columns={columns}
			actionRef={actionRef}
			rowKey={'userUuid'}
			searchBarRender={tableSearchBarRender()}
			request={async (params: any = {}, sort: any, filter: any) => {
				let data = {
					page: params.current,
					size: params.pageSize,
					search: search,
					appId: 'ck_fangke_xcx'
				};
				let rsp = await getShuZhiUser(data);
				if (rsp.err !== 0) {
					return {
						data: []
					};
				}
				return {
					data: rsp.data,
					total: rsp.total,
					success: true
				};
			}}
		/>
		<Modal title={'选择渠道'} visible={visible} onCancel={() => {
			setVisible(false);
		}} onOk={setChannel}>
			<Row gutter={8} align={'middle'}>
				<Col>
					选择渠道:
				</Col>
				<Col>
					<Select key={channelUuid} style={{ width: 300 }} defaultValue={channelUuid}
							onChange={(e: string) => {
								setChannelUuid(e);
							}}>
						{
							channelList.map((item: any) => {
								return <Select.Option value={item.uuid} key={item.uuid}>
									{item.type === 'szg' ? '数智港' : item.type === 'zjb' ? '驻京办' : ''}--{item.name}
								</Select.Option>;
							})
						}

					</Select>
				</Col>
			</Row>
		</Modal>
		<Modal destroyOnClose={true} title={'渠道列表'} width={1200} visible={channelVisible} onCancel={() => {
			setChannelVisible(false);
		}} footer={null}>
			<GSTable
				columns={channelColumns}
				actionRef={channelListActionRef}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await getUserBindChannelList({
						userUuid: userUuid,
						page: params.current,
						size: params.pageSize
					});
					return {
						data: res.data,
						total: res.total
					};
				}}
			/>
		</Modal>

	</>);

};
export default UserList;
