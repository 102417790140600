import * as XLSX from 'xlsx';

export function readData(e, callbackData) {
	var files = e.file.originFileObj;
	var fileReader = new FileReader();
	let persons = []; // 存储获取到的数据
	let workbook = {};
	fileReader.onload = function (ev) {
		try {
			let data = ev.target.result;
			workbook = XLSX.read(data, {
				type: 'binary',
			}); // 以二进制流方式读取得到整份excel表格对象
		} catch (e) {
			console.log('文件类型不正确', e);
			return;
		}
		// 表格的表格范围，可用于判断表头是否数量是否正确
		var fromTo = '';
		// 遍历每张表读取
		for (var sheet in workbook.Sheets) {
			if (workbook.Sheets.hasOwnProperty(sheet)) {
				fromTo = workbook.Sheets[sheet]['!ref'];
				console.log(fromTo);
				console.log('2', XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
				persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
				break; // 如果只取第一张表，就取消注释这行
			}
		}
		console.log('1', persons);
		callbackData(persons);
		return persons;
	};
	if (files !== null) {
		e.fileList.forEach(function (item, index) {
			item.status = 'done';
			//删除页面显示的元素 只能有一条
			if (index != 0) {
				e.fileList.splice(0, 1);
			}
		});
		// 以二进制方式打开文件
		fileReader.readAsBinaryString(files);
	} else {
		callbackData([]);
	}
}
