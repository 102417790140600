import Api from '../data/API';

export async function findAllRoom(params: any) {
	return Api.postWithAuth('/api/zhuhu/findAllRoom', params);
}


export async function findLeaseList(params: any) {
	return Api.postWithAuth('/api/zhuhu/findLeaseList', params);
}
export async function getHouseholdInfo(params: any) {
	return Api.postWithAuth('/admin/api/zhuhu/household/get', params);
}
