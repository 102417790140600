import * as React from "react";
import Api from "../../../data/API";
import {
    Button,
    Row,
    Col,
    Divider,
    Pagination,
    Modal,
    Tooltip,
    Input,
    Image,
    Menu,
    Table,
    Select,
    message,
    Space
} from "antd";
import DAL from "../../../data/DAL";
import {
    choiceNeedContrast,
    auditUserImageInfo,
    getHouseholdContrastResult,
    deleteHousehold
} from "../../../services/ZhuhushenheService";
import GSTable from "../../../components/GSTable";
import { ActionType } from '@ant-design/pro-table';
import moment from "moment";
import * as XLSX from "xlsx";


let that: any;
const confirm = Modal.confirm;
const Search = Input.Search;

class ShenheList extends React.Component {
    columns = [
        {
            title: "姓名",
            dataIndex: "user_id",
            key: "user_id"
        },
        {
            title: "房间号",
            dataIndex: "room_number",
            key: "room_number"
        },
        {
            title: "通知时间",
            key: "start_time",
            dataIndex: "start_time",
            render: (text: string, record: any) => (
                <span>{record.start_time === null || record.start_time === "" ? "-" : record.start_time}</span>
            )
        },
        {
            title: "提交时间",
            key: "submissionTime",
            dataIndex: "submissionTime",
            render: (text: string, record: any) => (
                <span>
					{record.submissionTime === null || record.submissionTime === "" ? "-" : record.submissionTime}
				</span>
            )
        },
        {
            title: "住户照片",
            key: "faceImg",
            dataIndex: "faceImg",
            render: (text: string, record: any) => (
                <span>
					{record.faceImg === null || record.faceImg === "" ? (
                        "未上传"
                    ) : (
                        <img
                            onClick={this.openImageModal.bind(this, record)}
                            style={{ width: 130, height: 130 }}
                            src={record.faceImg}
                        />
                    )}
				</span>
            )
        },
        {
            title: "审核图片",
            key: "file_name",
            dataIndex: "file_name",
            render: (text: string, record: any) => (
                <span>
					{record.file_name === null ? (
                        "未上传"
                    ) : (
                        <img
                            onClick={this.openImageModal.bind(this, record)}
                            style={{ width: 130, height: 130 }}
                            src={Api.apiRootPath +
                            "/api/file/get_file_qiniu/" +
                            record.file_name +
                            "/" +
                            localStorage.getItem("wyUuid")}
                        />
                    )}
				</span>
            )
        },
        {
            title: "状态",
            key: "state",
            dataIndex: "state",
            render: (text: string, record: any) => (
                <span>
					{record.state === 2 || record.state === -2
                        ? "未提交"
                        : record.state === -1
                            ? "未通过"
                            : record.state === 0
                                ? "待审核"
                                : record.state === 1
                                    ? "通过"
                                    : record.state === 3
                                        ? "通知超时"
                                        : record.state === 4
                                            ? "提交超时"
                                            : record.state === 5
                                                ? "已通知"
                                                : ""}
				</span>
            )
        },
        {
            title: "操作",
            key: "action",
            render: (text: string, record: any) => {
                return (this.state as any).batchState ? (
                    <div>
                        {record.state === 0 ? (
                            <a onClick={this.auditImage.bind(this, record.uuid, "1")}>通过</a>
                        ) : (
                            <Tooltip
                                placement="top"
                                title={
                                    record.state === -2 || record.state === 5 || record.state === 3
                                        ? "该用户尚未提交审核照片"
                                        : "该用户已审核"
                                }
                            >
                                通过
                            </Tooltip>
                        )}
                        <Divider type="vertical"/>
                        {record.state === 0 ? (
                            <a onClick={this.auditImage.bind(this, record.uuid, "-1")}>不通过</a>
                        ) : (
                            <Tooltip
                                placement="top"
                                title={
                                    record.state === -2 || record.state === 5 || record.state === 3
                                        ? "该用户尚未提交审核照片"
                                        : "该用户已审核"
                                }
                            >
                                不通过
                            </Tooltip>
                        )}
                    </div>
                ) : (
                    <div>
                        <a onClick={this.deleteSheHe.bind(this, record.uuid)}>删除</a>
                    </div>
                );
            }
        }
    ];

    deviceId = "";
    contact = "";
    users2 = [];
    stateString: string = "全部";
    randomNum: string = "";
    batchUuid: string;
    searchString: string;
    actionRef: any;

    constructor(props: any) {
        super(props);
        that = this;
        this.state = {
            value: "",
            builds: [],
            users: [],
            rooms: [],
            isShow: true,
            name: "",
            img_type: "",
            room_number: "",
            create_time: "",
            file_name: "",
            valid_time: "",
            state: "全部",
            user_id: "",
            valid_mill: "",
            wuye_number: "",
            inputStr: "",
            returnDataRoom: [],
            dataDate: "",
            total: 0,
            user: {},
            imageModalvisible: false,
            batchState: false,
            addShenHeVisible: false,
            phone: ""
        };
        this.batchUuid = DAL.dal.getQueryString("batchUuid");
        this.search = this.search.bind(this);
        this.cleanData = this.cleanData.bind(this);
        this.inputDate = this.inputDate.bind(this);
        this.getData = this.getData.bind(this);
        this.clickState = this.clickState.bind(this);
    }


    deleteSheHe = (uuid: string) => {
        confirm({
            title: "您确定要删除吗？",
            onOk: async () => {
                const res = await deleteHousehold({
                    uuid: uuid,
                    batchUuid: that.batchUuid
                });
                console.log("获得的用户", res);
                if (res.err === 0) {
                    message.error("删除成功");
                    that.getData();
                } else {
                    message.error(res.msg);
                }
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    };
    getData = async (page: any, size: any) => {
        const res = await getHouseholdContrastResult({
            search: this.searchString,
            state: (this.state as any).state,
            page: page,
            size: size,
            batchUuid: this.batchUuid,
            wuyeUuid: Api.wuyeUuid
        });
        console.log("获得的用户", res);
        if (res.err === 0) {
            this.randomNum = res.randomNum;
            this.setState({
                users: res.data,
                isShow: false,
                total: res.count,
                batchState: res.batchState
            });
            this.users2 = res.data;
            return {
                data: res.data,
                total: res.count
            }
        } else {
            message.error(res.msg);
            return {}
        }
    };

    search(ev: any) {
        this.searchString = ev;
        this.actionRef.reloadAndRest();
    }

    cleanData() {
        this.setState({
            users: this.users2,
            inputStr: ""
        });
    }

    inputDate(date: any, dateToString: any) {
        this.setState({
            dataDate: dateToString
        });
    }

    clickState(ev: any) {
        that.setState({
            state: ev
        });
        that.stateString = ev;
    }

    //审核
    auditImage = async (uuid: string, state: string) => {
        //关闭图片弹框
        this.closeImageModal();
        const res = await auditUserImageInfo({
            uuid: uuid,
            state: state
        });
        if (res.err === 0) {
            message.success("操作成功");
            this.actionRef.reloadAndRest();
        } else if (res.err === -2) {
            message.error(res.msg);
        } else {
            message.error(res.msg);
        }
    };
    //打开图片Modal
    openImageModal = (record: number) => {
        console.log("openImageModal", record);
        this.setState({
            imageModalvisible: true,
            user: record
        });
    };
    //关闭图片modal
    closeImageModal = () => {
        this.setState({
            imageModalvisible: false
        });
    };

    addShenhe = async () => {
        if ((this.state as any).phone === "") {
            message.warn("请输入住户手机号")
            return;
        }
        const res = await choiceNeedContrast({
            phone: (this.state as any).phone,
            batchUuid: this.batchUuid
        });
        this.setState({
            addShenHeVisible: false
        });
        if (res.err === 0) {
            message.success('添加成功')
            this.actionRef.reloadAndRest();

        } else {
            message.error(res.msg)
        }
    };
    tableSearchBarRender = () => [
        <Space>
            <Select
                style={{ marginLeft: 8 }}
                defaultValue={(this.state as any).state}
                onChange={(e: string) => {
                    this.clickState(e);
                }}
            >
                <Select.Option value="全部">全部</Select.Option>
                <Select.Option value="-2">未上传</Select.Option>
                <Select.Option value="0">等待审核</Select.Option>
                <Select.Option value="-1">审核失败</Select.Option>
                <Select.Option value="1">审核通过</Select.Option>
            </Select>
            <Search
                placeholder="姓名,手机号,房间号搜索"
                onSearch={this.search}
                allowClear={true}
                enterButton={true}
            />

        </Space>
    ];
    tableOperationsBarRender = () => [
        <Button
            type="primary"
            onClick={() => {
                this.setState({ addShenHeVisible: true });
            }}
        >
            添加抽查
        </Button>
    ];
    rowSelection = {
        fixed: true,
        columnWidth: 100,
        preserveSelectedRowKeys: true,
        selections:[
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
        ]
    };
    setExprotData = (selectedRows: any[]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        this.columns.map((item: any) => {
            if (item.title !== "操作") {
                titles.push(item.title);
            }
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data: [string?] = [];
            this.columns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
                    } else {
                        data.push("");
                    }
                } else if (item.valueType === "dateTime") {
                    data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "抽查详情");

        /* save to file */
        XLSX.writeFile(wb, "抽查详情.xlsx");
    };
    render() {
        return (
            <div>
                <div className="content">
                    <GSTable
                        columns={this.columns}
                        rowSelection={this.rowSelection}
                        rowKey={'uuid'}
                        actionRef={(ref: any) => this.actionRef = ref}
                        searchBarRender={this.tableSearchBarRender()}
                        operationsBarRender={this.tableOperationsBarRender()}
                        request={async (params: any = {}, sort: any, filter: any) => {

                            return this.getData(params.current, params.pageSize);
                        }}
                        tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                            return (
                                <Space size={24}>
								<span>
									已选 {selectedRowKeys.length} 项
									<a
                                        style={{ marginLeft: 8 }}
                                        onClick={() => {
                                            onCleanSelected();
                                        }}
                                    >
										取消选择
									</a>
								</span>
                                </Space>
                            );
                        }}
                        tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                            return (
                                <Space size={16}>
                                    <Button type={"link"} onClick={() => {
                                        this.setExprotData(selectedRows);
                                    }}>导出</Button>
                                </Space>
                            );
                        }}
                    />
                    <div>
                        <Modal
                            title="图片审核"
                            visible={(this.state as any).imageModalvisible}
                            onOk={() => {
                                alert("ok");
                            }}
                            onCancel={this.closeImageModal}
                            okText="确认"
                            cancelText="取消"
                            width={950}
                            footer={
                                (this.state as any).user.state === -2 || (this.state as any).user.state === 1 ? null : (
                                    <div style={{ textAlign: "center" }}>
                                        <Button
                                            onClick={this.auditImage.bind(this, (this.state as any).user.uuid, "-1")}
                                        >
                                            不通过
                                        </Button>
                                        <Button
                                            onClick={this.auditImage.bind(this, (this.state as any).user.uuid, "1")}
                                        >
                                            通过
                                        </Button>
                                    </div>
                                )
                            }
                        >
                            <Row justify={"start"} gutter={8}>
                                <Col span={8}>
                                    <h5 style={{ textAlign: "center" }}>身份证照片</h5>
                                    {(this.state as any).user.cardIdImg === null ||
                                    (this.state as any).user.cardIdImg === undefined ||
                                    (this.state as any).user.cardIdImg === "" ? (
                                        "未上传"
                                    ) : (
                                        <img
                                            key={(this.state as any).user.cardIdImg}
                                            src={(this.state as any).user.cardIdImg.toLowerCase()}
                                        />
                                    )}
                                </Col>
                                <Col span={8}>
                                    <h5 style={{ textAlign: "center" }}>住户照片</h5>
                                    {(this.state as any).user.faceImg === null ||
                                    (this.state as any).user.faceImg === "" ? (
                                        "未上传"
                                    ) : (
                                        <img src={(this.state as any).user.faceImg}/>
                                    )}
                                </Col>
                                <Col span={8}>
                                    <h5 style={{ textAlign: "center" }}>审核照片</h5>
                                    {(this.state as any).user.file_name === null ||
                                    (this.state as any).user.file_name === "" ? (
                                        "未上传"
                                    ) : (
                                        <img
                                            src={
                                                Api.apiRootPath +
                                                "/api/file/get_file_qiniu/" +
                                                (this.state as any).user.file_name +
                                                "/" +
                                                localStorage.getItem("wyUuid")
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Modal>

                        <Modal
                            title="添加审核"
                            visible={(this.state as any).addShenHeVisible}
                            onOk={this.addShenhe}
                            onCancel={() => {
                                this.setState({ addShenHeVisible: false });
                            }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Row align={"middle"}>
                                <Col span={4} offset={2}>
                                    手机号：{" "}
                                </Col>
                                <Col span={10}>
                                    <Input
                                        onChange={(value: any) => {
                                            this.setState({ phone: value.target.value });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShenheList;
