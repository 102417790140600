import React, {useEffect, useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import {ActionType} from "@ant-design/pro-table/lib/typing";
import {addResearchInstitutes, getInstitutesList, updateResearchInstitutes} from "../../../services/AchievementService";
import {Button, Form, Input, InputNumber, Image, message, Modal, Select, Space, Tag} from "antd";
import CustomDateFormItem from "../../assets/components/CustomDateFormItem";
import {getRightsAndInterests} from "../../../services/scheme";
import UploadImgFormItem from "../../../components/UploadImgFormItem";
import API from "../../../data/API";
import AuthorizedView from "../../../util/AuthorizedView";
import InstitutesUserList from "../components/InstitutesUserList";
import moment from "moment";
const Index: React.FC = () => {
    const actionRef = useRef<ActionType>()
    const [search, setSearch] = useState("");
    const [addVisible, setAddVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [userListVisible, setUserListVisible] = useState(false);
    const [institutesUuid, setInstitutesUuid] = useState('')
    const [addForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [rightsAndInterestList, setRightsAndInterestList] = useState([]);
    useEffect(() => {
        loadRightsAndInterests()
    }, [])
    const columns = [
        {
            title: 'LOGO',
            key: 'logo',
            dataIndex: 'logo',
            render: (text: string, record: any) => {
                if (record.logo) {
                    return <Image src={API.cdnRootPath + record.logo} width={50} height={50}></Image>
                }
                return <span>-</span>
            }
        },
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '简介',
            ellipsis: true,
            key: 'introduce',
            dataIndex: 'introduce',
        },
        {
            title: '科研成果方向',
            key: 'resultAreas',
            dataIndex: 'resultAreas',
        },
        {
            title: '会员',
            key: 'vip',
            dataIndex: 'vip',
            render: (text: string , record: any) => {
                if (record.vip) {
                    return  <Tag color={'green'} >是</Tag>
                } else {
                    return  <Tag color={'red'}>否</Tag>
                }
            }
        }, {
            title: '操作',
            key: 'active',
            render: (text: string, record: any) => {
                return <Space>
                    <AuthorizedView needAuthority={'sz:institutes:update'}>
                        <a onClick={() => {
                            setUpdateVisible(true)
                            updateForm.setFieldsValue({...record,memberExpirationTime: moment(record.memberExpirationTime), resultAreas: (record.resultAreas || '').split(',')})
                        }}>编辑</a>
                    </AuthorizedView>
                    <AuthorizedView needAuthority={'sz:institutes:userlist'}>
                        <a onClick={() => {
                            setInstitutesUuid(record.uuid)
                            setUserListVisible(true)
                        }}>院所成员列表</a>
                    </AuthorizedView>
                </Space>
            }
        }
    ]
    const tableSearchBarRender = () => [
        <Space>
            <Input onChange={(e) => {
                setSearch(e.target.value)

            }} placeholder={'请输入名称'}></Input>
            <Button type={"primary"} onClick={() => {
                actionRef.current.reloadAndRest();
            }}>查询</Button>
        </Space>
    ]
    const tableOperationsBarRender =() => [
        <AuthorizedView needAuthority={'sz:institutes:add'}>
            <Button type={"primary"} onClick={() => {
                setAddVisible(true)
            }}>添加</Button>
        </AuthorizedView>

    ]
    const loadRightsAndInterests = async () => {
        let rsp = await getRightsAndInterests({type: 'kjcg'});
        if (rsp.err === 0) {
            setRightsAndInterestList(rsp.data)
        }
    }
    const addItem = async () => {
        let data = await addForm.validateFields()
        let rsp = await addResearchInstitutes({...data, resultAreas: data.resultAreas.toString()});
        if (rsp.err === 0) {
            setAddVisible(false)
            if (actionRef.current) {
                actionRef.current.reload();
            }
            message.success(rsp.msg)
        } else {
            message.error(rsp.msg)
        }
    }
    const updateItem = async () => {
        let data = await updateForm.validateFields()
        let rsp = await updateResearchInstitutes({...data, resultAreas: data.resultAreas.toString()});
        if (rsp.err === 0) {
            setUpdateVisible(false)
            if (actionRef.current) {
                actionRef.current.reload();
            }
            message.success(rsp.msg)
        } else {
            message.error(rsp.msg)
        }
    }
    return (
        <div>
            <GSTable
                columns={columns}
                actionRef={actionRef}
                rowKey={'uuid'}
                searchBarRender={tableSearchBarRender()}
                operationsBarRender={tableOperationsBarRender()}
                request={async (params: any) => {
                    let rsp = await getInstitutesList({search: search, page: params.current, size: params.pageSize})
                    if (rsp.err === 0) {
                        return {
                            data: rsp.data,
                            total: rsp.total
                        }
                    }
                    return {
                        data: []
                    }
                }}
            >
            </GSTable>
            <Modal destroyOnClose={true} title={'添加院所'} visible={addVisible} onCancel={() => setAddVisible(false)} onOk={addItem}>
                <Form form={addForm} labelCol={{span: 6}} labelAlign={'left'}>
                    <Form.Item name={'name'} label={'院所名称'}
                               rules={[{required: true, message: '输入院所名称'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'logo'} label={'院所LOGO'}
                               rules={[{required: false, message: '输入院所LOGO'}]}>
                        	<UploadImgFormItem multiple={false}  hiddenImgCorp={false}
                        					   listType={'picture-card'}
                        					   heightRatio={100}
                        					   widthRatio={100} />
                    </Form.Item>
                    <Form.Item name={'introduce'} label={'院所简介'}
                               rules={[{required: true, message: '输入院所简介'}]}>
                        <Input.TextArea  showCount={true} maxLength={1000}/>
                    </Form.Item>

                    <Form.Item name={'resultAreas'} label={'科技成果方向'}
                               rules={[{required: true, message: '输入科技成果方向'}]}>
                        <Select mode={"tags"} />
                    </Form.Item>
                    <Form.Item name={'listImg'} label={'院所图片'}
                               rules={[{required: false, message: '输入院所图片'}]}>
                        <UploadImgFormItem multiple={true}  hiddenImgCorp={true}
                                           listType={'picture-card'}
                                           heightRatio={100}
                                           widthRatio={100} />
                    </Form.Item>
                    <Form.Item name={'rightsAndInterestsUuid'} label={'会员类型'}
                               rules={[{required: false, message: '请选择会员类型'}]}>
                        <Select>
                            {
                                rightsAndInterestList.map(item => {
                                    return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name={'memberExpirationTime'} label={'会员有效期'}
                               rules={[{required: false, message: '会员有效期'}]}>
                        <CustomDateFormItem format={'YYYY-MM-DD HH:mm:ss'} showTime={true} style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item name={'vipUserNum'} label={'会员账号数'}
                               rules={[{required: false, message: '会员账号数'}]}>
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>

                </Form>
            </Modal>
            <Modal destroyOnClose={true} title={'编辑院所'} visible={updateVisible} onCancel={() => setUpdateVisible(false)} onOk={updateItem}>
                <Form form={updateForm} labelCol={{span: 6}} labelAlign={'left'}>
                    <Form.Item name={'uuid'} hidden={true} label={'院所名称'}
                               rules={[{required: true, message: '输入院所名称'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'name'} label={'院所名称'}
                               rules={[{required: true, message: '输入院所名称'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'logo'} label={'院所LOGO'}
                               rules={[{required: false, message: '输入院所LOGO'}]}>
                        <UploadImgFormItem multiple={false}  hiddenImgCorp={false}
                                           listType={'picture-card'}
                                           heightRatio={100}
                                           widthRatio={100} />
                    </Form.Item>
                    <Form.Item name={'introduce'} label={'院所简介'}
                               rules={[{required: true, message: '输入院所简介'}]}>
                        <Input.TextArea maxLength={1000} showCount={true}/>
                    </Form.Item>

                    <Form.Item name={'resultAreas'} label={'科技成果方向'}
                               rules={[{required: true, message: '输入科技成果方向'}]}>
                        <Select mode={"tags"} />
                    </Form.Item>
                    <Form.Item name={'listImg'} label={'院所图片'}
                               rules={[{required: false, message: '输入院所图片'}]}>
                        <UploadImgFormItem multiple={true}  hiddenImgCorp={true}
                                           listType={'picture-card'}
                                           heightRatio={100}
                                           widthRatio={100} />
                    </Form.Item>
                    <Form.Item name={'rightsAndInterestsUuid'} label={'会员类型'}
                               rules={[{required: false, message: '请选择会员类型'}]}>
                        <Select>
                            {
                                rightsAndInterestList.map(item => {
                                    return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name={'memberExpirationTime'} label={'会员有效期'}
                               rules={[{required: false, message: '会员有效期'}]}>
                        <CustomDateFormItem format={'YYYY-MM-DD HH:mm:ss'} showTime={true} style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item name={'vipUserNum'} label={'会员账号数'}
                               rules={[{required: false, message: '会员账号数'}]}>
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>

                </Form>
            </Modal>
            <Modal destroyOnClose={true} width={800} visible={userListVisible} onCancel={() => {setUserListVisible(false)}} footer={null}>
                <InstitutesUserList  institutesUuid={institutesUuid} />
            </Modal>
        </div>
    );
}
export default Index;
