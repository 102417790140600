import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import Paper from './pages/Paper';
import QuestionBank from './pages/QuestionBank';


export default function ExamAnswerMiniApp() {
	const rootUrl = '/answer';
	const menus: MiniAppMenu[] = [
		{
			name: '试卷',
			path: 'paper',
			authority: 'paper_list',
			component: Paper,
		},
		{
			name: '题库',
			path: 'question_bank',
			authority: 'question_bank_list',
			component: QuestionBank,
		},
	];

	return <MiniApp miniAppName={'考试答题'} rootUrl={rootUrl} menus={menus} />;
}
