import React, { useEffect, useRef, useState } from 'react';

import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row, Select, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import GsTreeSelect from '../../../components/GsTreeSelect';
import { findByRoomUuid, updateRoomCharge } from '../../../services/roomCharge';
import { getFeeStandardList } from '../../../services/FeeStandardService';
import { EditableProTable } from '@ant-design/pro-table';
import { findFee } from '../../../services/orderService';
import { deleteItemInfo } from '../../../services/ZhuhushenheService';
interface Params {
	roomUuid: string;
	batch?: boolean;
}
const RoomChargeList: React.FC<Params> = (props: any) => {
	const actionRef = useRef<ActionType>();
	const [feeTypeList, setfeeTypeList] = useState([]);
	const [standardList, setstandardList] = useState([]);
	const [form] = Form.useForm<FormInstance>();
	const editorFormRef = useRef();
	const [checkedValues, setcheckedValues] = useState([]);
	useEffect(() => {
		if (!props.batch) {
			getData().then();
		}
		finFeeType().then();

		getFeeStandardData().then();
	}, []);
	const getData = async () => {
		const res = await findByRoomUuid({ roomUuid: props.roomUuid });
		if (res.err === 0) {
			const data = res.data || [];
			const formData = [];
			const checkeds = [];

			data.forEach((item) => {
				const data = {
					feeTypeUuid: item.feeTypeUuid,
					feeStandardUuid: item.feeStandardUuid,
				};
				formData.push(data);
				checkeds.push(item.feeTypeUuid);
			});
			setcheckedValues(checkeds);
			form.setFieldsValue({ roomCharge: formData });
		}
	};
	const finFeeType = async () => {
		const res = await findFee({});
		if (res.err == 0) {
			setfeeTypeList(res.data || []);
		}
	};
	const getFeeStandardData = async () => {
		const res = await getFeeStandardList({
			state: 1,
		});
		if (res.err == 0) {
			setstandardList(res.data || []);
		}
	};

	const onChange = (checkedValues) => {
		console.log('checked = ', checkedValues);
		setcheckedValues(checkedValues);
	};

	const onChange1 = (e: any) => {
		if (e.target.checked) {
			const data = {
				feeTypeUuid: e.target.value,
			};
			const roomChargeList = form.getFieldValue('roomCharge') || [];
			roomChargeList.push(data);
			form.setFieldsValue({ roomCharge: roomChargeList });
		} else {
			const roomChargeList = form.getFieldValue('roomCharge') || [];
			const item = roomChargeList.findIndex((item) => item.feeTypeUuid == e.target.value);
			roomChargeList.splice(item, 1);
			form.setFieldsValue({ roomCharge: roomChargeList });
			console.log('deltetItem', item);
		}
	};

	const renderFeeTypeUuid = (feeTypeUuid: string) => {
		const item = feeTypeList.find((item) => item.uuid == feeTypeUuid);
		console.log('feeTypeUuid', feeTypeUuid, item);
		if (item !== undefined) {
			return item.feeName;
		}
	};
	const renderPrice = (feeStandardUuid: string) => {
		const item = standardList.find((item) => item.uuid == feeStandardUuid);
		console.log('priceitem', item);

		if (item !== undefined) {
			return item.price;
		}
	};
	const renderPriceUnit = (feeStandardUuid: string) => {
		const item = standardList.find((item) => item.uuid == feeStandardUuid);
		if (item !== undefined) {
			return item.priceUnit;
		}
	};
	const renderRoomCharge = (feeTypeUuid) => {
		const feeList = standardList.filter((item) => item.feeTypeUuid == feeTypeUuid) || [];
		console.log('feeList', feeList, standardList);

		return feeList.map((item) => {
			return (
				<>
					<Select value={item.uuid}>{item.name}</Select>
				</>
			);
		});
	};
	const saveItem = () => {
		form.validateFields().then(async (data) => {
			console.log('data', data);
			const res = await updateRoomCharge({ ...data, roomUuid: props.roomUuid });
			if (res.err == 0) {
				message.success('设置成功');
			}
		});
	};
	const deleteItem = (feeTypeUuid: any) => {
		const roomChargeList = form.getFieldValue('roomCharge') || [];
		const item = roomChargeList.findIndex((item) => item.feeTypeUuid == feeTypeUuid);
		roomChargeList.splice(item, 1);
		form.setFieldsValue({ roomCharge: roomChargeList });
		const values = [...checkedValues];
		const findIndex = values.findIndex((item) => item == feeTypeUuid);
		console.log('findIndex', findIndex);
		values.splice(findIndex, 1);
		setcheckedValues([...values]);
	};
	return (
		<>
			<Checkbox.Group style={{ width: '100%' }} value={checkedValues} onChange={onChange}>
				<Row>
					{feeTypeList.map((item) => {
						return (
							<Col span={6}>
								<Checkbox onChange={onChange1} value={item.uuid}>
									{item.feeName}
								</Checkbox>
							</Col>
						);
					})}
				</Row>
			</Checkbox.Group>
			<Form form={form}>
				<div className="ant-pro-table ">
					<div className="ant-table-wrapper">
						<table
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								flex: 1,
							}}
						>
							<thead className={'ant-table-thead'} style={{ display: 'flex', flex: 1 }}>
								<tr style={{ display: 'flex', flex: 1 }}>
									<th style={{ flex: 1 }}>收费类型</th>
									<th style={{ flex: 1 }}>收费标准</th>
									<th style={{ flex: 1 }}>收费价格</th>
									{/* <th style={{ flex: 1 }}>收费单位</th> */}
									<th style={{ flex: 1 }}>操作</th>
								</tr>
							</thead>
							<tbody
								className={'ant-table-tbody'}
								style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
							>
								<Form.Item style={{ width: '100%' }} shouldUpdate noStyle>
									{() => (
										<Form.List name={'roomCharge'}>
											{(fields, { add, remove }) => (
												<>
													{fields.map((field: any, index: number) => (
														<>
															<tr style={{ flex: 1, display: 'flex' }}>
																<td style={{ flex: 1, display: 'flex' }}>
																	<Form.Item
																		{...field}
																		// label={'收费类型'}
																		name={[field.name, 'feeTypeUuid']}
																		fieldKey={[field.fieldKey, 'feeTypeUuid']}
																		rules={[
																			{
																				required: true,
																				message: '请选择类型',
																			},
																		]}
																	>
																		{renderFeeTypeUuid(
																			form.getFieldValue([
																				'roomCharge',
																				field.name,
																				'feeTypeUuid',
																			])
																		)}
																	</Form.Item>
																</td>
																<td style={{ flex: 1, display: 'flex' }}>
																	{
																		<Form.Item
																			style={{ width: '100%' }}
																			{...field}
																			// label={'收费标准'}
																			name={[field.name, 'feeStandardUuid']}
																			fieldKey={[
																				field.fieldKey,
																				'feeStandardUuid',
																			]}
																			rules={[
																				{
																					required: true,
																					message: '请选择收费标准',
																				},
																			]}
																		>
																			<Select
																				style={{ width: '100%' }}
																				showSearch
																				optionFilterProp="children"
																				allowClear
																			>
																				{renderRoomCharge(
																					form.getFieldValue([
																						'roomCharge',
																						field.name,
																						'feeTypeUuid',
																					])
																				)}
																			</Select>
																		</Form.Item>
																	}
																</td>
																<td style={{ flex: 1, display: 'flex' }}>
																	<Form.Item
																		{...field}
																		// label={'收费类型'}
																		name={[field.name, 'price']}
																		fieldKey={[field.fieldKey, 'price']}
																	>
																		{renderPrice(
																			form.getFieldValue([
																				'roomCharge',
																				field.name,
																				'feeStandardUuid',
																			])
																		)}
																	</Form.Item>
																</td>
																{/* <td style={{ flex: 1, display: 'flex' }}>
																	<Form.Item
																		{...field}
																		// label={'收费类型'}
																		name={[field.name, 'priceUnit']}
																		fieldKey={[field.fieldKey, 'priceUnit']}
																	>
																		{renderPriceUnit(
																			form.getFieldValue([
																				'roomCharge',
																				field.name,
																				'feeStandardUuid',
																			])
																		)}
																	</Form.Item>
																</td> */}
																<td
																	style={{
																		flex: 1,
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	<a
																		onClick={() =>
																			deleteItem(
																				form.getFieldValue([
																					'roomCharge',
																					field.name,
																					'feeTypeUuid',
																				])
																			)
																		}
																	>
																		删除
																	</a>
																</td>
															</tr>
														</>
													))}
												</>
											)}
										</Form.List>
									)}
								</Form.Item>
							</tbody>
							{/* ))} */}
						</table>
					</div>
				</div>
			</Form>
			<Button type="primary" onClick={saveItem}>
				{' '}
				保存
			</Button>
		</>
	);
};
export default RoomChargeList;
