import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Card,
	Col,
	DatePicker,
	Input,
	message,
	Row,
	Select,
	Space,
	Statistic,
	Tooltip,
	TreeSelect
} from 'antd';
import GSTable from '../../../components/GSTable';
import { getAllRecord, getDevices, getOpenDoorRecords ,getOpenDoorTopData} from '../../../services/ZhiNengMenJinService';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import CustomRangePicker from '../../../components/CustomRangePicker';
import moment from 'moment';
import DAL from "../../../data/DAL";
import GsTreeSelect from "../../../components/GsTreeSelect";
import * as XLSX from 'xlsx';
let msg = require('../../../util/SuspensionMsg');
const Search = Input.Search;
const Option = Select.Option;
let { RangePicker } = DatePicker;

const OpenDoorRecord: React.FC = (props: any) => {
	useEffect(() => {
		getUserDevice();
	}, []);
	const dal = DAL.dal;
	const actionRef = useRef<ActionType>();
	const [userDevices, setUserDevices] = useState(dal.getQueryString("deviceIds") === '' ? [] :dal.getQueryString("deviceIds").split(','));
	const [Stime, setStime] = useState(
		dal.getQueryString('startTime') || moment().hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:00')
	);
	const [Etime, setEtime] = useState(dal.getQueryString('endTime') || moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:59'));
	const [phone, setPhone] = useState(dal.getQueryString("phone") || '');

	const [openType, setOpenType] = useState('全部方式');
	const [selectionDates, setSelectionDates] = useState('最近7天');
	const [selectionDate, setSelectionDate] = useState('最近7天');
	const [userdevice, setUserdevice] = useState([]);
	const [selectState, setSelectState] = useState('2');
	const [direction, setDirection] = useState('');
	const[selectType, setSelectType] = useState(parseInt(dal.getQueryString('selectType') || '1'));
	const [topData, setTopData] = useState({ totalNum: 0, faceOpenNum: 0, remoteOpenNum: 0, phoneOpenNum: 0 });
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [quanxuan, setQunXuan] = useState(false);
	const [dataCount, setDataCount] = useState(0);
	const [eventType, seteventType] = useState("")
	const [isShow, setIsShow] = useState(false);
	const statusEnum = {
		"1": "成功",
		"2":"失败"
	}
	const columns = [
		{
			title: '用户',
			dataIndex: 'nickName',
			width: 150,
			render: (value: string, record: any) => {
				if (record.realName) {
					return (
						<span>
							{record.nickName}(姓名：{record.realName})
						</span>
					);
				}
				return <span>{record.nickName}</span>;
			},
		},
		{
			title: '账号',
			width: 150,
			dataIndex: 'email',
		},
		{
			title: '状态',
			width: 100,
			dataIndex: 'eventType',
			key: 'eventType',
			valueEnum: statusEnum,
		},
		{
			title: '设备名称',
			width: 100,
			dataIndex: 'deviceName',
		},
		{
			title: '方向',
			width: 50,
			dataIndex: 'direction',
		},
		{
			title: '人员类型',
			width: 100,
			dataIndex: 'employeeType',
		},
		{
			title: '部门名称',
			width: 100,
			dataIndex: 'departmentName',
		},
		{
			title: '设备ID',
			width: 100,
			dataIndex: 'deviceId',
		},
		{
			title: '设备电量',
			width: 80,
			dataIndex: 'deviceBattery',
		},
		{
			title: '事件',
			width: 150,
            fixed: 'right',
            dataIndex: 'event',
		},
		{
			title: '时间',
			width: 200,
			fixed: 'right',
			dataIndex: 'date',
		},
	];

	const getUserDevice = async () => {
		const res = await getDevices({isLook: true});
		if (res.err !== 0) {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		res.data.map((item: any) => {
			item["selectable"] = false
		})

		setUserdevice(res.data);
	};

	/** 选择时间 */
	const selectionDates1 = (ev: any) => {
		if (ev === '自定义') {
			setSelectState('1');
		} else {
			setSelectState('2');
			setEtime('');
			setStime('');
		}
		setSelectionDate(ev);
		setSelectionDates(ev);
	};

	const dateTime = (startTime: any, endTime: any) => {
		setStime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEtime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};

	const getInputStr = (ev: any) => {
		setPhone(ev.target.value);
	};
	const tableSearchBarRender = () => [
		<GsTreeSelect
			// key={this.props.deviceId === undefined ? '' : this.props.deviceId}
			treeData={userdevice}
			defaultValue={userDevices}
			onChange={(value: any) => {
				setUserDevices(value);
			}}
		/>,
		<Select
			onChange={(value: any) => {
				setOpenType(value);
			}}
			defaultValue={'全部方式'}
		>
			<Select.Option value={'全部方式'}>全部方式</Select.Option>
			<Select.Option value={'蓝牙'}>手机开门</Select.Option>
			<Select.Option value={'人脸'}>人脸开门</Select.Option>
			<Select.Option value={'远程'}>远程开门</Select.Option>
		</Select>,
		<Select
			style={{width: 100}}
			onChange={(value: any) => {
				setDirection(value)
			}}
			defaultValue={direction}
		>
			<Select.Option value={''}>全部方向</Select.Option>
			<Select.Option value={'进'}>进</Select.Option>
			<Select.Option value={'出'}>出</Select.Option>
		</Select>,
		<Select
		style={{width: 100}}
		onChange={(value: any) => {
			seteventType(value)
		}}
		defaultValue={eventType}
	>
		<Select.Option value={''}>全部状态</Select.Option>
		<Select.Option value={'1'}>成功</Select.Option>
		<Select.Option value={'2'}>失败</Select.Option>
	</Select>,
		<CustomRangePicker selectType={selectType} onChange={dateTime} />,
		<Input placeholder={'账号、姓名'} onChange={getInputStr} value={phone} />,

		<Button onClick={() => actionRef.current.reloadAndRest()} type={'primary'}>
			查询
		</Button>,
	];

	const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>{element}</div>
				{
					msg && <div>
						<Tooltip title={msg}>
							<ExclamationCircleOutlined style={iconStyle} />
						</Tooltip>
					</div>
				}

			</div>
		);
	};
	const tableExtraRender = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>总开门次数</span>,
								''
							)}
							value={topData.totalNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>次</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>手机开门次数</span>,
								''
							)}
							value={topData.phoneOpenNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>次</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>人脸开门次数</span>,
								''
							)}
							value={topData.faceOpenNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>次</span>}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span  style={{ color: '#000', fontSize: 16 }}>远程开门次数</span>,
								''
							)}
							value={topData.remoteOpenNum}
							valueStyle={{ fontSize: 30 }}
							suffix={<span style={{ fontSize: 16 }}>次</span>}
						/>
					</Card>
				</Col>
			</Row>
		);
	};
	const rowSelection = {
		fixed: true,
		preserveSelectedRowKeys: true,
		selectedRowKeys: selectedRowKeys,
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			setSelectedRowKeys(selectedRowKeys);
			setQunXuan(false);
		},
		selections: [
			{
				key: 'SELECT_CURRENT_ALL',
				text: '全选当前页面',
				onSelect: (changableRowKeys: any) => {
					setQunXuan(false);
					setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
				},
			},
			{
				key: 'SELECT_ALL',
				text: '全选全部页面',
				onSelect: (changableRowKeys: any) => {
					setQunXuan(true);
					setSelectedRowKeys(changableRowKeys);
				},
			},
		],
	};


	const exprotData = async (selectedRows: any) => {
		console.log('selectedRows:::::', selectedRows);
		setIsShow(true);
		if (quanxuan) {
			let data: any = {};
			if (userDevices !== null && userDevices !== undefined) {
				(data as any).device_id = userDevices.toString();
			}
			if (Stime !== null && Stime !== undefined) {
				(data as any).Stime = Stime;
			}
			if (Etime !== null && Etime !== undefined) {
				(data as any).Etime = Etime;
			}
			if (phone !== null && phone !== undefined) {
				(data as any).phone = phone;
			}
			(data as any).event = '开门';
			(data as any).type = '开门';
			(data as any).selectionDates = selectionDates;
			(data as any).direction = direction;
			(data as any).page = -1;
			(data as any).size = 0;

			(data as any).openType = openType;
			let res = await getOpenDoorRecords(data);
			if (res.err === 0) {
				setExprotData(res.records);
			} else {
				message.error(res.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
		setIsShow(false);
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}

					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '开门记录');

		/* save to file */
		XLSX.writeFile(wb, '开门记录.xlsx');
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				tableExtraRender={tableExtraRender}
				searchBarRender={tableSearchBarRender()}
				rowKey={"id"}
				rowSelection={rowSelection}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanxuan ? dataCount : selectedRowKeys.length} 项
								<a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={16}>
							<Button type={'link'} loading={isShow} onClick={() => exprotData(selectedRows)}>导出</Button>
						</Space>
					);
				}}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data: any = {};
					if (userDevices !== null && userDevices !== undefined) {
						(data as any).device_id = userDevices.toString();
					}
					if (Stime !== null && Stime !== undefined) {
						(data as any).Stime = Stime;
					}
					if (Etime !== null && Etime !== undefined) {
						(data as any).Etime = Etime;
					}
					if (phone !== null && phone !== undefined) {
						(data as any).phone = phone;
					}
					(data as any).event = '开门';
					(data as any).type = '开门';
					(data as any).selectionDates = selectionDates;
					(data as any).direction = direction;
					(data as any).page = params.current;
					(data as any).size = params.pageSize;

					(data as any).openType = openType;
					(data as any).eventType = eventType;
					let res = await getOpenDoorRecords(data);
					getOpenDoorTopData(data).then((rsp: any) => {
						if (rsp.err === 0) {
							setTopData(rsp.data);

						}
					})
					if (res.err !== 0) {
						msg.suspensionMsg({ content: res.msg, type: 'error' });
						return {
							data: [],
							total: 0,
						};
					}
					if (quanxuan) {
						let uuids: any = [];
						res.records.map((item: any) => {
							uuids.push(item.id);
						});
						setSelectedRowKeys(uuids);
					}
					setDataCount(res.count)
					return {
						data: res.records,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default OpenDoorRecord;
