import React, { useEffect, useRef, useState } from 'react';
import {
	getHouseStyle,
	addListOfItems,
	getListOfItemsList,
	updateListOfItems,
	deleteListOfItems,
	getAllItemInfo,
	getItemTypeList,
} from '../../../services/ZhuhushenheService';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { Button, Form, Input, InputNumber, message, Modal, Select, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import ProxyForm from '../../../components/ProxyForm';
import ListOfItemsBatch from './ListOfItemsBatch';
interface Params {
	houseStyleId: number;
}
const ListOfItems: React.FC<Params> = (props: any) => {
	const actionRef = useRef<ActionType>();
	const batchActionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [add, setadd] = useState(false);
	const [itemInfoList, setitemInfoList] = useState([]);
	const [form] = Form.useForm<FormInstance>();
	const [batchVisible, setbatchVisible] = useState(false);
	const [houseStyleId, sethouseStyleId] = useState('');
	const [datekey, setdatekey] = useState(new Date().getTime().toString());
	const [type, settype] = useState('');
	const [itemTypeList, setitemTypeList] = useState([]);

	useEffect(() => {
		getAllItem();
		getAllItemTypeList().then();
	}, []);
	const getAllItemTypeList = async () => {
		const res = await getItemTypeList({});
		if (res.err == 0) {
			setitemTypeList(res.data || []);
		}
	};
	const getAllItem = async () => {
		const res = await getAllItemInfo({});
		if (res.err == 0) {
			setitemInfoList(res.data || []);
		} else {
			message.error(res.msg);
		}
	};
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '品牌',
			dataIndex: 'brand',
			key: 'brand',
		},
		{
			title: '型号/规格',
			dataIndex: 'model',
			key: 'model',
		},
		{
			title: '物品类目',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '数量',
			dataIndex: 'quantity',
			key: 'quantity',
		},
		{
			title: '损坏数量',
			dataIndex: 'damagedQuantity',
			key: 'damagedQuantity',
		},
		{
			title: '单价',
			dataIndex: 'price',
			valueType: 'money',
			key: 'price',
		},
		{
			title: '创建时间',
			dataIndex: 'createdtime',
			valueType: 'dateTime',
			key: 'createdtime',
		},
		{
			title: '操作',
			key: 'action',
			render: (record: any) => (
				<Space>
					<a
						onClick={() => {
							form.setFieldsValue({ ...record, itemInfoUuid: record.iteminfouuid });
							setadd(false);
							setVisible(true);
						}}
					>
						编辑
					</a>
					<a
						onClick={() => {
							Modal.confirm({
								title: '提示',
								content: '确定要删除该物品吗？该操作不可恢复！',
								okType: 'danger',
								onOk: async () => {
									let data = {
										id: record.id,
									};
									let rsp = await deleteListOfItems(data);
									if (rsp.err === 0) {
										message.success(rsp.msg);
										if (actionRef.current) {
											actionRef.current.reload();
										}
										return;
									}
									message.error(rsp.msg);
								},
							});
						}}
					>
						删除
					</a>
				</Space>
			),
		},
	];

	const tableSearchBarRender = () => [
		<Select style={{ width: 150 }} value={type} onChange={(value) => settype(value)}>
			<Select.Option value={''}>全部</Select.Option>
			{itemTypeList.map((item) => {
				return (
					<>
						<Select.Option value={item.name}>{item.name}</Select.Option>
					</>
				);
			})}
		</Select>,
		<Input
			placeholder={'物品名称'}
			onChange={(event: any) => {
				setSearch(event.target.value);
			}}
		/>,
		<Button
			type="primary"
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setadd(true);
				setVisible(true);
			}}
		>
			新增
		</Button>,
		<Button
			type="primary"
			onClick={() => {
				setbatchVisible(true);
			}}
		>
			批量添加
		</Button>,
	];
	const addItemOk = async () => {
		let data: any = await form.validateFields();
		setLoading(true);
		let rsp = await addListOfItems({
			itemInfoUuid: data.itemInfoUuid,
			quantity: data.quantity,
			note: data.note,
			houseStyleId: props.houseStyleId,
		});
		setLoading(false);
		if (rsp.err === 0) {
			message.success(rsp.msg);
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
			return;
		}
		message.error(rsp.msg);
	};
	const updateItemOk = async () => {
		let data: any = await form.validateFields();
		setLoading(true);
		let rsp = await updateListOfItems({ ...data, houseStyleId: props.houseStyleId });
		setLoading(false);
		if (rsp.err === 0) {
			message.success(rsp.msg);
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
			return;
		}
		message.error(rsp.msg);
	};
	const saveItem = () => {
		if (add) {
			addItemOk();
		} else {
			updateItemOk();
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				// pagination={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getListOfItemsList({
						page: params.current,
						size: params.pageSize,
						name: search,
						houseStyleId: props.houseStyleId,
						type: type,
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data,
						// total: res.data,
						success: true,
					};
				}}
			/>
			<Modal
				title={add ? '添加' : '修改'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				maskClosable={false}
				confirmLoading={loading}
				onOk={saveItem}
			>
				<ProxyForm form={form}>
					<Form.Item name={'id'} hidden={true}>
						<Input></Input>
					</Form.Item>
					<Form.Item
						name={'itemInfoUuid'}
						label={'物品'}
						rules={[{ required: true, message: '请选择装修物品' }]}
					>
						<Select disabled={!add} showSearch optionFilterProp="children">
							{itemInfoList.map((item: any) => {
								return (
									<Select.Option key={item.uuid} value={item.uuid}>
										{item.name}-{item.brand}-{item.price}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item name={'quantity'} label={'数量'} rules={[{ required: true, message: '请填写物品数量' }]}>
						<InputNumber style={{ width: '100%' }} min={1} precision={0} step={1} />
					</Form.Item>
					<Form.Item name={'note'} label={'备注'}>
						<Input.TextArea maxLength={200} />
					</Form.Item>
				</ProxyForm>
			</Modal>
			<Modal
				title="批量添加"
				width={800}
				visible={batchVisible}
				footer={null}
				onCancel={() => setbatchVisible(false)}
			>
				<ListOfItemsBatch key={datekey} houseStyleId={props.houseStyleId}></ListOfItemsBatch>
			</Modal>
		</>
	);
};
export default ListOfItems;
