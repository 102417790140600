import { WuyeRoom } from './WuyeRoom';
import { WuyeBuildPlan } from './WuyeBuildPlan';

export class RoomStatusData {
	layer: number = 0;
	totalArea: number = 0;

	rentedArea: number = 0;

	forRentArea: number = 0;

	buildUuid: string = '';
	rooms: Array<WuyeRoom> = Array<WuyeRoom>();

	buildPlan: WuyeBuildPlan = new WuyeBuildPlan();

	public setProperty(data: any) {
		this.layer = data.layer || 0;
		this.buildUuid = data.buildUuid || '';
		let wuyeRooms: Array<WuyeRoom> = new Array<WuyeRoom>();
		data.rooms.map((item: any) => {
			let wuyeRoom = new WuyeRoom();
			wuyeRoom.setProperty(item);
			wuyeRooms.push(wuyeRoom);
		});
		this.totalArea = data.totalArea;
		this.rentedArea = data.rentedArea;
		this.forRentArea = data.forRentArea;
		this.rooms = wuyeRooms;
		let wuyeBuildPlan = new WuyeBuildPlan();
		wuyeBuildPlan.buildUuid = this.buildUuid;
		wuyeBuildPlan.layerNumber = this.layer;
		this.buildPlan.setProperty(data.buildPlan || wuyeBuildPlan);
	}
}
