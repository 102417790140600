import React, { useContext, useEffect } from 'react';
import { Pie, measureTextWidth } from '@ant-design/charts';
import { ColumnChartContext } from '../pages/controlPlatform/CurrentPhoneBrandChart';

const PieChart: React.FC = (props:any) => {
	const data = useContext(ColumnChartContext);
	useEffect(() => {
		console.log('PieChart::::', data);
	}, []);

	const config = {
		appendPadding: 10,
		data: data,
		angleField: 'value',
		colorField: 'type',
		radius: 1,
		innerRadius: 0.64,
		meta: {
			value: {
				formatter: function formatter(v: any) {
					return ''.concat(v, props.unit || '次');
				},
			},
		},
		label: {
			type: 'inner',
			offset: '-50%',
			style: { textAlign: 'center',color:'#fff' },
			autoRotate: false,
			content: function content(_ref: any) {
				let percent: any = (_ref.percent * 100).toFixed(2);
				return ''.concat(String(percent), '%');
			},
		},
		statistic: {
			title: {
				offsetY: -4,
				customHtml: (container: any, view: any, datum: any) => {
					const { width, height } = container.getBoundingClientRect();
					const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
					const text = datum ? datum.type : '总计';
					return renderStatistic(d, text, {
						fontSize: 18
					});
				}
			},
			content: {
				offsetY: 4,
				style: {
					fontSize: '22px'
				},
				customHtml: (container: any, view: any, datum: any, data: any) => {
					console.log('datum:::', datum, data);
					const { width } = container.getBoundingClientRect();
					const text = datum ? `${datum.value} ${props.unit}` : `${data.reduce((r: any, d: any) => r + d.value, 0)} ${props.unit}`;
					return renderStatistic(width, text, {
						fontSize: 22
					});
				}
			}
		},
		interactions: [{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-statistic-active' }],
	};
	const renderStatistic = (containerWidth: any, text: any, style: any) => {
		const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
		const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

		let scale = 1;

		if (containerWidth < textWidth) {
			scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
		}

		const textStyleStr = `width:${containerWidth}px;`;
		return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'}; color: #000000">${text}</div>`;
	};
	return <Pie {...config} {...props} />;
};
export default PieChart;
