import Api from '../data/API';
import { async } from 'q';
import { func } from 'prop-types';

export async function getActivity(params: any) {
	return Api.postWithAuth('/api/statistice/get_activity', params);
}

export async function getRoomIsCheckIn(params: any) {
	return Api.postWithAuth('/api/statistice/get_room_is_checkIn', params);
}

export async function getAppNewAddCount(params: any) {
	return Api.postWithAuth('/api/statistice/get_app_new_add_count', params);
}

export async function getNewlyAddedCompanyCount(params: any) {
	return Api.postWithAuth('/api/statistice/get_newly_added_company_count', params);
}

export async function getRecordStatisticsApi(params: any) {
	return Api.postWithAuth('/api/statistics/getRecordStatisticsApi', params);
}

export async function getPatrolDocumentationRecord(params: any) {
	return Api.postWithAuth('/api/statistice/get_patrol_documentation_record', params);
}

export async function getHuoDongReport(params: any) {
	return Api.postWithAuth('/api/huodong/getHuoDongReport', params);
}

export async function getAppSumCount(params: any) {
	return Api.postWithAuth('/api/statistice/get_app_sum_count', params);
}

export async function getCompanyEmployeeCount(params: any) {
	return Api.postWithAuth('/api/statistice/get_company_employee_count', params);
}
export async function getCurrentPlatformData(params: any) {
	return Api.postWithAuth('/api/statistice/get_current_platform_data', params);
}
export async function getDeviceOpenDoorData(params: any) {
	return Api.postWithAuth('/api/statistice/getDeviceOpenDoorData', params);
}
export async function getCurrentPlatformData2(params: any) {
	return Api.postWithAuth('/api/statistice/get_current_platform_data2', params);
}

export async function getCurrentPhoneBrand(params: any) {
	return Api.postWithAuth('/api/statistice/get_current_phone_brand', params);
}

export async function getAuthRecordPlatformData(params: any) {
	return Api.postWithAuth('/api/statistice/get_auth_record_platform_data', params);
}
