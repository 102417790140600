import Api from '../data/API';

export async function getPageSetList(params: any) {
    return Api.postWithAuth('/adminapi/pageSet/getPageSetList', params);
}
export async function addPageSet(params: any) {
    return Api.postWithAuth('/adminapi/pageSet/addPageSet', params);
}
export async function updatePageSet(params: any) {
    return Api.postWithAuth('/adminapi/pageSet/updatePageSet', params);
}
export async function deletePageSet(params: any) {
    return Api.postWithAuth('/adminapi/pageSet/deletePageSet', params);
}
export async function sortProduct(params: any) {
    return Api.postWithAuth('/adminapi/product/sortProduct', params);
}
export async function addProductCate(params: any) {
    return Api.postWithAuth('/adminapi/product/addProductCate', params);
}
export async function updateProductCate(params: any) {
    return Api.postWithAuth('/adminapi/product/updateProductCate', params);
}
export async function deleteProductCate(params: any) {
    return Api.postWithAuth('/adminapi/product/deleteProductCate', params);
}
export async function getProductCateList(params: any) {
    return Api.postWithAuth('/adminapi/product/getProductCateList', params);
}export async function sortProductCate(params: any) {
    return Api.postWithAuth('/adminapi/product/sortProductCate', params);
}
export async function getPId0ProductCateList(params: any) {
    return Api.postWithAuth('/adminapi/product/getPId0ProductCateList', params);
}
export async function getProductList(params: any) {
    return Api.postWithAuth('/adminapi/product/getProductList', params);
}
export async function updateProductStatus(params: any) {
    return Api.postWithAuth('/adminapi/product/updateProductStatus', params);
}
export async function getProductByUuid(params: any) {
    return Api.postWithAuth('/adminapi/product/getProductByUuid', params);
}
export async function setProductCategoryPerm(params: any) {
    return Api.postWithAuth('/adminapi/product/setProductCategoryPerm', params);
}
export async function getProductCategoryPerm(params: any) {
    return Api.postWithAuth('/adminapi/product/getProductCategoryPerm', params);
}
