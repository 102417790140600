import React, { useEffect, useRef, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import { Button, Col, Form, Input, message, Modal, Row, Select, Space, Switch, TreeSelect } from 'antd';
import { ActionType } from '@ant-design/pro-table';
import appConfig from '../../../appConfig';
import { Link } from 'react-router-dom';
import {
	addInspectionRoute,
	currentInspectionPersonnel,
	deleteRoute,
	findPatrollEmployeeTree,
	findRouteRoster,
	findWuyeRoute,
	inspectionDistributivePersonnel,
	routeBingPlane,
	updataRoute,
	updataRouteState,
} from '../../../services/patrolService';
import GSTable from '../../../components/GSTable';
import Api from '../../../data/API';
import ProxyForm from '../../../components/ProxyForm';
var msg = require('../../../util/SuspensionMsg');
const confirm = Modal.confirm;
let moment = require('moment');
const InspectionRouteList: React.FC = (props: any) => {
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const [addForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [plan, setPlan] = useState([]);
	const [employeeTree, setEmployeeTree] = useState<any>([]);
	const [employeeSelect, setEmployeeSelect] = useState([]);
	const [visible, setVisible] = useState(false);
	const [addRoutevisible, setAddRoutevisible] = useState(false);
	const [routevisible, setRoutevisible] = useState(false);
	const [distributivevisible, setDistributivevisible] = useState(false);
	const [routeUUID, setRouteUUID] = useState('');
	const [routeCycle, setRouteCycle] = useState('');
	const [distributiveUuid, setDistributiveUuid] = useState('');
	const [cycleNum, setCycleNum] = useState('');
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		allSecurity().then();
	}, [])
	const columns = [
		{
			title: '计划名称',
			dataIndex: 'routeName',
			key: 'routeName',
			render: (text: string, record: any) => (
				<span>
					{
						<a
							onClick={() => {
								update(record);
							}}
						>
							{text}
						</a>
					}
				</span>
			),
		},
		{
			title: '计划周期',
			dataIndex: 'routeCycle',
			key: 'routeCycle',
		},
		{
			title: '计划有效期',
			dataIndex: 'startTime',
			key: 'startTime',
			render: (text: string, record: any) => {
				if (!record.startTime) {
					return "-"
				}
				return moment(record.startTime).format('YYYY-MM-DD') + "-" + moment(record.endTime).format('YYYY-MM-DD')
			}
		},
		{
			title: '排班设置',
			dataIndex: 'name',
			key: "name",
			render: (text: string, record: any) => {
				if (!record.name) {
					return 	<a onClick={() => {
						distributive(record);
					}}>暂未排班</a>
				}
				return <a onClick={() => {
					distributive(record);
				}}>{text}</a>
			}
		},
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			render: (text: string, record: any) => (
				<Switch
					defaultChecked={record.state}
					onChange={() => {
						Isstate(record);
					}}
				/>
			),
		},
		{
			title: '平面图',
			dataIndex: 'planeImg',
			key: 'planeImg',
			render: (text: string, record: any) => (
				<span>
					{record.planeImg === '' || record.planeImg === null || record.planeImg === undefined ? (
						'未设置'
					) : (
						<img
							onClick={() => {
								findPlan(record.uuid);
							}}
							src={appConfig.cdnRootPath + record.planeImg}
							style={{ width: 80, height: 50 }}
						/>
					)}
				</span>
			),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => {
				return (
					<Space>
						{record.planeImg === '' || record.planeImg === null || record.planeImg === undefined ? (
							<a
								onClick={() => {
									findPlan(record.uuid);
								}}
							>
								设置点位
							</a>
						) : (
							<a>
								<Link
									to={`/patrolinspection/route_choose_device?uuid=${record.planeUuid}&routeUuid=${record.uuid}&routeType=xunjian`}
								>
									设置点位
								</Link>
							</a>
						)}
						<a
							onClick={() => {
								getuuid(record);
							}}
						>
							删除
						</a>
					</Space>
				);
			},
		},
	];

	const getuuid = (res: any) => {
		confirm({
			title: '您确认要删除该计划吗？',
			okType: 'danger',
			onOk() {
				deleteItem(res);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const deleteItem = async (item: any) => {
		if (item.uuid === null) {
			msg.suspensionMsg({ content: '删除异常', type: 'danger' });
			return;
		}
		const res = await deleteRoute({
			uuid: item.uuid,
		});
		if (res.err === 0) {
			msg.suspensionMsg({ content: '删除成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '删除失败', type: 'error' });
		}
	};

	const distributive = (value: any) => {
		setDistributiveUuid(value.uuid);
		setRouteCycle(value.routeCycle);
		// getDistributiveEmp(value.uuid).then(() => {});
		let employeeUuids: any = [];
		value.userUuids.map((peopleUuid: any) => {
			for (let i = 0; i<employeeTree.length; i++) {
				if (employeeTree[i].children) {
					for (let j=0; j<employeeTree[i].children.length; j++) {
						if (props.deviceId !== '' && employeeTree[i].children[j].value.includes(peopleUuid)) {
							employeeUuids.push(employeeTree[i].children[j].value);
							console.log('找到了：：：', employeeTree[i].children[j].value);
							return
						}
					}
				}
			}
		})
		setEmployeeSelect(employeeUuids);
		setCycleNum(value.zhouqi);
		setDistributivevisible(true);
	};

	const getDistributiveEmp = async (uuid: string) => {
		setEmployeeSelect([])
		setCycleNum("")
		const res = await currentInspectionPersonnel({
			routeUuid: uuid,
		});
		if (res.err === 0) {
			console.log('获得当前巡检计划已分配人员', res);
			let employeeUuids: any = [];
			res.data.map((peopleUuid: any) => {
				for (let i = 0; i<employeeTree.length; i++) {
					if (employeeTree[i].children) {
						for (let j=0; j<employeeTree[i].children.length; j++) {
							if (props.deviceId !== '' && employeeTree[i].children[j].value.includes(peopleUuid)) {
								employeeUuids.push(employeeTree[i].children[j].value);
								console.log('找到了：：：', employeeTree[i].children[j].value);
								return
							}
						}
					}
				}
			})
			// setEmployeeSel(employeeUuids);
			setEmployeeSelect(employeeUuids);
			setCycleNum(res.count);
		} else {
			setEmployeeSelect([])
			setCycleNum("")
		}
	};
	const allSecurity = async () => {
		const res = await findPatrollEmployeeTree({});
		if (res.err === 0) {
			setEmployeeTree(res.data);
		}
	};

	const findPlan = async (uuid: any) => {
		setRouteUUID(uuid);
		const res = await findRouteRoster({ uuid: uuid });
		if (res.err === 0) {
			console.log('====>', res);
			if (res.replace === 'NO') {
				msg.suspensionMsg({ content: '此计划已存在排班数据，不能更换平面图', type: 'danger' });
				return;
			} else {
				Api.postWithAuth('/api/patrolinspection/findPlan', {}).then((res) => {
					if (res.err === 0) {
						console.log('获得平面图列表', res);
						setPlan(res.data);
					}
				});
				setVisible(true);
			}
		}
	};

	const update = (value: any) => {
		const formData: any = {
			uuid: value.uuid,
			routeName: value.routeName,
			inspectionCycles:value.routeCycle
		};
		form.setFieldsValue(formData);
		setRoutevisible(true);
	};
	const Isstate = async (value: any) => {
		if (value.uuid === '' || value.uuid === undefined) {
			msg.suspensionMsg({ content: 'uuid不能为空', type: 'danger' });
			return;
		}
		var data = {};
		if (value.state) {
			(data as any).uuid = value.uuid;
			(data as any).state = '0';
		} else {
			(data as any).uuid = value.uuid;
			(data as any).state = '1';
		}
		const res = await updataRouteState(data);
		console.log('', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '修改成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '修改失败', type: 'error' });
		}
	};
	const updataInspection = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data::', data);
			const res = await updataRoute(data);
			console.log('', res);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				setRoutevisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
		});
	};

	const cycleNum1 = (ev: any) => {
		setCycleNum(ev.target.value);
	};
	const employeeOnChange = (value: any) => {
		let set = new Set();
		value.map((item: string) => {
			set.add(item.split(":::")[1])
		});
		setEmployeeSelect([...set]);
	};
	const distributivehandleOk = async () => {
		// if (employeeSelect.length < 1) {
		// 	msg.suspensionMsg({ content: '请选择人员', type: 'danger' });
		// 	return;
		// }
		// if (cycleNum === null || cycleNum === undefined || cycleNum === '') {
		// 	msg.suspensionMsg({ content: '请输入周期数量', type: 'danger' });
		// 	return;
		// }
		if (employeeSelect.length > 0 && !cycleNum) {
			message.error('请输入周期数量')
			return;
		}
		let employeeUuid: any = [];
		employeeSelect.map((item: string) => {
			if (item.includes(":::")) {
				employeeUuid.push(item.split(":::")[1])
			} else {
				employeeUuid.push(item)
			}
		})
		setLoading(true)
		const res = await inspectionDistributivePersonnel({
			distributiveUuid: distributiveUuid,
			employeeSelect: employeeUuid.toString(),
			cycleNum: cycleNum,
			routeCycle: routeCycle,
		});
		setLoading(false)
		console.log('', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '分配成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload()
			}
		} else {
			msg.suspensionMsg({ content: '分配失败', type: 'error' });
		}
		setDistributivevisible(false);
	};
	const addItem = () => {
		addForm.validateFields().then(async (data: any) => {
			const formData: any = {
				inspectionRouteName: data.inspectionRouteName,
				inspectionCycles: data.inspectionCycles,
				routeType: '巡检',
			};
			const res = await addInspectionRoute(formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '添加失败', type: 'error' });
			}
			setAddRoutevisible(false);
		});
	};
	const bindPlane = async (uuid: any) => {
		const res = await routeBingPlane({
			routeUuid: routeUUID,
			planeUuid: uuid,
		});
		if (res.err === 0) {
			msg.suspensionMsg({ content: '绑定成功', type: 'success' });
			actionRef.current.reload();
		} else {
			msg.suspensionMsg({ content: '绑定失败', type: 'error' });
		}
		setVisible(false);
	};
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setAddRoutevisible(true);
			}}
		>
			添加巡检计划
		</Button>,
	];

	return (
		<div>
			<GSTable
			rowKey={"uuid"}
			columns={columns}
				actionRef={actionRef}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findWuyeRoute({
						page: params.current,
						size: params.pageSize,
						routeType: '巡检',
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="编辑计划"
				visible={routevisible}
				onOk={updataInspection}
				onCancel={() => {
					setRoutevisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'计划名称'} name={'routeName'} rules={[{ required: true }]}>
						<Input placeholder={'请输入计划名称'}></Input>
					</FormItem>
					<FormItem label={'计划周期'} name={'inspectionCycles'} rules={[{ required: true }]}>
						<Select disabled placeholder={'选择周期'}>
							<Select.Option key="日检" value={'日检'}>
								日检
							</Select.Option>
							<Select.Option key="周检" value={'周检'}>
								周检
							</Select.Option>
							<Select.Option key="双周检" value={'双周检'}>
								双周检
							</Select.Option>
							<Select.Option key="月检" value={'月检'}>
								月检
							</Select.Option>
							<Select.Option key="季检" value={'季检'}>
								季检
							</Select.Option>
							<Select.Option key="半年检" value={'半年检'}>
								半年检
							</Select.Option>
							<Select.Option key="年检" value={'年检'}>
								年检
							</Select.Option>
						</Select>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="巡检分配人员"
				destroyOnClose={true}
				confirmLoading={loading}
				visible={distributivevisible}
				onOk={distributivehandleOk}
				onCancel={() => {
					setDistributivevisible(false);
				}}
			>
				<div>
					<Row align={'middle'} className={'d-flex align-items-center'}>
						<Col span={8} style={{ textAlign: 'right' }}>
							分配人员：
						</Col>
						<Col span={14}>
							<TreeSelect
								treeData={employeeTree}
								defaultValue={employeeSelect}
								onChange={employeeOnChange}
								treeCheckable={true}
								multiple={true}
								treeNodeFilterProp="title"
								searchPlaceholder="请选择人员"
								dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
								style={{ width: '100%' }}
							/>
						</Col>
					</Row>
					<br />
					<Row>
						<Col span={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
							未来几个{routeCycle}周期：
						</Col>
						<Col span={14}>
							<Input placeholder="请输入" type="number" value={cycleNum} onInput={cycleNum1} />
						</Col>
					</Row>
				</div>
			</Modal>
			<Modal
				title="添加巡检计划"
				visible={addRoutevisible}
				onOk={addItem}
				onCancel={() => {
					setAddRoutevisible(false);
				}}
			>
				<ProxyForm form={addForm}>
					<FormItem label={'计划名称'} name={'inspectionRouteName'} rules={[{ required: true }]}>
						<Input placeholder={'请输入计划名称'}></Input>
					</FormItem>
					<FormItem label={'计划周期'} name={'inspectionCycles'} rules={[{ required: true }]}>
						<Select placeholder={'选择周期'}>
							<Select.Option key="日检" value={'日检'}>
								日检
							</Select.Option>
							<Select.Option key="周检" value={'周检'}>
								周检
							</Select.Option>
							<Select.Option key="双周检" value={'双周检'}>
								双周检
							</Select.Option>
							<Select.Option key="月检" value={'月检'}>
								月检
							</Select.Option>
							<Select.Option key="季检" value={'季检'}>
								季检
							</Select.Option>
							<Select.Option key="半年检" value={'半年检'}>
								半年检
							</Select.Option>
							<Select.Option key="年检" value={'年检'}>
								年检
							</Select.Option>
						</Select>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="平面图"
				width={750}
				visible={visible}
				onOk={() => {
					setVisible(false);
				}}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<Row>

				{/* <div className="row scrollbar-hover" style={{ maxHeight: 400, overflow: 'scroll' }}> */}
					{plan
						? plan.map((item: any, index: any) => (
								// <div className="col-md-6">
							<Col span={6}>
									<a
										onClick={() => {
											bindPlane(item.uuid);
										}}
									>
										<img style={{ height: 200 }} src={Api.cdnRootPath + item.imgName} alt=" " />
										<p style={{ textAlign: 'center' }}>{item.planName}</p>
									</a>
								</Col>
								// </div>
						  ))
						: ''}
					{/* </div> */}
				</Row>

			</Modal>
		</div>
	);
};
export default InspectionRouteList;
