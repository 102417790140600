import React, {useEffect, useRef, useState} from 'react';
import GSTable from '../../../components/GSTable';
import {
    Button,
    Col, DatePicker,
    Dropdown,
    Form,
    Image,
    Input, InputNumber,
    Menu,
    message,
    Modal,
    Row,
    Select,
    Space,
    Switch,
    Tag,
    Upload
} from 'antd';
import AuthorizedView from '../../../util/AuthorizedView';
import {
    addBeijingOffice, addBeijingOfficeDemand, addBeijingOfficeUser,
    addCharacteristicEnterprises,
    deleteBeijingOffice, deleteBeijingOfficeDemand,
    deleteBeijingOfficeUser,
    deleteCharacteristicEnterprises, getBeijingOfficeDemandList,
    getBeijingOfficeUserList,
    getCharacteristicEnterprisesList,
    getList,
    updateBeijingOffice, updateBeijingOfficeDemand, updateBeijingOfficeUser,
    updateCharacteristicEnterprises
} from '../../../services/BeijingOfficeService';
import {ActionType} from '@ant-design/pro-table/lib/typing';
import UploadImgFormItem from '../../../components/UploadImgFormItem';
import appConfig from '../../../appConfig';
import Api from '../../../data/API';
import {
    addWenzhang,
    changeState,
    deleteWenzhang,
    findWenzhangListPc,
    updateWenzhang
} from '../../../services/WenzhangService';
import {ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons/lib';
import WangEditor from '../../../components/WangEditor';
import CustomDateFormItem from '../../assets/components/CustomDateFormItem';
import {getRightsAndInterests, getTreeSchemeTechList, getTreeSchemeTypeList} from '../../../services/scheme';
import * as moment from 'moment';
import CheckedComponents from '../../scenario/components/CheckedComponents';

const industrialFieldData = ['农林牧渔业', '采矿业', '制造业', '电力、燃气及水生产和供应业', '建筑业', '交通运输、仓储和邮政业', '信息传输、计算机服务和软件业', '批发和零售业', '住宿和餐饮业', '金融业', '房地产业', '租赁和商务服务业', '科学研究、技术服务和地质勘查业', '水利、环境和公共设施管理业', '居民服务和其他服务业']
const qualificationData = ['无', '市民营科技企业', '省民营科技企业', '国家高新技术企业', '上市后备企业', '中国驰名商标', '市专利培育企业', '中国名牌产品', '国家免检产品', '市企业技术/工程中心', '省企业技术/工程中心', '其他']
const typeEnum = {
    "驻京办": "城市名片",
    "园区": "园区名片"
}
const BeijingOfficeList: React.FC = () => {
    const FormItem = Form.Item;
    const actionRef = useRef<ActionType>();
    const zixunActionRef = useRef<ActionType>();
    const companyActionRef = useRef<ActionType>();
    const userActionRef = useRef<ActionType>();
    const demandActionRef = useRef<ActionType>();

    const [search, setSearch] = useState("");
    const [type, setType] = useState("");
    const [createdType, setCreatedType] = useState("");
    const [addVisible, setAddVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [industryVisible, setIndustryVisible] = useState(false);
    const [addZiXunVisible, setAddZiXunVisible] = useState(false);
    const [updateZiXunVisible, setUpdateZiXunVisible] = useState(false);
    const [companyVisible, setCompanyVisible] = useState(false);
    const [addCompanyVisible, setAddCompanyVisible] = useState(false);
    const [addUserVisible, setAddUserVisible] = useState(false);
    const [updateUserVisible, setUpdateUserVisible] = useState(false);
    const [updateCompanyVisible, setUpdateCompanyVisible] = useState(false);
    const [updateDemandVisible, setUpdateDemandVisible] = useState(false);
    const [demandVisible, setDemandVisible] = useState(false);
    const [userVisible, setUserVisible] = useState(false);
    const [addDemandVisible, setAddDemandVisible] = useState(false);
    const [typeUuid, setTypeUuid] = useState('');
    const [addForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [addZiXunForm] = Form.useForm();
    const [addCompanyForm] = Form.useForm();
    const [updateCompanyForm] = Form.useForm();
    const [updateZiXunForm] = Form.useForm();
    const [addUserForm] = Form.useForm();
    const [updateUserForm] = Form.useForm();
    const [addDemandForm] = Form.useForm();
    const [updateDemandForm] = Form.useForm();
    const [wenzhangNeirong, setWenzhangNeirong] = useState('');
    const [productContext, setProductContext] = useState('');
    const [tupianUrl, setTupianUrl] = useState('');
    const [source, setSource] = useState('自有平台');
    const [beijingOfficeUuid, setBeijingOfficeUuid] = useState('');
    const [introduceContext, setIntroduceContext] = useState('');
    const [rightsAndInterestList, setRightsAndInterestList] = useState([]);
    const [typeList, setTypeList] = useState([])
    const [techList, setTechList] = useState([]);
    useEffect(() => {
        loadRightsAndInterests().then();
        getTypeList().then();
        getTechList().then()
    }, [])
    const getTypeList = async () => {
        const rsp = await getTreeSchemeTypeList({});
        if (rsp.err === 0) {
            const data = rsp.data || [];
            setTypeList([...data]);
        }
    };

    const getTechList = async () => {
        const rsp = await getTreeSchemeTechList({});
        if (rsp.err === 0) {
            const data = rsp.data || [];
            setTechList([...data]);
        }
    };
    const loadRightsAndInterests = async () => {
        let rsp = await getRightsAndInterests({type: 'zjb'});
        if (rsp.err === 0) {
            setRightsAndInterestList(rsp.data)
        }
    }
    const getFileName = (fileList: any) => {
        console.log('fileList', fileList);
        if (fileList.file.status === 'done') {
            setTupianUrl(fileList.file.response.url);

            console.log(`${fileList.file.name}`);
            console.log('群头像', fileList.file.response.url);
        } else if (fileList.file.status === 'error') {
            message.error(`${fileList.file.name} 上传图片失败`);
        }
    };

    const ziXunColumns = [
        {
            title: '标题',
            dataIndex: 'wenzhangBiaoti',
            key: 'wenzhangBiaoti',
            render: (text: string, record: any) => (
                <a href={appConfig.mobileAppUrl + '/pinglun/tieba?wenzhang_uuid=' + record.uuid} target={'_blank'}>
                    {text}
                </a>
            ),
        },
        {
            title: '封面图',
            key: 'tupianUrl',
            dataIndex: 'tupianUrl',
            render: (text: string, record: any) => (
                <span>
					{record.tupianUrl === null || record.tupianUrl === '' || record.tupianUrl === undefined ? (
                        ''
                    ) : (
                        <Image className="homePage" src={Api.cdnRootPath + record.tupianUrl} width={100} height={70}/>
                    )}
				</span>
            ),
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            render: (text: string, record: any) => (
                <Tag color={record.status === '发布' ? 'green' : 'yellow'}>{record.status}</Tag>
            ),
        },
        {
            title: '开启评论',
            key: 'commentStatus',
            dataIndex: 'commentStatus',
        },
        {
            title: '全屏模式',
            key: 'isFullscreen',
            dataIndex: 'isFullscreen',
        },
        {
            title: '作者',
            dataIndex: 'fabuRenyuan',
            key: 'fabuRenyuan',
        },
        {
            title: '创建日期',
            key: 'fabuShijian',
            dataIndex: 'fabuShijian',
        },
        {
            title: '操作',
            key: 'action',
            render: (text: string, record: any) => (
                <Space>
                    <a onClick={() => {
                        updateZiXunForm.setFieldsValue({
                            uuid: record.uuid,
                            wenzhangBiaoti: record.wenzhangBiaoti,
                            wenzhangNeirong: record.zhuti,
                            wEditorValue: record.zhuti,
                            typeUuid: record.typeUuid,
                            faBuRen: record.fabuRenyuan,
                            source: record.source || '自有平台',
                            wenZhangUrl: record.wenZhangUrl,
                            isSettop: record.isSettop || 0,
                        });
                        setWenzhangNeirong(record.wenzhangNeirong)
                        setTupianUrl(record.tupianUrl)
                        setUpdateZiXunVisible(true)
                    }}>编辑</a>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item>
                                    <a
                                        onClick={() => {
                                            ChangeState(record);
                                        }}
                                    >
                                        {record.status === '发布' ? '下线' : '发布'}
                                    </a>
                                </Menu.Item>
                                <Menu.Item>
                                    <a
                                        onClick={() => {
                                            deleteItem(record);
                                        }}
                                    >
                                        删除
                                    </a>
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <a style={{textDecoration: 'none'}} href="#">
                            更多
                        </a>
                    </Dropdown>
                </Space>
            ),
        },
    ];
    const columns = [
        {
            title: '城市名片/产业名片名称',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            valueEnum: typeEnum

        },
        {
            title: '城市名称',
            dataIndex: 'cityName',
            key: 'cityName'
        },
        {
            title: '城市简介',
            ellipsis: true,
            dataIndex: 'cityProfile',
            key: 'cityProfile'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: '创建人',
            dataIndex: 'createdName',
            key: 'createdName'
        },
        {
            title: '创建人手机号',
            dataIndex: 'createdPhone',
            key: 'createdPhone'
        },
        {
            title: '创建方式',
            dataIndex: 'createdType',
            key: 'createdType'
        },
        {
            title: '操作',
            width: '20%',
            key: 'action',
            render: (text: string, item: any) => {
                return <Space>
                    <AuthorizedView needAuthority={'channel:manager:update'}>
                        <a onClick={() => {
                            setUpdateVisible(true)
                            updateForm.setFieldsValue({
                                ...item,
                                szMemberExpirationTime: moment(item.memberExpirationTime).format("YYYY-MM-DD HH:mm:ss")
                            })
                        }}>编辑</a>
                    </AuthorizedView>
                    {/*<a onClick={() => {setIndustryVisible(true); setTypeUuid(item.uuid);}}>产业政策</a>*/}
                    {/*<a onClick={() => {setCompanyVisible(true); setBeijingOfficeUuid(item.uuid)}}>特色企业</a>*/}
                    {/*<a onClick={() => {setDemandVisible(true); setBeijingOfficeUuid(item.uuid)}}>需求列表</a>*/}
                    <a onClick={() => {
                        setUserVisible(true);
                        setBeijingOfficeUuid(item.uuid)
                    }}>名片用户</a>
                    <AuthorizedView needAuthority={'channel:manager:delete'}>
                        <a onClick={() => {
                            Modal.confirm({
                                title: '删除',
                                content: '您确定删除该渠道吗？',
                                okType: 'danger',
                                okText: '删除',
                                onOk: async () => {
                                    let data = {
                                        uuid: item.uuid
                                    };
                                    let rsp = await deleteBeijingOffice(data);
                                    if (rsp.err === 0) {
                                        actionRef.current.reload();
                                    } else {
                                        message.error(rsp.msg);
                                    }
                                }
                            });
                        }}>删除</a>
                    </AuthorizedView>

                </Space>;
            }
        }
    ];

    const companyColumns = [
        {
            title: '企业名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '介绍',
            dataIndex: 'introduce',
            key: 'introduce'
        },
        {
            title: '产品领域',
            dataIndex: 'industrialField',
            key: 'industrialField'
        },
        {
            title: '资质',
            dataIndex: 'qualification',
            key: 'qualification'
        },

        {
            title: '联系人',
            dataIndex: 'contacts',
            key: 'contacts'
        },
        {
            title: '操作',
            key: 'action',
            render: (text: string, item: any) => {
                return <Space>
                    <a onClick={() => {
                        setUpdateCompanyVisible(true)
                        updateCompanyForm.setFieldsValue({
                            ...item,
                            qualification: (item.qualification || '').split(",")
                        });
                        setBeijingOfficeUuid(item.uuid);
                        setTupianUrl(item.tupian)
                        setProductContext(item.product)
                        setIntroduceContext(item.introduce)
                    }}>编辑</a>
                    <a onClick={() => {
                        Modal.confirm({
                            title: '删除',
                            content: '您确定删除该企业吗？',
                            okType: 'danger',
                            okText: '删除',
                            onOk: async () => {
                                let data = {
                                    uuid: item.uuid
                                };
                                let rsp = await deleteCharacteristicEnterprises(data);
                                if (rsp.err === 0) {
                                    actionRef.current.reload();
                                } else {
                                    message.error(rsp.msg);
                                }
                            }
                        });
                    }}>删除</a>

                </Space>;
            }
        }
    ];


    const userColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: '职位',
            dataIndex: 'position',
            key: 'position'
        },
        {
            title: '操作',
            key: 'action',
            render: (text: string, item: any) => {
                return <Space>
                    <a onClick={() => {
                        setUpdateUserVisible(true)
                        updateUserForm.setFieldsValue({...item, isVipUser: item.vipUser})
                    }}>编辑</a>
                    <a onClick={() => {
                        Modal.confirm({
                            title: '删除',
                            content: '您确定删除该驻京办用户吗？',
                            okType: 'danger',
                            okText: '删除',
                            onOk: async () => {
                                let data = {
                                    uuid: item.uuid
                                };
                                let rsp = await deleteBeijingOfficeUser(data);
                                if (rsp.err === 0) {
                                    userActionRef.current.reload();
                                } else {
                                    message.error(rsp.msg);
                                }
                            }
                        });
                    }}>删除</a>

                </Space>;
            }
        }
    ];

    const demandColumns = [
        {
            title: '名称',
            dataIndex: 'projectName',
            key: 'projectName'
        },
        {
            title: '需求',
            dataIndex: 'demand',
            ellipsis: true,
            key: 'demand'

        },
        {
            title: '需求单位',
            dataIndex: 'demandCompany',
            ellipsis: true,
            key: 'demandCompany'

        },
        {
            title: '需求单位简介',
            dataIndex: 'demandCompanyNote',
            ellipsis: true,
            key: 'demandCompanyNote'

        },
        {
            title: '联系人',
            dataIndex: 'contacts',
            key: 'contacts'
        },
        {
            title: '联系电话',
            dataIndex: 'contactsNumber',
            key: 'contactsNumber'
        },
        {
            title: '操作',
            key: 'action',
            render: (text: string, item: any) => {
                return <Space>
                    <a onClick={() => {
                        setUpdateDemandVisible(true)
                        updateDemandForm.setFieldsValue({
                            ...item,
                            publishTime: moment(item.publishTime),
                            expectedStartTime: moment(item.expectedStartTime)
                        })
                    }}>编辑</a>
                    <a onClick={() => {
                        Modal.confirm({
                            title: '删除',
                            content: '您确定删除该需求吗？',
                            okType: 'danger',
                            okText: '删除',
                            onOk: async () => {
                                let data = {
                                    uuid: item.uuid
                                };
                                let rsp = await deleteBeijingOfficeDemand(data);
                                if (rsp.err === 0) {
                                    demandActionRef.current.reload();
                                } else {
                                    message.error(rsp.msg);
                                }
                            }
                        });
                    }}>删除</a>

                </Space>;
            }
        }
    ];

    const ChangeState = (value: any) => {
        Modal.confirm({
            title: '提示',
            content: `您确定要${value.status === '发布' ? '下线' : '发布'}该政策?`,
            onOk: async () => {
                const res = await changeState({
                    uuid: value.uuid,
                    status: value.status === '发布' ? '草稿' : '发布',
                });

                if (res) {
                    message.success('操作成功');
                    if (zixunActionRef.current) {
                        zixunActionRef.current.reload();
                    }
                } else {
                    message.error(res.msg || '操作失败');
                }
            },
        });
    };

    const deleteItem = (value: any) => {
        Modal.confirm({
            title: '删除政策',
            icon: <ExclamationCircleOutlined/>,
            content: '您确定删除吗',
            okType: 'danger',
            onOk: async () => {
                const res = await deleteWenzhang({uuid: value.uuid});
                if (res.err === 0) {
                    message.success('删除成功');
                    if (zixunActionRef.current) {
                        zixunActionRef.current.reload();
                    }
                } else {
                    message.error('删除失败');
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const tableSearchBarRender = () => [
        <Space>
            <Select style={{width: 100}} defaultValue={type} onChange={(e:string) => setType(e)}>
				<select value={""}>名片类型</select>
                <Select.Option value={'驻京办'}>城市名片</Select.Option>
                <Select.Option value={'园区'}>园区名片</Select.Option>
            </Select>
			<Select style={{width: 100}} defaultValue={createdType} onChange={(e:string) => setCreatedType(e)}>
				<select value={""}>创建类型</select>
				<Select.Option value={'内部'}>内部</Select.Option>
				<Select.Option value={'外部'}>外部</Select.Option>
			</Select>
            <Input
                style={{minWidth: 150}}
                placeholder="名称"
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
            />

            <Button
                type="primary"
                onClick={() => {
                    if (actionRef.current) {
                        actionRef.current.reloadAndRest();
                    }
                }}
            >
                查询
            </Button>
        </Space>
    ];
    const tableOperationsBarRender = () => [
        <AuthorizedView needAuthority={'channel:manager:add'}>
            <Button type="primary" onClick={() => {
                setAddVisible(true)
                addForm.resetFields();
            }}>
                新增
            </Button>
        </AuthorizedView>
    ];
    const addBeijingOfficeItem = async () => {
        let data = await addForm.validateFields()
        let rsp = await addBeijingOffice(data);
        if (rsp.err !== 0) {
            message.error(rsp.msg)
            return
        }
        setAddVisible(false)
        actionRef.current.reload();
    }
    const updateBeijingOfficeItem = async () => {
        let data = await updateForm.validateFields()
        let rsp = await updateBeijingOffice(data);
        if (rsp.err !== 0) {
            message.error(rsp.msg)
            return
        }
        setUpdateVisible(false)
        actionRef.current.reload();
    }
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>上传封面图</div>
        </div>
    );

    const zancun = () => {
        addZiXunForm.validateFields().then(async (data: any) => {
            console.log('data::', data);
            const formData = {
                typeUuid: typeUuid,
                tagIds: "",
                commentStatus: "关闭",
                faBuRen: data.faBuRen,
                wenzhangBiaoti: data.wenzhangBiaoti,
                tupianUrl: tupianUrl,
                status: '草稿',
                isFullscreen: "关闭",
                wenzhangNeirong: wenzhangNeirong,
                wenZhangUrl: data.wenZhangUrl,
                source: data.source,
                isSettop: data.isSettop,
            };
            const res = await addWenzhang(formData);
            if (res.err === 0) {
                message.success('保存成功');
                setAddZiXunVisible(false)
                zixunActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const addCompany = () => {
        addCompanyForm.validateFields().then(async (data: any) => {
            console.log('data::', data);
            const formData = {
                ...data,
                introduce: introduceContext,
                qualification: data.qualification.toString(),
                product: productContext
            }
            const res = await addCharacteristicEnterprises(formData);
            if (res.err === 0) {
                message.success('保存成功');
                setAddCompanyVisible(false)
                companyActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const addUser = () => {
        addUserForm.validateFields().then(async (data: any) => {
            const res = await addBeijingOfficeUser(data);
            if (res.err === 0) {
                message.success('保存成功');
                setAddUserVisible(false)
                userActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const updateUser = () => {
        updateUserForm.validateFields().then(async (data: any) => {
            const res = await updateBeijingOfficeUser(data);
            if (res.err === 0) {
                message.success('保存成功');
                setUpdateUserVisible(false)
                userActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };

    const updateCompany = () => {
        updateCompanyForm.validateFields().then(async (data: any) => {
            console.log('data::', data);
            const formData = {
                ...data,
                introduce: introduceContext,
                qualification: data.qualification.toString(),
                product: productContext
            }
            const res = await updateCharacteristicEnterprises(formData);
            if (res.err === 0) {
                message.success('修改成功');
                setUpdateCompanyVisible(false)
                companyActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const updateTuwen = () => {
        updateZiXunForm.validateFields().then(async (data: any) => {
            console.log("修改,", data)
            const formData: any = {
                uuid: data.uuid,
                typeUuid: typeUuid,
                faBuRen: data.faBuRen,
                commentStatus: "关闭",
                wenzhangBiaoti: data.wenzhangBiaoti,
                wenzhangNeirong: wenzhangNeirong,
                isFullscreen: "关闭",
                tupianUrl: tupianUrl,
                seoTitle: data.seoTitle,
                seoKeywords: data.seoKeywords,
                seoDesc: data.seoDesc,
                tagIds: "",
                wenZhangUrl: data.wenZhangUrl,
                source: data.source,
                isSettop: data.isSettop,
            };
            const res = await updateWenzhang(formData);
            if (res.err === 0) {
                message.success(res.msg);
                setUpdateZiXunVisible(false)
                zixunActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const addDemand = () => {
        addDemandForm.validateFields().then(async (data: any) => {
            const res = await addBeijingOfficeDemand({
                ...data,
                publishTime: data.publishTime.format('YYYY-MM-DD'),
                expectedStartTime: data.expectedStartTime.format('YYYY-MM-DD')
            });
            if (res.err === 0) {
                message.success('保存成功');
                setAddDemandVisible(false)
                demandActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const updateDemand = () => {
        updateDemandForm.validateFields().then(async (data: any) => {
            const res = await updateBeijingOfficeDemand({
                ...data,
                publishTime: data.publishTime.format('YYYY-MM-DD'),
                expectedStartTime: data.expectedStartTime.format('YYYY-MM-DD')
            });
            if (res.err === 0) {
                message.success('保存成功');
                setUpdateDemandVisible(false)
                demandActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    return (
        <>
            <GSTable
                columns={columns}
                actionRef={actionRef}
                searchBarRender={tableSearchBarRender()}
                operationsBarRender={tableOperationsBarRender()}
                rowKey="uuid"
                request={async (params: any = {}, sort: any, filter: any) => {
                    const formData: any = {
                        page: params.current,
                        size: params.pageSize,
						type: type,
						createdType: createdType,
                        search: search
                    };
                    const res = await getList(formData);
                    return {
                        data: res.data,
                        total: res.total,
                        success: true
                    };
                }}
            />

            <Modal title="添加" destroyOnClose={true} visible={addVisible} onCancel={() => {
                setAddVisible(false);
            }} onOk={addBeijingOfficeItem}>
                <Form form={addForm} labelCol={{span: 6}} labelAlign={'left'}>
                    <Form.Item name={'name'} label={'城市名片名称'}
                               rules={[{required: true, message: '输入城市名片名称'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'type'} label={'类型'} rules={[{required: true, message: '请选择类型'}]}>
                        <Select>
                            <Select.Option key={'驻京办'} value={'驻京办'}>
                                城市名片
                            </Select.Option>
                            <Select.Option key={'园区'} value={'园区'}>
                                园区名片
                            </Select.Option>

                        </Select>
                    </Form.Item>
                    <Form.Item name={"status"} label={"状态"} rules={[{required: true, message: '状态'}]}>
                        <Select>
                            <Select.Option key="待编辑" value={"待编辑"}>
                                待编辑
                            </Select.Option>
                            <Select.Option key="上线" value={"上线"}>
                                上线
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    {/*<Form.Item  name={'cityName'} label={'城市名称'} rules={[{ required: true, message: '输入城市名称' }]}>*/}
                    {/*	<Input/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'cityImgList'} label={'城市图片'} rules={[{ required: true, message: '输入城市图片' }]}>*/}
                    {/*	<UploadImgFormItem multiple={true}  hiddenImgCorp={true}*/}
                    {/*					   listType={'picture-card'}*/}
                    {/*					   heightRatio={1}*/}
                    {/*					   widthRatio={1} />*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'cityProfile'} label={'城市简介'} rules={[{ required: true, message: '输入城市简介' }]}>*/}
                    {/*	<Input.TextArea/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'industrialEcologyImgList'} label={'产业生态图片'} rules={[{ required: true, message: '产业生态图片' }]}>*/}
                    {/*	<UploadImgFormItem multiple={true}  hiddenImgCorp={true}*/}
                    {/*					   listType={'picture-card'}*/}
                    {/*					   heightRatio={1}*/}
                    {/*					   widthRatio={1} />*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'industrialEcologyDescribe'} label={'产业生态描述'} rules={[{ required: true, message: '输入产业生态描述' }]}>*/}
                    {/*	<Input.TextArea/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item name={'rightsAndInterestsUuid'} label={'会员类型'}
                               rules={[{required: true, message: '请选择会员类型'}]}>
                        <Select>
                            {
                                rightsAndInterestList.map(item => {
                                    return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name={'szMemberExpirationTime'} label={'会员有效期'}
                               rules={[{required: true, message: '会员有效期'}]}>
                        <CustomDateFormItem format={'YYYY-MM-DD HH:mm:ss'} showTime={true} style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item name={'vipUserNum'} label={'会员账号数'}
                               rules={[{required: true, message: '会员账号数'}]}>
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>

                </Form>
            </Modal>
            <Modal title="修改" destroyOnClose={true} visible={updateVisible} onCancel={() => {
                setUpdateVisible(false);
            }} onOk={updateBeijingOfficeItem}>
                <Form form={updateForm} labelCol={{span: 6}} labelAlign={'left'}>
                    <Form.Item name={'uuid'} hidden={true} label={'驻京办名称'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'name'} label={'城市名片名称'}
                               rules={[{required: true, message: '输入城市名片名称'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'type'} label={'类型'} rules={[{required: true, message: '请选择类型'}]}>
                        <Select>
                            <Select.Option key={'驻京办'} value={'驻京办'}>
                                城市名片
                            </Select.Option>
                            <Select.Option key={'园区'} value={'园区'}>
                                园区名片
                            </Select.Option>

                        </Select>
                    </Form.Item>
                    <Form.Item name={"status"} label={"状态"} rules={[{required: true, message: '状态'}]}>
                        <Select>
                            <Select.Option key="待编辑" value={"待编辑"}>
                                待编辑
                            </Select.Option>
                            <Select.Option key="上线" value={"上线"}>
                                上线
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    {/*<Form.Item  name={'cityName'} label={'城市名称'} rules={[{ required: true, message: '输入城市名称' }]}>*/}
                    {/*	<Input/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'cityImgList'} label={'城市图片'} rules={[{ required: true, message: '输入城市图片' }]}>*/}
                    {/*	<UploadImgFormItem multiple={true}  hiddenImgCorp={true}*/}
                    {/*					   listType={'picture-card'}*/}
                    {/*					   heightRatio={1}*/}
                    {/*					   widthRatio={1} />*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'cityProfile'} label={'城市简介'} rules={[{ required: true, message: '输入城市简介' }]}>*/}
                    {/*	<Input.TextArea/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'industrialEcologyImgList'} label={'产业生态图片'} rules={[{ required: true, message: '产业生态图片' }]}>*/}
                    {/*	<UploadImgFormItem  multiple={true}  hiddenImgCorp={true}*/}
                    {/*					   listType={'picture-card'}*/}
                    {/*					   heightRatio={1}*/}
                    {/*					   widthRatio={1} />*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item  name={'industrialEcologyDescribe'} label={'产业生态描述'} rules={[{ required: true, message: '输入产业生态描述' }]}>*/}
                    {/*	<Input.TextArea/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item name={'rightsAndInterestsUuid'} label={'会员类型'}
                               rules={[{required: true, message: '请选择会员类型'}]}>
                        <Select>
                            {
                                rightsAndInterestList.map(item => {
                                    return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name={'szMemberExpirationTime'} label={'会员有效期'}
                               rules={[{required: true, message: '会员有效期'}]}>
                        <CustomDateFormItem format={'YYYY-MM-DD HH:mm:ss'} showTime={true} style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item name={'vipUserNum'} label={'会员账号数'}
                               rules={[{required: true, message: '会员账号数'}]}>
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title={'产业政策'} destroyOnClose={true} width={1200} visible={industryVisible} onCancel={() => {
                setIndustryVisible(false)
            }} footer={null}>
                <GSTable
                    columns={ziXunColumns}
                    actionRef={zixunActionRef}
                    searchBarRender={<Space>
                        <Input
                            placeholder="搜索"
                            onChange={(event) => {
                                setSearch(event.target.value);

                            }}
                        />
                        <Button type={"primary"} onClick={() => {
                            if (zixunActionRef.current) {
                                zixunActionRef.current.reloadAndRest();
                            }
                        }}>查询</Button>
                    </Space>}
                    operationsBarRender={[
                        <Button type={'primary'} onClick={() => {
                            setAddZiXunVisible(true)
                        }}>创建</Button>
                    ]}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        let res = await findWenzhangListPc({
                            typeUuid: typeUuid,
                            Search: search,
                            page: params.current,
                            size: params.pageSize,
                        });
                        return {
                            data: res.data,
                            total: res.total,
                            success: true,
                        };
                    }}
                />
            </Modal>

            <Modal title={"添加政策"} destroyOnClose={true} width={1200} visible={addZiXunVisible} onOk={zancun}
                   onCancel={() => {
                       setAddZiXunVisible(false)
                   }}>
                <div className="content">
                    <div className="add-tuwen-div">
                        <Form labelAlign={'left'} form={addZiXunForm}>
                            <Row gutter={8}>
                                <Col span={12}>
                                    <FormItem label={'文章来源'} name={'source'} rules={[{required: true}]}
                                              initialValue={source}>
                                        <Select>
                                            <Select.Option value={'自有平台'}>自有平台</Select.Option>
                                            <Select.Option value={'微信公众号'}>微信公众号</Select.Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label={'标题'} name={'wenzhangBiaoti'} rules={[{required: true}]}>
                                        <Input/>
                                    </FormItem>
                                    <FormItem label={'作者'} rules={[{required: true}]} name={'faBuRen'}>
                                        <Input/>
                                    </FormItem>
                                    <FormItem label={'文章地址'} name={'wenZhangUrl'} hidden={source !== '微信公众号'}>
                                        <Input/>
                                    </FormItem>
                                    <FormItem label={'置顶'} initialValue={0} hidden={true} name={'isSettop'}>
                                        <Select>
                                            <Select.Option value={0}>不置顶</Select.Option>
                                            <Select.Option value={1}>置顶</Select.Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <Row justify={'space-between'}>
                                        <Col>
                                            <Upload
                                                name="image"
                                                listType="picture-card"
                                                className="avatar-uploader1"
                                                showUploadList={false}
                                                action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
                                                onChange={getFileName}
                                                accept={'.jpg,.png'}
                                            >
                                                {tupianUrl !== null && tupianUrl !== undefined && tupianUrl !== '' ? (
                                                    <img
                                                        alt="avatar"
                                                        style={{width: 128, height: 128, objectFit: 'cover'}}
                                                        src={Api.cdnRootPath + tupianUrl}
                                                    />
                                                ) : (
                                                    uploadButton
                                                )}
                                            </Upload>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <FormItem
                                hidden={source === '微信公众号'}
                                label={'详情'}
                                name={'wenzhangNeirong'}
                            >
                                <WangEditor
                                    value1={wenzhangNeirong}
                                    changeCallback={(res: any) => setWenzhangNeirong(res)}
                                />
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal title={"编辑政策"} destroyOnClose={true} width={1200} visible={updateZiXunVisible} onOk={updateTuwen}
                   onCancel={() => {
                       setUpdateZiXunVisible(false)
                   }}>
                <div className="content">
                    <div className="add-tuwen-div">
                        <Form labelAlign={'left'} form={updateZiXunForm}>
                            <FormItem label={'id'} hidden={true} name={'uuid'} rules={[{required: true}]}>
                                <Input/>
                            </FormItem>
                            <Row gutter={8}>
                                <Col span={12}>
                                    <FormItem label={'文章来源'} name={'source'} rules={[{required: true}]}
                                              initialValue={source}>
                                        <Select defaultValue={source} onChange={(e: string) => {
                                            setSource(e)
                                        }}>
                                            <Select.Option value={'自有平台'}>自有平台</Select.Option>
                                            <Select.Option value={'微信公众号'}>微信公众号</Select.Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem label={'标题'} name={'wenzhangBiaoti'} rules={[{required: true}]}>
                                        <Input/>
                                    </FormItem>
                                    <FormItem label={'作者'} rules={[{required: true}]} name={'faBuRen'}>
                                        <Input/>
                                    </FormItem>
                                    <FormItem label={'文章地址'} name={'wenZhangUrl'} hidden={source !== '微信公众号'}>
                                        <Input/>
                                    </FormItem>
                                    <FormItem label={'置顶'} hidden={true} name={'isSettop'}>
                                        <Select>
                                            <Select.Option value={0}>不置顶</Select.Option>
                                            <Select.Option value={1}>置顶</Select.Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <Row justify={'space-between'}>
                                        <Col>
                                            <Upload
                                                name="image"
                                                listType="picture-card"
                                                className="avatar-uploader1"
                                                showUploadList={false}
                                                action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
                                                onChange={getFileName}
                                                accept={'.jpg,.png'}
                                            >
                                                {tupianUrl !== null && tupianUrl !== undefined && tupianUrl !== '' ? (
                                                    <img
                                                        alt="avatar"
                                                        style={{width: 128, height: 128, objectFit: 'cover'}}
                                                        src={Api.cdnRootPath + tupianUrl}
                                                    />
                                                ) : (
                                                    uploadButton
                                                )}
                                            </Upload>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <FormItem
                                hidden={source === '微信公众号'}
                                label={'详情'}
                                name={'wenzhangNeirong'}
                            >
                                <WangEditor
                                    value1={wenzhangNeirong}
                                    changeCallback={(res: any) => setWenzhangNeirong(res)}
                                />
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal title={'特色企业'} destroyOnClose={true} width={1200} visible={companyVisible} onCancel={() => {
                setCompanyVisible(false)
            }} footer={null}>
                <GSTable
                    columns={companyColumns}
                    actionRef={companyActionRef}
                    searchBarRender={<Space>
                        <Input
                            placeholder="搜索"
                            onChange={(event) => {
                                setSearch(event.target.value);

                            }}
                        />
                        <Button type={"primary"} onClick={() => {
                            if (companyActionRef.current) {
                                companyActionRef.current.reloadAndRest();
                            }
                        }}>查询</Button>
                    </Space>}
                    operationsBarRender={[
                        <Button type={'primary'} onClick={() => {
                            setAddCompanyVisible(true);
                            addCompanyForm.resetFields()
                        }}>添加</Button>
                    ]}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        let res = await getCharacteristicEnterprisesList({
                            Search: search,
                            beijingOfficeUuid: beijingOfficeUuid,
                            page: params.current,
                            size: params.pageSize,
                        });
                        return {
                            data: res.data,
                            total: res.total,
                            success: true,
                        };
                    }}
                />
            </Modal>

            <Modal title={"添加特色企业"} destroyOnClose={true} visible={addCompanyVisible} onOk={addCompany}
                   onCancel={() => {
                       setAddCompanyVisible(false)
                   }}>
                <Form labelAlign={'left'} labelCol={{span: 4}} form={addCompanyForm}>
                    <FormItem label={'驻京办'} hidden={true} name={'beijingOfficeUuid'} initialValue={beijingOfficeUuid}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'名称'} name={'name'} rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'企业图片'} name={'tupian'} rules={[{required: true, message: "请上传图片"}]}>
                        <UploadImgFormItem multiple={false} hiddenImgCorp={true}
                                           listType={'picture-card'}
                                           heightRatio={1}
                                           widthRatio={1}/>
                    </FormItem>

                    <FormItem label={'产品领域'} rules={[{required: true, message: "请输入产品领域"}]}
                              name={'industrialField'}>
                        <Select>
                            {industrialFieldData.map(item => {
                                return <Select.Option key={item} value={item}>{item}</Select.Option>
                            })}
                        </Select>
                    </FormItem>
                    <FormItem label={'资质'} name={'qualification'} rules={[{required: true, message: "请输入资质"}]}>
                        <Select mode={'multiple'}>
                            {qualificationData.map(item => {
                                return <Select.Option key={item} value={item}>{item}</Select.Option>
                            })}
                        </Select>
                    </FormItem>
                    <FormItem label={'需求'} name={'demand'} rules={[{required: true, message: "请输入资质"}]}>
                        <Input.TextArea/>
                    </FormItem>
                    <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'联系电话'} name={'tel'} rules={[{required: true, message: "请输入联系电话"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'简介'} name={'introduce'}>
                        <WangEditor
                            value1={introduceContext}
                            changeCallback={(res: any) => setIntroduceContext(res)}
                        />
                    </FormItem>
                    <FormItem
                        label={'产品介绍'}
                        name={'product'}
                    >
                        <WangEditor
                            id={'productContext'}
                            value1={productContext}
                            changeCallback={(res: any) => setProductContext(res)}
                        />
                    </FormItem>

                </Form>
            </Modal>
            <Modal title={"修改企业"} destroyOnClose={true} visible={updateCompanyVisible} onOk={updateCompany}
                   onCancel={() => {
                       setUpdateCompanyVisible(false)
                   }}>
                <Form labelAlign={'left'} labelCol={{span: 4}} form={updateCompanyForm}>
                    <FormItem label={'uuid'} hidden={true} name={'uuid'}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'驻京办'} hidden={true} name={'beijingOfficeUuid'} initialValue={beijingOfficeUuid}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'名称'} name={'name'} rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'企业图片'} name={'tupian'} rules={[{required: true, message: "请上传图片"}]}>
                        <UploadImgFormItem multiple={false} hiddenImgCorp={true}
                                           listType={'picture-card'}
                                           heightRatio={1}
                                           widthRatio={1}/>
                    </FormItem>
                    <FormItem label={'产品领域'} rules={[{required: true, message: "请输入产品领域"}]}
                              name={'industrialField'}>
                        <Select>
                            {industrialFieldData.map(item => {
                                return <Select.Option key={item} value={item}>{item}</Select.Option>
                            })}
                        </Select>
                    </FormItem>
                    <FormItem label={'资质'} name={'qualification'} rules={[{required: true, message: "请输入资质"}]}>
                        <Select mode={'multiple'}>
                            {qualificationData.map(item => {
                                return <Select.Option key={item} value={item}>{item}</Select.Option>
                            })}
                        </Select>
                    </FormItem>
                    <FormItem label={'需求'} name={'demand'} rules={[{required: true, message: "请输入资质"}]}>
                        <Input.TextArea/>
                    </FormItem>
                    <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'联系电话'} name={'tel'} rules={[{required: true, message: "请输入联系电话"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'简介'} name={'introduce'}>
                        <WangEditor
                            value1={introduceContext}
                            changeCallback={(res: any) => setIntroduceContext(res)}
                        />
                    </FormItem>
                    <FormItem
                        label={'产品介绍'}
                        name={'product'}
                    >
                        <WangEditor
                            id='productContext'
                            value1={productContext}
                            changeCallback={(res: any) => setProductContext(res)}
                        />
                    </FormItem>
                </Form>
            </Modal>


            <Modal title={'添加用户'} destroyOnClose={true} width={1200} visible={userVisible} onCancel={() => {
                setUserVisible(false)
            }} footer={null}>
                <GSTable
                    columns={userColumns}
                    actionRef={userActionRef}
                    searchBarRender={<Space>
                        <Input
                            placeholder="搜索"
                            onChange={(event) => {
                                setSearch(event.target.value);

                            }}
                        />
                        <Button type={"primary"} onClick={() => {
                            if (userActionRef.current) {
                                userActionRef.current.reloadAndRest();
                            }
                        }}>查询</Button>
                    </Space>}
                    operationsBarRender={[
                        <Button type={'primary'} onClick={() => {
                            setAddUserVisible(true);
                            addUserForm.resetFields()
                        }}>添加</Button>
                    ]}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        let res = await getBeijingOfficeUserList({
                            Search: search,
                            beijingOfficeUuid: beijingOfficeUuid,
                            page: params.current,
                            size: params.pageSize,
                        });
                        return {
                            data: res.data,
                            total: res.total,
                            success: true,
                        };
                    }}
                />
            </Modal>
            <Modal title={"添加用户"} destroyOnClose={true} visible={addUserVisible} onOk={addUser} onCancel={() => {
                setAddUserVisible(false)
            }}>
                <Form labelAlign={'left'} labelCol={{span: 4}} form={addUserForm}>
                    <FormItem label={'驻京办'} hidden={true} name={'beijingOfficeUuid'} initialValue={beijingOfficeUuid}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'用户姓名'} name={'name'} rules={[{required: true, message: "请输入用户姓名"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'用户手机号'} name={'phone'}
                              rules={[{required: true, message: "请输入用户手机号"}]}>
                        <Input/>
                    </FormItem>
                    {/*<FormItem label={'用户邮箱'} name={'email'} rules={[{ required: true, message: "请输入用户邮箱" }]}>*/}
                    {/*	<Input/>*/}
                    {/*</FormItem>*/}
                    {/*<FormItem label={'用户职位'}  rules={[{ required: true, message: "请输入用户职位"  }]} name={'position'}>*/}
                    {/*	<Input/>*/}
                    {/*</FormItem>*/}
                    <FormItem valuePropName={"checked"} label={'会员账户'} name={"isVipUser"}>
                        <Switch/>
                    </FormItem>
                </Form>
            </Modal>

            <Modal title={"修改用户"} destroyOnClose={true} visible={updateUserVisible} onOk={updateUser}
                   onCancel={() => {
                       setUpdateUserVisible(false)
                   }}>
                <Form labelAlign={'left'} labelCol={{span: 4}} form={updateUserForm}>
                    <FormItem label={'驻京办'} hidden={true} name={'beijingOfficeUuid'} initialValue={beijingOfficeUuid}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'uuid'} hidden={true} name={'uuid'}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'用户姓名'} name={'name'} rules={[{required: true, message: "请输入用户姓名"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'用户手机号'} name={'phone'}
                              rules={[{required: true, message: "请输入用户手机号"}]}>
                        <Input/>
                    </FormItem>
                    {/*<FormItem label={'用户邮箱'} name={'email'} rules={[{ required: true, message: "请输入用户邮箱" }]}>*/}
                    {/*	<Input/>*/}
                    {/*</FormItem>*/}
                    {/*<FormItem label={'用户职位'}  rules={[{ required: true, message: "请输入用户职位"  }]} name={'position'}>*/}
                    {/*	<Input/>*/}
                    {/*</FormItem>*/}
                    <FormItem valuePropName={"checked"} label={'会员账户'} name={"isVipUser"}>
                        <Switch/>
                    </FormItem>
                </Form>
            </Modal>

            <Modal title={'需求列表'} destroyOnClose={true} width={1200} visible={demandVisible} onCancel={() => {
                setDemandVisible(false)
            }} footer={null}>
                <GSTable
                    columns={demandColumns}
                    actionRef={demandActionRef}
                    searchBarRender={<Space>
                        <Input
                            placeholder="搜索"
                            onChange={(event) => {
                                setSearch(event.target.value);

                            }}
                        />
                        <Button type={"primary"} onClick={() => {
                            if (demandActionRef.current) {
                                demandActionRef.current.reloadAndRest();
                            }
                        }}>查询</Button>
                    </Space>}
                    operationsBarRender={[
                        <Button type={'primary'} onClick={() => {
                            setAddDemandVisible(true);
                            addDemandForm.resetFields();
                        }}>添加</Button>
                    ]}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        let res = await getBeijingOfficeDemandList({
                            Search: search,
                            beijingOfficeUuid: beijingOfficeUuid,
                            page: params.current,
                            size: params.pageSize,
                        });
                        return {
                            data: res.data,
                            total: res.total,
                            success: true,
                        };
                    }}
                />
            </Modal>

            <Modal title={"添加需求"} width={1200} destroyOnClose={true} visible={addDemandVisible} onOk={addDemand}
                   onCancel={() => {
                       setAddDemandVisible(false)
                   }}>
                <Form labelAlign={'left'} labelCol={{span: 3}} form={addDemandForm}>
                    <FormItem label={'驻京办'} hidden={true} name={'beijingOfficeUuid'} initialValue={beijingOfficeUuid}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'项目名称'} name={'projectName'}
                              rules={[{required: true, message: "请输入项目名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'需求描述'} name={'demand'} rules={[{required: true, message: "请输入需求描述"}]}>
                        <Input.TextArea/>
                    </FormItem>
                    <FormItem label={'发布时间'} name={'publishTime'}
                              rules={[{required: true, message: "请输入发布时间"}]}>
                        <DatePicker/>
                    </FormItem>
                    <FormItem label={'预期启动时间'} name={'expectedStartTime'}
                              rules={[{required: true, message: "请输入预期启动时间"}]}>
                        <DatePicker/>
                    </FormItem>
                    <FormItem label={'预计建设周期'} name={'constructionCycle'}
                              rules={[{required: true, message: "请输入预计建设周期"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'投资预算'} name={'budget'}
                              rules={[{required: false, message: "请输入预计建设周期"}]}>
                        <InputNumber/>
                    </FormItem>
                    <FormItem label={'数量规模'} name={'quantityAndScale'}
                              rules={[{required: true, message: "请输入数量规模"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'需求单位'} name={'demandCompany'}
                              rules={[{required: true, message: "请输入需求单位"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'需求单位简介'} name={'demandCompanyNote'}
                              rules={[{required: true, message: "请输入需求单位简介"}]}>
                        <Input.TextArea/>
                    </FormItem>
                    <FormItem label={'应用场景分类'} name={'schemeType'}
                              rules={[{required: true, message: "请输入应用场景分类"}]}>
                        <CheckedComponents
                            key={typeList.toString()}
                            dataList={typeList}
                        ></CheckedComponents>
                    </FormItem>
                    <FormItem label={'技术分类'} name={'techType'}
                              rules={[{required: true, message: "请输入技术分类"}]}>
                        <CheckedComponents
                            key={techList.toString()}
                            dataList={techList}
                        ></CheckedComponents>
                    </FormItem>
                    <FormItem label={'省市'} name={'province'} rules={[{required: false, message: "请输入省市"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'详细地址'} name={'address'}
                              rules={[{required: false, message: "请输入详细地址"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'职务'} name={'post'} rules={[{required: false, message: "请输入职务"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'联系电话'} name={'contactsNumber'}
                              rules={[{required: true, message: "请输入联系电话"}]}>
                        <Input/>
                    </FormItem>
                </Form>
            </Modal>
            <Modal title={"修改需求"} width={1200} destroyOnClose={true} visible={updateDemandVisible}
                   onOk={updateDemand} onCancel={() => {
                setUpdateDemandVisible(false)
            }}>
                <Form labelAlign={'left'} labelCol={{span: 3}} form={updateDemandForm}>
                    <FormItem label={'驻京办'} hidden={true} name={'beijingOfficeUuid'} initialValue={beijingOfficeUuid}
                              rules={[{required: true, message: "请输入名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'uuid'} hidden={true} name={'uuid'}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'项目名称'} name={'projectName'}
                              rules={[{required: true, message: "请输入项目名称"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'需求描述'} name={'demand'} rules={[{required: true, message: "请输入需求描述"}]}>
                        <Input.TextArea/>
                    </FormItem>
                    <FormItem label={'发布时间'} name={'publishTime'}
                              rules={[{required: true, message: "请输入发布时间"}]}>
                        <DatePicker/>
                    </FormItem>
                    <FormItem label={'预期启动时间'} name={'expectedStartTime'}
                              rules={[{required: true, message: "请输入预期启动时间"}]}>
                        <DatePicker/>
                    </FormItem>
                    <FormItem label={'预计建设周期'} name={'constructionCycle'}
                              rules={[{required: true, message: "请输入预计建设周期"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'投资预算'} name={'budget'}
                              rules={[{required: false, message: "请输入预计建设周期"}]}>
                        <InputNumber/>
                    </FormItem>
                    <FormItem label={'数量规模'} name={'quantityAndScale'}
                              rules={[{required: true, message: "请输入数量规模"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'需求单位'} name={'demandCompany'}
                              rules={[{required: true, message: "请输入需求单位"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'需求单位简介'} name={'demandCompanyNote'}
                              rules={[{required: true, message: "请输入需求单位简介"}]}>
                        <Input.TextArea/>
                    </FormItem>
                    <FormItem label={'应用场景分类'} name={'schemeType'}
                              rules={[{required: true, message: "请输入应用场景分类"}]}>
                        <CheckedComponents
                            key={typeList.toString()}
                            dataList={typeList}
                        ></CheckedComponents>
                    </FormItem>
                    <FormItem label={'技术分类'} name={'techType'}
                              rules={[{required: true, message: "请输入技术分类"}]}>
                        <CheckedComponents
                            key={techList.toString()}
                            dataList={techList}
                        ></CheckedComponents>
                    </FormItem>
                    <FormItem label={'省市'} name={'province'} rules={[{required: false, message: "请输入省市"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'详细地址'} name={'address'}
                              rules={[{required: false, message: "请输入详细地址"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'职务'} name={'post'} rules={[{required: false, message: "请输入职务"}]}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'联系电话'} name={'contactsNumber'}
                              rules={[{required: true, message: "请输入联系电话"}]}>
                        <Input/>
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
};
export default BeijingOfficeList;
