import {useDrag, useDrop} from 'react-dnd';
import * as React from 'react';

const type = 'DragableBodyRow';
const DragableBodyRow = ({index, moveRow, className, style, ...restProps}: any) => {
    const ref = React.useRef();
    const [{isOver, dropClassName}, drop] = useDrop({
                                                        accept: type,
                                                        collect: monitor => {
                                                            const item = monitor.getItem() || {};
                                                            if (item.pId !== restProps['children'][0].props.record.pId) {
                                                                return {};
                                                            }
                                                            return {
                                                                isOver: monitor.isOver(),
                                                                dropClassName: item.index < index ? ' drop-over-downward' : ' drop-over-upward',
                                                            };
                                                        },
                                                        drop: (item: any) => {
                                                            moveRow(item.index, index, item.key);

                                                        },
                                                    });
    const [, drag] = useDrag({
                                 item: {type, index, key: restProps['data-row-key'], pId: restProps['children'][0].props.record.pId},
                                 collect: monitor => ({
                                     isDragging: monitor.isDragging(),
                                 }),
                             });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{cursor: 'move', ...style}}
            {...restProps}
        />
    );
};
export default DragableBodyRow;