import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import AllDeviceG from './pages/AllDeviceG';
import PersonGroupList from './pages/PersonGroupList';
import Exception from './pages/Exception';
import ReportForm from './pages/ReportForm';
import PlaneManagement from './pages/PlaneManagement';
import XunJianReportForm from './pages/XunJianReportForm';
import AllDeviceJ from './compontents/AllDeviceJ';
import StaffRouteList from './compontents/StaffRouteList';
// @ts-ignore
import RoutePoints from './compontents/RoutePoints';
import RouteChooseDevice from './compontents/RouteChooseDevice';
import PatrolRouteList from './pages/PatroRouteList';
import InspectionRouteList from './pages/InspectionRouteList';
import PatrolPlane from './compontents/PatrolPlane';
import XunGengXunJianStatistics from './pages/XunGengXunJianStatistics';
import XunGengReport from './pages/XunGengReport';
import XunJianReport from './pages/XunJianReport';
import XunGengConsole from './pages/XunGengConsole';

export default function ZhiNengXunGengMiniApp() {
	const rootUrl = '/patrolinspection';
	const menus: MiniAppMenu[] = [
		{
			authority: 'intelligent_patrol:plan-management',
			name: '平面图',
			path: 'plan-management',
			component: PlaneManagement,
		},
		{
			authority: 'intelligent_patrol:patrollepoint',
			name: '巡更点',
			path: 'patrollepoint',
			component: AllDeviceG,
		},
		{
			authority: 'intelligent_patrol:person-group',
			name: '人员分组',
			path: 'person-group',
			component: PersonGroupList,
		},
		{
			authority: 'intelligent_patrol:route-list',
			name: '巡更路线',
			path: 'patrol-route-list',
			component: PatrolRouteList,
		},
		{
			authority: 'intelligent_patrol:route-list',
			name: '巡检计划',
			path: 'inspection-route-list',
			component: InspectionRouteList,
		},
		{
			authority: 'intelligent_patrol:patrol-exception',
			name: '异常报告',
			path: 'patrol-exception',
			component: Exception,
		},
		{
			authority: 'intelligent_patrol:patrol-report-form',
			name: '巡更报表',
			path: 'patrol-report-form',
			component: XunGengReport,
			// component: ReportForm,
		},
		{
			authority: 'intelligent_patrol:xunjian-report-form',
			name: '巡检报表',
			path: 'xunjian-report-form',
			// component: XunJianReportForm,
			component: XunJianReport,
		},
		{
			authority: 'xungeng_console',
			name: '控制台',
			path: 'xungeng-console',
			// component: XunJianReportForm,
			component: XunGengConsole,
		},
		// {
		// 	// authority: 'intelligent_patrol:xunjian-report-form',
		// 	name: '报表统计',
		// 	path: 'baobiao-tongji',
		// 	component: XunGengXunJianStatistics,
		// },
	];

	const routes: MiniAppRoute[] = [
		{
			path: 'allDeviceJ',
			component: AllDeviceJ,
		},
		{
			path: 'find_schedul',
			component: StaffRouteList,
		},
		{
			path: 'patrol_plane',
			component: PatrolPlane,
		},
		{
			path: 'route_points',
			component: RoutePoints,
		},
		{
			path: 'route_choose_device',
			component: RouteChooseDevice,
		},
	];

	return <MiniApp miniAppName={'巡更巡检'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
