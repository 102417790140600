import React, { useContext, useEffect, useRef } from 'react';
import { Line } from '@ant-design/charts';
import { DataContext } from '../pages/controlPlatform/CurrentPlatform';
interface Params { 
	theme?:string
}
const LineChart: React.FC<Params> = (props: any) => {
	const data = useContext(DataContext);
	const canvasRef = useRef(null);
	useEffect(() => {
		if (canvasRef.current) {
		}
		console.log('useEffect::::', data);
	}, []);
	const config = {
		data,
		xField: 'x',
		yField: 'y',
		theme: 'dark' == props.theme ? 'dark' : 'default',
		seriesField: 'name',
		
		point: {
			size: 5,
			shape: 'diamond',
			style: {
				fill: 'white',
				stroke: '#2593fc',
				lineWidth: 2,
			},
		},
		legend: {
			itemName:{
				style:{fontSize:15}
			  },
		  },
		
		yAxis: {
			style: {
				fontSize:20
			},
			grid: {
				line: {
					style: {
						lineWidth: 0,
					},
				},
			},
		},
	};
	return <Line {...config} {...props} ref={canvasRef} />;
};
export default LineChart;
