import { DividerProps } from 'antd';

/**
 * 企业微信咨询链接
 * @returns
 */
export default function getAdViceUrls() {

	const urls = [
		{ name: '租务中心', value: 'https://work.weixin.qq.com/kfid/kfccd84f2cb7f9dcfc2' },
		{ name: '品牌部', value: 'https://work.weixin.qq.com/kfid/kfc5876186a64940185' },
		{ name: '产业运营部', value: 'https://work.weixin.qq.com/kfid/kfc290f4f2da1462278' },
		{ name: '空间运营', value: 'https://work.weixin.qq.com/kfid/kfc18cee981f0ffb8f0' },
		{ name: '科技服务', value: 'https://work.weixin.qq.com/kfid/kfceb02bc53e98ea5cc' },
		{ name: '注册服务', value: 'https://work.weixin.qq.com/kfid/kfcda2a48a241334b1d' },
		{ name: '人力资源部', value: 'https://work.weixin.qq.com/kfid/kfc3a92e9b8f676afe5' },
		{ name: '中关村创客小镇（北京）科技有限公司客服', value: 'https://work.weixin.qq.com/kfid/kfc22658fd9df42099c' },
	];

	return {
		urls
	};
}
