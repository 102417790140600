import * as React from 'react';
import { Card, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getCurrentPhoneBrand } from '../../services/controlPlatformService';
import PieChart from '../../components/PieChart';
import CustomRangePicker from '../../components/CustomRangePicker';

export const ColumnChartContext = React.createContext([]);

const CurrentPhoneBrandChart: React.FC = () => {
	const [selectType, setSelectType] = useState(1);
	let startTime = moment().subtract(7, 'd');
	let endTime = moment();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>([]);

	useEffect(() => {
		getData();
	}, []);

	const changeSelectType = (e: number) => {
		setSelectType(e);
		switch (e) {
			case 1:
				startTime = moment().subtract(6, 'd');
				endTime = moment();
				break;
			case 2:
				startTime = moment().subtract(14, 'd');
				endTime = moment();
				break;
			case 3:
				startTime = moment().subtract(29, 'd');
				endTime = moment();
				break;
		}
		getData();
	};

	const getData = async () => {
		setLoading(true);
		let params = {
			startTime: startTime.format('YYYY-MM-DD'),
			endTime: endTime.format('YYYY-MM-DD'),
		};
		setLoading(true);
		let rsp = await getCurrentPhoneBrand(params);
		setLoading(false);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
		}
		let data = rsp.data;
		createChartData(data);
	};

	const createChartData = (data: any) => {
		let keys = Object.keys(data);
		let chartData: any = [];
		keys.map((key: string) => {
			chartData.push({
				type: key,
				value: data[key],
			});
		});
		console.log('shoujipinpai:::', chartData);
		setData(chartData);
	};

	const tabBarExtrContent = () => (
		<CustomRangePicker
			onChange={(_startTime, _endTime) => {
				startTime = _startTime;
				endTime = _endTime;
				getData();
			}}
			startTime={startTime}
			endTime={endTime}
		/>
	);
	return (
		<Card extra={tabBarExtrContent()} title={'手机开门品牌分布'} loading={loading} bodyStyle={{ paddingLeft: 15 }}>
			<ColumnChartContext.Provider value={data}>
				{/*<ColumnChart/>*/}
				<PieChart />
			</ColumnChartContext.Provider>
		</Card>
	);
};
export default CurrentPhoneBrandChart;
