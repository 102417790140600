import React, { useEffect, useRef, useState } from 'react';
import { editQuestionnaire } from '../../../services/QuestionnaireService';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space,
	Spin,
	Tag,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import {
	addDebitNote,
	billStatistical,
	createBillRefund,
	deleteChargeBill,
	findAll,
	updateBill,
} from '../../../services/PayCostManagementService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import AuthorizedView from '../../../util/AuthorizedView';
import { findFee } from '../../../services/orderService';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
import * as XLSX from 'xlsx';
import { jsChargeBillPrice } from '../../../services/roomCharge';
import { findLeaseList } from '../../../services/room';
import Search from 'antd/es/transfer/search';
import { getAssetsType } from '../../../services/ZhuhushenheService';
import { findBuilds } from '../../../services/WuyeService';
const Option = Select.Option;
interface Params {
	state?: string;
	billType?: string;
}
const LeaseList: React.FC<Params> = (props: any) => {
	useEffect(() => {
		findAssetsType().then();
		getBuilds().then();
	}, []);

	let { RangePicker } = DatePicker;
	const actionRef = useRef<ActionType>();
	const [Stime, setStime] = useState('');
	const [Etime, setEtime] = useState('');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [updateForm] = Form.useForm<FormInstance>();
	const [feeTypeUuid, setfeeTypeUuid] = useState([]);
	const [search, setsearch] = useState('');
	const [quanxuan, setquanxuan] = useState(false);
	const [total, settotal] = useState(0);
	const [loading, setloading] = useState(false);
	const [state, setstate] = useState('1');
	const [roomType, setroomType] = useState('');
	const [searchBuild, setSearchBuild] = useState('');
	const [searchUnit, setSearchUnit] = useState('');
	const [searchLayer, setSearchLayer] = useState('');
	const [builds, setBuilds] = useState([]);
	const [unitArray, setUnitArray] = useState([]);
	const [layerArray, setLayerArray] = useState([]);
	const [assetsTypeList, setAssetsTypeList] = useState([]);
	const columns = [
		{
			title: '房间号',
			dataIndex: 'roomnumber',
		},
		{
			title: '资产类型',
			dataIndex: 'roomtype',
		},
		{
			title: '公司名称',
			dataIndex: 'companyname',
		},
		{
			title: '客户名称',
			dataIndex: 'name',
		},
		{
			title: '状态',
			dataIndex: 'state',
			type: 'state',
			render: (text: any, item: any) => (
				<Tag color={'1' == item.state ? 'green' : 'red'}>{'1' == item.state ? '生效' : '失效'}</Tag>
			),
		},
		{
			title: '价格',
			dataIndex: 'price',
		},
		{
			title: '合同号',
			dataIndex: 'contractno',
		},
		{
			title: '合同开始时间',
			dataIndex: 'contractstarttime',
			valueType: 'date',
		},
		{
			title: '合同结束时间',
			dataIndex: 'contractendtime',
			valueType: 'date',
		},
	];

	const findAssetsType = async () => {
		const res = await getAssetsType({});
		if (res.err === 0) {
			setAssetsTypeList(res.data);
		}
	};

	/*获得楼栋列表*/
	const getBuilds = async () => {
		const res = await findBuilds({});
		if (res.err === 0) {
			console.log('获得楼栋列表', res);
			let tempUnit = 1;
			let tempLayer = 1;
			for (let i = 0; i < res.data.length; i++) {
				if (res.data[i].unitNumber > tempUnit) {
					tempUnit = res.data[i].unitNumber;
				}
				if (res.data[i].layers > tempLayer) {
					tempLayer = res.data[i].layers;
				}
			}
			console.log('tempUnit::', tempUnit);
			console.log('tempLayer', tempLayer);
			for (let i = 0; i < tempUnit; i++) {
				unitArray.push(i + 1);
			}
			console.log('unitArray,', unitArray);
			for (let i = 0; i < tempLayer; i++) {
				layerArray.push(i + 1);
			}
			console.log('layerArray,', layerArray);
			setLayerArray(layerArray);
			setUnitArray(unitArray);
			setBuilds(res.data);
		}
	};

	const tableSearchBarRender = () => [
		// <RangePicker
		// 	showTime
		// 	placeholder={['实收日期范围区间', '实收日期范围区间']}
		// 	onChange={dateTime}
		// 	style={{ width: '100%' }}
		// />,
		// <Select
		// 	mode="tags"
		// 	showSearch
		// 	allowClear
		// 	style={{ width: 300 }}
		// 	placeholder={"收费项目"}
		// 	onChange={(value:any) => { setfeeTypeUuid(value) }}
		// >
		// 	{fee.map(item => {
		// 		return <>
		// 			<Select.Option value={item.uuid}>{ item.feeName}</Select.Option>
		// 		</>
		// 	})}

		// </Select>,
		<Select
			value={state}
			onChange={(state) => {
				setstate(state);
			}}
		>
			<Select.Option value={''}>全部</Select.Option>
			<Select.Option value={'1'}>生效</Select.Option>
			<Select.Option value={'0'}>失效 </Select.Option>
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 100 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setroomType(value);
			}}
			placeholder="资产类型"
		>
			<Option value={''}>资产类型</Option>
			{assetsTypeList.map((item: any) => {
				return (
					<Option key={item} value={item}>
						{item}
					</Option>
				);
			})}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 130 }}
			onChange={(value: any) => {
				setSearchBuild(value);
			}}
			optionFilterProp="children"
			placeholder="楼栋"
		>
			{builds.map((build: any) => (
				<Option key={build.uuid} value={build.uuid}>
					{build.buildName}
				</Option>
			))}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 80 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setSearchUnit(value);
			}}
			placeholder="单元"
		>
			{unitArray.map((unit: any) => (
				<Option key={unit} value={unit}>
					{unit}单元
				</Option>
			))}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 80 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setSearchLayer(value);
			}}
			placeholder="楼层"
		>
			{layerArray.map((layer: any) => (
				<Option key={layer} value={layer}>
					{layer}层
				</Option>
			))}
		</Select>,
		<Input onChange={(value) => setsearch(value.target.value)} placeholder="公司名称，房间号，姓名"></Input>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];

	const dateTime = (date: any, dataToString: any) => {
		setStime(dataToString[0]);
		setEtime(dataToString[1]);
	};
	const tableOperationsBarRender = () => [];
	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		if (quanxuan) {
			const formData = {
				page: 1,
				size: total,
				search: search,
				state: state,
				buildUuid: searchBuild,
				unit: searchUnit,
				layer: searchLayer,
				roomType: roomType,
			};
			setloading(true);
			const res = await findLeaseList(formData);
			setloading(false);
			if (res.err == 0) {
				setExprotData(res.data || []);
			} else {
				message.error(res.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
	};

	const setExprotData = (selectedRows: [any?]) => {
		console.log('selectedRows:::', selectedRows);

		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else if (item.valueType === 'date') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD'));
				} else if (item.type == 'state') {
					data.push(selectedRows[i][item.dataIndex] ? '生效' : '失效');
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '租赁信息');
		XLSX.writeFile(wb, '租赁信息.xlsx');
	};
	return (
		<div>
			<Spin spinning={loading}>
				<GSTable
					columns={columns}
					actionRef={actionRef}
					searchBarRender={tableSearchBarRender()}
					rowKey={'id'}
					operationsBarRender={tableOperationsBarRender()}
					rowSelection={{
						fixed: true,
						columnWidth: 100,
						preserveSelectedRowKeys: true,
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys: any, selectedRows: any) => {
							setquanxuan(false);
							setselectedRowKeys(selectedRowKeys);
						},
						selections: [
							{
								key: 'SELECT_ALL',
								text: '全选全部页面',
								onSelect: (changableRowKeys: any) => {
									setquanxuan(true);
									setselectedRowKeys(changableRowKeys);
								},
							},
						],
					}}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {quanxuan ? total : selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
										}}
									>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={16}>
								<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							</Space>
						);
					}}
					request={async (params: any = {}, sort: any, filter: any) => {
						let data = {
							page: params.current,
							size: params.pageSize,
							state: state,
							roomType: roomType,
							buildUuid: searchBuild,
							unit: searchUnit,
							layer: searchLayer,
							// payTimeStartTime: Stime,
							// payTimeEndTime: Etime,
							search: search,
						};

						const res = await findLeaseList(data);

						if (res.err == 0) {
							if (quanxuan) {
								let uuids: any = [];
								res.data.map((item: any) => {
									uuids.push(item.id);
								});
								setselectedRowKeys(uuids);
							}
							settotal(res.count || 0);
						}
						return {
							data: res.data || [],
							total: res.count,
							success: true,
						};
					}}
				/>
			</Spin>
		</div>
	);
};
export default LeaseList;
