import React, { useEffect, useRef, useState } from 'react';
import {
	addRoomItem,
	deleteListOfItems,
	deleteRoomItem,
	getAllItemInfo,
	getHouseStyle,
	getItemTypeList,
	getListOfItemsList,
	getRoomItemList,
	getRoomItemRecordList,
	getRoomTreeData,
	updateRoomItem,
} from '../../../services/ZhuhushenheService';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Space, Tag } from 'antd';
import { FormInstance } from 'antd/es/form';
import GsTreeSelect from '../../../components/GsTreeSelect';
import moment from 'moment';
import * as XLSX from 'xlsx';
import DAL from '../../../data/DAL';
const statusEnum = {
	正常: <Tag color={'green'}>正常</Tag>,
	缺失: <Tag color={'blue'}>缺失</Tag>,
	丢失: <Tag color={'blue'}>丢失</Tag>,
	损坏: <Tag color={'red'}>损坏</Tag>,
	异常: <Tag color={'red'}>异常</Tag>,
};
interface Params {
	roomUuid?: any;
}
const RoomItemList: React.FC<Params> = (props: any) => {
	const actionRef = useRef<ActionType>();
	const infoActionRef = useRef<ActionType>();
	const [roomUuid, setRoomUuid] = useState([]);
	const [type, setType] = useState('');
	const [search, setSearch] = useState('');
	const [status, setStatus] = useState('');
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	const [roomTreeData, setRoomTreeData] = useState([]);
	const [itemInfoList, setitemInfoList] = useState([]);
	const [roomItemId, setRoomItemId] = useState('');
	const [recordVisible, setRecordVisible] = useState(false);
	const [title, setTitle] = useState('');
	const [form] = Form.useForm<FormInstance>();
	const [quanXuan, setquanXuan] = useState(false);
	const [updateForm] = Form.useForm<FormInstance>();
	const [updateStatus, setUpdateStatus] = useState('');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [dataTotal, setdataTotal] = useState(0);
	const [itemTypeList, setitemTypeList] = useState([])

	useEffect(() => {
		getRoomData().then();
		getAllItem().then();
		getAllItemTypeList().then()
	}, []);
	const getAllItemTypeList =async () => {
		const res = await getItemTypeList({
		});
		if (res.err == 0) {
			setitemTypeList(res.data || [])
		}
	}
	const getAllItem = async () => {
		const res = await getAllItemInfo({});
		if (res.err == 0) {
			setitemInfoList(res.data || []);
		} else {
			message.error(res.msg);
		}
	};
	const columns = [
		{
			title: '编号',
			dataIndex: 'id',
			width: 50,
			key: 'id',
		},
		{
			title: '类目',
			dataIndex: 'type',
			key: 'type',
			width: 50,
		},
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			width: 100,
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			width: 50,
			valueEnum: statusEnum,
		},
		{
			title: '品牌',
			dataIndex: 'brand',
			key: 'brand',
			width: 100,
		},
		{
			title: '型号/规格',
			dataIndex: 'model',
			key: 'model',
			width: 100,
		},

		{
			title: '单价',
			dataIndex: 'price',
			valueType: 'money',
			key: 'price',
			width: 80,
		},
		{
			title: '数量',
			dataIndex: 'quantity',
			key: 'quantity',
			width: 50,
		},
		{
			title: '损坏数量',
			dataIndex: 'damagedQuantity',
			key: 'damagedQuantity',
			width: 50,
		},
		{
			title: '缺失数量',
			dataIndex: 'missQuantity',
			key: 'missQuantity',
			width: 50,
		},
		{
			title: '位置',
			dataIndex: 'roomNumber',
			key: 'roomNumber',
			width: 80,
		},
		{
			title: '操作',
			key: 'action',
			width: 120,
			fixed: 'right',
			render: (record: any) => (
				<Space>
					<a
						onClick={() => {
							updateForm.setFieldsValue(record);
							setUpdateVisible(true);
							setUpdateStatus(record.status);
						}}
					>
						编辑
					</a>
					<a
						onClick={() => {
							Modal.confirm({
								title: '提示',
								okType: 'danger',
								content: '确定要删除该物品吗？该操作不可恢复！',
								onOk: async () => {
									let data = {
										id: record.id,
									};
									let rsp = await deleteRoomItem(data);
									if (rsp.err === 0) {
										message.success(rsp.msg);
										if (actionRef.current) {
											actionRef.current.reloadAndRest();
										}
										return;
									}
									message.error(rsp.msg);
								},
							});
						}}
					>
						删除
					</a>
					<a
						onClick={() => {
							setRecordVisible(true);
							setRoomItemId(record.id);
							setTitle(record.name);
						}}
					>
						查看记录
					</a>
				</Space>
			),
		},
	];

	const recordColumns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
			width: 100,
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			width: 50,
		},
		{
			title: '状态来源',
			dataIndex: 'source',
			key: 'source',
			width: 80,
		},
		{
			title: '操作人',
			dataIndex: 'opName',
			key: 'opName',
			width: 80,
		},
		{
			title: '位置',
			dataIndex: 'roomNumber',
			key: 'roomNumber',
			width: 100,
		},
		{
			title: '损坏数量',
			dataIndex: 'damagedQuantity',
			key: 'damagedQuantity',
			width: 50,

		},
		{
			title: '缺失数量',
			dataIndex: 'missQuantity',
			width: 50,
			key: 'missQuantity',
		},
		{
			title: '操作时间',
			dataIndex: 'createdTime',
			key: 'createdTime',
			width: 100,
			valueType: 'dateTime',
		},
	];

	const getRoomData = async () => {
		let data = {
			roomType: '公寓',
		};
		let rsp = await getRoomTreeData(data);
		console.log('rsp::::', rsp);
		if (rsp.err === 0) {
			setRoomTreeData(rsp.data);
		}
	};

	const tableSearchBarRender = () => [
		<Select
			defaultValue={type}
			style={{ width: 150 }}
			onChange={(value: any) => {
				setType(value);
			}}
		>
			<Select.Option value={''}>类目</Select.Option>
			{itemTypeList.map(item => {
				return <>
					<Select.Option value={item.name}>{item.name}</Select.Option>
				</>
			})}
		</Select>,
		<GsTreeSelect
			hidden={DAL.dal.isNotNull(props.roomUuid)}
			onChange={(value: any) => {
				setRoomUuid(value);
			}}
			showSearch={true}
			treeNodeFilterProp={'title'}
			treeCheckable={true}
			placeholder={'请选择房间'}
			multiple={true}
			style={{ width: 200 }}
			treeData={roomTreeData}
		/>,
		<Select
			defaultValue={status}
			style={{ width: 150 }}
			onChange={(value: string) => {
				setStatus(value);
			}}
		>
			<Select.Option value={''}>状态</Select.Option>
			<Select.Option value={'正常'}>正常</Select.Option>
			{/* <Select.Option value={'异常'}>异常</Select.Option> */}
			{/*<Select.Option value={'报修中'}>报修中</Select.Option>*/}
			{/* <Select.Option value={'维修中'}>维修中</Select.Option> */}
			<Select.Option value={'缺失'}>缺失</Select.Option>
			<Select.Option value={'损坏'}>损坏</Select.Option>
		</Select>,
		<Input
			placeholder={'物品名称'}
			onChange={(event: any) => {
				setSearch(event.target.value);
			}}
		/>,
		<Button
			type="primary"
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];
	const tableOperationsBarRender = () => [
		<Button
			loading={loading}
			type="primary"
			onClick={() => {
				setVisible(true);
			}}
		>
			新增
		</Button>,
	];
	const addItemOk = async () => {
		let data = await form.validateFields();
		console.log('data:::', data);
		setLoading(true);
		let rsp = await addRoomItem(data);
		setLoading(false);
		if (rsp.err === 0) {
			message.success(rsp.msg);
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		} else {
			message.error(rsp.msg);
		}
	};

	const updateItemOk = async () => {
		let data = await updateForm.validateFields();
		console.log('data:::', data);
		setLoading(true);
		let rsp = await updateRoomItem(data);
		setLoading(false);
		if (rsp.err === 0) {
			message.success(rsp.msg);
			setUpdateVisible(false);
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		} else {
			message.error(rsp.msg);
		}
	};

	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setLoading(true);
		if (quanXuan) {
			const res = await getRoomItemList({
				search: search,
				roomUuid: DAL.dal.isNotNull(props.roomUuid) ? props.roomUuid : (roomUuid || []).toString(),
				type: type,
				status: status,
			});
			setLoading(false);
			if (res.err == 0) {
				setExprotData(res.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setLoading(false);
			setExprotData(selectedRows);
		}
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '记录');
		XLSX.writeFile(wb, '记录.xlsx');
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				rowKey="id"
				rowSelection={{
					fixed: true,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setquanXuan(false);
						setselectedRowKeys(selectedRowKeys);
					},
					selections: [
						{
							key: 'SELECT_ALL',
							text: '全选全部页面',
							onSelect: (changableRowKeys: any) => {
								setquanXuan(true);
								setselectedRowKeys(changableRowKeys);
							},
						},
					],
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanXuan ? dataTotal : selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::', selectedRows);
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>
						</Space>
					);
				}}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getRoomItemList({
						page: params.current,
						size: params.pageSize,
						search: search,
						roomUuid: DAL.dal.isNotNull(props.roomUuid) ? props.roomUuid : (roomUuid || []).toString(),
						type: type,
						status: status,
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					setdataTotal(res.total);
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				title={'添加物品'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				maskClosable={false}
				confirmLoading={loading}
				onOk={addItemOk}
			>
				<Form form={form} labelCol={{ span: 6 }}>
					<Form.Item name={'roomUuid'} label={'房间'} rules={[{ required: true, message: '请选择房间' }]}>
						<GsTreeSelect
							showSearch={true}
							treeNodeFilterProp={'title'}
							treeCheckable={false}
							placeholder={'请选择房间'}
							multiple={false}
							style={{ width: '100%' }}
							treeData={roomTreeData}
						/>
					</Form.Item>
					<Form.Item
						name={'itemInfoUuid'}
						label={'物品'}
						rules={[{ required: true, message: '请选择装修物品' }]}
					>
						<Select showSearch={true} optionFilterProp="children">
							{itemInfoList.map((item: any) => {
								return (
									<Select.Option key={item.uuid} value={item.uuid}>
										{item.name}-{item.brand}-{item.price}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item
						name={'quantity'}
						label={'物品数量'}
						initialValue={1}
						rules={[{ required: true, message: '请填写物品数量' }]}
					>
						<InputNumber style={{ width: '100%' }} min={1} precision={0} step={1} />
					</Form.Item>
					<Form.Item name={'status'} label="状态" rules={[{ required: true, message: '请选择状态' }]}>
						<Select>
							<Select.Option value={'正常'}>正常</Select.Option>
							{/*<Select.Option value={'损坏'}>损坏</Select.Option>*/}
							<Select.Option value={'异常'}>异常</Select.Option>
							{/*<Select.Option value={'报修中'}>报修中</Select.Option>*/}
							{/*<Select.Option value={'维修中'}>维修中</Select.Option>*/}
							{/*<Select.Option value={'缺失'}>缺失</Select.Option>*/}
						</Select>
					</Form.Item>
					<Form.Item name={'note'} label={'备注'}>
						<Input.TextArea maxLength={200} />
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title={'编辑物品'}
				width={700}
				visible={updateVisible}
				onCancel={() => {
					setUpdateVisible(false);
				}}
				maskClosable={false}
				confirmLoading={loading}
				onOk={updateItemOk}
			>
				<Form form={updateForm}>
					<Form.Item
						hidden={true}
						name={'itemInfoUuid'}
						label={'物品编号'}
						rules={[{ required: true, message: '物品ID' }]}
					>
						<Input disabled={true} />
					</Form.Item>
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item
								name={'id'}
								label={'物品编号'}
								rules={[{ required: true, message: '请填写编号' }]}
							>
								<Input disabled={true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name={'type'} label={'类目'} rules={[{ required: true, message: '请填写类目' }]}>
								<Input disabled={true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={'name'}
								label={'物品名称'}
								rules={[{ required: true, message: '请填写物品名称' }]}
							>
								<Input disabled={true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={'brand'}
								label={'品牌'}
								rules={[{ required: true, message: '请填写品牌' }]}
							>
								<Input disabled={true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={'model'}
								label={'型号/规格'}
								rules={[{ required: true, message: '请填写型号/规格' }]}
							>
								<Input disabled={true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={'price'}
								label={'单价'}
								rules={[{ required: true, message: '请填写物品单价' }]}
							>
								<InputNumber disabled={true} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={'roomUuid'}
								label={'存放位置'}
								rules={[{ required: true, message: '请填写物品存放位置' }]}
							>
								<GsTreeSelect
									disabled={true}
									showSearch={true}
									treeNodeFilterProp={'title'}
									treeCheckable={false}
									placeholder={'请选择房间'}
									multiple={false}
									style={{ width: '100%' }}
									treeData={roomTreeData}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name={'quantity'}
								label={'物品数量'}
								initialValue={1}
								rules={[{ required: true, message: '请填写物品数量' }]}
							>
								<InputNumber style={{ width: '100%' }} min={1} precision={0} step={1} />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name={'status'}
								label={'物品状态'}
								rules={[{ required: true, message: '请填写物品状态' }]}
							>
								<Select
									onChange={(value: string) => {
										setUpdateStatus(value);
									}}
								>
									<Select.Option value={'正常'}>正常</Select.Option>
									<Select.Option value={'异常'}>异常</Select.Option>
									{/*<Select.Option value={'损坏'}>损坏</Select.Option>*/}
									{/*<Select.Option value={'报修中'}>报修中</Select.Option>*/}
									{/*<Select.Option value={'维修中'}>维修中</Select.Option>*/}
									{/*<Select.Option value={'缺失'}>缺失</Select.Option>*/}
								</Select>
							</Form.Item>
						</Col>
						{updateStatus === '异常' && (
							<>
								<Col span={12}>
									<Form.Item
										name={'damagedQuantity'}
										label={'损坏数量'}
										initialValue={1}
										rules={[{ required: true, message: '请填写物品损坏数量' }]}
									>
										<InputNumber style={{ width: '100%' }} min={0} precision={0} step={1} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name={'missQuantity'}
										label={'缺失数量'}
										initialValue={1}
										rules={[{ required: true, message: '请填写物品缺失数量' }]}
									>
										<InputNumber style={{ width: '100%' }} min={0} precision={0} step={1} />
									</Form.Item>
								</Col>
							</>
						)}

						<Col span={12}>
							<Form.Item name={'note'} label={'备注'} initialValue={1}>
								<Input.TextArea maxLength={200} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal
				key={title + roomItemId}
				width={800}
				title={title}
				footer={null}
				onCancel={() => {
					setRecordVisible(false);
				}}
				visible={recordVisible}
			>
				<GSTable
					columns={recordColumns}
					rowSelection={false}
					actionRef={infoActionRef}
					options={false}
					request={async (params: any = {}, sort: any, filter: any) => {
						const res = await getRoomItemRecordList({
							page: params.current,
							size: params.pageSize,
							roomItemId: roomItemId,
						});
						if (res.err !== 0) {
							return {
								data: [],
								total: 0,
							};
						}
						return {
							data: res.data.data,
							total: res.data.total,
							success: true,
						};
					}}
				/>
			</Modal>
		</>
	);
};
export default RoomItemList;
