import React, { useRef, useState } from 'react';

import { Button, Col, Form, Input, message, Modal, Row, Select, Space, Steps, Switch, Tag } from 'antd';
import { ActionType } from '@ant-design/pro-table';
import { FormInstance } from 'antd/es/form';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import {
	addFeeSubject,
	deleteFeeSubject,
	findFeeSubject,
	synchronizationFeeSubject,
	updataFeeSubject,
} from '../../../services/PayCostManagementService';
import AuthorizedView from '../../../util/AuthorizedView';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import FeeStandardList from '../pages/FeeStandardList';
import getBillTypes from '../models/BillTypes';
import { FormContext } from '../../../components/FormContext';
import ChargeTypeComponents from './ChargeTypeComponents';
import ChargeInfoComponents from './ChargeInfoComponents';
import ChargeAmountComponents from './ChargeAmountComponents';
import FeeSubjectForm from './FeeSubjectForm';
import moment from 'moment';
import DAL from '../../../data/DAL';
var msg = require('../../../util/SuspensionMsg');
const { Step } = Steps;
const confirm = Modal.confirm;


interface Params { 
	onCancel: () => any
	onOk:()=>any
}
const AddFeeSubjectForm: React.FC<Params> = (props: Params) => {
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [current, setcurrent] = useState(0);
	const billCycle = useRef('yue');
	const receviableList = useRef([])
	const [add, setadd] = useState(true)
	const [feeType, setfeeType] = useState("")
	const [feeTypeUuid, setfeeTypeUuid] = useState("")
	const wHeight = document.documentElement.clientHeight - 300;
	const saveItem = (toFeeSubject) => {
		form.validateFields().then(async (data: any) => {

			// console.log("startTime",moment(data.startTime).format("MM-DD"));
			
			const res = await addFeeSubject({
				...data, feeTypeUuid: feeTypeUuid, state: false,
				startTime: DAL.dal.isNotNull(data.startTime) ? moment(data.startTime).format("MM-DD") : "",
				endTime:DAL.dal.isNotNull(data.endTime) ? moment(data.endTime).format("MM-DD") : "",
			})
			if (res.err == 0) {
				message.success('保存成功')
				setfeeTypeUuid(res.data)
				setadd(false)
				if (toFeeSubject) {
					setcurrent(current + 1)
				} else { 
					props.onOk()
				} 
			} else { 
				message.error(res.msg)
			}
		});
	};
	return (
		<>
			<div
				style={{
					background: '#fff',
					padding: '10px 20px ',
					height: 100,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Steps current={current}>
					<Step title="收费项目类型" />
					<Step title="收费项目信息" />
					<Step title="金额保留设置" />
					<Step title="设置收费标准" />
				</Steps>
			</div>
			<div style={{ height: 10 }}></div>
			<div
				style={{
					width: '100%',
					background: '#fff',
					height: wHeight,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<FormContext.Provider value={form}>
					<ProxyForm form={form}>
						<div style={{ width: 800 }} hidden={current !== 0}>
							<ChargeTypeComponents  required={current == 0}></ChargeTypeComponents>
						</div>
						<div style={{ width: 800 }} hidden={current !== 1}>
							<ChargeInfoComponents
								feeType={ feeType}
								receviableTypes={receviableList.current}
								required={current == 1}
								billCycle={billCycle.current}
							></ChargeInfoComponents>
						</div>
						<div style={{ width: 800 }} hidden={current !== 2}>
							<ChargeAmountComponents required={current == 2}></ChargeAmountComponents>
						</div>
						<div style={{ width: 1300 }} hidden={current !== 3}>
							<FeeSubjectForm feeType={ feeType} > </FeeSubjectForm>
						</div>
					</ProxyForm>
				</FormContext.Provider>
			</div>

			{current == 0 && <></>}

			<div style={{ height: 20 }}></div>
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row-reverse',
					background: '#fff',
					alignItems: 'center',
					height: 60,
				}}
			>
				<div style={{ marginRight: 20 }}>
					<Space>
						<Button onClick={props.onCancel}> 取消</Button>
						<Button  hidden={current == 0} onClick={() => setcurrent(current - 1)}>上一步</Button>
						<Button
							type="primary"
							hidden={current == 2 || current == 3}
							onClick={() => {
								form.validateFields().then(async (data: any) => {
									console.log('dataLLL', data);
									billCycle.current = 'other';	
									setfeeType(data.type)
									receviableList.current = getBillTypes().receviableTypes.filter(
										(item) => item.type === billCycle.current
									);
									setcurrent(current + 1);
								});
							}}
						>
							下一步
						</Button>
						<Button hidden={current!==2  && current!==3} type="primary" onClick={()=>saveItem(false)}>
							保存
						</Button>
						<Button hidden={current!==2} type="primary" onClick={()=>saveItem(true)}>
							保存并设置收费标准
						</Button>
					</Space>
				</div>
			</div>
		</>
	);
};
export default AddFeeSubjectForm;
