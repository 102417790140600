import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import AddHuodong from './components/AddHuodong';
import LookHuodong from './pages/LookHuodong';
import UpdataHuodong from './components/UpdataHuodong';
import HuodongReport from './components/HuodongReport';
import HuoDongMangerMiniApp from './HuoDongMangerMiniApp';
import HuoDongManger from './components/HuoDongManger';

export default function HuoDongMiniApp() {
	const rootUrl = '/huodong';
	const menus: MiniAppMenu[] = [
		{
			authority: 'activity_management:huodong_list',
			name: '活动管理',
			path: 'find-huodong-list',
			component: LookHuodong,
		},
		{
			authority: 'activity_management:huodong_report',
			name: '活动统计',
			path: 'huodong-report',
			component: HuodongReport,
		},
	];
	const routes: MiniAppRoute[] = [
		{
			path: 'update-huodong',
			component: UpdataHuodong,
		},
		{
			path: 'add-huodong',
			component: AddHuodong,
		},
		{
			path: 'find-city-area-list',
			component: AddHuodong,
		},
		{
			path: 'find-bmry-list',
			component: HuoDongManger,
		},
		{
			path: 'find-sign-list',
			component: HuoDongManger,
		},
		{
			path: 'tongji',
			component: HuoDongManger,
		},
		{
			path: 'ding-dan-list',
			component: HuoDongManger,
		},
		{
			path: 'close-huodong',
			component: LookHuodong,
		},
		{
			path: 'find-biaoti',
			component: LookHuodong,
		},
		{
			path: 'find-status-list',
			component: LookHuodong,
		},
	];

	return <MiniApp miniAppName={'活动'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
