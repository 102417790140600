import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Button, Form, Input, message, Modal, Select, TreeSelect, Switch, InputNumber } from 'antd';
import { FormInstance } from 'antd/es/form';
import { ActionType } from '@ant-design/pro-table';
import {
	addAppModel1,
	deleteAppModel1,
	getRongQiList,
	getVersionController,
	updateAppModel1,
} from '../../../services/FoundationSetupService';
import { getWuyeProj } from '../../../services/WuyeService';
import CustomUploadImage from '../../../components/CustomUploadImage';
import { PlusOutlined } from '@ant-design/icons';
import API from '../../../data/API';
import appConfig from '../../../appConfig';
import { findAnswerRecordList, findPaperList } from '../../../services/Paper';

const Paper: React.FC = () => {
	useEffect(() => {}, []);

	const actionRef = useRef<ActionType>();
	const actionRef1 = useRef<ActionType>();
	const Option = Select.Option;
	const FormItem = Form.Item;
	const [search, setsearch] = useState('');
	const [state, setstate] = useState('');
	const [infoVisiable, setinfoVisiable] = useState(false);
	const [dateKey, setdateKey] = useState(new Date().getTime().toString());
	const [uuid, setuuid] = useState('');

	let statusEnum = {
		false: '未完成',
		true: '已完成',
	};
	const columns = [
		{
			title: '用户名称',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '手机号',
			dataIndex: 'userPhone',
			key: 'userPhone',
		},
		{
			title: '身份证号',
			dataIndex: 'userCardIdNum',
			key: 'userCardIdNum',
		},
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			valueEnum: statusEnum,
		},
		{
			title: '题量',
			dataIndex: 'numberOfQuestions',
			key: 'numberOfQuestions',
		},
		{
			title: '分数',
			dataIndex: 'fraction',
			key: 'fraction',
		},
		{
			title: '创建时间',
			dataIndex: 'createdTime',
			key: 'createdTime',
			valueType: 'dateTime',
		},
		// {
		// 	title: '操作',
		// 	key: 'action',
		// 	render: (text: string, record: any) => (
		// 		<>
		// 			<a
		// 				onClick={() => {
		// 					setuuid(record.uuid);
		// 					setdateKey(new Date().getTime().toString());
		// 					setinfoVisiable(true);
		// 				}}
		// 			>
		// 				详情
		// 			</a>
		// 		</>
		// 	),
		// },
	];

	const recordColumns = [
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
		},
		{
			title: '答题时间',
			dataIndex: 'createdTime',
			key: 'createdTime',
			valueType: 'dateTime',
		},
	];

	const tableSearchBarRender = () => [
		<Select
			value={state}
			placeholder="状态"
			style={{ width: 200 }}
			onChange={(value: any) => {
				setstate(value);
			}}
		>
			<Select.Option value={''}>状态</Select.Option>
			<Select.Option value={'0'}>未完成</Select.Option>
			<Select.Option value={'1'}>已完成</Select.Option>
		</Select>,
		<Input onChange={(e) => setsearch(e.target.value)} placeholder="姓名，手机号，身份证号"></Input>,
		<Button
			type="primary"
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			{' '}
			查询
		</Button>,
	];
	const tableOperationsBarRender = () => [];

	return (
		<>
			<GSTable
				// pagination={false}
				columns={columns}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				actionRef={actionRef}
				request={async (params: any) => {
					const data = {
						page: params.current,
						size: params.pageSize,
						state: state,
						search: search,
					};
					let rsp = await findPaperList(data);
					if (rsp.err === 0) {
						return {
							data: rsp.data,
							total: rsp.total,
						};
					} else {
						return {
							data: [],
							total: 0,
						};
					}
				}}
			/>
			{/* <Modal onOk={}  visible={infoVisiable} title="答题列表">
				<GSTable
					pagination={false}
					columns={recordColumns}
					// searchBarRender={tableSearchBarRender()}
					// operationsBarRender={tableOperationsBarRender()}
					actionRef={actionRef1}
					request={async (params: any) => {
						const data = {
							// page: params.current,
							// size: params.pageSize,
							// state: state,
							// search:search
						};
						let rsp = await findAnswerRecordList(data);
						if (rsp.err === 0) {
							return {
								data: rsp.data,
							};
						} else {
							return {
								data: [],
								total: 0,
							};
						}
					}}
				/>
			</Modal> */}
		</>
	);
};
export default Paper;
