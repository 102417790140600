import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { addAssetType, deleteAssetType, getAssetTypeList, updateAssetType } from '../../../services/ZhuhushenheService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Button, Form, Image, Input, InputNumber, message, Modal, Select, Switch, Upload } from 'antd';
import { FormInstance } from 'antd/es/form';
import Api from '../../../data/API';
import API from '../../../data/API';
import appConfig from '../../../appConfig';
import { PlusOutlined } from '@ant-design/icons';
import HouseTypeImg from './HouseTypeImg';
import { getContractTemplateList } from '../../../services/Contract';
const AssetTypeList: React.FC = () => {
	useEffect(() => {
		getContract().then()
	}, [])
	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const actionRef = useRef<ActionType>();
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [item, setItem] = useState<any>({})
	const [contractType,setContractType] = useState("")
	const [bannerVisible, setBannerVisible] = useState(false)
	const [contractList,setContractList] = useState([])
	const FormItem = Form.Item;
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '月租金',
			dataIndex: 'monthRent',
			key: 'monthRent',
			valueType:'money'
		},
		{
			title: '押金',
			dataIndex: 'deposit',
			key: 'deposit',
			valueType:'money'
		},
		{
			title: '备注',
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							openUpdateModal(record);
						}}
					>
						编辑
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							setBannerModal(record);
						}}
					>
						banner图设置
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];
	const getContract = async () =>{
		const res =await getContractTemplateList({})
		if(res.err === 0){
			console.log("contractList::",res.data)
			setContractList(res.data)
		}
	}
	const setBannerModal = (record: any) => {
		
		setItem(record)
		setBannerVisible(true)
	}
	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '你确定要删除' + value.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteAssetType({ id: value.id });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error('删除失败');
				}
			},
		});
	};
	const openUpdateModal = (value: any) => {
		console.log("value::",value)
		const formData: any = {
			id: value.id,
			name: value.name,
			note: value.note,
			monthRent:value.monthRent,
			deposit: value.deposit,
			personalContract:value.personalContract,
			companyContract: value.companyContract,
			contractType: value.contractType,
			description: value.description,
			phone:value.phone,
			contractApprove: value.contractApprove == undefined || value.contractApprove == null || value.contractApprove == '' ? false :value.contractApprove  

		};
		setContractType(value.contractType)
		form.setFieldsValue(formData);
		setAdd(false);
		setVisible(true);
	};
	const tableSearchBarRender = () => [
		// <Search
		// 	placeholder="名称"
		// 	onSearch={(value) => {
		// 		setSearch(value);
		// 		if (actionRef.current) {
		// 			actionRef.current.reloadAndRest();
		// 		}
		// 	}}
		// 	enterButton={true}
		// />,
	];
	const openAddModal = () => {
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				name: data.name,
				note: data.note,
				monthRent:data.monthRent,
				deposit: data.deposit,
				personalContract:data.personalContract,
				companyContract:data.companyContract,
				contractType: data.contractType,
				description: data.description,
				phone:data.phone,
				contractApprove: data.contractApprove == undefined || data.contractApprove == null || data.contractApprove == '' ? false :data.contractApprove  
			};
			const res = await addAssetType(formData);
			if (res.err === 0) {
				message.success('添加成功');
			} else {
				message.success('添加失败');
			}
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				id: data.id,
				name: data.name,
				note: data.note,
				monthRent:data.monthRent,
				deposit: data.deposit,
				personalContract:data.personalContract,
				companyContract:data.companyContract,
				contractType: data.contractType,
				description: data.description,
				phone:data.phone,
				contractApprove: data.contractApprove == undefined || data.contractApprove == null || data.contractApprove == '' ? false :data.contractApprove  
			};
			const res = await updateAssetType(formData);
			if (res.err === 0) {
				message.success('修改成功');
			} else {
				message.success('修改失败');
			}
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		});
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={openAddModal}>
			新增
		</Button>,
	];
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getAssetTypeList({
						page: params.current,
						size: params.pageSize,
						name: search,
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				visible={visible}
				title={add ? '添加' : '修改'}
				onOk={saveItem}
				width={900}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} name={'id'}>
						<Input />
					</FormItem>
					<FormItem name={'name'} label={'名称'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem name={'monthRent'} label={'月租金'}>
						<InputNumber step="0.01" style={{width:"100%"}} />
					</FormItem>
					<FormItem name={'deposit'} label={'押金'}>
						<InputNumber step="0.01" style={{width:"100%"}} />
					</FormItem>
					<FormItem name="contractType" label="合同类型">
						<Select	style={{width:"100%"}}
									showSearch
									value={contractType}
									onChange={(value: any) => {
										setContractType(value)
									}}
									optionFilterProp="children"
									allowClear
						>
							<Select.Option value={"个人"}>个人</Select.Option>
							<Select.Option value={"企业"}>企业</Select.Option>
							<Select.Option value={"个人和企业"}>个人和企业</Select.Option>
						</Select>
					</FormItem>
					<FormItem hidden={(contractType != "个人" && contractType != "个人和企业")}
						name="personalContract" label="个人合同模板">
						<Select 	style={{width:"100%"}}
							showSearch
							optionFilterProp="children"
							allowClear
							>
							{contractList.map((item: any) => {
								return (
									<Select.Option value={item.templateId} key={item.templateId}>
										{item.templateName}
									</Select.Option>
								);
							})}
						</Select>
					</FormItem>
					<FormItem hidden={(contractType != "企业" && contractType != "个人和企业")}
						name="companyContract" label="企业合同模板">
						<Select style={{width:"100%"}}
							showSearch
							optionFilterProp="children"
							allowClear
							>
							{contractList.map((item: any) => {
								return (
									<Select.Option value={item.templateId} key={item.templateId}>
										{item.templateName}
									</Select.Option>
								);
							})}
						</Select>
					</FormItem>
					<FormItem 
						valuePropName="checked"
						name="contractApprove" label="合同是否需要审批">
						<Switch unCheckedChildren="不需要" checkedChildren="需要"></Switch>
					</FormItem>
					<FormItem name={'description'} label={'介绍'}>
						<Input.TextArea autoSize={{ minRows: 4 }}  />
					</FormItem>
					<FormItem name={'phone'} label={'联系电话'}>
						<Input  />
					</FormItem>
					<FormItem name={'note'} label={'备注'}>
						<Input />
					</FormItem>

				</ProxyForm>
			</Modal>
			<Modal  width={1300} title={"banner图设置"} visible={bannerVisible} footer={null} onCancel={()=>{setBannerVisible(false)}}>
				<HouseTypeImg imgType={"资产类型图"} isHidenImgType={true} key={item.toString()} houseTypeId={item.id}/>
			</Modal>
		</>
	);
};
export default AssetTypeList;
