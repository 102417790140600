import * as React from 'react';
import DAl, { default as DAL } from '../../data/DAL';
import Api from '../../data/API';
import { Breadcrumb, Button, Modal, Pagination } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { questionnaireDetail, questionnaireData } from '../../services/QuestionnaireService';
var msg = require('../../util/SuspensionMsg');

class QuestionnaireData extends React.Component {
	dal = DAL.dal;
	uuid = '';
	name = '';

	constructor(props: any) {
		super(props);
		this.state = {
			findList: [],
			nameDate: [],
			questionDetail: [],
			isShow: true,
			stateIndex: 1,
			pageSize: 15,
			pageNumber: 1,
			total: 0,
			visible: false,
		};
		this.uuid = props.location.state.uuid;
		this.name = props.location.state.name;
	}

	componentDidMount() {
		this.getData((this.state as any).pageNumber, (this.state as any).pageSize);
	}

	getData = async (page: number, size: number) => {
		const res = await questionnaireData({
			questionUuid: this.uuid,
			page: page,
			size: size,
		});
		// Api.postWithAuth('/api/tuwen/questionnaire_data', {
		//     questionUuid: this.uuid,
		//     page: page,
		//     size: size
		// }).then(res => {
		console.log('res>>>>>>>>>>>>>>>>>>>>>>', res);
		if (res.err === 0) {
			this.setState({
				isShow: false,
				findList: res.data,
				nameDate: res.data2,
				total: res.count,
			});
		} else {
			this.setState({
				isShow: false,
			});
		}
		// });
	};

	onPageChange = (page: number, pageSize: number) => {
		this.setState({
			pageNumber: page,
		});
		window.location.hash = `#${page}`;
		this.getData(page, pageSize);
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	detail = async (uuid: string) => {
		const res = await questionnaireDetail({
			questionUuid: uuid,
		});
		// Api.postWithAuth('/api/tuwen/questionnaire_detail', {
		//     questionUuid: uuid,
		// }).then(res => {
		if (res.err === 0) {
			this.setState({
				isShow: false,
				questionDetail: res.data,
				visible: true,
			});
		} else {
			msg.suspensionMsg({ content: res.msg });
			this.setState({
				isShow: false,
			});
		}
		// });
	};

	render() {
		return (
			<div>
				<div className="content">
					<Breadcrumb>
						<Breadcrumb.Item href="/questionnaire/questionnaire">
							{/*<Icon type="home" />调查问卷*/}
							<HomeOutlined />
							调查问卷
						</Breadcrumb.Item>
						<Breadcrumb.Item href="">
							<span>{this.name}</span>
						</Breadcrumb.Item>
					</Breadcrumb>
					<br />
					<div className="table-responsive">
						<table className="table-bordered table-line">
							<thead>
								<tr>
									<th>提交时间</th>
									{(this.state as any).nameDate.map((item: any, index: any) => (
										<th>{item.ziduan_name}</th>
									))}
									<th>操作</th>
								</tr>
							</thead>
							<tbody>
								{(this.state as any).findList.map((item: any, index: any) => (
									<tr>
										<td>{item.createTime}</td>
										{(this.state as any).nameDate.map((item1: any, index1: any) => (
											<td>
												{item.value[0] === null || item.value[0] === undefined
													? ''
													: item1.ziduan_uuid === item.value[0].ziduan_uuid
													? item.value[0].value
													: item.value[1] === null || item.value[1] === undefined
													? ''
													: item1.ziduan_uuid === item.value[1].ziduan_uuid
													? item.value[1].value
													: item.value[2] === null || item.value[2] === undefined
													? ''
													: item1.ziduan_uuid === item.value[2].ziduan_uuid
													? item.value[2].value
													: ''}
											</td>
										))}
										<td>
											<ul className="ant-list-item-action" style={{ marginLeft: '0' }}>
												<li onClick={this.detail.bind(this, item.uuid)}>
													<a>详情</a>
												</li>
											</ul>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{/*分页实现*/}
					<Pagination
						className="pagination-com"
						showQuickJumper={true}
						defaultCurrent={(this.state as any).pageNumber}
						current={(this.state as any).pageNumber}
						total={(this.state as any).total}
						pageSize={(this.state as any).pageSize}
						onChange={this.onPageChange}
						showTotal={(e) => {
							return '共' + e + '项';
						}}
						showSizeChanger={true}
						pageSizeOptions={DAl.pageSize}
						onShowSizeChange={(current, size) => {
							this.setState({
								pageSize: size,
							});
							this.getData(1, size);
						}}
					/>

					{/*问卷详情*/}
					<Modal
						visible={(this.state as any).visible}
						title="问卷详情信息"
						onOk={this.handleCancel}
						onCancel={this.handleCancel}
						footer={[
							<Button key="back" onClick={this.handleCancel}>
								OK
							</Button>,
						]}
					>
						<form id="formData">
							<div className=" send-key-dialog-body ">
								{(this.state as any).questionDetail.map((item: any, index: number) => (
									<div className="send-key-dialog-footer-time">
										{item.name}：
										<input
											type="text"
											value={item.value}
											className="form-control send-key-dialog-footer-time-input"
										/>
									</div>
								))}
							</div>
						</form>
					</Modal>

					<div className="modal fade" id="deleteHuodongType" hidden={true}>
						<div className="modal-dialog  modal-sm">
							<div className="modal-content">
								<div className="modal-header">
									<button type="button" className="close" data-dismiss="modal">
										<span aria-hidden="true">×</span>
										<span className="sr-only">关闭</span>
									</button>
									<h4 className="modal-title">提示</h4>
								</div>
								<div className="modal-body">
									<p>确认删除？</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-default" data-dismiss="modal">
										关闭
									</button>
									{/*<button type="button" onClick={this.delHuodong} data-dismiss="modal" className="btn btn-primary">保存</button>*/}
								</div>
							</div>
						</div>
					</div>

					<div className="modal fade" id="tishiMsg" hidden={true}>
						<div className="modal-dialog modal-sm">
							<div className="modal-content">
								<div className="modal-header">
									<button type="button" className="close" data-dismiss="modal">
										<span aria-hidden="true">×</span>
										<span className="sr-only">关闭</span>
									</button>
									<h4 className="modal-title">提示</h4>
								</div>
								<div className="modal-body">
									<p>{(this.state as any).tishiMsg}</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-default" data-dismiss="modal">
										关闭
									</button>
									{/* <button type="button" className="btn btn-primary">保存</button>*/}
								</div>
							</div>
						</div>
					</div>

					{(this.state as any).isShow ? (
						<div className="cover">
							<div>
								<i className="icon icon-spin icon-spinner-indicator" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default QuestionnaireData;
