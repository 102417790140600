import { ActionType } from '@ant-design/pro-table';
import { Button, Input, message, Modal, Select, Space, Spin } from 'antd';
import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { findListByPage, updateSysMessage } from '../../../services/SystemSysMessageService';
const AlarmLog: React.FC = (props: any) => {
	const actionRef = useRef<ActionType>();
	const [handle, sethandle] = useState('待处理');
	const [level, setLevel] = useState('');
	const [eventMsg, seteventMsg] = useState('');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [loading, setloading] = useState(false);
	const levelEmun = {
		0: '通知',
		1: '警告',
		2: '异常',
		3: '错误',
	};
	const columns = [
		{
			title: '事件等级',
			dataIndex: 'level',
			key: 'level',
			valueEnum: levelEmun,
		},
		{
			title: '描述',
			dataIndex: 'event_msg',
			key: 'event_msg',
		},
		{
			title: '创建时间',
			dataIndex: 'created_time',
			valueType: 'dateTime',
			key: 'created_Time',
		},
		{
			title: '处理人',
			dataIndex: 'handler_name',
			key: 'handler_name',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<Space>
					<a
						hidden={'待处理' != record.handle}
						onClick={() => {
							hlItem(record.uuid);
						}}
					>
						忽略
					</a>
					<a
						hidden={'待处理' != record.handle}
						onClick={() => {
							clItem(record.uuid);
						}}
					>
						已处理
					</a>
				</Space>
			),
		},
	];
	const hlItem = (uuid) => {
		Modal.confirm({
			content: '您是否要忽略？',
			onOk() {
				updateItem(uuid, '忽略');
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const clItem = (uuid) => {
		Modal.confirm({
			content: '您是否要变更为已处理？',
			onOk() {
				updateItem(uuid, '已处理');
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const updateItem = async (uuid: string, handle: string) => {
		setloading(true);
		const res = await updateSysMessage({ uuid, handle });
		setloading(false);
		if (res.err === 0) {
			message.success('修改成功');
			setselectedRowKeys([]);
			actionRef.current.reload();
		} else {
			message.error(res.msg);
		}
	};
	const tableSearchBarRender = () => [
		<Space>
			<Select style={{ width: 100 }} value={level} onChange={(value) => setLevel(value)}>
				<Select.Option value={''}>全部</Select.Option>
				<Select.Option value={'0'}>通知</Select.Option>
				<Select.Option value={'1'}>警告</Select.Option>
				<Select.Option value={'2'}>异常</Select.Option>
				<Select.Option value={'3'}>错误</Select.Option>
			</Select>
			<Select style={{ width: 100 }} value={handle} onChange={(value) => sethandle(value)}>
				<Select.Option value={'待处理'}>待处理</Select.Option>
				<Select.Option value={'已处理'}>已处理</Select.Option>
				<Select.Option value={'忽略'}>忽略</Select.Option>
			</Select>
			<Input value={eventMsg} placeholder="事件描述" onChange={(e) => seteventMsg(e.target.value)}></Input>
			<Button
				type="primary"
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>
		</Space>,
	];
	const getList = async (page: number, size: number) => {
		let params = {
			level: level,
			handle: handle,
			page: page,
			size: size,
			eventMsg: eventMsg,
		};
		let rsp = await findListByPage(params);
		if (rsp.err === 0) {
			return {
				data: rsp.data,
				total: rsp.total,
			};
		} else {
			return {
				data: [],
				total: 0,
			};
		}
	};

	return (
		<div className="content-big">
			<Spin spinning={loading}>
				<GSTable
					columns={columns}
					rowKey={'uuid'}
					rowSelection={{
						fixed: true,
						columnWidth: 100,
						preserveSelectedRowKeys: true,
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys: any, selectedRows: any) => {
							setselectedRowKeys(selectedRowKeys);
						},
					}}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
										}}
									>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						console.log('tableAlertOptionRender::::', selectedRows);
						return (
							<Space size={16}>
								<a
									hidden={'待处理' != handle}
									onClick={() => {
										hlItem(selectedRowKeys.toString());
									}}
								>
									忽略
								</a>
								<a
									hidden={'待处理' != handle}
									onClick={() => {
										clItem(selectedRowKeys.toString());
									}}
								>
									已处理
								</a>
							</Space>
						);
					}}
					searchBarRender={tableSearchBarRender()}
					actionRef={actionRef}
					request={(params: any) => {
						return getList(params.current, params.pageSize);
					}}
				/>
			</Spin>
		</div>
	);
};
export default AlarmLog;
