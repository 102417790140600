import Api from '../data/API';

export async function findFaceInfoByWyUuid(params: any) {
	return Api.postWithAuth('/api/wuye/findFaceInfoByWyUuid', params);
}

export async function updFaceState(params: any) {
	return Api.postWithAuth('/api/wuye/updFaceState', params);
}

export async function getAllFaceDevice(params: any) {
	return Api.postWithAuth('/api/device/getFaceDeviceAll', params)
}
export async function getAllDetectRecord(params:any) {
	return Api.postWithAuth('/api/device/getDetectRecordList', params);
}
