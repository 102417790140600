import * as React from 'react';
import PayNotice from './pages/PayNotice';
import SmsRecord from './pages/SmsRecord';
import AdNotice from './pages/AdNotice';
import AdNoticeRecord from './pages/AdNoticeRecord';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';

export default function NotificationMiniApp() {
	const rootUrl = '/notification';
	const menus: MiniAppMenu[] = [
		{
			authority: 'notification_management:sms_notification',
			name: '短信通知',
			path: 'pay-notice',
			component: PayNotice, 
		},
		{
			authority: 'notification_management:sms_notification_record',
			name: '短信通知记录',
			path: 'sms-record',
			component: SmsRecord,
		},
		{
			authority: 'notification_management:open_door_notification',
			name: '开门通知',
			path: 'ad-notice',
			component: AdNotice,
		},
		{
			authority: 'notification_management:open_door_notification_record',
			name: '开门通知记录',
			path: 'ad-notice-record',
			component: AdNoticeRecord,
		},


		// , { bie guanzhu
		//     name: '设置',
		//     path: 'sms-set-up',
		//     components: SmsSetUp
		// }
	];

	return <MiniApp miniAppName={'通知'} rootUrl={rootUrl} menus={menus} />;
}
