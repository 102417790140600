import Api from '../data/API';

//import {} from "../../services/ZhuhushenheService";
export async function getList(params: any) {
	return Api.postWithAuth('/api/apple_community/get_list', params);
}

export async function batchImportHousehold(params: any) {
	return Api.postWithAuth('/api/zhuhu/batch_import_household', params);
}

export async function findBatchList(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_batch_list', params);
}

export async function findBuilds(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_builds', params);
}

export async function getDeviceDList(params: any) {
	//1111
	return Api.postWithAuth('/admin/api/device/getDeviceDList', params);
}

export async function findAllBuilds(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_all_builds', params);
}

export async function findRoomLive(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_room_live', params);
}

export async function findLiveRooms(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_live_rooms', params);
}

export async function choiceNeedContrast(params: any) {
	return Api.postWithAuth('/api/zhuhu/choice_need_contrast', params);
}

export async function updataCompanyInfo(params: any) {
	return Api.postWithAuth('/api/company/updata_company_info', params);
}

export async function jieshuBatch(params: any) {
	return Api.postWithAuth('/api/zhuhu/jieshu_batch', params);
}

export async function kaiShiBatch(params: any) {
	return Api.postWithAuth('/api/zhuhu/kaiShi_batch', params);
}

export async function getZhuhuStatistics(params: any) {
	return Api.postWithAuth('/api/statistics/getZhuhuStatistics', params);
}

export async function getHouseholdStatistics(params: any) {
	return Api.postWithAuth('/api/statistics/getHouseholdStatistics', params);
}

export async function getRecordStatisticsApi(params: any) {
	return Api.postWithAuth('/api/statistics/getRecordStatisticsApi', params);
}

export async function getFaceAuditStatistics(params: any) {
	return Api.postWithAuth('/api/statistics/getFaceAuditStatistics', params);
}

export async function getRoomVacantStatistics(params: any) {
	return Api.postWithAuth('/api/statistics/getRoomVacantStatistics', params);
}

export async function getRoomSubletStatistics(params: any) {
	return Api.postWithAuth('/api/statistics/getRoomSubletStatistics', params);
}

export async function creditsRecordList(params: any) {
	return Api.postWithAuth('/admin/api/credits/record/list', params);
}

export async function findhouseholdReportform(params: any) {
	return Api.postWithAuth('/api/zhuhu/findhousehold_reportform', params);
}

export async function findhouseholdReportFormInfo(params: any) {
	return Api.postWithAuth('/api/zhuhu/findhousehold_report_form_info', params);
}

export async function findhouseholdVerification(params: any) {
	return Api.postWithAuth('/api/zhuhu/findhousehold_verification', params);
}

export async function addHouseholdVerification(params: any) {
	return Api.postWithAuth('/api/zhuhu/add_household_verification', params);
}

export async function getHouseholdCheckOut(params: any) {
	return Api.postWithAuth('/api/zhuhu/get_household_check_out', params);
}

export async function getHouseholdList(params: any) {
	return Api.postWithAuth('/api/zhuhu/get_household_list', params);
}

export async function findByBuildId(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_by_buildId', params);
}

export async function updataHousehold(params: any) {
	return Api.postWithAuth('/api/zhuhu/updataHousehold', params);
}

export async function householdCheckOut(params: any) {
	return Api.postWithAuth('/api/zhuhu/household_check_out', params);
}

export async function addHousehold(params: any) {
	return Api.postWithAuth('/api/zhuhu/add_household', params);
}

export async function findRoom(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_room', params);
}

export async function findLiveRoom(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_live_room', params);
}
export async function findWuyeRoomList(params: any) {
	return Api.postWithAuth('/api/zhuhu/findWuyeRoomList', params);
}

export async function getOpenUnLockDate(params: any) {
	return Api.postWithAuth('/api/record/getOpenUnLockDate', params);
}

export async function addBuild(params: any) {
	return Api.postWithAuth('/api/zhuhu/add_build', params);
}

export async function updataBuild(params: any) {
	return Api.postWithAuth('/api/zhuhu/updata_build', params);
}

export async function getReqortFormsBatch(params: any) {
	return Api.postWithAuth('/api/zhuhu/get-reqort-forms-batch', params);
}

export async function freezeMapping(params: any) {
	return Api.postWithAuth('/api/device/freeze_mapping', params);
}

export async function unfreezeMapping(params: any) {
	return Api.postWithAuth('/api/device/unfreeze_mapping', params);
}

export async function spotCheck(params: any) {
	return Api.postWithAuth('/api/zhuhu/spot_check', params);
}

export async function getZhuHuReportFormList(params: any) {
	return Api.postWithAuth('/api/zhuhu/get-zhu-hu-report-form-list', params);
}

export async function getAssetsType(params: any) {
	return Api.postWithAuth('/api/zhuhu/get_assets_type', params);
}
export async function getHouseType(params: any) {
	return Api.postWithAuth('/api/zhuhu/getHouseType', params);
}

export async function getHouseStyle(params: any) {
	return Api.postWithAuth('/api/zhuhu/getHouseStyle', params);
}

export async function findRoomByUuid(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_room_by_uuid', params);
}

export async function saveRoomGoods(params: any) {
	return Api.postWithAuth('/api/zhuhu/saveRoomGoods', params);
}

export async function deleteRoom(params: any) {
	return Api.postWithAuth('/api/zhuhu/delete_room', params);
}

export async function addRoom(params: any) {
	return Api.postWithAuth('/api/zhuhu/add_room', params);
}

export async function deleteHousehold(params: any) {
	return Api.postWithAuth('/api/zhuhu/delete_household', params);
}

export async function getHouseholdContrastResult(params: any) {
	return Api.postWithAuth('/api/zhuhu/get_household_contrast_result', params);
}

export async function auditUserImageInfo(params: any) {
	return Api.postWithAuth('/api/zhuhu/audit-user-image-info', params);
}
export async function deleteBuild(params: any) {
	return Api.postWithAuth('/api/zhuhu/delete_build', params);
}

export async function randomGetAuditing(params: any) {
	return Api.postWithAuth('/api/zhuhu/random-get-auditing', params);
}
export async function getLaJiTongStatisticsiData(params: any) {
	return Api.postWithAuth('/api/wuye/get_la_ji_tong_data', params);

}

export async function getAssetTypeList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getAssetTypeList', params);
}
export async function addAssetType(params: any) {
	return Api.postWithAuth('/api/zhuhu/addAssetType', params);
}
export async function updateAssetType(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateAssetType', params);
}
export async function deleteAssetType(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteAssetType', params);
}

export async function getHouseTypeList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getHouseTypeList', params);
}
export async function sortHouseType(params: any) {
	return Api.postWithAuth('/api/zhuhu/sortHouseType', params);
}
export async function addHouseType(params: any) {
	return Api.postWithAuth('/api/zhuhu/addHouseType', params);
}
export async function updateHouseType(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateHouseType', params);
}

export async function deleteHouseType(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteHouseType', params);
}
export async function syncRoomItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/syncRoomItem', params);
}
export async function getHouseStyleList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getHouseStyleList', params);
}
export async function addHouseStyle(params: any) {
	return Api.postWithAuth('/api/zhuhu/addHouseStyle', params);
}
export async function updateHouseStyle(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateHouseStyle', params);
}

export async function deleteHouseStyle(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteHouseStyle', params);
}

export async function getRoomPictures(params: any) {
	return Api.postWithAuth('/api/zhuhu/getRoomPictures', params);
}
export async function addRoomPicture(params: any) {
	return Api.postWithAuth('/api/zhuhu/addRoomPicture', params);
}
export async function addPicture(params: any) {
	return Api.postWithAuth('/api/zhuhu/addPicture', params);
}
export async function updateRoomPhoto(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateRoomPhoto', params);
}
export async function sortRoomImg(params: any) {
	return Api.postWithAuth('/api/zhuhu/sortRoomImg', params);
}

export async function deleteRoomImg(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteRoomImg', params);
}

export async function getRoomAbnormalList(params: any) {
	return Api.postWithAuth("/api/zhuhu/get_room_abnormal_list", params);
}
export async function handleRoomAbnormal(params: any) {
	return Api.postWithAuth("/api/zhuhu/handle_room_abnormal_list", params);
}
export async function updateRoomSell(params: any) {
	return Api.postWithAuth("/api/zhuhu/updateRoomSell", params);
}
//import {} from "../../services/ZhuhushenheService";
export async function getListOfItemsList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getListOfItemsList', params);
}
export async function batchListOfItems(params: any) {
	return Api.postWithAuth('/api/zhuhu/batchListOfItems', params);
}
export async function batchItemInfo(params: any) {
	return Api.postWithAuth('/api/zhuhu/batchItemInfo', params);
}
export async function batchRoomItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/batchData', params);
}

export async function getLeaseInfoList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getLeaseInfoList', params);
}
export async function addListOfItems(params: any){
	return Api.postWithAuth('/api/zhuhu/addListOfItem', params)
}

export async function getAllItemInfo(params: any){
	return Api.postWithAuth('/api/zhuhu/getAllItemInfo', params)
}

export async function updateListOfItems(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateListOfItem', params)
}
export async function deleteListOfItems(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteListOfItem', params)
}
export async function getRoomItemList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getRoomItemList', params)
}
export async function getRoomItemRecordList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getRoomItemRecordList', params)
}

export async function  addRoomItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/addRoomItem', params);
}

export async function updateRoomItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateRoomItem', params);
}
export async function deleteRoomItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteRoomItem', params);
}
export async function getRoomTreeData(params: any) {
	return Api.postWithAuth('/api/zhuhu/getRoomTreeData', params)
}


export async function getItemInfoList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getItemInfoList', params)
}

export async function addItemInfo(params: any) {
	return Api.postWithAuth('/api/zhuhu/addItemInfo', params)
}

export async function updateItemInfo(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateItemInfo', params)
}

export async function deleteItemInfo(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteItemInfo', params)
}

export async function getCleanItemList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getCleanItemList', params)
}
export async function addCleanItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/addCleanItem', params)
}
export async function updateCleanItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateCleanItem', params)
}
export async function deleteCleanItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteCleanItem', params)
}
export async function batchCleanItem(params: any) {
	return Api.postWithAuth('/api/zhuhu/batchCleanItem', params)
}



export async function getItemTypeList(params: any) {
	return Api.postWithAuth('/api/zhuhu/getItemTypeList', params);
}

export async function addItemType(params: any) {
	return Api.postWithAuth('/api/zhuhu/addItemType', params);
}

export async function updateItemType(params: any) {
	return Api.postWithAuth('/api/zhuhu/updateItemType', params);
}

export async function deleteItemType(params: any) {
	return Api.postWithAuth('/api/zhuhu/deleteItemType', params);
}



