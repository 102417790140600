import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import { Input, Table, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { findhouseholdReportform } from '../../../services/ZhuhushenheService';

const Search = Input.Search;

class EntranceGuardReport extends React.Component {
	dal = DAL.dal;

	columns = [
		{
			title: '楼栋',
			dataIndex: 'buildName',
			key: 'buildName',
		},
		{
			title: '房间数',
			dataIndex: 'roomNumber',
			key: 'roomNumber',
		},
		{
			title: '入住人数',
			dataIndex: 'hairKey',
			key: 'hairKey',
		},
		{
			title: '未入住',
			key: 'notUsed',
			dataIndex: 'notUsed',
		},
		{
			title: '疑似转租',
			key: 'NotThroughs',
			dataIndex: 'NotThroughs',
			render: (text: string, record: any) => (
				<Link
					to={{
						pathname: '/zhuhu/entrance-guard-report-info',
						state: { deviceIds: record.devices, uuid: record.uuid },
					}}
				>
					{text}
				</Link>
			),
		},
		{
			title: '疑似空置',
			key: 'liveDay',
			dataIndex: 'liveDay',
			render: (text: string, record: any) => (
				<Link
					to={{
						pathname: '/zhuhu/entrance-guard-report-info',
						state: { deviceIds: record.devices, uuid: record.uuid },
					}}
				>
					{text}
				</Link>
			),
		},
	];

	size: number = 50;
	page: number = 1;
	search: string = '';
	constructor(props: any) {
		super(props);
		this.state = {
			ds: [],
			isShow: true,
			total: 0,
		};
		this.findhouseholdReportForm(null);
		this.findhouseholdReportForm = this.findhouseholdReportForm.bind(this);
	}

	findhouseholdReportForm = async (value: any) => {
		this.search = value;
		const res = await findhouseholdReportform({
			search: value,
			page: this.page,
			size: this.size,
		});
		// API.postWithAuth('/api/zhuhu/findhousehold_reportform' , {
		//     search: value,
		//     page: this.page,
		//     size: this.size
		// }).then(res => {
		if (res.err === 0) {
			console.log('获得住户门禁报表', res.data);
			this.setState({
				ds: res.data,
				isShow: false,
				total: res.total,
			});
		}
		// });
	};

	render() {
		return (
			<div>
				<nav className="filter-menu-bar navbar navbar-default" role="navigation">
					<div className="container-fluid">
						<ul className="nav navbar-nav ulli">
							<li>
								<Search
									placeholder="楼栋名称"
									onSearch={(value) => this.findhouseholdReportForm(value)}
									style={{ width: 300, paddingLeft: 20 }}
								/>
							</li>
						</ul>
					</div>
				</nav>
				<div className="content">
					<Table
						columns={this.columns}
						size="middle"
						dataSource={(this.state as any).ds}
						loading={(this.state as any).isShow}
						pagination={false}
					/>
					<br />
					{/*分页实现*/}
					<Pagination
						className="pagination-com"
						showQuickJumper={true}
						defaultCurrent={this.page}
						current={this.page}
						total={(this.state as any).total}
						pageSize={this.size}
						onChange={(page: number, pageSize: number) => {
							this.page = page;
							this.size = pageSize;
							this.findhouseholdReportForm(this.search);
						}}
						showTotal={(e) => {
							return '共' + e + '项';
						}}
						showSizeChanger={true}
						pageSizeOptions={DAL.pageSize}
						onShowSizeChange={(current: number, size: number) => {
							console.log('size:', size);
							this.page = 1;
							this.size = size;
							this.findhouseholdReportForm(this.search);
						}}
					/>
				</div>
			</div>
		);
	}
}

export default EntranceGuardReport;
