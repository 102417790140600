import React, { useRef, useState } from 'react';
import { deleteBlackList, getBlackList } from '../../../services/BlackUser';
import GSTable from '../../../components/GSTable';
import { Button, Input, message, Modal, Select, Space } from 'antd';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { getExitSlipList } from '../../../services/ZhiNengMenJinService';
import ApiImp from '../../../data/API';
const ExitSlipList: React.FC = (props: any) => {
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');

	const columns = [

		{
			title: '姓名',
			dataIndex: 'name',
			width: 100,
			key: 'name',
		},{
			title: '手机号',
			dataIndex: 'phone',
			width: 100,
			key: 'phone',
		},
		{
			title: '申请单位',
			dataIndex: 'applyDepartment',
			width: 150,
			key: 'applyDepartment',
		},
		{
			title: '携出单位',
			width: 150,
			dataIndex: 'takeOutDepartment',
			key: 'takeOutDepartment',
		},{
			title: '出厂事由',
			width: 100,
			dataIndex: 'cause',
			key: 'cause',
		},{
			title: '车辆类型',
			width: 100,
			dataIndex: 'carType',
			key: 'carType',
		},{
			title: '车牌号',
			width: 100,
			dataIndex: 'carNumber',
			key: 'carNumber',
		},
		{
			title: '创建时间',
			width: 200,
			dataIndex: 'createdTime',
			valueType: 'dateTime',
			key: 'createdTime',
		},
		{
			title: '操作',
			width: 100,
			fixed: 'right',
			key: 'action',
			render: (text: string, record: any) => (
				<Space>
					<a target={"_blank"} href={`/page/liucheng/Liuchengdan_data_info?shiliuuid=${record.shiliUuid}&Needorders`}>查看流程</a>
				</Space>
			),
		},
	];
	const tableSearchBarRender = () => [
		<Input placeholder={'姓名'} onChange={(event: any) => {
			setSearch(event.target.value)
		}} />,
		<Button type="primary" onClick={() => {
			if (actionRef.current) {
				actionRef.current.reload()
			}
		}}>查询</Button>
	];
	return (<>
		<GSTable
			columns={columns}
			actionRef={actionRef}
			searchBarRender={tableSearchBarRender()}
			operationsBarRender={[]}
			request={async (params: any = {}, sort: any, filter: any) => {
				const res = await getExitSlipList({
					page: params.current,
					size: params.pageSize,
					search: search,
				});
				if (res.err !== 0) {
					return {
						data: [],
						total: 0,
					};
				}
				return {
					data: res.data,
					total: res.total,
					success: true,
				};
			}}
		/>
	</>);
};
export default ExitSlipList;
