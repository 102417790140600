import Api from '../data/API';
export async function getList(params: any) {
    return Api.postWithAuth('/adminapi/car/record/list', params);
}
export async function getListSumData(params: any) {
    return Api.postWithAuth('/adminapi/car/record/getListSumData', params);
}

export async function asyncList(params: any) {
    return Api.postWithAuth('/adminapi/car/record/asyncRecordList', params);
}
