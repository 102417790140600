import React, { useEffect, useState } from 'react';
import { getRoomStatistics } from '../../services/StatisticsService';
import { Card, Col, Row, Statistic } from 'antd';
import { DataContext } from '../controlPlatform/CurrentPlatform';
import LineChart from '../../components/LineChart';
import BarChart from '../../components/BarChart';
import { ColumnChartContext } from '../controlPlatform/CurrentPhoneBrandChart';
import ColumnChart from '../../components/ColumnChart';
import DualAxesChart from '../../components/DualAxesChart';
import { CarryOutFilled, FundFilled, PayCircleFilled, ThunderboltFilled } from '@ant-design/icons/lib';

const EnergyConsumptionReport: React.FC = () => {


	const config = {
		xField: 'x',
		yField: 'y',
		seriesField: 'name',
		label: {
			// 可手动配置 label 数据标签位置
			position: 'middle',
			// 'top', 'bottom', 'middle'
			// 可配置附加的布局方法
			layout: [
				// 柱形图数据标签位置自动调整
				{
					type: 'interval-adjust-position'
				}, // 数据标签防遮挡
				{
					type: 'interval-hide-overlap'
				}, // 数据标签文颜色自动调整
				{
					type: 'adjust-color'
				}
			]
		}
	};
	const dianData: any = [
		{
			x: '2022-06-19',
			y: 	2167.50,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-20',
			y: 2148.40,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-21',
			y: 1908.94,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-22',
			y: 2118.78,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-23',
			y: 2015.52,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-24',
			y: 2134.61,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-25',
			y: 2201.48,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-26',
			y: 2305.98,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-27',
			y: 2172.66,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-28',
			y: 2184.96,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-29',
			y: 2220.91,
			name: '用电量(KWh)'
		},
		{
			x: '2022-06-30',
			y: 2327.45,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-01',
			y: 2432.32,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-02',
			y: 2469.36,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-03',
			y: 2488.42,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-04',
			y: 2299.01,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-05',
			y: 2368.55,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-06',
			y: 2412.76,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-07',
			y: 2408.03,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-08',
			y: 2897.24,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-09',
			y: 2603.27,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-10',
			y: 2618.44,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-11',
			y: 2511.37,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-12',
			y: 2357.57,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-13',
			y: 2344.99,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-14',
			y: 2556.10,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-15',
			y: 2522.83,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-16',
			y: 2598.93,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-17',
			y: 2605.64,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-18',
			y: 2477.27,
			name: '用电量(KWh)'
		},

		{
			x: '2022-07-19',
			y: 2359.89,
			name: '用电量(KWh)'
		}
	];

	const dianFeiData: any = [
		{
			x: '2022-06-19',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-20',
			y: 200.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-21',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-22',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-23',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-24',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-25',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-26',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-27',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-28',
			y: 1200.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-29',
			y: 15000.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-06-30',
			y: 1500.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-01',
			y: 33200.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-02',
			y: 4000.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-03',
			y: 500.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-04',
			y: 1500.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-05',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-06',
			y: 6000.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-07',
			y: 500.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-08',
			y: 	4000.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-09',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-10',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-11',
			y: 5000.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-12',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-13',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-14',
			y: 800.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-15',
			y: 4000.00,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-16',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-17',
			y: 0,
			name: '电缴费金额（元）'
		},
		{
			x: '2022-07-18',
			y: 	3200.00,
			name: '电缴费金额（元）'
		},

		{
			x: '2022-07-19',
			y: 0,
			name: '电缴费金额（元）'
		}
	];
	return (
		<div className="content-big">
			<Row gutter={12}>
				<Col span={6}>
					<div style={{
						display: 'flex',
						flex: 1,
						height: 104,
						backgroundColor: '#00a65a',
						padding: 30,
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
						<div style={{ color: '#fff', fontWeight: 'bold', fontSize: 30 }}>
							<div style={{ fontSize: 14, fontWeight: 400 }}>昨日用电量</div>
							<div>2359.89度</div>
						</div>
						<div>
							<ThunderboltFilled style={{ fontSize: 60, color: '#fff' }}/>
						</div>
					</div>
				</Col>
				<Col span={6}>
					<div style={{
						display: 'flex',
						flex: 1,
						height: 104,
						backgroundColor: '#0073b6',
						padding: 30,
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
						<div style={{ color: '#fff', fontWeight: 'bold', fontSize: 30 }}>
							<div style={{ fontSize: 14, fontWeight: 400 }}>本月用电量</div>
							<div>4.96万度</div>
						</div>
						<div>
							<CarryOutFilled style={{ fontSize: 60, color: '#fff' }}/>
						</div>
					</div>
				</Col>
				<Col span={6}>
					<div style={{
						display: 'flex',
						flex: 1,
						height: 104,
						backgroundColor: '#DD4c39',
						padding: 30,
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
						<div style={{ color: '#fff', fontWeight: 'bold', fontSize: 30 }}>
							<div style={{ fontSize: 14, fontWeight: 400 }}>今日售电金额</div>
							<div>￥0.00元</div>
						</div>
						<div>
							<PayCircleFilled style={{ fontSize: 60, color: '#fff' }}/>
						</div>
					</div>
				</Col>
				<Col span={6}>
					<div style={{
						display: 'flex',
						flex: 1,
						height: 104,
						backgroundColor: '#f39c11',
						padding: 30,
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
						<div style={{ color: '#fff', fontWeight: 'bold', fontSize: 30 }}>
							<div style={{ fontSize: 14, fontWeight: 400 }}>本月售电金额</div>
							<div>6.47万元</div>
						</div>
						<div>
							<FundFilled style={{ fontSize: 60, color: '#fff' }}/>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Card title={'最近30天缴费金额'}>
						<ColumnChartContext.Provider value={dianFeiData}>
							<ColumnChart {...config}/>
						</ColumnChartContext.Provider>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Card title={'最近30天用电量'}>
					<DataContext.Provider value={dianData}>
						<LineChart/>
					</DataContext.Provider>
					</Card>
				</Col>
			</Row>

		</div>
	);
};
export default EnergyConsumptionReport;
