import React, { useEffect, useState } from 'react';
import { getAuthRecordPlatformData, getDeviceOpenDoorData } from '../services/controlPlatformService';
import { Card, message, Select, Button, Space, TreeSelect } from 'antd';
import moment from 'moment';
import LineChart from './LineChart';
import { DataContext } from '../pages/controlPlatform/CurrentPlatform';
import CustomRangePicker from './CustomRangePicker';
import { getDevices } from "../services/ZhiNengMenJinService";
import GsTreeSelect from "./GsTreeSelect";
const AuthRecordLine: React.FC = () => {
	const [selectType, setSelectType] = useState(1);
	let startTime = moment().subtract(6, 'd');
	let endTime = moment();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>([]);
	const [devices, setDevices] = useState([]);
	let deviceId: string = "";

	useEffect(() => {
		getData();
		getSelectDeviceList().then()
	}, []);
	const getSelectDeviceList = async () => {
		const res = await getDevices({});
		if (res.err !== 0) {
			return;
		}
		let data: any = [];
		data.push({key: '全部设备', title: '全部设备', value: ''});
		data = data.concat(res.data)
		setDevices(data);
	};
	const getData = async () => {
		setLoading(true);
		let params = {
			deviceIds: deviceId,
			startTime: startTime.format('YYYY-MM-DD'),
			endTime: endTime.format('YYYY-MM-DD'),
		};
		setLoading(true);
		let rsp = await getDeviceOpenDoorData(params);
		setLoading(false);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
		}

		let chartData: any = [];
		const uNumbers = rsp.data.authNumbers || {}
		const uUsers = rsp.data.authUserNumbers || {}
		if ('{}' !== JSON.stringify(uNumbers)) { 
			let keys = Object.keys(uNumbers);
				keys.map((key: string) => {
					chartData.push({
						name: "次数",
						x: key,
						y: uNumbers[key],
					});
				});
		}
		if ('{}' !== JSON.stringify(uUsers)) { 
			let keys = Object.keys(uUsers);
				keys.map((key: string) => {
					chartData.push({
						name: "人数",
						x: key,
						y: uUsers[key],
					});
				});
		}
		setData(chartData)
		// let data = rsp.data;
		// createChartData(data);
	};

	// const createChartData = (data: any) => {
	// 	let sourceData = data[0] || {};
	// 	console.log('sourceData', sourceData);
	// 	let chartData: any = [];
	// 	(sourceData.datas || []).map((item: any) => {
	// 		let keys = Object.keys(item.dayData);
	// 		keys.map((key: string) => {
	// 			chartData.push({
	// 				name: item.unit,
	// 				x: key,
	// 				y: item.dayData[key],
	// 			});
	// 		});
	// 	});
	// 	console.log('chartData:::::', chartData);
	// 	setData(chartData);
	// };

	const tabBarExtrContent = () => (
		<Space>
			<GsTreeSelect
				treeData={devices}
				defaultValue={deviceId}
				onChange={(ev: any) => {
					deviceId = ev.toString();
				}}
			/>
			<CustomRangePicker
				onChange={(_startTime, _endTime) => {
					startTime = _startTime;
					endTime = _endTime;
				}}
				startTime={startTime}
				endTime={endTime}
			/>
			<Button type={"primary"} onClick={getData}> 查询</Button>
		</Space>
	);
	return (
		<Card title={'钥匙授权趋势'} extra={tabBarExtrContent()} loading={loading} bodyStyle={{ paddingLeft: 15 }}>
			<DataContext.Provider value={data}>
				<LineChart />
			</DataContext.Provider>
		</Card>
	);
};
export default AuthRecordLine;
