import Api from '../data/API';

export async function findLastMeterReading(params: any) {
	return Api.postWithAuth('/admin/meterReading/findLastMeterReading', params);
}
export async function getList(params: any) {
	return Api.postWithAuth('/admin/meterReading/getList', params);
}
export async function addMeterReading(params: any) {
	return Api.postWithAuth('/admin/meterReading/addMeterReading', params);
}
export async function updateMeterReading(params: any) {
	return Api.postWithAuth('/admin/meterReading/updateMeterReading', params);
}
export async function deleteMeterReading(params: any) {
	return Api.postWithAuth('/admin/meterReading/deleteMeterReading', params);
}




