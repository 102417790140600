import * as React from 'react';
import DAL from '../../../data/DAL';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import API from '../../../data/API';
import { qianDaoShu } from '../../../services/huodongService';
var echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/line'); //图表类型
require('echarts/lib/component/title'); //标题插件

class Tongji extends React.Component {
	dal = DAL.dal;
	signNumChart: any;
	lineData: any;

	constructor(props: any) {
		super(props);
		this.state = {
			tongji: [],
			isShow: false,
			browse: '',
			visitor: '',
			number: '',
			sign: '',
			zhuangTai: '',
		};
		this.setPieOption = this.setPieOption.bind(this);
		this.signNum = this.signNum.bind(this);
		// this.lines = this.lines.bind(this);
		this.getData = this.getData.bind(this);
		this.setPieOption = this.setPieOption.bind(this);
	}

	/* lines(lin: any) {
        if (lin !== null) {
            this.lineData = echarts.init(lin);
        }

    }*/

	componentDidMount() {
		this.getData();
	}

	signNum(chart: any) {
		if (chart !== null) {
			this.signNumChart = echarts.init(chart);
		}
	}

	getData = async () => {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		// let data = new FormData();
		// data.append('huodongUuid', uuid );
		// let url = '/api/huodong/qian_dao_shu';
		// this.dal.getNetWorkData(data, url).then(res => {
		//
		// });
		const res = await qianDaoShu({
			huodongUuid: uuid,
			kind: 'huodongTicket',
		});
		// API.postWithAuth(url , {
		//     huodongUuid: uuid,
		//     kind: 'huodongTicket'
		// }).then(res => {
		console.log('获得信息数据', res);
		if (res.err === 0) {
			console.log('获得信息数据.......................................', res.data.nameData.toString());
			let nameData = '[' + res.data.nameData.toString() + ']';
			let valueData = JSON.stringify(res.data.valueData).replace(/"/g, '');
			console.log('获得信息数据', nameData);
			console.log('获得信息数据', valueData);
			this.signNumChart.setOption(this.setPieOption(nameData, valueData));
		} else {
			alert(res.msg);
		}
		// });
	};

	setPieOption(nameData: any, valueData: any) {
		var option = {
			tooltip: {
				trigger: 'item',
				formatter: '{a} <br/>{b}: {c} ({d}%)',
			},
			legend: {
				orient: 'vertical',
				x: 'left',
				data: nameData,
			},
			series: [
				{
					name: '来源',
					type: 'pie',
					color: [
						'#37A2DA',
						'#32C5E9',
						'#67E0E3',
						'#9FE6B8',
						'#FFDB5C',
						'#ff9f7f',
						'#fb7293',
						'#E062AE',
						'#E690D1',
						'#e7bcf3',
						'#9d96f5',
						'#8378EA',
						'#96BFFF',
					],
					radius: ['20%', '70%'],
					avoidLabelOverlap: false,
					label: {
						normal: {
							show: false,
							position: 'center',
							formatter: '{d}% \n{b}',
							textStyle: {
								fontSize: '12',
							},
						},
						emphasis: {
							show: true,
							textStyle: {
								fontSize: '20',
								fontWeight: 'bold',
							},
						},
					},
					labelLine: {
						normal: {
							show: true,
						},
					},
					data: valueData,
				},
			],
		};
		return option;
	}

	render() {
		return (
			<div className="devices-list">
				<div className="row div-text-align-center">
					<div className="col-xs-12">
						<div>签到率</div>
						<canvas ref={this.signNum} width="400" height="400">
							x
						</canvas>
					</div>
				</div>
			</div>
		);
	}
}
export default Tongji;
