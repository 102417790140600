import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { FormInstance } from 'antd/es/form';
import { Button, Col, Form, Image, Input, InputNumber, Modal, TreeSelect, Upload } from 'antd';
import appConfig from '../../../appConfig';
import { Link } from 'react-router-dom';
import {
	addDemandType,
	deleteDemandType,
	findDemandType,
	findLiuchengdanName,
	updateDemandType,
} from '../../../services/operationManagementService';
import GSTable from '../../../components/GSTable';
import Api from '../../../data/API';
import ProxyForm from '../../../components/ProxyForm';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons/lib';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import WangEditor from '../../../components/WangEditor';
import API from '../../../data/API';

var msg = require('../../../util/SuspensionMsg');
const confirm = Modal.confirm;
const { TreeNode } = TreeSelect;

const DemandManage: React.FC = () => {
	useEffect(() => {
		findliuchengdan();
	}, []);

	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const wyUuid = Api.getWuyeUuid();
	const FormItem = Form.Item;
	const [visible, setVisible] = useState(false);
	const [liuchengdan, setLiuchengdan] = useState([]);
	const [add, setAdd] = useState(false);
	const [updateKey, setUpdateKey] = useState('');
	const [uuid, setUuid] = useState('');
	const [iconUrl, setIconUrl] = useState('');
	const [serviceContent, setServiceContent] = useState('');
	const [wEditorValue, setWEditorValue] = useState('');
	const columns = [
		{
			title: '名称',
			dataIndex: 'serviceName',
			key: 'serviceName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						updata(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: 'icon',
			dataIndex: 'serviceIcon',
			key: 'serviceIcon',
			render: (text: string, record: any) => (
				<Image width={50} height={50} src={appConfig.cdnRootPath + record.serviceIcon} />
			),
		},
		{
			title: '服务单',
			dataIndex: 'liuchengName',
			key: 'liuchengName',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<div>
					<span>
						<ul className="ant-list-item-action">
							<li>
								<Link
									to={{
										pathname: '/operationmanagement/liucheng-demand',
										state: { uuid: record.liucheng },
									}}
								>
									<a>处理</a>
								</Link>
								{/*<em className="ant-list-item-action-split" />*/}
							</li>
							<li>
								<a
									onClick={() => {
										deleteItem(record);
									}}
								>
									删除
								</a>
							</li>
						</ul>
					</span>
				</div>
			),
		},
	];

	const deleteItem = (value: any) => {
		confirm({
			title: '您确认要删除此服务类型吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteDemandType({
					uuid: value.uuid,
				});
				console.log('删除后获得平面图', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const updata = (value: any) => {
		setAdd(false);
		const formData: any = {
			name: value.serviceName,
			uuid: value.uuid,
			Icon: value.serviceIcon,
			serviceContent: value.serviceContent,
			order: value.sort,
			liucheng: value.liucheng,
		};
		setUpdateKey(value.serviceIcon);
		setServiceContent(value.serviceContent)
		form.setFieldsValue(formData);
		setVisible(true);
	};

	const findliuchengdan = async () => {
		const res = await findLiuchengdanName({});
		console.log('流程', res);
		if (res.err === 0) {
			setLiuchengdan(res.data);
		}
	};

	const Determine = () => {
		if (add) {
			addService();
		} else {
			updateService();
		}
	};

	const addService = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				name: data.name,
				icon: data.Icon,
				sort: data.order,
				wuyeUuid: Api.getWuyeUuid(),
				liucheng: data.liucheng,
				serviceContent: data.serviceContent,
			};
			const res = await addDemandType(formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '新增成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '新增失败', type: 'error' });
			}
			setVisible(false);
			console.log('data:::', formData);
		});
	};

	const getFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			setUpdateKey(fileList.file.response.url);
			const FormData: any = {
				Icon: fileList.file.response.url,
			};
			form.setFieldsValue(FormData);
			console.log(`${fileList.file.name}`);
			console.log('平面图', fileList.file.response.url);
		} else if (fileList.file.status === 'error') {
			msg.suspensionMsg({ content: `${fileList.file.name} 上传图片失败`, type: 'error' });
		}
	};

	const updateService = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data::', data);
			const formData: any = {
				uuid: data.uuid,
				name: data.name,
				icon: data.Icon,
				sort: data.order,
				wuyeUuid: Api.getWuyeUuid(),
				liucheng: data.liucheng,
				serviceContent: data.serviceContent,
			};
			const res = await updateDemandType(formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '更新成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '更新失败', type: 'error' });
			}
			setVisible(false);
		});
	};
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setAdd(true);
				setVisible(true);
			}}
		>
			新增
		</Button>,
	];

	const formLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 18 },
	};
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	return (
		<div>
			<GSTable
				columns={columns}
				rowKey={"uuid"}
				actionRef={actionRef}
				operationsBarRender={tableOperationsBarRender()}
				pagination={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findDemandType({
						wuyeUuid: wyUuid,
					});
					return {
						data: res.data,
						success: true,
					};
				}}
			/>
			<Modal
				title={add ? '新增' : '更新'}
				visible={visible}
				onOk={Determine}
				onCancel={() => {
					setVisible(false);
				}}
				width={900}
			>
				<Form {...formLayout} form={form}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'名称'} name={'name'} rules={[{ required: true }]}>
						<Input style={{ maxWidth: 400 }} placeholder={'请输入名称'} />
					</FormItem>
					<FormItem
						label={'Icon'}
						name={'Icon'}
						className="ant-form-item-required"
						rules={[{ required: true }]}
					>
						<Upload
							name="image"
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
							onChange={getFileName}
						>
							{updateKey !== null && updateKey !== undefined && updateKey !== '' ? (
								<img alt="avatar" style={{ width: '100%' }} src={appConfig.cdnRootPath + updateKey} />
							) : (
								uploadButton
							)}
						</Upload>
					</FormItem>
					<FormItem label={'绑定服务单'} name={'liucheng'} rules={[{ required: true }]}>
						<TreeSelect
							showSearch={true}
							style={{ maxWidth: 400 }}
							dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
							placeholder="请选择服务单"
							allowClear={true}
							treeDefaultExpandAll={true}
							treeNodeFilterProp="title"
						>
							{liuchengdan.map((item: any, index: any) => (
								<TreeNode value={item.uuid} title={item.name} key={item.uuid} />
							))}
						</TreeSelect>
					</FormItem>
					<FormItem label={'顺序'} name={'order'} rules={[{ required: true }]}>
						<InputNumber style={{ width: 112 }} placeholder="请输入" />
					</FormItem>
					<FormItem
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 20 }}
						label={'服务内容'}
						name={'serviceContent'}
						rules={[{ required: true }]}
					>
						<WangEditor
							key={serviceContent}
							value1={serviceContent}
							changeCallback={(res: any) => {
								setServiceContent(res)
								form.setFieldsValue({ serviceContent: res });
							}}
						/>
					</FormItem>
				</Form>
			</Modal>
		</div>
	);
};

export default DemandManage;
