import React, { useRef, useState, useEffect } from "react";
import GSTable from "../../../components/GSTable";
import { getFaceJobList, asyncJob } from "../../../services/FaceJobService";
import { message, Select, Button, Space, Modal, Input, TreeSelect,Tag } from "antd";
import { ActionType } from "@ant-design/pro-table";
import ReactJson from "react-json-view";
import { getAllDevicesGroup, getDevicesByAll } from "../../../services/ZhiNengMenJinService";
import AuthorizedView from "../../../util/AuthorizedView";
import GsTreeSelect from "../../../components/GsTreeSelect";

const typeEnum = {
    "add": "添加人员",
    "delete": "删除人员",
    "async": "同步人员",
    "set": "设置参数"

};
const statusEnum = {
    "0": "未接收",
    "1": "已接收"
};
const resStatusEnum = {
    "true": "成功",
    "false": "失败"
};
const onlineEnum = {
    true: { text: "在线", status: "Success" },
    false: { text: "离线", status: "Error" }
};
const FaceAsyncList: React.FC = (props: any) => {
    const { CheckableTag } = Tag;

    const column = [{
        title: "设备名称",
        key: "deviceName",
        width: 150,
        dataIndex: "deviceName"
    }, {
        title: "用户名称",
        key: "realName",
        width: 150,
        dataIndex: "realName"
    }, {
        title: "是否在线",
        key: "online",
        width: 100,
        dataIndex: "online",
        valueEnum: onlineEnum
    }, {
        title: "任务类型",
        key: "type",
        dataIndex: "type",
        width: 100,
        valueEnum: typeEnum

    }, {
        title: "任务状态",
        tooltip: "该信息表示终端设备是否接收到指令",
        key: "state",
        width: 100,
        dataIndex: "state",
        valueEnum: statusEnum

    }, {
        title: "处理状态",
        tooltip: "该信息表示终端设备是否处理成功",
        key: "resStatus",
        width: 100,
        dataIndex: "resStatus",
        render: (text: string, record: any) => {

            return <span>{text === "-" ? "未返回" : resStatusEnum[text]}</span>;
        }
    }, {
        title: "结果",
        tooltip: "该信息表示设备实际执行指令的结果",
        key: "msg",
        width: 100,
        dataIndex: "msg"

    },
        {
            title: "创建时间",
            key: "createTime",
            width: 200,
            dataIndex: "createTime",
            valueType: "dateTime"
        }, {
            title: "操作",
            key: "active",
            width: 150,
            fixed: 'right',
            render: (record: any) => {
                return <Space>
                    <a onClick={() => openInfo(record.data)}>详情</a>
                    <AuthorizedView needAuthority={"entrance_guard:face-async:async"}>
                        {record.state === "0" && <a onClick={() => sendAsyncJob(record.online, record.uuid)}>重新发送</a>}
                    </AuthorizedView>
                </Space>;
            }
        }];
    const actionRef = useRef<ActionType>();
    const [deviceId, setDeviceId] = useState("");
    // const [deviceList, setDeviceList] = useState([]);
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [visible, setVisible] = useState(false);
    const [item, setItem] = useState({});
    const [search, setSearch] = useState("");
    const [devices, setDevices] = useState([]);
    const [totalStatus, setTotalStatus] = useState<any>(false);
    const confirm = Modal.confirm;

    useEffect(() => {
        getSelectDeviceList().then();
    }, []);

    const openInfo = (data: string) => {
        try {
            setItem(JSON.parse(data));
            setVisible(true);
        } catch (e) {

        }
    };
    // const getDeviceList = async () => {
    //     let params = {
    //         typecode: "0701",
    //         wuyeid: localStorage.getItem("wyUuid")
    //     };
    //     let rsp = await getDevicesByAll(params);
    //     console.log("rsp::::", rsp);
    //     if (rsp.err === 0) {
    //         setDeviceList(rsp.data)
    //     }
    // };
    const getSelectDeviceList = async () => {
        const res = await getAllDevicesGroup({ typeCode: "0701" });
        if (res.err !== 0) {
            message.error(res.msg);
            return;
        }
        let data: any = [];
        data.push({ key: "全部设备", title: "全部设备", value: "" });
        res.data.map((item: any) => {
            item["selectable"] = false;
        });
        data = data.concat(res.data);
        setDevices(data);
    };

    const loadData = async (params: any) => {
        let data = {
            deviceId: deviceId,
            type: type,
            state: status,
            page: params.current,
            size: params.pageSize,
            search: search,
            totalStatus: totalStatus
        };
        let rsp = await getFaceJobList(data);
        if (rsp.err !== 0) {
            message.error(rsp.msg);
            return {
                data: [],
                total: 0
            };
        }
        return {
            data: rsp.data.data,
            total: rsp.data.total
        };

    };
    const sendAsyncJob = (online: boolean, uuid: string) => {
        if (!online) {
            message.warn("该设备未上线，请稍后再试。");
            return;
        }
        confirm({
            title: "提示",
            content: "您确定要重新发送该任务吗？",
            onOk: async () => {
                let data = {
                    uuid: uuid
                };
                let rsp = await asyncJob(data);
                if (rsp.err === 0) {
                    message.success(rsp.msg);
                    if (actionRef.current) {
                        actionRef.current.reload();
                    }
                } else {
                    message.error(rsp.msg);
                }
            }
        });

    };
    const tableSearchBarRender = [
        <CheckableTag
            key={"1"}
            className="faceTag"
            checked={totalStatus == null}
            onChange={() => {
                setTotalStatus(null)
            }}
        >
            全部
        </CheckableTag>,
        <CheckableTag
            key={"2"}
            className="faceTag"
            checked={totalStatus != null}
            onChange={() => {
                setTotalStatus(false)
            }}
        >
            失败
        </CheckableTag>,
        <GsTreeSelect
            treeCheckable={true}
            treeData={devices}
            treeNodeFilterProp={"title"}
            defaultValue={deviceId}
            multiple={true}
            onChange={(ev: any) => {
                let data: any = [];
                ev.map((item: string) => {
                    if (item.includes(":::")) {
                        data.push(item.split(":::")[1])
                    } else {
                        data.push(item)
                    }
                })
                setDeviceId(data.toString());
            }}
        />,
        <Select style={{ width: 200 }} defaultValue={type} onChange={(e: string) => {
            setType(e);
        }}>
            <Select.Option value="">全部类型</Select.Option>
            <Select.Option value="add">添加人员</Select.Option>
            <Select.Option value="delete">删除人员</Select.Option>
            <Select.Option value="async">同步人员</Select.Option>
            <Select.Option value="set">设置参数</Select.Option>
        </Select>,
        <Select style={{ width: 200 }} defaultValue={status} onChange={(e: string) => setStatus(e)}>
            <Select.Option value="">全部状态</Select.Option>
            <Select.Option value="1">已接收</Select.Option>
            <Select.Option value="0">未接收</Select.Option>
        </Select>,
        <Input placeholder={"用户名称，结果"} onChange={(e) => {
            setSearch(e.target.value);
        }}/>,
        <Button type={"primary"} onClick={() => {
            if (actionRef.current) {
                actionRef.current.reloadAndRest();
            }
        }}>查询</Button>
    ];
    return (
        <div>
            <GSTable
                actionRef={actionRef}
                columns={column}
                request={loadData}
                searchBarRender={tableSearchBarRender}
            />
            <Modal title={"详情"} visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
                <ReactJson src={item} name={"请求参数"}/>
            </Modal>
        </div>
    );
};
export default FaceAsyncList;
