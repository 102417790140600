import React, { useCallback, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Button, Form, Input, message, Modal, Space, Switch, Tag } from 'antd';
import {
	addEmployeeType,
	deleteEmployeeType,
	getEmployeeTypeList,
	initEmployeeType,
	setEmployeeTypeDefault,
	sortEmployeeType,
	updateEmployeeType,
} from '../../../services/EmployeeTypeService';
import { FormInstance } from 'antd/es/form';
import { DndProvider } from 'react-dnd';
import HTMLBackend from 'react-dnd-html5-backend';
import { sortHouseType } from '../../../services/ZhuhushenheService';
import DragableBodyRow from '../../../components/DragableBodyRow';
interface EmployeeTypeMamagerProps {
	onSubmit?: any;
}
const EmployeeTypeManager: React.FC<EmployeeTypeMamagerProps> = (props: any) => {
	const actionRef = useRef<ActionType>();
	const actionRef1 = useRef<ActionType>();
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [sortVisible, setSortVisible] = useState(false);
	const [sortData, setSortData] = useState([]);
	const [isSystem, setIsSystem] = useState(false);
	const columns = [
		{
			title: '选项名',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, item: any) => {
				return (
					<a
						onClick={() => {
							OpenUpdateModal(item);
						}}
					>
						{text}
					</a>
				);
			},
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '是否可用',
			dataIndex: 'state',
			key: 'state',
			render: (text: string, item: any) => {
				return <Tag color={item.state ? 'green' : 'red'}>{item.state ? '可用' : '不可用'}</Tag>;
			},
		},
		{
			title: '是否是默认值',
			dataIndex: 'defaultValue',
			key: 'defaultValue',
			render: (text: string, item: any) => {
				return <Tag color={item.defaultValue ? 'green' : 'red'}>{item.defaultValue ? '是' : '否'}</Tag>;
			},
		},
		{
			title: '操作',
			key: 'action',
			width: '20%',
			align: 'center',
			render: (text: string, record: any) => (
				<Space>
					{!record.defaultValue ? (
						<>
							<a
								onClick={() => {
									setDefaustValue(record);
								}}
							>
								设置为默认选项
							</a>
						</>
					) : (
						''
					)}

					<a
						disabled={(record.type || '') === '预置'}
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</Space>
			),
		},
	];
	const columns1 = [
		{
			title: '选项名',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '是否可用',
			dataIndex: 'state',
			key: 'state',
			render: (text: string, item: any) => {
				return <Tag color={item.state ? 'green' : 'red'}>{item.state ? '可用' : '不可用'}</Tag>;
			},
		},
		{
			title: '是否是默认值',
			dataIndex: 'defaultValue',
			key: 'defaultValue',
			render: (text: string, item: any) => {
				return <Tag color={item.defaultValue ? 'green' : 'red'}>{item.defaultValue ? '是' : '否'}</Tag>;
			},
		},
	];

	const setDefaustValue = async (item: any) => {
		Modal.confirm({
			title: '您要设置' + item.name + '为默认选项吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await setEmployeeTypeDefault({ id: item.id });

				if (res.err === 0) {
					message.success('修改成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error('修改失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const OpenUpdateModal = (item: any) => {
		const formData: any = {
			id: item.id,
			name: item.name,
			state: isNotNull(item.state) ? item.state : false,
		};
		if (item.type === '预置') {
			setIsSystem(true);
		}
		form.setFieldsValue(formData);
		setAdd(false);
		setVisible(true);
	};
	const deleteItem = (item: any) => {
		Modal.confirm({
			title: '您确定要删除' + item.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteEmployeeType({ id: item.id });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error('删除失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const tableOperationsBarRender = () => [
		<Button type={'primary'} onClick={openAddModal}>
			添加
		</Button>,
		<Button
			type="primary"
			onClick={() => {
				setSortVisible(true);
			}}
		>
			编辑排序
		</Button>,
	];
	const openAddModal = () => {
		setIsSystem(false);
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const isNotNull = (string: any) => {
		if (string !== null && string !== undefined && string !== '') {
			return true;
		} else {
			return false;
		}
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const data1: any = sortData[sortData.length - 1];
			let sort1 = 0;
			if (data1 !== null && data1 !== '' && data1 !== undefined) {
				sort1 = data1.sortBy + 1;
			}
			const formData: any = {
				name: data.name,
				state: isNotNull(data.state) ? data.state : false,
				type: '自定义',
				defaultValue: false,
				sortBy: isNotNull(sort1) ? sort1 : 0,
			};
			const res = await addEmployeeType(formData);
			if (res.err === 0) {
				message.success('添加成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('添加失败');
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				id: data.id,
				name: data.name,
				state: isNotNull(data.state) ? data.state : false,
				type: '自定义',
			};
			const res = await updateEmployeeType(formData);
			if (res.err === 0) {
				message.success('修改成功');
				setVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('修改失败');
			}
		});
	};
	const sortDataItem = async () => {
		console.log('排序：：：', '排序');
		const data = sortData;
		let ids: any = [];
		data.map((item: any) => {
			ids.push(item.id);
		});
		console.log('ids', ids);
		const res = await sortEmployeeType({ ids: ids.toString() });
		if (res.err === 0) {
			setSortVisible(false);
			message.success('排序成功');
			actionRef.current.reload();
		} else {
			message.error('排序失败');
		}
	};
	const moveRow = useCallback(
		async (dragIndex, hoverIndex, key) => {
			if (dragIndex === hoverIndex) {
				return;
			}
			console.log('dragIndex', dragIndex);
			console.log('hoverIndex', hoverIndex);
			let route = [...sortData];
			const d1 = route[dragIndex];
			const ho = route[hoverIndex];
			console.log('data::', d1, ho);
			let tmp = route[dragIndex]; //临时储存文件
			route.splice(dragIndex, 1); //移除拖拽项
			route.splice(hoverIndex, 0, tmp); //插入放置项
			setSortData([...route]);
			// findArray(routes, key, dragIndex, hoverIndex);
			actionRef1.current.reload();
		},
		[sortData]
	);
	const components = {
		body: {
			row: DragableBodyRow,
		},
	};
	const getData = () => {
		return {
			data: sortData,
		};
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				scroll={{
					x: 800
				}}
				// pagination={false}
				operationsBarRender={tableOperationsBarRender()}
				defaultExpandAllRows={true}
				request={async (params: any = {}) => {
					const res = await getEmployeeTypeList({});
					console.log('res.data', res.data);
					if (res.err === 0) {
						if (props.onSubmit) {
							props.onSubmit(res.data, res.defaultValue);
						}
						setSortData(res.data);
						return {
							data: res.data,
							success: true,
						};
					} else {
						setSortData([]);
						return {
							data: [],
						};
					}
				}}
			/>
			<Modal
				title={add ? '添加' : '修改'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={saveItem}
			>
				<ProxyForm form={form}>
					<FormItem name={'id'} hidden={true} />
					<FormItem name={'name'} label={'选项名'} rules={[{ required: true }]}>
						<Input disabled={isSystem} />
					</FormItem>
					<FormItem name={'state'} label={'是否可用'} valuePropName={'checked'}>
						<Switch />
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				key={sortData.toString()}
				visible={sortVisible}
				width={800}
				title={'排序'}
				onCancel={() => {
					setSortVisible(false);
				}}
				onOk={sortDataItem}
			>
				<DndProvider backend={HTMLBackend}>
					<GSTable
						rowSelection={false}
						scroll={{
							x: 800
						}}
						options={false}
						actionRef={actionRef1}
						components={components}
						onRow={(record: any, index: any) => ({
							index,
							moveRow,
						})}
						columns={columns1}
						request={getData}
						pagination={false}
					/>
				</DndProvider>
			</Modal>
		</>
	);
};

export default EmployeeTypeManager;
