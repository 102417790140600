import * as React from 'react';
import { Col, Input, Row, Select } from 'antd';
class Price {
	name: string;
	value: string;
}
export default class CustomPriceInput extends React.Component {
	props: any;
	state: any;

	static getDerivedStateFromProps(nextProps: any) {
		if ('value' in nextProps) {
			return {
				...(nextProps.value || { data: [new Price(), new Price(), new Price()] }),
			};
		}
		return null;
	}
	constructor(props: any) {
		super(props);
		this.props = props;
		const value = props.value || { data: [new Price(), new Price(), new Price()] };
		this.state = {
			data: [new Price(), new Price(), new Price()],
		};
	}

	triggerChange = (changedValue: any) => {
		const { onChange } = this.props as any;
		if (onChange) {
			onChange(Object.assign({}, this.state, changedValue));
		}
	};

	onChange = (index: number, key: string, e: any) => {
		let data = this.state.data;
		data[index][key] = e.target.value;
		this.setState({
			data,
		});
		this.triggerChange({ data: data });
	};

	render() {
		return (
			<div>
				<Row gutter={8} type={'flex'} justify={'center'} align={'middle'} style={{ marginTop: 0 }}>
					<Col span={11}>
						<Input
							style={{ width: '100%' }}
							value={this.state.data[0].name || ''}
							onChange={this.onChange.bind(this, 0, 'name')}
						/>
					</Col>
					<Col span={2}>
						<span style={{ display: 'flex', justifyContent: 'center' }}>---</span>
					</Col>
					<Col span={11}>
						<Input
							style={{ width: '100%' }}
							value={this.state.data[0].value || ''}
							onChange={this.onChange.bind(this, 0, 'value')}
						/>
					</Col>
				</Row>
				<Row gutter={8} type={'flex'} justify={'center'} align={'middle'}>
					<Col span={11}>
						<Input
							style={{ width: '100%' }}
							value={this.state.data[1].name || ''}
							onChange={this.onChange.bind(this, 1, 'name')}
						/>
					</Col>
					<Col span={2}>
						<span style={{ display: 'flex', justifyContent: 'center' }}>---</span>
					</Col>
					<Col span={11}>
						<Input
							style={{ width: '100%' }}
							value={this.state.data[1].value || ''}
							onChange={this.onChange.bind(this, 1, 'value')}
						/>
					</Col>
				</Row>
				<Row gutter={8} type={'flex'} justify={'center'} align={'middle'}>
					<Col span={11}>
						<Input
							style={{ width: '100%' }}
							value={this.state.data[2].name || ''}
							onChange={this.onChange.bind(this, 2, 'name')}
						/>
					</Col>
					<Col span={2}>
						<span style={{ display: 'flex', justifyContent: 'center' }}>---</span>
					</Col>
					<Col span={11}>
						<Input
							style={{ width: '100%' }}
							value={this.state.data[2].value || ''}
							onChange={this.onChange.bind(this, 2, 'value')}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}
