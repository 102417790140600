import Api from '../data/API';

export async function list(params: any) {
    return Api.postWithAuth('/admin/api/whitePaper/list', params);
}
export async function add(params: any) {
    return Api.postWithAuth('/admin/api/whitePaper/add', params);
}
export async function updateWhitePaper(params: any) {
    return Api.postWithAuth('/admin/api/whitePaper/update', params);
}
export async function deleteWhitePaper(params: any) {
    return Api.postWithAuth('/admin/api/whitePaper/delete', params);
}
