import * as React from 'react';
import DAL from '../../data/DAL';
import Api from '../../data/API';
import MyUeditor from '../../components/MyUeditor';
import { Breadcrumb, Row, Col, Input, Upload, Button, Modal, Checkbox } from 'antd';
import { HomeOutlined, UploadOutlined } from '@ant-design/icons';
import widget from '../../widget/Widget';
import WidgetComponents from '../../widget/WidgetComponents';
import LiuchengWangEditor from '../../components/LiuchengWangEditor';
import WangEditor from '../../components/WangEditor';
import { editQuestionnaire, findQuestionnaireDetails } from '../../services/QuestionnaireService';
import InputTime from '../../js/InputTime';
const kuozhan = InputTime.inputTime;
var msg = require('../../util/SuspensionMsg');
var relType = [
	{ name: '姓名', type: 'name' },
	{ name: '手机号', type: 'phoneNumber' },
	{
		name: '身份证号',
		type: 'idCard',
	},
	{ name: '性别', type: 'sex' },
	{ name: '住址', type: 'address' },
	{ name: '出生日期', type: 'dateOfBirth' },
	{
		name: '房间号',
		type: 'roomNumber',
	},
	{ name: '户口所在地', type: 'registeredResidence' },
	{ name: '公司', type: 'company' },
	{
		name: '部门',
		type: 'bumen',
	},
	{ name: '职位', type: 'position' },
	/*{name: '电话', type: 'phone'},*/ { name: '网址', type: 'URL' },
	{ name: '项目编号', type: 'projectNumber' },
	{ name: '用户UUID', type: 'userUuid' },
	{ name: '团队类型', type: 'teamType' },
	{ name: '产业方向', type: 'industrialDirection' },
];

const confirm = Modal.confirm;
class AddQuestionnaire extends React.Component {
	wid = new widget(1);
	dal = DAL.dal;
	UE1 = (window as any).UE;
	allData: any = [];
	isUpdata = false;

	constructor(props: any) {
		super(props);
		this.state = {
			isShow: false,
			userId: '',
			questionnaireBiaoti: '',
			questionnaireXiangqing: '',
			fengmianTu: '',
			isDelete: '',
			Customizing: false,
			date: [''],
			questionnaireUuid: '',
			huodongFormUuid: '',
			state: '',
			provinces: [],
			counties: [],
			areaName: '',
			areaParentId: '',
			isHidde: false,
			isCheck: '',
			buttomPostion: -1, // 用来标记点击选择button的下标
			ziduan: [],
			widget: [],
			substance: [],
			relTypes: relType, //关联选择属性
			widgetType: '',
			widgetName: '',
			descr: '', //描述
			aliasName: '', //别名
			field: '', //扩展字段key
			field1: '', //扩展字段value
			attributeValue: { name: '选择关联属性', type: '' }, //属性值，只有关联组件才会有
			addWidgetState: false,
			select: false,
			nature: true,
			buttonX: 100, //点击添加字段button x 坐标
			buttonY: 100, //点击添加字段button Y 坐标
			dangqian_jiedian_index: 0,
			dangqian_jiedian_name: '',
			dangqian_jiedian_miaoshu: '',
			shifoubixuan: false, //是否是必须选择
			liebiaoxianshi: false, //是否在列表显示
			yonghujujue: false, //用户是否可以拒绝
			morenzhi: '', //默认值
			allow_edit: true, //在关联字段中设置用户是否可以修改里面的数据
			is_hide: false, //是否隐藏
			is_newDate: false, //是否默认当前时间
			deleteJiedianId: 0, //点击删除节点的ID
			deleteZiduan: '0', //点击删除字段，分别是节点顺序和字段顺序
			updataZiduan: -1, //更新字段的位置，-1代表没有更新位置
			selectButtonValue: '', //添加多选按钮获得单选按钮的时候，默认值
			selectButtonNumber: ['默认值', '默认值'], //设置默认单选框获得多选框的个数
			OrderBuy: [{ name: '节点：0', uuid: 0 }],
			isUpdata: false, //是更新流程还是新建立一个流程
			isHideReviewer: false, //是否隐藏审核人信息
			liuchengdan_type: '', //流程单的类型，默认为空值
			extendedField: ['', ''], //扩展字段
			requestethodm: '',
			choiceState: '',
			choiceValue: '',
			splicingSql: '',
			widgetText: '',
			show: false,
		};
		this.getData = this.getData.bind(this);
		this.getQuestionnaireBiaoti = this.getQuestionnaireBiaoti.bind(this);
		this.addFabu = this.addFabu.bind(this);
		this.getHtmls = this.getHtmls.bind(this);
		this.getpostion = this.getpostion.bind(this);
		this.addWidget = this.addWidget.bind(this);
		this.cancelAddConfirmWidget = this.cancelAddConfirmWidget.bind(this);
		this.cleanWidget = this.cleanWidget.bind(this);
		this.shifoubixuan = this.shifoubixuan.bind(this);
		this.getWidgetName = this.getWidgetName.bind(this);
		this.callback = this.callback.bind(this);
		this.confirmWidget = this.confirmWidget.bind(this);
		this.updataZiduan = this.updataZiduan.bind(this);
		this.deleteZiduan = this.deleteZiduan.bind(this);
		this.querenDeleteZiduan = this.querenDeleteZiduan.bind(this);
		this.shubiaoPsition = this.shubiaoPsition.bind(this);
		this.associationAttributes = this.associationAttributes.bind(this);
		this.morenzhi = this.morenzhi.bind(this);
		this.selectButtonValue = this.selectButtonValue.bind(this);
		this.addSelectItem = this.addSelectItem.bind(this);
		this.is_newDate = this.is_newDate.bind(this);
	}

	componentDidMount() {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		if (uuid !== null && uuid !== undefined && uuid !== '') {
			this.getData();
			this.isUpdata = true;
			this.setState({
				questionnaireUuid: uuid,
			});
		}
	}

	getData = async () => {
		let ser = window.location.search;
		let sp = ser.split('=');
		if (sp[1] === undefined) {
			msg.suspensionMsg({ content: 'uuid异常', type: 'danger' });
			return;
		}
		const res = await findQuestionnaireDetails({
			uuid: sp[1],
		});
		// Api.postWithAuth('/api/tuwen/find_questionnaire_details', {
		//     uuid: sp[1]
		// }).then(res => {
		console.log('获得详细信息', res.data);
		if (res.err === 0) {
			let da = res.data;
			this.setState({
				questionnaireBiaoti: da.questionnaireBiaoti,
				fengmianTu: da.fengmianTu,
				questionnaireCreationDate: da.questionnaireCreationDate,
				questionnaireXiangqing: da.questionnaireXiangqing,
				questionnaireInitiator: da.questionnaireInitiator,
				state: da.state,
				submissionNumberPeople: da.submissionNumberPeople,
				ziduan: da.questionnaireJiegou,
			});
			if (
				da.questionnaireXiangqing !== null &&
				da.questionnaireXiangqing !== '' &&
				da.questionnaireXiangqing !== undefined
			) {
				// let that = this;
				// setTimeout(
				//     function () {
				//         that.UE1.getEditor('myDivContainer').setContent(da.questionnaireXiangqing);
				//     },
				//     500);
			}
		} else {
			msg.suspensionMsg({ content: res.msg });
		}

		// });
	};

	addFabu = async (start: string) => {
		let sta = this.state as any;
		this.setState({
			state: start,
		});
		if (sta.questionnaireBiaoti === null) {
			msg.suspensionMsg({ content: '标题不能为空', type: 'danger' });
			return;
		}
		const res = await editQuestionnaire({
			questionnaireUuid: (this.state as any).questionnaireUuid,
			questionnaireXiangqing: sta.questionnaireXiangqing,
			questionnaireBiaoti: (this.state as any).questionnaireBiaoti,
			state: start,
			fengmianTu: (this.state as any).fengmianTu,
			data: JSON.stringify((this.state as any).ziduan),
		});
		// Api.postWithAuth('/api/tuwen/edit_questionnaire', {
		//     'questionnaireUuid': (this.state as any).questionnaireUuid,
		//     'questionnaireXiangqing': sta.questionnaireXiangqing,
		//     'questionnaireBiaoti': (this.state as any).questionnaireBiaoti,
		//     'state': start,
		//     'fengmianTu': (this.state as any).fengmianTu,
		//     'data': JSON.stringify((this.state as any).ziduan),
		// }).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: '成功', type: 'success' });
			window.location.href = '/questionnaire';
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
		// });
	};

	getHtmls(e: any) {
		this.setState({
			questionnaireXiangqing: e,
		});
	}

	getFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			this.setState({
				fengmianTu: fileList.file.response.url,
			});
		} else if (fileList.file.status === 'error') {
			msg.suspensionMsg({ content: `${fileList.file.name} 上传图片失败`, type: 'error' });
		}
	};

	getQuestionnaireBiaoti(ev: any) {
		this.setState({
			questionnaireBiaoti: ev.target.value,
		});
	}

	/**
	 * 获得点击buttom 的位置
	 *
	 */
	getpostion(res: any) {
		this.setState({
			buttomPostion: res.target.id,
			typePosition: res.target.id,
		});
	}

	/**
	 * 点击确认添加组件
	 */
	confirmWidget() {
		this.setState({
			show: false,
		});
		kuozhan.getData('kuozhan', this.callback);
	}

	callback(data: any) {
		console.log('扩展字段', data.kuozhan + '');
		//获得state对象
		var st = this.state as any;
		// //descr
		// let ue = (window as any).UE;
		// st.descr = ue.getEditor('Mycontainer').getContent();
		// //获得组件类型
		var widgetType = (this.state as any).widgetType;
		//判断该组件是否是关联组件
		if (widgetType === 'rel') {
			//是关联组件判断是否已经关联字段
			var attributeValue = (this.state as any).attributeValue;
			if (attributeValue.type === '') {
				alert('请选择关联属性');
				return;
			}
		}
		//把信息保存到数组,还有其他字段后面在更改
		//必须放在这个每一次需要重新创建，不然会被覆盖
		var zd = {
			ziduan_name: '未命名',
			ziduan_type: 'text',
			ziduan_order: -1,
			is_required: false,
			select_value: '',
			select_limit: 0,
			guanlian_ziduan: '',
			ziduan_miaoshu: '',
			morenzhi: '',
			allow_edit: true,
			is_hide: false,
			is_newDate: false,
		};
		//赋值
		zd.ziduan_name = st.widgetName;
		zd.ziduan_miaoshu = st.descr;
		zd.ziduan_type = widgetType;
		//获得节点字段对象行
		//获得节点的字段
		var ziduan = (this.state as any).ziduan;
		//获得字段的个数， 当order为-1代表新添加一个
		if (zd.ziduan_order === -1) {
			zd.ziduan_order = ziduan.length;
		}
		zd.is_required = st.shifoubixuan;
		//判断是否是多选或者单选，获得默认值不一样
		if (st.widgetType === 'radio' || st.widgetType === 'checkbox') {
			zd.morenzhi = st.selectButtonNumber.toString();
		} else {
			zd.morenzhi = st.morenzhi;
		}
		zd.allow_edit = st.allow_edit;
		zd.is_hide = st.is_hide;
		zd.is_newDate = st.is_newDate;
		zd.guanlian_ziduan = (this.state as any).attributeValue.type;
		/**
		 * 判断字段位置是否为-1如果是-1代表添加一个字段，如果不是服役代表更新指定位置的字段
		 *
		 */
		if (st.updataZiduan === -1) {
			ziduan.push(zd);
		} else {
			ziduan[st.updataZiduan] = zd;
			st.updataZiduan = -1;
		}
		this.setState({
			select: (this.state as any).select,
		});
		this.cleanWidget();
	}

	/**
	 * 点击取消添加组件
	 */
	cancelAddConfirmWidget() {
		this.setState({
			show: false,
		});
		this.cleanWidget();
	}

	/**
	 * 取消添加组件/添加组件
	 * @returns {any}
	 */
	cleanWidget() {
		this.setState({
			addWidgetState: false,
			select: false,
			nature: true,
			widgetType: '', //取出的各种值也初始化
			widgetName: '',
			descr: '',
			attributeValue: { name: '选择关联属性', type: '' },
			dangqian_jiedian_name: '',
			dangqian_jiedian_miaoshu: '',
			shifoubixuan: false,
			morenzhi: '',
			allow_edit: true,
			is_hide: false,
			is_newDate: false,
			selectButtonNumber: ['默认值', '默认值'],
			extendedField: ['', ''], //扩展字段
		});
		// (window as any).UE.getEditor('Mycontainer').setContent('', false);
	}

	/**
	 * 获得删除字段的标记
	 * @returns {any}
	 */
	deleteZiduan(res: any) {
		let that = this;
		console.log('获得要删除字段的ID', res.target.id);
		this.setState({
			deleteZiduan: res.target.id,
		});
		confirm({
			title: '您确认要删除该字段吗？',
			onOk() {
				that.querenDeleteZiduan();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	/**
	 * 点击确认删除字段
	 * @returns {any}
	 */
	querenDeleteZiduan() {
		let st = this.state as any;
		let ziduanId = st.deleteZiduan;
		let pt = st.ziduan;
		pt.splice(ziduanId, 1);
		pt.map((item: any, index: any) => (item.order = index));
		this.setState({
			ziduan: pt,
		});
	}

	/**
	 * 点击更新组件
	 * @returns {any}
	 */
	updataZiduan(res: any) {
		console.log('点击更新', res.target.id);
		let st = this.state as any;
		let split = res.target.id;
		//
		let pt = st.ziduan;
		let ziduanInfo = pt[split];
		var moren = [];
		if (ziduanInfo.ziduan_type === 'radio' || ziduanInfo.ziduan_type === 'checkbox') {
			var mo = ziduanInfo.morenzhi.split(',');
			for (var i = 0; i < mo.length; i++) {
				moren.push(mo[i]);
			}
		}
		// var moren = [];
		// var mo = ziduanInfo.morenzhi.split(',');
		// for (var i = 0; i < mo.length; i++) {
		//     moren.push(mo[i]);
		// }
		this.setState({
			select: true,
			addWidgetState: true,
			updataZiduan: split, //保存更新字段的位置
			widgetName: ziduanInfo.ziduan_name, //获得原有的名字
			descr: ziduanInfo.ziduan_miaoshu, //原有描述值
			morenzhi: ziduanInfo.morenzhi, //原有描述值
			shifoubixuan: ziduanInfo.is_required, //原有必添值
			selectButtonNumber: moren,
		});
		//.getEditor('Mycontainer').setContent(ziduanInfo.ziduan_miaoshu, false);
	}

	/**
	 * 点击添加控件
	 */
	addWidget(res: any) {
		console.log('点击添加组件,获得的节点ID' + res.target.id);
		this.setState({
			select: true,
			updataZiduan: -1,
			addWidgetState: true,
		});
	}

	/**
	 * 点击选择好的控件
	 */
	selectButton(res: any) {
		this.setState({
			widgetType: res.type,
			select: false,
			nature: false,
			widgetText: res.name,
			show: true,
		});
	}

	/**
	 * 获得组件的名字
	 */
	getWidgetName(res: any) {
		console.log('组件的名称', res.target.value);
		this.setState({
			widgetName: res.target.value,
		});
	}

	/**
	 * 获得描述的
	 */
	describe(res: any) {
		console.log('描述的信息', res.target.value);
		this.setState({
			descr: res.target.value,
		});
	}

	/**
	 * 获得得单选或者多选按钮的值
	 * @returns {any}
	 */
	selectButtonValue(res: any) {
		let st = this.state as any;
		st.selectButtonNumber[res.target.id] = res.target.value;
		this.setState({
			selectButtonNumber: st.selectButtonNumber,
		});
		console.log('获得值的ID', st.selectButtonNumber.toString());
	}

	/**
	 * 删除单选或者多选button
	 * @returns {any}
	 */
	deleteSelectButton = (res: any) => {
		let st = this.state as any;
		if (st.selectButtonNumber.length <= 2) {
			alert('最少保留两个选项');
			return;
		}
		st.selectButtonNumber.splice(res.target.id, 1);
		this.setState({
			selectButtonNumber: st.selectButtonNumber,
		});
	};

	/**
	 * 添加单选或者多框的个数
	 * @returns {any}
	 */
	addSelectItem() {
		let st = this.state as any;
		st.selectButtonNumber.push('默认值');
		this.setState({
			selectButtonNumber: st.selectButtonNumber,
		});
	}

	/**
	 * 获得默认值
	 * @param res
	 */
	morenzhi(res: any) {
		console.log('获得默认值', res.target.value);
		this.setState({
			morenzhi: res.target.value,
		});
	}

	/**
	 * 点击必选按钮
	 * @param res
	 */
	shifoubixuan(res: any) {
		console.log('必选按钮', res.target.checked);
		this.setState({
			shifoubixuan: res.target.checked,
		});
	}

	/**
	 * 关联属性值的选择
	 */
	associationAttributes(res: any) {
		console.log('获得的关联属性值', res.target.id);
		var split = res.target.id.split(',');
		var vaule = { name: split[0], type: split[1] };
		this.setState({
			attributeValue: vaule,
		});
	}

	/**
	 * 获得关联字段是否可以修改
	 */
	isUpdataValue(res: any) {
		console.log('获得是否允许更改', res.target.value);
		this.setState({
			allow_edit: res.target.checked,
		});
	}

	/**
	 * 关联字段是否可以隐藏
	 * @param res
	 */
	isHide(res: any) {
		this.setState({
			is_hide: res.target.value === 'true' ? false : true,
		});
	}

	is_newDate(res: any) {
		this.setState({
			is_newDate: res.target.checked,
		});
	}

	/**
	 * 获得鼠标点击的位置
	 * @returns {any}
	 */
	shubiaoPsition(res: any) {
		console.log('鼠标点击事件', res.pageY);
		console.log('滚动距离', res.scrollX);
		let x = res.pageX + res.clientX;
		let y = res.pageY;
		if ((this.state as any).addWidgetState) {
			return;
		}
		this.setState({
			buttonX: x,
			buttonY: y,
		});
	}

	render() {
		return (
			<div className="content" onMouseDown={this.shubiaoPsition}>
				<Breadcrumb>
					<Breadcrumb.Item href="/questionnaire">
						{/*<Icon type="home"/>问卷调查*/}
						<HomeOutlined />
						问卷调查
					</Breadcrumb.Item>
					<Breadcrumb.Item>{this.isUpdata ? '编辑问卷' : '创建问卷'}</Breadcrumb.Item>
				</Breadcrumb>
				<br />
				<Row gutter={8} type="flex" justify="start" align={'middle'}>
					<Col span={4} style={{ textAlign: 'right' }}>
						标题：
					</Col>
					<Col span={10}>
						<Input
							value={(this.state as any).questionnaireBiaoti}
							name="QuestionnaireBiaoti"
							onInput={this.getQuestionnaireBiaoti}
						/>
					</Col>
				</Row>
				<br />
				{(this.state as any).fengmianTu === '' ? null : (
					<Row gutter={8} type="flex" justify="start" align={'middle'}>
						<Col offset={4} span={4}>
							<img
								className="homePage"
								src={Api.cdnRootPath + (this.state as any).fengmianTu}
								width={100}
								height={70}
							/>
						</Col>
					</Row>
				)}
				<br />
				<Row gutter={8} type="flex" justify="start" align={'middle'}>
					<Col span={4} style={{ textAlign: 'right' }}>
						上传封面图：
					</Col>
					<Col span={10}>
						<Upload
							key={(this.state as any).uuid}
							name="file"
							listType={'picture'}
							data={{ wuye_uuid: localStorage.getItem('wyUuid') }}
							action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
							onChange={this.getFileName}
							accept={'.jpg,.png'}
						>
							<Button>
								<UploadOutlined /> 上传封面
								{/*<Icon type="upload"/> 上传封面*/}
							</Button>
						</Upload>
					</Col>
				</Row>
				<br />
				<Row gutter={8} type="flex" justify="start" align={'middle'}>
					<Col span={4} style={{ textAlign: 'right' }}>
						详情：
					</Col>
					<Col span={18}>
						{/*<MyUeditor title={'调查问卷'} descr={''} uuid={''} initialValue={''} required={''} width={''} height={document.body.scrollHeight / 3}/>*/}
						<WangEditor
							key={(this.state as any).questionnaireXiangqing}
							value1={(this.state as any).questionnaireXiangqing}
							changeCallback={(value: string) => {
								this.setState({ questionnaireXiangqing: value });
							}}
						/>
					</Col>
				</Row>
				<br />
				<Row gutter={8} type="flex" justify="start" align={'middle'}>
					<Col span={4} style={{ textAlign: 'right' }}>
						调查信息：
					</Col>
					<Col span={18}>
						{/*获得所有组件*/}
						{(this.state as any).ziduan.map((item: any, index: any) => (
							//判断是否添加审核字段，如果是审核字段直接添加审核的组件
							<Row type="flex" justify="start" align={'middle'}>
								<Col span={9}>
									{this.wid.selectWidget(
										'',
										item.ziduan_type,
										'100%',
										35,
										item.ziduan_name,
										item.ziduan_miaoshu,
										[],
										index,
										item.is_required ? 'required' : '',
										item.morenzhi,
										false,
										'',
										item.aliasName,
										'',
										false,
										true
									)}
								</Col>
								<Col span={9}>
									<Button
										ghost={true}
										style={{ marginTop: 10, marginLeft: 20, color: 'red' }}
										id={index}
										onClick={this.deleteZiduan}
									>
										删除
									</Button>
									<Button
										ghost={true}
										onClick={this.updataZiduan}
										style={{ marginTop: 10, marginLeft: 20, color: '#0288d1' }}
										id={index}
									>
										更新
									</Button>
								</Col>
							</Row>
						))}
					</Col>
				</Row>
				<Row gutter={8} type="flex" justify="center" align={'middle'}>
					<Col>
						<Button
							size={'large'}
							type={'primary'}
							onMouseDown={this.addWidget}
							hidden={(this.state as any).addWidgetState}
						>
							添加组件
						</Button>
					</Col>
				</Row>
				<br />
				<Row gutter={8} type="flex" justify="center" align={'middle'}>
					<Col offset={2} span={2}>
						<Button type={'primary'} onClick={this.addFabu.bind(this, '进行中')}>
							发布
						</Button>
					</Col>
					<Col offset={4} span={2}>
						<Button onClick={this.addFabu.bind(this, '暂存中')}>暂存</Button>
					</Col>
				</Row>
				<br />
				<Modal
					title="选择组件"
					visible={(this.state as any).select}
					footer={null}
					onCancel={() => {
						this.setState({ select: false, addWidgetState: false });
					}}
				>
					<Row justify="center" align={'middle'}>
						<Col span={24}>
							<WidgetComponents clickCallback={(res: any) => this.selectButton(res)} />
						</Col>
					</Row>
				</Modal>
				<Modal
					width={900}
					style={{ zIndex: 10000000 }}
					title={(this.state as any).widgetText}
					visible={(this.state as any).show}
					onCancel={this.cancelAddConfirmWidget}
					onOk={this.confirmWidget}
				>
					<Row gutter={8} type="flex" justify="start" align={'middle'}>
						<Col span={4} style={{ textAlign: 'right' }}>
							名字:
						</Col>
						<Col>
							<Input value={(this.state as any).widgetName} onInput={this.getWidgetName} />
						</Col>
					</Row>
					<br />
					<Row gutter={8} type="flex" justify="start" align={'middle'}>
						<Col span={4} style={{ textAlign: 'right' }}>
							描述:
						</Col>
						<Col span={17}>
							<LiuchengWangEditor
								key={(this.state as any).descr}
								value={(this.state as any).descr}
								changeCallback={(value: string) => {
									this.setState({ descr: value });
								}}
							/>
						</Col>
					</Row>
					<br />
					{/*这里判断是否是单选或者多选按钮*/}
					{(this.state as any).widgetType === 'radio' || (this.state as any).widgetType === 'checkbox' ? (
						<div>
							{(this.state as any).selectButtonNumber.map((item: any, index: any) => (
								<Row gutter={8} type="flex" justify="center" align={'middle'} style={{ marginTop: 10 }}>
									<Col span={4} style={{ textAlign: 'right' }}>
										<Input onInput={this.selectButtonValue} id={index} value={item} />
									</Col>
									<Col span={5}>
										<Button type={'primary'} id={index} onClick={this.deleteSelectButton}>
											删除
										</Button>
									</Col>
								</Row>
							))}
							<Row gutter={8} type="flex" justify="center" align={'middle'} style={{ marginTop: 10 }}>
								<Col>
									<Button type={'primary'} onClick={this.addSelectItem}>
										添加选项
									</Button>
								</Col>
							</Row>
						</div>
					) : (
						<Row gutter={8} type="flex" justify="start" align={'middle'} style={{ padding: 5 }}>
							<Col span={4} style={{ textAlign: 'right' }}>
								默认值：
							</Col>
							<Col span={6}>
								<Input value={(this.state as any).morenzhi} onInput={this.morenzhi} />
							</Col>
						</Row>
					)}
					<br />
					<Row gutter={8} type="flex" justify="start" align={'middle'}>
						<Col span={4} style={{ textAlign: 'right' }}>
							是否必填：
						</Col>
						<Col span={3}>
							<Checkbox checked={(this.state as any).shifoubixuan} onChange={this.shifoubixuan} />
						</Col>
					</Row>
					<br />
					<Row
						gutter={8}
						type="flex"
						justify="start"
						align={'middle'}
						hidden={(this.state as any).widgetType !== 'rel'}
					>
						<Col span={4} style={{ textAlign: 'right' }}>
							是否允许更改：
						</Col>
						<Col>
							<Checkbox checked={(this.state as any).allow_edit} onChange={this.isUpdataValue} />
						</Col>
					</Row>
					<Row
						gutter={8}
						type="flex"
						justify="start"
						align={'middle'}
						hidden={(this.state as any).widgetType !== 'dateTime'}
					>
						<Col span={4} style={{ textAlign: 'right' }}>
							是否允许更改：
						</Col>
						<Col span={3}>
							<Checkbox checked={(this.state as any).allow_edit} onChange={this.isUpdataValue} />
						</Col>
					</Row>
					<Row
						gutter={8}
						type="flex"
						justify="start"
						align={'middle'}
						hidden={(this.state as any).widgetType !== 'date'}
					>
						<Col span={4} style={{ textAlign: 'right' }}>
							是否允许更改：
						</Col>
						<Col span={3}>
							<Checkbox checked={(this.state as any).allow_edit} onChange={this.isUpdataValue} />
						</Col>
					</Row>
					<br />
					<Row
						gutter={8}
						type="flex"
						justify="start"
						align={'middle'}
						hidden={(this.state as any).widgetType !== 'dateTime'}
					>
						<Col span={4} style={{ textAlign: 'right' }}>
							<div>是否默认当前时间：</div>
						</Col>
						<Col span={5}>
							<Checkbox checked={(this.state as any).is_newDate} onChange={this.is_newDate} />
						</Col>
					</Row>
					<Row
						gutter={8}
						type="flex"
						justify="start"
						align={'middle'}
						hidden={(this.state as any).widgetType !== 'date'}
					>
						<Col span={4} style={{ textAlign: 'right' }}>
							<div>是否默认当前时间：</div>
						</Col>
						<Col span={5}>
							<Checkbox checked={(this.state as any).is_newDate} onChange={this.is_newDate} />
						</Col>
					</Row>
				</Modal>
			</div>
		);
	}
}

export default AddQuestionnaire;
