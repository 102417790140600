import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import {
	findSmsRecord,
	findSmsSendDetailListByBatchId,
	getSmsParams,
	payNotice,
} from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Input, message, Modal, Select, Space, Spin, Tag } from 'antd';
import * as moment from 'moment';
import * as XLSX from "xlsx";
interface SmsDetailPro {
	batchId: any;
}
const SmsDetail: React.FC<SmsDetailPro> = (props: any) => {
	const [loading, setloading] = useState(false)
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const [quanXuan, setquanXuan] = useState(false)
	const [sendPhone, setSendPhone] = useState('');
	const [sendStatus, setSendStatus] = useState('');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [total, settotal] = useState(0)
	const columns = [
		{
			title: '手机号',
			dataIndex: 'sendPhone',
			key: 'sendPhone',
		},
		{
			title: '发送内容',
			dataIndex: 'sendContent',
			key: 'sendContent',
			ellipsis: true,
			width: '50%',
		},
		{
			title: '接收时间',
			dataIndex: 'sendTime',
			key: 'sendTime',
			width: 150,
			render: (text: string, record: any) => (
				<span>
					{record.sendTime !== undefined && record.sendTime !== '' && record.sendTime !== null
						? moment(text).format('YYYY-MM-DD HH:mm:ss')
						: '-'}
				</span>
			),
		},
		{
			title: '状态',
			key: 'sendStatus',
			dataIndex: 'sendStatus',
			render: (text: string, record: any) => (
				<Tag color={text === 'SUCCESS' ? 'green' : 'red'}>
					{text === 'SUCCESS' ? '成功' : text === 'FAIL' ? '失败' : ''}
				</Tag>
			),
		},
		{
			title: '描述',
			key: 'remark',
			dataIndex: 'remark',
		},
	];

	const tableSearchBarRender = () => [
		<Select
			defaultValue={sendStatus}
			onChange={(value) => {
				setSendStatus(value);
				actionRef.current.reloadAndRest();
			}}
		>
			<Select.Option key={''} value={''}>
				全部
			</Select.Option>
			<Select.Option key={'SUCCESS'} value={'SUCCESS'}>
				成功
			</Select.Option>
			<Select.Option key={'FAIL'} value={'FAIL'}>
				失败
			</Select.Option>
		</Select>,
		<Search
			placeholder="手机号"
			onSearch={(value) => {
				setSendPhone(value);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
			enterButton={true}
		/>,
	];
	const sendMessage = async (phones: any) => {
		setloading(true)
		const paramsRes = await getSmsParams({ batchUuid: props.batchId });
		if (paramsRes.err == 0) {
			console.log('paramsRes', paramsRes);
			let data = {
				wyUuid: localStorage.getItem('wyUuid'),
				inputInfo: paramsRes.data.smsParams,
				inputPhone: phones.toString(),
				userPhone: '[]',
				noticeType: paramsRes.data.templateType,
			};
			const res = await payNotice(data);
			if ((res as any).err === 0) {
				setloading(false)
				message.success((res as any).msg);
			} else {
				setloading(false)
				message.error('发送结果' + (res as any).msg);
			}
		} else {
			setloading(false)
			message.error(paramsRes.msg);
		}
	};
	const refSendSms = async (phones: any) => {
		Modal.confirm({
			title: '重发',
			content: '您确定要重发吗？',
			onOk: async () => {
				if (quanXuan) {
					setloading(true)
					const res = await findSmsSendDetailListByBatchId({
						batchId: props.batchId,
						sendPhone: sendPhone,
						sendStatus: sendStatus,
					});
					setloading(false)
					if (res.err == 0) {
						const data = res.data || []
						let ps = new Set()
						data.forEach(item => {
							ps.add(item.sendPhone)
						});
						sendMessage(Array.from(ps));
					} else {
						message.error(res.msg);
					}
				} else {
					let ps = new Set()
					phones.forEach(item => {
						ps.add(item.sendPhone)
					});
					setloading(false);
					sendMessage(Array.from(ps));
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const exportDate =async (selectedRows:any) => { 
		if (quanXuan) {
			setloading(true)
			const res = await findSmsSendDetailListByBatchId({
				batchId: props.batchId,
				sendPhone: sendPhone,
				sendStatus: sendStatus,
			});
			setloading(false)
			if (res.err == 0) {
				setExprotData(res.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setloading(false);
			setExprotData(selectedRows);
		}

	}

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		columns.map((item: any) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children || '');
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push("");
					}
				} else if (item.valueType === "dateTime") {
					if (selectedRows[i][item.dataIndex]) {
						data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
					} else {
						data.push("");
					}

				} else if (item.valueType === "date") {
					if (selectedRows[i][item.dataIndex]) {
						data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD"));
					} else {
						data.push("")
					}
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "记录");

		/* save to file */
		XLSX.writeFile(wb, "记录.xlsx");
	};

	return (
		<div>
			<Spin spinning={loading}>
			<GSTable
			columns={columns} 
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				rowKey="id"
				rowSelection={{
					fixed: true,
					columnWidth: 100,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setselectedRowKeys(selectedRowKeys);
					},
					selections: [
						{
							key: 'SELECT_ALL',
							text: '全选全部页面',
							onSelect: (changableRowKeys: any) => {
								setquanXuan(true);
								setselectedRowKeys(changableRowKeys);
							},
						},
					],
				}}
			
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanXuan ? total: selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::', selectedRowKeys, selectedRows);
					return (
						<Space size={16}>
							<a onClick={() => refSendSms(selectedRows)}>重发</a>
							<a onClick={() => exportDate(selectedRows)}>导出</a>

						</Space>
					);
				}}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findSmsSendDetailListByBatchId({
						page: params.current,
						size: params.pageSize,
						batchId: props.batchId,
						sendPhone: sendPhone,
						sendStatus: sendStatus,
					});
					if (res.err == 0) { 
						if (quanXuan) { 
							let sendPhone: any = [];
							res.data.map((item: any) => {
								sendPhone.push(item.sendPhone);
							});
							setselectedRowKeys(sendPhone);
						}
						settotal(res.count|| 0)
					}
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
				/>
				</Spin>
		</div>
	);
};

export default SmsDetail;
