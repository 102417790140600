import React, {useEffect, useRef, useState} from 'react';
import {ActionType} from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import {
    cancelApply,
    findBmryList,
    findBmryListExport,
    huoDongOrderList,
    shenHe,
} from '../../../services/huodongService';
import {Button, Col, Form, Input, message, Modal, Row, Select, Space} from 'antd';
import * as XLSX from 'xlsx';
import {FormInstance} from 'antd/es/form';
import ProxyForm from '../../../components/ProxyForm';

const LookBmry: React.FC = () => {
    useEffect(() => {
        getData();
    }, []);
    const actionRef = useRef<ActionType>();
    const [isCustom, setIsCustom] = useState(false);
    const Option = Select.Option;
    const Search = Input.Search;
    const sheetHeader = ['姓名', '手机号码', '公司', '职位', '备注', '类型', '订单编号', '报名时间', '状态'];
    const [checkSelect, setCheckSelect] = useState('全部');
    const [search, setSearch] = useState('');
    const [ziduan, setziduan] = useState([]);
    const [column1, setColumn1] = useState([]);
    const [checkVisible, setCheckVisible] = useState(false);
    const [checkForm] = Form.useForm<FormInstance>();
    const FormItem = Form.Item;
    const [form] = Form.useForm<FormInstance>();
    const [infoVisible, setInfoVisible] = useState(false);
    const columns = [
        {
            title: '姓名',
            dataIndex: 'userName',
        },
        {
            title: '手机号码',
            dataIndex: 'phone',
        },
        {
            title: '公司',
            dataIndex: 'company',
        },
        {
            title: '职位',
            dataIndex: 'position',
        },
        {
            title: '备注',
            dataIndex: 'remark',
        },
        {
            title: '类型',
            dataIndex: 'type',
        },
        {
            title: '订单编号',
            dataIndex: 'orderUUid',
        },
        {
            title: '报名时间',
            dataIndex: 'nowTime',
        },
        {
            title: '状态',
            dataIndex: 'status',
        },
        {
            title: '操作',
            render: (text: any, item: any, index: any) => (
                <ul className="ant-list-item-action">
                    <li>
                        <a
                            onClick={() => {
                                getInfo(item);
                            }}
                        >
                            详情
                        </a>
                    </li>
                    <li>
                        <a
                            id={index}
                            className={item.status !== '待审核' ? 'disableda' : ''}
                            onClick={() => {
                                checkItem(item);
                            }}
                        >
                            审核
                        </a>
                        {item.status === '待付款' ? <em className="ant-list-item-action-split"/> : ''}
                    </li>
                    {item.status === '待付款' ? (
                        <li>
                            <a
                                onClick={() => {
                                    cancelApply1(item.id, '已取消');
                                }}
                            >
                                取消
                            </a>
                        </li>
                    ) : (
                        ''
                    )}
                </ul>
            ),
        },
    ];

    const cancelApply1 = async (id: any, status: any) => {
        Modal.confirm({
            title: '提示',
            content: '您确定要取消该订单吗？',
            okType: "danger",
            okText: '取消订单',
            onOk: async () => {
                const res = await cancelApply({
                    id: id,
                    status: status,
                });
                if (res.err === 0) {
                    if (actionRef.current) {
                        actionRef.current.reload();
                    }
                } else {
                    message.error(res.msg);
                }
            }
        })

    };

    const checkItem = (item: any) => {
        const formData: any = {
            uuid: item.uuid,
            userName: item.userName,
            phone: item.phone,
        };
        checkForm.setFieldsValue(formData);
        setCheckVisible(true);
    };
    const getInfo = (item: any) => {
        const formData: any = {
            userName: item.userName,
            phone: item.phone,
        };
        form.setFieldsValue(formData);
        setInfoVisible(true);
    };
    const getAllData = async () => {
        let mySearchBox = '';
        if (isCustom) {
            mySearchBox = '';
        } else {
            mySearchBox = search;
        }
        let page = window.location.search;
        let c = page.split('&');
        let uuid = c[0].split('=')[1];
        const res = await findBmryListExport({
            huodongUuid: uuid,
            userName: mySearchBox,
            status: checkSelect === '全部' ? null : checkSelect,
        });
        console.log(res);
        if (res.err !== 0) {
            message.error(res.msg);
            return;
        }
        if (res.isCustom) {
            exportBmry1(res.data, res.ziduan);
        } else {
            exportBmry(res.data);
        }
    };
    const exportBmry = (findList: any) => {
        let sheetData = new Array(findList.length + 1);
        sheetData[0] = sheetHeader;
        for (let i = 0; i < findList.length; i++) {
            sheetData[i + 1] = [
                findList[i].userName,
                findList[i].phone,
                findList[i].company,
                findList[i].position,
                findList[i].remark,
                findList[i].type,
                findList[i].orderUUid,
                findList[i].nowTime,
                findList[i].status,
            ];
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'SheetJS.xlsx');
    };

    const getData = async () => {
        let page = window.location.search;
        console.log('page::::', page);
        let c = page.split('&');
        let uuid = c[0].split('=')[1];
        const res = await findBmryList({
            huodongUuid: uuid,
            page: 1,
            userName: '',
            size: 10,
        });
        if (res.err !== 0) {
            return;
        }
        setColumn(res.ziduan);
        setIsCustom(res.isCustom);
    };
    const exportBmry1 = (findList: any, ziduan: any) => {
        console.log('自定义字段导出', findList, ziduan);
        /* generate worksheet */
        let sheetData = new Array(findList.length + 1);
        var header = [];
        for (let i = 0; i < ziduan.length; i++) {
            console.log('headeritem', ziduan[i].split(',')[1]);
            header.push(ziduan[i].split(',')[1]);
        }
        console.log('header', header);
        sheetData[0] = header;
        for (let i = 0; i < findList.length; i++) {
            let s = i + 1;
            sheetData[s] = [];
            for (let j = 0; j < ziduan.length; j++) {
                let zidu = ziduan[j].split(',')[0];
                sheetData[s].push(findList[i][zidu]);
            }
        }
        console.log('sheet', sheetData);

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'SheetJS.xlsx');
    };

    const setColumn = (value: any) => {
        let column: any = [];
        value.map((item: any, index: any) => {
            const data: any = {
                title: item.split(',')[1],
                dataIndex: item.split(',')[0],
                key: item.split(',')[0],
            };
            column.push(data);
        });
        column.push({
            title: '操作',
            render: (text: any, item: any, index: any) => (
                <Space>
                    {
                        item.zhuangtai === '待审核' &&   <a
                            onClick={() => {
                                Modal.confirm({
                                    title: '审核',
                                    content: '是否通过审核',
                                    cancelText: '拒绝',
                                    onCancel: async () => {
                                        const res = await shenHe({
                                            uuid: item.uuid,
                                            status: "已拒绝",
                                        });
                                        if (res.err === 0) {
                                            if (actionRef.current) {
                                                actionRef.current.reload();
                                            }
                                        } else {
                                            message.error(res.msg);
                                        }
                                    },
                                    okText: '通过',
                                    onOk: async () => {
                                        const res = await shenHe({
                                            uuid: item.uuid,
                                            status: "待签到",
                                        });
                                        if (res.err === 0) {
                                            if (actionRef.current) {
                                                actionRef.current.reload();
                                            }
                                        } else {
                                            message.error(res.msg);
                                        }
                                    }
                                })
                            }}
                        >
                            审核
                        </a>
                    }
                    {item.zhuangtai !== '已取消' && (
                        <a
                            onClick={() => {
                                cancelApply1(item.id, '已取消');
                            }}
                        >
                            取消
                        </a>
                    )}
                </Space>
            ),
        },)
        console.log('column::::', column);
        setColumn1(column);
    };
    const tableSearchBarRender = () => [
        <Select
            onChange={(value) => {
                setCheckSelect(value);
                actionRef.current.reloadAndRest();
            }}
            defaultValue={'全部'}
        >
            <Option value={'全部'}>全部</Option>
            <Option value={'待付款'}>待付款</Option>
            <Option value={'待审核'}>待审核</Option>
            <Option value={'待签到'}>待签到</Option>
            <Option value={'已签到'}>已签到</Option>
            <Option value={'已拒绝'}>已拒绝</Option>
            <Option value={'已取消'}>已取消</Option>
        </Select>,
        <Search
            placeholder="搜索姓名"
            onSearch={(value) => {
                setSearch(value);
                actionRef.current.reloadAndRest();
            }}
            enterButton
        />,
    ];

    const tableOperationsBarRender = () => [
        <Button type={'primary'} onClick={getAllData}>
            导出订单
        </Button>,
    ];

    const updateShenhe = (status: any) => {
        checkForm.validateFields().then(async (data: any) => {
            const res = await shenHe({
                uuid: data.uuid,
                status: status,
            });
            if (res.err === 0) {
                if (actionRef.current) {
                    actionRef.current.reload();
                }
                setCheckVisible(false);
            } else {
                message.error(res.msg);
            }
        });
    };

    return (
        <div>
            {isCustom ? (
                <GSTable
                    columns={column1}
                    actionRef={actionRef}
                    searchBarRender={tableSearchBarRender()}
                    operationsBarRender={tableOperationsBarRender()}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        let page = window.location.search;
                        console.log('page::::', page);
                        let c = page.split('&');
                        let uuid = c[0].split('=')[1];
                        const res = await findBmryList({
                            huodongUuid: uuid,
                            page: params.current,
                            userName: search,
                            size: params.pageSize,
                            status: checkSelect === '全部' ? null : checkSelect,
                        });
                        setColumn(res.ziduan);
                        return {
                            data: res.data,
                            total: res.count,
                            success: true,
                        };
                    }}
                />
            ) : (
                <GSTable
                    columns={columns}
                    actionRef={actionRef}
                    searchBarRender={tableSearchBarRender()}
                    operationsBarRender={tableOperationsBarRender()}
                    request={async (params: any = {}, sort: any, filter: any) => {
                        let page = window.location.search;
                        console.log('page::::', page);
                        let c = page.split('&');
                        let uuid = c[0].split('=')[1];
                        const res = await findBmryList({
                            huodongUuid: uuid,
                            page: params.current,
                            userName: search,
                            size: params.pageSize,
                            status: checkSelect === '全部' ? null : checkSelect,
                        });
                        setIsCustom(res.isCustom);
                        return {
                            data: res.data,
                            total: res.count,
                            success: true,
                        };
                    }}
                />
            )}
            <Modal
                visible={infoVisible}
                title="详情"
                onCancel={() => {
                    setInfoVisible(false);
                }}
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            setInfoVisible(false);
                        }}
                    >
                        关闭
                    </Button>,
                ]}
            >
                <ProxyForm form={form}>
                    <FormItem label={'姓名'} name={'userName'}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'手机号'} name={'phone'}>
                        <Input/>
                    </FormItem>
                </ProxyForm>
            </Modal>
            {/*审核*/}
            <Modal
                visible={checkVisible}
                title="审核"
                onOk={() => {
                    updateShenhe('待签到');
                }}
                onCancel={() => {
                    setCheckVisible(false);
                }}
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            updateShenhe('已拒绝');
                        }}
                    >
                        审核拒绝
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            updateShenhe('待签到');
                        }}
                    >
                        审核通过
                    </Button>,
                ]}
            >
                <ProxyForm form={checkForm}>
                    <FormItem hidden={true} name={'uuid'} label={'uuid'}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'姓名'} name={'userName'}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'手机号'} name={'phone'}>
                        <Input/>
                    </FormItem>
                </ProxyForm>
            </Modal>
        </div>
    );
};
export default LookBmry;
