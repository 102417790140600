import React, {useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import {Button, Form, Image, Input, InputNumber, message, Modal, Select, Space, Tag} from "antd";
import API from "../../../data/API";
import AuthorizedView from "../../../util/AuthorizedView";
import {add, deleteWhitePaper, list, updateWhitePaper} from "../../../services/WhitePaperService";
import {ActionType} from "@ant-design/pro-table/lib/typing";
import UploadImgFormItem from "../../../components/UploadImgFormItem";
import CustomDateFormItem from "../../assets/components/CustomDateFormItem";
import UploadFileFormItem from "../../../components/UploadFileFormItem";

const WhitePaperList: React.FC = () => {
    const actionRef = useRef<ActionType>()
    const [search, setSearch] = useState("")
    const [addVisible, setAddVisible] = useState(false)
    const [updateVisible, setUpdateVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [addForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const columns = [
        {
            title: '名称',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '发布单位',
            ellipsis: true,
            key: 'publishingUnit',
            dataIndex: 'publishingUnit',
        },
        {
            title: '操作',
            key: 'active',
            render: (text: string, record: any) => {
                return <Space>
                    {/*<a href={*/}
                    {/*    API.apiRootPath + '/api/file/get_file_qiniu/' + record.fileName + '/' + localStorage.getItem("projUuid")*/}
                    {/*} target={"_blank"}>查看</a>*/}
                    <AuthorizedView needAuthority={'whitePaper:update'}>
                    <a onClick={() => {
                        updateForm.setFieldsValue(record)
                        setUpdateVisible(true)
                    }}>编辑</a>
                    </AuthorizedView>
                    <AuthorizedView needAuthority={'whitePaper:delete'}>
                        <a onClick={() => {
                            Modal.confirm({
                                title: "提示",
                                content: "您确定要删除该文件吗？",
                                okText: "删除",
                                okType: "danger",
                                onOk: async () => {
                                    let rsp = await deleteWhitePaper({uuid: record.uuid});
                                    if (rsp.err === 0) {
                                        actionRef.current.reload()
                                    }else {
                                        message.error(rsp.msg)
                                    }
                                }
                            })
                        }}>删除</a>
                    </AuthorizedView>

                </Space>
            }
        }
    ]
    const loadList = async (params: any) => {
        let rsp = await list({...params,search: search})
        if (rsp.err === 0) {
            return {
                data: rsp.data,
                total: rsp.total
            }
        } else {
            return {
                data: []
            }
        }
    }
    const tableSearchBarRender = () => [
        <Space>
            <Input onChange={(e) => {
                setSearch(e.target.value)

            }} placeholder={'请输入名称'}></Input>
            <Button type={"primary"} onClick={() => {
                actionRef.current.reloadAndRest();
            }}>查询</Button>
        </Space>
    ]
    const tableOperationsBarRender =() => [
        <AuthorizedView needAuthority={'whitePaper:add'}>
            <Button type={"primary"} onClick={() => {
                setAddVisible(true)
            }}>添加</Button>
        </AuthorizedView>

    ]
    const addItem = async () => {
        let data = await addForm.validateFields();
        setLoading(true);
        let rsp = await add(data);
        setLoading(false)
        if (rsp.err === 0) {
            actionRef.current.reload()
            setAddVisible(false)
            message.success(rsp.msg)
        } else {
            message.error(rsp.msg)
        }
    }
    const updateItem = async () => {
        let data = await updateForm.validateFields();
        setLoading(true);
        let rsp = await updateWhitePaper(data);
        setLoading(false)
        if (rsp.err === 0) {
            actionRef.current.reload()
            setUpdateVisible(false)
            message.success(rsp.msg)
        } else {
            message.error(rsp.msg)
        }
    }
    return <>
        <GSTable
            actionRef={actionRef}
            columns={columns}
            searchBarRender={tableSearchBarRender()}
            operationsBarRender={tableOperationsBarRender()}
            request={(params: any) => {
            return loadList(params)
        }}>


        </GSTable>
        <Modal destroyOnClose={true} confirmLoading={loading} title={'添加白皮书'} visible={addVisible} onCancel={() => setAddVisible(false)} onOk={addItem}>
            <Form form={addForm} labelCol={{span: 4}} labelAlign={'left'}>
                <Form.Item name={'name'} label={'名称'}
                           rules={[{required: true, message: '输入名称'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'publishingUnit'} label={'发布单位'}
                           rules={[{required: true, message: '输入发布单位'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'fileName'} label={'上传文件'}
                           rules={[{required: false, message: '上传文件'}]}>
                    <UploadFileFormItem multiple={false} private={true} />
                </Form.Item>
            </Form>
        </Modal>
        <Modal destroyOnClose={true} confirmLoading={loading} title={'修改白皮书'} visible={updateVisible} onCancel={() => setUpdateVisible(false)} onOk={updateItem}>
            <Form form={updateForm} labelCol={{span: 4}} labelAlign={'left'}>
                <Form.Item hidden={true} name={'uuid'} label={'uuid'}
                           rules={[{required: true, message: '输入uuid'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'name'} label={'名称'}
                           rules={[{required: true, message: '输入名称'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'publishingUnit'} label={'发布单位'}
                           rules={[{required: true, message: '输入发布单位'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'fileName'} label={'上传文件'}
                           rules={[{required: false, message: '上传文件'}]}>
                    <UploadFileFormItem multiple={false} private={true} />
                </Form.Item>
            </Form>
        </Modal>

    </>
}
export default WhitePaperList;
