import Api from './API';
import { getAllPermItem } from '../services/PermItemService';
import common from '../models/Common';
import loginModel from '../models/login';
var msg = require('../util/SuspensionMsg');

/**
 * 数据访问层
 */
class DAL {
	static user: any = loginModel.state.currentUser;
	static dal = new DAL();
	static pageSize = ['15', '30', '50', '100'];
	static permissions: Array<string> = (loginModel.state.currentUser as any).button || [];
	allPerm: any = [];
	userId: string;
	password: string;
	accessToken: string;
	shenhe: any;
	acl: string | null;
	url: string | null;
	userInfo: any;

	/**
	 * 判断当前用户权限是否拥有传入的权限值
	 * @param perm
	 */
	static checkPermission = (perm: string) => {
		if (DAL.user.button.length === 0) {
			return false;
		}
		// 如果传入权限值为空则表示该检查项目不需要任何权限检查，则返回检查为真
		if (perm === '') {
			return true;
		}
		// 如果传入权限值不为空，则根据当前用户权限判断
		return DAL.user.button.indexOf(perm) !== -1;
	};

	getQueryString(name: string) :string {
		let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
		let r = window.location.search.substr(1).match(reg);
		if(r != null) {
			return  unescape(r[2]);
		}
		return '';
	}
		// 设置Cookie
		setCookie(key: string, value: string) {
			let d = new Date();
			d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
			const expires = 'expires=' + d.toUTCString();
			document.cookie = key + '=' + value + ';' + expires;
		}
	
		// 获取Cookie
		getCookie(key: string) {
			const name = key + '=';
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				const c = ca[i].trim();
				if (c.indexOf(name) === 0) {
					return c.substr(name.length, c.length);
				}
			}
			return '';
		}
		isNotNull = (string: any) => {
        if (string !== "" && string !== null && string !== undefined && string != '{}' && string != '[]') {
            return true
        } else {
            return false
        }
    }
	getUserInfo(): Promise<any> {
		if (this.userInfo !== null && this.userInfo !== undefined) {
			return new Promise<any>((resolve) => {
				resolve(this.userInfo);
			});
		}
		return new Promise<any[]>((resolve) => {
			Api.postWithAuth('/api/wuye/get_user_info', {
				user_id: Api.userId,
				password: Api.pd,
			}).then((res) => {
				console.log('获得请求的值userinfo: ', res.data);
				if (res.err === 0) {
					this.userInfo = res.data;
					localStorage.setItem('wyUuid', res.data.wyUuid);
					resolve(res.data);
				} else {
					resolve([]);
				}
			});
		});
	}
	changeNumMoneyToChinese = (money) => {
        var cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"); //汉字的数字
        var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
        var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
        var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
        var cnInteger = "整"; //整数金额时后面跟的字符
        var cnIntLast = "元"; //整型完以后的单位
        var maxNum = 999999999999999.9999; //最大处理的数字
        var IntegerNum; //金额整数部分
        var DecimalNum; //金额小数部分
        var ChineseStr = ""; //输出的中文金额字符串
        var parts; //分离金额后用的数组，预定义
        var Symbol = ""; //正负值标记
        if (money == "") {
            return "";
        }
        money = parseFloat(money);
        if (money >= maxNum) {
            alert('超出最大处理数字');
            return "";
        }
        if (money == 0) {
            ChineseStr = cnNums[0] + cnIntLast + cnInteger;
            return ChineseStr;
        }
        if (money < 0) {
            money = -money;
            Symbol = "负 ";
        }
        money = money.toString(); //转换为字符串
        if (money.indexOf(".") == -1) {
            IntegerNum = money;
            DecimalNum = '';
        } else {
            parts = money.split(".");
            IntegerNum = parts[0];
            DecimalNum = parts[1].substr(0, 4);
        }
        if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
            var zeroCount = 0;
            var IntLen = IntegerNum.length;
            for (var i = 0; i < IntLen; i++) {
                var n = IntegerNum.substr(i, 1);
                var p = IntLen - i - 1;
                var q = p / 4;
                var m = p % 4;
                if (n == "0") {
                    zeroCount++;
                } else {
                    if (zeroCount > 0) {
                        ChineseStr += cnNums[0];
                    }
                    zeroCount = 0; //归零
                    ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                }
                if (m == 0 && zeroCount < 4) {
                    ChineseStr += cnIntUnits[q];
                }
            }
            ChineseStr += cnIntLast;
            //整型部分处理完毕
        }
        if (DecimalNum != '') { //小数部分
            var decLen = DecimalNum.length;
            for (var i = 0; i < decLen; i++) {
                var n = DecimalNum.substr(i, 1);
                if (n != '0') {
                    ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
                }
            }
        }
        if (ChineseStr == '') {
            ChineseStr += cnNums[0] + cnIntLast + cnInteger;
        } else if (DecimalNum == '') {
            ChineseStr += cnInteger;
        }
        ChineseStr = Symbol + ChineseStr;

        return ChineseStr;
    }
	/*
	 *
	 * ----------------------构造函数里面判断是否登陆-------------------------------
	 */
	getLoginState(): boolean {
		return Api.getLoginState();
	}

	/**
	 * 获取素有权限名称
	 * 先尝试从本地存储获取，如果没有从服务器获取
	 */
	getAllPerm() {
		let localAllPerm = localStorage.getItem('allPerm');
		if (localAllPerm != null) {
			this.allPerm = JSON.parse(localAllPerm);
			common.state.permItemList = this.allPerm || [];
		} else {
			getAllPermItem({}).then((res) => {
				console.log('获取总菜单权限：：：：', res);
				this.allPerm = res.data;
				common.state.permItemList = res.data;
			});
		}
		return this.allPerm;
	}
}

export default DAL;
