import React, { useRef } from 'react';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import API from '../../../data/API';
import { dingDanList, huoDongOrderList } from '../../../services/huodongService';
import { message } from 'antd';
const moment = require('moment');
const LookDingdan: React.FC = () => {
	const actionRef = useRef<ActionType>();

	const columns = [
		{
			title: '订单编号',
			dataIndex: 'outTradeNo',
		},
		{
			title: '下单人',
			dataIndex: 'userName',
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			render: (text: any) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
		},
		{
			title: '类型',
			dataIndex: 'type',
		},
		{
			title: '订单金额',
			dataIndex: 'totalFee',
		},
		{
			title: '订单状态',
			render: (text: any) => '订单状态',
		},
		{
			title: '付款方式',
			dataIndex: 'payment',
		},
		{
			title: '付款时间',
			dataIndex: 'payTime',
			render: (text: any, item: any) => (
				<span>{item.payTime !== null ? moment(item.payTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
			),
		},
		{
			title: '付款状态',
			render: (text: any, item: any) => (item.isPay === 0 ? '未支付' : 'ok'),
		},
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				// searchBarRender={tableSearchBarRender()}
				// operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let page = window.location.search;
					let c = page.split('&');
					let huodongUuid = c[0].split('=')[1];
					const res = await huoDongOrderList({
						huodongUuid: huodongUuid,
						kind: 'huodongTicket',
						page: params.current,
						size: params.pageSize,
					});
					// if (res.err !== 0) {
					//     message.error(res.msg);
					//     return;
					// }
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default LookDingdan;
