import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import ExitSlipList from '../ZhiNengMenJin/pages/ExitSlipList';


export default function ExitSlipMiniApp() {
	const rootUrl = '/exit-slip';
	const menus: MiniAppMenu[] = [
		{
			authority: "exitSlip:manager:list",
			name: "出门条",
			path: "exit-slip-list",
			component:  ExitSlipList
		},
	];

	return <MiniApp miniAppName={'考试答题'} rootUrl={rootUrl} menus={menus} />;
}
