import React, { useRef, useState } from "react";
import { ActionType } from "@ant-design/pro-table";
import { batchEmp, findAllPersonManage } from "../../../services/WuyeService";
import GSTable from "../../../components/GSTable";
import { Button, message, Space, Tag, Upload, Modal, Table, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons/lib";
import { batchImportHousehold } from "../../../services/ZhuhushenheService";
import API from "../../../data/API";
import * as XLSX from "xlsx";

let myXlsx = require("../../../util/myXlsx");
let moment = require("moment");
interface Params {
    saveEmpList:any
}
const BatchEmp: React.FC<Params> = (props:any) => {

    const [content, setContent] = useState("");
    const actionRef = useRef<ActionType>();
    const [batchData, setBatchData] = useState([]);
    const [errData, setErrData] = useState([]);
    const [errCount, setErrCount] = useState(0);
    const [size, setSize] = useState(0);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [success, setSuccess] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [errVisible, setErrVisible] = useState(false);
    const batchColumns = [
        {
            title: "联系电话",
            key: "联系电话",
            width: 150,
            dataIndex: "联系电话"
        },
        {
            title: "状态",
            key: "状态",
            width: 50,
            dataIndex: "状态"
        },
        {
            title: "姓名",
            width: 100,
            key: "姓名",
            dataIndex: "姓名"
        }, {
            title: "民族",
            key: "民族",
            width: 100,
            dataIndex: "民族"
        },
        {
            title: "性别",
            key: "性别",
            width: 100,
            dataIndex: "性别"
        },
        {
            title: "部门",
            key: "部门",
            width: 150,
            dataIndex: "部门"
        },
        {
            title: "IC卡",
            key: "IC卡",
            width: 100,
            dataIndex: "IC卡"
        },
        {
            title: "身份证号",
            key: "身份证号",
            width: 150,
            dataIndex: "身份证号"
        },
        {
            title: "人员类别",
            key: "人员类别",
            width: 100,
            dataIndex: "人员类别"
        },
        {
            title: "人员类型",
            key: "人员类型",
            width: 100,
            dataIndex: "人员类型"
        },
        {
            title: "是否能被访问",
            key: "是否能被访问",
            width: 100,
            dataIndex: "是否能被访问",
            render: (text: any, item: any) => {
                return <Tag color={item.是否能被访问 == 1 ?
                    "green" : "red"}>
                    {item.是否能被访问 == 1 ? "能" : "不能"}
                </Tag>;
            }
        },
        {
            title: "户籍地址",
            key: "户籍地址",
            width: 150,
            ellipsis: true,
            dataIndex: "户籍地址"
        },
        {
            title: "现住地址",
            key: "现住地址",
            ellipsis: true,
            width: 150,
            dataIndex: "现住地址"
        },
        {
            title: "截止日期",
            key: "截止日期",
            width: 100,
            dataIndex: "截止日期"
        },
        {
            title: "入职时间",
            key: "入职时间",
            width: 100,
            dataIndex: "入职时间"
        },
        {
            title: "备注",
            key: "备注",
            width: 100,
            ellipsis: true,
            dataIndex: "备注"
        }

    ];
    const errorColumns = [
        {
            title: "联系电话",
            key: "联系电话",
            dataIndex: "联系电话"
        },
        {
            title: "状态",
            key: "状态",
            dataIndex: "状态"
        },
        {
            title: "姓名",
            key: "姓名",
            dataIndex: "姓名"
        }, {
            title: "民族",
            key: "民族",
            dataIndex: "民族"
        },
        {
            title: "性别",
            key: "性别",
            dataIndex: "性别"
        },

        {
            title: "部门",
            key: "部门",
            dataIndex: "部门"
        },
        {
            title: "身份证号",
            key: "身份证号",
            dataIndex: "身份证号"
        },
        {
            title: "人员类别",
            key: "人员类别",
            dataIndex: "人员类别"
        },
        {
            title: "户籍地址",
            key: "户籍地址",
            ellipsis: true,
            dataIndex: "户籍地址"
        },
        {
            title: "现住地址",
            key: "现住地址",
            ellipsis: true,
            dataIndex: "现住地址"
        },
        {
            title: "错误原因",
            key: "errorMsg",
            dataIndex: "errorMsg",
            render: (text: string) => {
                return <div style={{ color: "red" }}>{text}</div>;
            }
        }
    ];
    const getData = () => {
        return {
            data: batchData
        };
    };
    const isNull = (string: any) => {
        if (string === null || string === undefined || string === "") {
            return true;
        } else {
            return false;
        }
    };
    const formatExcelDate = (num: any, format = "-") => {
        num = Number(num);	// 强制类型转化，以防传来的值是字符串
        let millisecond = 0;	// 转化后的毫秒数
        if (num > 60) {
            millisecond = (num - 25569) * 60 * 60 * 24 * 1000;
        } else { // 对小于61的错误日期进行处理
            millisecond = (num - 25568) * 60 * 60 * 24 * 1000;
        }
        let date = new Date(millisecond);	// 根据转化后的毫秒数获取对应的时间
        let yy = date.getFullYear();
        let mm = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return yy + format + mm + format + dd;	// 返回格式化后的日期
    };

    const checkMobileNumber = (mobile: any) => {
        if (!mobile) {
            return false;
        }
        let pattern = /^1[3456789]\d{9}$/;
        return pattern.test(mobile);
    };

    const chkEmail = (strEmail: any) => {
        if (!strEmail) {
            return false;
        }
        return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(strEmail);
    };

    const /*导入信息显示*/
        analyze = (evv: any) => {
            console.log("上传返回", evv);
            myXlsx.readData(evv, function(res: any) {
                console.log("excelData:::", res);
                let index = 0;
                const size = res.length;
                let errData: any = [];
                let errCount = 0;
                let departData: any = [];
                res.map((item: any) => {
                    if (!isNull(item.入职时间)) {
                        item.入职时间 = item.入职时间;
                    }
                    if (isNull(item.状态)) {
                        item.状态 = "正常";
                    }
                    // if (item.人员类型 !== '临时人员' && item.人员类型 !== '固定人员') {
                    //     if (isNull(item.截止日期)) {
                    //         errCount++;
                    //         item["errorMsg"] = "人员类型为固定人员或临时人员";
                    //         errData.push(item);
                    //         index++;
                    //         return;
                    //     }
                    // }
                    if (item.人员类型 === '临时人员') {
                        if (isNull(item.截止日期)) {
                            errCount++;
                            item["errorMsg"] = "人员类型为临时人员时，截止日期必填";
                            errData.push(item);
                            index++;
                            return;
                        } else {
                            console.log('时间差：：：：', moment(item.截止日期, 'YYYY-MM-DD 23:59:59').diff(moment(), 'days'))
                            if (moment(item.截止日期, 'YYYY-MM-DD 23:59:59').diff(moment(), 'days') > 90) {
                                errCount++;
                                item["errorMsg"] = "临时人员截止日期最多不能超过90天";
                                errData.push(item);
                                index++;
                                return;
                            }
                        }
                    }
                    if (isNull(item.部门)) {
                        errCount++;
                        item["errorMsg"] = "部门未填写";
                        errData.push(item);
                        index++;
                        return;
                    }
                    let datas = item.部门.split("/");
                    datas.map((item: any, index: any) => {
                        if (index === 0) {
                            const data = {
                                pId: 0,
                                name: item,
                                index: index,
                                children: []
                            };
                            departData.push(data);
                        } else {
                            const data = {
                                pId: datas[index - 1],
                                name: item,
                                index: index
                            };
                            departData.push(data);
                        }
                    });

                    if (isNull(item.联系电话)) {
                        errCount++;
                        item["errorMsg"] = "联系电话未填写";
                        errData.push(item);
                        index++;
                        item.err = "联系电话";
                        return;
                    }
                    // if (isNull(item.性别)) {
                    //     errCount++;
                    //     item["errorMsg"] = "性别未填写";
                    //     errData.push(item);
                    //     index++;
                    //     item.err = "性别";
                    //     return true;
                    // }
                    if (!isNull(item.联系电话)) {
                        item.联系电话 = item.联系电话.toString().replace(/\s*/g, "");
                        if (!checkMobileNumber(item.联系电话)) {
                            errCount++;
                            item["errorMsg"] = "联系电话格式不对";
                            errData.push(item);
                            index++;
                            return true;
                        }
                    }

                    if (!isNull(item.邮箱)) {
                        if (!chkEmail(item.邮箱)) {
                            errCount++;
                            item["errorMsg"] = "邮箱格式不对";
                            errData.push(item);
                            index++;
                            return true;
                        }
                    }
                    // if (!isNull(item.身份证号)) {
                    //     item.身份证号 = item.身份证号.toString().replace(/\s*/g, "").toUpperCase();
                    //     if (!(/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(item.身份证号))) {
                    //         errCount++;
                    //         item["errorMsg"] = "身份证号格式不对";
                    //         errData.push(item);
                    //         index++;
                    //         return true;
                    //     }
                    // }
                    index++;
                    return true;
                });
                if (index === size) {
                    setDepartments(deteleObject(departData));
                    console.log("departData::", deteleObject(departData));
                    setSize(size);
                    setErrCount(errCount)
                    if (errCount > 0) {
                        setSuccess(false);
                        setErrVisible(true);
                        setErrData(errData);
                    } else {
                        setSuccess(true);
                        setBatchData(res);
                        actionRef.current.reload();
                    }
                }

            });
        };

    const deteleObject = (obj: any) => {
        let uniques: any = [];
        let stringify = {};
        for (let i = 0; i < obj.length; i++) {
            let keys = Object.keys(obj[i]);
            keys.sort(function(a, b) {
                return (Number(a) - Number(b));
            });
            let str = "";
            for (let j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }
            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }
        uniques = uniques;
        return uniques;
    };
    /** 模板下载 */
    const exportBmry = () => {
        window.open(
            "https://pcdn.guosim.com/template/%E5%AF%BC%E5%85%A5%E5%91%98%E5%B7%A5%E6%A8%A1%E6%9D%BF.xlsx",
            "_blank"
        );
    };
    const tableSearchBarRender = () => [
        <div>导入 {size} 条 失败 <span style={{ color: "red" }}> {errCount}  </span> 条</div>

    ];
    const tableOperationsBarRender = () => [
        <Space>
            <Upload showUploadList={false} onChange={analyze} accept={".xlsx"}>
                <Button>
                    <UploadOutlined/> 选择文件
                </Button>
            </Upload>
            <Button type="primary" onClick={exportBmry}>
                模版下载
            </Button>
            <Button type="primary" loading={confirmLoading} onClick={batchImport}>
                开始导入
            </Button>
        </Space>
    ];
    const exportData = () => {
        let sheetData = new Array(errData.length + 1);
        let titles: [string?] = [];
        errorColumns.map((item) => {
            titles.push(item.title);
        });
        sheetData[0] = titles;
        for (let i = 0; i < errData.length; i++) {
            let data: [string?] = [];
            errorColumns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[errData[i][item.dataIndex]]) {
                        data.push(item.valueEnum[errData[i][item.dataIndex]].props.children);
                    } else {
                        data.push("");
                    }
                } else if (item.valueType === "dateTime") {
                    data.push(moment(errData[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
                } else {
                    data.push(errData[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "导入失败数据");

        /* save to file */
        XLSX.writeFile(wb, "导入失败数据.xlsx");
    };
    const errorTableOperationBarRender = () => {
        return [<Space>
            <Button type="primary" onClick={exportData}>
                导出
            </Button>
        </Space>];
    };

    /*导入数据*/
    const batchImport = async () => {
        if (batchData.length == 0) {
            message.warning("请先导入数据");
            return;
        }
        const depars: any = departments.sort((a: any, b: any) => b.index - a.index);	//降序
        const maxIndex = depars[0].index;
        console.log("departMent::", departments);

        const data = {
            companyUuid: API.getCompanyUuid(),
            departData: JSON.stringify(departments),
            data: JSON.stringify(batchData),
            maxIndex: maxIndex
        };
        if (success) {
            setConfirmLoading(true);
            const res = await batchEmp(data);
            setConfirmLoading(false);
            if (res.err === 0) {
                const size = batchData.length;
                setSize(size);
                const errCount = res.errCount;
                const sendData = [...batchData]
                setErrCount(errCount);

                if (errCount > 0) {
                    res.errData.map(item => {
                        const index = sendData.findIndex(item1 => item1.联系电话 == item.联系电话)
                        sendData.splice(index, 1)
                    })
                    setErrVisible(true);
                    setErrData(res.errData);
                    setBatchData([]);
                    actionRef.current.reload();
                    message.success("导入" + size + "条" + "失败" + errCount + "条");
                } else {
                    setBatchData([]);
                    message.success("导入" + size + "条，成功" + size + "条");
                }
                props.saveEmpList(sendData)
                actionRef.current.reload()
            }
        } else {
            message.error(content);
            return;
        }
    };
    return (
        <div style={{padding:16}}>
            <GSTable
                columns={batchColumns}
                actionRef={actionRef}
                scroll={{
                    x: 1300
                }}
                searchBarRender={tableSearchBarRender()}
                operationsBarRender={tableOperationsBarRender()}
                request={async (params: any = {}, sort: any, filter: any) => {
                    return getData();
                }}
                options={false}
            />
            <Modal maskClosable={false} destroyOnClose={true} title={"结果"} width={1280} footer={null} visible={errVisible}
                   onOk={() => setErrVisible(false)}
                   onCancel={() => {
                       setErrVisible(false);
                   }}>
                <GSTable size={"small"} searchBarRender={tableSearchBarRender()}
                         operationsBarRender={errorTableOperationBarRender()}
                         options={false} request={() => {
                    return {
                        data: errData
                    };
                }} columns={errorColumns}/>
            </Modal>
        </div>
    );
};
export default BatchEmp;
