import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { FormInstance } from 'antd/es/form';
import { Button, Form, Input, Modal, Switch } from 'antd';
import { Link } from 'react-router-dom';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import {
	changeTiebaTypeState,
	tiebaAddTiebaType,
	tiebaFindTiebaTypeList,
	tiebaUpdateTiebaType,
} from '../../../services/PostBarService';
var msg = require('../../../util/SuspensionMsg');
const LookTiebaType: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);

	const columns = [
		{
			title: '贴吧名称',
			dataIndex: 'type',
			key: 'type',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						update(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (text: string, record: any) => (
				<Switch
					checked={record.status === '开启' ? true : false}
					onChange={() => {
						ChangeState(record);
					}}
				/>
			),
		},
		{
			title: '创建人',
			dataIndex: 'creator',
			key: 'creator',
		},
		{
			title: '创建时间',
			dataIndex: 'creationTime',
			key: 'creationTime',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<Link to={`/postbar/find-tiezi-list?uuid=${record.uuid}&state=tieba`}>
								<a>帖子管理</a>
							</Link>
						</li>
					</ul>
				</span>
			),
		},
	];

	const update = (value: any) => {
		setAdd(false);
		const FormData: any = {
			uuid: value.uuid,
			type: value.type,
		};
		form.setFieldsValue(FormData);
		setVisible(true);
	};
	const ChangeState = async (value: any) => {
		if (value.status === '开启') {
			value.status = '禁用';
		} else {
			value.status = '开启';
		}
		const res = await changeTiebaTypeState({
			uuid: value.uuid,
			status: value.status,
		});
		if (res) {
			msg.suspensionMsg({ content: '修改成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '失败', type: 'error' });
		}
	};

	const handleOk = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};

	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data::', data);
			let res = await tiebaAddTiebaType({ type: data.type });
			if (res.err === 0) {
				msg.suspensionMsg({ content: '新增成功!', type: 'success' });
				setVisible(false);
				form.resetFields();
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: res.msg, type: 'error' });
			}
		});
	};

	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const res = await tiebaUpdateTiebaType({
				uuid: data.uuid,
				type: data.type,
			});
			if (res) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
				setVisible(false);
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
		});
	};
	const addTiba = () => {
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={addTiba}>
			新建贴吧
		</Button>,
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await tiebaFindTiebaTypeList({
						page: params.current,
						size: params.pageSize,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>

			<Modal
				title={add ? '添加' : '修改'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={handleOk}
			>
				<ProxyForm form={form}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'贴吧名称'} name={'type'} rules={[{ required: true }]}>
						<Input placeholder={'请输入贴吧名称'} />
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};

export default LookTiebaType;
