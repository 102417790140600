import React, { useEffect, useRef, useState } from 'react';
import {
    addCleanItem,
	addItemInfo,
	deleteCleanItem,
	deleteItemInfo,
	getCleanItemList,
	getHouseStyleList,
	getHouseTypeList,
	getItemInfoList,
	updateCleanItem,
	updateItemInfo,
} from '../../../services/ZhuhushenheService';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { Button, Form, Input, InputNumber, message, Modal, Select, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import ProxyForm from '../../../components/ProxyForm';
import TrimInput from '../../../components/TrimInput';
import CleanItemsBatch from '../components/CleanItemsBatch';
const CleanItemList: React.FC = () => {
	useEffect(() => {
		getHouseTypes().then();
	}, []);

	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [add, setadd] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [searchType, setsearchType] = useState('');
	const [batchVisible, setbatchVisible] = useState(false);
	const [datekey, setdatekey] = useState(new Date().getTime().toString());
	const [houseTypes, sethouseTypes] = useState([]);
	const getHouseTypes = async () => {
		const res = await getHouseTypeList({});
		if (res.err == 0) {
			sethouseTypes(res.data || []);
		}
	};
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '房型',
			dataIndex: 'houseType',
			key: 'houseType',
		},
		{
			title: '卫生标准',
			dataIndex: 'note',
			key: 'note',
		},
		{
			title: '价格',
			dataIndex: 'price',
			valueType: 'money',
			key: 'price',
		},
		// {
		// 	title: '数量单位',
		// 	dataIndex: 'quantityUnit',
		// 	key: 'quantityUnit',
		// },
		{
			title: '备注',
			dataIndex: 'remark',
			key: 'remark',
		},
		// {
		//     title: '创建时间',
		//     dataIndex: 'createdTime',
		//     valueType: 'dateTime',
		//     key: 'createdTime',
		// },
		{
			title: '操作',
			key: 'action',
			render: (record: any) => (
				<Space>
					<a
						onClick={() => {
							form.setFieldsValue(record);
							setadd(false);
							setVisible(true);
						}}
					>
						编辑
					</a>
					<a
						onClick={() => {
							Modal.confirm({
								title: '提示',
								content: '您确定要删除吗?',
								onOk: async () => {
									let data = {
										uuid: record.uuid,
									};
									let rsp = await deleteCleanItem(data);
									if (rsp.err === 0) {
										message.success(rsp.msg);
										if (actionRef.current) {
											actionRef.current.reload();
										}
										return;
									}
									message.error(rsp.msg);
								},
							});
						}}
					>
						删除
					</a>
				</Space>
			),
		},
	];

	const tableSearchBarRender = () => [
        <Select style={{ width: 200 }} value={searchType} onChange={(value) => setsearchType(value)}>
                <Select.Option value={""}>全部房型</Select.Option>
            {houseTypes.map((item) => {
				return (
					<>
						<Select.Option value={item.name}> {item.name} </Select.Option>
					</>
				);
			})}
		</Select>,
		<Input
			placeholder={'名称'}
			onChange={(event: any) => {
				setSearch(event.target.value);
			}}
		/>,
		<Button
			type="primary"
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reload();
				}
			}}
		>
			查询
		</Button>,
	];
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setadd(true);
				setVisible(true);
			}}
		>
			新增
		</Button>,
		<Button
			type="primary"
			onClick={() => {
				setbatchVisible(true);
			}}
		>
			批量添加
		</Button>,
	];
	const addItemOk = async () => {
		let data = await form.validateFields();
		console.log('data:::', data);
		setLoading(true);
		let rsp = await addCleanItem(data);
		setLoading(false);
		if (rsp.err === 0) {
			message.success(rsp.msg);
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(rsp.msg);
		}
	};

	const updateItemOk = async () => {
		let data = await form.validateFields();
		console.log('data:::', data);
		setLoading(true);
		let rsp = await updateCleanItem(data);
		setLoading(false);
		if (rsp.err === 0) {
			message.success(rsp.msg);
			setVisible(false);
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		} else {
			message.error(rsp.msg);
		}
	};
	const saveItem = () => {
		if (add) {
			addItemOk();
		} else {
			updateItemOk();
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getCleanItemList({
						page: params.current,
						size: params.pageSize,
						search: search,
						houseType: searchType,
					});
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				title={add ? '添加' : '修改'}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				maskClosable={false}
				confirmLoading={loading}
				onOk={saveItem}
			>
				<ProxyForm form={form}>
					<Form.Item name={'name'} label={'名称'} rules={[{ required: true, message: '请填写名称' }]}>
						<TrimInput />
					</Form.Item>
					<Form.Item name={'note'} label={'卫生标准'}>
						<Input.TextArea  />
					</Form.Item>
					<Form.Item name={'uuid'} hidden={true} label={'uuid'}>
						<Input />
					</Form.Item>
					<Form.Item name={'price'} label={'价格(元)'} rules={[{ required: true, message: '请填写价格' }]}>
						<InputNumber style={{ width: '100%' }} min={0} precision={2} step={1} />
					</Form.Item>
					<Form.Item name={'houseType'} label={'房型'} rules={[{ required: true, message: '请选择房型' }]}>
						<Select>
							{houseTypes.map((item) => {
								return (
									<>
										<Select.Option value={item.name}> {item.name} </Select.Option>
									</>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item name={'remark'} label={'备注'}>
						<Input.TextArea  />
					</Form.Item>
				</ProxyForm>
			</Modal>
			<Modal
				title="批量添加"
				width={800}
				visible={batchVisible}
				footer={null}
				onCancel={() => setbatchVisible(false)}
            >
				<CleanItemsBatch key={datekey}></CleanItemsBatch>
			</Modal>
		</>
	);
};
export default CleanItemList;
