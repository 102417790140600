import React, { useRef, useState } from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Api from "../../../data/API";
import { RcFile } from 'antd/lib/upload';
import Resizer from 'react-image-file-resizer';

const BatchUpdateUserImg: React.FC = () => {
    const [fileList, setFileList] = useState([]);

    const onChangeFile = (info: any) => {
        console.log("UploadFile.onChange: ", info, info.file.status);
        info.fileList.map((file: any) => {
            // console.log("file:::::", JSON.stringify(file))
            if (file.status === "done") {
                if (file.response.err !== 0) {
                    file.status = 'error';
                    file.response = file.response.msg;
                }
            }
        });
        setFileList(info.fileList);
        return
    };

    const resizeFile = (file: RcFile) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1028,
                1028,
                'jpeg',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file'
            );
        });
    };
    const transformFile = (file: RcFile) => {
        return resizeFile(file)
            .then((url: any) => {
                console.log('文件大小：：：', file.size, url.size);
                return url;
            })
            .catch((e: any) => {
                console.log('发生异常', e);
                return file;
            });
    };
    const uploadProp = {
        data: { wuye_uuid: localStorage.getItem("wyUuid"), "access_token": localStorage.getItem("token") },
        directory: true,
        onChange: onChangeFile,
        transformFile: transformFile
    };

    return (
        <>
                <Upload {...uploadProp} fileList={fileList} action={Api.apiRootPath + "/api/user/batch_upload_user_face_img"}>
                <Button icon={<UploadOutlined />}>选择文件夹</Button>
                <div style={{ display: 'flex' ,fontSize:14}}>
                    <div>

                        注：文件夹内图片需按照 "

                    </div>
                    <div style={{color:'red'}}>
                        身份证号-手机号
                    </div>
                    <div>
                        " 的命名方式，例：130323198706081623-18516995465。
                    </div>
                 </div>
            </Upload>
        </>
    );
};
export default BatchUpdateUserImg;
