import React, { useEffect, useState } from 'react';
import { Button, Cascader, Col, Form, Input, InputNumber, Menu, message, Row, Select, Switch, Upload } from 'antd';
import { FormInstance } from 'antd/es/form';
import HouseholdList from '../../zhuhushenhe/pages/HouseholdList';
import Api from '../../../data/API';
import {
	findBuilds,
	findRoomByUuid,
	getAssetsType,
	getHouseStyle,
	getHouseType,
} from '../../../services/ZhuhushenheService';
import RoomPicture from './RoomPicture';
import RoomGoods from './RoomGoods';
import { connect } from 'dva';
import ConnectState from '../../../models/connect';
import HouseTypeImg from './HouseTypeImg';
import LeaseInfo from './LeaseInfo';
import RoomChargeList from '../pages/RoomChargeList';
import RoomItemList from '../../homeInspection/page/RoomItemList';
interface RoomAndHouseholdPros {
	roomUUid: string;
	roomAndHouse: boolean;
}
const RoomAndHousehold: React.FC<RoomAndHouseholdPros> = (props: any) => {
	useEffect(() => {
		getBuilds().then();
		findRoom().then();
		findAssetsType().then();
		findHouseType().then();
		findHouseStyle().then();
	}, []);
	let unitArray = [];
	let layerArray = [];
	const { common } = props;
	const { deviceList } = common;
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [key1, setKey] = useState('1');
	const [options1, setOptions] = useState([]);
	const [defaultOption, setDefaultOption] = useState<any>('');
	const [buildUuid, setBuildUuid] = useState('');
	const [buildName, setBuildName] = useState('');
	const [units, setUnits] = useState('');
	const [layers, setLayers] = useState('');
	const [assetsTypeList, setAssetsTypeList] = useState([]);
	const [houseTypeList, setHouseTypeList] = useState([]);
	const [houseStyleList, setHouseStyleList] = useState([]);
	const [builds, setBuilds] = useState([]);
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 9 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 15 },
		},
	};
	const formItemLayout1 = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 3 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 21 },
		},
	};
	const position = (value: any, selectedOptions: any) => {
		console.log('position', selectedOptions, selectedOptions[0] !== undefined);
		if (selectedOptions[0] !== undefined) {
			setBuildUuid(value[0]);
			setBuildName(selectedOptions[0].label);
			setUnits(value[1]);
			setLayers(value[2]);
		}
	};
	const findAssetsType = async () => {
		const res = await getAssetsType({});
		if (res.err === 0) {
			setAssetsTypeList(res.data);
		}
	};
	const findHouseType = async () => {
		const res = await getHouseType({});
		if (res.err === 0) {
			setHouseTypeList(res.data);
		}
	};
	const findHouseStyle = async () => {
		const res = await getHouseStyle({});
		if (res.err === 0) {
			setHouseStyleList(res.data);
		}
	};
	const findRoom = async () => {
		const res = await findRoomByUuid({
			roomUuid: props.roomUUid,
		});
		console.log('房间', res);
		if (res.err === 0) {
			setDefaultOption([res.data.buildUuid, res.data.unit + '', res.data.layer + '']);
			setBuildName(res.data.position.split('-')[0]);
			setBuildUuid(res.data.buildUuid);
			setUnits(res.data.unit);
			setLayers(res.data.layer);
			const formData: any = {
				roomNumber: res.data.roomNumber,
				deviceId: res.data.deviceId,
				acreage: res.data.acreage,
				builtInArea: res.data.builtInArea,
				note: res.data.note,
				defaultOption: [res.data.buildUuid, res.data.unit + '', res.data.layer + ''],
				chargeArea: res.data.chargeArea,
				gardenArea: res.data.gardenArea,
				loftArea: res.data.loftArea,
				type: res.data.type,
				state: res.data.state,
				houseType: res.data.houseType,
				houseStyle: res.data.houseStyle,
				sell: res.data.isSell,
				monthRent: res.data.monthRent,
				deposit: res.data.deposit,
				orientation: res.data.orientation,
				roomsNum: res.data.roomsNum,
				description: res.data.description,
				dayAreaPay:res.data.dayAreaPay
			};
			form.setFieldsValue(formData);
		}
	};
	const getBuilds = async () => {
		const res = await findBuilds({});
		// Api.postWithAuth('/api/zhuhu/find_builds', {}).then(res => {
		if (res.err === 0) {
			console.log('获得楼栋列表', res);
			let tempUnit = 1;
			let tempLayer = 1;
			for (let i = 0; i < res.data.length; i++) {
				if (res.data[i].unitNumber > tempUnit) {
					tempUnit = res.data[i].unitNumber;
				}
				if (res.data[i].layers > tempLayer) {
					tempLayer = res.data[i].layers;
				}
			}
			for (let i = 0; i < tempUnit; i++) {
				unitArray.push(i + 1);
			}
			for (let i = 0; i < tempLayer; i++) {
				layerArray.push(i + 1);
			}
			setBuilds(res.data);
		}
		getOptions(res.data);
		// });
	};
	const getOptions = (value: any) => {
		let temp: any = [];
		let data: any = value;
		if (data !== undefined && data !== '') {
			for (let i = 0; i < data.length; i++) {
				let option: any = {
					value: data[i].uuid,
					label: data[i].buildName,
					children: [],
				};
				for (let j = 0; j < data[i].unitNumber; j++) {
					let children1 = {
						value: j + 1 + '',
						label: j + 1 + '单元',
						children: [],
					};
					for (let z = 0; z < data[i].layers; z++) {
						let children2 = {
							value: z + 1 + '',
							label: z + 1 + '层楼',
						};
						(children1.children as any).push(children2);
					}
					(option.children as any).push(children1);
				}
				temp.push(option);
			}
			setOptions(temp);
		}
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			if (buildUuid === '' || buildUuid === undefined) {
				message.error('楼栋不能为空');
				return;
			}
			if (units === '' || units === undefined) {
				message.error('单元不能为空');
				return;
			}
			if (layers === '' || layers === undefined) {
				message.error('层数不能为空');
				return;
			}
			Api.postWithAuth('/api/zhuhu/updateRoom', {
				uuid: props.roomUUid,
				buildUuid: buildUuid,
				buildName: buildName,
				position: buildName + '-' + units + '单元-' + layers + '层',
				units: units,
				layers: layers,
				roomNumber: data.roomNumber,
				note: data.note,
				deviceId: data.deviceId,
				acreage: data.acreage,
				builtInArea: data.builtInArea,
				chargeArea: data.chargeArea,
				gardenArea: data.gardenArea,
				loftArea: data.loftArea,
				type: data.type,
				state: data.state,
				houseType: data.houseType,
				houseStyle: data.houseStyle,
				sell: data.sell,
				monthRent: data.monthRent,
				deposit: data.deposit,
				description: data.description,
				orientation: data.orientation,
				roomsNum: data.roomsNum,
				dayAreaPay:data.dayAreaPay
			}).then((res) => {
				console.log('', res);
				if (res.err === 0) {
					message.success('保存成功');
					findRoom();
				} else {
					message.error(res.msg);
				}
			});
		});
	};

	const everyOne = () => {
		if (key1 == '1' || key1 == 'cost') {
			return (
				<div style={{ padding: 30 }}>
					<Form form={form} {...formItemLayout}>
						<FormItem hidden={true} name={'deviceId'} />
						<div hidden={key1 == 'cost'}>
							<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
								<Col span={8}>
									<FormItem label={'位置'} name={'defaultOption'} rules={[{ required: true }]}>
										{console.log('options1::', options1, '')}
										<Cascader
											key={defaultOption}
											defaultValue={defaultOption}
											options={options1}
											onChange={position}
											changeOnSelect={true}
											allowClear={true}
										/>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'房间号'} name={'roomNumber'} rules={[{ required: true }]}>
										<Input />
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'使用状态'} name={'state'}>
										<Select>
											<Select.Option value={0}>空置</Select.Option>
											<Select.Option value={1}>已出租</Select.Option>
										</Select>
									</FormItem>
								</Col>
							</Row>
							<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
								<Col span={8}>
									<FormItem label={'资产类型'} name={'type'}>
										<Select>
											{assetsTypeList.map((item: any) => {
												return (
													<Select.Option key={item} value={item}>
														{item}
													</Select.Option>
												);
											})}
										</Select>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'户型'} name={'houseType'}>
										<Select>
											{houseTypeList.map((item: any) => {
												return (
													<Select.Option key={item} value={item}>
														{item}
													</Select.Option>
												);
											})}
										</Select>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'装修风格'} name={'houseStyle'}>
										<Select>
											{houseStyleList.map((item: any) => {
												return (
													<Select.Option key={item} value={item}>
														{item}
													</Select.Option>
												);
											})}
										</Select>
									</FormItem>
								</Col>
							</Row>
							<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
								<Col span={8}>
									<FormItem {...formItemLayout} label={'设备ID'} name="deviceId">
										<Select optionFilterProp="children" showSearch style={{ width: '100%' }}>
											{deviceList.length > 0
												? deviceList.map((item: any) => {
														return (
															<Select.Option key={item.deviceid} value={item.deviceid}>
																{item.description}
															</Select.Option>
														);
												  })
												: ''}
										</Select>
									</FormItem>
								</Col>

								<Col span={8}>
									<FormItem label={'套内面积(㎡)'} name={'builtInArea'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'计费面积(㎡)'} name={'chargeArea'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>
							</Row>
							<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
								<Col span={8}>
									<FormItem label={'建筑面积(㎡)'} name={'acreage'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'花园面积(㎡)'} name={'gardenArea'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'阁楼面积(㎡)'} name={'loftArea'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>
							</Row>
							<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
								<Col span={8}>
									<FormItem label={'朝向'} name={'orientation'}>
										<Input placeholder={'请输入'} />
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'房间数量'} name={'roomsNum'}>
										<InputNumber style={{ width: '100%' }} min={0} placeholder={'请输入'} />
									</FormItem>
								</Col>
							</Row>
							<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
								<Col span={24}>
									<FormItem {...formItemLayout1} label={'房间介绍'} name={'description'}>
										<Input.TextArea autoSize={{ minRows: 4 }} />
									</FormItem>
								</Col>
							</Row>
						</div>
						<div hidden={key1 == '1'}>
							<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
								<Col span={8}>
									<FormItem label={'是否出售'} name={'sell'} >
										<Select style={{ width: '100%' }}>
											<Select.Option key={'2'} value={2}>
												出售
											</Select.Option>
											<Select.Option key={'1'} value={1}>
												锁定
											</Select.Option>
											<Select.Option key={'0'} value={0}>
												不出售
											</Select.Option>
										</Select>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'月租金'} name={'monthRent'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>

								<Col span={8}>
									<FormItem label={'押金'} name={'deposit'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>
								<Col span={8}>
									<FormItem label={'1㎡/天租金'} name={'dayAreaPay'}>
										<InputNumber
											style={{ width: '100%' }}
											min={0}
											step={0.01}
											placeholder={'请输入'}
										/>
									</FormItem>
								</Col>
							</Row>
						</div>
						<Row
							hidden={key1 == 'cost'}
							className="margin-top--10"
							justify={'start'}
							style={{ alignItems: 'center' }}
						>
							<Col span={24}>
								<FormItem {...formItemLayout1} label={'备注'} name={'note'}>
									<Input.TextArea autoSize={{ minRows: 4 }} />
								</FormItem>
							</Col>
						</Row>

						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={3}></Col>
							<Col span={8}>
								<Button type="primary" onClick={updateItem}>
									保存
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			);
		} else if (key1 == '2') {
			return (
				<div style={{ paddingLeft: 20 }}>
					<HouseTypeImg
						imgType={'房间'}
						isHidenImgType={true}
						key={props.toString()}
						houseTypeId={props.roomUUid}
					/>
					{/*<RoomPicture roomUuid={props.roomUUid} />*/}
				</div>
			);
		} else if (key1 == '3') {
			return (
				<div style={{ paddingLeft: 20 }}>

					<RoomItemList roomUuid={props.roomUUid} />
				</div>
			);
		} else if (key1 == '4') {
			return (
				<div style={{ paddingLeft: 20 }}>
					<HouseholdList roomUuid={props.roomUUid} roomAndHouse={props.roomAndHouse} />
				</div>
			);
		}
		else if (key1 == '5') {
			return (
				<div style={{ paddingLeft: 20 }}>
					<LeaseInfo roomUuid={props.roomUUid}  />
				</div>
			);
		}
		else if (key1 == 'roomCharge') {
			return (
				<div style={{ paddingLeft: 20 }}>
					<RoomChargeList roomUuid={props.roomUUid}  />
				</div>
			);
		}
		else {
			return <div style={{ paddingLeft: 20 }}></div>;
		}
	};
	return (
		<>
			<div style={{ minWidth: 1024, backgroundColor: '#fff' }}>
				<Row>
					<Col span={3}>
						<Menu
							onClick={(value: any) => {
								console.log('value::', value);
								setKey(value.key);
							}}
							style={{ width: '100%' }}
							defaultSelectedKeys={['1']}
							defaultOpenKeys={['sub1']}
							mode={'inline'}
							theme={'light'}
						>
							<Menu.Item key="1" style={{ margin: 0 }}>
								房间信息
							</Menu.Item>
							<Menu.Item key="2" style={{ margin: 0 }}>
								房间图片
							</Menu.Item>
							<Menu.Item key="3" style={{ margin: 0 }}>
								物品清单
							</Menu.Item>
							<Menu.Item key="4" style={{ margin: 0 }}>
								住户信息
							</Menu.Item>
							<Menu.Item key="5" style={{ margin: 0 }}>
								租赁信息
							</Menu.Item>
							<Menu.Item key="cost" style={{ margin: 0 }}>
								收费管理
							</Menu.Item>
							<Menu.Item key="roomCharge" style={{ margin: 0 }}>
								设置收费项目
							</Menu.Item>
						</Menu>
					</Col>
					<Col span={21}>{everyOne()}</Col>
				</Row>
			</div>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(RoomAndHousehold);
