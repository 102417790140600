import React, { useEffect } from 'react';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, message } from 'antd';
import { WuyeRoom } from './WuyeRoom';
import ApiImp from '../../../data/API';
const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 16 },
};
interface AddLeaseInfoFormProps {
	roomInfo: WuyeRoom;
	callback: () => void;
}
const AddLeaseInfoForm: React.FC<AddLeaseInfoFormProps> = (props: AddLeaseInfoFormProps) => {
	useEffect(() => {
		console.log('资产信息：：：', props.roomInfo, props.roomInfo.uuid);
	}, []);
	const onFinish = (values: any) => {
		console.log('Success:', values);
		values = {
			...values,
			contractEndTime: values.contractEndTime.format('YYYY-MM-DD HH:mm:ss'),
			contractStartTime: values.contractStartTime.format('YYYY-MM-DD HH:mm:ss'),
		};
		addLeaseInfo(values);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};
	const addLeaseInfo = (params: any) => {
		ApiImp.postWithAuth('/api/zhuhu/add_lease_info', params).then((res: any) => {
			if (res.err === 0) {
				message.success(res.msg);
				props.callback();
			} else {
				message.error(res.msg);
			}
		});
	};
	return (
		<div>
			<Form
				{...layout}
				name="操作"
				initialValues={{ assetsUuid: props.roomInfo.uuid }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					label="资产id"
					hidden={true}
					name="assetsUuid"
					rules={[{ required: true, message: '请输入' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item label="租赁方" name="name" rules={[{ required: true, message: '请输入' }]}>
					<Input />
				</Form.Item>
				{props.roomInfo.type === '公寓' && (
					<Form.Item label="租赁方手机号" name="phoneNo" rules={[{ required: true, message: '请输入' }]}>
						<Input />
					</Form.Item>
				)}
				{props.roomInfo.type === '公寓' && (
					<Form.Item label="公司名称" name="companyName">
						<Input />
					</Form.Item>
				)}

				<Form.Item label="租赁方证件号" name="zhengJianHao" rules={[{ required: true, message: '请输入' }]}>
					<Input />
				</Form.Item>
				<Form.Item label="合同号" name="contractNo" rules={[{ required: true, message: '请输入' }]}>
					<Input />
				</Form.Item>
				<Form.Item label="合同单价" name="price" rules={[{ required: true, message: '请输入' }]}>
					<InputNumber />
				</Form.Item>
				<Form.Item
					label="合同开始时间"
					name="contractStartTime"
					rules={[{ required: true, message: '请输入' }]}
				>
					<DatePicker />
				</Form.Item>
				<Form.Item label="合同结束时间" name="contractEndTime" rules={[{ required: true, message: '请输入' }]}>
					<DatePicker />
				</Form.Item>

				<Form.Item wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } }}>
					<Button type="primary" htmlType="submit">
						提交
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
export default AddLeaseInfoForm;
