import React, { useEffect, useState } from 'react';
import { Card, Col, List, Row, Statistic } from 'antd';
import LineChart from '../../components/LineChart';
import { DataContext } from '../controlPlatform/CurrentPlatform';
import BarChart from '../../components/BarChart';
import { getRoomStatistics } from '../../services/StatisticsService';
import ColumnChart from '../../components/ColumnChart';
import { ColumnChartContext } from '../controlPlatform/CurrentPhoneBrandChart';

const OptionReport: React.FC = (props: any) => {
	const [roomData, setRoomData] = useState({ total: 0, totalAcreage: 0, totalVacant: 0 });
	const [carData, setCarData] = useState<[any]>([
		{ name: '进场车辆', x: '2022-07-13', y: 153 },
		{ name: '进场车辆', x: '2022-07-14', y: 192 },
		{ name: '进场车辆', x: '2022-07-15', y: 132 },
		{ name: '进场车辆', x: '2022-07-16', y: 146 },
		{ name: '进场车辆', x: '2022-07-17', y: 161 },
		{ name: '进场车辆', x: '2022-07-18', y: 181 },
		{ name: '进场车辆', x: '2022-07-19', y: 154 },
		{ name: '出场车辆', x: '2022-07-13', y: 148 },
		{ name: '出场车辆', x: '2022-07-14', y: 186 },
		{ name: '出场车辆', x: '2022-07-15', y: 131 },
		{ name: '出场车辆', x: '2022-07-16', y: 144 },
		{ name: '出场车辆', x: '2022-07-17', y: 161 },
		{ name: '出场车辆', x: '2022-07-18', y: 176 },
		{ name: '出场车辆', x: '2022-07-19', y: 147 }]);
	const [userData, setUserData] = useState<[any]>([
		{ name: '人数', x: '2022-07-13', y: 1663 },
		{ name: '人数', x: '2022-07-14', y: 1659 },
		{ name: '人数', x: '2022-07-15', y: 1575 },
		{ name: '人数', x: '2022-07-16', y: 1299 },
		{ name: '人数', x: '2022-07-17', y: 1400 },
		{ name: '人数', x: '2022-07-18', y: 1622 },
		{ name: '人数', x: '2022-07-19', y: 1479 },
		{ name: '人次', x: '2022-07-13', y: 4623 },
		{ name: '人次', x: '2022-07-14', y: 4686 },
		{ name: '人次', x: '2022-07-15', y: 4385 },
		{ name: '人次', x: '2022-07-16', y: 3906 },
		{ name: '人次', x: '2022-07-17', y: 4016 },
		{ name: '人次', x: '2022-07-18', y: 4459 },
		{ name: '人次', x: '2022-07-19', y: 3876 }]);
	const [barData, setBarData] = useState<[any?]>([{
			year: '2022-01',
			value: 6100012,
			type: '已缴'
		},
		{
			year: '2022-02',
			value: 6100012,
			type: '已缴'
		},
		{
			year: '2022-03',
			value: 6008031,
			type: '已缴'
		},
		{
			year: '2022-04',
			value: 6008031,
			type: '已缴'
		},
		{
			year: '2022-05',
			value: 6008031,
			type: '已缴'
		},
		{
			year: '2022-06',
			value: 6018032,
			type: '已缴'
		},
		{
			year: '2022-01',
			value: 67854,
			type: '未缴'
		},
		{
			year: '2022-06',
			value: 67854,
			type: '未缴'
		},
		{
			year: '2022-05',
			value: 43671,
			type: '未缴'
		},
		{
			year: '2022-04',
			value: 32148,
			type: '未缴'
		},
		{
			year: '2022-03',
			value: 32148,
			type: '未缴'
		},
		{
			year: '2022-02',
			value: 65921,
			type: '未缴'
		},
		{
			year: '2022-01',
			value: 12873,
			type: '未缴'
		}
	]);
	const dianData: any = [
		{
			x: '2022-07-13',
			y: 2345.0,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-14',
			y: 2556.1,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-15',
			y: 2522.8,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-16',
			y: 2598.9,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-17',
			y: 2605.6,
			name: '用电量(KWh)'
		},
		{
			x: '2022-07-18',
			y: 2477.3,
			name: '用电量(KWh)'
		},

		{
			x: '2022-07-19',
			y: 2562.1,
			name: '用电量(KWh)'
		},




	];
	const [tabKey, setTabKey] = useState('电表');
	const tabList = [
		{
			key: '电表',
			tab: '电表',
		},
		{
			key: '水表',
			tab: '水表',
		},
		{
			key: '气表',
			tab: '气表',
		},
		{
			key: '热量表',
			tab: '热量表',
		},
	];
	const [dataSource, setDataSource] = useState<[any?]>([{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'},{name: '姓名', count: '次数'}])
	useEffect(() => {
		getRoomData().then();
	}, []);
	const getRoomData = async () => {
		let rsp = await getRoomStatistics({});
		if (rsp.err === 0) {
			setRoomData(rsp.data);
		}
	};
	const  config = {
		xField: 'x',
		yField: 'y',
		seriesField: 'name',
		label: {
			// 可手动配置 label 数据标签位置
			position: 'middle',
			// 'top', 'bottom', 'middle'
			// 可配置附加的布局方法
			layout: [
				// 柱形图数据标签位置自动调整
				{
					type: 'interval-adjust-position',
				}, // 数据标签防遮挡
				{
					type: 'interval-hide-overlap',
				}, // 数据标签文颜色自动调整
				{
					type: 'adjust-color',
				},
			],
		},
	};

	return (
		<div className="content-big">
			<Row gutter={12}>
				<Col span={6}>
					<Card title={'资产总数'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={roomData.total}
										   valueStyle={{ color: '#FFBF24', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'资产总面积'} valueStyle={{ color: '#000000', fontSize: 14 }}
										   value={211185}
										   suffix={'m²'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'闲置率'} valueStyle={{ color: '#000000', fontSize: 14 }}
										   value={(4.6).toFixed(2)}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'业户总数'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={Math.ceil(roomData.total * 93.4/100)}
										   valueStyle={{ color: '#FD5F26', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'租户人数'} valueStyle={{ color: '#000000', fontSize: 14 }} value={Math.ceil(roomData.total * 93.4/100)}
										   suffix={'人'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'出租率'} valueStyle={{ color: '#000000', fontSize: 14 }} value={93.40}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'设备总数'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={3152} valueStyle={{ color: '#41DCEA', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'运行中设备'} valueStyle={{ color: '#000000', fontSize: 14 }} value={3152}
										   suffix={'台'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'异常运行率'} valueStyle={{ color: '#000000', fontSize: 14 }} value={0}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'服务请求'} style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Row justify={'center'}>
							<Col>
								<Statistic value={13370}
										   valueStyle={{ color: '#5BE84E', fontSize: 22, fontWeight: 800 }}>

								</Statistic>
							</Col>
						</Row>
						<Row justify={'space-around'}>
							<Col>
								<Statistic title={'未处理事件'} valueStyle={{ color: '#000000', fontSize: 14 }} value={9}
										   suffix={'件'}>

								</Statistic>
							</Col>
							<Col>
								<Statistic title={'事件处理率'} valueStyle={{ color: '#000000', fontSize: 14 }} value={((13370-9)/13370*100).toFixed(2)}
										   suffix={'%'}>

								</Statistic>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col order={2} span={12}>
					<Card title={'车行统计'}>
						<DataContext.Provider value={carData}>
							<LineChart/>
						</DataContext.Provider>
					</Card>
				</Col>
				<Col order={1} span={12}>

					<Card title={'人行统计'}>
						<DataContext.Provider value={userData}>
							<LineChart/>
						</DataContext.Provider>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Card title={'缴费记录'}>
						<DataContext.Provider value={barData}>
							<BarChart/>
						</DataContext.Provider>
					</Card>
				</Col>
				<Col span={12}>
					<Card
						title={'用能统计'}
						// tabList={tabList}
						bodyStyle={{ paddingLeft: 15 }}
						activeTabKey={tabKey}
						onTabChange={key => {
							setTabKey(key);
						}}
					>
						{/*<DataContext.Provider value={dianData}>*/}
						{/*	<BarChart/>*/}
						{/*</DataContext.Provider>*/}
						<ColumnChartContext.Provider value={dianData}>
							<ColumnChart {...config}/>
						</ColumnChartContext.Provider>
					</Card>
				</Col>
				{/*<Col span={6}>*/}
				{/*	<List*/}
				{/*		style={{ height: '100%', background: '#fff' }}*/}
				{/*		bordered={true}*/}
				{/*		split={true}*/}
				{/*		header={'用能排行'}*/}
				{/*		itemLayout={'vertical'}*/}
				{/*		dataSource={dataSource}*/}
				{/*		pagination={*/}
				{/*			false*/}
				{/*		}*/}
				{/*		renderItem={(item: any) => (*/}
				{/*			<List.Item style={{ width: '100%' }} extra={item.count}>*/}
				{/*				{item.name}*/}
				{/*			</List.Item>*/}
				{/*		)}*/}
				{/*	/>*/}
				{/*</Col>*/}
			</Row>
		</div>
	);
};
export default OptionReport;
