import * as React from 'react';
import { Col, Row, Input, Checkbox, Pagination } from 'antd';
import DAl from '../../../data/DAL';
const Search = Input.Search;
interface CheckTableInterFace {
	onChange: any;
	onCheckAllChange: any;
	onSearch: any;
	page: number;
	size: number;
	total: number;
	data: any;
	selectDevice: any;
	pageChange: any;
	onShowSizeChange: any;
	disabled: boolean;
	indeterminate: boolean;
	checkAll: boolean;
	isSearch: boolean;
	isShowCheckAll: boolean;
}
export default class CheckTable extends React.Component<CheckTableInterFace> {
	constructor(props: any) {
		super(props);
	}
	// componentWillMount(): void {
	//     this.props.data();
	// }

	render() {
		return (
			<div style={{ minWidth: 600 }}>
				{this.props.isSearch ? (
					<Row type={'flex'} gutter={12}>
						<Col span={8} push={16}>
							<Search onSearch={this.props.onSearch} />
						</Col>
					</Row>
				) : null}

				<br />
				<Row type={'flex'} gutter={8} justify={'start'} align={'middle'}>
					<Checkbox.Group
						style={{ width: '100%' }}
						key={this.props.selectDevice.toString()}
						disabled={this.props.disabled}
						value={this.props.selectDevice}
						onChange={this.props.onChange}
					>
						{this.props.data.map((item: any) => {
							return (
								<Col key={item.key} span={8} style={{ padding: 4 }}>
									<Checkbox value={item.deviceId}>{item.deviceName}</Checkbox>
								</Col>
							);
						})}
					</Checkbox.Group>
				</Row>
				<br />
				{this.props.isShowCheckAll ? (
					<Row type={'flex'} gutter={8}>
						<Col span={4}>
							<Checkbox
								indeterminate={this.props.indeterminate}
								onChange={this.props.onCheckAllChange}
								checked={this.props.checkAll}
							>
								本页全选
							</Checkbox>
						</Col>
					</Row>
				) : null}

				<br />
				<Row type={'flex'} gutter={8}>
					<Col span={24}>
						<Pagination
							showQuickJumper={true}
							defaultCurrent={this.props.page}
							current={this.props.page}
							total={this.props.total}
							pageSize={this.props.size}
							size={'small'}
							onChange={this.props.pageChange}
							showTotal={(e) => {
								return '共' + e + '项';
							}}
							showSizeChanger={true}
							pageSizeOptions={DAl.pageSize}
							onShowSizeChange={this.props.onShowSizeChange}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}
