import * as React from 'react';
import CheckTable from './CheckTable';

interface CheckTableInterFace {
	allDevice: Array<any>;
	selectDevice: Array<string>;
	onCancel: any;
}
export default class DeviceList extends React.Component<CheckTableInterFace> {
	allDevice = this.props.allDevice;
	searchDevice = this.props.allDevice;

	state: any;
	constructor(props: any) {
		super(props);
		this.state = {
			page: 1,
			size: 30,
			data: [],
			selectDevice: props.selectDevice,
			indeterminate: false,
			checkAll: false,
		};
	}

	componentWillMount(): void {
		this.searchDevice = this.allDevice.filter((item: any) => {
			return this.state.selectDevice.includes(item.deviceId);
		});
		if (this.state.page === 1) {
			this.getData();
		} else {
			this.setState({ page: 1 }, this.getData);
		}
	}

	pageChange = (page: number, pageSize: number) => {
		console.log('点击页码：', page);
		this.setState({ page }, this.getData);
	};

	getData = () => {
		console.log('刷新数据：', this.searchDevice);
		let data = this.searchDevice.slice((this.state.page - 1) * this.state.size, this.state.page * this.state.size);
		let indeterminate = 0;
		data.map((item: any) => {
			if (this.state.selectDevice.includes(item.deviceId)) {
				indeterminate++;
			}
		});
		this.setState({
			data,
			indeterminate: !!indeterminate && indeterminate < data.length,
			checkAll: indeterminate === data.length,
		});
	};

	onShowSizeChange = (current: number, size: number) => {
		this.setState({ page: 1, size: size }, this.getData);
	};

	render() {
		return (
			<CheckTable
				isSearch={false}
				isShowCheckAll={false}
				checkAll={this.state.checkAll}
				size={this.state.size}
				disabled={true}
				onChange={null}
				onSearch={null}
				data={this.state.data}
				selectDevice={this.state.selectDevice}
				indeterminate={this.state.indeterminate}
				onCheckAllChange={null}
				onShowSizeChange={this.onShowSizeChange}
				page={this.state.page}
				pageChange={this.pageChange}
				total={this.searchDevice.length}
			/>
		);
	}
}
