import React, { useEffect } from 'react';
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Select,
	Spin,
	Switch,
	TreeSelect,
	Upload,
} from 'antd';
import { useState } from 'react';
import HouseTypeImg from '../../assets/components/HouseTypeImg';
import { findSchemeInfoByUuid, updateScheme ,getCompanyInfoByName} from '../../../services/scheme';

import { Player, ControlBar, BigPlayButton } from 'video-react';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Api from '../../../data/API';
import UploadImgFormItem from '../../../components/UploadImgFormItem';
import ConnectState from '../../../models/connect';
import { connect } from 'dva';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import DAL from '../../../data/DAL';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import GsTreeSelect from '../../../components/GsTreeSelect';
import UploadFilFormItem from '../../../components/UploadFileFormItem';
import CheckedComponents from './CheckedComponents';

const UpdateScheme: React.FC = (props: any) => {
	useEffect(() => {
		getData().then();
		// getLiuchengDanList().then();
	}, []);
	console.log('props::::', props);
	const maxFileSizeLimit = 30;
	const uuid = props.uuid;
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const { common } = props;
	const { scenarioTypeList = [], schemeTechList = [] } = props;
	const { empUserList=[]} = common
	const [dateKey, setdateKey] = useState(new Date().getTime().toString());
	const [scenarioImg, setscenarioImg] = useState('');
	const [bannerType, setbannerType] = useState('');
	const [bannerVideo, setbannerVideo] = useState('');
	const [uploadLoading, setuploadLoading] = useState(false);
	const [program, setprogram] = useState('');
	const [loading, setloading] = useState(false);
	const [liuchengList, setLiuchengList] = useState([]);
	const [typeUuid, settypeUuid] = useState([]);
	const [techUuid, settechUuid] = useState([]);
	const [fileList, setfileList] = useState([]);
	const getLiuchengDanList = async () => {
		const data = {
			state: true,
			selectedTags: '',
			searchContent: '',
		};
		const res = await getAllLiucheng(data);
		console.log('res:::', res);
		if (res.err === 0) {
			setLiuchengList(res.data || []);
		}
	};

	const saveItem = () => {
		form.validateFields().then(async (data) => {
			console.log('data::', data);
			const formData: any = {
				uuid: props.uuid,
				name: data.name,
				remark: data.remark,
				listImg: data.scenarioImg,
				button: JSON.stringify(data.button),
				successStories: JSON.stringify(data.successStories),
				yycj: data.yycj,
				gjjs: data.gjjs,
				mxkh: data.mxkh,
				program: data.program,
				bannerType: data.bannerType,
				bannerVideo: bannerVideo,
				phone: data.phone,
				companyName: data.companyName,
				productAnnex: DAL.dal.isNotNull(data.productAnnex) ? data.productAnnex.toString() : '',
				companyAnnex: DAL.dal.isNotNull(data.companyAnnex) ? data.companyAnnex.toString() : '',
				szSelected: data.szSelected !== undefined ? data.szSelected : false,
				schemeTop: data.schemeTop !== undefined ? data.schemeTop : false,
				techTop: data.techTop !== undefined ? data.techTop : false,
				typeUuid: data.typeUuid,
				techUuid: data.techUuid,
				contactName: data.contactName,
				companyNote: data.companyNote,
				solveQuestion: data.solveQuestion,
				productPrice: data.productPrice,
				painPoints: data.painPoints,
				requiredResources: data.requiredResources,
				sortBy: data.sortBy,
				partner: data.partner,
				createdEmpUuid:data.createdEmpUuid,
			};
			setloading(true);
			const res = await updateScheme(formData);
			setloading(false);
			if (res.err == 0) {
				message.success('修改成功');
				props.onOk();
			} else {
				message.error(res.message);
			}
		});
	};
	const uploadVideo = (info: any) => {
		const { status } = info.file;
		setbannerVideo('');
		if (status == undefined) return;

		let fileList = [...info.fileList];
		console.log('UploadFile.onChange: ', status, fileList.length);

		// 限制最多传一个文件
		fileList = fileList.slice(-1);
		setfileList(fileList as []);
		if (status === 'removed') {
			setbannerVideo('');
		}
		if (status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			setbannerVideo(info.file.response.url);
			message.success(`${info.file.name} 文件上传成功`);
		} else if (status === 'error') {
			message.error(`${info.file.name} 文件上传失败`);
		}
	};
	const getData = async () => {
		const res = await findSchemeInfoByUuid({ uuid: props.uuid });
		if (res.err == 0) {
			console.log(res);
			const { scheme = {} } = res.data;
			console.log('scheme', scheme);
			const formData = {
				name: scheme.name,
				remark: scheme.remark,
				scenarioImg: scheme.listImg,
				button: JSON.parse(scheme.button || '[]'),
				successStories: JSON.parse(scheme.successStories || '[]'),
				yycj: scheme.yycj,
				gjjs: scheme.gjjs,
				mxkh: scheme.mxkh,
				bannerType: scheme.bannerType,
				phone: scheme.phone,
				program: scheme.program,
				companyName: scheme.companyName,
				companyAnnex: DAL.dal.isNotNull(scheme.companyAnnex) ? scheme.companyAnnex : '',
				productAnnex: DAL.dal.isNotNull(scheme.productAnnex) ? scheme.productAnnex : '',
				szSelected: scheme.szSelected !== undefined ? scheme.szSelected : false,
				schemeTop: scheme.schemeTop !== undefined ? scheme.schemeTop : false,
				techTop: scheme.techTop !== undefined ? scheme.techTop : false,
				techUuid: scheme.techUuid,
				typeUuid: scheme.typeUuid,
				contactName: scheme.contactName,
				companyNote: scheme.companyNote,
				solveQuestion: scheme.solveQuestion,
				productPrice: scheme.productPrice,
				painPoints: scheme.painPoints,
				requiredResources: scheme.requiredResources,
				partner: scheme.partner,
				sortBy: scheme.sortBy,
				createdEmpUuid:scheme.createdEmpUuid
			};
			if (DAL.dal.isNotNull(scheme.bannerVideo)) {
				let file: any = [
					{
						uid: new Date().getTime(),
						name: '附件',
						status: 'done',
						url: Api.cdnRootPath + scheme.bannerVideo,
						response: {
							err: 0,
							url: Api.cdnRootPath + scheme.bannerVideo,
						},
					}
				]
				setfileList(file)
			}
			setbannerType(scheme.bannerType);
			setbannerVideo(scheme.bannerVideo);
			// setprogram(scheme.program);
			form.setFieldsValue({ ...formData });
			setdateKey(new Date().getTime().toString());
		}
	};
	const formLayout = {
		labelCol: { span: 7 },
		wrapperCol: { span: 16 },
	};

	/**
	 * 检查文件尺寸
	 */
	const beforeUploadFile = (file: RcFile, fileList: RcFile[]): boolean => {
		const fileSize = file.size / 1024 / 1024;
		console.log('UploadFile.beforeUpload: ', maxFileSizeLimit, fileSize);

		const isInLimit = fileSize < maxFileSizeLimit;
		if (!isInLimit) {
			message.error('上传文件不要超过' + maxFileSizeLimit + 'M');
		}
		return isInLimit;
	};

	const getUploadApiExtraData = (file: RcFile) => {
		return {
			wuye_uuid: localStorage.getItem('wyUuid'),
		};
	};
	const draggerProps = {
		name: 'file',
		multiple: false, // 禁止选择多个文件
		action: Api.apiRootPath + '/api/picture/upload_qiniu_two',
		beforeUpload: beforeUploadFile,
		onChange: uploadVideo,
		data: getUploadApiExtraData,
		showUploadList: {
			showDownloadIcon: true,
			downloadIcon: 'download',
			showRemoveIcon: true,
		},
	};

	const getCompany =  async (companyName: string) => {
		let param = {
			companyName: companyName
		};
		let rsp = await getCompanyInfoByName(param);
		if (rsp.err === 0) {
			let value = form.getFieldValue("companyNote");
			if (!value) {
				form.setFieldsValue({
					companyNote: rsp.data.displayIntroduction
				})
			}
		}
	}

	return (
		<>
			<Modal
				width={1300}
				maskClosable={false}
				confirmLoading={loading}
				title="编辑"
				visible={props.visible}
				onCancel={props.onCancel}
				onOk={saveItem}
			>
				<Form {...formLayout} scrollToFirstError={true} form={form}>
					<FormItem
						label="方案/产品名称"
						name={'name'}
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!DAL.dal.isNotNull(value)) {
										return Promise.reject(new Error('请填写方案/产品名称'));
									} else if (value.length > 15) {
										return Promise.reject(new Error('字数不能超过15个'));
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<Input></Input>
					</FormItem>
					<FormItem label="排序值" tooltip="值越大越靠前" name={'sortBy'}>
						<InputNumber style={{ width: '100%' }}></InputNumber>
					</FormItem>
					<FormItem label="企业名称" name={'companyName'} rules={[{ required: true }]}>
						<Input onBlur={(e) => {
							console.log('data', e.target.value)
							getCompany(e.target.value)
						}}></Input>
					</FormItem>
					<FormItem label="企业简介" name={'companyNote'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem label="产品/技术可解决哪方面问题" name={'solveQuestion'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem label="方案价格" name={'productPrice'}>
						<Input></Input>
					</FormItem>
					<FormItem label="痛点/难点" name={'painPoints'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem label="需要的资源" name={'requiredResources'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem label="合作伙伴" name={'partner'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem label="联系人" required={true} name={'contactName'}>
						<Input></Input>
					</FormItem>
					<FormItem label="联系电话" required={true} name={'phone'}>
						<Input></Input>
					</FormItem>
					<FormItem label="方案/产品描述" required={true} name={'remark'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem label="列表图" name={'scenarioImg'} rules={[{ required: true, message: '请上传图片' }]}>
						<UploadImgFormItem
							hiddenImgCorp={false}
							multiple={false}
							listType={'picture-card'}
							heightRatio={1}
							widthRatio={1}
						/>
					</FormItem>
					<FormItem label="应用场景" name={'typeUuid'}>
						<CheckedComponents
							key={scenarioTypeList.toString()}
							dataList={scenarioTypeList}
						></CheckedComponents>
					</FormItem>
					<FormItem label="通用技术" name={'techUuid'}>
						<CheckedComponents
							key={schemeTechList.toString()}
							dataList={schemeTechList}
						></CheckedComponents>
					</FormItem>
					<FormItem name={'productAnnex'} label="产品展示附件">
						<UploadFilFormItem multiple={true}></UploadFilFormItem>
					</FormItem>
					<FormItem name={'companyAnnex'} label="企业展示附件">
						<UploadFilFormItem multiple={true}></UploadFilFormItem>
					</FormItem>
					<>
						<FormItem label="banner视频" name={'banner_video'}>
							<Dragger {...draggerProps} fileList={fileList}>
								<p className="ant-upload-text">点击或拖拽文件到此处上传</p>
								<p className="ant-upload-hint">
									请上传5:3比例得视频文件,文件大小不要超过{maxFileSizeLimit}M
								</p>
							</Dragger>
							{bannerVideo != '' && bannerVideo != undefined && (
								<>
									<Player
										key={Api.cdnRootPath + bannerVideo}
										fluid={false}
										height={300}
										width={500}
										// autoPlay
										src={Api.cdnRootPath + bannerVideo}
									>
										<BigPlayButton position="center" />
									</Player>
								</>
							)}
							{/* </Spin> */}
						</FormItem>
					</>
					<FormItem label="面向客户" name={'mxkh'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem label="方案优势" rules={[{ required: true }]} name={'program'}>
						<Input.TextArea></Input.TextArea>
					</FormItem>

					<Row>
						<Col span={4} style={{ textAlign: 'right' }}>
							图文展示：
						</Col>
						<Col span={16}>
							<HouseTypeImg
								imgType={'数智应用场景详情图'}
								isHidenImgType={true}
								key={uuid}
								houseTypeId={uuid}
							/>
						</Col>
						<Col></Col>
					</Row>
					<Form.Item shouldUpdate noStyle>
						{() => (
							<Row>
								<Col span={4} style={{ textAlign: 'right' }}>
									服务案例：
								</Col>
								<Col span={16}>
									<Form.List name={'successStories'}>
										{(fields, { add, remove }) => (
											<>
												{fields.map((field: any, index: number) => (
													<Card
														size="small"
														bordered={true}
														title={`第${index + 1}个案例`}
														extra={
															fields.length > 0 && (
																<MinusCircleOutlined
																	onClick={() => remove(field.name)}
																/>
															)
														}
													>
														<Form.Item
															{...field}
															label={'案例名称'}
															name={[field.name, 'name']}
															fieldKey={[field.fieldKey, 'name']}
														>
															<Input></Input>
														</Form.Item>
														<Form.Item
															{...field}
															label={'案例图'}
															name={[field.name, 'image']}
															fieldKey={[field.fieldKey, 'image']}
														>
															<UploadImgFormItem
																hiddenImgCorp={true}
																multiple={true}
																listType={'picture-card'}
																widthRatio={7}
																heightRatio={3}
															/>
														</Form.Item>
														<Form.Item
															{...field}
															label={'服务对象'}
															name={[field.name, 'serviceObject']}
															fieldKey={[field.fieldKey, 'serviceObject']}
														>
															<Input></Input>
														</Form.Item>
														<Form.Item
															{...field}
															label={'简介'}
															name={[field.name, 'remark']}
															fieldKey={[field.fieldKey, 'remark']}
														>
															<Input.TextArea></Input.TextArea>
														</Form.Item>
														<Form.Item
															{...field}
															label={'成效'}
															name={[field.name, 'chengXiao']}
															fieldKey={[field.fieldKey, 'chengXiao']}
														>
															<Input.TextArea></Input.TextArea>
														</Form.Item>
														<Form.Item
															{...field}
															label={'附件'}
															name={[field.name, 'fujian']}
															fieldKey={[field.fieldKey, 'fujian']}
														>
															<UploadFilFormItem multiple={true}></UploadFilFormItem>
														</Form.Item>
													</Card>
												))}

												<Form.Item>
													<Button
														type="dashed"
														style={{ marginTop: 10 }}
														onClick={() => add()}
														block
														icon={<PlusOutlined />}
													>
														添加
													</Button>
												</Form.Item>
											</>
										)}
									</Form.List>
								</Col>
								<Col></Col>
							</Row>
						)}
					</Form.Item>
					<FormItem
						tooltip={'选中后会在首页数智精选展示'}
						valuePropName="checked"
						label="数智精选"
						name={'szSelected'}
					>
						<Switch></Switch>
					</FormItem>
					<FormItem
						tooltip={'选中后会在首页应用场景展示'}
						valuePropName="checked"
						label="应用场景置顶"
						name={'schemeTop'}
					>
						<Switch></Switch>
					</FormItem>
					<FormItem
						tooltip={'选中后会在首页通用技术展示'}
						valuePropName="checked"
						label="通用技术置顶"
						name={'techTop'}
					>
						<Switch></Switch>
					</FormItem>
					<FormItem label={'创建人'} name={'createdEmpUuid'}>
						<TreeSelect
							key={empUserList}
							treeData={empUserList}
							showSearch
							multiple={false}
							treeNodeFilterProp="title"
							searchPlaceholder="请选择"
							dropdownStyle={{ overflow: 'auto' }}
							style={{ width: 300, overflow: 'auto' }}
						/>
					</FormItem>
				</Form>
			</Modal>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(UpdateScheme);
