import Api from '../data/API';

export async function findPaperList(params: any) {
	return Api.postWithAuth('/api/testpaper/findPaperList', params);
}
export async function findAnswerRecordList(params: any) {
	return Api.postWithAuth('/api/testpaper/findAnswerRecordList', params);
}

export async function findQuestionBankList(params: any) {
	return Api.postWithAuth('/api/testpaper/findQuestionBankList', params);
}

