import React, {useEffect, useRef, useState} from 'react';
import { Col, Row, Tag, Input, Table, message, Pagination, Divider, Modal, Image } from 'antd';
import ApiImp from '../../../data/API';
import moment from 'moment';
import AuthorizedView from '../../../util/AuthorizedView';
import GSTable from '../../../components/GSTable';
import { getList } from '../../../services/ZhuhushenheService';
import {ActionType} from "@ant-design/pro-table";

const { CheckableTag } = Tag;
const { confirm } = Modal;

const ApplyCommunity: React.FC = (props: any) => {
	const [auditType, setAuditType] = useState('');
	const [search, setSearch] = useState('');
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(1);
	const [loading, setLoading] = useState(false);
	const [modelLoading, setModelLoading] = useState(false);
	const actionRef = useRef<ActionType>();
	const columns = [
		{
			title: '姓名',
			dataIndex: 'name',
		},
		{
			title: '性别',
			dataIndex: 'gender',
			render: (text: string, record: any) => {
				return <span>{record.gender === 0 ? '男' : '女'}</span>;
			},
		},
		{
			title: '身份证号',
			dataIndex: 'idNumber',
		},
		{
			title: '身份类型',
			dataIndex: 'householdType',
		},
		{
			title: '租住到期时间',
			dataIndex: 'expireDate',
			render: (text: string, record: any) => {
				return <span>{moment(text).format('YYYY-MM-DD')}</span>;
			},
		},
		{
			title: '人脸照片',
			dataIndex: 'faceImg',
			render: (text: string, record: any) => {
				return (
					<Image
						height={100}
						width={70}
						src={ApiImp.apiRootPath + '/api/file/get_file_qiniu/' + record.faceImg + '/' + ApiImp.wuyeUuid}
					/>
				);
			},
		},
		{
			title: '房间号',
			dataIndex: 'roomNum',
		},
		{
			title: '创建时间',
			dataIndex: 'createdTime',
			render: (text: string, record: any) => {
				return <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>;
			},
		},
		{
			title: '操作',
			dataIndex: 'active',
			render: (text: string, record: any) => {
				return (
					<div>
						<ul className="ant-list-item-action">
							<AuthorizedView needAuthority={'household:applyCommunity:active'}>
								<li>
									{record.status === '通过' ? (
										'通过'
									) : (
										<a
											onClick={() => {
												audit('通过', record);
											}}
										>
											通过
										</a>
									)}
								</li>
								<li>
									{record.status === '拒绝' ? (
										'拒绝'
									) : (
										<a
											onClick={() => {
												audit('拒绝', record);
											}}
										>
											拒绝
										</a>
									)}
								</li>
							</AuthorizedView>
						</ul>
					</div>
				);
			},
		},
	];


	const tableSearchBarRender = () => [
		<CheckableTag
			onChange={(checked) => {
				setAuditType('');
			}}
			style={{ width: 80, textAlign: 'center' }}
			className={'faceTag'}
			checked={auditType === ''}
		>
			全部
		</CheckableTag>,
		<CheckableTag
			onChange={(checked) => {
				setAuditType('等待审核');
			}}
			style={{ width: 80, textAlign: 'center' }}
			className="faceTag"
			checked={auditType === '等待审核'}
		>
			等待审核
		</CheckableTag>,
		<CheckableTag
			onChange={(checked) => {
				setAuditType('通过');
			}}
			style={{ width: 80, textAlign: 'center' }}
			className="faceTag"
			checked={auditType === '通过'}
		>
			通过
		</CheckableTag>,
		<CheckableTag
			onChange={(checked) => {
				setAuditType('拒绝');
			}}
			style={{ width: 80, textAlign: 'center' }}
			className="faceTag"
			checked={auditType === '拒绝'}
		>
			拒绝
		</CheckableTag>,
	];

	const audit = (status: string, record: any) => {
		confirm({
			title: '提示',
			content: '您确定' + status + '“' + record.name + '”的申请。',
			onOk() {
				return auditApplyCommunity(record.uuid, status);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const auditApplyCommunity = (uuid: string, status: string) => {
		let uri = '/api/apple_community/audit';
		let params = {
			uuid: uuid,
			status: status,
		};
		return ApiImp.postWithAuth(uri, params).then((res) => {
			if (res.err === 0) {
				message.success({ content: res.msg, type: 'danger' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error({ content: res.msg, type: 'danger' });
			}
		});
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				// options={false}
				request={async (params:any = {}, sort:any, filter:any) => {
					let res = await getList({
						page: params.current,
						size: params.pageSize,
						search: search,
						auditType: auditType,
					});
					console.log('查询结果', res);
					return {
						data: res.data.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default ApplyCommunity;
