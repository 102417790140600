import Api from '../data/API';

export async function saveAdNotice(params: any) {
	return Api.postWithAuth('/api/ad_notice/save_ad_notice', params);
}

export async function updateAdNotice(params: any) {
	return Api.postWithAuth('/api/ad_notice/update_ad_notice', params);
}
export async function getNoNoticeUser(params: any) {
	return Api.postWithAuth('/api/ad_notice/get_no_notice_user', params);
}
export async function getAdNoticeList(params: any) {
	return Api.postWithAuth('/api/ad_notice/get_ad_notice_list', params);
}

export async function getAdNoticeRecord(params: any) {
	return Api.postWithAuth('/api/ad_notice/get_ad_notice_record', params);
}
export async function payNotice(params: any) {
	return Api.postWithAuth('/api/zhuhu/pay-notice', params);
}

export async function getSmsContent(params: any) {
	return Api.postWithAuth('/api/zhuhu/getSmsContent', params);
}

export async function findSmsRecord(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_sms_record', params);
}
export async function findSmsSendBatchList(params: any) {
	return Api.postWithAuth('/api/ad_notice/findSmsSendBatchList', params);
}
export async function getSendKeyList(params: any) {
	return Api.postWithAuth('/api/ad_notice/getSendKeyList', params);
}
export async function getSendKeyRecordList(params: any) {
	return Api.postWithAuth('/api/ad_notice/getSendKeyRecordList', params);
}
export async function findSmsSendDetailListByBatchId(params: any) {
	return Api.postWithAuth('/api/ad_notice/findSmsSendDetailListByBatchId', params);
}

export async function findProductSms(params: any) {
	return Api.postWithAuth('/api/notification/find_product_sms', params);
}

export async function wxpayUnifiedorder(params: any) {
	return Api.postWithAuth('/api/wxpay_unifiedorder', params);
}
export async function getSmsParams(params: any) {
	return Api.postWithAuth('/api/ad_notice/getSmsParams', params);
}

