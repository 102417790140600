import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, message, Modal, Result, Row, Select, Spin, Tag } from 'antd';
import $ from 'jquery';
import Spot from '../pages/zhuhushenhe/models/Spot';
import Polygon from '../pages/zhuhushenhe/models/Polygon';
import { RoomStatusData } from '../pages/zhuhushenhe/models/RoomStatusData';
import { WuyeRoom } from '../pages/zhuhushenhe/models/WuyeRoom';
import ApiImp from '../data/API';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import UploadImage from './UploadImage';
import AuthorizedView from '../util/AuthorizedView';

// eslint-disable-next-line
const snapsvg = require('imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js');

interface LayerPlanComponentProps {
	data: RoomStatusData;
	type: string;
}
let rowRef: any;
const LayerPlanComponent: React.FC<LayerPlanComponentProps> = (props: LayerPlanComponentProps) => {
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [modelVisible, setModelVisible] = useState(false);
	const [currentPolygon, setCurrentPolygon] = useState(new Polygon());
	const [roomInfo, setRoomInfo] = useState(new WuyeRoom());
	const [windowWidth, setWindowWidth] = useState(1280);
	const [windowHeight, setWindowHeight] = useState(720);
	const [allPolygon] = useState(Array<Polygon>());
	const [imageUrl, setImageUrl] = useState(props.data.buildPlan.imageUrl);
	let tempUrl = '';
	let snap: any;
	let paper: any;
	let startX: number = 0;
	let startY: number = 0;
	let radius: number = 7;
	let canvasMouseX: number;
	let canvasMouseY: number;
	let storedLines: Array<Spot> = Array<Spot>();
	let ratio: number = 1;

	useEffect(() => {
		console.log('平面图数据：', props.data, props.data.buildPlan.imageUrl);
		initImage();
	}, []);

	const initImage = () => {
		snap = snapsvg(`#svg${props.data.layer}`);
		paper = snap.paper;
		paper.clear();
		const img = new Image();
		img.src = props.data.buildPlan.imageUrl;
		img.onload = function () {
			setWindowWidth(rowRef.clientWidth);
			let imageWidth = img.width;
			let imageHeight = img.height;
			setWindowHeight((rowRef.clientWidth * imageHeight) / imageWidth);
			ratio = rowRef.clientWidth / imageWidth;
			console.log('窗口宽高', rowRef.clientWidth, (rowRef.clientWidth * imageHeight) / imageWidth, ratio);

			let paperImage = paper.image(
				props.data.buildPlan.imageUrl,
				0,
				0,
				rowRef.clientWidth,
				(rowRef.clientWidth * imageHeight) / imageWidth
			);
			if (props.type === 'update') {
				paperImage.dblclick((e: any) => {
					handleMouseDown(e);
				});
			}
			console.log('图片', paperImage);
			getData();
		};
	};

	const getData = () => {
		setLoading(true);
		let params = {
			buildPlanId: props.data.buildPlan.id,
		};
		ApiImp.postWithAuth('/api/zhuhu/get_build_plan_spots', params).then((res) => {
			setLoading(false);
			if (res.err === 0) {
				console.log('数据为：', res.data);
				res.data.map((item: any) => {
					let spots = JSON.parse(item.spots);
					enlargeSpot(spots, ratio);
					fillPolyline(spots, item.roomUuid);
				});
			} else {
				message.error(res.msg);
			}
		});
	};

	const handleMouseDown = (e: any) => {
		let canvasOffset: any = $(`#svg${props.data.layer}`).offset();
		let offsetX = canvasOffset.left;
		let offsetY = canvasOffset.top;
		let scrollTop = window.pageYOffset || document.body.scrollTop;
		canvasMouseX = e.clientX - offsetX;
		canvasMouseY = e.clientY - offsetY + scrollTop;
		// Put your mousedown stuff here
		if (hitStartCircle(canvasMouseX, canvasMouseY)) {
			fillPolyline(storedLines, '');
			return;
		}
		storedLines.push({
			x: canvasMouseX,
			y: canvasMouseY,
		});

		if (storedLines.length === 1) {
			startX = canvasMouseX;
			startY = canvasMouseY;
			paper
				.circle(canvasMouseX, canvasMouseY, radius)
				.attr({
					fill: 'green',
				})
				.addClass('circle')
				.dblclick((e1: any) => {
					handleMouseDown(e1);
				});
		} else {
			let c = storedLines.length - 2;
			paper
				.line(storedLines[c].x, storedLines[c].y, canvasMouseX, canvasMouseY)
				.attr({
					stroke: '#000',
				})
				.addClass('line');
		}
	};

	const fillPolyline = (spotList: Array<Spot>, roomUuid: string) => {
		let spots: Array<number> = [];
		for (let i = 0; i < spotList.length; i++) {
			spots.push(spotList[i].x);
			spots.push(spotList[i].y);
		}
		spots.push(spotList[0].x);
		spots.push(spotList[0].y);
		let polygon = new Polygon();
		let wuyeRoom = getWuRoom(roomUuid);
		let polyline = paper
			.polyline(spots)
			.data('obj', polygon)
			.attr({
				fill: getColor(wuyeRoom.state),
				stroke: '#ffffff',
			})
			.click(function () {
				setVisible(true);
				setCurrentPolygon(polygon);
				setRoomInfo(wuyeRoom);
			});
		//按原图大小存点位
		reduceSpot(spotList, ratio);
		polygon.obj = polyline;
		polygon.spots = spotList;
		polygon.roomUuid = roomUuid;

		allPolygon.push(polygon);
		storedLines = [];

		//清空点和线
		snap.selectAll('.line').forEach((item: any) => item.remove());
		snap.selectAll('.circle').forEach((item: any) => item.remove());
	};

	//缩小点位坐标
	const reduceSpot = (spotList: Array<Spot>, reduceRatio: number) => {
		for (let i = 0; i < spotList.length; i++) {
			spotList[i].x = spotList[i].x / reduceRatio;
			spotList[i].y = spotList[i].y / reduceRatio;
		}
		return spotList;
	};

	//放大点位坐标
	const enlargeSpot = (spotList: Array<Spot>, reduceRatio: number) => {
		for (let i = 0; i < spotList.length; i++) {
			spotList[i].x = spotList[i].x * reduceRatio;
			spotList[i].y = spotList[i].y * reduceRatio;
		}
		return spotList;
	};

	const hitStartCircle = (x: number, y: number) => {
		let dx = x - startX;
		let dy = y - startY;
		return dx * dx + dy * dy < radius * radius;
	};

	const deletePolygon = () => {
		Modal.confirm({
			title: '提示',
			icon: <ExclamationCircleOutlined />,
			content: '是否要删除该图形',
			okText: '确认',
			onOk: () => {
				currentPolygon.obj.remove();
				allPolygonDeleteItem(currentPolygon);
				setVisible(false);
			},
			cancelText: '取消',
		});
	};

	const allPolygonDeleteItem = (polygon: Polygon) => {
		allPolygon.forEach((item: Polygon, index: number) => {
			if (item === polygon) {
				allPolygon.splice(index, 1);
			}
		});
		console.log('删除后的数组::', allPolygon);
	};
	const save = () => {
		let params = {
			buildPlanId: props.data.buildPlan.id,
			buildPlanSpotList: JSON.stringify(allPolygon),
		};
		ApiImp.postWithAuth('/api/zhuhu/set_build_plan_spots', params).then((res) => {
			console.log('返回：', res);
			if (res.err === 0) {
				message.success('保存成功');
			} else {
				if (res.errMsg !== undefined) {
					let msg: string[] = [];
					res.errMsg.map((item: any) => {
						Object.keys(item).forEach(function (element: string) {
							msg.push(item[element]);
						});
					});
					message.error(msg.toString());
				} else {
					message.error(res.msg);
				}
			}
		});
	};

	const selectOnChange = (uuid: string) => {
		currentPolygon.roomUuid = uuid;
		currentPolygon.obj
			.attr({
				fill: getColor(getWuRoom(uuid).state),
				stroke: '#ffffff',
			})
			.click(function () {
				setVisible(true);
				setCurrentPolygon(currentPolygon);
				setRoomInfo(getWuRoom(uuid));
			});
		setRoomInfo(getWuRoom(uuid));
		setCurrentPolygon(currentPolygon);
	};

	const isCheck = (uuid: string) => {
		let flag = false;
		allPolygon.map((item: Polygon) => {
			if (item.roomUuid === uuid) {
				flag = true;
				return;
			}
		});
		return flag;
	};
	const getWuRoom = (uuid: string): WuyeRoom => {
		return (
			props.data.rooms.find((item: WuyeRoom) => {
				return item.uuid === uuid;
			}) || new WuyeRoom()
		);
	};

	const getTab = (state: number) => {
		switch (state) {
			case 0:
				return <Tag color="#e2e2e2">空置</Tag>;
			case 1:
				return <Tag color="#f50">已出租</Tag>;
			default:
				return <Tag color="#e2e2e2">空置</Tag>;
		}
	};
	const getColor = (state: number) => {
		switch (state) {
			case 0:
				return '#e2e2e27f';
			case 1:
				return '#ff55007f';
			default:
				return '#e2e2e27f';
		}
	};

	//设置平面图
	const updatePlan = () => {
		console.log('楼栋UUid', props.data.buildPlan.buildUuid);
		let data = {
			buildUuid: props.data.buildPlan.buildUuid,
			layerNumber: props.data.buildPlan.layerNumber,
			imageUrl: tempUrl,
		};
		ApiImp.postWithAuth('/api/zhuhu/set_layer_plan', data).then((res: any) => {
			console.log('返回：', res);
			if (res.err === 0) {
				message.success(res.msg);
				props.data.buildPlan.id = res.data.id;
				props.data.buildPlan.imageUrl = tempUrl;
				setImageUrl(tempUrl);
				initImage();
				setModelVisible(false);
			} else {
				if (res.errMsg !== undefined) {
					let msg: string[] = [];
					res.errMsg.map((item: any) => {
						Object.keys(item).forEach(function (element: string) {
							msg.push(item[element]);
						});
					});
					message.error(msg.toString());
				} else {
					message.error(res.msg);
				}
			}
		});
	};

	const deletePlan = () => {
		Modal.confirm({
			title: '是否要删除该平面图？',
			icon: <ExclamationCircleOutlined />,
			content: '删除后将会把该平面图中所有图形删掉。',
			okText: '确认',
			onOk: () => {
				let data = {
					buildPlanId: props.data.buildPlan.id,
				};
				ApiImp.postWithAuth('/api/zhuhu/delete_layer_plan', data).then((res: any) => {
					console.log('返回：', res);
					if (res.err === 0) {
						message.success(res.msg);
						props.data.buildPlan.imageUrl = '';
						setImageUrl('');
						props.data.buildPlan.id = 0;
						initImage();
					} else {
						message.error(res.msg);
					}
				});
			},
			cancelText: '取消',
		});
	};

	return (
		<div
			ref={(e) => {
				rowRef = e;
			}}
			style={{ backgroundColor: '#fff' }}
		>
			<Spin spinning={loading}>
				{props.type === 'update' && (
					<AuthorizedView needAuthority={'household:build_plan:update'}>
						<Row justify={'end'} align={'middle'} gutter={8} style={{ paddingTop: 5, paddingBottom: 5 }}>
							<Col>
								{imageUrl !== '' && (
									<Button
										type={'primary'}
										onClick={() => {
											deletePlan();
										}}
									>
										删除平面图
									</Button>
								)}
							</Col>
							<Col>
								{
									<Button
										type={'primary'}
										onClick={() => {
											setModelVisible(true);
										}}
									>
										设置平面图
									</Button>
								}
							</Col>
							<Col>
								<Button type={'primary'} onClick={save}>
									保存
								</Button>
							</Col>
						</Row>
					</AuthorizedView>
				)}

				<Row id={'svg-container'} hidden={imageUrl === ''}>
					<Col span={24}>
						<svg id={'svg' + props.data.layer} width={windowWidth} height={windowHeight}>
							您的浏览器不支持canvas，请更换浏览器.
						</svg>
					</Col>
				</Row>
				{imageUrl === '' && (
					<Row justify={'center'} align={'middle'}>
						<Col>
							<Result status="404" title="该楼层暂未设置平面图" />
						</Col>
					</Row>
				)}
				<Drawer
					title="设置"
					width={400}
					placement="right"
					closable={false}
					onClose={() => {
						setVisible(false);
					}}
					visible={visible}
				>
					<Row justify={'center'} align={'middle'} gutter={8}>
						<Col span={6}>位置：</Col>
						<Col span={18}>{props.data.buildPlan.buildName}</Col>
					</Row>
					<br />
					<Row justify={'center'} align={'middle'} gutter={8}>
						<Col span={6}>楼层：</Col>
						<Col span={18}>{props.data.buildPlan.layerNumber}层</Col>
					</Row>
					<br />
					<Row align={'middle'} gutter={8}>
						<Col>关联房间</Col>
					</Row>
					<br />
					<Row justify={'center'} align={'middle'} gutter={8}>
						<Col span={24}>
							<Select
								key={props.type + currentPolygon.roomUuid}
								style={{ width: '100%' }}
								defaultValue={currentPolygon.roomUuid}
								onChange={selectOnChange}
								disabled={props.type === 'look'}
							>
								{props.data.rooms.map((item: WuyeRoom) => {
									return (
										<Select.Option disabled={isCheck(item.uuid)} key={item.uuid} value={item.uuid}>
											{item.roomNumber}
										</Select.Option>
									);
								})}
							</Select>
						</Col>
					</Row>
					<br />
					{roomInfo.uuid !== '' && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={6}>状态：</Col>
								<Col span={18}>{getTab(roomInfo.state)}</Col>
							</Row>
							<br />
						</>
					)}
					{roomInfo.uuid !== '' && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={6}>面积：</Col>
								<Col span={18}>{roomInfo.acreage} ㎡</Col>
							</Row>
							<br />
						</>
					)}
					{roomInfo.uuid !== '' && roomInfo.state === 1 && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={6}>租赁方</Col>
								<Col span={18}>{roomInfo.name}</Col>
							</Row>
							<br />
						</>
					)}
					{roomInfo.uuid !== '' && roomInfo.state === 1 && roomInfo.companyName !== '' && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={6}>租赁方公司</Col>
								<Col span={18}>{roomInfo.companyName}</Col>
							</Row>
							<br />
						</>
					)}
					{roomInfo.uuid !== '' && roomInfo.state === 1 && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={6}>合同号</Col>
								<Col span={18}>{roomInfo.contractNo}</Col>
							</Row>
							<br />
						</>
					)}
					{roomInfo.uuid !== '' && roomInfo.state === 1 && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={6}>合同单价</Col>
								<Col span={18}>{roomInfo.price} 元</Col>
							</Row>
							<br />
						</>
					)}
					{roomInfo.uuid !== '' && roomInfo.state === 1 && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={8}>合同开始时间</Col>
								<Col span={10}>{roomInfo.contractStartTime}</Col>
							</Row>
							<br />
						</>
					)}
					{roomInfo.uuid !== '' && roomInfo.state === 1 && (
						<>
							<Row align={'middle'} gutter={8}>
								<Col span={8}>合同结束时间</Col>
								<Col span={10}>{roomInfo.contractEndTime}</Col>
							</Row>
							<br />
						</>
					)}
					{props.type === 'update' && (
						<AuthorizedView needAuthority={'household:build_plan:update'}>
							<Row justify={'space-between'} align={'middle'} gutter={8}>
								<Col>
									<Button onClick={() => setVisible(false)}>关闭</Button>
								</Col>
								<Col>
									<Button onClick={deletePolygon}>删除</Button>
								</Col>
							</Row>
						</AuthorizedView>
					)}
				</Drawer>

				<Modal
					title="上传照片"
					visible={modelVisible}
					onCancel={() => {
						setModelVisible(false);
					}}
					onOk={updatePlan}
				>
					<Row>
						<Col>
							<UploadImage
								key={imageUrl}
								prefix={'room'}
								imageUrl={imageUrl}
								onChange={(url: string) => {
									console.log('图片地址为：', url);
									tempUrl = url;
								}}
								multiple={false}
							/>
						</Col>
					</Row>
				</Modal>
			</Spin>
		</div>
	);
};
export default LayerPlanComponent;
