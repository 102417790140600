import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import DataTransmission from "./pages/DataTransmission";


export default function DateTransmissionMiniApp() {
	const rootUrl = '/transmission';
	const menus: MiniAppMenu[] = [
		{
			name: '数据传输配置',
			authority: 'foundation-data-transmission:parameter',
			path: 'data-transmission',
			component: DataTransmission,
		},
	];

	return <MiniApp miniAppName={'数据传输配置'} rootUrl={rootUrl} menus={menus} />;
}
