import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import { findContractList } from '../../../services/Contract';
import { Button, Input, message, Modal, Select, Steps, Row, Col, Form, DatePicker, Checkbox, Space } from 'antd';
import { findAllRoom } from '../../../services/room';
import { findFeeSubject } from '../../../services/PayCostManagementService';
import { findHzHouseholdByRoomUuid, getGNHTParams, gyHtParams, xzGyParams } from '../../../services/roomCharge';
import DAL from '../../../data/DAL';
import moment from 'moment';

const SignAgreement: React.FC = () => {
    const [chargeData, setchargeData] = useState([]);
	const [loading, setloading] = useState(false)
	const [householder, sethouseholder] = useState({})
	useEffect(() => {
        getRooms().then();
        getCharges().then()
	}, []);

	const getRooms = async () => {
		const formData = {
			state: 1,
			type: '公寓',
		};
		const res = await findAllRoom(formData);
		if (res.err == 0) {
			setroomList(res.data || []);
			console.log('resLLL', res);
		}
	};
	const [form] = Form.useForm();
    const [roomList, setroomList] = useState([]);
    const [zhouqiList, setzhouqiList] = useState([])
    const [yajinLei, setyajinLei] = useState([])
	const getCharges = async () => {
		const res = await findFeeSubject({ state: 'true' });
		if (res.err == 0) {
			const data = res.data || [];
			// setcharges([...data]);
			// setchargeTotal(res.total);
			setData(data);
		}
	};
	const setData = (data: any) => {
		let list = [];
		const zhouqiList = data.filter((item) => '周期类' == item.type);
		if (zhouqiList.length > 0) {
			list.push({ type: '周期类', children: zhouqiList });
        }
        setzhouqiList(zhouqiList)
		const yajinLei = data.filter((item) => '押金类' == item.type);
		if (yajinLei.length > 0) {
			list.push({ type: '押金类', children: yajinLei });
        }
        setyajinLei(yajinLei)
		// if (false) {
		// 	const yibiap = data.filter((item) => '仪表类' == item.type);
		// 	if (yibiap.length > 0) {
		// 		list.push({ type: '仪表类', children: yibiap });
		// 	}
		// 	const linshilei = data.filter((item) => '临时类' == item.type);
		// 	if (linshilei.length > 0) {
		// 		list.push({ type: '临时类', children: linshilei });
		// 	}
		// 	const dingjinlei = data.filter((item) => '定金类' == item.type);
		// 	if (dingjinlei.length > 0) {
		// 		list.push({ type: '定金类', children: dingjinlei });
		// 	}
		// 	const wufeilei = data.filter((item) => !DAL.dal.isNotNull(item.type));
		// 	if (wufeilei.length > 0) {
		// 		list.push({ type: '无类型', children: wufeilei });
		// 	}
		// }

		setchargeData(list);
    };
    


    const saveGNHTParam = async () => { 
        form.validateFields().then((async data => {
            const formData = {
                ...data,
            }
            setloading(true)
            const res = await getGNHTParams(formData)
            setloading(false)
            console.log("res:::",res)
            if (res.err == 0) {
                message.success("供暖合同创建成功")
            } else { 
                message.error(res.msg)
            }
        }))
    }
    const saveGyHTParam = async() => { 
        form.validateFields().then((async data => {
            const formData = {
                ...data,
                feeTypeUuids: data.feeTypeUuids.toString()
            }
               setloading(true)
            const res = await gyHtParams(formData)
            setloading(false)
            console.log("res:::",formData)
            if (res.err == 0) {
                message.success("公寓租赁创建成功")
            } else { 
                message.error(res.msg)
            }
        }))
    }
	const saveXzGYHTParam = async() => { 
        form.validateFields().then((async data => {
            const formData = {
				...data,
				feeTypeUuids:data.feeTypeUuids.toString()
            }
               setloading(true)
            const res = await xzGyParams(formData)
            setloading(false)
            console.log("res:::",formData)
            if (res.err == 0) {
                message.success("小镇公约创建成功")
            } else { 
                message.error(res.msg)
            }
        }))
    }

	
	const getZhItem = async (roomUuid) => {
		if (DAL.dal.isNotNull(roomUuid)) { 
			const res = await findHzHouseholdByRoomUuid({ roomUuid: roomUuid })
			if (res.err == 0) { 
				const data = res.data || {}
				const formData: any = {
					htEndTime: "",
					htStartTime:""
				}
				if (DAL.dal.isNotNull(data.hetongEnd)) { 
					formData.htEndTime = moment(data.hetongEnd).format("YYYY-MM-DD")
				}
				if (DAL.dal.isNotNull(data.hetongStart)) { 
					formData.htStartTime = moment(data.hetongStart).format("YYYY-MM-DD")
				}
				form.setFieldsValue(formData)
				sethouseholder(data)
			}
		} else {
			const formData: any = {
				htEndTime: "",
				htStartTime:""
			}
			form.setFieldsValue(formData)
		}
	}
	return (
		<>
			<Form form={form}>
				<Form.Item label="房间" rules={[{ required: true, message: '请选择房间号' }]} name={'roomUuid'}>
					<Select onChange={(value) => { getZhItem(value)}}  optionFilterProp="children" showSearch placeholder="房间" allowClear>
						{roomList.length > 0 &&
							roomList.map((item) => {
								return (
									<Select.Option value={item.uuid}>
										{' '}
										{item.roomNumber}-{item.householdName}
									</Select.Option>
								);
							})}
					</Select>
				</Form.Item>
				<Form.Item label="收费项目" rules={[{ required: true, message: '请选择收费项目' }]}  name={"feeTypeUuids"}>
                    <Checkbox.Group
						style={{ width: '100%',  overflowY: 'auto',marginTop:-5 }}
					>
						{chargeData.map((item: any) => {
							return (
								<>
									<Row style={{ display: 'flex', alignItems: 'center' }}>
										<div
											style={{
												width: 4,
												height: 16,
												background: '#1890FF',
												borderRadius: '10px 10px 10px 10px',
												marginRight: 10,
											}}
										></div>
										<div>{item.type}</div>
									</Row>
									<Row>
										{item.children.map((item1) => {
											return (
												<>
													<Col span={6}>
														<Checkbox value={item1.uuid}>{item1.feeName}</Checkbox>
													</Col>
												</>
											);
										})}
									</Row>
								</>
							);
						})}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item label="合同开始时间" rules={[{ required: true, message: '合同开始时间' }]} name={'htStartTime'}>
					<Input disabled></Input>
				</Form.Item>
				<Form.Item label="合同结束时间" rules={[{ required: true, message: '合同结束时间' }]} name={'htEndTime'}>
					<Input disabled></Input>
				</Form.Item>
                {/* <Form.Item label="房租缴费" rules={[{ required: true, message: '房租缴费' }]} name={'gyCode'}>
					<Select optionFilterProp="children" showSearch placeholder="房租缴费" allowClear>
						{zhouqiList.length > 0 &&
							zhouqiList.map((item) => {
								return (
									<Select.Option value={item.feeTypeCode}>
										{item.feeName}
									</Select.Option>
								);
							})}
					</Select>
                </Form.Item>
                <Form.Item label="房租押金缴费" rules={[{ required: true, message: '房租押金缴费' }]} name={'yjCode'}>
					<Select optionFilterProp="children" showSearch placeholder="房租押金缴费" allowClear>
						{yajinLei.length > 0 &&
							yajinLei.map((item) => {
								return (
									<Select.Option value={item.feeTypeCode}>
										{item.feeName}
									</Select.Option>
								);
							})}
					</Select>
                </Form.Item> */}
                <Form.Item label="供暖缴费" rules={[{ required: true, message: '供暖缴费' }]} name={'gnCode'}>
					<Select optionFilterProp="children" showSearch placeholder="供暖缴费" allowClear>
						{zhouqiList.length > 0 &&
							zhouqiList.map((item) => {
								return (
									<Select.Option value={item.uuid}>
										{item.feeName}
									</Select.Option>
								);
							})}
					</Select>
                </Form.Item>

            </Form>
            <Space>
            <Button type='primary' loading={loading} onClick={saveGNHTParam}>供暖费</Button>
				{/* <Button type='primary' loading={loading} onClick={saveGyHTParam}>公寓</Button> */}
				<Button type='primary'  loading={loading} onClick={saveXzGYHTParam}>公寓合约</Button>
            </Space>
		</>
	);
};
export default SignAgreement;
