import React, { useEffect, useRef, useState } from 'react';
import { Col, Layout, message, Row, Space, Tooltip } from 'antd';
import Api from '../../data/API';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DAL from '../../data/DAL';
import AuthorizedView from '../../util/AuthorizedView';
import Text from 'antd/es/typography/Text';
import GSTable from '../../components/GSTable';
import { getDaiShenhe } from '../../services/WuyeService';
import { ActionType } from '@ant-design/pro-table';
const moment = require('moment');
const { Header, Footer, Sider, Content } = Layout;
require('./Home.css');

const weatherPic = {
	'00': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-01.png',
	'01': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-02.png',
	'02': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-04.png',
	'03': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'04': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'05': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'06': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'07': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'08': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'09': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'10': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'11': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'12': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'13': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'14': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'15': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'16': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'17': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'18': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-06.png',
	'19': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'20': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-08.png',
	'21': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'22': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'23': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'24': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'25': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-03.png',
	'26': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'27': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'28': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'29': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-07.png',
	'30': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-08.png',
	'31': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-08.png',
	'52': 'https://pcdn.guosim.com/%E5%A4%A9%E6%B0%94-08.png',
};

export default function Home(): JSX.Element {
	const dal = DAL.dal;
	const actionRef = useRef<ActionType>();
	const columns = [
		{
			title: '服务单',
			key: 'liucheng_name',
			dataIndex: 'liucheng_name',
		},
		{
			title: '当前节点',
			dataIndex: 'jiedianName',
			key: 'jiedianName',
		},
		{
			title: '进度',
			key: 'position',
			dataIndex: 'position',

			render: (text: string, record: any) => (
				<span>
					{record.position}/{record.sum}
				</span>
			),
		},
		{
			title: '发起人账号',
			key: 'userId',
			dataIndex: 'userId',
		},
		{
			title: '发起时间',
			key: 'time',
			dataIndex: 'time',
		},
		{
			title: '状态',
			key: 'state',
			dataIndex: 'state',
			render: (value: string, record: any) => (
				<a
					onClick={() => {
						jiedan(record.shili_uuid, record.state);
					}}
					target="_blank"
				>
					{record.state}
				</a>
			),
		},
	];
	const jiedan = (uuid: string, state: string) => {
		window.open('/page/liucheng/Liuchengdan_data_info?shiliuuid=' + uuid, '_blank');
	};
	const [time, setTime] = useState('');
	const [animalsYear, setAnimalsYear] = useState('');
	const [lunar, setLunar] = useState('');
	const [lunarYear, setLunarYear] = useState('');
	const [temperature, setTemperature] = useState(0);
	const [info, setInfo] = useState('');
	const [wid, setWid] = useState('');
	const [date, setDate] = useState('');
	const [weekday, setWeekday] = useState('');
	const [account, setAccount] = useState('');
	const [userName, setUserName] = useState('');
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [deviceCount, setDeviceCount] = useState('');
	const [rRoomCount, setRRoomCount] = useState('');
	const [oRoomCount, setORoomCount] = useState('');
	const [openStation, setOpenStation] = useState('');
	const [commercialSpace, setCommercialSpace] = useState('');

	// 获取天气数据
	const getWeatherData = () => {
		Api.postWithAuth('/api/get_weather', {
			city: '北京',
		})
			.then((res) => {
				if (res.err === 0) {
					setTemperature(res.data.temperature);
					setInfo(res.data.info);
					setWid(res.data.wid);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getLunarYear = (lunarYear: string) => {
		return lunarYear.replace('年', '');
	};
	// 获取日期数据(农历)
	const getDateData = () => {
		Api.postWithAuth('/api/get_nongli_date', {})
			.then((res) => {
				if (res.err === 0) {
					setAnimalsYear(res.data.animalsYear);
					setLunar(res.data.lunar);
					setLunarYear(getLunarYear(res.data.lunarYear));
					setWeekday(res.data.weekday);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCompanyPro = () => {
		Api.postWithAuth('/api/get_company_pro', {})
			.then((res) => {
				if (res.err === 0) {
					setName(res.data.name);
					setAddress(res.data.address);
					setDeviceCount(res.data.deviceCount);
					setRRoomCount(res.data.rRoomCount);
					setORoomCount(res.data.oRoomCount);
					setOpenStation(res.data.openStation);
					setCommercialSpace(res.data.commercialSpace);
				}
				{
					console.log('项目信息的数据', res);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getDate = () => {
		let str = moment().format('YYYY-MM-DD');
		let array: string[] = str.split('-');
		let year = array[0];
		let month = array[1];
		let day = array[2];
		if (month.charAt(0) === '0') {
			month = month.substr(1);
		}
		if (day.charAt(0) === '0') {
			day = day.substr(1);
		}
		return ''.concat(year, '年', month, '月', day, '日');
	};

	const getBasicInfo = () => {
		dal.getUserInfo().then((res) => {
			console.log('xinxi', res);
			setAccount(res.account);
			setUserName(res.userName);
		});
	};
	useEffect(() => {
		getWeatherData();
		getDateData();
		getBasicInfo();
		getCompanyPro();
		setDate(getDate());
	}, []);

	useEffect(() => {
		console.log('执行');
		let temp = setInterval(() => {
			setTime(moment().format('HH:mm'));
		}, 1000);
		return () => clearInterval(temp);
	}, [time]);

	const callbackFenye = async () => {
		const formData: any = {
			state: '等待接单和已接单',
			size: '10',
			pageNo: '1',
			wuyeUuid: localStorage.getItem('wyUuid'),
		};
		const res = await getDaiShenhe(formData);
		console.log('获得待审核', res);
		if (res.err === 0) {
			return {
				data: res.data,
				total: res.count,
			};
		}
	};
	const operationBar = () => [<a> 更多</a>];
	return (
		<div className="container1">
			<style>
				{`
				 .ant-pro-table-list-toolbar-container{
					padding: 0px !important ;
				 }
				`}
			</style>

			<div className={'row'} style={{ height: 223 }}>
				<div style={{ width: 300 }} className={'box'}>
					<div className={'title'}>
						你好 <span>{userName}</span>, 欢迎回来！
					</div>
					<div className={'weather'}>
						<div style={{ width: 57, height: 57, marginRight: 13 }}>
							<img width={60} height={60} src={weatherPic[wid]} />
						</div>
						<div className={'title'} style={{ marginBottom: 5, marginRight: 21 }}>
							{info}
						</div>
						<div className={'title'} style={{ fontSize: 26 }}>
							{temperature}℃
						</div>
					</div>
					<div className={'date'}>
						<div className={'time'}>{time}</div>
						<div className={'yinli_date'}>
							<div>
								{lunarYear}
								{animalsYear}年
							</div>
							<div>{lunar}</div>
						</div>
					</div>
					<div className={'yangli_date'}>
						{date} {weekday}
					</div>
				</div>

				<div style={{ flex: 1,marginLeft:15 }} className={'box'} >
					<div className={'title'}>常用功能</div>
					<div className={'box_item'} style={{ marginTop: 10 }}>
						<AuthorizedView needAuthority={'flow_sheet:flow_processing:select'}>
							<div className={'icon1'} style={{ backgroundColor: '#566eaa' }}>
								<Link to={'/liucheng'}>流程处理</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'assets_management:room_status'}>
							<div
								className={'icon1'}
								style={{ backgroundColor: '#AD539D', padding: '16px 10px 16px 10px' }}
							>
								<Link to={'/assets/room_status'}>房态图</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'household:household_list'}>
							<div className={'icon1'} style={{ backgroundColor: '#A27D45' }}>
								<Link to={'/zhuhu/household_list'}>住户管理</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'household:facebatch:select'}>
							<div
								className={'icon1'}
								style={{ backgroundColor: '#2C6F5D', padding: '16px 10px 16px 10px' }}
							>
								<Link to={'/zhuhu/face_audit/batch'}>住户人脸抽查</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'notification_management:open_door_notification'}>
							<div className={'icon1'} style={{ backgroundColor: '#FE553A' }}>
								<Link to={'/notification/ad-notice'}>开门通知</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'notification_management:sms_notification'}>
							<div className={'icon1'} style={{ backgroundColor: '#86CA28' }}>
								<Link to={'/notification/pay-notice'}>短信通知</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'entrance_guard:mapping_list:select'}>
							<div className={'icon1'} style={{ backgroundColor: '#f83927' }}>
								<Link to={'/zhineng-menjin/send-key'}>钥匙授权</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'community_service:wenzhang_type_list'}>
							<div className={'icon1'} style={{ backgroundColor: '#12E1DA' }}>
								<Link to={'/wenzhang/add_wenzhang?uuid=undefined&state=add'}>发布资讯</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'activity_management:huodong_list'}>
							<div className={'icon1'} style={{ backgroundColor: '#323366' }}>
								<Link to={'/huodong/add-huodong'}>发布活动</Link>
							</div>
						</AuthorizedView>

						<AuthorizedView needAuthority={'user_management'}>
							<div className={'icon1'} style={{ backgroundColor: '#203D40' }}>
								<Link to={'/user'}>用户查询</Link>
							</div>
						</AuthorizedView>
						<AuthorizedView needAuthority={'assets_management:room_management'}>
							<div className={'icon1'} style={{ backgroundColor: 'rgb(89, 61, 64)' }}>
								<Link to={'/assets/room_list'}>房间管理</Link>
							</div>
						</AuthorizedView>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
				{/* <div style={{ width: 336, marginRight: 20 }} className={'box'}>
					<div className={'title'}>消息通知</div>
				</div> */}

				<div style={{ display: 'flex', flex: 1, flexDirection: 'column',  }}>
					<div style={{display:'flex',justifyContent:'space-between',padding: '15px 0px 8px 0px',alignItems:'center'}}>
						<div style={{fontSize:16}}>流程待办</div>
						<div >
						<AuthorizedView needAuthority={'flow_sheet:flow_processing:select'}>
								<Link to={'/liucheng'}>
									<span style={{ fontSize: 12, color: '#707070', cursor: 'pointer' }}>
										更多
									</span>
								</Link>
						</AuthorizedView>
						</div>
					</div>
					<GSTable
						style={{ flex: 1 }}
						options={false}
						actionRef={actionRef}
						columns={columns}
						pagination={false}
						// headerTitle="流程代办"
						// operationsBarRender={operationBar()}
						rowKey="shili_uuid"
						request={async (params: any = {}, sort: any, filter: any) => {
							return callbackFenye();
						}}
					/>
				</div>

				<div style={{ width: 324, marginLeft: 16 }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px 0px 8px 0px',alignItems:'center' }}>
						<div style={{fontSize:16}}>系统公告</div>
						<div style={{fontSize:12,color:'#707070',cursor:'pointer'}}>更多</div>
					</div>
					<div className="box" style={{ height: 'calc(100% - 50.61px)' }}></div>
				</div>
				{/* <div style={{ width:328}}> */}
				{/* <div style={{ marginLeft: 20 }} className={'box'}>

						<div className={'title'}></div>
					</div> */}
				{/* <div style={{ width: '50%' }} className={'box'}>
						<div className={'title'}>项目信息</div>
						<div className={'project_info'}>
							<Row gutter={[43, 13]} style={{ marginTop: 4 }}>
								<Col className={'col1'}>项目名称</Col>
								<Col className={'col2'}>{name}</Col>
							</Row>
							<Row gutter={[43, 13]}>
								<Col
									className={'col2'}
									style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
								>
									<span className={'col1'} style={{ paddingRight: 42 }}>
										项目地址
									</span>
									<Tooltip title={address}>{address}</Tooltip>
								</Col>
							</Row>
							<Row gutter={[43, 13]}>
								<Col className={'col1'}>物联设备</Col>
								<Col className={'col2'}>{deviceCount}</Col>
							</Row>
							<Row gutter={[43, 13]}>
								<Col className={'col1'}>住宅房间</Col>
								<Col className={'col2'}>{rRoomCount}</Col>
							</Row>
							<Row gutter={[43, 13]}>
								<Col className={'col1'}>办公空间</Col>
								<Col className={'col2'}>{oRoomCount}</Col>
							</Row>
							<Row gutter={[43, 13]}>
								<Col className={'col1'}>开放工位</Col>
								<Col className={'col2'}>{openStation}</Col>
							</Row>
							<Row gutter={[43, 13]}>
								<Col className={'col1'}>商业空间</Col>
								<Col className={'col2'}>{commercialSpace}</Col>
							</Row>
						</div>
					</div> */}
				{/* </div> */}
			</div>
		</div>
	);
}
