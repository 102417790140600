import { Column } from '@ant-design/charts';
import { Card, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { collectionStatic, getClickNumberData } from '../../../services/scheme';

const SzStatic: React.FC = () => {
	useEffect(() => {
		getClickData();
		getCollectionData()

	}, []);

	const [tabKey, setTabKey] = useState('方案点击量');
	const [loading, setloading] = useState(true);
	const [collectionLoading, setcollectionLoading] = useState(true)
	const [clickNumberData, setclickNumberData] = useState([]);
	const [schemeList, setschemeList] = useState([]);
	const [talentList, settalentList] = useState([]);
	const [marketList, setmarketList] = useState([]);

	const [collectionKey, setcollectionKey] = useState('方案收藏')
	const [schemeCollection, setschemeCollection] = useState([])
	const [talentCollection, settalentCollection] = useState([])
	const [marketCollection, setmarketCollection] = useState([])
	const [collectionData, setcollectionData] = useState([])

	const onChangeTab = (key: string) => {
		console.log('key:::', key);
		setTabKey(key);
		switch (key) {
			case '方案点击量':
				setclickNumberData([...schemeList]);
				return;
			case '人才点击量':
				setclickNumberData([...talentList]);
				return;
			case '机会点击量':
				setclickNumberData([...marketList]);
				return;
			default:
				return;
		}
	};
	const onChangeCollectionTab = (key: string) => {
		console.log('key:::', key);
		setcollectionKey(key);
		switch (key) {
			case '方案收藏':
				setcollectionData([...schemeCollection]);
				return;
			case '人才收藏':
				setcollectionData([...talentCollection]);
				return;
			case '机会收藏':
				setcollectionData([...marketCollection]);
				return;
			default:
				return;
		}
	};
	const getClickData = async () => {
		const res = await getClickNumberData({});
		if (res.err !== 0) {
			message.error(res.msg);
			return;
		}
		setloading(false);
		setschemeList(res.data.schemeList || []);
		settalentList(res.data.talentList || []);
		setmarketList(res.data.marketList || []);
		setclickNumberData(res.data.schemeList || []);
	};
	const getCollectionData = async() => { 
		const res = await collectionStatic({})
		if (res.err !== 0) { 
			message.error(res.msg)
			return
		}
		setcollectionLoading(false)
		setschemeCollection(res.data.schemeCollection || [])
		settalentCollection(res.data.talentCollection || [])
		setmarketCollection(res.data.marketCollection || [])
		setcollectionData(res.data.schemeCollection || [])
	}
	const tabListNoTitle = [
		{
			key: '方案点击量',
			tab: '方案点击量',
		},
		{
			key: '机会点击量',
			tab: '机会点击量',
		},
		{
			key: '人才点击量',
			tab: '人才点击量',
		},
	];

	const collectionTitles = [
		{
			key: '方案收藏',
			tab: '方案收藏',
		},
		{
			key: '机会收藏',
			tab: '机会收藏',
		},
		{
			key: '人才收藏',
			tab: '人才收藏',
		},
	];
	const config = {
		data: clickNumberData,
		xField: 'name',
		yField: 'clicknumber',
		xAxis: {
			label: {
				autoRotate: false,
			},
		},
		slider: {
			start: 0,
			end: 1,
		},
	};
	const collectionConfig = {
		data: collectionData,
		xField: 'name',
		yField: 'count',
		xAxis: {
			label: {
				autoRotate: false,
			},
		},
		slider: {
			start: 0,
			end: 1,
		},
	};
	return (
		<>
			<Card
				loading={loading}
				tabList={tabListNoTitle}
				bodyStyle={{ paddingLeft: 15 }}
				activeTabKey={tabKey}
				onTabChange={onChangeTab}
			>
				<Column  {...config} />
			</Card>
			<Card
				loading={collectionLoading}
				tabList={collectionTitles}
				bodyStyle={{ paddingLeft: 15 }}
				activeTabKey={collectionKey}
				onTabChange={onChangeCollectionTab}
			>
				<Column {...collectionConfig} />
			</Card>
		</>
	);
};
export default SzStatic;
