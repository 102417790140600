import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'dva';
import ConnectState from '../../../models/connect';
import { ActionType } from '@ant-design/pro-table';
import { Link } from 'react-router-dom';
import Api from '../../../data/API';
import API from '../../../data/API';
import GSTable from '../../../components/GSTable';
import * as XLSX from 'xlsx';
import {
	Button,
	Card,
	Cascader,
	Col,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Select,
	Statistic,
	Switch,
	Tag,
	Tooltip,
	Table,
	Space,
} from 'antd';
import {
	addRoom,
	deleteRoom,
	findBuilds,
	findLiveRoom,
	getAssetsType,
	getHouseStyle,
	getHouseType,
	updateRoomSell,
} from '../../../services/ZhuhushenheService';
import { FormInstance } from 'antd/es/form';
import RoomAndHousehold from '../components/RoomAndHousehold';
import KaimenList from '../components/KaimenData';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import RoomChargeList from './RoomChargeList';
import CreateCharge from './CreateCharge';
import moment from 'moment';
const RoomList: React.FC = (props: any) => {
	const Confirm = Modal.confirm;
	const Option = Select.Option;
	const Search = Input.Search;
	const { CheckableTag } = Tag;
	const statusEnum = {
		正常: <Tag color={'green'}>正常</Tag>,
		缺失: <Tag color={'blue'}>缺失</Tag>,
		丢失: <Tag color={'blue'}>丢失</Tag>,
		损坏: <Tag color={'red'}>损坏</Tag>,
		异常: <Tag color={'red'}>异常</Tag>,
	};
	useEffect(() => {
		getBuilds();
		findAssetsType();
		findHouseType();
		findHouseStyle();
	}, []);
	let child: any;
	const unitArray: any = [];
	const layerArray: any = [];
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const { common } = props;
	const { deviceList } = common;
	//查询
	const [searchBuild, setSearchBuild] = useState('');
	const [searchUnit, setSearchUnit] = useState('');
	const [searchLayer, setSearchLayer] = useState('');
	const [type, setType] = useState('');
	const [roomItemStatus, setRoomItemStatus] = useState('');
	const [houseType, setHouseType] = useState('');
	const [dataTotal, setdataTotal] = useState(0)
	const [houseStyle, setHouseStyle] = useState('');
	const [search, setSearch] = useState('');
	const [seleectedRowKeys, setselectedRowKeys] = useState([])
	const [state, setState] = useState(1);

	const [assetsTypeList, setAssetsTypeList] = useState([]);
	const [houseTypeList, setHouseTypeList] = useState([]);
	const [houseStyleList, setHouseStyleList] = useState([]);
	const [builds, setBuilds] = useState([]);
	const [unitArray1, setUnitArray] = useState([]);
	const [layerArray1, setLayerArray] = useState([]);
	const [options, setOptions] = useState([]);
	const [addModalvisible, setAddModalvisible] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	//表单
	const [buildUuid, setBuildUuid] = useState('');
	const [buildName, setBuildName] = useState('');
	const [units, setUnits] = useState('');
	const [layers, setlayers] = useState('');

	//房间信息
	const [roomVisiable, setRoomVisiable] = useState(false);
	const [roomUuid, setRoomUuid] = useState('');
	const [roomAndHouse, setRoomAndHouse] = useState(false);

	const [deviceId, setDeviceId] = useState('');
	const [deviceName, setDeviceName] = useState('');
	const [kaimenVisiable, setKaimenVisiable] = useState(false);
	const [fangjianTitle, setFangjianTitle] = useState('');
	const [roomStatistics, setRoomStatistics] = useState({ totalNum: 0, checkInNum: 0 });
	const [fangJianSave, setFangjianSave] = useState(false);
	const [roomUuids, setroomUuids] = useState('');
	const [batchChargeVisible, setbatchChargeVisible] = useState(false);
	const [createChargeVisible, setcreateChargeVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [quanXuan, setquanXuan] = useState(false);
	const columns = [
		{
			title: '房间号',
			dataIndex: 'roomNumber',
			key: 'roomNumber',
			width: 100,
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						setRoomModal(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '是否出售',
			dataIndex: 'isSell',
			key: 'isSell',
			width: 80,
			render: (_: any, item: any) => {
				return (
					<Tag
						color={item.isSell == 2 ? 'green' : item.isSell == 0 ? 'blue' : item.isSell == 1 ? 'gold' : ''}
					>
						{item.isSell == 2 ? '出售' : item.isSell == 0 ? '不出售' : item.isSell == 1 ? '锁定' : ''}
					</Tag>
				);
			},
		},
		{
			title: '位置',
			dataIndex: 'position',
			key: 'position',
			width: 200,
		},
		{
			title: '资产类型',
			dataIndex: 'type',
			key: 'type',
			width: 80,
		},
		{
			title: '户型',
			dataIndex: 'houseType',
			key: 'houseType',
			width: 80,
		},
		{
			title: '装修风格',
			dataIndex: 'houseStyle',
			key: 'houseStyle',
			width: 80,
		},
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			width: 80,
			render: (text: number) => {
				return text === 1 ? '已入住' : '空置';
			},
		},
		{
			title: '物品状态',
			dataIndex: 'roomItemStatus',
			key: 'roomItemStatus',
			valueEnum: statusEnum,
			width: 80,

		},
		{
			title: '业主',
			key: 'householdName',
			dataIndex: 'householdName',
			width: 80,
		},
		{
			title: '照片',
			key: 'faceImg',
			width: 150,
			dataIndex: 'faceImg',
			render: (record: any) => {
				return record.state === 1 && record.faceImg !== '' ? (
					<img
						className="homePage"
						src={
							Api.apiRootPath +
							'/api/file/get_file_qiniu/' +
							record.faceImg +
							'/' +
							localStorage.getItem('wyUuid')
						}
					/>
				) : (
					'无'
				);
			}, //先写死root
		},
		// {
		// 	title: '备注',
		// 	key: 'note',
		// 	dataIndex: 'note',
		// },
		{
			title: '操作',
			key: 'action',
			align: 'left',
			width: 150,
			fixed: 'right',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					{record.state === 1 &&
					record.type === '公寓' &&
					record.deviceUuid !== null &&
					record.deviceUuid !== '' ? (
						<a
							onClick={() => {
								setkaiMenData(record);
							}}
						>
							开门记录
						</a>
					) : (
						''
					)}
				</span>
			),
		},
	];

	const setRoomModal = (record: any) => {
		console.log('record::', record);
		setFangjianTitle('房间信息    ' + '(' + record.roomNumber + ')');
		setFangjianSave(false);
		setRoomAndHouse(true);
		setRoomUuid(record.householdUUID);
		setRoomVisiable(true);
	};
	const setkaiMenData = (res: any) => {
		setDeviceId(res.deviceUuid);
		setDeviceName(res.roomNumber);
		setKaimenVisiable(true);
	};

	const deleteItem = (record: any) => {
		Confirm({
			title: '删除房间',
			content: '您确认要删除" ' + record.roomNumber + ' 房间"？',
			okText: '确定',
			okType: 'danger',
			maskClosable: true,
			cancelText: '取消',
			onOk: async () => {
				const res = await deleteRoom({ uuid: record.uuid });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error('删除失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	/*获得楼栋列表*/
	const getBuilds = async () => {
		const res = await findBuilds({});
		if (res.err === 0) {
			console.log('获得楼栋列表', res);
			let tempUnit = 1;
			let tempLayer = 1;
			for (let i = 0; i < res.data.length; i++) {
				if (res.data[i].unitNumber > tempUnit) {
					tempUnit = res.data[i].unitNumber;
				}
				if (res.data[i].layers > tempLayer) {
					tempLayer = res.data[i].layers;
				}
			}
			console.log('tempUnit::', tempUnit);
			console.log('tempLayer', tempLayer);
			for (let i = 0; i < tempUnit; i++) {
				unitArray.push(i + 1);
			}
			console.log('unitArray,', unitArray);
			for (let i = 0; i < tempLayer; i++) {
				layerArray.push(i + 1);
			}
			console.log('layerArray,', layerArray);
			setLayerArray(layerArray);
			setUnitArray(unitArray);
			setBuilds(res.data);
		}
	};
	const findAssetsType = async () => {
		const res = await getAssetsType({});
		if (res.err === 0) {
			setAssetsTypeList(res.data);
		}
	};
	const findHouseType = async () => {
		const res = await getHouseType({});
		if (res.err === 0) {
			setHouseTypeList(res.data);
		}
	};
	const findHouseStyle = async () => {
		const res = await getHouseStyle({});
		if (res.err === 0) {
			setHouseStyleList(res.data);
		}
	};

	//获取联级选择的数据
	const getOptions = () => {
		let temp: any = [];
		let data: any = builds;
		for (let i = 0; i < data.length; i++) {
			let option = {
				value: data[i].uuid,
				label: data[i].buildName,
				children: [],
			};
			for (let j = 0; j < data[i].unitNumber; j++) {
				let children1 = {
					value: j + 1 + '',
					label: j + 1 + '单元',
					children: [],
				};
				for (let z = 0; z < data[i].layers; z++) {
					let children2 = {
						value: z + 1 + '',
						label: z + 1 + '层楼',
					};
					(children1.children as any).push(children2);
				}
				(option.children as any).push(children1);
			}
			temp.push(option);
		}
		setOptions(temp);
	};

	const insertRoom = async () => {
		form.validateFields().then(async (data: any) => {
			setAddModalvisible(false);
			if (buildUuid === '' || buildUuid === undefined) {
				message.warning('楼栋不能为空');
				return;
			}
			if (units === '' || units === undefined) {
				message.warning('单元不能为空');
				return;
			}
			if (layers === '' || layers === undefined) {
				message.warning('层数不能为空');
				return;
			}
			const res = await addRoom({
				buildUuid: buildUuid,
				buildName: buildName,
				units: units,
				layers: layers,
				roomNumber: data.roomNumber,
				note: data.note,
				deviceId: data.deviceId,
				acreage: data.acreage,
				builtInArea: data.builtInArea,
				chargeArea: data.chargeArea,
				gardenArea: data.gardenArea,
				loftArea: data.loftArea,
				type: data.type,
				state: data.state,
				houseType: data.houseType,
				houseStyle: data.houseStyle,
				monthRent: data.monthRent,
				deposit: data.deposit,
				sell: data.sell,
				orientation: data.orientation,
				roomsNum: data.roomsNum,
				description: data.description,
				dayAreaPay: data.dayAreaPay,
			});
			if (res.err === 0) {
				message.success('添加成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('添加失败');
			}
		});
	};

	const tableSearchBarRender = () => [
		<CheckableTag
			className="faceTag"
			checked={state === 2}
			onChange={() => {
				setState(2);
				actionRef.current.reloadAndRest();
			}}
		>
			全部
		</CheckableTag>,
		<CheckableTag
			className="faceTag"
			checked={state === 1}
			onChange={() => {
				setState(1);
				actionRef.current.reloadAndRest();
			}}
		>
			已入住
		</CheckableTag>,
		<CheckableTag
			className="faceTag"
			checked={state === 0}
			onChange={() => {
				setState(0);
				actionRef.current.reloadAndRest();
			}}
		>
			空置
		</CheckableTag>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 100 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setType(value);
			}}
			placeholder="资产类型"
		>
			{assetsTypeList.map((item: any) => {
				return (
					<Option key={item} value={item}>
						{item}
					</Option>
				);
			})}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 100 }}
			onChange={(value: any) => {
				setRoomItemStatus(value)
			}}
			placeholder="物品状态"
		>
			<Option key={"全部"} value={""}>
				全部
			</Option>
			<Option key={"正常"} value={"正常"}>
				正常
			</Option>
			<Option key={"缺失"} value={"缺失"}>
				缺失
			</Option>
			<Option key={"损坏"} value={"损坏"}>
				损坏
			</Option>
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 100 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setHouseType(value);
			}}
			placeholder="户型"
		>
			{houseTypeList.map((item: any) => {
				return (
					<Option key={item} value={item}>
						{item}
					</Option>
				);
			})}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 100 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setHouseStyle(value);
			}}
			placeholder="装修风格"
		>
			{houseStyleList.map((item: any) => {
				return (
					<Option key={item} value={item}>
						{item}
					</Option>
				);
			})}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 130 }}
			onChange={(value: any) => {
				setSearchBuild(value);
			}}
			optionFilterProp="children"
			placeholder="楼栋"
		>
			{builds.map((build: any) => (
				<Option key={build.uuid} value={build.uuid}>
					{build.buildName}
				</Option>
			))}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 80 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setSearchUnit(value);
			}}
			placeholder="单元"
		>
			{unitArray1.map((unit: any) => (
				<Option key={unit} value={unit}>
					{unit}单元
				</Option>
			))}
		</Select>,
		<Select
			allowClear
			showSearch={true}
			style={{ width: 80 }}
			optionFilterProp="children"
			onChange={(value: any) => {
				setSearchLayer(value);
			}}
			placeholder="楼层"
		>
			{layerArray1.map((layer: any) => (
				<Option key={layer} value={layer}>
					{layer}层
				</Option>
			))}
		</Select>,
		<Input
			placeholder="房间号"
			onChange={(ev) => {
				setSearch(ev.target.value);
			}}
		/>,
		<Button
			type="primary"
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={openAddModel}>
			新增
		</Button>,
	];
	const openAddModel = () => {
		setBuildUuid('');
		setUnits('');
		setlayers('');
		setBuildName('');
		form.resetFields();
		setAddModalvisible(true);
		getOptions();
	};
	const position = (value: any, selectedOptions: any) => {
		console.log('position', selectedOptions, selectedOptions[0] !== undefined);
		if (selectedOptions[0] !== undefined) {
			setBuildUuid(value[0]);
			setBuildName(selectedOptions[0].label);
			setUnits(value[1]);
			setlayers(value[2]);
		}
	};
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 10 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};
	const formItemLayout1 = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 3 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 21 },
		},
	};

	const renderStatisticTitle = (element: any, msg: string, iconStyle?: any) => {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>{element}</div>
				{msg && (
					<div>
						<Tooltip title={msg}>
							<ExclamationCircleOutlined style={iconStyle} />
						</Tooltip>
					</div>
				)}
			</div>
		);
	};
	const tableExtraRender = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>资产总数</span>,
								''
							)}
							value={roomStatistics.totalNum}
							valueStyle={{ fontSize: 26 }}
							suffix={<span style={{ fontSize: 16 }}>套</span>}
						/>
					</Card>
				</Col>
				<Col span={5}>
					<Card style={{ backgroundColor: '#fff' }} bodyStyle={{ padding: '10px 15px' }}>
						<Statistic
							title={renderStatisticTitle(
								<span style={{ color: '#000', fontSize: 16 }}>已出租（出租率）</span>,
								''
							)}
							value={roomStatistics.checkInNum}
							valueStyle={{ fontSize: 26 }}
							suffix={<>
								<span style={{ fontSize: 16 }}>套</span>
								<span style={{ fontSize: 16 }}>（{roomStatistics.totalNum === 0 ? '-' : (roomStatistics.checkInNum * 100 / roomStatistics.totalNum).toFixed(2)}</span>
								<span style={{fontSize:16}}>{roomStatistics.totalNum === 0 ? '': '%'}</span>
								<span style={{fontSize:16}}>）</span>
							</>}
						/>
					</Card>
				</Col>
			</Row>
		);
	};

	const onRef = (ref: any) => {
		child = ref;
		console.log(child);
	};

	const saveFangjian = () => {
		console.log('child', child);
		child.updatahouse();
	};
	const rowSelection = {
		fixed: true,
		columnWidth: 100,
		preserveSelectedRowKeys: true,
		selectedRowKeys: seleectedRowKeys,
		selections: [{
			key: 'currentPage',
			text: '全选当前页面',
			onSelect: (changableRowKeys: any) => {
				setquanXuan(false);
				setselectedRowKeys(changableRowKeys);
			}
		},
			{
				key: '"SELECT_ALL"',
				text: '全选全部页面',
				onSelect: (changableRowKeys: any) => {
					setquanXuan(true);
					setselectedRowKeys(changableRowKeys);
				}
			}],
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			console.log('变化：：：：', selectedRowKeys)
			setquanXuan(false)
			setselectedRowKeys(selectedRowKeys);
		},
	};

	const updateSell = async (selectedRowKeys: any, type: any) => {
		if (selectedRowKeys.lenth == 0) {
			message.warn('请选择');
			return;
		}
		console.log('selectedRowKeys', selectedRowKeys);
		Modal.confirm({
			content: '您是否要' + type + '?',
			onOk: async () => {
				const res = await updateRoomSell({ uuids: selectedRowKeys.toString(), type: type });
				if (res.err === 0) {
					message.success('更新成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					message.error('更新失败');
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const updateCharge = (selectRowKeys: any) => {
		setroomUuids(selectRowKeys.toString());
		setbatchChargeVisible(true);
	};

	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setloading(true);
		if (quanXuan) {
			const res = await findLiveRoom({
				Search: search,
				buildUuid: searchBuild,
				unit: searchUnit,
				layer: searchLayer,
				type: type,
				state: state == 2 ? undefined : state,
				houseType: houseType,
				houseStyle: houseStyle,
				roomItemStatus: roomItemStatus
			});
			setloading(false);
			if (res.err == 0) {
				setExprotData(res.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setloading(false);
			setExprotData(selectedRows);
		}
	};
	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				}else if (item.key === 'isSell') {
					data.push(selectedRows[i][item.dataIndex] == 2 ? '出售' : selectedRows[i][item.dataIndex] == 0 ? '不出售' : item.isSell == 1 ? '锁定' : '');
				}  else if (item.key === 'state') {
					data.push(selectedRows[i][item.dataIndex] == 1 ? '已入住' : '空置');
				}
				else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '房间');
		/* save to file */
		XLSX.writeFile(wb, '房间.xlsx');
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				tableExtraRender={tableExtraRender}
				rowSelection={rowSelection}
				rowKey={'uuid'}
				// options={false}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanXuan ? dataTotal :selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
										if (quanXuan) {
											setselectedRowKeys([])
										}
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={16}>
							<Button
								type={'link'}
								onClick={() => {
									updateSell(selectedRowKeys, '出售');
								}}
							>
								出售
							</Button>
							<Button
								type={'link'}
								onClick={() => {
									updateSell(selectedRowKeys, '锁定');
								}}
							>
								锁定
							</Button>
							<Button
								type={'link'}
								onClick={() => {
									updateSell(selectedRowKeys, '不出售');
								}}
							>
								不出售
							</Button>
							<Button
								type={'link'}
								onClick={() => {
									updateCharge(selectedRowKeys);
								}}
							>
								设置收费项目
							</Button>
							{/* <Button
								type={'link'}
								onClick={() => {
									setroomUuids(selectedRowKeys.toString());
									setcreateChargeVisible(true);
								}}
							>
								生成缴费单
							</Button> */}
							<Space size={16}>
								<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							</Space>
						</Space>
					);
				}}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findLiveRoom({
						page: params.current,
						size: params.pageSize,
						Search: search,
						buildUuid: searchBuild,
						unit: searchUnit,
						layer: searchLayer,
						type: type,
						state: state == 2 ? undefined : state,
						houseType: houseType,
						houseStyle: houseStyle,
						roomItemStatus: roomItemStatus
					});
					if (quanXuan) {
						let uuids: any = [];
						res.data.map((item: any) => {
							uuids.push(item.uuid);
						});
						setselectedRowKeys(uuids);
						setdataTotal(res.count);
					}
					setdataTotal(res.count);
					if (res.err !== 0) {
						return {
							data: [],
							total: 0,
						};
					}
					setRoomStatistics(res.roomStatistics);
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="新增房间"
				visible={addModalvisible}
				onCancel={() => {
					setAddModalvisible(false);
				}}
				width={1300}
				onOk={insertRoom}
				okText={'添加'}
				okButtonProps={{ disabled: isDisabled }}
			>
				<div>
					<Form form={form} {...formItemLayout}>
						<FormItem hidden={true} name={'deviceId'} />
						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={8}>
								<FormItem
									{...formItemLayout}
									label={'位置'}
									name={'address'}
									rules={[{ required: true }]}
								>
									<Cascader
										style={{ width: '100%' }}
										placeholder={'请选择位置'}
										options={options}
										onChange={position}
										changeOnSelect={true}
									/>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem
									{...formItemLayout}
									label={'房间号'}
									name={'roomNumber'}
									rules={[{ required: true }]}
								>
									<Input />
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem {...formItemLayout} label={'设备ID'} name="deviceId">
									<Select optionFilterProp="children" showSearch style={{ width: '100%' }}>
										{deviceList.length > 0
											? deviceList.map((item: any) => {
													return (
														<Select.Option key={item.deviceid} value={item.deviceid}>
															{item.description}
														</Select.Option>
													);
											  })
											: ''}
									</Select>
								</FormItem>
							</Col>
						</Row>
						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={8}>
								<FormItem label={'资产类型'} name={'type'}>
									<Select>
										{assetsTypeList.map((item: any) => {
											return (
												<Select.Option key={item} value={item}>
													{item}
												</Select.Option>
											);
										})}
									</Select>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'户型'} name={'houseType'}>
									<Select>
										{houseTypeList.map((item: any) => {
											return (
												<Select.Option key={item} value={item}>
													{item}
												</Select.Option>
											);
										})}
									</Select>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'装修风格'} name={'houseStyle'}>
									<Select>
										{houseStyleList.map((item: any) => {
											return (
												<Select.Option key={item} value={item}>
													{item}
												</Select.Option>
											);
										})}
									</Select>
								</FormItem>
							</Col>
						</Row>
						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={8}>
								<FormItem label={'建筑面积(㎡)'} name={'acreage'}>
									<InputNumber
										style={{ width: '100%' }}
										min={0}
										step={0.0001}
										placeholder={'请输入'}
									/>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'套内面积(㎡)'} name={'builtInArea'}>
									<InputNumber
										style={{ width: '100%' }}
										min={0}
										step={0.0001}
										placeholder={'请输入'}
									/>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'计费面积(㎡)'} name={'chargeArea'}>
									<InputNumber
										style={{ width: '100%' }}
										min={0}
										step={0.0001}
										placeholder={'请输入'}
									/>
								</FormItem>
							</Col>
						</Row>
						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={8}>
								<FormItem label={'花园面积(㎡)'} name={'gardenArea'}>
									<InputNumber
										style={{ width: '100%' }}
										min={0}
										step={0.0001}
										placeholder={'请输入'}
									/>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'阁楼面积(㎡)'} name={'loftArea'}>
									<InputNumber
										style={{ width: '100%' }}
										min={0}
										step={0.0001}
										placeholder={'请输入'}
									/>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'是否出售'} name={'sell'}>
									<Select style={{ width: '100%' }}>
										<Select.Option key={'2'} value={2}>
											出售
										</Select.Option>
										<Select.Option key={'1'} value={1}>
											锁定
										</Select.Option>
										<Select.Option key={'0'} value={0}>
											不出售
										</Select.Option>
									</Select>
								</FormItem>
							</Col>
						</Row>

						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={8}>
								<FormItem label={'月租金'} name={'monthRent'}>
									<InputNumber style={{ width: '100%' }} min={0} step={0.01} placeholder={'请输入'} />
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'押金'} name={'deposit'}>
									<InputNumber style={{ width: '100%' }} min={0} step={0.01} placeholder={'请输入'} />
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'1㎡/天租金'} name={'dayAreaPay'}>
									<InputNumber style={{ width: '100%' }} min={0} step={0.01} placeholder={'请输入'} />
								</FormItem>
							</Col>
						</Row>

						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={8}>
								<FormItem label={'朝向'} name={'orientation'}>
									<Input />
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label={'房间数量'} name={'roomsNum'}>
									<InputNumber style={{ width: '100%' }} min={0} placeholder={'请输入'} />
								</FormItem>
							</Col>
						</Row>
						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={24}>
								<FormItem {...formItemLayout1} label={'房间介绍'} name={'description'}>
									<Input.TextArea autoSize={{ minRows: 4 }} />
								</FormItem>
							</Col>
						</Row>

						<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={24}>
								<FormItem {...formItemLayout1} label={'备注'} name={'note'}>
									<Input.TextArea autoSize={{ minRows: 4 }} />
								</FormItem>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
			<Modal
				title={fangjianTitle}
				visible={roomVisiable}
				width={1300}
				footer={null}
				onCancel={() => {
					setRoomVisiable(false);
				}}
			>
				<RoomAndHousehold key={new Date().getTime()} roomUUid={roomUuid} roomAndHouse={roomAndHouse} />
			</Modal>
			<Modal
				title="开门记录"
				visible={kaimenVisiable}
				width={1200}
				footer={null}
				onCancel={() => {
					setKaimenVisiable(false);
				}}
			>
				<KaimenList key={deviceId} deviceId={deviceId} deviceName={deviceName} userId={''} />
			</Modal>

			<Modal
				width={1200}
				visible={batchChargeVisible}
				onCancel={() => {
					setbatchChargeVisible(false);
				}}
				footer={null}
			>
				<RoomChargeList batch={true} key={roomUuids} roomUuid={roomUuids}></RoomChargeList>
			</Modal>
			<Modal
				width={1200}
				visible={createChargeVisible}
				onCancel={() => {
					setcreateChargeVisible(false);
				}}
				footer={null}
			>
				<CreateCharge key={roomUuids} roomUuid={roomUuids}></CreateCharge>
			</Modal>
		</div>
	);
};

export default connect(({ common }: ConnectState) => ({
	common: common,
}))(RoomList);
