import React, {useEffect, useRef, useState} from "react";
import {ActionType} from "@ant-design/pro-table";
import {batchEmp, findAllPersonManage} from "../../../services/WuyeService";
import GSTable from "../../../components/GSTable";
import { Button, message, Modal, Space, Tag, Upload } from 'antd';
import {UploadOutlined} from "@ant-design/icons/lib";
import {batchImportHousehold} from "../../../services/ZhuhushenheService";
import API from "../../../data/API";
import {batchCar} from "../../../services/Car";
import * as XLSX from 'xlsx';
import { findList } from "../../../services/BlackUser";
let myXlsx = require('../../../util/myXlsx');
let moment = require('moment');
const BatchCar: React.FC = () => {
	useEffect(() => {
		getBlackList()


	}, [])
	const getBlackList = async () => {
		const res = await findList({})
		if (res.err == 0) {
			console.log("blackList::",res.data);

			setblackList(res.data || [])
		}
	}
	const [blackList, setblackList] = useState([])
	const [content,setContent] = useState("")
	const actionRef = useRef<ActionType>();
	const [batchData,setBatchData] = useState([])
	const [confirmLoading,setConfirmLoading] = useState(false)
	const [success,setSuccess] = useState(true)
	const [errVisible, setErrVisible] = useState(false);
	const [errData, setErrData] = useState([]);
	const [size, setSize] = useState(0);
	const [errCount, setErrCount] = useState(0);
	const typeEnum ={
		"员工":"固定员工",
		"临时员工":"临时员工",
		"访客":"访客"
	}
	const batchColumns = [
		{
			title: '车牌号',
			key: '车牌号',
			width: 100,
			dataIndex: '车牌号',
		},
		{
			title: '机号',
			key: '机号',
			width: 100,
			dataIndex: '机号',
		},
		{
			title: '人员类型',
			key: '人员类型',
			width: 100,
			dataIndex: '人员类型',
			render:(text:string,record:any)=>(
				<span>{record.人员类型 === '员工'?'固定员工':record.人员类型}</span>
			)
		},
		{
			title: '车辆责任人',
			key: '车辆责任人',
			width: 100,
			dataIndex: '车辆责任人',
		},{
			title: '责任人身份证号',
			key: '责任人身份证号',
			width: 150,
			dataIndex: '责任人身份证号',
		},{
			title: '驾驶证档案编号',
			key: '驾驶证档案编号',
			width: 150,
			dataIndex: '驾驶证档案编号',
		},
		{
			title: '联系电话',
			key: '联系电话',
			width: 100,
			dataIndex: '联系电话',
		},{
			title: '车辆性质',
			key: '车辆性质',
			width: 100,
			dataIndex: '车辆性质',
		},{
			title: '车辆所有人名称',
			key: '车辆所有人名称',
			width: 150,
			dataIndex: '车辆所有人名称',
		},{
			title: '车辆品牌',
			key: '车辆品牌',
			width: 100,
			dataIndex: '车辆品牌',
		},
		{
			title: '开始时间',
			key: '开始时间',
			width: 100,
			dataIndex: '开始时间',
		},
		{
			title: '截止时间',
			key: '截止时间',
			width: 100,
			dataIndex: '截止时间',
		},

	];
	const errorColumns = [
		{
			title: '车牌号',
			key: '车牌号',
			dataIndex: '车牌号',
		},
		{
			title: '机号',
			key: '机号',
			dataIndex: '机号',
		},
		{
			title: '人员类型',
			key: '人员类型',
			dataIndex: '人员类型',
			render:(text:string,record:any)=>(
				<span>{record.人员类型 === '员工'?'固定员工':record.人员类型}</span>
			)
		},
		{
			title: '车辆责任人',
			key: '车辆责任人',
			dataIndex: '车辆责任人',
		},{
			title: '责任人身份证号',
			key: '责任人身份证号',
			dataIndex: '责任人身份证号',
		},{
			title: '驾驶证档案编号',
			key: '驾驶证档案编号',
			dataIndex: '驾驶证档案编号',
		},
		{
			title: '联系电话',
			key: '联系电话',
			dataIndex: '联系电话',
		},{
			title: '车辆性质',
			key: '车辆性质',
			dataIndex: '车辆性质',
		},{
			title: '车辆所有人名称',
			key: '车辆所有人名称',
			dataIndex: '车辆所有人名称',
		},{
			title: '车辆品牌',
			key: '车辆品牌',
			dataIndex: '车辆品牌',
		},
		{
			title: '开始时间',
			key: '开始时间',
			dataIndex: '开始时间',
		},
		{
			title: '截至时间',
			key: '截至时间',
			dataIndex: '截至时间',
		},
		{
			title: "错误原因",
			key: "errorMsg",
			dataIndex: "errorMsg",
			render: (text: string) => {
				return <div style={{ color: "red" }}>{text}</div>;
			}
		}

	];
	const getData = () =>{
		return{
			data:batchData
		}
	}
	const isNull = (string:any) =>{
		if(string===null||string===undefined||string===''){
			return true
		}else{
			return false
		}
	}
	const formatExcelDate = (num:any, format = '-') =>{
		num = Number(num);	// 强制类型转化，以防传来的值是字符串
		let millisecond = 0;	// 转化后的毫秒数
		if (num > 60) {
			millisecond = (num - 25569) * 60 * 60 * 24 * 1000;
		} else { // 对小于61的错误日期进行处理
			millisecond = (num - 25568) * 60 * 60 * 24 * 1000;
		}
		let date = new Date(millisecond);	// 根据转化后的毫秒数获取对应的时间
		let yy = date.getFullYear();
		let mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
		let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
		return yy + format + mm + format + dd;	// 返回格式化后的日期
	}

	const  checkMobileNumber = (mobile:any) => {
		if (!mobile) { return false; }
		let pattern = /^1[3456789]\d{9}$/;
		return pattern.test(mobile);
	}
	const isLicenseNo = (str: any) => {
		var regExp = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[A-Z])|([A-Z]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;
		if (!regExp.test(str)) {
			return false;
		} else {
			console.log('车牌号正确');
			return true;
		}
	};
	const /*导入信息显示*/
		analyze = (evv: any) => {
			console.log('上传返回', evv);
			myXlsx.readData(evv, function (res: any) {
				let index = 0
				const size = res.length
				let errData:any = []
				let errCount = 0
				let departData:any = []
				res.map((item:any)=>{
					if(isNull(item.状态)){
						item.状态 = "正常"
					}
					if(isNull(item.人员类型)){
						item.人员类型 = "访客"
					}else{
						if(item.人员类型 == "固定员工"){
							item.人员类型 = "员工"
						}
					}
					if(item.人员类型 == '临时人员'||item.人员类型 == '访客'){
						if(isNull(item.开始时间)){
							item["errorMsg"] = "请填写开始时间";
							errCount++;
							errData.push(item)
							index++
							return
						}
						if(isNull(item.截止时间)){
							item["errorMsg"] = "请填写截止时间";
							errCount++;
							errData.push(item)
							index++
							return
						}
					}
					if(!isNull(item.开始时间)){
						item.开始时间 = item.开始时间+ ' 00:00:00'
					}
					if(!isNull(item.截止时间)){
						item.截止时间 = item.截止时间+ ' 00:00:00'
					}

					if(isNull(item.机号)){
						item["errorMsg"] = "请填写机号";
						errCount++;
						errData.push(item)
						index++
						return
					}
					if(isNull(item.车牌号)){
						item["errorMsg"] = "请填写车牌号";
						errCount++;
						errData.push(item)
						index++
						return
					}
					if(isNull(item.车辆责任人)){
						item["errorMsg"] = "请填写车辆责任人";
						errCount++;
						errData.push(item)
						index++
						return
					}
					if(isNull(item.联系电话)){
						item["errorMsg"] = "请填写联系电话";
						errCount++;
						errData.push(item)
						index++
						return
					}
					if(!isNull(item.联系电话)){
						item.联系电话 = item.联系电话.toString().replace(/\s*/g,"")
						if(!checkMobileNumber(item.联系电话)){
							item["errorMsg"] = "联系电话格式不对";
							errCount++;
							errData.push(item)
							index++
							return
						}
					}

					if(!isNull(item.责任人身份证号)){
						item.责任人身份证号 = item.责任人身份证号.toString().replace(/\s*/g,"").toUpperCase()
						if(item.责任人身份证号.length != 18){
							item["errorMsg"] = "身份证格式不对";
							errCount++;
							errData.push(item)
							index++
							return
						}
					}
					if(!isNull(item.车牌号)){
						item.车牌号 = item.车牌号.toString().replace(/\s*/g,"").toUpperCase()
						if(!isLicenseNo(item.车牌号)){
							item["errorMsg"] = "车牌号格式不对";
							errCount++;
							errData.push(item)
							index++
							return
						}
						if (blackList.length > 0) {
							if (blackList.findIndex(data => data.paramInfo == item.车牌号) != -1) {
								item["errorMsg"] = "黑名单";
								errCount++;
								errData.push(item)
								index++
								return
							}
						}
					}
					index++
					return
				})
				if(index === size){
					setSize(size);
					setErrCount(errCount)
					if (errCount > 0) {
						setSuccess(false);
						setErrVisible(true);
						setErrData(errData);
					} else {
						setSuccess(true);
						setBatchData(res);
						actionRef.current.reload();
					}
				}

			});
		};
	/** 模板下载 */
	const exportBmry = () => {
		window.open(
			'https://pcdn.guosim.com/template/%E8%BD%A6%E8%BE%86%E5%AF%BC%E5%85%A5.xlsx',
			'_blank'
		);
	};
	const errorTableOperationBarRender = () => {
		return [<Space>
			<Button type="primary" onClick={exportData}>
				导出
			</Button>
		</Space>];
	};
	const exportData = () => {
		let sheetData = new Array(errData.length + 1);
		let titles: [string?] = [];
		errorColumns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < errData.length; i++) {
			let data: [string?] = [];
			errorColumns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[errData[i][item.dataIndex]]) {
						data.push(item.valueEnum[errData[i][item.dataIndex]].props.children);
					} else {
						data.push("");
					}
				} else if (item.valueType === "dateTime") {
					data.push(moment(errData[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
				} else {
					data.push(errData[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "导入失败数据");

		/* save to file */
		XLSX.writeFile(wb, "导入失败数据.xlsx");
	};
	const tableSearchBarRender = () =>[
		<span>{content}</span>

	]
	const tableOperationsBarRender = () =>[
		<Space >
			<Upload showUploadList={false} onChange={analyze} accept={'.xlsx'}>
				<Button>
					<UploadOutlined /> 选择文件
				</Button>
			</Upload>
			<Button type="primary" onClick={exportBmry}>
				模版下载
			</Button>
			<Button type="primary" loading={confirmLoading} onClick={batchImport}>
				开始导入
			</Button>
		</Space>
	]

	/*导入数据*/
	const batchImport = async () => {
		if(batchData.length==0){
			message.warning("请先导入数据")
			return
		}
		const data = {
			data:JSON.stringify(batchData),
		}
		if(success){
			setConfirmLoading(true)
			const res = await batchCar(data)
			setConfirmLoading(false)
			if(res.err === 0){
				const size = batchData.length;
				setSize(size);
				const errCount = res.errCount;
				setErrCount(errCount);
				if (errCount > 0) {
					setErrVisible(true);
					setErrData(res.errData);
					setBatchData([]);
					actionRef.current.reload();
					message.success("导入" + size + "条" + "失败" + errCount + "条");
				} else {
					setBatchData([]);
					actionRef.current.reload();
					message.success("导入" + size + "条，成功" + size + "条");
				}
			}
		}else{
			message.error(content)
			return
		}
	};
	return(
		<>
			<GSTable
				columns={batchColumns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					return getData()
				}}
				options={false}
			/>
			<Modal maskClosable={false} destroyOnClose={true} title={"结果"} width={1280} footer={null} visible={errVisible}
				   onOk={() => setErrVisible(false)}
				   onCancel={() => {
					   setErrVisible(false);
				   }}>
				<GSTable size={"small"} searchBarRender={tableSearchBarRender()}
						 operationsBarRender={errorTableOperationBarRender()}
						 options={false} request={() => {
					return {
						data: errData
					};
				}} columns={errorColumns}/>
			</Modal>
		</>
	)
}
export default BatchCar;
