import * as React from 'react';
import { Button, Col, Form, Input, message, Modal, Row, Space, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons/lib';
import CustomUploadImage from '../../../components/CustomUploadImage';
import { info, updateInfo } from '../../../services/CompanyService';
import AuthorizedView from '../../../util/AuthorizedView';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import Api from '../../../data/API'
const msg = require('../../../util/SuspensionMsg');

export default function CompanyInfo(): JSX.Element {
	useEffect(() => {
		getData();
	}, []);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');
	let [fileList, setFileList] = useState<any[]>([]);
	const [companyStatus, setCompanyStatus] = useState('');
	const [isModify, setModify] = useState(false);
	const [urls, setUrls] = useState<string[]>([]);
	const [form] = Form.useForm<FormInstance>();
	const getBase64 = (file: any) => {
		return new Promise((resovle, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resovle(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const FormItem = Form.Item;
	const handleCancel = () => {
		setPreviewVisible(false);
	};

	const handlePreview = async (file: any) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
	};

	const handleChange = (list: any) => {
		console.log('filelist:', list);
		if (list.file.status === 'done') {
			setFileList(list.fileList);
			console.log(`${list.file.name}`);
			console.log('企业图标', list.file.response.url);
			setUrls(list.file.response.data);
		} else if (list.file.status === 'error') {
			msg.suspensionMsg({ content: `${list.file.name} 上传文件失败`, type: 'danger' });
		} else if (list.file.status === 'removed') {
			fileList = fileList.filter((item: any) => item.status !== 'removed');
			setFileList(fileList);
		}
	};

	const getImage = (urls: string) => {
		let array = (urls || '').split(',');
		console.log('array', array);
		if (array.length === 1 && array[0] === '') return;
		let list: any[] = [];
		for (let i = 0; i < array.length; i++) {
			let url = array[i];
			list.push({
				uid: i,
				url: url,
				name: url.substring(url.lastIndexOf('/') + 1),
				status: 'done',
			});
		}
		console.log('filelist', fileList);
		fileList = list;
		setFileList(fileList);
		console.log('filelist', fileList);
	};

	/**
	 * 获取信息
	 */
	const getData = async () => {
		console.log('获取数据');
		const res = await info({
			companyUuid: localStorage.getItem('companyUuid'),
		});
		console.log('公司信息', res);
		if (res.err != 0) {
			msg.suspensionMsg({ content: res.msg, type: 'danger' });
			return;
		}
		getImage(res.data.companyIcon);
		setCompanyStatus(res.data.companyStatus);
		const formData: any = {
			companyName: res.data.companyName,
			connectName: res.data.connectName,
			connectEmail: res.data.connectEmail,
			connectPhone: res.data.connectPhone,
			connectTel: res.data.connectTel,
			companyAddress: res.data.companyAddress,
		};
		form.setFieldsValue(formData);
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);

	const modify = () => {
		setModify(true);
	};
	const save = async () => {
		form.validateFields().then(async (data: any) => {
			let urlStr = '';
			console.log('save filelist', fileList);
			for (let i = 0; i < fileList.length; i++) {
				if (fileList[i].url !== undefined) {
					urlStr += fileList[i].url;
				}
			}
			for (let i = 0; i < urls.length; i++) {
				urlStr += urls[i] + ',';
			}
			if (urls.length > 0) urlStr = urlStr.substr(0, urlStr.lastIndexOf(','));
			console.log('urls', urlStr);
			let formData: any = {
				companyUuid: localStorage.getItem('companyUuid'),
				companyIcon: urlStr,
				companyName: data.companyName,
				companyStatus: companyStatus,
				connectName: data.connectName,
				connectEmail: data.connectEmail,
				connectPhone: data.connectPhone,
				connectTel: data.connectTel,
				companyAddress: data.companyAddress,
			};
			const res = await updateInfo(formData);
			if (res.err != 0) {
				msg.suspensionMsg({ content: res.msg, type: 'danger' });
			} else {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
			}

			setModify(false);
		});
	};

	const cancel = () => {
		setModify(false);
	};
	const formLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 16 },
	};
	const formLayout1 = {
		labelCol: { span: 3 },
		wrapperCol: { span: 20 },
	};
	const render = () => (fileList.length >= 1 ? null : uploadButton);
	return (
		<div className="content-big">
			<div className={'content'} style={{ fontSize: 14, paddingBottom: 20 }}>
				<div style={{ marginLeft: 30 }}>
					<div style={{ position: 'absolute', right: 30 }}>
						{!isModify && (
							<AuthorizedView needAuthority={'admission:company:update'}>
								<Button onClick={modify}>修改</Button>
							</AuthorizedView>
						)}
						{isModify && (
							<Space>
								<Button type={'primary'} onClick={save}>
									保存
								</Button>
								<Button onClick={cancel}>取消</Button>
							</Space>
						)}
					</div>
					<Form form={form} {...formLayout}>
						<Row align={'middle'} justify={'start'} gutter={16}>
							<Col span={12}>
								<FormItem name={'companyIcon'} label={'企业图标'}>
									<CustomUploadImage
										key={fileList}
										disabled={!isModify}
										cdn={'public'}
										listType="picture-card"
										fileList={fileList.length > 0 ? fileList : null}
										onPreview={handlePreview}
										onChange={handleChange}
										render={render()}
									/>
									<Modal
										visible={previewVisible}
										title={previewTitle}
										footer={null}
										onCancel={handleCancel}
									>
										<img alt="example" style={{ width: '100%' }} src={previewImage} />
									</Modal>
								</FormItem>
							</Col>
						</Row>
						<Row align={'middle'} justify={'start'} gutter={16} className={'margin-top--10'}>
							<Col span={12}>
								<FormItem name={'companyName'} label={'企业名称'} rules={[{ required: true }]}>
									<Input placeholder={'请输入'} disabled={!isModify} />
								</FormItem>
							</Col>
							<Col span={12}>
								<FormItem name={'companyStatus'} label={'状态'}>
									{companyStatus}
								</FormItem>
							</Col>
						</Row>
						<Row align={'middle'} justify={'start'} gutter={16} className={'margin-top--10'}>
							<Col span={12}>
								<FormItem name={'connectName'} label={'联系人姓名'} rules={[{ required: true }]}>
									<Input placeholder={'请输入'} disabled={!isModify} />
								</FormItem>
							</Col>
							<Col span={12}>
								<FormItem name={'connectEmail'} label={'联系人邮箱'}>
									<Input placeholder={'请输入'} disabled={!isModify} />
								</FormItem>
							</Col>
						</Row>

						<Row align={'middle'} justify={'start'} gutter={16} className={'margin-top--10'}>
							<Col span={12}>
								<FormItem name={'connectPhone'} label={'联系人电话'} rules={[{ required: true }]}>
									<Input placeholder={'请输入'} disabled={!isModify} />
								</FormItem>
							</Col>
							<Col span={12}>
								<FormItem name={'connectTel'} label={'座机'}>
									<Input placeholder={'请输入'} disabled={!isModify} />
								</FormItem>
							</Col>
						</Row>
						<Row align={'middle'} justify={'start'} gutter={16} className={'margin-top--10'}>
							<Col span={24}>
								<FormItem
									{...formLayout1}
									name={'companyAddress'}
									label={'企业地址'}
									rules={[{ required: true }]}
								>
									<Input placeholder={'请输入'} disabled={!isModify} />
								</FormItem>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</div>
	);
}
