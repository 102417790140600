import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Form, Image, Input, Modal, Select, Space, Tag } from 'antd';
import { FormInstance } from 'antd/es/form';
import appConfig from '../../../appConfig';
import GSTable from '../../../components/GSTable';
import {
	addDevicePatrol,
	currentInspectionPersonnel,
	deleteDevicePatrol,
	findWuyeDevicePatrol,
	updataDeviceName,
} from '../../../services/patrolService';
import ProxyForm from '../../../components/ProxyForm';
var QRCode = require('qrcode.react');
var msg = require('../../../util/SuspensionMsg');

const AllDeviceG: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const [updateForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const confirm = Modal.confirm;
	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const [deviceVisible, setDeviceVisible] = useState(false);
	const [visible, setVisible] = useState(false);
	const [type, setType] = useState('');
	const [malfunction, setMalfunction] = useState('');
	const types = [
		{
			name: '点位类型',
			value: '',
		},
		{
			name: '蓝牙',
			value: 'ble',
		},
		{
			name: '二维码',
			value: 'qrcode',
		},
		{
			name: '拍照',
			value: 'photo',
		},
	];
	const valueEnum = {
		ble: <span>蓝牙</span>,
		qrcode: <span>二维码</span>,
		phote: <span>拍照</span>,
	};
	const columns = [
		{
			title: '设备名称',
			dataIndex: 'deviceName',
			key: 'deviceName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						update(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
			valueEnum: valueEnum,
		},
		{
			title: '设备ID',
			dataIndex: 'deviceId',
			key: 'deviceId',
		},
		{
			title: '设备电量',
			dataIndex: 'deviceBattery',
			key: 'deviceBattery',
		},
		{
			title: '状态',
			dataIndex: 'malfunction',
			key: 'malfunction',
			render: (text: string, record: any) => (
				<Tag color={!record.malfunction ? 'green' : 'red'}>{!record.malfunction ? '正常' : '异常'}</Tag>
			),
		},
		{
			title: '关联图片',
			dataIndex: 'deviceImg',
			key: 'deviceImg',
			render: (text: string, record: any) => (
				<span>
					<span>
						{record.deviceImg1 !== null && record.deviceImg1 !== undefined && record.deviceImg1 !== '' ? (
							<Image width={60} height={60} src={appConfig.cdnRootPath + record.deviceImg1} />
						) : (
							''
						)}
					</span>
					<span>
						{record.deviceImg2 !== null && record.deviceImg2 !== undefined && record.deviceImg2 !== '' ? (
							<Image width={60} height={60} src={appConfig.cdnRootPath + record.deviceImg2 } />
						) : (
							''
						)}
					</span>
					<span>
						{record.deviceImg3 !== null && record.deviceImg3 !== undefined && record.deviceImg3 !== '' ? (
							<Image width={60} height={60} src={appConfig.cdnRootPath + record.deviceImg3 } />
						) : (
							''
						)}
					</span>
				</span>
			),
		},
		{
			title: '备注',
			dataIndex: 'deviceDescribe',
			key: 'deviceDescribe',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<a
						onClick={() => {
							getuuid(record);
						}}
					>
						删除
					</a>
					{record.type === 'qrcode' ? (
						<>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<a
								onClick={() => {
									downloadQR(record.deviceId);
								}}
							>
								下载二维码
								<QRCode
									hidden={true}
									id={'qrCode' + record.deviceId}
									style={{ display: 'flex', alignItems: 'center' }}
									size={320}
									value={record.deviceId}
								/>
							</a>
						</>
					) : (
						''
					)}
				</span>
			),
		},
	];
	const downloadQR = (deviceId) => {
		const canvasImg: any = document.getElementById('qrCode' + deviceId); // 获取canvas类型的二维码
		let link = document.createElement('a');
		link.textContent = 'download image';
		link.setAttribute('href', canvasImg.toDataURL('image/png'));
		link.setAttribute('download', '二维码.png');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const getuuid = (value: any) => {
		confirm({
			title: '您确认要删除该设备吗？',
			okType: 'danger',
			onOk: async () => {
				if (value.uuid === null) {
					msg.suspensionMsg({ content: '删除异常', type: 'danger' });
					return;
				}
				const res = await deleteDevicePatrol({
					uuid: value.uuid,
				});
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: '没有权限管理该设备', type: 'danger' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const update = (value: any) => {
		const formData: any = {
			uuid: value.uuid,
			type: value.type,
			deviceName: value.deviceName,
			describes: value.deviceDescribe,
			deviceId: value.deviceId,
		};
		updateForm.setFieldsValue(formData);
		setVisible(true);
	};

	const tableSearchBarRender = () => [
		<Space>
			<Select
				allowClear
				style={{ width: 150 }}
				value={type}
				placeholder={'类型'}
				onChange={(value: any) => {
					setType(value);
					actionRef.current.reloadAndRest();
				}}
			>
				{types.map((item: any) => {
					return <Select.Option value={item.value}> {item.name}</Select.Option>;
				})}
			</Select>
			<Select
				allowClear
				style={{ width: 150 }}
				value={malfunction}
				placeholder={'状态'}
				onChange={(value: any) => {
					setMalfunction(value);
					actionRef.current.reloadAndRest();
				}}
			>
				<Select.Option value={''}>设备状态</Select.Option>
				<Select.Option value={'0'}>正常</Select.Option>
				<Select.Option value={'1'}>异常</Select.Option>
			</Select>
			<Input
				placeholder="请输入名称"
				onChange={(event) => {
					setSearch(event.target.value);
				}}
			/>
			<Button
				type={'primary'}
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>
		</Space>,
	];
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setDeviceVisible(true);
			}}
		>
			添加点位
		</Button>,
	];
	const updataDevice = () => {
		updateForm.validateFields().then(async (data: any) => {
			console.log('修改数据', data);
			const res = await updataDeviceName({
				uuid: data.uuid,
				deviceName: data.deviceName,
				deviceId: data.deviceId,
				type: data.type,
				describes: data.describes,
			});
			console.log('', res);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
			setVisible(false);
		});
	};

	const addDevice = () => {
		form.validateFields().then(async (data: any) => {
			console.log('添加的数据', data);
			const res = await addDevicePatrol({
				device_id: data.deviceId,
				device_name: data.deviceName,
				type: data.type,
				describes: data.describes,
			});
			console.log('', res);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
				form.resetFields();
				setDeviceVisible(false);
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
		});
	};

	return (
		<div>
			<GSTable
				rowKey={'uuid'}
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findWuyeDevicePatrol({
						page: params.current,
						size: params.pageSize,
						deviceName: search,
						type: type,
						malfunction: malfunction,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="编辑设备"
				visible={visible}
				onOk={updataDevice}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={updateForm}>
					<FormItem hidden={true} label={'uuid'} name={'uuid'}>
						<Input />
					</FormItem>
					<FormItem label={'设备名称'} name={'deviceName'} rules={[{ required: true }]}>
						<Input placeholder={'请输入设备名称'} />
					</FormItem>
					<FormItem label={'类型'} name={'type'} rules={[{ required: true }]}>
						<Select placeholder={'请输入类别'}>
							{types.map((item: any) => {
								return <Select.Option value={item.value}>{item.name}</Select.Option>;
							})}
						</Select>
					</FormItem>
					<FormItem label={'设备Id'} name={'deviceId'} rules={[{ required: true }]}>
						<Input placeholder={'请输入设备Id'} />
					</FormItem>
					<FormItem label={'备注'} name={'describes'}>
						<Input placeholder={'请输入备注'} />
					</FormItem>
				</ProxyForm>
			</Modal>

			<Modal
				title="添加点位"
				visible={deviceVisible}
				onOk={addDevice}
				onCancel={() => {
					setDeviceVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'名称'} name={'deviceName'} rules={[{ required: true }]}>
						<Input placeholder={'请输入名称'} />
					</FormItem>
					<FormItem label={'类型'} name={'type'} rules={[{ required: true }]}>
						<Select placeholder={'请输入类别'}>
							{types.map((item: any) => {
								if (item.value) {
									return <Select.Option value={item.value}>{item.name}</Select.Option>;
								}
								return;
							})}
						</Select>
					</FormItem>
					<FormItem label={'ID'} name={'deviceId'} rules={[{ required: true }]}>
						<Input placeholder={'请输入ID'} />
					</FormItem>
					<FormItem label={'备注'} name={'describes'}>
						<Input placeholder={'请输入备注'} />
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default AllDeviceG;
