import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import Monitor from './page/Monitor';


export default function SafetyMiniApp() {
	const rootUrl = '/safety_management';
	const menus: MiniAppMenu[] = [
		{
			authority: 'safety_management:monitor',
			name: '监控录像',
			path: 'monitor',
			component: Monitor,
		},

	];
	const routes: MiniAppRoute[] = [

	];

	return <MiniApp miniAppName={'物业安防'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
