import Api from '../data/API';
import { func } from "prop-types";

export async function getEmployeeTypeList(params: any) {
	return Api.postWithAuth('/adminapi/employeeType/getEmployeeTypeList', params);
}

export async function addEmployeeType(params: any) {
	return Api.postWithAuth('/adminapi/employeeType/addEmployeeType', params);
}

export async function updateEmployeeType(params: any) {
	return Api.postWithAuth('/adminapi/employeeType/updateEmployeeType', params);
}

export async function deleteEmployeeType(params: any) {
	return Api.postWithAuth('/adminapi/employeeType/deleteEmployeeType', params);
}


export async function initEmployeeType(params: any) {
	return Api.postWithAuth('/adminapi/employeeType/initEmployeeType', params);
}

export async function sortEmployeeType(params: any) {
	return Api.postWithAuth('/adminapi/employeeType/sortEmployeeType', params);
}


export async function setEmployeeTypeDefault(params: any) {
	return Api.postWithAuth('/adminapi/employeeType/setEmployeeTypeDefault', params);
}

