import React, { useEffect, useRef, useState } from 'react';
import ConnectState from '../../../models/connect';
import { connect } from 'dva';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import API from '../../../data/API';
import Search from 'antd/es/input/Search';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import ProxyForm from '../../../components/ProxyForm';
import TextArea from 'antd/es/input/TextArea';
import { addBuild, deleteBuild, updataBuild } from '../../../services/ZhuhushenheService';

const LouDong: React.FC = (props: any) => {
	const confirm = Modal.confirm;
	const FormItem = Form.Item;
	const [form] = Form.useForm<FormInstance>();
	const [addForm] = Form.useForm<FormInstance>();
	const { common } = props;
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [updateModalvisible, setUpdateModalvisible] = useState(false);
	const [addModalvisible, setAddModalvisible] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const columns = [
		{
			title: '编号',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: '名称',
			dataIndex: 'buildName',
			key: 'buildName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						update(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '单元数量',
			dataIndex: 'unitNumber',
			key: 'unitNumber',
		},
		{
			title: '层数',
			dataIndex: 'layers',
			key: 'layers',
		},
		{
			title: '房间数',
			key: 'roomNumber',
			dataIndex: 'roomNumber',
		},
		{
			title: '每层房间数',
			key: 'floorRoomNumber',
			dataIndex: 'floorRoomNumber',
		},
		{
			title: '备注',
			key: 'comment',
			dataIndex: 'comment',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<a
						onClick={() => {
							getuuid(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];

	const getuuid = (record: any) => {
		console.log('id', record);
		confirm({
			title: '删除楼栋',
			content: '您确认要删除" ' + record.buildName + ' "？',
			okText: '确定',
			okType: 'danger',
			maskClosable: true,
			cancelText: '取消',
			onOk() {
				deleteItem(record);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const deleteItem = async (record: any) => {
		if (record.uuid === null) {
			message.warning('删除异常');
			return;
		}
		setIsDisabled(true);
		const res = await deleteBuild({ uuid: record.uuid });

		if (res.err === 0) {
			message.success('删除成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
		setIsDisabled(false);
	};

	const tableSearchBarRender = () => [
		<Input
			placeholder="楼栋名称"
			onChange={(event) => {
				setSearch(event.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	const update = (record: any) => {
		const item: any = {
			uuid: record.uuid,
			buildName: record.buildName,
			unitNumber: record.unitNumber,
			layers: record.layers,
			describe: record.describe,
			comment: record.comment,
			floorRoomNumber: record.floorRoomNumber,
			buildNumber: record.number,
		};
		form.setFieldsValue(item);
		setUpdateModalvisible(true);
	};

	const updateBuild = () => {
		form.validateFields().then(async (data: any) => {
			setIsDisabled(true);
			const res = await updataBuild(data);
			if (res.err === 0) {
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('修改失败');
			}
			setUpdateModalvisible(false);
			setIsDisabled(false);
		});
	};

	const addBuilds = () => {
		form.validateFields().then(async (data: any) => {
			setIsDisabled(true);
			const res = await addBuild(data);
			if (res.err === 0) {
				message.success('添加成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
			setAddModalvisible(false);
			setIsDisabled(false);
		});
	};

	const resetForm = () => {
		form.resetFields();
	};

	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				resetForm();
				setAddModalvisible(true);
			}}
		>
			新增
		</Button>,
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let url = '/api/zhuhu/find_builds';
					let res = await API.postWithAuth(url, {
						page: params.current,
						size: params.pageSize,
						search: search,
					});
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>

			<Modal
				title="修改楼栋"
				visible={updateModalvisible}
				onCancel={() => {
					setUpdateModalvisible(false);
				}}
				onOk={updateBuild}
				okText={'修改'}
				okButtonProps={{ disabled: isDisabled }}
			>
				<ProxyForm form={form}>
					<FormItem name="uuid" label="uuid" hidden={true}>
						<Input />
					</FormItem>
					<FormItem name="buildNumber" label="楼栋编号" rules={[{ required: true }]}>
						<InputNumber style={{width: '100%'}} max={9999} placeholder="请输入楼栋编号" />
					</FormItem>
					<FormItem name="buildName" label="楼栋名称" rules={[{ required: true }]}>
						<Input placeholder="请输入楼栋名称" />
					</FormItem>
					<FormItem name="unitNumber" label="单元数量" rules={[{ required: false }]}>
						<InputNumber style={{width: '100%'}} placeholder="请输入单元数量" />
					</FormItem>
					<FormItem name="layers" label="层数" rules={[{ required: false }]}>
						<InputNumber style={{width: '100%'}} placeholder="请输入层数" />
					</FormItem>
					<FormItem name="floorRoomNumber" label="每层房间数" rules={[{ required: false }]}>
						<InputNumber style={{width: '100%'}} placeholder="请输入每层房间数" />
					</FormItem>
					<FormItem name="comment" label="备注">
						<TextArea name="comment" />
					</FormItem>
				</ProxyForm>
			</Modal>
			{/*新增楼栋*/}
			<Modal
				title="新增楼栋"
				visible={addModalvisible}
				onCancel={() => {
					setAddModalvisible(false);
				}}
				onOk={addBuilds}
				okText={'添加'}
				okButtonProps={{ disabled: isDisabled }}
			>
				<ProxyForm form={form}>
					<FormItem name="buildNumber" label="楼栋编号" rules={[{ required: true }]}>
						<InputNumber style={{width: '100%'}} max={9999} placeholder="请输入楼栋编号" />
					</FormItem>
					<FormItem name="buildName" label="楼栋名称" rules={[{ required: true }]}>
						<Input placeholder="请输入楼栋名称" />
					</FormItem>
					<FormItem name="unitNumber" label="单元数量" rules={[{ required: false }]}>
						<InputNumber style={{width: '100%'}} placeholder="请输入单元数量" />
					</FormItem>
					<FormItem name="layers" label="层数" rules={[{ required: false }]}>
						<InputNumber style={{width: '100%'}} placeholder="请输入层数" />
					</FormItem>
					<FormItem name="floorRoomNumber" label="每层房间数" rules={[{ required: false }]}>
						<InputNumber style={{width: '100%'}} placeholder="请输入每层房间数" />
					</FormItem>
					<FormItem name="comment" label="备注">
						<TextArea name="comment" />
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};

export default connect(({ common }: ConnectState) => ({
	common: common,
}))(LouDong);
