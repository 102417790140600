import * as React from 'react';
import { Input } from 'antd';

/**
 * 多行文本
 */

interface InputTextareaProps {
	title: string;
	descr: string;
	uuid: string;
	initialValue: string;
	required: string;
	width: any;
	height: any;
	aliasName: string;
	isHide: boolean;
}

class InputTextarea extends React.Component<InputTextareaProps> {
	constructor(props: any) {
		super(props);
		this.state = { selectValue: '', radio: {} };
		this.select = this.select.bind(this);
	}

	select(res: any) {
		(this.state as any).radio[res.target.value] = res.target.checked;
		this.setState({
			selectValue: res.target.value,
		});
	}

	render() {
		return (
			<div style={{ paddingBottom: 10 }} hidden={this.props.isHide}>
				<div>
					<span style={{ color: 'red' }}>
						<i
							style={{ fontSize: 10 }}
							className={this.props.required === 'required' ? 'icon icon-asterisk' : ''}
						/>
					</span>
					{this.props.title === '' ? '未命名' : this.props.title}
				</div>
				{/* <div style={{color: 'red'}}><i className={this.props.required ? 'icon icon-asterisk' : ''}/>{this.props.required ? '必填' : ''}</div>
				 */}
				{/* <div>{this.props.descr}</div>*/}
				<div dangerouslySetInnerHTML={{ __html: this.props.descr }} />
				<Input
					className={this.props.required}
					required={true}
					type="text"
					hidden={true}
					value={(this.state as any).selectValue}
					name={this.props.uuid}
				/>
				<Input.TextArea
					rows={3}
					style={{ width: this.props.width, height: this.props.height, borderRadius: 0, borderColor: '#ddd' }}
					id={this.props.uuid}
					value={(this.state as any).selectValue}
					onInput={this.select}
				/>
				{this.props.aliasName === '' ||
				this.props.aliasName === null ||
				this.props.aliasName === 'null' ||
				this.props.aliasName === undefined ||
				window.location.href.includes('user-add-shilie') ||
				window.location.href.includes('liucheng-dan?shili_uuid') ? (
					''
				) : (
					<text style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						别名: + {this.props.aliasName}
					</text>
				)}
			</div>
		);
	}
}

export default InputTextarea;
