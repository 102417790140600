import React, {useEffect} from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import AttendanceRecords from "./pages/AttendanceRecords";

export default function AttendanceManageMiniApp() {

    const rootUrl = '/attendance';
    useEffect(() => {
      console.log('列表管理')
    }, [])
    const menus: MiniAppMenu[] = [
        {
            authority: 'attendance_record',
            name: '考勤记录',
            path: 'record',
            component: AttendanceRecords,
        },

    ];

    return <MiniApp miniAppName={'考勤管理'} rootUrl={rootUrl} menus={menus} />;
}
