import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import * as Sentry from "@sentry/react";
import 'moment/locale/zh-cn';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import App from './App';
import dva from 'dva';
import createHashHistory from 'history/createHashHistory';


Sentry.init({
	dsn: "https://4d7f8a81712de1593ede3e0524bea658@o4506298566705152.ingest.sentry.io/4506349459144704",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const app = dva({
	history: createHashHistory()
});
moment.locale('zh_cn')
const userName = localStorage.getItem('userName');
let watermark = localStorage.getItem('watermark');
console.log('watermark:::', watermark);
app.model(require('./models/Common').default);
app.model(require('./models/login').default);
app.router(() => (
	<BrowserRouter>
		<ConfigProvider locale={zh_CN}>
			<App/>
		</ConfigProvider>
	</BrowserRouter>
));
app.start('#root');
//registerServiceWorker();

// TODO 快速开发阶段，关闭缓存
unregisterServiceWorker();
