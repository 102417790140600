import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Space, Switch } from 'antd';
import DAL from '../../../data/DAL';
import { addRouteDevice, getByRouteDevice } from '../../../services/patrolService';
import Api from '../../../data/API';
import $ from 'jquery';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import DragableBodyRow from '../../../components/DragableBodyRow';
import { DndProvider } from 'react-dnd';
import HTMLBackend from 'react-dnd-html5-backend';
// eslint-disable-next-line
let snapsvg = require('imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js');

require('./patrolplane.css');
const msg = require('../../../util/SuspensionMsg');

const RouteChooseDevice: React.FC = () => {
	useEffect(() => {
		getRoute();
		console.log('routeType::', routeType);
	}, []);
	let arr: any = [];
	let route: any = [];
	let dragIndex: any;
	let dragX = 0;
	let dragY = 0;
	let dragFlag = false;
	let readonly1 = null;
	let removeitem = true;
	const [deviceId, setDeviceId] = useState<any>(null);
	const dal = DAL.dal;
	const [imgWidth, setImgWidth] = useState(0);
	const [imgHight, setImgHight] = useState(0);
	const [imgName, setImgName] = useState('');
	const planUUID = dal.getQueryString('uuid');
	const routeUuid = dal.getQueryString('routeUuid');
	const routeType = dal.getQueryString('routeType');
	const [routes, setRoutes] = useState<any>([]);
	const [positions, setPositions] = useState([]);
	const actionRef: any = useRef<ActionType>();
	const [showName, setShowName] = useState(false);
	const [spotLinePoints, setSpotLinePoints] = useState<any>([]);
	const callback = async (b: any) => {
		console.log('提交数据ASAAA' + JSON.stringify(b));
		if (b === '' || b === null) {
			alert(false);
			return;
		}
		const res = await addRouteDevice({
			data: JSON.stringify(b),
			routeUuid: routeUuid,
			lineDate: JSON.stringify(spotLinePoints),
		});
		console.log('', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '设置成功', type: 'success' });
			if (routeType === 'xunjian') {
				window.location.href = '/patrolinspection/inspection-route-list';
			} else {
				window.location.href = '/patrolinspection/patrol-route-list';
			}
		} else {
			msg.suspensionMsg({ content: '设置失败', type: 'error' });
			return;
		}
	};
	const columns = [
		{
			title: 'deviceId',
			dataIndex: 'deviceId',
			key: 'deviceId',
			hideInTable: true,
		},
		{
			title: '名字',
			dataIndex: 'text_val',
			key: 'text_val',
		},
	];
	const getid = (id: any) => {
		//封装函数，通过id获取该id的html标签对象
		return document.getElementById(id);
	};
	const getRoute = async () => {
		const res = await getByRouteDevice({
			planUUID: planUUID,
			routeUuid: routeUuid,
		});
		if (res.err === 0) {
			console.log('1111111111111111', res);
			setRoutes([...res.data.chooseDevice]);
			setSpotLinePoints([...res.data.routeLine]);
			setPositions(res.data.position);
			route = res.data.chooseDevice;
			if (res.data.imgName !== null && res.data.imgName !== '' && res.data.imgName !== undefined) {
				console.log('po', res.position);
				plane(Api.cdnRootPath + res.data.imgName, true, res.data.position, true, res.data.chooseDevice);
				setImgWidth(res.data.width);
				setImgHight(res.data.height);
				setImgName(res.data.imgName);
			}
			actionRef.current.reload();
			drawSvg(res.data.routeLine, res.data.width, res.data.height, res.data.imgName);
			console.log('spotLinePoints:::::', spotLinePoints);
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
	};
	const plane = (img: any, state: any, position: any, readonlys: any, chooseDevice: any) => {
		//页面一加载调用
		readonly1 = readonlys;
		if (readonlys) {
		}
		state == true ? loadMark(position) : bindEvent();
	};
	const bindEvent = () => {
		console.log(arr);
	};
	//页面一加载的时候调用这个函数
	const loadMark = (position: any) => {
		//const arr = sessionStorage['arr']//获取本地存储里面的值
		arr = JSON.stringify(position);
		if (arr != '' && arr != undefined) {
			console.log('position', arr);
			arr = JSON.parse(arr);
			//arr = JSON.parse(arr);
		} else {
			arr = [];
			console.log('本地存储没有值');
		}
		console.log(arr);
		if (arr) {
			//判断本地存储里面是否有值
			const container = getid('container');
			for (let i = 0; i < arr.length; i++) {
				console.log('21212121', arr);
				addMark(container, arr[i].x, arr[i].y, arr[i].text_val, i, arr[i].deviceId); //在页面上创建和本地存储对应的内容
			}
		}
	};

	const showAllName = (showName: any) => {
		console.log('showName::', showName);
		const container = getid('container');
		const size = positions.length;
		console.log('arr:::', positions);
		for (let i = 0; i < size; i++) {
			if (getid('content_p' + i)) {
				container.removeChild(getid('content_p' + i));
			}
		}

		for (let i = 0; i < positions.length; i++) {
			console.log('21212121', arr);
			addMark1(container, positions[i].x, positions[i].y, positions[i].text_val, i, showName); //在页面上创建和本地存储对应的内容
		}
	};

	const addMark = (p: any, x: any, y: any, text_val: any, index: any, deviceId: any) => {
		//封装创建小红点和输入文字内容的函数
		const div = document.createElement('div'); //创建div元素
		div.id = 'mark' + index; //给div元素添加id
		div.className = 'mark'; //给div元素添加class
		div.style.left = x + 'px'; //div的css属性
		div.style.top = y + 'px';
		div.ondblclick = function () {
			routeSaveDevice1(deviceId, text_val);
		};
		p.appendChild(div); //把这个div元素追加到传过来的元素的下面
		removeitem = true;
		console.log('item', removeitem);
		if (removeitem) {
			$('#mark' + index).hover(
				function () {
					const content_p = document.createElement('p');
					content_p.className = 'content_p';
					console.log('text_val::' + text_val);
					content_p.innerHTML = text_val; //给p标签添加内容
					content_p.id = 'content_p' + index;
					content_p.style.left = x + 'px';
					content_p.style.top = y + 'px';
					p.appendChild(content_p);
				},
				function () {
					p.removeChild(getid('content_p' + index));
				}
			);
		}
	};

	const addMark1 = (p: any, x: any, y: any, text_val: any, index: any, showName: any) => {
		console.log('showName::', showName);
		if (showName) {
			const content_p = document.createElement('p');
			content_p.className = 'content_p';
			console.log('text_val::' + text_val);
			content_p.innerHTML = text_val; //给p标签添加内容
			content_p.id = 'content_p' + index;
			content_p.style.left = x + 'px';
			content_p.style.top = y + 'px';
			p.appendChild(content_p);
		} else {
			$('#mark' + index).hover(
				function () {
					const content_p = document.createElement('p');
					content_p.className = 'content_p';
					console.log('text_val::' + text_val);
					content_p.innerHTML = text_val; //给p标签添加内容
					content_p.id = 'content_p' + index;
					content_p.style.left = x + 'px';
					content_p.style.top = y + 'px';
					p.appendChild(content_p);
				},
				function () {
					p.removeChild(getid('content_p' + index));
				}
			);
		}
	};

	const routeSaveDevice = (deviceId1: any, textVal: any) => {
		setDeviceId(deviceId1);
		const device: any = {};
		device.deviceId = deviceId1;
		device.text_val = textVal;
		let data = [...routes];
		console.log('data1111111', routes);
		data.push(device);
		console.log('data1111111', routes, device, data);
		setRoutes(data);
		actionRef.current.reload();
	};
	const routeSaveDevice1 = (deviceId1: any, textVal: any) => {
		setDeviceId(deviceId1);
	};
	const svg1 = (width: any, Height: any, name: any) => {
		let height = 1000 / (width / Height);
		console.log('height:::', height)
		let svg1 = snapsvg('#svg'); //svg1.clear();
		svg1.attr('preserveAspectRatio', 'xMidYMin meet');
		let svgElement: any = document.getElementById("svg")
		svgElement.style.height = height
		svg1.paper.image(Api.cdnRootPath + name, 0, 0, 1000, height);
	};
	const onDragMoving = (x: any, y: any, data: any) => {
		console.log(':spotLinePoints::', data);
		let updatePoint: any = data[dragIndex];
		updatePoint.x = dragX + x;
		updatePoint.y = dragY + y;
		data[dragIndex] = updatePoint;
		drawSvg(data, imgWidth, imgHight, imgName);
	};

	const drawSvg = (data: any, imgWidth: any, imgHeight: any, imgName: any) => {
		svg1(imgWidth, imgHeight, imgName);
		let svg = snapsvg('#svg');
		//生成点
		if (data !== undefined && data !== null) {
			for (let j = 0; j < data.length; j++) {
				let spot = data[j];
				svg.paper.circle(spot.x, spot.y, 5).drag(
					() => {
						onDragMoving(spot.x, spot.y, data);
					},
					() => {
						onDragStart(j);
					}
				);
			}
			//生成线
			for (let i = 0; i < data.length - 1; i++) {
				let spotLine = data;
				svg.paper.line(spotLine[i].x, spotLine[i].y, spotLine[i + 1].x, spotLine[i + 1].y).attr({
					stroke: '#000',
					strokeWidth: 2,
				});
			}
			console.log('spotLinePoints: ', data);
		}
	};
	const onend1 = (x: any, index: any) => {
		console.log('拖拽后；', index, x);
	};
	const onDragStart = (index: any) => {
		console.log('拖拽前；', index);
		dragFlag = true;
		dragIndex = index;
		dragX = spotLinePoints[index].x;
		dragY = spotLinePoints[index].y;
	};
	const back = () => {
		let data11 = spotLinePoints;
		const data = data11.slice(0, data11.length - 1);
		console.log('data11::', data11);
		setSpotLinePoints([...data]);
		drawSvg([...data], imgWidth, imgHight, imgName);
		const item = data11[data11.length - 1] || {};
		if (item.deviceId !== undefined && item.deviceId !== null && item.deviceId !== '') {
			deleteRoute(item.deviceId);
		}
	};
	const deleteRoute = (deviceId: any) => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].deviceId === deviceId) {
				routes.splice(i, 1);
				break;
			}
		}
		route = [...routes];
		console.log('deleteRoute:::', routes);
		console.log('deleteRoute:::', route);
		setRoutes([...routes]);
		actionRef.current.reload();
	};
	const onSvgClick = (res: any) => {
		console.log('onSvgClick: ', res);
		let offset: any = $(`#svg`).offset();
		let offsetX = offset.left;
		let offsetY = offset.top;
		let scrollTop = window.pageYOffset || document.body.scrollTop;
		let x = res.clientX - offsetX; //用浏览器窗口的可视区域减去getOffset函数返回的x值
		let y = res.clientY - offsetY + scrollTop;
		console.log('x::', x);
		console.log('y::', y);
		console.log('deviceId22', deviceId);
		console.log('deviceId22', spotLinePoints);
		spotLinePoints.push({ x: x, y: y, deviceId: deviceId });
		if (deviceId !== null && deviceId !== undefined && deviceId !== '') {
			const items = positions.filter((item: any) => {
				return item.deviceId === deviceId;
			});
			const item: any = items[0];
			routeSaveDevice(item.deviceId, item.text_val);
		}
		// const data1 = datas.push({ x: x, y: y, deviceId: deviceId });
		// console.log('data', data1);
		let svg = snapsvg('#svg');
		const set = svg.selectAll('circle');
		console.log('set', set);
		set.clear();
		drawSvg(spotLinePoints, imgWidth, imgHight, imgName);
		setSpotLinePoints([...spotLinePoints]);
		setDeviceId(null);
	};
	const subData1 = (callback: any) => {
		console.log('数据：', routes);
		if (callback != undefined) {
			console.log('要提交的数据：', routes);
			if (routes.length == 0) {
				alert('请标记巡更点');
				return;
			}
			callback(routes);
			saveMark();
		}
	};
	//封装本地存储的函数
	const saveMark = () => {
		arr = JSON.stringify(arr);
		console.log('保存至对话存储', arr);
		sessionStorage['arr'] = arr;
	};

	const dragUp = (res: any) => {
		if (dragFlag) {
			let offset: any = $(`#svg`).offset();
			let offsetX = offset.left;
			let offsetY = offset.top;
			let scrollTop = window.pageYOffset || document.body.scrollTop;
			let x = res.clientX - offsetX; //用浏览器窗口的可视区域减去getOffset函数返回的x值
			let y = res.clientY - offsetY + scrollTop;
			console.log('click1111111122222222: ', x, y, dragIndex, spotLinePoints);
			spotLinePoints.splice(dragIndex, 1, { x: x, y: y });
			let svg = snapsvg('#svg');
			const set = svg.selectAll('circle');
			console.log('set', set);
			set.clear();
			drawSvg(spotLinePoints, imgWidth, imgHight, imgName);
		}
		dragFlag = false;
	};
	const getData = () => {
		return {
			data: routes,
		};
	};
	const components = {
		body: {
			row: DragableBodyRow,
		},
	};
	const moveRow = useCallback(
		async (dragIndex, hoverIndex, key) => {
			if (dragIndex === hoverIndex) {
				return;
			}
			console.log('dragIndex', dragIndex);
			console.log('hoverIndex', hoverIndex);
			let route = [...routes];
			const d1 = route[dragIndex];
			const ho = route[hoverIndex];
			console.log('data::', d1, ho);
			let tmp = route[dragIndex]; //临时储存文件
			route.splice(dragIndex, 1); //移除拖拽项
			route.splice(hoverIndex, 0, tmp); //插入放置项
			setRoutes([...route]);
			// findArray(routes, key, dragIndex, hoverIndex);
			actionRef.current.reload();
		},
		[routes]
	);

	const tableSearchBarRender = () => [<div>排序完成后请点击保存按钮进行保存</div>];

	return (
		<>
			<div className="bg">
				<div className={'d-flex justify-content-between'} style={{ padding: '16px 0' }}>
					<Space>
						{routeType === 'xunjian' ? (
							<Link to={'/patrolinspection/inspection-route-list'}>
								<Button type="primary">返回上一页</Button>
							</Link>
						) : (
							<Link to={'/patrolinspection/patrol-route-list'}>
								<Button type="primary">返回上一页</Button>
							</Link>
						)}

						<Button
							type="primary"
							onClick={() => {
								subData1(callback);
							}}
						>
							保存
						</Button>
						<Button type="primary" onClick={back}>
							退回上一步
						</Button>

						<div>
							显示点位名称{' '}
							<Switch
								checked={showName}
								onChange={(value) => {
									showAllName(value);
									setShowName(value);
								}}
							/>
						</div>
					</Space>
					<Space></Space>
				</div>
				<Row gutter={8}>
					<div
						id="container"
						style={{ width: 1000, minHeight: 800 }}
						onDoubleClick={(res) => {
							onSvgClick(res);
						}}
						onMouseUp={dragUp}
					>
						<div id="map" className="">
							<svg id="svg" width={1000} style={{minHeight: 800}} />
						</div>
					</div>
					<Col span={4}>
						<DndProvider backend={HTMLBackend}>
							<GSTable
								rowSelection={false}
								searchBarRender={tableSearchBarRender()}
								style={{ width: 500, marginTop: -43 }}
								options={false}
								actionRef={actionRef}
								components={components}
								onRow={(record: any, index: any) => ({
									index,
									moveRow,
								})}
								columns={columns}
								request={getData}
								pagination={false}
								rowKey={'deviceId'}
							/>
						</DndProvider>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default RouteChooseDevice;
