import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { companyAddEmployee, companyUserList, deleteCompanyEmp } from '../../../services/scheme';
import { Button, Form, Input, message, Modal, Space, Switch, Tag } from 'antd';
import EmployeeDetail from './EmployeeDetail';
import AuthorizedView from '../../../util/AuthorizedView';
import GSTable from '../../../components/GSTable';

interface CompanyEmployeeInfoProps {
	programNum: any;
	companyUuid: string;
}

const CompanyEmployeeInfo: React.FC<CompanyEmployeeInfoProps> = (props: any) => {
	let token: string;
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const [infoVisible, setinfoVisible] = useState(false);
	const [visible, setVisible] = useState(false);
	const [addLoading, setAddLoading] = useState(false);
	const [item, setitem] = useState<any>({});
	const [dateKey, setdateKey] = useState(new Date().getTime().toString());
	const employeeColumns = [
		{
			title: '姓名',
			dataIndex: 'realName',
			key: 'realName',
			render: (text: string, record: any) => (
				<span>
					{
						<a
							onClick={() => {
								console.log('record', record);

								setitem(record);
								setinfoVisible(true);
								setdateKey(new Date().getTime().toString());
							}}
						>
							{record.realName || '未实名'}
						</a>
					}
				</span>
			)
		},
		{
			title: '性别',
			dataIndex: 'sex',
			key: 'sex'
		},
		{
			title: '部门',
			dataIndex: 'department',
			key: 'department'
		},
		{
			title: '职位',
			dataIndex: 'position',
			key: 'position'
		},
		{
			title: '手机号',
			dataIndex: 'phone',
			key: 'phone'
		},
		{
			title: '邮箱',
			dataIndex: 'email',
			key: 'email'
		},
		{
			title: '入职时间',
			dataIndex: 'entryTime',
			key: 'entryTime'
		},
		{
			title: '会员用户',
			dataIndex: 'vip',
			key: 'vip',
			valueEnum: {
				false: <Tag color={"red"}>否</Tag>,
				true: <Tag color={"green"}>是</Tag>
			}
		},
		{
			title: '操作',
			dataIndex: 'active',
			render: (text: string, record: any) => {
				return (
					<Space>
						<a
							onClick={() => {
								Modal.confirm({
									title: '您确定删除该员工?',
									onOk: async () => {
										let data = {
											user_uuid: record.user_uuid,
											company_uuid: record.company_uuid
										};
										let rsp = await deleteCompanyEmp(data);
										if (rsp.err === 0) {
											message.success(rsp.msg);
											if (actionRef.current) {
												actionRef.current.reload();
											}
										} else {
											message.error(rsp.msg);
										}
									}
								});
							}}
						>
							删除
						</a>
					</Space>
				);
			}
		}
	];
	const tableOperationsBarRender = () => [
		<AuthorizedView needAuthority={'admission:employee:add'}>
			<Button
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			>
				新增员工
			</Button>
		</AuthorizedView>
	];
	const addItem = () => {
		form.validateFields().then(async (data1: any) => {
			setAddLoading(true);
			// const data = new FormData();
			const FormData: any = {
				companyUuid: props.companyUuid,
				access_token: token,
				employeeLogin: data1.employeeLogin,
				ePass: data1.ePass,
				programNum: props.programNum,
			};
			console.log('data:::', FormData);
			const res = await companyAddEmployee(FormData);
			if (res.err === 0) {
				message.success('新增成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
				setVisible(false);
			} else {
				message.error(res.msg);
			}
			setAddLoading(false);
		});
	};
	const layout = {
		labelCol: { span: 7 },
		wrapperCol: { span: 13 }
	};
	return (
		<div>
			<GSTable
				columns={employeeColumns}
				actionRef={actionRef}
				operationsBarRender={tableOperationsBarRender()}
				// pagination={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await companyUserList({
						companyUuid: props.companyUuid
					});
					return {
						data: res.data,
						// total: res.count,
						success: true
					};
				}}
			/>
			<Modal
				confirmLoading={addLoading}
				title={'新增员工'}
				width={1200}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={addItem}
			>
				<Form {...layout} form={form}>
					<FormItem label={'登录账号'} name={'employeeLogin'} rules={[{ required: true }]}>
						<Input/>
					</FormItem>
					<FormItem
						label={'通行证显示'}
						valuePropName={'checked'}
						name={'ePass'}
						rules={[{ required: true }]}
						initialValue={false}
					>
						<Switch/>
					</FormItem>
				</Form>
			</Modal>
			<EmployeeDetail
				visible={infoVisible}
				onCancel={() => setinfoVisible(false)}
				userUuid={item.user_uuid}
				key={dateKey}
				companyUuid={item.company_uuid}
			></EmployeeDetail>
		</div>
	);
};
export default CompanyEmployeeInfo;
