import React, { useRef, useState } from "react";
import {
    addHouseholdVerification, findhouseholdVerification,
    getRoomAbnormalList,
    handleRoomAbnormal
} from "../../../services/ZhuhushenheService";
import { message, Space, Select, Input, Button, Table, Modal, Row, Col, Tag,Image } from "antd";
import GSTable from "../../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import * as XLSX from "xlsx";
import moment from "moment";
import appConfig from "../../../appConfig";
import CustomUploadImage from "../../../components/CustomUploadImage";
import { PlusOutlined } from "@ant-design/icons";
import { getHeatlhRecordList } from "../../../services/TemperatureRecordsService";

const VacantRoomList: React.FC = () => {
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState("");
    const [process, setProcess] = useState("false");
    const [addModalvisible, setAddModalvisible] = useState(false);
    const [info, setInfo] = useState([]);
    const [content, setContent] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [uuid, setUuid] = useState("");
    const [recordModalVisible, setRecordModalVisible] = useState(false);
    let [fileList, setFileList] = useState<any[]>([]);
    const [img, setImg] = useState('');
    const [quanXuan, setQuanXuan] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);

    const processedEnum = {
        true: <Tag color={"green"}>已处理</Tag>,
        false: <Tag color={"red"}>未处理</Tag>
    };
    const columns = [
        {
            title: "房间号",
            dataIndex: "roomNumber",
            key: "roomNumber"
        },
        {
            title: "承租人姓名",
            dataIndex: "householderName",
            key: "householderName"
        },
        {
            title: "承租人手机号",
            dataIndex: "householderLoginId",
            key: "householderLoginId"
        },
        {
            title: "承租人身份证号",
            dataIndex: "householderCardIdNum",
            key: "householderCardIdNum"
        },
        {
            title: "承租人开门次数",
            key: "householderUnlockNum",
            dataIndex: "householderUnlockNum"
        }, {
            title: "状态",
            key: "processed",
            valueEnum: processedEnum,
            dataIndex: "processed"
        },
        {
            title: "操作",
            key: "action",
            dataIndex: "action",
            render: (text: string, record: any) => (
                <Space>
                    {
                        record.processed ? <Button type={"link"} onClick={() => {
                            setRecordModalVisible(true);
                            setUuid(record.id);
                            setInfo([]);
                            setTableLoading(true);
                            getVerificationList(record.id).then();
                        }}>处理列表</Button> : <Button type={"link"} onClick={() => {
                            setAddModalvisible(true);
                            setUuid(record.id);

                            getVerificationList(record.id).then();
                        }}>处理</Button>
                    }
                </Space>
            )
        }
    ];

    const sheheColumns = [
        {
            title: "日期",
            key: "checkDate",
            dataIndex: "checkDate"
        },
        {
            title: "记录",
            key: "content",
            dataIndex: "content"
        },
        {
            title: "图片",
            key: "img",
            dataIndex: "img",
            render: (text: string, record: any) => {
                if (text) {
                    return  <Image.PreviewGroup>
                        {
                            text.split(',').map((item: any) => {
                                return <Image style={{width: 100, height: 100}} src={appConfig.cdnRootPath + item} />
                            })
                        }
                    </Image.PreviewGroup>
                } else {
                    return <div>无</div>
                }
            }
        }
    ];

    const loadList = async (params: any) => {
        let rsp = await getRoomAbnormalList(params);
        if (rsp.err !== 0) {
            message.error(rsp.msg);
            return;
        }
        if (quanXuan) {
            let uuids: any = [];
            rsp.data.data.map((item: any) => {
                uuids.push(item.roomNumber);
            });
            setSelectedRowKeys(uuids);
        }
        setTotal(rsp.data.total);
        return {
            data: rsp.data.data,
            total: rsp.data.total
        };
    };
    const tableSearchBarRender = () => [
        <Select
            showSearch={true}
            style={{ width: 200 }}
            placeholder="请选择"
            onChange={(e: any) => {
                setProcess(e);
            }}
            defaultValue={process}
        >
            <Select.Option value={""} key={""}>
                全部
            </Select.Option>
            <Select.Option value={"false"} key={"false"}>
                未处理
            </Select.Option>
            <Select.Option value={"true"} key={"true"}>
                已处理
            </Select.Option>
        </Select>,
        <Input
            placeholder="请输入"
            onChange={(e: any) => setSearch(e.target.value)}
        />,
        <Button type={"primary"} onClick={() => {
            if (actionRef.current) {
                actionRef.current.reloadAndRest();
            }
        }}>查询</Button>
    ];
    const rowSelection = {
        fixed: true,
        columnWidth: 100,
        selectedRowKeys: selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
            setQuanXuan(false);
        },
        selections: [
            {
                key: 'SELECT_CURRENT_ALL',
                text: '全选当前页面',
                onSelect: (changableRowKeys: any) => {
                    setQuanXuan(false);
                    setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
                },
            },
            {
                key: 'SELECT_ALL',
                text: '全选全部页面',
                onSelect: (changableRowKeys: any) => {
                    setQuanXuan(true);
                    setSelectedRowKeys(changableRowKeys);
                },
            },
        ],
    };
    const exprotData = async (selectedRows: [any?]) => {
        console.log('selectedRows:::::', selectedRows);
        if (quanXuan) {
            let data: any = {
                search: search,
                type: "空置",
                process: process
            };
            let rsp = await getRoomAbnormalList(data);
            if (rsp.err === 0) {
                setExprotData(rsp.data.data);
            } else {
                message.error(rsp.msg);
            }
        } else {
            setExprotData(selectedRows);
        }
    };

    const setExprotData = (selectedRows: any[]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        columns.map((item: any) => {
            if (item.title !== "操作") {
                titles.push(item.title);
            }
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data: [string?] = [];
            columns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
                    } else {
                        data.push("");
                    }
                } else if (item.valueType === "dateTime") {
                    data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "空置房间");

        /* save to file */
        XLSX.writeFile(wb, "空置房间.xlsx");
    };
    const verificationClick = async () => {
        if (!content) {
            message.warn("请输入内容")
            return
        }
        const res = await handleRoomAbnormal({
            content: content,
            id: uuid,
            imgUrl: img
        });
        console.log("", res);
        if (res.err === 0) {
            message.success("成功");
            if (actionRef.current) {
                actionRef.current.reloadAndRest();
            }
            return getVerificationList(uuid);
        } else {
            return;
        }
    };
    const getVerificationList = async (uuid: string) => {
        setTableLoading(true);
        setInfo([])
        const res = await findhouseholdVerification({
            uuid: uuid
        });
        console.log("", res);
        setTableLoading(false);
        if (res.err === 0) {
            setInfo(res.data);
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );

    const render = () => (fileList.length >= 9 ? null : uploadButton);

    const getFileName = (fileList: any) => {
        console.log("fileList", fileList);
        if (fileList.file.status === "done") {
            setFileList(fileList.fileList);
            let tempImg: any = [];
            fileList.fileList.map((item: any) => {
                tempImg.push(item.response.url)
            });
            setImg(tempImg.toString());
        } else if (fileList.file.status === "error") {
            message.error(`${fileList.file.name} 上传文件失败`);
        } else if (fileList.file.status === "removed") {
            // fileList = fileList.filter((item: any) => item.status !== "removed");
            setFileList(fileList.fileList);
            let tempImg: any = [];
            fileList.fileList.map((item: any) => {
                tempImg.push(item.response.url)
            });
            setImg(tempImg.toString());
        }
    };

    return (
        <>
            <GSTable
                columns={columns}
                actionRef={actionRef}
                rowSelection={rowSelection}
                rowKey={"roomNumber"}
                searchBarRender={tableSearchBarRender()}
                tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                    return (
                        <Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
                                        style={{ marginLeft: 8 }}
                                        onClick={() => {
                                            onCleanSelected();
                                            setQuanXuan(false);
                                        }}
                                    >
										取消选择
									</a>
								</span>
                        </Space>
                    );
                }}
                tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                    return (
                        <Space size={16}>
                            <Button type={"link"} onClick={() => {
                                exprotData(selectedRows);
                            }}>导出</Button>
                        </Space>
                    );
                }}
                request={async (params: any = {}) => {
                    let data: any = {
                        page: params.current,
                        size: params.pageSize,
                        search: search,
                        type: "空置",
                        process: process
                    };

                    return loadList(data);
                }}
            />
            <Modal
                title="核查"
                visible={addModalvisible}
                onCancel={() => {
                    setAddModalvisible(false);
                }}
                onOk={verificationClick}
                okText={"添加"}
            >
                <Row gutter={8} justify="center" align="middle">
                    <Col span={4}>核查内容：</Col>
                    <Col span={20}>
                        <Input
                            onInput={(ev: any) => {
                                setContent(ev.target.value);
                            }}
                            placeholder="请输入核查内容"
                        />
                    </Col>
                </Row>
                <br/>
                <Row gutter={8} justify="center" align="middle">
                    <Col span={4}>上传照片：</Col>
                    <Col span={20}>
                        <CustomUploadImage
                            maxCount={9}
                            key={new Date().getTime()}
                            cdn={"public"}
                            multiple={false}
                            listType="picture-card"
                            defaultFileList={fileList.length > 0 ? fileList : null}
                            onChange={getFileName}
                            render={render()}
                        />
                    </Col>
                </Row>
                <br/>
                <Row gutter={8} justify="center">
                    <Col span={4}>历史记录：</Col>
                    <Col span={20}>
                        <Table size={"small"} loading={tableLoading} dataSource={info} columns={sheheColumns}/>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="核查记录"
                visible={recordModalVisible}
                onCancel={() => {
                    setRecordModalVisible(false);
                }}
                onOk={() => {setRecordModalVisible(false)}}
                footer={null}
            >
                <Table size={"small"} loading={tableLoading} dataSource={info} columns={sheheColumns}/>
            </Modal>
        </>
    );
};
export default VacantRoomList;
