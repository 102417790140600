import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import LouDong from '../assets/pages/LouDong';
import RoomList from '../assets/pages/RoomList';
import ControlPlatform from './pages/ControlPlatform';
import HouseholdList from './pages/HouseholdList';
import ApplyCommunity from './pages/ApplyCommunity';
import BatchList from './pages/BatchList';
import ReportForms from './pages/ReportForms';
import ShenheList from './components/ShenheList';
import EntranceGuardReport from './components/EntranceGuardReport';
import HouseholdCheckOut from './components/HouseholdCheckOut';
import EntranceGuardReportInfo from './components/EntranceGuardReportInfo';
import KaimenData from '../assets/components/KaimenData';
import BatchHousehold from './pages/BatchHousehold';
import CredirsRecordList from './pages/CreditsRecordList';

export default function ShenheMiniApp() {
	const rootUrl = '/zhuhu';
	const menus: MiniAppMenu[] = [
		{
			authority: 'household:household_list',
			name: '住户管理',
			path: 'household_list',
			component: HouseholdList,
		},
		{
			name: '业主认证审核',
			path: 'apply-community',
			authority: 'household:applyCommunity:select',
			component: ApplyCommunity,
		},
		{
			name: '批量导入',
			path: 'batch-household',
			authority: 'household:household_list',
			component: BatchHousehold,
		},
		{
			name: '住户积分',
			path: 'credits_record_list',
			authority: 'credits_record',
			component: CredirsRecordList,
		},
	];

	const routes: MiniAppRoute[] = [
		{
			path: 'entrance_guard_report',
			component: EntranceGuardReport,
		},
		{
			path: 'household-check-out',
			component: HouseholdCheckOut,
		},
		{
			path: 'statistics',
			component: KaimenData,
		},
	];

	return <MiniApp key={window.location.href} miniAppName={'住户'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
