import { Tabs } from 'antd';
import React, { useState } from 'react';
import HouseStyleList from '../../assets/components/HouseStyleList';
import RoomItemBatch from '../../assets/components/RoomItemBatch';
const ItemSetting: React.FC = () => {
	const [dataKey, setdataKey] = useState(new Date().getTime().toString())
	return (
		<>
			<Tabs defaultActiveKey="1" onChange={() => { setdataKey(new Date().getTime().toString())}}>
				<Tabs.TabPane tab="按风格批量设置" key="1">
					<HouseStyleList key={dataKey} type={'ItemSetting'} />
				</Tabs.TabPane>
				<Tabs.TabPane tab="按房间批量设置" key="2">
					<RoomItemBatch key={dataKey} ></RoomItemBatch>
				</Tabs.TabPane>
			</Tabs>
		</>
	);
};
export default ItemSetting;
