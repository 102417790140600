import * as React from 'react';

import DAL from '../../../data/DAL';
import {
	Button,
	Pagination,
	Divider,
	DatePicker,
	Modal,
	Table,
	Input,
	Row,
	Col,
	Select,
	Radio,
	Switch,
	InputNumber,
} from 'antd';
import LockUser from '../../../types/LockUser';
import { NavLink } from 'react-router-dom';
import SendKeyModal from './SendKeyModal';
import {
	unfreezeMapping,
	freezeMapping,
	updateMapping,
	keyDelay,
	deleteAllKey,
	deletKey,
	getWuyeDeviceRole,
} from '../../../services/ZhiNengMenJinService';
import GSTable from '../../../components/GSTable';

var deleteUser: string;
let moment = require('moment');
let msg = require('../../../util/SuspensionMsg');
const Search = Input.Search;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const confirm = Modal.confirm;
class YaoshiLiebiao extends React.Component {
	deviceId: string = '';
	deviceName: string = '';
	dal = DAL.dal;
	mappingType: string = '';
	userEmail: string = '';
	search: string = '';
	actionRef: any;
	columns = [
		{
			title: '账号',
			dataIndex: 'loginId',
		},
		{
			title: '账号昵称',
			dataIndex: 'nick_name',
		},
		{
			title: '发送者',
			dataIndex: 'sendName',
		},
		{
			title: '发送者账号',
			dataIndex: 'sent_by',
		},
		{
			title: '发送时间',
			dataIndex: 'createTime',
		},
		{
			title: '用户权限',
			dataIndex: 'type',
		},
		{
			title: '绑定次数',
			dataIndex: 'bindNum',
		},
		{
			title: '临时钥匙次数',
			dataIndex: 'faSongNum',
			render: (text: any, item: any) => {
				return 5 - item.faSongNum < 0 ? 0 : 5 - item.faSongNum;
			},
		},
		{
			title: '有效期',
			dataIndex: 'expire_time',
			render: (text: any, item: any) => {
				return (
					item.start_time +
					(item.start_time === null || item.start_time === '' ? '' : ' 至 ') +
					item.expire_time
				);
			},
		},
		{
			title: '操作',
			render: (text: any, item: any) => (
				<span>
					{item.expire_time === '永久' ? (
						<a className={'disableda'}>延期</a>
					) : (
						<a onClick={this.getUser.bind(this, item.loginId, 'deviceDelay')}>延期</a>
					)}
					<Divider type="vertical" />
					<a onClick={this.getUser.bind(this, item.loginId, 'delete')}>删除</a>
					<Divider type="vertical" />
					<a onClick={this.getRecord.bind(this, item)}>操作</a>
				</span>
			),
		},
	];
	state: any;
	constructor(props: any) {
		super(props);
		this.state = {
			ds: [],
			isShow: true,
			Jurlsdiction: '普通用户',
			serch: props.location.search,
			total: 0,
			pageSize: 30,
			pageNumber: 1,
			sendDeviceVisible: false,
			deviceDelayVisible: false,
			deviceOnekeyVisible: false,
			delayDate: null,
			deleteDeviceVisible: false,
			deleteMyDeviceVisible: false,
			myDeviceDelayVisible: false,
			showRow: false,
			starttime: moment().format('YYYY-MM-DD HH:mm:ss'),
			expiretime: moment().add(1, 'y').format('YYYY-MM-DD HH:mm:ss'),
			needBindImsi: false,
			needBindPhone: false,
			needUserCertified: false,
			bindNum: 5,
			deviceId: '',
			updateVisible: false,
			isAvailable: false,
			userId: '',
			nickName: '',
			confirmLoading: false,
			sendNum: 5,
			isAutoFill: false,
			deviceName: '',
		};
		// this.callbackFenye = this.callbackFenye.bind(this);
	}

	componentDidMount() {
		if ((this.state as any).serch !== null && (this.state as any).serch !== '') {
			const splits = (this.state as any).serch.split('=');
			let sp = splits[1].split(',');
			this.deviceId = sp[0];
			this.deviceName = sp[1];
			this.setState({
				deviceName: decodeURI(this.deviceName),
			});
		}
		// this.callbackFenye((this.state as any).pageNumber);
	}

	/** 页数点击 */
	onPageChange = (page: number, pageSize: number) => {
		this.setState({
			pageNumber: page,
		});
		window.location.hash = `#${page}`;
		// this.callbackFenye(page);
	};

	/** 查询全部数据 (模糊搜索/分页) */
	callbackFenye = async (e: any) => {
		this.setState({
			isShow: true,
		});
		const res = await getWuyeDeviceRole({
			device_id: this.deviceId,
			page: e,
			phone: this.search,
			mappingType: this.mappingType,
		});
		// let uri = '/api/wuye/get_wuye_device_role';
		// API.postWithAuth(uri, {
		//     device_id: this.deviceId,
		//     page: e,
		//     phone: this.search,
		//     mappingType: this.mappingType
		// }).then(res => {
		if (res.err === 0) {
			if (e === 1) {
				this.setState({
					pageNumber: 1,
				});
			}
			console.log('res.mappings', res.mappings);
			let ddd = res.mappings.map((d: any) => {
				let expire = Date.parse(d.expire_time);
				let newDate = new Date(expire);

				let start = Date.parse(d.start_time);
				let startDate = new Date(start);

				let dev: LockUser = {
					nick_name: d.nickName,
					device_name: d.device_name,
					device_id: d.device_id,
					loginId: d.loginId,
					isAvailable: d.available,
					createTime: d.createTime,
					sendName: d.sendName,
					email: d.email,
					expire_time:
						d.expire_time === '9999-01-01 00:00:00'
							? '永久'
							: d.expire_time === null
							? ''
							: moment(d.expire_time).format('YYYY-MM-DD HH:mm'),
					id: d.id,
					on_off_rssi_limit: d.on_off_rssi_limit,
					sent_by: d.sent_by,
					sequence: d.sequence,
					start_time:
						d.start_time === '1111-01-01 00:00:00'
							? ''
							: d.start_time === null
							? ''
							: moment(d.start_time).format('YYYY-MM-DD HH:mm'),
					status: d.status,
					type: this.getType(d.type),
					wechat_bind: d.wechat_bind,
					needBindImsi: d.needBindImsi,
					needBindPhone: d.needBindPhone,
					bindNum: d.bindNum,
					sendNum: d.sendNum,
					isAutoFill: d.autoFill,
					faSongNum: d.faSongNum,
					needUserCertified: d.needUserCertified,
				};
				return dev;
			});
			console.log('获得的钥匙', res);
			this.setState({
				ds: ddd,
				isShow: false,
				total: res.count,
			});
		} else {
			this.setState({
				isShow: false,
			});
			msg.suspensionMsg('没有权限管理该设备');
		}
		// });
	};

	getType = (value: any) => {
		console.log('value', value);
		let type = '';
		switch (value) {
			case 'admin':
				type = '超级管理员';
				break;
			case 'anytime':
				type = '普通用户';
				break;
			case 'manage':
				type = '管理';
				break;
			case 'look':
				type = '查看';
				break;
			case 'use_look':
				type = '使用和查看';
				break;
			case 'manage_look':
				type = '管理和查看';
				break;
			case 'use_manage':
				type = '使用和管理';
				break;
			default:
				break;
		}
		return type;
	};

	handleSubmit = (event: { preventDefault: () => void }) => {
		event.preventDefault();
		return false;
	};

	//删除钥匙
	deleteLock = async (e: { preventDefault: () => void }) => {
		if (!deleteUser) {
			msg.suspensionMsg('删除异常');
			return;
		}
		this.setState({
			confirmLoading: true,
		});
		const res = await deletKey({
			device_id: this.deviceId,
			contact: deleteUser + '',
		});
		// API.postWithAuth('/delete_key', {
		//     device_id: this.deviceId,
		//     contact: deleteUser + ''
		// }).then(res => {
		this.setState({
			confirmLoading: false,
		});
		if (res.status === 1) {
			msg.suspensionMsg('删除成功', 'success');
			this.actionRef.reload();
			// this.callbackFenye(1);
			this.handleCancel();
		} else {
			msg.suspensionMsg('删除失败');
		}
		// });
	};

	//删除钥匙
	getUser = (e: any, status: any) => {
		console.log('点击删除', e);
		if (status === 'deviceDelay') {
			this.userEmail = e;
			this.setState({
				deviceOnekeyVisible: true,
			});
		} else if (status === 'delete') {
			this.setState({
				deleteMyDeviceVisible: true,
			});
		}
		deleteUser = e + '';
	};

	//删除全部非管理员的钥匙
	deleteDevice = () => {
		console.log('全部删除');
		this.getData();
	};
	getData = async () => {
		this.setState({
			confirmLoading: true,
		});
		const res = await deleteAllKey({
			device_id: this.deviceId,
			mappingType: this.mappingType,
		});
		//
		// let url = '/delete_all_key';
		// API.postWithAuth(url, {
		//     device_id: this.deviceId,
		//     mappingType: this.mappingType
		// }).then(res => {
		console.log('获得的用户', res);
		this.setState({
			confirmLoading: false,
		});
		if (res.err === 0) {
			msg.suspensionMsg(res.msg, 'success');
			// this.callbackFenye(1);
			this.actionRef.reload();
			this.handleCancel();
		} else {
			msg.suspensionMsg(res.msg);
		}
		// });
	};

	//一键延期
	keyDelay = async () => {
		const res = await keyDelay({
			deviceId: this.deviceId,
			search: this.search,
			mappingType: this.mappingType,
			expireTime: (this.state as any).delayDate,
			loginId: this.userEmail,
		});

		// let url = '/api/wuye/key_delay';
		this.setState({
			confirmLoading: true,
		});
		// API.postWithAuth(url, {
		//     deviceId: this.deviceId,
		//     search: this.search,
		//     mappingType: this.mappingType,
		//     expireTime: (this.state as any).delayDate,
		//     loginId: this.userEmail
		// }).then(res => {
		console.log('', res);
		this.setState({
			confirmLoading: false,
		});
		if (res.err === 0) {
			msg.suspensionMsg('钥匙延期成功', 'success');
			// this.callbackFenye(1);
			this.actionRef.reload();
			this.handleCancel();
		} else {
			msg.suspensionMsg('钥匙延期失败');
		}
		// });
	};

	//延期时间
	delayDate = (date: any, dataToString: any) => {
		this.setState({
			delayDate: dataToString,
		});
	};

	/** 隐藏Modal框 */
	handleCancel = () => {
		this.setState({
			sendDeviceVisible: false,
			deviceDelayVisible: false,
			deviceOnekeyVisible: false,
			deleteDeviceVisible: false,
			myDeviceDelayVisible: false,
			deleteMyDeviceVisible: false,
		});
	};

	/** 显示Modal框 */
	showModal = (status: any) => {
		if (status === 'send') {
			this.setState({
				sendDeviceVisible: true,
			});
		} else if (status === 'delete') {
			this.setState({
				deleteDeviceVisible: true,
			});
		} else if (status === 'deviceDelay') {
			this.userEmail = '';
			this.setState({
				deviceDelayVisible: true,
			});
		} else if (status === 'deviceOnekey') {
			this.setState({
				deviceOnekeyVisible: true,
			});
		}
	};

	getRecord = (record: LockUser) => {
		console.log('item:', record);
		this.setState({
			needBindImsi: record.needBindImsi,
			needBindPhone: record.needBindPhone,
			bindNum: record.bindNum,
			deviceId: record.device_id,
			updateVisible: true,
			isAvailable: record.isAvailable,
			userId: record.email,
			nickName: record.nick_name,
			sendNum: record.sendNum,
			isAutoFill: record.isAutoFill,
			needUserCertified: record.needUserCertified,
		});
	};
	updataMapping = async () => {
		this.setState({
			updateVisible: false,
		});
		let data = {
			needBindImsi: this.state.needBindImsi,
			needBindPhone: this.state.needBindPhone,
			bindNum: this.state.bindNum,
			device_id: this.state.deviceId,
			isAvailable: this.state.isAvailable,
			isAutoFill: this.state.isAutoFill,
			userId: this.state.userId,
			sendNum: this.state.sendNum,
			needUserCertified: this.state.needUserCertified,
		};
		let that = this;
		const res = await updateMapping(data);
		// API.postWithAuth('/api/device/update_mapping', data).then(res => {
		if (res.err === 0) {
			that.callbackFenye(1);
			msg.suspensionMsg('操作成功', 'success');
		} else {
			msg.suspensionMsg('操作失败:' + res.msg);
		}
		// });
	};
	//冻结钥匙
	freezeMapping = (email: string) => {
		let that = this;
		confirm({
			title: '您确定要冻结该钥匙吗？',
			onOk: async () => {
				const res = await freezeMapping({
					deviceId: that.deviceId,
					userId: email,
				});
				// API.postWithAuth('/api/device/freeze_mapping', {
				//     deviceId: that.deviceId,
				//     userId: email
				// }).then(res => {
				if (res.err === 0) {
					that.callbackFenye(1);
					msg.suspensionMsg('钥匙冻结成功', 'success');
				} else {
					msg.suspensionMsg('钥匙冻结失败');
				}
				// });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	unFreezeMapping = (loginId: string) => {
		let that = this;
		confirm({
			title: '您确定要解除冻结该钥匙吗？',
			onOk: async () => {
				const res = await unfreezeMapping({
					deviceId: that.deviceId,
					userId: loginId,
				});
				// API.postWithAuth('/api/device/unfreeze_mapping', {
				//     deviceId: that.deviceId,
				//     userId: loginId
				// }).then(res => {
				if (res.err === 0) {
					that.callbackFenye(1);
					msg.suspensionMsg('钥匙解除冻结成功', 'success');
				} else {
					msg.suspensionMsg('钥匙解除冻结失败');
				}
				// });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	tableSearchBarRender = () => [
		<Select
			style={{ width: 100 }}
			onChange={(value: any) => {
				this.mappingType = value;
				this.actionRef.reload();
			}}
			defaultValue={''}
		>
			<Option value={''}>全部钥匙</Option>
			<Option value="anytime">普通用户</Option>
			<Option value="manage">管理</Option>
			<Option value="look">查看</Option>
			<Option value="use_look">使用和查看</Option>
			<Option value="manage_look">管理和查看</Option>
			<Option value="use_manage">使用和管理</Option>
			<Option value="admin">超级管理员</Option>
		</Select>,
		<Search
			placeholder="账号搜索"
			onSearch={(value: any) => {
				this.search = value;
				this.actionRef.reload();
			}}
			enterButton={true}
		/>,
	];
	tableOperationsBarRender = () => [
		<Button
			style={{ marginRight: 10 }}
			className="addEmpRight"
			type={'primary'}
			onClick={this.showModal.bind(this, 'delete')}
		>
			删除所有钥匙
		</Button>,
		<Button
			style={{ marginRight: 10 }}
			className=" addEmpRight"
			type={'primary'}
			onClick={this.showModal.bind(this, 'deviceOnekey')}
		>
			钥匙一键延期
		</Button>,
		<Button className="addEmpRight" type={'primary'} onClick={this.showModal.bind(this, 'send')}>
			发送钥匙
		</Button>,
	];

	render() {
		return (
			<div>
				<div>
					{/*<nav className="filter-menu-bar navbar navbar-default" role="navigation">*/}
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<NavLink to={'/zhineng-menjin/zhineng-menjin'}>
							<i className="icon icon-home">设备管理</i>
						</NavLink>
						<div className="active">
							&nbsp;&nbsp;&nbsp; 当前设备： {this.state.deviceName + '（' + this.deviceId + '）'}
						</div>
					</div>

					{/*<div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:10,marginBottom:10}}>*/}
					{/*    <div style={{display:"flex"}}>*/}
					{/*       <div>*/}

					{/*       </div>*/}
					{/*        <div style={{marginLeft:10}}>*/}
					{/*            <Search*/}
					{/*                placeholder="账号搜索"*/}
					{/*                onSearch={(value: any) => { this.search = value;  this.callbackFenye(1); }}*/}
					{/*                enterButton={true}*/}
					{/*            />*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/*    <div style={{display:"flex"}}>*/}
					{/*        <Button style={{marginRight:10}} className="addEmpRight" type={'primary'} onClick={this.showModal.bind(this, 'delete')}>删除所有钥匙*/}
					{/*        </Button>*/}
					{/*        <Button style={{marginRight:10}} className=" addEmpRight" type={'primary'} onClick={this.showModal.bind(this, 'deviceOnekey')}>钥匙一键延期*/}
					{/*        </Button>*/}
					{/*        <Button className="addEmpRight" type={'primary'} onClick={this.showModal.bind(this, 'send')}>发送钥匙*/}
					{/*        </Button>*/}
					{/*    </div>*/}
					{/*</div>*/}

					{/*</nav>*/}
					{/*<div className="content">*/}
					<GSTable
						columns={this.columns}
						actionRef={(ref: any) => (this.actionRef = ref)}
						searchBarRender={this.tableSearchBarRender()}
						// options={false}
						operationsBarRender={this.tableOperationsBarRender()}
						request={async (params: any = {}, sort: any, filter: any) => {
							const res = await getWuyeDeviceRole({
								page: params.current,
								size: params.pageSize,
								phone: this.search,
								device_id: this.deviceId,
								mappingType: this.mappingType,
							});
							let ddd = res.mappings.map((d: any) => {
								let expire = Date.parse(d.expire_time);
								let newDate = new Date(expire);

								let start = Date.parse(d.start_time);
								let startDate = new Date(start);

								let dev: LockUser = {
									nick_name: d.nickName,
									device_name: d.device_name,
									device_id: d.device_id,
									loginId: d.loginId,
									isAvailable: d.available,
									createTime: d.createTime,
									sendName: d.sendName,
									email: d.email,
									expire_time:
										d.expire_time === '9999-01-01 00:00:00'
											? '永久'
											: d.expire_time === null
											? ''
											: moment(d.expire_time).format('YYYY-MM-DD HH:mm'),
									id: d.id,
									on_off_rssi_limit: d.on_off_rssi_limit,
									sent_by: d.sent_by,
									sequence: d.sequence,
									start_time:
										d.start_time === '1111-01-01 00:00:00'
											? ''
											: d.start_time === null
											? ''
											: moment(d.start_time).format('YYYY-MM-DD HH:mm'),
									status: d.status,
									type: this.getType(d.type),
									wechat_bind: d.wechat_bind,
									needBindImsi: d.needBindImsi,
									needBindPhone: d.needBindPhone,
									bindNum: d.bindNum,
									sendNum: d.sendNum,
									isAutoFill: d.autoFill,
									faSongNum: d.faSongNum,
									needUserCertified: d.needUserCertified,
								};
								return dev;
							});
							return {
								data: ddd,
								total: res.count,
								success: true,
							};
						}}
					/>
					{/*<Table*/}
					{/*    columns={this.columns}*/}
					{/*    dataSource={(this.state as any).ds}*/}
					{/*    loading={(this.state as any).isShow}*/}
					{/*    size="middle"*/}
					{/*    pagination={false}*/}
					{/*/>*/}
					{/*<br/>*/}
					{/*<Pagination*/}
					{/*    className={'pagination-com'}*/}
					{/*    showQuickJumper={true}*/}
					{/*    defaultCurrent={(this.state as any).pageNumber}*/}
					{/*    current={(this.state as any).pageNumber}*/}
					{/*    total={(this.state as any).total}*/}
					{/*    pageSize={(this.state as any).pageSize}*/}
					{/*    onChange={this.onPageChange}*/}
					{/*    showTotal={(e) => { return '共' + e + '项'; }}*/}
					{/*    showSizeChanger={true}*/}
					{/*    pageSizeOptions={DAL.pageSize}*/}
					{/*    onShowSizeChange={this.onPageChange}*/}
					{/*/>*/}

					{/*钥匙一键延期*/}
					<Modal
						visible={(this.state as any).deviceOnekeyVisible}
						title="钥匙延期"
						confirmLoading={this.state.confirmLoading}
						onOk={this.keyDelay}
						onCancel={this.handleCancel}
					>
						<Row gutter={8}>
							<Col span={4} offset={4}>
								延期：
							</Col>
							<Col span={12}>
								<RadioGroup
									onChange={(value: any) => {
										console.log('value', value);
										this.setState({ showRow: value.target.value, delayDate: '' });
									}}
									defaultValue={false}
								>
									<Radio value={false}>一年</Radio>
									<Radio value={true}>自定义</Radio>
								</RadioGroup>
							</Col>
						</Row>
						<br />
						{(this.state as any).showRow ? (
							<Row gutter={8} type="flex" justify="center" align={'middle'}>
								<Col span={10} offset={2}>
									选择延期当前设备有效期至：
								</Col>
								<Col span={12}>
									<DatePicker
										showTime={true}
										format="YYYY-MM-DD HH:mm:ss"
										placeholder="选择或者输入日期+时间"
										onChange={this.delayDate}
										getCalendarContainer={(value: any) => {
											return value;
										}}
									/>
								</Col>
							</Row>
						) : null}
					</Modal>

					{/*发送钥匙*/}
					<SendKeyModal
						visible={(this.state as any).sendDeviceVisible}
						onCancel={this.handleCancel}
						deviceId={this.deviceId}
						callback={this.callbackFenye.bind(this, 1)}
					/>
					{/*删除个人钥匙*/}
					<Modal
						visible={(this.state as any).deleteMyDeviceVisible}
						title="提示"
						confirmLoading={this.state.confirmLoading}
						onOk={this.deleteLock.bind(this, '')}
						onCancel={this.handleCancel}
					>
						<form id="formData" onSubmit={this.handleSubmit}>
							<div className="modal-body">
								<p>您确认要删除该用户吗？</p>
							</div>
						</form>
					</Modal>

					{/*删除所有钥匙*/}
					<Modal
						visible={(this.state as any).deleteDeviceVisible}
						title="提示"
						onOk={this.deleteDevice}
						confirmLoading={this.state.confirmLoading}
						onCancel={this.handleCancel}
					>
						<form id="formData" onSubmit={this.handleSubmit}>
							<div className="modal-body">
								<p>您确认要删除所有非管理员的钥匙？</p>
							</div>
						</form>
					</Modal>

					{/*更多设置*/}
					<Modal
						visible={(this.state as any).updateVisible}
						title="操作"
						onOk={this.updataMapping}
						onCancel={() => {
							this.setState({ updateVisible: false });
						}}
					>
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								账号昵称:
							</Col>
							<Col span={6}>{this.state.nickName}</Col>
						</Row>
						<br />
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								是否冻结:
							</Col>
							<Col span={6}>
								<Switch
									key={this.state.deviceId}
									checked={!this.state.isAvailable}
									onChange={(checked) => {
										this.setState({ isAvailable: !checked });
									}}
								/>
							</Col>
						</Row>
						<br />
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								是否需要绑定IMSI卡:
							</Col>
							<Col span={4}>
								<Switch
									key={this.state.deviceId}
									checked={this.state.needBindImsi}
									onChange={(checked) => {
										this.setState({ needBindImsi: checked });
									}}
								/>
							</Col>
						</Row>
						<br />
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								绑定时是否自动填写验证码:
							</Col>
							<Col span={4}>
								<Switch
									key={this.state.deviceId}
									checked={this.state.isAutoFill}
									onChange={(checked) => {
										this.setState({ isAutoFill: checked });
									}}
								/>
							</Col>
						</Row>
						<br />
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								是否需要绑定手机:
							</Col>
							<Col span={4}>
								<Switch
									key={this.state.deviceId}
									checked={this.state.needBindPhone}
									onChange={(checked) => {
										this.setState({ needBindPhone: checked });
									}}
								/>
							</Col>
						</Row>
						<br />
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								是否需要实名认证:
							</Col>
							<Col span={4}>
								<Switch
									key={this.state.deviceId}
									checked={this.state.needUserCertified}
									onChange={(checked) => {
										this.setState({ needUserCertified: checked });
									}}
								/>
							</Col>
						</Row>
						<br />
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								绑定次数:
							</Col>
							<Col span={6}>
								<InputNumber
									key={this.state.deviceId}
									min={1}
									max={10}
									defaultValue={this.state.bindNum}
									onChange={(value) => {
										this.setState({ bindNum: value });
									}}
								/>
							</Col>
						</Row>
						<br />
						<Row gutter={8} type={'flex'} justify={'start'} align={'middle'}>
							<Col offset={2} span={8}>
								临时钥匙次数:
							</Col>
							<Col span={6}>
								<InputNumber
									key={this.state.deviceId}
									min={1}
									max={10}
									defaultValue={this.state.sendNum}
									onChange={(value) => {
										this.setState({ sendNum: value });
									}}
								/>
							</Col>
						</Row>
					</Modal>

					{/*</div>*/}
				</div>
			</div>
		);
	}
}

export default YaoshiLiebiao;

// helpers
