import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic, Select, message, List } from 'antd';
import moment from 'moment';
import LineChart from '../../../components/LineChart';
import { DataContext } from '../../controlPlatform/CurrentPlatform';
import { getHuoDongReport } from '../../../services/controlPlatformService';
import CustomRangePicker from '../../../components/CustomRangePicker';

const LineHuodongReport: React.FC = () => {
	let startTime = moment().subtract(6, 'd');
	let endTime = moment();
	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		getData();
	}, []);
	const getData = async () => {
		setLoading(true);
		let params = {
			startDay: startTime.format('YYYY-MM-DD'),
			endDay: endTime.format('YYYY-MM-DD'),
		};
		let rsp = await getHuoDongReport(params);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
		}
		let data = rsp.data;
		setData(data);
	};

	const tabBarExtrContent = () => (
		<CustomRangePicker
			onChange={(_startTime, _endTime) => {
				startTime = _startTime;
				endTime = _endTime;
				getData();
			}}
			startTime={startTime}
			endTime={endTime}
		/>
	);
	return (
		<Card extra={tabBarExtrContent()} title={'活动趋势'} loading={loading} bodyStyle={{ paddingLeft: 15 }}>
			<DataContext.Provider value={data}>
				<LineChart />
			</DataContext.Provider>
		</Card>
	);
};
export default LineHuodongReport;
