import Api from '../data/API';

export async function getWuyeProjectInfo(params: any) {
	return Api.postWithAuth('/api/wuye/get_wuye_projectInfo', params);
}

export async function updataWuyeProjectInfo(params: any) {
	return Api.postWithAuth('/api/wuye/updata_wuye_project_info', params);
}

export async function getKuangshiJobParam(params: any) {
	return Api.postWithAuth('/admin/api/foundation/getKuangshiJobParam', params);
}

export async function setKuangshiJobParam(params: any) {
	return Api.postWithAuth('/admin/api/foundation/setKuangshiJobParam', params);
}

export async function syncKuangShiFace1(params: any) {
	return Api.postWithAuth('/admin/api/foundation/syncKuangShiFace', params);
}

export async function setMailInfo(params: any) {
	return Api.postWithAuth('/admin/api/foundation/set_mail_info', params);
}

export async function testMailInfo(params: any) {
	return Api.postWithAuth('/admin/api/foundation/test_mail_info', params);
}

export async function getAppModel1List(params: any) {
	return Api.postWithAuth('/admin/api/foundation/getAppModel1List', params);
}
export async function getRongQiList(params: any) {
	return Api.postWithAuth('/admin/api/foundation/getRongQiList', params);
}
export async function getVersionController(params: any) {
	return Api.postWithAuth('/admin/api/foundation/getVersionController', params);
}
export async function getAllLiuchengDanData(params: any) {
	return Api.postWithAuth('/api/liucheng/get_all_liuchengdan_data', params);
}

export async function getAllLiucheng(params: any) {
	return Api.postWithAuth('/api/liucheng/get_all_liucheng', params);
}
export async function addAppModel1(params: any) {
	return Api.postWithAuth('/admin/api/foundation/addAppModel1', params);
}
export async function tongbuAppModel1(params: any) {
	return Api.postWithAuth('/admin/api/foundation/tongbuAppModel1', params);
}

export async function sortAppModel1(params: any) {
	return Api.postWithAuth('/admin/api/foundation/sortAppModel1', params);
}
export async function deleteAppModel1(params: any) {
	return Api.postWithAuth('/admin/api/foundation/deleteAppModel1 ', params);
}
export async function updateAppModel1(params: any) {
	return Api.postWithAuth('/admin/api/foundation/updateAppModel1 ', params);
}
export async function getDataTranList(params: any) {
	return Api.postWithAuth('/adminapi/dataTransmission/getDataTranList', params);
}
export async function addDataTran(params: any) {
	return Api.postWithAuth('/adminapi/dataTransmission/addDataTran', params);
}
export async function updateDataTran(params: any) {
	return Api.postWithAuth('/adminapi/dataTransmission/updateDataTran', params);
}
export async function deleteDataTran(params: any) {
	return Api.postWithAuth('/adminapi/dataTransmission/deleteDataTran', params);
}