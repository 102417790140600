import React, { useCallback, useRef, useState } from "react";
import { ActionType } from '@ant-design/pro-table';
import {Button, Col, Form, Input, message, Modal, Popconfirm, Row, Space} from 'antd';
import API from '../../../data/API';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import AddDeviceGroup from '../components/AddDeviceGroup';
import {
	deleteDeviceGroup,
	deviceBind,
	getDeviceGroupMapping,
	updateDeviceGroup,
	getDeviceGroupList, sortDeviceGroup
} from "../../../services/ZhiNengMenJinService";
import { FormInstance } from 'antd/es/form';
import DeviceList from '../components/DeviceList';
import UpdateDeviceGroup from '../components/UpdateDeviceGroup';
import HTMLBackend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import DragableBodyRow from "../../../components/DragableBodyRow";
import { sortEmployeeType } from "../../../services/EmployeeTypeService";

const DeviceGroup: React.FC = () => {
	let msg = require('../../../util/SuspensionMsg');
	const Search = Input.Search;
	const Confirm = Modal.confirm;
	const [updateForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const actionRef = useRef<ActionType>();
	const [deviceGroupName, setDeviceGroupName] = useState('');
	const [allKey, setAllKey] = useState([]);
	const [addVisible, setAddVisible] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [targetKeys, setTargetKeys] = useState([]);
	const [updateVisible, setUpdateVisible] = useState(false);
	const [seeVisible, setSeeVisible] = useState(false);
	const [groupName, setGroupName] = useState('');
	const [uuid, setUuid] = useState('');
	const [groupLeft, setGroupLeft] = useState([]);
	const [deviceGroupVisible, setDeviceGroupVisible] = useState(false);
	const [orderVisible, setOrderVisible] = useState(false);
	const actionRef1 = useRef<ActionType>();
	const [sortData, setSortData] = useState([]);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const columns = [
		{
			title: '名称',
			width: 250,
			dataIndex: 'deviceGroupName',
			render: (text: any, record: any) => {
				return (
					<a
						onClick={() => {
							openUpdateDeviceGroupModal(record);
						}}
					>
						{text}
					</a>
				);
			},
		},
		{
			title: '设备数量',
			dataIndex: 'deviceNum',
			width: 100,
			render: (text: any, record: any) => {
				return (
					<a
						onClick={() => {
							seeDeviceList(record);
						}}
					>
						{text}
					</a>
				);
			},
		},
		// {
		// 	title: '时间',
		// 	width: 200,
		// 	dataIndex: 'createTime',
		// },
		{
			title: '操作',
			width: 300,
			fixed: 'right',
			render: (text: any, item: any) => (
				<Space>
					<a
						onClick={() => {
							findDeviceGroupMapping(item);
						}}
					>
						编辑
					</a>
					<a
						onClick={() => {
							deleteDeviceGroupItem(item);
						}}
					>
						删除
					</a>
					<Popconfirm
						title="您确定要开启手机绑定吗?"
						onConfirm={() => {
							deviceBindItem(item.uuid, 'needBindPhone', true);
						}}
						okText="确定"
						cancelText="取消"
					>
						<a>手机绑定开启</a>
					</Popconfirm>
					<Popconfirm
						title="您确定要关闭手机绑定吗?"
						onConfirm={() => {
							deviceBindItem(item.uuid, 'needBindPhone', false);
						}}
						okText="确定"
						cancelText="取消"
					>
						<a>手机绑定关闭</a>
					</Popconfirm>
					<Popconfirm
						title="您确定要开启实名认证吗?"
						onConfirm={() => {
							deviceBindItem(item.uuid, 'needUserCertified', true);
						}}
						okText="确定"
						cancelText="取消"
					>
						<a>实名认证开启</a>
					</Popconfirm>
					<Popconfirm
						title="您确定要关闭实名认证吗?"
						onConfirm={() => {
							deviceBindItem(item.uuid, 'needUserCertified', false);
						}}
						okText="确定"
						cancelText="取消"
					>
						<a>实名认证关闭</a>
					</Popconfirm>
				</Space>
			),
		},
	];

	const columns1 = [
		{
			title: '名称',
			width: 250,
			dataIndex: 'deviceGroupName',
			render: (text: any, record: any) => {
				return (
					<a
						onClick={() => {
							openUpdateDeviceGroupModal(record);
						}}
					>
						{text}
					</a>
				);
			},
		}
	]


	const deviceBindItem = async (uuid: string, key: string, value: boolean) => {
		let param = {
			uuid: uuid,
		};
		param[key] = value;
		const res = await deviceBind(param);
		if (res.err === 0) {
			message.success('操作成功');
		} else {
			message.error(res.msg);
		}
	};

	const deleteDeviceGroupItem = (record: any) => {
		Confirm({
			title: '删除设备分组',
			content: '您确认要删除" ' + record.deviceGroupName + ' "这个设备分组吗？',
			okText: '确定',
			okType: 'danger',
			maskClosable: true,
			cancelText: '取消',
			onOk() {
				return new Promise(async (resolve, reject) => {
					const res = await deleteDeviceGroup({
						uuid: record.uuid,
					});
					setButtonLoading(false);
					if (res.err === 0) {
						resolve();
						if (actionRef.current) {
							actionRef.current.reload();
						}
						msg.suspensionMsg({ content: '删除成功', type: 'success' });
					} else {
						reject();
						msg.suspensionMsg({ content: res.msg, type: 'error' });
					}
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const findDeviceGroupMapping = async (value: any) => {
		console.log('value:::', value);
		setUuid(value.uuid);
		setTargetKeys([]);
		setGroupLeft([]);
		setDeviceGroupVisible(true);
		const res = await getDeviceGroupMapping({
			deviceGroupUuid: value.uuid,
		});
		if (res.err === 0) {
			setTargetKeys(res.groupRight);
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
	};

	const seeDeviceList = async (record: any) => {
		setSeeVisible(true);
		setTargetKeys([]);
		setGroupName(record.deviceGroupName);
		const res = await getDeviceGroupMapping({
			deviceGroupUuid: record.uuid,
		});
		if (res.err === 0) {
			setTargetKeys(res.groupRight);
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
	};

	const openUpdateDeviceGroupModal = (record: any) => {
		console.log('record::::', record);
		const formData: any = {
			uuid: record.uuid,
			deviceGroupName: record.deviceGroupName,
			deviceGroup: record.deviceGroup,
		};
		updateForm.setFieldsValue(formData);
		setUpdateVisible(true);
	};

	const components = {
		body: {
			row: DragableBodyRow,
		},
	};

	const updateDeviceGroupItem = () => {
		updateForm.validateFields().then(async (data: any) => {
			console.log('data:::a', data);
			setButtonLoading(true);
			const formData: any = {
				uuid: data.uuid,
				deviceGroupName: data.deviceGroupName,
				deviceGroup: data.deviceGroup,
			};
			console.log('formData:::::,', formData);
			const res = await updateDeviceGroup(formData);
			setButtonLoading(false);
			if (res.err === 0) {
				setUpdateVisible(false);
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: res.msg, type: 'error' });
			}
		});
	};

	const tableSearchBarRender = () => [
		<Input
			id="inputSearchExample3"
			placeholder="名称"
			value={deviceGroupName}
			onChange={(e: any) => {
				setDeviceGroupName(e.target.value);
			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	const getData = async () => {
		if (actionRef.current) {
			actionRef.current.reload();
		}
	};

	const openAddMadal = () => {
		setAddVisible(true);
		setTargetKeys([]);
	};
	const tableOperationsBarRender = () => [
		<Button type={"primary"} onClick={() =>{setOrderVisible(true)}}>编辑排序</Button>,
		<Button onClick={openAddMadal} type={'primary'}>
			添加设备分组
		</Button>,
	];

	const moveRow = useCallback(
		async (dragIndex, hoverIndex, key) => {
			if (dragIndex === hoverIndex) {
				return;
			}
			console.log('dragIndex', dragIndex);
			console.log('hoverIndex', hoverIndex);
			let route = [...sortData];
			const d1 = route[dragIndex];
			const ho = route[hoverIndex];
			console.log('data::', d1, ho);
			let tmp = route[dragIndex]; //临时储存文件
			route.splice(dragIndex, 1); //移除拖拽项
			route.splice(hoverIndex, 0, tmp); //插入放置项
			setSortData([...route]);
			// findArray(routes, key, dragIndex, hoverIndex);
			actionRef1.current.reload();
		},
		[sortData]
	);

	const getAllList= async () => {
		let rep = await getDeviceGroupList({});
		if (rep.err === 0) {
			setSortData(rep.data)
			return {
				data: rep.data
			}
		}
		return {
			data: []
		}
	}

	const sortDataItem = async () => {
		console.log('排序：：：', '排序');
		const data = sortData;
		let ids: any = [];
		data.map((item: any) => {
			ids.push(item.id);
		});
		console.log('ids', ids);
		setConfirmLoading(true)
		const res = await sortDeviceGroup({ ids: ids.toString() });
		setConfirmLoading(false);
		if (res.err === 0) {
			setOrderVisible(false);
			message.success('排序成功');
			actionRef.current.reload();
		} else {
			message.error('排序失败');
		}
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let url = '/api/device/getDeviceGroup';
					let res = await API.postWithAuth(url, {
						deviceGroupName: deviceGroupName,
						page: params.current,
						size: params.pageSize,
					});
					if (res.err === 0) {
						setAllKey(res.groupLeft);
					} else {
						msg.suspensionMsg({ content: res.msg, type: 'danger' });
					}
					getAllList().then()
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			{/*新增设备分组*/}
			<Modal
				width={850}
				visible={addVisible}
				confirmLoading={buttonLoading}
				title="添加设备分组"
				onCancel={() => {
					setAddVisible(false);
				}}
				footer={null}
			>
				<Row justify={'center'}>
					<Col>
						<AddDeviceGroup
							key={new Date().getTime().toString()}
							disabled={false}
							allDevice={allKey}
							selectDevice={targetKeys}
							onCancel={() => {
								setAddVisible(false);
							}}
							refreshData={getData}
						/>
					</Col>
				</Row>
			</Modal>
			{/*编辑设备*/}
			<Modal
				visible={updateVisible}
				confirmLoading={buttonLoading}
				title="修改设备分组"
				onOk={updateDeviceGroupItem}
				onCancel={() => {
					setUpdateVisible(false);
				}}
			>
				<ProxyForm form={updateForm}>
					<FormItem label={'deviceGroup'} name={'deviceGroup'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'分组名称'} name={'deviceGroupName'}>
						<Input />
					</FormItem>
				</ProxyForm>
			</Modal>

			{/*分组详情*/}
			<Modal
				width={850}
				visible={seeVisible}
				title="设备列表"
				onCancel={() => {
					setSeeVisible(false);
				}}
				footer={null}
			>
				<Row justify="start" align="top" gutter={8}>
					<Col span={3}>分组名称</Col>
					<Col span={7}>{groupName}</Col>
				</Row>
				<Row justify={'start'} align={'top'} gutter={8}>
					<Col span={3} style={{ paddingTop: 20 }}>
						设备列表
					</Col>
					<Col span={21}>
						<DeviceList
							key={targetKeys.toString()}
							allDevice={allKey}
							selectDevice={targetKeys}
							onCancel={() => {
								setSeeVisible(false);
							}}
						/>
					</Col>
				</Row>
			</Modal>
			{/*设备分组*/}
			<Modal
				width={850}
				visible={deviceGroupVisible}
				title="设备分组"
				onCancel={() => {
					setDeviceGroupVisible(false);
				}}
				footer={null}
			>
				<Row justify={'center'}>
					<Col>
						<UpdateDeviceGroup
							groupUuid={uuid}
							key={new Date().getTime().toString()}
							disabled={false}
							allDevice={allKey}
							selectDevice={targetKeys}
							onCancel={() => {
								setDeviceGroupVisible(false);
							}}
							refreshData={() => {
								if (actionRef.current) {
									actionRef.current.reload();
								}
							}}
						/>
					</Col>
				</Row>
			</Modal>

			<Modal title={'编辑排序'} visible={orderVisible} confirmLoading={confirmLoading} onCancel={() => {setOrderVisible(false)}} onOk={sortDataItem}>
				<DndProvider backend={HTMLBackend}>
					<GSTable
						rowSelection={false}
						options={false}
						actionRef={actionRef1}
						components={components}
						onRow={(record: any, index: any) => ({
							index,
							moveRow,
						})}
						columns={columns1}
						dataSource={sortData}
						pagination={false}
					/>
				</DndProvider>
			</Modal>
		</div>
	);
};
export default DeviceGroup;
