import React, { useRef, useState, useEffect } from "react";
import GSTable from "../../../components/GSTable";
import { message, Select, Button, Space, Modal } from "antd";
import { ActionType } from "@ant-design/pro-table";
import ReactJson from 'react-json-view';
import AuthorizedView from "../../../util/AuthorizedView";
import {getCheChangSyncRecordList, sendJob} from "../../../services/Car";
const typeEnum = {
    "addCar": '添加车辆',
    "deleteCar": '删除车辆',
};
const statusEnum = {
    "0": '未接收',
    "1": '已接收'
};
const cheChangEnum = {
    "cheChang592ee9ef": '车场-1',
};
const onlineEnum = {
    true: { text: '在线', status: 'Success' },
    false: { text: '离线', status: 'Error' },
};
const CarAsyncList: React.FC = (props: any) => {
    const column = [
        // {
        // title: "设备名称",
        // key: "deviceId",
        // dataIndex: "deviceId",
        // valueEnum: cheChangEnum,
        //  },
    {
        title: "任务类型",
        key: "type",
        dataIndex: "type",
        valueEnum: typeEnum,

    },
    {
        title: '是否在线',
        key: 'online',
        dataIndex: 'online',
        valueEnum: onlineEnum,
    }, {
        title: "任务状态",
        tooltip: '该信息表示终端设备是否接收到指令',
        key: "state",
        dataIndex: "state",
        valueEnum: statusEnum,

    }, {
        title: "结果",
        tooltip:'该信息表示设备实际执行指令的结果',
        key: "msg",
        dataIndex: "msg",

    },
        //     {
        //     title: '任务数据',
        //     key: 'data',
        //     dataIndex: 'data',
        //     width: 120,
        //     valueType: 'code'
        // },
        {
            title: "创建时间",
            key: "createTime",
            dataIndex: "createTime",
            valueType: "dateTime",
        }, {
            title: "操作",
            key: "active",
            render: (record: any) => {
                return <Space>
                    <a onClick={() => openInfo(record.data)}>详情</a>
                    <AuthorizedView needAuthority={'car-sync-record-mamager:sync'}>
                        {record.state === '0' && <a onClick={() => sendAsyncJob(record.online,record.uuid)}>重新发送</a>}
                    </AuthorizedView>
                </Space>
            }
        }];
    const actionRef = useRef<ActionType>();
    const [deviceId, setDeviceId] = useState("");
    const [deviceList, setDeviceList] = useState([]);
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [visible, setVisible] = useState(false);
    const [item, setItem] = useState({});
    const confirm = Modal.confirm;

    useEffect(() => {
        getDeviceList().then();
    }, []);

    const openInfo = (data: string) => {
        try {
            setItem(JSON.parse(data));
            setVisible(true);
        }catch (e) {

        }
    };
    const getDeviceList = async () => {
        // let rsp = await getCarPathList({});
        // console.log("rsp::::", rsp);
        // if (rsp.err === 0) {
        // }
        const datas:any = [
            {uuid:'cheChang592ee9ef',name:'车场-1'}
        ]
        setDeviceList(datas)

    };
    const loadData = async (params: any) => {
        let data = {
            deviceId: deviceId,
            type: type,
            state: status,
            page: params.current,
            size: params.pageSize

        };
        let rsp = await getCheChangSyncRecordList(data);
        if (rsp.err !== 0) {
            message.error(rsp.msg);
            return {
                data: [],
                total: 0
            };
        }
        return {
            data: rsp.data.data,
            total: rsp.data.total
        };

    };
    const sendAsyncJob =  (online: boolean, uuid: string) => {
        if (!online) {
            message.warn('该设备未上线，请稍后再试。');
            return;
        }
        confirm({
            title: '提示',
            content: '您确定要重新发送该任务吗？',
            onOk: async () => {
                let data = {
                    uuid: uuid
                };
                let rsp = await sendJob(data);
                if (rsp.err === 0) {
                    message.success(rsp.msg);
                    if (actionRef.current) {
                        actionRef.current.reload()
                    }
                } else {
                    message.error(rsp.msg)
                }
            }
        })

    }
    const tableSearchBarRender =  [
        // <Select style={{ width: 200 }} defaultValue={deviceId} onChange={(e: string) => {setDeviceId(e);}}>
        //     <Select.Option value="">全部车场</Select.Option>
        //     {
        //         deviceList.map((item: any) => {
        //             return <Select.Option value={item.uuid}>{item.name}</Select.Option>
        //         })
        //     }
        // </Select>,
        <Select style={{ width: 200 }} defaultValue={type} onChange={(e: string) => {
            setType(e);
        }}>
            <Select.Option value="">全部类型</Select.Option>
            <Select.Option value="addCar">添加车辆</Select.Option>
            <Select.Option value="deleteCar">删除车辆</Select.Option>
        </Select>,
        <Select style={{ width: 200 }} defaultValue={status} onChange={(e: string) => setStatus(e)}>
            <Select.Option value="">全部状态</Select.Option>
            <Select.Option value="1">已接收</Select.Option>
            <Select.Option value="0">未接收</Select.Option>
        </Select>,
        <Button type={"primary"} onClick={() => {
            if (actionRef.current) {
                actionRef.current.reloadAndRest();
            }
        }}>查询</Button>
    ];
    return (
        <div>
            <GSTable
                rowKey={"uuid"}
                actionRef={actionRef}
                columns={column}
                request={loadData}
                searchBarRender={tableSearchBarRender}
            />
            <Modal title={'详情'} visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
                <ReactJson src={item} name={'请求参数'} />
            </Modal>
        </div>
    );
};
export default CarAsyncList;
