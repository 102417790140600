import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Modal, Row, Statistic, Space, Table, message } from 'antd';
import VerificationRecordLine from '../../../components/VerificationRecordLine';
import { getHealthData } from '../../../services/StatisticsService';
import moment from 'moment';
import VerificationDataPieChart from '../../../components/VerificationDataPieChart';
import GSTable from "../../../components/GSTable";
import { getDevicesByAll } from "../../../services/ZhiNengMenJinService";
import * as XLSX from "xlsx";
import CustomRangePicker from "../../../components/CustomRangePicker";
import { getCodeList } from '../../../services/HeadlthCodeService';
import { getHeatlhRecordList } from '../../../services/TemperatureRecordsService';

// const valueEnum = {
// 	'-6':  <span style={{ color: '#ff0000' }}>健康宝数据解析异常(-6)</span>,
// 	'-5':  <span style={{ color: '#ff0000' }}>健康码获取异常(-5)</span>,
// 	'-4':  <span style={{ color: '#ff0000' }}>无身份证信息(-4)</span>,
// 	'-3': <span style={{ color: '#ff0000' }}>获取超时(-3)</span>,
// 	'-2': <span style={{ color: '#ff0000' }}>网络异常(-2)</span>,
// 	'-1': <span>未开启健康码验证(-1)</span>,
// 	0: <span style={{ color: '#008000' }}>绿码，状态正常(0)</span>,
// 	10: <span style={{ color: '#008000' }}>绿码，白名单(10)</span>,
// 	20: <span style={{ color: '#008000' }}>绿码，入境完成隔离或行程轨迹风险低(20)</span>,
// 	30: <span style={{ color: '#008000' }}>绿码，环京通勤人员(30)</span>,
// 	1: <span style={{ color: '#ff0000' }}>黄码，社区隔离中(1)</span>,
// 	2: <span style={{ color: '#ff0000' }}>红码，隔离或密切接触人员！！！(2)</span>,
// 	3: <span style={{ color: '#ff0000' }}>黄码，此人近期有可能去过中高风险地区</span>,
// 	11: <span style={{ color: "#ff0000" }}>“进京后72小时内未做核酸人员、出现发热等“十一类症状”去小诊所后72小时内未做核酸人员</span>,
// 	31: <span style={{ color: '#ff0000' }}>异常，行程轨迹中存在中高风险地区(31)</span>,
// 	41: <span style={{ color: '#ff0000' }}>异常，入境人员进京但未在社区登记解除隔离(41)</span>,
// 	21: <span style={{ color: '#ff0000' }}>非健康宝注册用户(21)</span>,
// 	88: <span style={{ color: '#ff0000' }}>请到健康宝进行行程申报(88)</span>,
// 	' ': <span style={{ color: '#ff0000' }}>请到健康宝进行行程申报</span>,
// };
const idCardEnum = {
	通过: <span style={{ color: '#008000' }}>通过</span>,
	未通过: <span style={{ color: '#ff0000' }}>未通过</span>,
};

const  EpidemicPreventionReport: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>({});
	const [list, setList] = useState([]);
	const [dataModalVisible, setDataModalVisible] = useState(false);
	const [deviceEnum, setDeviceEnum] = useState<any>({});
	const [startTime, setStartTime] = useState(moment().hours(0).minute(0).second(0));
	const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
	const selectTypeRef= useRef('');
	const [valueEnum, setValueEnum] = useState({
		"-6": <span style={{ color: "#ff0000" }}>健康宝数据解析异常(-6)</span>,
		"-5": <span style={{ color: "#ff0000" }}>健康码获取异常(-5)</span>,
		"-4": <span style={{ color: "#ff0000" }}>无身份证信息(-4)</span>,
		"-3": <span style={{ color: "#ff0000" }}>获取超时(-3)</span>,
		"-2": <span style={{ color: "#ff0000" }}>网络异常(-2)</span>,
		"-1": <span>未开启健康码验证(-1)</span>,
	})
	const [quanXuan, setQuanXuan] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [total, setTotal] = useState(0);
	const infoColumns = [
		{
			title: '姓名',
			dataIndex: 'name',
		},
		{
			title: '设备名称',
			dataIndex: 'deviceName',
			// valueEnum: deviceEnum
		},
		{
			title: '部门名称',
			dataIndex: 'departmentName',
		},
		{
			title: '身份证号',
			dataIndex: 'idCard',
		},
		{
			title: '手机号',
			dataIndex: 'phoneNo',
		},
		{
			title: '体温',
			dataIndex: 'temperature',
			render: (text: string) => {
				if (text === '-') {
					return <span>-</span>;
				}
				return <span style={{ color: parseFloat(text) > 37.3 ? '#ff0000' : '#333' }}>{text}℃</span>;
			},
		},
		{
			title: '健康码',
			valueEnum: valueEnum,
			dataIndex: 'healthCheck',
		},
		{
			title: '人证核验',
			valueEnum: idCardEnum,
			dataIndex: 'idCheck',
		},
		{
			title: '记录时间',
			valueType: 'dateTime',
			dataIndex: 'createdTime',
		},
	]
	useEffect(() => {
		getDeviceList().then()
		loadCodeList().then()
	}, []);

	const loadCodeList = async() => {
		let rsp = await getCodeList({})
		if (rsp.err === 0) {
			(rsp.data || []).map((item: any) => {
				valueEnum[item.code] = <span style={{ color: item.status === 'SUCCESS' ? "#008000" : "#ff0000"}}>{item.msg}</span>
			})
			console.log('valueEnum::::', valueEnum)
			setValueEnum({...valueEnum})
		}
	}
	useEffect(() => {
		getData().then();
	}, [startTime, endTime])

	const getDeviceList = async () => {
		let params = {
			typecode: "0701",
			wuyeid: localStorage.getItem("wyUuid")
		};
		let rsp = await getDevicesByAll(params);
		console.log("rsp::::", rsp);
		if (rsp.err === 0) {
			let valueEnum: any = {};
			rsp.data.map((item: any) => {
				if (item.typecode === "0701") {
					valueEnum[item.deviceid] = item.description;
				}
			});
			setDeviceEnum(valueEnum);
		}
	};
	const getData = async () => {
		let params = {
			startTime: startTime.format('YYYY-MM-DD 00:00:00'),
			endTime: endTime.format('YYYY-MM-DD 23:59:59'),
		};
		setLoading(true);
		let rsp = await getHealthData(params);
		if (rsp.err === 0) {
			setLoading(false);
			setData(rsp.data);
		}
	};
	const showListModel = (data: any) => {
		setDataModalVisible(true);
		selectTypeRef.current = data
	}


	const getDataRecord = async (param: any) => {
		console.log('state:::::', startTime.format('YYYY-MM-DD 00:00:00'), endTime.format('YYYY-MM-DD 23:59:59'));
		let data = {
			selectType: selectTypeRef.current,
			startTime: startTime.format('YYYY-MM-DD HH:mm:00'),
			endTime: endTime.format('YYYY-MM-DD HH:mm:59'),
			page: param.current,
			size: param.pageSize,
		};
		let rsp = await getHeatlhRecordList(data);
		if (rsp.err === 0) {
			if (quanXuan) {
				let uuids: any = [];
				rsp.data.data.map((item: any) => {
					uuids.push(item.uuid);
				});
				setSelectedRowKeys(uuids);
				setTotal(rsp.data.total);
			}
			setTotal(rsp.data.total);
			return {
				data: rsp.data.data,
				total: rsp.data.total,
			};
		} else {
			return {
				data: [],
				total: 0,
			};
		}
	};

	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setLoading(true);
		if (quanXuan) {
			let data = {
				selectType: selectTypeRef.current,
				startTime: startTime.format('YYYY-MM-DD 00:00:00'),
				endTime: endTime.format('YYYY-MM-DD 23:59:59'),
			};
			let rsp = await getHeatlhRecordList(data);
			if (rsp.err === 0) {
				setExprotData(rsp.data.data);
			} else {
				message.error(rsp.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
		setLoading(false);
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		infoColumns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data: [string?] = [];
			infoColumns.map((item) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children || '');
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}

					} else {
						data.push("");
					}
				} else if (item.valueType === "dateTime") {
					data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "防疫记录");

		/* save to file */
		XLSX.writeFile(wb, "防疫记录.xlsx");
	};

	return (
		<div>
			<Row>
				<Col>
					<CustomRangePicker
						onChange={(startTime, endTime) => {
							setStartTime(startTime);
							setEndTime(endTime);
						}}
						selectType={1}
						startTime={startTime}
						endTime={endTime}
					/>
				</Col>
			</Row>
			<Row gutter={16}  align="middle">
				<Col span={4}>
					<Card className={'clickItem'} style={{ backgroundColor: '#E82D2D' }} onClick={() => showListModel('2')} loading={loading} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>隔离或密接人员</span>}
							valueStyle={{ color: '#fff' }}
							value={data.hongFail || 0}
						/>
					</Card>
				</Col>
                <Col span={4}>
                    <Card className={'clickItem'} loading={loading} onClick={() => showListModel('1')} style={{ backgroundColor: '#FAAD14' }} type="inner" bordered={false}>
                        <Statistic
                            title={<span style={{ color: '#fff' }}>社区隔离中</span>}
                            valueStyle={{ color: '#fff' }}
                            value={data.sheQuGeLi || 0}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card className={'clickItem'} loading={loading} onClick={() => showListModel('2')} style={{ backgroundColor: '#FAAD14' }} type="inner" bordered={false}>
                        <Statistic
                            title={<span style={{ color: '#fff' }}>入境人员未完成隔离</span>}
                            valueStyle={{ color: '#fff' }}
                            value={data.otherFail || 0}
                        />
                    </Card>
                </Col>
				<Col span={4}>
					<Card  className={'clickItem'} loading={loading} onClick={() => showListModel('31')} style={{ backgroundColor: '#FAAD14' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>经过中高风险地区</span>}
							valueStyle={{ color: '#fff' }}
							value={data.gaoWei || 0}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card className={'clickItem'} loading={loading} onClick={() => showListModel('3,4,51,11')} style={{ backgroundColor: '#FAAD14' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>健康码弹框</span>}
							valueStyle={{ color: '#fff' }}
							value={data.keNengGaoWei || 0}
						/>
					</Card>
				</Col>


				<Col span={4}>
					<Card className={'clickItem'} loading={loading} onClick={() => showListModel('-99')}  style={{ backgroundColor: '#FAAD14' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>体温异常</span>}
							valueStyle={{ color: '#fff' }}
							value={data.tiWenFail || 0}
						/>
					</Card>
				</Col>
			</Row>
			<Row gutter={16}  align="middle">
				<Col span={4}>
					<Card loading={loading} type="inner" bordered={false}>
						<Statistic title="健康宝核验人数" value={data.total || 0} />
					</Card>
				</Col>

				<Col span={4}>
					<Card className={'clickItem'} loading={loading} onClick={() => showListModel('21, ,88')} style={{ backgroundColor: 'rgba(0,0,0,0.4)' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>健康宝行程未申报</span>}
							valueStyle={{ color: '#fff' }}
							value={data.xinchengweishengbao || 0}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card className={'clickItem'} loading={loading} onClick={() => showListModel('-4,500:idCard格式不正确')} style={{ backgroundColor: 'rgba(0,0,0,0.4)' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>无身份证信息</span>}
							valueStyle={{ color: '#fff' }}
							value={data.wuShenfengZheng || 0}
						/>
					</Card>
				</Col>
			{/*</Row>*/}
			{/*<Row gutter={16}  align="middle">*/}
				<Col span={4}>
					<Card loading={loading} style={{ backgroundColor: '#52C41A' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>状态正常</span>}
							valueStyle={{ color: '#fff' }}
							value={data.success || 0}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card loading={loading} style={{ backgroundColor: '#52C41A' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>入境完成隔离或行程风险低</span>}
							valueStyle={{ color: '#fff' }}
							value={data.ruJingGeLi || 0}
						/>
					</Card>
				</Col>
				<Col span={4}>
					<Card loading={loading} style={{ backgroundColor: '#52C41A' }} type="inner" bordered={false}>
						<Statistic
							title={<span style={{ color: '#fff' }}>环京人员</span>}
							valueStyle={{ color: '#fff' }}
							value={data.huanJing || 0}
						/>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col>
					<span style={{ fontSize: 16 }}>历史趋势</span>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<VerificationRecordLine />
				</Col>
				<Col span={12}>
					<VerificationDataPieChart />
				</Col>
			</Row>
			<Modal title={'详情'}
				   destroyOnClose={true}
				   width={1200}
				   visible={dataModalVisible}
				   onOk={() => {setDataModalVisible(false)}}
				   onCancel={() => {setDataModalVisible(false)}}
				   footer={null}
			>
				<GSTable
					columns={infoColumns}
					rowSelection={{
						fixed: true,
						columnWidth: 100,
						preserveSelectedRowKeys: true,
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys: any, selectedRows: any) => {
							setSelectedRowKeys(selectedRowKeys);
							setQuanXuan(false);
						},
						selections: [
							{
								key: 'SELECT_CURRENT_ALL',
								text: '全选当前页面',
								onSelect: (changableRowKeys: any) => {
									setQuanXuan(false);
									setSelectedRowKeys(selectedRowKeys.concat(changableRowKeys));
								},
							},
							{
								key: 'SELECT_ALL',
								text: '全选全部页面',
								onSelect: (changableRowKeys: any) => {
									setQuanXuan(true);
									setSelectedRowKeys(changableRowKeys);
								},
							},
						],
					}}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
											setQuanXuan(false);
										}}
									>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						console.log('tableAlertOptionRender::::', selectedRows);
						return (
							<Space size={16}>
								<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							</Space>
						);
					}}
					rowKey={'uuid'}
					rowKey={"id"}
					options={false}
					request={getDataRecord}

				/>
			</Modal>
		</div>
	);
};
export default EpidemicPreventionReport;
