import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import { NavLink } from 'react-router-dom';
import {
	Button,
	Input,
	Table,
	Divider,
	Modal,
	DatePicker,
	Select,
	Pagination,
	Row,
	Col,
	Radio,
	InputNumber,
	Switch,
	message,
	Tooltip,
	Space,
	Form
} from 'antd';
import LockUser from '../../../types/LockUser';
import SendKeyModal from './SendKeyModal';
import {
	unfreezeMapping,
	updateMapping,
	keyDelay,
	userDeviceDelay,
	deleteUserAllKey,
	getDeviceByEmail,
	getDevices,
	getWuyeDeviceRole,
	updeteKeyAvailable,
	deleteMappingList,
	batchUpdeteKeyAvailable
} from '../../../services/ZhiNengMenJinService';
import GSTable from '../../../components/GSTable';
import ThenPromise from 'promise';
import { FormInstance } from 'antd/es/form';
import { throws } from 'assert';

let msg = require('../../../util/SuspensionMsg');
let moment = require('moment');
const Search = Input.Search;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const confirm = Modal.confirm;

class SingleLockUserList extends React.Component {
	deviceId = '';
	dal = DAL.dal;
	contact = '';
	mappingType: string = '';
	search: string = '';
	email: string = this.dal.getQueryString('id');
	page: number = 1;
	size: number = 50;
	actionRef: any;
	columns = [
		{
			title: '账号昵称',
			dataIndex: 'nick_name'
		},
		{
			title: '设备名称',
			dataIndex: 'device_name'
		},
		{
			title: '设备ID',
			dataIndex: 'device_id'
		},
		{
			title: '发送者',
			dataIndex: 'sendName'
		},
		{
			title: '发送者账号',
			dataIndex: 'sent_by'
		},
		{
			title: '发送时间',
			dataIndex: 'createTime'
		},
		{
			title: '用户权限',
			dataIndex: 'type'
		},
		{
			title: '绑定次数',
			dataIndex: 'bindNum'
		},
		{
			title: '临时钥匙次数',
			dataIndex: 'faSongNum',
			render: (text: any, item: any) => {
				return 5 - item.faSongNum < 0 ? 0 : 5 - item.faSongNum;
			}
		},
		{
			title: '有效期',
			dataIndex: 'lastLoginTime',
			render: (text: any, item: any) => {
				return (
					item.start_time +
					(item.start_time === null || item.start_time === '' ? '' : ' 至 ') +
					item.expire_time
				);
			}
		},
		{
			title: '操作',
			dataIndex: 'type',
			width: 300,
			render: (text: any, item: any) => (
				<span>
					<a onClick={this.getUser.bind(this, 'deviceDelay', item.device_id)}>延期</a>
					<Divider type="vertical"/>
					<a onClick={this.getUser.bind(this, 'delete', item.device_id)}>删除</a>
					<Divider type="vertical"/>
					<a onClick={this.getRecord.bind(this, item)}>操作</a>
					<Divider type="vertical"/>
					<a
						onClick={() => {
							this.DJORJD(item);
						}}
					>
						{item.isAvailable ? '冻结' : '解冻'}
					</a>
				</span>
			)
		}
	];
	state: any;

	constructor(props: any) {
		super(props);
		this.state = {
			value: '',
			ds: [],
			isShow: false,
			deviceOnekeyVisible: false,
			deleteDeviceVisible: false,
			deviceDelayVisible: false,
			deleteMyDeviceVisible: false,
			myDeviceDelayVisible: false,
			total: 0,
			showRow: false,
			sendDeviceVisible: false,
			deviceIds: [],
			devices: [],
			starttime: moment().format('YYYY-MM-DD HH:mm:ss'),
			expiretime: moment().add(1, 'y').format('YYYY-MM-DD HH:mm:ss'),
			needBindImsi: false,
			needBindPhone: false,
			needUserCertified: false,
			bindNum: 5,
			sendNum: 5,
			deviceId: '',
			updateVisible: false,
			isAvailable: false,
			isAutoFill: false,
			userId: '',
			deviceName: '',
			confirmLoading: false,
			item: {},
			reason: '',
			keyRemarkVisible: false,
			selectedRowKeys: [],
			selectedRows: [],
			tempDeviceId: '',
			tempLoginId: '',
			batchYq: false,
			batchUpdateDJOrJDVisible: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.deleteLock = this.deleteLock.bind(this);
		this.deleteDevice = this.deleteDevice.bind(this);
		this.getData = this.getData.bind(this);
		this.keyDelay = this.keyDelay.bind(this);
		this.personalkeyDelay = this.personalkeyDelay.bind(this);
		this.getUser = this.getUser.bind(this);
	}

	componentWillMount() {
		this.getSelectDeviceList();
	}

	getSelectDeviceList = async () => {
		const res = await getDevices({});
		// API.postWithAuth('/api/device/getDevices' , {}).then(res => {
		if (res.err !== 0) {
			message.success(res.msg);
			return;
		}
		this.setState({
			devices: res.data
		});
	};

	handleChange(event: { target: { value: any } }) {
		this.contact = event.target.value;
	}

	handleSubmit(event: { preventDefault: () => void }) {
		event.preventDefault();
		return false;
	}

	DJORJD = (item: any) => {
		this.setState({
			item: item,
			reason: '',
			keyRemarkVisible: true
		});
	};

	//删除钥匙
	deleteLock(e: { preventDefault: () => void }) {
		console.log('点击删除', this.email);

		if (this.email === null) {
			alert('删除异常');
			return;
		}
		this.setState({
			confirmLoading: true
		});
		API.postWithAuth('/delete_key', {
			device_id: this.deviceId,
			contact: this.email
		}).then((res) => {
			console.log('获得删除用户钥匙的数据: ', res);
			this.setState({
				confirmLoading: false
			});
			if (res.err === 0) {
				message.success('删除成功');
				this.handleCancel();
				if (this.actionRef) {
					this.actionRef.reloadAndRest();
				}
			} else {
				message.error('删除失败');
			}
		});
	}

	getUser(status: any, e: string) {
		console.log('1234', e);
		if (status === 'deviceDelay') {
			this.setState({
				batchYq: false,
				deviceOnekeyVisible: true
			});
		} else if (status === 'delete') {
			this.setState({
				deleteMyDeviceVisible: true
			});
		}
		this.deviceId = e;
	}

	//删除全部非管理员的钥匙
	deleteDevice() {
		console.log('全部删除');
		this.getData();
	}

	updateKeyAvailable = async () => {
		const reason = this.state.reason;
		const item = this.state.item;
		if (reason == '' || reason == undefined || reason == null) {
			message.warning('请输入原因');
			return;
		}
		const formData: any = {
			userId: item.email,
			deviceId: item.device_id,
			isAvailable: !item.isAvailable,
			deviceName: item.deivice_name,
			event: reason
		};
		const res = await updeteKeyAvailable(formData);
		if (res.err === 0) {
			message.success('修改成功');
			this.setState({
				keyRemarkVisible: false,
				item: {},
				reason: ''
			});
			if (this.actionRef) {
				this.actionRef.reload();
			}
		} else {
			message.error('修改失败');
		}
	};
	getData = async () => {
		this.setState({
			confirmLoading: true
		});
		const res = await deleteUserAllKey({
			email: this.email,
			search: this.search,
			mappingType: this.mappingType
		});
		console.log('获得的用户', res);
		this.setState({
			confirmLoading: false
		});
		if (res.err === 0) {
			message.success(res.msg);
			this.handleCancel();
			if (this.actionRef) {
				this.actionRef.reloadAndRest();
			}
		} else {
			message.error(res.msg);
		}
		// });
	};

	//一键延期
	keyDelay = async () => {
		if (this.state.batchYq) {
			const formData: any = {
				loginId: this.state.tempLoginId,
				search: this.search,
				deviceId: this.state.tempDeviceId,
				mappingType: this.mappingType,
				expireTime: (this.state as any).delayDate
			};
			this.setState({
				confirmLoading: true
			});
			const res = await keyDelay(formData);
			this.setState({
				confirmLoading: false
			});
			if (res.err === 0) {
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				this.setState({
					deviceOnekeyVisible: false
				});
				if (this.actionRef) {
					this.actionRef.reload();
				}
			} else {
				msg.suspensionMsg({ content: '钥匙延期失败' });
			}
			console.log('extensionData:::', formData);
		} else {
			let data = new FormData();
			this.setState({
				confirmLoading: true
			});
			const res = await userDeviceDelay({
				phone: this.email,
				search: this.search,
				deviceId: this.deviceId,
				mappingType: this.mappingType,
				expireTime: (this.state as any).delayDate
			});
			this.setState({
				confirmLoading: false
			});
			console.log('', res);
			if (res.err === 0) {
				this.handleCancel();
				if (this.actionRef) {
					this.actionRef.reloadAndRest();
				}
				message.success(res.msg);
			} else {
				message.error('钥匙延期失败');
			}
		}
	};

	//延期时间
	delayDate = (date: any, dataToString: any) => {
		this.setState({
			delayDate: dataToString
		});
	};

	//个人时间延期
	personalkeyDelay = async () => {
		let delayDate = (this.state as any).delayDate;
		let delayD = delayDate.substring(0, 10);
		let d = new Date(Date.parse(delayD.replace(/-/g, '/')));
		if (d <= new Date()) {
			alert('请选择大于当前时间');
			return;
		}
		const res = await keyDelay({
			phone: this.email,
			expireTime: delayDate,
			device_id: (this.state as any).deviceid
		});
		console.log('', res);
		if (res.err === 0) {
			message.success(res.msg);
			this.handleCancel();
			if (this.actionRef) {
				this.actionRef.reloadAndRest();
			}
		} else {
			message.error('钥匙延期失败');
		}
	};

	/** 隐藏Modal框 */
	handleCancel = () => {
		this.deviceId = '';
		this.setState({
			deviceOnekeyVisible: false,
			deviceDelayVisible: false,
			deleteDeviceVisible: false,
			deleteMyDeviceVisible: false,
			myDeviceDelayVisible: false
		});
	};

	/** 显示Modal框 */
	showModal = (status: any) => {
		if (status === 'delete') {
			this.setState({
				deleteDeviceVisible: true
			});
		} else if (status === 'deviceDelay') {
			this.setState({
				deviceDelayVisible: true
			});
		} else if (status === 'deviceOnekey') {
			this.setState({
				deviceOnekeyVisible: true
			});
		} else if (status === 'send') {
			this.setState({
				sendDeviceVisible: true
			});
		}
	};

	getType = (value: any) => {
		console.log('value', value);
		let type = '';
		switch (value) {
			case 'admin':
				type = '超级管理员';
				break;
			case 'anytime':
				type = '普通用户';
				break;
			case 'manage':
				type = '管理';
				break;
			case 'look':
				type = '查看';
				break;
			case 'use_look':
				type = '使用和查看';
				break;
			case 'manage_look':
				type = '管理和查看';
				break;
			case 'use_manage':
				type = '使用和管理';
			default:
				break;
		}
		return type;
	};

	//设置设备ID
	setDeviceIds = (ev: any) => {
		this.setState({
			deviceIds: ev
		});
	};
	//设置设备权限
	jurlsdiction = (ev: any) => {
		this.setState({
			keyType: ev
		});
	};

	/** 发送钥匙 */
	sendKeys = () => {
		this.setState({
			sendDeviceVisible: false
		});

		if ((this.state as any).deviceIds === undefined || (this.state as any).deviceIds.length === 0) {
			message.warn('发送的设备不能为空');
			return;
		}

		if (this.email === undefined || this.email.length === 0) {
			message.warn('接受人不能为空');
			return;
		}
		let url = '/send_keys';
		let datas = { contacts: '', contact: '', device_id: '', key_type: '', start_time: '', expire_time: '' };
		datas.contacts = this.email;
		datas.device_id = (this.state as any).deviceIds.toString();
		datas.key_type = (this.state as any).keyType || 'anytime';
		datas.start_time = (this.state as any).starttime || moment().format('YYYY-MM-DD HH:mm:ss');
		datas.expire_time = (this.state as any).expiretime;

		API.postWithAuth(url, datas).then((res) => {
			if (res.err === 0) {
				message.success('发送成功');
				this.actionRef.reload();
			} else {
				message.error((res as any).msg);
			}
		});
	};
	setDateByRadio = (value: any) => {
		let type = value.target.value;
		let startTime = moment().format('YYYY-MM-DD HH:mm:ss');
		let endTime = '';
		switch (type) {
			case 1:
				endTime = moment().add(1, 'y').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 2:
				endTime = moment().add(1, 'M').format('YYYY-MM-DD HH:mm:ss');
				break;
			case 3:
				startTime = '1111-01-01 00:00:00';
				endTime = '9999-01-01 00:00:00';
				break;
			default:
				endTime = '';
				break;
		}
		console.log(startTime, endTime);
		this.setState({
			showRow: type === -1,
			starttime: startTime,
			expiretime: endTime
		});
	};
	getExpiretime = (date: any, dataToString: any) => {
		console.log(date, dataToString);
		this.setState({
			starttime: dataToString[0],
			expiretime: dataToString[1]
		});
	};

	//获取设备记录
	getRecord = async (record: LockUser) => {
		console.log('item:', record);
		this.setState({
			needBindImsi: record.needBindImsi,
			needBindPhone: record.needBindPhone,
			bindNum: record.bindNum,
			deviceId: record.device_id,
			updateVisible: true,
			isAvailable: record.isAvailable,
			userId: record.email,
			deviceName: record.device_name,
			sendNum: record.sendNum,
			isAutoFill: record.isAutoFill,
			needUserCertified: record.needUserCertified
		});
	};
	//设置钥匙
	updataMapping = async () => {
		this.setState({
			updateVisible: false
		});
		let data = {
			needBindImsi: this.state.needBindImsi,
			needBindPhone: this.state.needBindPhone,
			bindNum: this.state.bindNum,
			device_id: this.state.deviceId,
			isAvailable: this.state.isAvailable,
			isAutoFill: this.state.isAutoFill,
			userId: this.state.userId,
			sendNum: this.state.sendNum,
			needUserCertified: this.state.needUserCertified
		};
		let that = this;
		const res = await updateMapping(data);
		// API.postWithAuth('/api/device/update_mapping', data).then(res => {
		if (res.err === 0) {
			that.actionRef.reload();
			message.success('操作成功');
		} else {
			message.error('操作失败:' + res.msg);
		}
		// });
	};

	//冻结钥匙
	freezeMapping = (deviceId: string) => {
		let that = this;
		confirm({
			title: '您确定要冻结该钥匙吗？',
			onOk() {
				API.postWithAuth('/api/device/freeze_mapping', {
					deviceId: deviceId,
					userId: that.email
				}).then((res) => {
					if (res.err === 0) {
						that.actionRef.reload();
						message.success('钥匙冻结成功');
					} else {
						message.error(res.msg);
					}
				});
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	unFreezeMapping = (deviceId: string) => {
		let that = this;
		confirm({
			title: '您确定要解除冻结该钥匙吗？',
			onOk: async () => {
				const res = await unfreezeMapping({
					deviceId: deviceId,
					userId: that.email
				});
				if (res.err === 0) {
					that.actionRef.reload();
					message.success('钥匙解除冻结成功');
				} else {
					message.error(res.msg);
				}
				// });
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	tableSearchBarRender = () => [
		<Select
			style={{ width: 100 }}
			onChange={(value: any) => {
				this.mappingType = value;
				this.actionRef.reload();
			}}
			defaultValue={''}
		>
			<Option value={''}>全部钥匙</Option>
			<Option value="anytime">普通用户</Option>
			<Option value="manage">管理</Option>
			<Option value="look">查看</Option>
			<Option value="use_look">使用和查看</Option>
			<Option value="manage_look">管理和查看</Option>
			<Option value="use_manage">使用和管理</Option>
			<Option value="admin">超级管理员</Option>
		</Select>,
		<Search
			placeholder="设备名搜索"
			onSearch={(value: string) => {
				this.search = value;
				this.actionRef.reloadAndRest();
			}}
			enterButton={true}
		/>
	];

	tableOperationsBarRender = () => [
		<Button
			style={{ marginRight: 10 }}
			className="addEmpRight"
			type={'primary'}
			onClick={this.showModal.bind(this, 'delete')}
		>
			删除所有钥匙
		</Button>,
		<Button
			style={{ marginRight: 10 }}
			className=" addEmpRight"
			type={'primary'}
			onClick={this.showModal.bind(this, 'deviceOnekey')}
		>
			钥匙一键延期
		</Button>,
		<Button className="addEmpRight" type={'primary'} onClick={this.showModal.bind(this, 'send')}>
			发送钥匙
		</Button>
	];

	render() {
		const batchJdOrDj = async () => {
			if (this.state.reason == '' || this.state.reason == undefined || this.state.reason == null) {
				message.warning('请输入原因');
				return;
			}
			let data = [];
			console.log('batchDataList::', this.state.batchDataList);

			this.state.batchDataList.forEach(item => {
				data.push({
					deviceId: item.device_id,
					userId: item.userId
				});
			});
			const formData: any = {
				data: JSON.stringify(data),
				isAvailable: this.state.batchIsAvailable,
				event: this.state.reason
			};
			this.setState({
				confirmLoading: true
			});
			const res = await batchUpdeteKeyAvailable(formData);
			this.setState({
				confirmLoading: false
			});
			if (res.err === 0) {
				message.success('修改成功');
				this.setState({
					batchUpdateDJOrJDVisible: false,
					reason: ''
				});
				if (this.actionRef) {
					this.actionRef.reload();
				}
			} else {
				message.error('修改失败');
			}
		};
		const batchDjOrJd = (selectedRow: any, isAvailable) => {
			console.log('selectEdRow', selectedRow);
			this.setState({
				batchDataList: selectedRow,
				batchIsAvailable: isAvailable,
				batchUpdateDJOrJDVisible: true
			});
		};
		const keyListDelay = (selectedRow: any) => {
			console.log('selectedRow:::::', selectedRow);
			let deviceIds: any = [];
			let userEmails: any = [];
			selectedRow.map((item: any) => {
				deviceIds.push(item.device_id);
				userEmails.push(item.loginId);
			});
			this.setState({
				tempDeviceId: deviceIds.toString(),
				tempLoginId: this.email,
				batchYq: true,
				deviceOnekeyVisible: true
			});
		};
		const bathDeleteMapping = () => {
			let that = this;
			Modal.confirm({
				title: '提示',
				content: '您确定要删除该钥匙？',
				okType: 'danger',
				okText: '删除',
				onOk: async () => {
					let params = {
						sync: true,
						ids: this.state.selectedRowKeys.toString()
					};
					let rsp = await deleteMappingList(params);
					if (rsp.err === 0) {
						this.setState({
							selectedRowKeys: [],
							selectedRows: [],
							deleteLoading: true,
							deletePercent: 0
						});
						if (that.actionRef) {
							that.actionRef.reload();
						}
					} else {
						message.error(rsp.msg);
					}
				}
			});
		};
		const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
			let select = new Set();
			this.setState({
				selectedRowKeys: selectedRowKeys,
				selectedRows: selectedRows
			});
		};
		const rowSelection = {
			selectedRowKeys: this.state.selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange
			// selections: [
			// 	{
			// 		key: 'currentPage',
			// 		text: '全选当前页面',
			// 		onSelect: (changableRowKeys: any) => {
			// 			let select = new Set();
			// 			if (datas.length > 0) {
			// 				datas.map((item: any) => {
			// 					select.add(item.login_id);
			// 				});
			// 			} else {
			// 				select = new Set();
			// 			}
			// 			setSelectedRowKeys(changableRowKeys);
			// 			setSelectedRows(datas);
			// 		},
			// 	},
			// 	{
			// 		key: '"SELECT_ALL"',
			// 		text: '全选全部页面',
			// 		onSelect: (changableRowKeys: any) => {
			// 			setQunXuan(true);
			// 			setSelectedRows([]);
			// 			setSelectedRowKeys(changableRowKeys);
			// 		},
			// 	},
			// ],
		};
		return (
			<div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<NavLink to={'/zhineng-menjin/staff-list'}>
						<i className="icon icon-home">人员列表</i>
					</NavLink>
					<div className="active">&nbsp;&nbsp;&nbsp; 当前账号： {this.email}</div>
				</div>
				<GSTable
					columns={this.columns}
					actionRef={(ref: any) => (this.actionRef = ref)}
					searchBarRender={this.tableSearchBarRender()}
					rowKey="id"
					rowSelection={rowSelection}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {selectedRowKeys.length} 项
									<a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={() => {
						return (
							<Space size={16}>
								<a onClick={bathDeleteMapping}>删除</a>
								{<a onClick={() => keyListDelay(this.state.selectedRows)}>钥匙延期</a>}
								{<a onClick={() => batchDjOrJd(this.state.selectedRows, false)}>冻结</a>}
								{<a onClick={() => batchDjOrJd(this.state.selectedRows, true)}>解冻</a>}
								{/*<a onClick={sendSMS}>发送短信通知</a>*/}
								{/* <Button type={'link'} loading={isShow} onClick={expireData}>
									导出
								</Button> */}
							</Space>
						);
					}}
					operationsBarRender={this.tableOperationsBarRender()}
					request={async (params: any = {}, sort: any, filter: any) => {
						const res = await getDeviceByEmail({
							page: params.current,
							size: params.pageSize,
							search: this.search,
							email: this.email,
							mappingType: this.mappingType
						});
						let ddd = res.data.map((d: any) => {
							let expire = Date.parse(d.expire_time);
							let newDate = new Date(expire);
							let start = Date.parse(d.start_time);
							let startDate = new Date(start);
							let dev: LockUser = {
								isAvailable: d.isAvailable,
								loginId: d.loginId,
								nick_name: d.nickName,
								sendName: d.sendName,
								device_name: d.device_name,
								device_id: d.device_id,
								createTime: d.createTime,
								email: d.email,
								expire_time:
									d.expire_time === '9999-01-01 00:00:00'
										? '永久'
										: d.expire_time === null
										? ''
										: moment(d.expire_time).format('YYYY-MM-DD HH:mm'),
								id: d.id,
								on_off_rssi_limit: d.on_off_rssi_limit,
								sent_by: d.sent_by,
								sequence: d.sequence,
								start_time:
									d.start_time === '1111-01-01 00:00:00'
										? ''
										: d.start_time === null
										? ''
										: moment(d.start_time).format('YYYY-MM-DD HH:mm'),
								status: d.status,
								type: this.getType(d.types),
								wechat_bind: d.wechat_bind,
								needBindImsi: d.needBindImsi,
								needBindPhone: d.needBindPhone,
								bindNum: d.bindNum,
								sendNum: d.sendNum,
								isAutoFill: d.isAutoFill,
								faSongNum: d.faSongNum,
								userId: d.userId,
								needUserCertified: d.needUserCertified
							};
							return dev;
						});
						return {
							data: ddd,
							total: res.total,
							success: true
						};
					}}
				/>
				<Modal
					visible={(this.state as any).deviceOnekeyVisible}
					title="钥匙延期"
					onOk={this.keyDelay}
					onCancel={this.handleCancel}
					confirmLoading={this.state.confirmLoading}
				>
					<Row gutter={8}>
						<Col span={4} offset={4}>
							延期：
						</Col>
						<Col span={12}>
							<RadioGroup
								onChange={(value: any) => {
									console.log('value', value);
									this.setState({ showRow: value.target.value, delayDate: '' });
								}}
								defaultValue={false}
							>
								<Radio value={false}>
									<Tooltip overlay={'钥匙有效期在原有基础上延长一年'}>一年</Tooltip>
								</Radio>
								<Radio value={true}>自定义</Radio>
							</RadioGroup>
						</Col>
					</Row>
					<br/>
					{(this.state as any).showRow ? (
						<Row gutter={8} justify="center" align={'middle'}>
							<Col span={6}>有效期延长至：</Col>
							<Col span={12}>
								<DatePicker
									showTime={true}
									format="YYYY-MM-DD HH:mm:ss"
									placeholder="选择或者输入日期+时间"
									onChange={this.delayDate}
									getCalendarContainer={(value: any) => {
										return value;
									}}
								/>
							</Col>
						</Row>
					) : null}
				</Modal>

				{/*删除个人钥匙*/}
				<Modal
					visible={(this.state as any).deleteMyDeviceVisible}
					title="提示"
					onOk={this.deleteLock.bind(this)}
					onCancel={this.handleCancel}
					confirmLoading={this.state.confirmLoading}
				>
					<div className="modal-body">
						<p>您确认要删除该用户吗？</p>
					</div>
				</Modal>

				{/*删除所有钥匙*/}
				<Modal
					visible={(this.state as any).deleteDeviceVisible}
					title="提示"
					onOk={this.deleteDevice}
					onCancel={this.handleCancel}
					confirmLoading={this.state.confirmLoading}
				>
					<form id="formData" onSubmit={this.handleSubmit}>
						<div className="modal-body">
							<p>您确认要删除所有非管理员的钥匙？</p>
						</div>
					</form>
				</Modal>

				{/*发钥匙*/}
				<SendKeyModal
					visible={(this.state as any).sendDeviceVisible}
					onCancel={() => {
						this.setState({ sendDeviceVisible: false });
					}}
					callback={() => {
						if (this.actionRef) {
							this.actionRef.reload();
						}
					}}
					userId={this.email}
				/>

				{/*更多设置*/}
				<Modal
					visible={(this.state as any).updateVisible}
					title="操作"
					onOk={this.updataMapping}
					onCancel={() => {
						this.setState({ updateVisible: false });
					}}
				>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							设备名称:
						</Col>
						<Col span={6}>{this.state.deviceName}</Col>
					</Row>
					<br/>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							是否冻结:
						</Col>
						<Col span={6}>
							<Switch
								key={this.state.deviceId}
								checked={!this.state.isAvailable}
								onChange={(checked) => {
									this.setState({ isAvailable: !checked });
								}}
							/>
						</Col>
					</Row>
					<br/>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							是否需要绑定IMSI卡:
						</Col>
						<Col span={4}>
							<Switch
								key={this.state.deviceId}
								checked={this.state.needBindImsi}
								onChange={(checked) => {
									this.setState({ needBindImsi: checked });
								}}
							/>
						</Col>
					</Row>
					<br/>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							绑定时是否自动填写验证码:
						</Col>
						<Col span={4}>
							<Switch
								key={this.state.deviceId}
								checked={this.state.isAutoFill}
								onChange={(checked) => {
									this.setState({ isAutoFill: checked });
								}}
							/>
						</Col>
					</Row>
					<br/>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							是否需要绑定手机:
						</Col>
						<Col span={4}>
							<Switch
								key={this.state.deviceId}
								checked={this.state.needBindPhone}
								onChange={(checked) => {
									this.setState({ needBindPhone: checked });
								}}
							/>
						</Col>
					</Row>
					<br/>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							是否需要实名认证:
						</Col>
						<Col span={4}>
							<Switch
								key={this.state.deviceId}
								checked={this.state.needUserCertified}
								onChange={(checked) => {
									this.setState({ needUserCertified: checked });
								}}
							/>
						</Col>
					</Row>
					<br/>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							绑定次数:
						</Col>
						<Col span={6}>
							<InputNumber
								key={this.state.deviceId}
								min={1}
								max={10}
								defaultValue={this.state.bindNum}
								onChange={(value) => {
									this.setState({ bindNum: value });
								}}
							/>
						</Col>
					</Row>
					<br/>
					<Row gutter={8} justify={'start'} align={'middle'}>
						<Col offset={2} span={8}>
							临时钥匙次数:
						</Col>
						<Col span={6}>
							<InputNumber
								key={this.state.deviceId}
								min={1}
								max={10}
								defaultValue={this.state.sendNum}
								onChange={(value) => {
									this.setState({ sendNum: value });
								}}
							/>
						</Col>
					</Row>
				</Modal>
				<Modal
					title={
						'钥匙：' +
						this.state.item.device_name +
						(this.state.item.isAvailable ? ' 冻结' : '解冻') +
						'原因'
					}
					width={600}
					visible={this.state.keyRemarkVisible}
					onCancel={() => {
						this.setState({
							keyRemarkVisible: false
						});
					}}
					onOk={this.updateKeyAvailable}
				>
					<Input.TextArea
						value={this.state.reason}
						onChange={(e) => {
							this.setState({
								reason: e.target.value
							});
						}}
					/>
				</Modal>
				<Modal
					title={(this.state.batchIsAvailable ? '解冻' : '冻结')}
					width={600}
					confirmLoading={this.state.confirmLoading}
					visible={this.state.batchUpdateDJOrJDVisible}
					onCancel={() => {
						this.setState({
							batchUpdateDJOrJDVisible: false
						});
					}}
					onOk={batchJdOrDj}
				>
					<Row>
						<Col span={24}>
							<Input.TextArea
								value={this.state.reason}
								allowClear={true}
								autoSize={{ maxRows: 3, minRows: 3 }}
								onChange={(e) => {
									this.setState({
										reason: e.target.value
									});
								}}
								placeholder={'钥匙：' + (this.state.batchIsAvailable ? ' 解冻' : '冻结') + '原因'}
							/>
						</Col>
					</Row>
				</Modal>
			</div>
		);
	}
}

export default SingleLockUserList;

// helpers

/*function getExclamationMarks(numChars: number) {
    return Array(numChars + 1).join('!');
}*/
