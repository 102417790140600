import Api from '../data/API';

export async function changeStatus(params: any) {
	return Api.postWithAuth('/api/qunzu/change_status', params);
}

export async function groupList(params: any) {
	return Api.postWithAuth('/api/qunzu/group_list', params);
}

export async function delete_group(params: any) {
	return Api.postWithAuth('/api/qunzu/delete_group', params);
}

export async function add_group(params: any) {
	return Api.postWithAuth('/api/qunzu/add_group', params);
}
export async function update_group(params: any) {
	return Api.postWithAuth('/api/qunzu/group_update', params);
}

export async function update_group_picture(params: any) {
	return Api.postWithAuth('/api/qunzu/update_group_picture', params);
}

export async function add_tieba(params: any) {
	return Api.postWithAuth('/api/wenzhang/add_tieba', params);
}
export async function findListDongtai(params: any) {
	return Api.postWithAuth('/api/qunzu/find_list_dongtai', params);
}

export async function deleteTieZi(params: any) {
	return Api.postWithAuth('/api/qunzu/del_tiezi', params);
}

export async function getPinglunList(params: any) {
	return Api.postWithAuth('/api/pinglun/find_pinglun_list', params);
}

export async function findDongtaiDetails(params: any) {
	return Api.postWithAuth('/api/qunzu/find_dongtai_details', params);
}

export async function delPinglun(params: any) {
	return Api.postWithAuth('/api/pinglun/del_pinglun', params);
}

export async function addDongtai(params: any) {
	return Api.postWithAuth('/api/pinglun/del_pinglun', params);
}

export async function changeRole(params: any) {
	return Api.postWithAuth('/api/qunzu/change_role', params);
}

export async function addGroupUser(params: any) {
	return Api.postWithAuth('/api/qunzu/add_group_user', params);
}

export async function deleteGroupUser(params: any) {
	return Api.postWithAuth('/api/qunzu/delete_group_user', params);
}

export async function groupUserList(params: any) {
	return Api.postWithAuth('/api/qunzu/group_user_list', params);
}
