import React, {useEffect, useRef, useState} from 'react'
import {Button, Cascader, DatePicker, Form, Input, InputNumber, message, Modal, Select, Space, Switch, Tag} from "antd";
import  moment from "moment/moment";
import {
    addBeijingOfficeDemand, addBeijingOfficeTechDemand,
    deleteBeijingOfficeDemand, getAllBeijingOfficeOptionData,
    getBeijingOfficeDemandList, getCityList, updateBeijingOfficeDemand, updateBeijingOfficeTechDemand,
    updateStatusBeijingOfficeDemand
} from "../../../services/BeijingOfficeService";
import GSTable from "../../../components/GSTable";
import {ActionType} from "@ant-design/pro-table/lib/typing";
import AuthorizedView from "../../../util/AuthorizedView";
import CheckedComponents from "../../scenario/components/CheckedComponents";
import {getTreeSchemeTechList, getTreeSchemeTypeList} from "../../../services/scheme";
import CheckedDemandComponents from "../../scenario/components/CheckedDemandComponents";

const DemandList: React.FC = () => {
    const demandActionRef = useRef<ActionType>();
    const [search, setSearch] = useState("");
    const {CheckableTag} = Tag;
    const [addDemandForm] = Form.useForm();
    const [addTechDemandForm] = Form.useForm();
    const [updateDemandForm] = Form.useForm();
    const [updateTechDemandForm] = Form.useForm();
    const [status, setStatus] = useState('')
    const [updateDemandVisible, setUpdateDemandVisible] = useState(false);
    const [addDemandVisible, setAddDemandVisible] = useState(false);
    const [addTechDemandVisible, setAddTechDemandVisible] = useState(false);
    const [updateTechDemandVisible, setUpdateTechDemandVisible] = useState(false);
    const FormItem = Form.Item;
    const [typeList, setTypeList] = useState([])
    const [type, setType] = useState("项目需求")
    const [techList, setTechList] = useState([]);
    const [optionData,setOptionData] = useState([])
    const [cityOption, setCityOption] = useState([])

    useEffect(() => {
        getTypeList().then();
        getTechList().then()
        getBeijingOfficeOptionData().then()
        getCityListReq()

    }, [])
    const getCityListReq = async () => {
        let rsp = await getCityList({})
        if (rsp.err === 0) {
            console.log('data', rsp)
            setCityOption(rsp.data)
        }
    }
    const getBeijingOfficeOptionData = async () => {
        let rsp = await getAllBeijingOfficeOptionData({})
        if (rsp.err === 0) {
            setOptionData(rsp.data)
        }
    }
    const getTypeList = async () => {
        const rsp = await getTreeSchemeTypeList({});
        if (rsp.err === 0) {
            const data = rsp.data || [];
            setTypeList([...data]);
        }
    };

    const getTechList = async () => {
        const rsp = await getTreeSchemeTechList({});
        if (rsp.err === 0) {
            const data = rsp.data || [];
            setTechList([...data]);
        }
    };
    const demandColumns = [
        {
            title: '名称',
            dataIndex: 'projectName',
            key: 'projectName'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '需求',
            dataIndex: 'demand',
            ellipsis: true,
            key: 'demand'

        },
        {
            title: '需求单位',
            dataIndex: 'demandCompany',
            ellipsis: true,
            key: 'demandCompany'

        },
        {
            title: '需求单位简介',
            dataIndex: 'demandCompanyNote',
            ellipsis: true,
            key: 'demandCompanyNote'

        },
        {
            title: '联系人',
            dataIndex: 'contacts',
            key: 'contacts'
        },
        {
            title: '联系电话',
            dataIndex: 'contactsNumber',
            key: 'contactsNumber'
        },
        {
            title: '操作',
            key: 'action',
            render: (text: string, item: any) => {
                return <AuthorizedView needAuthority={"beijingOfficeManager:demand:updateStatus"}>
                    <Space>
                        <a onClick={() => {
                            setUpdateDemandVisible(true)
                            updateDemandForm.setFieldsValue({
                                ...item,
                                province: [item.province, item.city],
                                publishTime: moment(item.publishTime),
                                schemeType: item.schemeType3,
                                techType: item.techType3,
                                expectedStartTime: moment(item.expectedStartTime)
                            })
                        }}>编辑</a>
                        {
                            item.status === '待审核' &&
                            <>
                                <a onClick={() => updateStatusItem(item.uuid, "通过")}>通过</a>
                                <a onClick={() => updateStatusItem(item.uuid, "未通过")}>不通过</a>
                            </>
                        }
                        {
                            item.status === '未通过' && <>
                                <a onClick={() => updateStatusItem(item.uuid, "通过")}>通过</a>
                            </>
                        }
                        {
                            item.status === '通过' && <>
                                <a onClick={() => updateStatusItem(item.uuid, "未通过")}>不通过</a>
                            </>
                        }
                    </Space>
                </AuthorizedView>
            }
        }
    ];
    const demandTechColumns = [
        {
            title: '需求公司',
            dataIndex: 'demandCompany',
            key: 'demandCompany'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '技术需求描述',
            dataIndex: 'demand',
            ellipsis: true,
            key: 'demand'

        },
        {
            title: '项目背景',
            dataIndex: 'background',
            ellipsis: true,
            key: 'background'

        },
        {
            title: '联系人',
            dataIndex: 'contacts',
            key: 'contacts'
        },
        {
            title: '联系电话',
            dataIndex: 'contactsNumber',
            key: 'contactsNumber'
        },
        {
            title: '操作',
            key: 'action',
            render: (text: string, item: any) => {
                return <AuthorizedView needAuthority={"beijingOfficeManager:demand:updateStatus"}>
                    <Space>
                        <a onClick={() => {
                            setUpdateTechDemandVisible(true)
                            updateTechDemandForm.setFieldsValue({
                                ...item,
                                province: [item.province, item.city],
                                schemeType: (item.schemeType1 || '').split(','),
                                expectedStartTime: moment(item.expectedStartTime)
                            })
                        }}>编辑</a>
                        {
                            item.status === '待审核' &&
                            <>
                                <a onClick={() => updateStatusItem(item.uuid, "通过")}>通过</a>
                                <a onClick={() => updateStatusItem(item.uuid, "未通过")}>不通过</a>
                            </>
                        }
                        {
                            item.status === '未通过' && <>
                                <a onClick={() => updateStatusItem(item.uuid, "通过")}>通过</a>
                            </>
                        }
                        {
                            item.status === '通过' && <>
                                <a onClick={() => updateStatusItem(item.uuid, "未通过")}>不通过</a>
                            </>
                        }
                    </Space>
                </AuthorizedView>
            }
        }
    ];
    const updateStatusItem =  (uuid: string, status: string) => {
        Modal.confirm({
            title: '提示',
            content: '您确实要' + status + "吗？",
            // okType: "danger",
            okText: "确定",
            cancelText: "取消",
            onOk: async  () => {
                let rsp = await updateStatusBeijingOfficeDemand({uuid, status})
                if (rsp.err !== 0) {
                    message.error(rsp.msg)
                }
                if (demandActionRef.current) {
                    demandActionRef.current.reload()
                }
            }
        })

    }
    const addDemand = () => {
        addDemandForm.validateFields().then(async (data: any) => {
            const res = await addBeijingOfficeDemand({
                ...data,
                province: (data.province || ['',''])[0],
                city: (data.province || ['',''])[1],
                publishTime: data.publishTime.format('YYYY-MM-DD'),
                expectedStartTime: data.expectedStartTime.format('YYYY-MM-DD')
            });
            if (res.err === 0) {
                message.success('保存成功');
                setAddDemandVisible(false)
                demandActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const addTechDemand = async () => {
        let data = await addTechDemandForm.validateFields();
        const res = await addBeijingOfficeTechDemand({
            ...data,
            province: (data.province || ['',''])[0],
            city: (data.province || ['',''])[1],
            schemeType: data.schemeType.toString(),
            expectedStartTime: data.expectedStartTime.format('YYYY-MM-DD')
        });
        if (res.err === 0) {
            message.success('保存成功');
            setAddTechDemandVisible(false)
            demandActionRef.current.reload();
        } else {
            message.error(res.msg);
        }
    }
    const updateDemand = () => {
        updateDemandForm.validateFields().then(async (data: any) => {
            const res = await updateBeijingOfficeDemand({
                ...data,
                province: (data.province || ['',''])[0],
                city: (data.province || ['',''])[1],
                publishTime: data.publishTime.format('YYYY-MM-DD'),
                expectedStartTime: data.expectedStartTime.format('YYYY-MM-DD')
            });
            if (res.err === 0) {
                message.success('保存成功');
                setUpdateDemandVisible(false)
                demandActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    const updateTechDemand = () => {
        updateTechDemandForm.validateFields().then(async (data: any) => {
            const res = await updateBeijingOfficeTechDemand({
                ...data,
                province: (data.province || ['',''])[0],
                city: (data.province || ['',''])[1],
                schemeType: data.schemeType.toString(),
                expectedStartTime: data.expectedStartTime.format('YYYY-MM-DD')
            });
            if (res.err === 0) {
                message.success('保存成功');
                setUpdateTechDemandVisible(false)
                demandActionRef.current.reload();
            } else {
                message.error(res.msg);
            }
        });
    };
    return <>
        <GSTable
            columns={type === '项目需求'? demandColumns : demandTechColumns }
            actionRef={demandActionRef}
            operationsBarRender={[<Button type={"primary"} onClick={() => {
                setAddDemandVisible(true)
                addDemandForm.resetFields()
            }
            }>添加项目需求</Button>, <Button type={"primary"} onClick={() => {
                setAddTechDemandVisible(true)
                addTechDemandForm.resetFields()
            }
            }>添加技术需求</Button>]}
            searchBarRender={[
                <Select value={type} style={{width: 100}} onChange={(e:string) => {
                    setType(e)
                    demandActionRef.current.reloadAndRest();

                }}>
                    <Select.Option value={'项目需求'}>项目需求</Select.Option>
                    <Select.Option value={'技术需求'}>技术需求</Select.Option>
                </Select>,
                <CheckableTag
                key={'1'}
                className="faceTag"
                checked={status === ''}
                onChange={() => {
                    setStatus("")
                }}
            >
                全部
            </CheckableTag>, <CheckableTag
                key={'1'}
                className="faceTag"
                checked={status === '待审核'}
                onChange={() => {
                    setStatus("待审核")
                }}
            >
                待审核
            </CheckableTag>,
                <CheckableTag
                    key={'2'}
                    className="faceTag"
                    checked={status === '通过'}
                    onChange={() => {
                        setStatus("通过")
                    }}
                >
                    通过
                </CheckableTag>,
                <CheckableTag
                    key={'3'}
                    className="faceTag"
                    checked={status === '未通过'}
                    onChange={() => {
                        setStatus("未通过")
                    }}
                >
                    未通过
                </CheckableTag>, <Space>


                    <Input
                        placeholder="搜索"
                        onChange={(event) => {
                            setSearch(event.target.value);

                        }}
                    />
                    <Button type={"primary"} onClick={() => {
                        if (demandActionRef.current) {
                            demandActionRef.current.reloadAndRest();
                        }
                    }}>查询</Button>
                </Space>]}
            request={async (params: any = {}, sort: any, filter: any) => {
                let res = await getBeijingOfficeDemandList({
                    Search: search,
                    status: status,
                    type: type,
                    page: params.current,
                    size: params.pageSize,
                });
                return {
                    data: res.data,
                    total: res.total,
                    success: true,
                };
            }}
        />
        <Modal title={"添加需求"} width={1200} destroyOnClose={true} visible={addDemandVisible} onOk={addDemand}
               onCancel={() => {
                   setAddDemandVisible(false)
               }}>
            <Form labelAlign={'left'} labelCol={{span: 3}} form={addDemandForm}>
                <FormItem label={'城市名片'} name={'beijingOfficeUuid'}
                          rules={[{required: false, message: "请输入城市名片"}]}>
                    <Select>
                        {
                            optionData.map((item: any ) => {
                                return  <Select.Option value={item.uuid} key={item.uuid}>
                                    {item.name}
                                </Select.Option>
                            })
                        }

                    </Select>
                </FormItem>
                <FormItem label={'项目名称'} name={'projectName'}
                          rules={[{required: true, message: "请输入项目名称"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'需求描述'} name={'demand'} rules={[{required: true, message: "请输入需求描述"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'功能要求'} name={'functionalRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'主要指标'} name={'mainIndex'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'其他要求'} name={'otherRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'发布时间'} name={'publishTime'}
                          rules={[{required: true, message: "请输入发布时间"}]}>
                    <DatePicker/>
                </FormItem>
                <FormItem label={'预期启动时间'} name={'expectedStartTime'}
                          rules={[{required: true, message: "请输入预期启动时间"}]}>
                    <DatePicker/>
                </FormItem>
                <FormItem label={'预计建设周期'} name={'constructionCycle'}
                          rules={[{required: true, message: "请输入预计建设周期"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'投资预算(万元)'} name={'budget'}
                          rules={[{required: false, message: "请输入预计建设周期"}]}>
                    <InputNumber/>
                </FormItem>
                <FormItem label={'数量规模'} name={'quantityAndScale'}
                          rules={[{required: true, message: "请输入数量规模"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'需求单位'} name={'demandCompany'}
                          rules={[{required: true, message: "请输入需求单位"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'需求单位简介'} name={'demandCompanyNote'}
                          rules={[{required: true, message: "请输入需求单位简介"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'应用场景分类'} name={'schemeType'}
                          rules={[{required: true, message: "请输入应用场景分类"}]}>
                    <CheckedDemandComponents
                        key={typeList.toString()}
                        dataList={typeList}
                    ></CheckedDemandComponents>
                </FormItem>
                <FormItem label={'技术分类'} name={'techType'}
                          rules={[{required: true, message: "请输入技术分类"}]}>
                    <CheckedDemandComponents
                        key={techList.toString()}
                        dataList={techList}
                    ></CheckedDemandComponents>
                </FormItem>
                <FormItem label={'省市'} name={'province'} rules={[{required: false, message: "请输入省市"}]}>
                    <Cascader options={cityOption}  />
                </FormItem>
                <FormItem label={'详细地址'} name={'address'}
                          rules={[{required: false, message: "请输入详细地址"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'职务'} name={'post'} rules={[{required: false, message: "请输入职务"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系电话'} name={'contactsNumber'}
                          rules={[{required: true, message: "请输入联系电话"}]}>
                    <Input/>
                </FormItem>
            </Form>
        </Modal>
        <Modal title={"修改需求"} width={1200} destroyOnClose={true} visible={updateDemandVisible}
               onOk={updateDemand} onCancel={() => {
            setUpdateDemandVisible(false)
        }}>
            <Form labelAlign={'left'} labelCol={{span: 3}} form={updateDemandForm}>
                <FormItem label={'城市名片'} name={'beijingOfficeUuid'}
                          rules={[{required: false, message: "请输入城市名片"}]}>
                  <Select>
                      {
                          optionData.map((item: any ) => {
                              return  <Select.Option value={item.uuid} key={item.uuid}>
                                  {item.name}
                              </Select.Option>
                          })
                      }

                  </Select>
                </FormItem>
                <FormItem label={'uuid'} hidden={true} name={'uuid'}>
                    <Input/>
                </FormItem>
                <FormItem label={'项目名称'} name={'projectName'}
                          rules={[{required: true, message: "请输入项目名称"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'需求描述'} name={'demand'} rules={[{required: true, message: "请输入需求描述"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'功能要求'} name={'functionalRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'主要指标'} name={'mainIndex'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'其他要求'} name={'otherRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'发布时间'} name={'publishTime'}
                          rules={[{required: true, message: "请输入发布时间"}]}>
                    <DatePicker/>
                </FormItem>
                <FormItem label={'预期启动时间'} name={'expectedStartTime'}
                          rules={[{required: true, message: "请输入预期启动时间"}]}>
                    <DatePicker/>
                </FormItem>
                <FormItem label={'预计建设周期'} name={'constructionCycle'}
                          rules={[{required: true, message: "请输入预计建设周期"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'投资预算(万元)'} name={'budget'}
                          rules={[{required: false, message: "请输入预计建设周期"}]}>
                    <InputNumber/>
                </FormItem>
                <FormItem label={'数量规模'} name={'quantityAndScale'}
                          rules={[{required: true, message: "请输入数量规模"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'需求单位'} name={'demandCompany'}
                          rules={[{required: true, message: "请输入需求单位"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'需求单位简介'} name={'demandCompanyNote'}
                          rules={[{required: true, message: "请输入需求单位简介"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'应用场景分类'} name={'schemeType'}
                          rules={[{required: true, message: "请输入应用场景分类"}]}>
                    <CheckedDemandComponents
                        key={typeList.toString()}
                        dataList={typeList}
                    ></CheckedDemandComponents>
                </FormItem>
                <FormItem label={'技术分类'} name={'techType'}
                          rules={[{required: true, message: "请输入技术分类"}]}>
                    <CheckedDemandComponents
                        key={techList.toString()}
                        dataList={techList}
                    ></CheckedDemandComponents>
                </FormItem>
                <FormItem label={'省市'} name={'province'} rules={[{required: false, message: "请输入省市"}]}>
                    <Cascader options={cityOption}  />
                </FormItem>
                <FormItem label={'详细地址'} name={'address'}
                          rules={[{required: false, message: "请输入详细地址"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'职务'} name={'post'} rules={[{required: false, message: "请输入职务"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系电话'} name={'contactsNumber'}
                          rules={[{required: true, message: "请输入联系电话"}]}>
                    <Input/>
                </FormItem>
            </Form>
        </Modal>
        <Modal title={"添加技术需求"} width={1200} destroyOnClose={true} visible={addTechDemandVisible} onOk={addTechDemand}
               onCancel={() => {
                   setAddTechDemandVisible(false)
               }}>
            <Form labelAlign={'left'} labelCol={{span: 4}} form={addTechDemandForm}>
                <FormItem label={'需求企业名称'} name={'demandCompany'}
                          rules={[{required: true, message: "请输入需求企业名称"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'项目背景'} name={'background'} rules={[{required: false, message: "请输入项目背景"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'需求领域'} name={'schemeType'}
                          rules={[{required: true, message: "请选择需求领域"}]}>
                   <Select mode={"multiple"}>
                       {
                           typeList.map((item: any) => {
                               return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                           })
                       }
                   </Select>
                </FormItem>
                <FormItem label="技术需求描述" name={"demand"} rules={[{required: true, message: "请填写需求"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'功能要求'} name={'functionalRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'主要指标'} name={'mainIndex'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'其他要求'} name={'otherRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'需求期限'} name={'expectedStartTime'}
                          rules={[{required: true, message: "请输入需求期限"}]}>
                    <DatePicker/>
                </FormItem>
                <FormItem label={'预期投入资金(万元)'} name={'budget'}
                          rules={[{required: false, message: "请输入预计建设周期"}]}>
                    <InputNumber/>
                </FormItem>
                <FormItem initialValue={false} valuePropName={"checked"} label={'是否需要专利'} name={'isPatent'}
                          rules={[{required: false, message: "请输入是否需要专利"}]}>
                    <Switch/>
                </FormItem>

                <FormItem  label={'合作方式'} name={'cooperationMe'}
                          rules={[{required: true, message: "请选择合作方式"}]}>
                    <Select>
                        <Select.Option value={"购买"}>购买</Select.Option>
                        <Select.Option value={"技术转让许可"}>技术转让许可</Select.Option>
                        <Select.Option value={"技术作价入股"}>技术作价入股</Select.Option>
                        <Select.Option value={"合作转换"}>合作转换</Select.Option>
                        <Select.Option value={"其它"}>其它</Select.Option>
                    </Select>
                </FormItem>
                <FormItem label={'技术指标'} name={'technicalIndex'}  rules={[{required: true, message: "请输入技术指标"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'省市'} name={'province'} rules={[{required: false, message: "请输入省市"}]}>
                    <Cascader options={cityOption}  />
                </FormItem>
                <FormItem label={'详细地址'} name={'address'}
                          rules={[{required: false, message: "请输入详细地址"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'职务'} name={'post'} rules={[{required: false, message: "请输入职务"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系电话'} name={'contactsNumber'}
                          rules={[{required: true, message: "请输入联系电话"}]}>
                    <Input/>
                </FormItem>
            </Form>
        </Modal>
        <Modal title={"修改技术需求"} width={1200} destroyOnClose={true} visible={updateTechDemandVisible} onOk={updateTechDemand}
               onCancel={() => {
                   setAddTechDemandVisible(false)
               }}>
            <Form labelAlign={'left'} labelCol={{span: 4}} form={updateTechDemandForm}>
                <FormItem label={'uuid'} hidden={true} name={'uuid'}>
                    <Input/>
                </FormItem>
                <FormItem label={'需求企业名称'} name={'demandCompany'}
                          rules={[{required: true, message: "请输入需求企业名称"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'项目背景'} name={'background'} rules={[{required: false, message: "请输入项目背景"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'需求领域'} name={'schemeType'}
                          rules={[{required: true, message: "请选择需求领域"}]}>
                    <Select mode={"multiple"}>
                        {
                            typeList.map((item: any) => {
                                return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </FormItem>
                <FormItem label="技术需求描述" name={"demand"} rules={[{required: true, message: "请填写需求"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'功能要求'} name={'functionalRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'主要指标'} name={'mainIndex'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'其他要求'} name={'otherRequirements'} >
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'需求期限'} name={'expectedStartTime'}
                          rules={[{required: true, message: "请输入需求期限"}]}>
                    <DatePicker/>
                </FormItem>
                <FormItem label={'预期投入资金(万元)'} name={'budget'}
                          rules={[{required: false, message: "请输入预计建设周期"}]}>
                    <InputNumber/>
                </FormItem>
                <FormItem initialValue={false} valuePropName={"checked"} label={'是否需要专利'} name={'isPatent'}
                          rules={[{required: false, message: "请输入是否需要专利"}]}>
                    <Switch/>
                </FormItem>

                <FormItem  label={'合作方式'} name={'cooperationMethods'}
                           rules={[{required: true, message: "请选择合作方式"}]}>
                    <Select>
                        <Select.Option value={"购买"}>购买</Select.Option>
                        <Select.Option value={"技术转让许可"}>技术转让许可</Select.Option>
                        <Select.Option value={"技术作价入股"}>技术作价入股</Select.Option>
                        <Select.Option value={"合作转换"}>合作转换</Select.Option>
                        <Select.Option value={"其它"}>其它</Select.Option>
                    </Select>
                </FormItem>
                <FormItem label={'技术指标'} name={'technicalIndex'}  rules={[{required: true, message: "请输入技术指标"}]}>
                    <Input.TextArea/>
                </FormItem>
                <FormItem label={'省市'} name={'province'} rules={[{required: false, message: "请输入省市"}]}>
                    <Cascader options={cityOption}  />
                </FormItem>
                <FormItem label={'详细地址'} name={'address'}
                          rules={[{required: false, message: "请输入详细地址"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系人'} name={'contacts'} rules={[{required: true, message: "请输入联系人"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'职务'} name={'post'} rules={[{required: false, message: "请输入职务"}]}>
                    <Input/>
                </FormItem>
                <FormItem label={'联系电话'} name={'contactsNumber'}
                          rules={[{required: true, message: "请输入联系电话"}]}>
                    <Input/>
                </FormItem>
            </Form>
        </Modal>
    </>
}
export default DemandList;
