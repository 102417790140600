import Api from '../data/API';
import { async } from 'q';
import { func } from 'prop-types';
export async function getGuardFeedback(params: any) {
	return Api.postWithAuth('/get_guard_feedback', params);
}

export async function getDeviceGroup(params: any) {
	return Api.postWithAuth('/api/device/getDeviceGroup', params);
}

export async function deviceBind(params: any) {
	return Api.postWithAuth('/api/device/device_bind', params);
}

export async function getDeviceGroupMapping(params: any) {
	return Api.postWithAuth('/api/device/getDeviceGroupMapping', params);
}

export async function updateDeviceGroup(params: any) {
	return Api.postWithAuth('/api/device/updateDeviceGroup', params);
}

export async function deleteDeviceGroup(params: any) {
	return Api.postWithAuth('/api/device/deleteDeviceGroup', params);
}

export async function getDevices(params: any) {
	return Api.postWithAuth('/api/device/getDevices', params);
}
export async function getAllDevicesGroup(params: any) {
	return Api.postWithAuth('/api/device/getDeviceListLiuChengDan', params);
}
export async function getDevicesByAll(params: any) {
	return Api.postWithAuth('/admin/api/device/getDeviceDList', params);
}
export async function getUserDevicesWithUseKeys0(params: any) {
	return Api.postWithAuth('/api/user/get_user_devices_withUseKeys0', params);
}
export async function getUserDeviceStateCount(params: any) {
	return Api.postWithAuth('/api/user/getUserDeviceStateCount', params);
}


export async function copyDevice(params: any) {
	return Api.postWithAuth('/api/device/copy_device', params);
}

export async function getAllRecord(params: any) {
	return Api.postWithAuth('/api/getAllRecord', params);
}
export async function getOpenDoorRecords(params: any) {
	return Api.postWithAuth('/api/getOpenDoorRecords', params);
}
export async function getOpenDoorTopData(params: any) {
	return Api.postWithAuth('/api/getOpenDoorTopData', params);
}
export async function getEquipmentRecords(params: any) {
	return Api.postWithAuth('/api/getEquipmentRecords', params);
}
export async function getAuthRecord(params: any) {
	return Api.postWithAuth('/api/AuthRecord', params);
}
export async function getAuthRecordTop( params: any) {
	return Api.postWithAuth('/api/authRecordTop', params)
}

export async function getUserDevice(params: any) {
	return Api.postWithAuth('/api/device/get_user_device', params);
}

export async function deletKey(params: any) {
	return Api.postWithAuth('/delete_key', params);
}

export async function keyDelay(params: any) {
	return Api.postWithAuth('/api/wuye/key_delay', params);
}
export async function batchKeyDelay(params: any) {
	return Api.postWithAuth('/api/wuye/batchKeyDelay', params);
}

export async function sendKeys(params: any) {
	return Api.postWithAuth('/send_keys', params);
}

export async function updateMapping(params: any) {
	return Api.postWithAuth('/api/device/update_mapping', params);
}

export async function getMappingInfo(params: any) {
	return Api.postWithAuth('/api/device/getMappingInfo', params);
}
export async function updeteKeyAvailable(params: any) {
	return Api.postWithAuth('/api/device/updeteKeyAvailable', params);
}

export async function freezeMapping(params: any) {
	return Api.postWithAuth('/api/device/freeze_mapping', params);
}

export async function unfreezeMapping(params: any) {
	return Api.postWithAuth('/api/device/unfreeze_mapping', params);
}

export async function sendKeySms(params: any) {
	return Api.postWithAuth('/api/device/sendKeySms', params);
}

export async function sendKeyKson(params: any) {
	return Api.postWithAuth('/send_keys_json', params);
}

export async function getUserDevicesWithUseKeys(params: any) {
	return Api.postWithAuth('/api/user/get_user_devices_withUseKeys', params);
}

export async function getDeviceByEmail(params: any) {
	return Api.postWithAuth('/api/device/get_device_by_email', params);
}

export async function deleteUserAllKey(params: any) {
	return Api.postWithAuth('/delete_user_all_key', params);
}

export async function userDeviceDelay(params: any) {
	return Api.postWithAuth('/api/wuye/user_device_delay', params);
}

export async function getSpecialAttentionList(params: any) {
	return Api.postWithAuth('/api/attention/get_special_attention_list', params);
}

export async function addSpecialAttention(params: any) {
	return Api.postWithAuth('/api/attention/add_special_attention', params);
}

export async function setNoticeCheck(params: any) {
	return Api.postWithAuth('/api/attention/set_notice_check', params);
}

export async function updateSpecialAttention(params: any) {
	return Api.postWithAuth('/api/attention/update_special_attention', params);
}

export async function deleteSpecialAttention(params: any) {
	return Api.postWithAuth('/api/attention/delete_special_attention', params);
}

export async function staffList(params: any) {
	return Api.postWithAuth('/api/device/staff_list', params);
}

export async function getKeyRequestUser(params: any) {
	return Api.postWithAuth('/api/wuye/get_key_request_user', params);
}

export async function getWuyeDeviceRole(params: any) {
	return Api.postWithAuth('/api/wuye/get_wuye_device_role', params);
}

export async function deleteAllKey(params: any) {
	return Api.postWithAuth('/delete_all_key', params);
}

export async function asyncFaceDeviceImage(params: any) {
	return Api.postWithAuth('/admin/api/ekey/asyncFaceDeviceImage', params);
}

export async function setFaceParams(params: any) {
	return Api.postWithAuth('/api/device/setFaceDeviceParam', params);
}

export async function updateDeviceStaff(params: any) {
	return Api.postWithAuth('/api/device/updateDeviceStaff', params);
}

export async function add2GDevice(params: any) {
	return Api.postWithAuth('/api/device/add2GDevice', params);
}
export async function deleteDeviceList(params: any) {
	return Api.postWithAuth("/admin/api/device/delete_device", params);
}
export async function deleteMappingList(params: any) {
	return Api.postWithAuth("/admin/api/ekey/delectMappingList", params);
}
export async function getKeyEventNote(params: any) {
	return Api.postWithAuth("/admin/api/ekey/getKeyEventNote ", params);
}
export async function sendKeyByEmp(params: any) {
	return Api.postWithAuth("/send_keys_emp", params);
}

export async function getMappingList(params: any) {
	return Api.postWithAuth('/api/device/get_mapping_list', params)
}

export async function getDeviceGroupList(params: any) {
	return Api.postWithAuth("/admin/api/device/getDeviceGroupList", params)
}

export async function sortDeviceGroup(params: any) {
	return Api.postWithAuth("/admin/api/device/sortDeviceGroup", params)
}

export async function getCompanyEmpData(params: any) {
	return Api.postWithAuth('/admin/api/company/getCompanyEmpData', params)
}

export async function batchUpdateEKey(params: any) {
	return Api.postWithAuth('/admin/api/ekey/batchUpdateEKey', params)
}
export async function getMappingData(params: any) {
	return Api.postWithAuth('/api/device/get_mapping_Data', params)
}

export async function batchDeleteEkey(params: any) {
	return Api.postWithAuth('/admin/api/ekey/batchDeleteEKey', params)
}

export async function getUserData(params: any) {
	return Api.postWithAuth('/api/device/staff_data', params)
}
export async function cpEkey(params: any) {
	return Api.postWithAuth('/api/op/face_device/cpekey', params)
}
export async function getEquipmentStatus(params: any) {
	return Api.postWithAuth("/api/getEquipmentStatusData", params)
}
export async function getAllDeviceList(params: any) {
	return Api.postWithAuth("/api/device/getAllDeviceList", params)
}
export async function getProjSendKeyConfig(params: any) {
	return Api.postWithAuth("/admin/api/ekey/getProjSendKeyConfig", params);
}
export async function batchUpdeteKeyAvailable(params: any) {
	return Api.postWithAuth("/api/device/batchUpdeteKeyAvailable", params);
}

export async function getExitSlipList(params: any) {
	return Api.postWithAuth('/adminapi/exit_slip/getExitSlipList', params);
}
