import * as React from 'react';
import DAl, { default as DAL } from '../../../data/DAL';
import API from '../../../data/API';
import { Breadcrumb, Button, Input, Modal, Pagination, Table } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import GSTable from '../../../components/GSTable';
import {
	addDongtai,
	addGroupUser,
	changeRole,
	deleteGroupUser,
	findListDongtai,
	groupUserList,
} from '../../../services/GroupService';
var msg = require('../../../util/SuspensionMsg');
const Search = Input.Search;
const confirm = Modal.confirm;
class GroupUser extends React.Component {
	dal = DAL.dal;
	findList2 = [];
	search = '';
	columns = [
		{
			title: '昵称',
			dataIndex: 'nickname',
			key: 'nickname',
		},
		{
			title: '帐号',
			key: 'email',
			dataIndex: 'email',
		},
		{
			title: '加入时间',
			key: 'joinTime',
			dataIndex: 'joinTime',
		},
		{
			title: '身份',
			key: 'role',
			dataIndex: 'role',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<a hidden={record.role === '群主'} onClick={this.getEmail.bind(this, record)}>
								删除成员
							</a>
							{/*<em className="ant-list-item-action-split" />*/}
						</li>
						<li>
							<a hidden={record.role === '群主'} onClick={this.changeRole.bind(this, record)}>
								{record.msg}
							</a>
						</li>
					</ul>
				</span>
			),
		},
	];

	state: any;
	actionRef: any;
	constructor(props: any) {
		super(props);
		this.state = {
			isShow: true,
			users: [],
			email: '',
			uuid: '',
			msg: '',
			zhuti: '',
			group_uuid: '',
			findList: [],
			fabuRenyuan: '',
			fabuShijian: '',
			qunNum: '',
			isHidde: true,
			total: 0,
			pageSize: 15,
			pageNumber: 1,
			visible: false,
		};
		this.getEmail = this.getEmail.bind(this);
		this.addGroupUser = this.addGroupUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.changeRole = this.changeRole.bind(this);
		this.getZhuti = this.getZhuti.bind(this);
		this.getDongtai = this.getDongtai.bind(this);
		this.getFabushijian = this.getFabushijian.bind(this);
		this.getFabuRenyuan = this.getFabuRenyuan.bind(this);
		this.getQunNum = this.getQunNum.bind(this);
		this.callbackFenye = this.callbackFenye.bind(this);
	}

	getDongtai = async (res: any) => {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		let sta = this.state as any;
		if (sta.zhuti.length === 0) {
			msg.suspensionMsg({ content: '内容不能为空', type: 'danger' });
			return;
		}

		const ress = await addDongtai({
			group_uuid: uuid,
		});

		// API.postWithAuth('/api/qunzu/add_dongtai' , {
		//     group_uuid: uuid
		// }).then(ress => {
		if (ress.err === 0) {
			this.setState({
				wenzhang: ress.data,
				isShow: false,
			});
			msg.suspensionMsg({ content: '发布成功!', type: 'success' });
			this.setState({
				zhuti: '',
			});
		} else {
			msg.suspensionMsg({ content: ress.msg });
		}
		// });
	};

	getZhuti(ev: any) {
		console.log('内容', ev.target.value);

		this.setState({
			zhuti: ev.target.value,
		});
	}

	getFabushijian(ev: any) {
		this.setState({
			fabuShijian: ev.target.value,
		});
	}

	getFabuRenyuan(ev: any) {
		this.setState({
			fabuRenyuan: ev.target.value,
		});
	}

	getDatas = async () => {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];

		const res = await findListDongtai({
			group_uuid: uuid,
		});

		// API.postWithAuth('/api/qunzu/find_list_dongtai' , {
		//     group_uuid: uuid
		// }).then(res => {
		if (res.err === 0) {
			this.setState({
				findList: res.data,
				isShow: false,
			});
			this.findList2 = res.data;
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
		// });
	};

	componentDidMount() {
		this.getUuid();
		this.getDatas();
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		console.log(uuid);

		// this.callbackFenye((this.state as any).pageNumber, (this.state as any).pageSize);
	}

	getUuid() {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		this.setState({
			qunName: decodeURI(c[3]),
		});
		return uuid;
	}

	callbackFenye(e: any, size: number) {
		API.postWithAuth('/api/qunzu/group_user_list', {
			group_uuid: this.getUuid(),
			uuid: this.getUuid(),
			page: e,
			Search: this.search,
			size: size,
		}).then((res) => {
			if (res.err !== 0) {
				msg.suspensionMsg({ content: res.msg });
				return;
			}
			this.setState({
				users: res.data,
				isShow: false,
				total: res.count,
			});
		});
	}

	changeRole = async (ev: any) => {
		if (ev.role === '管理员') {
			ev.role = '普通成员';
			ev.msg = '设为管理员';
		} else {
			ev.role = '管理员';
			ev.msg = '降为普通';
		}
		this.setState({
			user: ev,
		});

		const res = await changeRole({
			msg: ev.msg,
			email: ev.email,
			uuid: ev.uuid,
		});
		// API.postWithAuth('/api/qunzu/change_role' , {
		//     msg: ev.msg,
		//     email: ev.email,
		//     uuid: ev.uuid,
		// }).then(res => {
		if (res.err === 0) {
			return res.err;
		} else {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// });
	};

	getEmail(ev: any) {
		this.setState({
			email: ev.email,
		});
		this.showConfirm();
	}

	getEmail1 = (ev: any) => {
		this.setState({
			email: ev.target.value,
		});
	};

	showConfirm = () => {
		let that = this;
		confirm({
			title: '您确认要删除该成员吗？',
			okType: 'danger',
			onOk() {
				that.deleteUser();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	getQunNum(ev: any) {
		this.setState({
			qunNum: ev.target.value,
		});
	}

	addGroupUser = async () => {
		const res = await addGroupUser({
			email: (this.state as any).email,
			uuid: this.getUuid(),
		});
		// API.postWithAuth('/api/qunzu/add_group_user' , {
		//     email: (this.state as any).email,
		//     uuid: this.getUuid()
		// }).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: res.msg, type: 'success' });
			this.actionRef.reload();
			// this.callbackFenye((this.state as any).pageNumber, (this.state as any).pageSize);
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
		this.handleCancel();
		// });
	};

	deleteUser = async () => {
		const res = await deleteGroupUser({
			email: (this.state as any).email,
			uuid: this.getUuid(),
		});
		// API.postWithAuth('/api/qunzu/delete_group_user' , {
		//     email: (this.state as any).email,
		//     uuid: this.getUuid()
		// }).then(res => {
		if (res.err === 0) {
			this.actionRef.reload();
			msg.suspensionMsg({ content: res.msg, type: 'success' });

			// this.callbackFenye(1, (this.state as any).pageSize);
		} else {
			this.actionRef.reload();
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// });
	};

	onPageChange = (page: number, pageSize: number) => {
		this.setState({
			pageNumber: page,
		});
		window.location.hash = `#${page}`;
		this.callbackFenye(page, pageSize);
	};
	getSearch = (search: string) => {
		this.search = search;
		this.actionRef.reload();
		// this.callbackFenye(1, 15);
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = () => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	tableSearchBarRender = () => [
		<Input
			placeholder="搜索"
			onChange={(event) => {
				// this.getSearch(value);
				this.search = event.target.value;

			}}
		/>,
		<Button type={"primary"} onClick={() => {
			this.actionRef.reload();
		}}>查询</Button>
	];

	tableOperationsBarRender = () => [
		<Button type="primary" onClick={this.showModal}>
			邀请新成员
		</Button>,
	];

	render() {
		return (
			<div>
				<Breadcrumb>
					<Breadcrumb.Item>
						<a href="/group/group-list">群组列表</a>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{(this.state as any).qunName}</Breadcrumb.Item>
				</Breadcrumb>

				<div>
					<GSTable
						columns={this.columns}
						actionRef={(ref: any) => (this.actionRef = ref)}
						searchBarRender={this.tableSearchBarRender()}
						operationsBarRender={this.tableOperationsBarRender()}
						request={async (params = {}, sort, filter) => {
							const res = await groupUserList({
								page: params.current,
								size: params.pageSize,
								group_uuid: this.getUuid(),
								uuid: this.getUuid(),
								Search: this.search,
							});
							// let res = await API.postWithAuth(url, {
							//     page: params.current,
							//     size: params.pageSize,
							//     group_uuid: this.getUuid(),
							//     uuid: this.getUuid(),
							//     Search: this.search,
							// });
							return {
								data: res.data,
								total: res.count,
								success: true,
							};
						}}
					/>

					{/*<Table columns={this.columns} size="middle"  dataSource={(this.state as any).users} loading={(this.state as any).loading} pagination={false}/>*/}
					{/*<br/>*/}

					{/*<Pagination*/}
					{/*    className="pagination-com"*/}
					{/*    showQuickJumper={true}*/}
					{/*    defaultCurrent={(this.state as any).pageNumber}*/}
					{/*    current={(this.state as any).pageNumber}*/}
					{/*    total={(this.state as any).total}*/}
					{/*    pageSize={(this.state as any).pageSize}*/}
					{/*    onChange={this.onPageChange}*/}
					{/*    showTotal={(e) => { return '共' + e + '项'; }}*/}
					{/*    showSizeChanger={true}*/}
					{/*    pageSizeOptions={DAl.pageSize}*/}
					{/*    onShowSizeChange={(current, size) => {*/}
					{/*        this.setState({*/}
					{/*            pageSize: size*/}
					{/*        });*/}
					{/*        this.callbackFenye(1, size);*/}
					{/*    }}*/}
					{/*/>*/}

					<Modal
						title="邀请新成员"
						visible={this.state.visible}
						onOk={this.addGroupUser}
						onCancel={this.handleCancel}
					>
						<div className="modal-body">
							<div className="input-control has-icon-left ">
								{/*<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} onInput={this.getEmail1} type="text" placeholder="请输入邮箱或手机号"/>*/}
								<Input
									prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
									onInput={this.getEmail1}
									type="text"
									placeholder="请输入邮箱或手机号"
								/>
							</div>
						</div>
					</Modal>

					{(this.state as any).isShow ? (
						<div className="cover">
							<div>
								<i className="icon icon-spin icon-spinner-indicator" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default GroupUser;
