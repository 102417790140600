import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import { getKeyRequestUser } from '../../../services/ZhiNengMenJinService';
import API from '../../../data/API';
import { Button, Input } from "antd";
const UserNotesList: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const Search = Input.Search;
	const columns = [
		{
			title: '申请账号',
			width: 150,
			dataIndex: 'userId',
		},
		{
			title: '设备ID',
			width: 150,
			dataIndex: 'deviceId',
		},
		{
			title: '设备名称',
			dataIndex: 'device_name',
			width: 150,
		},
		{
			title: '申请手机类型',
			width: 150,
			dataIndex: 'phoneInfo',
			className: 'table-td',
		},
		{
			title: '申请结果',
			dataIndex: 'ok',
			width: 150,
		},
		{
			title: '申请时间',
			dataIndex: 'createdDate',
			width: 200,
		},
	];

	const tableSearchBarRender = () => [
		<Input
			id="inputSearchExample3"
			placeholder="账号搜索"
			onChange={(value: any) => {
				setSearch(value.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={() => {actionRef.current.reloadAndRest();}}>查询</Button>
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getKeyRequestUser({
						user_id: search,
						page: params.current,
						size: params.pageSize,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default UserNotesList;
