import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { findSmsRecord, findSmsSendBatchList } from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Col, DatePicker, message, Modal, Row, Tag } from 'antd';
import * as moment from 'moment';
import { batchList, findCheckBillInfo } from '../../../services/PayCostManagementService';
import SmsDetail from '../../notification/components/SmsDetail';
import BillRecordDetailList from './BillRecordDetailList';
import LOGO from './ckxz_logo.png';
import DAL from '../../../data/DAL';
import { printBill, updateOrderPrintStatus } from '../../../services/orderService';
let roomId;
let contractNo
let phone
let billRefund
let createTime
const RefundRoom: React.FC = () => {
	useEffect(() => {
		roomId = DAL.dal.getQueryString('roomId');
		contractNo = DAL.dal.getQueryString('contractNo');
		billRefund = DAL.dal.getQueryString('billRefund');
		phone = DAL.dal.getQueryString('phone');
		createTime = DAL.dal.getQueryString('createTime');
		getData().then()
	}, []);
	const [fzEndTime, setfzEndTime] = useState(moment())
	const [ttDate, setttDate] = useState(moment())
	const [chargeList, setchargeList] = useState([])
	const [info, setinfo] = useState<any>({})
	const getData = async () => { 
		const res = await findCheckBillInfo({ roomNumber: roomId, contractNo: contractNo, billRefund, phone,createTime })
		if (res.err == 0) {
			const data = res.data || {}
			setinfo(data || {})
			setchargeList(data.chargeBillList || [])

		} else { 
			message.error(res.msg)
		}
	}
	const dayin = (id) => {
		var printStr = "<html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'></head><body >";
		var content = '';
		var print = '';
		print = document.getElementById(id).innerHTML;
		printStr = printStr + print + '</body></html>';
		window.document.write(printStr); 
		window.document.close(); //这句很重要，没有就无法实现
		window.print();
		window.location.reload();
	};
	return (
		<>
	
			<Row align={'middle'}>
				<Col span={2}>房租截止日期：</Col>
				<Col>  <DatePicker value={fzEndTime} onChange={(date, dateString) => setfzEndTime(date)} /></Col>
				<Col span={1}></Col>
				<Col span={2}>腾退日期：</Col>
				<Col>  <DatePicker  value={ttDate} onChange={(date, dateString)=>setttDate(date)}  /></Col>
			</Row>
	
			<div id="piaoju" className="piaoju">
				<div className="main">
					<div className="body">
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							{/* NO：<span style={{ color: '#9C5223' }}>2396827</span> */}
						</div>
						<div className="title" style={{ display: 'flex', justifyContent: 'center' ,marginBottom:30}}>
							<div>温泉科技园一期腾退确认单</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' ,alignItems:'center',marginBottom:10}}>
							<div>
							资产管理部
							</div>
							<div>
							<img src={LOGO} style={{ height: 40 }}></img>
							</div>
						</div>
						<table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
							<tr>
								<td colSpan={1}>编号：</td>
								<td colSpan={5}>{ info.bianHao}</td>
							</tr>
							<tr>
								<td colSpan={1}>合同号：</td>
								<td colSpan={5}>{ info.contractNo}</td>
							</tr>
							<tr>
								<td colSpan={1}>公司名称：</td>
								<td colSpan={5}>{info.companyName}</td>
							</tr>
							<tr>
								<td colSpan={1}>承租人姓名：</td>
								<td colSpan={1}>{ info.wuyeHouseholdName}</td>
								<td colSpan={1}>身份证号：</td>
								<td colSpan={3}>{info.cardIdNum}</td>
							</tr>
							<tr>
								<td colSpan={1}>腾退房源：</td>
								<td colSpan={1}>{info.roomNumber}</td>
								<td colSpan={1}>联系电话：</td>
								<td colSpan={3}>{info.phone}</td>
							</tr>
							<tr>
								<td colSpan={1}>入住日期：</td>
								<td colSpan={1}>{info.stayDate}</td>
								<td colSpan={1}></td>
								<td colSpan={3}></td>
							</tr>
							<tr>
								<td colSpan={1}>银行：</td>
								<td colSpan={1}>{info.bankcardName}</td>
								<td colSpan={1}>银行卡号：</td>
								<td colSpan={3}>{info.bankcardNo}</td>
							</tr>
							<tr>
								<td colSpan={1}>房租截止日期：</td>
								<td colSpan={1}>{ fzEndTime.format("YYYY-MM-DD")}</td>
								<td colSpan={1}>腾退日期：</td>
								<td colSpan={3}>{ ttDate.format("YYYY-MM-DD")}</td>
							</tr>
							<tr style={{height:80}}>
								<td colSpan={1}>腾退原因：</td>
								<td colSpan={5}></td>
							</tr>
							<tr>
								<td style={{ textAlign: 'center' }}>序号</td>
								<td style={{ textAlign: 'center' }}> 收费项目</td>
								<td style={{ textAlign: 'center' }}>计量单位</td>
								<td style={{ textAlign: 'center' }}>数量</td>
								<td style={{ textAlign: 'center' }}>需退金额</td>
								<td style={{ textAlign: 'center' }}>备注</td>
							</tr>
							{chargeList.map((item,index) => { 
								return <>
								<tr>
										<td style={{ textAlign: 'center' }}>{ (index+1)}</td>
									<td>{ item.name}</td>
										<td style={{ textAlign: 'center' }}></td>
									<td style={{ textAlign: 'end' }}></td>
									<td style={{ textAlign: 'end' }}>{-(item.actualAmount / 100).toFixed(2)}</td>
									<td style={{ textAlign: 'end' }}></td>
								</tr>
								
								</>
							})}
							
							<tr>
								<td >合计金额：</td>
								<td >{-(info.sumPrice / 100).toFixed(2)}</td>
								<td style={{ textAlign: 'center' }}></td>
								<td style={{ textAlign: 'center' }}></td>
								<td style={{ textAlign: 'center' }}></td>
								<td style={{ textAlign: 'center' }}></td>
							</tr>
						</table>
						<div style={{ display: 'flex',  marginTop: 30 }}>
							承租人签确
						</div>
						<div style={{ paddingLeft: 20 }}>
							本人已确定腾退本房源，中关村创客小镇（北京）科技有限公司有权处理遗留房间物品
						</div>
						<div style={{ display: 'flex',  marginTop: 30 }}>
							同意退房款退到以上银行
						</div>


						<div style={{ width: '100%', display: 'flex', height: '40px' }}>
							<div style={{ width: '50%', height: '40px', lineHeight: '40px' }}>
							</div>
							<div style={{ width: '30%', height: '40px', lineHeight: '40px' }}>
							</div>
							<div style={{ width: '20%', height: '40px', lineHeight: '40px' }}>
								承租人签字：
							</div>
						</div>
						<div style={{ width: '100%', display: 'flex', height: '40px' }}>
							<div style={{ width: '50%', height: '40px', lineHeight: '40px' }}>
							</div>
							<div style={{ width: '30%', height: '40px', lineHeight: '40px' }}>
							</div>
							<div style={{ width: '20%', height: '40px', lineHeight: '40px' }}>
								日期：
							</div>
						</div>
					</div>
				</div>
			</div>
			<Button onClick={() => dayin('piaoju')}>打印</Button>
		</>
	);
};

export default RefundRoom;
