import Api from '../data/API';

export async function huodongReport(params: any) {
	return Api.postWithAuth('/api/huodong/huodong_report', params);
}

export async function cancelApply(params: any) {
	return Api.postWithAuth('/api/huodong/cancelApply', params);
}

export async function findBmryList(params: any) {
	return Api.postWithAuth('/api/huodong/find_bmry_list', params);
}

export async function findHuodongTicketType(params: any) {
	return Api.postWithAuth('/api/huodong/find_huodong_ticket_type', params);
}

export async function shenHe(params: any) {
	return Api.postWithAuth('/api/huodong/shen_he', params);
}

export async function findBmryListExport(params: any) {
	return Api.postWithAuth('/api/huodong/find_bmry_list_export', params);
}

export async function dingDanList(params: any) {
	return Api.postWithAuth('/api/huodong/ding_dan_list', params);
}
export async function huoDongOrderList(params: any) {
	return Api.postWithAuth('/api/huodong/huoDongOrderList', params);
}

export async function findHuodongList(params: any) {
	return Api.postWithAuth('/api/huodong/find_huodong_list', params);
}

export async function delHuodong(params: any) {
	return Api.postWithAuth('/api/huodong/del_huodong', params);
}
export async function closeHuodong(params: any) {
	return Api.postWithAuth('/api/huodong/close_huodong', params);
}

export async function helpSign(params: any) {
	return Api.postWithAuth('/api/huodong/help_sign', params);
}
export async function findSignList(params: any) {
	return Api.postWithAuth('/api/huodong/find_sign_list', params);
}

export async function qianDaoShu(params: any) {
	return Api.postWithAuth('/api/huodong/qian_dao_shu', params);
}

export async function findHuodongEtails(params: any) {
	return Api.postWithAuth('/api/huodong/find_huodong_etails', params);
}

export async function findCityAreaList(params: any) {
	return Api.postWithAuth('/api/huodong/find_city_area_list', params);
}
export async function updateHuodong(params: any) {
	return Api.postWithAuth('/api/huodong/update_huodong', params);
}
