import React, { useCallback, useEffect, useRef, useState } from 'react';
import OrderList from '../../order/pages/OrderList';
const ProductOrder: React.FC = () => {
    
    return <>
        <OrderList kind={
            "productPay"
       }></OrderList>

    </>
    
}
export default ProductOrder