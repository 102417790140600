import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Form, Image, Input, Modal, Switch, Upload } from 'antd';
import Api from '../../../data/API';
import { Link } from 'react-router-dom';
import { add_group, changeStatus, delete_group, groupList, update_group } from '../../../services/GroupService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import appConfig from '../../../appConfig';
import { PlusOutlined } from '@ant-design/icons/lib';
const Group: React.FC = () => {
	const msg = require('../../../util/SuspensionMsg');
	const Search = Input.Search;
	const [add, setAdd] = useState(false);
	const [visible, setVisible] = useState(false);
	const actionRef = useRef<ActionType>();
	const confirm = Modal.confirm;
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [searchType, setSearchType] = useState('');
	const [group, setGroup] = useState({});
	const [isSearched, setIsSearched] = useState('不可以');
	const [isJiaru, setIsJiaru] = useState('不可以');
	const [picture, setPicture] = useState('');
	const columns = [
		{
			title: '群头像',
			dataIndex: 'picture',
			key: 'picture',
			render: (text: string, record: any) => (
				<span>
					{record.pictrue === '' ? (
						''
					) : (
						<Image className="homePage" src={Api.cdnRootPath + record.picture} height={80} width={80} />
					)}
				</span>
			),
		},
		{
			title: '群名称',
			dataIndex: 'qunName',
			key: 'qunName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						findByUuid(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			key: 'createTime',
		},
		{
			title: '可否被查询',
			dataIndex: 'isSearched',
			key: 'isSearched',
		},
		{
			title: '可否直接加入',
			dataIndex: 'isJiaru',
			key: 'isJiaru',
		},
		{
			title: '创建人',
			dataIndex: 'creater',
			key: 'creater',
		},
		{
			title: '群人数',
			dataIndex: 'userNum',
			key: 'userNum',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (text: string, record: any) => (
				<Switch
					checked={record.status === '开启' ? true : false}
					onChange={() => {
						ChangeState(record);
					}}
				/>
			),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<Link to={'/group/group-user?uuid=' + record.uuid + '=qunName=' + record.qunName}>
								<a>成员管理</a>
							</Link>
						</li>
						<li>
							<Link to={`/group/find_tiezi_list?uuid=${record.uuid}&state=group`}>
								<a>帖子管理</a>
							</Link>
						</li>
						<li>
							<a
								onClick={() => {
									deleteItem(record);
								}}
							>
								删除
							</a>
						</li>
					</ul>
				</span>
			),
		},
	];

	const deleteItem = (value: any) => {
		confirm({
			title: '您确认要删除该群组吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await delete_group({
					uuid: value.uuid,
				});
				if (res.err === 0) {
					if (actionRef.current) {
						actionRef.current.reload();
					}
					msg.suspensionMsg({ content: res.msg, type: 'success' });
				} else {
					msg.suspensionMsg({ content: res.msg });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const findByUuid = (value: any) => {
		console.log('修改Item::', value);
		setIsJiaru(value.isJiaru);
		setIsSearched(value.isSearched);
		setPicture(value.picture);
		setAdd(false);
		const formData: any = {
			uuid: value.uuid,
			qunName: value.qunName,
			miaoshu: value.miaoshu,
			gongGao: value.gongGao,
		};
		form.setFieldsValue(formData);
		setVisible(true);
	};

	const ChangeState = async (value: any) => {
		if (value.status === '开启') {
			value.status = '禁用';
		} else {
			value.status = '开启';
		}
		setGroup(value);
		const res = await changeStatus({
			uuid: value.uuid,
			status: value.status,
		});
		if (res) {
			return res;
		} else {
			msg.suspensionMsg({ content: '失败', type: 'error' });
		}
	};
	const tableSearchBarRender = () => [
		<Input
			placeholder="搜索群名"
			onChange={(event) => {
				setSearchType(event.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];
	const xinjian = () => {
		setIsJiaru('不可以');
		setIsSearched('不可以');
		setPicture('');
		form.resetFields();
		setAdd(true);
		setVisible(true);
	};
	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={xinjian}>
			新建群组
		</Button>,
	];

	const handleOk = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				miaoshu: data.miaoshu,
				gongGao: data.gongGao,
				isSearched: isSearched,
				qunName: data.qunName,
				isJiaru: isJiaru,
				picture: picture,
				user_id: Api.userId,
			};
			const res = await add_group(formData);
			console.log('adddata::;', formData);
			if (res.err === 0) {
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
				setVisible(false);
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				uuid: data.uuid,
				miaoshu: data.miaoshu,
				gongGao: data.gongGao,
				isSearched: isSearched,
				qunName: data.qunName,
				isJiaru: isJiaru,
				picture: picture,
			};
			console.log('updatedata::;', formData);
			const res = await update_group(formData);
			if (res.err === 0) {
				if (actionRef.current) {
					actionRef.current.reload();
				}
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				setVisible(false);
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
		});
	};
	const getIsSearched1 = (ev: any) => {
		console.log('能否加入', ev);
		if (ev === '不可以') {
			setIsSearched('可以');
		} else {
			setIsSearched('不可以');
		}
	};
	const getIsJiaru1 = (ev: any) => {
		if (ev === '不可以') {
			setIsJiaru('可以');
		} else {
			setIsJiaru('不可以');
		}
	};
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	const getFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			setPicture(fileList.file.response.url);
			console.log(`${fileList.file.name}`);
			console.log('群头像', fileList.file.response.url);
		} else if (fileList.file.status === 'error') {
			msg.suspensionMsg({ content: `${fileList.file.name} 上传图片失败`, type: 'error' });
		}
	};
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await groupList({
						page: params.current,
						size: params.pageSize,
						Search: searchType,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title={add ? '添加群组' : '修改群组'}
				visible={visible}
				onOk={handleOk}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} label={'uuid'} name={'uuid'}>
						<Input />
					</FormItem>
					<FormItem label={'名称'} name={'qunName'} rules={[{ required: true }]}>
						<Input placeholder={'请输入群组名称'} />
					</FormItem>
					<FormItem label={'描述'} name={'miaoshu'}>
						<TextArea rows={4} cols={50} />
					</FormItem>
					<FormItem label={'公告'} name={'gongGao'}>
						<TextArea rows={4} cols={50} />
					</FormItem>
					<FormItem label={'能否被搜索'} name={'isSearched'}>
						<Switch
							checked={isSearched === '可以' ? true : false}
							onChange={() => {
								getIsSearched1(isSearched);
							}}
						/>
					</FormItem>
					<FormItem label={'能否直接加入'} name={'isJiaru'}>
						<Switch
							checked={isJiaru === '可以' ? true : false}
							onChange={() => {
								getIsJiaru1(isJiaru);
							}}
						/>
					</FormItem>
					<FormItem label={'群头像'} name={'picture'}>
						<Upload
							name="image"
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							data={{ wuye_uuid: localStorage.getItem('wyUuid') }}
							action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
							onChange={getFileName}
							accept={'.jpg,.png'}
						>
							{picture !== null && picture !== undefined && picture !== '' ? (
								<img alt="avatar" style={{ width: '100%' }} src={appConfig.cdnRootPath + picture} />
							) : (
								uploadButton
							)}
						</Upload>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default Group;
