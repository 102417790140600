import Api from '../data/API';

export async function getWxBusinessList(params: any) {
	return Api.postWithAuth('/adminapi/wx/getWxBusinessList', params);
}
export async function addWxBusiness(params: any) {
	return Api.postWithAuth('/adminapi/wx/addWxBusiness', params);
}
export async function updateWxBusiness(params: any) {
	return Api.postWithAuth('/adminapi/wx/updateWxBusiness', params);
}
export async function deleteWxBusiness(params: any) {
	return Api.postWithAuth('/adminapi/wx/deleteWxBusiness', params);
}
export async function setDefaultWxBusiness(params: any) {
	return Api.postWithAuth('/adminapi/wx/setDefaultWxBusiness', params);
}

