import * as React from 'react';
import DAL from '../../../data/DAL';
import Api from '../../../data/API';
import appConfig from '../../../appConfig';
import { Spin } from 'antd';

class LiuchengDemand extends React.Component {
	dal = DAL.dal;
	ifra: any;
	state: any;
	uuid: any;
	constructor(props: any) {
		super(props);
		this.state = { liuchengdan: [], isShow: true, iframHeight: window.outerHeight - 100 };
		this.onLoad = this.onLoad.bind(this);
		this.getIframe = this.getIframe.bind(this);
		this.uuid = props.location.state.uuid;
	}

	onLoad(infrem: any) {
		console.log('获得的对象111>>>>>>>>>>>>>>>>>', infrem);
		this.setState({
			isShow: false,
		});
	}

	getIframe() {
		var win = window.frames;
		console.log('获得的iframeURL', appConfig.webCompanyUrl);
		//console.log('获得的iframeUrl',  'http://localhost:3036/liucheng/look-liucheng?access_token=' + Api.getAccessToken() + '&user_id=' + Api.getUserId());
		this.ifra = win[0];
		this.ifra.location.href =
			appConfig.webCompanyUrl +
			'/page/liucheng/xin-shenhe-demand?access_token=' +
			Api.getAccessToken() +
			'&user_id=' +
			Api.getUserId() +
			'&wyUuid=' +
			Api.getWuyeUuid() +
			'&isdemand=' +
			this.uuid;
		//this.ifra.location.href = 'http://192.168.31.87:3002/page/liucheng/xin-shenhe-demand?access_token=' + Api.getAccessToken() + '&user_id=' + Api.getUserId() + '&wyUuid=' + Api.getWuyeUuid() + '&isdemand=' + this.uuid;
	}

	render() {
		return (
			<div style={{ marginLeft: 0, marginTop: 0, marginRight: 0, marginBottom: 0, overflow: 'hidden' }}>
				<Spin
					size="large"
					style={{ paddingTop: 20, width: '100%' }}
					delay={1000}
					spinning={this.state.isShow}
				/>
				<iframe
					id="main"
					onLoad={this.onLoad}
					ref={this.getIframe}
					style={{ width: '100%', height: (this.state as any).iframHeight, margin: 0, border: 0 }}
				/>
			</div>
		);
	}
}

export default LiuchengDemand;
