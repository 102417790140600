import Api from '../data/API';

export async function findDoorList(params: any) {
  return Api.postWithAuth('/admin/api/door/list ', params);
}
export async function updateDoorInfo(params: any) {
  return Api.postWithAuth('/admin/api/door/update ', params);
}
export async function findDoorRecordList(params: any) {
  return Api.postWithAuth('/admin/api/door/record/list ', params);
}
