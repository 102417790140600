import React, { useEffect, useRef, useState } from 'react';
import { editQuestionnaire } from '../../../services/QuestionnaireService';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space,
	Spin,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import { addDebitNote, deleteChargeBill, findAll, updateBill } from '../../../services/PayCostManagementService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import AuthorizedView from '../../../util/AuthorizedView';
import { findFee } from '../../../services/orderService';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
import * as XLSX from 'xlsx';
const Option = Select.Option;
interface Params {
	state?: string;
	billType?: string;
}
const DebitNote: React.FC<Params> = (props: any) => {
	useEffect(() => {
		getFee();
	}, []);

	const TreeNode = TreeSelect.TreeNode;
	const confirm = Modal.confirm;
	const Search = Input.Search;
	let { RangePicker } = DatePicker;
	let msg = require('../../../util/SuspensionMsg');
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [searchInput, setSearchInput] = useState('');
	const [fee, setFee] = useState([]);
	const [visible, setVisible] = useState(false);
	const [updateVisible, setupdateVisible] = useState(false);
	const [subjectName, setSubjectName] = useState('');
	const [state, setState] = useState<any>(props.state || '');
	const [feeId, setFeeId] = useState('');
	const [Stime, setStime] = useState('');
	const [Etime, setEtime] = useState('');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [billType, setbillType] = useState(props.billType || '');
	const billTypes = getBillTypes().list || [];
	const [updateForm] = Form.useForm<FormInstance>();
	const [receivableDate, setreceivableDate] = useState('');
	const [source, setsource] = useState('');
	const [payStartTime, setpayStartTime] = useState('');
	const [payEndTime, setpayEndEndTime] = useState('');
	const [quanxuan, setquanxuan] = useState(false);
	const [loading, setloading] = useState(false);
	const [total, settotal] = useState(0);
	const columns = [
		{
			title: '房间',
			dataIndex: 'roomId',
			width: 100,
		},
		{
			title: '客户',
			dataIndex: 'custName',
			width: 100,
		},
		{
			title: '缴费类型',
			dataIndex: 'billType',
			width: 100,
		},
		{
			title: '合同号',
			dataIndex: 'contractNo',
			width: 120,
		},
		{
			title: '手机号',
			dataIndex: 'phone',
			width: 100,
		},
		{
			title: '应收编号',
			dataIndex: 'chargeBillId',
			width: 100,
		},
		{
			title: '费用期间',
			dataIndex: 'startTime',
			valueType: 'dateQj',
			width: 200,
			render: (text: string, record: any) => {
				return (
					<span>
						{record.startTime !== undefined && record.startTime !== null && record.startTime !== ''
							? moment(record.startTime).format('YYYY-MM-DD') + '~'
							: '-'}
						{record.endTime !== undefined && record.endTime !== null && record.endTime !== ''
							? moment(record.endTime).format('YYYY-MM-DD')
							: ''}
					</span>
				);
			},
		},
		{
			title: '收费科目',
			dataIndex: 'name',
			width: 100,
		},
		{
			title: '应收金额(元)',
			dataIndex: 'actualAmount',
			valueT: 'price',
			width: 100,
			render: (text: string) => <span>{(parseInt(text) / 100).toFixed(2)}</span>,
		},
		{
			title: '实收金额',
			dataIndex: 'state',
			valueT: 'price1',
			width: 100,
			render: (text: string, record: any) => {
				return <span>{text == '1' ? (record.actualAmount / 100).toFixed(2) : 0}</span>;
			},
		},
		{
			title: '创建日期',
			dataIndex: 'createTime',
			valueType: 'dateTime',
			width: 200,
		},

		{
			title: '实收日期',
			dataIndex: 'payTime',
			valueType: 'dateTime',
			width: 200,
			render: (text: string, record: any) => {
				return (
					<span>
						{record.payTime === null || record.payTime === undefined
							? '-'
							: moment(record.payTime).format('YYYY-MM-DD HH:mm:ss')}
					</span>
				);
			},
		},
		{
			title: '操作',
			key: 'action',
			width: 100,
			fixed: 'right',
			// hideInTable: DAL.dal.isNotNull(props.state),
			render: (text: string, record: any) => {
				return record.third ? (
					'无法操作'
				) : (
					<Space>
						<a href={'/pay_cost_management/print_bill?orderUuid=' + record.uuid}>
							收据
						</a>
						<a
							// hidden={record.state}
							onClick={() => {
								deleteItem(record);
							}}
						>
							删除
						</a>
						{/* <a
								onClick={() => {
								openUpdateModel(record)
							}}
						>
							编辑
						</a> */}
					</Space>
				);
			},
		},
	];
	const openUpdateModel = (item) => {
		updateForm.setFieldsValue({ ...item, billRefund: 0 == item.billRefund ? undefined : item.billRefund });
		setupdateVisible(true);
	};
	const tableSearchBarRender = () => [
		<div hidden={DAL.dal.isNotNull(props.state)}>
			<Select
				placeholder={'缴费状态'}
				allowClear
				onChange={(value: any) => {
					setState(value);
				}}
			>
				<Option value={'true'}>已缴费</Option>
				<Option value={'false'}>未缴费</Option>
			</Select>
		</div>,
		<div>
			<Select value={billType} onChange={(value) => setbillType(value)}>
				<Select.Option value={''}>全部类型</Select.Option>
				{billTypes.map((item: any) => (
					<Option value={item.value}>{item.name}</Option>
				))}
			</Select>
		</div>,
		<div>
			<Select value={source} onChange={(value) => setsource(value)}>
				<Select.Option value={''}>全部来源</Select.Option>
				<Option value={'0'}>系统生成</Option>
				<Option value={'1'}>外部生成</Option>
			</Select>
		</div>,
		<Input
			placeholder="姓名,账号,房间号"
			onChange={(event) => {
				setSearchInput(event.target.value);
			}}
		/>,
		<RangePicker
			showTime={false}
			allowClear={true}
			onChange={(dates: any) => {
				console.log('dates:::::', dates);
				setpayStartTime(dates ? moment(dates[0]).format('YYYY-MM-DD') : undefined);
				setpayEndEndTime(dates ? moment(dates[1]).format('YYYY-MM-DD') : undefined);
			}}
			format="YYYY-MM-DD"
			placeholder={['实收日期时间范围', '实收日期时间范围']}
		/>,
		<Button
			type={'primary'}
			onClick={() => {
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];

	const deleteItem = (value: any) => {
		confirm({
			title: '您确定要删除此缴费单吗?',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteChargeBill({ uuid: value.uuid });
				console.log('.....', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg, type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const getFee = async () => {
		const res = await findFee({});
		console.log('科目列表', res);
		if (res.err === 0) {
			setFee(res.data);
		}
	};

	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			let value = data.subjectName.split(',');
			let formData: any = {
				name: value[1],
				loginId: data.loginId,
				actualAmount: data.actualAmount,
				Stime: Stime,
				Etime: Etime,
				feeId: value[0],
				roomNumber: data.roomNumber,
				contractNo: data.contractNo,
				receivableDate,
			};
			console.log('data::;', formData);
			const res = await addDebitNote(formData);
			if (res.err === 0) {
				if (actionRef.current) {
					actionRef.current.reload();
				}
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				setVisible(false);
			} else {
				message.warning(res.msg);
			}
		});
	};
	const updateItem = () => {
		updateForm.validateFields().then(async (data: any) => {
			console.log('data::;', data);
			let formData: any = {
				...data,
			};
			const res = await updateBill(formData);
			if (res.err === 0) {
				if (actionRef.current) {
					actionRef.current.reload();
				}
				msg.suspensionMsg({ content: res.msg, type: 'success' });
				setupdateVisible(false);
			} else {
				message.warning(res.msg);
			}
		});
	};

	const dateTime = (date: any, dataToString: any) => {
		setStime(dataToString[0]);
		setEtime(dataToString[1]);
	};
	const setYSData = (date: any, dataToString: any) => {
		setreceivableDate(dataToString);
	};
	const tableOperationsBarRender = () => [
		// <div hidden={DAL.dal.isNotNull(props.state)}>
		<AuthorizedView needAuthority={'payment_management:debit_note:add'}>
			<Button
				type="primary"
				onClick={() => {
					setVisible(true);
				}}
			>
				新增缴费单
			</Button>
		</AuthorizedView>,
		// </div>,
	];
	const exprotData = async (selectedRows: [any?]) => {
		if (quanxuan) {
			let data = {
				page: 1,
				size: total,
				state: state,
				searchInput: searchInput,
				billType: billType,
				source: source,
				payStartTime: payStartTime,
				payEndTime: payEndTime,
			};
			setloading(true);
			const res = await findAll(data);
			setloading(false);
			if (res.err == 0) {
				setExprotData(res.data || []);
			} else {
				message.error(res.msg);
			}
		} else {
			setExprotData(selectedRows);
		}
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(
						selectedRows[i][item.dataIndex] != undefined
							? moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss')
							: ''
					);
				} else if (item.valueType == 'dateQj') {
					const time1 =
						selectedRows[i].startTime !== undefined &&
						selectedRows[i].startTime !== null &&
						selectedRows[i].startTime !== ''
							? moment(selectedRows[i].startTime).format('YYYY-MM-DD') + '~'
							: '-';
					const time2 =
						selectedRows[i].endTime !== undefined &&
						selectedRows[i].endTime !== null &&
						selectedRows[i].endTime !== ''
							? moment(selectedRows[i].endTime).format('YYYY-MM-DD')
							: '';
					data.push(time1 + time2);
				} else if ('price' == item.valueT) {
					const price = (parseInt(selectedRows[i][item.dataIndex]) / 100).toFixed(2);
					data.push(price);
				} else if ('price1' == item.valueT) {
					const price =
						selectedRows[i][item.dataIndex] == '1' ? (selectedRows[i].actualAmount / 100).toFixed(2) : 0;
					data.push(price);
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '缴费单');
		/* save to file */
		XLSX.writeFile(wb, '缴费单.xlsx');
	};

	return (
		<Spin spinning={loading}>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				rowSelection={{
					fixed: true,
					columnWidth: 60,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setquanxuan(false);
						setselectedRowKeys(selectedRowKeys);
					},
					selections: [
						{
							key: 'SELECT_ALL',
							text: '全选全部页面',
							onSelect: (changableRowKeys: any) => {
								setquanxuan(true);
								setselectedRowKeys(changableRowKeys);
							},
						},
					],
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {quanxuan ? total : selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::', selectedRows);
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>
						</Space>
					);
				}}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {
						page: params.current,
						size: params.pageSize,
						state: state,
						searchInput: searchInput,
						billType: billType,
						source: source,
						payStartTime: payStartTime,
						payEndTime: payEndTime,
					};
					const res = await findAll(data);
					if (res.err == 0) {
						if (quanxuan) {
							let uuids: any = [];
							res.data.map((item: any) => {
								uuids.push(item.uuid);
							});
							setselectedRowKeys(uuids);
						}
						settotal(res.count);
					}
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="修改缴费单"
				visible={updateVisible}
				onOk={updateItem}
				onCancel={() => {
					setupdateVisible(false);
				}}
			>
				<ProxyForm form={updateForm}>
					<FormItem hidden={true} label={'uuid'} name={'uuid'}>
						<Input placeholder={''} />
					</FormItem>
					<FormItem label={'退款状态'} name={'billRefund'}>
						<Select allowClear>
							<Select.Option value={1}>退款处理中</Select.Option>
							<Select.Option value={2}>退款成功</Select.Option>
							<Select.Option value={3}>退款关闭</Select.Option>
							<Select.Option value={4}>退款异常</Select.Option>
							<Select.Option value={5}>退款失败</Select.Option>
						</Select>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="新增缴费单"
				visible={visible}
				onOk={addItem}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'客户帐号'} name={'loginId'} rules={[{ required: true }]}>
						<Input placeholder={'请输入客户账号'} />
					</FormItem>
					<FormItem label={'房间号'} name={'roomNumber'}>
						<Input placeholder={'请输入房间号'} />
					</FormItem>
					<FormItem label={'合同号'} name={'contractNo'}>
						<Input placeholder={'请输入合同号'} />
					</FormItem>
					<FormItem label={'收费科目'} name={'subjectName'} rules={[{ required: true }]}>
						<TreeSelect
							showSearch={true}
							style={{ width: '100%' }}
							dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
							placeholder="请选择"
							treeNodeFilterProp="title"
							allowClear={true}
							treeDefaultExpandAll={true}
						>
							{fee.map((item: any, index: number) => (
								<TreeNode value={item.uuid + ',' + item.feeName} title={item.feeName} key={item.uuid} />
							))}
						</TreeSelect>
					</FormItem>
					<FormItem label={'应收金额'} name={'actualAmount'} rules={[{ required: true }]}>
						<InputNumber style={{ width: '100%' }} step={0.01} placeholder={'请输入应收金额'} />
					</FormItem>
					<FormItem label={'应收日期'} name={'receivableDate'}>
						<DatePicker format="YYYY-MM-DD" onChange={setYSData} style={{ width: '100%' }} />
					</FormItem>
					<FormItem label={'费用期间'} name={'jineTime'}>
						<RangePicker
							format="YYYY-MM-DD"
							placeholder={['请选择开始时间', '请选择结束时间']}
							onChange={dateTime}
							style={{ width: '100%' }}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
		</Spin>
	);
};
export default DebitNote;
