import React, { useEffect, useState } from 'react';
import { getHealthRecordData } from '../services/StatisticsService';
import { Card, message, Select } from 'antd';
import moment from 'moment';
import LineChart from './LineChart';
import { DataContext } from '../pages/controlPlatform/CurrentPlatform';
const VerificationRecordLine: React.FC = () => {
	const [selectType, setSelectType] = useState(1);
	let startTime = moment().subtract(6, 'd');
	let endTime = moment();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>([]);

	useEffect(() => {
		getData();
	}, []);

	const changeSelectType = (e: number) => {
		setSelectType(e);
		switch (e) {
			case 1:
				startTime = moment().subtract(6, 'd');
				endTime = moment();
				break;
			case 2:
				startTime = moment().subtract(14, 'd');
				endTime = moment();
				break;
			case 3:
				startTime = moment().subtract(29, 'd');
				endTime = moment();
				break;
		}
		getData();
	};

	const getData = async () => {
		setLoading(true);
		let params = {
			startTime: startTime.format('YYYY-MM-DD 00:00:00'),
			endTime: endTime.format('YYYY-MM-DD 23:59:59'),
		};
		setLoading(true);
		let rsp = await getHealthRecordData(params);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
		}
		let data = rsp.data;
		createChartData(data);
	};

	const createChartData = (data: any) => {
		let sourceData = data[0] || {};
		console.log('sourceData', sourceData);
		let chartData: any = [];
		(sourceData.datas || []).map((item: any) => {
			let keys = Object.keys(item.dayData);
			keys.map((key: string) => {
				chartData.push({
					name: item.unit,
					x: key,
					y: item.dayData[key],
				});
			});
		});
		console.log('chartData:::::', chartData);
		setData(chartData);
	};

	const tabBarExtrContent = () => (
		<Select defaultValue={selectType} onChange={changeSelectType}>
			<Select.Option value={1}>最近7天</Select.Option>
			<Select.Option value={2}>最近15天</Select.Option>
			<Select.Option value={3}>最近30天</Select.Option>
		</Select>
	);
	return (
		<Card title={'防疫记录'} extra={tabBarExtrContent()} loading={loading} bodyStyle={{ paddingLeft: 15 }}>
			<DataContext.Provider value={data}>
				<LineChart />
			</DataContext.Provider>
		</Card>
	);
};
export default VerificationRecordLine;
