import React, { useCallback, useEffect, useState } from 'react';
import { setFirstImg } from '../../../services/meetingService';
import { Button, Card, Col, Form, Image, Input, message, Modal, Row, Select, Tag, Upload } from 'antd';
import Api from '../../../data/API';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons/lib';
import {
	addPicture,
	deleteRoomImg,
	getRoomPictures,
	sortRoomImg,
	updateRoomPhoto,
} from '../../../services/ZhuhushenheService';
import CardItem from './CardItem';
import HTMLBackend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import FengMianImg from '../../../img/333.png';
import ImgCrop from 'antd-img-crop';
import { getAllLiucheng } from '../../../services/FoundationSetupService';
import UploadImg from '../../../components/UploadImg';
interface HouseTypeImgPros {
	houseTypeId: any;
	isHidenImgType: boolean;
	imgType: any;
}
const HouseTypeImg: React.FC<HouseTypeImgPros> = (props: any) => {
	useEffect(() => {
		let params: any = { wuye_uuid: localStorage.getItem('wyUuid'), prefix: 'room' };
		if (props.imgType == '单页配置图') {
			setWidthRatio(5);
			setHeightRatio(3);
			setHiddenImgCorp(false);
			setImgContent('请准备分辨率比例为5:3的图片');
		} else if (props.imgType == '资产类型图') {
			setWidthRatio(5);
			setHeightRatio(3);
			setHiddenImgCorp(false);
			setImgContent('请准备分辨率比例为5:3的图片');
		} else if (props.imgType == '数智应用场景banner图') {
			setWidthRatio(7);
			setHeightRatio(3);
			setHiddenImgCorp(false);
			sethiddenFengMian(true);
			setImgContent('请准备分辨率比例为7:3的图片');
		} else if (props.imgType == '数智应用场景详情图') {
			setWidthRatio(0);
			setHiddenImgCorp(true);
			setHeightRatio(0);
			sethiddenFengMian(true);
			setImgContent('请准备分辨率比例为5:3的图片');
		} else if (props.imgType == '房间') {
			setWidthRatio(5);
			setHeightRatio(3);
			setHiddenImgCorp(false);
			setImgContent('请准备分辨率比例为5:3的图片');
		} else {
			setWidthRatio(0);
			setHiddenImgCorp(true);
			setHeightRatio(0);
		}
		setUploadParams(params);
		getdata('户型图');
		getLiuchengDanList();
	}, []);
	const [uploadParams, setUploadParams] = useState({});
	const [imgContent, setImgContent] = useState('');
	const [hiddenImgCorp, setHiddenImgCorp] = useState(true);
	const [widthRatio, setWidthRatio] = useState(0);
	const [hiddenFengMian, sethiddenFengMian] = useState(false);
	const [heightRatio, setHeightRatio] = useState(0);
	const { isHidenImgType } = props;
	const [imgs, setImgs] = useState([]);
	const [type, setType] = useState('户型图');
	const [visible, setVisible] = useState(false);
	const [checkedType, setCheckedType] = useState('户型图');
	const [photo, setPhoto] = useState('');
	const [add, setAdd] = useState(false);
	const [note, setNote] = useState('');
	const [item, setItem] = useState({});
	const [fileList, setFileList] = useState([]);
	const [urls, setUrls] = useState([]);
	const [jumpType, setJumpType] = useState('liuChengDan');
	const [jumpUrl, setJumpUrl] = useState('');
	const [liuChengList, setLiuChengList] = useState([]);
	const [condition, setCondition] = useState([{ condition: '', msg: '', type: 'liuChengDan', value: '' }]);
	const { CheckableTag } = Tag;
	const imgType = [
		{
			name: '户型图',
		},
		{
			name: '实景图',
		},
		{
			name: '配套图',
		},
		{
			name: '交通图',
		},
		{
			name: '证照',
		},
	];
	const getLiuchengDanList = async () => {
		const data = {
			state: true,
			selectedTags: '',
			searchContent: '',
		};
		const res = await getAllLiucheng(data);
		console.log('res:::', res);
		if (res.err === 0) {
			setLiuChengList(res.data);
		}
	};
	const getdata = async (type1: any) => {
		const formData: any = {
			uuid: getUuid(),
			imgType: props.imgType,
		};
		if (!isHidenImgType) {
			formData.type = type1;
		}
		const res = await getRoomPictures(formData);

		console.log('meeting', res);
		if (res.err === 0) {
			setImgs(res.imgs);
		} else {
			message.error(res.msg);
		}
	};
	const getUuid = () => {
		return props.houseTypeId;
	};
	const setFirst = async (id: any) => {
		const formData: any = {
			uuid: getUuid(),
			fileName: id,
		};
		if (!isHidenImgType) {
			formData.roomImgType = type;
		}
		const res = await setFirstImg(formData);

		console.log('meeting', res);
		if (res.err === 0) {
			getdata(type);
			message.success('切换成功');
		} else {
			message.error(res.ms);
		}
	};

	const deleteImg = (id: any) => {
		Modal.confirm({
			title: '您确认要删除该图片吗?',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteRoomImg({
					uuid: getUuid(),
					img: id,
				});
				if (res.err === 0) {
					getdata(type);
					message.success(res.msg);
				} else {
					message.error(res.msg);
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const imgInfos = () => {
		setPhoto('');
		setUrls([]);
		setNote('');
		setFileList([]);
		setCondition([{ condition: '', msg: '', type: 'liuChengDan', value: '' }]);
		setJumpUrl('');
		setJumpType('');
		setAdd(true);
		setVisible(true);
	};
	const openModalUpdate = (item: any) => {
		console.log('item::;', item);
		setItem(item);
		setJumpType(item.jumpType);
		setJumpUrl(item.jumpUrl);
		setCondition(JSON.parse(item.condition || '[]'));
		setPhoto(item.img);
		setAdd(false);
		setVisible(true);
		setNote(item.note);
	};
	const addInfoImage = async () => {
		if (add) {
			console.log('数组的长度', imgs.length);
			const data: any = imgs[imgs.length - 1];
			let sort1 = 0;
			if (data !== null && data !== '' && data !== undefined) {
				sort1 = data.sort1 + 1;
			}
			console.log('获取的:::', data);
			if (urls.length === 0 || urls.length < 0) {
				message.warning('请上传图片');
				return;
			}
			const formData: any = {
				uuid: getUuid(),
				img: urls.toString(),
				sort: sort1,
				imgType: props.imgType,
				jumpType: jumpType,
				jumpUrl: jumpUrl,
				condition: JSON.stringify(condition),
				note: note,
			};
			if (!isHidenImgType) {
				formData.roomType = checkedType;
			}
			const res = await addPicture(formData);
			if (res.err === 0) {
				getdata(type);
				message.success(res.msg);
				setVisible(false);
				setPhoto('');
				setNote('');
			} else {
				message.error(res.msg);
			}
		} else {
			const res = await updateRoomPhoto({
				uuid: getUuid(),
				id: item.id,
				img: photo,
				imgType: props.imgType,
				jumpType: jumpType,
				jumpUrl: jumpUrl,
				condition: JSON.stringify(condition),
				note: note,
			});
			if (res.err === 0) {
				getdata(type);
				message.success('修改成功');
				setVisible(false);
				setPhoto('');
				setNote('');
			} else {
				message.error('修改失败');
			}
		}
	};
	const getFileName = (value: any) => {
		setPhoto(value);
	};
	const getMeetFileName1 = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			if (fileList.file.response.err !== 0) {
				fileList.file.status = 'error';
				message.warning(fileList.file.response.msg);
			} else {
				setPhoto(fileList.file.response.url);
				console.log(`${fileList.file.name}`);
				console.log('房间', fileList.file.response.url);
			}
		} else if (fileList.file.status === 'error') {
			message.error('上传图片失败');
		}
	};
	const getMeetFileName = (info: any) => {
		let fileList: any = [...info.fileList];
		let urls: any = [];
		fileList = fileList.map((item: any) => {
			if (item.status === undefined) {
				item.status = 'error';
				item.response = '';
			}
			if (item.status === 'done') {
				if (item.response.err !== 0) {
					item.status = 'error';
					message.error(item.response.msg);
					item.response = '';
				} else {
					if (item.response) {
						urls.push(item.response.url);
						item.url = item.response.url;
					}
				}
			}
			return item;
		});
		console.log('fileList::::', fileList);
		setFileList(fileList);
		setUrls(urls);
	};
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传</div>
		</div>
	);
	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			/**
			 * 1、如果此时拖拽的组件是 Box 组件，则 dragIndex 为 undefined，则此时修改，则此时修改 cardList 中的占位元素的位置即可
			 * 2、如果此时拖拽的组件是 Card 组件，则 dragIndex 不为 undefined，此时替换 dragIndex 和 hoverIndex 位置的元素即可
			 */
			console.log('dragIndex', dragIndex);
			console.log('hoverIndex', hoverIndex);
			let CardList = [...imgs];
			const d1 = CardList[dragIndex];
			const ho = CardList[hoverIndex];
			console.log('data::', d1, ho);
			let tmp = CardList[dragIndex]; //临时储存文件
			CardList.splice(dragIndex, 1); //移除拖拽项
			CardList.splice(hoverIndex, 0, tmp); //插入放置项
			setImgs([...CardList]);
			// eslint-disable-next-line
		},
		[imgs]
	);
	const paixun = async () => {
		let data: any = [];
		imgs.forEach((item: any) => {
			data.push(item.id);
		});
		console.log('data:::', data.toString());
		const res = await sortRoomImg({
			ids: data.toString(),
		});
		if (res.err === 0) {
			getdata(type);
			message.success('操作成功');
		} else {
			message.error('操作失败');
		}
	};
	const handleChange = (checked: any, tag: any) => {
		setType(tag);
		setCheckedType(tag);
		console.log('checked', checked);
		getdata(tag);
	};
	const changeChildrenValue = (index: any, value: any, name: any) => {
		condition[index][name] = value;
		setCondition([...condition]);
	};
	const deleteChildrenParams = (index: any) => {
		condition.splice(index, 1);
		setCondition([...condition]);
	};

	const addButton = () => {
		condition.push({ condition: '', msg: '', type: 'liuChengDan', value: '' });
		setCondition([...condition]);
	};

	const downLoadImg  = (url,fileName) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET',url, true);
		xhr.responseType = 'blob';
		xhr.onload = function() {
		if (this.status === 200) {
			const blob = new Blob([this.response]);
			const blobUrl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = blobUrl;
			a.download = fileName;
			a.click();
			window.URL.revokeObjectURL(blobUrl);
		}
			};
			xhr.send();
	}

	const onPreview = async (file: any) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		// @ts-ignore
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};
	const getAction = (i) => {
		const arr = [];

		if (!hiddenFengMian) {
			arr.push(
				<a
					onClick={() => {
						setFirst(i.img);
					}}
				>
					设为封面
				</a>
			);
		}
		arr.push(
			<a
				onClick={() => {
					openModalUpdate(i);
				}}
			>
				修改
			</a>
		);
		arr.push(
			<a
				onClick={() => {
					deleteImg(i.img);
				}}
			>
				删除图片
			</a>
		);
		arr.push(
			<a
				onClick={()=>downLoadImg(Api.cdnRootPath + i.img,i.img)}
				// href={ Api.cdnRootPath + i.img}
				// download={i.img}
			>
				下载图片
			</a>
		);
		return arr
	};
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: ' flex-end ' }}>
				<div>
					<div hidden={isHidenImgType}>
						{imgType.map((tag) => (
							<CheckableTag
								key={tag.name}
								checked={tag.name === type}
								onChange={(checked: any) => handleChange(checked, tag.name)}
							>
								{tag.name}
							</CheckableTag>
						))}
					</div>
				</div>
				<div>
					<Button type="primary" style={{ marginRight: 10 }} onClick={imgInfos}>
						添加图片
					</Button>
					<Button type="primary" style={{ marginRight: 10 }} onClick={paixun}>
						完成排序
					</Button>
				</div>
			</div>
			<div style={{ paddingTop: 20 }}>图片拖拽排序完成后，请点击完成排序，{imgContent} </div>
			<DndProvider backend={HTMLBackend}>
				<Row>
					{imgs.length > 0
						? imgs.map((i: any, index: any) => (
								<Col>
									<CardItem
										index={index}
										hoverable
										type={'CARD'}
										key={index}
										moveCard={moveCard}
										style={{ marginRight: 20, width: 240, height: 220, marginTop: 30 }}
										cover={
											i.first === 1 ? (
												<div style={{ height: 180, display: 'flex', alignItems: 'center' }}>
													<div style={{}}>
														<Image
															className="meeting-img-one img-auto"
															width="240px"
															// height="240px"
															alt="房间图片"
															src={Api.cdnRootPath + i.img}
														/>
													</div>
													<div style={{ position: 'absolute', top: -4, left: -1 }}>
														<Image
															className="cover-img"
															width="40px"
															height="40px"
															style={{ position: 'absolute', top: 0, left: 0 }}
															alt="会议图片"
															src={FengMianImg}
														/>
													</div>
												</div>
											) : (
												<div style={{ height: 180, display: 'flex', alignItems: 'center' }}>
													<Image
														className="meeting-img-nocover img-auto"
														width="240px"
														// height="240px"
														// alt="会议图片"
														src={Api.cdnRootPath + i.img}
													/>
												</div>
											)
										}
										bodyStyle={{ padding: 0, margin: 0 }}
										actions={getAction(i)}
									></CardItem>
								</Col>
						  ))
						: ''}
				</Row>
			</DndProvider>
			<Modal
				width={1000}
				visible={visible}
				title={add ? '添加' : '修改'}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={addInfoImage}
			>
				<Row align={'middle'} gutter={15} hidden={!add}>
					<Col span={6} style={{ textAlign: 'right' }}>
						添加图片：
					</Col>
					<Col span={18}>
						{!hiddenImgCorp ? (
							<ImgCrop quality={1} aspect={widthRatio / heightRatio} rotate>
								<Upload
									name="image"
									listType="picture-card"
									className="avatar-uploader"
									// showUploadList={false}
									// multiple={true}
									fileList={fileList}
									data={uploadParams}
									action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
									onChange={getMeetFileName}
									onPreview={onPreview}
									accept={'.jpg,.png'}
								>
									{uploadButton}
								</Upload>
							</ImgCrop>
						) : (
							<Upload
								name="image"
								listType="picture-card"
								className="avatar-uploader"
								// showUploadList={false}
								multiple={true}
								fileList={fileList}
								data={uploadParams}
								action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
								onChange={getMeetFileName}
								accept={'.jpg,.png'}
							>
								{uploadButton}
							</Upload>
						)}

						<div>{imgContent}</div>
					</Col>
				</Row>
				<Row align={'middle'} gutter={15} hidden={add}>
					<Col span={6} style={{ textAlign: 'right' }}>
						添加图片:
					</Col>
					<Col span={18}>
						<UploadImg
							uploadParams={uploadParams}
							action={'public'}
							getFileName={(value: any) => {
								getFileName(value);
							}}
							hiddenImgCorp={hiddenImgCorp}
							photo={photo}
							heightRatio={heightRatio}
							widthRatio={widthRatio}
						/>
					</Col>
				</Row>
				<Row align={'middle'} gutter={15} hidden={!add || isHidenImgType}>
					<Col span={6} style={{ textAlign: 'right' }}>
						图片类别:
					</Col>
					<Col span={18}>
						<Select
							value={checkedType}
							onChange={(value: any) => {
								setCheckedType(value);
							}}
						>
							{imgType.map((item: any) => {
								return (
									<Select.Option key={item.name} value={item.name}>
										{item.name}
									</Select.Option>
								);
							})}
						</Select>
					</Col>
				</Row>
				<Row gutter={15} align={'middle'}>
					<Col span={6} style={{ textAlign: 'right' }}>
						跳转类型:
					</Col>
					<Col span={18}>
						<Select
							style={{ width: '100%' }}
							onChange={(value: any) => {
								setJumpType(value);
							}}
							value={jumpType}
						>
							<Select.Option value={'liuChengDan'}>流程单</Select.Option>
							<Select.Option value={'url'}>跳转链接</Select.Option>
						</Select>
					</Col>
				</Row>
				<Row gutter={15} align={'middle'}>
					<Col span={6} style={{ textAlign: 'right' }}>
						跳转路径:
					</Col>
					<Col span={18}>
						{jumpType === 'liuChengDan' ? (
							<Select
								style={{ width: '100%' }}
								showSearch
								value={jumpUrl}
								onChange={(value: any) => {
									setJumpUrl(value);
								}}
								optionFilterProp="children"
								allowClear
							>
								{liuChengList.map((item: any) => {
									return (
										<Select.Option value={item.uuid} key={item.uuid}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						) : (
							<Input
								value={jumpUrl}
								onChange={(e) => {
									setJumpUrl(e.target.value);
								}}
							/>
						)}
					</Col>
				</Row>
				<Row gutter={15}>
					<Col span={6} style={{ textAlign: 'right', marginTop: 10 }}>
						条件判断:
					</Col>
					<Col span={4} style={{ marginTop: 10 }}>
						条件
					</Col>
					<Col span={4} style={{ marginTop: 10 }}>
						提示信息
					</Col>
					<Col span={4} style={{ marginTop: 10 }}>
						类型
					</Col>
					<Col span={4} style={{ marginTop: 10 }}>
						值
					</Col>
				</Row>
				{condition.length > 0 &&
					condition.map((item: any, index: any) => {
						return (
							<>
								<Row align={'middle'} gutter={15}>
									<Col span={6} style={{ marginTop: 10 }} />
									<Col span={4} style={{ marginTop: 10 }}>
										<Input
											value={item.condition}
											onChange={(e) => {
												changeChildrenValue(index, e.target.value, 'condition');
											}}
										/>
									</Col>
									<Col span={4} style={{ marginTop: 10 }}>
										<Input
											value={item.msg}
											onChange={(e) => {
												changeChildrenValue(index, e.target.value, 'msg');
											}}
										/>
									</Col>
									<Col span={4} style={{ marginTop: 10 }}>
										<Select
											style={{ width: '100%' }}
											value={item.type}
											onChange={(value: any) => {
												changeChildrenValue(index, value, 'type');
											}}
										>
											<Select.Option value={'liuChengDan'}>流程单</Select.Option>
											<Select.Option value={'url'}>跳转链接</Select.Option>
										</Select>
									</Col>
									<Col span={4} style={{ marginTop: 10 }}>
										{item.type === 'liuChengDan' ? (
											<Select
												style={{ width: '100%' }}
												showSearch
												value={item.value}
												onChange={(value: any) => {
													changeChildrenValue(index, value, 'value');
												}}
												optionFilterProp="children"
												allowClear
											>
												{liuChengList.map((item: any) => {
													return (
														<Select.Option value={item.uuid} key={item.uuid}>
															{item.name}
														</Select.Option>
													);
												})}
											</Select>
										) : (
											<Input
												value={item.value}
												onChange={(e) => {
													changeChildrenValue(index, e.target.value, 'value');
												}}
											/>
										)}
									</Col>
									<Col span={2} style={{ textAlign: 'right', marginTop: 10 }}>
										<CloseCircleOutlined
											style={{ cursor: 'pointer' }}
											onClick={() => {
												deleteChildrenParams(index);
											}}
										/>
									</Col>
								</Row>
							</>
						);
					})}
				<Row align={'middle'} gutter={15}>
					<Col span={6} style={{ textAlign: 'right' }}></Col>
					<Col span={16}>
						<Button type={'primary'} onClick={addButton}>
							添加条件
						</Button>
					</Col>
				</Row>
				<Row gutter={15} align={'middle'}>
					<Col span={6} style={{ textAlign: 'right' }}>
						描述:
					</Col>
					<Col span={18}>
						<Input.TextArea
							value={note}
							onChange={(e) => {
								setNote(e.target.value);
							}}
						/>
					</Col>
				</Row>
			</Modal>
		</div>
	);
};
export default HouseTypeImg;
