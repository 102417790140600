import React, { useCallback, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import {
	addSchemeTech,
	addSchemeType,
	deleteSchemeTech,
	deleteSchemeType,
	getSchemeTechList,
	getSchemeTypeList,
	getTreeSchemeTechList,
	getTreeSchemeTypeList,
	moveSchemeTech,
	sortSchemeTech,
	sortSchemeType,
	updateSchemeTech,
	updateSchemeType,
} from '../../../services/scheme';
import ConnectState from '../../../models/connect';
import { connect } from 'dva';
import SonTypeList from './SonTypeList';
import HTMLBackend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import update from 'immutability-helper';
import DragableBodyRow from '../../../components/DragableBodyRow';
import DragableThreeBodyRow from '../../../components/DragableThreeBodyRow';
import {Button, Form, FormInstance, Input, message, Modal, Select, Space, Switch, TreeSelect} from 'antd';
import { ActionType } from '@ant-design/pro-table';
import ProxyForm from '../../../components/ProxyForm';
import GsTreeSelect from '../../../components/GsTreeSelect';
import DAL from '../../../data/DAL';
const SchemeTypeList: React.FC = (props: any) => {
	const [loading, setloading] = useState(false);
	const [addVisible, setaddVisible] = useState(false);
	const [data, setdata] = useState([]);
	const actionRef = useRef<ActionType>();
	const [add, setadd] = useState(false);
	const actionRef1 = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const [visible, setvisible] = useState(false);
	const [treeData, settreeData] = useState([]);
	const FormItem = Form.Item;
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [moveVisible, setmoveVisible] = useState(false);
	const [movePid, setmovePid] = useState('');
	const [sortVisible, setSortVisible] = useState(false);
	const [sortData, setSortData] = useState([]);
	const columns1 = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
	];
	const columns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '是否在app显示',
			dataIndex: 'appShow',
			key: 'appShow',
			render: (_, record: any) => <span>{record.appShow ? '显示' : '不显示'}</span>,
		},
		// {
		// 	title: '数量',
		// 	dataIndex: 'number',
		// 	key: 'number',
		// 	render: (_, record: any) => <span>{record.children ? record.children.length :  0}</span>,
		// },
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							openUpdateModal(record);
						}}
					>
						编辑
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];
	const openUpdateModal = (value: any) => {
		const formData: any = {
			uuid: value.uuid,
			name: value.name,
			pid: value.pid == "0" ? undefined : value.pid+"",
			appShow: value.appShow,
			keyword: value.keyword ? value.keyword.split(',') : [],
			downstreamNode: DAL.dal.isNotNull(value.downstreamNode) ? value.downstreamNode.split(',') : undefined,
			note: value.note,
		};
		form.setFieldsValue(formData);
		setadd(false);
		setvisible(true);
	};
	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '你确定要删除' + value.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteSchemeTech({ uuid: value.uuid });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error(res.msg);
				}
			},
		});
	};

	const findArray = (data: any, id: any, dragIndex: number, hoverIndex: number) => {
		data.map(async (item: any) => {
			if (item.id === id) {
				const dragRow = data[dragIndex];
				let newArr = update(data, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragRow],
					],
				});
				newArr.map((item: any, index: number) => {
					item.order = index;
				});
				let params = {
					data: JSON.stringify(newArr),
				};
				console.log('params:::', params);

				setloading(true);
				let rsp = await sortSchemeTech(params);
				setloading(false);
				if (rsp.err === 0) {
					if (actionRef.current) {
						actionRef.current.reload();
					}
				}
				return;
			}
			if (item.children) {
				findArray(item.children, id, dragIndex, hoverIndex);
			}
		});
	};
	const renChildren = (list) => {
		list.forEach((element) => {
			element.title = element.name;
			element.value = element.id+"";
			if (element.children != undefined && element.children.length > 0) {
				renChildren(element.children);
			}
		});
	};
	const getData = async () => {
		setloading(true);
		let rsp = await getTreeSchemeTechList({});
		setloading(false);
		if (rsp.err === 0) {
			setdata([...rsp.data]);
			const data = rsp.data || [];
			renChildren(data);
			settreeData([...data]);
			return {
				data: rsp.data,
			};
		} else {
			return {
				data: [],
			};
		}
	};
	const tableOperationsBarRender = () => [
		<Button
			type={'primary'}
			onClick={() => {
				setvisible(true);
				setadd(true);
			}}
		>
			{' '}
			添加
		</Button>,
		<Button
			type="primary"
			onClick={async () => {
				let rsp = await getSchemeTechList({ pid: 0 });
				if (rsp.err === 0) {
					setSortData([...rsp.data]);
					setSortVisible(true);
				} else {
					message.error(rsp.msg);
				}
			}}
		>
			编辑排序
		</Button>,
	];
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			const formData:any = {
				name: data.name,
				appShow: data.appShow,
				keyword: (data.keyword || []).toString(),
				pid: data.pid,
				// downstreamNode: DAL.dal.isNotNull(data.downstreamNode) ? data.downstreamNode.toString() : '',
				note: data.note,
			};
			if (data.downstreamNode != undefined && data.downstreamNode.length > 0) {
				let values = [];
				const flag = Object.keys(data.downstreamNode[data.downstreamNode.length - 1]).indexOf('value');
				if (flag < 0) {
					formData.downstreamNode = data.downstreamNode.toString();
				} else {
					data.downstreamNode.map((item) => {
						values.push(item.value);
					});
					formData.downstreamNode = values.toString();
				}
			} else {
				formData.downstreamNode = '';
			}
			const res = await addSchemeTech(formData);
			if (res.err === 0) {
				message.success('添加成功');
				setvisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData :any= {
				uuid: data.uuid,
				name: data.name,
				keyword: (data.keyword || []).toString(),
				pid: data.pid,
				appShow: data.appShow,
				// downstreamNode: DAL.dal.isNotNull(data.downstreamNode) ? data.downstreamNode.toString() : '',
				note: data.note,
			};
			if (data.downstreamNode != undefined && data.downstreamNode.length > 0) {
				let values = [];
				const flag = Object.keys(data.downstreamNode[data.downstreamNode.length - 1]).indexOf('value');
				if (flag < 0) {
					formData.downstreamNode = data.downstreamNode.toString();
				} else {
					data.downstreamNode.map((item) => {
						values.push(item.value);
					});
					formData.downstreamNode = values.toString();
				}
			} else {
				formData.downstreamNode = '';
			}
			const res = await updateSchemeTech(formData);
			if (res.err === 0) {
				message.success('修改成功');
				setvisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
		});
	};
	const saveItem = () => {
		if (add) {
			addItem();
		} else {
			updateItem();
		}
	};
	const moveItem = async () => {
		const res = await moveSchemeTech({ ids: selectedRowKeys.toString(), pid: movePid });
		if (res.err == 0) {
			setmoveVisible(false);
			message.success('移动成功');
			setselectedRowKeys([]);
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			message.error(res.msg);
		}
	};
	const components = {
		body: {
			row: DragableBodyRow,
		},
	};
	const getSortData = async () => {
		return {
			data: sortData,
		};
	};
	const moveRow = useCallback(
		async (dragIndex, hoverIndex, key) => {
			if (dragIndex === hoverIndex) {
				return;
			}
			console.log('dragIndex', dragIndex);
			console.log('hoverIndex', hoverIndex);
			let route = [...sortData];
			const d1 = route[dragIndex];
			const ho = route[hoverIndex];
			console.log('data::', d1, ho);
			let tmp = route[dragIndex]; //临时储存文件
			route.splice(dragIndex, 1); //移除拖拽项
			route.splice(hoverIndex, 0, tmp); //插入放置项
			setSortData([...route]);
			// findArray(routes, key, dragIndex, hoverIndex);
			actionRef1.current.reload();
		},
		[sortData]
	);

	const sortDataItem = async () => {
		console.log('排序：：：', '排序');
		const data = sortData;
		let ids: any = [];
		data.map((item) => {
			ids.push(item.id);
		});
		console.log('ids', ids);
		const res = await sortSchemeTech({ ids: ids.toString() });
		if (res.err === 0) {
			setSortVisible(false);
			message.success('排序成功');
			actionRef.current.reload();
		} else {
			message.error('排序失败');
		}
	};
	return (
		<>
			<GSTable
				operationsBarRender={tableOperationsBarRender()}
				actionRef={actionRef}
				loading={loading}
				pagination={false}
				columns={columns}
				request={getData}
				rowKey="id"
				rowSelection={{
					fixed: true,
					columnWidth: 100,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setselectedRowKeys(selectedRowKeys);
					},
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					console.log('tableAlertOptionRender::::', selectedRowKeys);
					return (
						<Space size={16}>
							<a
								onClick={() => {
									setmovePid('');
									setmoveVisible(true);
								}}
							>
								迁移
							</a>
						</Space>
					);
				}}
			/>
			<Modal
				visible={visible}
				title={add ? '添加' : '修改'}
				onOk={saveItem}
				onCancel={() => {
					setvisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem hidden={true} name={'uuid'}>
						<Input />
					</FormItem>
					<FormItem name={'name'} label={'名称'} rules={[{ required: true }]}>
						<Input />
					</FormItem>
					<FormItem name={'pid'} label="父节点">
						<GsTreeSelect
							style={{ width: '100%' }}
							checkable={false}
							treeCheckable={false}
							treeData={treeData}
							treeNodeFilterProp={'title'}
							placeholder={'应用场景'}
							multiple={false}
							defaultExpandAll={false}
						/>
					</FormItem>
					<FormItem name={'downstreamNode'} label="下游节点">
						<GsTreeSelect
							maxTagCount={99}
							style={{ width: '100%' }}
							treeCheckStrictly={true}
							showCheckedStrategy={TreeSelect.SHOW_ALL}
							treeData={treeData}
							treeNodeFilterProp={'title'}
							placeholder={'下游节点'}
							multiple={false}
							defaultExpandAll={false}
						/>
					</FormItem>
					<FormItem name={'keyword'} label="关键词">
						<Select mode={"tags"} tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}>

						</Select>
					</FormItem>
					<FormItem name={'note'} label="描述">
						<Input.TextArea></Input.TextArea>
					</FormItem>
					<FormItem valuePropName="checked" name={'appShow'} label={'是否在app显示'}>
						<Switch></Switch>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal title="迁移" visible={moveVisible} onCancel={() => setmoveVisible(false)} onOk={moveItem}>
				<GsTreeSelect
					style={{ width: '100%' }}
					checkable={false}
					treeCheckable={false}
					treeData={treeData}
					treeNodeFilterProp={'title'}
					placeholder={'父级'}
					multiple={false}
					defaultExpandAll={false}
					onChange={(value) => setmovePid(value)}
				/>
			</Modal>
			<Modal
				visible={sortVisible}
				title={'排序'}
				onCancel={() => {
					setSortVisible(false);
				}}
				onOk={sortDataItem}
			>
				<DndProvider backend={HTMLBackend}>
					<GSTable
						options={false}
						actionRef={actionRef1}
						components={components}
						onRow={(record: any, index: any) => ({
							index,
							moveRow,
						})}
						columns={columns1}
						request={getSortData}
						pagination={false}
					/>
				</DndProvider>
			</Modal>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(SchemeTypeList);
