import * as React from 'react';
import DAL from '../../data/DAL';
import API from '../../data/API';
import { Card, Col, Row } from 'antd';
import {
	getActivity,
	getCompanyEmployeeCount,
	getAppSumCount,
	getAppNewAddCount,
	getRoomIsCheckIn,
	getNewlyAddedCompanyCount,
	getRecordStatisticsApi,
	getPatrolDocumentationRecord,
} from '../../services/controlPlatformService';
var msg = require('../../util/SuspensionMsg');
let echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/pie'); //图表类型
require('echarts/lib/component/title'); //标题插件

class BasicFacts extends React.Component {
	deviceId = '';
	dal = DAL.dal;
	contact = '';
	users2 = [];
	sumChart: any;
	successChart: any;
	failChart: any;
	failChart1: any;
	incomeChart: any;
	openNumChart: any;
	activiteStatisticsChart: any;
	customerNumChart: any;
	companyNumChart: any;

	state: any;
	constructor(props: any) {
		super(props);
		this.state = {
			value: '',
			users: [],
			isShow: false,
			name: '',
			img_type: '',
			room_number: '',
			create_time: '',
			file_name: '',
			valid_time: '',
			state: '',
			user_id: '',
			valid_mill: '',
			wuye_number: '',
			inputStr: '',
			totalZaiZhuHuShu: 0,
			totalKongFangHuShu: 0,
			companyCount: 0,
			employeeCount: 0,
			monthSum: [],
			month: [],
			monthSumCompanyCount: [],
			monthCompany: [],
			week: [],
			weekSum: [],
			activity: [],
			appSum: [],
			loadingGuanLiHuShuData: true,
			loadingAPPShiYongData: true,
			loadingCompanyData: true,
			loadingAddCompanyData: true,
			loadingAuditData: false,
			loadingUnlockData: true,
			loadingFuWuData: false,
			loadingXunGengData: true,
		};
		this.setPieOption = this.setPieOption.bind(this);
		this.sum = this.sum.bind(this);
		this.fial = this.fial.bind(this);
		this.fial1 = this.fial1.bind(this);
		this.success = this.success.bind(this);
	}

	componentDidMount() {
		this.getRoomIsCheckIn();
		this.getCompanyCount();
		this.getRecordStatisticsApi();
		this.getAppNewAddCount();
		this.getNewlyAddedCompany();
		this.patrolDocumentation();
		this.getAppSumCount();
		this.getActivity();
	}

	getActivity = async () => {
		const res = await getActivity({});
		// API.postWithAuth('/api/statistice/get_activity', {}).then(res => {
		if (res.err === 0) {
			this.setState({
				activity: res.data,
				loadingGuanLiHuShuData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		this.activiteStatisticsChart.setOption(this.manageHouserNum(this.state.activity, '', ['投资类']));
		// });
	};

	//获得房间是否空房数量
	getRoomIsCheckIn = async () => {
		const res = await getRoomIsCheckIn({});
		// API.postWithAuth('/api/statistice/get_room_is_checkIn', {}).then(res => {
		if (res.err === 0) {
			console.log('是否空房数量', res);
			this.setState({
				totalZaiZhuHuShu: res.totalZaiZhuHuShu,
				totalKongFangHuShu: res.totalKongFangHuShu,
				loadingGuanLiHuShuData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		this.sumChart.setOption(
			this.manageHouserNum(
				[
					{ value: this.state.totalZaiZhuHuShu, name: '已入住' },
					{ value: this.state.totalKongFangHuShu, name: '空房' },
				] as any,
				'',
				['已入住', '空房']
			)
		);
		// });
	};

	//获得app新增使用人数
	getAppNewAddCount = async () => {
		const res = await getAppNewAddCount({});
		// API.postWithAuth('/api/statistice/get_app_new_add_count', {}).then(res => {
		if (res.err === 0) {
			console.log('app新增使用人数', res);
			this.setState({
				month: res.month,
				monthSum: res.monthSum,
				loadingAPPShiYongData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		if (this.customerNumChart !== undefined) {
			this.customerNumChart.setOption(this.customerNumC(this.state.month, this.state.monthSum));
		}
		// });
	};

	//获得app使用人数
	getAppSumCount = async () => {
		const res = await getAppSumCount({});

		// API.postWithAuth('/api/statistice/get_app_sum_count', {}).then(res => {
		if (res.err === 0) {
			console.log('app使用人数', res);
			this.setState({
				appSum: res.data,
				loadingAPPShiYongData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		this.failChart.setOption(this.circularDiagram(this.state.appSum));
		// });
	};

	//企业员工总数
	getCompanyCount = async () => {
		const res = await getCompanyEmployeeCount({});
		// API.postWithAuth('/api/statistice/get_company_employee_count', {}).then(res => {
		if (res.err === 0) {
			console.log('企业员工总数', res);
			this.setState({
				companyCount: res.companyCount,
				employeeCount: res.employeeCount,
				loadingCompanyData: false,
			});
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'danger' });
		}
		this.incomeChart.setOption(this.companyEmployee(this.state.companyCount, this.state.employeeCount));
		// });
	};

	//新增企业数量
	getNewlyAddedCompany = async () => {
		const res = await getNewlyAddedCompanyCount({});

		// API.postWithAuth('/api/statistice/get_newly_added_company_count', {}).then(res => {
		if (res.err === 0) {
			console.log('新增企业数量', res);
			this.setState({
				monthCompany: res.monthCompany,
				monthSumCompanyCount: res.monthSumCompanyCount,
				loadingAddCompanyData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		if (this.companyNumChart !== undefined) {
			this.companyNumChart.setOption(this.companyNumC(this.state.monthCompany, this.state.monthSumCompanyCount));
		}
		// });
	};

	//获取开锁记录统计
	getRecordStatisticsApi = async () => {
		const res = await getRecordStatisticsApi({});

		// API.postWithAuth('/api/statistics/getRecordStatisticsApi', {}).then(res => {
		if (res.err === 0) {
			this.setState({
				dateList: res.dateList,
				sumList: res.sumList,
				loadingUnlockData: false,
			});
		} else {
			msg.suspensionMsg(res.msg);
		}
		if (this.openNumChart !== undefined) {
			this.openNumChart.setOption(
				this.openNumChartData((this.state as any).dateList, (this.state as any).sumList)
			);
		}
		// });
	};

	//管理户数
	manageHouserNum = (data: any, title: string, legendArray: any) => {
		return {
			tooltip: {},
			series: [
				{
					name: title,
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'],
					data: data,
					avoidLabelOverlap: true,
					label: {
						normal: {
							show: true,
							position: 'outside',
							formatter: '{b}: {c}',
						},
						emphasis: {
							show: true,
						},
					},
				},
			],
		};
	};

	//报事处理
	circularDiagram = (data: any) => {
		return {
			tooltip: {},
			series: [
				{
					name: '访问来源',
					type: 'pie',
					radius: ['50%', '70%'],
					data: data,
					avoidLabelOverlap: true,
					label: {
						normal: {
							show: true,
							position: 'outside',
							formatter: '{b}: {c}',
						},
						emphasis: {
							show: true,
							textStyle: {
								fontSize: '14',
							},
						},
					},
					/*labelLine: {
                        normal: {
                            show: false
                        }
                    },*/
				},
			],
		};
	};

	//巡更打点记录
	patrolDocumentation = async () => {
		const res = await getPatrolDocumentationRecord({});

		// API.postWithAuth('/api/statistice/get_patrol_documentation_record', {}).then(res => {
		if (res.err === 0) {
			this.setState({
				week: res.week,
				weekSum: res.weekSum,
				loadingXunGengData: false,
			});
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'danger' });
		}
		if (this.successChart !== undefined) {
			this.successChart.setOption(this.rateOfCharge(this.state.week, this.state.weekSum));
		}
		// });
	};

	companyNum = (chart: any) => {
		if (chart !== null) {
			this.companyNumChart = echarts.init(chart);
		}
	};

	activiteStatistics = (chart: any) => {
		if (chart !== null) {
			this.activiteStatisticsChart = echarts.init(chart);
		}
	};

	income = (chart: any) => {
		if (chart !== null) {
			this.incomeChart = echarts.init(chart);
		}
	};

	openNum = (chart: any) => {
		if (chart !== null) {
			this.openNumChart = echarts.init(chart);
		}
	};

	customerNum = (chart: any) => {
		if (chart !== null) {
			this.customerNumChart = echarts.init(chart);
		}
	};

	sum(chart: any) {
		console.log('chart对象', chart);
		if (chart !== null) {
			this.sumChart = echarts.init(chart);
		}
	}

	fial(chart: any) {
		if (chart !== null) {
			this.failChart = echarts.init(chart);
		}
	}

	fial1(chart: any) {
		if (chart !== null) {
			this.failChart1 = echarts.init(chart);
		}
	}

	success(chart: any) {
		if (chart !== null) {
			console.log('图表对象', chart);
			this.successChart = echarts.init(chart);
		}
	}
	//入驻企业人员
	companyNumC = (monthCompany: any, monthSumCompanyCount: any) => {
		return {
			xAxis: [
				{
					type: 'category',
					data: monthCompany,
				},
			],
			yAxis: [
				{
					type: 'value',
				},
			],
			series: [
				{
					name: '蒸发量',
					type: 'bar',
					data: monthSumCompanyCount,
					markPoint: {
						data: [
							{ type: 'max', name: '最大值' },
							{ type: 'min', name: '最小值' },
						],
					},
					markLine: {
						data: [{ type: 'average', name: '平均值' }],
					},
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	customerNumC = (month: any, monthSum: any) => {
		return {
			xAxis: {
				type: 'category',
				data: month,
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					data: monthSum,
					type: 'bar',
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	customerSumNumC = (month: any, monthSum: any) => {
		return {
			xAxis: {
				type: 'category',
				data: month,
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					data: monthSum,
					type: 'bar',
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	companyEmployee = (companyCount: any, employeeCount: any) => {
		return {
			xAxis: {
				type: 'category',
				data: ['企业', '员工'],
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					data: [companyCount, employeeCount],
					type: 'bar',
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	rateOfCharge = (week: any, weekSum: any) => {
		return {
			xAxis: {
				type: 'category',
				data: week,
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					data: weekSum,
					type: 'line',
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	openNumChartData = (dateList: any, sumList: any) => {
		return {
			xAxis: {
				type: 'category',
				data: dateList,
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
			},
			yAxis: {
				type: 'value',
				axisLabel: {
					formatter: '{value}',
				},
			},
			series: [
				{
					data: sumList,
					type: 'line',
					label: {
						normal: {
							show: true,
							position: 'top',
						},
					},
				},
			],
		};
	};

	//一个基本的echarts图表配置函数
	setPieOption(data: any, title: string) {
		return {
			title: {
				x: 'center', //标题位置
				y: 'center',
				text: title, //传入标题名称‘参保情况’
				textStyle: {
					//标题字体颜色等设置
					fontSize: 16,
					fontWeight: 'bold',
				},
			},
			legendHoverLink: true,
			series: [
				{
					name: '比例',
					type: 'pie',
					radius: ['40%', '80%'],
					data: data, //传入外部的data数据
					label: {
						normal: {
							show: false,
							position: 'inner',
							textStyle: {
								fontSize: '12',
							},
							formatter: '{b}:{c}',
						},
						emphasis: {
							show: true,
							textStyle: {
								fontSize: '12',
							},
						},
					},
					labelLine: {
						normal: {
							show: false,
						},
					},
				},
			],
		};
	}
	componentWillUnmount() {
		console.log('...............................', this.refs.chart);
	}

	render() {
		return (
			<div className="content-big">
				<Row gutter={16}>
					<Col span={6}>
						<Card title="管理户数" bordered={false} loading={this.state.loadingGuanLiHuShuData}>
							<canvas ref={this.sum} width="400" height="400" style={{ width: '100%', height: '100%' }}>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="APP新增人数" bordered={false} loading={this.state.loadingAPPShiYongData}>
							<canvas
								ref={this.customerNum}
								width="400"
								height="400"
								style={{ width: '100%', height: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="企业员工总数" bordered={false} loading={this.state.loadingCompanyData}>
							<canvas
								ref={this.income}
								width="400"
								height="400"
								style={{ width: '100%', height: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="新增企业" bordered={false} loading={this.state.loadingAddCompanyData}>
							<canvas
								ref={this.companyNum}
								width="400"
								height="400"
								style={{ width: '100%', height: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
				</Row>

				<br />

				<Row gutter={16}>
					<Col span={6}>
						<Card title="APP总用户数" bordered={false} loading={this.state.loadingFuWuData}>
							<canvas ref={this.fial} width="400" height="400" style={{ width: '100%', height: '100%' }}>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="活动场次" bordered={false} loading={this.state.loadingAuditData}>
							<canvas
								ref={this.activiteStatistics}
								width="400"
								height="400"
								style={{ width: '100%', height: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="近一月开门次数" bordered={false} loading={this.state.loadingUnlockData}>
							<canvas
								ref={this.openNum}
								width="400"
								height="400"
								style={{ width: '100%', height: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
					<Col span={6}>
						<Card title="巡更记录" bordered={false} loading={this.state.loadingXunGengData}>
							<canvas
								ref={this.success}
								width="400"
								height="400"
								style={{ width: '100%', height: '100%' }}
							>
								x
							</canvas>
						</Card>
					</Col>
				</Row>

				{(this.state as any).isShow ? (
					<div className="cover">
						<div>
							<i className="icon icon-spin icon-spinner-indicator" />
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default BasicFacts;
