import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Image, Input, message, Modal, Row, Select, Space, Switch } from 'antd';
import Api from '../../../data/API';
import DAL from '../../../data/DAL';
import { addImgDevice, findWuyeDevicePatrol, getPlanByRoute, getPlanByUUID } from '../../../services/patrolService';
import { Link } from 'react-router-dom';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table';
import $ from 'jquery';
require('./patrolplane.css');
var msg = require('../../../util/SuspensionMsg');
const Search = Input.Search;
let arr: any = [];
let x: any = 0;
let y: any = 0;
let mouseDownAndUpTimer: any = null;
let mark: any = {}; //创建一个对象，
let index: any = 0;
let isDown: any = false;
let x1: any = 0;
let y1: any = 0;
let l1: any = 0;
let t1: any = 0;
let x2: any = 0;
let y2: any = 0;
let onMouseDownFlag: any = true;
let removeitem: any = true;
const confirm = window.confirm;
const PatrolPlane: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const dal = DAL.dal;
	const planUUID = dal.getQueryString('uuid') || '';
	const readOnly = dal.getQueryString('readOnly') == 'true';
	useEffect(() => {
		setPlanState(true);
		findPlanByRoute(true);
	}, []);
	const [search, setSearch] = useState('');
	const [planState, setPlanState] = useState(false);
	const [visible, setVisible] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [showName, setShowName] = useState(false);
	const [imgName, setImgName] = useState('');
	const [type, setType] = useState(undefined);
	const [position, setPosition] = useState([]);
	const [loading, setLoading] = useState(false);
	const types = [
		{
			name: '蓝牙',
			value: 'ble',
		},
		{
			name: '二维码',
			value: 'qrcode',
		},
		{
			name: '拍照',
			value: 'photo',
		},
	];
	const valueEnum = {
		ble: <span>蓝牙</span>,
		qrcode: <span>二维码</span>,
		photo: <span>拍照</span>,
	};
	const columns = [
		{
			title: '设备Id',
			dataIndex: 'deviceId',
			key: 'deviceId',
		},
		{
			title: '设备名称',
			dataIndex: 'deviceName',
			key: 'deviceName',
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
			valueEnum: valueEnum,
		},
		{
			title: '设备备注',
			dataIndex: 'deviceDescribe',
			key: 'deviceDescribe',
		},
		{
			title: '设备图片',
			dataIndex: 'deviceImg1',
			key: 'deviceImg1',
			render: (text: any, item: any) =>
				item.deviceImg1 === null || item.deviceImg1 === undefined || item.deviceImg1 === '' ? (
					'无'
				) : (
					<Image width={100} height={100} src={Api.cdnRootPath + item.deviceImg1} />
				),
		},
		{
			title: '设备图片',
			dataIndex: 'deviceImg2',
			key: 'deviceImg2',
			render: (text: any, item: any) =>
				item.deviceImg2 === null || item.deviceImg2 === undefined || item.deviceImg2 === '' ? (
					'无'
				) : (
					<Image width={100} height={100} src={Api.cdnRootPath + item.deviceImg2} />
				),
		},
		{
			title: '设备图片',
			dataIndex: 'deviceImg3',
			key: 'deviceImg3',
			render: (text: any, item: any) =>
				item.deviceImg3 === null || item.deviceImg3 === undefined || item.deviceImg3 === '' ? (
					'无'
				) : (
					<Image width={100} height={100} src={Api.cdnRootPath + item.deviceImg3} />
				),
		},
		{
			title: '操作',
			key: 'activity',
			dataIndex: 'activity',
			render: (text: string, record: any) => {
				return (
					<span>
						<a
							onClick={() => {
								selectItem(record);
							}}
						>
							选择
						</a>
					</span>
				);
			},
		},
	];
	const findPlanByRoute = async (planState1: any) => {
		const res = await getPlanByRoute({
			planUUID: planUUID,
		});

		if (res.err === 0) {
			console.log('1111111111111111', res);
			setIsShow(false);
			if (res.data.imgName !== null && res.data.imgName !== '' && res.data.imgName !== undefined) {
				console.log('po', res.data.imgName, planState, res.data.position, readOnly);
				setImgName(Api.cdnRootPath + res.data.imgName);
				setPosition(res.data.position);
				plane(Api.cdnRootPath + res.data.imgName, planState1, res.data.position, readOnly, showName);
				// Plane1.plane(Api.cdnRootPath + res.data.imgName, planState1, res.data.position, readOnly, showName);
			}
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
	};

	const showAllName = (showName: any) => {
		if (arr != '' && arr != undefined) {
			if (typeof arr !== 'object') {
				arr = JSON.parse(arr);
			}
		}
		console.log('showName::', showName);
		const container = getid('container');
		const size = arr.length + 1;
		console.log('arr:::', position);
		for (let i = 0; i < size; i++) {
			if (getid('content_p' + i)) {
				container.removeChild(getid('content_p' + i));
			}
		}

		for (let i = 0; i < arr.length; i++) {
			console.log('21212121', arr);
			addMark1(container, arr[i].x, arr[i].y, arr[i].text_val, i, showName); //在页面上创建和本地存储对应的内容
		}
	};

	const addMark1 = (p: any, x: any, y: any, text_val: any, index: any, showName: any) => {
		console.log('showName::', showName);
		if (showName) {
			const content_p = document.createElement('p');
			content_p.className = 'content_p';
			console.log('text_val::' + text_val);
			content_p.innerHTML = text_val; //给p标签添加内容
			content_p.id = 'content_p' + index;
			content_p.style.left = x + 'px';
			content_p.style.top = y + 'px';
			p.appendChild(content_p);
		} else {
			$('#mark' + index).hover(
				function () {
					const content_p = document.createElement('p');
					content_p.className = 'content_p';
					console.log('text_val::' + text_val);
					content_p.innerHTML = text_val; //给p标签添加内容
					content_p.id = 'content_p' + index;
					content_p.style.left = x + 'px';
					content_p.style.top = y + 'px';
					p.appendChild(content_p);
				},
				function () {
					p.removeChild(getid('content_p' + index));
				}
			);
		}
	};
	const isNotBlack = (string: any) => {
		if (string !== null && string !== undefined && string !== '') {
			return true;
		} else {
			return false;
		}
	};
	const add = (val: any, id: any, showName: any) => {
		var container = getid('container');
		if (isNotBlack(val) && isNotBlack(id)) {
			//点击确认按钮时调用
			console.log(arr);
			const text_val = val;
			const deviceId = id;
			if (deviceId === undefined || deviceId === '') {
				message.warning('未选择设备！');
				return;
			}
			console.log('radio', text_val, deviceId);
			if (arr != '' && arr != undefined) {
				if (typeof arr !== 'object') {
					arr = JSON.parse(arr);
				}
			} else {
				arr = [];
				console.log('本地存储没有值');
			}
			var flag = true;
			for (var i = 0; i < arr.length; i++) {
				console.log('遍历Arr:', arr[i]);
				if (arr[i].deviceId === deviceId) {
					flag = false;
					break;
				}
			}
			if (flag) {
				addMark(container, x, y, text_val, arr.length, deviceId, showName); //创建内容
			} else {
				message.warning('巡更点已存在');
				return;
			}
			index++;
			console.log('arr.length', eval(arr).length);
			mark.x = x;
			mark.y = y;
			mark.text_val = text_val;
			mark.deviceId = deviceId;
			//arr = sessionStorage['arr']   //获取本地存储里面的值
			console.log(mark);
			arr.push(mark); //把x，y坐标和文字内容放到数组里面
			console.log(arr);
			saveMark(); //调用本地存储的函数
			orderByPatrol(arr);
			bindEvent('', '');
			message.success('添加成功');

			setVisible(false);
			showAllName(showName);
		}
	};
	const selectItem = (record: any) => {
		add(record.deviceName, record.uuid, showName);
	};
	const callback = async () => {
		if (arr.length === 0) {
			message.warning('请标记巡更点');
			return;
		}
		setLoading(true)
		const res = await addImgDevice({
			data: JSON.stringify(arr),
			planUUID: planUUID,
		});
		setLoading(false)
		console.log('', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '设置成功', type: 'success' });
			window.location.href = '/patrolinspection/plan-management';
		} else {
			msg.suspensionMsg({ content: res.msg, type: 'danger' });
			return;
		}
	};
	const mapClick = () => {
		setVisible(true);
	};

	const plane = (img: any, state: any, position: any, readonlys: any, showName: any) => {
		console.log('jinrulplane1');
		//页面一加载调用
		if (readonlys) {
			bindEvent('', '');
		}
		state == true ? loadMark(position, showName) : bindEvent('', '');
		const container = getid('container');
		console.log('container:', container);
		const reg = new RegExp('"', 'g');
		let map = document.getElementById('map');
		const canvasNew = document.createElement('canvas');
		const Newimage = document.createElement('img');
		const context = canvasNew.getContext('2d');
		const imgData = localStorage.getItem('pdfsaveimg');
		const pdfPageIndex = localStorage.getItem('pageindex');
		const canvasWidth = localStorage.getItem('imgWidth');
		const canvasHeight = localStorage.getItem('imgHeight');
		console.log('pdfPageIndex', pdfPageIndex);
		console.log('Width, Height', canvasWidth, canvasHeight);
		console.log('canvasNew', canvasNew);
		console.log('img', img);
		Newimage.src = img;
		Newimage.style.width = '1000px';
		container.style.width = '1000px';
		container.style.minHeight = '800px';
		map.appendChild(Newimage);
	};
	const getid = (id: any) => {
		//封装函数，通过id获取该id的html标签对象
		return document.getElementById(id);
	};
	const bindEvent = (val: any, id: any) => {
		getid('map').onclick = function (oEvent: any) {
			//点击图片时调用
			console.log(arr);
			oEvent = oEvent;
			const container = getid('container');
			const context: any = getid('context');
			const offset = getOffset(container); //调用getOffset函数
			console.log(offset.y);
			console.log(oEvent.clientY);
			const scrollTop = context.scrollTop
			console.log("scrollTop::::", context.scrollTop);
			x = oEvent.clientX - offset.x; //用浏览器窗口的可视区域减去getOffset函数返回的x值
			y = oEvent.clientY - offset.y + scrollTop;
			//addInput(container, x, y, arr.length);
			const i = arr.length;
			console.log('val::', val, 'id::', id);
		};
	};
	const getOffset = (obj: any) => {
		//封装坐标函数
		let x = 0,
			y = 0;
		while (obj) {
			x += obj.offsetLeft; //offsetLeft返回的就是元素距离父元素左边的距离，单位是像素
			y += obj.offsetTop; //offsetTop返回的就是元素距离父元素上边的距离，单位是像素
			obj = obj.offsetParent; //指定的父元素
		}
		return {
			//返回x和y的坐标
			x: x,
			y: y,
		};
	};
	const loadMark = (position: any, showName: any) => {
		arr = JSON.stringify(position);
		if (arr != '' && arr != undefined) {
			console.log('position', arr);
			arr = JSON.parse(arr);
		} else {
			arr = [];
			console.log('本地存储没有值');
		}
		console.log(arr);
		if (arr) {
			//判断本地存储里面是否有值
			var container = getid('container');
			for (var i = 0; i < arr.length; i++) {
				console.log('21212121', arr);
				addMark(container, arr[i].x, arr[i].y, arr[i].text_val, i, arr[i].deviceId, showName); //在页面上创建和本地存储对应的内容
			}
		}
		orderByPatrol(arr);
	};

	const addMark = (p: any, x: any, y: any, text_val: any, index: any, deviceId: any, showName: any) => {
		//封装创建小红点和输入文字内容的函数
		let container = getid('container');
		const div = document.createElement('div'); //创建div元素
		div.id = 'mark' + index; //给div元素添加id
		div.className = 'mark'; //给div元素添加class
		div.style.left = x + 'px'; //div的css属性
		div.style.top = y + 'px';
		if (readOnly) {
			div.onmousedown = function (e) {
				//onMouseDownFlag = false;
				mouseDownAndUpTimer = setTimeout(function () {
					setShowName(false);
					moveItem(index, deviceId, e, text_val);
					onMouseDownFlag = false;
				}, 200);
			};

			//鼠标抬起事件
			div.onmouseup = function () {
				//开关关闭
				isDown = false;
				div.style.cursor = 'default';
				console.log('deviceId', deviceId);
				if (onMouseDownFlag) {
					console.log('移动2', onMouseDownFlag);
					div.onclick = function () {
						if (arr[index].flag) {
							message.warning('此点位已有路线使用，不能删除');
						} else {
							if (confirm('你确定删除此巡更点吗？')) {
								cleanMarkItem(index, deviceId);
							}
						}
					};
				} else {
					div.onclick = function () {
						console.log('移动0000000000000000000');
						cleanMarkItem(index, deviceId);
						if (arr != '' && arr != undefined) {
							if (typeof arr !== 'object') {
								arr = JSON.parse(arr);
							}
						} else {
							arr = [];
						}
						mark.x = x2;
						mark.y = y2;
						mark.text_val = text_val;
						mark.deviceId = deviceId;
						console.log('1111', mark);
						arr.push(mark);
						saveMark();
						orderByPatrol(arr);
						console.log('移动1111111111', JSON.parse(arr));
						addMark(container, x2, y2, text_val, index, deviceId, showName);
					};
					removeitem = false;
					x = x2;
					y = y2;
				}
				clearTimeout(mouseDownAndUpTimer);

				onMouseDownFlag = true;
			};
		}

		p.appendChild(div); //把这个div元素追加到传过来的元素的下面
		removeitem = true;
		console.log('item', removeitem);
		// if (removeitem) {
		// 	$('#mark' + index).hover(
		// 		function () {
		// 			var content_p = document.createElement('p');
		// 			content_p.className = 'content_p';
		// 			console.log('text_val::' + text_val);
		// 			content_p.innerHTML = text_val; //给p标签添加内容
		// 			content_p.id = 'content_p' + index;
		// 			content_p.style.left = x + 'px';
		// 			content_p.style.top = y + 'px';
		// 			p.appendChild(content_p);
		// 		},
		// 		function () {
		// 			p.removeChild(Plane1.getid('content_p' + index));
		// 		}
		// 	);
		// }
		showAllName(false);
	};

	const moveItem = (index: any, deviceId: any, e: any, text_val: any) => {
		// if (arr != '' && arr != undefined) {
		// 	if (typeof arr !== 'object') {
		// 		arr = JSON.parse(arr);
		// 	}
		// }
		// console.log('showName::', showName);
		// const container = Plane1.getid('container');
		// const size = arr.length + 1;
		// console.log('arr:::', position);
		// for (let i = 0; i < size; i++) {
		// 	if (Plane1.getid('content_p' + i)) {
		// 		container.removeChild(Plane1.getid('content_p' + i));
		// 	}
		// }
		let movediv: any = document.getElementById('mark' + index);
		x1 = e.clientX;
		y1 = e.clientY;
		//获取左部和顶部的偏移量
		l1 = movediv.offsetLeft;
		t1 = movediv.offsetTop;
		//开关打开
		isDown = true;
		//设置样式
		movediv.style.cursor = 'move';
		//鼠标移动
		window.onmousemove = function (e) {
			if (isDown == false) {
				return;
			}
			//获取x和y
			var nx = e.clientX;
			var ny = e.clientY;
			//计算移动后的左偏移量和顶部的偏移量
			x2 = nx - (x1 - l1);
			y2 = ny - (y1 - t1);
			movediv.style.left = x2 + 'px';
			movediv.style.top = y2 + 'px';
		};
	};
	const cleanMarkItem = (index: any, deviceId: any) => {
		//获取本地存储里面的值
		if (arr != '' && arr != undefined && typeof arr !== 'object') {
			arr = JSON.parse(arr);
		}
		console.log('fjlsdjflsdjflds', arr, index, deviceId);
		if (arr.length === 0) {
			alert('请标记巡更点');
		} else {
			const container = getid('container');
			const content_p = getid('content_p' + index);
			console.log('content_p::', content_p);
			container.removeChild(content_p);

			const mark = getid('mark' + index);
			console.log('content_p::', mark);
			deleteArrItem(deviceId);
			saveMark();
			container.removeChild(mark);
			orderByPatrol(arr);
			// setShowName(false);
			// showAllName(false);
			console.log('删除巡更点后，arr:{},length:{}', arr, arr.length);
		}
	};
	const deleteArrItem = (deviceId: any) => {
		console.log('deleteArrItem -> arr:', arr);
		if (typeof arr === 'object') {
			for (let i = 0; i < arr.length; i++) {
				console.log('deleteArrItem -> flag:', arr[i].deviceId, deviceId, arr[i].deviceId === deviceId);
				if (arr[i].deviceId === deviceId) {
					console.log('deleteArrItem :', deviceId, arr[i].deviceId);
					arr.splice(i, 1);
					break;
				}
			}
		}
	};
	const saveMark = () => {
		arr = JSON.stringify(arr);
		console.log('保存至对话存储', arr);
		sessionStorage['arr'] = arr;
	};

	const orderByPatrol = (arr: any) => {
		if (arr != '' && arr != undefined && typeof arr !== 'object') {
			arr = JSON.parse(arr);
		}
		let sortableList: any = document.getElementById('sortableList');
		while (sortableList.lastChild) {
			sortableList.removeChild(sortableList.lastChild);
		}
		console.log('数组长度：', arr.length, arr);
		for (let i = 0; i < arr.length; i++) {
			const tempDiv = document.createElement('div');
			tempDiv.className = 'list-group-item';
			tempDiv.id = arr[i].deviceId;
			const tempI = document.createElement('i');
			tempI.className = 'icon-move';
			if (arr[i].text_val == null || arr[i].text_val === '') {
				tempI.innerHTML = '&nbsp;';
			} else {
				tempI.innerHTML = arr[i].text_val;
			}
			tempDiv.appendChild(tempI);
			sortableList.appendChild(tempDiv);
		}
	};
	const tableSearchBarRender = () => [
		<Space>
			<Select
				allowClear
				value={type}
				style={{width: 150}}
				placeholder={'类型'}
				onChange={(value: any) => {
					setType(value);
					actionRef.current.reloadAndRest();
				}}
			>
				{types.map((item: any) => {
					return <Select.Option value={item.value}> {item.name}</Select.Option>;
				})}
			</Select>
			<Search
				placeholder="名称、备注"
				onSearch={(value) => {
					setSearch(value);
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
				enterButton={true}
			/>
		</Space>,
	];
	return (
		<div id={'context'} style={{width: '100%', height: '100%', overflowY: 'auto'}}>
			<div style={{ padding: '16px 0px' }}>
				<Space>
					<Link to={'/patrolinspection/plan-management'}>
						<Button type="primary">返回</Button>
					</Link>
					{!readOnly ? (
						''
					) : (
						<Button type="primary" loading={loading} onClick={callback}>
							保存
						</Button>
					)}
					<div>
						显示点位名称{' '}
						<Switch
							checked={showName}
							onChange={(value) => {
								setShowName(value);
								showAllName(value);
							}}
						/>
					</div>
				</Space>
			</div>
			{!readOnly ? (
				<div className={'d-flex'} style={{width: 1500}}>
					<div id="container" style={{width: 1000}}>
						<div id="map" style={{width: 1000}} />
					</div>
					<div className="list-group" id="sortableList" style={{ width: 500 }} />
				</div>
			) : (
				<div className={'d-flex '} style={{width: 1500}}>
					<div id="container" style={{width: 1000}}>
						<div id="map" style={{width: 1000}} onClick={mapClick} />
					</div>
					<div className="list-group" id="sortableList" style={{ width: 500 }} />
				</div>
			)}
			{/*选择设备*/}
			<Modal
				title="选择设备"
				visible={visible}
				footer={null}
				width={950}
				onCancel={(value: any) => {
					setVisible(false);
				}}
			>
				<GSTable
					columns={columns}
					rowSelection={false}
					actionRef={actionRef}
					searchBarRender={tableSearchBarRender()}
					request={async (params: any = {}, sort: any, filter: any) => {
						const res = await findWuyeDevicePatrol({
							page: params.current,
							size: params.pageSize,
							deviceName: search,
							typecode: '1111',
							type: type,
						});
						return {
							data: res.data,
							total: res.count,
							success: true,
						};
					}}
				/>
			</Modal>
		</div>
	);
};
export default PatrolPlane;
