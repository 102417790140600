import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import { getList, add, deleteInfo, update } from '../../../services/UserCertificate';
import { Button, Form, Input, Modal, DatePicker, message, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
interface UserCertificateProps {
	userUuid: string

}
const UserCertificate: React.FC<UserCertificateProps> = (props: any) => {
	const actionRef = useRef<ActionType>();
	const [visible, setVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [updateForm] = Form.useForm<FormInstance>();

	const proColumns = [
		{
			title: '名称',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: '入机名称',
			dataIndex: 'note',
			key: 'note'
		},
		{
			title: '过期时间',
			dataIndex: 'expirationTime',
			key: 'expirationTime',
			valueType: 'date'
		},
		{
			title: '操作',
			dataIndex: 'active',
			key: 'active',
			render: (text:string, record: any) => {
				return <Space>
					<a onClick={() => {
						updateForm.setFieldsValue({
							...record,
							expirationTime: moment(record.expirationTime)
						})
						setUpdateVisible(true)
					}}>编辑</a>
					<a onClick={() => {
						Modal.confirm({
							title: '提示',
							content:'您确定删除该数据吗？',
							onOk: async () => {
								let rsp = await deleteInfo({uuid: record.uuid});
								if (rsp.err === 0) {
									message.success(rsp.msg)
									if (actionRef.current) {
										actionRef.current.reloadAndRest()
									}
								} else {
									message.error(rsp.msg)
								}
							}
						})
					}}>删除</a>
				</Space>
			}
		}
	];
	const loadData = async (params: any)=> {
		let data = {
			userUuid: props.userUuid
		}
		let rsp = await getList(data)
		if (rsp.err === 0) {
			return {
				data: rsp.data,
				success: true,
			}
		} else {
			return {
				data: [],
				total: 0,
				success: true,
			}
		}
	}
	const tableOperationsBarRender = () => {
		return [
			<Button
				type="primary"
				onClick={() => {
					setVisible(true)
				}}
			>
				添加证书
			</Button>
		];
	};
	const addOk = async () => {
		let data = await form.validateFields()
		console.log('data:::',data)
		let params = {
			...data,
			expirationTime: data.expirationTime.format('YYYY-MM-DD')
		}
		console.log('params:::',params)
		let rsp = await add(params)
		if (rsp.err === 0) {
			setVisible(false)
			if (actionRef.current) {
				actionRef.current.reloadAndRest()
			}
		} else {
			message.error(rsp.msg)
		}
	}
	const updateOk = async () => {
		let data = await updateForm.validateFields()
		console.log('data:::',data)
		let params = {
			...data,
			expirationTime: data.expirationTime.format('YYYY-MM-DD')
		}
		console.log('params:::',params)
		let rsp = await update(params)
		if (rsp.err === 0) {
			setUpdateVisible(false)
			if (actionRef.current) {
				actionRef.current.reloadAndRest()
			}
		} else {
			message.error(rsp.msg)
		}
	}
	return (
		<>
			<GSTable
				rowSelection={false}
				columns={proColumns}
				options={false}
				actionRef={actionRef}
				rowKey={'uuid'}
				request={loadData}
				operationsBarRender={tableOperationsBarRender()}
			/>
			<Modal destroyOnClose={true} title={'添加证书'} visible={visible} onCancel={() => setVisible(false)} onOk={addOk}>
				<Form form={form} preserve={false} >
					<Form.Item hidden={true} name={'userUuid'} initialValue={props.userUuid}>
						<Input/>
					</Form.Item>
					<Form.Item  label={'名称'} name={'name'} rules={[{required: true, message: '请输入名称'}]}>
						<Input placeholder="请输入名称" />
					</Form.Item>
					<Form.Item  label={'过期时间'} name={'expirationTime'} rules={[{required: true, message: '请输入过期时间'}]}>
						<DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
					</Form.Item>
				</Form>
			</Modal>
			<Modal destroyOnClose={true} title={'修改证书'} visible={updateVisible} onCancel={() => setUpdateVisible(false)} onOk={updateOk}>
				<Form form={updateForm} preserve={false} >
					<Form.Item hidden={true} name={'uuid'}>
						<Input/>
					</Form.Item>
					<Form.Item  label={'名称'} name={'name'} rules={[{required: true, message: '请输入名称'}]}>
						<Input placeholder="请输入名称" />
					</Form.Item>
					<Form.Item  label={'过期时间'} name={'expirationTime'} rules={[{required: true, message: '请输入过期时间'}]}>
						<DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}
export default UserCertificate;
