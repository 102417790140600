import * as React from 'react';
import DAl, { default as DAL } from '../../data/DAL';
import Api from '../../data/API';
import { Button, Col, Modal, Row, Select, Table } from 'antd';
import API from '../../data/API';
import GSTable from '../../components/GSTable';
import { Input } from 'antd';
import {
	addDrawPrize,
	deleteDraw,
	findAllHuoDongByZhuangTai,
	findDrawPrizeByUuid,
	findHuoDongFormUser,
	getLuckDraw,
	luckDraw,
	saveLuckDrawPrizeUser,
	updDrawPrize,
	updDrawUser,
} from '../../services/luckdrawService';
const { Option } = Select;

var msg = require('../../util/SuspensionMsg');
const confirm = Modal.confirm;
class LuckDraw extends React.Component {
	dal = DAL.dal;
	uuid = null;
	index = 0;

	columns = [
		{
			title: '抽奖名称',
			dataIndex: 'luckName',
			key: 'luckName',
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			key: 'createTime',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<a onClick={this.luckDrawInfo.bind(this, record.uuid, 'prize', 0)}>奖项详情</a>
							{/*<em className="ant-list-item-action-split" />*/}
						</li>
						<li>
							<a onClick={this.luckDrawInfo.bind(this, record.uuid, 'user', 0)}>抽奖人</a>
							{/*<em className="ant-list-item-action-split" />*/}
						</li>
						<li>
							<a onClick={this.delete.bind(this, record)}>删除</a>
						</li>
					</ul>
				</span>
			),
		},
	];

	state: any;
	private actionRef: any;
	constructor(props: any) {
		super(props);
		this.state = {
			wyUuid: localStorage.getItem('wyUuid'),
			luckDraw: [],
			luckDrawPrize: [],
			luckDrawUser: [],
			luckPrizeUser: [],
			newLuckUser: [],
			huoDong: [],
			luckName: null,
			userVisible: false,
			addLockVisible: false,
			prizeVisible: false,
			deleteLockVisible: false,
			prizeUserVisible: false,
			huoDongVisible: false,
			selectHuoDong: '',
			huoDongUuid: null,
			loading: true,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		// this.getData();
		// console.log('wyUuid', this.state.wyUuid);
	}
	delete = (value: any) => {
		this.uuid = value.uuid;
		this.showConfirm();
	};

	showConfirm = () => {
		let that = this;
		confirm({
			title: '您确认要删除该抽奖吗？',
			okType: 'danger',
			onOk() {
				that.deleteLuckDraw();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	/** 初始化数据 */
	// getData = () => {
	//     let that = this;
	//     Api.postWithAuth('/api/luck/getLuckDraw', {wuyeUuid: this.state.wyUuid}).then(res => {
	//         that.setState({
	//             luckDraw: res.data,
	//             addLockVisible: false,
	//             deleteLockVisible: false,
	//             loading: false
	//         });
	//     });
	// }

	/** 根据uuid查询抽奖人/抽奖奖项的信息 (请求后端) */
	luckDrawInfo = async (uuid: any, state: any, index: any) => {
		let that = this;
		this.uuid = uuid;
		if (state !== 'delete') {
			//根据uuid查询
			const res = await findDrawPrizeByUuid({ uuid: uuid });
			// Api.postWithAuth('/api/luck/findDrawPrizeByUuid', {uuid: uuid}).then(res => {

			that.setState({
				luckDrawPrize: res.luckPrize,
				luckDrawUser: res.luckUser,
				luckPrizeUser: res.luckPrize[parseInt(index, 0)].luckUser,
			});
			// });
		}
		that.showLuckModal(state);
	};

	/** 删除抽奖操作 (请求后端) */
	deleteLuckDraw = async () => {
		let that = this;
		//根据uuid查询
		const res = await deleteDraw({ uuid: that.uuid });
		// Api.postWithAuth('/api/luck/deleteDraw', {uuid: that.uuid}).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: '删除成功', type: 'success' });
			// that.getData();
			this.actionRef.reload();
		} else {
			msg.suspensionMsg({ content: '删除失败', type: 'danger' });
		}
		// });
	};

	/** 添加抽奖 (请求后端） */
	addLuckDraw = async () => {
		let that = this;
		let luckName = (this.state as any).luckName;
		let luckPrize = [
			{ luckName: '', luckNum: 0, isDisabled: false, luckUser: [] },
			{ luckName: '', luckNum: 0, isDisabled: false, luckUser: [] },
			{ luckName: '', luckNum: 0, isDisabled: false, luckUser: [] },
			{ luckName: '', luckNum: 0, isDisabled: false, luckUser: [] },
			{ luckName: '', luckNum: 0, isDisabled: false, luckUser: [] },
		];
		let luckUser = [{ userName: '', phone: '' }];
		//添加抽奖
		const res = await addDrawPrize({
			luckName: luckName,
			luckPrize: JSON.stringify(luckPrize),
			luckUser: JSON.stringify(luckUser),
			wuyeUuid: this.state.wyUuid,
		});

		// Api.postWithAuth('/api/luck/addDrawPrize', {luckName: luckName, luckPrize: JSON.stringify(luckPrize), luckUser: JSON.stringify(luckUser), wuyeUuid: this.state.wyUuid}).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: '添加成功', type: 'success' });
			// that.getData();
			this.actionRef.reload();
		} else {
			// msg.suspensionMsg('添加失败');
			msg.suspensionMsg({ content: '添加失败', type: 'danger' });
		}
		// });
	};

	/** 修改抽奖奖项的信息 (请求后端) */
	updLuckDrawPrize = async () => {
		let that = this;
		let drawPrize = (this.state as any).luckDrawPrize;
		//修改抽奖奖项的信息
		const res = await updDrawPrize({ drawPrize: JSON.stringify(drawPrize), uuid: that.uuid });
		// Api.postWithAuth('/api/luck/updDrawPrize', {drawPrize: JSON.stringify(drawPrize), uuid: that.uuid}).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: '修改成功', type: 'success' });
			that.setState({
				prizeVisible: false,
			});
		} else {
			msg.suspensionMsg({ content: '修改失败', type: 'danger' });
		}
		// });
	};

	/** 修改抽奖人的信息 (请求后端) */
	updLuckDrawUser = async () => {
		let that = this;
		let drawUser = (this.state as any).luckDrawUser;
		//修改抽奖人的信息
		const res = await updDrawUser({ drawUser: JSON.stringify(drawUser), uuid: that.uuid });
		// Api.postWithAuth('/api/luck/updDrawUser', {drawUser: JSON.stringify(drawUser), uuid: that.uuid}).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: '修改成功', type: 'success' });
			that.setState({
				userVisible: false,
			});
		} else {
			msg.suspensionMsg({ content: '修改失败', type: 'danger' });
		}
		// });
	};

	/** 随机抽奖 (请求后端 返回抽奖中的名单人) */
	luckDraw = async (index: any) => {
		let that = this;
		that.index = index;
		const res = await luckDraw({ index: index, uuid: that.uuid });
		// Api.postWithAuth('/api/luck/luckDraw', {index: index, uuid: that.uuid}).then(res => {
		if (res.err === 0) {
			that.setState({
				prizeUserVisible: true,
				luckPrizeUser: res.data,
				newLuckUser: res.user,
			});
		}
		// });
	};

	/** 保存中奖的抽奖人 (请求后端) */
	updLuckDrawPrizeUser = async () => {
		let that = this;
		let luckPrizeUser = (this.state as any).luckPrizeUser;
		let newLuckUser = (this.state as any).newLuckUser;
		const res = await saveLuckDrawPrizeUser({
			index: that.index,
			uuid: that.uuid,
			luckPrizeUser: JSON.stringify(luckPrizeUser),
			luckUser: JSON.stringify(newLuckUser),
		});
		// Api.postWithAuth('/api/luck/saveLuckDrawPrizeUser', {index: that.index, uuid: that.uuid, luckPrizeUser: JSON.stringify(luckPrizeUser), luckUser: JSON.stringify(newLuckUser)}).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: '保存成功', type: 'success' });
			that.setState({
				prizeUserVisible: false,
			});
			that.luckDrawInfo(that.uuid, 'prize', 0);
		} else {
			msg.suspensionMsg({ content: '保存失败', type: 'danger' });
		}
		// });
	};

	/** 查询全部活动列表 (请求后端 (进行中状态)) */
	findAllHuoDong = async () => {
		let that = this;
		const res = await findAllHuoDongByZhuangTai({});
		// Api.postWithAuth('/api/huodong/findAllHuoDongByZhuangTai', {}).then(res => {
		if (res.err === 0) {
			that.setState({
				huoDongVisible: true,
				huoDong: res.data,
				selectHuoDong: res.data[0] == null ? '' : res.data[0].huodongBiaoti,
				huoDongUuid: res.data[0] == null ? '' : res.data[0].uuid,
			});
		}
		// });
	};

	/** 添加活动导入的用户数据 (请求后端) */
	addLuckUser = async () => {
		let that = this;
		let huoDongUuid = (this.state as any).huoDongUuid;
		const res = await findHuoDongFormUser({ huoDongUuid: huoDongUuid, status: '已签到' });
		// Api.postWithAuth('/api/huodong/findHuoDongFormUser', {huoDongUuid: huoDongUuid, status: '已签到'}).then(res => {
		if (res.err === 0) {
			let size = res.data.length;
			for (let i = 0; i < size; i++) {
				(this.state as any).luckDrawUser.push(res.data[i]);
			}
			this.setState({
				luckDrawUser: (this.state as any).luckDrawUser,
			});
			that.setState({
				huoDongVisible: false,
			});
		}
		// });
	};

	/** 更新抽奖奖项的数量 (值) */
	updLuckNum = (index: any, ev: any) => {
		let luckNum = 0;
		if (ev.target.value === '') {
			luckNum = ev.target.value;
		} else {
			luckNum = parseInt(ev.target.value, 0);
		}
		(this.state as any).luckDrawPrize[index].luckNum = luckNum;
		let draw = (this.state as any).luckDrawPrize;
		this.setState({
			luckDrawPrize: draw,
		});
	};

	/** 更新抽奖奖项的名称 (值) */
	updLuckName = (index: any, ev: any) => {
		(this.state as any).luckDrawPrize[index].luckName = ev.target.value;
		let draw = (this.state as any).luckDrawPrize;
		this.setState({
			luckDrawPrize: draw,
		});
	};

	/** 获取要新建抽奖的名称 (值) */
	addLuckName = (ev: any) => {
		this.setState({
			luckName: ev.target.value,
		});
	};

	/** 更新抽奖人的名称 (值) */
	updUserName = (index: any, ev: any) => {
		(this.state as any).luckDrawUser[index].userName = ev.target.value;
		let draw = (this.state as any).luckDrawUser;
		this.setState({
			luckDrawUser: draw,
		});
	};

	/** 更新抽奖人的手机号 (值) */
	updUserPhone = (index: any, ev: any) => {
		(this.state as any).luckDrawUser[index].phone = ev.target.value;
		let draw = (this.state as any).luckDrawUser;
		this.setState({
			luckDrawUser: draw,
		});
	};

	/** 添加一条抽奖人的信息 名称/手机号 (值) */
	addLuckDrawUser = () => {
		(this.state as any).luckDrawUser.push({ userName: '', phone: '' });
		this.setState({
			luckDrawUser: (this.state as any).luckDrawUser,
		});
	};

	/** 删除一条抽奖人的信息 名称/手机号 (值) */
	deleteUser(res: any) {
		console.log('date', res);
		(this.state as any).luckDrawUser.splice(res, 1);
		this.setState({
			luckDrawUser: (this.state as any).luckDrawUser,
		});
	}

	/** 下拉框选择的活动 (值) */
	onSelectHuoDong = (event: any) => {
		let res: any = JSON.parse(event);
		this.setState({
			selectHuoDong: res.huoDongBiaoTi,
			huoDongUuid: res.huoDongUuid,
		});
	};

	/** 点击显示的modal框 */
	showLuckModal = (state: any) => {
		if (state === 'add') {
			this.setState({
				addLockVisible: true,
			});
		} else if (state === 'delete') {
			this.setState({
				deleteLockVisible: true,
			});
		} else if (state === 'prize') {
			this.setState({
				prizeVisible: true,
			});
		} else if (state === 'user') {
			this.setState({
				userVisible: true,
			});
		} else if (state === 'prizeUser') {
			this.setState({
				prizeUserVisible: true,
			});
		}
	};

	/** 隐藏弹出框 */
	handleCancel = () => {
		this.setState({
			prizeVisible: false,
			userVisible: false,
			addLockVisible: false,
			deleteLockVisible: false,
		});
	};

	/** 隐藏抽奖中的人的modal框 */
	handleUserLuck = () => {
		this.setState({
			prizeUserVisible: false,
		});
	};

	/** 隐藏活动modal框 */
	handleHuoDong = () => {
		this.setState({
			huoDongVisible: false,
		});
	};

	handleSubmit(event: { preventDefault: () => void }) {
		event.preventDefault();
		return false;
	}

	tableSearchBarRender = () => [<div hidden={true}></div>];

	tableOperationsBarRender = () => [
		<Button type="primary" onClick={this.showLuckModal.bind(this, 'add')}>
			新建抽奖
		</Button>,
	];

	render() {
		return (
			<div>
				{/*<nav className="filter-menu-bar navbar navbar-default" role="navigation">*/}
				{/*    <ul className="pull-right">*/}
				{/*        <li>*/}
				{/*            <Button type="primary" onClick={this.showLuckModal.bind(this, 'add')}>新建抽奖</Button>*/}
				{/*        </li>*/}
				{/*    </ul>*/}
				{/*</nav>*/}

				{/*<div className="content">*/}
				{/*    <Table columns={this.columns} size="middle" dataSource={(this.state as any).luckDraw} loading={(this.state as any).loading} pagination={false}/>*/}
				{/*    <br/>*/}
				{/*</div>*/}

				<GSTable
					columns={this.columns}
					actionRef={(ref: any) => (this.actionRef = ref)}
					searchBarRender={this.tableSearchBarRender()}
					operationsBarRender={this.tableOperationsBarRender()}
					request={async (params = {}, sort, filter) => {
						const res = await getLuckDraw({
							wuyeUuid: this.state.wyUuid,
							page: params.current,
							size: params.pageSize,
						});
						// let url = '/api/luck/getLuckDraw';
						// let res = await API.postWithAuth(url, {
						//     wuyeUuid: this.state.wyUuid,
						//     page: params.current,
						//     size: params.pageSize,
						// });
						this.setState({
							// findList: res.data,
							addLockVisible: false,
							deleteLockVisible: false,
							loading: false,
						});
						return {
							data: res.data,
							total: res.count,
							success: true,
						};
					}}
				/>

				{/*抽奖的奖项详情*/}
				<Modal
					visible={(this.state as any).prizeVisible}
					title="奖项详情"
					onOk={this.updLuckDrawPrize}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							取消
						</Button>,
						<Button key="submit" type="primary" onClick={this.updLuckDrawPrize}>
							保存
						</Button>,
					]}
				>
					<form id="formData" onSubmit={this.handleSubmit}>
						{(this.state as any).luckDrawPrize.map((item: any, index: any) => (
							<Row align={'middle'} gutter={[10, 10]}>
								<Col>
									<Row align={'middle'} gutter={[10, 10]}>
										<Col>奖项{index + 1}</Col>
										<Col>
											<Input
												name="username"
												style={{ width: '100px' }}
												disabled={item.isDisabled}
												value={item.luckName}
												onChange={this.updLuckName.bind(this, index)}
												type="text"
											/>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row align={'middle'} gutter={[10, 10]}>
										<Col>数量</Col>
										<Col>
											<Input
												name="userId"
												style={{ width: '100px' }}
												disabled={item.isDisabled}
												value={item.luckNum}
												onChange={this.updLuckNum.bind(this, index)}
												className="form-control"
												type="text"
											/>
										</Col>
										<Col>
											<Button
												type={'primary'}
												disabled={item.isDisabled}
												onClick={this.luckDraw.bind(this, index)}
												style={{ left: '-5px' }}
											>
												抽奖
											</Button>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row align={'middle'} gutter={[10, 10]}>
										<Col>
											<Button
												type={'primary'}
												onClick={this.luckDrawInfo.bind(this, this.uuid, 'prizeUser', index)}
											>
												中奖名单
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
							// <div className="row qiyeguanli-div" key={index}>
							//     <div className="col-xs-2 qiyeguanli-name-num">
							//         奖项{index + 1}
							//     </div>
							//     <div className="col-xs-2 qiyeguanli-name-num-input">
							//         <input name="username" disabled={item.isDisabled} value={item.luckName} onChange={this.updLuckName.bind(this, index)} className="form-control" type="text"/>
							//     </div>
							//     <div className="col-xs-2 qiyeguanli-name-num">
							//         数量
							//     </div>
							//     <div className="col-xs-2 qiyeguanli-name-num-input">
							//         <input name="userId" disabled={item.isDisabled} value={item.luckNum} onChange={this.updLuckNum.bind(this, index)} className="form-control" type="text"/>
							//     </div>
							//     <div className="col-xs-2 qiyeguanli-name-num-input">
							//         <button  className="btn" disabled={item.isDisabled} onClick={this.luckDraw.bind(this, index)}>抽奖</button>
							//     </div>
							//     <div className="col-xs-2 qiyeguanli-name-num-input">
							//         <a className="btn" onClick={this.luckDrawInfo.bind(this, this.uuid, 'prizeUser', index)}>中奖名单</a>
							//     </div>
							// </div>
						))}
					</form>
				</Modal>

				{/*抽奖人*/}
				<Modal
					visible={(this.state as any).userVisible}
					title="抽奖人"
					onOk={this.updLuckDrawUser}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							取消
						</Button>,
						<Button key="submit" type="primary" onClick={this.updLuckDrawUser}>
							保存
						</Button>,
					]}
				>
					<Row justify={'center'} gutter={[10, 20]} style={{ marginTop: '-20px' }}>
						<Col>
							<Button onClick={this.findAllHuoDong}>活动导入</Button>
						</Col>
						<Col>
							<Button onClick={this.addLuckDrawUser}>添加抽奖人</Button>
						</Col>
					</Row>
					{(this.state as any).luckDrawUser.map((item: any, index: any) => (
						<Row gutter={[10, 10]} align={'middle'} justify={'center'}>
							<Col>
								<Row gutter={[10, 10]} align={'middle'}>
									<Col>名称</Col>
									<Col>
										<Input
											name="username"
											style={{ width: '150px' }}
											value={item.userName}
											onChange={this.updUserName.bind(this, index)}
											type="text"
										/>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row gutter={[10, 10]} align={'middle'}>
									<Col>电话</Col>
									<Col>
										<Input
											name="userId"
											style={{ width: '150px' }}
											value={item.phone}
											onChange={this.updUserPhone.bind(this, index)}
											type="text"
										/>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row gutter={[10, 10]} align={'middle'}>
									<Col>
										<Button type={'primary'} onClick={this.deleteUser.bind(this, index)}>
											删除
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					))}
					{/*<form id="formData"  onSubmit={this.handleSubmit} >*/}
					{/*    <div className="row qiyeguanli-div">*/}
					{/*        <div className="col-xs-12">*/}
					{/*            <button className="btn" onClick={this.findAllHuoDong} type="button" >活动导入</button>*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/*    {(this.state as any).luckDrawUser.map((item: any, index: any) => (*/}
					{/*        <div className="row qiyeguanli-div">*/}
					{/*            <div className="col-xs-2 qiyeguanli-name-num">*/}
					{/*                名称*/}
					{/*            </div>*/}
					{/*            <div className="col-xs-3 qiyeguanli-name-num-input">*/}
					{/*                <input name="username" value={item.userName} onChange={this.updUserName.bind(this, index)} className="form-control" type="text"/>*/}
					{/*            </div>*/}
					{/*            <div className="col-xs-2 qiyeguanli-name-num">*/}
					{/*                电话*/}
					{/*            </div>*/}
					{/*            <div className="col-xs-3 qiyeguanli-name-num-input">*/}
					{/*                <input name="userId" value={item.phone} onChange={this.updUserPhone.bind(this, index)} className="form-control" type="text"/>*/}
					{/*            </div>*/}
					{/*            <div className="col-xs-1 qiyeguanli-name-num"/>*/}
					{/*            <div className="col-xs-1 qiyeguanli-name-num-input">*/}
					{/*                <a className="btn" onClick={this.deleteUser.bind(this, index)}>删除</a>*/}
					{/*            </div>*/}
					{/*        </div>*/}
					{/*    ))}*/}
					{/*    <div className="row qiyeguanli-div">*/}
					{/*        <div className="col-xs-5"/>*/}
					{/*        <div className="col-xs-2">*/}
					{/*            <button className="btn" onClick={this.addLuckDrawUser} type="button" >添加抽奖人</button>*/}
					{/*        </div>*/}
					{/*        <div className="col-xs-5"/>*/}
					{/*    </div>*/}
					{/*</form>*/}
				</Modal>

				{/*新建抽奖*/}
				<Modal
					visible={(this.state as any).addLockVisible}
					title="新建抽奖"
					onOk={this.addLuckDraw}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							取消
						</Button>,
						<Button key="submit" type="primary" onClick={this.addLuckDraw}>
							保存
						</Button>,
					]}
				>
					<form id="formData" onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-xs-2 qiyeguanli-name-num">抽奖名称</div>
							<div className="col-xs-4 qiyeguanli-name-num-input">
								<input
									name="username"
									onChange={this.addLuckName}
									className="form-control"
									type="text"
								/>
							</div>
						</div>
					</form>
				</Modal>

				{/*中奖的抽奖人*/}
				<Modal
					visible={(this.state as any).prizeUserVisible}
					title="中奖名单"
					onOk={this.updLuckDrawPrizeUser}
					onCancel={this.handleUserLuck}
					footer={[
						<Button key="back" onClick={this.handleUserLuck}>
							取消
						</Button>,
						<Button key="submit" type="primary" onClick={this.updLuckDrawPrizeUser}>
							保存
						</Button>,
					]}
				>
					<form id="formData" onSubmit={this.handleSubmit}>
						{(this.state as any).luckPrizeUser.map((item: any, index: any) => (
							<Row justify={'space-between'} align={'middle'}>
								<Col>
									<Row align={'middle'} gutter={[10, 10]}>
										<Col>名称</Col>
										<Col>
											<Input disabled={true} name="username" value={item.userName} type="text" />
										</Col>
									</Row>
								</Col>
								<Col>
									<Row align={'middle'} gutter={[10, 10]}>
										<Col>电话</Col>
										<Col>
											<Input disabled={true} name="userId" value={item.phone} type="text" />
										</Col>
									</Row>
								</Col>
							</Row>
							// <div className="row qiyeguanli-div">
							//     <div className="col-xs-2 qiyeguanli-name-num">
							//         名称
							//     </div>
							//     <div className="col-xs-4 qiyeguanli-name-num-input">
							//         <input disabled={true} name="username" value={item.userName} className="form-control" type="text"/>
							//     </div>
							//     <div className="col-xs-2 qiyeguanli-name-num">
							//         电话
							//     </div>
							//     <div className="col-xs-4 qiyeguanli-name-num-input">
							//         <input disabled={true} name="userId" value={item.phone} className="form-control" type="text" />
							//     </div>
							// </div>
						))}
					</form>
				</Modal>

				{/*活动导入*/}
				<Modal
					visible={(this.state as any).huoDongVisible}
					title="活动列表"
					onOk={this.addLuckUser}
					onCancel={this.handleHuoDong}
					footer={[
						<Button key="back" onClick={this.handleHuoDong}>
							取消
						</Button>,
						<Button key="submit" type="primary" onClick={this.addLuckUser}>
							确定
						</Button>,
					]}
				>
					<form id="formData" onSubmit={this.handleSubmit}>
						<Select
							showSearch
							style={{ width: 200 }}
							placeholder="Select a person"
							optionFilterProp="children"
							onChange={this.onSelectHuoDong}
						>
							{(this.state as any).huoDong.map((item: any, index: any) => (
								<Option
									value={JSON.stringify({
										huoDongUuid: item.uuid,
										huodongBiaoti: item.huodongBiaoti,
									})}
								>
									{item.huodongBiaoti}
								</Option>
							))}
						</Select>
						{/*<nav className="filter-menu-bar navbar navbar-default" role="navigation">*/}
						{/*    <div className="container-fluid">*/}
						{/*        <ul className="nav navbar-nav">*/}
						{/*            <li>*/}
						{/*                <button className="btn query-conditions-first" type="button" data-toggle="dropdown">{(this.state as any).selectHuoDong}<span className="caret"/></button>*/}
						{/*                <ul className="dropdown-menu">*/}
						{/*                    // <li style={{padding: 10, cursor: 'pointer'}} onClick={this.selectHuoDong.bind(this, item.huodongBiaoti, item.uuid)}>*/}
						{/*                    //*/}
						{/*                    // </li>*/}
						{/*                </ul>*/}
						{/*            </li>*/}
						{/*        </ul>*/}
						{/*    </div>*/}
						{/*</nav>*/}
					</form>
				</Modal>
			</div>
		);
	}
}

export default LuckDraw;
