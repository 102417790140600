import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import OfficeSpace from './page/OfficeSpace';
import LeadManager from './page/LeadManager';

export default function InvestmentManagementMiniApp() {
	const rootUrl = '/bangong_management';
	const menus: MiniAppMenu[] = [
		{
			authority: 'bangong_space',
			name: '办公空间',
			path: 'bangong',
			component: OfficeSpace,
		},
		{
			authority: 'clue_management',
			name: '线索管理',
			path: 'xiansuo',
			component: LeadManager,
		},
	];
	// const routes: MiniAppRoute[]  = [
	//     // {
	//     //     path: 'liucheng-demand',
	//     //     components: LiuchengDemand
	//     // }
	//     ];

	return <MiniApp miniAppName={'招商管理'} rootUrl={rootUrl} menus={menus} />;
}
