import * as React from 'react';
import ContractList from './pages/ContractList';

import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import ContractTemplate from "./pages/ContractTemplate";
import PendingApprovalContractList from "./pages/PendingApprovalContractList";
import SignAgreement from './pages/SignAgreement';
import ToBeSignedContractList from './pages/ToBeSignedContractList';
import SignedContractList from './pages/SignedContractList';
import CompletedContractList from './pages/CompletedContractList';
import RescindedContractList from './pages/RescindedContractList';
import ExpiredButNotSignedContractList from './pages/ExpiredButNotSignedContractList';
import Test from './pages/Test';

export default function ContractMiniApp() {
	const rootUrl = '/contract';
	const menus: MiniAppMenu[] = [
		{
			authority: 'contract_management:contract_list',
			name: '全部合同',
			path: 'contract-list',
			component: ContractList,
		},{
			authority: 'contract_management:to_be_signed_contract_list',
			name: '待签署合同',
			path: 'to-be-signed-contract-list',
			component: ToBeSignedContractList,
		},{
			authority: 'contract_management:signed_contract_list',
			name: '已签署合同',
			path: 'signed-contract-list',
			component: SignedContractList,
		},{
			authority: 'contract_management:completed_contract_list',
			name: '已完成合同',
			path: 'completed-contract-list',
			component: CompletedContractList,
		},{
			authority: 'contract_management:rescinded_contract_list',
			name: '已撤销合同',
			path: 'rescinded-contract-list',
			component: RescindedContractList,
		},{
			authority: 'contract_management:expired_but_not_signed_contract_list',
			name: '过期未签署合同',
			path: 'expired-but-not-signed-contract-list',
			component: ExpiredButNotSignedContractList,
		},
		{
			name: '待审批合同',
			path: 'pending_approval_contract_list',
			component: PendingApprovalContractList,
		},
		{
			authority: 'contract_management:sign_agreement',
			name: '签订协议',
			path: 'sign_agreement',
			component: SignAgreement,
		},
		{
			authority: 'contract_management:contract_template_list',
			name: '合同模板管理',
			path: 'contract_template_list',
			component: ContractTemplate,
			// component: Test,
		}

	];

	return <MiniApp miniAppName={'合同管理'} rootUrl={rootUrl} menus={menus} />;
}
