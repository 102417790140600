import * as React from 'react';
import { Row, Col, Divider, Input, Button, message, Modal } from 'antd';
import Api from '../../../data/API';
import * as moment from 'moment';
import { getHandleRecordList, saveHandleBanGongRecord } from '../../../services/BanGongService';
interface HandleIntentionInterface {
	record: any;
	intentionUuid: string;
	// banGongData: any;
}
const { confirm } = Modal;
let that: any;
class HandleIntention extends React.Component<HandleIntentionInterface> {
	state: any;
	constructor(props: any) {
		super(props);
		that = this;
		this.state = {
			intentionUuid: this.props.intentionUuid || '',
			handleRecordList: [],
			record: this.props.record || {},
			// banGongData: this.props.banGongData || [],
			msg: '',
		};
	}

	componentWillMount(): void {
		this.getHandleRecordList(this.state.intentionUuid);
	}

	getHandleRecordList = async (uuid: any) => {
		let data = {
			uuid: uuid,
		};
		const res = await getHandleRecordList(data);
		// Api.postWithAuth('/api/bangGong/get_handle_ban_gong_record_list', data).then((res) => {
		console.log('获取处理记录返回》》》', res);
		if (res.err === 0) {
			this.setState({
				handleRecordList: res.data,
			});
		} else {
			message.error(res.msg);
		}
		// });
	};

	saveHandleRecord = () => {
		if (this.state.msg === '') {
			message.error('请填写描述');
			return;
		}
		let data = {
			msg: this.state.msg,
			intentionUuid: this.state.record.uuid,
		};

		confirm({
			title: '您确定要提交吗？',
			onOk() {
				that.saveData(data);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	saveData = async (data: any) => {
		const res = await saveHandleBanGongRecord(data);
		// Api.postWithAuth('/api/bangGong/save_handle_ban_gong_record', data).then(res => {
		if (res.err === 0) {
			this.getHandleRecordList(this.state.intentionUuid);
			message.success('添加成功');
		} else {
			message.error(res.msg);
		}
		// });
	};

	render() {
		return (
			<div>
				<Row>
					<Col span={24}>
						{/*<Row style={{marginBottom: 10}}>*/}
						{/*    <Col span={20}>*/}
						{/*        <span style={{fontSize: 22, fontWeight: 600}}>{this.state.banGongData.name}</span>*/}
						{/*    </Col>*/}
						{/*</Row>*/}
						<Row gutter={8} style={{ marginBottom: 10 }}>
							<Col span={4} style={{ textAlign: 'right' }}>
								<span>意向客户：</span>
							</Col>
							<Col span={6}>
								<span>{this.state.record.name || ''}</span>
							</Col>
							<Col span={4} style={{ textAlign: 'right' }}>
								<span>手机号：</span>
							</Col>
							<Col span={6}>
								<span>{this.state.record.phone || ''}</span>
							</Col>
						</Row>
						<Row gutter={8} style={{ marginBottom: 10 }}>
							<Col span={4} style={{ textAlign: 'right' }}>
								<span>提交时间：</span>
							</Col>
							<Col span={6}>
								<span>{moment(this.state.record.createdTime).format('YYYY-MM-DD HH:mm:ss')}</span>
							</Col>
							<Col span={4} style={{ textAlign: 'right' }}>
								<span>提交方式：</span>
							</Col>
							<Col span={6}>
								<span>{this.state.record.submitPlatform || ''}</span>
							</Col>
						</Row>
						<Row gutter={8} style={{ marginBottom: 10 }}>
							<Col span={4} style={{ textAlign: 'right' }}>
								<span>公司名称：</span>
							</Col>
							<Col span={6}>
								<span>{this.state.record.companyName || ''}</span>
							</Col>
						</Row>
					</Col>
				</Row>
				<Divider />
				<Row style={{ marginBottom: 10 }}>
					<Col span={20}>
						<span style={{ fontSize: 18, fontWeight: 600 }}>处理历史</span>
					</Col>
				</Row>
				{/*<Row style={{marginBottom: 10, maxHeight: 260, overflowY: 'auto', overflowX: 'hidden'}}>*/}
				{/*    <Col>*/}
				{this.state.handleRecordList.map((item: any) => {
					return (
						<div key={item.intentionUuid}>
							<Row gutter={8} style={{ marginBottom: 10 }}>
								<Col span={4} style={{ textAlign: 'right' }}>
									<span>处理人：</span>
								</Col>
								<Col span={6}>
									<span>{item.operatorName}</span>
								</Col>
								<Col span={4} style={{ textAlign: 'right' }}>
									<span>提交时间：</span>
								</Col>
								<Col span={6}>
									<span>{moment(item.operatorTime).format('YYYY-MM-DD HH:mm:ss')}</span>
								</Col>
							</Row>
							<Row gutter={8} style={{ marginBottom: 10 }}>
								<Col span={4} style={{ textAlign: 'right' }}>
									<span>描述：</span>
								</Col>
								<Col span={16}>
									<span>{item.msg}</span>
								</Col>
							</Row>

							<Divider />
						</div>
					);
				})}
				{/*    </Col>*/}
				{/*</Row>*/}
				<Divider />

				<Row style={{ marginBottom: 10 }}>
					<Col span={18}>
						<span style={{ fontSize: 18, fontWeight: 600 }}>处理描述</span>
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={4} style={{ textAlign: 'right' }}>
						<span>描述：</span>
					</Col>
					<Col span={20}>
						<Input.TextArea
							onChange={(e) => {
								this.setState({ msg: e.target.value });
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={4}></Col>
					<Col span={10}>
						<Button type={'primary'} onClick={this.saveHandleRecord}>
							提交
						</Button>
					</Col>
				</Row>
			</div>
		);
	}
}
export default HandleIntention;
