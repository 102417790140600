import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Image, Row, Statistic } from 'antd';
import { ColumnChartContext } from '../../controlPlatform/CurrentPhoneBrandChart';
import PieChart from '../../../components/PieChart';
import { DataContext } from '../../controlPlatform/CurrentPlatform';
import LineChart from '../../../components/LineChart';
import {
	findException,
	getDevicePatrolStatusPieData,
	getDevicePatrolTypePieData, getXunGengXunJianLineChartData,
	getXunGengXunJianTopData
} from '../../../services/patrolService';
import GSTable from '../../../components/GSTable';
import Api from '../../../data/API';
import AuthorizedView from '../../../util/AuthorizedView';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import moment from 'moment' ;

const XunGengConsole = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>({});
	const [deviceStatusPieData, setDeviceStatusPieData] = useState([]);
	const [deviceTypePieData, setDeviceTypePieData] = useState([]);

	const actionRef = useRef<ActionType>();

	const [xunData, setXunData] = useState<[any?]>([]);
	const columns = [
		{
			title: '巡更路线',
			dataIndex: 'routeName'
		},
		{
			title: '路线类型',
			dataIndex: 'routeType'
		},
		{
			title: '异常位置',
			dataIndex: 'deviceName'
		},
		{
			title: '异常说明',
			dataIndex: 'description'
		},
		{
			title: '照片',
			render: (text: any, item: any) => (
				<Image width={80} height={80} className="homePage" src={Api.cdnRootPath + item.img}/>
			)
		},
		{
			title: '处理说明',
			dataIndex: 'process'
		},
		{
			title: '反馈人',
			dataIndex: 'feedbackName'
		},
		{
			title: '反馈时间',
			dataIndex: 'feedbackTime'
		},
		{
			title: '备注',
			dataIndex: 'remarks'
		}
	];
	useEffect(() => {
		getTopData().then();
		deviceStausPieData().then();
		getDeviceTypePieData().then();
		getLineChartData().then()
	}, []);

	const getTopData = async () => {
		let rsp = await getXunGengXunJianTopData({});
		if (rsp.err === 0) {
			setData(rsp.data);
		} else {
			setData({});
		}
	};
	const deviceStausPieData = async () => {
		let rsp = await getDevicePatrolStatusPieData({});
		if (rsp.err === 0) {
			setDeviceStatusPieData(rsp.data);
		} else {
			setDeviceStatusPieData([]);
		}
	};
	const getDeviceTypePieData = async () => {
		let rsp = await getDevicePatrolTypePieData({});
		if (rsp.err === 0) {
			setDeviceTypePieData(rsp.data);
		} else {
			setDeviceTypePieData([]);
		}
	};

	const getLineChartData = async () => {
		let params = {
			startTime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
			endTime: moment().subtract(1, 'd').format('YYYY-MM-DD')
		};
		let rsp = await getXunGengXunJianLineChartData(params);
		if (rsp.err === 0) {
			setXunData(rsp.data)
		} else {
			setXunData([])
		}
	};
	return (
		<>
			<div style={{
				backgroundColor: '#fff',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 10
			}}>
				<div style={{
					flex: 1,
					flexDirection: 'column',
					display: 'flex',
					height: 156,
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<div style={{ color: '#000', fontWeight: 'bold', fontSize: 22, marginBottom: 15 }}>
						{data.imgCount || 0}
					</div>
					<div style={{ color: '#707070', fontSize: 16 }}>
						平面图总数
					</div>
				</div>
				<div style={{
					flex: 1,
					flexDirection: 'column',
					display: 'flex',
					height: 156,
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<div style={{ color: '#000', fontWeight: 'bold', fontSize: 22, marginBottom: 15 }}>
						{data.deviceCount || 0}
					</div>
					<div style={{ color: '#707070', fontSize: 16 }}>
						点位总数
					</div>
				</div>
				<div style={{
					flex: 1,
					flexDirection: 'column',
					display: 'flex',
					height: 156,
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<div style={{ color: '#000', fontWeight: 'bold', fontSize: 22, marginBottom: 15 }}>
						{data.groupCount || 0}
					</div>
					<div style={{ color: '#707070', fontSize: 16 }}>
						人员分组总数
					</div>
				</div>
				<div style={{
					flex: 1,
					flexDirection: 'column',
					display: 'flex',
					height: 156,
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<div style={{ color: '#000', fontWeight: 'bold', fontSize: 22, marginBottom: 15 }}>
						{data.xunGengCount || 0}
					</div>
					<div style={{ color: '#707070', fontSize: 16 }}>
						巡更路线总数
					</div>
				</div>
				<div style={{
					flex: 1,
					flexDirection: 'column',
					display: 'flex',
					height: 156,
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<div style={{ color: '#000', fontWeight: 'bold', fontSize: 22, marginBottom: 15 }}>
						{data.xunJianCount || 0}
					</div>
					<div style={{ color: '#707070', fontSize: 16 }}>
						巡检计划总数
					</div>
				</div>
			</div>
			<Row gutter={8}>
				<Col span={12}>
					<Card title={'巡更巡检趋势图'}>
						<DataContext.Provider value={xunData}>
							<LineChart/>
						</DataContext.Provider>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'设备状态统计图'}>
						<ColumnChartContext.Provider value={deviceStatusPieData}>
							<PieChart unit={'个'}/>
						</ColumnChartContext.Provider>
					</Card>
				</Col>
				<Col span={6}>
					<Card title={'点位类型统计图'}>
						<ColumnChartContext.Provider value={deviceTypePieData}>
							<PieChart unit={'个'}/>
						</ColumnChartContext.Provider>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Card title={'异常报告'} extra={<a style={{color: '#707070'}} href={'/patrolinspection/patrol-exception'}>更多</a>}>
						<GSTable
							rowKey={'uuid'}
							options={false}
							columns={columns}
							actionRef={actionRef}
							pagination={false}
							request={async (params: any = {}, sort: any, filter: any) => {
								const res = await findException({
									page: 1,
									size: 10,
									search: '',
									routeType: ''
								});

								return {
									data: res.data,
									total: res.count,
									success: true
								};
							}}
						/>
					</Card>
				</Col>
			</Row>
		</>
	);
};
export default XunGengConsole;
