import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import { findRouteInfo } from '../../../services/patrolService';
var msg = require('../../../util/SuspensionMsg');

class RoutePoints extends React.Component {
	deviceUuid = '';
	dal = DAL.dal;

	constructor(props: any) {
		super(props);
		this.state = {
			ds: [],
			value: '',
			uuid: '',
			deviceId: '',
			deviceName: '',
			typecode: '',
			deviceBattery: '',
			address: '',
			describe: '',
		};
		const serch = props.location.search;
		if (serch !== null && serch !== '') {
			const splits = serch.split('=');
			this.deviceUuid = splits[1];
			this.findRouteInfo();
			// this.dal.getRouteInfo(this.deviceUuid).then(res => {
			//     console.log('获得路线详细信息', res);
			//     this.setState({
			//         ds: res,
			//         isShow: false
			//     });
			// });
		} else {
			msg.suspensionMsg('参数异常');
		}
	}

	findRouteInfo = async () => {
		const res = await findRouteInfo({ uuid: this.deviceUuid });
		// API.postWithAuth('/api/patrolinspection/find_route_info' , { uuid: this.deviceUuid}).then( res => {
		console.log('获得路线详细信息', res);
		if (res.err === 0) {
			this.setState({
				ds: res,
				isShow: false,
			});
		} else {
			msg.suspensionMsg('没有权限管理该设备');
		}
		// });
	};

	render() {
		return (
			<div className="devices-list">
				<table className="table table-striped ">
					<thead>
						<tr>
							<th>设备ID</th>
							<th>设备名称</th>
							<th>设备类型</th>
							{/*<th></th>*/}
							<th>设备描述</th>
						</tr>
					</thead>
					<tbody>
						{(this.state as any).ds.map((d: any, index: any) => (
							<tr key={d.uuid}>
								<td>{d.deviceId}</td>
								<td>{d.deviceName}</td>
								<td>{d.typecode === '1111' ? '巡更设备' : '巡检设备'}</td>
								{/*<td>{d.deviceBattery}</td>*/}
								<td>{d.address}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default RoutePoints;

// helpers

/*function getExclamationMarks(numChars: number) {
    return Array(numChars + 1).join('!');
}*/
