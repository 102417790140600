import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Form, Input, message, Modal, Switch } from 'antd';
import { FormInstance } from 'antd/es/form';
import { Link } from 'react-router-dom';
import {
	findWenzhangTypeList,
	addWenzhangType,
	changeLeixingState,
	updateWenzhangType,
	deleteWenzhangType,
} from '../../../services/WenzhangService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
var msg = require('../../../util/SuspensionMsg');
const LookWenzhangType: React.FC = () => {
	const { confirm } = Modal;
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const Search = Input.Search;
	const [searchType, setSearchType] = useState('');
	const [update, setUpdate] = useState(false);
	const [visible, setVisible] = useState(false);
	const columns = [
		{
			title: '类目名称',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						updateModal(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '类目ID',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: '类型',
			dataIndex: 'cateType',
			key: 'cateType',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (text: string, record: any) => (
				<Switch
					checked={record.status === '开启' ? true : false}
					onChange={() => {
						ChangeState(record);
					}}
				/>
			),
		},
		{
			title: '创建人',
			dataIndex: 'creator',
			key: 'creator',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<a
								onClick={() => {
									deleteItem(record);
								}}
								style={{ color: record.cateType === '系统' ? '#AAAAAA' : '' }}
							>
								删除
							</a>
						</li>
					</ul>
				</span>
			),
		},
	];

	const deleteItem = (value: any) => {
		if (value.cateType !== '系统') {
			confirm({
				title: '删除类目',
				icon: <ExclamationCircleOutlined />,
				content: '您确定删除吗',
				okType: 'danger',
				onOk: async () => {
					const res = await deleteWenzhangType({ uuid: value.uuid });
					if (res.err === 0) {
						message.success('删除成功');
						if (actionRef.current) {
							actionRef.current.reload();
						}
					} else {
						message.error('删除失败');
					}
				},
				onCancel() {
					console.log('Cancel');
				},
			});
		}
	};

	const updateModal = (value: any) => {
		setUpdate(true);
		const formData: any = {
			name: value.name,
			uuid: value.uuid,
			type: value.type,
		};
		form.setFieldsValue(formData);
		setVisible(true);
	};
	const ChangeState = async (value: any) => {
		if (value.status === '开启') {
			value.status = '禁用';
		} else {
			value.status = '开启';
		}
		const res = await changeLeixingState({
			uuid: value.uuid,
			status: value.status,
		});
		if (res.err === 0) {
			message.success('修改成功');
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '失败', type: 'error' });
		}
	};
	const tableSearchBarRender = () => [
		<Input
			placeholder="搜索类目"
			onChange={(e) => {
				setSearchType(e.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={() => {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	const xinjian = () => {
		form.resetFields();
		setUpdate(false);
		setVisible(true);
	};

	const handleOk = () => {
		if (update) {
			updateItem();
		} else {
			callData();
		}
	};

	const updateItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				uuid: data.uuid,
				name: data.name,
				type: data.type,
			};
			const res = await updateWenzhangType(formData);
			console.log('', res);
			if (res.err === '0') {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
			setVisible(false);
		});
	};

	const callData = async () => {
		form.validateFields().then(async (data: any) => {
			const res = await addWenzhangType({
				name: data.name,
				type: data.type,
			});
			if (res.err === 0) {
				message.success('操作成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error('操作失败');
			}
			setVisible(false);
		});
	};

	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={xinjian}>
			新建类目
		</Button>,
	];

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await findWenzhangTypeList({
						page: params.current,
						size: params.pageSize,
						searchType: searchType,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>

			<Modal
				title={update ? '编辑' : '新增'}
				visible={visible}
				onOk={handleOk}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'类目名称'} name={'name'} rules={[{ required: true }]}>
						<Input placeholder={'请输入类目名称'} />
					</FormItem>
					<FormItem
						label={'类目ID'}
						name={'type'}
						tooltip={'类目ID创建后不可修改'}
						rules={[{ required: true }]}
					>
						<Input disabled={update} placeholder={'请输入类目ID'}></Input>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default LookWenzhangType;
