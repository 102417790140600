import Api from '../data/API';
export async function getList(params: any) {
	return Api.postWithAuth('/admin/api/channel/getList', params);
}
export async function addChannel(params: any) {
	return Api.postWithAuth('/admin/api/channel/add', params);
}
export async function updateChannel(params: any) {
	return Api.postWithAuth('/admin/api/channel/update', params);
}
export async function deleteChannel(params: any) {
	return Api.postWithAuth('/admin/api/channel/delete', params);
}
export async function getShuZhiUser(params: any) {
	return Api.postWithAuth('/admin/api/channel/getShuZhiUser', params);
}
export async function getAll(params: any) {
	return Api.postWithAuth('/admin/api/channel/getAll', params);
}
export async function setUserChannel(params: any) {
	return Api.postWithAuth('/admin/api/channel/setUserChannel', params);
}
export async function getOrderVipList(params: any) {
	return Api.postWithAuth('/admin/api/channel/getOrderList', params);
}
export async function getChannelUserList(params: any) {
	return Api.postWithAuth('/adminapi/channel/getChannelUserList', params);
}
export async function getUserBindChannelList(params: any) {
	return Api.postWithAuth('/adminapi/channel/getUserBindChannelList', params);
}export async function deleteUserChannel(params: any) {
	return Api.postWithAuth('/adminapi/channel/deleteUserChannel', params);
}

