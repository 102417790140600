import { Effect } from 'dva';
import { Reducer } from 'redux';
import { change_AdminProject, getAdminToken, loginWithSms, tokenSignIn } from '../services/login';
import Api from '../data/API';
import { parse } from 'querystring';
import { createBrowserHistory } from 'history';
import DAL from '../data/DAL';
import { message } from 'antd';
import API from '../data/API';

const history = createBrowserHistory();

export interface CurrentUser {
	uuid?: string;
	token?: string;
	wyUuid?: string;
	wyYeShortName?: string;
	button?: Array<string>;
	menu?: Array<any>;
	companyUuid?: string;
	wuyeSlogan?: string;
}
export interface StateType {
	status: boolean;
	currentUser?: CurrentUser;
}

export interface LoginModelType {
	namespace: string;
	state: StateType;
	effects: {
		login: Effect;
		phoneLogin: Effect;
		tokenLogin: Effect;
		changeAdminProject: Effect;
		logout: Effect;
	};
	reducers: {
		changeLoginStatus: Reducer<StateType>;
		changeProjectLoginStatus: Reducer<StateType>;
	};
}
const loginModel: LoginModelType = {
	namespace: 'login',
	state: {
		status: false,
		currentUser: JSON.parse(localStorage.getItem('userInfo11') || '{}'),
	},
	effects: {
		*login({ payload }, { call, put }) {
			console.log('payload::::', payload);
			const response = yield call(getAdminToken, payload);
			if (response.err === 0) {
				message.success('登录成功');
				Api.setUserState(
					payload.user_id,
					payload.password,
					response.token,
					response.wyYeShortName,
					response.wuyeSlogan,
					response.wyUuid,
					response.companyUuid
				);
				yield put({
					type: 'changeLoginStatus',
					payload: response,
				});
			} else {
				message.error(response.msg);
			}
		},
		*phoneLogin({ payload }, { call, put }) {
			const response = yield call(loginWithSms, payload);
			if (response.err === 0) {
				Api.setUserState(
					payload.userId,
					'',
					response.token,
					response.wyYeShortName,
					response.wuyeSlogan,
					response.wyUuid,
					response.companyUuid
				);
				yield put({
					type: 'changeLoginStatus',
					payload: response,
				});
				message.success('登录成功');
			} else {
				message.error(response.msg);
			}
		},
		*tokenLogin({ payload }, { call, put }) {
			const response = yield call(tokenSignIn, payload);
			if (response.err === 0) {
				Api.setUserState(
					'',
					'',
					response.token,
					response.wyYeShortName,
					response.wuyeSlogan,
					response.wyUuid,
					response.companyUuid
				);
				console.log('response::::::', response);
				yield put({
					type: 'changeLoginStatus',
					payload: response,
				});
				message.success('登录成功');
			} else {
				message.error(response.msg);
			}
		},
		*changeAdminProject({ payload }, { call, put }) {
			const response = yield call(change_AdminProject, payload);
			console.log('changeAdminProject::::', response);
			if (response.err === 0) {
				yield put({
					type: 'changeProjectLoginStatus',
					payload: response,
				});
				message.success('切换成功');
			} else {
				message.warning(response.msg);
			}
		},

		logout() {
			console.log('logout：：：');
		},
	},
	reducers: {
		changeLoginStatus(state, { payload }) {
			localStorage.setItem('userInfo11', JSON.stringify(payload));
			if (payload.err === 0) {
				const permissions = payload.button;
				DAL.permissions = permissions.split(',');
				if (payload.menu[0]) {
					window.location.href = payload.menu[0].children
						? payload.menu[0].children[0].path
						: payload.menu[0].path;
				} else {
					window.location.href = '/';
				}
			}
			return {
				...state,
				status: payload.err === 0,
				currentUser: { ...payload },
			};
		},
		changeProjectLoginStatus(state: any, { payload }) {
			localStorage.setItem('userInfo11', JSON.stringify(payload));
			console.log('payload::::payload', payload, state);
			let currentUser = state.currentUser;

			if (payload.err === 0) {
				localStorage.removeItem('userPermissions');
				API.setWyNameAndSlogan(payload.wyYeShortName, payload.wuyeSlogan);
				API.changeAfter(payload.projectUuid, payload.companyUUid);
				const permissions = payload.button;
				console.log('permissions', permissions);
				DAL.permissions = permissions.split(',');
				localStorage.setItem('userPermissions', permissions);
				currentUser.menu = payload.menu;
				currentUser.button = payload.button;
				if (payload.menu[0]) {
					window.location.href = payload.menu[0].children
						? payload.menu[0].children[0].path
						: payload.menu[0].path;
				} else {
					window.location.href = '/';
				}
				// window.location.href = payload.menu[0].children ? payload.menu[0].children[0].path : payload.menu[0].path;
			}

			return {
				...state,
				currentUser: currentUser,
			};
		},
	},
};
export default loginModel;
