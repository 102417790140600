import React, { useRef, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Switch, TimePicker } from 'antd';
import { ActionType } from '@ant-design/pro-table';
import appConfig from '../../../appConfig';
import { Link } from 'react-router-dom';
import {
	addRoute,
	deleteRoute,
	findRouteRoster,
	findWuyeRoute,
	getXungengShiftByRouteUuid,
	routeBingPlane,
	updataRoute,
	updataRouteState,
} from '../../../services/patrolService';
import GSTable from '../../../components/GSTable';
import Api from '../../../data/API';
import ProxyForm from '../../../components/ProxyForm';

var msg = require('../../../util/SuspensionMsg');
const confirm = Modal.confirm;
let moment = require('moment');
const PatrolRouteList: React.FC = (props: any) => {
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const [addForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [plan, setPlan] = useState([]);
	const [visible, setVisible] = useState(false);
	const [addRoutevisible, setAddRoutevisible] = useState(false);
	const [routevisible, setRoutevisible] = useState(false);
	const [routeUUID, setRouteUUID] = useState('');
	const [createTime, setCreateTime] = useState('');
	const [uuid, setUuid] = useState('');
	const [date, setDate] = useState([{ className: '', classStartTime: '', classEndTime: '' }]);
	const columns = [
		{
			title: '路线名称',
			dataIndex: 'routeName',
			key: 'routeName',
			render: (text: string, record: any) => (
				<span>
					{
						<a
							onClick={() => {
								update(record);
							}}
						>
							{text}
						</a>
					}
				</span>
			),
		},
		{
			title: '路线周期',
			dataIndex: 'routeCycle',
			key: 'routeCycle',
		},
		{
			title: '开始时间',
			dataIndex: 'createdTime',
			key: 'createdTime',
		} /*, {
        title: '结束时间',
        dataIndex: 'endTime',
        key: 'endTime',
    }*/,
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			render: (text: string, record: any) => (
				<Switch
					defaultChecked={record.state}
					onChange={() => {
						Isstate(record);
					}}
				/>
			),
		},
		{
			title: '平面图',
			dataIndex: 'planeImg',
			key: 'planeImg',
			render: (text: string, record: any) => (
				<span>
					{record.planeImg === '' || record.planeImg === null || record.planeImg === undefined ? (
						'未设置'
					) : (
						<img
							onClick={() => {
								findPlan(record.uuid);
							}}
							src={appConfig.cdnRootPath + record.planeImg}
							style={{ width: 80, height: 50 }}
						/>
					)}
				</span>
			),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any, item: any) => {
				return (
					<div>
						<span>
							{record.planeImg === '' || record.planeImg === null || record.planeImg === undefined ? (
								<a
									onClick={() => {
										findPlan(record.uuid);
									}}
								>
									设置点位
								</a>
							) : (
								<a>
									<Link
										to={`/patrolinspection/route_choose_device?uuid=${record.planeUuid}&routeUuid=${record.uuid}&routeType=xungeng`}
									>
										设置点位
									</Link>
								</a>
							)}
						</span>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<a>
							<Link to={`/patrolinspection/find_schedul?uuid=${record.uuid}`}>排班</Link>
						</a>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<a
							onClick={() => {
								getuuid(record);
							}}
						>
							删除
						</a>
					</div>
				);
			},
		},
	];
	const getuuid = (res: any) => {
		confirm({
			title: '您确认要删除该路线吗？',
			okType: 'danger',
			onOk() {
				deleteItem(res);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const xungengShiftByRouteUuid = async (routeUuid) =>{
		const res = await getXungengShiftByRouteUuid({ routeUuid: routeUuid })
		if (res.err == 0) { 
			const data = res.data || []
			if (data.length > 0) {
				let data1 = []
				data.forEach(element => {
					const item = {
						className: element.shiftName,
						classStartTime: moment(element.startTime).format("HH:mm"),
						classEndTime: moment(element.endTime).format("HH:mm")
					}
					data1.push(item)
				});
				setDate(data1)
			} else { 
				setDate([])
			}
		}
	}
	const deleteItem = async (item: any) => {
		if (item.uuid === null) {
			msg.suspensionMsg({ content: '删除异常', type: 'danger' });
			return;
		}
		const res = await deleteRoute({
			uuid: item.uuid,
		});
		if (res.err === 0) {
			msg.suspensionMsg({ content: '删除成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '删除失败', type: 'error' });
		}
	};

	const findPlan = async (uuid: string) => {
		console.log('record:::', uuid);
		setRouteUUID(uuid);
		const res = await findRouteRoster({ uuid: uuid });
		if (res.err === 0) {
			console.log('====>', res);
			if (res.replace === 'NO') {
				msg.suspensionMsg({ content: '此路线已存在排班数据，不能更换平面图', type: 'danger' });
				return;
			} else {
				Api.postWithAuth('/api/patrolinspection/findPlan', {}).then((res) => {
					if (res.err === 0) {
						console.log('获得平面图列表', res);
						setPlan(res.data);
					}
				});
				setVisible(true);
			}
		}
	};
	const update = (value: any) => {
		console.log('value:::', value);
		const formData: any = {
			uuid: value.uuid,
			routeName: value.routeName,
			routeCycle:value.routeCycle,
			closeOrderDot: value.closeOrderDot,
			createdTime:value.createdTime,
		};
		form.setFieldsValue(formData);
		xungengShiftByRouteUuid(value.uuid)
		setRoutevisible(true);
	};
	const Isstate = async (value: any) => {
		if (value.uuid === '' || value.uuid === undefined) {
			msg.suspensionMsg({ content: 'uuid不能为空', type: 'danger' });
			return;
		}
		var data = {};
		if (value.state) {
			(data as any).uuid = value.uuid;
			(data as any).state = '0';
		} else {
			(data as any).uuid = value.uuid;
			(data as any).state = '1';
		}
		const res = await updataRouteState(data);
		console.log('', res);
		if (res.err === 0) {
			msg.suspensionMsg({ content: '修改成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '修改失败', type: 'error' });
		}
	};
	const updataPatro = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data::', data);
			const res = await updataRoute(data);
			console.log('', res);
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				setRoutevisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
		});
	};

	const addItem = () => {
		let flag = false;
		addForm.validateFields().then(async (data: any) => {
			if (date.length <= 0) {
				msg.suspensionMsg({ content: '必须存在一个班次', type: 'danger' });
				return;
			}
			date.map((item: any) => {
				if (item.className === null || item.className === undefined || item.className === '') {
					msg.suspensionMsg({ content: '班次名称不能为空', type: 'danger' });
					flag = true;
					return;
				}
				if (item.classStartTime === null || item.classStartTime === undefined || item.classStartTime === '') {
					msg.suspensionMsg({ content: '班次开始时间不能为空', type: 'danger' });
					flag = true;
					return;
				}
				if (item.classEndTime === null || item.classEndTime === undefined || item.classEndTime === '') {
					msg.suspensionMsg({ content: '班次结束时间不能为空', type: 'danger' });
					flag = true;
					return;
				}
				if (item.classStartTime > item.classEndTime) {
					msg.suspensionMsg({ content: '班次开始时间不能大于结束时间', type: 'danger' });
					flag = true;
					return;
				}
			});
			if (flag) {
				return;
			}
			const da = {
				route_name: data.routeName + '',
				created_time: createTime,
				route_cycle: data.routeCycle + '',
				routeType: '巡更',
				clazz: date,
				closeOrderDot: data.closeOrderDot?true:false,
			};
			const res = await addRoute({
				data: JSON.stringify(da),
			});
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				setAddRoutevisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '添加失败', type: 'error' });
			}

			console.log('AddData:::', da);
		});
	};

	const createdTime = (date: any, dateString: any) => {
		setCreateTime(dateString);
	};

	const getShiftName = (item: any, index: any, ev: any) => {
		console.log('ev:', ev);
		item.className = ev.target.value;
		date[index] = item;
		setDate(date);
	};
	const oninputTime = (item: any, index: any, flag: any, time: any, timeString: any) => {
		console.log(item, index, flag, timeString);
		if (flag === 'classStartTime') {
			item.classStartTime = timeString;
			console.log(item.classStartTime);
		} else {
			item.classEndTime = timeString;
			console.log(item.classEndTime);
		}
		date[index] = item;
		setDate(date);
		console.log(date);
	};

	const deleteShift = (res: any) => {
		console.log('date', res);
		const date1 = date.concat([]);
		date1.splice(res, 1);
		setDate(date1);
	};
	const addDate = () => {
		const date1 = date.concat([]);
		date1.push({ className: '', classStartTime: '', classEndTime: '' });
		setDate(date1);
	};

	const bindPlane = async (uuid: any) => {
		const res = await routeBingPlane({
			routeUuid: routeUUID,
			planeUuid: uuid,
		});
		if (res.err === 0) {
			msg.suspensionMsg({ content: '绑定成功', type: 'success' });
			actionRef.current.reload();
		} else {
			msg.suspensionMsg({ content: '绑定失败', type: 'error' });
		}
		setVisible(false);
	};

	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setDate([{ className: '', classStartTime: '', classEndTime: '' }])
				setAddRoutevisible(true);
			}}
		>
			添加巡更路线
		</Button>,
	];
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		},
	};
	return (
		<div>
			<GSTable
				rowKey={"uuid"}
				columns={columns}
				actionRef={actionRef}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findWuyeRoute({
						page: params.current,
						size: params.pageSize,
						routeType: '巡更',
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="编辑路线"
				visible={routevisible}
				width={1000}
				onOk={updataPatro}
				onCancel={() => {
					setRoutevisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<Row className={'margin-top--10'}>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'路线名称'}
								name={'routeName'}
								rules={[{ required: true }]}
							>
								<Input placeholder={'请输入路线名称'} />
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'路线周期'}
								name={'routeCycle'}
								rules={[{ required: true }]}
							>
								<Input disabled></Input>
							</FormItem>
						</Col>
					</Row>
					<Row className={'margin-top--10'}>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'开始时间'}
								name={'createdTime'}
								rules={[{ required: true }]}
							>
								<Input disabled></Input>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'关闭顺序打点'}
								name={'closeOrderDot'}
								valuePropName={'checked'}
								tooltip={'开启后巡更打点将不需要按照顺序打点'}
							>
								<Switch />
							</FormItem>
						</Col>
					</Row>
					{date.map((item: any, index: any) => (
						<Row justify="start" style={{ display: 'flex', alignItems: 'center' }} gutter={[16, 16]}>
							<Col span={3} style={{ paddingLeft: '17px' }}>
								班次名称
							</Col>
							<Col span={3} pull={1}>
								<Input
									disabled
									value={item.className}
									// defaultValue={item.className}
									// onChange={(value) => {
									// 	getShiftName(item, index, value);
									// }}
								/>
							</Col>
							<Col span={3}>班次开始时间</Col>
							<Col span={4}>
								<Input disabled
									value={item.classStartTime}
								></Input>
								{/* <TimePicker
									defaultValue={
										item.classStartTime === '' ? null : moment(item.classStartTime, 'HH:mm')
									}
									format="HH:mm"
									onChange={(time, timeString) => {
										oninputTime(item, index, 'classStartTime', time, timeString);
									}}
								/> */}
							</Col>
							<Col span={3}>班次结束时间</Col>
							<Col span={4}>
							<Input disabled
									value={item.classEndTime}
								></Input>
								{/* <TimePicker
									defaultValue={item.classEndTime === '' ? null : moment(item.classEndTime, 'HH:mm')}
									format="HH:mm"
									onChange={(time, timeString) => {
										oninputTime(item, index, 'classEndTime', time, timeString);
									}}
								/> */}
							</Col>
							<Col span={3}>
								{/* <Button
									type="primary"
									onClick={() => {
										deleteShift(index);
									}}
								>
									删除
								</Button> */}
							</Col>
						</Row>
					))}
					
				</ProxyForm>
			</Modal>
			<Modal
				title="添加巡更路线"
				width={1000}
				visible={addRoutevisible}
				onOk={addItem}
				onCancel={() => {
					setAddRoutevisible(false);
				}}
			>
				<Form form={addForm}>
					<Row className={'margin-top--10'}>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'路线名称'}
								name={'routeName'}
								rules={[{ required: true }]}
							>
								<Input placeholder={'请输入路线名称'} />
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'路线周期'}
								name={'routeCycle'}
								rules={[{ required: true }]}
							>
								<Select style={{ width: 200 }} placeholder={'请选择路线周期'}>
									<Select.Option key="日检" value={'日检'}>
										日检
									</Select.Option>
									<Select.Option key="周检" value={'周检'}>
										周检
									</Select.Option>
									<Select.Option key="双周检" value={'双周检'}>
										双周检
									</Select.Option>
									<Select.Option key="月检" value={'月检'}>
										月检
									</Select.Option>
									<Select.Option key="季检" value={'季检'}>
										季检
									</Select.Option>
									<Select.Option key="半年检" value={'半年检'}>
										半年检
									</Select.Option>
									<Select.Option key="年检" value={'年检'}>
										年检
									</Select.Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
					<Row className={'margin-top--10'}>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'开始时间'}
								name={'createTime'}
								rules={[{ required: true }]}
							>
								<DatePicker
									showTime={true}
									format="YYYY-MM-DD HH:mm"
									placeholder="请选择时间"
									onChange={createdTime}
								/>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								{...formItemLayout}
								label={'关闭顺序打点'}
								name={'closeOrderDot'}
								valuePropName={'checked'}
								tooltip={'开启后巡更打点将不需要按照顺序打点'}
							>
								<Switch />
							</FormItem>
						</Col>
					</Row>
					{date.map((item: any, index: any) => (
						<Row justify="start" style={{ display: 'flex', alignItems: 'center' }} gutter={[16, 16]}>
							<Col span={3} style={{ paddingLeft: '17px' }}>
								班次名称
							</Col>
							<Col span={3} pull={1}>
								<Input
									defaultValue={item.className}
									onChange={(value) => {
										getShiftName(item, index, value);
									}}
								/>
							</Col>
							<Col span={3}>班次开始时间</Col>
							<Col span={4}>
								<TimePicker
									defaultValue={
										item.classStartTime === '' ? null : moment(item.classStartTime, 'HH:mm')
									}
									format="HH:mm"
									onChange={(time, timeString) => {
										oninputTime(item, index, 'classStartTime', time, timeString);
									}}
								/>
							</Col>
							<Col span={3}>班次结束时间</Col>
							<Col span={4}>
								<TimePicker
									defaultValue={item.classEndTime === '' ? null : moment(item.classEndTime, 'HH:mm')}
									format="HH:mm"
									onChange={(time, timeString) => {
										oninputTime(item, index, 'classEndTime', time, timeString);
									}}
								/>
							</Col>
							<Col span={3}>
								<Button
									type="primary"
									onClick={() => {
										deleteShift(index);
									}}
								>
									删除
								</Button>
							</Col>
						</Row>
					))}
					<Row justify="center">
						<Col>
							<Button type="primary" onClick={addDate}>
								添加班次
							</Button>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal
				title="平面图"
				width={750}
				visible={visible}
				onOk={() => {
					setVisible(false);
				}}
				onCancel={() => {
					setVisible(false);
				}}
			>
				{/* <div className="row scrollbar-hover" style={{ maxHeight: 400, overflow: 'scroll' }}> */}
				<Row>
					{plan
						? plan.map((item: any, index: any) => (
								<Col span={6}>
									<a
										onClick={() => {
											bindPlane(item.uuid);
										}}
									>
										<img
											style={{ height: 200, width: '100%' }}
											src={Api.cdnRootPath + item.imgName}
											alt=" "
										/>
										<p style={{ textAlign: 'center' }}>{item.planName}</p>
									</a>
								</Col>
								// <div className="col-md-6">

								// 	</div>
						  ))
						: ''}
					{/* </div> */}
				</Row>
			</Modal>
		</div>
	);
};
export default PatrolRouteList;
