import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { getDepartment } from '../../../services/PermItemService';
import {
	findWuyeGroup,
	getDepartmentList, setDepartmentLeaders,
	setDepartmentManager,
	setDepartmentPrincipal, setDepartmentSuperiorLeaders
} from '../../../services/WuyeService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Col, Form, Input, message, Modal, Row, Space, TreeSelect } from 'antd';
import { connect } from 'dva';
import ConnectState from '../../../models/connect';
import { FormInstance } from 'antd/es/form';
import AuthorizedView from "../../../util/AuthorizedView";

const DepartList: React.FC = (props: any) => {
	const [form] = Form.useForm<FormInstance>();
	const [managerForm] = Form.useForm<FormInstance>();
	const [leadersForm] = Form.useForm<FormInstance>();
	const [superiorLeadersForm] = Form.useForm<FormInstance>();

	const FormItem = Form.Item;
	const { common } = props;
	const [chargeVisible, setChargeVisible] = useState(false);
	const [managerVisible, setManagerVisible] = useState(false);
	const [leadersVisible, setLeadersVisible] = useState(false);
	const [superiorLeadersVisible, setsuperiorLeadersVisible] = useState(false);
	const actionRef = useRef<ActionType>();
	const { empUserList } = common;
	const [empUserData, setEmpUserData] = useState([]);
	useEffect(() => {
		console.log("empUserList::",empUserList);

		empUserList.map((item: any) => {
			item["selectable"] = false
		})
	}, [empUserList])
	const columns = [
		{
			title: '部门名称',
			width: 200,
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: '人数',
			width: 50,
			dataIndex: 'departmentCount',
			key: 'departmentCount',
		},
		{
			title: '管理人',
			width: 200,
			dataIndex: 'principalName',
			key: 'principalName',
		},
		{
			title: '负责人',
			width: 100,
			dataIndex: 'managerName',
			key: 'managerName',
		},{
			title: '领导',
			width: 200,
			dataIndex: 'leaderNames',
			key: 'leaderNames',
		},
		{
			title: '主管领导',
			width: 200,
			dataIndex: 'superiorLeaderNames',
			key: 'superiorLeaderNames',
		},
		{
			title: '操作',
			key: 'action',
			width: 230,
			fixed: 'right',
			align: 'center',
			render: (text: string, record: any) => {
				return (
					<Space>
						<AuthorizedView needAuthority={'business:departments:setPrincipal'}>
							<a
								onClick={() => {
									updatePrincipal(record);
								}}
							>
								管理员
							</a>
						</AuthorizedView>
						<AuthorizedView needAuthority={'business:departments:setPrincipal'}>
							<a
								onClick={() => {
									updateManageUuid(record);
								}}
							>
								负责人
							</a>
						</AuthorizedView>
						<AuthorizedView needAuthority={'business:departments:setPrincipal'}>
							<a
								onClick={() => {
									const formData: any = {
										departmentUuid: record.value,
										managerUuid: (record.leaders|| '').split(','),
									};
									leadersForm.setFieldsValue(formData);
									setLeadersVisible(true);
								}}
							>
								领导
							</a>
						</AuthorizedView>
						<AuthorizedView needAuthority={'business:departments:setPrincipal'}>
							<a
								onClick={() => {
									const formData: any = {
										departmentUuid: record.value,
										managerUuid: (record.superiorLeaders|| '').split(','),
									};
									superiorLeadersForm.setFieldsValue(formData);
									setsuperiorLeadersVisible(true);
								}}
							>
								主管领导
							</a>
						</AuthorizedView>
					</Space>
				)
			},
		},
	];
	const updatePrincipal = (recore: any) => {
		console.log('record:::', recore);
		const formData: any = {
			departmentUuid: recore.value,
			principal: (recore.principal || '').split(','),
		};
		form.setFieldsValue(formData);
		setChargeVisible(true);
	};

	const updateManageUuid = (recore: any) => {
		console.log('record:::', recore);
		const formData: any = {
			departmentUuid: recore.value,
			managerUuid: recore.managerId,
		};
		managerForm.setFieldsValue(formData);
		setManagerVisible(true);
	};

	const updateCharge = async () => {
		form.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			let params = {
				...data,
				principal: data.principal.toString()
			}
			const res = await setDepartmentPrincipal(params);
			if (res.err === 0) {
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg)
			}
			setChargeVisible(false);
		});
	};
	const updateManager = async () => {
		managerForm.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			let params = {
				...data,
				managerUuid: data.managerUuid.toString()
			}
			const res = await setDepartmentManager(params);
			if (res.err === 0) {
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			}
			setManagerVisible(false);
		});
	};
	const updateLeaders = async () => {
		leadersForm.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			let params = {
				...data,
				managerUuid: data.managerUuid.toString()
			}
			const res = await setDepartmentLeaders(params);
			if (res.err === 0) {
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			}
			setLeadersVisible(false);
		});
	};
	const updateSuperiorLeaders = async () => {
		superiorLeadersForm.validateFields().then(async (data: any) => {
			console.log('data:::', data);
			let params = {
				...data,
				managerUuid: data.managerUuid.toString()
			}
			const res = await setDepartmentSuperiorLeaders(params);
			if (res.err === 0) {
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			}
			setsuperiorLeadersVisible(false);
		});
	};
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				rowKey={"value"}
				pagination={false}
				defaultExpandAllRows={true}
				request={async (params: any = {}) => {
					const res = await getDepartmentList({
						companyUuid: localStorage.getItem('companyUuid'),
					});
					console.log('res:::', res);
					res.data.sort((a: any, b: any) => {
						return a.number - b.number;
					});
					res.data.map((item: any) => {
						if (item.children !== undefined && item.children !== null) {
							item.children.sort((a: any, b: any) => {
								return a.number - b.number;
							});
						}
					});
					console.log('res.data', res.data);
					return {
						data: res.data,
						success: true,
					};
				}}
			/>
			<Modal
				title="设置部门管理员"
				visible={chargeVisible}
				onOk={updateCharge}
				onCancel={() => {
					setChargeVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'departmentUuid'} name={'departmentUuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'选择负责人'} name={'principal'} required={true}>
						<TreeSelect
							key={empUserList}
							treeData={empUserList}
							showSearch
							treeCheckable={true}
							multiple={true}
							treeNodeFilterProp="title"
							searchPlaceholder="请选择"
							dropdownStyle={{ overflow: 'auto' }}
							style={{ width: 300, overflow: 'auto' }}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="设置部门负责人"
				visible={managerVisible}
				onOk={updateManager}
				onCancel={() => {
					setManagerVisible(false);
				}}
			>
				<ProxyForm form={managerForm}>
					<FormItem label={'departmentUuid'} name={'departmentUuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'选择负责人'} name={'managerUuid'} required={true}>
						<TreeSelect
							key={empUserList}
							treeData={empUserList}
							showSearch
							multiple={false}
							treeNodeFilterProp="title"
							searchPlaceholder="请选择"
							dropdownStyle={{ overflow: 'auto' }}
							style={{ width: 300, overflow: 'auto' }}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="设置部门领导"
				visible={leadersVisible}
				onOk={updateLeaders}
				onCancel={() => {
					setLeadersVisible(false);
				}}
			>
				<ProxyForm form={leadersForm}>
					<FormItem label={'departmentUuid'} name={'departmentUuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'选择负责人'} name={'managerUuid'} required={true}>
						<TreeSelect
							key={empUserList}
							treeData={empUserList}
							showSearch
							treeCheckable={true}
							multiple={true}
							treeNodeFilterProp="title"
							searchPlaceholder="请选择"
							dropdownStyle={{ overflow: 'auto' }}
							style={{ width: 300, overflow: 'auto' }}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="设置部门主管领导"
				visible={superiorLeadersVisible}
				onOk={updateSuperiorLeaders}
				onCancel={() => {
					setsuperiorLeadersVisible(false);
				}}
			>
				<ProxyForm form={superiorLeadersForm}>
					<FormItem label={'departmentUuid'} name={'departmentUuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'选择负责人'} name={'managerUuid'} required={true}>
						<TreeSelect
							key={empUserList}
							treeData={empUserList}
							showSearch
							treeCheckable={true}
							multiple={true}
							treeNodeFilterProp="title"
							searchPlaceholder="请选择"
							dropdownStyle={{ overflow: 'auto' }}
							style={{ width: 300, overflow: 'auto' }}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(DepartList);
