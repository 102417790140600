import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { findSmsRecord, findSmsSendBatchList } from '../../../services/notificationService';
import { ActionType } from '@ant-design/pro-table';
import { Button, Modal, Tag } from 'antd';
import * as moment from 'moment';
import { batchList } from '../../../services/PayCostManagementService';
import SmsDetail from '../../notification/components/SmsDetail';
import BillRecordDetailList from './BillRecordDetailList';

const BillRecordBatchList: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [visible, setVisiable] = useState(false);
	const [batchId, setBatchId] = useState('');
	const columns = [
		{
			title: '创建时间',
			key: 'createdTime',
			width: 200,
			dataIndex: 'createdTime',
			render: (text: string, record: any) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
		},
		{
			title: '总房间数',
			dataIndex: 'allRoomNumber',
			key: 'allRoomNumber',
		},
		{
			title: '成功房间数',
			dataIndex: 'successRoomNumber',
			key: 'successRoomNumber',
		},
		{
			title: '成功人数',
			dataIndex: 'successCustNumber',
			key: 'successCustNumber',
		},
		{
			title:'总金钱',
			dataIndex: 'amount',
			key: 'amount',
			valueType:'money'
		},
		{
			title: '状态',
			key: 'status',
			dataIndex: 'status',
			// tip: '',
			render: (text: string, record: any) => <Tag color={record.status?'green':'red'}>{record.status?'成功':'失败'}</Tag>,
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						showModal(record);
					}}
				>
					查看详情
				</a>
			),
		},
	];

	const showModal = (value: any) => {
		setBatchId(value.uuid);
		setVisiable(true);
	};

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await batchList({
						page: params.current,
						size: params.pageSize,
					});
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				title="详情"
				visible={visible}
				onOk={() => {
					setVisiable(false);
				}}
				onCancel={() => {
					setVisiable(false);
				}}
				width={1200}
				footer={null}
			>
				<BillRecordDetailList key={batchId} batchId={batchId} />
			</Modal>
		</div>
	);
};

export default BillRecordBatchList;
