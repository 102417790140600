import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { Button, Form, Input, InputNumber, message, Modal, Select, Image, Space, Switch, TreeSelect, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import Api from '../../../data/API';
import {
	addScheme,
	deleteScheme,
	getSchemeList,
	getSchemeTechList,
	getSchemeTypeList,
	getTreeSchemeTechList,
	getTreeSchemeTypeList,
	updateSchemeStatus,
} from '../../../services/scheme';
import { connect } from 'dva';
import ConnectState from '../../../models/connect';
import UpdateScheme from '../components/UpdateScheme';
import GsTreeSelect from '../../../components/GsTreeSelect';
import DAL from '../../../data/DAL';
import * as XLSX from 'xlsx';
import moment from 'moment';
const SchemeList: React.FC = (props: any) => {
	useEffect(() => {
		getAllTech().then();
		getAlltype().then();
		getTypeList().then();
		getTechList().then();
	}, []);

	const renChildren = (list) => {
		list.forEach((element) => {
			element.title = element.name;
			element.value = element.uuid;
			if (element.children != undefined && element.children.length > 0) {
				renChildren(element.children);
			}
		});
	};
	const getTypeList = async () => {
		const rsp = await getTreeSchemeTypeList({});
		if (rsp.err === 0) {
			const data = rsp.data || [];
			renChildren(data);
			settypeList([...data]);
		}
	};

	const getTechList = async () => {
		const rsp = await getTreeSchemeTechList({});
		if (rsp.err === 0) {
			const data = rsp.data || [];
			renChildren(data);
			settechList([...data]);
		}
	};

	const getAlltype = async () => {
		const res = await getSchemeTypeList({});
		if (res.err == 0) {
			setallType(res.data);
		}
	};
	const getAllTech = async () => {
		const res = await getSchemeTechList({});
		if (res.err == 0) {
			setallTech(res.data);
		}
	};

	const statusEnum = {
		true: '上架',
		false: '下架',
	};
	const [allType, setallType] = useState([]);
	const [allTech, setallTech] = useState([]);
	const Search = Input.Search;
	const [search, setSearch] = useState('');
	const [techList, settechList] = useState([]);
	const actionRef = useRef<ActionType>();
	const actionRef1 = useRef<ActionType>();
	const [typeList, settypeList] = useState([]);
	const { common } = props;
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const { scenarioTypeList = [], schemeTechList = [],empUserList } = common;
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [sortData, setSortData] = useState([]);
	const [scenarioImg, setscenarioImg] = useState('');
	const [quanXuan, setquanXuan] = useState(false);
	const [dataTotal, setdataTotal] = useState(0);
	const [updateVisible, setupdateVisible] = useState(false);
	const [uuid, setuuid] = useState('');
	const [techUuid, settechUuid] = useState('');
	const [typeId, settypeId] = useState('');
	const [name, setname] = useState('');
	const [sortByType, setsortByType] = useState('数字排序');
	const [status, setstatus] = useState('');
	const [topType, settopType] = useState('');
	const [infoSearch, setinfoSearch] = useState('');
	const [typeUuids, settypeUuids] = useState([]);
	const [techUuids, settechUuids] = useState([]);
	const fielName = { label: 'title', value: 'value', children: 'children' };
	const [loading, setloading] = useState(false);
	const [dateKey, setdateKey] = useState(new Date().getTime().toString());
	const FormItem = Form.Item;

	const columns = [
		{
			title: '方案/产品名称',
			dataIndex: 'name',
			key: 'name',
		},

		{
			title: '公司名称',
			dataIndex: 'companyName',
			key: 'companyName',
		},
		{
			title: '公司简介',
			hideInTable: true,
			dataIndex: 'companyNote',
			key: 'companyNote',
		},
		{
			title: '列表图',
			dataIndex: 'listImg',
			key: 'listImg',
			render: (text: string, record: any) => (
				<>
					<Image src={Api.cdnRootPath + record.listImg} height={80} width={80} />
				</>
			),
		},
		{
			title: '联系人',
			hideInTable: true,
			dataIndex: 'contactName',
		},
		{
			title: '联系电话',
			hideInTable: true,
			dataIndex: 'phone',
		},
		{
			title: '方案/产品描述',
			hideInTable: true,
			dataIndex: 'remark',
		},
		{
			title: '方案价格',
			hideInTable: true,
			dataIndex: 'productPrice',
			key: 'productPrice',
		},
		{
			title: '贵公司产品/技术可解决的哪方面的问题',
			hideInTable: true,
			dataIndex: 'solveQuestion',
			key: 'solveQuestion',
		},
		{
			title: '痛点/难点',
			hideInTable: true,
			dataIndex: 'painPoints',
			key: 'painPoints',
		},
		{
			title: '需要的资源',
			hideInTable: true,
			dataIndex: 'requiredResources',
			key: 'requiredResources',
		},
		{
			title: '合作伙伴',
			hideInTable: true,
			dataIndex: 'partner',
			key: 'partner',
		},
		{
			title: '排序值',
			dataIndex: 'sortBy',
			key: 'sortBy',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			valueEnum: statusEnum,
		},
		{
			title: '面向客户',
			dataIndex: 'mxkh',
			hideInTable: true,
			key: 'mxkh',
		},
		{
			title: '应用场景',
			hideInTable: true,
			valueType: 'types',
			dataIndex: 'typeUuid',
		},
		{
			title: '一级应用场景',
			hideInTable: true,
			valueType: 'oneTypes',
			dataIndex: 'typeUuid',
		},
		{
			title: '二级应用场景',
			hideInTable: true,
			valueType: 'twoTypes',
			dataIndex: 'typeUuid',
		},
		{
			title: '三级级应用场景',
			hideInTable: true,
			valueType: 'threeTypes',
			dataIndex: 'typeUuid',
		},
		{
			title: '通用技术',
			hideInTable: true,
			valueType: 'techs',
			dataIndex: 'techUuid',
		},
		{
			title: '一级通用技术',
			hideInTable: true,
			valueType: 'oneTechs',
			dataIndex: 'techUuid',
		},
		{
			title: '二级通用技术',
			hideInTable: true,
			valueType: 'twoTechs',
			dataIndex: 'techUuid',
		},
		{
			title: '三级级通用技术',
			hideInTable: true,
			valueType: 'threeTechs',
			dataIndex: 'techUuid',
		},
		{
			title: '方案优势',
			hideInTable: true,
			dataIndex: 'program',
		},
		{
			title: '服务案例',
			hideInTable: true,
			valueType: 'jsonArray',
			dataIndex: 'successStories',
		},

		{
			title: '服务案例名称',
			hideInTable: true,
			valueType: 'jsonArrayNames',
			dataIndex: 'successStories',
		},
		{
			title: '案例一名称',
			hideInTable: true,
			valueType: 'jsonArrayName1',
			dataIndex: 'successStories',
		},
		{
			title: '案例一服务对象',
			hideInTable: true,
			valueType: 'jsonArrayServiceObject1',
			dataIndex: 'successStories',
		},
		{
			title: '案例一简介',
			hideInTable: true,
			valueType: 'jsonArrayRemark1',
			dataIndex: 'successStories',
		},
		{
			title: '案例一成效',
			hideInTable: true,
			valueType: 'jsonArrayChengXiao1',
			dataIndex: 'successStories',
		},
		{
			title: '案例二名称',
			hideInTable: true,
			valueType: 'jsonArrayName2',
			dataIndex: 'successStories',
		},
		{
			title: '案例二服务对象',
			hideInTable: true,
			valueType: 'jsonArrayServiceObject2',
			dataIndex: 'successStories',
		},
		{
			title: '案例二简介',
			hideInTable: true,
			valueType: 'jsonArrayRemark2',
			dataIndex: 'successStories',
		},
		{
			title: '案例二成效',
			hideInTable: true,
			valueType: 'jsonArrayChengXiao2',
			dataIndex: 'successStories',
		},
		{
			title: '案例三名称',
			hideInTable: true,
			valueType: 'jsonArrayName3',
			dataIndex: 'successStories',
		},
		{
			title: '案例三服务对象',
			hideInTable: true,
			valueType: 'jsonArrayServiceObject3',
			dataIndex: 'successStories',
		},
		{
			title: '案例三简介',
			hideInTable: true,
			valueType: 'jsonArrayRemark3',
			dataIndex: 'successStories',
		},
		{
			title: '案例三成效',
			hideInTable: true,
			valueType: 'jsonArrayChengXiao3',
			dataIndex: 'successStories',
		},
		{
			title: '案例四名称',
			hideInTable: true,
			valueType: 'jsonArrayName4',
			dataIndex: 'successStories',
		},
		{
			title: '案例四服务对象',
			hideInTable: true,
			valueType: 'jsonArrayServiceObject4',
			dataIndex: 'successStories',
		},
		{
			title: '案例四简介',
			hideInTable: true,
			valueType: 'jsonArrayRemark4',
			dataIndex: 'successStories',
		},
		{
			title: '案例四成效',
			hideInTable: true,
			valueType: 'jsonArrayChengXiao4',
			dataIndex: 'successStories',
		},
		{
			title: '案例五名称',
			hideInTable: true,
			valueType: 'jsonArrayName5',
			dataIndex: 'successStories',
		},
		{
			title: '案例五服务对象',
			hideInTable: true,
			valueType: 'jsonArrayServiceObject5',
			dataIndex: 'successStories',
		},
		{
			title: '案例五简介',
			hideInTable: true,
			valueType: 'jsonArrayRemark5',
			dataIndex: 'successStories',
		},
		{
			title: '案例五成效',
			hideInTable: true,
			valueType: 'jsonArrayChengXiao5',
			dataIndex: 'successStories',
		},
		{
			title: '收藏量',
			dataIndex: 'collectionNumber',
			key: 'collectionNumber',
		},
		{
			title: '点击量',
			dataIndex: 'clickNumber',
			key: 'clickNumber',
		},
		{
			title: '创建人',
			dataIndex: 'createdEmpName',
			key: 'createdEmpName',
		},
		{
			title: '修改人',
			dataIndex: 'updateEmpName',
			key: 'updateEmpName',
		},
		{
			title: '操作',
			key: 'action',
			align: 'left',
			render: (record: any) => (
				<span>
					<a
						onClick={() => {
							openUpdateModal(record);
						}}
					>
						编辑
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							updateStatus(record);
						}}
					>
						{record.status ? '下架' : '上架'}
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];

	const updateStatus = async (item: any) => {
		Modal.confirm({
			title: '你确定要' + (item.status ? '下架' : '上架') + item.name + '吗？',
			onOk: async () => {
				const formData: any = {
					uuid: item.uuid,
					status: item.status ? false : true,
				};
				const res = await updateSchemeStatus(formData);
				if (res.err === 0) {
					message.success('修改成功');
					actionRef.current.reload();
				} else {
					message.error(res.msg);
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const openUpdateModal = (item: any) => {
		setuuid(item.uuid);
		setdateKey(new Date().getTime().toString());
		setupdateVisible(true);
	};
	const deleteItem = (value: any) => {
		Modal.confirm({
			title: '你确定要删除' + value.name + '吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await deleteScheme({ uuid: value.uuid });
				if (res.err === 0) {
					message.success('删除成功');
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -2) {
					message.warning(res.msg);
				} else {
					message.error(res.msg);
				}
			},
		});
	};

	const openAddModal = () => {
		form.resetFields();
		setVisible(true);
	};
	const addItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				name: data.name,
				remark: data.remark,
				listImg: data.scenarioImg,
				szSelected: data.szSelected !== undefined ? data.szSelected : false,
				schemeTop: data.schemeTop !== undefined ? data.schemeTop : false,
				techTop: data.szSelected !== undefined ? data.szSelected : false,
				companyName: data.companyName,
			};
			if (data.typeUuid != undefined && data.typeUuid.length > 0) {
				let values = [];
				data.typeUuid.map((item) => {
					values.push(item.value);
				});
				formData.typeUuid = values.toString();
			} else {
				formData.typeUuid = '';
			}
			if (data.techUuid != undefined && data.techUuid.length > 0) {
				let values = [];
				data.techUuid.map((item) => {
					values.push(item.value);
				});
				formData.techUuid = values.toString();
			} else {
				formData.techUuid = '';
			}
			const res = await addScheme(formData);
			if (res.err === 0) {
				setVisible(false);
				message.success('添加成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.success('添加失败');
			}
		});
	};

	const tableOperationsBarRender = () => [
		<Button type="primary" onClick={openAddModal}>
			新增
		</Button>,
	];

	const saveItem = () => {
		setupdateVisible(false);
		actionRef.current.reload();
	};
	const getFileName = (value: any) => {
		setscenarioImg(value);
	};
	const toChinesNum = (num) => {
		let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
		let unit = ['', '十', '百', '千', '万'];
		num = parseInt(num);
		let getWan = (temp) => {
			let strArr = temp.toString().split('').reverse();
			let newNum = '';
			for (var i = 0; i < strArr.length; i++) {
				newNum =
					(i == 0 && strArr[i] == 0
						? ''
						: i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
						? ''
						: changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) + newNum;
			}
			return newNum;
		};
		return getWan(num);
	};
	const tableSearchBarRender = () => [
		<Space>
			<GsTreeSelect
				style={{ width: 150 }}
				checkable={false}
				treeCheckable={false}
				treeData={typeList}
				treeNodeFilterProp={'title'}
				placeholder={'应用场景'}
				defaultValue={typeId == '' ? undefined : typeId}
				multiple={false}
				defaultExpandAll={false}
				onChange={(ev: any) => {
					settypeId(ev);
				}}
			/>
			<GsTreeSelect
				style={{ width: 150 }}
				checkable={false}
				treeCheckable={false}
				treeData={techList}
				treeNodeFilterProp={'title'}
				placeholder={'通用技术'}
				defaultValue={techUuid == '' ? undefined : techUuid}
				multiple={false}
				defaultExpandAll={false}
				onChange={(ev: any) => {
					settechUuid(ev);
				}}
			/>
			<Select
				style={{ width: 80 }}
				value={topType}
				onChange={(value) => {
					settopType(value);
				}}
			>
				<Select.Option value={''}>全部</Select.Option>
				<Select.Option key={'数智精选'} value={'数智精选'}>
					数智精选
				</Select.Option>
				<Select.Option key={'应用场景置顶'} value={'应用场景置顶'}>
					应用场景置顶
				</Select.Option>
				<Select.Option key={'通用技术置顶'} value={'通用技术置顶'}>
					通用技术置顶
				</Select.Option>
			</Select>
			<Select
				style={{ width: 80 }}
				value={sortByType}
				onChange={(value) => {
					setsortByType(value);
				}}
			>
				<Select.Option value={'数字排序'}>数字排序降序</Select.Option>
				<Select.Option value={'更新时间'}>更新时间降序</Select.Option>
				<Select.Option value={'时间'}>时间降序</Select.Option>
				<Select.Option value={'收藏量'}>收藏量降序</Select.Option>
				<Select.Option key={'点击量'} value={'点击量'}>
					点击量降序
				</Select.Option>
			</Select>
			<Select
				style={{ width: 80 }}
				value={status}
				placeholder={'状态'}
				onChange={(value: any) => {
					setstatus(value);
				}}
			>
				<Select.Option value="">全部</Select.Option>
				<Select.Option value={1}>上架</Select.Option>
				<Select.Option value={0}>下架</Select.Option>
			</Select>
			<Input
				style={{ minWidth: 150 }}
				placeholder="名称,公司名,创建人"
				onChange={(e) => {
					setname(e.target.value);
				}}
			/>
			<Input
				style={{ minWidth: 150 }}
				placeholder="详情"
				onChange={(e) => {
					setinfoSearch(e.target.value);
				}}
			/>

			<Button
				type="primary"
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>
		</Space>,
	];
	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setloading(true);
		if (quanXuan) {
			const formData: any = {
				typeId: typeId,
				status: status,
				name: name,
				techUuid: techUuid,
				sortByType: sortByType,
				infoSearch: infoSearch,
				sortType: 'desc',
			};
			if (topType != undefined && topType !== null && topType !== '') {
				switch (topType) {
					case '数智精选':
						formData.szSelected = 1;
						break;
					case '应用场景置顶':
						formData.schemeTop = 1;
						break;
					case '通用技术置顶':
						formData.techTop = 1;
						break;
					default:
						break;
				}
			}
			const res = await getSchemeList(formData);
			setloading(false);
			if (res.err == 0) {
				setExprotData(res.data);
			} else {
				message.error(res.msg);
			}
		} else {
			setloading(false);
			setExprotData(selectedRows);
		}
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType == 'jsonArray') {
					data.push(serviceJsonArray(selectedRows[i][item.dataIndex]));
				} else if (item.valueType == 'jsonArrayNames') {
					data.push(serviceJsonArrayNames(selectedRows[i][item.dataIndex]));
				} else if (item.valueType == 'jsonArrayName1') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'name', 0));
				} else if (item.valueType == 'jsonArrayName2') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'name', 1));
				} else if (item.valueType == 'jsonArrayName3') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'name', 2));
				} else if (item.valueType == 'jsonArrayName4') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'name', 3));
				} else if (item.valueType == 'jsonArrayName5') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'name', 4));
				} else if (item.valueType == 'jsonArrayServiceObject1') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'serviceObject', 0));
				} else if (item.valueType == 'jsonArrayServiceObject2') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'serviceObject', 1));
				} else if (item.valueType == 'jsonArrayServiceObject3') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'serviceObject', 2));
				} else if (item.valueType == 'jsonArrayServiceObject4') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'serviceObject', 3));
				} else if (item.valueType == 'jsonArrayServiceObject5') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'serviceObject', 4));
				} else if (item.valueType == 'jsonArrayRemark1') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'remark', 0));
				} else if (item.valueType == 'jsonArrayRemark2') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'remark', 1));
				} else if (item.valueType == 'jsonArrayRemark3') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'remark', 2));
				} else if (item.valueType == 'jsonArrayRemark4') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'remark', 3));
				} else if (item.valueType == 'jsonArrayRemark5') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'remark', 4));
				} else if (item.valueType == 'jsonArrayChengXiao1') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'chengXiao', 0));
				} else if (item.valueType == 'jsonArrayChengXiao2') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'chengXiao', 1));
				} else if (item.valueType == 'jsonArrayChengXiao3') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'chengXiao', 2));
				} else if (item.valueType == 'jsonArrayChengXiao4') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'chengXiao', 3));
				} else if (item.valueType == 'jsonArrayChengXiao5') {
					data.push(serviceJsonArrayByZiDuan(selectedRows[i][item.dataIndex], 'chengXiao', 4));
				} else if (item.valueType == 'types') {
					data.push(renderSchemeType(selectedRows[i][item.dataIndex],"all"));
				} else if (item.valueType == 'oneTypes') {
					data.push(renderSchemeType(selectedRows[i][item.dataIndex],"1"));
				} else if (item.valueType == 'twoTypes') {
					data.push(renderSchemeType(selectedRows[i][item.dataIndex],"2"));
				} else if (item.valueType == 'threeTypes') {
					data.push(renderSchemeType(selectedRows[i][item.dataIndex],"3"));
				} else if (item.valueType == 'techs') {
					data.push(renderSchemeTech(selectedRows[i][item.dataIndex],"all"));
				} else if (item.valueType == 'oneTechs') {
					data.push(renderSchemeTech(selectedRows[i][item.dataIndex],"1"));
				} else if (item.valueType == 'twoTechs') {
					data.push(renderSchemeTech(selectedRows[i][item.dataIndex],"2"));
				}else if (item.valueType == 'threeTechs') {
					data.push(renderSchemeTech(selectedRows[i][item.dataIndex],"3"));
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '解决方案');
		XLSX.writeFile(wb, '解决方案.xlsx');
	};
	const serviceJsonArray = (string: any) => {
		let data = JSON.parse(string || '[]');

		let string1 = '';
		if (data.length > 0) {
			data.forEach((item, index) => {
				string1 =
					string1 +
					'案例' +
					toChinesNum(index + 1) +
					'名称：' +
					(DAL.dal.isNotNull(item.name) ? item.name : '') +
					`\t\n` +
					'案例' +
					toChinesNum(index + 1) +
					'服务对象：' +
					(DAL.dal.isNotNull(item.serviceObject) ? item.serviceObject : '') +
					`\t\n` +
					'案例' +
					toChinesNum(index + 1) +
					'简介：' +
					(DAL.dal.isNotNull(item.remark) ? item.remark : '') +
					`\t\n` +
					'案例' +
					toChinesNum(index + 1) +
					'成效：' +
					(DAL.dal.isNotNull(item.chengXiao) ? item.chengXiao : '') +
					`\t\n` +
					`\t\n`;
			});
		}
		console.log('serviceData::', string1);
		return string1;
	};
	const serviceJsonArrayByZiDuan = (string: any, ziduan: string, index: number) => {
		let data = JSON.parse(string || '[]');
		if (data.length > index) {
			const item = data[index];
			console.log('ziduanInfo', item);

			return DAL.dal.isNotNull(item[ziduan]) ? item[ziduan] : '';
		} else {
			return '';
		}
	};
	const serviceJsonArrayNames = (string: any) => {
		let data = JSON.parse(string || '[]');

		let string1 = '';
		if (data.length > 0) {
			data.forEach((item, index) => {
				string1 =
					string1 +
					'案例' +
					toChinesNum(index + 1) +
					'名称：' +
					(DAL.dal.isNotNull(item.name) ? item.name : '') +
					`\t\n`;
			});
		}
		console.log('serviceData::', string1);
		return string1;
	};

	const chilrenSchemeType = (typeTree: any, pid: any, allType: any) => {
		const item = allType.find((item) => item.id == pid);
		if (item) {
			const tItme = typeTree.get(item.id);
			if (tItme == undefined) {
				typeTree.set(item.id, item);
			}
			if (item.pid !== 0) {
				chilrenSchemeType(typeTree, item.pid, allType);
			}
		}
	};

	const generateOptions = (params: any) => {
		const result = [];
		params.map((param) => {
			if (param.pid == 0) {
				const item:any = {
					id: param.id,
					uuid: param.uuid,
					name:param.name
				};
				item.children = getChilds(param.id, params);
				result.push(item);
			}
		});
		return result;
	};

	const getChilds = (pid, params) => {
		let childrens = [];
		params.map((param) => {
			if (param.pid == pid) {
				childrens.push({ id: param.id ,uuid: param.uuid,
					name:param.name});
			}
		});
		childrens.map((item) => {
			const c1 = getChilds(item.id, params);
			if (c1.length > 0) {
				item.children = c1;
			}
		});
		return childrens;
	};

	const schemeTypeTree = (types) => {
		let typeTree: any = new Map();
		let items = [];
		if (DAL.dal.isNotNull(types)) {
			const uuids = types.split(',');
			uuids.map((uuid) => {
				const item = allType.find((item) => item.uuid == uuid);
				if (item) {
					const tItme = typeTree.get(item.id);
					if (tItme == undefined) {
						typeTree.set(item.id, item);
					}
					if (item.pid !== 0) {
						chilrenSchemeType(typeTree, item.pid, allType);
					}
				}
			});
		}
		typeTree.forEach((value, key, map) => {
			items.push(value);
		});
		const item = generateOptions(items)
		console.log("item:::",item,items);

		return item;
	};

	const schemeTechTree = (types) => {
		let typeTree: any = new Map();
		let items = [];
		if (DAL.dal.isNotNull(types)) {
			const uuids = types.split(',');
			uuids.map((uuid) => {
				const item = allTech.find((item) => item.uuid == uuid);
				if (item) {
					const tItme = typeTree.get(item.id);
					if (tItme == undefined) {
						typeTree.set(item.id, item);
					}
					if (item.pid !== 0) {
						chilrenSchemeType(typeTree, item.pid, allTech);
					}
				}
			});
		}
		typeTree.forEach((value, key, map) => {
			items.push(value);
		});
		const item = generateOptions(items)
		return item;
	};
	const renderSchemeType = (types: string,xianshi:any) => {
		let typeNames: any = schemeTypeTree(types);
		let string = '';
		if (xianshi == 'all') {
			typeNames.map(item => {
				string = string + item.name
				if (item.children && item.children.length > 0) {
					string = string + ":"
					item.children.map(c1 => {
						string = string + c1.name + '、'
						if (c1.children && c1.children.length > 0) {
							c1.children.map(c2 => {
								string = string + c2.name + '、'
							})
						} else {
							string = string + `\t\n`
						}
					})
				} else {
					string = string + `\t\n`
				}
			})
		} else if (xianshi == '1') {
			typeNames.map(item => {
				string  = string + item.name + `\t\n`;
			})
		}else if (xianshi == '2') {
			typeNames.map(item => {
				if (item.children) {
					item.children.map(c1 => {
						string  = string + c1.name + `\t\n`;
					})
				}
			})
		}else if (xianshi == '3') {
			typeNames.map(item => {
				if (item.children) {
					item.children.map(c1 => {
						if (c1.children) {
							c1.children.map(c2 => {
								string  = string + c2.name + `\t\n`;

							})
						}
					})
				}
			})
		}

		return string;
	};
	const renderSchemeTech = (types: string,xianshi:any) => {
		let typeNames: any = schemeTechTree(types);
		let string = '';
		if (xianshi == 'all') {
			typeNames.map(item => {
				string = string + item.name
				if (item.children && item.children.length > 0) {
					string = string + ":"
					item.children.map(c1 => {
						string = string + c1.name + '、'
						if (c1.children && c1.children.length > 0) {
							c1.children.map(c2 => {
								string = string + c2.name + '、'
							})
						} else {
							string = string + `\t\n`
						}
					})
				} else {
					string = string + `\t\n`
				}
			})
		} else if (xianshi == '1') {
			typeNames.map(item => {
				string  = string + item.name + `\t\n`;
			})
		}else if (xianshi == '2') {
			typeNames.map(item => {
				if (item.children) {
					item.children.map(c1 => {
						string  = string + c1.name + `\t\n`;
					})
				}
			})
		}else if (xianshi == '3') {
			typeNames.map(item => {
				if (item.children) {
					item.children.map(c1 => {
						if (c1.children) {
							c1.children.map(c2 => {
								string  = string + c2.name + `\t\n`;

							})
						}
					})
				}
			})
		}

		return string;
	};


	return (
		<>
			<Spin spinning={loading}>
				<GSTable
					columns={columns}
					actionRef={actionRef}
					searchBarRender={tableSearchBarRender()}
					operationsBarRender={tableOperationsBarRender()}
					rowKey="uuid"
					rowSelection={{
						fixed: true,
						columnWidth: 100,
						preserveSelectedRowKeys: true,
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys: any, selectedRows: any) => {
							setquanXuan(false);
							setselectedRowKeys(selectedRowKeys);
						},
						selections: [
							{
								key: 'SELECT_ALL',
								text: '全选全部页面',
								onSelect: (changableRowKeys: any) => {
									setquanXuan(true);
									setselectedRowKeys(changableRowKeys);
								},
							},
						],
					}}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {quanXuan ? dataTotal : selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
										}}
									>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						console.log('tableAlertOptionRender::::', selectedRows);
						return (
							<Space size={16}>
								<a onClick={() => exprotData(selectedRows)}>导出数据</a>
							</Space>
						);
					}}
					request={async (params: any = {}, sort: any, filter: any) => {
						const formData: any = {
							page: params.current,
							size: params.pageSize,
							typeId: typeId,
							status: status,
							name: name,
							techUuid: techUuid,
							sortByType: sortByType,
							infoSearch: infoSearch,
							sortType: 'desc',
						};
						if (topType != undefined && topType !== null && topType !== '') {
							switch (topType) {
								case '数智精选':
									formData.szSelected = 1;
									break;
								case '应用场景置顶':
									formData.schemeTop = 1;
									break;
								case '通用技术置顶':
									formData.techTop = 1;
									break;
								default:
									break;
							}
						}
						const res = await getSchemeList(formData);
						if (res.err !== 0) {
							setSortData([]);
							return {
								data: [],
								total: 0,
							};
						}
						if (quanXuan) {
							let uuids: any = [];
							res.data.map((item: any) => {
								uuids.push(item.uuid);
							});
							setselectedRowKeys(uuids);
							setdataTotal(res.total);
						}
						setdataTotal(res.total);
						setSortData(res.data);
						return {
							data: res.data,
							total: res.total,
							success: true,
						};
					}}
				/>
				<Modal
					visible={visible}
					title={'添加'}
					maskClosable={false}
					onOk={addItem}
					onCancel={() => {
						setVisible(false);
					}}
				>
					<ProxyForm form={form}>
						<FormItem hidden={true} name={'uuid'}>
							<Input />
						</FormItem>
						<FormItem
							label="方案/产品名称"
							name={'name'}
							rules={[
								// { required: true,message:'请填写方案/产品名称' },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!DAL.dal.isNotNull(value)) {
											return Promise.reject(new Error('请填写方案/产品名称'));
										} else if (value.length > 15) {
											return Promise.reject(new Error('字数不能超过15个'));
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
						>
							<Input></Input>
						</FormItem>
					</ProxyForm>
				</Modal>
				<UpdateScheme
					scenarioTypeList={typeList}
					schemeTechList={techList}
					key={dateKey}
					onOk={saveItem}
					visible={updateVisible}
					uuid={uuid}
					onCancel={() => {
						setupdateVisible(false);
					}}
				></UpdateScheme>
			</Spin>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(SchemeList);
