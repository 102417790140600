import * as React from 'react';
import { Button, Col, Row } from 'antd';

/**
 * 流程组件
 */

interface LiuchengComponent {
	clickCallback: any;
}

class WidgetComponents extends React.Component<LiuchengComponent> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				<Row gutter={8} justify="space-around" align="middle">
					<Col span={8}>
						<Button
							id="text"
							name="单行文本"
							onClick={(e) => this.props.clickCallback({ type: 'text', name: '单行文本' })}
						>
							单行文本
						</Button>
					</Col>

					<Col span={8}>
						<Button
							id="phoneNumber"
							name="手机号码"
							onClick={(e) => this.props.clickCallback({ type: 'phoneNumber', name: '手机号码' })}
						>
							手机号码
						</Button>
					</Col>
					<Col span={8}>
						<Button
							id="number"
							name="数字"
							onClick={(e) => this.props.clickCallback({ type: 'number', name: '数字"' })}
						>
							数字
						</Button>
					</Col>
				</Row>
				<Row gutter={8} justify="space-around" align="middle">
					<Col span={8}>
						<Button
							id="dateTime"
							name="日期时间"
							onClick={(e) => this.props.clickCallback({ type: 'dateTime', name: '日期时间' })}
						>
							日期时间
						</Button>
					</Col>

					<Col span={8}>
						<Button
							id="textarea"
							name="多行文本"
							onClick={(e) => this.props.clickCallback({ type: 'textarea', name: '多行文本' })}
						>
							多行文本
						</Button>
					</Col>
					<Col span={8}>
						<Button
							id="date"
							name="日期"
							onClick={(e) => this.props.clickCallback({ type: 'date', name: '日期' })}
						>
							日期
						</Button>
					</Col>
				</Row>

				<Row gutter={8} justify="space-around" align="middle">
					<Col span={8}>
						<Button
							id="checkbox"
							name="多选按钮"
							onClick={(e) => this.props.clickCallback({ type: 'checkbox', name: '多选按钮' })}
						>
							多选按钮
						</Button>
					</Col>
					<Col span={8}>
						<Button
							id="radio"
							name="单选按钮"
							onClick={(e) => this.props.clickCallback({ type: 'radio', name: '单选按钮' })}
						>
							单选按钮
						</Button>
					</Col>
					<Col span={8}></Col>
				</Row>
			</div>
		);
	}
}

export default WidgetComponents;
