import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { dingDanList, findSignList, helpSign } from '../../../services/huodongService';
import { Form, Input, message, Modal, Select } from 'antd';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { FormInstance } from 'antd/es/form';
const LookSign: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const Search = Input.Search;
	const Option = Select.Option;
	const [sign, setSign] = useState('全部');
	const [signForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [visible, setVisible] = useState(false);
	const columns = [
		{
			title: '序号',
			dataIndex: 'id',
		},
		{
			title: '姓名',
			dataIndex: 'userName',
		},
		{
			title: '手机号码',
			dataIndex: 'phone',
		},
		{
			title: '签到时间',
			dataIndex: 'checkTime',
		},
		{
			title: '状态',
			dataIndex: 'status',
		},
		{
			title: '负责人',
			dataIndex: 'operator',
		},
		{
			title: '备注',
			dataIndex: 'remarks',
		},
		{
			title: '操作',
			render: (text: any, item: any, index: any) => (
				<ul className="ant-list-item-action">
					<li>
						<a
							className={item.status === '已签到' ? 'disableda' : ''}
							onClick={() => {
								signIn(item);
							}}
						>
							签到
						</a>
					</li>
				</ul>
			),
		},
	];

	const signIn = (value: any) => {
		console.log('sign:::', value);
		const formData: any = {
			uuid: value.uuid,
			type: value.remarks,
			operator: value.operator,
			checkTime: value.checkTime,
		};
		signForm.setFieldsValue(formData);
		setVisible(true);
	};

	const tableSearchBarRender = () => [
		<Select
			onChange={(value) => {
				setSign(value);
				actionRef.current.reloadAndRest();
			}}
			defaultValue={'全部'}
		>
			<Option value="全部">全部</Option>
			<Option value="待签到">未签到</Option>
			<Option value="已签到">已签到</Option>
		</Select>,
		<Search
			placeholder={'姓名'}
			onSearch={(value) => {
				setSearch(value);
				actionRef.current.reloadAndRest();
			}}
			enterButton
		/>,
	];
	const tableOperationsBarRender = () => [];
	const updateItem = () => {
		signForm.validateFields().then(async (data: any) => {
			const formData: any = {
				uuid: data.uuid,
				remarks: data.remarks,
				operator: data.operator,
				checkTime: data.checkTime,
				status: '已签到',
			};
			const res = await helpSign(formData);
			console.log('formData:::', formData);
			if (res.err === 0) {
				message.success('成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
				signForm.resetFields();
				setVisible(false);
			} else {
				message.error('失败');
			}
		});
	};
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let page = window.location.search;
					let c = page.split('&');
					let uuid = c[0].split('=')[1];
					const res = await findSignList({
						huodongUuid: uuid,
						page: params.current,
						size: params.pageSize,
						userName: search,
						status: sign === '全部' ? null : sign,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>

			<Modal
				visible={visible}
				title={'签到'}
				onOk={updateItem}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={signForm}>
					<FormItem hidden={true} name={'uuid'} label={'uuid'}>
						<Input />
					</FormItem>
					<FormItem hidden={true} name={'operator'} label={'operator'}>
						<Input />
					</FormItem>
					<FormItem hidden={true} name={'checkTime'} label={'checkTime'}>
						<Input />
					</FormItem>
					<FormItem label={'备注'} name={'remark'}>
						<Input />
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};

export default LookSign;
