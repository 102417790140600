import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import LuckDraw from './LuckDraw';

export default function LuckDrawMiniApp() {
	const rootUrl = '/luckdraw';
	const menus: MiniAppMenu[] = [
		{
			authority: 'lottery_management:luck_draw',
			name: '抽奖管理',
			path: 'luck-draw',
			component: LuckDraw,
		},
	];
	const routes: MiniAppRoute[] = [
		{
			path: 'luck-draw',
			component: LuckDraw,
		},
	];

	return <MiniApp miniAppName={'抽奖'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
