import Api from '../data/API';
import { async } from 'q';

export async function findWuyeDevicePatrol(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_wuye_device_patrol', params);
}

export async function deleteDevicePatrol(params: any) {
	return Api.postWithAuth('/api/patrolinspection/delete_device_patrol', params);
}

export async function updataDeviceName(params: any) {
	return Api.postWithAuth('/api/patrolinspection/updata_device_name', params);
}

export async function addDevicePatrol(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_device_patrol', params);
}

export async function findException(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_exception', params);
}

export async function deleteXunException(params: any) {
	return Api.postWithAuth('/api/patrolinspection/deleteXunException', params);
}

export async function nightPatrolSchidulHistorical(params: any) {
	return Api.postWithAuth('/api/patrolinspection/night_patrol_schidul_historical', params);
}

export async function findNotStaffRoute(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_not_staff_route', params);
}

export async function patrolGRouteHistorical(params: any) {
	return Api.postWithAuth('/api/patrolinspection/patrolG_route_historical', params);
}

export async function getPlanByUUID(params: any) {
	return Api.postWithAuth('/api/patrolinspection/get_plan_by_UUID', params);
}

export async function getPlanByRoute(params: any) {
	return Api.postWithAuth('/api/patrolinspection/get_plan_by_route', params);
}

export async function addImgDevice(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_img_device', params);
}

export async function findAllGroup(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_all_group', params);
}
export async function findWuyeRoute(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_wuye_route', params);
}
export async function findAllWuyeStaff(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_all_wuye_staff', params);
}
export async function findPlan(params: any) {
	return Api.postWithAuth('/api/patrolinspection/findPlan', params);
}

export async function findXunJianRecordList(params: any) {
	return Api.postWithAuth('/api/patrolinspection/findXunJianRecordList', params);
}
export async function getXunJianInfo(params: any) {
	return Api.postWithAuth('/api/patrolinspection/getXunJianInfo', params);
}
export async function findXunGengRecordList(params: any) {
	return Api.postWithAuth('/api/patrolinspection/findXunGengRecordList', params);
}
export async function getXunGengInfo(params: any) {
	return Api.postWithAuth('/api/patrolinspection/getXunGengInfo', params);
}
export async function addRoute(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_route', params);
}
export async function findGroup(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_group', params);
}
export async function addGroup(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_group', params);
}
export async function updataGroup(params: any) {
	return Api.postWithAuth('/api/patrolinspection/updata_group', params);
}

export async function deleteGroup(params: any) {
	return Api.postWithAuth('/api/patrolinspection/delete_group', params);
}

export async function departmentAndPerson(params: any) {
	return Api.postWithAuth('/api/wuye/department_and_person', params);
}

export async function addPlan(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_plan', params);
}

export async function deletePlan(params: any) {
	return Api.postWithAuth('/api/patrolinspection/delete_plan', params);
}

export async function findReportForm(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_report_form', params);
}

export async function findReportDevice(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_report_device', params);
}

export async function likeFindReport(params: any) {
	return Api.postWithAuth('/api/patrolinspection/like_find_report', params);
}

export async function getByRouteDevice(params: any) {
	return Api.postWithAuth('/api/patrolinspection/get_by_route_device', params);
}

export async function addRouteDevice(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_route_device', params);
}
export async function currentInspectionPersonnel(params: any) {
	return Api.postWithAuth('/api/patrolinspection/current_inspection_personnel', params);
}

export async function updataRoute(params: any) {
	return Api.postWithAuth('/api/patrolinspection/updata_route', params);
}

export async function deleteRoute(params: any) {
	return Api.postWithAuth('/api/patrolinspection/delete_route', params);
}
export async function updataRouteState(params: any) {
	return Api.postWithAuth('/api/patrolinspection/updata_routeState', params);
}export async function getXungengShiftByRouteUuid(params: any) {
	return Api.postWithAuth('/api/patrolinspection/getXungengShiftByRouteUuid', params);
}
export async function findRouteRoster(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_route_roster', params);
}

export async function addInspectionRoute(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_inspection_route', params);
}
export async function routeBingPlane(params: any) {
	return Api.postWithAuth('/api/patrolinspection/route_bing_plane', params);
}
export async function inspectionDistributivePersonnel(params: any) {
	return Api.postWithAuth('/api/patrolinspection/inspection_distributive_personnel', params);
}

export async function findPatrollEmployeeTree(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_patroll_employee_tree', params);
}

export async function findRouteInfo(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_route_info', params);
}

export async function findSchedul(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_schedul', params);
}

export async function addSchedul(params: any) {
	return Api.postWithAuth('/api/patrolinspection/add_schedul', params);
}

export async function updataSchedul(params: any) {
	return Api.postWithAuth('/api/patrolinspection/updata_schedul', params);
}
export async function batchSchedul(params: any) {
	return Api.postWithAuth('/api/patrolinspection/batch_schedul', params);
}
export async function findXunJianReportForm(params: any) {
	return Api.postWithAuth('/api/patrolinspection/find_xun_jian_report_form', params);
}
export async function getXunGengXunJianTopData(params: any) {
	return Api.postWithAuth('/api/patrolinspection/getStatisticsData', params);
}
export async function getDevicePatrolStatusPieData(params: any) {
	return Api.postWithAuth('/api/patrolinspection/getDevicePatrolStatusPieData', params);
}
export async function getDevicePatrolTypePieData(params: any) {
	return Api.postWithAuth('/api/patrolinspection/getDevicePatrolTypePieData', params);
}
export async function getXunGengXunJianLineChartData(params: any) {
	return Api.postWithAuth('/api/patrolinspection/getXunGengXunJianLineChartData', params);
}
