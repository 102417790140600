import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import Api from '../../../data/API';
import { Image, Modal } from 'antd';
import {
	delPinglu,
	findDetails,
	findDongTaiDetailsAfter,
	findPingluList,
	findTieziDetails,
	findWenzhangListPc,
} from '../../../services/WenzhangService';
import GSTable from '../../../components/GSTable';
import DAL from '../../../data/DAL';
const WenZhangInfo: React.FC = (props: any) => {
	const msg = require('../../../util/SuspensionMsg');
	const actionRef = useRef<ActionType>();
	const confirm = Modal.confirm;

	const [typestate, setTypestate] = useState(DAL.dal.getQueryString('state'));
	const [uuid, setUuid] = useState(DAL.dal.getQueryString('uuid'));
	const [typeUuid, setTypeUuid] = useState(DAL.dal.getQueryString('wenZhangType'));
	const [xiangqing, setXiangqing] = useState([]);
	const [imgs, setImgs] = useState('');

	useEffect(() => {
		if (typestate === 'wenzhang') {
			getdatas(uuid);
		}
		if (typestate === 'tieba') {
			getdatatieba(uuid);
			//this.getTiebaType();
		}
		if (typestate === 'group') {
			getdatadongTai(uuid);
		}
	}, []);

	const getdatas = async (uuid: any) => {
		const res = await findDetails({
			uuid: uuid,
		});
		console.log('xiangqing', res);
		if (res.err === 0) {
			setXiangqing(res.data);
			setImgs(res.imgs);
		}
	};
	const getdatatieba = async (uuid: any) => {
		const res = await findTieziDetails({
			uuid: uuid,
		});
		console.log('xiangqing', res);
		if (res.err === 0) {
			setXiangqing(res.data);
			setImgs(res.imgs);
		}
	};
	const getdatadongTai = async (uuid: any) => {
		const res = await findDongTaiDetailsAfter({
			uuid: uuid,
		});
		console.log('xiangqing', res);
		if (res.err === 0) {
			setXiangqing(res.data);
			setImgs(res.imgs);
		}
	};

	const columns = [
		{
			title: '评论人',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '被评论人',
			key: 'pinglunName',
			dataIndex: 'pinglunName',
		},
		{
			title: '内容',
			key: 'pinglunNeirong',
			dataIndex: 'pinglunNeirong',
		},
		{
			title: '图片',
			key: 'pinglunImg',
			dataIndex: 'pinglunImg',
			render: (text: string, record: any) => (
				<span>
					{record.pinglunImg === null || record.pinglunImg === '' || record.pinglunImg === undefined ? (
						''
					) : (
						<Image
							className="homePage"
							src={Api.cdnRootPath + record.pinglunImg}
							width={100}
							height={70}
							style={{ float: 'left' }}
						/>
					)}
				</span>
			),
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];

	const deleteItem = (value: any) => {
		console.log('deleteItem::', value);
		confirm({
			title: '您确认要删除该评论吗？',
			okType: 'danger',
			onOk: async () => {
				const res = await delPinglu({
					uuid: value.uuid,
				});

				console.log('评论', res);
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else {
					msg.suspensionMsg({ content: res.msg });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	return (
		<div>
			{xiangqing.length === 0 ? (
				''
			) : (
				<div>
					<p>标题： {typestate === 'wenzhang' ? xiangqing.wenzhangBiaoti : xiangqing.zhuti}</p>
					<p>作者： {xiangqing.fabuRenyuan}</p>
					<p>创建时间： {xiangqing.fabuShijian}</p>
					{xiangqing.tupianUrl === null || xiangqing.tupianUrl === undefined || xiangqing.tupianUrl === ''
						? ''
						: xiangqing.tupianArray.map((item: any) => (
								<Image
									className="homePage"
									src={Api.cdnRootPath + item}
									width={100}
									height={70}
									style={{ float: 'left' }}
								/>
						  ))}
				</div>
			)}
			<GSTable
				columns={columns}
				actionRef={actionRef}
				// options={false}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findPingluList({
						overallUuid: uuid,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default WenZhangInfo;
