import React, {useEffect, useRef} from 'react';

const Index: React.FC = (props: any) => {
    const PlayerControl = (window as any).PlayerControl;
    const canvas = useRef<any>();
    const video = useRef<any>();
    useEffect(() => {
        const options = {
            wsURL: `ws://${props.ip}/rtspoverwebsocket`,
            rtspURL: `rtsp://${props.ip}/cam/realmonitor?channel=1&subtype=0&proto=Private3`,
            username: 'admin',
            password: 'admin123'
        };
        const player = new PlayerControl(options);
        player.on('WorkerReady', function(){
            player.connect();
        });
        player.on('DecodeStart', function(rs: any){
            console.log('开始解码');
            console.log(rs);

        });
        player.on('PlayStart', function(rs: any){
            console.log('开始播放');
            console.log(rs);
        });
        player.on('Error', function(rs: any){
            console.log('发生错误');
            console.log(rs);
        });
        player.on('FileOver', function(rs: any){
            console.log('回放播放完成');
            console.log(rs);
        });
        player.on('MSEResolutionChanged', function(rs: any){
            console.log('分辨率改变');
            console.log(rs);
        });
        player.on('FrameTypeChange', function(rs: any){
            console.log('编码模式改变');
            console.log(rs);
        });
        player.on('audioChange', function(rs: any){
            console.log('音频编码改变');
            console.log(rs);
        });
        player.init(canvas, video);
        return () => {
            player?.close();
        }
    }, [props.ip])
    return <>
        <canvas ref={e => {
            canvas.current = e;
        }} style={{backgroundColor: '#000', width: 600, height: 300}}></canvas>
        <div>
            <video ref={e => {
                video.current = e;
            }} style={{backgroundColor: '#000', width: 600, height: 300}}></video>
        </div>
    </>
}
export default Index;
