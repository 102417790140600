import React, { useState } from 'react';
import { Col, Menu, Row } from 'antd';
import AssetTypeList from '../components/AssetTypeList';
import HouseTypeList from '../components/HouseTypeList';
import HouseStyleList from '../components/HouseStyleList';
import ListOfItems from "../components/ListOfItems";
const RoomSetting: React.FC = () => {
	const [type, setType] = useState('wuye_asset_type');

	const everyOne = () => {
		switch (type) {
			case 'wuye_asset_type':
				return (
					<>
						<AssetTypeList />
					</>
				);
			case 'wuye_house_type':
				return (
					<>
						<HouseTypeList />
					</>
				);
			case 'wuye_house_style':
				return (
					<>
						<HouseStyleList />
					</>
				);
			// case "list_of_items":
			// 	return (
			// 		<>
			// 			<ListOfItems/>
			// 		</>
			// 	);
			default:
				return <div>default</div>;
		}
	};
	return (
		<div style={{ minWidth: 1024, backgroundColor: '#fff' }}>
			<Row>
				<Col span={4} style={{ borderRight: '10px solid #f0f2f5' }}>
					<Menu
						onClick={(e: any) => {
							setType(e.key);
						}}
						style={{ width: '100%', minHeight: window.outerHeight - 285 }}
						mode={'inline'}
						theme={'light'}
						defaultSelectedKeys={['wuye_asset_type']}
					>
						<Menu.Item key="wuye_asset_type">资产类型</Menu.Item>
						<Menu.Item key="wuye_house_type">房间户型</Menu.Item>
						<Menu.Item key="wuye_house_style">装修风格</Menu.Item>
						{/* <Menu.Item key="list_of_items">房间物品</Menu.Item> */}
					</Menu>
				</Col>
				<Col span={20} style={{ padding: '0px 10px 10px 10px' }}>
					{everyOne()}
				</Col>
			</Row>
		</div>
	);
};
export default RoomSetting;
