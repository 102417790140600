import React, { useState } from 'react';
import { Button, Col, Input, message, Radio, Row, Modal, Space, Form, Tooltip } from 'antd';
import Api from '../../../data/API';
import { InfoCircleOutlined } from '@ant-design/icons';
import { setMailInfo, testMailInfo } from '../../../services/FoundationSetupService';

interface NoticeSetFormProp {
	projectInfo: {};
}

const NoticeSetForm: React.FC<NoticeSetFormProp> = (props: any) => {
	const projectInfo = props.projectInfo;
	const [receiveMail, setReceiveMail] = useState('');
	const [visible, setVisible] = useState(false);

	const [form] = Form.useForm();

	const formItemLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 16 },
	};
	const tailLayout = {
		wrapperCol: { offset: 6, span: 16 },
	};

	const handleSubmit = () => {
		form.validateFields()
			.then(async (values) => {
				const res = await setMailInfo(values);
				// Api.postWithAuth('/admin/api/foundation/set_mail_info', values).then(res => {
				console.log('信息', res);
				if (res.err === 0) {
					message.success(res.msg);
				} else {
					message.error(res.msg);
				}
				// });
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const resetFields = () => {
		Modal.confirm({
			title: '提示',
			content: '您确定要重置所填内容？',
			onOk: () => {
				form.resetFields();
			},
		});
	};

	const testSendMail = () => {
		if (receiveMail === '') {
			message.error('请输入收件人');
			return;
		}
		form.validateFields()
			.then(async (values) => {
				values = {
					...values,
					receiveMail: receiveMail,
				};
				const res = await testMailInfo(values);
				// Api.postWithAuth('/admin/api/foundation/test_mail_info', values).then(res => {
				if (res.err === 0) {
					setVisible(false);
					message.success(res.msg);
				} else {
					message.error(res.msg);
				}
				// });
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	return (
		<Row>
			<Col span={12}>
				<Form
					form={form}
					{...formItemLayout}
					name="notice-setting"
					initialValues={{
						wuyeUuid: projectInfo.uuid,
						sendMail: projectInfo.sendMail || false,
						mailAccount: projectInfo.smtpAccount || '',
						sender: projectInfo.smtpSender || '',
						smtpServe: projectInfo.smtpServer || '',
						smtpPort: projectInfo.smtpPort || '',
						smtpPassword: projectInfo.smtpPassword || '',
					}}
					onFinish={handleSubmit}
				>
					<Form.Item
						style={{ display: 'none' }}
						name="wuyeUuid"
						rules={[{ required: true, message: '请输入' }]}
					></Form.Item>
					<Form.Item
						label={
							<span>
								是否开启&nbsp;
								<Tooltip title="关闭后将停止发送所有邮件">
									<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
								</Tooltip>
							</span>
						}
						name="sendMail"
						rules={[{ required: true, message: '请选择是否开启' }]}
					>
						<Radio.Group>
							<Radio value={false}>关闭</Radio>
							<Radio value={true}>开启</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="发送邮件账号"
						name="mailAccount"
						rules={[{ required: true, message: '请输入发送邮件账号' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item label="发信人" name="sender" rules={[{ required: true, message: '请输入发信人' }]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="SMTP服务器"
						name="smtpServe"
						rules={[{ required: true, message: '请输入SMTP服务器' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="SMTP端口号"
						name="smtpPort"
						rules={[{ required: true, message: '请输入SMTP端口号' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="SMTP密码"
						name="smtpPassword"
						rules={[{ required: true, message: '请输入SMTP密码' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item {...tailLayout}>
						<Space>
							<Button type="primary" htmlType="submit">
								保存
							</Button>
							<Button onClick={resetFields}>重置</Button>
							<Button onClick={() => setVisible(true)}>测试发信</Button>
						</Space>
					</Form.Item>
					<Modal title="测试发信" visible={visible} onOk={testSendMail} onCancel={() => setVisible(false)}>
						<Row type={'flex'} gutter={8} align={'middle'}>
							<Col>收件人</Col>
							<Col>
								<Input value={receiveMail} onChange={(e: any) => setReceiveMail(e.target.value)} />
							</Col>
						</Row>
					</Modal>
				</Form>
			</Col>
		</Row>
	);
};
export default NoticeSetForm;
