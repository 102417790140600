import React, { useRef, useState, useEffect } from 'react';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Switch,
	Table,
	Tooltip,
	Radio,
	TreeSelect,
	Space
} from 'antd';
import { ActionType } from '@ant-design/pro-table';
import { Link } from 'react-router-dom';
import { FormInstance } from 'antd/es/form';
import GSTable from '../../../components/GSTable';
import { getAdNoticeList, getNoNoticeUser, saveAdNotice, updateAdNotice } from '../../../services/notificationService';
import ProxyForm from '../../../components/ProxyForm';
import AdNoticeRecord from './AdNoticeRecord';
import { getAllDevicesGroup, getDevices } from "../../../services/ZhiNengMenJinService";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
let moment = require('moment');
let msg = require('../../../util/SuspensionMsg');

const Search = Input.Search;
const AdNotice: React.FC = () => {
	const reg =  /\r|\n/g;
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const [updateForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [search, setSearch] = useState('');
	const [addVisiable, setAddVisiable] = useState(false);
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [uuid, setUUid] = useState('');
	const [updateVisiable, setUpdateVisiable] = useState(false);
	const [state, setState] = useState(false);
	const [noticeUserVisible, setNoticeUserVisible] = useState(false);
	const [record, setRecord] = useState({});
	const [userData, setUserData] = useState([]);
	const [noticeTotal, setNoticeTotal] = useState(0);
	const [userType, setUserType] = useState('loginId');
	const [devices, setDevices] = useState([]);
	const columns = [
		{
			title: '通知内容',
			key: 'text',
			dataIndex: 'text',
			onCell: () => {
				return {
					style: {
						maxWidth: 300,
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						cursor: 'pointer',
					},
				};
			},
			render: (text: string, record: any) => (
				<Tooltip placement="topLeft" title={text}>
					{text}
				</Tooltip>
			),
		},
		// {
		// 	title: '通知人',
		// 	key: 'user_uuid',
		// 	onCell: () => {
		// 		return {
		// 			style: {
		// 				maxWidth: 300,
		// 				overflow: 'hidden',
		// 				whiteSpace: 'nowrap',
		// 				textOverflow: 'ellipsis',
		// 				cursor: 'pointer',
		// 			},
		// 		};
		// 	},
		// 	dataIndex: 'user_uuid',
		// 	render: (text: string, record: any) => (
		// 		<span>
		// 			{text === '' ? (
		// 				'全部'
		// 			) : (
		// 				<Tooltip placement="topLeft" title={text}>
		// 					{text}
		// 				</Tooltip>
		// 			)}
		// 		</span>
		// 	),
		// },
		{
			title: '通知时间',
			key: 'notice_time',
			width: 200,
			dataIndex: 'notice_time',
			render: (text: any, item: any) => (
				<span>
					{moment(item.start_time).format('YYYY-MM-DD HH:mm') +
						'~' +
						moment(item.end_time).format('YYYY-MM-DD HH:mm')}
				</span>
			),
		},
		{
			title: '通知人数',
			width: 100,
			key: 'notice_user_num',
			dataIndex: 'notice_user_num',
			render: (text: any, item: any) => {
				return <span>{(item.user_uuid.substring(0, item.user_uuid.length - 1).split(',') || []).length}</span>;
			},
		},
		{
			title: '已接收人数 ',
			width: 100,
			key: 'accept',
			dataIndex: 'accept',
		},
		{
			title: '状态',
			key: 'state',
			width: 100,
			dataIndex: 'state',
			render: (text: any) => <span>{text ? '开启' : '关闭'}</span>,
		},
		{
			title: '创建人',
			key: 'created_by',
			width: 100,
			dataIndex: 'created_by',
		},
		{
			title: '创建时间',
			key: 'created_time',
			width: 100,
			dataIndex: 'created_time',
			render: (text: any) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
			// render: (text: any, item: any) => <span>{moment(item.created_time, 'YYYY-MM-DD HH:mm:ss')}</span>
		},
		{
			title: '操作',
			key: 'activity',
			dataIndex: 'activity',
			width: 100,
			fixed: 'right',
			render: (text: string, record: any) => {
				return (
					<Space>
						<a
							onClick={() => {
								updateModal(record);
							}}
						>
							编辑
						</a>
						<a
							onClick={() => {
								getNoAdNoticeUser(record);
							}}
						>
							详情
						</a>
					</Space>
				);
			},
		},
	];
	useEffect(() => {
		getDeviceList().then()
	}, [])

	const userColumns = [
		{
			title: '账号',
			key: 'loginId',
			dataIndex: 'loginId',
		},
	];
	const getNoAdNoticeUser = async (record: any) => {
		let data = {
			uuid: record.uuid,
		};
		setRecord(record);
		setNoticeUserVisible(true);
		const res = await getNoNoticeUser(data);
		if (res.err === 0) {
			setUserData(res.data);
			setNoticeTotal(res.total);
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
	};

	const updateModal = (res: any) => {
		console.log(res);
		const item: any = {
			uuid: res.uuid,
			phones:
				res.user_type === 'loginId' ?
				res.phones === undefined
					? []
					: !res.phones
					? '全部'
					: (res.phones || ',').substring(0, res.user_uuid.length - 1).split(',') : res.device_id,
			text: res.text,
			type: res.user_type,
		};
		console.log('item::::', item);
		setUUid(res.uuid);
		setUserType(res.user_type)
		setState(res.state);
		setStartTime(res.start_time);
		setEndTime(res.end_time);
		updateForm.setFieldsValue(item);

		setUpdateVisiable(true);
	};
	const tableSearchBarRender = () => [
		<Input
			placeholder="内容搜索"
			onChange={(event) => {
				setSearch(event.target.value);

			}}
		/>,
		<Button type={"primary"} onClick={()=> {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];
	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setEndTime('');
				setStartTime('');
				setAddVisiable(true);
			}}
		>
			新增通知
		</Button>,
	];

	const dateOnChage = (dates: any, dateStrings: any) => {
		console.log('dateStrings', dateStrings);
		setStartTime(dateStrings[0] + ':00');
		setEndTime(dateStrings[1] + ':59');
	};
	const addAdNotice = async () => {
		form.validateFields().then(async (data: any) => {
			if (startTime === '') {
				msg.suspensionMsg({ content: '请选择开始时间', type: 'danger' });
				return;
			}
			if (endTime === '') {
				msg.suspensionMsg({ content: '请选择结束时间', type: 'danger' });
				return;
			}
			const formData: any = {
				text: data.text,
				startTime: startTime,
				endTime: endTime,
				state: data.state,
				phones: data.phones.toString().replace(reg, ""),
				userType: userType
			};
			console.log('formData', formData);
			const res = await saveAdNotice(formData);
			if (res.err === 0) {
				setAddVisiable(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
			form.resetFields();
		});
	};

	const updateItem = () => {
		updateForm.validateFields().then(async (data: any) => {
			const formData: any = {
				uuid: data.uuid,
				text: data.text,
				start_time: startTime,
				end_time: endTime,
				state: state,
				userType: data.type,
				phones: data.phones.toString().replace(reg, ""),
			};
			console.log('data::::,', formData);
			const res = await updateAdNotice(formData);
			if (res.err === 0) {
				setUpdateVisiable(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
				setEndTime('');
				setStartTime('');
			} else {
				msg.suspensionMsg({ content: res.msg });
			}
		});
	};
	const getDeviceList = async () => {
		const res = await getAllDevicesGroup({});
		console.log('res:::', res);
		if (res.err === 0) {
			setDevices(res.data);
		}
	};
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				rowKey={"uuid"}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await getAdNoticeList({
						page: params.current,
						size: params.pageSize,
						text: search,
					});
					return {
						data: res.data,
						total: res.total,
						success: true,
					};
				}}
			/>
			<Modal
				title="添加通知"
				width={600}
				visible={addVisiable}
				onOk={addAdNotice}
				onCancel={() => {
					setAddVisiable(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'接收方式'} name={'type'} initialValue={userType}>
						<Radio.Group onChange={(e: any) => {setUserType(e.target.value)}}>
							<Radio value={'device'}>按设备</Radio>
							<Radio value={'loginId'}>按登录账号</Radio>
						</Radio.Group>
					</FormItem>
					<FormItem label={'接收者'} name={'phones'} rules={[{ required: true }]}>
						{
							userType === 'loginId' ? <Select
								maxTagCount={50}
								mode="tags"
								placeholder={'输入接收者手机号'}
								style={{ width: '100%' }}
								tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
							/> : <TreeSelect
								maxTagCount={50}
								style={{ width: '100%' }}
								dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
								treeData={devices}
								allowClear={true}
								multiple={true}
								treeNodeFilterProp={'title'}
								treeCheckable={true}
								showSearch={true}
							/>
						}

					</FormItem>
					<FormItem
						label={'通知内容'}
						tooltip="通知内容只对尚未接收的人生效"
						name={'text'}
						rules={[{ required: true }]}
					>
						<TextArea placeholder="请输入" />
					</FormItem>
					<FormItem label={'通知时间'} name={'time'} tooltip={'在该时间段内开锁的用户将会收到通知信息'}>
						<RangePicker format={'YYYY-MM-DD HH:mm'} showTime={true} onChange={dateOnChage} />
					</FormItem>
					<FormItem label={'是否开启'} name={'state'}>
						<Switch />
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="修改通知"
				width={600}
				visible={updateVisiable}
				onOk={updateItem}
				onCancel={() => {
					setUpdateVisiable(false);
				}}
			>
				<ProxyForm form={updateForm}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'接收方式'} name={'type'} >
						<Radio.Group>
							<Radio value={'device'}>按设备</Radio>
							<Radio value={'loginId'}>按登录账号</Radio>
						</Radio.Group>
					</FormItem>
					<FormItem label={'接收者'} name={'phones'} rules={[{ required: true }]}>
						{
							userType === 'loginId' ? <Select
								maxTagCount={50}
								mode="tags"
								placeholder={'输入接收者手机号'}
								style={{ width: '100%' }}
								tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
							/> : <TreeSelect
								maxTagCount={50}
								style={{ width: '100%' }}
								dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
								treeData={devices}
								allowClear={true}
								multiple={true}
								treeNodeFilterProp={'title'}
								treeCheckable={true}
								showSearch={true}
							/>
						}
					</FormItem>
					<FormItem
						label={'通知内容'}
						tooltip="通知内容只对尚未接收的人生效"
						name={'text'}
						rules={[{ required: true }]}
					>
						<TextArea placeholder="请输入" />
					</FormItem>
					<FormItem label={'通知时间'} name={'time'} tooltip={'在该时间段内开锁的用户将会收到通知信息'}>
						<RangePicker
							format={'YYYY-MM-DD HH:mm'}
							key={uuid}
							showTime={true}
							defaultValue={[moment(startTime), moment(endTime)]}
							onChange={dateOnChage}
						/>
					</FormItem>
					<FormItem label={'是否开启'} name={'state'}>
						<Switch
							defaultChecked={state}
							onChange={(checked) => {
								setState(checked);
							}}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="详情"
				width={1000}
				visible={noticeUserVisible}
				onOk={() => {
					setNoticeUserVisible(false);
				}}
				onCancel={() => {
					setNoticeUserVisible(false);
				}}
			>
				<div>
					<AdNoticeRecord key={record.uuid} uuid={record.uuid} flag={true}></AdNoticeRecord>
					{/*<Row>*/}
					{/*	<Col>*/}
					{/*		<span>*/}
					{/*			通知人数为： {noticeTotal},未接到通知人数：{userData.length}*/}
					{/*		</span>*/}
					{/*	</Col>*/}
					{/*</Row>*/}
					{/*<br />*/}
					{/*<Row>*/}
					{/*	<Col span={24}>*/}
					{/*		<Table columns={userColumns} dataSource={userData} size="middle" />*/}
					{/*	</Col>*/}
					{/*</Row>*/}
				</div>
			</Modal>
		</div>
	);
};
export default AdNotice;
