import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space,
	Spin,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import { addDebitNote, billArrears, deleteChargeBill, findAll } from '../../../services/PayCostManagementService';
import GSTable from '../../../components/GSTable';
import { findFee } from '../../../services/orderService';
import getBillTypes from '../models/BillTypes';
import { sendCallNotice } from '../../../services/CallNoticeService';

const Option = Select.Option;
const BillArrears: React.FC = (props: any) => {
	useEffect(() => {
		getFee();
	}, []);

	const actionRef = useRef<ActionType>();
	const [searchInput, setSearchInput] = useState('');
	const [fee, setFee] = useState([]);
	const [state, setState] = useState<any>(props.state || '');
	const [billType, setbillType] = useState(props.billType || '');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const billTypes = getBillTypes().list || [];
	const [loading, setloading] = useState(false);
	const columns = [
		{
			title: '房间',
			dataIndex: 'roomId',
		},
		{
			title: '客户',
			dataIndex: 'custName',
		},
		{
			title: '应收日期',
			dataIndex: 'receivableDate',
			valueType:'date',
		},
		{
			title: '缴费类型',
			dataIndex: 'billType',
		},
		{
			title: '费用期间',
			dataIndex: 'startTime',
			render: (text: string, record: any) => {
				return (
					<span>
						{record.startTime !== undefined && record.startTime !== null && record.startTime !== ''
							? moment(record.startTime).format('YYYY-MM-DD') + '~'
							: '-'}
						{record.endTime !== undefined && record.endTime !== null && record.endTime !== ''
							? moment(record.endTime).format('YYYY-MM-DD')
							: ''}
					</span>
				);
			},
		},
		{
			title: '收费科目',
			dataIndex: 'name',
		},
		{ title: '欠费天数', dataIndex: 'dDay' },
		{
			title: '应收金额(元)',
			dataIndex: 'actualAmount',
			render: (text: string) => <span>{(parseInt(text) / 100).toFixed(2)}</span>,
		},
	];
	const tableSearchBarRender = () => [];

	const deleteItem = (value: any) => {};

	const getFee = async () => {
		const res = await findFee({});
		console.log('科目列表', res);
		if (res.err === 0) {
			setFee(res.data);
		}
	};

	const addItem = () => {};

	const dateTime = (date: any, dataToString: any) => {};
	const tableOperationsBarRender = () => [];

	const sendCuiJiao = async () => {
		Modal.confirm({
			content: '您是否要发送催缴通知？',
			onOk: async () => {
				setloading(true)
				const res = await sendCallNotice({ uuids: selectedRowKeys.toString() });
				setloading(false)
				if (res.err == 0) {
					setselectedRowKeys([]);
					message.success('催缴成功');
				} else {
					message.error(res.msg);
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	return (
		<div>
			<Spin spinning={loading}>
				<GSTable
					columns={columns}
					actionRef={actionRef}
					rowKey="uuid"
					rowSelection={{
						fixed: true,
						columnWidth: 60,
						preserveSelectedRowKeys: true,
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys: any, selectedRows: any) => {
							setselectedRowKeys(selectedRowKeys);
						},
						// selections: [
						// 	{
						// 		key: 'SELECT_ALL',
						// 		text: '全选全部页面',
						// 		onSelect: (changableRowKeys: any) => {
						// 			setquanXuan(true);
						// 			setselectedRowKeys(changableRowKeys);
						// 		},
						// 	},
						// ],
					}}
					tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						return (
							<Space size={24}>
								<span>
									已选 {selectedRowKeys.length} 项
									<a
										style={{ marginLeft: 8 }}
										onClick={() => {
											onCleanSelected();
										}}
									>
										取消选择
									</a>
								</span>
							</Space>
						);
					}}
					tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
						console.log('tableAlertOptionRender::::', selectedRows);
						return (
							<Space size={16}>
								<a onClick={() => sendCuiJiao(selectedRows)}>催缴通知</a>
							</Space>
						);
					}}
					searchBarRender={tableSearchBarRender()}
					operationsBarRender={tableOperationsBarRender()}
					request={async (params: any = {}, sort: any, filter: any) => {
						let data = {
							page: params.current,
							size: params.pageSize,
						};
						const res = await billArrears(data);
						return {
							data: res.data,
							total: res.total,
							success: true,
						};
					}}
				/>
			</Spin>
		</div>
	);
};
export default BillArrears;
