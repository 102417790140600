import * as React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import LogList from './pages/LogList';
import { useEffect } from 'react';
import ApplicationLogList from './pages/ApplicationLogList';

export default function SystemMiniApp() {
	useEffect(() => {
		console.log('SystemMiniApp');
	}, []);
	const rootUrl = '/system';
	const menus: MiniAppMenu[] = [
		{
			authority: 'system_log',
			name: '登录日志',
			path: 'logs',
			component: LogList,
		},
		{
			authority: 'system_application_log',
			name: '应用日志',
			path: 'applicationLogs',
			component: ApplicationLogList,
		},
	];

	return <MiniApp miniAppName={'系统'} rootUrl={rootUrl} menus={menus} />;
}
