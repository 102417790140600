import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';

import MeetingInfo from './MeetingInfo';
import MeetingImgs from './MeetingImgs';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import OrderTime from './OrderTime';
import { Breadcrumb, Col, Menu, Row } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import {
	addMeetingImg,
	deleteMeetingCharge,
	meetingCharge,
	meetingFindByUuid,
	meetingOrderListOne,
	updateHomeImage,
} from '../../../services/meetingService';

let msg = require('../../../util/SuspensionMsg');

class MeetingDetail extends React.Component {
	dal = DAL.dal;

	constructor(props: any) {
		super(props);
		this.state = {
			url: window.location.pathname,
			uuid: '',
			value: '',
			meeting: {},
			imgs: ['loading'],
			isShow: false,
			picture: '',
			name: '',
			price1: '',
			price2: '',
			price3: '',
			people_num: '',
			auth_time: '',
			area: '',
			img: '',
			meetingCharge: [],
			meetingOrders: [],
			curMenuPath: 'fmeeting_info',
		};

		this.uploadImg = this.uploadImg.bind(this);
		this.saveMeeting_img = this.saveMeeting_img.bind(this);
		this.getMeetingCharge = this.getMeetingCharge.bind(this);
		//this.addMeetingCharge = this.addMeetingCharge.bind(this);
		this.deleteMeetingCharge = this.deleteMeetingCharge.bind(this);
		this.saveMeetingPicture = this.saveMeetingPicture.bind(this);
		this.getUuid = this.getUuid.bind(this);
		//dateForm.setCallBack(this.addMeetingCharge);
		// img.setCallBack(this.saveMeeting_img);
		// img.setCallBack1(this.saveMeetingPicture);
	}

	componentDidMount() {
		//this.getMeetingOrder();
		this.getData();
	}

	/*addMeetingCharge(ress: any) {
        console.log('表单数据', ress);
        let url = '/api/add_meeting_charge';
        const data = new FormData();
        data.append('meetingUuid', this.getUuid());
        data.append('startTime', ress.startTime);
        data.append('endTime', ress.endTime);
        data.append('price', ress.price);
        this.dal.getNetWorkData(data, url).then(res => {
            if (res.err === 0) {
                alert(res.msg);
                return;
            } else {
                alert(res.msg);
                return;
            }
        });
    }*/

	getData = async () => {
		const res = await meetingFindByUuid({
			uuid: this.getUuid(),
		});
		// let url = '/api/meeting/meeting_findByUuid';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid()
		// }).then(res => {
		console.log(11 + res);
		this.setState({
			meetingName: res.name,
		});
		// });
	};

	deleteMeetingCharge = async (ev: any) => {
		const res = await deleteMeetingCharge({
			uuid: ev.target.id,
		});
		// let url = '/api/meeting/delete_meeting_charge';
		// API.postWithAuth(url , {
		//     uuid: ev.target.id
		// }).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: res.msg, type: 'success' });
			this.setState({
				isShow: true,
			});
			return;
		} else {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// });
	};

	getMeetingCharge = async () => {
		const res = await meetingCharge({
			uuid: this.getUuid(),
		});
		// let url = '/api/meeting/meeting_charge';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid()
		// }).then(res => {
		if (res.err === 0) {
			this.setState({
				meetingCharge: res.data,
			});
			return;
		} else {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// });
	};

	getUuid() {
		var page = window.location.search;
		var c = page.split('=');
		var uuid = c[1];
		return uuid;
	}

	uploadImg = async (ev: any) => {
		const res = await updateHomeImage({
			uuid: this.getUuid(),
			img: (this.state as any).img,
		});
		// let url = '/api/meeting/update_home_image';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid(),
		//     img: (this.state as any).img
		// }).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: res.msg, type: 'success' });
		} else {
			msg.suspensionMsg({ content: res.msg });
		}
		// });
	};

	saveMeeting_img = async (ress: any) => {
		console.log('首页图', ress);
		const res = await addMeetingImg({
			uuid: this.getUuid(),
			img: ress,
		});
		// let url = '/api/meeting/add_meeting_img';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid(),
		//     img: ress
		// }).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: res.msg, type: 'success' });
			return;
		} else {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// });
	};

	saveMeetingPicture = async (ress: any) => {
		console.log('图片', ress);
		const res = await updateHomeImage({
			uuid: this.getUuid(),
			img: ress,
		});
		// let url = '/api/meeting/update_home_image';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid(),
		//     img: ress
		// }).then(res => {
		if (res.err === 0) {
			msg.suspensionMsg({ content: res.msg, type: 'success' });
			return;
		} else {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// });
	};

	getMeetingOrder = async () => {
		const res = await meetingOrderListOne({
			uuid: this.getUuid(),
		});
		// let url = '/api/meeting/meeting_order_list_one';
		// API.postWithAuth(url , {
		//     uuid: this.getUuid(),
		// }).then(res => {
		if (res.err === 0) {
			this.setState({
				meetingOrders: res.data,
			});
		} else {
			msg.suspensionMsg({ content: res.msg });
			return;
		}
		// });
	};
	switchMenu = (e: any) => {
		this.setState({
			curMenuPath: e.key,
		});
	};

	render() {
		return (
			<div>
				<nav className="submenu navbar navbar-default" role="navigation">
					{/*<ol className="breadcrumb">*/}
					{/*    <li><Link to="/meeting/meeting"><a><i className="icon icon-home">会议室列表</i></a></Link></li>*/}
					{/*    <li className="active">{(this.state as any).meetingName}</li>*/}
					{/*</ol>*/}
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to="/meeting/meeting">
								<a>会议室列表</a>
							</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>{(this.state as any).meetingName}</Breadcrumb.Item>
					</Breadcrumb>
					<Row>
						<Col span={24} style={{ paddingRight: 20 }}>
							<Menu
								onClick={this.switchMenu}
								selectedKeys={[this.state.curMenuPath]}
								mode="horizontal"
								style={{ textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0)', marginLeft: -18 }}
							>
								<Menu.Item key={'fmeeting_info'}>
									<NavLink to={'/meeting/meeting_info?uuid=' + this.getUuid()}>
										<a>基本信息</a>
									</NavLink>{' '}
								</Menu.Item>
								<Menu.Item key={'meeting_imgs'}>
									<NavLink to={'/meeting/meeting_imgs?uuid=' + this.getUuid()}>
										<a>图片详情</a>
									</NavLink>
								</Menu.Item>
								<Menu.Item key={'order_time'}>
									<NavLink to={'/meeting/order_time?uuid=' + this.getUuid()}>
										<a>预定记录</a>
									</NavLink>
								</Menu.Item>
							</Menu>
						</Col>
					</Row>
				</nav>
				<br />
				<div>
					<Switch>
						<Route path="/meeting/meeting_info" component={MeetingInfo} />
						<Route path="/meeting/meeting_imgs" component={MeetingImgs} />
						<Route path="/meeting/order_time" component={OrderTime} />
						<Route render={() => <MeetingInfo />} />
					</Switch>
				</div>

				{/*<a href={'../meeting/meeting-order?uuid=' + (this.state as any).meeting.uuid}>
                    <button type="button" className="btn btn-lg btn-primary" data-toggle="modal" data-target="#myModal">预定
                    </button>
                </a>

                <button type="button" className="btn btn-lg btn-primary" data-toggle="modal" data-target="#addMeetingCharge">添加特殊收费段
                </button>
                <span className="btn btn-lg btn-primary">点击图片即可删除</span>

                    <div>*/}

				{/*{(this.state as any).meetingCharge.map((mc: any, index: any) => (
                            <table  className="table table-striped">
                                <tr>
                                    <td>特殊时间段({index + 1})</td>
                                    <td>开始时间{mc.startTime}</td>
                                </tr>
                                <tr>
                                    <td/>
                                    <td>结束时间{mc.endTime}</td>
                                </tr>
                                <tr>
                                    <td/>
                                    <td>价格{mc.price}元(收费单位不变)</td>
                                </tr>
                                <button className="btn " onClick={this.deleteMeetingCharge} id={mc.uuid}  type="button" data-toggle="modal" data-target="#mySmModal">删除</button>
                            </table>
                        ))}*/}

				{/* <div>
                            该会议室已预订时间：
                            <table className="table-striped">
                                <thead>
                                <th key={(this.state as any).meeting.uuid}>
                                    <td>开始时间</td>
                                    <td>结束时间</td>
                                </th>
                                </thead>
                                <tbody>
                                {(this.state as any).meetingOrders.map((mo: any) => (
                                    <tr>
                                        <td>{mo.met_start_time}</td>
                                        <td>{mo.met_end_time}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>



                    </div>



                <div className="modal fade" id="addMeetingImg">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div id="uploaderExample" className="uploader">
                                <div className="file-list" data-drag-placeholder="请拖拽文件到此处"/>
                                <button type="button" className="btn btn-primary uploader-btn-browse"><i className="icon icon-cloud-upload"/> 选择文件
                                </button>

                                <a href={'../meeting/meeting-detail?uuid=' + (this.state as any).meeting.uuid}>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">完成
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>




                 <div className="modal fade" id="addMeetingCharge">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">

                            <form id="fromTable" accept-charset="UTF-8">
                                请输入开始时间：<input type="text"  name="startTime"  className="form-control start_time" placeholder="请选择开始时间" /><br/>
                                请输入结束时间：<input type="text" name="endTime"  className="form-control end_time" placeholder="请选择结束时间" />
                                请输入该时间内价格：<input type="number" name="price"  className="form-control end_time" placeholder="请输入价格" />
                                <button id="fromBtn" className="btn"  >保存</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>*/}
			</div>
		);
	}
}

export default MeetingDetail;

// helpers

/*function getExclamationMarks(numChars: number) {
    return Array(numChars + 1).join('!');
}*/
