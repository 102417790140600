import * as React from 'react';
import DAL from '../../data/DAL';
import API from '../../data/API';
import { getDeviceidData } from '../../services/StatisticsService';

var msg = require('../../util/SuspensionMsg');

var echarts = require('echarts/lib/echarts'); //必须
require('echarts/lib/chart/pie'); //图表类型
require('echarts/lib/component/title'); //标题插件

class BasicFacts extends React.Component {
	deviceId = '';
	dal = DAL.dal;
	contact = '';
	users2 = [];
	sumChart: any;
	successChart: any;
	failChart: any;

	constructor(props: any) {
		super(props);
		this.state = {
			value: '',
			users: [],
			isShow: false,
			name: '',
			img_type: '',
			room_number: '',
			create_time: '',
			file_name: '',
			valid_time: '',
			state: '',
			user_id: '',
			valid_mill: '',
			wuye_number: '',
			inputStr: '',
		};
		this.setPieOption = this.setPieOption.bind(this);
		this.getData = this.getData.bind(this);
		this.sum = this.sum.bind(this);
		this.fial = this.fial.bind(this);
		this.success = this.success.bind(this);
	}

	componentDidMount() {
		this.getData();
	}

	getData = async () => {
		// let data = new FormData();
		// let url = '/api/mongodb/get_deviceid_data';
		// this.dal.getNetWorkData(data , url).then(res => {
		//
		//
		//   if (res.err === 0) {
		//
		//       var datas = res.data;
		//       var showDataSum = [];
		//       var showDataSuccess = [];
		//       var showDataFail = [];
		//
		//       for ( var i = 0 ; i < datas.length ; i++ ) {
		//
		//           var da = datas[i];
		//           var device_name = da.device_name;
		//
		//           var sum = da.sum;
		//           var success = da.success;
		//           var fail = da.fail;
		//
		//           showDataSum[i] = {value: sum[0], name: device_name};
		//           showDataSuccess[i] = {value: success[0], name: device_name};
		//           showDataFail[i] = {value: fail[0], name: device_name};
		//       }
		//
		//       console.log('解析的数据：' + showDataSum);
		//
		//
		//       let optionsSum = this.setPieOption(showDataSum as any, '整体概况');
		//       this.sumChart.setOption(optionsSum);
		//
		//       let optionsSuccess = this.setPieOption(showDataSuccess as any, '成功次数');
		//       this.successChart.setOption(optionsSuccess);
		//
		//       let optionsFail = this.setPieOption(showDataFail as any, '失败次数');
		//       this.failChart.setOption(optionsFail);
		//
		//   } else {
		//       msg.suspensionMsg(res.msg);
		//   }
		//
		//
		//
		// });

		const res = await getDeviceidData({});
		// API.postWithAuth(url , {}).then(res => {
		if (res.err === 0) {
			var datas = res.data;
			var showDataSum = [];
			var showDataSuccess = [];
			var showDataFail = [];

			for (var i = 0; i < datas.length; i++) {
				var da = datas[i];
				var device_name = da.device_name;

				var sum = da.sum;
				var success = da.success;
				var fail = da.fail;

				showDataSum[i] = { value: sum[0], name: device_name };
				showDataSuccess[i] = { value: success[0], name: device_name };
				showDataFail[i] = { value: fail[0], name: device_name };
			}

			console.log('解析的数据：' + showDataSum);

			let optionsSum = this.setPieOption(showDataSum as any, '整体概况');
			this.sumChart.setOption(optionsSum);

			let optionsSuccess = this.setPieOption(showDataSuccess as any, '成功次数');
			this.successChart.setOption(optionsSuccess);

			let optionsFail = this.setPieOption(showDataFail as any, '失败次数');
			this.failChart.setOption(optionsFail);
		} else {
			msg.suspensionMsg(res.msg);
		}

		// });
	};

	sum(chart: any) {
		if (chart !== null) {
			this.sumChart = echarts.init(chart);
		}
	}

	fial(chart: any) {
		if (chart !== null) {
			this.failChart = echarts.init(chart);
		}
	}

	success(chart: any) {
		if (chart !== null) {
			this.successChart = echarts.init(chart);
		}
	}

	//一个基本的echarts图表配置函数
	setPieOption(data: any, title: string) {
		return {
			title: {
				x: 'center', //标题位置
				y: 'center',
				text: title, //传入标题名称‘参保情况’
				textStyle: {
					//标题字体颜色等设置
					fontSize: 16,
					fontWeight: 'bold',
				},
			},
			series: [
				{
					name: '比例',
					type: 'pie',
					radius: ['40%', '80%'],
					avoidLabelOverlap: true,
					data: data, //传入外部的data数据
					label: {
						normal: {
							show: false,
							position: 'inner',
							textStyle: {
								fontSize: '12',
							},
							formatter: '{b}:{c}',
						},
						emphasis: {
							show: true,
							textStyle: {
								fontSize: '12',
							},
						},
					},
					labelLine: {
						normal: {
							show: false,
						},
					},
				},
			],
		};
	}
	componentWillUnmount() {
		console.log('...............................', this.refs.chart);
	}

	render() {
		return (
			<div className="content">
				<div className="row div-text-align-center">
					<div className="col-xs-4">
						<div>设备的使用的总共次数（没有使用记录不显示）</div>
						<canvas ref={this.sum} width="400" height="400">
							x
						</canvas>
					</div>
					<div className="col-xs-4">
						<div>设备使用的成功次数（当天没有使用不显示）</div>
						<canvas ref={this.success} width="400" height="400">
							x
						</canvas>
					</div>

					<div className="col-xs-4">
						<div>设备的使用的失败次数（没有使用记录不显示）</div>
						<canvas ref={this.fial} width="400" height="400">
							x
						</canvas>
					</div>
				</div>

				{(this.state as any).isShow ? (
					<div className="cover">
						<div>
							<i className="icon icon-spin icon-spinner-indicator" />
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default BasicFacts;

// helpers

/*function getExclamationMarks(numChars: number) {
    return Array(numChars + 1).join('!');
}*/
