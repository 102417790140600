import * as React from 'react';
import { Card, Col, DatePicker, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PieChart from '../../../components/PieChart';
import { huodongReport } from '../../../services/huodongService';
import { ColumnChartContext } from '../../controlPlatform/CurrentPhoneBrandChart';
import CustomRangePicker from '../../../components/CustomRangePicker';
let { RangePicker } = DatePicker;
const PieHuodongReport: React.FC = () => {
	let startTime = moment().subtract(6, 'd');
	let endTime = moment();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any>([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		let params = {
			startDay: startTime.format('YYYY-MM-DD'),
			endDay: endTime.format('YYYY-MM-DD'),
		};
		setLoading(true);
		const rsp = await huodongReport(params);
		setLoading(false);
		setLoading(false);
		if (rsp.err !== 0) {
			message.error(rsp.msg);
		}
		let data = rsp.data;
		createChartData(data);
	};

	const createChartData = (data: any) => {
		let chartData: any = [];
		data.map((item: any) => {
			chartData.push({
				value: item.value,
				type: item.key,
			});
		});
		console.log('data:::', chartData);
		setData(chartData);
	};
	const isNotNull = (data: any) => {
		if (data !== null && data !== undefined && data !== null) {
			return true;
		} else {
			return false;
		}
	};
	const tabBarExtrContent = () => (
		<CustomRangePicker
			onChange={(_startTime, _endTime) => {
				startTime = _startTime;
				endTime = _endTime;
				getData();
			}}
			startTime={startTime}
			endTime={endTime}
		/>
	);
	return (
		<Card
			extra={tabBarExtrContent()}
			title={'累计场次'}
			loading={loading}
			bodyStyle={{ paddingLeft: 15, paddingRight: 15 }}
		>
			{console.log('valuseLL,::', data)}
			<ColumnChartContext.Provider value={data}>
				{/*<ColumnChart/>*/}
				<PieChart unit={'场'} />
			</ColumnChartContext.Provider>
		</Card>
	);
};
export default PieHuodongReport;
