import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Dropdown, Image, Input, Menu, message, Select, Tooltip } from 'antd';
import Api from '../../../data/API';
import { Link, NavLink } from 'react-router-dom';
import { default as DAL } from '../../../data/DAL';
import GSTable from '../../../components/GSTable';
import API from '../../../data/API';
import * as copy from 'copy-to-clipboard';
import { closeHuodong } from '../../../services/huodongService';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons/lib';
import * as moment from 'moment';
import Phone from '../../../components/Phone';
import appConfig from '../../../appConfig';
interface Huodong {
	startDay: any;
	endDay: any;
	actionRef: any;
	classification: string;
	isHuodongReport: boolean;
}
const LookHuodong: React.FC<Huodong> = (props: any) => {
	let msg = require('../../../util/SuspensionMsg');
	let QRCode = require('qrcode.react');
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const dal = DAL.dal;
	const [search, setSearch] = useState('');
	const [zhuangTaiValue, setZhuangTaiValue] = useState('');
	const columns = [
		{
			title: '活动名称',
			dataIndex: 'huodongBiaoti',
			className: 'huodong-width',
			render: (text: any, item: any, index: any) => (
				<>
					{/*<a target="_blank" href={Api. +apiRootPath '/api/huodong/activity_details/' + item.uuid + '/' + Api.getAccessToken() + '/' + localStorage.getItem('wyUuid')}>*/}
					<a
						target="_blank"
						href={
							'/preview.html?previewUrl=' +
							appConfig.mobileAppUrl +
							'/huodong/activities-info?uuid=' +
							item.uuid +
							'&wyUuid=' +
							localStorage.getItem('wyUuid') +
							'&access_token=' +
							localStorage.getItem('token')
						}
					>
						{item.huodongBiaoti}
					</a>
				</>
			),
		},
		{
			title: '封面图',
			render: (text: any, item: any) => <Image src={Api.cdnRootPath + item.tupianUrl} width={80} height={80} />,
		},
		{
			title: '类型',
			dataIndex: 'classification',
			key: 'classification',
		},
		{
			title: '活动时间',
			render: (text: any, item: any) => (
				<span>
					开始: {moment(item.huodongKaishiDate).format('YYYY-MM-DD HH:mm')}
					<br />
					结束: {moment(item.huodongJieshuDate).format('YYYY-MM-DD HH:mm')}
				</span>
			),
		},
		{
			title: '报名截止时间',
			valueType: 'dateTime',
			dataIndex: 'huodongJiezhiDate',
		},
		{
			title: '报名人数',
			dataIndex: 'number',
		},
		{
			title: '剩余名额',
			dataIndex: 'shengyuMr',
		},
		{
			title: (
				<>
					状态
					<Tooltip
						style={{ cursor: 'pointer' }}
						placement="top"
						title={
							<div style={{ width: 250 }}>
								<span>未开始：活动还未到开始时间</span> <br />
								<span>进行中：活动正在进行</span> <br />
								<span>已结束：活动已过截至时间，活动结束</span> <br />
								<span>下线：活动终止</span> <br />
							</div>
						}
					>
						<ExclamationCircleOutlined style={{ marginLeft: 4 }} />
					</Tooltip>
				</>
			),
			dataIndex: 'zhuangTai',
			render: (text: any, item: any) => <span>{text === '已关闭' ? '下线' : text}</span>,
		},
		{
			title: '报名二维码',
			dataIndex: 'baoming',
			render: (text: any, item: any, index: any) => (
				<Tooltip title={'点击下载二维码'}>
					<a className="QRCodeA" onClick={downloadQR}>
						{console.log(
							Api.huodongPath +
								'/huodong/activities-info?uuid=' +
								item.uuid +
								'&wyUuid=' +
							    localStorage.getItem('wyUuid') +
								'&access_token='
						)}
						<code>
							{/*<QRCode style={{width: 80, height: 80}} value={Api.apiRootPath + '/api/huodong/activity_details/' + item.uuid + '/untoken/' + dal.userInfo.wyUuid}/>*/}
							<QRCode
								style={{ width: 80, height: 80 }}
								value={
									Api.huodongPath +
									'/huodong/activities-info?uuid=' +
									item.uuid +
									'&wyUuid=' +
									localStorage.getItem('wyUuid') +
									'&access_token='
								}
							/>
						</code>
					</a>
				</Tooltip>
			),
		},
		{
			title: '签到二维码',
			dataIndex: 'lastLoginTime',
			render: (text: any, item: any, index: any) => (
				<Tooltip title={'点击下载二维码'}>
					<a className="QRCodeA" onClick={downloadQR}>
						<code>
							<QRCode
								style={{ width: 80, height: 80 }}
								value={Api.apiRootPath + '/api/huodong/qian_dao?uuid=' + item.uuid}
							/>
						</code>
					</a>
				</Tooltip>
			),
		},
		{
			title: '操作',
			dataIndex: 'type',
			render: (text: any, item: any, index: any) => (
				<div>
					<Link to={'/huodong/add-huodong?uuid=' + item.uuid} style={{ paddingRight: 8 }}>
						编辑
					</Link>
					<Dropdown
						overlay={
							<Menu>
								<Menu.Item>
									<NavLink
										to={
											'/huodong/find-bmry-list?uuid=' +
											item.uuid +
											'&name=' +
											item.huodongBiaoti +
											'&key=' +
											'find-bmry-list'
										}
									>
										报名管理
									</NavLink>
								</Menu.Item>
								<Menu.Item>
									<NavLink
										to={
											'/huodong/find-sign-list?uuid=' +
											item.uuid +
											'&name=' +
											item.huodongBiaoti +
											'&key=' +
											'find-sign-list'
										}
									>
										签到管理
									</NavLink>
								</Menu.Item>
								<Menu.Item>
									<NavLink
										to={
											'/huodong/ding-dan-list?uuid=' +
											item.uuid +
											'&name=' +
											item.huodongBiaoti +
											'&key=' +
											'ding-dan-list'
										}
									>
										订单管理
									</NavLink>
								</Menu.Item>
								<Menu.Item>
									<a
										rel="noopener noreferrer"
										onClick={() => {
											editHuoDong('copyUrl', item);
										}}
									>
										复制链接
									</a>
								</Menu.Item>
								<Menu.Item>
									<a
										rel="noopener noreferrer"
										onClick={() => {
											editHuoDong('zhuangTai', item);
										}}
									>
										{item.zhuangTai === '已关闭' ? '上线' : '下线'}
									</a>
								</Menu.Item>
							</Menu>
						}
					>
						<a style={{ textDecoration: 'none' }} href="#">
							更多
						</a>
					</Dropdown>
				</div>
			),
		},
	];
	/** 活动管理操作 */
	const editHuoDong = async (value: any, item: any) => {
		if (value === 'copyUrl') {
			let url = '/api/huodong/activity_details/' + item.uuid + '/untoken/' + dal.userInfo.wyUuid;
			if (copy(Api.apiRootPath + url)) {
				msg.suspensionMsg({ content: '复制成功', type: 'success' });
			}
		} else if (value === '编辑') {
			window.location.href = '/huodong/add-huodong?uuid=' + item.uuid;
		} else if (value === 'zhuangTai') {
			const res = await closeHuodong({
				uuid: item.uuid,
				zhuangTai: item.zhuangTai,
			});
			if (res.err === 0) {
				message.success('成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else if (res.err === -5) {
				message.warning('已有人员支付，暂时不能关闭');
			} else {
				message.error('关闭失败');
			}
		}
	};

	const downloadQR = (ev: any) => {
		const canvas: any = ev.target;
		console.log('二维码' + canvas);
		let link = document.createElement('a');
		link.textContent = 'download image';
		link.setAttribute('href', canvas.toDataURL());
		link.setAttribute('download', ev.target.id + '.png');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const splitTime = (time: string) => {
		let times = time.split(' ');
		return (
			<span>
				{times[0]}
				<br />
				&nbsp;&nbsp;&nbsp;{times[1]}
			</span>
		);
	};
	const tableSearchBarRender = () => [
		<Select
			onChange={(value: any) => {
				setZhuangTaiValue(value);
				actionRef.current.reloadAndRest();
			}}
			defaultValue={'全部活动'}
		>
			<Select.Option value={'全部活动'}>全部活动</Select.Option>
			<Select.Option value={'未开始'}>未开始</Select.Option>
			<Select.Option value={'进行中'}>进行中</Select.Option>
			<Select.Option value={'已结束'}>已结束</Select.Option>
			<Select.Option value={'已关闭'}>已关闭</Select.Option>
			<Select.Option value={'未发布'}>未发布</Select.Option>
		</Select>,
		<Input
			placeholder="活动名称"
			onChange={(event) => {
				setSearch(event.target.value);
			}}
		/>,
		<Button type={"primary"} onClick={()=> {
			if (actionRef.current) {
				actionRef.current.reloadAndRest();
			}
		}}>查询</Button>
	];

	const tableOperationsBarRender = () => [
		<NavLink to={'../huodong/add-huodong'}>
			<Button type={'primary'}>创建活动</Button>
		</NavLink>,
	];

	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				// options={{ density:false}}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let url = '/api/huodong/find_huodong_list';
					let res = await API.postWithAuth(url, {
						page: params.current,
						size: params.pageSize,
						Search: search,
						zhuangTai: zhuangTaiValue === '全部活动' ? '' : zhuangTaiValue,
						startDay: props.startDay,
						endDay: props.endDay,
						classification: props.classification,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default LookHuodong;
