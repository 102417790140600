import * as React from 'react';
import DAL from '../../../data/DAL';
import API from '../../../data/API';
import { Input, Table } from 'antd';
import {} from '../../../services/ZhuhushenheService';

const Search = Input.Search;

class HouseholdCheckOut extends React.Component {
	dal = DAL.dal;

	columns = [
		{
			title: '住户姓名',
			dataIndex: 'householdName',
			key: 'householdName',
		},
		{
			title: '性别',
			dataIndex: 'sex',
			key: 'sex',
			render: (text: string, record: any) => <span>{record.sex === 0 ? '男' : '女'}</span>,
		},
		{
			title: '手机号',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: '房间号',
			key: 'roomNumber',
			dataIndex: 'roomNumber',
		},
		{
			title: '是否为承租人',
			key: 'householder',
			dataIndex: 'householder',
			/*render: (text: string, record: any) => <span>{record.householder === 1 ? '是' : '否'}</span>,*/
		},
		{
			title: '照片',
			key: 'faceImg',
			dataIndex: 'faceImg',
		},
		{
			title: '入住时间',
			key: 'stayDate',
			dataIndex: 'stayDate',
		},
		{
			title: '退房时间',
			key: 'checkOutTime',
			dataIndex: 'checkOutTime',
		},
	];

	constructor(props: any) {
		super(props);
		this.state = { ds: [] };
		this.getHouseholdCheckOut(null);
		this.getHouseholdCheckOut = this.getHouseholdCheckOut.bind(this);
	}

	getHouseholdCheckOut = async (value: any) => {
		const res = await getHouseholdCheckOut({
			Search: value,
		});
		// API.postWithAuth('/api/zhuhu/get_household_check_out' , {
		//     Search: value
		// }).then(res => {
		if (res.err === 0) {
			console.log('获得住户退房列表', res);
			this.setState({
				ds: res.data,
				isShow: false,
			});
		}
		// });
	};

	render() {
		return (
			<div>
				<nav className="filter-menu-bar navbar navbar-default" role="navigation">
					<div className="container-fluid">
						<ul className="nav navbar-nav">
							<li>
								<Search
									placeholder="住户姓名"
									onSearch={(value) => this.getHouseholdCheckOut(value)}
									style={{ width: 300, paddingLeft: 20 }}
								/>
							</li>
						</ul>
					</div>
				</nav>
				<div className="content">
					<Table
						columns={this.columns}
						size="middle"
						dataSource={(this.state as any).ds}
						pagination={false}
					/>
				</div>
			</div>
		);
	}
}

export default HouseholdCheckOut;
