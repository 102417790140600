import { Card, Checkbox, Col, Collapse, Row } from 'antd';
import { connect } from 'dva';
import React, { useEffect, useState } from 'react';
import DAL from '../../../data/DAL';
import ConnectState from '../../../models/connect';

interface Params {
	value?: string;
	onChange?: (value: string) => void;
	dataList?: any;
}
const CheckedComponents: React.FC = (props: any) => {
	const [valueList, setvalueList] = useState([]);
	const { Panel } = Collapse;

	useEffect(() => {
		console.log('scenarioTypeList', props.value);
		if (DAL.dal.isNotNull(props.value)) {
			const values = props.value.split(',') || [];
			setvalueList([...values]);
		} else {
			setvalueList([]);
		}
	}, [props.value]);

	const { common } = props;
	const { scenarioTypeList = [], schemeTechList = [] } = common;
	const triggerChange = (value?: string) => {
		props.onChange?.(value || '');
	};
	const onChange = (value) => {
		triggerChange(value.toString());
	};
	const childrenUuids = (list) => {
		let uuids = [];
		if (list) {
			list.map((item) => {
				uuids.push(item.value);
			});
		}
		return uuids;
	};
	const renderData = (data: any) => (
		<>
			<Panel header={data.title || data.name} key={data.value || data.uuid}>
				<Collapse defaultActiveKey={childrenUuids(data.children)} ghost>
					{data.children &&
					data.children.map((item: any) => (
						<Panel header={item.title || item.name} key={item.value || item.uuid}>
							<Row style={{ marginBottom: 10 }}>
								{item.children != undefined &&
								item.children.length > 0 &&
								item.children.map((item1: any) => (
									<Col>
										<Checkbox value={item1.value || item1.name} key={item1.key || item1.uuid}>
											{item1.title || item1.name}
										</Checkbox>
									</Col>
								))}
							</Row>
						</Panel>
					))}
				</Collapse>
			</Panel>
		</>
	);
	return (
		<>
			<Card>
				{props.dataList.length > 0 && (
					<Checkbox.Group   value={valueList} style={{ width: '100%' }} onChange={onChange}>
						<Collapse  defaultActiveKey={childrenUuids(props.dataList)}  ghost>
							{props.dataList.map((item) => {
								return renderData(item);
							})}
						</Collapse>
					</Checkbox.Group>
				)}
			</Card>
		</>
	);
};
export default connect(({ common }: ConnectState) => ({
	common: common,
}))(CheckedComponents);
