import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Dropdown, Form, Input, Menu, message, Modal, notification, Select, Space, Tag} from "antd";
import {NavLink} from "react-router-dom";
import AuthorizedView from "../../../util/AuthorizedView";
import GSTable from "../../../components/GSTable";
import {ActionType} from "@ant-design/pro-table";
import GsTreeSelect from "../../../components/GsTreeSelect";
import {findDoorList, updateDoorInfo} from "../../../services/DoorService";
import SocketContext from "../../../context/SocketContext";
import {FormInstance} from "antd/es/form";

const DoorList: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [search, setSearch] = useState('');
  const socket: any = useContext(SocketContext);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [updateForm] = Form.useForm<FormInstance>();
  useEffect(() => {
    if (socket != null) {
      console.log('初始化socket.io  not null', socket.id)
      socket.on('refresh', (data: any) => {
        message.success(JSON.parse(data).msg);
        if (actionRef.current) {
          actionRef.current.reload();
        }

      });
      socket.on('reconnect', (attemptNumber: number) => {
        console.log('mappingList::socket', '重连成功');
      });
      socket.on('connect', () => {
        console.log('mappingList::连接成功');
      });
      socket.on('disconnect', function () {
        console.log('断开连接');
      });
      socket.on('doorAlarmNotice', (data: any) => {
        console.log('mappingList:::handle', data);
        const noticeData = JSON.parse(data || '{}')
        notification.open({
          message: '通知',
          description: noticeData.message,
          onClick: () => {
          },
        });
      });
    }
    return () => {
      if (socket != null) {
        socket.off('reconnect');
        socket.off('disconnect');
        socket.off('connect')
        socket.off('doorAlarmNotice')
        console.log('mapping::::socket:::取消监听')
      }
    };
  }, [socket]);
  const proColumns = [
    {
      title: '名称',
      width: 200,
      dataIndex: 'name',
    },
    {
      title: '设备SN',
      width: 100,
      dataIndex: 'deviceSn'
    },
    {
      title: '门号',
      width: 100,
      dataIndex: 'accessControlControllerNo'
    },
    {
      title: '状态',
      width: 150,
      dataIndex: 'status',
      valueEnum: {
        1: <Tag color={"red"}>开启</Tag>,
        0: <Tag color={'green'}>关闭</Tag>
      }
    },
    {
      title: '在线状态',
      width: 100,
      dataIndex: 'online',
      valueEnum: {
        false: <Tag color={"red"}>离线</Tag>,
        true: <Tag color={"green"}>在线</Tag>
      }
    },
    {
      title: '操作',
      width: 200,
      fixed: 'right',
      render: (text: any, item: any, index: any) => {
        return <Space>
          <AuthorizedView needAuthority={'door_manager:door:update'}>
            <a onClick={() => {
              updateForm.setFieldsValue(item)
              setUpdateVisible(true)
            }}>编辑</a>
          </AuthorizedView>
        </Space>
      }
    }
  ];
  const tableSearchBarRender = () => [
    <Input
      placeholder="名称"
      onChange={(value: any) => {
        setSearch(value.target.value);
      }}
    />,
    <Button type={'primary'} onClick={() => {
      if (actionRef.current) {
        actionRef.current.reloadAndRest();
      }
    }}>查询</Button>
  ];
  const loadList = async (params: any) => {
    let req = {
      page: params.current,
      size: params.pageSize,
      search: search,
    }
    let rsp = await findDoorList(req);
    if (rsp.err === 0) {
      return {
        data: rsp.data.data,
        total: rsp.data.total
      }
    } else {
      return {
        data: [],
        total: 0
      }
    }
  }
  const updateItem = async () => {
    let data = await updateForm.validateFields();
    console.log('data', data);
    let rsp = await updateDoorInfo(data);
    if (rsp.err === 0) {
      message.success('修改成功');
      setUpdateVisible(false);
      actionRef.current.reload()
    } else {
      message.error(rsp.message)
    }
  }
  return <>
    <GSTable
      polling={60000}
      actionRef={actionRef}
      searchBarRender={tableSearchBarRender()}
      // operationsBarRender={tableOperationsBarRender()}
      columns={proColumns}
      request={async (params: any, sort: any, filter: any) => {
        return loadList(params);
      }}
      rowKey="id"
    />
    <Modal destroyOnClose={true} title={'编辑'} onCancel={() => {
      setUpdateVisible(false)
    }} visible={updateVisible} onOk={updateItem}>
      <Form form={updateForm} labelCol={{span: 4}}>
        <Form.Item name='id' label={'id'} hidden={true}>
          <Input/>
        </Form.Item>
        <Form.Item name='name' label='名称' rules={[{required: true}]}>
          <Input/>
        </Form.Item>
        <Form.Item name='deviceSn' label='设备SN' rules={[{required: true}]}>
          <Input disabled={true}></Input>
        </Form.Item>
        <Form.Item name='accessControlControllerNo' label='门号' rules={[{required: true}]}>
          <Input disabled={true}></Input>
        </Form.Item>
      </Form>
    </Modal>
  </>
}
export default DoorList;
