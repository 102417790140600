import * as React from 'react';
import DAL from '../../data/DAL';
import Api from '../../data/API';
import appConfig from '../../appConfig';
import { Spin, Row, Col } from 'antd';

class LiuchengIframe extends React.Component {
	dal = DAL.dal;
	ifra: any;
	state: any;
	constructor(props: any) {
		super(props);
		this.state = { liuchengdan: [], isShow: true, iframHeight: window.innerHeight - 70 };
		this.onLoad = this.onLoad.bind(this);
		this.getIframe = this.getIframe.bind(this);
	}

	onLoad(infrem: any) {
		console.log('获得的对象111>>>>>>>>>>>>>>>>>', infrem);
		this.setState({
			isShow: false,
		});
	}

	getIframe() {
		var win = window.frames;
		console.log('获得的iframeURL', appConfig.webCompanyUrl);
		this.ifra = win[0];
		if (window.location.pathname === '/liucheng') {
			this.ifra.location.href =
				appConfig.webCompanyUrl +
				'/page/liucheng/xin-shenhe?access_token=' +
				Api.getAccessToken() +
				'&user_id=' +
				Api.getUserId() +
				'&wyUuid=' +
				localStorage.getItem('wyUuid');
		} else {
			this.ifra.location.href =
				appConfig.webCompanyUrl +
				'/page' + window.location.pathname + '?access_token=' +
				Api.getAccessToken() +
				'&user_id=' +
				Api.getUserId() +
				'&wyUuid=' +
				localStorage.getItem('wyUuid');
		}

	}

	render() {
		return (
			<Row style={{ marginTop: 0 }}>
				<Col span="24">
					<iframe
						key={window.location.pathname}
						id="main"
						onLoad={this.onLoad}
						ref={this.getIframe}
						allowTransparency={true}
						style={{ width: '100%', height: this.state.iframHeight, margin: 0, border: 0}}
					/>
				</Col>
			</Row>
		);
	}
}

export default LiuchengIframe;
