import React, { useEffect, useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Col, Form, Input, Modal, Row, TreeSelect } from 'antd';
import { FormInstance } from 'antd/es/form';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { addGroup, deleteGroup, departmentAndPerson, findGroup, updataGroup } from '../../../services/patrolService';
import API from '../../../data/API';
var msg = require('../../../util/SuspensionMsg');

const PersonGroupList: React.FC = () => {
	useEffect(() => {
		getdepartment();
	}, []);
	const actionRef = useRef<ActionType>();
	const [addForm] = Form.useForm<FormInstance>();
	const [updateForm] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const confirm = Modal.confirm;
	const [member, setMember] = useState([]);
	const [TreeSelect1, setTreeSelect] = useState([]);
	const [addVisible, setAddVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	const columns = [
		{
			title: '分组名称',
			dataIndex: 'groupName',
			key: 'groupName',
			render: (text: string, record: any) => (
				<a
					onClick={() => {
						updateItem(record);
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: '组成员',
			dataIndex: 'employeeName',
			key: 'employeeName',
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, record: any) => (
				<span>
					<a
						onClick={() => {
							deleteItem(record);
						}}
					>
						删除
					</a>
				</span>
			),
		},
	];

	const deleteItem = (item: any) => {
		confirm({
			title: '您确认要删除此分组吗？',
			okType: 'danger',
			onOk: async () => {
				if (item.uuid === null) {
					msg.suspensionMsg({ content: '删除异常', type: 'danger' });
					return;
				}
				const res = await deleteGroup({
					uuid: item.uuid,
				});
				if (res.err === 0) {
					msg.suspensionMsg({ content: '删除成功', type: 'success' });
					if (actionRef.current) {
						actionRef.current.reload();
					}
				} else if (res.err === -1) {
					msg.suspensionMsg({ content: '没有权限管理该设备', type: 'danger' });
				} else {
					msg.suspensionMsg({ content: '删除失败', type: 'error' });
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
	const updateItem = (value: any) => {
		console.log('value', value);
		setTreeSelect(value.empUUID);
		const formData: any = {
			uuid: value.uuid,
			groupName: value.groupName,
			member: value.empUUID,
		};
		updateForm.setFieldsValue(formData);
		setUpdateVisible(true);
	};

	const getdepartment = async () => {
		const res = await departmentAndPerson({
			companyUuid: API.getCompanyUuid(),
		});
		if (res.err === 0) {
			console.log('获得部门和人员', res);
			setMember(res.data);
		}
	};

	const tableOperationsBarRender = () => [
		<Button
			type="primary"
			onClick={() => {
				setAddVisible(true);
			}}
		>
			新增分组
		</Button>,
	];

	const insertGroup = () => {
		addForm.validateFields().then(async (data: any) => {
			const res = await addGroup({ data: data.groupName });
			if (res.err === 0) {
				msg.suspensionMsg({ content: '添加成功', type: 'success' });
				setAddVisible(false);
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '添加失败', type: 'error' });
			}
		});
	};

	const updateGroup = () => {
		updateForm.validateFields().then(async (data: any) => {
			const da = { uuid: data.uuid, group_name: data.groupName, member: TreeSelect1 };
			const res = await updataGroup({ data: JSON.stringify(da as any) });
			if (res.err === 0) {
				msg.suspensionMsg({ content: '修改成功', type: 'success' });
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				msg.suspensionMsg({ content: '修改失败', type: 'error' });
			}
			setUpdateVisible(false);
		});
	};

	return (
		<div>
			<GSTable
				rowKey={"uuid"}
				columns={columns}
				actionRef={actionRef}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					const res = await findGroup({
						page: params.current,
						size: params.pageSize,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
			<Modal
				title="新增人员分组"
				visible={addVisible}
				onOk={insertGroup}
				onCancel={() => {
					setAddVisible(false);
				}}
			>
				<ProxyForm form={addForm}>
					<FormItem label={'分组名称'} name={'groupName'} rules={[{ required: true }]}>
						<Input placeholder={'请输入分组名称'} />
					</FormItem>
				</ProxyForm>
			</Modal>
			<Modal
				title="修改人员分组"
				visible={updateVisible}
				onOk={updateGroup}
				onCancel={() => {
					setUpdateVisible(false);
				}}
			>
				<ProxyForm form={updateForm}>
					<FormItem label={'uuid'} name={'uuid'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'分组名称'} name={'groupName'} rules={[{ required: true }]}>
						<Input placeholder={'请输入分组名称'} />
					</FormItem>
					<FormItem label={'更新成员'} name={'member'}>
						<TreeSelect
							treeData={member}
							value={TreeSelect1}
							onChange={(value: any) => {
								setTreeSelect(value);
							}}
							treeCheckable={true}
							multiple={true}
							treeNodeFilterProp="title"
							searchPlaceholder="请选择"
							dropdownStyle={{ overflow: 'auto' }}
							style={{ width: '100%' }}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default PersonGroupList;
