import React, { useEffect, useState } from 'react';
import { Col, Descriptions, Input, message, Row, Statistic } from 'antd';
import GSTable from '../../../components/GSTable';
import ApiImp from '../../../data/API';
import { creditsRecordList, getLaJiTongStatisticsiData } from '../../../services/ZhuhushenheService';

let actionRef: any = null;

const CreditsRecordList: React.FC = (prop: any) => {
	let search = '';
	const [laJiData, setLaJiData] = useState([]);
	useEffect(() => {
		getLaJiTongData();
	}, []);
	const getLaJiTongData = async () => {
		let rsp = await getLaJiTongStatisticsiData({});
		if (rsp.err === 0) {
			setLaJiData(rsp.data);
		} else {
			message.error(rsp.msg);
		}
	};
	const columns = [
		{
			title: '昵称',
			dataIndex: 'nickname',
			key: 'nickname',
		},
		{
			title: '姓名',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '账号',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: '事件描述',
			key: 'eventMsg',
			dataIndex: 'eventMsg',
		},
		{
			title: '获得积分',
			key: 'credits',
			dataIndex: 'credits',
			/*render: (text: string, record: any) => <span>{record.householder === 1 ? '是' : '否'}</span>,*/
		},
		{
			title: '日期',
			key: 'createdTime',
			valueType: 'dateTime',
			dataIndex: 'createdTime',
		},
	];

	const getData = async (params: any) => {
		let data = {
			page: params.current,
			size: params.pageSize,
			search: search,
		};
		const res = await creditsRecordList(data);
		if (res.err !== 0) {
			message.error(res.msg);
			return {
				data: [],
				total: 0,
				success: true,
			};
		}
		return {
			data: res.data,
			total: res.total,
			success: true,
		};
	};
	const onSearch = (e: any) => {
		search = e;
		actionRef.reloadAndRest();
	};
	const tableSearchBarRender = [<Input.Search onSearch={onSearch} />];
	return (
		<div>
			<Row gutter={8} style={{ backgroundColor: '#fff', padding: 15, margin: 3 }}>
				<Col span={24}>
					<Descriptions column={4} title="智能垃圾桶投放信息" style={{ paddingBottom: 0 }}>
						{laJiData.map((item) => {
							return (
								<Descriptions.Item>
									<Statistic title={item.deviceName} value={item.count} precision={0} suffix="次" />
								</Descriptions.Item>
							);
						})}
					</Descriptions>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<GSTable
						searchBarRender={tableSearchBarRender}
						actionRef={(ref: any) => (actionRef = ref)}
						columns={columns}
						request={getData}
					/>
				</Col>
			</Row>
		</div>
	);
};
export default CreditsRecordList;
