import React, { useEffect, useRef, useState } from 'react';
import { editQuestionnaire } from '../../../services/QuestionnaireService';
import { ActionType } from '@ant-design/pro-table';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	TreeSelect,
	Select,
	InputNumber,
	Space,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import {
	addDebitNote,
	billStatistical,
	createBillRefund,
	deleteChargeBill,
	findAll,
	updateBill,
} from '../../../services/PayCostManagementService';
import GSTable from '../../../components/GSTable';
import ProxyForm from '../../../components/ProxyForm';
import { currentInspectionPersonnel } from '../../../services/patrolService';
import AuthorizedView from '../../../util/AuthorizedView';
import { findFee } from '../../../services/orderService';
import DAL from '../../../data/DAL';
import getBillTypes from '../models/BillTypes';
import * as XLSX from 'xlsx';
import { jsChargeBillPrice } from '../../../services/roomCharge';
const Option = Select.Option;
interface Params {
	state?: string;
	billType?: string;
}
const BillStatistical: React.FC<Params> = (props: any) => {
	useEffect(() => {
		getFee();
	}, []);

	const TreeNode = TreeSelect.TreeNode;
	const confirm = Modal.confirm;
	const Search = Input.Search;
	let { RangePicker } = DatePicker;
	let msg = require('../../../util/SuspensionMsg');
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [searchInput, setSearchInput] = useState('');
	const [fee, setFee] = useState([]);
	const [updateVisible, setupdateVisible] = useState(false);
	const [Stime, setStime] = useState('');
	const [Etime, setEtime] = useState('');
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	const [billType, setbillType] = useState(props.billType || '');
	const billTypes = getBillTypes().list || [];
	const [updateForm] = Form.useForm<FormInstance>();
	const [feeTypeUuid, setfeeTypeUuid] = useState([])
	const columns = [
		{
			title: '收费项目',
			dataIndex: 'feeName',
		},
		{
			title: '收入金额',
			dataIndex: 'incomeAmount',
		},
		{
			title: '支出金额',
			dataIndex: 'spendAmount',
		},
		{
			title: '合计金额',
			dataIndex: 'sumAmount',
		},
	];
	const openUpdateModel = (item) => {
		updateForm.setFieldsValue({ ...item, billRefund: 0 == item.billRefund ? undefined : item.billRefund });
		setupdateVisible(true);
	};
	const tableSearchBarRender = () => [
		<RangePicker
			showTime={false}
			placeholder={['实收日期范围区间', '实收日期范围区间']}
			onChange={dateTime}
			style={{ width: '100%' }}
		/>,
		<Select
			mode="multiple"
			showSearch
			allowClear
			style={{ width: 300 }}
			placeholder={"收费项目"}
			onChange={(value:any) => { setfeeTypeUuid(value) }}
		>
			{fee.map(item => {
				return <>
					<Select.Option value={item.uuid}>{ item.feeName}</Select.Option>
				</>
			})}

		</Select>,

		<Button
			type={'primary'}
			onClick={() => {

				if (!DAL.dal.isNotNull(Stime)) {
					message.warning("请选择实收开始时间")
					return
				}
				if (!DAL.dal.isNotNull(Etime)) {
					message.warning("请选择实收结束时间")
					return
				}
				if (feeTypeUuid.length == 0) {
					message.warning("请选择收费项目")
					return
				}

				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
		>
			查询
		</Button>,
	];
	const getFee = async () => {
		const res = await findFee({});
		console.log('科目列表', res);
		if (res.err === 0) {
			const data = res.data || []
			const list = []
			data.forEach(item => {
				if (DAL.dal.isNotNull(item.type)) {
					list.push(item)
				}
			});
			setFee(list);
		}
	};

	const dateTime = (date: any, dataToString: any) => {
		setStime(date[0].format('YYYY-MM-DD 00:00:00'));
		setEtime(date[1].format('YYYY-MM-DD 23:59:59'));
	};
	const tableOperationsBarRender = () => [];
	const exprotData = async (selectedRows: [any?]) => {
		console.log('selectedRows:::::', selectedRows);
		setExprotData(selectedRows);
	};

	const setExprotData = (selectedRows: [any?]) => {
		let sheetData = new Array(selectedRows.length + 1);
		let titles: [string?] = [];
		console.log('columns:::', columns);

		columns.map((item) => {
			titles.push(item.title);
		});
		sheetData[0] = titles;
		for (let i = 0; i < selectedRows.length; i++) {
			let data = [];
			columns.map((item: any) => {
				if (item.valueEnum) {
					if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
						if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
						} else {
							data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
						}
					} else {
						data.push('');
					}
				} else if (item.valueType === 'dateTime') {
					data.push(moment(selectedRows[i][item.dataIndex]).format('YYYY-MM-DD HH:mm:ss'));
				} else {
					data.push(selectedRows[i][item.dataIndex]);
				}
			});
			sheetData[i + 1] = data;
		}
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, '账单统计');
		/* save to file */
		XLSX.writeFile(wb, '账单统计.xlsx');
	};
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				pagination={false}
				searchBarRender={tableSearchBarRender()}
				rowKey={"feeName"}
				operationsBarRender={tableOperationsBarRender()}
				rowSelection={{
					fixed: true,
					columnWidth: 100,
					preserveSelectedRowKeys: true,
					selectedRowKeys: selectedRowKeys,
					onChange: (selectedRowKeys: any, selectedRows: any) => {
						setselectedRowKeys(selectedRowKeys);
					},
				}}
				tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={24}>
							<span>
								已选 {selectedRowKeys.length} 项
								<a
									style={{ marginLeft: 8 }}
									onClick={() => {
										onCleanSelected();
									}}
								>
									取消选择
								</a>
							</span>
						</Space>
					);
				}}
				tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
					return (
						<Space size={16}>
							<a onClick={() => exprotData(selectedRows)}>导出数据</a>
						</Space>
					);
				}}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {
						payTimeStartTime: Stime,
						payTimeEndTime: Etime,
						feeTypeUuid:feeTypeUuid.toString()
					};
					if (!DAL.dal.isNotNull(Stime) || !DAL.dal.isNotNull(Etime) || feeTypeUuid.length == 0) {
						return {
							data: [],
							total: 0,
							success: true,
						};
					}
					const res = await billStatistical(data);
					return {
						data: res.data || [],
						total: 0,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default BillStatistical;
