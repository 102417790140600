import Api from '../data/API';

export async function getBanGongDataList(params: any) {
	return Api.postWithAuth('/api/banGong/get_ban_gong_list', params);
}

export async function deleteBanGong(params: any) {
	return Api.postWithAuth('/api/bangGong/delete_ban_gong', params);
}

export async function addBanGong(params: any) {
	return Api.postWithAuth('/api/banGong/save_ban_gong', params);
}

export async function updateBanGong(params: any) {
	return Api.postWithAuth('/api/banGong/update_ban_gong', params);
}

export async function getBanGongIntentionList(params: any) {
	return Api.postWithAuth('/api/bangGong/get_ban_gong_intention_list', params);
}

export async function getHandleRecordList(params: any) {
	return Api.postWithAuth('/api/bangGong/get_handle_ban_gong_record_list', params);
}

export async function saveHandleBanGongRecord(params: any) {
	return Api.postWithAuth('/api/bangGong/save_handle_ban_gong_record', params);
}
