import * as React from 'react';
import appConfig from '../appConfig';
import API from '../data/API';
let E = require('wangeditor');
/**
 * wangEditor
 */
interface LiuchengWangEditorProps {
	value: any;
	changeCallback: any;
}

class LiuchengWangEditor extends React.Component<LiuchengWangEditorProps> {
	constructor(props: any) {
		super(props);
		this.state = {
			editorContent: '',
		};
	}

	componentDidMount() {
		var editor = new E('#Mycontainer');
		//editor.customConfig.uploadImgShowBase64 = true;
		console.log('editor.customConfig::', editor, editor.customConfig);
		if (editor.customConfig !== undefined) {
			editor.customConfig.uploadImgServer = appConfig.apiRootPath + '/api/picture/upload_qiniu_two';
			editor.customConfig.uploadImgParams = {
				wuye_uuid: API.getWuyeUuid(),
			};
		}

		editor.customConfig.onchange = (html: any) => {
			this.setState({
				editorContent: html,
			});
			// this.props.changeCallback(html);
		};
		editor.create();
		console.log('value', this.props.value);
		if (this.props.value !== null && this.props.value !== undefined && this.props.value !== '') {
			editor.txt.html(this.props.value);
		}
	}

	render() {
		return (
			<div>
				<div id="Mycontainer" />
			</div>
		);
	}
}

export default LiuchengWangEditor;
