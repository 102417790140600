import React, {useEffect} from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import TimedTaskList from "./pages/TimedTaskList";

export default function TimedTaskMiniApp() {

    const rootUrl = '/timedtask';
    useEffect(() => {
      console.log('列表管理')
    }, [])
    const menus: MiniAppMenu[] = [
        {
            authority: 'foundation_timed_task:list',
            name: '任务列表',
            path: 'list',
            component: TimedTaskList,
        },

    ];

    return <MiniApp miniAppName={'任务管理'} rootUrl={rootUrl} menus={menus} />;
}
