import Api from '../data/API';

export async function quit(params: any) {
	return Api.postWithAuth('/api/wuye/quit', params);
}
export async function recoverUser(params: any) {
	return Api.postWithAuth('/api/wuye/recoverUser', params);
}
export async function batchQuit(params: any) {
	return Api.postWithAuth("/api/wuye/batchQuit", params);
}

export async function batchHandleRequeryKey(params: any) {
	return Api.postWithAuth("/api/wuye/batchHandleRequeryKey", params);
}
export async function batchRejectRequeryKey(params: any) {
	return Api.postWithAuth("/api/wuye/batchRejectRequeryKey", params);
}

export async function updataPersonManage(params: any) {
	return Api.postWithAuth('/api/wuye/updata_person_manage', params);
}

export async function findProjectList(params: any) {
	return Api.postWithAuth('/api/wuye/find_project_list', params);
}

export async function findRole(params: any) {
	return Api.postWithAuth('/api/wuye/find_role', params);
}

export async function wuyeAddEmployee(params: any) {
	return Api.postWithAuth('/api/wuye/wuye_add_employee', params);
}

export async function findAllPersonManage(params: any) {
	return Api.postWithAuth('/api/wuye/find_all_person_manage', params);
}
export async function exportAllPersonManage(params: any) {
	return Api.postWithAuth('/api/wuye/export_all_person_manage', params);
}

export async function findDepartmentDirectory(params: any) {
	return Api.postWithAuth('/api/wuye/find_department_directory', params);
}

export async function addDepartment(params: any) {
	return Api.postWithAuth('/api/wuye/add_department', params);
}

export async function updataDepartment(params: any) {
	return Api.postWithAuth('/api/wuye/updata_department', params);
}
export async function deleteDepartment(params: any) {
	return Api.postWithAuth('/api/wuye/delete_department', params);
}
export async function dragDropDepartment(params: any) {
	return Api.postWithAuth('/api/wuye/drag_drop_department', params);
}
export async function projectPersonAndAllPerson(params: any) {
	return Api.postWithAuth('/api/wuye/project_person_and_all_person', params);
}
export async function addProjectEmp(params: any) {
	return Api.postWithAuth('/api/wuye/add_project_emp', params);
}

export async function deleteProjectEmp(params: any) {
	return Api.postWithAuth('/api/wuye/delete_project_emp', params);
}
export async function groupOfMember(params: any) {
	return Api.postWithAuth('/api/v2/wuye/group_of_member', params);
}
export async function findWuyeGroup(params: any) {
	return Api.postWithAuth('/api/v2/wuye/find_wuye_group', params);
}

export async function getPermGLiuChengByGroupId(params: any) {
	return Api.postWithAuth('/api/wuye/getPermGLiuChengByGroupId', params);
}
export async function getPermGByEmpUuid(params: any) {
	return Api.postWithAuth('/api/wuye/getPermGByEmpUuid', params);
}

export async function getLiuChenDanByUserUuid(params: any) {
	return Api.postWithAuth("/api/wuye/shenhe/getLiuchengDan", params);
}

export async function addAdminWuyeGroup(params: any) {
	return Api.postWithAuth('/api/wuye/add_admin_wuye_group', params);
}
export async function updataAdminWuyeGroup(params: any) {
	return Api.postWithAuth('/api/wuye/updata_admin_wuye_group', params);
}

export async function deleteAdminWuyeGroup(params: any) {
	return Api.postWithAuth('/api/wuye/delete_admin_wuye_group', params);
}

export async function findDepartment(params: any) {
	return Api.postWithAuth('/api/wuye/find_department', params);
}

export async function getWuyeProj(params: any) {
	return Api.postWithAuth('/api/wuye/getWuyeProj', params);
}

export async function updateWuyeProjByKeyAuto(params: any) {
	return Api.postWithAuth('/api/wuye/updateWuyeProjByKeyAuto', params);
}

export async function getDepartmentList(params: any) {
	return Api.postWithAuth('/api/wuye/getDepartmentList', params);
}

export async function setDepartmentPrincipal(params: any) {
	return Api.postWithAuth('/api/wuye/setDepartmentPrincipal', params);
}

export async function setDepartmentManager(params: any) {
	return Api.postWithAuth('/api/wuye/setDepartmentManager', params);
}
export async function setDepartmentSuperiorLeaders(params: any) {
	return Api.postWithAuth('/api/wuye/setDepartmentSuperiorLeaders', params);
}
export async function setDepartmentLeaders(params: any) {
	return Api.postWithAuth('/api/wuye/setDepartmentLeaders', params);
}
export async function deleteEmployee(params: any) {
	return Api.postWithAuth('/api/wuye/deleteEmployee', params);
}
export async function updateCompEmployee(params: any) {
	return Api.postWithAuth('/api/wuye/updateCompEmployee', params);
}

export async function updateDeviceInfo(params: any) {
	return Api.postWithAuth('/api/wuye/updata_device_info', params);
}

export async function batchEmp(params: any) {
	return Api.postWithAuth('/api/wuye/BatchEmp', params);
}

export async function getDaiShenhe(params:any) {
    return Api.postWithAuth('/api/liucheng/get_dai_shenhe',params);
}
export async function removeLiuChengDanShenHeRen(params: any) {
	return Api.postWithAuth('/api/wuye/shenhe/removeLiuChengDanShenHeRen', params);
}
export async function updateLiuChengDanShenHeRen(params: any) {
	return Api.postWithAuth('/api/wuye/shenhe/updateLiuChengDanShenHeRen', params);
}
export async function updateShiliShenHeRen(params: any) {
	return Api.postWithAuth('/api/wuye/shenhe/updateShiliShenHeRen', params);
}
export async function getShiLiShenHe(params: any) {
	return Api.postWithAuth('/api/wuye/shenhe/getShiLiShenHe', params);
}
export async function findBuilds(params: any) {
	return Api.postWithAuth('/api/zhuhu/find_builds', params);
}
export async function findHouseholdList(params: any) {
	return Api.postWithAuth('/api/wuye/findHouseholdList', params);
}

export async function getAbnormalPersonnelList(params: any) {
	return Api.postWithAuth('/admin/api/AbnormalPersonnel/list', params);
}

