import React from 'react';
import DAL from '../data/DAL';

interface AuthorizedViewProps {
	children: any;
	needAuthority: string;
	mismatch?: any;
}

export default function AuthorizedView(props: AuthorizedViewProps) {
	const checkPermission = (perm: string): boolean => {
		let newDALPermission = DAL.permissions;
		if (newDALPermission.length === 0) {
			return true;
		}
		return newDALPermission.indexOf(perm) !== -1;
	};

	const render = () => {
		if (checkPermission(props.needAuthority)) {
			return props.children;
		} else {
			return props.mismatch || '';
		}
	};

	return render();
}
