import React, { useEffect, useState } from 'react';
import { Button, Card, Col, InputNumber, message, Row, Switch } from 'antd';
import Api from '../../../data/API';
import { getKuangshiJobParam, setKuangshiJobParam, syncKuangShiFace1 } from '../../../services/FoundationSetupService';
const KuangshiJob: React.FC = (props: any) => {
	const [syncKuangShiJob, setSyncKuangShiJob] = useState(false);
	const [syncKuangShiJobHour, setSyncKuangShiJobHour] = useState(1);
	useEffect(() => {
		getData();
	}, []);
	const getData = async () => {
		const res = await getKuangshiJobParam({});
		// Api.postWithAuth('/admin/api/foundation/getKuangshiJobParam', {}).then(res => {
		if (res.err === 0) {
			setSyncKuangShiJob(res.data.syncKuangShiJob);
			setSyncKuangShiJobHour(res.data.syncKuangShiJobHour);
		} else {
			message.error(res.msg);
		}
		// });
	};
	const setData = async () => {
		let params = {
			syncKuangShiJob,
			syncKuangShiJobHour,
		};

		const res = await setKuangshiJobParam(params);

		// Api.postWithAuth('/admin/api/foundation/setKuangshiJobParam', params).then(res => {
		if (res.err === 0) {
			message.success(res.msg);
			getData();
		} else {
			message.error(res.msg);
		}
		// })
	};
	const syncKuangShiFace = async () => {
		const res = await syncKuangShiFace1({});
		// Api.postWithAuth('/admin/api/foundation/syncKuangShiFace', {}).then(res => {
		if (res.err === 0) {
			message.success(res.msg);
		} else {
			message.error(res.msg);
		}
		// })
	};
	return (
		<div style={{ backgroundColor: '#fff', paddingBottom: 20, paddingTop: 20 }}>
			<Card title="手动同步" style={{ width: '100%' }}>
				<Row align={'middle'}>
					<Col>
						<Button type={'primary'} onClick={syncKuangShiFace}>
							手动同步
						</Button>
					</Col>
				</Row>
			</Card>
			<Card title="定时任务设置" style={{ width: '100%' }}>
				<Row gutter={8} align={'middle'}>
					<Col push={2}>是否开启：</Col>
					<Col push={2}>
						<Switch
							key={syncKuangShiJob}
							defaultChecked={syncKuangShiJob}
							onChange={(checked) => {
								setSyncKuangShiJob(checked);
							}}
						/>
					</Col>
				</Row>
				<br />
				<Row gutter={8} align={'middle'}>
					<Col push={2}>同步时间：</Col>
					<Col push={2}>
						<InputNumber
							key={syncKuangShiJobHour}
							min={0}
							max={23}
							defaultValue={syncKuangShiJobHour}
							onChange={(value: any) => {
								setSyncKuangShiJobHour(value);
							}}
						/>
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col push={2}>
						<Button type={'primary'} onClick={setData}>
							保存
						</Button>
					</Col>
				</Row>
			</Card>
		</div>
	);
};
export default KuangshiJob;
