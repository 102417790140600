import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Image, Input, message, Modal, Select, Space, Switch } from 'antd';
import GSTable from '../../../components/GSTable';
import { ActionType } from '@ant-design/pro-table/lib/typing';
import {
	addChannel,
	deleteChannel, deleteUserChannel,
	getChannelUserList,
	getList,
	getOrderVipList, getUserBindChannelList, setUserChannel,
	updateChannel
} from '../../../services/ChannelService';
import AuthorizedView from '../../../util/AuthorizedView';
import { getBeijingOfficeUserList } from '../../../services/BeijingOfficeService';

const ChannelList: React.FC = () => {
	const [addVisible, setAddVisible] = useState(false);
	const [orderVisible, setOrderVisible] = useState(false);
	const [updateVisible, setUpdateVisible] = useState(false);
	const [addUserVisible, setAddUserVisible] = useState(false);
	const [channelVisible, setChannelVisible] = useState(false);
	const [search, setSearch] = useState('');
	const [channelUuid, setChannelUuid] = useState('');
	const [userListVisible, setUserListVisible] = useState(false);
	const [type, setType] = useState('');

	const actionRef = useRef<ActionType>();
	const channelListActionRef = useRef<ActionType>();
	const orderActionRef = useRef<ActionType>();
	const userListActionRef = useRef<ActionType>();
	const [addForm] = Form.useForm();
	const [updateForm] = Form.useForm();
	const [addUserForm] = Form.useForm();
	const columns = [
		{
			title: '渠道名称',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: '渠道类型',
			dataIndex: 'type',
			valueEnum: {
				'szg': '企业服务渠道',
				'zjb': '城市服务渠道',
				'kjcg': '科技成果服务渠道'
			},
			key: 'type'
		},
		{
			title: '描述',
			dataIndex: 'note',
			key: 'note'
		},
		{
			title: '创建时间',
			dataIndex: 'createdTime',
			valueType: 'dateTime',
			key: 'createdTime'
		},
		{
			title: '操作人',
			dataIndex: 'operator',
			key: 'operator'
		},
		{
			title: '操作',
			key: 'action',
			render: (text: string, item: any) => {
				return <Space>
					<AuthorizedView needAuthority={'channel:manager:update'}>
						<a onClick={() => {
							updateForm.setFieldsValue(item);
							setUpdateVisible(true);
						}}>编辑</a>
					</AuthorizedView>
					<AuthorizedView needAuthority={'channel:manager:userList'}>
						<a onClick={() => {
							setChannelUuid(item.uuid);
							setUserListVisible(true);
						}}>添加用户</a>
					</AuthorizedView>
					<a onClick={() => {
						setChannelUuid(item.uuid);
						setChannelVisible(true);
					}}>邀请用户列表</a>
					<a onClick={() => {
						setChannelUuid(item.uuid);
						setOrderVisible(true);
					}}>成交订单</a>
					<AuthorizedView needAuthority={'channel:manager:delete'}>
						<a onClick={() => {
							Modal.confirm({
								title: '删除',
								content: '您确定删除该渠道吗？',
								okType: 'danger',
								okText: '删除',
								onOk: async () => {
									let data = {
										uuid: item.uuid
									};
									let rsp = await deleteChannel(data);
									if (rsp.err === 0) {
										actionRef.current.reload();
									} else {
										message.error(rsp.msg);
									}
								}
							});
						}}>删除</a>
					</AuthorizedView>

				</Space>;
			}
		}
	];
	const orderColumns = [
		{
			title: '订单号',
			dataIndex: 'orderNo',
			key: 'orderNo'
		},
		{
			title: '描述',
			dataIndex: 'body',
			key: 'body'
		},
		{
			title: '支付金额',
			dataIndex: 'totalFee',
			key: 'totalFee',
			render: (text, record) => {
				return <span>{(record.totalFee / 100).toFixed(2)}</span>;
			}
		},
		{
			title: '企业名称',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: '支付者',
			dataIndex: 'realName',
			key: 'realName'
		},
		{
			title: '支付者账号',
			dataIndex: 'loginId',
			key: 'loginId'
		},
		{
			title: '推荐人',
			dataIndex: 'inviterName',
			key: 'inviterName'
		},
		{
			title: '推荐人账号',
			dataIndex: 'inviterPhone',
			key: 'inviterPhone'
		},
		{
			title: '支付时间',
			dataIndex: 'payTime',
			valueType: 'dateTime',
			key: 'payTime'
		},
		{
			title: '创建时间',
			dataIndex: 'createTime',
			valueType: 'dateTime',
			key: 'createTime'
		}
	];
	const userListColumns = [
		{
			title: '名称',
			dataIndex: 'userName',
			key: 'userName'
		},
		{
			title: '手机号',
			dataIndex: 'userPhone',
			key: 'userPhone'
		},
		{
			title: '创建时间',
			dataIndex: 'createdTime',
			valueType: 'dateTime',
			key: 'createdTime'
		},
		{
			title: '操作',
			dataIndex: 'active',
			render: (text: string, record: any) => {
				return <Space>
					<AuthorizedView needAuthority={'channel:manager:deleteUser'}>
						<a onClick={() => {
							Modal.confirm({
								title: "是否确认删除该用户？",
								okType: 'danger',
								okText: "删除",
								cancelText: "取消",
								onOk: async () => {
									let rsp = await deleteUserChannel({
										loginId: record.userPhone,
										channelUuid: channelUuid
									});
									if (rsp.err === 0) {
										message.success(rsp.msg)
										userListActionRef.current.reload()
									} else {
										message.error(rsp.msg)
									}
								}
							})
						}
						}>删除</a>
					</AuthorizedView>
				</Space>;
			}
		}
	];
	const channelColumns = [
		{
			title: '姓名',
			key: 'userName',
			dataIndex: 'userName'
		}, {
			title: '手机号',
			key: 'userPhone',
			dataIndex: 'userPhone'
		},
		{
			title: '推荐人帐号',
			key: 'referrerPhone',
			dataIndex: 'referrerPhone'
		},
		{
			title: '加入时间',
			key: 'joinChannelTime',
			valueType: 'dateTime',
			dataIndex: 'joinChannelTime'
		}
	];
	const tableSearchBarRender = () => [
		<Space>
			<Select style={{ width: 150 }} defaultValue={type} onChange={(e: string) => {
				setType(e);
			}}>
				<Select.Option key={''} value={''}>全部</Select.Option>
				<Select.Option key={'szg'} value={'szg'}>企业服务渠道</Select.Option>
				<Select.Option key={'zjb'} value={'zjb'}>城市服务渠道</Select.Option>
				<Select.Option key={'kjcg'} value={'kjcg'}>科技成果服务渠道</Select.Option>
			</Select>
			<Input
				style={{ minWidth: 150 }}
				placeholder="名称"
				onChange={(e) => {
					setSearch(e.target.value);
				}}
			/>

			<Button
				type="primary"
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>
		</Space>
	];
	const tableOperationsBarRender = () => [
		<AuthorizedView needAuthority={'channel:manager:add'}>
			<Button type="primary" onClick={() => {
				setAddVisible(true);
			}}>
				新增
			</Button>
		</AuthorizedView>
	];
	const addChannelItem = async () => {
		let data = await addForm.validateFields();
		let rsp = await addChannel(data);
		if (rsp.err === 0) {
			setAddVisible(false);
			actionRef.current.reload();
		} else {
			message.error(rsp.msg);
		}
	};
	const updateChannelItem = async () => {
		let data = await updateForm.validateFields();
		let rsp = await updateChannel(data);
		if (rsp.err === 0) {
			setUpdateVisible(false);
			actionRef.current.reload();
		} else {
			message.error(rsp.msg);
		}
	};
	const addUserChannel = async () => {
		let data = await addUserForm.validateFields();
		let rsp = await setUserChannel(data);
		if (rsp.err === 0) {
			setAddUserVisible(false);
			userListActionRef.current.reload();
		} else {
			message.error(rsp.msg);
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				rowKey="uuid"
				request={async (params: any = {}, sort: any, filter: any) => {
					const formData: any = {
						page: params.current,
						size: params.pageSize,
						type: type,
						search: search
					};
					const res = await getList(formData);
					return {
						data: res.data,
						total: res.total,
						success: true
					};
				}}
			/>
			<Modal title="添加渠道" visible={addVisible} onCancel={() => {
				setAddVisible(false);
			}} onOk={addChannelItem}>
				<Form form={addForm}>
					<Form.Item name={'name'} label={'渠道名称'} rules={[{ required: true, message: '输入名称' }]}>
						<Input/>
					</Form.Item>
					<Form.Item initialValue={'szg'} name={'type'} label={'类型'}>
						<Select>
							<Select.Option key={'szg'} value={'szg'}>企业服务渠道</Select.Option>
							<Select.Option key={'zjb'} value={'zjb'}>城市服务渠道</Select.Option>
							<Select.Option key={'kjcg'} value={'kjcg'}>科技成果服务渠道</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item valuePropName={'checked'} name={'status'} label={'状态'}>
						<Switch/>
					</Form.Item>
					<Form.Item name={'note'} label={'备注'}>
						<Input.TextArea/>
					</Form.Item>
				</Form>
			</Modal>
			<Modal title="编辑渠道" visible={updateVisible} onCancel={() => {
				setUpdateVisible(false);
			}} onOk={updateChannelItem}>
				<Form form={updateForm}>
					<Form.Item name={'uuid'} hidden={true} label={'id'}>
						<Input/>
					</Form.Item>
					<Form.Item name={'name'} label={'渠道名称'} rules={[{ required: true, message: '输入名称' }]}>
						<Input/>
					</Form.Item>
					<Form.Item initialValue={'szg'} name={'type'} label={'类型'}>
						<Select disabled={true}>
							<Select.Option key={'szg'} value={'szg'}>企业服务渠道</Select.Option>
							<Select.Option key={'zjb'} value={'zjb'}>城市服务渠道</Select.Option>
							<Select.Option key={'kjcg'} value={'kjcg'}>科技成果服务渠道</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item valuePropName={'checked'} name={'status'} label={'状态'}>
						<Switch/>
					</Form.Item>
					<Form.Item name={'note'} label={'备注'}>
						<Input.TextArea/>
					</Form.Item>
				</Form>
			</Modal>

			<Modal destroyOnClose={true} title={'成交记录'} width={1200} visible={orderVisible} onCancel={() => {
				setOrderVisible(false);
			}} footer={null}>
				<GSTable
					columns={orderColumns}
					actionRef={orderActionRef}
					searchBarRender={<Space>
						<Input
							placeholder="搜索"
							onChange={(event) => {
								setSearch(event.target.value);

							}}
						/>
						<Button type={'primary'} onClick={() => {
							if (orderActionRef.current) {
								orderActionRef.current.reloadAndRest();
							}
						}}>查询</Button>
					</Space>}
					request={async (params: any = {}, sort: any, filter: any) => {
						let res = await getOrderVipList({
							Search: search,
							channelUuid: channelUuid,
							isPay: true,
							page: params.current,
							size: params.pageSize
						});
						return {
							data: res.data,
							total: res.total,
							success: true
						};
					}}
				/>
			</Modal>
			<Modal destroyOnClose={true} title={'用户列表'} width={1200} visible={userListVisible} onCancel={() => {
				setUserListVisible(false);
			}} footer={null}>
				<GSTable
					columns={userListColumns}
					actionRef={userListActionRef}
					searchBarRender={<Space>
						<Input
							placeholder="搜索"
							onChange={(event) => {
								setSearch(event.target.value);

							}}
						/>
						<Button type={'primary'} onClick={() => {
							if (userListActionRef.current) {
								userListActionRef.current.reloadAndRest();
							}
						}}>查询</Button>
					</Space>}
					operationsBarRender={[<AuthorizedView needAuthority={'channel:manager:user'}><Button
						type={'primary'} onClick={() => {
						addUserForm.resetFields();
						setAddUserVisible(true);
					}}>添加用户</Button></AuthorizedView>]}
					request={async (params: any = {}, sort: any, filter: any) => {
						let res = await getChannelUserList({
							Search: search,
							channelUuid: channelUuid,
							page: params.current,
							size: params.pageSize
						});
						return {
							data: res.data,
							total: res.total,
							success: true
						};
					}}
				/>
			</Modal>
			<Modal title="添加渠道用户" destroyOnClose={true} visible={addUserVisible} onCancel={() => {
				setAddUserVisible(false);
			}} onOk={addUserChannel}>
				<Form form={addUserForm}>
					<Form.Item initialValue={channelUuid} name={'channelUuid'} label={'渠道UUID'} hidden={true}
							   rules={[{ required: true }]}>
						<Input/>
					</Form.Item>
					<Form.Item name={'loginId'} label={'用户账户'} rules={[{ required: true, message: '输入账户' }]}>
						<Input/>
					</Form.Item>
				</Form>
			</Modal>
			<Modal destroyOnClose={true} title={'邀请人员列表'} width={1200} visible={channelVisible} onCancel={() => {
				setChannelVisible(false);
			}} footer={null}>
				<GSTable
					columns={channelColumns}
					actionRef={channelListActionRef}
					request={async (params: any = {}, sort: any, filter: any) => {
						let res = await getUserBindChannelList({
							channelUuid: channelUuid,
							page: params.current,
							size: params.pageSize
						});
						return {
							data: res.data,
							total: res.total
						};
					}}
				/>
			</Modal>
		</>
	);
};
export default ChannelList;
