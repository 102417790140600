import React, { useRef, useState } from 'react';
import { ActionType } from '@ant-design/pro-table';
import { Button, Image, Input, Tooltip } from 'antd';
import appConfig from '../../appConfig';
import Api from '../../data/API';
import { Link } from 'react-router-dom';
import GSTable from '../../components/GSTable';
import { questionnaireList, questionnaireState } from '../../services/QuestionnaireService';
import * as copy from 'copy-to-clipboard';
var msg = require('../../util/SuspensionMsg');
var QRCode = require('qrcode.react');

const Questionnaire: React.FC = () => {
	const [searchType, setSearchType] = useState('');
	const Search = Input.Search;
	const actionRef = useRef<ActionType>();
	const columns = [
		{
			title: '问卷名称',
			dataIndex: 'questionnaireBiaoti',
			key: 'questionnaireBiaoti',
			width: '15%',
			render: (text: string, record: any) => (
				<Tooltip title={record.questionnaireBiaoti}>
					<span id={'ts' + record.uuid + '1'}>
						<a
							target="_blank"
							style={{ padding: 0 }}
							href={
								appConfig.mobileAppUrl +
								'/huodong/fill_questionnaire?question=' +
								record.uuid +
								'&token=untoken&wyUuid=' +
								record.wyUuid
							}
						>
							{' '}
							{record.questionnaireBiaoti}
						</a>
					</span>{' '}
				</Tooltip>
			),
		},
		{
			title: '封面图',
			dataIndex: 'fengmianTu',
			key: 'fengmianTu',
			width: '10%',
			render: (text: string, record: any) => (
				<span>
					{record.fengmianTu === null || record.fengmianTu === undefined || record.fengmianTu === '' ? (
						''
					) : (
						<Image className="homePage" src={Api.cdnRootPath + record.fengmianTu} width={80} height={80} />
					)}
				</span>
			),
		},
		{
			title: '创建时间',
			dataIndex: 'questionnaireCreationDate',
			key: 'questionnaireCreationDate',
			width: '10%',
		},
		{
			title: '创建人',
			dataIndex: 'questionnaireInitiator',
			key: 'questionnaireInitiator',
			width: '8%',
		},
		{
			title: '提交人数',
			dataIndex: 'submissionNumberPeople',
			key: 'submissionNumberPeople',
			width: '8%',
		},
		{
			title: '状态',
			dataIndex: 'state',
			key: 'state',
			width: '8%',
		},
		{
			title: '二维码',
			dataIndex: 'creationTime',
			key: 'creationTime',
			width: '10%',
			render: (text: string, record: any) => (
				<Tooltip title="点击下载二维码">
					{' '}
					<span id={'ts' + record.uuid + '2'}>
						<a className="QRCodeA" onClick={downloadQR}>
							<code>
								<QRCode
									style={{ width: 80, height: 80 }}
									value={
										appConfig.mobileAppUrl +
										'/huodong/fill_questionnaire?question=' +
										record.uuid +
										'&token=untoken&wyUuid=' +
										record.wyUuid
									}
								/>
							</code>
						</a>
					</span>
				</Tooltip>
			),
		},
		{
			title: '操作',
			key: 'action',
			width: '20%',
			render: (text: string, record: any) => (
				<span>
					<ul className="ant-list-item-action">
						<li>
							<Link
								to={{
									pathname: '/questionnaire/questionnaire_data',
									state: { uuid: record.uuid, name: record.questionnaireBiaoti },
								}}
							>
								<a>管理</a>
							</Link>
						</li>
						<li>
							<Link to={'/questionnaire/add_questionnaire?uuid=' + record.uuid}>
								<a style={{ cursor: 'pointer' }}>编辑</a>
							</Link>
						</li>
						<li>
							<a
								style={{ cursor: 'pointer' }}
								onClick={() => {
									offState(record.uuid, record.state === '暂存中' ? '进行中' : '暂存中');
								}}
							>
								{record.state === '暂存中' ? '开启' : '关闭'}
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									duplicate(record);
								}}
								style={{ cursor: 'pointer' }}
							>
								复制链接
							</a>
						</li>
					</ul>
				</span>
			),
		},
	];

	const downloadQR = (ev: any) => {
		const canvas: any = ev.target;
		console.log('二维码' + canvas.toDataURL());
		var link = document.createElement('a');
		link.textContent = 'download image';
		link.setAttribute('href', canvas.toDataURL());
		link.setAttribute('download', ev.target.id + '.png');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const offState = async (uuid: string, state: string) => {
		const res = await questionnaireState({
			uuid: uuid,
			state: state,
		});
		if (res.err === 0) {
			msg.suspensionMsg({ content: '成功', type: 'success' });
			if (actionRef.current) {
				actionRef.current.reload();
			}
		} else {
			msg.suspensionMsg({ content: '失败', type: 'error' });
		}
	};
	/**
	 * 点击复制
	 */
	const duplicate = (value: any) => {
		let uri = '/huodong/fill_questionnaire?question=' + value.uuid + '&token=untoken&wyUuid=' + value.wyUuid;
		if (copy(appConfig.mobileAppUrl + uri)) {
			msg.suspensionMsg({ content: '复制成功', type: 'success' });
		}
	};

	const tableSearchBarRender = () => [
		<Search
			placeholder="搜索类目"
			onSearch={(value) => {
				setSearchType(value);
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}
			enterButton={true}
		/>,
	];
	/*创建按钮*/
	const tableOperationsBarRender = () => [
		<Link to={'/questionnaire/add_questionnaire'}>
			<Button type="primary">创建问卷</Button>
		</Link>,
	];
	return (
		<div>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				operationsBarRender={tableOperationsBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let res = await questionnaireList({
						page: params.current,
						size: params.pageSize,
						Search: searchType,
					});
					return {
						data: res.data,
						total: res.count,
						success: true,
					};
				}}
			/>
		</div>
	);
};
export default Questionnaire;
