import React, { useEffect, useRef, useState } from "react";
import {
    addItemType,
    deleteItemType,
    getItemTypeList,
     updateItemType
} from '../../../services/ZhuhushenheService';
import GSTable from "../../../components/GSTable";
import { ActionType } from "@ant-design/pro-table/lib/typing";
import { Button, Form, Input, InputNumber, message, Modal, Select, Space } from "antd";
import { FormInstance } from "antd/es/form";
import ProxyForm from "../../../components/ProxyForm";
import ItemsBatch from "../../assets/components/ItemsBatch";
const ItemTypeList: React.FC = () => {
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [add, setadd] = useState(false)
    const [form] = Form.useForm<FormInstance>();
    const [searchType, setsearchType] = useState("")
    const [batchVisible, setbatchVisible] = useState(false)
    const columns = [
     
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: '创建时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            valueType:'dateTime'
        },
       
        {
            title: '操作',
            key: 'action',
            render: (record: any) => (
                <Space>
                    <a onClick={() => {
                        form.setFieldsValue(record);
                        setadd(false)
                        setVisible(true)
                    }}>编辑</a>
                    <a onClick={() =>{
                        Modal.confirm({
                            title: '提示',
                            content: "您确定要删除此类目吗？",
                            okType: 'danger',
                            onOk: async() => {
                                let data = {
                                    name: record.name
                                }
                                let rsp = await deleteItemType(data)
                                if (rsp.err === 0) {
                                    message.success(rsp.msg)
                                    if (actionRef.current) {
                                        actionRef.current.reload()
                                    }
                                    return
                                }
                                message.error(rsp.msg)
                            }
                        })
                    }}>删除</a>
                </Space>
            ),
        },
    ];

    const tableSearchBarRender = () => [
        // <Select style={{width:200}} value={searchType} onChange={(value) => setsearchType(value)} >
        //      <Select.Option value={""}>全部</Select.Option>
        //     <Select.Option value={"家具"}>家具</Select.Option>
        //     <Select.Option value={"家电"}>家电</Select.Option>
        //     <Select.Option value={"厨卫"}>厨卫</Select.Option>
        //     <Select.Option value={"设备设施"}>设备设施</Select.Option>
        //     <Select.Option value={"五金装饰"}>五金装饰</Select.Option>
        //     <Select.Option value={"其他"}>其他</Select.Option>
        // </Select>,
        // <Input placeholder={'名称'} onChange={(event: any) => {
        //     setSearch(event.target.value)
        // }}/>,
        // <Button type="primary" onClick={() => {
        //     if (actionRef.current) {
        //         actionRef.current.reload()
        //     }
        // }}>查询</Button>
    ];
    const tableOperationsBarRender = () => [
        <Button type="primary" onClick={() => {
            setadd(true)
            setVisible(true)
        }}>
            新增
        </Button>,
    ];
    const addItemOk = async () => {
        let data = await form.validateFields();
        console.log('data:::', data);
        setLoading(true)
        let rsp = await addItemType(data)
        setLoading(false)
        if (rsp.err === 0) {
            message.success(rsp.msg);
            setVisible(false)
            if (actionRef.current) {
                actionRef.current.reload()
            }
        } else {
            message.error(rsp.msg)
        }
    }

    const updateItemOk = async () => {
        let data = await form.validateFields();
        console.log('data:::', data);
        setLoading(true)
        let rsp = await updateItemType(data)
        setLoading(false)
        if (rsp.err === 0) {
            message.success(rsp.msg);
            setVisible(false)
            if (actionRef.current) {
                actionRef.current.reloadAndRest()
            }
        } else {
            message.error(rsp.msg)
        }

    }
    const saveItem = () => {
        if (add) {
            addItemOk()
        } else {
            updateItemOk()
        }
    }
    return <>
        <GSTable
            columns={columns}
            actionRef={actionRef}
            searchBarRender={tableSearchBarRender()}
            operationsBarRender={tableOperationsBarRender()}
            request={async (params: any = {}, sort: any, filter: any) => {
                const res = await getItemTypeList({
                    page: params.current,
                    size: params.pageSize,
                    search: search,
                    type:searchType,
                });
                if (res.err !== 0) {
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return {
                    data: res.data,
                    total: res.total,
                    success: true,
                };
            }}
        />
        <Modal title={add?'添加':'修改'} visible={visible} onCancel={() => {setVisible(false)}} maskClosable={false} confirmLoading={loading} onOk={saveItem}>
            <ProxyForm form={form}>
            <Form.Item name={'id'} label={'id'}  hidden>
                    <Input />
                </Form.Item>
                <Form.Item name={'name'} label={'名称'}   rules={[{required: true, message: '请填写名称'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name={'note'} label={'备注'}>
                    <Input.TextArea maxLength={200}/>
                </Form.Item>
            </ProxyForm>
        </Modal>
       

    </>
}
export default ItemTypeList;
