import React, { useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { Button, Input } from 'antd';
import { getSystemLogs } from '../../../services/SystemLogSevice';
import CustomRangePicker from '../../../components/CustomRangePicker';
import { ActionType } from '@ant-design/pro-table';
import moment from 'moment';
const LogList: React.FC = () => {
	const actionRef = useRef<ActionType>();
	const [search, setSearch] = useState('');
	const [startTime, setStartTime] = useState(
		moment().subtract(7, 'd').hours(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss')
	);
	const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:ss'));
	const columns = [
		{
			title: '操作人员',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '账号',
			dataIndex: 'userLoginId',
			key: 'userLoginId',
		},
		{
			title: '操作时间',
			dataIndex: 'createdTime',
			valueType: 'dateTime',
			key: 'createdTime',
		},
		{
			title: 'IP地址',
			dataIndex: 'ip',
			key: 'ip',
		},
	];
	const rangePickerClick = (startTime: any, endTime: any) => {
		setStartTime(startTime.format('YYYY-MM-DD HH:mm:00'));
		setEndTime(endTime.format('YYYY-MM-DD HH:mm:59'));
	};
	const tableSearchBarRender = () => {
		return [
			<CustomRangePicker onChange={rangePickerClick} />,
			<Input
				placeholder={'姓名，账号'}
				onChange={(e: any) => {
					setSearch(e.target.value);
				}}
			/>,
			<Button
				type={'primary'}
				onClick={() => {
					if (actionRef.current) {
						actionRef.current.reloadAndRest();
					}
				}}
			>
				查询
			</Button>,
		];
	};
	const getList = async (page: number, size: number) => {
		let params = {
			search: search,
			startTime: startTime,
			endTime: endTime,
			page: page,
			size: size,
			sysModule: 'login',
		};
		let rsp = await getSystemLogs(params);
		if (rsp.err === 0) {
			return {
				data: rsp.data.data,
				total: rsp.data.total,
			};
		} else {
			return {
				data: [],
				total: 0,
			};
		}
	};
	return (
		<>
			<GSTable
				columns={columns}
				searchBarRender={tableSearchBarRender()}
				actionRef={actionRef}
				request={(params: any) => {
					return getList(params.current, params.pageSize);
				}}
			/>
		</>
	);
};
export default LogList;
