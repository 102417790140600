import React from 'react';
import MiniApp, { MiniAppMenu } from '../../util/MiniApp';
import ParameterSet from './pages/ParameterSet';
import NoticeSet from './pages/NoticeSet';
import WxBusinessList from './pages/WxBusinessList';
export default function CompanyProMiniApp() {
	const rootUrl = '/company-project-manager';
	const menus: MiniAppMenu[] = [
		{
			authority: 'foundation_setup:foundation_parameter',
			name: '基础信息',
			path: 'foundation-parameter',
			component: ParameterSet,
		},
		{
			authority: 'foundation_setup:notice_set',
			name: '通知设置',
			path: 'notice-set',
			component: NoticeSet,
		},
		{
			authority: 'wx_business:list',
			name: '支付设置',
			path: 'business-list',
			component: WxBusinessList,
		},
	];

	return <MiniApp miniAppName={'项目管理'} rootUrl={rootUrl} menus={menus} />;
}
