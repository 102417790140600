import React, { useEffect, useRef, useState } from 'react';
import GSTable from '../../../components/GSTable';
import { Button, Input, Space, TreeSelect } from 'antd';
import GsTreeSelect from '../../../components/GsTreeSelect';
import { findDepartmentDirectory, getAbnormalPersonnelList } from '../../../services/WuyeService';

const AbnormalPersonnel: React.FC = () => {
	const actionRef = useRef<any>();
	const [search, setSearch] = useState('');

	useEffect(() => {
	}, []);
	const columns = [{
		title: "部门",
		dataIndex: "departmentName",
		key: "departmentName"
	},{
		title: "姓名",
		dataIndex: "name",
		key: "name"
	},{
		title: "手机号",
		dataIndex: "phone",
		key: "phone"
	}, {
		title: "身份证号",
		dataIndex: "cardIdNum",
		key: "cardIdNum"
	},  {
		title: "未开锁天数",
		dataIndex: "unlockedDay",
		key: "unlockedDay"
	}];

	const tableSearchBarRender = () => {
		return [
			<Space>
				<Input onChange={(e: any) => {setSearch(e.target.value)}}/>
				<Button type={"primary"} onClick={() => {
					actionRef.current.reloadAndRest();
				}}>查询</Button>
			</Space>
		];
	};

	return (
		<>
			<GSTable
				columns={columns}
				rowKey={"uuid"}
				expandable={{
					defaultExpandAllRows: false
				}}
				actionRef={actionRef}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					let data = {
						search: search,
						page: params.current,
						size: params.pageSize
					}
					let rsp  = await getAbnormalPersonnelList(data)
					if (rsp.err === 0) {
						return {
							data: rsp.data.data,
							total: rsp.data.total
						}
					}
					return {
						data: [],
						total: 0
					}
				}}
			/>
		</>);
};
export default AbnormalPersonnel;
