import React, { useEffect, useRef, useState } from "react";
import GSTable from "../../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import {
    Button,
    Image,
    Modal,
    Input,
    message,
    Space,
    Select,
    Row,
    Col,
    Card,
    Statistic
} from "antd";
import { getList, asyncList, getListSumData } from "../../../services/CarRecord";
import * as XLSX from "xlsx";

import CustomRangePicker from "../../../components/CustomRangePicker";
import AuthorizedView from "../../../util/AuthorizedView";
import { carParkList } from "../../../services/Car";

let moment = require("moment");
const CarRecord: React.FC = (props: any) => {
    useEffect(() => {
        getCarParkList().then()
        loadSumData().then()
    }, []);
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState(undefined);
    const [startTime, setStartTime] = useState(moment().subtract(7, "d").hours(0).minute(0).second(0));
    const [endTime, setEndTime] = useState(moment().hours(23).minute(59).second(59));
    const [quanXuan, setQuanXuan] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('out');
    const [isShow, setisShow] = useState(false)
    const [carSumData, setcarSumData] = useState<any>({})
    const [carParkData, setCarParkData] = useState([]);
    const [carParkUuid, setCarParkUuid] = useState('');
    const columns = [
        {
            title: "车牌号",
            dataIndex: "cph",
            key: "cph",
            fixed: 'left',
            width: 100,
        },
        {
            title: "姓名",
            dataIndex: "userName",
            width: 100,
            key: "userName"
        },
        {
            title: "部门",
            dataIndex: "departmentName",
            width: 150,
            key: "departmentName"
        },
        {
            title: "类型",
            width: 100,
            dataIndex: "type",
            key: "type",
            valueEnum: {
                "out": "出场记录",
                "come": "场内车辆"
            }
        },
        {
            title: "入厂时间",
            dataIndex: "inTime",
            key: "inTime",
            width: 200,
            valueType: "dateTime"
        },
        {
            title: "入厂图片",
            dataIndex: "inPic",
            width: 100,
            key: "inPic",
            render: (text: string, record: any) => {
                return <>{
                    text === '-' ? '-': <Image src={text} style={{ width: 100, height: 100 }}/>
                }</>
            }
        },
        {
            title: "入厂车道名称",
            dataIndex: "inGateName",
            key: "inGateName",
            width: 100
        },
        {
            title: "出厂时间",
            dataIndex: "outTime",
            key: "outTime",
            width: 200,
            valueType: "dateTime"
        },
        {
            title: "出厂图片",
            dataIndex: "outPic",
            key: "outPic",
            width: 100,
            render: (text: string, record: any) => {
                return <>{
                    text === '-' ? '-': <Image src={text} style={{ width: 100, height: 100 }}/>
                }</>
            }
        },
        {
            title: "出车道名称",
            width: 100,
            dataIndex: "outGateName",
            key: "outGateName"
        },
        {
            title: "停留时间",
            width: 100,
            fixed: 'right',
            dataIndex: "stayTime",
            key: "stayTime"
        }
    ];
    const getCarParkList = async () => {
        let rsp = await carParkList({});
        if (rsp.err === 0) {
            setCarParkData(rsp.data)
        }
    }

    const tableSearchBarRender = () => [
        <Space>
            <Select defaultValue={carParkUuid} onChange={(e: string) => {return setCarParkUuid(e)}}>
                <Select.Option value={''}>全部车场</Select.Option>
                {
                    carParkData.map((item: any) => {
                        return <Select.Option value={item.uuid}>{item.name}</Select.Option>
                    })
                }
            </Select>
            <Select defaultValue={type} style={{width: 100}} onChange={(e:string) => setType(e)}>
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'out'}>出场记录</Select.Option>
                <Select.Option value={'come'}>场内车辆</Select.Option>
            </Select>
            <CustomRangePicker
                onChange={(startTime, endTime) => {
                    setStartTime(startTime);
                    setEndTime(endTime);
                }}
                selectType={3}
                startTime={startTime}
                endTime={endTime}
            />
            <Input
                placeholder={"车牌号"}
                onChange={(e: any) => {
                    setSearch(e.target.value);
                }}
            />
            <Button
                type={"primary"}
                onClick={() => {
                    actionRef.current.reloadAndRest();
                    loadSumData()
                }}
            >
                查询
            </Button>
        </Space>
    ];






    const loadSumData = async () => {
          let params = {
            carFactoryId: carParkUuid,
            startTime: startTime.format("YYYY-MM-DD HH:mm:ss"),
            endTime: endTime.format("YYYY-MM-DD HH:mm:ss"),
            search: search,
            type: type
        };
        setisShow(true)
        const res = await getListSumData(params);
        setisShow(false)
        if (res.err == 0) {

            setcarSumData(res.data || {})

        } else  {
            message.error(res.msg)
        }

    };

    const loadData = async (params: any) => {
        const res = await getList(params);
        if (res.err !== 0) {
            message.error(res.msg);
            return {
                data: [],
                total: 0,
                success: true
            };
        }
        setTotal(res.total);
        return {
            data: res.data,
            total: res.total,
            success: true
        };
    };
    const exprotData = async (selectedRows: [any?]) => {
        console.log("selectedRows:::::", selectedRows);
        setLoading(true);
        if (quanXuan) {
            let data = {
                search: search,
                type: type,
                carFactoryId: carParkUuid,
                startTime: startTime.format("YYYY-MM-DD 00:00:00"),
                endTime: endTime.format("YYYY-MM-DD 23:59:59")
            };
            let rsp = await getList(data);
            if (rsp.err === 0) {
                setExprotData(rsp.data);
            } else {
                message.error(rsp.msg);
            }
        } else {
            setExprotData(selectedRows);
        }
        setLoading(false);
    };
    const setExprotData = (selectedRows: [any?]) => {
        let sheetData = new Array(selectedRows.length + 1);
        let titles: [string?] = [];
        columns.map((item) => {
            titles.push(item.title);
        });
        sheetData[0] = titles;
        for (let i = 0; i < selectedRows.length; i++) {
            let data: [string?] = [];
            columns.map((item: any) => {
                if (item.valueEnum) {
                    if (item.valueEnum[selectedRows[i][item.dataIndex]]) {
                        if (item.valueEnum[selectedRows[i][item.dataIndex]].props) {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]].props.children);
                        } else {
                            data.push(item.valueEnum[selectedRows[i][item.dataIndex]]);
                        }

                    } else {
                        data.push("");
                    }
                } else if (item.valueType === "dateTime") {
                    data.push(moment(selectedRows[i][item.dataIndex]).format("YYYY-MM-DD HH:mm:ss"));
                } else {
                    data.push(selectedRows[i][item.dataIndex]);
                }
            });
            sheetData[i + 1] = data;
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "车辆通行记录");

        /* save to file */
        XLSX.writeFile(wb, "车辆通行记录.xlsx");
        setLoading(false);
    };
    const tableOperationsBarRender = () => [
        <AuthorizedView needAuthority={'car-manager:record:async'}>
            <Button type={"primary"} loading={loading} onClick={() => asyncRecordList()}>
                同步数据
            </Button>
        </AuthorizedView>

    ];

    const asyncRecordList = () => {
        Modal.confirm({
            title: '提示',
            content: '您确定要同步车场记录',
            okText: '同步',
            onOk: async () => {
                let data: any = {};
                setLoading(true)
                let rsp = await asyncList(data);
                setLoading(false)
                if (rsp.err === 0) {
                    message.success(rsp.msg);
                } else {
                    message.error(rsp.msg);
                }
            }
        })

    };




    const tableExtraRender = () => {
        return (
            <Row gutter={16}>
                <Col span={6}>
                    <Card  style={{ backgroundColor: "#fff" }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>入厂车辆</span>}
                            value={carSumData.incarsum}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>辆</span>}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card  style={{ backgroundColor: "#fff" }} bodyStyle={{ padding: "10px 15px" }}>
                        <Statistic
                            loading={isShow}
                            title={<span style={{ color: "#000", fontSize: 16 }}>出厂车辆</span>}
                            value={carSumData.outcarsum}
                            valueStyle={{ fontSize: 30 }}
                            suffix={<span style={{ fontSize: 16 }}>辆</span>}
                        />
                    </Card>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <GSTable
                columns={columns}
                rowKey={"id"}
                tableExtraRender={tableExtraRender}
                rowSelection={{
                    fixed: true,
                    columnWidth: 70,
                    preserveSelectedRowKeys: true,
                    selectedRowKeys: selectedRowKeys,
                    onChange: (selectedRowKeys: any, selectedRows: any) => {
                        setSelectedRowKeys(selectedRowKeys);
                        setQuanXuan(false);
                    },
                    selections: [
                        {
                            key: "SELECT_CURRENT_ALL",
                            text: "全选当前页面",
                            onSelect: (changableRowKeys: any) => {
                                setQuanXuan(false);
                                setSelectedRowKeys(changableRowKeys);
                            }
                        },
                        {
                            key: "SELECT_ALL",
                            text: "全选全部页面",
                            onSelect: (changableRowKeys: any) => {
                                setQuanXuan(true);
                                setSelectedRowKeys(changableRowKeys);
                            }
                        }
                    ]
                }}
                tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                    return (
                        <Space size={24}>
								<span>
									已选 {quanXuan ? total : selectedRowKeys.length} 项
									<a
                                        style={{ marginLeft: 8 }}
                                        onClick={() => {
                                            onCleanSelected();
                                            setQuanXuan(false);
                                        }}
                                    >
										取消选择
									</a>
								</span>
                        </Space>
                    );
                }}
                tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                    console.log("tableAlertOptionRender::::", selectedRows);
                    return (
                        <Space size={16}>
                            <Button loading={loading} type={"primary"}
                                    onClick={() => exprotData(selectedRows)}>导出数据</Button>
                        </Space>
                    );
                }}
                searchBarRender={tableSearchBarRender()}
                actionRef={actionRef}
                operationsBarRender={tableOperationsBarRender()}
                request={async (params: any = {}, sort: any, filter: any) => {
                    let param = {
                        page: params.current,
                        size: params.pageSize,
                        carFactoryId: carParkUuid,
                        startTime: startTime.format("YYYY-MM-DD HH:mm:ss"),
                        endTime: endTime.format("YYYY-MM-DD HH:mm:ss"),
                        search: search,
                        type: type
                    };
                    return loadData(param);
                }}
            />

        </>
    );
};
export default CarRecord;
