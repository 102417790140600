import Api from '../data/API';

export async function getCarList(params: any) {
	return Api.postWithAuth('/adminapi/car/getCarList', params);
}
export async function addCar(params: any) {
	return Api.postWithAuth('/adminapi/car/addCar', params);
}
export async function deleteCar(params: any) {
	return Api.postWithAuth('/adminapi/car/deleteCar', params);
}
export async function updateCar(params: any) {
	return Api.postWithAuth('/adminapi/car/updateCar', params);
}
export async function getCarPathList(params: any) {
	return Api.postWithAuth('/adminapi/car/getCarPathList', params);
}
export async function deleteCarPath(params: any) {
	return Api.postWithAuth('/adminapi/car/deleteCarPath', params);
}
export async function addCarPath(params: any) {
	return Api.postWithAuth('/adminapi/car/addCarPath', params);
}
export async function updateCarPath(params: any) {
	return Api.postWithAuth('/adminapi/car/UpdateCarPath', params);
}
export async function getCheChangSyncRecordList(params: any) {
	return Api.postWithAuth('/adminapi/car/getCheChangSyncRecordList', params);
}
export async function sendJob(params: any) {
	return Api.postWithAuth('/adminapi/car/sendJob', params);
}
export async function batchCar(params: any) {
	return Api.postWithAuth('/adminapi/car/batchCar', params);
}
export async function carParkList(params: any) {
	return Api.postWithAuth('/adminapi/car/carPark/list', params);
}
