import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import LookTiebaType from './pages/LookTiebaType';
import LookTieba from './compontents/LookTieba';
import WenZhangInfo from '../wenzhang/components/WenZhangInfo';

export default function TiebaMiniApp() {
	const rootUrl = '/postbar';
	const menus: MiniAppMenu[] = [
		{
			authority: 'post_bar_management:tieba_type_list',
			name: '贴吧管理',
			path: 'find-tieba-type-list',
			component: LookTiebaType,
		},
	];

	const routes: MiniAppRoute[] = [
		{
			path: 'find-tiezi-list',
			component: LookTieba,
		},
		{
			path: 'wenzhang_info',
			component: WenZhangInfo,
		},
	];
	return <MiniApp miniAppName={'贴吧'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
